import React from 'react';
import { shape } from 'prop-types';

const MarketIconCart = ({ colors }) => (
  <svg
    width="65"
    height="60"
    viewBox="0 0 65 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={{
        fill: `${colors.brand.primary}`,
        strokeWidth: `2.57903`,
        stroke: `${colors.brand.dark}`,
      }}
      d="M53.1832 32.3465L53.1832 32.3465L53.1825 32.357C53.1739 32.49 53.0789 32.6056 52.9342 32.6357L24.4072 38.5749C22.8395 38.9013 21.8356 40.4332 22.1619 42.0009C22.4883 43.5686 24.0202 44.5726 25.5879 44.2462L54.1255 38.3048C54.2987 38.2687 54.4648 38.3776 54.5009 38.5508C54.537 38.7241 54.4281 38.8902 54.2548 38.9263L25.7278 44.8655C23.824 45.2618 21.9473 44.0319 21.551 42.1281C21.2556 40.7095 21.8596 39.3069 22.9726 38.5212L23.8458 37.9048L23.4021 36.9324C22.8201 35.657 12.7866 13.4243 12.3993 12.5633L11.9744 11.6187L10.9604 11.8298L2.77986 13.533C2.60659 13.569 2.4405 13.4602 2.40443 13.2869C2.36835 13.1136 2.4772 12.9476 2.65047 12.9115L12.0896 10.9463C12.2321 10.9166 12.3879 10.9935 12.4521 11.1226L14.6229 15.9411L15.048 16.8849L16.0614 16.6739L54.51 8.66911C54.6144 8.64736 54.7143 8.67889 54.7763 8.73439C54.8593 8.80873 54.898 8.91398 54.8908 9.00803L54.8906 9.01181L53.1832 32.3465Z"
    />
    <path
      style={{
        fill: `${colors.brand.primary}`,
        strokeWidth: `2.57903`,
        stroke: `${colors.brand.dark}`,
      }}
      d="M30.8301 53.6521C28.9263 54.0484 27.0496 52.8185 26.6532 50.9147C26.2569 49.011 27.4868 47.1342 29.3906 46.7379C31.2943 46.3415 33.1711 47.5714 33.5674 49.4752C33.9638 51.379 32.7339 53.2557 30.8301 53.6521Z"
    />
    <path
      style={{
        fill: `${colors.brand.primary}`,
        strokeWidth: `2.57903`,
        stroke: `${colors.brand.dark}`,
      }}
      d="M53.0645 49.0232C51.1607 49.4195 49.284 48.1896 48.8876 46.2858C48.4913 44.382 49.7212 42.5053 51.625 42.109C53.5287 41.7126 55.4055 42.9425 55.8018 44.8463C56.1982 46.7501 54.9682 48.6268 53.0645 49.0232Z"
    />
  </svg>
);

MarketIconCart.propTypes = {
  colors: shape().isRequired,
};

export default MarketIconCart;
