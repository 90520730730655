import React from 'react';
import styled from 'styled-components';
import { node, bool } from 'prop-types';

import ProgressBar from '../components/Progressbar/ProgressBar';
import data from '../content.json';
import { useTranslation } from 'react-i18next';
import { getSocialLogin } from '../../../../store';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const Layout = ({ children, noMatches }) => {
  const sections = data.sections;
  const { t } = useTranslation();
  const flowStore = useFlowStore();

  return (
    <Background>
      <MainContainer>
        <Header>
          <Logo src={flowStore?.distribution?.logoFe} alt="WeGroup" />
          <ProgressBar steps={sections} />
        </Header>
        <ContentContainer>{children}</ContentContainer>
        {!noMatches && !getSocialLogin() ? (
          <Star>{t('MarketLayout.responsibleMsg')}</Star>
        ) : null}
        {!noMatches && getSocialLogin() ? (
          <Star>
            {t(
              'The rates shown online are indicative and may therefore differ from the actual rate It is therefore always advisable to contact your estate agent if you have any questions and/or comments',
            )}
          </Star>
        ) : null}
      </MainContainer>
    </Background>
  );
};

const Background = styled.main`
  background-color: ${({ theme }) => theme.brand.primary};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.font};
  font-size: 1.5rem;
  padding: 4rem 0;
  min-height: 100vh;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  max-height: 7rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  max-width: 120rem;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

Layout.defaultProps = {
  noMatches: false,
};

Layout.propTypes = {
  children: node.isRequired,
  noMatches: bool,
};

const Star = styled.p`
  font-size: 1.6rem;
  color: white;
  max-width: 85rem;
  font-weight: normal;
  margin: 2rem 0;
  line-height: 1.4;
`;

export default Layout;
