import React, { useState, useEffect } from 'react';
import AddressSearchSelector from './AddressSearchSelector';
import { useTranslation } from 'react-i18next';
import { SearchSelectInput, TextInput } from 'wg-fe-ui';
import _ from 'lodash';
import * as Yup from 'yup';
import styled from 'styled-components';

import useForm from '../hooks/useForm';
import {
  streetName,
  streetNumber,
  boxNumber,
  postalCode,
  municipalityName as municipalityNameValidation,
} from '../services/validationSchemas';
import {
  retrieveAddressStreets,
  retrieveAddressCities,
} from '../services/apiRouterService';
import { string, func, object, bool } from 'prop-types';
import { isObjectEmpty } from '../services/objectService';

const AddressSearchSelectorManualFallback = ({
  title,
  onSubmit,
  setValue,
  error,
  prefilled,
  ...otherProps
}) => {
  const [address, setAddress] = useState();
  const [streetNames, setStreetNames] = useState();
  const [municipalityName, setMunicipalityName] = useState();
  const [selected, setSelected] = useState({});
  const [postals, setPostals] = useState({});
  const { t, i18n } = useTranslation();

  const SignupSchema = Yup.object().shape({
    streetName: streetName({ min: '', max: '', matches: '', required: '' }),
    streetNumber: streetNumber({ max: '', matches: '', required: '' }),
    boxNumber: boxNumber({ max: '', matches: '' }),
    postalCode: postalCode({ required: '' }),
    municipalityName: municipalityNameValidation({
      min: '',
      max: '',
      matches: '',
      required: '',
    }),
  });

  const { values, handleChange, errors } = useForm({
    validationSchema: SignupSchema,
  });

  useEffect(() => {
    if (typeof setValue === 'object') {
      setAddress(setValue);
    }
  }, []);

  useEffect(() => {
    if (typeof setValue === 'object') {
      setAddress(setValue);
    }
  }, [setValue]);

  useEffect(() => {
    if (address) {
      const selectedObj = {};
      Object.keys(address).forEach(key => {
        handleChange({ name: key, value: address[key] });
        const selectedItem = {
          label: address[key],
          value: address[key],
        };
        selectedObj[key] = selectedItem;
      });
      setSelected(selectedObj);
    }
  }, [address]);

  useEffect(() => {
    if (values.streetName) {
      getStreetNames({
        searchQuery: values['streetName'].substring(0, 6),
        municipalityName: values['municipalityName'],
      });
    }
  }, [values.streetName]);

  useEffect(() => {
    const valuesCompare = _.pick(values, [
      'country_code',
      'municipalityName',
      'postalCode',
      'streetName',
      'streetNumber',
      'boxNumber',
    ]);

    if (values?.boxNumber === undefined) valuesCompare.boxNumber = undefined;

    if (_.isEqual(valuesCompare, setValue)) return;
    if (!isObjectEmpty(values)) {
      onSubmit(values);
    }
  }, [values]);

  async function fetchStreets(value, values) {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.municipalityName)
    ) {
      let streetsOptionList = [];
      let payload;
      if (value.searchQuery) {
        payload = {
          city: value.municipalityName
            ? value.municipalityName
            : values['municipalityName'],
          searchQuery: value.searchQuery,
        };
      } else {
        payload = {
          city: value.municipalityName,
        };
      }

      const [resp, status] = await retrieveAddressStreets(payload);
      if (status !== 200) return;
      resp.forEach(street => {
        streetsOptionList.push({
          value: street.name,
          label: street.name,
        });
      });
      return streetsOptionList;
    }
  }

  async function fetchCities(value) {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.zipcode)
    ) {
      let citiesOptionList = [];
      let cityPostals = {};
      const [resp, status] = await retrieveAddressCities(value);
      if (status !== 200) return;
      resp.forEach(city => {
        cityPostals[city.name] = city.zipcode;
        citiesOptionList.push({
          value: city.name,
          label: city.name,
        });
      });

      return { cityPostals, citiesOptionList };
    }
  }

  const getStreetNames = async val => {
    const resp = await fetchStreets(val, values);
    setStreetNames(resp);
    return resp;
  };

  const getCityNames = async val => {
    const resp = await fetchCities(val);
    setStreetNames({});
    setMunicipalityName(resp.citiesOptionList);
    // delete streetname
    const tmpSelected = { ...selected };
    delete tmpSelected['streetName'];
    setSelected(tmpSelected);
    handleChange({ name: 'streetName', value: undefined });
    setPostals(resp.cityPostals);
    return resp.citiesOptionList;
  };

  const handleSelected = value => {
    const selectedVal = { label: value.value, value: value.value };
    const tmpSelected = { ...selected };
    if (value.name === 'municipalityName') {
      //delete streetname
      delete tmpSelected['streetName'];
      if (postals[selectedVal.value]) {
        tmpSelected['postalCode'] = postals[selectedVal.value];
        handleChange({ name: 'postalCode', value: postals[selectedVal.value] });
      }
    }
    tmpSelected[value.name] = selectedVal;
    setSelected(tmpSelected);
    handleChange(value);
  };

  const handlZipcodeValidation = async () => {
    if (values['postalCode'] && values['postalCode'].length === 4) {
      const [resp] = await getCityNames({
        zipcode: parseInt(values['postalCode']),
      });
      handleChange({ name: 'streetName', value: undefined });
      handleChange({ name: 'streetNumber', value: undefined });
      handleSelected({ name: 'municipalityName', value: resp.value });
    }
  };

  if (!address) {
    return (
      <AddressSearchSelector
        error={error}
        onSelected={obj => !_.isNull(obj) && setAddress(obj.value)}
      >
        {title
          ? title
          : t(
              'Enter your street number and city Your box or apartment number can be filled in later',
            )}
      </AddressSearchSelector>
    );
  }

  return (
    <>
      <FlexWrapper {...otherProps}>
        <StyledSearchSelectInput
          async
          cacheOptions={false}
          defaultOptions={municipalityName}
          error={errors.municipalityName}
          id="municipalityName"
          prefilled={prefilled}
          name="municipalityName"
          loadOptions={e => getCityNames({ searchQuery: e })}
          onSelected={value => handleSelected(value)}
          value={selected.municipalityName || ''}
          noOptionsMessage={() => 'no city found'}
        >
          {i18n.t('Municipality')} *
        </StyledSearchSelectInput>
        <StyledTextInput
          error={errors.postalCode}
          onChange={handleChange}
          prefilled={prefilled}
          type="number"
          name="postalCode"
          dataTestId="underwriting_address_postalCode"
          value={values['postalCode']}
          onBlur={() => handlZipcodeValidation()}
          placeholder="zipcode"
        >
          {i18n.t('Postal code')} *
        </StyledTextInput>
      </FlexWrapper>
      <FlexWrapper>
        <StyledSearchSelectInput
          async
          cacheOptions={false}
          defaultOptions={streetNames}
          prefilled={prefilled}
          error={errors.streetNames}
          id="streetName"
          name="streetName"
          loadOptions={e => getStreetNames({ searchQuery: e })}
          onSelected={value => handleSelected(value)}
          value={selected.streetName || ''}
          noOptionsMessage={() => 'no streets found'}
        >
          {i18n.t('Street')} *
        </StyledSearchSelectInput>
        <FlexWrapper>
          <StyledTextInput
            error={errors.streetNumber}
            prefilled={prefilled}
            onChange={handleChange}
            type="text"
            name="streetNumber"
            disabled={!values['streetName']}
            value={
              values['streetNumber'] && values['streetName']
                ? values['streetNumber']
                : ''
            }
          >
            <FlowAdressInputLabel>{i18n.t('Housenr')} *</FlowAdressInputLabel>
          </StyledTextInput>
          <TextInput
            error={errors.boxNumber}
            onChange={handleChange}
            type="string"
            name="boxNumber"
            disabled={!values['streetName']}
            value={values['boxNumber'] ? values['boxNumber'] : ''}
          >
            {i18n.t('Boxnr')}
          </TextInput>
        </FlexWrapper>
      </FlexWrapper>
    </>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  & .Select__control {
    ${({ prefilled, error }) =>
      prefilled ? `border-color: #FFBC0F; background-color: #FFF9EA;` : ''}
  }
`;

const StyledTextInput = styled(TextInput)`
  & input {
    ${({ prefilled, error }) =>
      prefilled ? `border-color: #FFBC0F; background-color: #FFF9EA;` : ''}
  }
`;

const FlowAdressInputLabel = styled.p`
  height: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
`;
AddressSearchSelectorManualFallback.propTypes = {
  title: string,
  onSubmit: func,
  setValue: object,
  prefilled: bool,
  error: string,
};
export default AddressSearchSelectorManualFallback;
