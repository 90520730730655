import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { array } from 'prop-types';
import InfoPopUp from './InfoPopUp';
import LoadingCompareIcons from '../models/Marketplace/Market/components/LoadingCompareIcons';
import Arrow from '../../assets/images/arrow';

const MarketplaceInsuranceCardCoverageCarousel = ({ items }) => {
  const [scrollValue, setScrollValue] = useState(0);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);

  const handleScroll = direction => {
    // scrolling to left is '+' scrolling to right is '-'
    const views = chunk(items, 4);
    const maxScroll = -((views.length - 1) * 82);

    switch (direction) {
      case 'right':
        if (scrollValue > maxScroll) {
          setScrollValue(scrollValue - 41);
        }
        break;
      case 'left':
        if (scrollValue !== 0) {
          setScrollValue(scrollValue + 41);
        }
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    const views = chunk(items, 4);
    const maxScroll = -((views.length - 1) * 82);

    if (scrollValue !== maxScroll) {
      setRightArrow(true);
    } else {
      setRightArrow(false);
    }

    if (scrollValue === 0) {
      setLeftArrow(false);
    } else {
      setLeftArrow(true);
    }
  }, [scrollValue]);

  useEffect(() => {
    if (scrollValue !== 0) {
      setScrollValue(0);
    }

    checkArrows();
  }, [items]);

  const checkArrows = () => {
    // check if arrows have to show when items change
    const chunkedArray = chunk(items, 4);

    if (chunkedArray.length > 1) {
      setRightArrow(true);
      setLeftArrow(false);
    } else {
      setRightArrow(false);
      setLeftArrow(false);
    }
  };

  const loadItems = () => {
    const allItems = [];

    // made an element per item and pushed them into an array
    items.map((item, i) => {
      const element = (
        <div key={i}>
          {item.description ? (
            <StyledInfoPopUp
              title={item.title}
              info={item.description}
              buttonText={item.title}
              isLink={true}
            />
          ) : (
            <p>{item.title}</p>
          )}
          {<LoadingCompareIcons type={item.value} />}
        </div>
      );
      allItems.push(element);
    });

    // Return Views with 4 items in it from allItems
    return chunk(allItems, 4).map((arr, i) => {
      return (
        <View key={i}>
          {arr.map(element => {
            return element;
          })}
        </View>
      );
    });
  };

  // chunk splits an array in to multiple arrays. It returns an array of those arrays
  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
      arr.slice(i * size, i * size + size),
    );

  return (
    <MainContainer>
      {leftArrow ? (
        <div className="left arrow">
          <Arrow colors="#C1C1C1C1" onClick={() => handleScroll('left')} />
        </div>
      ) : null}
      <HiddenContainer>
        <Container scrollValue={scrollValue}>{loadItems()}</Container>
      </HiddenContainer>
      {rightArrow ? (
        <div className="right arrow">
          <Arrow colors="#C1C1C1C1" onClick={() => handleScroll('right')} />
        </div>
      ) : null}
    </MainContainer>
  );
};

const MainContainer = styled.div`
  position: relative;
  .arrow {
    width: 3rem;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    display: flex;
    align-items: center;
  }

  .left {
    margin-left: -0.5rem;
    left: -3rem;
    & svg {
      transform: rotate(-90deg);
      cursor: pointer;
    }
  }

  .right {
    margin-right: -0.5rem;
    right: -3rem;
    & svg {
      transform: rotate(90deg);
      cursor: pointer;
    }
  }
`;

const HiddenContainer = styled.div`
  display: flex;
  width: 82rem;
  overflow: hidden;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  transition: 0.3s;
  transform: ${props => `translateX(${props.scrollValue}rem)`};
`;

const View = styled.div`
  width: 82rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: 50%;
    margin-bottom: 2rem;
  }

  & span {
    font-style: italic;
    font-size: 1.4rem;
    font-weight: normal;
  }
`;

const StyledInfoPopUp = styled(InfoPopUp)`
  color: black;
  text-decoration: none;
  border-radius: 0;

  &:hover {
    color: ${({ theme }) => theme.brand.primary};
    text-decoration: underline;
  }
`;

MarketplaceInsuranceCardCoverageCarousel.propTypes = {
  items: array.isRequired,
};

export default MarketplaceInsuranceCardCoverageCarousel;
