import ApiRoutes from '../../../store/ApiRoutes';
// import { generateId } from '../../../store';
// import { getPublicBrokerData } from '../../../services/brokerDataService';
import {
  retrieveAutomobileByCar,
  retrieveLicensePlateV2,
} from '../../../services/apiRouterService';
const api = new ApiRoutes();

const postLicensePlate = plate => retrieveLicensePlateV2(plate);

const postAddressData = data => {
  return api.postAddressToolkit({
    street: data.streetName.toLowerCase(),
    housenr: data.streetNumber,
    boxnr: data.boxNumber,
    zipcode: data.postalCode,
    country_code: 'BE',
    lat: data.latitude,
    lng: data.longitude,
    city: data.municipalityName,
  });
};

const getManualData = ({ brand_id, model_id, version_id }) =>
  retrieveAutomobileByCar(brand_id, model_id, version_id);

const getBrands = () => api.getCarBrandsToolkit();

const getModels = brand => api.getCarModelsToolkit({ brand });

const getVersions = (brand, model) =>
  api.getCarVersionsToolkit({ brand, model });

const adaptVehicleCarToToolkitFormat = car => {
  return {
    brand: car?.brand,
    cc: car?.cc,
    chassis_number: car?.vin,
    co2: car?.co2,
    category: car?.category,
    color: car?.color,
    color_hex: car?.color_hex,
    img: car?.images && car?.images.length > 0 ? car.images[0] : null,
    is_cabrio: car?.category === 'CABRIO',
    is_jeep: car?.category === 'SUV',
    is_sportscar: car?.is_sportscar,
    kw: car?.kw,
    model: car?.series,
    model_year: car?.model_year,
    motor_type: car?.motor_type,
    name: car?.name,
    number_plate: car?.license_plate,
    registration_first: car?.registration_first,
    registration_last: car?.registration_last,
    seats: car?.amount_of_seats_incl_driver,
    value: car?.price,
    version: car?.version,
    kwh: car?.kwh,
    emission_standards: car?.emission_standards,
    transmission_type: car?.transmission_type,
    taxation: {
      biv: car?.taxation?.biv,
      road_tax: car?.taxation?.road_tax,
      biv_with_particulate_filter: car?.taxation?.biv_with_particulate_filter,
      road_tax_with_particulate_filter:
        car?.taxation?.road_tax_with_particulate_filter,
    },
  };
};

const generateTerminationLetter = data => api.generateTerminationLetter(data);

const generateCustomerSheet = data => api.generateCustomerSheet(data);

const generateRequiredInformation = data =>
  api.generateRequiredInformation(data);

const patchBrokerInfo = async data => {
  // patch settings as broker
  const { phone, name, assistant_name, theme } = data;
  const responseData = await api.patchBrokerSettingsAsBroker({
    phone,
    name,
    assistant_name,
    theme,
  });
  // const responseData = await api.patchBrokerSettings(JSON.stringify(data));
  const resp = responseData.data;
  const status = responseData.status;
  return [resp, status];
};

const patchMeInfo = async data => {
  // patch settings as broker on user account
  const { email, language } = data;
  const responseData = await api.patchMeSettingsAsBroker({
    email,
    language,
  });
  // const responseData = await api.patchBrokerSettings(JSON.stringify(data));
  const resp = responseData.data;
  const status = responseData.status;
  return [resp, status];
};

export {
  postLicensePlate,
  postAddressData,
  adaptVehicleCarToToolkitFormat,
  generateRequiredInformation,
  getManualData,
  generateTerminationLetter,
  patchBrokerInfo,
  patchMeInfo,
  getBrands,
  getModels,
  getVersions,
  generateCustomerSheet,
};
