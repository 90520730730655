import React, { createContext } from 'react';
import { node } from 'prop-types';
import * as configcat from 'configcat-js';
import { CONFIGCAT_KEY } from '../../env.config';

export const FlagsProvider = createContext(undefined);

export const FlagsProviderHoc = ({ children }) => {
  let logger = configcat.createConsoleLogger(3);
  let configCatClient = configcat.createClient(CONFIGCAT_KEY, {
    logger: logger,
  });

  return (
    <FlagsProvider.Provider value={configCatClient}>
      {children}
    </FlagsProvider.Provider>
  );
};

FlagsProviderHoc.propTypes = {
  children: node.isRequired,
};
