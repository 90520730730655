import { proFlowStorage } from './localforageService';
import { getMeData } from './meDataService';

function getStoreInstance(dbName) {
  switch (dbName) {
    case 'pro_flow':
      return proFlowStorage;
    default:
      console.error('you forgot assign a database');
      break;
  }
}

export async function newStorageEntry(dbName, id) {
  const lf = getStoreInstance(dbName);
  const { id: userId } = (await getMeData()) || {};
  if (!lf) return;
  return await lf.setItem(id, {
    created_at: new Date().toISOString(),
    session_completed: false,
    user_id: userId,
    answers: [],
  });
}

export async function retrieveStorageById(dbName, id) {
  const lf = getStoreInstance(dbName);
  if (!lf) return;
  return await lf.getItem(id);
}

export async function patchStorage(dbName, id, payload) {
  const lf = getStoreInstance(dbName);
  if (!lf) return;
  const storedData = await lf.getItem(id);
  if (!storedData) return;
  const updatedData = Object.assign(storedData, payload);
  return await lf.setItem(id, updatedData);
}
