import React from 'react';
import { Section, OverviewTable } from 'wg-fe-ui';
import { object } from 'prop-types';
import styled from 'styled-components';
import InfoPopUp from '../../../components/InfoPopUp';
import { useTranslation } from 'react-i18next';
// import { getNestedObject } from '../../../services/objectService';

const DemographicData = ({ addressData }) => {
  const { t } = useTranslation();
  const demographics = addressData.demographic;

  const info =
    'The theft score is a score out of ten, which reflects the risk of a possible theft, based on a number of predetermined parameters This score is calculated as follows (higher score is higher risk) -Half of the total score is determined by a derivation of the average number of house burglaries in the city -The other half is classified as follows 1 point if the closest neighbor is more than 50 meters away 1 point if the border of Belgium is less than 3 km 1 point if less than 5 people live within a 100-meter radius 1 point if the nearest police station is more than 3km away 1 point if the closest fire brigade is more than 3km away';
  return (
    <Section data-test-id="dashboard_toolkit_addresslookup_section_annexes">
      <Section.Title>{t('Demographics')}</Section.Title>
      <Section.Content>
        <OverviewTable>
          <OverviewTable.Head>
            <StyledTableData width="40%">
              {t('Theft risk')}
              <InfoPopUp title={t('Theft risk')} info={t(info)} />
            </StyledTableData>
            <StyledTableData width="40%">{t('Mean income')}</StyledTableData>
          </OverviewTable.Head>
          <OverviewTable.Body>
            <StyledRow>
              <OverviewTable.BodyCell>
                {demographics.theft.risk !== null
                  ? demographics.theft.risk + ' / 10'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {demographics.income.mean !== null
                  ? '€ ' + demographics.income.mean.toFixed(2)
                  : '--'}
              </OverviewTable.BodyCell>
            </StyledRow>
          </OverviewTable.Body>
        </OverviewTable>
      </Section.Content>
    </Section>
  );
};

const StyledTableData = styled(OverviewTable.HeaderCell)`
  width: 40%;
  font-weight: 700;
  font-size: 1.6rem;
  color: #5b5550;
`;

const StyledRow = styled(OverviewTable.Row)`
  &:hover * {
    background-color: white;
  }
`;

DemographicData.propTypes = {
  addressData: object.isRequired,
};

export default DemographicData;
