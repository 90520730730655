import React from 'react';
import { shape } from 'prop-types';

const CheckmarkLock = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M18.0816 6.51525L7.4234 14.6842L17.289 4.42554C17.6263 5.09213 17.892 5.79262 18.0816 6.51525Z"
      fill="black"
    />
    <path
      d="M0.839723 8.415C0.70712 8.27773 0.63211 8.09489 0.630096 7.90404C0.635036 7.71573 0.709742 7.53597 0.839723 7.39963L1.83545 6.4039C1.89689 6.33528 1.97212 6.28038 2.05621 6.2428C2.14031 6.20522 2.23138 6.1858 2.32349 6.1858C2.4156 6.1858 2.50667 6.20522 2.59077 6.2428C2.67486 6.28038 2.75008 6.33528 2.81153 6.4039L2.88359 6.47596L6.81409 10.6685C6.87782 10.7313 6.96371 10.7665 7.05319 10.7665C7.14268 10.7665 7.22857 10.7313 7.2923 10.6685L16.85 0.783278H16.9286C16.9931 0.718706 17.0697 0.66748 17.154 0.63253C17.2383 0.597579 17.3286 0.57959 17.4199 0.57959C17.5112 0.57959 17.6015 0.597579 17.6858 0.63253C17.7701 0.66748 17.8467 0.718706 17.9112 0.783278L18.9069 1.77901C19.0354 1.90898 19.1074 2.08433 19.1074 2.26704C19.1074 2.44976 19.0354 2.62511 18.9069 2.75508L7.53468 14.6055C7.4036 14.7344 7.22716 14.8066 7.04337 14.8066C6.85958 14.8066 6.68314 14.7344 6.55205 14.6055L0.983842 8.62463L0.839723 8.415Z"
      fill={'#fff'}
    />
  </svg>
);

CheckmarkLock.propTypes = {
  colors: shape().isRequired,
};

export default CheckmarkLock;
