import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import ProFlowChooseRiskAndInsurance from './ProFlowChooseRiskAndInsurance.js';
import ProFlowNewTarrificationProspect from './ProFlowNewTarrificationProspect.js';
import ProFlowRiskObjects from './ProFlowRiskObjects';
import ProFlowOffers from './ProFlowOffers';

import Layout from '../models/DashboardLayout/views/Layout';
import { ProFlowStorageContextProvider } from '../services/context/ProFlowStorageContext.js';

const ProFlowRouter = () => {
  return (
    <StyledLayout showTop={false}>
      <ProFlowStorageContextProvider>
        <Switch>
          <Route
            path={'/professional-flow/choose-risk-and-insurance'}
            component={ProFlowChooseRiskAndInsurance}
          />
          <Route
            path={'/professional-flow/new-tarrification-prospect'}
            component={ProFlowNewTarrificationProspect}
          />
          <Route
            path={'/professional-flow/risk-objects/:sessionId'}
            component={ProFlowRiskObjects}
          />
          <Route
            path={'/professional-flow/offers/:sessionId'}
            component={ProFlowOffers}
          />
          <Redirect
            strict
            from="/professional-flow"
            to="/professional-flow/choose-risk-and-insurance"
          />
        </Switch>
      </ProFlowStorageContextProvider>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

export default ProFlowRouter;
