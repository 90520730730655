import React from 'react';
import { shape, string } from 'prop-types';

const MapMock = ({ colors, className }) => (
  <div className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1268 481">
      <g id="garage">
        <rect
          x="1018.89"
          y="216"
          width="224.11"
          height="189"
          fill={colors.brand.lightest}
        />
        <rect x="1059.5" y="268.91" width="143" height="135.77" fill={'#fff'} />
        <polygon
          points="1241 215.94 1164 173 1019.31 173 1019.31 216 1241 215.94"
          fill={colors.brand.primary}
        />
        <rect
          x="1065.5"
          y="268.91"
          width="143"
          height="135.77"
          fill={colors.brand.secondary}
        />
        <rect
          x="1059.5"
          y="268.91"
          width="143"
          height="135.77"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="1018.89"
          y1="216"
          x2="1096.5"
          y2="216"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="1109.56"
          y1="215.98"
          x2="1123.5"
          y2="215.98"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="1184.08"
          y1="215.96"
          x2="1241.5"
          y2="215.96"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <rect
          x="1019.5"
          y="173"
          width="18.5"
          height="232"
          fill={colors.brand.darker}
          opacity="0.1"
        />
        <polyline
          points="1019.31 173 1164.5 173 1241.5 215.5 1241.5 404.68"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="1059.5"
          y1="302.14"
          x2="1202.5"
          y2="302.14"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="1060.5"
          y1="336.5"
          x2="1202.5"
          y2="336.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="1059.5"
          y1="370.71"
          x2="1202.5"
          y2="370.71"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <g id="bedroom_other" data-name="bedroom+other">
        <rect
          x="197"
          y="170.56"
          width="229"
          height="234.69"
          fill={colors.brand.lightest}
        />
        <polygon
          points="305.85 236 278.36 288.49 278.36 269.95 296.14 236 305.85 236"
          fill={colors.brand.lightest}
        />
        <polygon
          points="327.62 236 278.36 330.03 278.36 306.31 315.19 236 327.62 236"
          fill={colors.brand.lightest}
        />
        <path
          d="M623,353.5"
          transform="translate(-311.5 -18)"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.361"
        />
        <path
          d="M623,256.5"
          transform="translate(-311.5 -18)"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3.361"
        />
        <polygon
          points="197.78 170.94 350.95 86 426.5 86 426.5 170.56 197.78 170.94"
          fill={colors.brand.primary}
        />
        <line
          x1="198.5"
          y1="170.5"
          x2="312.14"
          y2="170.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="331.96"
          y1="170.95"
          x2="345.41"
          y2="170.95"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="390.52"
          y1="170.95"
          x2="426"
          y2="170.95"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <rect
          x="406.5"
          y="86"
          width="19.5"
          height="319"
          fill={colors.brand.darker}
          opacity="0.1"
        />
        <polyline
          points="426 86 352 86 198 171 198 405.25"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <rect x="284" y="238" width="57.41" height="101.8" fill={'#fff'} />
        <polygon
          points="301.85 238 284 272.08 284 253.54 292.14 238 301.85 238"
          fill={colors.brand.lightest}
        />
        <polygon
          points="323.62 238 284 313.63 284 289.91 311.19 238 323.62 238"
          fill={colors.brand.lightest}
        />
        <rect
          x="284"
          y="239"
          width="56"
          height="6"
          fill={colors.brand.secondary}
        />
        <rect
          x="284"
          y="241"
          width="6"
          height="97"
          fill={colors.brand.secondary}
        />
        <path
          d="M649.91,259v95.8H596.5V259h53.41m3-3H593.5V357.8h59.41V256Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.dark}
        />
      </g>
      <g id="living_kitchen" data-name="living+kitchen">
        <rect
          x="662.89"
          y="170.56"
          width="356.42"
          height="234.69"
          fill={colors.brand.lightest}
        />
        <rect x="743" y="238" width="57.41" height="101.8" fill={'#fff'} />
        <polygon
          points="760.85 238 743 272.08 743 253.54 751.14 238 760.85 238"
          fill={colors.brand.lightest}
        />
        <polygon
          points="782.62 238 743 313.63 743 289.91 770.19 238 782.62 238"
          fill={colors.brand.lightest}
        />
        <rect
          x="743"
          y="239"
          width="56"
          height="6"
          fill={colors.brand.secondary}
        />
        <rect
          x="793"
          y="241"
          width="6"
          height="97"
          fill={colors.brand.secondary}
        />
        <path
          d="M1108.91,259v95.8H1055.5V259h53.41m3-3H1052.5V357.8h59.41V256Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.dark}
        />
        <polygon
          points="663 170.3 663 86.5 662.3 86 866.83 86 1020 170.95 663 170.3"
          fill={colors.brand.primary}
        />
        <line
          x1="725.5"
          y1="170.5"
          x2="1018.5"
          y2="170.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="711.64"
          y1="170.5"
          x2="662.5"
          y2="170.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="663 86 867 86 1019.5 170.5 1019 405"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <rect
          x="661.5"
          y="86"
          width="19.5"
          height="319"
          fill={colors.brand.darker}
          opacity="0.1"
        />
        <rect x="832" y="238" width="113" height="101.8" fill={'#fff'} />
        <polygon
          points="923.9 238 870.57 339.8 835.06 339.8 888.39 238 923.9 238"
          fill={colors.brand.lightest}
        />
        <polygon
          points="869.71 238 832 309.99 832 286.27 857.29 238 869.71 238"
          fill={colors.brand.lightest}
        />
        <rect
          x="835"
          y="240"
          width="110"
          height="5"
          fill={colors.brand.secondary}
        />
        <rect
          x="883"
          y="241"
          width="6"
          height="97"
          fill={colors.brand.secondary}
        />
        <rect
          x="938"
          y="241"
          width="6"
          height="97"
          fill={colors.brand.secondary}
        />
        <line
          x1="889.5"
          y1="240.5"
          x2="889.5"
          y2="337.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M1254.5,259v95.8h-108V259h108m3-3h-114V357.8h114V256Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.dark}
        />
      </g>
      <g id="door">
        <polyline
          points="426 239.8 426 236 662.89 236 662.89 405.25 426 405.25 426 239.8"
          fill={colors.brand.lightest}
        />
        <line
          x1="663"
          y1="405"
          x2="663"
          y2="298.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="662.89"
          y1="279.76"
          x2="662.89"
          y2="256.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="426.5"
          y1="268.5"
          x2="426.5"
          y2="405.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="512 405.32 512 296.77 575.25 296.77 575.25 405.32"
          fill={'#fff'}
        />
        <polyline
          points="516.12 405.32 516.12 296.77 580.81 296.77 580.81 405.32"
          fill={colors.brand.secondary}
        />
        <line
          x1="523.28"
          y1="350.84"
          x2="531.23"
          y2="350.84"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="512 403.58 512 296.77 575.25 296.77 575.25 404.45"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <g id="bath">
        <polygon
          points="544.45 1 426 86 426 236 662.89 236 662.89 86 544.45 1"
          fill={colors.brand.lightest}
        />
        <polyline
          points="426.5 216.76 426.5 86.5 544.5 1.5 662.3 86 663 86.5 663 170.3 663 212.08"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M738,234.76"
          transform="translate(-311.5 -18)"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M738,423.5"
          transform="translate(-311.5 -18)"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M894.7,187.29v.21a37.84,37.84,0,0,1-.23,4A39,39,0,0,1,860,226.05a40.19,40.19,0,0,1-4.28.23h-.22A38.71,38.71,0,0,1,842.57,224h0a38.69,38.69,0,0,1-13.87-8.61,38.86,38.86,0,0,1-10-15.79A38.56,38.56,0,0,1,817,191.5a37.69,37.69,0,0,1-.23-4v-.21a39,39,0,0,1,28.54-37.56,38.49,38.49,0,0,1,9.48-1.4l.75,0h.22a39.07,39.07,0,0,1,4.22.23H860a39.18,39.18,0,0,1,4,.64h0a39,39,0,0,1,30.74,38.11Z"
          transform="translate(-311.5 -18)"
          fill={'#fff'}
        />
        <path
          d="M854.75,148.33l-31.92,59.91a38.85,38.85,0,0,1-4.12-8.65l26.56-49.86A38.49,38.49,0,0,1,854.75,148.33Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.lightest}
        />
        <path
          d="M878.84,155.9,842.57,224h0a38.69,38.69,0,0,1-13.87-8.61L864,149.18h0A38.88,38.88,0,0,1,878.84,155.9Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.lightest}
        />
        <path
          d="M855.72,151.31a36.54,36.54,0,0,1,3.9.21l.19,0a35.92,35.92,0,0,1,31.89,35.75v.19a36,36,0,0,1-32,35.59,37.66,37.66,0,0,1-4,.21h-.2a35.94,35.94,0,0,1-35.58-32.12,35,35,0,0,1-.21-3.66v-.21a36.06,36.06,0,0,1,35.77-36h.22m0-3h-.22a39,39,0,0,0-38.77,39v.21a37.69,37.69,0,0,0,.23,4,39,39,0,0,0,38.54,34.78h.22a40.19,40.19,0,0,0,4.28-.23,39,39,0,0,0,34.47-34.55,37.84,37.84,0,0,0,.23-4v-.21A39,39,0,0,0,860,148.54h-.06a39.07,39.07,0,0,0-4.22-.23Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.dark}
        />
        <polyline
          points="544.5 132 544.5 169.5 544.5 206.28"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="581.2 169.5 544.5 169.5 510.9 169.5 507.23 169.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
      <g id="solar_panels" data-name="solar panels">
        <rect
          x="680.5"
          y="102.5"
          width="34"
          height="48"
          fill={colors.brand.secondary}
        />
        <rect
          x="681.13"
          y="102.5"
          width="34"
          height="48"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="692.38 102.5 692.38 117.5 692.38 134.5 692.38 150.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          points="703.88 102.5 703.88 117.5 703.88 134.5 703.88 150.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="715.12"
          y1="117.5"
          x2="681.12"
          y2="117.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="715.12"
          y1="134.5"
          x2="681.12"
          y2="134.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="725.5"
          y="102.5"
          width="34"
          height="48"
          fill={colors.brand.secondary}
        />
        <rect
          x="726.13"
          y="102.5"
          width="34"
          height="48"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="737.38 102.5 737.38 117.5 737.38 134.5 737.38 150.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          points="748.88 102.5 748.88 117.5 748.88 134.5 748.88 150.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="760.12"
          y1="117.5"
          x2="726.12"
          y2="117.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="760.12"
          y1="134.5"
          x2="726.12"
          y2="134.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="769.5"
          y="102.5"
          width="34"
          height="48"
          fill={colors.brand.secondary}
        />
        <rect
          x="770.13"
          y="102.5"
          width="34"
          height="48"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <polyline
          points="781.38 102.5 781.38 117.5 781.38 134.5 781.38 150.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <polyline
          points="792.88 102.5 792.88 117.5 792.88 134.5 792.88 150.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="804.12"
          y1="117.5"
          x2="770.12"
          y2="117.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="804.12"
          y1="134.5"
          x2="770.12"
          y2="134.5"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <g id="garden">
        <path
          d="M419.42,273.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79C468.85,51,485,71,490.23,100.67c6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09C488.62,281.25,473.79,371.49,443,370,418.14,368.8,420.31,313,419.42,273.41Z"
          transform="translate(-311.5 -18)"
          fill={'#fff'}
        />
        <path
          d="M426.42,273.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79C475.85,51,492,71,497.23,100.67c6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09C495.62,281.25,480.79,371.49,450,370,425.14,368.8,427.31,313,426.42,273.41Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.secondary}
        />
        <path
          d="M419.42,273.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79C468.85,51,485,71,490.23,100.67c6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09C488.62,281.25,473.79,371.49,443,370,418.14,368.8,420.31,313,419.42,273.41Z"
          transform="translate(-311.5 -18)"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="130.64"
          y1="403.25"
          x2="130.64"
          y2="169"
          fill="none"
          stroke={colors.brand.dark}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M555.44,423H345.77c-.7-5.06-1.15-13.62,2.92-17.82C359.5,394,392.5,404,409.5,403c29.72-1.75,79.41-21.48,111-14C549.77,395.93,554.72,414.72,555.44,423Z"
          transform="translate(-311.5 -18)"
          fill={colors.brand.primary}
        />
        <path
          d="M550.44,423H340.77c-.7-5.06-1.15-13.62,2.92-17.82C354.5,394,387.5,404,404.5,403c29.72-1.75,79.41-21.48,111-14C544.77,395.93,549.72,414.72,550.44,423Z"
          transform="translate(-311.5 -18)"
          fill="none"
          stroke={colors.brand.dark}
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </g>
      <g id="ground" fill="none" stroke={colors.brand.dark}>
        <line
          y1="405"
          x2="1268"
          y2="405"
          strokeMiterlimit="10"
          strokeWidth="4"
        />
        <line
          x1="159"
          y1="462.5"
          x2="546"
          y2="462.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="586"
          y1="462.5"
          x2="648"
          y2="462.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <line
          x1="847"
          y1="479.5"
          x2="1096"
          y2="479.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </svg>
  </div>
);

MapMock.defaultProps = {
  className: '',
};

MapMock.propTypes = {
  colors: shape().isRequired,
  className: string,
};

export default MapMock;
