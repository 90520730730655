import ApiRoutes from '../../../store/ApiRoutes';
import {
  auth,
  generateSocialId,
  generateCampaignId,
  setSocialLogin,
} from '../../../store/index';

const api = new ApiRoutes();

const postAuth = payload => api.postAuth(JSON.stringify(payload));

const validateSocialLogin = brokerId => {
  auth.isAuthenticated = true;
  setSocialLogin(true);
  return generateSocialId(brokerId).then(() => {});
};

const validateCampaignLogin = (brokerId, campaignId) => {
  auth.isAuthenticated = true;
  setSocialLogin(true);
  return generateCampaignId(brokerId, campaignId).then(() => {});
};

export { postAuth, validateSocialLogin, validateCampaignLogin };
