import React, { useState } from 'react';
import styled from 'styled-components';
import { string, node, bool, shape, func } from 'prop-types';
import { Field, ErrorMessage } from 'formik';

import checkmark from '../../assets/images/validation-checkmark.svg';
import LoadingGif from '../../assets/animations/Loading.gif';
import errormark from '../../assets/images/validation-error.svg';
import { detect } from 'detect-browser';
import { useDebouncedCallback } from 'use-debounce';
import { sendEmail } from '../models/Flow/User/store';
import InfoPopUp from './InfoPopUp';

const InputBox = ({
  type,
  name,
  children,
  placeholder,
  disabled,
  onFocus,
  onBlur,
  autofocus,
  errors,
  touched,
  setValidEmail,
  info,
  pending,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  setPending,
  invalidError,
  dataTestId,
  className,
}) => {
  const browser = detect();
  const [focus, setFocus] = useState();
  const [iconRight, setIconRight] = useState('1rem');
  // const [value, setValue] = useState('');
  const handleFocus = () => {
    setFocus(true);
    switch (browser.name) {
      case 'safari':
        setIconRight('3.5rem');
        break;
      case '':
        setIconRight('3.5rem');
        break;
      default:
        setIconRight('1rem');
        break;
    }
  };

  const [debouncedCallback] = useDebouncedCallback(value => {
    checkEmail(value);
  }, 1000);

  const checkEmail = value => {
    // only check if the field passes Yup email validation first
    if (process.env.NODE_ENV !== 'development') {
      sendEmail(value)
        .then(() => {
          setFieldError(name, undefined);
          setValidEmail(true);
          setPending(false);
          setFieldTouched(name, true);
          return true;
        })
        .catch(() => {
          setValidEmail(false);
          setFieldError(name, invalidError);
          setPending(false);
          setFieldTouched(name, true);
          return false;
        });
    } else {
      console.log(`skipped mail check because you're on dev`);
      setFieldError(name, undefined);
      setValidEmail(true);
      setPending(false);
      setFieldTouched(name, true);
      return true;
    }
  };

  return (
    <div className={className}>
      <StyledLabel
        onFocus={() => handleFocus()}
        onBlur={() => setFocus(false)}
        htmlFor={name}
      >
        <Field
          name={name}
          render={({ field }) => (
            <StandardInput
              {...field}
              onChange={e => {
                setValidEmail(false);
                setFieldValue(name, e.target.value);
                if (
                  name === 'email' ||
                  name === 'useremail' ||
                  name === 'complaints_mail' ||
                  name === 'claims_mail'
                ) {
                  debouncedCallback(e.target.value);
                }
                // setValue(e.target.value);
              }}
              onFocus={e => onFocus(name, e.target.value)}
              onBlur={e => {
                setFieldTouched(name, true);
                setFieldValue(name, e.target.value);
                if (
                  name === 'email' ||
                  name === 'useremail' ||
                  name === 'complaints_mail' ||
                  name === 'claims_mail'
                ) {
                  checkEmail(e.target.value);
                }
                onBlur(name, e.target.value);
              }}
              data-test-id={dataTestId}
              id={name}
              disabled={disabled}
              type={type}
              name={name}
              // value={value}
              errors={errors[name]}
              isTouched={touched[name]}
              Styledtouched={touched[name] ? touched[name] : null}
              autoFocus={autofocus}
              placeholder={placeholder !== null ? placeholder : null}
            />
          )}
        />
        <LabelContainer>
          <span>{children}</span>
          {info !== null ? <InfoPopUp title={children} info={info} /> : null}
        </LabelContainer>
        {pending ? (
          <Icon
            focus={focus}
            right={iconRight}
            browser={browser.name}
            src={LoadingGif}
            alt="loading"
          />
        ) : errors[name] && touched[name] ? (
          <Icon
            focus={focus}
            right={iconRight}
            browser={browser.name}
            src={errormark}
            alt="error"
          />
        ) : touched[name] ? (
          <Icon
            focus={focus}
            right={iconRight}
            browser={browser.name}
            src={checkmark}
            alt="checkmark"
          />
        ) : null}
      </StyledLabel>
      <ErrorContainer>
        <ErrorMss name={name} component="p" />
      </ErrorContainer>
    </div>
  );
};

const StyledLabel = styled.label`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column-reverse;
  margin-bottom: 0.5rem;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & span {
    font-size: 1.4rem;
    transition: 0.2s;
    line-height: 1rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.typo.interactive};
    margin-left: 0.5rem;
    align-self: flex-end;

    & span {
      color: black;
    }
  }
`;

const StandardInput = styled(Field)`
  background-color: white;
  border: 0.1rem solid rgba(0, 0, 0, 0);
  border-color: ${props =>
    props.errors && props.isTouched
      ? 'red !important'
      : props.isTouched
      ? '#49C562 !important'
      : 'rgba(0,0,0,0)'};
  border-radius: 0.3rem;
  width: 100%;
  height: 3.5rem;
  padding-left: 1rem;
  font-size: 1.5rem;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.brand.primary};

    & + span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ErrorContainer = styled.div`
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const ErrorMss = styled(ErrorMessage)`
  color: red;
  font-size: 1.2rem;
`;

const Icon = styled.img`
  position: absolute;
  right: ${props => (props.focus ? props.right : '1rem')};
  bottom: 1.2rem;
  max-width: 2rem;
  transition: 0.2s;
  object-fit: contain;
`;

InputBox.defaultProps = {
  placeholder: null,
  disabled: false,
  autofocus: false,
  errors: {},
  touched: {},
  invalidError: ``,
  dataTestId: ``,
  pending: false,
  info: null,
  setFieldValue: () => {},
  setFieldError: () => {},
  setFieldTouched: () => {},
  setValidEmail: () => {},
  setPending: () => {},
  onBlur: () => {},
  onFocus: () => {},
};

InputBox.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  pending: bool,
  children: node.isRequired,
  placeholder: string,
  disabled: bool,
  autofocus: bool,
  errors: shape(),
  touched: shape(),
  info: string,
  setFieldValue: func,
  setFieldError: func,
  setFieldTouched: func,
  setValidEmail: func,
  invalidError: string,
  dataTestId: string,
  setPending: func,
  className: string,
  value: string,
  onFocus: func,
  onBlur: func,
};

export default InputBox;
