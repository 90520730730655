import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  ActionButton,
  H3,
  AddEntityButton,
  Tabs,
  Switcher,
  Modal,
  LoadingSpinner,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import CampaignsAccordionBox from '../components/CampaignsAccordionBox';
import NewCampaignModal from '../components/NewCampaignModal';
import CampaignCreatedModal from '../components/CampaignCreatedModal';
import { useCampaignStore } from '../context/CampaignContext';
import { differenceInCalendarDays } from 'date-fns';
import {
  createCampaignFromFormData,
  useCampaignCreationDispatch,
  useCampaignCreationStore,
} from '../context/CampaignCreationContext';
import { getBrokerData } from '../../../../js/services/brokerDataService';

const CampaignBuilder = () => {
  const { campaigns, isCampaignsLoading } = useCampaignStore();
  const campaignCreationStore = useCampaignCreationStore();
  const dispatch = useCampaignCreationDispatch();
  const { t } = useTranslation();

  const [campaignTypeTab, setCampaignTypeTab] = useState('email');
  const [campaignTab, setCampaignTab] = useState('active');
  const [addNewCampaignModalOpen, setAddNewCampaignModalOpen] = useState(false);
  const [addNewCampaignSuccessOpen, setAddNewCampaignSuccessOpen] = useState(
    false,
  );

  async function handleCampaignCreatedModal(values) {
    const { broker } = await getBrokerData();
    const { affiliations } = campaigns || {};
    createCampaignFromFormData(
      {
        values,
        broker,
        distribution: campaigns?.distribution,
        affiliations,
      },
      dispatch,
    );
  }

  useEffect(() => {
    const { creationStatus } = campaignCreationStore || {};
    if (creationStatus === 'succes') {
      setAddNewCampaignSuccessOpen(true);
    }
  }, [campaignCreationStore]);

  function computeAmountOfDaysLeft(date) {
    return differenceInCalendarDays(new Date(date), new Date());
  }

  function computeCampaignAmount(_campaigns, key, _isCampaignsLoading) {
    if (_isCampaignsLoading) return '';
    return _campaigns?.[key]?.filter(isCampaignActive)?.length;
  }

  function isCampaignActive(obj) {
    if (obj.end_date === null && obj.active) return true;
    return obj.active && 0 <= computeAmountOfDaysLeft(obj.end_date);
  }

  function popUpMessage(status) {
    switch (status) {
      case 'success':
        return t('Your campaign was created succesfully');
      case 'patched':
        return t('Your campaign was succesfully updated');
      case 'error':
        return t('Something went wrong while creating your campaign');
      default:
        break;
    }
  }

  return (
    <div>
      <CampaignContainer>
        <Title>{t('Campaigns')}</Title>
        <StyledTabsContainer>
          <Tabs onSelect={selected => setCampaignTypeTab(selected)}>
            <Tabs.Item name="email">
              {t('Email campaigns')}
              <CampaignTypeCount active={campaignTypeTab === 'email'}>
                {computeCampaignAmount(campaigns, 'email', isCampaignsLoading)}
              </CampaignTypeCount>
            </Tabs.Item>
            <Tabs.Item name="website">
              {t('Websites')}
              <CampaignTypeCount active={campaignTypeTab === 'website'}>
                {computeCampaignAmount(
                  campaigns,
                  'website',
                  isCampaignsLoading,
                )}
              </CampaignTypeCount>
            </Tabs.Item>
          </Tabs>
        </StyledTabsContainer>
        <Campaigns>
          <div>
            {campaignTypeTab === 'email' ? (
              <H3>{t('Your email campaigns')}</H3>
            ) : (
              <H3>{t('Your websites')}</H3>
            )}
            <Switcher>
              <Switcher.SwitcherButton
                active={campaignTab === 'active'}
                onClick={() => setCampaignTab('active')}
              >
                {t('Active campaigns')}
              </Switcher.SwitcherButton>
              <Switcher.SwitcherButton
                active={campaignTab === 'all'}
                onClick={() => setCampaignTab('all')}
              >
                {t('All campaigns')}
              </Switcher.SwitcherButton>
            </Switcher>
          </div>
          <AddEntityButton
            fullwidth={true}
            icon="IconPlusFilled"
            name={t('Add a new campaign')}
            onClick={() => {
              setAddNewCampaignModalOpen(true);
            }}
          />
          <Modal
            large
            canClose
            title={t('New campaign')}
            showModal={addNewCampaignModalOpen}
            setShowModal={() => setAddNewCampaignModalOpen(false)}
          >
            <NewCampaignModal
              sendSuccess={handleCampaignCreatedModal}
              setAddNewCampaignModalOpen={setAddNewCampaignModalOpen}
              initialType={campaignTypeTab}
            />
          </Modal>
          <Modal
            large
            canClose
            title={popUpMessage(campaignCreationStore?.creationStatus)}
            showModal={addNewCampaignSuccessOpen}
            setShowModal={() => setAddNewCampaignSuccessOpen(false)}
          >
            {campaignCreationStore?.creationStatus === 'succes' && (
              <CampaignCreatedModal id={campaignCreationStore?.campaignId} />
            )}
            <Modal.ModalActions position="center">
              <ActionButton
                level="primary"
                onClick={() => setAddNewCampaignSuccessOpen(false)}
              >
                {t('Back to overview')}
              </ActionButton>
            </Modal.ModalActions>
          </Modal>
        </Campaigns>
        {isCampaignsLoading ? (
          <LoadingSpinner />
        ) : (
          campaigns?.[campaignTypeTab]
            ?.filter(({ active, end_date }) =>
              campaignTab === 'active'
                ? isCampaignActive({ active, end_date })
                : true,
            )
            .sort((a, b) => a?.end_date?.localeCompare(b?.end_date))
            .sort((a, b) => {
              if (isCampaignActive(a) && isCampaignActive(b)) return 1;
              if (isCampaignActive(a) && !isCampaignActive(b)) return -1;
              if (!isCampaignActive(a) && !isCampaignActive(b)) return 0;
            })
            .map(({ id }) => (
              <CampaignsAccordionBox
                setAddNewCampaignModalOpen={setAddNewCampaignModalOpen}
                id={id}
                key={id}
              />
            ))
        )}
      </CampaignContainer>
    </div>
  );
};

const StyledTabsContainer = styled.div`
  margin-bottom: 3rem;
  li {
    display: flex;
    height: fit-content;
    align-items: center;
    padding: 2rem;
    width: fit-content;
  }
`;

const CampaignTypeCount = styled.div`
  background-color: ${({ active }) => (active ? '#FF8000' : '#D5D5D5')};
  border-radius: 50%;
  color: white;
  width: 2rem;
  justify-content: center;
  height: 2rem;
  display: flex;
  margin-left: 1rem;
  align-items: center;
  font-size: 1.3rem;
`;

const Campaigns = styled.div`
  div:first-child {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
  button {
    background: transparent;
    border: 2px dashed #d5d5d5;
    margin-top: 3rem;
    flex-direction: row;
    span.label {
      color: #d5d5d5;
    }
    svg {
      height: 2.5rem;
      padding-top: 0.6rem;
      path {
        fill: #d5d5d5;
      }
    }
    :focus {
      outline: none;
    }
  }
`;

const CampaignContainer = styled.div`
  max-width: 150rem;
  padding: 3.5rem;
  position: absolute;
  width: calc(100% - 25rem);
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 800;
  letter-spacing: 0.1rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

export default CampaignBuilder;
