import React, { Component } from 'react';
import { number, object, node, bool, string } from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import checkedImage from '../../../../../../assets/images/checkmark.svg';
import cross from '../../../../../../assets/images/skipped-cross.svg';
import { getCurrentStep, getContent } from '../../../../../store';
import routes from '../../../../../store/content.json';

class Step extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { progressPercent: 0 };
    this.props = props;
  }

  checkStepPassed = () => {
    const { content, skipped } = this.props;

    if (getCurrentStep() > content.id && skipped === false) {
      this.checkmark = <CheckedIcon src={checkedImage} alt="checked" />;
      this.circle = <Circle checked />;
    }
    if (getCurrentStep() <= content.id && skipped === false) {
      this.checkmark = '';
      this.circle = <Circle checked={false} />;
    }
    if (getCurrentStep() === content.id) {
      this.checkmark = '';
      this.circle = <Circle checked={false} active />;
    }
    if (skipped && getCurrentStep() > content.id) {
      this.checkmark = <Cross src={cross} alt="checked" />; // Will be a cross later
      this.circle = <Circle checked />;
    }

    if (skipped && getCurrentStep() < content.id) {
      this.checkmark = '';
      this.circle = <Circle checked={false} />;
    }
  };

  checkMiniProgress = () => {
    const { content, currentStep, subStep } = this.props;
    if (currentStep > content.id) {
      const Percent = 100;
      this.setState({ progressPercent: Percent });
    }
    if (currentStep === content.id) {
      const Percent =
        (subStep / Object.keys(getContent()[content.name]).length) * 100;
      this.setState({ progressPercent: Percent });
    }
    if (currentStep < content.id) {
      const Percent = 0;
      this.setState({ progressPercent: Percent });
    }
  };

  componentDidMount = () => {
    this.checkMiniProgress();
  };

  render() {
    this.checkStepPassed();
    const { icon, content, dataTestId } = this.props;
    const { progressPercent } = this.state;
    const firstMainStep = routes[content.name][0];
    return (
      <ProgressStep>
        {content.id > getCurrentStep() ? (
          <div data-test-id={dataTestId}>
            {this.checkmark}
            <StepIcon alt={icon.key}>{icon}</StepIcon>
            {this.circle}
          </div>
        ) : (
          <Link data-test-id={dataTestId} to={firstMainStep.route}>
            {this.checkmark}
            <StepIcon alt={icon.key}>{icon}</StepIcon>
            {this.circle}
          </Link>
        )}
        <MiniProgressBar>
          <Progress percent={progressPercent} />
        </MiniProgressBar>
      </ProgressStep>
    );
  }
}

const ProgressStep = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    width: 5rem;

    & > div:last-child {
      display: none;
    }
  }

  @media screen and (max-width: 800px) {
    transform: scale(0.95);
  }
`;

const Circle = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  color: white;
  border: solid 5px ${({ theme }) => theme.brand.secondary};
  background-color: ${({ theme, checked, active }) =>
    checked || active ? theme.brand.secondary : theme.brand.primary};
  border-radius: 4rem;
  font-weight: bold;
  font-size: 2.5rem;
  transition: 0.3s;
`;

const StepIcon = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  position: absolute;
  margin-left: -1rem;
  color: white;
  font-weight: bold;
  font-size: 2.5rem;
  cursor: pointer;
`;
const CheckedIcon = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  position: absolute;
  z-index: 10;
  margin-left: 0.5rem;
  margin-top: -0.5rem;
`;
const Cross = styled.img`
  width: 4rem;
  height: 4rem;
  position: absolute;
  z-index: 10;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
`;
const MiniProgressBar = styled.div`
  background-color: ${({ theme }) => theme.brand.secondary};
  border-radius: 1rem;
  width: 50%;
  height: 7px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
`;
const Progress = styled.div`
  background-color: white;
  border-radius: 1rem;
  width: ${props => props.percent}%;
  /* transition: 0.3s; */
  height: 7px;
`;

Step.defaultProps = {
  currentStep: 0,
  subStep: 0,
};

Step.propTypes = {
  currentStep: number,
  subStep: number,
  dataTestId: string,
  // eslint-disable-next-line react/forbid-prop-types
  content: object.isRequired,
  icon: node.isRequired,
  skipped: bool.isRequired,
};

export default Step;
