import React from 'react';
import { shape } from 'prop-types';

const StepIconCar = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.03 44.84"
    style={{ width: `100%`, height: `100%` }}
  >
    <title>Car</title>
    <g id="Car">
      <path
        style={{ fill: `${colors.brand.primary}` }}
        d="M48.87,54a5.62,5.62,0,0,1-1.52-.2A5.9,5.9,0,0,1,43.76,51a7,7,0,0,1-.65-5.22l.16-.59-.37-.49c-.34-.45-.34-.45-14.83-4.36-14.24-3.84-14.52-3.84-14.73-3.84h-.08l-.74.09-.36.71,0,.13A6.48,6.48,0,0,1,6,42.47a6.74,6.74,0,0,1-1.77-.24A5.73,5.73,0,0,1,.8,39.44,7,7,0,0,1,.19,34.2a13.41,13.41,0,0,1,.54-1.34l.48-1.08-1-.55s-4.54-2.36-5.73-3.06a.84.84,0,0,1-.12-.73c.68-2.57,1.37-5.15,2.07-7.72a.7.7,0,0,1,.67-.59c3.73.12,7.43.26,11.13.4l2.78.11c1.74.08,3.48.16,5.22.2h.14a6.44,6.44,0,0,0,3.4-1l10.91-6.76a2.52,2.52,0,0,1,1.3-.4,2.47,2.47,0,0,1,.6.08l26.59,7.12A1.58,1.58,0,0,1,60.28,20q2.69,8,5.33,16a4.09,4.09,0,0,1,0,2.38c-.58,2.23-1.19,4.47-1.79,6.69l-.23.85c-.19.71-.57.83-.91.85s-.74,0-2,0c-1.54,0-3.39,0-4,0h0A1.49,1.49,0,0,0,55.27,48c-.07.44-.14.84-.19,1A6.57,6.57,0,0,1,48.87,54Z"
        transform="translate(6.97 -10.4)"
      />
      <path
        style={{ fill: `${colors.brand.dark}` }}
        d="M31.93,12.9a1.45,1.45,0,0,1,.28,0L58.8,20.06c.19.05.23.1.3.29,1.82,5.41,3.61,10.8,5.33,16a3,3,0,0,1,0,1.67c-.58,2.23-1.18,4.46-1.78,6.68l-.22.79c-.26,0-.75,0-1.69,0-1.62,0-3.59,0-4,0h-.06A2.76,2.76,0,0,0,54,47.8a8.2,8.2,0,0,1-.16.89,5.32,5.32,0,0,1-5,4.05,4.79,4.79,0,0,1-1.19-.15,4.67,4.67,0,0,1-2.84-2.24,5.66,5.66,0,0,1-.52-4.25l.33-1.19-.75-1c-.55-.72-.55-.72-5.78-2.16L28.4,39.11C14,35.23,13.75,35.23,13.34,35.23h-.16l-.16,0-1.27.16-.61,1.13L11,36.8l-.08.29a5.18,5.18,0,0,1-5,4.13A5.09,5.09,0,0,1,4.53,41a4.44,4.44,0,0,1-2.64-2.19,5.67,5.67,0,0,1-.49-4.29c.06-.21.29-.78.46-1.17l1-2.16L.74,30.12c-1.08-.55-3.77-2-5.13-2.71q.93-3.51,1.88-7,5.32.17,10.65.38l2.75.11c1.72.08,3.5.15,5.26.2h.17a7.69,7.69,0,0,0,4.06-1.21l5-3.12,5.89-3.64a1.17,1.17,0,0,1,.64-.22m0-2.5A3.64,3.64,0,0,0,30,11L19.06,17.75a5.18,5.18,0,0,1-2.74.84h-.11c-2.66-.07-5.32-.21-8-.31-3.7-.14-7.41-.28-11.11-.4h-.07a1.92,1.92,0,0,0-1.87,1.51C-5.53,22-6.21,24.54-6.9,27.12a2,2,0,0,0,.69,2.13C-5,30-.41,32.34-.41,32.34A14.17,14.17,0,0,0-1,33.86C-2.18,38,.08,42.36,3.85,43.43A7.77,7.77,0,0,0,6,43.72a7.7,7.7,0,0,0,7.38-6h0c.43,0,28.6,7.6,28.57,7.7h0A7.69,7.69,0,0,0,47,55a6.84,6.84,0,0,0,1.84.24,7.82,7.82,0,0,0,7.41-5.89c.09-.33.18-.88.23-1.18a.23.23,0,0,1,.22-.21h0c.6,0,2.47,0,4,0,.88,0,1.66,0,2.06,0a2.18,2.18,0,0,0,2-1.77c.68-2.52,1.36-5,2-7.55a5.41,5.41,0,0,0-.06-3.09q-2.64-8-5.33-16a2.82,2.82,0,0,0-2-1.9q-13.29-3.57-26.59-7.12a3.25,3.25,0,0,0-.92-.13Z"
        transform="translate(6.97 -10.4)"
      />
    </g>
  </svg>
);

StepIconCar.propTypes = {
  colors: shape().isRequired,
};
export default StepIconCar;
