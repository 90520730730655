import ApiRoutes from '../../../../store/ApiRoutes';

const api = new ApiRoutes();

const answers = [''];
let data = {};
let famData = {
  is_married: false,
  children: false,
  works_at_home: false,
  travels: false,
  shared_objects: false,
  students: false,
};

const dataById = {};
const famDataById = {};

const addDataForIdLegal = id => {
  dataById[id] = JSON.parse(JSON.stringify(data));
  famDataById[id] = JSON.parse(JSON.stringify(famData));
};

const getAllAnswers = () => answers;

const getAnswer = key => data[key];

const setInsuredData = (key, value) => {
  const id = sessionStorage.getItem('id');
  if (key === 'wants_independent_legal_aid' && value === false) {
    dataById[id] = {};
  }
  dataById[id][key] = value;

  if (value === false && key === 'already_insured_with') {
    if (dataById[id].hasOwnProperty('previous_insurance_start_date')) {
      delete dataById[id].previous_insurance_start_date;
    }
    delete dataById[id].already_insured_with;
  }
};

const getInsuredData = () => {
  const id = sessionStorage.getItem('id');
  return {
    already_insured_with: dataById[id]?.already_insured_with,
    previous_insurance_start_date: dataById[id]?.previous_insurance_start_date,
  };
};

const setFamData = (key, value) => {
  famDataById[sessionStorage.getItem('id')][key] = value;
};

const updateTrait = (key, value) => {
  dataById[sessionStorage.getItem('id')][key] = value;
};

const addAnswer = (answer, key, status) => {
  const id = sessionStorage.getItem('id');
  dataById[id][key] = status;
  localStorage.setItem('legal', JSON.stringify(dataById[id]));
  answers.push(answer);

  localStorage.setItem('legalAnswers', JSON.stringify(answers));
};

const clearAnswer = from => {
  answers.splice(from, answers.length - 1);
};

const getLegalData = () => dataById[sessionStorage.getItem('id')];

const postLegalData = () => {
  return api.postLegalData(
    JSON.stringify(dataById[sessionStorage.getItem('id')]),
  );
};

const postFamData = () =>
  api.postFamilyData(JSON.stringify(famDataById[sessionStorage.getItem('id')]));
export {
  setFamData,
  postFamData,
  addDataForIdLegal,
  postLegalData,
  getInsuredData,
  getLegalData,
  addAnswer,
  clearAnswer,
  updateTrait,
  setInsuredData,
  getAllAnswers,
  getAnswer,
};
