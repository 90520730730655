import React from 'react';
import { string } from 'prop-types';

const PROPERTY = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <title>{title}</title>
    <g id="icons">
      <path
        d="M39.56,18.56,36,16.17V10.81a1,1,0,0,0-1-1H31a1,1,0,0,0-1,1v1.34l-4.45-3a1,1,0,0,0-1.1,0l-14,9.39a1,1,0,0,0-.45.84V40a1,1,0,0,0,1,1h6V31a1,1,0,0,1,1-1h5a1,1,0,0,1,1,1V41H39a1,1,0,0,0,1-1V19.4A1,1,0,0,0,39.56,18.56Z"
        fill={color}
      />
    </g>
  </svg>
);

PROPERTY.defaultProps = {
  title: '',
  color: '#ccc',
};

PROPERTY.propTypes = {
  title: string,
  color: string,
};

export default PROPERTY;
