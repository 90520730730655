import { setLoggedIn /*, goToLogin*/ } from './authService';
import { request } from './httpSessionService';

/* * * * * * * * * * * * * * *
 * AUTHENTICATION
 * * * * * * * * * * * * * * */
export async function authMe() {
  const path = 'v1/api/aut/v1/api/auth/me';
  const [resp, status] = await request(path, 'GET');
  if (status === 200) {
    setLoggedIn({
      email: resp.me.email,
      language: resp.me.language,
      role: resp.me.role,
    });
  } else {
    console.error(resp, status);
  }
  return [resp, status];
}

export async function auth(email, password) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request(path, 'POST', {
    email: email,
    password: password,
  });
  return [resp, status];
}

export async function changePassword(oldPassword, password) {
  const path = 'v1/api/aut/v1/api/user/me/password';
  const [resp, status] = await request(path, 'PATCH', {
    old_password: oldPassword,
    password: password,
  });
  return [resp, status];
}

export async function verifyInit() {
  /*
   * the verify method that is called on page load
   * it checks if you have cookies set.
   *
   * if there are expired cookies, renew them and go to dashboard
   * if no cookies found redirect to login page
   */
  const path = 'v1/api/aut/v1/api/auth/verify';
  const [resp, status] = await request(path, 'GET');
  if (status === 401) {
    if (resp.msg === 'NOT_AUTHENTICATED' || resp.msg === 'INVALID_TOKEN') {
      window.location.href = '#/logout';
    }
  } else {
    await authMe();
  }
  return [resp, status];
}

export async function logout() {
  const path = 'v1/api/aut/v1/api/logout';
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * EXTERNAL AUTHENTICATION
 * * * * * * * * * * * * * * */

export async function fbAuth(accessToken, userId, askRole, askBrokerId) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request(path, 'POST', {
    facebook_access_token: accessToken,
    facebook_user_id: userId,
    ask_role: askRole,
    ask_broker_id: askBrokerId,
  });
  return [resp, status];
}

export async function googleAuth(googleCode, askRole, askBrokerId) {
  const path = 'v1/api/aut/v1/api/auth';
  const [resp, status] = await request(path, 'POST', {
    google_code: googleCode,
    ask_role: askRole,
    ask_broker_id: askBrokerId,
  });
  return [resp, status];
}

export async function captchaAuth({
  captcha,
  email,
  firstName,
  lastName,
  askBrokerId,
}) {
  const path = 'v1/api/aut/v1/api/auth';
  const payload = {
    captcha: captcha,
    captcha_user_information: {
      email: email,
      first_name: firstName,
      last_name: lastName,
    },
    ask_broker_id: askBrokerId,
    ask_role: 'social_login',
  };
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * BROKER DATA
 * * * * * * * * * * * * * * */

export async function retrieveBrokerById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function patchBrokerById(brokerId, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function updateUserById(userId, payload) {
  const path = `v1/api/aut/v1/api/user`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function retrieveDistributionById(distributionId) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function patchDistributionById(distributionId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

// These are the insurance companies (allianz, axa, ...)
export async function retrieveAffiliationsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

// This is almost CALLANT only (honda, valckenier, ...)
export async function retrieveAffinitiesById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affinities`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveMembershipsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/memberships`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAvailableAffiliations() {
  const path = `v1/api/broker/v1/api/affiliations`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}
export async function retrieveIntegrationsById(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/integrations`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function validateBrokerAffiliation(name, payload) {
  let path = `v1/api/policy/v1/api/check/${name}?`;
  for (let i = 0; i < Object.keys(payload).length; i++) {
    if (i === 0) {
      path += `${Object.keys(payload)[i]}=${payload[Object.keys(payload)[i]]}`;
    } else {
      path += `&${Object.keys(payload)[i]}=${payload[Object.keys(payload)[i]]}`;
    }
  }
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function patchBrokerAffiliation(brokerId, name, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations/${name}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function patchBrokerIntegrations(brokerId, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/integrations`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function addBrokerAffiliation(brokerId, payload) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function deleteBrokerAffiliation(brokerId, name) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/affiliations/${name}`;
  const [resp, status] = await request(path, 'DELETE');
  return [resp, status];
}

export async function uploadBrokerAvatar(userId, formData) {
  const path = `v1/api/broker/v1/api/brokers/${userId}/upload/assistant_avatar`;
  const [resp, status] = await request(path, 'POST', formData);
  return [resp, status];
}

export async function uploadAvatarAsUser(userId, payload) {
  const path = `v1/api/broker/v1/api/users/${userId}/upload/avatar`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function retrieveCanSellList(brokerId) {
  const path = `v1/api/broker/v1/api/brokers/${brokerId}/can_sell`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * MAILER
 * * * * * * * * * * * * * * */

export async function sendFeedbackWithMailer(formData) {
  const path = `v1/api/mailer/v1/api/mail/feedback`;
  const [resp, status] = await request(path, 'POST', formData);
  return [resp, status];
}

export async function sendRequestAccessMail(formData) {
  const path = `v1/api/mailer/v1/api/mail/broker/request_access`;
  const [resp, status] = await request(path, 'POST', formData);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * POLICY
 * * * * * * * * * * * * * * */

export async function getSignatureCallbackCheck(signature_id) {
  const path = `v1/api/policy/v1/api/signature_check/${signature_id}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function exportToInsurerById(id) {
  const path = `v1/api/policy/v1/api/save`;
  const [resp, status] = await request(path, 'POST', { quotes: [id] });
  return [resp, status];
}

export async function exportToInsurerById_V2(id) {
  const path = `v1/api/quotes/v1/api/quotes/${id}`;
  const [resp, status] = await request(path, 'POST', {});
  return [resp, status];
}

export async function exportToCrmById(id, payload) {
  const path = `v1/api/quotes/v1/api/quotes/${id}/crm`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * QUOTES
 * * * * * * * * * * * * * * */

export async function retrieveProFlowQuestionsNumber(query) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveInsuranceCompanies() {
  const path = `v1/api/quotes/v1/api/multiquotes/support`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Retrieve available options for quote
 * @param {string} query - ex: 'insurance_type=legal&&company=vivium'
 * @param {Object} payload - An object.
 */
export async function retrieveProFlowQuestions(query, payload) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes/questions${queryPath}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/**
 * Retrieve available options for quote
 * @param {string} query - ex: 'insurance_type=legal&&company=vivium'
 */
export async function retrieveProFlowGuarantees(query) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes/tags${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveProFlowQuestionsProspect(query, payload) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/multiquotes${queryPath}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function createQuotesSession() {
  const path = `v1/api/quotes/v1/api/quotes/session`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function createRiskObjectSession(sessionId, payload) {
  const path = `v1/api/quotes/v1/api/multiquotes/${sessionId}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function batchQuotesForSession(sessionId, payload) {
  const path = `v1/api/quotes/v1/api/batch/${sessionId}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function getPyModelsObjectsFromQuoteStorage(quoteId) {
  const path = `v1/api/quotes/v1/api/quotes/${quoteId}?typecast=PYMODELS`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getPyModelsObjectsFromQuoteStorageSMT(quoteId) {
  const path = `v1/api/quotes/v1/api/quotes/${quoteId}?typecast=SMT`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getResultsFromFinishedBatchById(sessionId, query) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/batch/results/${sessionId}${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Retrieve available options for quote
 * @param {string} insuranceType - ex: 'car', 'home',...
 * @param {string} insuranceCompany - ex: 'pnp', 'vivium',...
 * @param {string} insurance - ex: 'civil_liability', 'mini_omnium',...
 */

export async function retrieveAvailableOptionsByQuote(
  insuranceType,
  insuranceCompany,
  insurance,
) {
  const path = `v1/api/quotes/v1/api/quote/${insuranceType}/${insuranceCompany}/${insurance}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Calculate a home quote by id and quote specification
 * @param {Object} obj - An object.
 * @param {string} obj.insuranceType - ex: 'car', 'home',...
 * @param {string} obj.insuranceCompany - ex: 'pnp', 'vivium',...
 * @param {string} obj.insurance - ex: 'civil_liability', 'mini_omnium',...
 * @param {Object} obj.payload - ex: {quote_specifications: {...}, id: 'adb6231f-44c1-45fa-a334-19b7655e4037'}
 * @param {string} obj.query - ex: 'option=omnium&&option=legal'
 */
export async function calculateQuoteByInsuranceType({
  insuranceType,
  insuranceCompany,
  insurance,
  payload,
  query,
}) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/quotes/v1/api/quote/${insuranceType}/${insuranceCompany}/${insurance}${queryPath}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

export async function searchAddress(query, language = 'NL') {
  const path = `v1/api/address/v1/api/search?q=${query}&language_code=${language}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAddressStreets({ searchQuery, city }) {
  let path;
  if (searchQuery) {
    path = `v1/api/address/v1/api/streets?q=${searchQuery}&q_city=${city}`;
  } else if (city) {
    path = `v1/api/address/v1/api/streets?q_city=${city}`;
  }
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAddressCities({ zipcode, searchQuery }) {
  let path;
  if (zipcode && !searchQuery) {
    path = `v1/api/address/v1/api/cities?zipcode=${zipcode}`;
  } else if (searchQuery && !zipcode) {
    path = `v1/api/address/v1/api/cities?q=${searchQuery}`;
  }
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveRandomAddress() {
  const path = `v1/api/address/v1/api/random`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAR
 * * * * * * * * * * * * * * */

export async function retrieveLicensePlate(licensePlate) {
  const path = `v1/api/car/v1/api/license_plate/${licensePlate}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CRM
 * * * * * * * * * * * * * * */

/* Leads
-------------------------------------------------- */
export async function getAllLeadsByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads?pagelen=10&page=${_pageNumber}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getFilteredLeadsByDistributionId(
  _distributionId,
  _pageNumber,
  _filteredOptions,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads?pagelen=15&page=${_pageNumber}${query}${
    _filteredOptions?.length > 0 ? `&` : ''
  }${_filteredOptions}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getLeadById(_distributionId, _leadId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function updateLead(_distributionId, _leadId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

/**
 * Route to calculate an offer from a lead.
 * @param {string} _distributionId The distribution ID.
 * @param {string} _leadId The ID of the lead you want to calculate an offer for.
 * @param {object} _payload The payload as defined in the portal.
 */
export async function calculateQuoteAsLead(_distributionId, _leadId, _payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/calculate/quote`;
  const [resp, status] = await request(path, 'POST', _payload);
  return [resp, status];
}

/**
 * Convert a current lead into an offer.
 * @param {string} _distributionId The distribution ID.
 * @param {string} _leadId The ID of the lead you want to convert to an offer.
 * @param {object} _payload The payload as defined in portal.
 */
export async function convertLeadToOffer(_distributionId, _leadId, _payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/leads/${_leadId}`;
  const [resp, status] = await request(path, 'POST', _payload);
  return [resp, status];
}

/* Offers
-------------------------------------------------- */
export async function getAllOffersByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers?pagelen=20&page=${_pageNumber}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getFilteredOffersByDistributionId(
  _distributionId,
  _pageNumber,
  _queryString,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers?pagelen=15&page=${_pageNumber}${query}${
    _queryString?.length > 0 ? `&` : ''
  }${_queryString}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getOfferById(_distributionId, _offerId, _revisionId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${
    _revisionId ? _revisionId : 0
  }`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getOfferRevisions(_distributionId, _offerId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function changeOfferStatus(
  _distributionId,
  _offerId,
  _revisionId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revisionId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function updateOfferById(_distributionId, _offerId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function updateContractById(
  _distributionId,
  _contract_case_id,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contract_case_id}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function updateLeadById(_distributionId, _leadId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function convertOfferToContract(
  _distributionId,
  _offerId,
  _revisionId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/offers/${_offerId}/revisions/${_revisionId}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function getAllDocumentsByOfferId(_distributionId, _offerId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/documents`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getAllDocumentsByOfferIdAndRevision(
  _distributionId,
  _offerId,
  _revisionId,
) {
  // const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/documents`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revisionId}/documents`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Fetch data of a specific document connected to an offer.
 * @param {string} _distributionId The distribution ID.
 * @param {string} _offerId The offer ID.
 * @param {int} _revision The revision of the offer
 * @param {string} _documentId The ID of the document to get.
 */
export async function getOfferRevisionDocumentById(
  _distributionId,
  _offerId,
  _revision,
  _documentId,
) {
  // const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/documents`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/documents/${_documentId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* Contracts
-------------------------------------------------- */
/**
 * Function to convert an offer to a contract while overwriting the guarantees.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer to convert.
 * @param {integer} _revisionId The revision number of the offer to convert.
 * @param {object} payload The payload to post.
 */
export async function convertOfferToContractManual(
  _distributionId,
  _offerId,
  _revisionId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/offers/${_offerId}/revisions/${_revisionId}/manual`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

// Contracts
export async function getAllContractsByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts?pagelen=20&page=${_pageNumber}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getFilteredContractsByDistributionId(
  _distributionId,
  _pageNumber,
  _filteredOptions,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts?pagelen=100&page=${_pageNumber}${query}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getContractById(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getContractRevisions(_distributionId, _contractCaseId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Create contract addendum.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _contractId The 'ID' of the contract.
 * @param {object} payload The payload which will be added.
 */
export async function createContractAddendum(
  _distributionId,
  _contractId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/addendums`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

// Prospects
export async function getAllProspectsByDistributionId(
  _distributionId,
  _pageNumber,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects?pagelen=10&page=${_pageNumber}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getFilteredProspectsByDistributionId(
  _distributionId,
  _pageNumber,
  _filteredOptions,
  _topSearchValue,
) {
  let query = '';
  if (_topSearchValue) query += `&q=${encodeURIComponent(_topSearchValue)}`;
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects?pagelen=15&page=${_pageNumber}${query}${
    _filteredOptions?.length > 0 ? `&` : ''
  }${_filteredOptions}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Get Prospect by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the prospect.
 */
export async function getProspectById(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Create Prospect.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {('companies' | 'customers')} _type The type of the prospect.
 * @param {object} payload The payload which will be added.
 */
export async function createProspect(_distributionId, _type, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_type}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/**
 * Delete Prospect by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the prospect.
 */
export async function deleteProspectById(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}`;
  const [resp, status] = await request(path, 'DELETE');
  return [resp, status];
}

/**
 * Update Prospect of type Customer by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the customer / lead / prospect.
 * @param {object} payload The payload which will be patched.
 */
export async function updateProspectCustomerById(
  _distributionId,
  _prospectId,
  payload,
) {
  console.log(payload);
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/customers/${_prospectId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

/**
 * Update Prospect of type Company by ID.
 * @param {string} _distributionId The 'ID' of the distribution.
 * @param {string} _prospectId The ID of the customer / lead / prospect.
 * @param {object} payload The payload which will be patched.
 */
export async function updateProspectCompanyById(
  _distributionId,
  _prospectId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/companies/${_prospectId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

export async function getLeadsByProspectId(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads?pagelen=100&prospect_id=${_prospectId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getOffersByProspectId(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers?pagelen=100&prospect_id=${_prospectId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getContractsByProspectId(_distributionId, _prospectId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts?pagelen=100&prospect_id=${_prospectId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getBrokersByDistributionId(_distributionId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/brokers?pagelen=1000`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}
// Notes
export async function getNotesByLeadId(_distributionId, _leadId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/notes`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function appendNoteToLead(_distributionId, _leadId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/notes`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function deleteLeadNoteById(_distributionId, _leadId, _noteId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/notes/${_noteId}`;
  const [resp, status] = await request(path, 'DELETE');
  return [resp, status];
}

export async function getNotesByContractsId(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/notes`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function appendNoteToContracts(
  _distributionId,
  _contractId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/notes`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function deleteContractsNoteById(
  _distributionId,
  _contractId,
  _noteId,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/notes/${_noteId}`;
  const [resp, status] = await request(path, 'DELETE');
  return [resp, status];
}

export async function getNotesByOfferId(_distributionId, _offerId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/notes`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function appendNoteToOffer(_distributionId, _offerId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/notes`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function deleteOfferNoteById(_distributionId, _offerId, _noteId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/notes/${_noteId}`;
  const [resp, status] = await request(path, 'DELETE');
  return [resp, status];
}

// RiskObjects

/**
 * Get Risk Object by call type and id.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _callTypeId The id of the call type type.
 * @param {('teachers' | 'cars' | 'residences' | 'two_wheelers' | 'families')} _riskType The type of the risk object.
 * @param {('prospects' | 'offers' | 'leads')} _tallType The type the page.
 */
export async function getRiskObjectsByCallType(
  _distributionId,
  _callTypeId,
  _riskType,
  _callType,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/${_callType}/${_callTypeId}/risk_objects/${_riskType}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Get Risk Object by risk type and id.
 * @param {string} _distributionId The ID of the distribution.
 * @param {('teachers' | 'cars' | 'residences' | 'two_wheelers' | 'families')} _riskType The type of the risk object.
 * @param {string} _riskObjectId The id of the risk object.
 * @param {string} _revision The rivision id of the risk object.
 */
export async function getRiskObjectByTypeAndId(
  _distributionId,
  _riskType,
  _riskObjectId,
  _revision,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/risk_objects/${_riskType}/${_riskObjectId}/${_revision}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function createRiskObjectRevision(
  _distributionId,
  _prospectId,
  _riskType,
  _riskObjectId,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/risk_objects/${_riskType}/${_riskObjectId}`;
  const [resp, status] = await request(path, 'PUT', payload);
  return [resp, status];
}

export async function createRiskObjectForProspect(
  _distributionId,
  _prospectId,
  _riskType,
  payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/risk_objects/${_riskType}`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function getAllAffinities() {
  const path = `v1/api/broker/v1/api/affinities`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

// Insurances and guarantees
export async function getAllInsuranceCompanies() {
  const path = `v1/api/broker/v1/api/insurance_companies?pagelen=100`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getInsuranceCompanyByKey(insuranceCompanyKey) {
  const path = `v1/api/broker/v1/api/insurance_companies/${insuranceCompanyKey}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getInsurances(insuranceCompanyKey) {
  const path = `v1/api/broker/v1/api/insurance_companies/${insuranceCompanyKey}/insurances`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function getGuarantees(insuranceCompanyKey, insuranceId) {
  const path = `v1/api/broker/v1/api/insurance_companies/${insuranceCompanyKey}/insurances/${insuranceId}/guarantees/info?pagelen=100`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

// CRMs
export async function retrieveAllCRMs() {
  const path = `v1/api/broker/v1/api/broker_crms?pagelen=100`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function exportLeadToCRM(_distributionId, _leadId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/leads/${_leadId}/export/crm`;
  const [resp, status] = await request(path, 'POST');
  return [resp, status];
}

export async function exportOfferToCRM(_distributionId, _offerId, _revision) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/export/crm`;
  const [resp, status] = await request(path, 'POST');
  return [resp, status];
}

export async function mailOfferToProspect(
  _distributionId,
  _offerId,
  _revision,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/mails`;
  const [resp, status] = await request(path, 'POST');
  return [resp, status];
}

export async function exportContractToCRM(_distributionId, _contractId) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/export/crm`;
  const [resp, status] = await request(path, 'POST');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * DOCGEN
 * * * * * * * * * * * * * * */

export async function generatePolicyProposition(payload) {
  const path = `v1/api/docgen/v1/api/proposition`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function generateTerminationLetter(payload) {
  const path = `v1/api/docgen/v1/api/notice`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function generateInformationRequirementSheet(payload) {
  const path = `v1/api/docgen/v1/api/information_requirement_sheet`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function generateCustomerSheet(payload) {
  const path = `v1/api/docgen/v1/api/customer_sheet`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function generateCallantProposition(affinity, payload) {
  const path = `v1/api/docgen/v1/api/callant/${affinity}/proposition`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function generateTerminationLetterV2(payload) {
  const path = `v1/api/docgen/v2/api/notice`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function generateInformationRequirementSheetV2(payload) {
  const path = `v1/api/docgen/v2/api/information_requirement_sheet`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

export async function getDocumentById(id) {
  const path = `v1/api/docgen/v1/api/documents/${id}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * ENUMS
 * * * * * * * * * * * * * * */

/**
 * Get ENUMS by name
 * @param {string} _enumName Name of the enum you want to fetch.
 */
export async function getEnumByName(_enumName) {
  const path = `v1/api/broker/enums/${_enumName}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * QUOTES AND OFFERS
 * * * * * * * * * * * * * * */

/**
 * Calculate a new offer.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer you wish to recalculate.
 * @param {object} _payload The payload to be put.
 */

export async function calculateQuote(
  _distributionId,
  _offerId,
  _payload,
  _revisionId,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revisionId}/calculate/quote`;
  const [resp, status] = await request(path, 'POST', _payload);
  return [resp, status];
}

export async function calculateQuoteByContractId(
  _distributionId,
  _contractId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/calculate/quote`;
  const [resp, status] = await request(path, 'POST', _payload);
  return [resp, status];
}

/**
 * Calculate a new contract.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _contractId The ID of the contract you wish to recalculate.
 * @param {object} _payload The payload to be put.
 */

export async function calculateContractQuote(
  _distributionId,
  _contractId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/contracts/${_contractId}/calculate/quote`;
  const [resp, status] = await request(path, 'POST', _payload);
  return [resp, status];
}

/**
 * Create an offer revision.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer you wish to make a revision of.
 * @param {object} _payload The payload to be put.
 */
export async function createOfferRevision(_distributionId, _offerId, _payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}`;
  const [resp, status] = await request(path, 'PUT', _payload);
  return [resp, status];
}

export async function createContractRevision(
  _distributionId,
  _contractCaseId,
  _contractsId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts/${_contractsId}`;
  const [resp, status] = await request(path, 'PUT', _payload);
  return [resp, status];
}

export async function createContractRevisionManual(
  _distributionId,
  _contractCaseId,
  _contractsId,
  _payload,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/cases/${_contractCaseId}/contracts/${_contractsId}/manual`;
  const [resp, status] = await request(path, 'PUT', _payload);
  return [resp, status];
}

export async function getAffinityByCompanyKey(_companyKey) {
  const path = `v1/api/broker/v1/api/affinities/${_companyKey}`;
  const [resp, status] = await request(path, 'GET');

  return [resp, status];
}

export async function getAffinityInsurancesByCompanyKey(
  companyKey,
  affinityKey,
) {
  const path = `v1/api/broker/v1/api/affinities/${companyKey}/${affinityKey}/insurances`;
  const [resp, status] = await request(path, 'GET');

  return [resp, status];
}

export async function getAffinityInsurancesByCompanyKeyAndId(
  companyKey,
  affinityKey,
  id,
) {
  const path = `v1/api/broker/v1/api/affinities/${companyKey}/${affinityKey}/insurances/${id}/guarantees/info`;
  const [resp, status] = await request(path, 'GET');

  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CUSTOMER PAGE
 * * * * * * * * * * * * * * */

/**
 * Fetch an offer from a customer page.
 * @param {string} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer.
 * @param {number} _revision The revision number.
 */
export async function getOfferAsCustomerById(_offerId, _revision, _token) {
  const path = `v1/api/broker/v1/api/customer/offers/${_offerId}/revisions/${_revision}`;
  const header = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request(path, 'GET', null, header);
  return [resp, status];
}

/**
 * Calculate a new quote from a customer page.
 * @param {string} _offerId The ID of the offer.
 * @param {object} _payload The payload to be sent
 * @param {string} _token Validation token the customer receives in the URL.
 */
export async function calculateQuoteAsCustomer(
  _offerId,
  _revisionId,
  _payload,
  _token,
) {
  const path = `v1/api/broker/v1/api/customer/offers/${_offerId}/calculate/quote?revision=${_revisionId}`;
  const header = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request(path, 'POST', _payload, header);
  return [resp, status];
}

/**
 * Create a new offer revision from a customer page.
 * @param {string} _offerId The ID of the offer.
 * @param {object} _payload The payload to be sent
 * @param {string} _token Validation token the customer receives through the URL.
 */
export async function createOfferRevisionAsCustomer(
  _offerId,
  _payload,
  _token,
) {
  const path = `v1/api/broker/v1/api/customer/offers/${_offerId}`;
  const header = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request(path, 'PUT', _payload, header);
  return [resp, status];
}

export async function createCarRevisionAsCustomer(
  _riskObjectId,
  _payload,
  _token,
) {
  const path = `v1/api/broker/v1/api/customer/risk_objects/cars/${_riskObjectId}`;
  const header = { 'x-wg-customer-email-token': _token };

  const [resp, status] = await request(path, 'PUT', _payload, header);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * AUTOMOBILE
 * * * * * * * * * * * * * * */

export async function retrieveAutomobileBrands(query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicle/v1/api/automobile/brands${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAutomobileModelsByBrand(brand, query) {
  const queryPath = query ? `?pagelen=500&${query}` : '?pagelen=500';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/models${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAutomobileVersionsBySeries(
  brand,
  seriesId,
  query,
) {
  const queryPath = query ? `?${query}` : '';
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/models/${seriesId}/versions${queryPath}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveAutomobileByCar(brand, seriesId, carId) {
  const path = `v1/api/vehicle/v1/api/automobile/brands/${brand}/models/${seriesId}/versions/${carId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

export async function retrieveLicensePlateV2(licensePlate) {
  const path = `v1/api/vehicle/v1/api/license_plate/${licensePlate}?country_code=BE&vehicle_type=CAR`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * ADDRESS
 * * * * * * * * * * * * * * */

export async function getInfoAboutAddress(_payload) {
  const path = `v1/api/address/v2/api/info`;
  const [resp, status] = await request(path, 'POST', _payload);
  return [resp, status];
}

export async function getRoomsEstimationFromAddress(_id) {
  const path = `v1/api/address/v2/api/room_estimation/${_id}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CREATE OFFER
 * * * * * * * * * * * * * * */

export async function createOffer(_distributionId, _prospectId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/prospects/${_prospectId}/offers`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * SIGNATURES
 * * * * * * * * * * * * * * */

/**
 * Refresh Offer Signature.
 * @param {object} _distributionId The ID of the distribution.
 * @param {string} _offerId The ID of the offer.
 * @param {string} _revision The revision of the offer.
 */

export async function refreshSignaturesFromOffer(
  _distributionId,
  _offerId,
  _revision,
) {
  const path = `v1/api/broker/v1/api/distributions/${_distributionId}/offers/${_offerId}/revisions/${_revision}/signatures/refresh`;
  const [resp, status] = await request(path, 'POST');
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * CAMPAIGNS
 * * * * * * * * * * * * * * */

/**
 * Retrieve campaign data by campaing id.
 * @param {string} campaingId The ID of the campaign.
 */
export async function retrieveCampaignById(campaignId) {
  const path = `v1/api/broker/v1/api/campaigns/${campaignId}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 * @param {string} type campaign type "EMAIL", "WEBSITE".
 */
export async function retrieveCampaignsByDistribution(distributionId, type) {
  const params = type ? `?type=${type}&pagelen=100` : '';
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/campaigns${params}`;
  const [resp, status] = await request(path, 'GET');
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 */
export async function createCampaign(distributionId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/campaigns`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 * @param {string} campaingId The ID of the campaign.
 * @param {string} type campaign type "EMAIL", "WEBSITE".
 */
export async function patchCampaign(distributionId, campaignId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/campaigns/${campaignId}`;
  const [resp, status] = await request(path, 'PATCH', payload);
  return [resp, status];
}

/**
 * Retrieve campaign data by campaing id.
 * @param {string} distributionId The ID of the distribution.
 * @param {string} campaingId The ID of the campaign.
 * @param {string} payload a photo in formdata type
 */
export async function postCampaignLogo(distributionId, campaignId, payload) {
  const path = `v1/api/broker/v1/api/distributions/${distributionId}/campaigns/${campaignId}/upload/logo`;
  const [resp, status] = await request(path, 'POST', payload);
  return [resp, status];
}

/* * * * * * * * * * * * * * *
 * MARKETPLACE
 * * * * * * * * * * * * * * */

/**
 * Save all the data of the user for an lead campaign flow
 * @param {string} lang the current language
 * @param {string} id the louise flow id
 */

export async function postLeadFlow(lang, id) {
  const path = `v1/api/marketplace/v1/api/lead/${lang}/${id}`;
  const [resp, status] = await request(path, 'POST', {});
  return [resp, status];
}

export async function validateInputCall(method, url, body) {
  const path = url.replace(/^\//, '');
  const [resp, status] = await request(path, method, body);
  return [resp, status];
}
