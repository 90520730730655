import React from 'react';

const LegalTotal = () => (
  <svg viewBox="0 0 197.52 228.95">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'legal_total'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M197.52 124.52a3.65 3.65 0 00-.57-2L162.43 68.2a3.66 3.66 0 00-3.09-1.7 4.17 4.17 0 00-.71.08h-56.75V54.27a4.39 4.39 0 00-4.38-4.39 4.39 4.39 0 00-4.39 4.39v12.27H38.9a4.41 4.41 0 00-.71-.08 3.66 3.66 0 00-3.1 1.7L.57 122.55a3.64 3.64 0 00-.56 2H0v.12a38.19 38.19 0 0076.38 0v-.12a3.73 3.73 0 00-.56-2L45.87 75.43v-.07H93v145.5a4.54 4.54 0 004 4.6 4.39 4.39 0 004.78-4.37V75.4h49.74v.07l-29.94 47.12a3.64 3.64 0 00-.56 2v.12a38.19 38.19 0 0076.37 0v-.12zm-129.25 0H8.1v-.07l30.05-47.36h.07l30.09 47.36-.04.07zm60.94-.07l30.09-47.36h.07l30.09 47.36v.07h-60.21s-.05-.04-.04-.07z"
        />
        <path
          className="prefix__cls-1"
          d="M48.72 211.4H148.8v17.56H48.72zM47.62 0l5.55 11.25 12.42 1.8-8.99 8.76 2.13 12.37-11.11-5.84-11.1 5.84 2.12-12.37-8.99-8.76 12.42-1.8L47.62 0zM98.76 0l5.55 11.25 12.42 1.8-8.98 8.76 2.12 12.37-11.11-5.84-11.1 5.84 2.12-12.37-8.98-8.76 12.41-1.8L98.76 0zM149.9 0l5.56 11.25 12.41 1.8-8.98 8.76 2.12 12.37-11.11-5.84-11.1 5.84 2.12-12.37-8.98-8.76 12.41-1.8L149.9 0z"
        />
      </g>
    </g>
  </svg>
);

export default LegalTotal;
