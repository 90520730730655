import React from 'react';
import { string } from 'prop-types';

const Content = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 322.88 280.31">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M309.83,6.8a8.34,8.34,0,0,0-8.2-6.8H258.76a8.35,8.35,0,0,0-8.21,6.8L237.64,75.49a8.34,8.34,0,0,0,8.21,9.88h26V263.63h-15.6a8.34,8.34,0,0,0,0,16.68h47.89a8.34,8.34,0,0,0,0-16.68H288.53V85.37h26a8.34,8.34,0,0,0,8.21-9.88Z"
        />
        <rect fill={color} x="55.59" y="210.05" width="111.53" height="24.01" />
        <rect fill={color} y="159.15" width="39.93" height="119.62" rx="6.72" />
        <rect
          fill={color}
          x="182.79"
          y="159.15"
          width="39.9"
          height="119.62"
          rx="6.72"
        />
        <path
          fill={color}
          d="M168,193.76v-27.9a21.52,21.52,0,0,1,21.52-21.53h22.35V108.76a9.12,9.12,0,0,0-9.11-9.12H20a9.13,9.13,0,0,0-9.12,9.12v35.57H33.2a21.53,21.53,0,0,1,21.53,21.53v27.9Z"
        />
      </g>
    </g>
  </svg>
);

Content.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Content.propTypes = {
  title: string,
  color: string,
};

export default Content;
