import React from 'react';
import { string } from 'prop-types';

const ElectricStep = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="26"
    fill="none"
    viewBox="0 0 33 26"
  >
    <path
      fill={color}
      d="M4.747 16.206a4.746 4.746 0 104.747 4.747 4.746 4.746 0 00-4.747-4.747zm0 6.56a1.81 1.81 0 01-1.813-1.813 1.81 1.81 0 011.813-1.813c1.01 0 1.813.803 1.813 1.813 0 1.01-.803 1.813-1.813 1.813zM27.97 16.206a4.746 4.746 0 104.747 4.747 4.746 4.746 0 00-4.747-4.747zm0 6.56a1.81 1.81 0 01-1.813-1.813 1.81 1.81 0 011.813-1.813 1.81 1.81 0 011.813 1.813 1.81 1.81 0 01-1.813 1.813z"
    />
    <path
      fill={color}
      d="M12.553 19.984l-3.959-8.968 3.017-9.95A.825.825 0 0010.034.58L4.512 18.74h1.73l1.619-5.315 3.404 7.709a.832.832 0 00.762.498h13.41v-1.647H12.552z"
    />
    <path
      fill={color}
      d="M24.884 22.6h-8.761a1.583 1.583 0 01-1.592-1.592v-.429c0-.885.706-1.591 1.592-1.591h8.76V22.6zM10.767 1.882H6.103a.833.833 0 01-.83-.83V.83c0-.456.374-.83.83-.83h4.664v1.882z"
    />
  </svg>
);

ElectricStep.propTypes = {
  color: string.isRequired,
};

export default ElectricStep;
