import React from 'react';

const Plantation = () => (
  <svg viewBox="0 0 181.77 184.08">
    <title>{'plantationmatrix_family_'}</title>
    <path
      d="M139.41 162.49a34.88 34.88 0 00-24.81 10.72 45.38 45.38 0 00-21.12-28.16v-21.12l38.6-38.6h33.4a29.36 29.36 0 00-8-20h24.25A29.54 29.54 0 00153 35.78a29.46 29.46 0 00-25.52-14.87 29.82 29.82 0 00-3.67.37 41.48 41.48 0 00-72.25-.37 29.47 29.47 0 00-26.28 16.28A29.48 29.48 0 004.53 65.28H50L81.72 97v43.35a44.06 44.06 0 00-50.4 22.21A35.54 35.54 0 000 184.07h171.45a34.59 34.59 0 00-32.04-21.58zM93.48 65.28h20.81a29.36 29.36 0 00-8 20h8.56l-21.37 21.46zm-26.29 0h14.53V79.8z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default Plantation;
