import React from 'react';
import { string } from 'prop-types';

const Bicycle = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="17"
    fill="none"
    viewBox="0 0 33 17"
  >
    <path
      fill={color}
      d="M26.425 4.235c-.943 0-1.873.207-2.778.622l-2.15-3.344h1.144c.428 0 .78-.341.78-.756A.772.772 0 0022.64 0h-2.54a.781.781 0 00-.678.39.713.713 0 00.025.757l.641.989h-8.146l-.428-.672h.755c.415 0 .741-.329.741-.72 0-.402-.339-.72-.741-.72H8.61a.732.732 0 00-.741.72c0 .403.34.72.741.72h1.157l.893 1.392-1.283 2.013a6.518 6.518 0 00-2.803-.634C2.955 4.235 0 7.09 0 10.605s2.954 6.383 6.575 6.383c3.306 0 6.122-2.465 6.5-5.663h3.419a.776.776 0 00.364-.097c.05-.025.076-.062.1-.086l.026-.012c.038-.037.1-.073.138-.147l4.312-6.724.893 1.391c-1.572 1.22-2.477 3.027-2.477 4.967 0 3.515 2.955 6.383 6.575 6.383S33 14.132 33 10.617c0-3.514-2.954-6.382-6.575-6.382zm-15.11 5.65H7.906l1.798-2.807a4.517 4.517 0 011.61 2.807zm3.846 0h-2.087a6.32 6.32 0 00-2.426-4.259l.893-1.391 3.62 5.65zm-8.586 5.419c-2.665 0-4.828-2.1-4.828-4.687S3.91 5.931 6.575 5.931c.628 0 1.27.134 1.873.39L5.934 10.24c-.139.22-.151.5-.013.732a.744.744 0 00.654.366h4.74c-.365 2.27-2.352 3.967-4.74 3.967zm13.54-11.716l-3.621 5.65-3.62-5.65h7.24zm6.712 7.664a.773.773 0 00.352-.464.715.715 0 00-.1-.573l-2.514-3.918a4.723 4.723 0 011.848-.378c2.665 0 4.827 2.099 4.827 4.686 0 2.587-2.162 4.686-4.827 4.686-2.666 0-4.828-2.099-4.828-4.686 0-1.342.604-2.611 1.66-3.515l2.501 3.906c.139.22.39.354.654.354a.694.694 0 00.427-.098z"
    />
  </svg>
);

Bicycle.propTypes = {
  color: string.isRequired,
};

export default Bicycle;
