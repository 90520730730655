import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SearchSelectInput } from 'wg-fe-ui';
import { func, string } from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';

import { searchAddress } from '../services/apiRouterService';

const ProFlowAddressSearchInput = ({ onSelected, error, ...otherProps }) => {
  const { t } = useTranslation();

  const fetchResults = async (value, callback) => {
    if (hasNumber(value)) {
      const [data] = await searchAddress(value);
      return callback(cleanAddressData(data));
    }
  };

  const hasNumber = string => {
    return /\d/.test(string);
  };

  const cleanAddressData = payload => {
    const arr = [];
    arr.push(
      payload.map(r => {
        if (!('housenr' in r)) {
          return undefined;
        }

        const { housenr, city, street, boxnr, zipcode, lat, lng } = r;

        const cleanedStreetName = street
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        const cleanedHouseNr = housenr
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        const cleanedMunicipalityName = city
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
        const obj = {
          value: {
            streetName: cleanedStreetName,
            boxNumber: boxnr,
            streetNumber: cleanedHouseNr,
            postalCode: zipcode,
            municipalityName: cleanedMunicipalityName,
            latitude: lat,
            longitude: lng,
          },
          label: `${cleanedStreetName} ${cleanedHouseNr}${
            boxnr ? `/${boxnr}` : ``
          }, ${zipcode} ${cleanedMunicipalityName} `,
        };
        return housenr !== undefined ? obj : undefined;
      }),
    );
    return arr[0].filter(e => e !== undefined);
  };

  const [debounceFetchResults] = useDebouncedCallback(fetchResults, 500);

  return (
    <StyledSearchSelectInput
      async
      onChange={onSelected}
      loadOptions={debounceFetchResults}
      error={error}
      onBlurResetsInput={false}
      isClearable
      noOptionsMessage={() => t(`AdresInput.input.placeholder`)}
      classNamePrefix="Select"
      placeholder={t(`AdresInput.input.placeholder`)}
      {...otherProps}
    />
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  .Select__control {
    height: 6rem;
    padding-left: 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  .Select {
    &__menu-notice--no-options {
      font-size: 1.6rem;
    }

    &__option {
      font-size: 1.6rem;
      padding: 1.6rem;

      &:hover {
        font-weight: bold;
      }
    }

    &__menu {
      max-height: 20rem;
      z-index: 999;
    }

    &__menu-list {
      max-height: 20rem;
      padding: 1rem;
    }
  }

  .Select__dropdown-indicator,
  .Select__indicator-separator {
    display: none;
  }
`;

ProFlowAddressSearchInput.propTypes = {
  onSelected: func.isRequired,
  error: string,
};

export default ProFlowAddressSearchInput;
