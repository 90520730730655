import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  IconPropertyFilled,
  IconGardenFilled,
  IconPorch,
  IconSolarPanelsFilled,
  IconPoolFilled,
  IconBasementFilled,
  IconAtticFilled,
  IconActionClose,
  Section,
  Label,
  Title,
  Text,
  EditActionButton,
  TextInput,
  FeatureInput,
  ActionButton,
} from 'wg-fe-ui';
import { object, func, bool } from 'prop-types';

import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import cogoToast from 'cogo-toast';

import { isObjectEmpty, sumObjectValues } from '../services/objectService';

const CRMRiskObjectDetailResidenceRooms = ({
  homeData,
  onSubmitFunction,
  customerPageFunction,
  disableEditing,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState(homeData);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setData(homeData);
  }, [homeData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  /*
   *  Form handling
   */

  const ToggleEditMode = e => {
    setEditMode(!editMode);

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const ValidationSchema = Yup.object().shape({
    bathrooms: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
    bedrooms: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
    garages: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
    kitchens: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
    living_rooms: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
    other_rooms: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
    storage_rooms: Yup.number()
      .min(0)
      .max(15)
      .required(t('Required')),
  });

  const setInitialFormValues = () => {
    if (data) {
      Object.keys(data).map(name => {
        handleChange({ name, value: data[name] });
      });
      Object.keys(data?.main_building).map(name => {
        handleChange({ name, value: data?.main_building?.[name] });
      });

      Object.keys(data?.main_building?.rooms).map(name => {
        handleChange({ name, value: data?.main_building?.rooms[name] });
      });
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: ValidationSchema,
    mode: 'onChange',
  });

  const handleFeatureChange = ({ name, value }) => {
    handleChange({ name: name, value: value });
  };

  const handleFormValues = async () => {
    cogoToast.error(
      t('The editing and saving of a risk object is not supported yet'),
    );
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
    } else {
      const newValues = {
        ...homeData,
        has_garden: !!values?.has_garden,
        has_solar_panels: !!values?.has_solar_panels,
        has_outside_swimming_pool: !!values?.has_outside_swimming_pool,
        main_building: {
          ...homeData.main_building,
          has_attic: !!values?.has_attic,
          has_cellar: !!values?.has_cellar,
          has_porch: !!values?.has_porch,
          has_elevator: !!values?.has_elevator,
          rooms: {
            ...homeData.main_building.rooms,
            living_rooms: parseInt(values?.living_rooms),
            bathrooms: parseInt(values?.bathrooms),
            bedrooms: parseInt(values?.bedrooms),
            garages: parseInt(values?.garages),
            kitchens: parseInt(values?.kitchens),
            other_rooms: parseInt(values?.other_rooms),
            storage_rooms: parseInt(values?.storage_rooms),
          },
        },
      };

      delete newValues?.created_at;
      delete newValues?.previous_insurances;
      delete newValues?.risk_object_id;
      delete newValues?.risk_object_revision_id;

      onSubmitFunction(newValues);
      setData(newValues);
      setEditMode(false);
      if (customerPageFunction) {
        customerPageFunction(false);
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Section>
        <Section.Title>{t('Room layout')}</Section.Title>
        <Section.Content background>
          {editMode || disableEditing ? null : (
            <StyledEditButton onClick={e => ToggleEditMode(e)}>
              {t('Edit')}
            </StyledEditButton>
          )}
          <SectionContentHeader>
            <TextWrapper>
              <Label>{t('Room layout')}</Label>
              <Title>
                {!isObjectEmpty(data?.main_building?.rooms)
                  ? `${sumObjectValues(data?.main_building?.rooms)} ${t(
                      ' rooms',
                    )}`
                  : `-`}
              </Title>
            </TextWrapper>
          </SectionContentHeader>
          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconPropertyFilled color={'#CCCCCC'} />
              </IconContainer>
              <TextWrapper>
                {editMode ? (
                  <StyledTextInput
                    error={errors.living_rooms}
                    name="living_rooms"
                    placeholder={t('Living rooms')}
                    type="number"
                    value={values.living_rooms}
                    onChange={handleChange}
                  >
                    {t('Living rooms')}
                  </StyledTextInput>
                ) : (
                  <>
                    <Label>{t('Living rooms')}</Label>
                    <Text>{data?.main_building?.rooms?.living_rooms}</Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.kitchens}
                  name="kitchens"
                  placeholder={t('Kitchens')}
                  type="number"
                  value={values.kitchens}
                  onChange={handleChange}
                >
                  {t('Kitchens')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Kitchens')}</Label>
                  <Text>{t(`${[data?.main_building?.rooms?.kitchens]}`)}</Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.bedrooms}
                  name="bedrooms"
                  placeholder={t('Bedrooms')}
                  type="number"
                  value={values.bedrooms}
                  onChange={handleChange}
                >
                  {t('Bedrooms')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Bedrooms')}</Label>
                  <Text>{t(`${[data?.main_building?.rooms?.bedrooms]}`)}</Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.bathrooms}
                  name="bathrooms"
                  placeholder={t('Bathrooms')}
                  type="number"
                  value={values.bathrooms}
                  onChange={handleChange}
                >
                  {t('Bathrooms')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Bathrooms')}</Label>
                  <Text>{t(`${[data?.main_building?.rooms?.bathrooms]}`)}</Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.storage_rooms}
                  name="storage_rooms"
                  placeholder={t('Storage rooms')}
                  type="number"
                  value={values.storage_rooms}
                  onChange={handleChange}
                >
                  {t('Storage rooms')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Storage rooms')}</Label>
                  <Text>
                    {t(`${[data?.main_building?.rooms?.storage_rooms]}`)}
                  </Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.garages}
                  name="garages"
                  placeholder={t('Garages')}
                  type="number"
                  value={values.garages}
                  onChange={handleChange}
                >
                  {t('Garages')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Garages')}</Label>
                  <Text>{t(`${[data?.main_building?.rooms?.garages]}`)}</Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.other_rooms}
                  name="other_rooms"
                  placeholder={t('Other rooms')}
                  type="number"
                  value={values.other_rooms}
                  onChange={handleChange}
                >
                  {t('Other rooms')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Other rooms')}</Label>
                  <Text>
                    {t(`${[data?.main_building?.rooms?.other_rooms]}`)}
                  </Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>
          <SectionBorder />
          <SectionContentInner>
            <Features>
              <FeatureInput
                checked={data?.has_garden}
                icon={<IconGardenFilled />}
                name="has_garden"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Garden')}
              </FeatureInput>
              <FeatureInput
                checked={data?.main_building?.has_porch}
                icon={<IconPorch />}
                name="has_porch"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Porch')}
              </FeatureInput>
              <FeatureInput
                checked={data?.has_solar_panels}
                icon={<IconSolarPanelsFilled />}
                name="has_solar_panels"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Solar panels')}
              </FeatureInput>
              <FeatureInput
                checked={
                  data?.has_outside_swimming_pool ||
                  data?.main_building?.has_inside_swimming_pool
                    ? true
                    : false
                }
                icon={<IconPoolFilled />}
                name="has_outside_swimming_pool"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Swimming pool')}
              </FeatureInput>
              <FeatureInput
                checked={data?.main_building?.has_cellar}
                icon={<IconBasementFilled />}
                name="has_cellar"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Cellar')}
              </FeatureInput>

              <FeatureInput
                checked={data?.main_building?.has_attic}
                icon={<IconAtticFilled />}
                name="has_attic"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Attic')}
              </FeatureInput>

              <FeatureInput
                checked={data?.main_building?.has_elevator}
                icon={<IconActionClose />}
                name="has_elevator"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Elevator')}
              </FeatureInput>
            </Features>
          </SectionContentInner>
          {editMode ? (
            <SectionContentFooter>
              <ActionButton level="default" onClick={ToggleEditMode}>
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton>{t('Save')}</ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const Spacer = styled.div`
  width: 15px;
`;

const Features = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.5rem;
  flex-flow: row wrap;

  > * {
    flex: 0 0 49%;
    margin-top: 1rem;
    margin-right: 1%;
  }

  :nth-of-type(-n + 2) {
    margin-top: 0;
  }

  @media only screen and (max-width: 940px) {
    flex: 0 0 100%;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
`;

const SectionContentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;

  /* two items */
  :first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ div {
    width: 49%;
  }

  /* three items */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 49%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const SectionContentInner = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    :nth-of-type(2n + 3) {
      flex: 0 0 calc(50% - 6rem);
      margin-left: 6rem;
    }
  }

  > ${IconWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    > ${TextWrapper} {
      flex: 1 1 100%;
    }
  }
`;

const SectionBorder = styled.div`
  margin-top: 3rem;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.ui.outline};
`;

const StyledEditButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;
`;

CRMRiskObjectDetailResidenceRooms.propTypes = {
  homeData: object,
  onSubmitFunction: func,
  customerPageFunction: func,
  disableEditing: bool,
};

export default CRMRiskObjectDetailResidenceRooms;
