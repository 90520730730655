import React, { useEffect } from 'react';
import styled from 'styled-components';
import LeftSide from '../components/LeftSide';
import LouiseLeftSide from '../models/Flow/User/views/LouiseLeftSide';
import Layout from '../models/Flow/LayoutFlow/views/App';
import RightSide from '../components/RightSide';
import { useTranslation } from 'react-i18next';
import { getBrokerDistribution } from '../services/brokerDataService';
import { useState } from 'react';
import { Label } from 'wg-fe-ui';

const CampaignExpired = () => {
  const { t } = useTranslation();
  const [distribution, setDistribution] = useState({});

  useEffect(() => {
    (async () => {
      const distributionData = await getBrokerDistribution();
      setDistribution(distributionData);
    })();
  }, []);
  return (
    <StyledLayout>
      <Container>
        <StyledLeftSide>
          <LouiseLeftSide
            question={t(
              'Unfortunately, this campaign has expired However, you can still contact your broker in case of further interest',
            )}
          />
        </StyledLeftSide>
        {console.log(distribution)}
        <RightSide>
          <Ul>
            <li>
              <Label>{t('Company name')}</Label>
              <Value>{distribution?.name}</Value>
            </li>
            <li style={{ width: '50%' }}>
              <Label>{t('Website')}</Label>
              <Value>{distribution?.website}</Value>
            </li>
            <li style={{ width: '100%' }}>
              <Label>{t('Address')}</Label>
              <Value>
                {`${distribution?.address?.street}
                 ${distribution?.address?.housenr}
                - ${distribution?.address?.zipcode}
                ${distribution?.address?.city}
                `}
              </Value>
            </li>
            <li>
              <Label>{t('Email')}</Label>
              <Value>{distribution?.email}</Value>
            </li>
            <li>
              <Label>FSMA</Label>
              <Value>{distribution?.fsma?.key}</Value>
            </li>
            <li>
              <Label>{t('KBO')}</Label>
              <Value>{distribution?.cbe}</Value>
            </li>
          </Ul>
        </RightSide>
      </Container>
    </StyledLayout>
  );
};

const Value = styled.p``;
const Ul = styled.ul`
  display: flex;
  flex-wrap: wrap;
  > li {
    width: 45%;
    margin-bottom: 2rem;
  }
`;
const StyledLayout = styled(Layout)`
  > div:first-of-type {
    display: none;
  }
`;

const StyledLeftSide = styled(LeftSide)`
  justify-content: space-around;
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
    width: 50%;
  }
`;
// const Background = styled.main`
//   background-color: ${({ theme }) => theme.brand.primary};
//   background-repeat: no-repeat;
//   background-position: center center;
//   background-size: cover;
//   display: flex;
//   justify-content: center;
//   font-family: ${({ theme }) => theme.font};
//   font-size: 1.5rem;
//   padding: 4rem 0;
//   min-height: 100vh;
//   overflow: hidden;
// `;

export default CampaignExpired;
