import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const Ground = ({ colors }) => {
  const styles = {
    main: {
      height: `100%`,
    },

    cls1Class: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeMiterlimit: '10',
      strokeWidth: '4px',
    },

    cls2Class: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
  };

  return (
    <SvgContainer>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1890 78"
        style={styles.main}
      >
        <title>Ground</title>
        <line style={styles.cls1Class} y1="2" x2="1890" y2="2" />
        <line style={styles.cls2Class} x1="470" y1="59.5" x2="857" y2="59.5" />
        <line style={styles.cls2Class} x1="897" y1="59.5" x2="959" y2="59.5" />
        <line
          style={styles.cls2Class}
          x1="1158"
          y1="76.5"
          x2="1407"
          y2="76.5"
        />
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  height: 2.9rem;
  position: relative;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
`;

Ground.propTypes = {
  colors: shape().isRequired,
};

export default Ground;
