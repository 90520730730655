/* stylelint-disable no-descending-specificity, declaration-no-important, value-keyword-case, declaration-block-no-redundant-longhand-properties */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import Layout from '../../LayoutFlow/views/App';
import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';
import InteractiveMap from '../components/InteractiveMap';
import Button from '../../../../components/Button';
import InfoPopUp from '../../../../components/InfoPopUp';

import checkmark from '../../../../../assets/images/checkmark.svg';

// HouseStore import
import {
  getTraits,
  getAdress,
  getAddressInfo,
  setMoreHouseData,
} from '../store';

// Commonstore import
import {
  getNextPath,
  getComponentName,
  addBrowserHistory,
} from '../../../../store';

// UserStore import
import { useTranslation } from 'react-i18next';

const AdresCheck = ({ history }) => {
  const traits = getTraits();
  const building = getAddressInfo().building;
  let shouldUpdateFlooring = true;
  const { t } = useTranslation();

  const content = t(getComponentName(), { returnObjects: true });
  const SignupSchema = Yup.object().shape({
    age: Yup.string().required(t(`${getComponentName()}.errors.required`)),
  });

  const clickHandler = values => {
    setMoreHouseData(values);
    history.push(getNextPath());
  };

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);
  return (
    <Layout>
      <Container>
        <StyledLeftSide>
          {/* wordt data uit store */}
          <div>
            <InteractiveMap manual={getAdress().manual} building={building} />
            {/* <HomeTitle>
              <h3>Adres</h3>
              <p>
                {`${getAdress().street} ${getAdress().housenr}${
                  getAdress().boxnr ? `/${getAdress().boxnr}` : ``
                }, ${getAdress().zipcode} ${getAdress().city}`.toLowerCase()}
              </p>
            </HomeTitle> */}
          </div>
        </StyledLeftSide>
        <StyledRightSide>
          <Formik
            initialValues={{
              age: traits.age,
              cellar: traits.cellar,
              atticHabitable:
                traits.is_attic_unhabited === null
                  ? traits.is_attic_unhabited
                  : !traits.is_attic_unhabited,
              attic: traits.attic,
              cellarHabitable:
                traits.is_cellar_unhabited === null
                  ? traits.is_cellar_unhabited
                  : !traits.is_cellar_unhabited,
              under_construction: traits.under_construction,
              passive_house: traits.passive_house,
              special_flooring: traits.special_flooring,
              special_roofing: traits.special_roofing,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <FormWrapper>
                  <BuildingAge>
                    <Title>{content.buildingAge.question}</Title>
                    <RadioField component="div" name="age">
                      <Left>
                        {content.buildingAge.options.left.map(option => (
                          <RadioInputContainer key={option.value}>
                            <StyledLabel
                              htmlFor={option.value}
                              checked={values.age === option.value}
                              data-test-id={`underwriting_home_building_age_${option.value}`}
                            >
                              <input
                                type="radio"
                                id={option.value}
                                defaultChecked={values.age === option.value}
                                name={option.key}
                                value={option.value}
                              />
                              <RadioCheckMark
                                checked={values.age === option.value}
                              />
                              {option.name}
                            </StyledLabel>
                          </RadioInputContainer>
                        ))}
                      </Left>
                      <Right>
                        {content.buildingAge.options.right.map(option => (
                          <RadioInputContainer key={option.value}>
                            <StyledLabel
                              htmlFor={option.value}
                              checked={values.age === option.value}
                              data-test-id={`underwriting_home_building_age_${option.value}`}
                            >
                              <input
                                type="radio"
                                id={option.value}
                                defaultChecked={values.age === option.value}
                                name={option.key}
                                value={option.value}
                              />
                              <RadioCheckMark
                                checked={values.age === option.value}
                              />
                              {option.name}
                            </StyledLabel>
                          </RadioInputContainer>
                        ))}
                      </Right>
                    </RadioField>
                  </BuildingAge>
                  <Extras>
                    <Title>{content.extras.question}</Title>
                    <HabitableQuestions>
                      {content.extras.options.map(option => (
                        <StyledLabelCheckbox
                          htmlFor={option.key}
                          key={option.key}
                          checked={values[option.key]}
                          data-test-id={`underwriting_home_extras_${option.key}`}
                          disabled={
                            option.blocker ? !values[option.blocker] : false
                          }
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: option.name }}
                          />
                          <StandardInputCheckbox
                            id={option.key}
                            type="checkbox"
                            name={option.key}
                            checked={values[option.key]}
                            disabled={
                              option.blocker ? !values[option.blocker] : false
                            }
                            onChange={e => {
                              if (option.blocking) {
                                setFieldValue(option.blocking, false);
                              }
                              setFieldValue(option.key, e.target.checked);
                            }}
                          />
                          <CheckboxItem checked={values[option.key]}>
                            {values[option.key] ? (
                              <img src={checkmark} alt="checkmark" />
                            ) : (
                              ''
                            )}
                          </CheckboxItem>
                        </StyledLabelCheckbox>
                      ))}
                    </HabitableQuestions>
                    <Line />
                    <HabitableQuestions>
                      {content.moreExtras.map(option => (
                        <StyledLabelCheckbox
                          key={option.key}
                          htmlFor={option.key}
                          checked={values[option.key]}
                          data-test-id={`underwriting_home_extras_${option.key}`}
                        >
                          {option.popUp ? (
                            <span>
                              {option.name}
                              <InfoPopUp
                                title={`${option.name}${option.popUp.button}`}
                                buttonText={option.popUp.button}
                                info={option.popUp.text}
                                isLink={true}
                                onClick={closing => {
                                  shouldUpdateFlooring = closing;
                                }}
                              />
                            </span>
                          ) : (
                            <span
                              dangerouslySetInnerHTML={{ __html: option.name }}
                            />
                          )}
                          <StandardInputCheckbox
                            id={option.key}
                            type="checkbox"
                            name={option.key}
                            checked={values[option.key]}
                            onChange={e => {
                              if (option.popUp) {
                                if (shouldUpdateFlooring) {
                                  setFieldValue(option.key, e.target.checked);
                                } else {
                                  shouldUpdateFlooring = true;
                                }
                              } else {
                                setFieldValue(option.key, e.target.checked);
                              }
                            }}
                          />
                          <CheckboxItem checked={values[option.key]}>
                            {values[option.key] ? (
                              <img src={checkmark} alt="checkmark" />
                            ) : (
                              ''
                            )}
                          </CheckboxItem>
                        </StyledLabelCheckbox>
                      ))}
                    </HabitableQuestions>
                  </Extras>
                  <ButtonContainer>
                    <Button
                      disabled={false}
                      dataTestId="underwriting_home_submit"
                      type="submit"
                    >
                      {t('AdresInputHome.btnText.default')}
                    </Button>
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </StyledRightSide>
      </Container>
    </Layout>
  );
};

const Line = styled.div`
  height: 1px;
  width: calc(100% - 2rem);
  margin: auto;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  background-color: #a29c95;
`;

const StyledLabelCheckbox = styled.label`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  cursor: pointer;
  line-height: 1.5;
  max-width: calc(50% - 0.75rem);
  background-color: ${props => (!props.disabled ? 'white' : 'none')};
  flex: 0 0 50%;
  color: #aeaeae;
  padding: 0.65rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  align-items: center;
  margin-bottom: 0.75rem;
  & > span a {
    font-size: 1.4rem;
    transition: color 0.1s ease;
    color: ${({ theme }) => theme.brand.primary};
    font-weight: bold;
  }
  & > span {
    font-size: 1.4rem;
    width: 90%;
    transition: color 0.1s ease;
    color: #aeaeae;

    & > span {
      font-weight: bold;
      color: black;
    }
  }
  ${props =>
    props.disabled
      ? `
    color: #AEAEAE;
    & > span {
      color: #aeaeae;

      & > span {
        font-weight: bold;
        color: #aeaeae;
      }
    }
  `
      : null}
`;

const CheckboxItem = styled.div`
  width: 2rem;
  height: 2rem;
  margin-top: 0.3rem;
  background-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : 'none'};
  transition: all 0.1s ease;
  flex-grow: 0;
  flex-shrink: 0;
  border: 0.2rem solid #d3d4d8;
  border-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : '#D3D4D8'};
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const StandardInputCheckbox = styled.input`
  border-radius: 0.3rem;
  opacity: 0;
  margin-left: 1.5rem;
  margin-right: -1.6rem;
  cursor: pointer;
  &:focus + ${CheckboxItem} {
    outline: none;
    border-color: ${({ theme }) => theme.brand.lighter};
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 2rem;
  z-index: 1;

  & button {
    margin: 0;
    margin-bottom: 2rem;
  }
`;

const HabitableQuestions = styled.div`
  color: black;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex: 1 1 100%;
  margin-right: 2rem;
  flex-wrap: wrap;
`;

const Right = styled.div`
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
`;

const RadioInputContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.4rem;
  align-items: center;
  transition: color 0.2s ease;

  color: ${({ theme, checked }) => (checked ? theme.brand.primary : '#aeaeae')};
`;

const RadioCheckMark = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  border-radius: 50%;
  border: 2px solid #dedede;
  margin-right: 2.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  transition: all 0.2s ease;

  ${props =>
    props.checked
      ? `
    border: 5px solid white;
    background: white;
  `
      : null}
  border-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : '#dedede'};
`;

const FormWrapper = styled(Form)`
  /* display: flex; */
  width: 100%;
  flex-shrink: 0;
  position: relative;
  padding-bottom: 8rem;
`;

const Extras = styled.div`
  margin-top: 0;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

const StyledLeftSide = styled(LeftSide)`
  width: 45%;
  > div {
    /* overflow: auto; */
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledRightSide = styled(RightSide)`
  width: 55%;
  padding-bottom: 0;

  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`;

const BuildingAge = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;

  & input {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
  }
`;

const RadioField = styled(Field)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0;
  flex-wrap: nowrap;
`;

const Title = styled.h2`
  font-weight: 900;
  font-size: 1.7rem;
  margin-bottom: 1rem;
  color: black;
  flex: 0 0 100%;
  width: 100%;
  letter-spacing: 1.19px;
  flex-shrink: 0;
`;

// const HomeTitle = styled.div`
//   font-size: 1.5rem;
//   color: #505050;
//   text-transform: lowercase;
//   font-weight: 900;
//   width: 40%;
//   padding: 1.5rem 2rem;
//   background: white;
//   box-shadow: 0 4px 13px rgba(0, 0, 0, 0.1);
//   border-radius: 1rem;
//   position: absolute;
//   bottom: 4rem;
//   z-index: 4;

//   & > h3 {
//     text-transform: capitalize;
//     color: #a29c95;
//     font-weight: normal;
//   }

//   & > p {
//     text-transform: capitalize;
//     width: 100%;
//     max-width: auto;
//     text-align: left;
//   }
// `;

const Container = styled.section`
  display: flex;
  position: relative;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  width: 100%;
  margin: auto;
  overflow: hidden;
`;

AdresCheck.propTypes = {
  history: shape().isRequired,
};

export default withRouter(AdresCheck);
