import React from 'react';
import { RadioOption } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { string, shape, func } from 'prop-types';
import Checkmark from '../../assets/images/checkmark-orange.jsx';
const ProFlowInputTypeRadio = ({
  value,
  name,
  handleChange,
  enums,
  title,
  tag,
  field,
  ...otherProps
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <Label>{title}</Label>
      <RadioGrid>
        {enums.map(enumObj => {
          const [key] = Object.keys(enumObj);
          return (
            <StyledRadioOption
              key={key}
              name={key}
              handleChange={({ name: value }) => handleChange({ name, value })}
              checked={value === key}
              data-test-id={`proFlow_${tag.toLowerCase()}_${field}_${key.toLowerCase()}`}
            >
              {value === key ? <Checkmark /> : <p></p>}
              {enumObj[key]?.[i18n.language]}
            </StyledRadioOption>
          );
        })}
      </RadioGrid>
    </>
  );
};

const StyledRadioOption = styled(RadioOption)`
  width: 100%;
  height: 100%;
  padding: 2rem;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : '1px solid #E4E4E4'};
  min-height: 8rem;
  background-color: white;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  font-weight: ${({ checked }) => (checked ? '700' : '400')};
  position: relative;
  border-radius: 6px;

  box-shadow: ${({ checked }) =>
    checked
      ? '0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04)'
      : '0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06), 0 4px 8px rgba(0, 0, 0, 0.04)'};

  svg {
    fill: white;

    path {
      fill: white;
    }
  }

  span {
    width: 100%;
    text-align: center;
    position: relative;

    svg {
      left: 0;
      top: 0.25rem;
      position: absolute;
    }
  }

  > span:nth-of-type(1) {
    color: ${({ checked, theme }) =>
      checked ? theme.brand.primary : theme.typo.subTitle};
    font-size: 1.6rem;
  }

  > span:nth-of-type(2) {
    margin: 0;
    transition: all 0.3s;
    display: none;
  }
`;

const RadioGrid = styled.div`
  margin-top: 2.4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
`;

const Label = styled.h3`
  color: #8990a3;
  font-weight: 500;
  margin-bottom: 2rem;
`;

ProFlowInputTypeRadio.propTypes = {
  enums: shape().isRequired,
  error: string,
  name: string.isRequired,
  handleChange: func.isRequired,
  value: string,
  title: string.isRequired,
  tag: string.isRequired,
  field: string.isRequired,
};

export default ProFlowInputTypeRadio;
