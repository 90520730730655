import React from 'react';
import { shape, bool } from 'prop-types';
import styled from 'styled-components';

const BedroomSolar = ({ colors, mirror }) => {
  const styles = {
    main: {
      height: `100%`,
    },
    // .cls-1
    cls1: {
      fill: `${colors.brand.lightest}`,
    },
    // .cls-2
    cls2: {
      fill: `${colors.brand.lightest}`,
    },
    // .cls-3,.cls-5
    cls3: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3.36px',
    },
    cls5: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
    // .cls-4
    cls4: {
      fill: `${colors.brand.primary}`,
    },
    // .cls-6
    cls6: {
      fill: `${colors.brand.primary}`,
      opacity: '0.1',
    },
    // .cls-7
    cls7: {
      fill: '#fff',
    },
    // .cls-8
    cls8: {
      fill: `${colors.brand.secondary}`,
    },
    // .cls-9
    cls9: {
      fill: `${colors.brand.dark}`,
    },
    // .cls-10
    cls10: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
  };

  return (
    <SvgContainer mirror={mirror}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 231 322.25"
        style={styles.main}
      >
        <title>BedroomSolar</title>
        <rect
          style={styles.cls1}
          x="0.5"
          y="86.06"
          width="229"
          height="234.69"
        />
        <polygon
          style={styles.cls2}
          points="109.35 151.5 81.86 203.99 81.86 185.45 99.64 151.5 109.35 151.5"
        />
        <polygon
          style={styles.cls2}
          points="131.12 151.5 81.86 245.53 81.86 221.81 118.69 151.5 131.12 151.5"
        />
        <path style={styles.cls3} d="M115,251" />
        <path style={styles.cls3} d="M115,154" />
        <polygon
          style={styles.cls4}
          points="1.28 86.44 154.45 1.5 230 1.5 230 86.06 1.28 86.44"
        />
        <line style={styles.cls5} x1="2" y1="86" x2="115.64" y2="86" />
        <line
          style={styles.cls5}
          x1="135.46"
          y1="86.45"
          x2="148.91"
          y2="86.45"
        />
        <line
          style={styles.cls5}
          x1="194.02"
          y1="86.45"
          x2="229.5"
          y2="86.45"
        />
        <rect style={styles.cls6} x="210" y="1.5" width="19.5" height="319" />
        <polyline
          style={styles.cls5}
          points="229.5 1.5 155.5 1.5 1.5 86.5 1.5 320.75"
        />
        <rect
          style={styles.cls7}
          x="87.5"
          y="153.5"
          width="57.41"
          height="101.8"
        />
        <polygon
          style={styles.cls2}
          points="105.35 153.5 87.5 187.58 87.5 169.04 95.64 153.5 105.35 153.5"
        />
        <polygon
          style={styles.cls2}
          points="127.12 153.5 87.5 229.13 87.5 205.41 114.69 153.5 127.12 153.5"
        />
        <rect style={styles.cls8} x="87.5" y="154.5" width="56" height="6" />
        <rect style={styles.cls8} x="87.5" y="156.5" width="6" height="97" />
        <path
          style={styles.cls9}
          d="M141.91,156.5v95.8H88.5V156.5h53.41m3-3H85.5V255.3h59.41V153.5Z"
        />
        <rect style={styles.cls8} x="139" y="18" width="34" height="48" />
        <rect style={styles.cls5} x="139.63" y="18" width="34" height="48" />
        <polyline
          style={styles.cls10}
          points="150.88 18 150.88 33 150.88 50 150.88 66"
        />
        <polyline
          style={styles.cls10}
          points="162.38 18 162.38 33 162.38 50 162.38 66"
        />
        <line style={styles.cls10} x1="173.62" y1="33" x2="139.62" y2="33" />
        <line style={styles.cls10} x1="173.62" y1="50" x2="139.62" y2="50" />
        <rect style={styles.cls8} x="183" y="18" width="34" height="48" />
        <rect style={styles.cls5} x="183.63" y="18" width="34" height="48" />
        <polyline
          style={styles.cls10}
          points="194.88 18 194.88 33 194.88 50 194.88 66"
        />
        <polyline
          style={styles.cls10}
          points="206.38 18 206.38 33 206.38 50 206.38 66"
        />
        <line style={styles.cls10} x1="217.62" y1="33" x2="183.62" y2="33" />
        <line style={styles.cls10} x1="217.62" y1="50" x2="183.62" y2="50" />
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  z-index: 2;
  height: 10rem;
  position: relative;
  transform: ${props => (props.mirror ? 'scale(-1,1)' : '')};
  animation: ${props => (props.mirror ? 'fadeInLeft' : 'fadeInRight')} 0.3s;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 10%;
  }
`;

BedroomSolar.propTypes = {
  colors: shape().isRequired,
  mirror: bool.isRequired,
};

export default BedroomSolar;
