import React from 'react';
import { string } from 'prop-types';

const Children = ({ color }) => {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M5.16597 24.1883C6.31339 24.1883 7.24356 23.2228 7.24356 22.0318C7.24356 20.8408 6.31339 19.8752 5.16597 19.8752C4.01855 19.8752 3.08838 20.8408 3.08838 22.0318C3.08838 23.2228 4.01855 24.1883 5.16597 24.1883Z"
          fill={color}
        />
        <path
          d="M14.0493 24.1883C15.1967 24.1883 16.1269 23.2228 16.1269 22.0318C16.1269 20.8408 15.1967 19.8752 14.0493 19.8752C12.9018 19.8752 11.9717 20.8408 11.9717 22.0318C11.9717 23.2228 12.9018 24.1883 14.0493 24.1883Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7116 18.4764H4.49209C2.01021 18.4764 0 16.3898 0 13.8136V10.1416C0 9.81523 0.247065 9.55878 0.561511 9.55878H18.6422C18.9566 9.55878 19.2037 9.81523 19.2037 10.1416V13.8136C19.2037 16.3781 17.1935 18.4764 14.7116 18.4764Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.2777 8.18317H0.224605C0.101072 8.18317 0 8.07826 0 7.93837C0.0449209 6.27142 0.572741 4.72103 1.4487 3.45041C1.52731 3.33384 1.6733 3.32219 1.77438 3.41544L6.42369 7.77518C6.58091 7.91506 6.47984 8.18317 6.2777 8.18317Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.88379 0.233141V6.76108C7.88379 6.95925 7.65919 7.06416 7.5132 6.93593L2.66174 2.38969C2.56067 2.29643 2.56067 2.13324 2.66174 2.03998C4.00937 0.815992 5.73882 0.0582851 7.64796 0C7.78272 0 7.88379 0.104913 7.88379 0.233141Z"
          fill={color}
        />
        <path
          d="M18.5522 8.18322C18.1816 8.18322 17.8896 7.86848 17.8896 7.49545V5.07079C17.8896 4.68611 18.1929 4.38303 18.5522 4.38303H21.3261C21.6967 4.38303 21.9887 4.69777 21.9887 5.07079C21.9887 5.45547 21.6855 5.75856 21.3261 5.75856H19.2148V7.4838C19.2148 7.86848 18.9228 8.18322 18.5522 8.18322Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="24.2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Children.propTypes = {
  color: string,
};

export default Children;
