import React, { useState, useEffect } from 'react';
import { ActionButton, Modal, TertiaryButton, Alert } from 'wg-fe-ui';
import cogoToast from 'cogo-toast';
import { array, shape, string as propString } from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import useForm from '../hooks/useForm';
import moment from 'moment';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../components/LoadingButton';
import CRMOfferConvertModalForm from './CRMOfferConvertModalForm';
import { string, date } from '../../constants/validationSchemas';
import { isFuture, isToday } from 'date-fns';
import {
  createRiskObjectRevision,
  createOfferRevision,
  convertOfferToContract,
  convertOfferToContractManual,
} from '../services/apiRouterService';
import {
  parseIncDateToObject,
  parseObjectToNewDate,
  parseIncDateToIsoFormat,
} from '../services/dateService';

const CRMOfferConvertModal = ({
  modalState,
  data,
  distributionId,
  revisionId,
}) => {
  // const [validationSchema, setValidationSchema] = useState();
  const history = useHistory();
  const [showModal, setShowModal] = modalState;
  const [paymentSpread, setPaymentSpread] = useState({});
  const [languageOverwrite, setLanguageOverwrite] = useState();
  const [guarantees, setGuarantees] = useState({});
  const [loading, setLoading] = useState(false);
  const [dateInPastWarning, setDateInPastWarning] = useState(false);
  const [changeGuaranteePricing, setChangeGuaranteePricing] = useState(false);
  const [manualGuarantees, setManualGuarantees] = useState([]);
  const [shouldBeNotified, setShouldBeNotified] = useState(false);
  const { t } = useTranslation();

  const validatePastDate = value => {
    const dateObject = parseIncDateToObject(value);
    const dateOb = parseObjectToNewDate(dateObject);
    return isFuture(dateOb) || isToday(dateOb);
  };

  // useEffect(() => {
  //   console.log('data', data);
  // }, [data]);

  let defaultValidationSchema = {};

  if (data.insurance.type === 'TWO_WHEELER' || data.insurance.type === 'CAR') {
    defaultValidationSchema = {
      payment_spread: string.required,
      language_overwrite: string.required,
      commencement_date: date,
      main_expiration_date: string.required.min(5, t('too short')),
      number_plate: Yup.string()
        .nullable()
        .matches(/^$|^[A-Za-z0-9 -]{1,15}$/, t(`incorrect format`)),
      chassis_number: Yup.string()
        .matches(/^[A-Z0-9]{11,17}$/, t('Please use a valid chassis no'))
        .ensure()
        .required(t('Required')),
      edit_pricing: Yup.boolean(),
      net_premium: Yup.number().when('edit_pricing', {
        is: true,
        then: Yup.number()
          .min(1)
          .required(),
        otherwise: Yup.number().nullable(),
      }),
    };
  } else {
    defaultValidationSchema = {
      payment_spread: string.required,
      commencement_date: date,
      main_expiration_date: string.required.min(5, t('too short')),
    };
  }

  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape(defaultValidationSchema),
  );

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
    change: true,
  });

  useEffect(() => {
    const startDate = moment(data.commencement_date);

    data.acknowledgements.forEach(ack => {
      if (
        [
          'primary_driver_lessened_driving_ability',
          'primary_driver_convicted',
          'primary_driver_driving_ban',
          'had_claims_last_five_years',
          'previously_rejected',
        ].includes(ack?.key) &&
        ack?.agreed
      ) {
        setShouldBeNotified(true);
      }
    });

    if (
      data.insurance.type === 'TWO_WHEELER' ||
      data.insurance.type === 'CAR'
    ) {
      if (data.insurance.type === 'CAR') {
        handleChange({
          name: 'number_plate',
          value: data?.risk_objects.cars[0]?.number_plate,
        });
        handleChange({
          name: 'chassis_number',
          value: data?.risk_objects.cars[0]?.chassis_number,
        });
      } else {
        handleChange({
          name: 'number_plate',
          value: data?.risk_objects.two_wheelers[0]?.number_plate,
        });
        handleChange({
          name: 'chassis_number',
          value: data?.risk_objects.two_wheelers[0]?.chassis_number,
        });
      }
    }
    handleChange({
      name: 'commencement_date',
      value: startDate.format('DD/MM/YYYY'),
    });
    handleChange({
      name: 'main_expiration_date',
      // value: startDate.format('DD/MM'),
      value: startDate.format('DD/MM/YYYY'),
    });
    handleChange({
      name: 'payment_spread',
      value: data.payment_spread,
    });
    setPaymentSpread({
      label: t(data.payment_spread),
      value: data.payment_spread,
    });
    handleChange({
      name: 'language_overwrite',
      value: data.language,
    });
    setLanguageOverwrite({
      label: t(data.language === `NL` ? `Dutch` : `French`),
      value: data.language,
    });
    // Set value here to trigger checkbox auto checked in modal for convertOfferToContract
    handleChange({
      name: 'export_to_crm',
      value: true,
    });
    const tempValidationSchema = defaultValidationSchema;
    data?.guarantees?.map(item => {
      tempValidationSchema[item.name] = date;
      handleChange({
        name: item.name,
        value: startDate.format('DD/MM/YYYY'),
      });
    });
    setValidationSchema(Yup.object().shape(tempValidationSchema));

    setManualGuarantees(data?.guarantees);
  }, [data]);

  // useEffect(() => {
  //   console.log('manualGuarantees', manualGuarantees);
  // }, [manualGuarantees]);

  const handleGuaranteesChange = val => {
    handleChange({ name: val.name, value: val.value });
    if (data?.guarantees?.[0].name === val?.name && val?.value?.length > 5) {
      handleChange({
        name: 'main_expiration_date',
        value: val?.value,
      });
    }
    setGuarantees(prevValue => ({
      ...prevValue,
      [val.name]: val.value,
    }));
  };

  const handleChangePaymentSpread = ({ name, value }) => {
    setPaymentSpread({ label: t(value), value });
    handleChange({ name: name, value });
  };

  const handleChangeLanguageOverwrite = ({ name, value }) => {
    setLanguageOverwrite({ label: t(value), value });
    handleChange({ name: name, value });
  };

  const onSubmit = async val => {
    setLoading(true);
    const closeLoadingToast = cogoToast.loading(t('Converting offer'), {
      hideAfter: 0,
    });
    const now = new Date();
    let payload = {};
    console.log('DEBUG ON SUBMIT VALUE', val);
    if (
      data.insurance.type === 'TWO_WHEELER' ||
      data.insurance.type === 'CAR'
    ) {
      // Check if the licese plate or the chassis_numer changed from the original version
      const groupType = data.insurance.type === 'CAR' ? 'cars' : 'two_wheelers';
      const license_plate_changed =
        data.risk_objects?.[groupType]?.[0]?.number_plate !== val?.number_plate;
      const chassis_number_changed =
        data.risk_objects?.[groupType]?.[0]?.chassis_number !==
        val?.chassis_number;
      if (license_plate_changed || chassis_number_changed) {
        const riskObjectPayload = {
          ...data.risk_objects?.[groupType]?.[0],
          number_plate: val?.number_plate,
          chassis_number: val?.chassis_number,
        };
        delete riskObjectPayload?.created_at;
        delete riskObjectPayload?.quote_id;
        delete riskObjectPayload?.previous_insurances;
        delete riskObjectPayload?.risk_object_id;
        delete riskObjectPayload?.risk_object_revision_id;
        // If changed, first make a new revision of that risk object
        const [respROR, statusROR] = await createRiskObjectRevision(
          distributionId,
          data?.prospect?.id,
          groupType,
          data.risk_objects?.[groupType]?.[0].risk_object_id,
          riskObjectPayload,
        );
        if (statusROR === 201) {
          const guaranteesTaken = [];
          data?.guarantees.map(guarantee => {
            if (guarantee.taken) {
              guaranteesTaken.push(guarantee.name);
            }
          });
          data?.acknowledgements.map(ack => {
            delete ack?.created_at;
            delete ack?.offer_revision_id;
            return ack;
          });
          // Starting from that new risk object, make a new offer revision
          const offerRevisionPayload = {
            guarantees: guaranteesTaken,
            acknowledgements: data?.acknowledgements,
            commencement_date: parseIncDateToIsoFormat(val?.commencement_date),
            end_date: data?.end_date,
            payment_spread: val?.payment_spread,
            send_mail_to_prospect: false,
            risk_object_revisions: {
              [groupType]: [respROR?.risk_object_revision_id],
            },
          };
          const [respOfferRev, statusOfferRev] = await createOfferRevision(
            distributionId,
            data?.id,
            offerRevisionPayload,
          );
          if (statusOfferRev === 201) {
            revisionId = respOfferRev?.revision;
          } else {
            cogoToast.error(t('something went wrong, try again later'));
          }
        } else if (respROR.msg != null) {
          const { hide } = cogoToast.error(
            t(respROR.msg, {
              onClick: () => {
                hide();
              },
            }),
          );
        } else {
          cogoToast.error(t('something went wrong, try again later'));
        }
      }
    }
    // With that offer revision data in mind, create the contract
    if (changeGuaranteePricing === true) {
      console.log('PREMIUM: ' + JSON.stringify(data?.premium, null, 2));

      payload = {
        commencement_date: parseIncDateToIsoFormat(val?.commencement_date),
        payment_spread: val?.payment_spread,
        language_overwrite: val?.language_overwrite,
        main_expiry_date: parseIncDateToIsoFormat(
          `${val?.main_expiration_date}/${now.getFullYear() + 1}`,
        ),
        export_to_crm: val?.export_to_crm,
        manual_guarantees: Object.keys(guarantees).length > 0 ? [] : undefined,
        policy_costs: 0,
        bonus_malus:
          data?.premium?.bonus_malus === null ? 0 : data?.premium?.bonus_malus,
      };

      manualGuarantees.forEach(item => {
        let newGuarantee = {};

        newGuarantee.name = item?.name;
        newGuarantee.taxes = item?.taxes;
        newGuarantee.net_premium = item?.net_premium;
        newGuarantee.total_premium = item?.total_premium;
        newGuarantee.commission = item?.commission;
        newGuarantee.taxes_in_euro = item?.taxes_in_euro;
        newGuarantee.mandatory = item?.mandatory;
        newGuarantee.taken = item?.taken;

        Object.keys(guarantees).forEach(key => {
          item?.name === key
            ? (newGuarantee.commencement_date = parseIncDateToIsoFormat(
                val?.[key],
              ))
            : null;
        });

        payload.manual_guarantees.push(newGuarantee);
      });

      const [resp, status] = await convertOfferToContractManual(
        distributionId,
        data?.id,
        revisionId,
        payload,
      );

      closeLoadingToast();
      setLoading(false);

      if (status === 200) {
        cogoToast.success(t('Offer converted to contract successfully'));
        history.push(
          `/sales-management/case/${resp?.contract_case_id}/contract/${resp?.id}`,
        );
        setShowModal(false);
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
      } else {
        cogoToast.error(t('something went wrong, try again later'));
      }
    } else {
      payload = {
        commencement_date: parseIncDateToIsoFormat(val?.commencement_date),
        payment_spread: val?.payment_spread,
        main_expiry_date: parseIncDateToIsoFormat(
          `${val?.main_expiration_date}/${now.getFullYear() + 1}`,
        ),
        language_overwrite: val?.language_overwrite,
        export_to_crm: val?.export_to_crm,
        commencement_date_per_guarantees:
          Object.keys(guarantees).length > 0 ? [] : undefined,
      };

      Object.keys(guarantees).forEach(key => {
        payload.commencement_date_per_guarantees.push({
          guarantee: key,
          commencement_date: parseIncDateToIsoFormat(val?.[key]),
        });
      });

      const [resp, status] = await convertOfferToContract(
        distributionId,
        data?.id,
        revisionId,
        payload,
      );

      closeLoadingToast();
      setLoading(false);
      if (status === 200) {
        cogoToast.success(t('Offer converted to contract successfully'));
        history.push(
          `/sales-management/case/${resp?.contract_case_id}/contract/${resp?.id}`,
        );
        setShowModal(false);
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
      } else {
        cogoToast.error(t('something went wrong, try again later'));
      }
    }
  };

  const handleNetPremiumChange = (event, taxes, guaranteeId) => {
    const newNetPremium = Math.round(parseFloat(event.value) * 100) / 100;
    const accurateTax = taxes * 100;
    let taxPrice = 0;
    let newTotal = 0;

    if (!isNaN(newNetPremium)) {
      taxPrice = Math.round(((newNetPremium * accurateTax) / 100) * 100) / 100;
      newTotal = newNetPremium + taxPrice;
    }

    setManualGuarantees(prevState => {
      let newManGuarantees = [];

      prevState.map((item, i) => {
        item?.id === guaranteeId
          ? (newManGuarantees[i] = {
              ...item,
              net_premium: isNaN(newNetPremium) ? 0 : newNetPremium,
              taxes_in_euro: taxPrice,
              total_premium: newTotal,
            })
          : (newManGuarantees[i] = { ...item });
      });

      return newManGuarantees;
    });
  };

  const handleTotalPremiumChange = (event, taxes, guaranteeId) => {
    const newTotalPremium = Math.round(parseFloat(event.value) * 100) / 100;
    const accurateTax = taxes * 100;
    const totalTax = accurateTax + 100;

    let taxPrice = 0;
    let newNetPremium = 0;

    if (!isNaN(newTotalPremium)) {
      newNetPremium =
        Math.round(((newTotalPremium * 100) / totalTax) * 100) / 100;
      taxPrice = newTotalPremium - newNetPremium;
    }

    setManualGuarantees(prevState => {
      let newManGuarantees = [];

      prevState.map((item, i) => {
        item?.id === guaranteeId
          ? (newManGuarantees[i] = {
              ...item,
              net_premium: newNetPremium,
              taxes_in_euro: taxPrice,
              total_premium: isNaN(newTotalPremium) ? 0 : newTotalPremium,
            })
          : (newManGuarantees[i] = { ...item });
      });

      return newManGuarantees;
    });
  };

  console.log('DEBUG VALUES', values);

  useEffect(() => {
    if (
      !validatePastDate(values.commencement_date) &&
      values.commencement_date != null
    ) {
      setDateInPastWarning(true);
    } else {
      setDateInPastWarning(false);
    }
  }, [values]);

  return (
    <StyledModal
      canClose
      showModal={showModal}
      setShowModal={setShowModal}
      title={t('Convert offer to contract')}
    >
      <Container>
        {dateInPastWarning ? (
          <StyledAlert icon type="warning">
            {t('Be aware, the commencement date is in the past')}.
          </StyledAlert>
        ) : null}
        {shouldBeNotified ? (
          <Alert icon type="warning">
            {t(
              'Warning there are checked items in the acknowledgements Please check them before converting the offer to a contract',
            )}
            .
          </Alert>
        ) : null}

        <CRMOfferConvertModalForm
          errors={errors}
          values={values}
          handleChange={handleChange}
          paymentSpread={paymentSpread}
          languageOverwrite={languageOverwrite}
          handleGuaranteesChange={handleGuaranteesChange}
          handleChangePaymentSpread={handleChangePaymentSpread}
          handleChangeLanguageOverwrite={handleChangeLanguageOverwrite}
          guarantees={data?.guarantees}
          riskObjects={data?.risk_objects}
          changeGuaranteePricing={changeGuaranteePricing}
          setChangeGuaranteePricing={setChangeGuaranteePricing}
          handleNetPremiumChange={handleNetPremiumChange}
          handleTotalPremiumChange={handleTotalPremiumChange}
          manualGuarantees={manualGuarantees}
        />

        <Modal.ModalActions position="right">
          <TertiaryButton
            label={t('Cancel')}
            onClick={() => setShowModal(!showModal)}
          />
          {!loading ? (
            <ActionButton
              level="primary"
              onClick={() => handleSubmit(onSubmit)}
            >
              {t('Save')}
            </ActionButton>
          ) : (
            <StyledLoadingButton>{t('Saving')}</StyledLoadingButton>
          )}
        </Modal.ModalActions>
      </Container>
    </StyledModal>
  );
};

const StyledAlert = styled(Alert)`
  width: 100%;
  align-items: center;
  max-width: inherit;

  & > div {
    padding-top: 0;
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  height: 4rem;
  transition: 0.1s ease-in-out;
  display: flex;
  margin: 0;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '1rem 0.4rem'};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  height: 80vh;
  overflow-y: scroll;
`;

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 70rem;
`;

CRMOfferConvertModal.propTypes = {
  modalState: array,
  data: shape(),
  revisionId: propString,
  distributionId: propString,
};

export default CRMOfferConvertModal;
