import React from 'react';
import Section from './Section';
import styled from 'styled-components';
import { func, array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchSelectInput } from 'wg-fe-ui';

const LanguageSettings = ({
  languageOptions,
  languageSelector,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <Section data-test-id="dashboard_settings_section_user_settings">
      <Section.Title>{t('Settings.languageSettings.title')}</Section.Title>
      <Section.Content data-test-id="dashboard_settings_section_user_settings_content">
        <LanguageSwitch
          data-test-id="dashboard_settings_section_user_settings_content_language"
          id="changelanguageSection"
        >
          <SelectLanguage
            id="changelanguage"
            name="language"
            options={languageOptions}
            onSelected={({ value }) => {
              handleChange({ name: 'language', value });
            }}
            initial={languageSelector?.[0]}
            data-test-id="dashboard_settings_section_user_settings_content_language_select"
            autoComplete="off"
          >
            {''}
          </SelectLanguage>
        </LanguageSwitch>
      </Section.Content>
    </Section>
  );
};

const LanguageSwitch = styled.div`
  width: 47%;
`;

const SelectLanguage = styled(SearchSelectInput)`
  & .Select {
    &__option {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.typo.interactive};

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
        color: white;
      }

      &--is-focused {
        color: white;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background-color: ${({ theme }) => theme.brand.light};
        color: black;
      }
    }
  }
`;

LanguageSettings.defaultProps = {
  disabled: false,
};

LanguageSettings.propTypes = {
  handleChange: func,
  languageOptions: array,
  languageSelector: array,
};

export default LanguageSettings;
