import {
  generatePolicyProposition,
  generateTerminationLetter,
  generateInformationRequirementSheet,
  generateCustomerSheet,
  generateInformationRequirementSheetV2,
  generateTerminationLetterV2,
  getDocumentById,
} from './apiRouterService';

export async function getPolicyPropositionLink(payload) {
  const [idRes, status] = await generatePolicyProposition(payload);
  if (status === 201) {
    const [res, status] = await getDocumentById(idRes?.id);
    if (status === 200) {
      const downloadLink = res?.link;
      const fileName = res?.filename;
      return [downloadLink, fileName];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}

export async function getTerminationLetterLink(payload) {
  const [idRes, status] = await generateTerminationLetter(payload);
  if (status === 201) {
    const [res, status] = await getDocumentById(idRes?.id);
    if (status === 200) {
      const downloadLink = res?.link;
      const fileName = res?.filename;
      return [downloadLink, fileName];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}

export async function getInformationRequirementSheetLink(payload) {
  const [idRes, status] = await generateInformationRequirementSheet(payload);
  if (status === 201) {
    const [res, status] = await getDocumentById(idRes?.id);
    if (status === 200) {
      const downloadLink = res?.link;
      const fileName = res?.filename;
      return [downloadLink, fileName];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}

export async function getCustomerSheetLink(payload) {
  const [idRes, status] = await generateCustomerSheet(payload);
  if (status === 201) {
    const [res, status] = await getDocumentById(idRes?.id);
    if (status === 200) {
      const downloadLink = res?.link;
      const fileName = res?.filename;
      return [downloadLink, fileName];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}

export async function getTerminationLetterLinkV2(payload) {
  const [idRes, status] = await generateTerminationLetterV2(payload);
  if (status === 201) {
    const [res, status] = await getDocumentById(idRes?.id);
    if (status === 200) {
      const downloadLink = res?.link;
      const fileName = res?.filename;
      return [downloadLink, fileName];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}
export async function getInformationRequirementSheetLinkV2(payload) {
  const [idRes, status] = await generateInformationRequirementSheetV2(payload);
  if (status === 201) {
    const [res, status] = await getDocumentById(idRes?.id);
    if (status === 200) {
      const downloadLink = res?.link;
      const fileName = res?.filename;
      return [downloadLink, fileName];
    } else {
      return [null, null];
    }
  } else {
    return [null, null];
  }
}
