import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Section from './Section';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';
import { useTranslation } from 'react-i18next';
import INCOME from '../../assets/images/extentions/INCOME';
import PROPERTY from '../../assets/images/extentions/PROPERTY';
import CONSUMER from '../../assets/images/extentions/CONSUMER';

const DashboardBrokerHistoryLegalNeeds = ({ legalSituationData }) => {
  const { income, consumer, property } = legalSituationData || {};
  const { t } = useTranslation();

  return (
    <Section>
      <Section.Subtitle>{t('legal aid')}</Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_legal_aid_needs">
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('coverage income')}
          checked={income}
          disabled={true}
          icon={INCOME}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('coverage residence')}
          checked={property}
          disabled={true}
          icon={PROPERTY}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('coverage consumer')}
          checked={consumer}
          disabled={true}
          icon={CONSUMER}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

DashboardBrokerHistoryLegalNeeds.propTypes = {
  legalSituationData: shape().isRequired,
};

export default DashboardBrokerHistoryLegalNeeds;
