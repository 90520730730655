import React from 'react';

const NatureDamage = () => (
  <svg viewBox="0 0 261.54 205.84">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'nature_damage'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M259.24 136.39c-16.72-18-86.91-16.59-86.91-16.59l-14.15-24.45C121.13 81.8 53.65 84.57 25.94 94.47 22.39 95.74 0 123 0 133.29l2.11 38.07a9.15 9.15 0 008.81 6.64l19.29.93.61 5.49a26.88 26.88 0 0052.64 0l.43-5.49h97.32l.93 5.49a26.89 26.89 0 0052.65 0l1-5.49 11.75-.93a9.14 9.14 0 008.8-6.65l4.79-16.88c.87-3.05.27-15.74-1.89-18.08zM57.14 196.6a17.5 17.5 0 01-16.56-12.17 17.09 17.09 0 01-1.11-5.49 17.68 17.68 0 0135.35 0 17.36 17.36 0 01-1.11 5.49 17.51 17.51 0 01-16.57 12.17zm151.32 0a17.51 17.51 0 01-16.56-12.17 17.36 17.36 0 01-1.11-5.49 17.68 17.68 0 0135.35 0 17.36 17.36 0 01-1.11 5.49 17.51 17.51 0 01-16.57 12.17zM244.17 35.08a1.79 1.79 0 00-1.62-1h-21.12l20.85-31.46a1.65 1.65 0 00.05-1.74 1.8 1.8 0 00-1.56-.88h-28.52a1.8 1.8 0 00-1.6.94l-26.74 51.14a1.67 1.67 0 00.08 1.66 1.82 1.82 0 001.52.81h18.33l-20 45.36a1.67 1.67 0 00.71 2.12 1.83 1.83 0 00.93.25 1.8 1.8 0 001.36-.61l57-64.77a1.67 1.67 0 00.33-1.82z"
        />
      </g>
    </g>
  </svg>
);

export default NatureDamage;
