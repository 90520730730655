import React, { useEffect, useState, useContext } from 'react';
import Layout from '../../DashboardLayout/views/Layout';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';
import { isObjectEmpty } from '../../../services/objectService';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import PasswordSettings from '../components/PasswordSettings';
import * as Yup from 'yup';
import useForm from '../../../../hooks/useForm';
import i18next from 'i18next';
import Button from '../../../components/NewButton';
import {
  changePassword,
  auth,
  verifyInit,
} from '../../../services/apiRouterService';
import regex from '../../../services/regexService';
import { formatChangePasswordExeptions } from '../../../services/authService';
import { getMeData } from '../../../services/meDataService';
import { FlagsProvider } from '../../../hoc/FlagsProviderHoc';

const SettingsUserSettings = () => {
  const { t } = useTranslation();
  const configCatClient = useContext(FlagsProvider);
  const [canSave, setCanSave] = useState(false);
  const [loggedInEmail, setLoggedInEmail] = useState();
  const [isPasswordSettingsOnly, setIsPasswordSettingsOnly] = useState(false);

  const { setNavTabs } = useContext(TopNavigationItems);
  const content = t('Login', { returnObjects: true });

  useEffect(() => {
    setFeatures();
    getMeData().then(({ me }) => {
      setLoggedInEmail(me.email);
    });
  }, []);

  const setFeatures = async () => {
    const { me } = (await getMeData()) || {};

    const emailConfig = {
      email: me?.email,
    };

    const configCatValue = await configCatClient.getValueAsync(
      'passwordSettingsOnly',
      false,
      emailConfig,
    );

    setIsPasswordSettingsOnly(configCatValue);
  };

  const [touched, setTouched] = useState({
    oldPassword: false,
    newPassword: false,
  });

  const setFieldTouched = name => {
    setTouched({ ...touched, [name]: true });
  };

  const SignupSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, content.errors.short)
      .max(32, content.errors.long)
      .test('OldPassword-value', t(`Verify.errors.required`), value => {
        return (
          (!value && !values.newPassword) ||
          (value && values.newPassword) ||
          (value && touched.oldPassword && !values.newPassword)
        );
      }),
    newPassword: Yup.string()
      .min(8, content.errors.short)
      .max(32, content.errors.long)
      .matches(
        regex.password,
        t(
          `Password is not valid must be at least 8 characters long contain an uppercase letter a lowercase letter a number and a special character`,
        ),
      )
      .test('newPassword-value', t(`Verify.errors.required`), value => {
        return (
          (!value && !values.oldPassword) ||
          (value && values.oldPassword) ||
          (value && touched.newPassword && !values.oldPassword)
        );
      }),
    confirmPassword: Yup.string().test(
      'passwords-match',
      t(`Verify.errors.password must match`),
      value => {
        return (!value && !values.newPassword) || value === values.newPassword;
      },
    ),
  });

  let {
    handleChange,
    handleSubmit,
    values,
    errors,
    resetForm,
    setValues,
    setErrors,
  } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
    initialvalues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  useEffect(() => {
    console.log(errors);

    setCanSave(isObjectEmpty(errors));
  }, [errors]);

  useEffect(() => {
    if (values) {
      if (values.oldPassword === '' && !values.newPassword) {
        setValues({});
      } else if (values.oldPassword === '' && values.newPassword === '') {
        setValues({});
      } else if (values.newPassword === '' && !values.oldPassword) {
        setValues({});
      } else if (values.oldPassword === '' && values.newPassword === '') {
        setValues({});
      }
    }
  }, [values]);

  const switchErrorHandelingPassword = (msg, status) => {
    console.log(msg, status);

    const formattedMsg = formatChangePasswordExeptions(msg, status);
    console.log(formattedMsg);

    if (formattedMsg === 'environmentMismatch') {
      // go to master environment
      window.location.href = 'https://marketplace.wegroup.be';
      return;
    }

    formattedPaswordValidations(formattedMsg);
  };

  function formattedPaswordValidations(formattedMsg) {
    switch (formattedMsg) {
      case 'passwordsAreTheSame':
        setErrors({
          oldPassword: t(
            'Old and new password are the same they must be different',
          ),
        });
        break;
      case 'passwordMismatch':
        setErrors({
          oldPassword: t('incorrect password'),
        });
        break;
      default:
        setErrors({ oldPassword: t('something went wrong, try again later') });
        break;
    }
  }

  useEffect(() => {
    if (!isPasswordSettingsOnly) {
      setNavTabs([
        {
          title: t('Account settings'),
          testId: 'settings_account_settings',
          to: '/settings/account-settings',
        },
        {
          title: t('Change password'),
          testId: 'settings_user_settings',
          to: '/settings/user-settings',
        },
        {
          title: t('Edit affinities'),
          testId: 'settings_affinity_settings',
          to: '/settings/affinity-settings',
        },
      ]);
    } else {
      setNavTabs([
        {
          title: t('Change password'),
          testId: 'settings_user_settings',
          to: '/settings/user-settings',
        },
      ]);
    }
  }, [i18next.language, isPasswordSettingsOnly]);

  const onSubmit = async data => {
    const closeLoadingToast = cogoToast.loading(t('Saving your settings'), {
      hideAfter: 0,
    });
    if (
      data.oldPassword &&
      data.oldPassword !== '' &&
      data.newPassword &&
      data.confirmPassword &&
      data.newPassword !== ''
    ) {
      postPasswordValues(closeLoadingToast);
    }
    closeLoadingToast();
  };

  async function postPasswordValues(closeLoadingToast) {
    const { oldPassword, confirmPassword } = values;

    await verifyInit();

    const [passwordResp, passwordStatus] = await changePassword(
      oldPassword,
      confirmPassword,
    );
    await closeLoadingToast();
    if (passwordStatus >= 400) {
      switchErrorHandelingPassword(passwordResp.msg, passwordStatus);
      return;
    }

    const [, authStatus] = await auth(loggedInEmail, confirmPassword);
    if (authStatus >= 400) return;

    cogoToast.success(t('Settings successfully saved'));
    resetForm();
    setErrors({});
    setValues({});
  }

  return (
    <StyledLayout>
      <Header>
        <div>
          <Line />
          <Button
            dataTestId="dashboard_user_settings_submit"
            disabled={!canSave}
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            type="submit"
          >
            {t('Save changes')}
          </Button>
        </div>
      </Header>
      <PasswordSettings
        loggedInEmail={loggedInEmail}
        errors={errors}
        handleChange={handleChange}
        values={values}
        setFieldTouched={name => setFieldTouched(name)}
        setErrors={setErrors}
      />
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-top: 7rem; /* same as header height */
  width: 90%;
  max-width: 100rem;

  .content {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
`;

const Header = styled.div`
  width: 100% - 25rem;
  left: 25rem;
  right: 0;
  top: 8rem;
  position: fixed;
  display: flex;
  background-color: #f7f7f7;
  justify-content: center;
  z-index: 5;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

  & > div {
    height: 7rem;
    width: 90%;
    max-width: 100rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Line = styled.div`
  width: 0.1rem;
  height: 80%;
  background-color: #ccc;
  margin: 0 2.5rem;
`;

SettingsUserSettings.propTypes = {
  history: shape().isRequired,
};

export default SettingsUserSettings;
