import React from 'react';
import { string } from 'prop-types';

const PassiveHouseIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      fill="none"
      viewBox="0 0 22 19"
    >
      <path
        fill={color}
        d="M1.304 17.837L.02 13.047a.618.618 0 01.07-.478l2.03-3.167c.04-.06.06-.12.08-.19l1.464-6.75a.52.52 0 01.309-.39l4.67-2.03c.27-.12.568.03.668.328l2.858 8.514c.03.1.04.2.02.309l-1.604 8.604c-.05.259-.259.448-.498.448H1.782c-.209 0-.418-.17-.478-.408zM12.208 17.568l1.564-8.385a.65.65 0 00-.02-.309L12.517 5.2c-.14-.429.21-.857.617-.737l4.651 1.344c.2.06.349.24.378.458l.21 1.444c.02.13.07.24.159.329l2.54 2.559c.119.12.178.299.159.478l-.717 6.672c-.03.279-.25.498-.508.498h-7.29c-.328 0-.567-.329-.508-.677z"
      ></path>
    </svg>
  );
};

PassiveHouseIcon.defaultProps = {
  color: `white`,
};

PassiveHouseIcon.propTypes = {
  color: string,
};

export default PassiveHouseIcon;
