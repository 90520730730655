import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { IconPropertyFilled } from 'wg-fe-ui';

const ResidenceCardMap = ({ satelliteData, homeData }) => {
  return (
    <Container>
      <SatelliteImage
        src={satelliteData?.marked}
        alt="Satellite view of the address"
        // height="415"
      />
      <SatelliteInfoOverlay>
        <IconPropertyFilled color="#cccccc" size={35} />
        <SatelliteOverlayText>
          <p>
            {`${homeData?.address?.street} ${homeData?.address?.housenr}`}
            {homeData?.address?.boxnr ? homeData?.address?.boxnr : null},
          </p>
          <p>{`${homeData?.address?.zipcode} ${homeData?.address?.city}`}</p>
        </SatelliteOverlayText>
      </SatelliteInfoOverlay>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 45%;
  max-height: 41.5rem;
  max-height: 100%;
`;

const SatelliteImage = styled.img`
  display: block;
  border-radius: 6px 0 0 6px;
  width: 100%;
  height: 100%;
`;

const SatelliteInfoOverlay = styled.div`
  position: absolute;
  display: flex;
  background: white;
  border-radius: 5px;
  bottom: 1.6rem;
  left: 1.6rem;
  right: 1.6rem;
  width: 92%;
  max-width: 42rem;
  height: 7.6rem;
  padding: 2rem;
  align-items: center;
`;

const SatelliteOverlayText = styled.div`
  color: #050505;
  font-size: 1.6rem;
  margin-left: 2.4rem;
  font-weight: bold;
  line-height: 2rem;
`;

ResidenceCardMap.propTypes = {
  satelliteData: shape({}).isRequired,
  homeData: shape({}).isRequired,
};

export default ResidenceCardMap;
