/* stylelint-disable no-descending-specificity */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { shape } from 'prop-types';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Button from '../../../components/Button';
import LoadingButton from '../../../components/LoadingButton';
import NL from '../../../../assets/images/flags/NL.svg';
import EN from '../../../../assets/images/flags/EN.svg';

// stores
import PersonalInput from '../components/PersonalInput';
// svg
import bg from '../../../../assets/images/bg-login.svg';
import logo from '../../../../assets/images/logo-white.svg';
import { postAuth, validateSocialLogin } from '../store';
import { ThemeContext } from '../../../services/context/ThemingContext';

const Captcha = ({ match, history }) => {
  const { t } = useTranslation();
  const [langIcon, setLangIcon] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [sendStep, setSendStep] = useState({ status: 'initial' });
  const [captchaToken, setCaptchaToken] = useState(undefined);
  const { setTheme } = useContext(ThemeContext);

  const setLanguage = lang => {
    i18next.changeLanguage(lang);

    if (i18next.language === 'nl') {
      setLangIcon(NL);
    }
    if (i18next.language === 'en') {
      setLangIcon(EN);
    }
  };

  useEffect(() => {
    if (i18next.language === 'nl') {
      setLangIcon(NL);
    }
    if (i18next.language === 'en') {
      setLangIcon(EN);
    }
    switch (sendStep.status) {
      case 'pending':
        break;
      case 'success':
        history.push('/session/user');
        break;
      case 'error':
        break;
      default:
        break;
    }
  }, [sendStep]);

  const onChange = token => {
    setCaptchaToken(token);
  };

  const submitHandler = values => {
    setSendStep({ status: 'pending' });
    const payload = {
      ask_role: 'social_login',
      ask_broker_id: match.params.id,
      captcha: captchaToken,
      captcha_user_information: {
        email: values.email,
        last_name: values.last_name,
        first_name: values.first_name,
      },
    };
    postAuth(payload)
      .then(res => {
        validateSocialLogin(res, match.params.id, setTheme)
          .then(() => {
            setSendStep({ status: 'success', succesResponse: res });
          })
          .catch(err => {
            console.error(err);
          });
      })
      .catch(error => {
        setSendStep({ status: 'error' });
        console.error(error);
      });
  };

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(t(`Email not valid`))
      .required(t(`Required`))
      .min(2, t(`Too short`))
      .max(50, t(`Too long`)),
    first_name: Yup.string()
      .required(t(`Required`))
      .min(2, t(`Too short`))
      .max(50, t(`Too long`)),
    last_name: Yup.string()
      .required(t(`Required`))
      .min(2, t(`Too short`))
      .max(50, t(`Too long`)),
  });

  const disableHandler = (errors, touched) => {
    if (errors && touched && captchaToken) {
      if (
        Object.entries(errors).length === 0 &&
        Object.entries(touched).length
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <Container>
      <GoogleReCaptchaProvider reCaptchaKey="6LcoCcAUAAAAALrN6h0fgePbz1OLk6zer-Z9RWOy">
        <LangSelector>
          <FlagIcon
            src={langIcon}
            alt={i18next.language}
            width="30"
            height="30"
            button={true}
          />
          <DropDownContainer>
            <button type="button" onClick={() => setLanguage('nl')}>
              <FlagIcon src={NL} alt="Nederlands" width="30" height="30" /> NL
            </button>
            <button type="button" onClick={() => setLanguage('en')}>
              <FlagIcon src={EN} alt="English" width="30" height="30" /> EN
            </button>
          </DropDownContainer>
        </LangSelector>
        <LeftSide>
          <LoginContainer>
            <Title>{t('Manual login')}</Title>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                email: '',
              }}
              onSubmit={values => {
                submitHandler(values);
              }}
              validationSchema={SignupSchema}
            >
              {({ errors, setFieldTouched, touched, setFieldValue }) => {
                disableHandler(errors, touched);
                return (
                  <FormWrapper>
                    <InputWrapper>
                      <PersonalInput
                        type="first_name"
                        name="first_name"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      >
                        {t('First name')}
                      </PersonalInput>
                      <PersonalInput
                        type="text"
                        name="last_name"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      >
                        {t('Last name')}
                      </PersonalInput>
                      <PersonalInput
                        type="email"
                        name="email"
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                      >
                        {t('Email')}
                      </PersonalInput>
                      <ReCaptcha
                        onVerify={token => {
                          onChange(token);
                        }}
                      />
                    </InputWrapper>
                    <ButtonContainer>
                      {/* {_errors !== [] && _errors
                        ? _errors.map(error => (
                            <ErrorMsg key={error}>{error}</ErrorMsg>
                          ))
                        : null} */}
                      {sendStep.status === 'pending' ? (
                        <LoadingButton>{t(`Loading`)}</LoadingButton>
                      ) : (
                        <Button
                          data-testid="step-1-verify-submit"
                          disabled={isDisabled}
                          type="submit"
                        >
                          {t(`Start conversation`)}
                        </Button>
                      )}
                    </ButtonContainer>
                  </FormWrapper>
                );
              }}
            </Formik>
          </LoginContainer>
        </LeftSide>
        <RightSide>
          <BackgroundContainer>
            <LouiseImage src={bg} alt="bg image" />
          </BackgroundContainer>
          <LogoContainer>
            <a href="/">
              <Logo src={logo} alt="logo" />
            </a>
          </LogoContainer>
          <LinkContainer>
            <a
              href="https://wegroup.be/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </LinkContainer>
        </RightSide>
      </GoogleReCaptchaProvider>
    </Container>
  );
};

const DropDownContainer = styled.div`
  display: none;
  position: absolute;
  left: 0;
  top: 3.2rem;
  flex-direction: column;
  background: #fafafa;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  width: auto;
  border-radius: 0.5rem;
  padding: 0.7rem 0;

  @media screen and (min-width: 1200px) {
    top: 3.5rem;
  }

  & button {
    cursor: pointer;
    border: none;
    background: none;
    text-align: left;
    align-items: center;
    outline: none;
    display: flex;
    padding: 0.7rem 1.5rem 0.7rem 0;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
    font-weight: bold;

    &:active {
      border: none;
    }

    &:hover {
      color: #ff8000;
      background-color: #ececec;
    }

    &:last-child {
      margin-bottom: 0;
    }

    & > img {
      margin: 0 1.5rem;
    }
  }
`;

const FlagIcon = styled.img`
  width: 3rem;
  height: 3rem;
  margin-left: 1.5rem;

  @media screen and (max-width: 768px) {
    display: flex;

    width: 5rem;
    height: 5rem;
  }
`;

const LangSelector = styled.div`
  left: 2rem;
  z-index: 50;
  top: 3rem;
  position: absolute;
  &:hover {
    & ${DropDownContainer} {
      display: flex;
    }
  }
`;

const ReCaptcha = styled(GoogleReCaptcha)`
  width: 100%;
  padding-top: 0;
  margin-bottom: 5rem;
  display: flex;
  flex: 0;
  flex-basis: 60%;
  justify-content: center;
  z-index: 999;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  flex: 0 0 100%;
  flex-wrap: wrap;
  min-width: 100%;

  & button {
    width: 20rem;
    font-size: 1.6rem;
  }
`;

// const ErrorMsg = styled.p`
//   color: red;
//   font-weight: 500;
//   margin-bottom: 1rem;
//   font-size: 1.6rem;
//   flex: 0 0 100%;
//   text-align: center;
// `;

const FormWrapper = styled(Form)`
  display: flex;
  height: 100%;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & input {
    margin-top: 0;
  }
`;

const LogoContainer = styled.div`
  width: 60%;
  padding-top: 5rem;
  display: flex;
  flex: 0;
  flex-basis: 60%;
  justify-content: center;
  position: relative;

  @media (orientation: portrait) {
    width: auto;
    padding: 2rem 0;
  }

  & a {
    align-self: flex-start;
  }
`;

const LouiseImage = styled.img`
  align-self: center;
  position: absolute;
  right: 0;
  width: 100%;
  flex: 0 0 100%;
  object-fit: scale-down;

  @media (orientation: portrait) {
    display: none;
  }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 0;
  bottom: 0;
  right: 0;
  top: 0;
  width: 155%;
`;

const Logo = styled.img`
  width: 100%;

  @media (orientation: portrait) {
    width: auto;
    height: 4rem;
  }
`;

const RightSide = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgb(255, 128, 0) 58.56%, rgb(255, 155, 33) 100%);
  background-repeat: no-repeat;
  flex-wrap: wrap;
  position: relative;

  @media (orientation: portrait) {
    width: 100%;
    position: initial;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  z-index: 0;
  margin-bottom: 3rem;

  & a {
    text-decoration: none;
    color: white;
    font-size: 1.6rem;
    margin-bottom: 1rem;

    @media (orientation: portrait) {
      color: ${({ theme }) => theme.typo.interactive};
    }
  }

  @media (orientation: portrait) {
    width: auto;
    margin: 0;
    flex: 0 0 100%;
    position: absolute;
    bottom: 2rem;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  font-size: 2rem;
  line-height: 2.6rem;
  flex-wrap: nowrap;

  @media (orientation: portrait) {
    flex-flow: column-reverse wrap;
  }
`;

const LeftSide = styled.div`
  width: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  @media (orientation: portrait) {
    width: 100%;
    margin: auto;
  }
`;

const LoginContainer = styled.div`
  width: 100%;
  z-index: 30;
`;

const Title = styled.h1`
  flex: 0 0 100%;
  font-weight: 900;
  font-size: 4.5rem;
  text-align: center;
  margin-bottom: 5rem;
`;

Captcha.propTypes = {
  history: shape().isRequired,
  match: shape().isRequired,
};

export default withRouter(Captcha);
