import React from 'react';
import { shape } from 'prop-types';

const Juwels = ({ colors }) => (
  <svg
    width="74"
    height="99"
    viewBox="0 0 74 99"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7644 11.2965L36.768 1.29199L45.0262 11.2965H28.7644Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M36.7679 1.29199H46.0448L54.5941 11.2965H44.9534L36.7679 1.29199Z"
      fill={colors.brand.primary}
    />
    <path
      d="M36.768 1.29199H27.4911L19.1237 11.2965H28.7644L36.768 1.29199Z"
      fill={colors.brand.primary}
    />
    <path
      d="M28.7644 11.2964H44.9535L36.9499 31.6692L28.7644 11.2964Z"
      fill={colors.brand.primary}
    />
    <path
      d="M19.1237 11.2964H28.7644L36.9499 31.6692L19.1237 11.2964Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M44.9535 11.2964H54.5942L36.9499 31.6692L44.9535 11.2964Z"
      fill={colors.brand.light}
    />
    <path
      d="M46.4633 0.182373H27.2728L18.2142 10.4961L17.5048 11.2964L28.1096 23.32L36.9499 33.3427L45.6993 23.32L56.1949 11.2964L46.4633 0.182373ZM38.1322 28.6497L36.9499 30.0139L35.7312 28.6315L31.02 23.3018L20.4334 11.2964V11.2783L28.2551 2.36517H45.4628L53.3027 11.2783L42.8253 23.2836L38.1322 28.6497Z"
      fill={colors.brand.primary}
    />
    <path
      opacity="0.15"
      d="M45.6993 23.3018L36.9499 33.3244L28.1096 23.3018H45.6993Z"
      fill="#141414"
    />
    <path
      d="M36.8589 26.8125C17.4502 26.8125 1.66132 42.6014 1.66132 62.0101C1.66132 81.4188 17.4502 97.2077 36.8589 97.2077C56.2676 97.2077 72.0565 81.4188 72.0565 62.0101C72.0565 42.6014 56.2676 26.8125 36.8589 26.8125ZM36.8589 91.5688C20.5607 91.5688 7.30021 78.3083 7.30021 62.0101C7.30021 45.7119 20.5607 32.4514 36.8589 32.4514C53.1571 32.4514 66.4176 45.7119 66.4176 62.0101C66.4176 78.3083 53.1571 91.5688 36.8589 91.5688Z"
      fill={colors.brand.primary}
    />
    <path
      d="M36.8589 97.2077C17.4502 97.2077 1.66132 81.4188 1.66132 62.0101C1.66132 42.6014 17.4502 26.8125 36.8589 26.8125C56.2676 26.8125 72.0565 42.6014 72.0565 62.0101C72.0565 81.4188 56.2676 97.2077 36.8589 97.2077Z"
      stroke={colors.brand.primary}
      strokeWidth="2.1828"
      strokeMiterlimit="10"
    />
    <path
      d="M36.859 91.5688C20.5608 91.5688 7.30029 78.3083 7.30029 62.0101C7.30029 45.7119 20.5608 32.4514 36.859 32.4514C53.1572 32.4514 66.4177 45.7119 66.4177 62.0101C66.4177 78.3083 53.1572 91.5688 36.859 91.5688Z"
      stroke={colors.brand.lighter}
      strokeWidth="2.1828"
      strokeMiterlimit="10"
    />
  </svg>
);

Juwels.propTypes = {
  colors: shape().isRequired,
};

export default Juwels;
