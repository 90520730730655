import React from 'react';
import { string } from 'prop-types';

const PassiveHouseIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="26"
      fill="none"
      viewBox="0 0 23 26"
    >
      <path
        fill={color}
        d="M22.664 7.145L19.698 5.21V1.314a.732.732 0 00-.716-.738h-2.82a.732.732 0 00-.717.738V2.42L11.92.115A.645.645 0 0011.54 0a.646.646 0 00-.381.115L.336 7.157A.762.762 0 000 7.779v17.15C0 25.515.47 26 1.04 26h20.92c.57 0 1.04-.484 1.04-1.072V7.768a.734.734 0 00-.336-.623zM21.545 24.49h-9.804v-3.907c3.089-.426 5.473-3.146 5.473-6.43V10.43c-3.481 0-6.313 2.904-6.313 6.5v.265c-.235-2.65-2.395-4.737-5.036-4.737v2.985c0 2.57 1.813 4.714 4.197 5.129v3.918H1.466V8.194L11.55 1.637l3.134 2.05 2.239 1.465V2.086h1.343v3.976l.671.438 2.62 1.706V24.49h-.012z"
      ></path>
    </svg>
  );
};

PassiveHouseIcon.defaultProps = {
  color: `white`,
};

PassiveHouseIcon.propTypes = {
  color: string,
};

export default PassiveHouseIcon;
