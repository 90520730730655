import React, { useEffect, useState } from 'react';
import Section from './Section';
import styled from 'styled-components';

import Affinity from './Affinity';
import { array } from 'prop-types';
import {
  getBrokerAffiliations,
  resetBrokerData,
  getBrokerIntegrations,
} from '../../../services/brokerDataService';
import { useTranslation } from 'react-i18next';
import { retrieveAvailableAffiliations } from '../../../services/apiRouterService';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const [affiliations, setAffiliations] = useState([]);
  const [allAffiliations, setAllAffiliations] = useState([]);
  const [integrations, setIntegrations] = useState([]);

  useEffect(() => {
    fetchAffiliations();
  }, []);

  const getAffiliations = async () => {
    const [{ items }] = await retrieveAvailableAffiliations();
    setAllAffiliations(items);
  };

  async function fetchAffiliations() {
    await getAffiliations();
    await resetBrokerData();
    const { items } = await getBrokerAffiliations();
    setAffiliations(items);
    const integrations = await getBrokerIntegrations();
    setIntegrations(integrations);
  }

  return (
    <Section>
      <Section.Title>{t('Affinities')}</Section.Title>
      {!allAffiliations ? (
        <Section.Content data-test-id="dashboard_settings_section_personal_info">
          <p>Loading...</p>
        </Section.Content>
      ) : (
        <Section.Content data-test-id="dashboard_settings_section_personal_info">
          <AffinityContainer showLine={affiliations?.length}>
            {affiliations?.length > 0
              ? allAffiliations?.map(({ affiliation_info }) => {
                  const { affiliation } =
                    affiliations?.find(
                      ({ affiliation }) =>
                        affiliation?.name === affiliation_info?.name,
                    ) || {};
                  if (affiliation) {
                    return (
                      <Affinity
                        key={affiliation_info?.name}
                        name={affiliation_info?.name}
                        label={affiliation_info?.label}
                        affiliationData={affiliation}
                        logo={affiliation_info?.logo}
                        integrations={integrations}
                        required_nrs={affiliation_info?.required_nrs}
                        required_integrations={
                          affiliation_info?.required_integrations
                        }
                        insurances={affiliation_info?.insurances}
                        needs_verification={
                          affiliation_info?.needs_verification
                        }
                        needs_access_request={
                          affiliation_info?.needs_access_request
                        }
                        checked={true}
                        reload={fetchAffiliations}
                      />
                    );
                  }
                })
              : ''}
          </AffinityContainer>
          <AffinityContainer>
            {allAffiliations?.map(({ affiliation_info }) => {
              const affiliationData = affiliations?.find(
                ({ affiliation }) => affiliation.name === affiliation_info.name,
              );
              if (!affiliationData) {
                return (
                  <Affinity
                    key={affiliation_info.name}
                    name={affiliation_info.name}
                    label={affiliation_info.label}
                    affiliationData={affiliationData}
                    logo={affiliation_info.logo}
                    integrations={integrations}
                    required_nrs={affiliation_info.required_nrs}
                    required_integrations={
                      affiliation_info.required_integrations
                    }
                    insurances={affiliation_info.insurances}
                    needs_verification={affiliation_info.needs_verification}
                    checked={false}
                    reload={fetchAffiliations}
                  />
                );
              }
            })}
          </AffinityContainer>
        </Section.Content>
      )}
    </Section>
  );
};

const AffinityContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;

  ${({ showLine }) =>
    showLine
      ? `
  &:first-child {
    border-bottom: 1px solid #ccc;
    padding-bottom: 4rem;
    margin-bottom: 4rem;
  }
  `
      : ``}

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

PersonalInfo.defaultProps = {
  affinities: [],
};

PersonalInfo.propTypes = {
  affinities: array,
};

export default PersonalInfo;
