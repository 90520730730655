import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { func, object, string, array } from 'prop-types';
import { Tooltip, H4, LoadingSpinner, ToggleInput } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../env.config';
import { useParams } from 'react-router';
import _ from 'lodash';

import {
  getInfoAboutAddress,
  getRoomsEstimationFromAddress,
} from '../services/apiRouterService';

import ProFlowAddressSearchInputWithFallback from './ProFlowAddressSearchInputWithFallback';
import ResidenceCardMap from './ResidenceCardMap';
import ResidenceCardInputs from './ResidenceCardInputs';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import { retrieveStorageById } from '../services/storeService';

const ProFlowInputTypeAddress = ({
  onSubmit,
  value,
  error,
  annexesData,
  saveChangesAnnexes,
  roomData,
  setRoomData,
  dataTestId,
}) => {
  const { i18n } = useTranslation();
  const [address, setAddress] = useState();
  const [homeData, setHomeData] = useState();
  const [satelliteData, setSatelliteData] = useState({});
  const [useInfo, setUseInfo] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isManualActive, setIsManualActive] = useState(true);
  const [oldAddress, setOldAddress] = useState();
  const [hasHouse, setHasHouse] = useState();
  const { sessionId } = useParams();
  const { answers } = useContext(ProFlowStorageContext);

  useEffect(() => {
    if (address != null) {
      getAddressInformation(address);
    }
  }, [address]);

  useEffect(() => {
    if (value && value?.initial) {
      setHomeData(value);
      saveChangesAnnexes(value?.annexes);
    }
  }, [value]);

  useEffect(() => {
    if (useInfo) usePreviousInformation(false);
  }, [
    _.find(answers, {
      id: 'e1ff7109-0552-475f-b4bc-4b8ab3920d67',
    }),
  ]);

  useEffect(() => {
    (async () => {
      const { hasHouse } = await retrieveStorageById('pro_flow', sessionId);
      setHasHouse(hasHouse ? true : false);
    })();
  }, []);

  async function getAddressInformation(data) {
    // If only box number changes address should not be pulled from API again
    if (
      !_.isEqual(_.omit(oldAddress, 'boxNumber'), _.omit(address, 'boxNumber'))
    ) {
      setIsLoading(true);
      const payload = {
        street: data.streetName,
        zipcode: data.postalCode,
        housenr: data.streetNumber,
        country_code: 'BE',
        boxnr: data.boxNumber,
      };

      let [response, status] = await getInfoAboutAddress(payload);
      if (status !== 200) {
        setHomeData(null);
        return console.error('Error Response: ', response);
      } else {
        setHomeData(response);
        // Adding an id to every annex so that we can remove or edit them in an easy way later on
        saveChangesAnnexes(response?.parcel?.annexes);
      }
      await setIsLoading(false);
    }
  }

  useEffect(() => {
    if (homeData && homeData?.links) {
      // ID Code to get external data from our API
      const code = homeData?.links?.[0]?.href?.split('/')?.pop();
      getRooms(code);
      getSatelliteImage(code);
    }
  }, [homeData]);

  useEffect(() => {
    homeData != null && handleHomeData();
  }, [annexesData]);

  async function getRooms(id) {
    let [response, status] = await getRoomsEstimationFromAddress(id);

    if (status !== 200) {
      // Default if room estimation failed
      return console.error('Error Response: ', response);
    } else {
      setRoomData(response);
    }
  }

  useEffect(() => {
    setIsManualActive(isManualActive => !isManualActive);
    if (useInfo !== undefined) {
      usePreviousInformation(true);
    }
  }, [useInfo]);

  function usePreviousInformation(toggle) {
    // If toggle was false it will just update the information
    if (!toggle || useInfo) {
      // Add info
      const previousQuestionAnswer = _.find(answers, {
        id: 'e1ff7109-0552-475f-b4bc-4b8ab3920d67',
      }).answer;

      // Used to check if address should be pulled from API again
      setOldAddress(address);
      setAddress(previousQuestionAnswer);
    } else {
      // Remove info
      setAddress(null);
      setHomeData(null);
      saveChangesAnnexes([]);
      setRoomData(null);
    }
  }

  function getSatelliteImage(id) {
    if (id) {
      setSatelliteData({
        blank: `${BASE_URL}v1/api/address/v2/api/aerial_image/${id}?mark_type=blank&map_type=satellite`,
        marked: `${BASE_URL}v1/api/address/v2/api/aerial_image/${id}?mark_type=marked&map_type=hybrid`,
      });
    }
  }

  function handleHomeData(_home) {
    // below code is redundant
    // let newAnnexesData = JSON.parse(JSON.stringify(annexesData));
    // console.log(newAnnexesData);
    annexesData?.forEach(annex => {
      annex.type = annex.type.toUpperCase();
      delete annex.id;
    });
    const payload = {
      address: homeData?.address,
      attachment:
        _home?.attachment ||
        homeData?.parcel?.main_building?.house_type.toUpperCase(),
      cellar: _home?.cellar || 'NONE',
      attic: _home?.attic || 'UNINHABITABLE',
      //annexes: annexesData,
      facade: {
        width: homeData?.parcel?.main_building?.facade?.width,
      },
    };
    onSubmit(payload);
  }

  return (
    <AddressQuestionContainer>
      {!hasHouse && (
        <>
          <H4>{i18n.t('Take over the address of the policyholder')}</H4>
          <ToggleInput
            falseLabel={i18n.t('No')}
            trueLabel={i18n.t('Yes')}
            onChange={() =>
              !_.isEmpty(
                _.find(answers, {
                  id: 'e1ff7109-0552-475f-b4bc-4b8ab3920d67',
                }),
              ) && setUseInfo(useInfo => !useInfo)
            }
            disabled={_.isEmpty(
              _.find(answers, {
                id: 'e1ff7109-0552-475f-b4bc-4b8ab3920d67',
              }),
            )}
            data-test-id={`${dataTestId}_take_over`}
          />
        </>
      )}

      {!homeData?.initial && (
        <>
          <QuestionTitle>
            <H4>
              {i18n.t('Search for an address')} * {error}
            </H4>
            <StyledTooltip toggleIcon="?">
              {i18n.t(
                'By entering your street name and house number, the system will automatically look up your address If the address is not immediately displayed, you can always further specify by postal code or city If you really cant find the address, you can always enter it manually by clicking on I cant find my address below',
              )}
            </StyledTooltip>
          </QuestionTitle>

          <ProFlowAddressSearchInputWithFallback
            error={error}
            address={address}
            setAddress={setAddress}
            getAddressInformation={getAddressInformation}
            isManualActive={isManualActive}
            setIsManualActive={setIsManualActive}
            useInfo={useInfo}
            data-test-id={`${dataTestId}_address`}
          />
        </>
      )}
      {(isLoading || homeData != null) && (
        <>
          <QuestionTitle>
            <H4>{i18n.t('Info about the residence')}</H4>
          </QuestionTitle>
          <ResidenceCard>
            {isLoading ? (
              <LoadingWrapper>
                <LoadingSpinner />
              </LoadingWrapper>
            ) : (
              <>
                <ResidenceCardMap
                  satelliteData={satelliteData}
                  homeData={homeData}
                />
                <ResidenceCardInputs
                  onChange={handleHomeData}
                  homeData={homeData}
                  dataTestId={`${dataTestId}_address_info`}
                />
              </>
            )}
          </ResidenceCard>
        </>
      )}
    </AddressQuestionContainer>
  );
};

const AddressQuestionContainer = styled.div`
  margin-bottom: 2rem;
`;

const QuestionTitle = styled.div`
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  z-index: 999999;
  margin-top: -0.2rem;
  margin-left: 0.5rem;
`;

const ResidenceCard = styled.div`
  display: flex;
  background: white;
  border: 1px solid #f0f1f3;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  margin-top: 1.4rem;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem;
  width: 100%;
`;

ProFlowInputTypeAddress.propTypes = {
  onSubmit: func.isRequired,
  value: object,
  error: string,
  annexesData: array,
  saveChangesAnnexes: func.isRequired,
  roomData: object.isRequired,
  setRoomData: func.isRequired,
  dataTestId: string,
};

export default ProFlowInputTypeAddress;
