import React from 'react';
import { object } from 'prop-types';
import styled from 'styled-components';
import { getNestedObject } from '../services/objectService';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DashboardBrokerHistoryConversationInformation = ({ sessionData }) => {
  const { t } = useTranslation();
  const { first_name, last_name } =
    getNestedObject(sessionData, ['personal']) || '';

  const { street, housenr, zipcode, city } =
    getNestedObject(sessionData, ['personal', 'billing_address']) || '';

  const parseDateToMoment = () => {
    const date = sessionData?.created_at;

    if (typeof date === 'string') {
      return moment(date).format('DD/MM/YYYY');
    } else {
      let { day, month, year } = date || {};
      return `${day}/${month}/${year}`;
    }
  };

  const parseTimeToMoment = () => {
    const date = sessionData?.created_at;
    if (typeof date === 'string') {
      return moment(date).format('HH:mm');
    } else {
      let { hour, minute } = date || {};
      return `${hour}:${minute}`;
    }
  };

  return (
    <SectionWrapper data-test-id="dashboard_history_detail_section_conversation_information">
      <InfoBlock>
        <Label>{t('History.information.customer')}</Label>
        <Value>
          {first_name} {last_name}
        </Value>
      </InfoBlock>
      <InfoBlock>
        <Label>{t('History.information.time')}</Label>
        <Value>{parseTimeToMoment()}</Value>
      </InfoBlock>
      <InfoBlock>
        <Label>{t('History.information.date')}</Label>
        <Value>{parseDateToMoment()}</Value>
      </InfoBlock>
      <InfoBlock>
        <Label>{t('History.information.street')}</Label>
        <Value>
          {street} {housenr}
        </Value>
      </InfoBlock>
      <InfoBlock>
        <Label>{t('History.information.postal')}</Label>
        <Value>
          {zipcode} {city}
        </Value>
      </InfoBlock>
    </SectionWrapper>
  );
};

const InfoBlock = styled.div`
  /* min-width: 15rem; */
  margin-right: 2rem;
`;

const Label = styled.p`
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.1rem;
  color: #a29c95;
`;

const Value = styled.p`
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #505050;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  margin-top: 6rem;
  display: flex;
  border-bottom: 0.1rem solid;
  border-color: ${({ theme }) => theme.ui.background};
  padding-bottom: 2.5rem;
  justify-content: space-between;
  padding-right: 5rem;
  max-width: 100rem;
`;

DashboardBrokerHistoryConversationInformation.propTypes = {
  sessionData: object,
};

export default DashboardBrokerHistoryConversationInformation;
