import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { OverviewTable, Section } from 'wg-fe-ui';
import { object, bool } from 'prop-types';

const CRMRiskObjectDetailResidenceAnnexes = ({ homeData, disableEditing }) => {
  const { t } = useTranslation();

  // const annexTypes = {
  //   garden_shed: 'Garden shed',
  //   carport: 'Carport',
  //   gazebo: 'Gazebo',
  //   other: 'Other',
  // };

  return (
    <>
      {homeData?.annexes?.length > 0 ? (
        <StyledSection>
          <Section.Title>{t('Annexes')}</Section.Title>

          <OverviewTable>
            <OverviewTable.Head>
              <OverviewTable.HeaderCell width="29%">
                {t('Type')}
              </OverviewTable.HeaderCell>
              <OverviewTable.HeaderCell width="24%">
                {t('Height')}
              </OverviewTable.HeaderCell>
              <OverviewTable.HeaderCell width="24%">
                {t('Surface')}
              </OverviewTable.HeaderCell>
              <OverviewTable.HeaderCell width="24%">
                {t('Volume')}
              </OverviewTable.HeaderCell>
            </OverviewTable.Head>

            <OverviewTable.Body>
              {homeData.annexes.map((annex, i) => {
                return (
                  <OverviewTable.Row key={i}>
                    <OverviewTable.BodyCell>
                      {t(annex?.type) || {}}
                    </OverviewTable.BodyCell>
                    <OverviewTable.BodyCell>
                      {(Math.round(annex?.height * 100) / 100).toFixed(2)} m
                    </OverviewTable.BodyCell>
                    <OverviewTable.BodyCell>
                      {(Math.round(annex?.surface * 100) / 100).toFixed(2)} m
                      <SuperText>2</SuperText>
                    </OverviewTable.BodyCell>
                    <OverviewTable.BodyCell>
                      {(Math.round(annex?.volume * 100) / 100).toFixed(2)} m
                      <SuperText>3</SuperText>
                    </OverviewTable.BodyCell>
                  </OverviewTable.Row>
                );
              })}
            </OverviewTable.Body>
          </OverviewTable>
        </StyledSection>
      ) : (
        <StyledSection>
          <Section.Title>{t('Annexes')}</Section.Title>
          <StyledMessage>{t('There are no annexes available')}.</StyledMessage>
        </StyledSection>
      )}
    </>
  );
};

const StyledMessage = styled.p`
  margin-top: 1rem;
`;

const StyledSection = styled(Section)`
  margin-top: 0;
`;

const SuperText = styled.sup`
  vertical-align: super;
  font-size: 1rem;
`;

CRMRiskObjectDetailResidenceAnnexes.propTypes = {
  homeData: object,
  disableEditing: bool,
};

export default CRMRiskObjectDetailResidenceAnnexes;
