import React from 'react';
import { object, bool } from 'prop-types';

const ThemePreviewCard = ({ colors, disabled }) => {
  return (
    <svg
      width="152"
      height="102"
      viewBox="0 0 152 102"
      opacity={disabled ? '.5' : '1'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M11.2462 5H140.665C143.054 5 144.912 6.936 144.912 9.224V88.688C144.912 91.064 142.965 92.912 140.665 92.912H11.2462C8.94615 93 7 91.064 7 88.776V9.224C7 6.936 8.94615 5 11.2462 5Z"
          fill="#FBFBFB"
        />
        <path
          d="M24.6923 5H12C9.23858 5 7 7.23858 7 10V88C7 90.7614 9.23858 93 12 93H24.6923V5Z"
          fill={colors.brand.primary}
        />
        <path
          d="M38.8462 47.24C41.289 47.24 43.2692 45.2701 43.2692 42.84C43.2692 40.4099 41.289 38.44 38.8462 38.44C36.4034 38.44 34.4231 40.4099 34.4231 42.84C34.4231 45.2701 36.4034 47.24 38.8462 47.24Z"
          fill="#707070"
        />
        <path
          d="M125.715 45.656H55.4769C53.6192 45.656 52.1154 44.248 52.1154 42.576C52.1154 40.904 53.6192 39.496 55.4769 39.496H125.804C127.662 39.496 129.165 40.904 129.165 42.576C129.165 44.248 127.573 45.656 125.715 45.656Z"
          fill="#D3D4D8"
        />
        <path
          d="M38.8462 64.84C41.289 64.84 43.2692 62.87 43.2692 60.44C43.2692 58.0099 41.289 56.04 38.8462 56.04C36.4034 56.04 34.4231 58.0099 34.4231 60.44C34.4231 62.87 36.4034 64.84 38.8462 64.84Z"
          fill="#707070"
        />
        <path
          d="M125.715 63.256H55.4769C53.6192 63.256 52.1154 61.848 52.1154 60.176C52.1154 58.504 53.6192 57.096 55.4769 57.096H125.804C127.662 57.096 129.165 58.504 129.165 60.176C129.165 61.848 127.573 63.256 125.715 63.256Z"
          fill="#D3D4D8"
        />
        <path
          d="M38.8462 82.44C41.289 82.44 43.2692 80.4701 43.2692 78.04C43.2692 75.6099 41.289 73.64 38.8462 73.64C36.4034 73.64 34.4231 75.6099 34.4231 78.04C34.4231 80.4701 36.4034 82.44 38.8462 82.44Z"
          fill="#707070"
        />
        <path
          d="M125.715 80.856H55.4769C53.6192 80.856 52.1154 79.448 52.1154 77.776C52.1154 76.104 53.6192 74.696 55.4769 74.696H125.804C127.662 74.696 129.165 76.104 129.165 77.776C129.165 79.448 127.573 80.856 125.715 80.856Z"
          fill="#D3D4D8"
        />
        <path
          d="M106.077 15.56H127.75C128.281 15.56 128.635 15.912 128.635 16.44V21.72C128.635 22.248 128.281 22.6 127.75 22.6H106.077C105.546 22.6 105.192 22.248 105.192 21.72V16.44C105.192 15.912 105.546 15.56 106.077 15.56Z"
          fill={colors.brand.lighter}
        />
        <path
          d="M35.3077 15.56H94.5769C95.1077 15.56 95.4616 15.912 95.4616 16.44V21.72C95.4616 22.248 95.1077 22.6 94.5769 22.6H35.3077C34.7769 22.6 34.4231 22.248 34.4231 21.72V16.44C34.4231 15.912 34.7769 15.56 35.3077 15.56Z"
          fill="#292929"
        />
      </g>
    </svg>
  );
};

ThemePreviewCard.propTypes = {
  disabled: false,
};

ThemePreviewCard.propTypes = {
  colors: object.isRequired,
  disabled: bool,
};

export default ThemePreviewCard;
