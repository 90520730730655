import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import ChatBox from '../../../../components/ChatBox';
import {
  getNextPath,
  showErrorPopup,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { clearAnswer } from '../store';

const HomeQuestion = ({
  questionNumber,
  history,
  dataTestId1,
  dataTestId2,
}) => {
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [sendStep, setSendStep] = useState(`initial`); // 'initial' | 'pending' | 'success' | 'error'
  clearAnswer(questionNumber + 1);
  const [content, setContent] = useState(
    t(`${getComponentName()}`, { returnObjects: true }),
  );
  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
    if (content === t(`${getComponentName()}`, { returnObjects: true })) {
      const newContent = t(`${getComponentName()}`, { returnObjects: true });
      setContent(newContent);
    }
  }, []);

  useEffect(() => {
    switch (sendStep) {
      case 'pending':
        setDisabled(true);
        break;
      case 'success':
        setDisabled(true);
        history.push(getNextPath('a'));
        break;
      case 'error':
        showErrorPopup(true);
        setDisabled(false);
        break;
      default:
        setDisabled(false);
        break;
    }
  }, [sendStep]);

  const handleAnswer = answer => {
    if (answer === content.answers[0].title) {
      addChatboxAnswer(content.answers[0].response);
    } else {
      addChatboxAnswer(content.answers[1].response);
    }
    addChatboxQuestion(content.question);
    setSendStep('success');
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          to={getNextPath('a')}
          Answer1={content.answers[0].title}
          Answer2={content.answers[1].title}
          dataTestId1={dataTestId1}
          dataTestId2={dataTestId2}
          noLink={getNextPath('a')}
          disabled={disabled}
          response={answer => handleAnswer(answer)}
        >
          {content.question}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const MessageContainer = styled.div`
  display: flex;
  width: 80%;
  height: 70%;
  margin-top: -15%;
  justify-content: flex-end;
  flex-direction: column;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  overflow: hidden;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div {
    z-index: 10;
  }

  & ${MessageContainer} {
    z-index: 8;
  }
`;

HomeQuestion.propTypes = {
  questionNumber: number.isRequired,
  history: shape().isRequired,
  dataTestId1: string,
  dataTestId2: string,
};

export default withRouter(HomeQuestion);
