/* stylelint-disable */
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { shape, bool } from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router';

import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import { getBrokerDistribution } from '../../../services/brokerDataService';

const TopNavigationBar = ({ history, hideSidebar }) => {
  const [logo, setLogo] = useState('');
  const { navTabs } = useContext(TopNavigationItems);

  useEffect(() => {
    initializeBrokerLogo();
  }, []);

  async function initializeBrokerLogo() {
    if (!hideSidebar) {
      const { links } = (await getBrokerDistribution()) || {};
      const [logoObject] = links?.filter(({ rel }) => rel === 'logo') || [];
      const { href: logoUrl } = logoObject || {};
      setLogo(logoUrl);
    } else {
      setLogo(
        'https://storage.googleapis.com/broker_avatars/logos/distributions/f9dbe526-cf92-46cf-bdf3-99aba3baebbf/logo/callant-logo.png?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=broker-api-avatars%40sapient-tracer-168309.iam.gserviceaccount.com%2F20200525%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20200525T014303Z&X-Goog-Expires=604800&X-Goog-SignedHeaders=host&X-Goog-Signature=147803d032f6375cd4d3a7b248e92139d5f21aa9e465fe417d3abb44b89b40653d20683549bd033f9acb61435f4d9e2d7104388cdf23a29700b7ca8df1de794c9f3a6ca8d524dea8de66f516f199e98134e0467540f272620258abc3c56356bace11ce4c4155fb642bf71244a223ce02d3cad04ced155374a8b8b64d563661260d4b38df43356cd34207f6a3408cbfadd644239b322b854151852c3f8327b83c5c407d870d23fd4aba3880eaebd40aad4dff895cf5a6bc99fa8a40488e73714c7af340a47e728b361e13b27a18847ce8a9b980088edea173cc6629b6c24368d0a2a82c420b8919b8e487169c7157597dba07299d7b5521011522cbad2e9cadef',
      );
    }
  }

  return (
    <Container>
      <LogoContainer>
        <Logo src={logo} alt="WeGroup" />
      </LogoContainer>
      <Navigation>
        {navTabs.map(tab => {
          return (
            <NavItem
              onClick={() =>
                history.push({
                  pathname: tab.to,
                  state: tab.state,
                })
              }
              key={tab.title}
              // eslint-disable-next-line react-hooks/rules-of-hooks
              active={useRouteMatch({
                path: tab.to,
                exact: true,
              })}
              data-test-id={tab.testId}
            >
              {tab.title}
            </NavItem>
          );
        })}
      </Navigation>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;
  width: 100%;
  position: fixed;
  top: 0;
  border-bottom: 0.2px solid #e2e2e2;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 11;
  background-color: white;
`;

const NavItem = styled.li`
  transition: all 0.2s;
  cursor: pointer;
  margin: 0 3rem;
  font-size: 1.6rem;
  padding: 2.5rem 2rem 2rem;
  display: flex;
  align-items: flex-end;
  font-family: ${({ theme }) => theme.font};
  border-bottom-width: 3px;
  border-bottom-color: ${({ theme, active }) =>
    active ? theme.brand.primary : `rgba(0, 0, 0, 0)`};
  border-bottom-style: solid;
  font-weight: ${props => (props.active === true ? `bold` : 'none')};
  color: ${({ theme }) => theme.typo.highlight};

  @media screen and (max-width: 1250px) {
    font-size: 1.4rem;
    padding: 2rem 1rem;
  }
`;

const Navigation = styled.ul`
  display: flex;
  margin: 0 auto;
  align-self: flex-end;
`;

const LogoContainer = styled.div`
  height: 100%;
  width: 25rem; /* same as navigation bar on the left */
  text-align: center;
  padding-bottom: 1.8rem;
  padding-top: 2rem;
`;

const Logo = styled.img`
  height: 100%;
`;

TopNavigationBar.propTypes = {
  history: shape().isRequired,
  match: shape(),
  hideSidebar: bool,
};

export default withRouter(TopNavigationBar);
