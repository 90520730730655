import React from 'react';
import { string } from 'prop-types';

const attention_assist = ({ className, color }) => (
  <svg viewBox="0 0 303.24 400" className={className}>
    <g fill={color}>
      <path d="m56.53 131.06a13 13 0 0 1 -11.33-6.55l-11.69-20.35a36.56 36.56 0 0 1 13.49-49.85 10.42 10.42 0 0 0 3.83-14.21l-11.68-20.35a13 13 0 1 1 22.63-13l11.69 20.35a36.51 36.51 0 0 1 -13.47 49.9 10.42 10.42 0 0 0 -3.84 14.21l11.68 20.29a13.06 13.06 0 0 1 -11.31 19.56z" />
      <path d="m120.52 131.06a13 13 0 0 1 -11.33-6.55l-11.69-20.35a36.56 36.56 0 0 1 13.5-49.85 10.42 10.42 0 0 0 3.84-14.21l-11.7-20.35a13.05 13.05 0 1 1 22.64-13l11.68 20.35a36.51 36.51 0 0 1 -13.46 49.9 10.42 10.42 0 0 0 -3.84 14.21l11.69 20.34a13.06 13.06 0 0 1 -11.31 19.56z" />
      <path d="m184.51 131.06a13 13 0 0 1 -11.33-6.55l-11.68-20.35a36.55 36.55 0 0 1 13.5-49.85 10.39 10.39 0 0 0 3.84-14.21l-11.71-20.35a13.05 13.05 0 1 1 22.64-13l11.68 20.35a36.51 36.51 0 0 1 -13.45 49.9 10.43 10.43 0 0 0 -3.85 14.21l11.69 20.34a13.06 13.06 0 0 1 -11.31 19.56z" />
      <path d="m260.07 211.65h-25.07v-46.65h-235v117.52a117.48 117.48 0 0 0 117.48 117.48 117.49 117.49 0 0 0 116.44-102h26.15a43.18 43.18 0 0 0 0-86.35zm0 60.24h-25.07v-34.14h25.11a17.07 17.07 0 0 1 0 34.14z" />
    </g>
  </svg>
);

attention_assist.propTypes = {
  className: string,
  color: string,
};

export default attention_assist;
