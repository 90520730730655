import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { node } from 'prop-types';
import {
  getBrokersByDistributionId,
  retrieveAvailableAffiliations,
  retrieveCampaignsByDistribution,
} from '../../../../js/services/apiRouterService';
import {
  getBrokerData,
  getBrokerDistribution,
} from '../../../../js/services/brokerDataService';

const CampaignStateContext = createContext();
const CampaignDispatchContext = createContext();

const initialState = {
  campaigns: {
    email: [],
    website: [],
    distribution: {},
    brokers: {},
    affiliations: {},
  },
  campaignsError: null,
  isCampaignsLoading: true,
  shouldFetch: true,
};

function storeReducer(state, action) {
  switch (action.type) {
    case 'FETCHING_CAMPAIGNS': {
      return { ...state, loadingCampaigns: true, shouldFetch: false };
    }
    case 'RELOAD_CAMPAIGNS': {
      return { ...state, shouldFetch: true };
    }
    case 'SET_CAMPAIGNS': {
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          email: action.payload.email,
          website: action.payload.website,
          distribution: action.payload.distribution,
          brokers: action.payload.brokers,
          affiliations: action.payload.affiliations,
        },
        campaignsError: null,
        isCampaignsLoading: false,
        shouldFetch: false,
      };
    }
    case 'FAILED_TO_FETCH_CAMPAIGNS': {
      return {
        ...state,
        campaigns: {
          email: [],
          website: [],
          distribution: {},
          brokers: {},
          affiliations: {},
        },
        campaignsError: 'Failed to fetch campaigns',
        isCampaignsLoading: false,
        shouldReload: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CampaignProvider({ children }) {
  const [store, dispatch] = useReducer(storeReducer, initialState);

  useEffect(() => {
    (async () => {
      if (!store?.shouldFetch) return;
      dispatch({
        type: 'FETCHING_CAMPAIGNS',
      });
      const { distribution_id: distributionId } = await getBrokerData();
      const [emailResp, emailStatus] = await retrieveCampaignsByDistribution(
        distributionId,
        'EMAIL',
      );
      const [
        websiteResp,
        websiteStatus,
      ] = await retrieveCampaignsByDistribution(distributionId, 'WEBSITE');

      const distribution = await getBrokerDistribution();
      const [brokersResp, brokersStatus] = await getBrokersByDistributionId(
        distributionId,
      );
      const [
        affiliationsResp,
        affiliationsStatus,
      ] = await retrieveAvailableAffiliations();

      const statuses = [
        emailStatus,
        websiteStatus,
        brokersStatus,
        affiliationsStatus,
      ];

      if (statuses.every(status => status === 200)) {
        dispatch({
          type: 'SET_CAMPAIGNS',
          payload: {
            email: emailResp.items,
            website: websiteResp.items,
            distribution: distribution,
            brokers: brokersResp,
            affiliations: affiliationsResp.items,
          },
        });
      } else {
        dispatch({ type: 'FAILED_TO_FETCH_CAMPAIGNS' });
      }
    })();
  }, [store.shouldFetch]);

  return (
    <CampaignStateContext.Provider value={store}>
      <CampaignDispatchContext.Provider value={dispatch}>
        {children}
      </CampaignDispatchContext.Provider>
    </CampaignStateContext.Provider>
  );
}

function useCampaignStore() {
  const context = useContext(CampaignStateContext);
  if (context === undefined) {
    throw new Error('useCampaignStore must be used within a CampaignProvider');
  }
  return context;
}

function useCampaignById(_id) {
  const context = useContext(CampaignStateContext);
  if (context === undefined) {
    throw new Error('useCampaignStore must be used within a CampaignProvider');
  }

  const { email, website } = context?.campaigns || {};
  const [emailByid] = email?.filter(({ id }) => _id === id);
  const [websiteByid] = website?.filter(({ id }) => _id === id);

  return emailByid || websiteByid;
}

function useCampaignDispatch() {
  const context = useContext(CampaignDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCampaignDispatch must be used within a CampaignProvider',
    );
  }
  return context;
}

CampaignProvider.propTypes = {
  children: node,
};

export {
  CampaignProvider,
  useCampaignStore,
  useCampaignById,
  useCampaignDispatch,
};
