import React from 'react';
import { string } from 'prop-types';

const IndirectRisk = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 275.08 83.96">
    <title>{title}</title>
    <g id="icons">
      <path
        fill={color}
        d="M105.85.78H93.24l-25.4,21.6,4.86,6.67a4.76,4.76,0,0,0,1.71,1.39,5.67,5.67,0,0,0,4.23.31,5,5,0,0,0,1.83-1l9.77-8.17c0,1.19-.06,2.36-.06,3.47V70.72H74V83h46.3V70.72h-14.4Z"
      />
      <path
        fill={color}
        d="M178.1,10.35h0a26.3,26.3,0,0,0-9.73-7.8A29,29,0,0,0,156.3,0a28.43,28.43,0,0,0-12,2.56,26.09,26.09,0,0,0-9.65,7.79,38.52,38.52,0,0,0-6.32,13.08,67.79,67.79,0,0,0-2.25,18.49,67.89,67.89,0,0,0,2.25,18.47,38.36,38.36,0,0,0,6.32,13.1,26,26,0,0,0,9.65,7.8,28.39,28.39,0,0,0,12,2.56,28.75,28.75,0,0,0,12.07-2.56,26.17,26.17,0,0,0,9.73-7.79,38.56,38.56,0,0,0,6.38-13.11,67,67,0,0,0,2.28-18.47,67.07,67.07,0,0,0-2.28-18.5A38.62,38.62,0,0,0,178.1,10.35ZM169.39,56.1a27.88,27.88,0,0,1-3.28,8.77,11.5,11.5,0,0,1-4.49,4.37,11.77,11.77,0,0,1-10.55,0,11.3,11.3,0,0,1-4.44-4.36,28.38,28.38,0,0,1-3.23-8.77,68.72,68.72,0,0,1-1.22-14.19,68.62,68.62,0,0,1,1.22-14.18A28.28,28.28,0,0,1,146.63,19a11.3,11.3,0,0,1,4.44-4.36,11.61,11.61,0,0,1,5.23-1.26,11.92,11.92,0,0,1,5.32,1.26A11.43,11.43,0,0,1,166.11,19a27.91,27.91,0,0,1,3.28,8.76,67.08,67.08,0,0,1,1.25,14.18,67,67,0,0,1-1.25,14.18Z"
      />
      <path
        fill={color}
        d="M202.6,40.58A20.28,20.28,0,0,0,210.34,42a19.47,19.47,0,0,0,7.42-1.46A18.8,18.8,0,0,0,224,36.4a20.46,20.46,0,0,0,4.29-6.55,22.27,22.27,0,0,0,1.6-8.58,25.41,25.41,0,0,0-1.45-8.77,19.3,19.3,0,0,0-4.09-6.71,18.31,18.31,0,0,0-6.24-4.25,21.11,21.11,0,0,0-15.53,0,18.11,18.11,0,0,0-6.18,4.25,19.34,19.34,0,0,0-4.06,6.72,25.27,25.27,0,0,0-1.45,8.76,24.15,24.15,0,0,0,1.45,8.55,19.4,19.4,0,0,0,4.06,6.56A17.84,17.84,0,0,0,202.6,40.58ZM205.54,13a4.94,4.94,0,0,1,2.08-1.61,7.67,7.67,0,0,1,5.48,0A5.12,5.12,0,0,1,215.25,13a8.87,8.87,0,0,1,1.5,3.13,19.19,19.19,0,0,1,.57,5.1,17.63,17.63,0,0,1-.57,4.89,8.28,8.28,0,0,1-1.49,3,5.11,5.11,0,0,1-2.14,1.54,8.06,8.06,0,0,1-5.52,0,5,5,0,0,1-2.08-1.53,8.28,8.28,0,0,1-1.44-3,18.78,18.78,0,0,1-.54-4.91,20.23,20.23,0,0,1,.54-5.11A8.44,8.44,0,0,1,205.54,13Z"
      />
      <path
        fill={color}
        d="M273.64,54.37a19.26,19.26,0,0,0-4.09-6.71,18.31,18.31,0,0,0-6.24-4.25,20.16,20.16,0,0,0-7.78-1.49,19.93,19.93,0,0,0-7.75,1.49,18,18,0,0,0-6.18,4.25,19.3,19.3,0,0,0-4.06,6.71,25.35,25.35,0,0,0-1.45,8.76,24.11,24.11,0,0,0,1.45,8.55,19.19,19.19,0,0,0,4.06,6.57,18.18,18.18,0,0,0,6.18,4.22A20,20,0,0,0,255.53,84a19.26,19.26,0,0,0,13.67-5.7,20.33,20.33,0,0,0,4.28-6.55,22,22,0,0,0,1.6-8.58A25.35,25.35,0,0,0,273.64,54.37Zm-13.19,16.7a5.24,5.24,0,0,1-2.14,1.54,8.06,8.06,0,0,1-5.52,0,4.71,4.71,0,0,1-2-1.52,8.65,8.65,0,0,1-1.46-3,18.39,18.39,0,0,1-.57-4.93,19.2,19.2,0,0,1,.58-5.1,9.33,9.33,0,0,1,1.46-3.15,4.81,4.81,0,0,1,2.05-1.59,7.16,7.16,0,0,1,2.72-.51,7.4,7.4,0,0,1,2.76.51,5.19,5.19,0,0,1,2.14,1.61A9.2,9.2,0,0,1,262,58a18.72,18.72,0,0,1,.59,5.09,17.56,17.56,0,0,1-.59,4.92A8.53,8.53,0,0,1,260.45,71.07Z"
      />
      <path
        fill={color}
        d="M260,.89a5.93,5.93,0,0,0-3.32.83,8.07,8.07,0,0,0-1.9,1.72L194,83h12.21a6,6,0,0,0,3-.71,6.55,6.55,0,0,0,2-1.73L271.9.89Z"
      />
      <path
        fill={color}
        d="M55.32,44.11l-15.53,0,0-15.51a8.1,8.1,0,0,0-16.2,0l0,15.51-15.51,0a8.1,8.1,0,0,0,0,16.2l15.52,0,0,15.51A8.09,8.09,0,0,0,31.75,84h.94l.15-.08a8.09,8.09,0,0,0,7-8l0-15.51,15.51,0a8.1,8.1,0,0,0,0-16.2Z"
      />
    </g>
  </svg>
);

IndirectRisk.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

IndirectRisk.propTypes = {
  title: string,
  color: string,
};

export default IndirectRisk;
