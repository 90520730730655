import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { number, string } from 'prop-types';

import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import ChatBox from '../../../../components/ChatBox';

import {
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

import { addAnswer, clearAnswer, updateTrait } from '../store';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

const FamilyQuestion = ({ questionNumber, dataTestId1, dataTestId2 }) => {
  const { t } = useTranslation();
  const location = useLocation();
  clearAnswer(questionNumber + 1);
  const [content, setContent] = useState(
    t(`${getComponentName()}`, { returnObjects: true }),
  );

  useEffect(() => {
    addBrowserHistory(location.pathname);
    if (content === t(`${getComponentName()}`, { returnObjects: true })) {
      const newContent = t(`${getComponentName()}`, { returnObjects: true });
      setContent(newContent);
    }
  }, []);

  const handleAnswer = answer => {
    addChatboxAnswer(answer);
    addChatboxQuestion(content.question);

    if (answer === content.answers[0].title) {
      addAnswer(content.answers[0].response, content.key, true);
      if (questionNumber === 3)
        updateTrait('living_less_than_one_year_together', false);
      addChatboxAnswer(content.answers[0].response);
    } else {
      if (questionNumber === 0) updateTrait('students', false);
      addAnswer(content.answers[1].response, content.key, false);
      if (questionNumber === 3)
        updateTrait('living_less_than_one_year_together', true);
      addChatboxAnswer(content.answers[1].response);
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          to={getNextPath()}
          Answer1={content.answers[0].title}
          Answer2={content.answers[1].title}
          tooltip={content.tooltip}
          dataTestId1={dataTestId1}
          dataTestId2={dataTestId2}
          noLink={
            questionNumber === 0 || questionNumber === 2
              ? getNextPath('b')
              : null
          }
          disabled={false}
          response={answer => handleAnswer(answer)}
        >
          {content.question}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  overflow: hidden;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;

FamilyQuestion.propTypes = {
  questionNumber: number.isRequired,
  dataTestId1: string,
  dataTestId2: string,
};

export default FamilyQuestion;
