import React from 'react';
import { string } from 'prop-types';

const poolIcon = ({ color }) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.6112 6.18678C22.3536 6.18678 22.1444 6.39675 22.1444 6.65527V8.73917C20.0709 9.74898 17.4437 10.2603 14.808 9.98037C13.2069 9.81183 11.7126 9.42333 10.2368 9.00483V8.81201C10.2368 8.81058 10.2382 8.81058 10.2382 8.80916C10.2382 8.80773 10.2368 8.80773 10.2368 8.8063V6.95664C10.2368 6.95521 10.2382 6.95521 10.2382 6.95378C10.2382 6.95236 10.2368 6.95236 10.2368 6.95093V5.10127C10.2368 5.09984 10.2382 5.09984 10.2382 5.09842C10.2382 5.09699 10.2368 5.09699 10.2368 5.09556V2.89882C10.2368 1.44481 9.05701 0.260742 7.60965 0.260742C7.16136 0.260742 6.74437 0.385005 6.37435 0.583539C6.59921 0.803499 6.78137 1.06202 6.93507 1.34054C7.14285 1.25056 7.36914 1.19628 7.60823 1.19628C8.54182 1.19628 9.30179 1.96043 9.30179 2.8974V4.63136H6.75006V2.89882C6.75006 1.44481 5.57026 0.260742 4.12148 0.260742C2.6727 0.260742 1.4929 1.44481 1.4929 2.89882C1.4929 3.15735 1.7021 3.36731 1.95969 3.36731C2.21729 3.36731 2.42649 3.15878 2.42649 2.89882C2.42649 1.96043 3.18646 1.19771 4.12148 1.19771C5.05507 1.19771 5.81504 1.96185 5.81504 2.89882V7.85362C5.27282 7.74507 4.72348 7.65223 4.1599 7.59224C3.00572 7.4694 1.91842 7.52082 0.933595 7.62937V6.65527C0.933595 6.39675 0.72439 6.18678 0.466797 6.18678C0.209205 6.18678 0 6.39675 0 6.65527V14.9752C0 15.2337 0.209205 15.4436 0.466797 15.4436H22.6112C22.8688 15.4436 23.078 15.2337 23.078 14.9752V6.65527C23.078 6.39675 22.8674 6.18678 22.6112 6.18678ZM6.75006 5.5669H9.30322V6.48673H6.75006V5.5669ZM6.75006 7.42227H9.30322V8.3421H7.88005C7.50576 8.23926 7.13004 8.14214 6.75006 8.05215V7.42227Z"
      fill={color}
    />
  </svg>
);

poolIcon.defaultProps = {
  color: `white`,
};

poolIcon.propTypes = {
  color: string,
};

export default poolIcon;
