import React, { useState, useEffect, useRef } from 'react';
import i18n from 'i18next';
import styled, { css } from 'styled-components';
import { shape, string, func, array } from 'prop-types';
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';

import { H4, Body, Tooltip } from 'wg-fe-ui';
import QuoteGuaranteeIcons from './QuoteGuaranteeIcons.jsx';

const OfferedGuarantee = ({
  guarantee,
  handleChange,
  chosenGuarantees,
  insuranceType,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const canClick = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (chosenGuarantees[insuranceType]?.includes(guarantee.tag)) {
      setIsSelected(true);
    } else if (!chosenGuarantees[insuranceType]?.includes(guarantee.tag)) {
      setIsSelected(false);
    }
  }, [chosenGuarantees]);

  return (
    <StyledDiv
      className="offeredGuarantee"
      onClick={() => {
        if (guarantee.mandatory) {
          if (canClick.current) {
            cogoToast.info(t('This guarantee cannot be unchecked'));
            canClick.current = false;
            setTimeout(() => (canClick.current = true), 3000);
          }
        } else {
          handleChange({
            name: guarantee.tag,
            addOption: !isSelected,
            insuranceType,
          });
        }
      }}
      mandatory={guarantee.mandatory}
      checked={isSelected}
    >
      <GuaranteeHeader mandatory={guarantee.mandatory}>
        <QuoteGuaranteeIcons
          type={guarantee.adjustedName ? guarantee.adjustedName : guarantee.tag}
          color={guarantee.mandatory ? '#8990a3' : '#050505'}
        />
        <StyledH4>{guarantee?.title?.[i18n.language]}</StyledH4>
        {guarantee.mandatory && (
          <StyledTooltip toggleIcon="!">
            {t('This guarantee cannot be unchecked')}
          </StyledTooltip>
        )}
      </GuaranteeHeader>
      <StyledBody light>{guarantee?.description?.[i18n.language]}</StyledBody>
    </StyledDiv>
  );
};

const StyledH4 = styled(H4)`
  font-size: 1.4rem;
`;

const StyledTooltip = styled(Tooltip)`
  div {
    margin-left: 1rem;
    width: 23rem;
  }
`;

const GuaranteeHeader = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    width: 2.5rem;
  }

  & > *:nth-child(2) {
    margin-left: 1rem;
    flex-grow: 1;
  }

  h4 {
    flex: 0;
    ${({ mandatory }) => mandatory && `color: #8990a3`}
  }

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e4e4e4;
`;

const StyledBody = styled(Body)`
  margin-top: 0.5rem;
  font-size: 1.3rem;

  @media screen and (min-width: 1460px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1120px) {
    font-size: 1.4rem;
  }
`;

const StyledDiv = styled.div`
  background-color: white;
  /* Border is increased by 1px on checked, padding has to be 1px lower, otherwise elements move */
  padding: ${({ checked }) => (checked ? '15px 19px' : '16px 20px')};
  display: flex;
  flex-direction: column;
  border: ${({ checked, theme }) =>
    checked ? `2px solid ${theme.brand.primary}` : '1px solid #f0f1f3'};
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: 18.5rem;

  ${({ mandatory }) =>
    !mandatory &&
    css`
      :hover {
        transition: 0.2s ease box-shadow;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
          0 16px 24px rgba(0, 0, 0, 0.06);
      }
    `}

  @media screen and (max-width: 1460px) {
    height: 19rem;
  }

  @media screen and (max-width: 760px) {
    height: 19.5rem;
  }

  @media screen and (max-width: 660px) {
    height: 15rem;
  }
`;

OfferedGuarantee.propTypes = {
  guarantee: shape({}),
  handleChange: func,
  chosenGuarantees: array,
  insuranceType: string,
};

export default OfferedGuarantee;
