import React from 'react';
import { string } from 'prop-types';

const Pets = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="21"
    fill="none"
    viewBox="0 0 29 21"
  >
    <path
      fill={color}
      d="M19.548.904c-.058-.035-.105-.082-.164-.117-1.173-.81-2.65-.858-3.917-.212a2.306 2.306 0 01-1.032.247c-.363 0-.715-.094-1.032-.247C12.15-.07 10.66-.012 9.487.787c-.012.012-.024.012-.035.023L6.966 12.86c.351.682.691 1.375.961 2.044 1.032 2.537 1.724 5.368 3.471 5.99.551.2 1.806-.2 2.498-.446.305-.106.633-.106.938.012.716.282 2.064.728 2.627.446 1.935-.951 2.568-3.453 3.6-6.002.281-.693.633-1.397.973-2.102L19.548.904zm-8.197 8.88a1.017 1.017 0 01-1.008-1.01c0-.553.457-1.01 1.008-1.01.551 0 1.009.457 1.009 1.01 0 .563-.446 1.01-1.009 1.01zm2.557 7.34V15.07a2.1 2.1 0 01-1.49-2.009.39.39 0 01.118-.282.41.41 0 01.281-.117h3.366c.223 0 .41.188.41.411 0 .576-.234 1.092-.61 1.48a2.032 2.032 0 01-.89.529v2.043c0 .329-.27.6-.599.6a.599.599 0 01-.586-.6zm3.729-7.34a1.017 1.017 0 01-1.009-1.01c0-.553.458-1.01 1.009-1.01.551 0 1.008.457 1.008 1.01 0 .563-.445 1.01-1.008 1.01zM24.497 12.508l4.386-5.52a.53.53 0 00-.059-.728L22.011.14c-.387-.34-.985 0-.88.506l2.428 11.64a.532.532 0 00.938.222zM4.503 12.51L.117 6.99a.53.53 0 01.059-.729L6.989.142c.387-.34.985 0 .88.505L5.44 12.287a.532.532 0 01-.938.222z"
    />
  </svg>
);

Pets.propTypes = {
  color: string.isRequired,
};

export default Pets;
