/* eslint-disable camelcase */
import ApiRoutes from '../../../../store/ApiRoutes';
import { getSubStep } from '../../../../store';
import { retrieveAutomobileByCar } from '../../../../services/apiRouterService';

const api = new ApiRoutes();

const answers = {};
let manual = false;

let data = {
  vehicle_status: null,
  vehicle_type: null,
  primary_driver: null,
  license_plate: ``,
  km_per_year: null,
  replacement_after_accident: null,
  vehicle_usage: null,
  car_loan: null,
  already_insured_with: null,
  previous_insurance_start_date: null,
};

const editedCarExtra = [];

let carData = {
  registration_first: ``,
  value: null,
  invoice_value: null,
  options_value: null,
  vehicle_status: null,
  vehicle_type: null,
  is_sportscar: false,
  is_cabrio: false,
  is_jeep: false,
};

const carDataById = {};

const dataById = {};

const addEditedCarExtra = value => {
  if (!editedCarExtra.includes(value)) {
    editedCarExtra.push(value);
  }
};

const addDataForIdCar = id => {
  dataById[id] = JSON.parse(JSON.stringify(data));
  carDataById[id] = JSON.parse(JSON.stringify(carData));
};

const getSingleTrait = key => {
  if (dataById[sessionStorage.getItem('id')]) {
    return dataById[sessionStorage.getItem('id')][key];
  } else {
    return data[key];
  }
};

const resetCarData = () => {
  carDataById[sessionStorage.getItem('id')] = {
    registration_first: ``,
    value: null,
    invoice_value: null,
    options_value: null,
    vehicle_status: null,
    vehicle_type: null,
  };
};

const setInsuredData = (key, value) => {
  const id = sessionStorage.getItem('id');
  if (value === false) {
    if (dataById[id].hasOwnProperty(key)) {
      delete dataById[id][key];
    }
    if (key === 'already_insured_with') {
      if (dataById[id].hasOwnProperty('previous_insurance_start_date')) {
        delete dataById[id].previous_insurance_start_date;
      }
    }
  } else if (key === 'previous_insurance_start_date' && value === null) {
    delete dataById[id].previous_insurance_start_date;
  } else {
    dataById[id][key] = value;
  }
};

const getInsuredData = () => {
  const id = sessionStorage.getItem('id');
  return {
    already_insured_with: dataById[id]?.already_insured_with,
    previous_insurance_start_date: dataById[id]?.previous_insurance_start_date,
  };
};

let compareCarData;

const setCarData = object => {
  carDataById[sessionStorage.getItem('id')] = object;

  compareCarData = { ...object };
};

const setManual = bool => {
  manual = bool;
};

const getManual = () => manual;

const addCarData = (key, value) => {
  carDataById[sessionStorage.getItem('id')][key] = value;
};

const setCarValue = (key, value) => {
  dataById[sessionStorage.getItem('id')][key] = value;
};

const setPayloadCar = (key, value) => {
  const id = sessionStorage.getItem('id');
  if (data[key] !== undefined) {
    dataById[id][key] = value;
  } else {
    console.error('Data key not found');
  }
};

const getPayloadCar = () => dataById[sessionStorage.getItem('id')];

const getAllCarData = () => carDataById[sessionStorage.getItem('id')];

const getAllAnswers = () => answers;

const addAnswer = answer => {
  answers[getSubStep()] = answer;
};

const removeHigherAnswers = () => {
  Object.keys(answers).forEach(answerKey => {
    if (parseInt(answerKey) >= getSubStep()) {
      delete answers[answerKey];
    }
  });
};

const getCarData = () =>
  api.getCarData(
    JSON.stringify({
      license_plate: dataById[sessionStorage.getItem('id')].license_plate,
    }),
  );

const sendCarData = () => {
  const tempData = JSON.parse(
    JSON.stringify(dataById[sessionStorage.getItem('id')]),
  );
  Object.keys(tempData).forEach(dataKey => {
    if (
      (tempData[dataKey] === null || tempData[dataKey].length === 0) &&
      dataKey !== 'already_insured_with' &&
      dataKey !== 'previous_insurance_start_date'
    ) {
      delete tempData[dataKey];
    }
    if (dataKey === 'primary_driver') {
      if (typeof tempData[dataKey].sameAsPolicy !== undefined) {
        delete tempData[dataKey].sameAsPolicy;
      }
    }
  });
  return api.sendCarData(JSON.stringify(tempData));
};

const sendCarExtraData = () => {
  let validKeys = [
    'registration_first',
    'value',
    'invoice_value',
    'options_value',
    'vehicle_status',
    'vehicle_type',
    'adaptive_cruise_control',
    'attention_assist',
    'automatic_emergency_braking',
    'automatic_parking',
    'blind_spot_check',
    'ecall',
    'lane_support',
    'parking_aid',
    'is_cabrio',
    'is_jeep',
    'is_sportscar',
    'onlyBa',
  ];

  const usedKeys = validKeys.concat(editedCarExtra);

  const tempData = { ...carDataById[sessionStorage.getItem('id')] };
  tempData.invoice_value = parseFloat(tempData.invoice_value);
  tempData.value = parseFloat(tempData.value);
  tempData.options_value = parseFloat(tempData.options_value);

  Object.keys(tempData).forEach(key => {
    return usedKeys.includes(key) || delete tempData[key];
  });

  if (isNaN(tempData['options_value'])) delete tempData['options_value'];
  if (isNaN(tempData['invoice_value'])) delete tempData['invoice_value'];
  if (isNaN(tempData['value'])) delete tempData['value'];

  Object.keys(tempData).forEach(dataKey => {
    if (tempData[dataKey] === null) {
      delete tempData[dataKey];
    }
    if (tempData[dataKey] === 0) {
      delete tempData[dataKey];
    }

    if (tempData[dataKey] === [])
      if (tempData[dataKey].length === 0) {
        delete tempData[dataKey];
      }

    if (compareCarData)
      if (tempData[dataKey] === compareCarData[dataKey])
        delete tempData[dataKey];
  });
  if (tempData.onlyBa) {
    tempData.invoice_value = null;
    tempData.options_value = null;
    tempData.value = null;
  }

  delete tempData.onlyBa;

  if (Object.keys(tempData).length === 0) {
    return new Promise(resolve => resolve({}));
  }

  return api.sendCarExtraData(tempData);
};

const getBrands = () => api.getCarBrands();

const getModels = brand => api.getCarModels(brand);

const getVersions = (brand, model) => api.getCarVersions(brand, model);

const getManualData = ({ brand_id, model_id, version_id }) =>
  retrieveAutomobileByCar(brand_id, model_id, version_id);

export {
  addAnswer,
  addDataForIdCar,
  getAllAnswers,
  setInsuredData,
  getCarData,
  setCarData,
  getAllCarData,
  sendCarData,
  sendCarExtraData,
  addEditedCarExtra,
  getSingleTrait,
  setCarValue,
  getPayloadCar,
  setPayloadCar,
  getInsuredData,
  addCarData,
  removeHigherAnswers,
  resetCarData,
  setManual,
  getManual,
  getBrands,
  getModels,
  getVersions,
  getManualData,
};
