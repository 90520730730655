import React from 'react';
import { shape } from 'prop-types';

const StepIconLock = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.66 53"
  >
    <title>Lock</title>
    <g id="Lock">
      <path
        style={{ fill: `${colors.brand.primary}` }}
        d="M17.38,55c-.12,0-.34,0-.61-.82L8.84,30c-.26-.78-.17-1-.17-1s.1-.17.79-.4l2.62-.86-.59-1.27c-.05-.17-.12-.39-.2-.62a21.6,21.6,0,0,1-1.52-7.15A14.31,14.31,0,0,1,20.34,4.82a13.22,13.22,0,0,1,3.2-.39A14.51,14.51,0,0,1,36.17,12c.31.59.58,1.17.86,1.85l-4.23,1.4a9.88,9.88,0,0,0-9.1-6.06,12,12,0,0,0-1.33.08A9.14,9.14,0,0,0,16,13.41a9.84,9.84,0,0,0-1.38,8.06c.24.86.52,1.71.79,2.55l.36,1.1.32,1.32L17.36,26l9-3h0l14.36-4.71.58-.19a1.85,1.85,0,0,1,.46-.08c.13,0,.32,0,.53.64q1.49,4.47,3,8.94l5.08,15.51c.31.95.17,1-.55,1.27Q33.89,49.64,18,54.85A1.94,1.94,0,0,1,17.38,55Z"
        transform="translate(-7.41 -3.18)"
      />
      <path
        style={{ fill: `${colors.brand.dark}` }}
        d="M23.54,5.68a13.22,13.22,0,0,1,11.52,7c.09.17.18.34.26.51l-1.89.63A11,11,0,0,0,23.7,8a12.25,12.25,0,0,0-1.48.09A10.34,10.34,0,0,0,15,12.72a11.07,11.07,0,0,0-1.54,9.08c.24.9.53,1.77.81,2.61.1.33.21.66.32,1L15.15,28l2.6-.85,7.92-2.6,2.39-.79h0l7.3-2.4L41,19.54l.16-.06.08,0,2.52,7.66.43,1.31.1.28Q46.69,36,49.1,43.32L17.9,53.56Q14,41.64,10.06,29.7l.61-.2.47-.15,2.69-.89L12.67,26c-.07-.19-.13-.39-.2-.58A20.91,20.91,0,0,1,11,18.69,12.89,12.89,0,0,1,20.65,6a12.16,12.16,0,0,1,2.89-.35m0-2.5a14.33,14.33,0,0,0-3.5.43A15.47,15.47,0,0,0,8.52,18.67a22.86,22.86,0,0,0,1.59,7.57l.21.65a.3.3,0,0,0,0,.08l-1.29.42c-1.61.53-2,1.28-1.42,3q4,12.09,7.93,24.18c.38,1.14.93,1.68,1.8,1.68a3.2,3.2,0,0,0,1-.18L50.19,45.59c1.45-.48,1.85-1.33,1.35-2.85L46.59,27.65Q45,23,43.51,18.29c-.34-1-.9-1.5-1.72-1.5a2.84,2.84,0,0,0-.82.13l-.73.24-15.35,5h0L17,24.8l0-.08h0c-.38-1.19-.79-2.37-1.12-3.57-1.32-4.77,2-10,6.71-10.59a9.41,9.41,0,0,1,1.17-.07,8.64,8.64,0,0,1,8.09,5.63.84.84,0,0,0,.77.54,1,1,0,0,0,.24,0l5.08-1.68c.93-.31.43-1.22.43-1.22a23.82,23.82,0,0,0-1-2.26A15.75,15.75,0,0,0,23.54,3.18Z"
        transform="translate(-7.41 -3.18)"
      />
    </g>
  </svg>
);

StepIconLock.propTypes = {
  colors: shape().isRequired,
};

export default StepIconLock;
