import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'wg-fe-ui';
import { retrieveLicensePlateV2 as retrieveLicensePlate } from '../services/apiRouterService';
import { func, string } from 'prop-types';

const LicensePlateSearchInput = ({ onChange, error, ...otherProps }) => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  function handleInputChange(e) {
    const code = e.keyCode || e.which;
    if (code === 13) {
      handleSearch();
    }
    setText(e.currentTarget.value.toUpperCase());
  }

  async function handleSearch() {
    setIsLoading(true);
    const [resp, status] = await retrieveLicensePlate(text);
    await setIsLoading(false);
    if (status !== 200) return;
    const { vehicle } = resp || {};
    if (vehicle?.model == null) {
      vehicle.model = vehicle.series;
    }
    onChange(vehicle);
  }

  return (
    <>
      <Label>{t('Search based on license plate')}</Label>
      <InfoContainer>
        <InfoIcon>i</InfoIcon>
        <p>
          {t(
            'By filling in a number plate, all data about the car is already retrieved Please check if these data are correct before proceeding',
          )}
        </p>
      </InfoContainer>
      <LicenseLookup>
        <LicenseInputContainer error={error}>
          <LicenseInputLetter>B</LicenseInputLetter>
          <LicenseInput
            onChange={handleInputChange}
            onKeyPress={handleInputChange}
            value={text}
            error={error}
            pattern="\d+"
            type="text"
            {...otherProps}
          />
          {error && <ErrorMss>{error}</ErrorMss>}
        </LicenseInputContainer>
        {isLoading ? (
          <StyledActionButton disabled>
            {t('ToolkitInfo.btnText.pending')}{' '}
            <DotH2>
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </DotH2>
          </StyledActionButton>
        ) : (
          <StyledActionButton onClick={handleSearch}>
            {t('Search')}{' '}
          </StyledActionButton>
        )}
      </LicenseLookup>
    </>
  );
};

const ErrorMss = styled.p`
  font-size: 1.2rem;
  color: red;
  position: absolute;
  top: 5rem;
  width: 100%;
  text-align: right;
  margin-top: 1rem;
  flex: 0 0 100%;
`;

const StyledActionButton = styled(ActionButton)`
  width: 20rem;
  padding: 2.4rem 1rem;
  font-size: 1.6rem;
`;

const DotH2 = styled.p`
  > span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    :nth-child(2) {
      animation-delay: 0.2s;
    }
    :nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const InfoIcon = styled.a`
  align-items: center;
  align-self: center;
  background-color: white;
  border: 0.5px solid #8990a3;
  border-radius: 50%;
  color: #8990a3;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  height: 1.6rem;
  width: 1.6rem;
  text-align: center;
`;

const InfoContainer = styled.p`
  color: #8990a3;
  display: flex;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 3rem;
  p {
    margin-left: 1rem;
    width: 75%;
  }
  svg > path {
    fill: #8990a3;
    margin-right: 2rem;
  }
`;

const Label = styled.h3`
  color: #0e0e0e;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
  a {
    background-color: white;
    border: 0.5px solid #8990a3;
    color: #8990a3;
    font-size: 1rem;
    height: 1.6rem;
    line-height: 1.2;
    margin-bottom: 0.2rem;
    width: 1.6rem;
  }
`;

const LicenseLookup = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 100%;
`;

const LicenseInputContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  margin-right: 2rem;
  position: relative;
  flex-wrap: wrap;

  & > div,
  & > input {
    border: 1px solid ${({ error }) => (error ? 'red' : '#ccc')};
  }
`;

const LicenseInputLetter = styled.div`
  align-items: center;
  background-color: #fbfbfb;
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: 0;
  color: #5b5550;
  display: flex;
  flex: 0 0 5rem;
  font-size: 2.5rem;
  font-weight: 900;
  height: 5rem;
  justify-content: center;
  line-height: 4.6rem;
  width: 5rem;
`;

const LicenseInput = styled.input`
  border-radius: 0 0.5rem 0.5rem 0;
  flex: 1 0 auto;
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
  padding: 0 0.5em;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;

  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.brand.primary};
    outline: 0;
  }
`;

LicensePlateSearchInput.propTypes = {
  onChange: func.isRequired,
  error: string,
};

export default LicensePlateSearchInput;
