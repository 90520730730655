import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { node, shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '../../../../services/context/ThemingContext';
import qs from 'query-string';

// data / components
import ProgressBar from '../components/Progressbar/ProgressBar';

// svg
import BrokerBackground from '../../../../../assets/images/bg-broker';

// stores
import {
  getCurrentStep,
  getErrorPopup,
  showErrorPopup,
  getCurrentTheme,
  getSubStep,
} from '../../../../store';
// import logoDiaMuch from '../../../../../assets/images/logos/dia-munich-logo.png';
import {
  getBrokerData,
  getBrokerDistribution,
} from '../../../../services/brokerDataService';

import { LoadingSpinner } from 'wg-fe-ui/dist';
import { useFetchedCampaignData } from '../../../../hooks/useFetchedCampaignData';
import {
  useFlowDispatch,
  useFlowStore,
} from '../../../../services/context/LouiseFlowContext';
import usePrevious from '../../../../hooks/usePrevious';

import { retrieveBrokerId } from '../../../../services/brokerDataService';

import { detect } from 'detect-browser';
import { retrieveCanSellList } from '../../../../services/apiRouterService';
import ChromeDisclaimer from '../../../../components/ChromeDisclaimer';
// import { useParams } from 'react-router';
const App = ({ children, history, className }) => {
  const [campaignData, fetchCampaignData] = useFetchedCampaignData();
  const { setTheme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState(false);

  const flowStore = useFlowStore();
  const flowDispatch = useFlowDispatch();

  const previousTheme = usePrevious(flowStore?.distribution?.theme);
  // const urlParams = useParams();
  const { t } = useTranslation();
  let svgString = encodeURIComponent(
    renderToStaticMarkup(<BrokerBackground colors={getCurrentTheme()} />),
  );

  let dataUri = `url("data:image/svg+xml,${svgString}")`;

  // temporary sections object
  let orientation = null;
  let startOrientation;

  let width = 0;
  let height = 0;

  if (window.innerWidth > window.innerHeight) {
    startOrientation = `landscape`;
    width = window.innerWidth;
    height = window.innerHeight;
    orientation = `landscape`;
  } else {
    height = window.innerWidth;
    width = window.innerHeight;
    orientation = `portrait`;
    startOrientation = `portrait`;
  }
  const viewport = document.querySelector('meta[name=viewport]');
  if (viewport) {
    viewport.setAttribute(
      `content`,
      `height=${height}, width=${width}, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no`,
    );
    window.addEventListener('resize', () => {
      const oldRotation = orientation;
      if (window.innerWidth > window.innerHeight) {
        orientation = `landscape`;
      } else {
        orientation = `portrait`;
      }

      if (oldRotation !== orientation) {
        document.activeElement.blur();
        if (startOrientation === `landscape`) {
          viewport.setAttribute(
            `content`,
            `height=${height}px, width=${width}px, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no`,
          );
        } else {
          viewport.setAttribute(
            `content`,
            `height=${window.innerHeight}px, width=${window.innerWidth}px, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no`,
          );
        }
      }
    });
  }

  useEffect(() => {
    svgString = encodeURIComponent(
      renderToStaticMarkup(<BrokerBackground colors={getCurrentTheme()} />),
    );
    dataUri = `url("data:image/svg+xml,${svgString}")`;
  });

  useEffect(() => {
    const { distribution } = flowStore || {};
    if (!distribution) return;
    const currentTheme = distribution.theme;

    if (currentTheme !== previousTheme) {
      setTheme(currentTheme);
      setIsLoading(false);
    }
  }, [flowStore]);

  useEffect(() => {
    setIsLoading(true);
    initializeFlow();
  }, []);

  useEffect(() => {
    const { status } = campaignData || {};
    if (status === 'fetched') {
      (async () => {
        const {
          logo: campaignLogo,
          name: campaignName,
          theme: campaignTheme,
          insurances,
          extend,
          end_date: endDate,
          active,
        } = campaignData?.data || {};
        const { broker } = (await getBrokerData()) || {};

        const {
          email,
          links,
          name,
          address,
          website,
          cbe,
        } = await getBrokerDistribution();

        const [{ href: avatar }] = await broker?.links?.filter(
          ({ rel }) => rel === 'assistant_avatar',
        );
        const payload = {
          logo: campaignLogo || links.filter(el => el.rel === 'logo')[0]?.href,
          logoFe:
            campaignLogo || links.filter(el => el.rel === 'logo_fe')[0]?.href,
          avatar,
          email,
          name: campaignName || name,
          address,
          website,
          cbe,
          theme: campaignTheme?.name || broker?.theme,
        };

        flowDispatch({
          type: 'SET_DISTRIBUTION_INFO',
          payload,
        });

        const canSellItems = new Set();
        const insuranceEnum = {
          RESIDENCE: 'home',
          CAR: 'car',
          LEGAL: 'legal',
          FAMILY: 'family',
        };
        insurances.forEach(({ insurance_type }) =>
          canSellItems.add(insuranceEnum?.[insurance_type]),
        );

        flowDispatch({ type: 'SET_CAN_SELL', payload: [...canSellItems] });
        flowDispatch({
          type: 'SET_CAMPAIGN_INFO',
          payload: { extend, endDate, active },
        });
      })();
    }
  }, [campaignData]);

  async function initializeFlow() {
    const url = window.location.href;
    const isCampain = url.includes('campaigns');
    if (isCampain) {
      // if the user refreshes the flow go back to login so the campaign info get's reloaded
      if (flowStore.type !== 'campaign') history.replace('/login');

      const { campaignId } = qs.parse(history?.location?.search);
      if (!flowStore.distribution) {
        initializeCampaign(campaignId);
      } else {
        setIsLoading(false);
      }
    } else {
      getBrokerLogo();
    }
  }

  async function initializeCampaign(campaignId) {
    fetchCampaignData(campaignId);
  }

  async function getBrokerLogo() {
    const { broker } = (await getBrokerData()) || {};
    if (!broker) return;
    const {
      email,
      links,
      name,
      address,
      website,
      cbe,
    } = await getBrokerDistribution();

    const [{ href: avatar }] = await broker?.links?.filter(
      ({ rel }) => rel === 'assistant_avatar',
    );

    const payload = {
      logo: links.filter(el => el.rel === 'logo')[0]?.href,
      logoFe: links.filter(el => el.rel === 'logo_fe')[0]?.href,
      avatar,
      email,
      name,
      address,
      website,
      cbe,
      theme: broker?.theme,
    };

    flowDispatch({
      type: 'SET_DISTRIBUTION_INFO',
      payload,
    });

    setIsLoading(false);
    checkCanSell();
  }

  async function checkCanSell() {
    const brokerId = await retrieveBrokerId();
    const [resp, status] = await retrieveCanSellList(brokerId);
    if (status !== 200) return;
    const { items } = resp;
    const insuranceTypes = filterUniqueInsuranceTypes(items);

    flowDispatch({ type: 'SET_CAN_SELL', payload: insuranceTypes });
  }

  function filterUniqueInsuranceTypes(items) {
    const canSellItems = new Set();
    items?.forEach(([, insuranceTypes]) => {
      insuranceTypes.reduce(
        (prevVal, currVall) => prevVal.add(currVall),
        canSellItems,
      );
    });
    return [...canSellItems];
  }
  const handleErrorButton = history => {
    showErrorPopup(false);
    history.push('/');
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Background img={dataUri}>
      {detect().name !== 'chrome' ? <ChromeDisclaimer /> : null}
      {getErrorPopup() && (
        <ErrorPopup>
          <div>
            <p>{t('ErrorPopup.title')}</p>
            <button onClick={() => handleErrorButton(history)}>
              {t('ErrorPopup.button')}
            </button>
          </div>
        </ErrorPopup>
      )}
      <LouiseContainer className={className}>
        <ProgressBar currentStep={getCurrentStep()} subStep={getSubStep()} />
        <ContentContainer>
          <MobileLayover>
            <p>{t('mobileLayover')}</p>
          </MobileLayover>
          {children}
        </ContentContainer>
      </LouiseContainer>
    </Background>
  );
};

const MobileLayover = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & p {
      color: white;
      font-size: 3rem;
      width: 80%;
      text-align: center;
      line-height: 1.25;
    }
  }
`;

const LouiseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: 90%;
  max-width: 1200px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const Background = styled.main`
  background-image: ${props => props.img},
    linear-gradient(
      180deg,
      ${({ theme }) => theme.brand.primary} 45.86%,
      ${({ theme }) => theme.brand.secondary} 100%
    );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.font};
`;

const ErrorPopup = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    background-color: white;
    border-radius: 3rem;
    width: 90%;
    max-width: 60rem;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    font-weight: 900;
    animation: 0.3s fade;

    @keyframes fade {
      from {
        transform: scale(0);
      }

      to {
        transform: scale(1);
      }
    }
  }

  & button {
    background-color: ${({ theme }) => theme.brand.primary};
    width: 22.5rem;
    height: 5.1rem;
    border-radius: 0.5rem;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    color: white;
    border: 0;
    font-weight: bold;
    margin: 1rem;
    text-decoration: none;
    align-self: center;
    margin-bottom: 2rem;
    margin-right: 2rem;
    z-index: 100;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    margin-top: 4rem;

    &:active {
      outline: none;
      transform: scale(0.98);
    }

    &:focus {
      border: 0 0 0;
      outline: none;
    }

    &:hover {
      background-color: ${({ theme }) => theme.brand.lighter};
    }

    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.brand.lighter};
    }

    @media screen and (max-width: 1025px) {
      transform: scale(0.9);
      margin-bottom: 1rem;

      &:active {
        transform: scale(0.88);
      }
    }
  }
`;

const ContentContainer = styled.div`
  background-color: white;
  height: 90%;
  width: 100%;
  border-radius: 1rem;
`;

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: node.isRequired,
  history: shape().isRequired,
  className: string,
};

export default withRouter(App);
