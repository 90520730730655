import React from 'react';
import { shape } from 'prop-types';

const StepIconPeople = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 59 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: `100%`, height: `100%` }}
  >
    <title>Family</title>
    <g id="People">
      <path
        d="M19.8728 26.9044C23.6494 26.5005 27.2549 27.4489 30.2128 29.3617C27.1375 33.2473 25.7779 38.2119 26.2961 43.1253L26.4815 44.8832L5.9899 47.0445L5.73211 44.6004C4.80518 35.8122 11.1122 27.8419 19.8728 26.9044ZM19.8728 26.9044L19.7665 25.9101L19.8728 26.9044Z"
        fill={colors.brand.primary}
        stroke={colors.brand.dark}
        strokeWidth="2"
      />
      <path
        d="M26.9855 13.2086C27.4696 17.7981 24.1415 21.911 19.552 22.3951C14.9625 22.8791 10.8496 19.551 10.3655 14.9615C9.88144 10.3721 13.2095 6.25914 17.799 5.77507C22.3885 5.291 26.5014 8.61909 26.9855 13.2086Z"
        fill={colors.brand.primary}
        stroke={colors.brand.dark}
        strokeWidth="2"
      />
      <path
        d="M57.619 39.6267L57.8248 41.5773L32.3256 44.2668L32.1199 42.3162C31.3773 35.2751 36.4836 28.9645 43.5247 28.2218C50.5658 27.4792 56.8764 32.5856 57.619 39.6267Z"
        fill={colors.brand.primary}
        stroke={colors.brand.dark}
        strokeWidth="2"
      />
      <path
        d="M48.9291 16.2335C49.3133 19.8762 46.6718 23.1408 43.029 23.525C39.3862 23.9092 36.1217 21.2676 35.7374 17.6248C35.3532 13.9821 37.9948 10.7175 41.6376 10.3333C45.2804 9.94909 48.5449 12.5907 48.9291 16.2335Z"
        fill={colors.brand.primary}
        stroke={colors.brand.dark}
        strokeWidth="2"
      />
    </g>
  </svg>
);

StepIconPeople.propTypes = {
  colors: shape().isRequired,
};

export default StepIconPeople;
