import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const LivingRoomSolar = ({ colors }) => {
  const styles = {
    main: {
      height: '100%',
    },
    cls1: {
      fill: `${colors.brand.lightest}`,
    },
    cls2: {
      fill: '#fff',
    },
    cls3: {
      fill: `${colors.brand.lightest}`,
    },
    cls4: {
      fill: `${colors.brand.secondary}`,
    },
    cls5: {
      fill: `${colors.brand.dark}`,
    },
    cls6: {
      fill: `${colors.brand.primary}`,
    },
    cls7: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
    cls8: {
      fill: `${colors[`primary-darkest`]}`,
      opacity: '0.1',
    },
    cls9: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
  };
  return (
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 360 322"
        style={styles.main}
      >
        <title>LivingroomSolar</title>
        <g id="living_kitchen" data-name="living+kitchen">
          <rect
            style={styles.cls1}
            x="1.89"
            y="86.06"
            width="356.42"
            height="234.69"
          />
          <rect
            style={styles.cls2}
            x="82"
            y="153.5"
            width="57.41"
            height="101.8"
          />
          <polygon
            style={styles.cls3}
            points="99.85 153.5 82 187.58 82 169.04 90.14 153.5 99.85 153.5"
          />
          <polygon
            style={styles.cls3}
            points="121.62 153.5 82 229.13 82 205.41 109.19 153.5 121.62 153.5"
          />
          <rect style={styles.cls4} x="82" y="154.5" width="56" height="6" />
          <rect style={styles.cls4} x="132" y="156.5" width="6" height="97" />
          <path
            style={styles.cls5}
            d="M136.41,156.5v95.8H83V156.5h53.41m3-3H80V255.3h59.41V153.5Z"
          />
          <polygon
            style={styles.cls6}
            points="2 85.8 2 2 1.3 1.5 205.83 1.5 359 86.45 2 85.8"
          />
          <line style={styles.cls7} x1="64.5" y1="86" x2="357.5" y2="86" />
          <line style={styles.cls7} x1="50.64" y1="86" x2="1.5" y2="86" />
          <polyline
            style={styles.cls7}
            points="2 1.5 206 1.5 358.5 86 358 320.5"
          />
          <rect style={styles.cls8} x="0.5" y="1.5" width="19.5" height="319" />
          <rect
            style={styles.cls2}
            x="171"
            y="153.5"
            width="113"
            height="101.8"
          />
          <polygon
            style={styles.cls3}
            points="262.9 153.5 209.57 255.3 174.06 255.3 227.39 153.5 262.9 153.5"
          />
          <polygon
            style={styles.cls3}
            points="208.71 153.5 171 225.49 171 201.77 196.29 153.5 208.71 153.5"
          />
          <rect style={styles.cls4} x="174" y="155.5" width="110" height="5" />
          <rect style={styles.cls4} x="222" y="156.5" width="6" height="97" />
          <rect style={styles.cls4} x="277" y="156.5" width="6" height="97" />
          <line style={styles.cls7} x1="228.5" y1="156" x2="228.5" y2="253" />
          <path
            style={styles.cls5}
            d="M282,156.5v95.8H174V156.5H282m3-3H171V255.3H285V153.5Z"
          />
        </g>
        <g id="solar_panels" data-name="solar panels">
          <rect style={styles.cls4} x="19.5" y="18" width="34" height="48" />
          <rect style={styles.cls7} x="20.13" y="18" width="34" height="48" />
          <polyline
            style={styles.cls9}
            points="31.38 18 31.38 33 31.38 50 31.38 66"
          />
          <polyline
            style={styles.cls9}
            points="42.88 18 42.88 33 42.88 50 42.88 66"
          />
          <line style={styles.cls9} x1="54.12" y1="33" x2="20.12" y2="33" />
          <line style={styles.cls9} x1="54.12" y1="50" x2="20.12" y2="50" />
          <rect style={styles.cls4} x="64.5" y="18" width="34" height="48" />
          <rect style={styles.cls7} x="65.13" y="18" width="34" height="48" />
          <polyline
            style={styles.cls9}
            points="76.38 18 76.38 33 76.38 50 76.38 66"
          />
          <polyline
            style={styles.cls9}
            points="87.88 18 87.88 33 87.88 50 87.88 66"
          />
          <line style={styles.cls9} x1="99.12" y1="33" x2="65.12" y2="33" />
          <line style={styles.cls9} x1="99.12" y1="50" x2="65.12" y2="50" />
          <rect style={styles.cls4} x="108.5" y="18" width="34" height="48" />
          <rect style={styles.cls7} x="109.13" y="18" width="34" height="48" />
          <polyline
            style={styles.cls9}
            points="120.38 18 120.38 33 120.38 50 120.38 66"
          />
          <polyline
            style={styles.cls9}
            points="131.88 18 131.88 33 131.88 50 131.88 66"
          />
          <line style={styles.cls9} x1="143.12" y1="33" x2="109.12" y2="33" />
          <line style={styles.cls9} x1="143.12" y1="50" x2="109.12" y2="50" />
        </g>
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  height: 10rem;
  z-index: 2;
  display: relative;
  animation: fadeInLeft 0.5s;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 16%;
  }
`;

LivingRoomSolar.propTypes = {
  colors: shape().isRequired,
};
export default LivingRoomSolar;
