import React, { useState } from 'react';
import 'react-dates/initialize';
import { shape, func, string, node } from 'prop-types';
import { DateRangePicker } from 'react-dates';
import styled from 'styled-components';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import i18next from 'i18next';

const DateFilterComponent = ({
  startDate = null,
  endDate = null,
  name,
  children,
  setStartDate = () => {},
  setEndDate = () => {},
}) => {
  const [inputFocused, setFocusedInput] = useState();
  let locale = 'en-gb';
  switch (i18next.language.toLowerCase()) {
    case 'nl':
      locale = 'nl';
      break;
    case 'fr':
      locale = 'fr';
      break;
    case 'en':
      locale = 'en-gb';
      break;
    default:
      break;
  }
  moment.locale(locale);

  const checkRange = () => false;

  return (
    <Wrapper>
      <Label>{children}</Label>
      <InputWrapper>
        <Labels>
          <LabelItem>{i18next.t('From')}:</LabelItem>
          <LabelItem>{i18next.t('To')}:</LabelItem>
        </Labels>
        <DateRangePicker
          startDate={startDate} // momentPropTypes.momentObj or null,
          startDateId={`${name}Start`} // PropTypes.string.isRequired,
          endDate={endDate} // momentPropTypes.momentObj or null,
          isOutsideRange={checkRange}
          small={true}
          openDirection="up"
          startDatePlaceholderText={i18next.t('Start date')}
          endDatePlaceholderText={i18next.t('End date')}
          showClearDates={true}
          hideKeyboardShortcutsPanel={true}
          initialVisibleMonth={() => moment().subtract(1, 'month')}
          displayFormat="DD/MM/YYYY"
          numberOfMonths={2}
          endDateId={`${name}End`} // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }} // PropTypes.func.isRequired,
          focusedInput={inputFocused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={focusedInput => setFocusedInput(focusedInput)} //
        />
      </InputWrapper>
    </Wrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 1.4rem;
  color: #505050;
  margin-right: 0.5rem;
`;

const LabelItem = styled.div``;

const Wrapper = styled.div`
  & .DateInput_input {
    font-size: 1.4rem;
  }

  & .DateRangePickerInput_arrow {
    display: none;
  }
  & .CalendarDay {
    vertical-align: middle;
  }

  & .DateRangePicker_picker {
    z-index: 999;
  }

  & .DateInput_input__focused {
    border-bottom: 2px solid ${({ theme }) => theme.brand.primary};
  }

  & .CalendarDay__selected {
    background: ${({ theme }) => theme.brand.primary};
    border: 1px double ${({ theme }) => theme.brand.lighter};

    &:hover {
      background: ${({ theme }) => theme.brand.dark};
      border: 1px double ${({ theme }) => theme.brand.lighter};
    }
  }
  & .CalendarDay__hovered_span {
    background: ${({ theme }) => theme.brand.lightest};
    border: 1px double ${({ theme }) => theme.brand.lighter};

    &:hover {
      background: ${({ theme }) => theme.brand.lighter};
      border: 1px double ${({ theme }) => theme.brand.lighter};
    }
  }
  & .CalendarDay__selected_span {
    background: ${({ theme }) => theme.brand.lighter};
    border: 1px double ${({ theme }) => theme.brand.primary};

    &:hover {
      background: ${({ theme }) => theme.brand.dark};
      border: 1px double ${({ theme }) => theme.brand.lighter};
    }
  }
`;

const Label = styled.label`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #505050;
  margin-bottom: 0.5rem;
  display: block;
`;

DateFilterComponent.propTypes = {
  startDate: shape(),
  endDate: shape(),
  name: string.required,
  setStartDate: func,
  setEndDate: func,
  children: node,
};

export default DateFilterComponent;
