import React, { useState, useEffect } from 'react';
import { shape } from 'prop-types';
import Section from './Section';
import styled from 'styled-components';
import License from '../../assets/images/dashboard/car/License';
import Surface from '../../assets/images/dashboard/car/Surface';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryPropertyHouseInformation = ({ propertyInfo }) => {
  const [propertyData, setPropertyData] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    setPropertyData({
      street: propertyInfo.street,
      housenr: propertyInfo.housenr,
      zipcode: propertyInfo.zipcode,
      city: propertyInfo.city,
      trustee: propertyInfo.trustee,
      houseType: propertyInfo.houseType,
      private_garage: propertyInfo.private_garage,
      isOwner: propertyInfo.isOwner,
      age: propertyInfo.age,
      surface: propertyInfo.surface ? propertyInfo.surface : '--',
      parcelArea: propertyInfo.parcelArea ? propertyInfo.parcelArea : '--',
      annexes: propertyInfo.annexes,
      facadeWidth: propertyInfo.facadeWidth ? propertyInfo.facadeWidth : '--',
      habitableSurface: propertyInfo.habitableSurface
        ? propertyInfo.habitableSurface
        : '--',
      buildingHeight: propertyInfo.buildingHeight
        ? propertyInfo.buildingHeight
        : '--',
      distantNeighbor:
        propertyInfo.distantNeighbor !== null
          ? propertyInfo.distantNeighbor
          : '--',
    });
  }, [propertyInfo]);

  return (
    <Section>
      <Section.Subtitle>{t('History.property.title')}</Section.Subtitle>
      <Section.Content data-test-id="dashboard_history_detail_property_section_property_information">
        <Header>
          <Section.Split>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.address.addressInfo.address')} (1)</Label>
              <HeaderText>
                {propertyData.street
                  ? propertyData.street.charAt(0).toUpperCase() +
                    propertyData.street.slice(1)
                  : '--'}{' '}
                {propertyData.housenr}
              </HeaderText>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.address.addressInfo.address')} (2)</Label>
              <HeaderText>
                {propertyData.city
                  ? propertyData.city.charAt(0).toUpperCase() +
                    propertyData.city.slice(1)
                  : '--'}{' '}
                ({propertyData.zipcode})
              </HeaderText>
            </Section.SplitContent>
          </Section.Split>
        </Header>
        <SectionBordered>
          <Section.Split>
            <Section.SplitContent>
              <IconWrapper>
                <IconContainer>
                  <License color={'#CCCCCC'} />
                </IconContainer>
                <div>
                  <Label>
                    {t('ToolkitInfo.address.addressInfo.holderState')}
                  </Label>
                  <Text>
                    {propertyData.isOwner
                      ? t('ToolkitInfo.address.addressInfo.owner')
                      : t('ToolkitInfo.address.addressInfo.tenant')}
                  </Text>
                  <Label>
                    {t('ToolkitInfo.address.addressInfo.building_type')}
                  </Label>
                  <Text>
                    {propertyData.houseType == 'apartment'
                      ? t('AdresCheck.building_types.apartment')
                      : t('ToolkitInfo.address.addressInfo.house_type_house')}
                  </Text>
                  <Label>{t('Storage unit on a different location')}</Label>
                  <Text>
                    {propertyData.private_garage ? t('Yes') : t('No')}
                  </Text>
                </div>
              </IconWrapper>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.address.addressInfo.age')}</Label>
              <Text>
                {propertyData.age
                  ? t('ToolkitInfo.address.age.' + propertyData.age)
                  : '--'}
              </Text>
              <Label>{t('ToolkitInfo.address.addressInfo.adaptation')}</Label>
              <Text>
                {propertyData.houseType == 'apartment'
                  ? t('AdresCheck.building_types.closed')
                  : t('AdresCheck.building_types.' + propertyData.houseType)}
              </Text>
              {propertyData.trustee !== undefined &&
              propertyData.trustee !== null &&
              propertyData.trustee !== '' ? (
                <>
                  <Label>{t('Block policy managed by a trustee')}</Label>
                  <Text>{propertyData.trustee ? t('Yes') : t('No')}</Text>
                </>
              ) : null}
            </Section.SplitContent>
          </Section.Split>
        </SectionBordered>
        <Section.Split>
          <Section.SplitContent>
            <IconWrapper>
              <IconContainer>
                <Surface color={'#CCCCCC'} />
              </IconContainer>
              <div>
                <Label>
                  {t('ToolkitInfo.address.addressInfo.building_ground_surface')}
                </Label>
                <Text>{propertyData.surface} m²</Text>
                <Label>
                  {t('ToolkitInfo.address.addressInfo.parcel_area')}
                </Label>
                <Text>{propertyData.parcelArea} m²</Text>
                <Label>{t('ToolkitInfo.address.addressInfo.annexes')}</Label>
                <Text>
                  {propertyData.annexes ? propertyData.annexes.length : 0}
                </Text>
                <Label>
                  {t('ToolkitInfo.address.addressInfo.facade_width')}
                </Label>
                <Text>{propertyData.facadeWidth} m</Text>
              </div>
            </IconWrapper>
          </Section.SplitContent>
          <Section.SplitContent>
            <Label>
              {t('ToolkitInfo.address.addressInfo.habitable_surface')}
            </Label>
            <Text>{propertyData.habitableSurface} m²</Text>
            <Label>
              {t('ToolkitInfo.address.addressInfo.building_height')}
            </Label>
            <Text>{propertyData.buildingHeight} m</Text>
            <Label>
              {t('ToolkitInfo.address.addressInfo.distance_nearest_neighbour')}
            </Label>
            <Text>{propertyData.distantNeighbor} m</Text>
          </Section.SplitContent>
        </Section.Split>
      </Section.Content>
    </Section>
  );
};

const Label = styled.label`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #a29c95;
`;

const HeaderText = styled.p`
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 1.5;
  color: #050505;
`;

const Text = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #5b5550;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};
`;
const IconWrapper = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const SectionBordered = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};
`;

DashboardBrokerHistoryPropertyHouseInformation.defaultProps = {};

DashboardBrokerHistoryPropertyHouseInformation.propTypes = {
  propertyInfo: shape().isRequired,
};

export default DashboardBrokerHistoryPropertyHouseInformation;
