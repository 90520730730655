export const driverLicenseTypeOptions = [
  { value: 'DEFINITIVE', label: 'Definitive' },
  { value: 'PROVISIONAL', label: 'Provisional' },
];
export const primaryOptions = [
  { value: 'yes', label: 'yes' },
  { value: 'no', label: 'no' },
];

export const usedForOptions = [
  { value: 'private', label: 'private' },
  { value: 'professional', label: 'professional' },
];

export const accidentStatementType = [
  { value: 'CLIENT_DECLARATION', label: 'Client declaration' },
  { value: 'OFFICIAL_CERTIFICATE', label: 'Official certificate' },
];

export default accidentStatementType;
