import React from 'react';
import { Section, OverviewTable } from 'wg-fe-ui';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { getNestedObject } from '../../../services/objectService';

const ExtraBuildingData = ({ addressData }) => {
  const { t } = useTranslation();
  const distanceData = addressData.parcel.distances;
  const drivingTimes = addressData.parcel.driving_times;
  let totalSecondsToPolice = null;
  let totalSecondsToFire = null;
  if (drivingTimes) {
    totalSecondsToPolice = drivingTimes.nearest_police_station;
    totalSecondsToFire = drivingTimes.nearest_fire_station;
  }

  function convertSecondsToMinAndSec(totalSeconds) {
    if (totalSeconds === null) return '--';
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;
    if (seconds < 9) {
      seconds = `0${seconds}`;
    }
    return `${minutes}m ${seconds}s`;
  }

  return (
    <Section data-test-id="dashboard_toolkit_addresslookup_section_annexes">
      <Section.Title>{t('Distances')}</Section.Title>
      <DistancesContent>
        <OverviewTable>
          <OverviewTable.Head>
            <StyledTableData width="26%">{t('Country border')}</StyledTableData>
            <StyledTableData width="26%">{t('Street')}</StyledTableData>
            <StyledTableData width="26%">{t('Neighbor')}</StyledTableData>
            <StyledTableData width="26%">{t('Tram station')}</StyledTableData>
          </OverviewTable.Head>
          <OverviewTable.Body>
            <StyledRow>
              <OverviewTable.BodyCell>
                {distanceData.country_border !== null
                  ? distanceData.country_border.toFixed(2) + ' km'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {distanceData.street !== null
                  ? distanceData.street.toFixed(2) + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {distanceData.nearest_neighbor !== null
                  ? distanceData.nearest_neighbor.toFixed(2) + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {distanceData.nearest_tram_station !== null
                  ? distanceData.nearest_tram_station.toFixed(2) + ' km'
                  : '--'}
              </OverviewTable.BodyCell>
            </StyledRow>
          </OverviewTable.Body>
          <br />
          <OverviewTable.Head>
            <StyledTableData width="26%">{t('Police station')}</StyledTableData>
            <StyledTableData width="26%">{t('Fire station')}</StyledTableData>
            <StyledTableData width="26%">{t('Train station')}</StyledTableData>
            <StyledTableData width="26%">{t('Airport')}</StyledTableData>
          </OverviewTable.Head>
          <OverviewTable.Body>
            <StyledRow>
              <OverviewTable.BodyCell>
                {distanceData.nearest_police_station !== null
                  ? distanceData.nearest_police_station.toFixed(2) + ' km'
                  : '--'}
                {totalSecondsToPolice != null
                  ? ' (' + convertSecondsToMinAndSec(totalSecondsToPolice) + ')'
                  : ''}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {distanceData.nearest_fire_station !== null
                  ? distanceData.nearest_fire_station.toFixed(2) + ' km'
                  : '--'}
                {totalSecondsToFire != null
                  ? ' (' + convertSecondsToMinAndSec(totalSecondsToFire) + ')'
                  : ''}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {distanceData.nearest_train_station !== null
                  ? distanceData.nearest_train_station.toFixed(2) + ' km'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {distanceData.nearest_airport !== null
                  ? distanceData.nearest_airport.toFixed(2) + ' km'
                  : '--'}
              </OverviewTable.BodyCell>
            </StyledRow>
          </OverviewTable.Body>
        </OverviewTable>
      </DistancesContent>
    </Section>
  );
};

const DistancesContent = styled(Section.Content)`
  background-color: white;
  padding: 1rem 0 0 4rem;
  border: 0.1rem solid #ccc;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
  table {
    margin-bottom: 2rem;
  }
  tbody {
    box-shadow: none;
  }
`;

const StyledTableData = styled(OverviewTable.HeaderCell)`
  width: 26%;
  font-weight: 700;
  font-size: 1.6rem;
  color: #5b5550;
  padding-bottom: 0;
`;

const StyledRow = styled(OverviewTable.Row)`
  /* stylelint-disable-next-line */
  &:hover * {
    background-color: white;
  }
  td {
    :first-of-type,
    :last-of-type {
      padding-left: 0;
    }
    border-bottom: 0;
    padding-left: 0;
  }
`;

ExtraBuildingData.propTypes = {
  addressData: object.isRequired,
};

export default ExtraBuildingData;
