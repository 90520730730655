import React from 'react';
import { shape } from 'prop-types';

const GardenLock = ({ colors }) => {
  const style = {
    cls1: {
      fill: colors.brand.primary,
    },
    cls2: {
      fill: colors.brand.primary,
      strokeMiterlimit: '10',
    },
    cls3: {
      stroke: colors.brand.dark,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
    cls4: {
      fill: colors.brand.dark,
    },
    cls5: {
      fill: colors.black,
      opacity: '0.1',
    },
    cls6: {
      fill: colors.brand.lighter,
    },
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 827.07 553.16"
      style={{ width: `100%`, height: `100%` }}
    >
      <path
        style={style.cls1}
        d="M46.58,507c9.73-35.34,35.19-34.18,75.86-39.65,32.23-4.33,83.35-32.86,88.17,20.32A49.86,49.86,0,0,1,208.53,507"
      />
      <path
        style={style.cls2}
        d="M46.58,507c9.73-35.34,35.19-34.18,75.86-39.65,32.23-4.33,83.35-32.86,88.17,20.32A49.86,49.86,0,0,1,208.53,507"
      />
      <path
        style={style.cls1}
        d="M298.23,509c-1.51-10.88-2.14-32.27,10.32-42,32-25,91.25,18,144,14C516.4,476.12,606.1,419.77,674,435.85c63,14.91,73.6,55.32,75.15,73.13"
      />
      <path
        style={style.cls2}
        d="M298.23,509c-1.51-10.88-2.14-32.27,10.32-42,32-25,91.25,18,144,14C516.4,476.12,606.1,419.77,674,435.85c63,14.91,73.6,55.32,75.15,73.13"
      />
      <line
        style={style.cls3}
        x1="168.53"
        y1="547.16"
        x2="350.14"
        y2="547.16"
      />
      <line
        style={style.cls3}
        x1="408.55"
        y1="546.98"
        x2="452.55"
        y2="546.98"
      />
      <path
        style={style.cls4}
        d="M454,245.94c6.41,15.15,8,41.45,4.35,70.37-3.81,29.9-12.38,56.19-23.51,72.16-9.69,13.9-19.77,17.68-27.24,18.08-.48,0-.95,0-1.41,0s-.83,0-1.23,0c-34.61-1.64-35.44-60.75-36.17-112.89-.08-5.81-.16-11.3-.28-16.64-.35-15.16-6-37.41-12.07-61-11.77-46-25.11-98.09-5.07-123.41,15-18.9,31.5-11.78,42.44-7.07,3.83,1.65,8.17,3.52,10.14,3.08.81-.17,2.43-2.11,3.65-8.13.06-.32.13-.66.19-1,1.29-7.12,1.56-16.83,1.83-26.23.49-17.71,1-34.44,7.52-42.86C425.94-.92,435.27-.63,440,.55c14.68,3.63,27.54,24.87,32.76,54.1,6.05,33.9-4.53,56.84-15.73,81.12-4.16,9-8.47,18.39-12,28.57C434,196.23,438.06,208.46,454,245.94Z"
      />
      <path
        style={style.cls5}
        d="M454,245.94c6.41,15.15,8,41.45,4.35,70.37-3.81,29.9-12.38,56.19-23.51,72.16-9.69,13.9-19.77,17.68-27.24,18.08v-326c.06-.32.13-.66.19-1,1.29-7.12,1.56-16.83,1.83-26.23.49-17.71,1-34.44,7.52-42.86C425.94-.92,435.27-.63,440,.55c14.68,3.63,27.54,24.87,32.76,54.1,6.05,33.9-4.53,56.84-15.73,81.12-4.16,9-8.47,18.39-12,28.57C434,196.23,438.06,208.46,454,245.94Z"
      />
      <path
        style={style.cls6}
        d="M413.75,168.84V510.48a5.82,5.82,0,0,1-.55,2.5,6,6,0,0,1-10.9,0,5.82,5.82,0,0,1-.55-2.5V168.84a6,6,0,0,1,12,0Z"
      />
      <path
        style={style.cls6}
        d="M407.74,333a5.86,5.86,0,0,1-2.53-.57,6,6,0,0,1-2.9-8l19.9-42.57A6,6,0,1,1,433.08,287l-19.9,42.57A6,6,0,0,1,407.74,333Z"
      />
      <path
        style={style.cls6}
        d="M407,244.84a6,6,0,0,1-5.34-3.24L377,194a6,6,0,0,1,10.66-5.51l24.62,47.56a6,6,0,0,1-2.57,8.09A5.93,5.93,0,0,1,407,244.84Z"
      />
      <path
        style={style.cls5}
        d="M413.75,491v19.5a5.82,5.82,0,0,1-.55,2.5,6,6,0,0,1-10.9,0,5.82,5.82,0,0,1-.55-2.5V491Z"
      />
      <polyline
        style={style.cls3}
        points="821.07 512.57 209.53 512.57 47.58 512.57 6 512.57"
      />
    </svg>
  );
};

GardenLock.propTypes = {
  colors: shape().isRequired,
};

export default GardenLock;
