import React, { useState, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  ActionButton,
  Title,
  Section,
  Label,
  Text,
  SearchSelectInput,
  EditActionButton,
  IconFamilyFilled,
} from 'wg-fe-ui';

import useForm from '../hooks/useForm';
import * as Yup from 'yup';

import { formatISODate } from '../services/dateService';

import cogoToast from 'cogo-toast';

const CRMRiskObjectDetailFamily = ({
  familyData,
  onSubmitFunction,
  isCustomerPage,
  customerPageFunction,
  disableEditing,
}) => {
  /* INITS
  -------------------------------------------------- */
  const { t } = useTranslation();

  const [data, setData] = useState(familyData);
  const [editMode, setEditMode] = useState(false);
  const [familyStatus, setFamilyStatus] = useState('');
  const [livingTogether, setLivingTogether] = useState();

  useEffect(() => {
    setData(familyData);
  }, [familyData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  const familyStatusOptions = [
    { value: 'WITH_CHILDREN', label: t('WITH_CHILDREN') },
    { value: 'SINGLE_WITHOUT_CHILDREN', label: t('SINGLE_WITHOUT_CHILDREN') },
    { value: 'COUPLE', label: t('COUPLE') },
    { value: 'ELDERLY', label: t('ELDERLY') },
  ];

  const livingTogetherOptions = [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ];

  /* HELPER FUNCTIONS
  -------------------------------------------------- */
  const findFamilyStatusInEnums = status => {
    const result = familyStatusOptions.filter(obj => {
      return obj.value === status;
    });

    return result;
  };

  const findLivingTogetherInEnums = status => {
    const result = livingTogetherOptions.filter(obj => {
      return obj.value === status;
    });

    return result;
  };

  /* FORM HANDLING
  -------------------------------------------------- */
  const ValidationSchema = Yup.object().shape({
    status: Yup.string().nullable(),
    living_less_than_one_year_together: Yup.string().nullable(),
  });

  const setInitialFormValues = () => {
    if (data) {
      Object.keys(data).forEach(name => {
        handleChange({ name, value: data[name] });
      });
      setFamilyStatus(findFamilyStatusInEnums(familyData.status));
      setLivingTogether(
        findLivingTogetherInEnums(
          familyData.living_less_than_one_year_together,
        ),
      );
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: ValidationSchema,
    mode: 'onChange',
  });

  const handleFormValues = async () => {
    cogoToast.error(
      t('The editing and saving of a risk object is not supported yet'),
    );
  };

  const editClickHandler = e => {
    setEditMode(!editMode);
    setInitialFormValues();

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const handleFamilyStatusChange = e => {
    const selectedFamilyStatus = findFamilyStatusInEnums(e.value);
    setFamilyStatus(selectedFamilyStatus);
    handleChange({ name: 'status', value: selectedFamilyStatus[0].value });
  };

  const handleLivingTogetherChange = e => {
    const selectedLivingTogether = findLivingTogetherInEnums(e.value);
    setLivingTogether(selectedLivingTogether);
    handleChange({
      name: 'living_less_than_one_year_together',
      value: selectedLivingTogether[0].value,
    });
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
    } else {
      const payload = {
        status: values?.status,
        living_less_than_one_year_together:
          values?.living_less_than_one_year_together,
        birth: values?.birth,
      };

      onSubmitFunction(payload);
      setData(payload);
      setEditMode(false);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Section>
        <Section.Title>{t('Family information')}</Section.Title>
        <Section.Content background>
          <SectionContentHeader>
            <IconContainer>
              <IconFamilyFilled color="#CCCCCC" />
            </IconContainer>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.status}
                  initial={familyStatus}
                  name="status"
                  onSelected={handleFamilyStatusChange}
                  options={familyStatusOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Household')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Household')}</Label>
                  <HeaderText>
                    {familyData?.status ? `${t(familyData?.status)}` : `-`}
                  </HeaderText>
                </>
              )}
            </TextWrapper>
            {!isCustomerPage ? (
              <DOBContainer>
                <Label>{t('Date of birth')}</Label>
                <HeaderText>
                  {familyData?.birth ? formatISODate(familyData?.birth) : '-'}
                </HeaderText>
              </DOBContainer>
            ) : (
              ''
            )}
            {editMode || disableEditing ? null : (
              <StyledEditActionButton onClick={editClickHandler}>
                {t('Edit')}
              </StyledEditActionButton>
            )}
          </SectionContentHeader>
          {!isCustomerPage ? (
            <SectionContentInner>
              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.living_less_than_one_year_together}
                    initial={livingTogether}
                    name="living_less_than_one_year_together"
                    onSelected={handleLivingTogetherChange}
                    options={livingTogetherOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Have you been living together for less than a year?')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>
                      {t('Have you been living together for less than a year?')}
                    </Label>
                    <Text>
                      {familyData?.living_less_than_one_year_together === null
                        ? '-'
                        : familyData?.living_less_than_one_year_together
                        ? t('Yes')
                        : t('No')}
                    </Text>
                  </>
                )}
              </TextWrapper>
            </SectionContentInner>
          ) : (
            ''
          )}
          {editMode ? (
            <SectionContentFooter>
              <ActionButton level="default" onClick={editClickHandler}>
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton>{t('Save')}</ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

const DOBContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  padding-right: 15px;
`;

const IconContainer = styled.div`
  flex: 0 0 6rem;
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const StyledEditActionButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;
`;

const TextWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding-right: 15px;

  /* two items */
  :first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ div {
    width: 49%;
  }

  /* three items */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 49%;
  }
`;

const HeaderText = styled(Title)`
  text-transform: capitalize;
`;

const SectionContentHeader = styled.div`
  /* width: 100%; */
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const SectionContentInner = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    :nth-of-type(2n + 3) {
      flex: 0 0 calc(50% - 6rem);
      margin-left: 6rem;
    }
  }
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const Spacer = styled.div`
  width: 15px;
`;

CRMRiskObjectDetailFamily.propTypes = {
  familyData: object,
  onSubmitFunction: func,
  isCustomerPage: bool,
  customerPageFunction: func,
  disableEditing: bool,
};

export default CRMRiskObjectDetailFamily;
