import React from 'react';

const WorksAtHome = () => (
  <svg viewBox="0 0 205.5 225.6" width={205.5} height={225.6}>
    <style>{'.prefix__st0{fill:#fff}'}</style>
    <path
      className="prefix__st0"
      d="M103.1 14.2l28 17.8 20 12.7V18.1h12v34.5l6 3.8 23.4 14.8v141.4H13V71.1l90.1-56.9m0-14.2c-1.2 0-2.4.3-3.4 1L3 62.1c-1.8 1.2-3 3.2-3 5.4v148.8c0 5.1 4.2 9.3 9.3 9.3h186.9c5.1 0 9.3-4.2 9.3-9.3V67.5c0-2.2-1.1-4.2-3-5.4L176 45.4V11.5c0-3.5-2.9-6.4-6.4-6.4h-25.2c-3.5 0-6.4 2.9-6.4 6.4V21L106.5 1c-1-.7-2.2-1-3.4-1z"
    />
    <path
      className="prefix__st0"
      d="M44.5 105.9v70.2c0 4.8 3.9 8.7 8.7 8.7h12.3V97.2H53.2c-4.8 0-8.7 3.9-8.7 8.7zM152.3 97.2H140v87.6h12.3c4.8 0 8.7-3.9 8.7-8.7v-70.2c0-4.8-3.9-8.7-8.7-8.7zM123.7 86.2c0-4.1-3.3-7.4-7.4-7.4H89.1c-4.1 0-7.4 3.3-7.4 7.4v11h-4.8v87.6h51.6V97.2h-4.8v-11zm-34.6 0h27.2v11H89.1v-11z"
    />
  </svg>
);

export default WorksAtHome;
