import ApiRoutes from '../../../../store/ApiRoutes';

let img;

let markedImg;

const api = new ApiRoutes();

const data = {
  address: {
    manual: false,
    street: '',
    housenr: '',
    zipcode: '',
    country_code: '',
    city: '',
  },
  room_layout: {
    bathrooms: 1,
    bedrooms: 2,
    garages: 1,
    kitchens: 1,
    living_rooms: 1,
    other_rooms: 1,
    room_count: 7,
  },
  traits: {
    is_owner: false,
    garden: false,
    solar_panels: false,
    swimming_pool: false,
    special_flooring: false,
    special_roofing: false,
    cellar: false,
    attic: false,
    is_cellar_unhabited: null,
    is_attic_unhabited: null,
    passive_house: false,
    under_construction: false,
    age: 'between_10_and_20',
    has_attic: false,
    has_cellar: false,
    garden_house: false,
    private_garage: false,
    floor: null,
    trustee: null,
    rental_price: null,
    already_insured_with: false,
    previous_insurance_start_date: null,
  },
  addressInfo: {
    habitableSurface: '',
    building: '',
    parcel_area: '',
  },
};

const dataById = {};

const addDataForIdWoning = id => {
  dataById[id] = JSON.parse(JSON.stringify(data));
};

const getData = () => dataById[sessionStorage.getItem('id')];

const answers = [''];

const getHomeData = () => data;

const getAllAnswers = () => answers;

const addAnswer = (answer, key, status) => {
  dataById[sessionStorage.getItem('id')].traits[key] = status;
  answers.push(answer);

  localStorage.setItem('familyAnswers', JSON.stringify(answers));
};

const clearAnswer = from => {
  answers.splice(from, answers.length - 1);
};

const setManual = bool => {
  dataById[sessionStorage.getItem('id')].address.manual = bool;
};

// RoomLayout (housedata)
const getRoomLayout = () => dataById[sessionStorage.getItem('id')].room_layout;

const setRoomLayout = object => {
  dataById[sessionStorage.getItem('id')].room_layout = object;
};

// Address
const getAdress = () => {
  if (dataById[sessionStorage.getItem('id')])
    return dataById[sessionStorage.getItem('id')].address;
  else return data.address;
};

const setAdress = object => {
  dataById[sessionStorage.getItem('id')].address = object;
};

const setMapsPhoto = newImage => {
  img = newImage;
};

const setMarkedMapsPhoto = newImage => {
  markedImg = newImage;
};

const getMapsPhoto = () => img;

const getMarkedMapsPhoto = () => markedImg;

// data.address
const setAddressData = payload => {
  const tempPayload = JSON.parse(JSON.stringify(payload));
  tempPayload.manual = false;
  setAdress(tempPayload);
  return api.postAddressInfo(payload);
};

const setAddressSizes = payload => {
  const id = sessionStorage.getItem('id');
  dataById[id].addressInfo.surface = payload.surface;
  dataById[id].addressInfo.habitableSurface = payload.habitableSurface;
  dataById[id].addressInfo.height = payload.height;
  dataById[id].addressInfo.building = payload.building;
};

const setAddressInfo = payload => {
  const id = sessionStorage.getItem('id');
  // Set building information
  if (payload.extra_address_data) {
    dataById[id].addressInfo.surface = payload.extra_address_data
      .building_ground_surface
      ? payload.extra_address_data.building_ground_surface
      : '--';

    dataById[id].addressInfo.parcel_area = payload.extra_address_data
      .parcel_area
      ? payload.extra_address_data.parcel_area
      : '--';

    dataById[id].addressInfo.height = payload.extra_address_data.height
      ? payload.extra_address_data.height
      : '--';

    dataById[id].addressInfo.building = payload.extra_address_data.house_type
      ? payload.extra_address_data.house_type
      : '';

    // Set traits
    dataById[id].traits.garden = payload.extra_address_data.has_garden
      ? payload.extra_address_data.has_garden
      : false;

    dataById[id].traits.garden_house = payload.extra_address_data
      .has_garden_house
      ? payload.extra_address_data.has_garden_house
      : false;

    dataById[id].traits.porch = payload.extra_address_data.has_porch
      ? payload.extra_address_data.has_porch
      : false;

    dataById[id].traits.swimming_pool = payload.extra_address_data
      .has_swimming_pool
      ? payload.extra_address_data.has_swimming_pool
      : false;

    dataById[id].traits.solar_panels = payload.extra_address_data
      .has_solar_panels
      ? payload.extra_address_data.has_solar_panels
      : false;
  } else {
    if (payload.extra_data) {
      dataById[id].traits.garden = payload.extra_data.has_garden
        ? payload.extra_data.has_garden
        : false;

      dataById[id].traits.garden_house = payload.extra_data.has_garden_house
        ? payload.extra_data.has_garden_house
        : false;

      dataById[id].traits.porch = payload.extra_data.has_porch
        ? payload.extra_data.has_porch
        : false;

      dataById[id].traits.swimming_pool = payload.extra_data.has_swimming_pool
        ? payload.extra_data.has_swimming_pool
        : false;

      dataById[id].traits.solar_panels = payload.extra_data.has_solar_panels
        ? payload.extra_data.has_solar_panels
        : false;
    } else {
      dataById[id].traits.garden = false;
      dataById[id].traits.garden_house = false;
      dataById[id].traits.porch = false;
      dataById[id].traits.swimming_pool = false;
      dataById[id].traits.solar_panels = false;
      dataById[id].addressInfo.building = '';
    }
  }

  if (payload.room_estimation) {
    dataById[id].room_layout = payload.room_estimation;
    dataById[id].room_layout.room_count = 0;
    Object.keys(payload.room_estimation).forEach(key => {
      if (key !== 'room_count') {
        dataById[id].room_layout.room_count += payload.room_estimation[key];
      }
    });
  }

  if (payload.aerial_images) {
    if (payload.aerial_images.blank) {
      img = payload.aerial_images.blank.satellite;
    } else {
      if (payload.aerial_images.satellite) {
        img = payload.aerial_images.satellite;
      } else {
        img = undefined;
      }
    }

    if (payload.aerial_images.marked) {
      markedImg = payload.aerial_images.marked.hybrid;
    } else {
      markedImg = undefined;
    }
  } else {
    img = undefined;
    markedImg = undefined;
  }
};

const getAddressInfo = () => {
  if (dataById[sessionStorage.getItem('id')])
    return dataById[sessionStorage.getItem('id')].addressInfo;
  else if (process.env.NODE_ENV === 'development') return data.addressInfo;
};

// BPost address
const getBpostAddressInfo = payload => api.getBpostAddresses(payload);

const getFullAddress = payload => api.getAddressInfo(payload);

const getStreets = payload => api.getAddressStreets(payload);

const getAddressCities = payload => api.getAddressCities(payload);

const setInsuredData = (key, value) => {
  const id = sessionStorage.getItem('id');
  if (value === false) {
    if (dataById[id].traits.hasOwnProperty(key)) {
      delete dataById[id].traits[key];
    }
    if (key === 'already_insured_with') {
      if (dataById[id].traits.hasOwnProperty('previous_insurance_start_date')) {
        delete dataById[id].traits.previous_insurance_start_date;
      }
    }
  } else if (key === 'previous_insurance_start_date' && value === null) {
    delete dataById[id].traits.previous_insurance_start_date;
  } else {
    dataById[id].traits[key] = value;
  }
};

const cleanAddressData = payload => {
  const arr = [];
  arr.push(
    payload.map(r => {
      if (!('housenr' in r)) {
        return undefined;
      }

      const { housenr, city, street, boxnr, zipcode, lat, lng } = r;

      const cleanedStreetName = street
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const cleanedHouseNr = housenr
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const cleanedMunicipalityName = city
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const obj = {
        value: {
          streetName: cleanedStreetName,
          boxNumber: boxnr,
          streetNumber: cleanedHouseNr,
          postalCode: zipcode,
          municipalityName: cleanedMunicipalityName,
          latitude: lat,
          longitude: lng,
        },
        label: `${cleanedStreetName} ${cleanedHouseNr}${
          boxnr ? `/${boxnr}` : ``
        }, ${zipcode} ${cleanedMunicipalityName} `,
      };
      return housenr !== undefined ? obj : undefined;
    }),
  );
  return arr[0].filter(e => e !== undefined);
};

const cleanupBpostData = payload => {
  const arr = [];
  arr.push(
    payload.map(r => {
      if (!('housenr' in r)) {
        return undefined;
      }

      const { housenr, city, street, boxnr, zipcode, lat, lng } = r;

      const cleanedStreetName = street
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const cleanedHouseNr = housenr
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const cleanedMunicipalityName = city
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      const obj = {
        value: {
          streetName: cleanedStreetName,
          boxNumber: boxnr,
          streetNumber: cleanedHouseNr,
          postalCode: zipcode,
          municipalityName: cleanedMunicipalityName,
          latitude: lat,
          longitude: lng,
        },
        label: `${cleanedStreetName} ${cleanedHouseNr}${
          boxnr ? `/${boxnr}` : ``
        }, ${zipcode} ${cleanedMunicipalityName} `,
      };
      return housenr !== undefined ? obj : undefined;
    }),
  );
  return arr[0].filter(e => e !== undefined);
};

// Traits
const getTraits = () => {
  if (dataById[sessionStorage.getItem('id')])
    return dataById[sessionStorage.getItem('id')].traits;
  else return data.traits;
};

const setTraits = (key, value) => {
  dataById[sessionStorage.getItem('id')].traits[key] = value;
};

const getSingleTrait = key => {
  if (dataById[sessionStorage.getItem('id')]) {
    return dataById[sessionStorage.getItem('id')].traits[key];
  } else {
    return data.traits[key];
  }
};

const getInsuredData = () => {
  const id = sessionStorage.getItem('id');
  return {
    already_insured_with: dataById[id].traits.already_insured_with,
    previous_insurance_start_date:
      dataById[id].traits.previous_insurance_start_date,
  };
};

// Send final data
const sendHomeData = () => {
  const id = sessionStorage.getItem('id');
  const payload = {
    is_owner: dataById[id].traits.is_owner,
    private_garage: dataById[id].traits.private_garage,
    age: dataById[id].traits.age,
    passive_house: dataById[id].traits.passive_house,
    special_flooring: dataById[id].traits.special_flooring,
    under_construction: dataById[id].traits.under_construction,
    special_roofing: dataById[id].traits.special_roofing,
    is_cellar_unhabited: dataById[id].traits.is_cellar_unhabited,
    is_attic_unhabited: dataById[id].traits.is_attic_unhabited,
    has_attic: dataById[id].traits.attic,
    has_cellar: dataById[id].traits.cellar,
    rental_price: dataById[id].traits.rental_price,
    already_insured_with: dataById[id].traits.already_insured_with,
    previous_insurance_start_date:
      dataById[id].traits.previous_insurance_start_date,
    address: {
      street: dataById[id].address.street,
      housenr: dataById[id].address.housenr,
      boxnr: dataById[id].address.boxnr,
      zipcode: parseInt(dataById[id].address.zipcode),
      country_code: dataById[id].address.country_code,
      city: dataById[id].address.city,
      lat: parseFloat(dataById[id].address.lat),
      lng: parseFloat(dataById[id].address.lng),
    },
    room_layout: {
      bathrooms: dataById[id].room_layout.bathrooms,
      bedrooms: dataById[id].room_layout.bedrooms,
      garages: dataById[id].room_layout.garages,
      kitchens: dataById[id].room_layout.kitchens,
      living_rooms: dataById[id].room_layout.living_rooms,
      other_rooms: dataById[id].room_layout.other_rooms,
      storage_rooms: 0, // Hardcoded for now, support will be added later on
    },
  };

  for (const key in payload) {
    if (payload[key] === null || payload[key] === undefined) {
      delete payload[key];
    }
  }

  if (dataById[id].addressInfo.building === 'apartment') {
    delete payload.age;
    delete payload.passive_house;
    delete payload.under_construction;
    delete payload.is_attic_unhabited;
    delete payload.has_attic;
    delete payload.special_roofing;
    payload.trustee = dataById[id].traits.trustee;
    payload.floor = parseInt(dataById[id].traits.floor);
  }

  if (dataById[id].traits.is_owner) {
    delete payload.rental_price;
  }

  return api.postAddressData(JSON.stringify(payload));
};

const setMoreHouseData = payload => {
  const id = sessionStorage.getItem('id');
  dataById[id].traits.age = payload.age;
  dataById[id].traits.cellar = payload.cellar;
  dataById[id].traits.attic = payload.attic;
  dataById[id].traits.is_attic_unhabited =
    payload.atticHabitable === null ? null : !payload.atticHabitable;
  dataById[id].traits.is_cellar_unhabited =
    payload.cellarHabitable === null ? null : !payload.cellarHabitable;
  dataById[id].traits.passive_house = payload.passive_house;
  dataById[id].traits.under_construction = payload.under_construction;
  dataById[id].traits.special_flooring = payload.special_flooring;
  dataById[id].traits.special_roofing = payload.special_roofing;
};

const postAddressDataExtra = () => {
  const id = sessionStorage.getItem('id');
  let payload = {
    house_type: dataById[id].addressInfo.building,
    has_garden: dataById[id].traits.garden,
    has_solar_panels: dataById[id].traits.solar_panels,
    has_swimming_pool: dataById[id].traits.swimming_pool,
    has_garden_house: dataById[id].traits.garden_house,
    has_porch: dataById[id].traits.porch,
  };
  if (
    dataById[id].addressInfo.habitableSurface &&
    dataById[id].addressInfo.habitableSurface !== ''
  ) {
    payload.habitable_surface = parseFloat(
      dataById[id].addressInfo.habitableSurface,
    ).toFixed(2);
  }
  if (dataById[id].addressInfo.building === 'apartment') {
    payload.has_solar_panels = false;
    payload.has_swimming_pool = false;
    payload.has_garden_house = false;
    payload.has_garden = false;
    payload.has_porch = false;
  }
  return api.postAddressDataExtra(JSON.stringify(payload));
};

export {
  addDataForIdWoning,
  setTraits,
  getTraits,
  getSingleTrait,
  setAdress,
  getAdress,
  setRoomLayout,
  getRoomLayout,
  getMapsPhoto,
  getInsuredData,
  getMarkedMapsPhoto,
  setMoreHouseData,
  setAddressData,
  setAddressInfo,
  getAddressInfo,
  setInsuredData,
  getBpostAddressInfo,
  getData,
  cleanAddressData,
  cleanupBpostData,
  sendHomeData,
  postAddressDataExtra,
  setAddressSizes,
  getAllAnswers,
  addAnswer,
  clearAnswer,
  setManual,
  getHomeData,
  setMapsPhoto,
  setMarkedMapsPhoto,
  getStreets,
  getAddressCities,
  getFullAddress,
};
