import React from 'react';
import { shape, string } from 'prop-types';

const DefaultCarIcon = ({ colors, className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 756.95 756.95"
    viewBox="0 0 756.95 756.95"
  >
    <circle cx="378.38" cy="378.38" r="378.57" fill={colors.brand.primary} />
    <path
      fill="#fff"
      d="M622.69 343.47H513.15l-35.06-78.41-3.9-8.74-8.62-19.32H247.03l-7.96 19.33-3.59 8.74-32.24 78.41h-41.55c-20.35 0-36.9 14.64-37.7 33.32-.03.31-.04.84-.04 1.41v94.75c0 2.13 1.73 3.87 3.87 3.87h37.73v.04h21.9c0 .25-.02.5-.02.76 0 26.54 21.51 48.05 48.05 48.05 26.54 0 48.05-21.51 48.05-48.05 0-.25-.02-.5-.02-.76h21.65v-.05l135.44-.01v.06h21.9c0 .25-.02.5-.02.76 0 26.54 21.51 48.05 48.05 48.05 26.54 0 48.05-21.51 48.05-48.05 0-.25-.02-.5-.02-.76h21.66v-.07h58.31c2.13 0 3.86-1.73 3.86-3.87V363.15c-.02-10.87-8.83-19.68-19.7-19.68z"
    />
  </svg>
);

DefaultCarIcon.defaultProps = {
  className: '',
};

DefaultCarIcon.propTypes = {
  className: string,
  colors: shape().isRequired,
};

export default DefaultCarIcon;
