import React from 'react';
import { string } from 'prop-types';

const Leisure = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 213.12 260.76">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M74.39,142.54a.47.47,0,0,0-.17-.25.51.51,0,0,0-.29-.1.55.55,0,0,0-.3.07.83.83,0,0,0-.2.22c-1.46,3.13-2.82,6.36-3,6.7-4.86,11.43-4.49,10-9.25,21.75-.42,1.13-.91,2.2-1.38,3.32A20.34,20.34,0,0,1,56.25,173c-11.71-4.76-23.16-9.57-34.86-14.34-4.45-1.86-8.83-2.24-13,.38C2.25,162.76.05,168.52,0,175.27c.11,6.36,4.17,10.26,9.74,12,19.08,7.91,37.9,15.88,57,23.75,7.38,3.13,11.22,1.25,14.11-5.86,5-11.81,10-24.22,15.24-36.41l-7.28-5.93A46.83,46.83,0,0,1,74.39,142.54Z"
        />
        <path
          fill={color}
          d="M172.17,55.93a28,28,0,0,0,28.22-27.65C200.51,12.61,188,0,172.76,0c-15.88.05-28.4,12.13-28.4,28A27.66,27.66,0,0,0,172.17,55.93Z"
        />
        <path
          fill={color}
          d="M199.19,94.34a10.34,10.34,0,0,0,.65-2.63c-.15-7.19-4-12-9.83-15.43-5.08-2.84-10-1.67-14.1,1.55l-2.86-3.7a18.43,18.43,0,0,0-5.8-5.8c-10.37-6.84-20.37-14.09-30.91-20.54l-4-2.56C103.25,26.35,54.83,26.42,31.23,55.35c-36.42,44.64,2.12,89.33,50,70.54,5.5-3,11.86-9.65,18.61-16.82,16.5-17.59,39-41.58,66.55-22.57l-8.16,6.2c-17.47-10.33-32.53,2.82-51.38,23-8.36,8.9-15.7,16.46-23.37,19.78,1.81,11,10.68,18.68,11.92,19.62l11.81,9.57c9.64,5.61,19.2,11.18,28.72,16.52,1.71,1.18,1.6,2.44,1.6,4.08q-.15,30.3-.27,60.62a16.21,16.21,0,0,0,.17,4.23c1.59,7,7.16,10.9,15.93,10.68,9-.31,14.7-6,14.76-14.86-.11-23.74,0-47.63.1-71.54-.1-5.51-2-9.56-6.89-12.51-10-5.74-19.9-12.14-29.69-18.23a11.14,11.14,0,0,1-2.88-1.87l13.82-21.11a12.56,12.56,0,0,0,.62,1.28,15.48,15.48,0,0,0,21.74,3c8.35-6.35,17.78-13.48,24.68-18.76l9.56,16.14,13.9-30.55Z"
        />
      </g>
    </g>
  </svg>
);

Leisure.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Leisure.propTypes = {
  title: string,
  color: string,
};

export default Leisure;
