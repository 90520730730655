/* stylelint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';

import Layout from '../../LayoutFlow/views/App';
import Button from '../../../../components/Button';
import {
  setCoverage,
  // getAllCoverages,
  getFlow,
  getNextPath,
  getComponentName,
  addChatboxQuestion,
  addChatboxAnswer,
  addBrowserHistory,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
import { setSkippedSteps } from '../../../Marketplace/LayoutMarket/store';
import { getBrokerDistribution } from '../../../../services/brokerDataService';
import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';
import ChooseInsuranceBox from '../../../../components/ChooseInsuranceBox.jsx';
import LouiseLeftSide from '../../User/views/LouiseLeftSide';

const Coverages = ({ history }) => {
  const { t } = useTranslation();

  const [coverages, setLocalCoverages] = useState({
    home: false,
    family: false,
    car: false,
    legal: false,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const location = useLocation();
  const flow = getFlow();
  addChatboxQuestion(t(`${getComponentName()}.question`));

  const diaMunichAccounts = [
    'lander_broker@wegroup.be',
    'dia_broker@wegroup.be',
  ];

  const onClickHandler = async () => {
    const { email } = await getBrokerDistribution();
    if (!isDisabled || diaMunichAccounts.includes(email)) {
      var selected = [];
      if (coverages['home'] && flow.home) {
        selected.push(t(`${getComponentName()}.home`));
      }
      if (coverages['family'] && flow.family) {
        selected.push(t(`${getComponentName()}.family`));
      }
      if (coverages['car'] && flow.car) {
        selected.push(t(`${getComponentName()}.car`));
      }
      if (coverages['legal'] && flow.legal) {
        selected.push(t(`${getComponentName()}.legal`));
      }

      var insurances = '';
      if (selected.length === 1) {
        insurances = selected[0].toLowerCase();
      } else {
        for (var i = 0; i < selected.length; i++) {
          if (i !== selected.length - 1) {
            insurances += `${selected[i].toLowerCase() + ', '}`;
          } else {
            insurances += t(`${getComponentName()}.and`);
            insurances += `${' ' + selected[i].toLowerCase()}`;
          }
        }
      }
      addChatboxAnswer(
        t(`${getComponentName()}.interactiveMsg`, {
          insurances: insurances,
        }),
      );
      if (coverages['home'] && flow.home) {
        history.push(getNextPath('a'));
      } else if (coverages['family'] && flow.family) {
        history.push(getNextPath('b'));
      } else if (coverages['car'] && flow.car) {
        setSkippedSteps(4, true);
        setCoverage('lock', false);
        history.push(getNextPath('c'));
      } else {
        setSkippedSteps(4, true);
        setCoverage('lock', false);
        history.push(getNextPath('d'));
      }
    }
  };

  useEffect(() => {
    initalizeDiaAccount();
  }, []);

  async function initalizeDiaAccount() {
    const { email } = await getBrokerDistribution();
    if (diaMunichAccounts.includes(email)) {
      setCoverage('family', false);
      onClickHandler();
    } else {
      addBrowserHistory(location.pathname);
    }
  }

  useEffect(() => {
    let _RETURN = true;
    Object.keys(coverages).forEach(key => {
      if (
        (key === 'home' ||
          key === 'family' ||
          key === 'car' ||
          key === 'legal') &&
        coverages[key]
      ) {
        _RETURN = false;
      }
    });
    setIsDisabled(_RETURN);
  }, [coverages]);

  useEffect(() => {
    Object.keys(coverages).forEach(key => {
      setCoverage(key, false);
    });
  }, []);

  const changeType = e => {
    setCoverage(e.name, e.checked);
    setLocalCoverages({
      ...coverages,
      [e.name]: e.checked,
    });
  };

  return (
    <Layout>
      <Container>
        <StyledLeftSideV2>
          <LouiseLeftSide question={t(`${getComponentName()}.question`)} />
        </StyledLeftSideV2>
        <StyledRightSide>
          <ScrollContainer>
            <ListOfCoverages>
              <GuaranteesContainer>
                <ChooseInsuranceBox
                  icon={'fire'}
                  tag={'home'}
                  title={t(`${getComponentName()}.home`)}
                  description={t(`${getComponentName()}.descriptions.home`)}
                  handleChange={changeType}
                  // initialChecked={coverages['home'] && flow.home}
                  disabled={!flow.home}
                  data-test-id="coverages_type_home"
                />
                <ChooseInsuranceBox
                  icon={'family'}
                  tag={'family'}
                  title={t(`${getComponentName()}.family`)}
                  description={t(`${getComponentName()}.descriptions.family`)}
                  handleChange={changeType}
                  // initialChecked={coverages['family'] && flow.family}
                  disabled={!flow.family}
                  data-test-id="coverages_type_family"
                />
                <ChooseInsuranceBox
                  icon={'vehicle'}
                  tag={'car'}
                  title={t(`${getComponentName()}.car`)}
                  description={t(`${getComponentName()}.descriptions.car`)}
                  handleChange={changeType}
                  // initialChecked={coverages['car'] && flow.car}
                  disabled={!flow.car}
                  data-test-id="coverages_type_car"
                />
                <ChooseInsuranceBox
                  icon={'legal_plus'}
                  tag={'legal'}
                  title={t(`${getComponentName()}.legal`)}
                  description={t(`${getComponentName()}.descriptions.legal`)}
                  handleChange={changeType}
                  // initialChecked={coverages['legal'] && flow.car}
                  disabled={!flow.legal}
                  data-test-id="coverages_type_legal"
                />
              </GuaranteesContainer>
            </ListOfCoverages>
            <ButtonContainer>
              <Button
                onClick={() => onClickHandler()}
                dataTestId="underwriting_insurance_submit"
                disabled={isDisabled}
              >
                {t(`${getComponentName()}.btnText`)}
              </Button>
            </ButtonContainer>
          </ScrollContainer>
        </StyledRightSide>
      </Container>
    </Layout>
  );
};

const StyledLeftSideV2 = styled(LeftSide)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  padding: 6rem 2rem 2rem;
`;

const StyledRightSide = styled(RightSide)`
  padding-bottom: 2rem;
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
`;

const ListOfCoverages = styled.div`
  width: 100%;
  min-height: calc(100% - 11.1rem);
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  flex-shrink: 0;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  position: relative;
  /* overflow-y: auto;
  overflow-x: scroll; */
  line-height: 2.6rem;
  /* overflow-x: hidden; */
`;

const ButtonContainer = styled.div`
  margin: 3rem 0;
  border-radius: 3rem;
  z-index: 1;
  align-self: flex-end;

  & button {
    margin: 0;
  }
`;

const GuaranteesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

Coverages.propTypes = {
  history: shape().isRequired,
};

export default withRouter(Coverages);
