/* stylelint-disable declaration-block-no-redundant-longhand-properties, value-keyword-case */
import React from 'react';
import styled from 'styled-components';
import { node, string, func, bool, number } from 'prop-types';
import Circlespinner from './CircleSpinner';

import InfoPopUp from './InfoPopUp';

const QuestionBox = ({
  children,
  Answer1,
  Answer2,
  dataTestId1,
  dataTestId2,
  mainDisabled,
  secondDisabled,
  question,
  response,
  shouldLoad,
  tooltip,
  oneLine,
}) => {
  const left = true; // workaround for error msg

  const handleClick = e => {
    if (!mainDisabled || !secondDisabled) {
      e.preventDefault();
      response(e.currentTarget.innerHTML);
    }
  };

  return (
    <Container>
      <Question oneLine={oneLine}>
        <p dangerouslySetInnerHTML={{ __html: question }} />
        {children}
        {tooltip !== null ? (
          <InfoPopUp title={children} info={tooltip} />
        ) : null}
      </Question>
      {Answer1 || Answer2 ? (
        <Answers>
          <Answer
            disabled={secondDisabled || shouldLoad === 2}
            left={left ? 1 : 0}
            onClick={e => handleClick(e)}
            pending={shouldLoad === 1}
            data-test-id={dataTestId1}
          >
            {Answer1}
            {shouldLoad === 1 ? (
              <LoadingContainer>
                <Circlespinner />
              </LoadingContainer>
            ) : null}
          </Answer>
          <Answer
            left={left ? 0 : 1}
            pending={shouldLoad === 2}
            onClick={e => {
              handleClick(e);
            }}
            data-test-id={dataTestId2}
            disabled={mainDisabled || shouldLoad === 1}
          >
            {Answer2}
            {shouldLoad === 2 ? (
              <LoadingContainer>
                <Circlespinner />
              </LoadingContainer>
            ) : null}
          </Answer>
        </Answers>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 18rem;
  box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, 0.15);
  border-radius: 0 1.6rem 1.6rem;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  margin: auto;
  flex-shrink: 0;
  margin-top: 0;
  margin-bottom: 3rem;
  background-color: white;
  padding-bottom: 4.5rem;
`;

const Answers = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
`;

const LoadingContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Answer = styled.button`
  width: 50%;
  height: 4.5rem;
  display: flex;
  position: relative;
  outline: none;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: ${({ theme, pending, disabled }) =>
    pending
      ? theme.brand.primary
      : disabled
      ? theme.brand.lighter
      : theme.brand.primary};
  color: white;
  border: solid 0.2rem;
  border-radius: ${props => (props.left ? '0 0 0 1.6rem' : '0 0 1.6rem 0')};
  border-color: ${({ theme, pending, disabled }) =>
    pending
      ? theme.brand.primary
      : disabled
      ? theme.brand.lighter
      : theme.brand.primary};
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, pending, disabled }) =>
      pending
        ? theme.brand.primary
        : disabled
        ? theme.brand.lighter
        : theme.brand.lighter};
    cursor: ${props =>
      props.pending ? `progress` : props.disabled ? `default` : `pointer`};
    border-color: ${({ theme, pending, disabled }) =>
      pending
        ? theme.brand.primary
        : disabled
        ? theme.brand.lighter
        : theme.brand.primary};
  }
`;

const Question = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2rem 3rem;
  align-self: flex-start;
  ${props =>
    props.oneLine
      ? `
      align-items: center;
      flex-wrap: nowrap;
    `
      : null}

  & span {
    font-weight: bold;
  }

  & > p {
    flex-basis: 100%;
    margin-bottom: 1rem;
    ${props =>
      props.oneLine
        ? `
      flex: 1 1 auto;
      margin-right: 3rem;
      font-size: 2rem;
    `
        : null}
  }
`;

QuestionBox.defaultProps = {
  to: null,
  mainDisabled: false,
  secondDisabled: false,
  Answer1: null,
  Answer2: null,
  question: null,
  noLink: null,
  oneLine: false,
  tooltip: null,
  shouldLoad: undefined,
  dataTestId1: '',
  dataTestId2: '',
  response: () => {},
};

QuestionBox.propTypes = {
  children: node.isRequired,
  Answer1: string,
  Answer2: string,
  question: string,
  to: string,
  response: func,
  mainDisabled: bool,
  secondDisabled: bool,
  noLink: string,
  tooltip: string,
  oneLine: bool,
  shouldLoad: number,
  dataTestId1: string,
  dataTestId2: string,
};

export default QuestionBox;
