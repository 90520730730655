import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Uploader from './Uploader';
import DefaultLogo from '../../../../assets/images/dashboard/defaultLogo.svg';
import { node, func, bool } from 'prop-types';
import Card from './Card';
import { useTranslation } from 'react-i18next';

const SettingsCard = styled(Card)`
  box-shadow: none;
  margin-top: 2rem;
`;

const Logo = styled.img`
  background-color: #fbfbfb;
  border: 0.1rem solid rgba(14, 14, 14, 0.1);
  border-radius: 0.5rem;
  width: 12rem;
  height: 12rem;
  align-self: flex-end;
`;

const StyledPhotoUploadSection = styled.div`
  .logoHeader {
    display: inline-block;
    font-size: 1.4rem;
    top: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }

  > div {
    display: flex;
  }
`;

const LogoPreview = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhotoSettings = ({ className, selectedLogo, disabled }) => {
  const [logo, setLogo] = useState('');
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (logo !== '') {
      selectedLogo({ logo, fileName });
    }
  }, [logo]);

  const { t } = useTranslation();

  return (
    <SettingsCard className={className}>
      <StyledPhotoUploadSection>
        <span className="logoHeader">{t('Settings.customization.avatar')}</span>
        <div>
          <LogoPreview>
            <Logo src={logo || DefaultLogo} />
          </LogoPreview>
          <Uploader
            getFileName={val => {
              setFileName(val);
            }}
            setLogo={setLogo}
            disabled={disabled}
          />
        </div>
      </StyledPhotoUploadSection>
    </SettingsCard>
  );
};

PhotoSettings.defaultProps = {
  disabled: false,
};

PhotoSettings.propTypes = {
  className: node,
  selectedLogo: func,
  getFileName: func,
  disabled: bool,
};

export default PhotoSettings;
