import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import styled from 'styled-components';
import { func, shape, bool, string } from 'prop-types';
import { SearchSelectInput, TextInput, Alert, ActionButton } from 'wg-fe-ui';
import { useDebouncedCallback } from 'use-debounce';
import useForm from '../hooks/useForm';
import _ from 'lodash';

import {
  streetName,
  streetNumber,
  boxNumber,
  postalCode,
  municipalityName as municipalityNameValidation,
} from '../services/validationSchemas';
import {
  retrieveAddressStreets,
  retrieveAddressCities,
} from '../services/apiRouterService';

import ProFlowAddressSearchInput from './ProFlowAddressSearchInput';

const ProFlowAddressSearchInputWithFallback = ({
  address,
  setAddress,
  getAddressInformation,
  isManualActive,
  setIsManualActive,
  useInfo,
  error,
  dataTestId,
}) => {
  const [streetNames, setStreetNames] = useState();
  const [municipalityName, setMunicipalityName] = useState();
  const [selected, setSelected] = useState({});
  const [postals, setPostals] = useState({});
  const { i18n } = useTranslation();

  const SignupSchema = Yup.object().shape({
    streetName: streetName({ min: '', max: '', matches: '', required: '' }),
    streetNumber: streetNumber({ max: '', matches: '', required: '' }),
    boxNumber: boxNumber({ max: '', matches: '' }),
    postalCode: postalCode({ required: '' }),
    municipalityName: municipalityNameValidation({
      min: '',
      max: '',
      matches: '',
      required: '',
    }),
  });

  const { values, handleChange, errors } = useForm({
    validationSchema: SignupSchema,
  });

  useEffect(() => {
    if (address) {
      const selectedObj = {};
      Object.keys(address).forEach(key => {
        handleChange({ name: key, value: address[key] });
        const selectedItem = {
          label: address[key],
          value: address[key],
        };
        selectedObj[key] = selectedItem;
      });
      setIsManualActive(true);
      setSelected(selectedObj);
    }
  }, [address]);

  useEffect(() => {
    if (values.streetName) {
      getStreetNames({
        searchQuery: values['streetName'].substring(0, 6),
        municipalityName: values['municipalityName'],
      });
    }
  }, [values.streetName]);

  // useEffect(() => {
  //   if (!isObjectEmpty(values)) {
  //     onSubmit(values);
  //   }
  // }, [values]);

  async function fetchStreets(value, values) {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.municipalityName)
    ) {
      let streetsOptionList = [];
      let payload;
      if (value.searchQuery) {
        payload = {
          city: value.municipalityName
            ? value.municipalityName
            : values['municipalityName'],
          searchQuery: value.searchQuery,
        };
      } else {
        payload = {
          city: value.municipalityName,
        };
      }

      const [resp, status] = await retrieveAddressStreets(payload);
      if (status !== 200) return;
      resp.forEach(street => {
        streetsOptionList.push({
          value: street.name,
          label: street.name,
        });
      });
      return streetsOptionList;
    }
  }

  async function fetchCities(value) {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.zipcode)
    ) {
      let citiesOptionList = [];
      let cityPostals = {};
      const [resp, status] = await retrieveAddressCities(value);
      if (status !== 200) return;
      resp.forEach(city => {
        cityPostals[city.name] = city.zipcode;
        citiesOptionList.push({
          value: city.name,
          label: city.name,
        });
      });

      return { cityPostals, citiesOptionList };
    }
  }

  const getStreetNames = async (val, callback) => {
    const resp = await fetchStreets({ searchQuery: val }, values);
    setStreetNames(resp);
    if (callback) {
      return callback(resp);
    }
    return resp;
  };

  const getCityNames = async (val, callback) => {
    let resp;
    if (callback) {
      resp = await fetchCities({ searchQuery: val });
    } else {
      resp = await fetchCities(val);
    }
    setStreetNames({});
    setMunicipalityName(resp.citiesOptionList);
    // delete streetname
    const tmpSelected = { ...selected };
    delete tmpSelected['streetName'];
    setSelected(tmpSelected);
    handleChange({ name: 'streetName', value: undefined });
    setPostals(resp.cityPostals);
    if (callback) {
      return callback(resp.citiesOptionList);
    }
    return resp.citiesOptionList;
  };

  const handleSelected = value => {
    const selectedVal = { label: value.value, value: value.value };
    const tmpSelected = { ...selected };
    if (value.name === 'municipalityName') {
      //delete streetname
      delete tmpSelected['streetName'];
      delete tmpSelected['boxNumber'];
      delete tmpSelected['streetNumber'];
      handleChange({ name: 'streetName', value: undefined });
      handleChange({ name: 'boxNumber', value: undefined });
      handleChange({ name: 'streetNumber', value: undefined });
      if (postals[selectedVal.value]) {
        tmpSelected['postalCode'] = postals[selectedVal.value];
        handleChange({ name: 'postalCode', value: postals[selectedVal.value] });
      }
    } else if (value.name === 'streetName') {
      delete tmpSelected['boxNumber'];
      delete tmpSelected['streetNumber'];
      handleChange({ name: 'boxNumber', value: undefined });
      handleChange({ name: 'streetNumber', value: undefined });
    }

    tmpSelected[value.name] = selectedVal;
    setSelected(tmpSelected);
    handleChange(value);
  };

  const handlZipcodeValidation = async () => {
    if (values['postalCode'] && values['postalCode'].length === 4) {
      const [resp] = await getCityNames({
        zipcode: parseInt(values['postalCode']),
      });
      handleChange({ name: 'streetName', value: undefined });
      handleChange({ name: 'streetNumber', value: undefined });
      handleSelected({ name: 'municipalityName', value: resp.value });
    }
  };

  const searchAddress = async () => {
    setAddress({
      streetName: values.streetName,
      postalCode: values.postalCode,
      streetNumber: values.streetNumber,
      municipalityName: values.municipalityName,
    });
  };

  const [debounceFetchCities] = useDebouncedCallback(getCityNames, 500);
  const [debounceFetchStreets] = useDebouncedCallback(getStreetNames, 500);

  return (
    <>
      {!address && !isManualActive && (
        <SearchInputContainer>
          <ProFlowAddressSearchInput
            onSelected={obj => !_.isNull(obj) && setAddress(obj.value)}
            address={address}
            error={error}
            data-test-id={`${dataTestId}_search_input`}
          />
          <SearchManualButton
            onClick={() => setIsManualActive(true)}
            data-test-id={`${dataTestId}_cant_find`}
          >
            {i18n.t("I can't find my address")}
          </SearchManualButton>
        </SearchInputContainer>
      )}

      {!useInfo && address && (
        <StyledAlert icon type="primary">
          {i18n.t(
            'The information below was filled in by us Please check all yellow boxes for correctness before proceeding',
          )}
        </StyledAlert>
      )}

      {isManualActive && (
        <ManualInputContainer>
          <FlexWrapper>
            <SearchSelectInput
              async
              cacheOptions={false}
              defaultOptions={municipalityName}
              error={errors.municipalityName}
              id="municipalityName"
              name="municipalityName"
              loadOptions={debounceFetchCities}
              onSelected={value => handleSelected(value)}
              value={selected.municipalityName || ''}
              noOptionsMessage={() => 'no city found'}
              disabled={useInfo}
              data-test-id={`${dataTestId}_municipality`}
            >
              {i18n.t('Municipality')} *
            </SearchSelectInput>
            <TextInput
              error={errors.postalCode}
              onChange={handleChange}
              type="number"
              name="postalCode"
              dataTestId="underwriting_address_postalCode"
              value={values['postalCode']}
              onBlur={() => handlZipcodeValidation()}
              placeholder="zipcode"
              disabled={useInfo}
              data-test-id={`${dataTestId}_zipcode`}
            >
              {i18n.t('Postal code')} *
            </TextInput>
          </FlexWrapper>
          <FlexWrapper>
            <SearchSelectInput
              async
              cacheOptions={false}
              defaultOptions={streetNames}
              error={errors.streetName}
              id="streetName"
              name="streetName"
              loadOptions={debounceFetchStreets}
              onSelected={value => handleSelected(value)}
              value={selected.streetName || ''}
              noOptionsMessage={() => 'no streets found'}
              disabled={useInfo}
              data-test-id={`${dataTestId}_street`}
            >
              {i18n.t('Street')} *
            </SearchSelectInput>
            <FlexWrapper>
              <TextInput
                error={errors.streetNumber}
                onChange={handleChange}
                type="text"
                name="streetNumber"
                disabled={!values['streetName'] || useInfo}
                value={
                  values['streetNumber'] && values['streetName']
                    ? values['streetNumber']
                    : ''
                }
                data-test-id={`${dataTestId}_housenr`}
              >
                <FlowAdressInputLabel>
                  {i18n.t('Housenr')} *
                </FlowAdressInputLabel>
              </TextInput>
              <TextInput
                error={errors.boxNumber}
                onChange={handleChange}
                type="string"
                name="boxNumber"
                disabled={!values['streetName'] || useInfo}
                value={values['boxNumber'] ? values['boxNumber'] : ''}
                data-test-id={`${dataTestId}_boxnr`}
              >
                {i18n.t('Boxnr')}
              </TextInput>
            </FlexWrapper>
          </FlexWrapper>

          {!useInfo && (
            <ActionButtonContainer>
              <ActionButton
                level="primary"
                onClick={() => searchAddress()}
                data-test-id={`${dataTestId}_manual_search`}
              >
                {i18n.t('Search address')}
              </ActionButton>
            </ActionButtonContainer>
          )}
        </ManualInputContainer>
      )}
    </>
  );
};

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SearchInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  border-bottom: 1px solid #f0f1f3;
`;

const SearchManualButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  color: #8990a3;
  text-decoration: underline;
  text-align: center;
  font-weight: regular;
  align-self: center;

  :hover {
    cursor: pointer;
  }
`;

const FlowAdressInputLabel = styled.p`
  height: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
`;

const ManualInputContainer = styled.div`
  margin-top: 1.4rem;
  padding-bottom: 4rem;
  margin-bottom: 3rem;
  border-bottom: 1px solid #f0f1f3;
`;

const StyledAlert = styled(Alert)`
  margin: 1.4rem 0 2.4rem;
`;

ProFlowAddressSearchInputWithFallback.propTypes = {
  setAddress: func.isRequired,
  address: shape({}).isRequired,
  getAddressInformation: func.isRequired,
  isManualActive: bool.isRequired,
  setIsManualActive: func.isRequired,
  useInfo: bool.isRequired,
  error: string,
  dataTestId: string,
};

export default ProFlowAddressSearchInputWithFallback;
