import React from 'react';
import { string } from 'prop-types';

const Omnium = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265.77 230">
    <title>{title}</title>
    <g id="icons">
      <path
        fill={color}
        d="M197.68,148.32c-7-7.55-20.12-12.85-35.52-16.57-3.42-.83-7-1.58-10.6-2.25-39.06-7.37-86.08-6.45-86.08-6.45L15.07,90.28Q7.42,89.53,0,89.16V197.43H80.23a40.93,40.93,0,0,0,80.13,0H180a13.93,13.93,0,0,0,13.39-10.12l7.28-25.67A14,14,0,0,0,197.68,148.32Zm-51.84,49.11a26.84,26.84,0,0,1-51.06,0,25.71,25.71,0,0,1-1.35-8.34,26.87,26.87,0,1,1,53.73,0A26.53,26.53,0,0,1,145.84,197.43Z"
      />
      <path
        fill={color}
        d="M136.53,103.9a8.1,8.1,0,0,1-5.73-2.29l-25.63-25a8,8,0,0,1,5.51-13.81l45.14-.6,1.31-54.37a8,8,0,0,1,14.74-4.28L199,44.1l37.7-12.46A8.06,8.06,0,0,1,246.34,43L230.63,73.12a8.05,8.05,0,0,1-14.28-7.44l6.53-12.53L198.2,61.31A8.06,8.06,0,0,1,189,58.14L172.62,33.66l-.88,36.72a8.06,8.06,0,0,1-8,7.85l-33.46.44L142,90.08a8.06,8.06,0,0,1-5.51,13.82Z"
      />
      <path
        fill={color}
        d="M265.77,118.78a25,25,0,0,0-25-25h0a25,25,0,0,0-25,25v40H232v62.48a8.75,8.75,0,0,0,17.5,0V158.77h16.25Z"
      />
    </g>
  </svg>
);

Omnium.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Omnium.propTypes = {
  title: string,
  color: string,
};

export default Omnium;
