import React from 'react';
import { shape, bool } from 'prop-types';

const SettingsIcon = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };

  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30.7168"
        cy="30.0327"
        r="30"
        fill={colorsDict.secondaryColor}
      />
      <path
        d="M35.7698 15.9756L32.7073 17.1787L33.2542 19.8256C33.0409 20.0389 32.814 20.285 32.5761 20.5475L29.9511 19.9568L28.6604 22.9756L30.8917 24.4631C30.8671 24.8322 30.8671 25.2096 30.8917 25.5787L28.5948 26.9787L29.7979 30.0412L32.3792 29.5162C32.6116 29.7951 32.8659 30.0494 33.1448 30.2818L32.5542 32.8631L35.5948 34.1537L37.0823 31.9225C37.4542 31.9471 37.8042 31.9471 38.1761 31.9225L39.5979 34.2193L42.6605 33.0162L42.0698 30.2162C42.346 29.9865 42.5839 29.724 42.8136 29.4506L45.4823 30.1287L46.7948 27.0662L44.4761 25.6443C44.498 25.2807 44.498 24.9361 44.4761 24.5725L46.773 23.1506L45.5698 20.0881L42.9011 20.6787C42.6714 20.4025 42.4116 20.1428 42.1355 19.9131L42.7261 17.3318L39.6855 16.0412L38.2636 18.2725C37.8972 18.2369 37.528 18.2178 37.1698 18.2506L35.7698 15.9756ZM37.7167 22.2318C39.2644 22.2318 40.5167 23.4842 40.5167 25.0318C40.5167 26.5795 39.2644 27.8318 37.7167 27.8318C36.169 27.8318 34.9167 26.5795 34.9167 25.0318C34.9167 23.4842 36.169 22.2318 37.7167 22.2318ZM22.6886 28.5318L22.2511 31.3318C21.8655 31.4549 21.4745 31.6299 21.0917 31.835L18.7511 30.1287L16.3011 32.5787L17.9198 34.9412C17.7257 35.324 17.5726 35.7096 17.4386 36.1006L14.6167 36.6256V40.0381L17.4167 40.5412C17.5397 40.9295 17.712 41.3369 17.9198 41.7225L16.2136 43.9975L18.6636 46.4475L21.0261 44.8287C21.4062 45.0229 21.7972 45.176 22.1854 45.31L22.6229 48.1318H26.0573L26.5604 45.3318C26.9515 45.2088 27.3562 45.0365 27.7417 44.8287L30.0823 46.535L32.5323 44.085L30.8479 41.7225C31.0394 41.3424 31.1733 40.9514 31.3073 40.5631L34.2167 40.0381V36.6037L31.3511 36.1662C31.228 35.7807 31.053 35.3869 30.8479 35.0068L32.5323 32.6662L30.1042 30.1287L27.7198 31.835C27.3452 31.6436 26.9679 31.4877 26.5823 31.3537L26.1448 28.5318H22.6886ZM24.4167 35.5318C25.9562 35.5318 27.2167 36.7924 27.2167 38.3318C27.2167 39.8713 25.9562 41.1318 24.4167 41.1318C22.8772 41.1318 21.6167 39.8713 21.6167 38.3318C21.6167 36.7924 22.8772 35.5318 24.4167 35.5318Z"
        fill={colorsDict.iconColor}
      />
    </svg>
  );
};

SettingsIcon.defaultProps = {
  disabled: true,
};

SettingsIcon.propTypes = {
  colors: shape().isRequired,
  disabled: bool,
};

export default SettingsIcon;
