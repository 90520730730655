import React, { useEffect, useState, useContext } from 'react';
import { node, string, shape, bool } from 'prop-types';
import styled from 'styled-components';
import { LoadingSpinner } from 'wg-fe-ui/dist';
import Navigation from '../components/Navigation';
import TopNavigationBar from '../components/TopNavigationBar';
import DashboardBackGroundElement from '../../../../assets/images/dashboardBackGroundElement';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { getCurrentTheme, getSocialLogin } from '../../../store';
import { getMeData } from '../../../services/meDataService';
import { ThemeContext } from '../../../services/context/ThemingContext';
import { ZendeskAPI } from 'react-zendesk';
import {
  getBrokerData,
  getBrokerDistribution,
} from '../../../services/brokerDataService';

const Layout = ({
  children,
  className,
  history,
  showTop,
  hideSidebar,
  hideMobileLayover,
}) => {
  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const showGradient = window.location.href.includes('dashboard');
  const { t } = useTranslation();
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    if (history.location.search.includes('popup=error')) {
      setShowError(true);
    }

    initializeTheme();

    if (!getSocialLogin() && !hideSidebar) {
      getMeData().then(({ me }) => {
        if (me.role === 'social_login') {
          history.push('/login?popup=expired');
        } else {
          // Initialise the chat component
          getBrokerData().then(({ broker }) => {
            getBrokerDistribution().then(distribution => {
              // Set the widget language
              ZendeskAPI(
                'webWidget',
                'setLocale',
                me?.language === 'en' ? 'en-US' : me?.language,
              );
              // Set theme data for widget
              ZendeskAPI('webWidget', 'updateSettings', {
                color: {
                  theme: getCurrentTheme().brand.primary,
                },
              });
              // Set contact details for broker client
              ZendeskAPI('webWidget', 'identify', {
                name: broker.name,
                email: me.email,
                organization: distribution.name,
              });
            });
          });
        }
      });
    }
  }, []);

  async function initializeTheme() {
    if (!hideSidebar) {
      const { theme } = (await getBrokerData()) || {};
      setTheme(theme?.name);
      // console.log(theme);
    } else {
      setTheme('dark_red');
    }
    await setLoading(false);
  }

  if (loading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Container showGradient={showGradient} id="bdd_dashboard_view">
      {showError ? (
        <ErrorPopup>
          <div>
            <h1>{t('Dashboard.error.title')}</h1>
            <p>{t('Dashboard.error.body')}</p>
            <button
              onClick={() => {
                history.replace('/dashboard');
                setShowError(false);
              }}
            >
              {t('Dashboard.error.button')}
            </button>
          </div>
        </ErrorPopup>
      ) : (
        ''
      )}
      <CssBgElement>
        <DashboardBackGroundElement colors={getCurrentTheme()} />
      </CssBgElement>
      {showTop && <TopNavigationBar hideSidebar={hideSidebar} />}
      {!hideSidebar && <Navigation showLogo={!showTop} />}
      <MainContentWrapper paddingTop={showTop} paddingLeft={hideSidebar}>
        {hideMobileLayover ? null : (
          <MobileLayover>
            <p>{t('mobileLayover')}</p>
          </MobileLayover>
        )}
        <div className={className}>{children}</div>
      </MainContentWrapper>
    </Container>
  );
};

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileLayover = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: fixed; /* Stay in place */
    z-index: 99; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    & p {
      color: white;
      font-size: 3rem;
      width: 80%;
      text-align: center;
      line-height: 1.25;
    }
  }
`;

const ErrorPopup = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    background-color: white;
    border-radius: 3rem;
    width: 90%;
    max-width: 60rem;
    height: 25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: 0.3s fade;

    @keyframes fade {
      from {
        transform: scale(0);
      }

      to {
        transform: scale(1);
      }
    }
  }

  & h1 {
    font-size: 4rem;
    font-weight: 900;
  }

  & p {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 1.6rem;
  }

  & button {
    background-color: ${({ theme }) => theme.brand.primary};
    width: 22.5rem;
    height: 5.1rem;
    border-radius: 0.5rem;
    display: flex;
    font-size: 2rem;
    justify-content: center;
    align-items: center;
    color: white;
    border: 0;
    font-weight: bold;
    margin: 1rem;
    text-decoration: none;
    align-self: center;
    margin-bottom: 2rem;
    margin-right: 2rem;
    z-index: 100;
    cursor: pointer;
    transition: all 0.2s ease 0s;
    margin-top: 4rem;

    &:active {
      outline: none;
      transform: scale(0.98);
    }

    &:focus {
      border: 0 0 0;
      outline: none;
    }

    &:disabled {
      cursor: default;
      background-color: ${({ theme }) => theme.brand.lighter};
    }

    @media screen and (max-width: 1025px) {
      transform: scale(0.9);
      margin-bottom: 1rem;

      &:active {
        transform: scale(0.88);
      }
    }
  }
`;

const CssBgElement = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 100vh;
  z-index: -99;
  padding-left: 25rem;

  & > svg {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background: ${props =>
    props.showGradient
      ? `
  linear-gradient(
    0deg,
    ${getCurrentTheme().brand.primary}0A 33.49%,
    ${getCurrentTheme().brand.secondary}00 109.09%
  )
  `
      : '#FBFBFB'};
  @media screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

const MainContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-left: ${({ paddingLeft }) => (paddingLeft ? '' : '25rem')};
  padding-top: ${({ paddingTop }) => (paddingTop ? '8rem' : '0rem')};

  @media screen and (max-width: 400px) {
    height: 7rem;
    margin-top: 6rem;
  }
`;

Layout.defaultProps = {
  showTop: true,
};

Layout.propTypes = {
  children: node.isRequired,
  history: shape().isRequired,
  className: string.isRequired,
  showTop: bool,
  hideSidebar: bool,
  hideMobileLayover: bool,
};

export default withRouter(Layout);
