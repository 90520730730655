import React from 'react';

const SharedObjects = () => (
  <svg viewBox="0 0 234.29 248.28">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'shared_objects'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M189.93 86.32l-16.24 16.24a1.72 1.72 0 01-2.43 0L163.72 95a.86.86 0 00-1.47.61V178a1.72 1.72 0 01-1.73 1.72H75.38a1.72 1.72 0 01-1.72-1.72V95.62a.86.86 0 00-1.47-.62l-7.54 7.55a1.73 1.73 0 01-2.44 0L46 86.32a1.73 1.73 0 010-2.44l24-23.94a12.78 12.78 0 019-3.74h17.63a22.14 22.14 0 0042.64 0H157a12.81 12.81 0 019 3.74l23.94 23.94a1.73 1.73 0 01-.01 2.44z"
        />
        <path
          className="prefix__cls-1"
          d="M117.15 0a117.16 117.16 0 00-37.38 228.2 5.23 5.23 0 003.33-9.92 106.77 106.77 0 1146.19 4.86l5.27-9a5.23 5.23 0 10-9-5.29l-11.66 19.89a5.23 5.23 0 001.87 7.16l19.89 11.66a5.08 5.08 0 002.5.72 5.23 5.23 0 002.79-9.75l-8.84-5.18A117.15 117.15 0 00117.15 0z"
        />
      </g>
    </g>
  </svg>
);

export default SharedObjects;
