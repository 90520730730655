import React from 'react';

const GardenContent = () => (
  <svg viewBox="0 0 245 194">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'garden_contentmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path className="prefix__cls-1" d="M112.45 22.61h9.42v116.83h-9.42z" />
        <path
          className="prefix__cls-1"
          d="M179.23 47.11H55.09A1.1 1.1 0 0154 45.68a66.45 66.45 0 01126.24 0 1.09 1.09 0 01-1.01 1.43zM6 160.79h211.4v9.42H6a4.71 4.71 0 01-4.71-4.71A4.71 4.71 0 016 160.79z"
        />
        <path
          className="prefix__cls-1"
          d="M242.72 175.64l-35.63-46.23a7.32 7.32 0 00-5.8-2.85H53.08a3 3 0 00-2.33 1.14l-37.44 48.63a11 11 0 0017.35 13.41l32.81-42.48a3 3 0 012.33-1.14h125a3 3 0 012.33 1.14l32.27 41.79a11 11 0 0015.38 2 11 11 0 001.94-15.41z"
        />
        <path
          className="prefix__cls-1"
          d="M32.31 45.28a11 11 0 0111 11V145a1 1 0 01-1 1h-20a1 1 0 01-1-1V56.24a11 11 0 0111-10.96z"
          transform="rotate(-32.84 32.294 95.595)"
        />
      </g>
    </g>
  </svg>
);

export default GardenContent;
