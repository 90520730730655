import React from 'react';
import { shape, bool } from 'prop-types';

const ToolkitIcon = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30.3447"
        cy="30.9673"
        r="30"
        fill={colorsDict.secondaryColor}
      />
      <path
        d="M25.2094 16.7234C23.4752 14.9891 20.9356 14.5513 18.795 15.4098L22.9392 19.554L19.5788 22.9143L15.4608 18.7963C14.6482 20.9189 15.0977 23.4143 16.8085 25.1252C18.5193 26.836 21.013 27.2837 23.1356 26.4711L42.2806 45.6161C43.2085 46.5441 44.713 46.5441 45.641 45.6161C46.5689 44.6882 46.5689 43.1837 45.641 42.2558L26.5229 23.1378C27.3815 20.9972 26.9437 18.4576 25.2094 16.7234Z"
        fill={colorsDict.iconColor}
      />
      <path
        d="M31.5946 33.1171L38.2793 26.4324C39.5595 26.655 40.8613 26.2153 41.6234 25.1261L45.9667 19.2559C46.1883 18.9559 46.136 18.5144 45.8433 18.2216L43.1279 15.5063C42.8351 15.2135 42.3937 15.1613 42.0937 15.3829L36.2234 19.7261C35.1342 20.4892 34.6946 21.791 34.9171 23.0703L28.2324 29.755C27.0396 29.5847 25.7847 29.9586 24.8676 30.8757L16.4604 39.2829C14.9126 40.8306 14.9126 43.3405 16.4604 44.8883C18.0081 46.436 20.518 46.436 22.0658 44.8883L30.473 36.4811C31.391 35.5649 31.7649 34.309 31.5946 33.1171Z"
        fill={colorsDict.iconColor}
      />
    </svg>
  );
};

ToolkitIcon.propTypes = {
  colors: shape().isRequired,
  disabled: bool.isRequired,
};

export default ToolkitIcon;
