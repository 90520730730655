import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import OfferParameters from './ProFlowOffersSelectedOffersSidebar';
import { ProFlowOffersContextProvider as OffersContext } from '../services/context/ProFlowOffersContext.js';
import ProFlowSelectedOffersAccordionBox from './ProFlowSelectedOffersAccordionBox.jsx';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext.js';
import { useTranslation } from 'react-i18next';
import { LoadingSpinner, H1 } from 'wg-fe-ui';

const ProFlowOffersSelectedOffers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { selectedOffers } = useContext(ProFlowStorageContext);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const insuranceTypesSet = new Set();
    selectedOffers.forEach(({ insuranceType }) =>
      insuranceTypesSet.add(insuranceType.key),
    );
    setInsuranceTypes([...insuranceTypesSet]);
    setIsLoading(false);
  }, [selectedOffers]);

  if (isLoading) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner />
      </LoadingSpinnerWrapper>
    );
  }

  if (insuranceTypes?.length === 0) {
    return (
      <NoOfferWrapper>
        <StyledH1>{t(`No offers were added yet`)}</StyledH1>
      </NoOfferWrapper>
    );
  }

  return (
    <MainContent>
      <OffersContext>
        <OfferParameters />
        <Offers>
          {insuranceTypes.map(insuranceType => (
            <>
              <HomeHeader>{t(insuranceType)}</HomeHeader>
              {selectedOffers
                .filter(
                  ({ insuranceType: _insuranceType }) =>
                    _insuranceType.key === insuranceType,
                )
                .map(quoteDetails => {
                  return (
                    <AccordionContainer key={quoteDetails.id}>
                      <ProFlowSelectedOffersAccordionBox
                        id={quoteDetails.id}
                        insurance={quoteDetails.insurance}
                        insuranceCompany={quoteDetails.insuranceCompany}
                        insuranceType={quoteDetails.insuranceType}
                        guarantees={quoteDetails.guarantees}
                        options={quoteDetails.options}
                        language={quoteDetails.language}
                        premium={quoteDetails.premium}
                        promotions={quoteDetails.promotions}
                        quote={quoteDetails.quote}
                        CRM={quoteDetails.CRM}
                        error={quoteDetails.error}
                        originalQuote={quoteDetails}
                      />
                    </AccordionContainer>
                  );
                })}
            </>
          ))}
        </Offers>
      </OffersContext>
    </MainContent>
  );
};

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
`;

const StyledH1 = styled(H1)`
  font-size: 3rem;
`;

const NoOfferWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
`;

const AccordionContainer = styled.div`
  margin-bottom: 3rem;
`;

const HomeHeader = styled.span`
  display: flex;
  width: 100%;
  border-bottom: 2px solid #e4e4e4;
  padding-bottom: 1rem;
  font-weight: bold;
  font-size: 24px;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

const Offers = styled.div`
  margin-left: 1.5rem;
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 20rem auto;
  grid-gap: 3rem;
  margin-bottom: 5rem;
`;

export default ProFlowOffersSelectedOffers;
