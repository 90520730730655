import React from 'react';
import styled from 'styled-components';
import { bool, node, string } from 'prop-types';

const StyledCard = styled.div`
  ${({ hideShadow }) =>
    !hideShadow && 'box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1)'};
  width: 100%;
  margin-bottom: ${({ margin }) => margin || '16px'};
  box-sizing: border-box;
`;
function Card({ children, className, hideShadow, margin, padding }) {
  return (
    <StyledCard
      className={className}
      hideShadow={hideShadow}
      margin={margin}
      padding={padding}
    >
      {children}
    </StyledCard>
  );
}

Card.propTypes = {
  children: node,
  className: string,
  hideShadow: bool,
  margin: string,
  padding: string,
};

export default Card;
