/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Layout from '../../LayoutMarket/views/Layout';
import { getCards } from '../store';
import BigInsuranceCardCarousel from '../components/BigInsuranceCardCarousel';
import { useTranslation } from 'react-i18next';
import { setCurrentStep } from '../../LayoutMarket/store';

import BAIcon from '../../../../../assets/images/ba.jsx';
import MiniOmniumIcon from '../../../../../assets/images/MiniOmnium.jsx';
import OmniumIcon from '../../../../../assets/images/Omnium';
import CompareListDialUp from '../components/CompareListDialUp';

import { getCurrentTheme, addBrowserHistory } from '../../../../store';

import {
  removeDuplicates,
  getNestedObject,
} from '../../../../services/objectService';
import { getInsurances } from '../../../../services/insurancesService';
import { useLocation } from 'react-router';

const Car = ({ match }) => {
  const { t } = useTranslation();
  const { params } = match;
  setCurrentStep(3);
  const [firstScroll, setFirstScroll] = useState(true);
  const [insuranceData, setInsuranceData] = useState({});
  const [companyCards, setCompanyCards] = useState([]);
  const [showTypeButtons, setShowTypeButtons] = useState({
    omnium: true,
    ba: true,
    miniOmnium: true,
  });
  const [allInsuranceCards, setAllInsuranceCards] = useState([]);
  const [loadedItems, setLoadedItems] = useState(
    params.packageId
      ? params.packageId + 1 + (3 - ((params.packageId + 1) % 3))
      : 3,
  );
  const [filterOnInsuranceType, setFilterOnInsuranceType] = useState({
    BA: true,
    'MINI OMNIUM': true,
    OMNIUM: true,
  });
  const [compareItems, setCompareItems] = useState([]);
  let cards = getCards('car');

  let insuranceQuotes;
  if (cards.length > 0) {
    insuranceQuotes = Array.from(
      new Set(
        cards.map(card => ({
          company: card.insurance_company.name,
          insurance: card.insurance_details.name,
        })),
      ),
    );
  }
  insuranceQuotes = removeDuplicates(insuranceQuotes);
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
    if (Object.keys(insuranceData) < 1) {
      getInsurances().then(res => setInsuranceData(res));
    }
    let baCount = 0;
    let miniOCount = 0;
    let omnCount = 0;
    cards.forEach(card => {
      if (getNestedObject(card, ['insurance_details', 'type']) === 'BA') {
        baCount += 1;
      } else if (
        getNestedObject(card, ['insurance_details', 'type']) === 'MINI OMNIUM'
      ) {
        miniOCount += 1;
      } else if (
        getNestedObject(card, ['insurance_details', 'type']) === 'OMNIUM'
      ) {
        omnCount += 1;
      }
    });
    setShowTypeButtons({
      omnium: omnCount >= 1,
      ba: baCount >= 1,
      miniOmnium: miniOCount >= 1,
    });
  }, []);

  useEffect(() => {
    const companyArray = [];
    const insuranceTypes = [];
    if (cards.length > 0) {
      insuranceQuotes.forEach(insuranceQuoteName => {
        companyArray.push({
          [insuranceQuoteName.insurance]: cards
            .filter(
              item =>
                item.insurance_details.name === insuranceQuoteName.insurance &&
                item.insurance_company.name === insuranceQuoteName.company,
            )
            .sort((a, b) => b.score - a.score),
        });
      });
    }

    // make list of all different types of car insurances
    companyArray.forEach(companyArr => {
      Object.keys(companyArr).forEach(insuranceName => {
        insuranceTypes.push({
          insurer: companyArr[insuranceName][0].insurance_company.name,
          type: companyArr[insuranceName][0].insurance_details.type,
        });
      });
    });

    const uniqueInsuranceTypes = removeDuplicates(insuranceTypes);
    // combine insurances based on the different types
    const filteredCompanyArray = uniqueInsuranceTypes.map(typesList => {
      const matchingCompanies = [];
      const tmpObj = {};
      companyArray.forEach((insurancePack, i) => {
        const insuranceTitle = Object.keys(insurancePack)[0];
        const packs = insurancePack[insuranceTitle];
        // making shure key is unique by adding index to it
        tmpObj[`${insuranceTitle}-${i}`] = [];
        packs.forEach(pack => {
          if (
            pack.insurance_company.name === typesList.insurer &&
            pack.insurance_details.type === typesList.type
          ) {
            tmpObj[`${insuranceTitle}-${i}`].push(pack);
          } else {
            delete tmpObj[`${insuranceTitle}-${i}`];
          }
        });
      });

      Object.keys(tmpObj).forEach(key => {
        matchingCompanies.push({ [key]: tmpObj[key] });
      });

      return matchingCompanies;
    });

    setAllInsuranceCards(companyArray);
    setCompanyCards(filteredCompanyArray);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (firstScroll) {
      if (companyCards.length > 0) {
        if (params.packageName) {
          if (
            document.querySelector(`.${params.packageName}${params.packageId}`)
          ) {
            setTimeout(() => {
              document
                .querySelector(`.${params.packageName}${params.packageId}`)
                .scrollIntoView({ behavior: 'smooth', block: 'center' });
            }, 350);
            setFirstScroll(false);
          }
        }
      }
    }
  }, [companyCards]);

  useEffect(() => {
    const allowedTypes = [];
    if (cards.length > 0) {
      Object.keys(filterOnInsuranceType).forEach(key => {
        if (filterOnInsuranceType[key]) {
          allowedTypes.push(key);
        }
      });
    }

    const filteredCompanyCards = [];
    const insuranceTypes = [];

    if (cards.length > 0) {
      allInsuranceCards.forEach(insuranceObj => {
        const bool = Object.keys(insuranceObj).map(insuranceName =>
          allowedTypes.includes(
            insuranceObj[insuranceName][0].insurance_details.type,
          ),
        )[0];

        if (bool) {
          filteredCompanyCards.push(insuranceObj);
        }
      });
    }

    // make list of all different types of car insurances
    filteredCompanyCards.forEach(companyArr => {
      Object.keys(companyArr).forEach(insuranceName => {
        insuranceTypes.push({
          insurer: companyArr[insuranceName][0].insurance_company.name,
          type: companyArr[insuranceName][0].insurance_details.type,
        });
      });
    });

    const uniqueInsuranceTypes = removeDuplicates(insuranceTypes);

    // combine insurances based on the different types
    const filteredCompanyArray = uniqueInsuranceTypes.map(typesList => {
      const matchingCompanies = [];
      const tmpObj = {};
      filteredCompanyCards.forEach((insurancePack, i) => {
        const insuranceTitle = Object.keys(insurancePack)[0];
        const packs = insurancePack[insuranceTitle];
        // making shure key is unique by adding index to it
        tmpObj[`${insuranceTitle}-${i}`] = [];
        packs.forEach(pack => {
          if (
            pack.insurance_company.name === typesList.insurer &&
            pack.insurance_details.type === typesList.type
          ) {
            tmpObj[`${insuranceTitle}-${i}`].push(pack);
          } else {
            delete tmpObj[`${insuranceTitle}-${i}`];
          }
        });
      });

      Object.keys(tmpObj).forEach(key => {
        matchingCompanies.push({ [key]: tmpObj[key] });
      });

      return matchingCompanies;
    });

    if (allInsuranceCards.length !== 0) {
      setCompanyCards(filteredCompanyArray);
    }
  }, [filterOnInsuranceType]);

  const handleCheckMarkChange = e => {
    setFilterOnInsuranceType({
      ...filterOnInsuranceType,
      [e.target.name]: e.target.checked,
    });
  };

  const addToCompareItems = insuranceItem => {
    if (compareItems.length === 0) {
      // store first item in array
      setCompareItems([insuranceItem]);
    } else {
      // check if id is already in array, if not store it
      const doesIdExist = compareItems.some(
        ({ id }) => id === insuranceItem.id,
      );
      !doesIdExist ? setCompareItems([...compareItems, insuranceItem]) : null;
    }
  };

  const removeCompareItem = id => {
    setCompareItems(compareItems.filter(obj => obj.id !== id));
  };
  return (
    <Layout noMatches={cards.length === 0}>
      <Header>
        <h1>{t('MarketCar.title')}</h1>
        <p>{t('MarketCar.text')}</p>
      </Header>
      {cards.length !== 0 ? (
        <Form>
          <FormContainer>
            <FilterText>{t('MarketCar.carTypeFilter')}</FilterText>
            <Filter>
              {showTypeButtons.ba ? (
                <FilterCheckMarkContainer checked={filterOnInsuranceType['BA']}>
                  <BAIcon
                    title={t('MarketCar.BA')}
                    colors={getCurrentTheme().brand.primary}
                    height="4rem"
                  />
                  <FilterCheckMarkInput
                    onChange={handleCheckMarkChange}
                    type="checkbox"
                    name="BA"
                    checked={filterOnInsuranceType['BA']}
                    id="BA"
                  />
                  <FilterCheckMarkLabel
                    data-test-id={`underwriting_car_BA_button`}
                    htmlFor="BA"
                  >
                    {t('MarketCar.BA')}
                  </FilterCheckMarkLabel>
                </FilterCheckMarkContainer>
              ) : (
                ''
              )}
              {showTypeButtons.miniOmnium ? (
                <FilterCheckMarkContainer
                  checked={filterOnInsuranceType['MINI OMNIUM']}
                >
                  <MiniOmniumIcon
                    title={t('MarketCar.MINI_OMNIUM')}
                    colors={getCurrentTheme().brand.primary}
                    height="4rem"
                  />
                  <FilterCheckMarkInput
                    onChange={handleCheckMarkChange}
                    type="checkbox"
                    name="MINI OMNIUM"
                    checked={filterOnInsuranceType['MINI OMNIUM']}
                    id="MINI OMNIUM"
                  />
                  <FilterCheckMarkLabel
                    htmlFor="MINI OMNIUM"
                    data-test-id={`underwriting_car_mini_omnium_button`}
                  >
                    {t('MarketCar.MINI_OMNIUM')}
                  </FilterCheckMarkLabel>
                </FilterCheckMarkContainer>
              ) : (
                ''
              )}
              {showTypeButtons.omnium ? (
                <FilterCheckMarkContainer
                  checked={filterOnInsuranceType['OMNIUM']}
                >
                  <OmniumIcon
                    colors={getCurrentTheme().brand.primary}
                    height="4rem"
                    title={t('MarketCar.OMNIUM')}
                  />
                  <FilterCheckMarkInput
                    onChange={handleCheckMarkChange}
                    type="checkbox"
                    name="OMNIUM"
                    checked={filterOnInsuranceType['OMNIUM']}
                    id="OMNIUM"
                  />
                  <FilterCheckMarkLabel
                    data-test-id={`underwriting_car_omnium_button`}
                    htmlFor="OMNIUM"
                  >
                    {t('MarketCar.OMNIUM')}
                  </FilterCheckMarkLabel>
                </FilterCheckMarkContainer>
              ) : (
                ''
              )}
            </Filter>
          </FormContainer>
        </Form>
      ) : null}
      <List className="bigInsuranceCardList" hasItems={cards.length !== 0}>
        {companyCards.slice(0, loadedItems).map((row, i) => {
          return (
            <BigInsuranceCardCarousel
              key={i}
              id={i}
              row={row}
              selectedItem={params.activeItem ? params.activeItem : 0}
              selectedPacks={params.selectedPackages}
              insuranceCompanies={insuranceData}
              isSelected={parseInt(params.packageId) === i}
              addToCompareItems={res => addToCompareItems(res)}
              compareItems={compareItems}
            />
          );
        })}
        {filterOnInsuranceType.length === 0 ? (
          <p>Gelieve één type autoverzekering aan te duiden</p>
        ) : null}
        {cards.length === 0 ? (
          <NoMatchesError>{t('MarketLayout.noMatches')}</NoMatchesError>
        ) : null}
        {companyCards.length > loadedItems ? (
          <Button onClick={() => setLoadedItems(loadedItems + 3)}>
            {t('MarketCar.loadTxt')}
          </Button>
        ) : null}
      </List>
      <CompareListDialUp
        hidden={compareItems.length === 0}
        compareItems={compareItems}
        removeId={id => removeCompareItem(id)}
        insurType="car"
      />
    </Layout>
  );
};

Car.propTypes = {
  match: shape().isRequired,
};

const NoMatchesError = styled.h3`
  font-size: 3rem;
  color: white;
  text-align: left;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const Filter = styled.div`
  display: flex;
`;

const Button = styled.button`
  background-color: white;
  width: 70%;
  height: 5.1rem;
  border-radius: 0.5rem;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.brand.primary};
  border: 0;
  font-weight: bold;
  text-decoration: none;
  align-self: flex-end;
  margin: 2rem auto;
  z-index: 11;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
    box-shadow: 0 0 0.35rem 0 rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  @media screen and (max-width: 1025px) {
    transform: scale(0.9);
    margin-bottom: 1rem;

    &:active {
      transform: scale(0.88);
    }
  }
`;

const List = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: all 0.1s ease 0s;
  margin-top: ${props => (props.hasItems ? '5rem' : '0')};
  align-items: center;

  @media print {
    &.hidePrint {
      display: none;
    }
  }
`;
const Header = styled.header`
  color: white;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 2rem;

  & h1 {
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

const Form = styled.form`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const FormContainer = styled.div`
  width: 90rem;
`;

const FilterText = styled.p`
  color: white;
  font-size: 2rem;
  margin-bottom: 3.5rem;
  font-weight: 700;
`;

const FilterCheckMarkContainer = styled.div`
  margin-right: 2rem;
  opacity: ${props => (props.checked ? 1 : 0.5)};
  transition: 0.3s;
  display: flex;
  align-items: center;
`;

const FilterCheckMarkInput = styled.input`
  display: none;
`;
const FilterCheckMarkLabel = styled.label`
  background-color: white;
  color: ${({ theme }) => theme.brand.primary};
  cursor: pointer;
  padding: 1.5rem 4rem;
  border-radius: 2.5rem;
  font-size: 1.8rem;
  padding-left: 7rem;
  font-weight: 700;
`;

export default Car;
