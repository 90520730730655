import React from 'react';
import { string } from 'prop-types';

const automatic_emergency_braking = ({ className, color }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <g fill={color}>
      <path d="m213 285.12a72.15 72.15 0 1 1 72.12-72.12 72.23 72.23 0 0 1 -72.12 72.12zm0-99.41a27.26 27.26 0 1 0 27.22 27.29 27.29 27.29 0 0 0 -27.22-27.29z" />
      <path d="m345.22 80.72a186.09 186.09 0 0 0 -90.16-50 2.05 2.05 0 0 0 -2.5 2v49.49a2 2 0 0 0 1.43 2 134.66 134.66 0 0 1 94.13 128.79c0 74.52-60.63 135.15-135.15 135.15a134.06 134.06 0 0 1 -81.08-27 136.32 136.32 0 0 1 -48.16-68.48 2 2 0 0 0 -2-1.45h-49.38a2.05 2.05 0 0 0 -1.59.77 2 2 0 0 0 -.4 1.72 187.06 187.06 0 0 0 369.64-40.71 185.8 185.8 0 0 0 -54.78-132.28z" />
      <path d="m77.83 212.29c0-1.16 0-2.32 0-3.47 0-1.15.08-2.3.15-3.43.14-2.36.32-4.55.54-6.65l.38-3.35.09-.62.07-.5c.1-.76.21-1.53.32-2.23l.16-.93c.14-.84.27-1.67.4-2.4 0-.16.07-.33.1-.51l.07-.36c.14-.84.31-1.69.49-2.52a3.82 3.82 0 0 0 .08-.39l.22-1c.15-.68.29-1.36.46-2 .25-1.09.52-2.16.8-3.23.62-2.33 1.22-4.41 1.84-6.38a.56.56 0 0 0 0-.12c.32-1 .66-2 1-3 .42-1.22.85-2.43 1.3-3.64.33-.86.65-1.7 1-2.54.75-1.88 1.55-3.77 2.37-5.59l.28-.61c.26-.56.52-1.12.79-1.68.58-1.21 1.17-2.42 1.8-3.65.3-.61.62-1.22 1-1.85.68-1.3 1.41-2.59 2.16-3.89.53-.95 1.08-1.87 1.63-2.77l.85-1.38c.35-.58.72-1.16 1.11-1.75.76-1.18 1.54-2.35 2.38-3.55.42-.61.84-1.23 1.26-1.82a143.05 143.05 0 0 1 10-12.33c.73-.8 1.47-1.58 2.22-2.37s1.49-1.55 2.26-2.32c1.44-1.45 3-3 4.69-4.47 3.21-2.92 6.58-5.69 10-8.25l.29-.22c.4-.3.79-.58 1.25-.9 1.18-.87 2.41-1.74 3.85-2.71.5-.34 1-.68 1.54-1a134.31 134.31 0 0 1 37.05-16.92c2.14-.6 4.31-1.16 6.46-1.65a135 135 0 0 1 30.46-3.52h.56a2.05 2.05 0 0 0 2.17-2v-73.77a2.05 2.05 0 0 0 -2-2h-.73a212.82 212.82 0 0 0 -213 212.23 2 2 0 0 0 2.05 2.05h73.73a2 2 0 0 0 2.05-2.04z" />
    </g>
  </svg>
);

automatic_emergency_braking.propTypes = {
  className: string,
  color: string,
};

export default automatic_emergency_braking;
