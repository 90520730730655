import React from 'react';
import { string, shape } from 'prop-types';

const SvgComponent = ({ className, colors }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors.brand.primary}
      fillRule="evenodd"
    />
    <g fill="#fff">
      <path d="m306.46 186a6.06 6.06 0 0 0 -6 6.06l-3.7 40.68a8.14 8.14 0 0 1 .56 2.82 9.34 9.34 0 0 1 -1.22 4.39v.05h-.1a9.44 9.44 0 0 1 -1.47 2.19l-17.68 17.7-2.43-2.43 16.28-16.3 1.4-1.4a5.66 5.66 0 0 0 1.56-3.19c0-.14.06-.27.08-.4a6.16 6.16 0 0 0 -.67-3.51 5.8 5.8 0 0 0 -1-1.46 6 6 0 0 0 -8.56 0l-14.76 14.8-.84.19a30.9 30.9 0 0 0 -14.72 7.93l-8.19 7.88a11.78 11.78 0 0 0 -3.6 8.47v19.23h-.4a3.53 3.53 0 0 0 -3.52 3.52v20.24a3.53 3.53 0 0 0 3.52 3.54h39.5a3.53 3.53 0 0 0 3.53-3.53v-20.24a3.53 3.53 0 0 0 -3.52-3.53h-.28v-9.62l29.35-29.37a10 10 0 0 0 2.94-7.09v-51.59a6.06 6.06 0 0 0 -6.06-6.03z" />
      <path d="m93.54 186a6.06 6.06 0 0 1 6 6.06l3.7 40.68a8.14 8.14 0 0 0 -.56 2.82 9.34 9.34 0 0 0 1.22 4.39v.05h.1a9.44 9.44 0 0 0 1.47 2.19l17.68 17.7 2.43-2.43-16.28-16.3-1.4-1.4a5.66 5.66 0 0 1 -1.56-3.19c0-.14-.06-.27-.08-.4a6.16 6.16 0 0 1 .67-3.51 5.8 5.8 0 0 1 1-1.46 6 6 0 0 1 8.56 0l14.76 14.8.84.19a30.9 30.9 0 0 1 14.72 7.93l8.19 7.88a11.78 11.78 0 0 1 3.6 8.47v19.23h.4a3.53 3.53 0 0 1 3.52 3.52v20.24a3.53 3.53 0 0 1 -3.52 3.54h-39.5a3.53 3.53 0 0 1 -3.53-3.53v-20.24a3.53 3.53 0 0 1 3.52-3.53h.28v-9.62l-29.37-29.37a10 10 0 0 1 -2.94-7.09v-51.59a6.06 6.06 0 0 1 6.08-6.03z" />
      <path d="m200 83a85 85 0 1 0 85 85 85 85 0 0 0 -85-85zm-12 145.69a5.19 5.19 0 0 1 -6.6 5 68.39 68.39 0 0 1 -43.4-37.3 5.18 5.18 0 0 1 4.7-7.36h22.75a22.62 22.62 0 0 1 22.55 22.62zm12-45.8a10.19 10.19 0 1 1 10.19-10.19 10.19 10.19 0 0 1 -10.19 10.19zm62.73 13.41a68.32 68.32 0 0 1 -45.73 37.94 5.17 5.17 0 0 1 -6.36-5v-17.59a22.63 22.63 0 0 1 22.66-22.65h24.7a5.17 5.17 0 0 1 4.73 7.3zm-1.38-30.36-31.1-12.56a80.8 80.8 0 0 0 -60.48 0l-31.11 12.56a5.18 5.18 0 0 1 -6.42-5.63 68.21 68.21 0 0 1 135.54 0 5.19 5.19 0 0 1 -6.43 5.63z" />
    </g>
  </svg>
);

SvgComponent.propTypes = {
  className: string,
  colors: shape(),
};

export default SvgComponent;
