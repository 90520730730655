/* stylelint-disable value-keyword-case, declaration-no-important */
import React, { useState } from 'react';
import styled from 'styled-components';
import { string, node, bool } from 'prop-types';
// import PersonalData from '../store';
import { detect } from 'detect-browser';
import { Field, ErrorMessage } from 'formik';
import checkmark from '../../assets/images/validation-checkmark.svg';
import errormark from '../../assets/images/validation-error.svg';

const PersonalInput = ({
  type,
  name,
  children,
  className,
  error,
  valid,
  placeholder,
  disabled,
}) => {
  const browser = detect();
  const [focus, setFocus] = useState();
  const [iconRight, setIconRight] = useState('1rem');

  const handleFocus = () => {
    setFocus(true);
    switch (browser.name) {
      case 'safari':
        setIconRight('3.5rem');
        break;
      case '':
        setIconRight('3.5rem');
        break;
      default:
        setIconRight('1rem');
        break;
    }
  };
  return (
    <>
      <StyledLabel
        className={className}
        htmlFor={name}
        name={name}
        disabled={disabled}
      >
        <p>{children}</p>
        <InputContainer>
          <StandardInput
            id={name}
            onFocus={() => handleFocus()}
            data-test-id={`toolkit_termination_generator_${name}`}
            error={error}
            valid={valid}
            type={type}
            component="textarea"
            name={name}
            placeholder={placeholder}
            disabled={disabled}
          />
          {error ? (
            <Icon
              focus={focus}
              right={iconRight}
              browser={browser.name}
              src={errormark}
              alt="error"
            />
          ) : valid ? (
            <Icon
              focus={focus}
              right={iconRight}
              browser={browser.name}
              src={checkmark}
              alt="checkmark"
            />
          ) : null}
        </InputContainer>
        <ErrorMss name={name} component="p" />
      </StyledLabel>
    </>
  );
};

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const Icon = styled.img`
  position: absolute;
  right: ${props => (props.focus ? props.right : '1rem')};
  align-self: flex-end;
  bottom: 1rem;
  transition: 0.1s;
`;

const StyledLabel = styled.label`
  position: relative;
  line-height: 2.1rem;
  font-size: 1.5rem;
  max-width: none !important;
  color: #a29c95;
  font-weight: normal;
  width: 100%;
  margin-bottom: 2rem;

  & > p {
    display: flex;
    align-items: baseline;
    transform-origin: 0 0;
    transition: all 0.1s ease;
    margin-bottom: 0.5rem;
  }
`;

const StandardInput = styled(Field)`
  width: 100%;
  border: 1px solid #ccc;
  resize: vertical;
  min-height: 10rem;
  font-size: 1.5rem;
  transition: all 0.1s ease;
  padding: 0.5rem;
  padding-left: 1rem;
  background: white;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 3px;

  ${props =>
    props.error
      ? `
    border-color: red;
  `
      : ``}

  ${props =>
    props.valid
      ? `
    border-color: #49C562;
  `
      : ``}

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const ErrorMss = styled(ErrorMessage)`
  font-size: 1.1rem;
  color: red;
  margin-left: 0.6rem;
  text-align: right !important;
  position: absolute;
  bottom: -2.5rem;
  right: 0;
  z-index: 21;
  margin-top: 0.5rem;
`;

PersonalInput.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  children: node.isRequired,
  error: bool,
  valid: bool,
  className: string,
  placeholder: string,
  disabled: bool,
  // getInput: func.isRequired,
};

export default PersonalInput;
