import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { bool, array, func, string } from 'prop-types';
import Arrow from '../../../../../assets/images/arrow';
import CompareListItem from './CompareListItem';
import { getCompareMatrix } from '../store';
import CompareMatrix from './CompareMatrix';
import { getCurrentTheme } from '../../../../store';
import { useTranslation } from 'react-i18next';

const CompareListDialUp = ({ hidden, compareItems, removeId, insurType }) => {
  const { t } = useTranslation();
  const [isExtended, setIsExtended] = useState(false);
  const [showMatrix, setShowMatrix] = useState(false);
  const [idList, setIdList] = useState([]);
  const [comparisonList, setComparisonList] = useState({});

  useEffect(() => {
    const tempIdList = [];
    compareItems.map(({ id }) => {
      tempIdList.push(id);
    });
    setIdList(tempIdList);
  }, [compareItems]);

  useEffect(() => {
    if (showMatrix) {
      setIsExtended(true);
      getCompareData();
    }
  }, [showMatrix]);

  useEffect(() => {
    if (isExtended && showMatrix) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
    if (isExtended) {
      document
        .querySelector('.bigInsuranceCardList')
        .classList.add('hidePrint');
    } else {
      document
        .querySelector('.bigInsuranceCardList')
        .classList.remove('hidePrint');
    }
  }, [isExtended, showMatrix]);

  useEffect(() => {
    if (showMatrix) {
      getCompareData();
    }
  }, [idList]);

  const getCompareData = () => {
    getCompareMatrix(insurType, idList)
      .then(({ data }) => {
        setComparisonList(data.comparison);
      })
      .catch(res => console.error(res));
  };

  return (
    <Container hidden={hidden} isExtended={isExtended}>
      <Top isExtended={isExtended}>
        <ArrowButton
          dataTestId="underwriting_compare_list_open"
          isExtended={isExtended}
          onClick={() => {
            setIsExtended(!isExtended);
          }}
          colors={getCurrentTheme().brand.primary}
        />
        <CompareButtonContainer>
          <SaveButton
            isExtended={isExtended}
            onClick={() => window.print()}
            data-test-id="underwriting_compare_list_view_changer"
          >
            {t('print')}
          </SaveButton>
          <CompareButton
            data-test-id="underwriting_compare_list_view_changer"
            onClick={() => {
              if (idList.length >= 2) setShowMatrix(!showMatrix);
            }}
            isDisabled={idList.length < 2}
          >
            <CompareButtonNumber isDisabled={idList.length < 2}>
              {idList.length}
            </CompareButtonNumber>
            {showMatrix ? (
              <CompareButtonText>
                {t(`Checkout.matrix_button.list`)}
              </CompareButtonText>
            ) : (
              <CompareButtonText>
                {t(`Checkout.matrix_button.compareItems`)}
              </CompareButtonText>
            )}
          </CompareButton>
        </CompareButtonContainer>
      </Top>
      {showMatrix ? (
        <MatrixView isExtended={isExtended}>
          <CompareMatrix
            comparisonList={comparisonList}
            packs={compareItems}
            insurType={insurType}
          />
        </MatrixView>
      ) : (
        <ListView isExtended={isExtended}>
          {compareItems.map((compareItem, i) => (
            <>
              <CompareListItem
                sortKey={i}
                key={compareItem.id}
                id={compareItem.id}
                removeItem={id => removeId(id)}
                insurerName={compareItem.insurerName}
                insuranceName={compareItem.insuranceName}
                insurerLogo={compareItem.insurerLogo}
                packages={compareItem.packages}
                selectedPackages={compareItem.selectedPackages}
                premium={compareItem.premium}
                score={compareItem.score}
              />
            </>
          ))}
        </ListView>
      )}
    </Container>
  );
};

const CompareButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SaveButton = styled.button`
  transition: all 0.3s;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.ui.interactive : theme.brand.primary};
  color: white;
  height: 4rem;
  padding: 1rem 2rem;
  border-radius: 2.5rem;
  margin-right: 2rem;
  opacity: ${({ isExtended }) => (isExtended ? 1 : 0)};
  border: none;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: ${props => (props.isDisabled ? `default` : `pointer`)};
  :focus {
    outline: none;
  }
`;

const ArrowButton = styled(Arrow)`
  cursor: pointer;
  transform: ${props => (props.isExtended ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const CompareButtonNumber = styled.span`
  transition: 0.3s;
  background-color: white;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.ui.interactive : theme.brand.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
`;

const CompareButtonText = styled.span`
  margin-left: 2rem;
  line-height: 1;
`;

const CompareButton = styled.button`
  transition: 0.3s;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.ui.interactive : theme.brand.primary};
  color: white;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  border-radius: 2.5rem;
  margin-right: 2rem;
  border: none;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: ${props => (props.isDisabled ? `default` : `pointer`)};
  :focus {
    outline: none;
  }
`;

const Top = styled.div`
  transition: background-color 0.3s;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7rem;
  background-color: ${({ theme, isExtended }) =>
    isExtended ? theme.brand.secondary : 'white'};

  .list {
    display: none;
  }
  @media print {
    display: ${({ isExtended }) => (isExtended ? 'none' : 'flex')};
  }
`;

const ListView = styled.ul`
  transition: max-height 0.3s
    ${props => (props.isExtended ? `ease-in` : `ease-out`)};
  max-height: ${props => (props.isExtended ? `48vh` : `0`)};
  display: flex;
  flex-direction: column;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const MatrixView = styled.div`
  transition: max-height 0.3s
    ${props => (props.isExtended ? `ease-in` : `ease-out`)};
  max-height: ${props => (props.isExtended ? `90vh` : `0`)};
  @media print {
    width: 100%;
    max-height: ${props => (props.isExtended ? `100vh` : `0`)};
  }
`;

const Container = styled.div`
  z-index: 11;
  width: 100%;
  position: fixed;
  bottom: ${props => (props.hidden ? `-6rem` : `0`)};
  left: 0;
  background-color: white;
  transition: bottom 0.3s ease-out;
  box-shadow: ${props =>
    props.isExtended
      ? `0px -20px 1rem 100rem rgb(0 0 0 / 50%)`
      : '0 0 1rem rgba(0, 0, 0, 0.15)'};
  @media print {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

CompareListDialUp.propTypes = {
  hidden: bool.isRequired,
  compareItems: array.isRequired,
  removeId: func.isRequired,
  insurType: string.isRequired,
};

export default CompareListDialUp;
