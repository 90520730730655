import React from 'react';
import { shape } from 'prop-types';

const StepIconHouse = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 54 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: `100%`, height: `100%` }}
  >
    <title>House</title>
    <g>
      <path
        d="M46.8451 15.5514L49.8698 44.2913C49.9189 44.7585 49.5806 45.178 49.112 45.2273L33.7449 46.8446C33.5925 46.8606 33.45 46.7943 33.3624 46.6808C33.3162 46.6214 33.2856 46.5476 33.277 46.4657L31.9101 33.4782C31.8609 33.011 31.4413 32.6713 30.9742 32.7205L23.7761 33.478C23.6096 33.4956 23.4594 33.5599 23.337 33.6584C23.1157 33.833 22.9868 34.1134 23.0184 34.414L24.3853 47.4015C24.4099 47.6358 24.2407 47.8448 24.0064 47.8695L8.63789 49.4869C8.43042 49.5088 8.2306 49.4542 8.07045 49.3426C7.86937 49.2082 7.72913 48.9875 7.70194 48.7292L4.67799 19.9964C4.64739 19.7056 4.76847 19.4189 4.99771 19.2378L16.7842 9.94327L17.6129 9.28952L22.5687 5.38166L23.9997 4.25355C24.1274 4.15163 24.2796 4.0928 24.4362 4.07632C24.5957 4.05953 24.7584 4.08665 24.9075 4.16086L26.7767 5.09434L32.762 8.08189L33.3856 8.393L34.0077 8.70426L33.6224 5.04314C33.5732 4.57597 33.9129 4.15635 34.3801 4.10719L38.6142 3.66157C38.8146 3.64048 39.0054 3.69032 39.1615 3.79091C39.3726 3.92566 39.5219 4.15111 39.5502 4.41927L40.3334 11.8615L46.3791 14.8798C46.6396 15.0093 46.8147 15.2635 46.8451 15.5514Z"
        fill={colors.brand.primary}
      />
      <path
        d="M32.3156 8.9767L32.9381 9.28731L32.9391 9.2878L33.5602 9.59857L35.1933 10.4156L35.0022 8.59959L34.6324 5.08615L38.5712 4.67162L39.3389 11.9661L39.3963 12.5113L39.8867 12.7562L45.8592 15.7379L48.8597 44.2483L34.2114 45.79L32.9046 33.3735C32.7976 32.3571 31.8859 31.619 30.8695 31.726L23.6715 32.4835C23.3124 32.5213 22.9829 32.6609 22.7139 32.8763C22.2358 33.2554 21.9552 33.8655 22.0239 34.5187L23.3307 46.9351L8.68091 48.4769L5.681 19.9725L17.4034 10.7285L17.4035 10.7284L18.2321 10.0748L18.2323 10.0747L23.1878 6.16698L23.1879 6.16689L24.5445 5.09737L26.3299 5.98897L26.3301 5.98907L32.3154 8.97663L32.3156 8.9767Z"
        stroke={colors.brand.dark}
        strokeWidth="2"
      />
    </g>
  </svg>
);

StepIconHouse.propTypes = {
  colors: shape().isRequired,
};

export default StepIconHouse;
