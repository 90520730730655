/* stylelint-disable value-keyword-case */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { string, bool, func } from 'prop-types';
import { getBrokerData } from '../services/brokerDataService';

const Balloon = ({ text, timeStamp, left, shown, setShown }) => {
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    initializeAvatar();
  }, []);

  async function initializeAvatar() {
    const { broker } = (await getBrokerData()) || {};
    const [{ href }] = broker?.links?.filter(
      ({ rel }) => rel === 'assistant_avatar',
    );

    setAvatar(href);
  }

  useEffect(() => {
    let shownTimeout;
    if (!shown) {
      shownTimeout = setTimeout(() => {
        setShown(true);
      }, 600);
    }
    return () => {
      if (!shown) {
        clearTimeout(shownTimeout);
        setShown(true);
      }
    };
  }, []);

  // TODO: Change image shizzle
  return (
    <Container left={left} shown={shown}>
      {left ? <LouisePicture src={`${avatar}`} /> : null}
      <Text left={left} dangerouslySetInnerHTML={{ __html: text }} />
      <TimeStamp left={left}>{timeStamp}</TimeStamp>
    </Container>
  );
};

const Container = styled.div`
  align-self: ${props => (props.left ? 'flex-start' : 'flex-end')};
  color: white;
  display: inline-block;
  max-width: 60rem;
  position: relative;
  margin: 0;
  margin-top: -1.5rem;
  opacity: 1;
  animation: ${props =>
    !props.shown
      ? props.left
        ? 'animation 0.6s ease-in-out'
        : 'animation 0.6s ease-in-out'
      : 'none'};

  @media screen and (max-width: 1010px) {
    max-width: calc(100% - 5rem);
  }

  @keyframes animation {
    from {
      margin-bottom: -7.6rem;
      opacity: 0;
    }

    to {
      margin-bottom: 0;
      opacity: 1;
    }
  }
`;

const LouisePicture = styled.img`
  position: absolute;
  left: -3rem;
  bottom: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #c4c4c4;
`;

const Text = styled.p`
  padding: 1.2rem 1.8rem;
  font-size: 1.8rem;
  border-radius: ${props => (props.left ? '2rem 2rem 2rem 0' : '2rem 2rem 0')};
  opacity: ${props => (!props.left ? '0.6' : '1')};
  background-color: ${({ theme }) => theme.brand.primary};
`;

const TimeStamp = styled.p`
  color: ${({ theme }) => theme.brand.primary};
  font-size: 1.1rem;
  width: 100%;
  text-align: ${props => (props.left ? 'left' : 'right')};
`;

Balloon.defaultProps = {
  text: '',
  timeStamp: '',
  left: false,
  shown: false,
  setShown: () => {},
};

Balloon.propTypes = {
  text: string,
  timeStamp: string,
  left: bool,
  shown: bool,
  setShown: func,
};

export default Balloon;
