import React from 'react';
import { string } from 'prop-types';
import ALLRISK from '../../../../../assets/images/extentions/ALLRISK';
import ASSISTANCE_VEHICLE from '../../../../../assets/images/extentions/ASSISTANCE_VEHICLE';
import ASSISTANCE_PERSON from '../../../../../assets/images/extentions/ASSISTANCE_PERSON';
import BICYCLE from '../../../../../assets/images/extentions/BICYCLE';
import BUSINESS from '../../../../../assets/images/extentions/BUSINESS';
import CONTENT from '../../../../../assets/images/extentions/CONTENT';
import DRIVER from '../../../../../assets/images/extentions/DRIVER';
import EXTRA from '../../../../../assets/images/extentions/EXTRA';
import GARDEN from '../../../../../assets/images/extentions/GARDEN';
import INDIRECTRISK from '../../../../../assets/images/extentions/INDIRECTRISK';
import JOKER from '../../../../../assets/images/extentions/JOKER';
import LEGAL from '../../../../../assets/images/extentions/LEGAL';
import LEGAL_PLUS from '../../../../../assets/images/extentions/LEGAL_PLUS';
import LEISURE from '../../../../../assets/images/extentions/LEISURE';
import POOL from '../../../../../assets/images/extentions/POOL';
import REPLACEMENT from '../../../../../assets/images/extentions/REPLACEMENT';
import SUPPORT from '../../../../../assets/images/extentions/SUPPORT';
import SURROUND from '../../../../../assets/images/extentions/SURROUND';
import THEFT from '../../../../../assets/images/extentions/THEFT';
import THEFTPLUS from '../../../../../assets/images/extentions/THEFT+';
import VEHICLE from '../../../../../assets/images/extentions/VEHICLE';
import AFTER_FIRE from '../../../../../assets/images/extentions/AFTER_FIRE';
import MOBILITY from '../../../../../assets/images/extentions/MOBILITY';
import CONSUMER from '../../../../../assets/images/extentions/CONSUMER';
import PROPERTY from '../../../../../assets/images/extentions/PROPERTY';
import INCOME from '../../../../../assets/images/extentions/INCOME';

const LoadingExtentionIcons = ({ type, color }) => {
  switch (type) {
    case 'ALLRISK':
      return <ALLRISK color={color} />;
    case 'ASSISTANCE_VEHICLE':
      return <ASSISTANCE_VEHICLE color={color} />;
    case 'ASSISTANCE_PERSON':
      return <ASSISTANCE_PERSON color={color} />;
    case 'BICYCLE':
      return <BICYCLE color={color} />;
    case 'BUSINESS':
      return <BUSINESS color={color} />;
    case 'CONTENT':
      return <CONTENT color={color} />;
    case 'DRIVER':
      return <DRIVER color={color} />;
    case 'EXTRA':
      return <EXTRA color={color} />;
    case 'FULL_ASSISTANCE':
      return <ASSISTANCE_VEHICLE color={color} />;
    case 'GARDEN':
      return <GARDEN color={color} />;
    case 'INDIRECTRISK':
      return <INDIRECTRISK color={color} />;
    case 'JOKER':
      return <JOKER color={color} />;
    case 'LEGAL':
      return <LEGAL color={color} />;
    case 'LEGAL_PLUS':
      return <LEGAL_PLUS color={color} />;
    case 'LEISURE':
      return <LEISURE color={color} />;
    case 'POOL':
      return <POOL color={color} />;
    case 'REPLACEMENT':
      return <REPLACEMENT color={color} />;
    case 'ROAD_USER':
      return <ASSISTANCE_PERSON color={color} />;
    case 'SUPPORT':
      return <SUPPORT color={color} />;
    case 'SURROUND':
      return <SURROUND color={color} />;
    case 'THEFT':
      return <THEFT color={color} />;
    case 'THEFT+':
      return <THEFTPLUS color={color} />;
    case 'VEHICLE':
      return <VEHICLE color={color} />;
    case 'SUPPORT_AFTER_FIRE':
      return <AFTER_FIRE color={color} />;
    case 'MOBILITY':
      return <MOBILITY color={color} />;
    case 'CONSUMER':
      return <CONSUMER color={color} />;
    case 'PROPERTY':
      return <PROPERTY color={color} />;
    case 'INCOME':
      return <INCOME color={color} />;
    default:
      return <p>[No Icon found]</p>;
  }
};

LoadingExtentionIcons.defaultProps = {
  type: '',
  color: '#FFFFFF',
};

LoadingExtentionIcons.propTypes = {
  type: string,
  color: string,
};

export default LoadingExtentionIcons;
