import React, { useState, useEffect, createRef, useContext } from 'react';
import _ from 'lodash';
import { shape } from 'prop-types';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import cogoToast from 'cogo-toast';
import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { getBrokerCRMName } from '../../services/brokerDataService';
import styled from 'styled-components';
import Frame from '../../../assets/images/Frame.svg';
import OfferDocument from '../../components/OfferDocument';

import {
  SubNavigation,
  Label,
  ActionButton,
  Section,
  SubTitle,
  CheckBox_v2 as CheckBox,
  TextBold,
  // RiskObjectBar,
  TextArea,
  CommentBox,
  PhaseLabel,
  BackButton,
  IconDocumentFilled,
  LoadingSpinner,
  Text,
  StripedTable,
  H1,
  Alert,
  SearchSelectInput,
  TextInput,
  SubLabel,
} from 'wg-fe-ui';
// import FileSaver from 'file-saver';

import useForm from '../../hooks/useForm';
import * as Yup from 'yup';

import {
  formatISODate,
  parseIncDateToIsoFormat,
} from '../../services/dateService';
import {
  getBrokerDistribution,
  getBrokerData,
} from '../../services/brokerDataService';
import { getMeData } from '../../services/meDataService';
import {
  getOfferById,
  exportOfferToCRM,
  mailOfferToProspect,
  getAffinityByCompanyKey,
  createOfferRevision,
  changeOfferStatus,
  calculateQuote,
  createRiskObjectRevision,
  updateOfferById,
  getBrokersByDistributionId,
  getNotesByOfferId,
  appendNoteToOffer,
  deleteOfferNoteById,
  // getAllDocumentsByOfferIdAndRevision,
  // getOfferRevisionDocumentById,
  getAffinityInsurancesByCompanyKey,
  getAffinityInsurancesByCompanyKeyAndId,
  retrieveAvailableAffiliations,
  getInsurances,
  getGuarantees,
} from '../../services/apiRouterService';

import { setTabItems } from '../../services/CRMService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';

import OfferConvertModal from '../../components/CRMOfferConvertModal';
import CRMOfferDetailRejectionModal from '../../components/CRMOfferRejectionModal';
import ExportModal from '../../components/CRMExportModal';
import CRMProspectClientData from '../../components/CRMProspectClientData';
import QuoteGuaranteeIcons from '../../components/QuoteGuaranteeIcons';
import CRMRiskObjectTabsOffers from '../../components/CRMRiskObjectTabsOffers';

import PDF from '../../../assets/images/pdf-download.svg';
import QuickActionSubMenu from '../../components/QuickActionSubmenu';
import QuickActionSubMenuStatus from '../../components/QuickActionSubmenuStatus';
import DateInput from '../../components/DateInput_v3';
import { BASE_URL } from '../../../env.config';

const CRMOfferDetail = ({ match }) => {
  const [isCallantFeature, setIsCallantFeature] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [offerHasRecalculated, setOfferHasRecalculated] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [isMailing, setIsMailing] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [rejectionStatus, setRejectionStatus] = useState();
  const [isProFlowEnabled, setIsProfFlowEnabled] = useState();

  const { t } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const history = useHistory();
  const location = useLocation();

  const refs = {};

  const configCatClient = useContext(FlagsProvider);
  const [isContractsEnabled, setIsContractsEnabled] = useState(null);
  const [canEditOffers, setCanEditOffers] = useState(false);

  const [data, setData] = useState();
  const [brokerCRMName, setBrokerCRMName] = useState();
  const [distributionId, setDistributionId] = useState();
  const [insuranceLogo, setInsuranceLogo] = useState(
    'https://via.placeholder.com/125x30',
  );

  const [editOfferMode, setEditOfferMode] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [allBrokers, setAllBrokers] = useState([]);
  const [wasAlreadyConverted, setWasAlreadyConverted] = useState(false);
  const [paymentSpread, setPaymentSpread] = useState(null);
  const [commencementDate, setCommencementDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [bonusMalus, setBonusMalus] = useState(null);
  const [updateOfferRequired, setUpdateOfferRequired] = useState(
    (location.state?.updatedCarData ||
      location.state?.updatedTwoWheelerData ||
      location.state?.updatedResidenceData ||
      location.state?.updatedFamilyData ||
      location.state?.updatedTeacherData) ??
      false,
  );
  const [updateRecalcRequired, setUpdateRecalcRequired] = useState(
    (location.state?.updatedCarData ||
      location.state?.updatedTwoWheelerData ||
      location.state?.updatedResidenceData ||
      location.state?.updatedFamilyData ||
      location.state?.updatedTeacherData) ??
      false,
  );
  const [updatedData, setUpdatedData] = useState();
  const [updatedCarData] = useState(location.state?.updatedCarData ?? []);
  const [updatedTwoWheelerData] = useState(
    location.state?.updatedTwoWheelerData ?? [],
  );
  const [updatedResidenceData] = useState(
    location.state?.updatedResidenceData ?? [],
  );
  const [updatedFamiliesData] = useState(
    location.state?.updatedFamilyData ?? [],
  );
  const [updatedTeachersData] = useState(
    location.state?.updatedTeacherData ?? [],
  );

  const [totalNetPrice, setTotalNetPrice] = useState();
  const [totalTaxPrice, setTotalTaxPrice] = useState();
  const [totalPremiumPrice, setTotalPremiumPrice] = useState();
  const [offerDocuments, setOfferDocuments] = useState([]);
  const [unsignedDocuments, setUnsignedDocuments] = useState();

  const [insuranceCompanyName, setInsuranceCompanyName] = useState('');
  const [insuranceName, setInsuranceName] = useState('');
  const [guaranteeNames, setGuaranteeNames] = useState({});
  const [termsLink, setTermsLink] = useState('');
  const [ipidLink, setIpidLink] = useState('');
  const [overwriteInsuranceWith, setOverwriteInsuranceWith] = useState();
  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  useEffect(() => {
    console.log(unsignedDocuments);
  }, [unsignedDocuments]);

  const [sections, setSections] = useState([
    {
      id: 1,
      label: 'Offer',
      subsections: [
        {
          id: 1,
          label: t('Offer information'),
        },
        {
          id: 2,
          label: t('Prospect.Client information'),
        },
        {
          id: 3,
          label: t('Risk objects'),
        },
      ],
    },
  ]);

  const paymentSpreadOptions = [
    { value: 'ANNUAL', label: t(`spread.ANNUAL`) },
    { value: 'HALF_ANNUAL', label: t(`spread.HALF_ANNUAL`) },
    { value: 'QUARTERLY', label: t(`spread.QUARTERLY`) },
    { value: 'MONTHLY', label: t(`spread.MONTHLY`) },
  ];

  const FindPaymentSpreadInEnums = paymentSpread => {
    const result = paymentSpreadOptions.filter(obj => {
      return obj.value === paymentSpread;
    });
    return result;
  };

  const [notes, setNotes] = useState([]);

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    setIsCallantFeature(
      await configCatClient.getValueAsync('callantFeature', true, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setIsContractsEnabled(
      await configCatClient.getValueAsync('contracts', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setCanEditOffers(
      await configCatClient.getValueAsync('editOffers', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  const retrieveBrokersFromDistribution = async id => {
    const [resp, status] = await getBrokersByDistributionId(id);
    console.log(resp, status, brokers);
    const sortedAllBrokers = resp.items.sort((b1, b2) => {
      if (b1?.broker?.name > b2?.broker?.name) return 1;
      if (b1?.broker?.name < b2?.broker?.name) return -1;
      return 0;
    });
    setAllBrokers(sortedAllBrokers);
    const sortedBrokers = sortedAllBrokers.filter(
      item => item.broker.plan !== 'CALLANT',
    );
    setBrokers(sortedBrokers);
  };

  useEffect(() => {
    getOffer(match.params.id, match.params.revision);
    setNavTabs(
      setTabItems(
        match.params.id,
        t,
        match.params.prospectId ? 'offerDetailCustomer' : 'offerDetail',
        null,
        match.params.revision,
        { ...location.state },
        match.params.prospectId,
      ),
    );
    setFeatureToggles();
    retrieveBrokerCRMName();
  }, []);

  // useEffect(() => {
  //   if (offerDocuments) {
  //     offerDocuments.forEach((doc, i) => {
  //       console.log('CRMOfferDetail -> i', i);

  //       fetchOfferDocumentUrl(
  //         data?.id,
  //         distributionId,
  //         data?.revision,
  //         doc?.document?.id,
  //       ).then(resp => {
  //         console.log(resp?.document?.link);
  //       });
  //     });
  //   }
  // }, [offerDocuments]);

  useEffect(() => {
    setInitialFormValues();
    console.log(updatedData);
  }, [updatedData]);

  async function retrieveBrokerCRMName() {
    const _brokerCRMName = await getBrokerCRMName();
    setBrokerCRMName(_brokerCRMName);
  }

  // async function fetchOfferDocumentUrl(
  //   offerId,
  //   distributionId,
  //   revisionId,
  //   documentId,
  // ) {
  //   let [response, status] = await getOfferRevisionDocumentById(
  //     distributionId,
  //     offerId,
  //     revisionId,
  //     documentId,
  //   );
  //   console.log('nothing happens');

  //   if (status === 200) {
  //     console.log('fetchOfferDocumentUrl -> response', response);
  //     return response;
  //   } else {
  //     console.error(response);
  //   }
  // }

  // async function fetchOfferDocuments(offerId, distributionId, revisionId) {
  //   let [response, status] = await getAllDocumentsByOfferIdAndRevision(
  //     distributionId,
  //     offerId,
  //     revisionId,
  //   );

  //   if (status === 200) {
  //     setOfferDocuments(response?.items);
  //   } else {
  //     console.error(response);
  //   }
  // }

  async function getOffer(offerId, revisionId) {
    const { id } = await getBrokerDistribution();

    setDistributionId(id);

    let [response, status] = await getOfferById(id, offerId, revisionId);
    console.log('OFFER RESPONSE', response);
    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      console.log('FAILED TO LOAD');
      setIsLoading(!isLoading);
      setData(null);
      return;
    }

    // fetchOfferDocuments(offerId, id, revisionId);
    retrieveBrokersFromDistribution(id);
    setOfferDocuments(response?.documents);
    setData(response);
    setUpdatedData(JSON.parse(JSON.stringify(response)));
    setTotalNetPrice(response?.premium.net_premium);
    setTotalTaxPrice(response?.premium.taxes_in_euro);
    setTotalPremiumPrice(response?.premium.total_premium);
    setWasAlreadyConverted(response?.has_been_converted_to_contract);
    setPaymentSpread(FindPaymentSpreadInEnums(response?.payment_spread));
    setCommencementDate(response?.commencement_date);
    setEndDate(response?.end_date);
    setBonusMalus(response?.premium?.bonus_malus);

    if (response.insurance.company === 'callant') {
      const [logoResp, logoStatus] = await getAffinityByCompanyKey(
        response.insurance.company,
      );

      if (logoStatus !== 200) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(logoResp.msg)}</strong>
            <p>{t(logoResp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
        return console.error(logoResp);
      }

      getLogoFromAffinity(logoResp.items, response.insurance.affinity);

      // Get All Affinity Insurances By Company Key
      const [
        insurancesResp,
        insurancesStatus,
      ] = await getAffinityInsurancesByCompanyKey(
        response.insurance.company,
        response.insurance.affinity,
      );

      if (insurancesStatus !== 200) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(insurancesResp.msg)}</strong>
            <p>{t(insurancesResp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
        return console.error(insurancesResp);
      }

      // Search for the proper insurance ID from that group
      let insuranceId = null;
      insurancesResp?.items.forEach(item => {
        if (item?.insurance?.type === response.insurance.type) {
          insuranceId = item?.insurance?.id;
          setInsuranceName(item?.insurance?.names?.[i18n.language]);
          setTermsLink(item?.insurance?.terms?.[i18n.language]);
          setIpidLink(item?.insurance?.ipid?.[i18n.language]);
        }
      });

      if (insuranceId) {
        // Retrieve the information from the insurance ID
        const [
          insuranceResp,
          insuranceStatus,
        ] = await getAffinityInsurancesByCompanyKeyAndId(
          response.insurance.company,
          response.insurance.affinity,
          insuranceId,
        );

        if (insuranceStatus !== 200) {
          cogoToast.error(
            <ToasterContent>
              <strong>{t(insuranceResp.msg)}</strong>
              <p>{t(insuranceResp.detail)}</p>
            </ToasterContent>,
            {
              onClick: hide => {
                hide();
              },
              hideAfter: 15,
            },
          );
          return console.error(insuranceResp);
        }

        const guaranteeNamesDict = {};
        insuranceResp?.items.forEach(item => {
          guaranteeNamesDict[item?.guarantee_info?.key] =
            item?.guarantee_info?.names;
        });

        setGuaranteeNames(guaranteeNamesDict);
      }
    } else {
      const [logoResp, logoStatus] = await retrieveAvailableAffiliations();

      if (logoStatus !== 200) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(logoResp.msg)}</strong>
            <p>{t(logoResp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
        return console.error(logoResp);
      }

      getLogoFromAffiliation(logoResp.items, response.insurance.company);

      // Get All Affinity Insurances By Company Key
      const [insurancesResp, insurancesStatus] = await getInsurances(
        response.insurance.company,
      );

      if (insurancesStatus !== 200) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(insurancesResp.msg)}</strong>
            <p>{t(insurancesResp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
        return console.error(insurancesResp);
      }

      // Search for the proper insurance ID from that group
      let insuranceId = null;
      insurancesResp?.items.forEach(item => {
        if (item?.insurance?.type === response.insurance.type) {
          insuranceId = item?.insurance?.id;
          setInsuranceName(item?.insurance?.names?.[i18n.language]);
          // setInsuranceType(item?.insurance?.names?.en);
          // setInsuranceName(item?.insurance?.names?.en)
          setTermsLink(item?.insurance?.terms?.[i18n.language]);
          setIpidLink(item?.insurance?.ipid?.[i18n.language]);
        }
      });

      if (insuranceId) {
        // Retrieve the information from the insurance ID
        const [insuranceResp, insuranceStatus] = await getGuarantees(
          response.insurance.company,
          insuranceId,
        );

        if (insuranceStatus !== 200) {
          cogoToast.error(
            <ToasterContent>
              <strong>{t(insuranceResp.msg)}</strong>
              <p>{t(insuranceResp.detail)}</p>
            </ToasterContent>,
            {
              onClick: hide => {
                hide();
              },
              hideAfter: 15,
            },
          );
          return console.error(insuranceResp);
        }

        const guaranteeNamesDict = {};
        insuranceResp?.items.forEach(item => {
          guaranteeNamesDict[item?.guarantee_info?.key] =
            item?.guarantee_info?.names;
        });

        setGuaranteeNames(guaranteeNamesDict);
      }
    }

    setIsLoading(!isLoading);
  }

  const getLogoFromAffinity = (object, value) => {
    object.forEach(affinity => {
      const { affinity_info } = affinity;
      if (
        Object.keys(affinity_info).some(key => affinity_info[key] === value)
      ) {
        setInsuranceLogo(affinity_info.logo);
        setInsuranceCompanyName(affinity_info?.names?.[i18n.language]);
      }
    });
  };

  const getLogoFromAffiliation = (object, value) => {
    object.forEach(affiliation => {
      const { affiliation_info } = affiliation;
      if (
        Object.keys(affiliation_info).some(
          key => affiliation_info[key] === value,
        )
      ) {
        setInsuranceLogo(affiliation_info.logo);
        setInsuranceCompanyName(affiliation_info?.label);
      }
    });
  };

  const exportClickHandler = async () => {
    setIsExporting(true);
    cogoToast.loading(t('Exporting'), {
      hideAfter: 7,
    });

    let [response, status] = await exportOfferToCRM(
      distributionId,
      match.params.id,
      match.params.revision,
    );

    if (status === 200) {
      cogoToast.success(t('The offer has been exported successfully'));
      setIsExporting(false);
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const mailOfferToProspectClickHandler = async () => {
    setIsMailing(true);
    cogoToast.loading(t('Mailing'), {
      hideAfter: 7,
    });

    let [response, status] = await mailOfferToProspect(
      distributionId,
      match.params.id,
      match.params.revision,
    );
    console.log(response, status);
    if (status === 200 || status === 202) {
      cogoToast.success(t('The offer has been e-mailed to the prospect'));
      setIsMailing(false);
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      setIsMailing(false);
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const signOfferClickHandler = async () => {
    setIsSigning(true);
    const signUrl = `${BASE_URL}v1/api/broker/v1/api/distributions/${distributionId}/offers/${match.params.id}/revisions/${match.params.revision}/signatures`;
    window.open(signUrl, '_blank');
  };

  useEffect(() => {
    setSections([
      {
        id: 1,
        label: `Offer | ${data?.ref}/0${data?.revision}`,
        subsections: [
          {
            id: 1,
            label: t('Offer information'),
          },
          {
            id: 2,
            label: t('Prospect.Client information'),
          },
          {
            id: 3,
            label: t('Risk objects'),
          },
        ],
      },
    ]);
  }, [data]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  /**
   * Helper functions
   */

  const isObjectSame = (obj1, obj2) => {
    // console.log('DEBUG EDIT MODE 1', obj1);
    // console.log('DEBUG EDIT MODE 2', obj2);
    // function changes(object, base) {
    //   return _.transform(object, function(result, value, key) {
    //     if (!_.isEqual(value, base[key])) {
    //       result[key] =
    //         _.isObject(value) && _.isObject(base[key])
    //           ? changes(value, base[key])
    //           : value;
    //     }
    //   });
    // }
    // console.log('DEBUG EDIT MODE DIFFERENCE', changes(obj2, obj1));
    return _.isEqual(obj1, obj2);
  };

  /**
   * Edit functionality
   */

  const ToggleEditMode = e => {
    setEditOfferMode(!editOfferMode);

    if (!isObjectSame(data, updatedData)) {
      showUpdateOfferMessage();
    }
  };

  // const ValidationSchema = Yup.object().shape({});

  // const { handleChange, errors, handleSubmit } = useForm({
  //   validationSchema: ValidationSchema,
  //   mode: 'onChange',
  // });

  const setInitialFormValues = () => {
    if (updatedData) {
      Object.keys(updatedData).forEach(name => {
        handleChange({ name, value: updatedData[name] });
      });

      Object.keys(updatedData.premium).forEach(name => {
        handleChange({ premium: { name }, value: updatedData.premium[name] });
      });
    }
  };

  const handleFormSubmit = e => {
    if (e != null) {
      e.preventDefault();
    }

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    handleSubmit(handleFormValues);
  };

  const handlePaymentSpreadChange = e => {
    const selectedPaymentSpread = FindPaymentSpreadInEnums(e.value);
    console.log('DEBUG EDIT MODE', selectedPaymentSpread);
    setPaymentSpread(selectedPaymentSpread);
    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.payment_spread = selectedPaymentSpread[0].value;
    setUpdatedData(newData);
  };

  const handleCommencementDateChange = e => {
    const isoFormattedDate = parseIncDateToIsoFormat(e.value);
    setCommencementDate(isoFormattedDate);
    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.commencement_date = isoFormattedDate;
    setUpdatedData(newData);
  };

  const handleEndDateChange = e => {
    const isoFormattedDate = parseIncDateToIsoFormat(e.value);
    setEndDate(isoFormattedDate);
    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.main_expiry_date = isoFormattedDate;
    setUpdatedData(newData);
  };

  const handleBonusMalusChange = e => {
    setBonusMalus(e.value);
    setUpdateRecalcRequired(true);
  };

  const handleAcknowledgementChange = (acknowledgementId, event) => {
    const elementPos = updatedData?.acknowledgements
      .map(x => {
        return x.id;
      })
      .indexOf(acknowledgementId);

    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.acknowledgements[elementPos].agreed = event.value;
    setUpdatedData(newData);
  };

  const handleGuaranteesChange = (guaranteeId, event) => {
    const elementPos = updatedData?.guarantees
      .map(x => {
        return x.id;
      })
      .indexOf(guaranteeId);

    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.guarantees[elementPos].taken = event.value;

    if (!event.value) {
      let net = totalNetPrice;
      let tax = totalTaxPrice;
      let total = totalPremiumPrice;
      setTotalNetPrice((net -= newData.guarantees[elementPos].net_premium));
      setTotalTaxPrice((tax -= newData.guarantees[elementPos].taxes_in_euro));
      setTotalPremiumPrice(
        (total -= newData.guarantees[elementPos].total_premium),
      );
    } else {
      let net = totalNetPrice;
      let tax = totalTaxPrice;
      let total = totalPremiumPrice;
      setTotalNetPrice((net += newData.guarantees[elementPos].net_premium));
      setTotalTaxPrice((tax += newData.guarantees[elementPos].taxes_in_euro));
      setTotalPremiumPrice(
        (total += newData.guarantees[elementPos].total_premium),
      );
    }

    setUpdatedData(newData);
  };

  const handleEditModeChange = active => {
    setIsBeingEdited(active);
  };

  const showUpdateOfferMessage = () => {
    setUpdateOfferRequired(true);
  };

  const handleCreateNewOffer = async () => {
    const closeLoadingToast = cogoToast.loading(t('Saving adjustments'), {
      hideAfter: 0,
    });
    let guarantees = [];
    let acknowledgements = [];
    setUpdateOfferRequired(false);

    // First, create the new risk object revisions
    const updatedRiskObjects = {
      cars: updatedData?.risk_objects?.cars,
      families: updatedData?.risk_objects?.families,
      residences: updatedData?.risk_objects?.residences,
      two_wheelers: updatedData?.risk_objects?.two_wheelers,
      teachers: updatedData?.risk_objects?.teachers,
    };

    // TODO: Needs to have a better fallback here to catch errors
    const risk_object_revisions = {};
    for (var riskType in updatedRiskObjects) {
      if (updatedRiskObjects.hasOwnProperty(riskType)) {
        console.log(riskType, updatedRiskObjects[riskType]);

        // delete updatedRiskObjects[riskType][0].created_at;

        if (
          updatedRiskObjects[riskType] &&
          updatedRiskObjects[riskType].length > 0 &&
          !isObjectSame(
            updatedRiskObjects[riskType][0],
            data?.risk_objects?.[riskType][0],
          )
        ) {
          delete updatedRiskObjects[riskType][0].created_at;
          delete updatedRiskObjects[riskType][0].id;
          delete updatedRiskObjects[riskType][0].risk_object_id;
          delete updatedRiskObjects[riskType][0].risk_object_revision_id;

          const [resp, status] = await createRiskObjectRevision(
            distributionId,
            data.prospect.id,
            riskType,
            data.risk_objects[riskType][0].risk_object_id,
            updatedRiskObjects[riskType][0],
          );
          console.log(resp, status);
          if (status === 201) {
            risk_object_revisions[riskType] = [resp?.risk_object_revision_id];
          } else if (status === 422) {
            cogoToast.error(
              <ToasterContent>
                <strong>{t(resp.msg)}</strong>
                <p>{t(resp.detail)}</p>
              </ToasterContent>,
              {
                onClick: hide => {
                  hide();
                },
                hideAfter: 15,
              },
            );
          }
        } else if (
          data?.risk_objects?.[riskType] &&
          data?.risk_objects?.[riskType]?.length > 0 &&
          isObjectSame(
            updatedRiskObjects[riskType][0],
            data?.risk_objects?.[riskType][0],
          )
        ) {
          // If nothing has been changed to the risk objects, just take the original ids
          risk_object_revisions[riskType] = [
            updatedRiskObjects[riskType][0]?.risk_object_revision_id,
          ];
          console.log('DEBUG NEW OFFER NOTHING CHANGED IN RISKS');
        }
      }
    }

    // Next, pass them to the offer
    updatedData.guarantees.forEach(guarantee => {
      if (guarantee?.taken === true) {
        guarantees.push(guarantee.name);
      }
    });

    updatedData.acknowledgements.forEach(acknowledgement => {
      const ack = {
        text: acknowledgement.text,
        agreed: acknowledgement.agreed,
        key: acknowledgement.key,
        id: acknowledgement.id,
      };

      acknowledgements.push(ack);
    });

    // TODO: Need to add support here for a different end_date, commencement_date, payment_spread en bonus_malus (bonus_malus is passed through quote_specifications)
    const payload = {
      quote_id: offerHasRecalculated ? data?.quote_id : undefined,
      guarantees: guarantees,
      acknowledgements: acknowledgements,
      risk_object_revisions,
      send_mail_to_prospect: !!values?.send_mail_to_prospect,
      end_date: endDate,
      payment_spread: paymentSpread?.[0]?.value, // TODO: Activate when available in the API
      commencement_date: commencementDate,
    };

    const [resp, status] = await createOfferRevision(
      distributionId,
      match.params.id,
      payload,
    );
    console.log('DEBUG NEW OFFER: ', resp, status);
    closeLoadingToast();
    if (status === 201) {
      cogoToast.success(
        t('The offer adjustments have been successfully saved'),
      );
      setOfferHasRecalculated(false);
      console.log('DEBUG', resp);
      console.log('redirecting', location.state);
      if (match.params.prospectId) {
        history.push({
          pathname: `/sales-management/customer/${match.params.prospectId}/contracts/offer/${resp.offer_id}/${resp.revision}`,
          state: {
            filters: { ...location.state.filters },
            page: location.state.page,
            topSearch: location.state.topSearch,
          },
        });
      } else {
        history.push({
          pathname: `/sales-management/offer/${resp.offer_id}/${resp.revision}`,
          state: {
            filters: { ...location?.state?.filters },
            page: location?.state?.page,
            topSearch: location?.state?.topSearch,
          },
        });
      }
      // window.location.reload(false);
    } else if (resp.msg != null || resp.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      console.log(
        `DEBUG RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`,
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const updateStatus = async status => {
    if (status === 'REJECTED' || status === 'CANCELLED') {
      setRejectionStatus(status);
      setShowRejectionModal(true);
    } else {
      const [resp, respStatus] = await changeOfferStatus(
        distributionId,
        match.params.id,
        match.params.revision,
        { status },
      );
      if (respStatus === 200) {
        cogoToast.success(t('Offer status has been changed'));
        setData(prevValue => ({
          ...prevValue,
          status,
        }));
      } else if (resp.msg != null || resp.detail != null) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(resp.msg)}</strong>
            <p>{t(resp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
      } else {
        cogoToast.error(t('Something went wrong'));
      }
    }
  };

  const updateAssignedTo = async broker => {
    const tempData = JSON.parse(JSON.stringify(data));
    setData(prevValue => ({
      ...prevValue,
      assigned_to: broker,
    }));
    const [resp, respStatus] = await updateOfferById(
      distributionId,
      match.params.id,
      { assigned_to: broker },
    );
    if (respStatus === 200) {
      cogoToast.success(t('Offer was successfully assigned'));
    } else if (resp.msg != null || resp.detail != null) {
      setData(tempData);
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  useEffect(() => {
    if (overwriteInsuranceWith != null) {
      console.log('PAYLOAD USEFFECT');
      setUpdateRecalcRequired(true);
    }
  }, [overwriteInsuranceWith]);

  const handleCalculateNewQuote = async () => {
    setUpdateRecalcRequired(false);
    //Check if there were changes made and applies them, if not use data from offer
    console.log('PAYLOAD - ENTERED FUNC');
    let carsArray = [];
    let twoWheelersArray = [];
    let residencesArray = [];
    let teachersArray = [];
    let familiesArray = [];

    if (updatedCarData.length === 0) {
      if (data.risk_objects.cars.length > 0) {
        const payload = JSON.parse(JSON.stringify(data.risk_objects.cars));
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        carsArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(JSON.stringify(updatedCarData));
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      carsArray.push(_updatedRiskModelData);
      await setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            cars: [updatedCarData],
          },
        };
      });
    }

    if (updatedTwoWheelerData.length === 0) {
      if (data.risk_objects.two_wheelers.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(data.risk_objects.two_wheelers),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        twoWheelersArray.push(...payload);
      }
    } else {
      delete updatedTwoWheelerData.drive_assistance_system;
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedTwoWheelerData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      twoWheelersArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            two_wheelers: [updatedTwoWheelerData],
          },
        };
      });
    }

    if (updatedResidenceData.length === 0) {
      if (data.risk_objects.residences.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(data.risk_objects.residences),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        residencesArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedResidenceData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      residencesArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            residences: [updatedResidenceData],
          },
        };
      });
    }

    if (updatedTeachersData.length === 0) {
      if (data.risk_objects.teachers.length > 0) {
        const payload = JSON.parse(JSON.stringify(data.risk_objects.teachers));
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        teachersArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedTeachersData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      teachersArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            teachers: [updatedTeachersData],
          },
        };
      });
    }

    if (updatedFamiliesData.length === 0) {
      if (data.risk_objects.families.length > 0) {
        const payload = JSON.parse(JSON.stringify(data.risk_objects.families));
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        // TODO: Hardcoded for now, remove below when fix in broker-api
        payload[0].birth = '1980-06-15T15:58:08.820072+00:00';
        familiesArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedFamiliesData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      // TODO: Hardcoded for now, remove below when fix in broker-api
      _updatedRiskModelData.birth = '1980-06-15T15:58:08.820072+00:00';
      familiesArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            families: [updatedFamiliesData],
          },
        };
      });
    }

    const payload = {
      risk_objects: {
        cars: carsArray,
        residences: residencesArray,
        families: familiesArray,
        two_wheelers: twoWheelersArray,
        teachers: teachersArray,
      },
      quote_specifications: {
        bonus_malus: bonusMalus,
      },
    };

    if (overwriteInsuranceWith) {
      payload.overwrite_insurance_with = overwriteInsuranceWith;
    }

    console.log('PAYLOAD', payload);

    const [response, status] = await calculateQuote(
      distributionId,
      match.params.id,
      payload,
      match.params.revision,
    );
    console.log(response, status);

    // if (status !== 200 && response.msg != null) {
    //   const { hide } = cogoToast.error(
    //     t(response.msg, {
    //       onClick: () => {
    //         hide();
    //       },
    //     }),
    //   );
    //   return console.error('DEBUG RECALC RESPONSE', status);
    // } else {
    //   cogoToast.success(t('The offer was recalculated'));

    //   // Set the taken parameter based on mandatory or the taken before check
    //   let newTotalNetPremium = 0;
    //   let newTotalTaxes = 0;
    //   let newTotalPremium = 0;
    //   for (var i = 0; i < response.guarantees.length; i++) {
    //     if (response.guarantees[i].taken) {
    //       newTotalNetPremium += response.guarantees[i].net_premium;
    //       newTotalTaxes += response.guarantees[i].taxes_in_euro;
    //       newTotalPremium += response.guarantees[i].total_premium;
    //     }
    //   }

    if (status !== 200 && (response.msg != null || response.detail != null)) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('DEBUG RECALC RESPONSE', status);
    } else if (status !== 200) {
      cogoToast.error(
        t('Something went wrong', {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        }),
      );
    } else {
      setOfferHasRecalculated(true);
      cogoToast.success(t('The offer was recalculated'));

      // Set the taken parameter based on mandatory or the taken before check
      let newTotalNetPremium = 0;
      let newTotalTaxes = 0;
      let newTotalPremium = 0;
      for (var i = 0; i < response.guarantees.length; i++) {
        if (response.guarantees[i].taken) {
          newTotalNetPremium += response.guarantees[i].net_premium;
          newTotalTaxes += response.guarantees[i].taxes_in_euro;
          newTotalPremium += response.guarantees[i].total_premium;
        }
      }

      setData({
        ...data,
        guarantees: response.guarantees,
        quote_id: response.id,
      });
      setTotalNetPrice(newTotalNetPremium);
      setTotalTaxPrice(newTotalTaxes);
      setTotalPremiumPrice(newTotalPremium);
      await setUpdatedData(updatedData => {
        return {
          ...updatedData,
          guarantees: response.guarantees,
          quote_id: response.id,
        };
      });
    }

    // if (bonusMalus !== updatedData.premium.bonus_malus) {
    //   showUpdateOfferMessage();
    // }
  };

  useEffect(() => {
    if (distributionId !== undefined) {
      getNotes(distributionId, match.params.id);
    }
  }, [distributionId]);

  async function getNotes(distribution, offer) {
    let [response, status] = await getNotesByOfferId(distribution, offer);
    setNotes(response.items);

    if (status !== 200) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error(response);
    }
  }

  const deleteNoteHandler = async noteId => {
    let [response, status] = await deleteOfferNoteById(
      distributionId,
      match.params.id,
      noteId,
    );

    if (status === 200) {
      setNotes(notes.filter(object => object.note.id !== noteId));
      cogoToast.success(t('Note successfully deleted'));
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const [CommentSchema] = useState(
    Yup.object().shape({
      comment: Yup.string().nullable(),
    }),
  );

  const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
    validationSchema: CommentSchema,
    change: () => {},
  });

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleNoteFormValues);
  }

  const handleNoteFormValues = async formValues => {
    const { broker } = await getBrokerData();
    const payload = {
      created_at: new Date(),
      private: formValues.private,
      author: broker.id,
      text: formValues.comment,
    };

    const [resp, status] = await appendNoteToOffer(
      distributionId,
      match.params.id,
      payload,
    );
    resetForm();
    if (status >= 400) return;
    setNotes(notes => [
      {
        note: {
          id: resp?.id,
          created_at: new Date(),
          private: formValues.private,
          author: broker.id,
          text: formValues.comment,
        },
      },
      ...notes,
    ]);
    values.private = false;
  };

  console.log(location.state);

  const handleFormValues = async formValues => {
    setEditOfferMode(!editOfferMode);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (!isObjectSame(data, updatedData)) {
      showUpdateOfferMessage();
    }
  };

  const handlePrivateChange = () => {
    values.private = !values.private;
  };

  // const handleOfferDocumentClick = documentId => {
  //   fetchOfferDocumentUrl(
  //     data?.id,
  //     distributionId,
  //     data?.revision,
  //     documentId,
  //   ).then(resp => {
  //     console.log(resp?.document?.link);
  //     FileSaver.saveAs(resp?.document?.link, resp?.document?.filename);
  //   });
  // };

  useEffect(() => {
    const signed = offerDocuments?.signed;
    const unSigned = offerDocuments?.unsigned;
    const grouped = unSigned?.filter(x => {
      return !signed.some(y => {
        return x.type === y.type;
      });
    });
    if (grouped?.length > 0) {
      setUnsignedDocuments(grouped);
    }
    // Object.entries(offerDocuments)?.map(docs => {
    //   console.log(docs);
    // });
  }, [offerDocuments]);

  const startNewTarrification = () => {
    history.push(
      `/professional-flow/new-tarrification-prospect/?prospectId=${updatedData?.prospect?.id}`,
    );
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (data == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Offer not found')}</h1>
        <p>
          {`${t('We could not find the offer with id')}: `}{' '}
          <strong> {match.params.id}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <OfferConvertModal
        modalState={[showModal, setShowModal]}
        data={data}
        revisionId={match.params.revision}
        distributionId={distributionId}
      />
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton
              onClick={() =>
                match?.params?.prospectId
                  ? history.push({
                      pathname: `/sales-management/customer/${match.params.prospectId}/contracts`,
                      state: {
                        ...location.state,
                      },
                    })
                  : history.push({
                      pathname: `/sales-management/contracts/offers/${location
                        ?.state?.page || 1}`,
                      state: {
                        ...location.state,
                      },
                    })
              }
              name={t('Previous page')}
            />
          </TopBarLeft>

          {isCallantFeature ? (
            <TopBarRight>
              <TopBarAction>
                <ActionButton
                  onClick={() => setShowModal(true)}
                  disabled={wasAlreadyConverted || !isContractsEnabled}
                >
                  {wasAlreadyConverted
                    ? t('Already converted to contract')
                    : t('Convert to contract')}
                </ActionButton>
              </TopBarAction>
            </TopBarRight>
          ) : (
            <TopBarRight>
              <TopBarAction>
                <ActionButton
                  onClick={startNewTarrification}
                  disabled={!isProFlowEnabled}
                >
                  {t('New tarification')}
                </ActionButton>
              </TopBarAction>
            </TopBarRight>
          )}
        </TopBarContainer>
      </TopBar>

      <CRMOfferDetailRejectionModal
        canClose={true}
        data={data}
        setData={setData}
        modalState={[showRejectionModal, setShowRejectionModal]}
        distributionId={distributionId}
        offerId={match.params.id}
        revisionId={match.params.revision}
        rejectionStatus={rejectionStatus}
      />

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <StyledSubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </StyledSubNavigation>
          ))}
          <ExportModal
            modalState={[showExportModal, setShowExportModal]}
            proceedExport={exportClickHandler}
            type="Offer"
            // data={data}
            // revisionId={match.params.revision}
            // distributionId={distributionId}
          />
          <ActionButton
            onClick={e => {
              updatedData.amount_of_crm_exports > 0
                ? setShowExportModal(true)
                : exportClickHandler(e);
            }}
            level="secondary"
            disabled={brokerCRMName == null || editOfferMode || isExporting}
          >
            {brokerCRMName
              ? `${t(`Export to`)} ${brokerCRMName}`
              : t(`No crm found`)}
          </ActionButton>
          <ActionButton
            onClick={e => {
              mailOfferToProspectClickHandler(e);
            }}
            // onMouseOver={}
            alt={data?.prospect?.email == null && 'There is no prospect email'}
            level="secondary"
            disabled={isMailing || data?.prospect?.email == null}
          >
            {data?.prospect?.email != null
              ? `${t('Mail offer to')} ${
                  data?.prospect?.type === 'COMPANY'
                    ? `${data?.prospect.name}`
                    : `${data?.prospect.first_name} ${data?.prospect.last_name}`
                }`
              : t(`No prospect email found`)}
          </ActionButton>
          {!isCallantFeature ? (
            <ActionButton
              onClick={e => {
                signOfferClickHandler(e);
              }}
              // onMouseOver={}
              alt={
                ((offerDocuments?.unsigned || []).length === 0 &&
                  'No documents found to sign') ||
                ((offerDocuments?.unsigned || []).length > 0 &&
                  (offerDocuments?.unsigned || []).length ===
                    (offerDocuments?.signed || []).length &&
                  'Documents already signed')
              }
              level="secondary"
              disabled={
                isSigning ||
                (offerDocuments?.unsigned || []).length === 0 ||
                ((offerDocuments?.unsigned || []).length > 0 &&
                  (offerDocuments?.unsigned || []).length ===
                    (offerDocuments?.signed || []).length)
              }
            >
              {(offerDocuments?.unsigned || []).length > 0
                ? (offerDocuments?.unsigned || []).length ===
                  (offerDocuments?.signed || []).length
                  ? `${t('Documents already signed')}`
                  : `${t('Sign online')}`
                : t(`No documents found to sign`)}
            </ActionButton>
          ) : null}
          {/* <ActionButton
            onClick={e => {
              console.log('resend email to client button clicked');
            }}
            level="secondary"
            // disabled={brokerCRMName == null || editOfferMode}
          >
            {t(`Send email`)}
          </ActionButton> */}
        </Sidebar>

        <Content>
          <section ref={refs[1][1]}>
            <ContractHeader>
              <section>
                <ContractHeaderItem>
                  <Label>{t('Internal reference')}</Label>
                  <TextBold>
                    {data?.ref ? `${data.ref}/0${data?.revision}` : `-`}
                  </TextBold>
                </ContractHeaderItem>
                <ContractHeaderItem>
                  <Label>{t('Status')}</Label>
                  <QuickActionSubMenuStatus
                    status={data?.status.toUpperCase()}
                    label={t(`offers.${data?.status}`)}
                  >
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="PENDING">
                          {t('offers.PENDING')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('PENDING')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="SEND">
                          {t('offers.SEND')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('SEND')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="SIGNED">
                          {t('offers.SIGNED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('SIGNED')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="ACCEPTED">
                          {t('offers.ACCEPTED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('ACCEPTED')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="REJECTED">
                          {t('offers.REJECTED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('REJECTED')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="CANCELLED">
                          {t('offers.CANCELLED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('CANCELLED')}
                      type=""
                    />
                  </QuickActionSubMenuStatus>
                  {/* <PhaseLabel status="offer-send">
                    <TextBold>{data.status ? t(data?.status) : `-`}</TextBold>
                  </PhaseLabel> */}
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Created on')}</Label>
                  <TextBold>{formatDate(data?.created_at)}</TextBold>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Created by')}</Label>
                  <Text>
                    {data?.created_by
                      ? allBrokers?.length > 0
                        ? allBrokers?.find(
                            item => item?.broker?.id === data?.created_by,
                          )?.broker?.name
                        : t('loading')
                      : t('Client')}
                  </Text>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Assigned to')}</Label>
                  <QuickActionSubMenu
                    name={
                      data?.assigned_to
                        ? brokers?.length > 0
                          ? brokers?.find(
                              item => item?.broker?.id === data?.assigned_to,
                            )?.broker?.name
                          : t('loading')
                        : t('No one')
                    }
                  >
                    {brokers?.map(item => (
                      <QuickActionSubMenu.SubMenuItem
                        key={item.broker.id}
                        label={<SubLabel>{item?.broker?.name}</SubLabel>}
                        onClick={() => updateAssignedTo(item?.broker?.id)}
                        type=""
                      />
                    ))}
                    <QuickActionSubMenu.SubMenuItem
                      label={<SubLabel>{t('No one')}</SubLabel>}
                      onClick={() => updateAssignedTo(null)}
                      type=""
                    />
                  </QuickActionSubMenu>
                  {/* <PhaseLabel status="offer-send">
                    <TextBold>{data.status ? t(data?.status) : `-`}</TextBold>
                  </PhaseLabel> */}
                </ContractHeaderItem>
              </section>

              {!canEditOffers ? null : editOfferMode ? (
                <section>
                  <ActionButton onClick={e => handleFormSubmit(e)}>
                    {t(`Save`)}
                  </ActionButton>
                </section>
              ) : isCallantFeature ? (
                <section>
                  <ActionButton
                    disabled={wasAlreadyConverted}
                    onClick={e => ToggleEditMode(e)}
                  >
                    {t(`Edit offer`)}
                  </ActionButton>
                </section>
              ) : null}
              {/* 
              {editOfferMode ? (
                <section>
                  <ActionButton onClick={e => handleFormSubmit(e)}>
                    {t(`Save changes`)}
                  </ActionButton>
                </section>
              ) : (
                <section>
                  <ActionButton
                    disabled={wasAlreadyConverted}
                    onClick={e => ToggleEditMode()}
                  >
                    {t(`Edit offer`)}
                  </ActionButton>
                </section>
              )} */}
            </ContractHeader>

            {updateOfferRequired && !updateRecalcRequired ? (
              <StyledAlert icon type="normal">
                <StyledAlertContent>
                  {t(
                    'You have made changes to the offer, would you like to save these',
                  )}
                  <ActionButtonContainer>
                    <StyledCheckBox
                      name="send_mail_to_prospect"
                      checked={values.send_mail_to_prospect}
                      onChange={handleChange}
                    >
                      {t('Mail new quote to customer')}
                    </StyledCheckBox>
                    <ActionButton
                      onClick={() => handleCreateNewOffer()}
                      disabled={editOfferMode || isBeingEdited ? true : false}
                    >
                      {t(`Save changes`)}
                    </ActionButton>
                  </ActionButtonContainer>
                </StyledAlertContent>
              </StyledAlert>
            ) : null}

            {updateRecalcRequired ? (
              <StyledAlert icon type="normal">
                <StyledAlertContent>
                  {t(
                    'Some risk objects have changed Please recalculate your offer',
                  )}
                  <ActionButton
                    onClick={() => handleCalculateNewQuote()}
                    disabled={editOfferMode || isBeingEdited ? true : false}
                  >
                    {t(`Re-calculate`)}
                  </ActionButton>
                </StyledAlertContent>
              </StyledAlert>
            ) : null}
            <form onSubmit={e => handleFormSubmit(e)}>
              <Section>
                <Section.Title>{t('Offer information')}</Section.Title>

                <Section.Content background>
                  <OfferHeader>
                    <OfferInfoLogo>
                      <img
                        src={insuranceLogo}
                        alt={`${data?.insurance?.company}'s logo`}
                        height="35"
                      />
                    </OfferInfoLogo>

                    <OfferHeaderName>
                      <h3>{insuranceCompanyName}</h3>
                      <h4>{insuranceName}</h4>
                      {!isCallantFeature ? (
                        <h5>
                          [{t('External reference')}:{' '}
                          {data?.external_ref_insurance_module
                            ? `${data.external_ref_insurance_module}`
                            : t(`NONE`)}
                          ]
                        </h5>
                      ) : null}
                    </OfferHeaderName>

                    <OfferPrice>
                      {totalPremiumPrice !== undefined
                        ? totalPremiumPrice.toLocaleString('nl-BE', {
                            style: 'currency',
                            currency: 'EUR',
                          })
                        : null}
                      <span>/{t(`ANNUAL`).toLowerCase()}</span>
                      {/* {data?.payment_spread !== undefined ? (
                        <span>/{t(data?.payment_spread).toLowerCase()}</span>
                      ) : null} */}
                    </OfferPrice>
                  </OfferHeader>

                  <OfferGuarantees>
                    <StripedTable>
                      <StripedTable.Head>
                        <StripedTable.HeaderCell width="5%" />
                        <StripedTable.HeaderCell width="5%" />
                        <StripedTable.HeaderCell width="unset">
                          {t(`Guarantee`)}
                        </StripedTable.HeaderCell>
                        <StripedTable.HeaderCell width="unset">
                          {t(`Net premium`)}
                        </StripedTable.HeaderCell>
                        <StripedTable.HeaderCell width="unset">
                          {t(`Taxes`)}
                        </StripedTable.HeaderCell>
                        <StripedTable.HeaderCell width="unset">
                          {t(`Total premium`)}
                        </StripedTable.HeaderCell>
                        <StripedTable.HeaderCell width="unset">
                          {t(`Commission`)}
                        </StripedTable.HeaderCell>
                        {/* <StripedTable.HeaderCell width="unset">
                  VK
                </StripedTable.HeaderCell> */}
                      </StripedTable.Head>
                      <StripedTable.Body>
                        {updatedData.guarantees.map((guarantee, i) => (
                          <StripedTable.Row key={i + 1}>
                            <StripedTable.BodyCell>
                              {editOfferMode ? (
                                <CheckBox
                                  disabled={guarantee?.mandatory}
                                  checked={guarantee?.taken}
                                  onChange={e => {
                                    handleGuaranteesChange(guarantee?.id, e);
                                  }}
                                />
                              ) : (
                                <CheckBox disabled checked={guarantee?.taken} />
                              )}
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              <QuoteGuaranteeIcons
                                type={
                                  guarantee?.name === 'civil_liability' &&
                                  data?.insurance?.type === 'FAMILY'
                                    ? 'family'
                                    : guarantee?.name
                                }
                                color="#8990A3"
                                size={25}
                              />
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              {editOfferMode &&
                              (['omnium', 'mini_omnium'].includes(
                                guarantee?.name,
                              ) ||
                                (guarantee?.name === 'civil_liability' &&
                                  !updatedData.guarantees
                                    .map(guarantee => guarantee?.name)
                                    .includes('mini_omnium') &&
                                  !updatedData.guarantees
                                    .map(guarantee => guarantee?.name)
                                    .includes('omnium'))) &&
                              ['CAR', 'TWO_WHEELER'].includes(
                                data?.insurance?.type,
                              ) ? (
                                <QuickActionSubMenu
                                  name={
                                    guarantee?.name in guaranteeNames
                                      ? guaranteeNames[
                                          overwriteInsuranceWith
                                            ? overwriteInsuranceWith
                                            : guarantee?.name
                                        ]?.[i18n.language]
                                      : t(
                                          `anon_pack_types.${
                                            overwriteInsuranceWith
                                              ? overwriteInsuranceWith
                                              : guarantee?.name
                                          }`,
                                        )
                                  }
                                >
                                  {['omnium', 'mini_omnium'].map(item => (
                                    <QuickActionSubMenu.SubMenuItem
                                      key={item}
                                      label={<SubLabel>{t(item)}</SubLabel>}
                                      onClick={() =>
                                        setOverwriteInsuranceWith(item)
                                      }
                                      type=""
                                    />
                                  ))}
                                </QuickActionSubMenu>
                              ) : (['omnium', 'mini_omnium'].includes(
                                  guarantee?.name,
                                ) ||
                                  (guarantee?.name === 'civil_liability' &&
                                    !updatedData.guarantees
                                      .map(guarantee => guarantee?.name)
                                      .includes('mini_omnium') &&
                                    !updatedData.guarantees
                                      .map(guarantee => guarantee?.name)
                                      .includes('omnium'))) &&
                                ['CAR', 'TWO_WHEELER'].includes(
                                  data?.insurance?.type,
                                ) ? (
                                guarantee?.name in guaranteeNames ? (
                                  guaranteeNames[
                                    overwriteInsuranceWith
                                      ? overwriteInsuranceWith
                                      : guarantee?.name
                                  ]?.[i18n.language]
                                ) : (
                                  t(
                                    `anon_pack_types.${
                                      overwriteInsuranceWith
                                        ? overwriteInsuranceWith
                                        : guarantee?.name
                                    }`,
                                  )
                                )
                              ) : guarantee?.name in guaranteeNames ? (
                                guaranteeNames[guarantee?.name]?.[i18n.language]
                              ) : (
                                t(
                                  guarantee?.name === 'family'
                                    ? 'family_guarantee'
                                    : guarantee?.name,
                                )
                              )}
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              &euro; {guarantee?.net_premium?.toFixed(2)}
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              &euro;{' '}
                              {(
                                guarantee?.taxes * guarantee?.net_premium
                              )?.toFixed(2)}
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              &euro; {guarantee?.total_premium?.toFixed(2)}
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              {(guarantee?.commission * 100)?.toFixed(2)}%
                            </StripedTable.BodyCell>
                          </StripedTable.Row>
                        ))}
                      </StripedTable.Body>
                      <StripedTable.Footer>
                        <StripedTable.FooterCell width="unset" />
                        <StripedTable.FooterCell width="unset" />
                        <StripedTable.FooterCell width="unset">
                          {t(`Checkout.total`)}
                        </StripedTable.FooterCell>
                        <StripedTable.FooterCell width="unset">
                          &euro; {totalNetPrice?.toFixed(2)}
                        </StripedTable.FooterCell>
                        <StripedTable.FooterCell width="unset">
                          &euro; {totalTaxPrice?.toFixed(2)}
                        </StripedTable.FooterCell>
                        <StripedTable.FooterCell width="unset">
                          &euro; {totalPremiumPrice?.toFixed(2)}
                        </StripedTable.FooterCell>
                        <StripedTable.FooterCell width="unset" />
                        <StripedTable.FooterCell width="unset" />
                      </StripedTable.Footer>
                    </StripedTable>
                  </OfferGuarantees>

                  {data.description ? (
                    <DescriptionWrapper>
                      <Label>{t('Description')}</Label>
                      <Text>{data.description}</Text>
                    </DescriptionWrapper>
                  ) : null}

                  <SectionContentInner>
                    <TextWrapper>
                      <Label>{t('General documents')}</Label>
                      <DownloadableDoc href={termsLink} target={'_blank'}>
                        <img src={PDF} alt="PDF" draggable="false" />
                        <Text>{t('Checkout.card.termsPDF')}</Text>
                      </DownloadableDoc>
                      <DownloadableDoc href={ipidLink} target={'_blank'}>
                        <img src={PDF} alt="PDF" draggable="false" />
                        <Text>{t('Checkout.card.IPID')}</Text>
                      </DownloadableDoc>
                    </TextWrapper>

                    <TextWrapper>
                      <Label> </Label>
                      <Text> </Text>
                    </TextWrapper>

                    <TextWrapper>
                      {editOfferMode ? (
                        <DateInput
                          error={errors.commencement_date}
                          name="commencement_date"
                          onChange={handleCommencementDateChange}
                          value={formatISODate(commencementDate)}
                        >
                          {t('Commencement date')}
                        </DateInput>
                      ) : (
                        <>
                          <Label>{t('Commencement date')}</Label>
                          <Text>{formatISODate(commencementDate)}</Text>
                        </>
                      )}
                    </TextWrapper>

                    <TextWrapper>
                      {editOfferMode ? (
                        <SearchSelectInput
                          error={errors.payment_spread}
                          initial={paymentSpread}
                          name="payment_spread"
                          onSelected={handlePaymentSpreadChange}
                          options={paymentSpreadOptions}
                          placeholder={t('Choose an option')}
                        >
                          {t('Payment cycle')}
                        </SearchSelectInput>
                      ) : (
                        <>
                          <Label>{t('Payment cycle')}</Label>
                          <Text>{`${t(
                            `spread.` + paymentSpread?.[0]?.value,
                          )}`}</Text>
                        </>
                      )}
                    </TextWrapper>

                    <TextWrapper>
                      {editOfferMode ? (
                        <DateInput
                          error={errors.end_date}
                          name="end_date"
                          onChange={handleEndDateChange}
                          value={formatISODate(endDate)}
                        >
                          {t('Offer is valid till')}
                        </DateInput>
                      ) : (
                        <>
                          <Label>{t('Offer is valid till')}</Label>
                          <Text>{formatISODate(endDate)}</Text>
                        </>
                      )}
                    </TextWrapper>

                    <TextWrapper>
                      {editOfferMode ? (
                        ''
                      ) : (
                        <>
                          <Label>{t('Language')}</Label>
                          <Text>{`${t(
                            data.language === `NL` ? `Dutch` : `French`,
                          )}`}</Text>
                        </>
                      )}
                    </TextWrapper>
                  </SectionContentInner>

                  {data.insurance.type === 'CAR' ||
                  data.insurance.type === 'TWO_WHEELER' ? (
                    <>
                      <Divider />
                      <OfferEvaluation>
                        {editOfferMode ? (
                          <StyledTextInput
                            error={errors.bonus_malus}
                            name="bonus_malus"
                            placeholder={t('Checkout.insured.bonusMalus')}
                            type="number"
                            value={bonusMalus}
                            onChange={handleBonusMalusChange}
                          >
                            {t('Checkout.insured.bonusMalus')}
                          </StyledTextInput>
                        ) : (
                          <>
                            <EvaluationMethod>
                              <h5>{t(`Checkout.insured.bonusMalus`)}</h5>
                              <EvaluationWrapper>
                                <h4>{bonusMalus != null ? bonusMalus : '-'}</h4>
                              </EvaluationWrapper>
                            </EvaluationMethod>
                          </>
                        )}
                      </OfferEvaluation>
                    </>
                  ) : null}

                  {data.insurance.type === 'RESIDENCE' ? (
                    <>
                      <Divider />
                      {data?.premium?.insured_value?.value ? (
                        <>
                          <OfferTitle>{t('Building')}</OfferTitle>
                          <OfferEvaluation>
                            <ValueBlock>
                              <h5>{t('insured value')}</h5>
                              <h4>
                                &euro;&nbsp;
                                {t(
                                  data?.premium?.insured_value?.value.toFixed(
                                    2,
                                  ),
                                )}
                              </h4>
                            </ValueBlock>
                            <EvaluationMethod>
                              <h5>{t('Evaluation method')}</h5>
                              <EvaluationWrapper>
                                <h4>
                                  {t(
                                    data?.premium?.insured_value
                                      ?.evaluation_type,
                                  )}
                                </h4>
                              </EvaluationWrapper>
                            </EvaluationMethod>
                          </OfferEvaluation>
                        </>
                      ) : null}

                      {data?.premium?.insured_content_value?.value ? (
                        <>
                          <OfferTitle>{t('Content')}</OfferTitle>
                          <OfferEvaluation>
                            <ValueBlock>
                              <h5>{t('insured content value')}</h5>
                              <h4>
                                &euro;&nbsp;
                                {t(
                                  data?.premium?.insured_content_value?.value.toFixed(
                                    2,
                                  ),
                                )}
                              </h4>
                            </ValueBlock>
                            <EvaluationMethod>
                              <h5>{t('Evaluation method')}</h5>
                              <EvaluationWrapper>
                                <h4>
                                  {t(
                                    data?.premium?.insured_content_value
                                      ?.evaluation_type,
                                  )}
                                </h4>
                              </EvaluationWrapper>
                            </EvaluationMethod>
                          </OfferEvaluation>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  {(data?.acknowledgements || []).length > 0 ? (
                    <section>
                      <OfferTitle>{t('Acknowledgements')}</OfferTitle>

                      <OfferAcknowledgements>
                        {data?.acknowledgements.map(acknowledgement => (
                          <div key={acknowledgement?.id}>
                            {editOfferMode ? (
                              <CheckBox
                                disabled={false}
                                checked={acknowledgement?.agreed}
                                name={acknowledgement?.id}
                                onChange={e => {
                                  handleAcknowledgementChange(
                                    acknowledgement?.id,
                                    e,
                                  );
                                }}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: acknowledgement?.text,
                                  }}
                                ></p>
                              </CheckBox>
                            ) : (
                              <CheckBox
                                disabled
                                checked={acknowledgement?.agreed}
                                name={acknowledgement?.id}
                              >
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: acknowledgement?.text,
                                  }}
                                ></p>
                              </CheckBox>
                            )}
                          </div>
                        ))}
                      </OfferAcknowledgements>
                    </section>
                  ) : null}

                  {offerDocuments ? (
                    <>
                      <Divider />

                      <section>
                        <OfferTitle>{t('Offer documents')}</OfferTitle>

                        <OfferDocuments>
                          {offerDocuments?.signed?.map(doc => {
                            return (
                              <OfferDocument
                                key={doc.id}
                                unsignedDocs={offerDocuments?.unsigned}
                                distributionId={distributionId}
                                data={data}
                                docInfo={doc}
                                unsigned={false}
                                // onClick={() => {
                                //   handleOfferDocumentClick(doc?.id);
                                // }}
                              />
                            );
                          })}
                          {unsignedDocuments?.map(unsigned => {
                            return (
                              <OfferDocument
                                key={unsigned.id}
                                unsigned={true}
                                distributionId={distributionId}
                                data={data}
                                docInfo={unsigned}
                                // onClick={() => {
                                //   handleOfferDocumentClick(doc?.id);
                                // }}
                              />
                            );
                          })}
                        </OfferDocuments>
                      </section>
                    </>
                  ) : null}

                  {editOfferMode ? (
                    <SectionContentFooter>
                      <ActionButton level="default" onClick={ToggleEditMode}>
                        {t('Cancel')}
                      </ActionButton>
                      <Spacer />
                      <ActionButton submit>{t('Save')}</ActionButton>
                    </SectionContentFooter>
                  ) : null}
                </Section.Content>
              </Section>
            </form>
          </section>

          <section ref={refs[1][2]}>
            <CRMProspectClientData
              disableEdit={wasAlreadyConverted || !canEditOffers}
              prospectData={data.prospect}
              distributionId={distributionId}
              customerPageFunction={editValue =>
                handleEditModeChange(editValue)
              }
            />
          </section>

          <section ref={refs[1][3]}>
            <SubTitle>{t('Risk objects')}</SubTitle>
            <CRMRiskObjectTabsOffers
              prospectId={match.params.prospectId}
              disableEdit={wasAlreadyConverted || !canEditOffers}
              riskObjects={updatedData.risk_objects}
              offerId={data?.id}
              offerRevision={data?.revision}
            />

            {/* <p>
              We zijn hard aan het werk om dit onderdeel zo snel mogelijk aan te
              bieden.
            </p> */}
          </section>
          <section ref={refs[1][4]}>
            <Section>
              <Section.Title>{t('Notes')}</Section.Title>

              <Section.Content background>
                <form onSubmit={formSubmitHandler}>
                  <TextArea
                    maxLength={-1}
                    name="comment"
                    error={errors.comment}
                    onChange={val => handleChange(val)}
                    value={values.comment ? values.comment : ''}
                    data-test-id="quote_note_inputfield"
                  >
                    {t('Add a new note')}
                  </TextArea>

                  <NoteButtonWrapper>
                    <CheckBox
                      name="private"
                      onChange={handlePrivateChange}
                      checked={values.private}
                      data-test-id="quote_note_checkbox_private"
                    >
                      {t('Private note?')}
                    </CheckBox>

                    <ActionButton
                      type="submit"
                      value="Submit"
                      disabled={errors.comment ? true : false}
                      data-test-id="quote_note_btn_add"
                    >
                      {t('Add a new note')}
                    </ActionButton>
                  </NoteButtonWrapper>
                </form>

                {notes
                  .slice(0)
                  .reverse()
                  .map((noteObject, i) => {
                    const { note } = noteObject;
                    return (
                      <>
                        <Divider />
                        <div data-test-id={`quote_comment_${i}`}>
                          <CommentBox
                            icon={<IconDocumentFilled color="#CCCCCC" />}
                            id={note.id}
                            title={`${formatISODate(note.created_at)} - ${t(
                              `Added by`,
                            )} ${
                              note?.author
                                ? brokers?.length > 0
                                  ? brokers?.find(
                                      item => item?.broker?.id === note?.author,
                                    )?.broker?.name
                                  : t('loading')
                                : t('Client')
                            }`}
                            key={note.id}
                            deleteHandler={() => deleteNoteHandler(note.id)}
                          >
                            {note.text}
                          </CommentBox>
                        </div>
                      </>
                    );
                  })}
              </Section.Content>
            </Section>
          </section>

          {/* <Section>
            <Section.Title>Documents</Section.Title>

            <Section.Content>
              <AddEntityButton
                fullwidth
                icon="Upload"
                name="Click or drag files here to upload them"
              />
            </Section.Content>
          </Section> */}

          {/* <section ref={refs[1][4]}>
            <Section>
              <Section.Title>Sticky notes</Section.Title>

              <Section.Content background>
                <form onSubmit={formSubmitHandler}>
                  <TextArea
                    maxLength={-1}
                    name="comment"
                    error={errors.comment}
                    onChange={val => handleChange(val)}
                    value={values.comment ? values.comment : ''}
                  >
                    Add a new note
                  </TextArea>

                  <NoteButtonWrapper>
                    <CheckBox
                      name="private"
                      onChange={handlePrivateChange}
                      checked={values.private}
                    >
                      Private note?
                    </CheckBox>

                    <ActionButton
                      type="submit"
                      value="Submit"
                      disabled={errors.comment ? true : false}
                    >
                      {t('Add a new note')}
                    </ActionButton>
                  </NoteButtonWrapper>
                </form>

                {Notes.map(note => {
                  return (
                    <>
                      <Divider />

                      <CommentBox
                        icon={<IconDocumentFilled color="#ccc" />}
                        id={note.id}
                        title={`${note.created_at} - Added by ${note.author}`}
                        key={note.id}
                      >
                        {note.text}
                      </CommentBox>
                    </>
                  );
                })}
              </Section.Content>
            </Section>
          </section> */}
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const DescriptionWrapper = styled.div`
  margin-top: 2rem;
`;

const NoteButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  > button {
    margin-left: 35px;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const StyledAlertContent = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const Spacer = styled.div`
  width: 15px;
`;

const StyledSubNavigation = styled(SubNavigation)`
  margin-bottom: 0.5rem;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OfferHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

const OfferInfoLogo = styled.div`
  max-height: 3.5rem;
  flex: 0 1 auto;

  > img {
    max-height: 100%;
  }
`;

const OfferHeaderName = styled.div`
  flex: 1 0 auto;
  margin: 0 4rem;
  text-transform: capitalize;

  h3 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 130%;
    color: ${({ theme }) => theme.typo.title};
  }

  h4 {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.subTitle};
  }

  h5 {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.2rem;
    line-height: 2rem;
    text-transform: none;
    color: ${({ theme }) => theme.typo.subTitle};
  }
`;

const OfferPrice = styled(H1)`
  flex: 0 1 auto;
  font-family: ${({ theme }) => theme.font};
  font-size: 3.6rem;
  color: ${({ theme }) => theme.typo.title};

  span {
    font-size: 2.1rem;
  }
`;

const OfferGuarantees = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.ui.outline};
  border-bottom: 1px solid ${({ theme }) => theme.ui.outline};
  padding: 2.4rem 0;
`;

const DownloadableDoc = styled.a`
  display: flex;
  margin-top: 1.2rem;
  cursor: pointer;

  img {
    margin-right: 1.2rem;
    max-height: 2rem;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const OfferEvaluation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  h5 {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.subTitle};
    font-weight: bold;
  }

  h4 {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }
`;

const EvaluationMethod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0 0 49%;
  background-color: ${({ theme }) => theme.ui.background};
  padding: 1.2rem;
  border-radius: 8px;

  h4,
  h5 {
    text-align: center;
  }
`;

const EvaluationWrapper = styled.div`
  background: #f0f1f3;
  border-radius: 5px;
  padding: 1.2rem;
  margin-top: 0.6rem;
`;

const ValueBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 0 0 49%;
  background-color: ${({ theme }) => theme.ui.background};
  padding: 1.6rem;
  border-radius: 8px;

  h5 {
    margin-bottom: 0.5rem;
  }
`;

const OfferTitle = styled.h2`
  margin-top: 2.4rem;
  font-size: 1.6rem;
  font-weight: 900;
  line-height: 130%;
  color: ${({ theme }) => theme.typo.title};
`;

const OfferAcknowledgements = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 1.6rem;

  > div {
    margin: 0.5rem 0;
  }
`;

const OfferDocuments = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  margin-top: 2.4rem;

  > div {
    grid-column: span 3;
    @media screen and (max-width: 750px) {
      grid-column: span 6;
    }
  }
`;

// const OfferDocument = styled.div`
//   background-color: ${({ theme }) => theme.ui.background};
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1.2rem;
//   transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
//   cursor: pointer;

//   h4 {
//     font-size: 1.4rem;
//     font-weight: bold;
//     color: ${({ theme }) => theme.typo.text};
//   }

//   &:hover {
//     box-shadow: 0 0.7px 3.3px rgba(0, 0, 0, 0.02),
//       0 1.7px 8px rgba(0, 0, 0, 0.03), 0 3.1px 15.2px rgba(0, 0, 0, 0.04),
//       0 5.6px 27px rgba(0, 0, 0, 0.04), 0 10.4px 50.6px rgba(0, 0, 0, 0.05),
//       0 25px 121px rgba(0, 0, 0, 0.07);
//     background-color: white;
//   }
// `;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    height: unset;
    line-height: 1.6rem;
    margin-right: 0.78vw;
    min-height: 4rem;
    width: 90%;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

// const SubTitleSpacer = styled.div`
//   height: 20px;
// `;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContentInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 15px;
  }
`;

// const RiskObjectSpacer = styled.div`
//   height: 15px;
// `;

// const NoteButtonWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin-top: 20px;

//   > button {
//     margin-left: 35px;
//   }
// `;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.ui.outline};
  margin: 25px 0;
`;

const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.ui.outline};
  margin-bottom: 15px;

  section {
    display: flex;
    align-items: center;
  }
`;

const StyledCheckBox = styled(CheckBox)`
  font-weight: normal;
  margin-bottom: 6px;
`;

const ContractHeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  p:first-child {
    margin-bottom: 0.5rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
`;

CRMOfferDetail.propTypes = {
  match: shape(),
};

export default CRMOfferDetail;
