/* stylelint-disable no-descending-specificity */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { LoadingSpinner } from 'wg-fe-ui';

import Layout from '../../LayoutMarket/views/LayoutSignature';
import SocialLoginLayout from '../../LayoutMarket/views/LayoutNoStep';
import { getSignatureData } from '../store';
import {
  getOfferById,
  retrieveAvailableAffiliations,
} from '../../../../services/apiRouterService';
import { getBrokerDistribution } from '../../../../services/brokerDataService';
import { useTranslation } from 'react-i18next';
import { getSocialLogin } from '../../../../store';

import SignatureCard from '../components/SignatureCard';

const ThankYou = ({ history }) => {
  const { t } = useTranslation();
  const [offers, setOffers] = useState([]);
  const [distribution, setDistribution] = useState('');
  const [availableAffiliations, setAvailableAffiliations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const signatureData = getSignatureData();

  useEffect(() => {
    getAvailableInsurances();
    Object.keys(signatureData).forEach(offer => {
      getOffer(signatureData[offer]?.offer_id);
    });
  }, []);

  async function getOffer(offerId) {
    const { id } = await getBrokerDistribution();
    setDistribution(id);
    let [response, status] = await getOfferById(id, offerId);
    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      console.log('FAILED TO LOAD');
      // setIsLoading(!isLoading);
      // setOffer(null);
      return;
    }

    setOffers(offers => [...offers, response]);
  }

  async function getAvailableInsurances() {
    if (!getSocialLogin()) {
      const [logoResp, logoStatus] = await retrieveAvailableAffiliations();

      if (logoStatus !== 200) {
        return console.error(logoResp);
      }

      setAvailableAffiliations(availableAffiliations => [
        ...availableAffiliations,
        ...logoResp.items,
      ]);
      setIsLoading(!isLoading);
    } else {
      setIsLoading(!isLoading);
    }
  }

  if (isLoading) {
    return (
      <Layout>
        <div>
          <StyledLoadingSpinner isAbsolute={false} />
        </div>
      </Layout>
    );
  }

  if (getSocialLogin()) {
    return (
      <SocialLoginLayout>
        <Container>
          <Content>
            <h1>{t('ThankYou.title')}</h1>
            <p dangerouslySetInnerHTML={{ __html: t('ThankYou.info') }} />
          </Content>
        </Container>
      </SocialLoginLayout>
    );
  }

  return (
    <Layout>
      <Header>
        <h1>{t('Your insurance proposal are ready to be signed')}</h1>
        <p>{t('Thanks for calculating your quote with louise')}</p>
      </Header>
      <SignatureSection>
        {offers.map(offer => {
          return (
            <SignatureCard
              key={offer.id}
              offer={offer}
              availableAffiliations={availableAffiliations}
              distribution={distribution}
            />
          );
        })}
      </SignatureSection>
    </Layout>
  );
};

const Header = styled.header`
  color: white;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 2rem;

  & h1 {
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

const SignatureSection = styled.section`
  display: flex;
  flex-direction: column;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  > g {
    > circle {
      color: red;
    }
  }
`;

// const A = styled.a`
//   cursor: pointer;
//   background-color: ${({ theme, secondary }) =>
//     secondary ? theme.brand.interactive : theme.brand.primary};
//   width: 22.5rem;
//   height: 5.1rem;
//   border-radius: 0.5rem;
//   display: flex;
//   font-size: 2rem;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   border: 0;
//   font-weight: bold;
//   margin: 1rem;
//   text-decoration: none;
//   align-self: flex-end;
//   margin-bottom: 2rem;
//   margin-right: 2rem;
//   z-index: 11;

//   &:focus {
//     border: 0 0 0;
//   }

//   @media screen and (max-width: 1025px) {
//     transform: scale(0.9);
//     transform-origin: 100% 100%;
//     margin-bottom: 1rem;
//     margin-right: 1rem;
//   }
// `;

// const ButtonContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   padding-top: 4rem;
// `;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem;

  & p {
    margin-bottom: 2rem;

    & span {
      font-weight: 700;
    }
  }

  & h1 {
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

ThankYou.propTypes = {
  history: shape().isRequired,
};

export default ThankYou;
