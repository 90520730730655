import React from 'react';
import { string, shape } from 'prop-types';

const SvgComponent = ({ className, colors }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors.brand.primary}
      fillRule="evenodd"
    />
    <g fill="#fff">
      <path d="m199.79 240.36a40.1 40.1 0 1 1 40.09-40.1 40.14 40.14 0 0 1 -40.09 40.1zm0-55.24a15.15 15.15 0 1 0 15.14 15.14 15.16 15.16 0 0 0 -15.14-15.14z" />
      <path d="m273.28 126.78a103.42 103.42 0 0 0 -50.1-27.78 1.17 1.17 0 0 0 -1 .22 1.16 1.16 0 0 0 -.43.89v27.49a1.14 1.14 0 0 0 .79 1.09 75.11 75.11 0 1 1 -67.84 131.67 75.71 75.71 0 0 1 -26.7-38.05 1.15 1.15 0 0 0 -1.09-.81h-27.48a1.14 1.14 0 0 0 -.89.43 1.11 1.11 0 0 0 -.22 1 103.94 103.94 0 1 0 175-96.11z" />
      <path d="m124.7 199.88c0-.64 0-1.28 0-1.93 0-.64 0-1.28.08-1.91.08-1.31.17-2.52.3-3.69l.21-1.86c0-.11 0-.23.05-.35v-.27c.06-.43.12-.85.18-1.24l.09-.52c.07-.47.14-.93.21-1.33 0-.1 0-.19.06-.29v-.2c.08-.47.17-.93.27-1.4a1.29 1.29 0 0 0 0-.21l.12-.55c.08-.37.16-.75.25-1.13.15-.61.29-1.2.45-1.8.34-1.29.68-2.45 1-3.54a.19.19 0 0 0 0-.07c.18-.56.37-1.13.56-1.68s.47-1.35.73-2c.18-.48.35-.94.54-1.41.42-1 .86-2.09 1.32-3.1l.16-.34.43-.94c.32-.67.65-1.34 1-2l.54-1c.38-.72.78-1.44 1.2-2.17.29-.52.6-1 .9-1.54l.48-.76c.19-.32.4-.64.61-1 .43-.66.86-1.31 1.33-2 .23-.34.46-.69.7-1a77.85 77.85 0 0 1 5.53-6.86l1.23-1.32 1.26-1.28c.8-.81 1.68-1.65 2.61-2.49 1.78-1.62 3.65-3.16 5.55-4.58l.35-.12c.22-.17.43-.33.69-.5.65-.49 1.34-1 2.14-1.51.28-.19.55-.38.86-.57a74.73 74.73 0 0 1 20.58-9.4c1.19-.33 2.39-.64 3.59-.92a75.13 75.13 0 0 1 16.91-1.91h.31a1.15 1.15 0 0 0 1.21-1.14v-41a1.14 1.14 0 0 0 -1.14-1.14h-.38a118.25 118.25 0 0 0 -118.34 118 1.18 1.18 0 0 0 .33.81 1.14 1.14 0 0 0 .81.33h41a1.14 1.14 0 0 0 1.13-1.17z" />
    </g>
  </svg>
);

SvgComponent.propTypes = {
  className: string,
  colors: shape(),
};

export default SvgComponent;
