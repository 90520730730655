import React, { useState, createContext, useEffect } from 'react';
import { node } from 'prop-types';
import { patchStorage, retrieveStorageById } from '../storeService';

export const ProFlowStorageContext = createContext({
  answers: [],
  selectedOffers: [],
  getAnswers: () => {},
  addAnswer: () => {},
  removeAnswer: () => {},
  addSelectedOffers: () => {},
  removeSelectedOffers: () => {},
});

export const ProFlowStorageContextProvider = ({ children }) => {
  const [answers, setAnswers] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [sessionId, setSessionId] = useState();

  async function addAnswer(
    id,
    value,
    _sessionId,
    typeTags,
    type = undefined,
    field,
    tag,
  ) {
    setSessionId(_sessionId);
    setAnswers(_answers => {
      const index = _answers?.findIndex(({ id: _id }) => _id === id);
      const payload = {
        id,
        answer: value,
        typeTags,
        type,
        tag,
        ...(field && { field }),
      };
      if (index === -1) {
        _answers?.push(payload);
      } else if (id !== undefined) {
        _answers[index] = payload;
      }
      return [..._answers];
    });
  }

  async function removeAnswer(id, _sessionId) {
    const index = answers?.findIndex(({ id: _id }) => _id === id);

    // If found
    if (index > 0) {
      setSessionId(_sessionId);
      setAnswers(_answers => {
        _answers.splice(
          _answers?.findIndex(({ id: _id }) => _id === id),
          1,
        );

        return [..._answers];
      });
    }
  }

  async function addBackendAnswers(payload, _sessionId) {
    setSessionId(_sessionId);
    setAnswers(payload);
    patchStorage('pro_flow', sessionId, { answers: payload });
  }

  function addSelectedOffers(offer, _sessionId) {
    setSessionId(_sessionId);
    setSelectedOffers(offers => {
      const index = offers?.findIndex(({ id }) => id === offer.id);
      if (index === -1) {
        offers?.push(offer);
      } else {
        offers[index] = offer;
      }
      return [...offers];
    });
  }

  function removeSelectedOffers(id, _sessionId) {
    setSessionId(_sessionId);
    const offers = new Set(selectedOffers);
    offers.forEach(offer => (offer.id === id ? offers.delete(offer) : offer));
    setSelectedOffers([...offers]);
  }

  useEffect(() => {
    if (answers?.length === 0) return;
    if (sessionId) {
      patchStorage('pro_flow', sessionId, { answers });
    }
  }, [answers]);

  useEffect(() => {
    if (sessionId) {
      patchStorage('pro_flow', sessionId, { selected_offers: selectedOffers });
    }
  }, [selectedOffers]);

  function resetAnswers() {
    setAnswers([]);
    setSelectedOffers([]);
  }

  async function getAnswers(id) {
    const { answers: _answers } =
      (await retrieveStorageById('pro_flow', id)) || {};
    setAnswers(_answers);
    return _answers || [];
  }

  return (
    <ProFlowStorageContext.Provider
      value={{
        answers,
        getAnswers,
        addAnswer,
        removeAnswer,
        resetAnswers,
        selectedOffers,
        addBackendAnswers,
        addSelectedOffers,
        removeSelectedOffers,
      }}
    >
      {children}
    </ProFlowStorageContext.Provider>
  );
};

ProFlowStorageContextProvider.propTypes = {
  children: node.isRequired,
};
