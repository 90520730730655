import React from 'react';
import { string } from 'prop-types';

const Driver = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.5 239.5">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M119.75,0A119.75,119.75,0,1,0,239.5,119.75,119.75,119.75,0,0,0,119.75,0ZM102.9,205.21a7.3,7.3,0,0,1-9.29,7,96.35,96.35,0,0,1-61.24-52.5A7.3,7.3,0,0,1,39,149.35H71a31.87,31.87,0,0,1,31.87,31.87Zm16.85-64.52a14.34,14.34,0,1,1,14.35-14.34A14.34,14.34,0,0,1,119.75,140.69Zm88.36,18.9A96.26,96.26,0,0,1,143.73,213a7.28,7.28,0,0,1-8.95-7.1V181.22a31.87,31.87,0,0,1,31.87-31.87h34.79A7.27,7.27,0,0,1,208.11,159.59Zm-1.94-42.76L162.35,99.14a113.8,113.8,0,0,0-85.19,0L33.34,116.83a7.3,7.3,0,0,1-9-7.93,96.08,96.08,0,0,1,190.92,0A7.31,7.31,0,0,1,206.17,116.83Z"
        />
      </g>
    </g>
  </svg>
);

Driver.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Driver.propTypes = {
  title: string,
  color: string,
};

export default Driver;
