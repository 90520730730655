import React from 'react';

const LegalCoverage = () => (
  <svg viewBox="0 0 193.98 226.91">
    <title>{'legal_coverage'}</title>
    <path
      d="M194 91.91a101.42 101.42 0 00-89.49-71.58V7.5a7.5 7.5 0 00-15 0v12.83A101.42 101.42 0 000 91.91h89.49v112.55a7.45 7.45 0 11-14.9 0V193a7.5 7.5 0 00-15 0v11.43a22.45 22.45 0 1044.9 0V91.91z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default LegalCoverage;
