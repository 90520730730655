import React, { useState, useEffect, useContext } from 'react';
import { string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { cloneDeep, isEqual } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import cogoToast from 'cogo-toast';

/* API services */
import {
  getBrokerDistribution,
  getBrokerData,
} from '../../services/brokerDataService';
import { getMeData } from '../../services/meDataService';
import {
  getFilteredOffersByDistributionId,
  getAffinityByCompanyKey,
  retrieveAvailableAffiliations,
  retrieveAffinitiesById,
  retrieveAffiliationsById,
  getBrokersByDistributionId,
  updateOfferById,
  changeOfferStatus,
} from '../../services/apiRouterService';
import CheckBoxContainer from '../../components/CheckBoxContainer';
import {
  CollapsibleSidebar,
  CheckBox_v2 as CheckBox,
  OrderTable,
  RiskObjectLabel,
  PhaseLabel,
  SearchSelectInput,
  Pagination,
  IconPropertyFilled,
  IconFamilyFilled,
  IconLegalFilled,
  IconCarFilled,
  IconHeavyScooter,
  IconStats,
  ActionButton,
  SearchInput,
  LoadingSpinner,
  SubLabel,
  OverviewTable,
} from 'wg-fe-ui';

import QuickActionSubMenu from '../../components/QuickActionSubmenu';
import QuickActionSubMenuStatus from '../../components/QuickActionSubmenuStatus';

import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';
import DateFilterComponent from '../../components/DateFilterComponent';
import CRMOfferRejectionModal from '../../components/CRMOfferRejectionModal';

import Frame from '../../../assets/images/Frame.svg';
import i18next from 'i18next';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc.jsx';
import { useLocation } from 'react-router';
import { stringify } from 'query-string';

// Add variables out the rerender scope to save the amount of items are present in that filter
// If during query param generation the filter count is the same amount, they aren't
// added to the string
let affinityLength = 0;
let companyLength = 0;
let insuranceTypeLength = 4;
let statusesLength = 7;
const initialFilters = {
  status: {
    PENDING: true,
    SEND: true,
    SIGNED: true,
    REJECTED: true,
    CANCELLED: true,
    EXPIRED: true,
  },
  created_at_ge: null,
  created_at_le: null,
  start_date_ge: null,
  start_date_le: null,
  end_date_ge: null,
  end_date_le: null,
  insurance_affinity: {},
  insurance_company: {},
  insurance_type: {
    CAR: true,
    FAMILY: true,
    RESIDENCE: true,
    LEGAL: true,
  },
  assigned_to: [],
  created_by: [],
};

const CRMOffersOverview = ({ match }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const [distributionId, setDistributionId] = useState();
  const [amountOfEntries, setAmountOfEntries] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const [topSearchValue, setTopSearchValue] = useState(
    location?.state?.topSearch || '',
  );
  const [paginationData, setPaginationData] = useState({});
  const [affinities, setAffinities] = useState([]);
  const [affinityKeyList, setAffinityKeyList] = useState([]);
  const [affiliations, setAffiliations] = useState([]);
  const [affiliationKeyList, setAffiliationKeyList] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);
  const configCatClient = useContext(FlagsProvider);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [isCallantFeature, setIsCallantFeature] = useState(null);
  const [activeFilters, setActiveFilters] = useState(
    location?.state?.filters || initialFilters,
  );
  const [previousFilters, setPreviousFilters] = useState(
    location?.state?.filters || initialFilters,
  );
  const [haveFiltersChanged, setHaveFiltersChanged] = useState(false);
  const history = useHistory();

  const [retrievedData, setRetrievedData] = useState([]);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectionStatus, setRejectionStatus] = useState();

  const showAmountOfEntries = ({ amount, pagelen, page }) => {
    setAmountOfEntries(
      `${page * pagelen - pagelen + 1} ${t('pagination.to')} ${page *
        pagelen} ${t('pagination.of')} ${amount}`,
    );
  };

  const getAffinities = async filters => {
    const { broker } = await getBrokerData();
    const [resp, status] = await getAffinityByCompanyKey('callant');
    const [brokerAffinities, statusAff] = await retrieveAffinitiesById(
      broker?.id,
    );
    const tempAffinities = [];
    const tempAffinityKeyList = [];
    const tempFilters = cloneDeep(filters);
    let areFiltersSet = false;
    if (location?.state?.filters) areFiltersSet = true;

    // NOTE: Some initial sorting and filtering on the affinities shown
    const sortedItems = await resp.items.sort((a1, a2) => {
      if (a1?.affinity_info?.names?.nl > a2?.affinity_info?.names?.nl) return 1;
      if (a1?.affinity_info?.names?.nl < a2?.affinity_info?.names?.nl)
        return -1;
      return 0;
    });

    affinityLength = resp?.items?.length;
    const filteredItems = [];
    if (statusAff !== 200) {
      return console.error('Error Response: ', brokerAffinities);
    } else {
      const affinitiesToDisplay = brokerAffinities?.items || [];
      affinitiesToDisplay.forEach(affinityTD => {
        const companyKey = affinityTD?.affinity?.company_key;
        const keys = affinityTD?.affinity?.affinity_keys || [];
        sortedItems.forEach(item => {
          if (
            keys.includes(item?.affinity_info?.key) &&
            item?.affinity_info?.company_key === companyKey
          ) {
            filteredItems.push(item);
          }
        });
      });
    }

    if (status !== 200) return setAffinities(false);
    filteredItems.forEach(element => {
      tempAffinities.push({
        key: element.affinity_info.key,
        label: element.affinity_info.names[i18next.language],
      });
      tempAffinityKeyList.push(element.affinity_info.key);
      tempFilters.insurance_affinity[element.affinity_info.key] = areFiltersSet
        ? tempFilters?.insurance_affinity?.[element.affinity_info.key]
          ? true
          : false
        : true;
    });

    setAffinityKeyList(tempAffinityKeyList);
    setAffinities(tempAffinities);
    return tempFilters;
  };

  const getAffiliations = async filters => {
    const { broker } = await getBrokerData();
    const [resp, status] = await retrieveAvailableAffiliations();
    const [brokerAffiliations, statusAff] = await retrieveAffiliationsById(
      broker?.id,
    );
    const tempAffiliations = [];
    const tempAffiliationKeyList = [];
    const tempFilters = cloneDeep(filters);
    companyLength = resp?.items?.length;
    let areFiltersSet = false;
    if (location?.state?.filters) areFiltersSet = true;
    // NOTE: Some initial sorting and filtering on the affiliations shown
    const sortedItems = await resp.items.sort((a1, a2) => {
      if (a1?.affiliation_info?.names?.nl > a2?.affiliation_info?.names?.nl)
        return 1;
      if (a1?.affiliation_info?.names?.nl < a2?.affiliation_info?.names?.nl)
        return -1;
      return 0;
    });
    const filteredItems = [];
    if (statusAff !== 200) {
      return console.error('Error Response: ', brokerAffiliations);
    } else {
      const affiliationsToDisplay = brokerAffiliations?.items || [];
      affiliationsToDisplay.forEach(affiliationTD => {
        const companyKey = affiliationTD?.affiliation?.name;
        sortedItems.forEach(item => {
          if (item?.affiliation_info?.name === companyKey) {
            filteredItems.push(item);
          }
        });
      });
    }

    if (status !== 200) return setAffiliations(false);
    filteredItems.forEach(element => {
      tempAffiliations.push({
        key: element.affiliation_info.name,
        label: element.affiliation_info.label,
      });
      tempAffiliationKeyList.push(element.affiliation_info.name);
      tempFilters.insurance_company[
        element.affiliation_info.name
      ] = areFiltersSet
        ? tempFilters?.insurance_company?.[element.affiliation_info.name]
          ? true
          : false
        : true;
    });
    setAffiliationKeyList(tempAffiliationKeyList);
    setAffiliations(tempAffiliations);
    return tempFilters;
  };

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const callantFeature = await configCatClient.getValueAsync(
      'callantFeature',
      true,
      {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      },
    );
    let areFiltersSet = false;
    if (location?.state?.filters) areFiltersSet = true;
    let tempFilters = cloneDeep(activeFilters);
    if (callantFeature) {
      if (areFiltersSet) {
        tempFilters.insurance_type.TEACHER =
          tempFilters?.insurance_type?.TEACHER || false;
        tempFilters.insurance_type.TWO_WHEELER =
          tempFilters?.insurance_type?.TWO_WHEELER || false;
      } else {
        tempFilters.insurance_type.TEACHER = true;
        tempFilters.insurance_type.TWO_WHEELER = true;
      }
      setActiveFilters(tempFilters);
      setPreviousFilters(tempFilters);
      insuranceTypeLength = 6;
      tempFilters = await getAffinities(tempFilters);
    } else {
      tempFilters = await getAffiliations(tempFilters);
    }

    await setIsCallantFeature(callantFeature);
    return tempFilters;
  }

  const retrieveBrokersFromDistribution = async id => {
    const [resp] = await getBrokersByDistributionId(id);

    const idsToRemoveFrom = [
      '3471bb16-fe88-4e34-840e-b2d000ac1603',
      '0b6c634d-4f1d-4264-89ee-bbe8a06bfa24',
    ];

    // Sets all brokers and removes specific brokers from the CALLANT list.
    setBrokers(
      resp.items.filter(
        item =>
          item.broker.plan !== 'CALLANT' &&
          !idsToRemoveFrom.some(obj => obj === item.broker.id),
      ),
    );

    const sortedAllBrokers = resp.items.sort((b1, b2) => {
      if (b1?.broker?.name > b2?.broker?.name) return 1;
      if (b1?.broker?.name < b2?.broker?.name) return -1;
      return 0;
    });

    const tempCreatedByOptions = [];

    sortedAllBrokers
      .filter(object => !idsToRemoveFrom.some(obj => obj === object.broker.id))
      .forEach(item => {
        tempCreatedByOptions.push({
          label: item.broker.name,
          value: item.broker.id,
        });
      });

    setCreatedByOptions(tempCreatedByOptions);

    const tempAssignedToOptions = [];
    resp.items
      .filter(object => !idsToRemoveFrom.some(obj => obj === object.broker.id))
      .forEach(item => {
        tempAssignedToOptions.push({
          label: item.broker.name,
          value: item.broker.id,
        });
      });

    setAssignedToOptions(tempAssignedToOptions);
  };

  useEffect(() => {
    setIsLoadingOffers(true);
    const getFilteredData = async () => {
      let tempFilters = cloneDeep(activeFilters);
      if (affinities.length === 0 && affiliations.length === 0) {
        tempFilters = await setFeatureToggles();
      }
      setNavTabs(
        setTabItems(null, t, 'offersOverview', match.match.params.page),
      );
      if (location?.state?.filters) {
        setActiveFilters(location?.state?.filters);
        setPreviousFilters(location?.state?.filters);
        await getFilteredOffers(location?.state?.filters);
      } else {
        setActiveFilters(tempFilters);
        setPreviousFilters(tempFilters);
        await getFilteredOffers(tempFilters);
      }
    };

    getFilteredData();
  }, [match.match.params.page, location?.state?.filters]);

  // useEffect(() => {
  //   setFeatureToggles();
  // }, []);

  useEffect(() => {
    showAmountOfEntries(paginationData);
  }, [paginationData]);

  const handleApplyFilters = async filters => {
    setIsLoadingOffers(true);
    if (!filters) filters = activeFilters;
    setPreviousFilters(cloneDeep(filters));
    setHaveFiltersChanged(false);
    getFilteredOffers(filters);
  };

  async function getFilteredOffers(filters) {
    setIsLoadingOffers(true);
    const { id } = await getBrokerDistribution();
    if (!filters) filters = activeFilters;
    setDistributionId(id);
    const paramDefaultLength = {
      status: statusesLength,
      insurance_type: insuranceTypeLength,
      insurance_affinity: affinityLength,
      insurance_company: companyLength,
    };
    const objectToConvertToParams = {};
    Object.keys(filters).forEach(key => {
      switch (key) {
        case 'insurance_type':
        case 'insurance_affinity':
        case 'insurance_company':
        case 'status':
          if (
            Object.keys(filters[key]).filter(item => item).length <
            paramDefaultLength[key]
          ) {
            objectToConvertToParams[key] = Object.keys(filters[key]).filter(
              item => item,
            );
          }
          break;
        case 'created_at_ge':
        case 'created_at_le':
        case 'start_date_ge':
        case 'start_date_le':
        case 'end_date_ge':
        case 'end_date_le':
          if (filters[key]) {
            objectToConvertToParams[key] = filters[key]?.toISOString();
          }
          break;
        case 'assigned_to':
        case 'created_by':
          if (filters[key].length > 0) {
            objectToConvertToParams[key] = filters[key]?.map(
              item => item.value,
            );
          }
          break;
        default:
          break;
      }
    });

    let [response, status] = await getFilteredOffersByDistributionId(
      id,
      match.match.params.page,
      stringify(objectToConvertToParams),
      topSearchValue,
    );

    if (status !== 200) {
      return console.error('Error Response: ', response);
    }

    // If the current page is not 1 and has no items in the response,
    // Redirect to page one so the call is redone to see if there are any items
    if (match.match.params.page > 1 && response?.items?.length === 0)
      return history.push({
        pathname: '/sales-management/contracts/offers/1',
        filters,
      });
    retrieveBrokersFromDistribution(id);
    setRetrievedData(await response.items);
    setPaginationData(await response.pagination);
    setIsLoading(false);
    setIsLoadingOffers(false);
  }

  const [debounceFetchContracts] = useDebouncedCallback(getFilteredOffers, 500);

  const handleTopSearch = e => {
    setTopSearchValue(e);
    debounceFetchContracts();
  };

  const handleTopSearchClear = () => {
    setTopSearchValue('');
    debounceFetchContracts();
  };

  const contractClickHandler = (e, offer) => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/offer/${offer.id}/${offer.revision}`,
      state: {
        filters: { ...previousFilters },
        page: match.match.params.page,
        topSearch: topSearchValue,
      },
    });
  };

  const updateAssignedTo = async (broker, i, offerId) => {
    const { id } = await getBrokerDistribution();
    const tempData = JSON.parse(JSON.stringify(retrievedData));
    setRetrievedData(prevValue => {
      const temp = JSON.parse(JSON.stringify(prevValue));
      temp.forEach((item, i) => {
        if (item.id === offerId) temp[i].assigned_to = broker;
      });
      return temp;
    });
    const [resp, respStatus] = await updateOfferById(id, offerId, {
      assigned_to: broker,
    });
    if (respStatus === 200) {
      cogoToast.success(t('Offer was successfully assigned'));
    } else if (resp.msg != null) {
      setRetrievedData(tempData);
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const updateStatus = async (status, i, offerId, revision) => {
    const { id } = await getBrokerDistribution();
    const tempData = JSON.parse(JSON.stringify(retrievedData));

    if (status === 'REJECTED' || status === 'CANCELLED') {
      setRejectionStatus(status);
      setShowRejectionModal(true);
      setSelectedOffer({
        offer: offerId,
        revision: revision,
        index: i,
      });
    } else {
      setRetrievedData(prevValue => {
        const temp = JSON.parse(JSON.stringify(prevValue));
        temp[i].status = status;
        return temp;
      });
      const [resp, respStatus] = await changeOfferStatus(
        id,
        offerId,
        revision,
        {
          status,
        },
      );
      if (respStatus === 200) {
        cogoToast.success(t('Offer status has been changed'));
      } else if (resp.msg != null || resp.detail != null) {
        setRetrievedData(tempData);
        cogoToast.error(
          <ToasterContent>
            <strong>{t(resp.msg)}</strong>
            <p>{t(resp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
      } else {
        setRetrievedData(tempData);
        cogoToast.error(t('Something went wrong'));
      }
    }
  };

  const createOfferHandler = () => {
    console.log('Create offer');
  };

  function getRiskObjectIcon(key) {
    switch (key) {
      case 'cars':
        return <IconCarFilled />;
      case 'families':
        return <IconFamilyFilled />;
      case 'residences':
        return <IconPropertyFilled />;
      case 'teachers':
        return <IconStats />;
      case 'two_wheelers':
        return <IconHeavyScooter />;
      case 'legal':
        return <IconLegalFilled />;
      default:
        return '';
    }
  }

  const checkValuesInObjectIsZero = object => {
    if (object != null || undefined) {
      return Object.keys(object).every(function(key) {
        return object[key] === 0;
      });
    }
  };

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  const toggleAllCheckBoxes = (checkboxNames, value, type) => {
    const newFilters = cloneDeep(activeFilters);
    checkboxNames.forEach(key => {
      if (!value) delete newFilters[type][key];
      else newFilters[type][key] = value;
    });
    setActiveFilters(newFilters);
    if (isEqual(previousFilters, newFilters)) {
      setHaveFiltersChanged(false);
    } else {
      setHaveFiltersChanged(true);
    }
  };

  const handleFilterChange = (e, key) => {
    setActiveFilters(prevValue => {
      let newFilters = cloneDeep(prevValue);
      if (key) {
        newFilters[key][e.name] = e.value;
        if (!e.value) delete newFilters[key][e.name];
      } else {
        newFilters[e.name] = e.value;
        if (!e.value) delete newFilters[e.name];
      }
      if (isEqual(previousFilters, newFilters)) {
        setHaveFiltersChanged(false);
      } else {
        setHaveFiltersChanged(true);
      }
      return newFilters;
    });
  };

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft></TopBarLeft>

          <TopBarRight>
            <TopBarSearch
              placeholder={t('Search for a name')}
              onChange={event => {
                handleTopSearch(event);
              }}
              text={topSearchValue}
              onClear={() => handleTopSearchClear()}
              data-test-id="offers_search"
            />

            <TopBarAction>
              <ActionButton onClick={createOfferHandler} disabled>
                {t('New offer')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <CRMOfferRejectionModal
        canClose={true}
        data={retrievedData}
        setData={setRetrievedData}
        modalState={[showRejectionModal, setShowRejectionModal]}
        distributionId={distributionId}
        offerId={selectedOffer}
        rejectionStatus={rejectionStatus}
        offerData={selectedOffer}
      />

      <MainContent>
        <Sidebar>
          <CollapsibleSidebar label={t('Filters')} open>
            {isCallantFeature && affinities ? (
              <>
                <CheckBoxContainer
                  label={t('Affinities')}
                  defaultChecked={() => {
                    let isChecked = true;
                    affinityKeyList.forEach(el => {
                      if (!activeFilters?.insurance_affinity?.[el])
                        return (isChecked = false);
                    });
                    return isChecked;
                  }}
                  toggleAllCheckBoxes={(names, value) =>
                    toggleAllCheckBoxes(names, value, 'insurance_affinity')
                  }
                >
                  {affinities.map(item => (
                    <StyledCheckBox
                      key={item.key}
                      name={item.key}
                      checked={activeFilters?.insurance_affinity?.[item.key]}
                      onChange={e =>
                        handleFilterChange(e, 'insurance_affinity')
                      }
                    >
                      {t(`${item.label}`)}
                    </StyledCheckBox>
                  ))}
                </CheckBoxContainer>

                <SidebarSpacer />
              </>
            ) : (
              <>
                <CheckBoxContainer
                  label={t('Affinities')}
                  defaultChecked={() => {
                    let isChecked = true;
                    affiliationKeyList.forEach(el => {
                      if (!activeFilters?.insurance_company?.[el])
                        return (isChecked = false);
                    });
                    return isChecked;
                  }}
                  toggleAllCheckBoxes={(names, value) =>
                    toggleAllCheckBoxes(names, value, 'insurance_company')
                  }
                >
                  {affiliations.map(item => (
                    <StyledCheckBox
                      key={item.key}
                      name={item.key}
                      checked={activeFilters?.insurance_company?.[item.key]}
                      onChange={e => handleFilterChange(e, 'insurance_company')}
                    >
                      {t(`${item.label}`)}
                    </StyledCheckBox>
                  ))}
                </CheckBoxContainer>

                <SidebarSpacer />
              </>
            )}
            <CheckBoxContainer
              label={t('Offer phase')}
              toggleAllCheckBoxes={(names, value) =>
                toggleAllCheckBoxes(names, value, 'status')
              }
              defaultChecked={
                activeFilters?.status?.PENDING &&
                activeFilters?.status?.SIGNED &&
                activeFilters?.status?.SEND &&
                activeFilters?.status?.REJECTED &&
                activeFilters?.status?.ACCEPTED &&
                activeFilters?.status?.CANCELLED &&
                activeFilters?.status?.EXPIRED
              }
            >
              <StyledCheckBox
                name="PENDING"
                checked={activeFilters?.status?.PENDING}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.PENDING`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="SEND"
                checked={activeFilters?.status?.SEND}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.SEND`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="SIGNED"
                checked={activeFilters?.status?.SIGNED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.SIGNED`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="ACCEPTED"
                checked={activeFilters?.status?.ACCEPTED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.ACCEPTED`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="CANCELLED"
                checked={activeFilters?.status?.CANCELLED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.CANCELLED`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="REJECTED"
                checked={activeFilters?.status?.REJECTED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.REJECTED`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="EXPIRED"
                checked={activeFilters?.status?.EXPIRED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`offers.EXPIRED`)}
              </StyledCheckBox>
            </CheckBoxContainer>

            <SidebarSpacer />

            <CheckBoxContainer
              label={t('Insurance types')}
              toggleAllCheckBoxes={(names, value) =>
                toggleAllCheckBoxes(names, value, 'insurance_type')
              }
              defaultChecked={
                activeFilters?.insurance_type?.RESIDENCE &&
                activeFilters?.insurance_type?.FAMILY &&
                activeFilters?.insurance_type?.CAR &&
                activeFilters?.insurance_type?.LEGAL
              }
            >
              <StyledCheckBox
                name="RESIDENCE"
                checked={activeFilters?.insurance_type?.RESIDENCE}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconPropertyFilled size={18} color="#505050" />
                <CheckBoxSpacer /> {t('Property')}
              </StyledCheckBox>

              <StyledCheckBox
                name="FAMILY"
                checked={activeFilters?.insurance_type?.FAMILY}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconFamilyFilled size={18} color="#505050" />
                <CheckBoxSpacer />
                {t('Liability')}
              </StyledCheckBox>

              <StyledCheckBox
                name="CAR"
                checked={activeFilters?.insurance_type?.CAR}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconCarFilled size={18} color="#505050" />
                <CheckBoxSpacer />
                {t('Car insurance type')}
              </StyledCheckBox>
              <StyledCheckBox
                name="LEGAL"
                checked={activeFilters?.insurance_type?.LEGAL}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconLegalFilled size={18} color="#505050" />
                <CheckBoxSpacer />
                {t('legal')}
              </StyledCheckBox>

              {isCallantFeature
                ? [
                    <StyledCheckBox
                      key={0}
                      name="TWO_WHEELER"
                      checked={activeFilters?.insurance_type?.TWO_WHEELER}
                      onChange={e => handleFilterChange(e, 'insurance_type')}
                    >
                      <IconHeavyScooter size={18} color="#505050" />
                      <CheckBoxSpacer />
                      {t('Two wheeler insurance type')}
                    </StyledCheckBox>,
                    <StyledCheckBox
                      key={1}
                      name="TEACHER"
                      checked={activeFilters?.insurance_type?.TEACHER}
                      onChange={e => handleFilterChange(e, 'insurance_type')}
                    >
                      <IconStats size={18} color="#505050" />
                      <CheckBoxSpacer />
                      {t('Teacher insurance type')}
                    </StyledCheckBox>,
                  ]
                : null}
            </CheckBoxContainer>

            <SidebarSpacer />

            <DateFilterComponent
              startDate={activeFilters?.created_at_ge}
              endDate={activeFilters?.created_at_le}
              name={'filterDateCreated'}
              setStartDate={value =>
                handleFilterChange({ name: 'created_at_ge', value })
              }
              setEndDate={value =>
                handleFilterChange({ name: 'created_at_le', value })
              }
            >
              {t('Created between')}
            </DateFilterComponent>

            <SidebarSpacer />

            <DateFilterComponent
              startDate={activeFilters?.start_date_ge}
              endDate={activeFilters?.start_date_le}
              name={'filterDateStarted'}
              setStartDate={value =>
                handleFilterChange({ name: 'start_date_ge', value })
              }
              setEndDate={value =>
                handleFilterChange({ name: 'start_date_le', value })
              }
            >
              {t('Started between')}
            </DateFilterComponent>

            <SidebarSpacer />

            <DateFilterComponent
              startDate={activeFilters?.end_date_ge}
              endDate={activeFilters?.end_date_le}
              name={'filterDateEnded'}
              setStartDate={value =>
                handleFilterChange({ name: 'end_date_ge', value })
              }
              setEndDate={value =>
                handleFilterChange({ name: 'end_date_le', value })
              }
            >
              {t('Ended between')}
            </DateFilterComponent>

            <SidebarSpacer />
            <StyledSearchSelectInput
              isMulti
              name="assigned_to"
              value={activeFilters?.assigned_to}
              placeholder={t('Choose an option')}
              options={assignedToOptions}
              onSelected={({ value }) => {
                const option = value ? value : [];
                handleFilterChange({ name: 'assigned_to', value: option });
              }}
            >
              {t('Assigned to')}
            </StyledSearchSelectInput>

            <StyledSearchSelectInput
              isMulti
              name="created_by"
              value={activeFilters?.created_by}
              placeholder={t('Choose an option')}
              options={createdByOptions}
              onSelected={({ value }) => {
                const option = value ? value : [];
                handleFilterChange({ name: 'created_by', value: option });
              }}
            >
              {t('Created by')}
            </StyledSearchSelectInput>

            <SidebarSpacer />

            <ActionButton
              disabled={!haveFiltersChanged}
              fullwidth
              icon={null}
              level="primary"
              onClick={() => {
                handleApplyFilters();
              }}
            >
              {t('Apply filters')}
            </ActionButton>
          </CollapsibleSidebar>
        </Sidebar>

        <Content>
          {isLoadingOffers ? (
            <LoadingContainerOffers>
              <LoadingSpinner />
            </LoadingContainerOffers>
          ) : topSearchValue !== '' && retrievedData?.length === 0 ? (
            <EmptyState>
              <img src={Frame} alt="Test" />
              <h1>{t('No search results found')}</h1>
              <p>
                {`${t(
                  'We could not find anything with the following search term',
                )}: `}{' '}
                <strong> {topSearchValue}</strong>
              </p>
            </EmptyState>
          ) : retrievedData?.length === 0 ? (
            <EmptyState>
              <img src={Frame} alt="Missing data" />
              <h1>{t('There are no offers yet')}</h1>
              <p>{t('Looks like theres no offers yet')}</p>
              {/* <ActionButton onClick={createOfferHandler}>
                Create offer
              </ActionButton> */}
            </EmptyState>
          ) : (
            <>
              <OrderTable>
                <OverviewTable.Head otherProps={{}}>
                  <OverviewTable.HeaderCell width="12%">
                    {t('Ref no')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="18%">
                    {t('Client / Company name')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="15%">
                    {t('Created on')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="20%">
                    {t('Assigned to')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="10%">
                    {isCallantFeature ? t('Affinity') : t('Insurance company')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="15%">
                    {t('Insurance types')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="10%">
                    {t('Phase')}
                  </OverviewTable.HeaderCell>
                </OverviewTable.Head>
                <OrderTable.Body>
                  {retrievedData.map((offer, i) => {
                    return (
                      <StyledOrderTableRow
                        onClick={e => contractClickHandler(e, offer)}
                        key={i}
                      >
                        <OrderTable.BodyCell>
                          <IdLabel>{`${offer?.ref}/0${offer?.revision}`}</IdLabel>
                        </OrderTable.BodyCell>
                        <StyledBodyCell>
                          {offer?.prospect?.first_name}{' '}
                          {offer?.prospect?.last_name}
                          {offer?.prospect?.name}
                        </StyledBodyCell>
                        <OrderTable.BodyCell>
                          {formatDate(offer?.created_at)}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <QuickActionSubMenu
                            onClick={e => e.stopPropagation()}
                            name={
                              offer?.assigned_to
                                ? brokers?.length > 0
                                  ? brokers?.find(
                                      item =>
                                        item.broker.id === offer?.assigned_to,
                                    )?.broker?.name
                                  : t('loading')
                                : t('No one')
                            }
                          >
                            {brokers?.map(item => (
                              <QuickActionSubMenu.SubMenuItem
                                key={item.broker.id}
                                label={
                                  <SubLabel>{item?.broker?.name}</SubLabel>
                                }
                                onClick={() =>
                                  updateAssignedTo(
                                    item?.broker?.id,
                                    i,
                                    offer?.id,
                                  )
                                }
                                type=""
                              />
                            ))}
                            <QuickActionSubMenu.SubMenuItem
                              label={<SubLabel>{t('No one')}</SubLabel>}
                              onClick={() =>
                                updateAssignedTo(null, i, offer?.id)
                              }
                              type=""
                            />
                          </QuickActionSubMenu>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          {isCallantFeature
                            ? affinities.reduce(function(map, affinity) {
                                map[affinity.key] = affinity.label;
                                return map;
                              }, {})?.[offer?.insurance?.affinity]
                            : affiliations.reduce(function(map, affiliation) {
                                map[affiliation.key] = affiliation.label;
                                return map;
                              }, {})?.[offer?.insurance?.company]}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <RiskObjectsWrapper>
                            {!checkValuesInObjectIsZero(
                              offer?.amount_of_risk_objects,
                            ) ? (
                              Object.keys(offer?.amount_of_risk_objects).map(
                                key => {
                                  if (offer?.amount_of_risk_objects[key] === 0)
                                    return null;
                                  return (
                                    <RiskObjectLabel
                                      key={key}
                                      amount={
                                        offer?.amount_of_risk_objects[key]
                                      }
                                      icon={getRiskObjectIcon(key)}
                                    />
                                  );
                                },
                              )
                            ) : (
                              <RiskObjectLabel
                                key={'legal'}
                                amount="1"
                                icon={getRiskObjectIcon('legal')}
                              />
                            )}
                          </RiskObjectsWrapper>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <QuickActionSubMenuStatus
                            status={offer?.status.toUpperCase()}
                            onClick={e => e.stopPropagation()}
                            label={t(`offers.${offer?.status}`)}
                          >
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="PENDING">
                                  {t('offers.PENDING')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'PENDING',
                                  i,
                                  offer?.id,
                                  offer?.revision,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="SEND">
                                  {t('offers.SEND')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'SEND',
                                  i,
                                  offer?.id,
                                  offer?.revision,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="SIGNED">
                                  {t('offers.SIGNED')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'SIGNED',
                                  i,
                                  offer?.id,
                                  offer?.revision,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="ACCEPTED">
                                  {t('offers.ACCEPTED')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'ACCEPTED',
                                  i,
                                  offer?.id,
                                  offer?.revision,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="REJECTED">
                                  {t('offers.REJECTED')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'REJECTED',
                                  i,
                                  offer?.id,
                                  offer?.revision,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="CANCELLED">
                                  {t('offers.CANCELLED')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'CANCELLED',
                                  i,
                                  offer?.id,
                                  offer?.revision,
                                )
                              }
                              type=""
                            />
                          </QuickActionSubMenuStatus>
                        </OrderTable.BodyCell>
                      </StyledOrderTableRow>
                    );
                  })}
                </OrderTable.Body>
              </OrderTable>
            </>
          )}
          <ContentFooter>
            <span className="records">
              {t('pagination.showing')} <strong>{amountOfEntries}</strong>{' '}
              {t('pagination.entries')}
            </span>
            <Pagination
              base="/sales-management/contracts/offers/"
              currentPage={paginationData.page}
              pageLength={15}
              prevText={t('PrevPGN')}
              nextText={t('NextPGN')}
              onClick={() => setIsLoadingOffers(true)}
              totalPages={paginationData.pages}
            />
          </ContentFooter>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

const StyledSearchSelectInput = styled(SearchSelectInput)`
  > label {
    font-weight: bold;
  }

  div {
    font-weight: normal;
  }
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 6px;
`;

const StyledBodyCell = styled(OrderTable.BodyCell)`
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const LoadingContainerOffers = styled.div`
  width: 100%;
  flex: 0 0 100%;
  min-height: 70vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarSearch = styled(SearchInput)`
  background-color: #f7f7f7;
  padding-left: 3.5rem;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
  visibility: hidden;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 32px;
  flex: 0 0 252px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 182px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 110rem;
`;

const SidebarSpacer = styled.div`
  margin-top: 20px;
`;

const RiskObjectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex: 0 0 33.33%;
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  .records {
    font-size: 1.4rem;
    line-height: 130%;
    color: ${({ theme }) => theme.ui.disabled};

    strong {
      font-weight: 900;
    }
  }
`;

const CheckBoxSpacer = styled.div`
  width: 6px;
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;
    margin-bottom: 35px;
  }
`;

const IdLabel = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.typo.interactive};
  text-align: left;
`;

const StyledOrderTableRow = styled(OrderTable.Row)`
  &:hover {
    p {
      color: ${({ theme }) => theme.typo.title};
    }
  }
`;

CRMOffersOverview.propTypes = {
  match: shape({}).isRequired,
  type: string.isRequired,
};

export default CRMOffersOverview;
