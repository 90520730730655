import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';

const Section = ({ children, className }) => {
  return <StyledSection className={className}>{children}</StyledSection>;
};

const StyledSection = styled.section`
  width: 100%;
  margin: auto;
  max-width: 100rem;
  margin-top: 4rem;
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
`;

const Subtitle = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  color: #505050;
`;

const Label = styled.h3`
  font-size: 1.4rem;
  color: #a29c95;
  line-height: 1.5;
`;

const Content = styled.div`
  background-color: white;
  position: relative;
  font-size: 1.5rem;
  padding: 3.5rem;
  border: 0.1rem solid ${({ theme }) => theme.ui.background};
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  margin-top: 2rem;
`;

const Split = styled.div`
  width: 100%;
  display: flex;
`;

const SplitContent = styled.div`
  width: 50%;
`;

Section.propTypes = {
  children: node,
  className: string,
};

Section.Title = Title;
Section.Subtitle = Subtitle;
Section.Content = Content;
Section.Split = Split;
Section.SplitContent = SplitContent;
Section.Label = Label;

export default Section;
