import React, { useState, useEffect, createRef, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import {
  Alert,
  SubNavigation,
  BackButton,
  ActionButton,
  LoadingSpinner,
} from 'wg-fe-ui';
import { isEqual, cloneDeep } from 'lodash';
import cogoToast from 'cogo-toast';
import Frame from '../../../assets/images/Frame.svg';
import PropertyInformation from '../../components/CRMRiskObjectDetailResidenceInformation';
import PropertyRooms from '../../components/CRMRiskObjectDetailResidenceRooms';
import PropertyAnnexes from '../../components/CRMRiskObjectDetailResidenceAnnexes';

import { useTranslation } from 'react-i18next';
import { getBrokerData } from '../../services/brokerDataService';
import {
  getRiskObjectByTypeAndId,
  createRiskObjectRevision,
} from '../../services/apiRouterService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';
import { getMeData } from '../../services/meDataService';

const CRMRiskObjectDetailResidence = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const refs = {};
  const configCatClient = useContext(FlagsProvider);
  const [homeData, setHomeData] = useState(
    location?.state?.updatedHomeData ?? {},
  );
  const [updateRequired, setUpdateRequired] = useState(false);
  const [updatedHomeData, setUpdatedHomeData] = useState(
    location?.state?.updatedHomeData ?? {},
  );
  const [isLoading, setIsLoading] = useState(true);
  const [canEditOffers, setCanEditOffers] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });
  const [isCallantFeature, setIsCallantFeature] = useState();
  const [isProFlowEnabled, setIsProfFlowEnabled] = useState();

  const {
    id: riskObjectId,
    revision: riskObjectRevision,
    offerId,
    offerRevision,
    contractCaseId,
    contractId,
    prospectId,
    riskObjectType,
    leadId,
    editing,
  } = useParams();

  useEffect(() => {
    setFeatureToggles();
    if (location?.state?.updatedHomeData) return setIsLoading(false);
    retrieveHomeData();
  }, []);

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const emailConfig = {
      email: me?.email,
      custom: {
        plan: broker?.plan,
      },
    };

    if (offerId || contractId || prospectId) {
      setCanEditOffers(
        leadId
          ? false
          : await configCatClient.getValueAsync(
              offerId || contractId
                ? 'editOffers'
                : 'canEditProspectsAndRiskObjects',
              false,
              {
                email: me?.email,
                custom: {
                  plan: broker?.plan,
                },
              },
            ),
      );
    }

    setIsCallantFeature(
      await configCatClient.getValueAsync('callantFeature', true, emailConfig),
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,

        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  async function retrieveHomeData() {
    const { distribution_id } = await getBrokerData();
    const [response, status] = await getRiskObjectByTypeAndId(
      distribution_id,
      'residences',
      riskObjectId,
      riskObjectRevision,
    );

    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      console.log('FAILED TO LOAD');
      setIsLoading(false);
      setHomeData(null);
      return;
    }

    setHomeData(response);
    setIsLoading(!isLoading);
    setUpdatedHomeData(response);
  }

  const [sections] = useState([
    {
      id: 1,
      label: `${t('Risk object')} | ${t('Property')} - ${riskObjectId}`,
      subsections: [
        {
          id: 1,
          label: t('Property information'),
        },
        {
          id: 2,
          label: t('Room layout'),
        },
        {
          id: 3,
          label: t('Annexes'),
        },
      ],
    },
  ]);

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  });

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const homeInformationSave = values => {
    const payload = { ...values };
    payload.risk_object_id = updatedHomeData.risk_object_id;
    payload.risk_object_revision_id = updatedHomeData.risk_object_revision_id;
    setUpdatedHomeData(payload);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (!isEqual(homeData, payload)) {
      showUpdateMessage();
    }
  };

  const homeAnnexesInformationSave = values => {
    const payload = { ...values };

    setUpdatedHomeData(payload);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (!isEqual(homeData, payload)) {
      showUpdateMessage();
    }
  };

  const showUpdateMessage = () => {
    setUpdateRequired(true);
  };

  const handleSaveHomeOfferChanges = () => {
    const pathname =
      prospectId && offerId
        ? `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`
        : `/sales-management/offer/${offerId}/${offerRevision}`;
    history.push({
      pathname,
      state: { ...location.state, updatedHomeData },
    });
  };

  const handleSaveHomeContractChanges = () => {
    const pathname = prospectId
      ? `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`
      : `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    history.push({
      pathname,
      state: { ...location.state, updatedHomeData },
    });
  };

  const handleSaveHomeChanges = async () => {
    const { distribution_id } = await getBrokerData();
    const tempUpdatedHomeData = cloneDeep(updatedHomeData);
    delete tempUpdatedHomeData.id;
    delete tempUpdatedHomeData.risk_object_id;
    delete tempUpdatedHomeData.risk_object_revision_id;
    let [response, status] = await createRiskObjectRevision(
      distribution_id,
      leadId || prospectId,
      'residences',
      riskObjectId,
      tempUpdatedHomeData,
    );

    if (!(status < 204 && status >= 200)) {
      cogoToast.error(t('Something went wrong'));
      return console.error(response);
    }

    cogoToast.success(t('Risk object revision successfully saved'));

    setUpdateRequired(false);
    history.push({
      pathname:
        prospectId && leadId
          ? `/sales-management/customer/${prospectId}/contracts/lead/${leadId}/risk-object/${riskObjectType}/${riskObjectId}/${response.risk_object_revision_id}`
          : `/sales-management/customer/${prospectId}/risk-object/${riskObjectType}/${riskObjectId}/${response.risk_object_revision_id}`,
      state: { ...location.state },
    });
  };

  const startNewTarrification = () => {
    history.push({
      pathname: `/professional-flow/new-tarrification-prospect/`,
      search: `?prospectId=${prospectId}`,
      state: { type: 'residence', riskObjectId },
    });
  };

  const backButtonHandler = () => {
    let pathname = '';
    if (prospectId && offerId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`;
    } else if (prospectId && contractId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`;
    } else if (prospectId && leadId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/lead/${leadId}`;
    } else if (prospectId) {
      pathname = `/sales-management/customer/${prospectId}`;
    } else if (contractId) {
      pathname = `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    } else if (leadId) {
      pathname = `/sales-management/lead/${leadId}`;
    } else {
      pathname = `/sales-management/offer/${offerId}/${offerRevision}`;
    }
    history.push({
      pathname,
      state: { ...location.state },
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (homeData == null || isEmpty(homeData)) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Risk object not found')}</h1>
        <p>
          {`${t('We could not find the risk object with id')}: `}{' '}
          <strong> {riskObjectId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton onClick={backButtonHandler} name={t('Previous page')} />
          </TopBarLeft>
          {!isCallantFeature && (
            <TopBarRight>
              <TopBarAction>
                <ActionButton
                  onClick={startNewTarrification}
                  disabled={!isProFlowEnabled}
                >
                  {t('New tarification')}
                </ActionButton>
              </TopBarAction>
            </TopBarRight>
          )}
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
        </Sidebar>

        <Content>
          {updateRequired && offerId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object, would you like to save these changes and go back to the offer',
                )}
                <ActionButton onClick={() => handleSaveHomeOfferChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          {updateRequired && contractId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object, would you like to save these changes and go back to the contract',
                )}
                <ActionButton onClick={() => handleSaveHomeContractChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          {updateRequired &&
          (prospectId || leadId) &&
          !contractId &&
          !offerId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object would you like to save these changes',
                )}
                <ActionButton onClick={() => handleSaveHomeChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          <section ref={refs[1][1]}>
            <PropertyInformation
              homeData={homeData}
              initEdit={editing ?? false}
              onSubmitFunction={homeInformationValues =>
                homeInformationSave(homeInformationValues)
              }
              disableEditing={!canEditOffers}
            />
          </section>

          <section ref={refs[1][2]}>
            <PropertyRooms
              initEdit={editing ?? false}
              homeData={homeData}
              disableEditing={!canEditOffers}
              onSubmitFunction={homeInformationValues =>
                homeInformationSave(homeInformationValues)
              }
            />
          </section>

          <section ref={refs[1][3]}>
            <PropertyAnnexes
              initEdit={editing ?? false}
              homeData={homeData}
              disableEditing={!canEditOffers}
              onSubmitFunction={homeInformationValues =>
                homeAnnexesInformationSave(homeInformationValues)
              }
            />
          </section>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;
const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const StyledAlertContent = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    margin-right: 0.78vw;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

export default CRMRiskObjectDetailResidence;
