import React from 'react';

const PrivateGarage = () => (
  <svg viewBox="0 0 185.42 179.96">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'private_garagematrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M185.42 0v179.96h-28.63V39.27H28.63v140.69H0V0h185.42z"
        />
        <rect
          className="prefix__cls-1"
          x={43.63}
          y={56.99}
          width={98.16}
          height={14.18}
          rx={7.09}
        />
        <rect
          className="prefix__cls-1"
          x={43.63}
          y={86.99}
          width={98.16}
          height={14.18}
          rx={7.09}
        />
        <path
          className="prefix__cls-1"
          d="M139.58 141.13c-12.09-13-62.86-12-62.86-12l-24-15.59c-3.27-.33-5.43-.54-8.58-.64v51.59h39.6a19.46 19.46 0 0038.1 0h9.3a6.65 6.65 0 006.38-4.82l3.45-12.2a6.63 6.63 0 00-1.39-6.34zm-24.66 23.36a12.78 12.78 0 11.64-4 12.46 12.46 0 01-.64 4z"
        />
      </g>
    </g>
  </svg>
);

export default PrivateGarage;
