import React from 'react';
import { string, shape } from 'prop-types';

const SvgComponent = ({ className, colors }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors.brand.primary}
      fillRule="evenodd"
    />
    <g fill="#fff">
      <path d="m285.14 267.44a9 9 0 0 0 -.63-3q-.7-1.47-7.83-5t-17.68-8.14l-10.59-4.71q-1-.5-3.84-2.19a29.33 29.33 0 0 0 -5-2.47 9.72 9.72 0 0 0 -4.1-.63q-3.44.28-8.47 6.95t-9.87 13.42q-4.85 6.75-7.9 7a9.39 9.39 0 0 1 -3.21-.41 29.9 29.9 0 0 1 -3.06-1c-.8-.32-1.93-.84-3.4-1.55s-2.41-1.18-2.81-1.41a138.93 138.93 0 0 1 -61.45-52.12c-.29-.36-.9-1.21-1.84-2.54s-1.64-2.37-2.09-3.1a30.52 30.52 0 0 1 -1.48-2.87 9.13 9.13 0 0 1 -.93-3.1q-.21-2.48 3.39-6.72a75.15 75.15 0 0 1 8-8.07 64.83 64.83 0 0 0 8-8.26c2.38-3 3.5-5.39 3.34-7.3a9.69 9.69 0 0 0 -1.28-3.93 30.17 30.17 0 0 0 -3.26-4.55q-2.13-2.52-2.79-3.43c-.44-.73-1.55-2.47-3.31-5.21s-3.6-5.51-5.5-8.31-3.83-5.68-5.81-8.66a78.53 78.53 0 0 0 -5.41-7.36c-1.64-1.91-2.78-3-3.44-3.18a9.13 9.13 0 0 0 -3.09-.13 50.52 50.52 0 0 0 -19 5.79q-8.46 4.75-13.8 19.33t-4.45 25.48a47.45 47.45 0 0 0 1 6.46c.51 2.27 1 4.19 1.43 5.75a63.25 63.25 0 0 0 2.24 6.17q1.57 3.83 2.38 5.49c.54 1.11 1.51 3.14 2.91 6.11s2.25 4.82 2.57 5.56q14 30.42 46.42 57.88t64.68 36.37q1.17.28 5.91 1.63t6.5 1.87c1.19.35 3.12.84 5.8 1.45a64.47 64.47 0 0 0 6.45 1.2q2.43.28 5.91.48a47.14 47.14 0 0 0 6.54-.06q10.91-.89 24.42-8.54t16.81-16.78a50.61 50.61 0 0 0 2.62-19.66z" />
      <path d="m293.33 96.79h-102.33a8.39 8.39 0 0 0 -8.39 8.39v73.9a8.39 8.39 0 0 0 8.39 8.39h15.79l-.07 41.38a2 2 0 0 0 3.67 1.11l28.28-42.49h54.64a8.39 8.39 0 0 0 8.4-8.39v-73.9a8.4 8.4 0 0 0 -8.38-8.39zm-56.8 17.36h11.34v20a55.92 55.92 0 0 1 -.29 5.83c-.19 1.86-.45 3.86-.77 5.94l-.26 1.7h-8.69l-.26-1.7c-.32-2.08-.58-4.08-.77-5.94a55.9 55.9 0 0 1 -.3-5.83zm11.81 49.94a6.72 6.72 0 0 1 -6.21 4 7 7 0 0 1 -2.65-.51 6.69 6.69 0 0 1 -2.17-1.42 6.55 6.55 0 0 1 -1.46-2.15 7.06 7.06 0 0 1 0-5.27 6.43 6.43 0 0 1 1.46-2.18 6.82 6.82 0 0 1 9.6 0 6.75 6.75 0 0 1 1.43 2.14 6.6 6.6 0 0 1 .54 2.64 6.68 6.68 0 0 1 -.54 2.75z" />
    </g>
  </svg>
);

SvgComponent.propTypes = {
  className: string,
  colors: shape(),
};

export default SvgComponent;
