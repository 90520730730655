import React from 'react';
import { string } from 'prop-types';

const IsMarried = ({ color }) => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1808 9.01318V7.04734H15.1042C14.4969 7.04734 14.0071 6.5837 14.0071 6.00879C14.0071 5.43387 14.4969 4.97023 15.1042 4.97023H17.1808V4.71986L15.6527 3.80185L15.6429 0.945827C15.6429 0.649097 15.3882 0.408004 15.0748 0.408004H12.8317C12.5183 0.408004 12.2636 0.649097 12.2636 0.945827V1.75256L9.46216 0.0834553C9.27605 -0.0278184 9.04096 -0.0278184 8.85485 0.0834553L0.26447 5.23914C0.0979519 5.34114 0 5.50805 0 5.69351L0.0293856 18.2211C0.0293856 18.6569 0.401603 19 0.852182 19H3.24221L3.23241 13.223L7.51291 13.2138L7.52271 18.9907L17.4648 18.9722C17.9252 18.9722 18.2876 18.6198 18.2876 18.1933L18.268 10.0517C17.6705 10.0517 17.1808 9.57882 17.1808 9.01318Z"
        fill={color}
      />
      <path
        d="M21.4417 5.47101L18.8166 5.48028L18.8068 2.99517C18.8068 2.70771 18.562 2.47589 18.2583 2.47589C17.9547 2.47589 17.7098 2.70771 17.7098 2.99517V5.04446V5.48028L15.0847 5.48956C14.781 5.48956 14.5361 5.72138 14.5361 6.00883C14.5361 6.29629 14.781 6.52811 15.0847 6.52811L17.7098 6.51884L17.7196 9.00395C17.7196 9.29141 17.9645 9.52323 18.2681 9.52323C18.5718 9.52323 18.8166 9.29141 18.8166 9.00395L18.8068 6.51884L21.4319 6.50956C21.7356 6.50956 21.9805 6.27774 21.9805 5.99029C22.0001 5.70283 21.7454 5.47101 21.4417 5.47101Z"
        fill={color}
      />
      <path
        d="M21.4515 6.50956L18.8264 6.51884L18.8362 9.00395C18.8362 9.29141 18.5913 9.52323 18.2876 9.52323C17.984 9.52323 17.7391 9.29141 17.7391 9.00395L17.7293 6.51884L15.1042 6.52811C14.8005 6.52811 14.5557 6.29629 14.5557 6.00883C14.5557 5.72138 14.8005 5.48956 15.1042 5.48956L17.7293 5.48028V5.04446V2.99517C17.7293 2.70771 17.9742 2.47589 18.2778 2.47589C18.5815 2.47589 18.8264 2.70771 18.8264 2.99517L18.8362 5.48028L21.4613 5.47101C21.7649 5.47101 22.0098 5.70283 22.0098 5.99029C22 6.27774 21.7551 6.50956 21.4515 6.50956Z"
        fill={color}
      />
    </svg>
  );
};

IsMarried.propTypes = {
  color: string,
};

export default IsMarried;
