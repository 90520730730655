import React, { useState, useContext, useEffect } from 'react';
import { shape, string, array, func } from 'prop-types';
import InputSelector from './ProFlowInputSelector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from 'wg-fe-ui';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';

const ProFlowQuestionsSpecs = ({
  taggedQuestions,
  allQuestions,
  tag,
  questionErrors,
  focus,
  setFocus,
}) => {
  const [questionList, setQuestionList] = useState(taggedQuestions);
  const { answers } = useContext(ProFlowStorageContext);
  const { i18n } = useTranslation();

  const fullWidthSpans = ['6855cde6-9861-4c7e-b0b1-98dbc063a975'];
  const thirdWidthSpans = [];
  const halfSpanLonely = [];

  function getSpanLenght(id) {
    if (fullWidthSpans.includes(id) || halfSpanLonely.includes(id)) return 6;
    if (thirdWidthSpans.includes(id)) return 2;
    return 3;
  }

  function getElementwidth(id) {
    if (halfSpanLonely.includes(id)) return 'calc(50% - 10px);';
  }

  useEffect(() => {
    filterRequiredAnswers();
  }, [answers]);

  function filterRequiredAnswers() {
    let toBeShowedQuestions = [];
    toBeShowedQuestions = taggedQuestions.filter(question => {
      const { required } = question;
      return showCriteria(required, answers);
    });
    setQuestionList(toBeShowedQuestions);
  }

  function showCriteria(required = [], _answers = []) {
    // show input if nothing is required
    if (required.length === 0) return true;
    const nulledRequires = required.filter(({ answer }) => answer === null);
    const generalRequires = required.filter(({ answer }) => answer !== null);

    const shouldShowQuestion =
      areAllNulledQuestionsAnswered(nulledRequires, answers) ||
      isOneOfQeneralQuestionsAnswered(generalRequires, answers);

    return shouldShowQuestion;
  }

  function areAllNulledQuestionsAnswered(required = [], _answers = []) {
    // if there are no nulled questions return true
    if (required.length === 0) return false;

    // return true if all nulled questions are answered
    return required
      .map(({ id }) => _answers.some(({ id: _id }) => id === _id))
      .includes(false);
  }

  function isOneOfQeneralQuestionsAnswered(required = [], _answers = []) {
    // if there are no questions return true
    if (required.length === 0) return false;
    // return true if id and answer matches
    return required.some(require =>
      _answers.some(
        ({ id, answer }) => require.id === id && answer === require.answer,
      ),
    );
  }

  return (
    questionList?.length > 0 && (
      <>
        <Title>{i18n.t(`Information from the ${tag}`)}</Title>
        <SubTitle>{i18n.t('Fill in extra information if requested')}</SubTitle>
        <StyledSection background>
          {questionList.map(
            ({
              id,
              rules,
              title,
              error_messages,
              description,
              insurance_type_tags,
              field,
              tag,
              value,
            }) => {
              return (
                <StyledDiv
                  key={id}
                  span={getSpanLenght(id)}
                  width={getElementwidth(id)}
                >
                  <InputSelector
                    description={description && description[i18n.language]}
                    typeTags={insurance_type_tags}
                    key={id}
                    rules={rules}
                    questionErrors={questionErrors}
                    name={id}
                    field={field}
                    errorMessages={error_messages}
                    title={title?.[i18n.language]}
                    tag={tag}
                    value={value}
                    focus={focus}
                    setFocus={setFocus}
                  />
                </StyledDiv>
              );
            },
          )}
        </StyledSection>
      </>
    )
  );
};

const StyledDiv = styled.div`
  grid-column: span ${({ span }) => span};
  width: ${({ width }) => width};
  h3 {
    color: #8990a3;
    font-weight: 600;
    letter-spacing: 0.01em;
    font-size: 14px;
  }
`;

const StyledSection = styled(Section.Content)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 36px;
`;

const Title = styled.h2`
  font-size: 2.1rem;
  color: #0e0e0e;
  font-weight: 700;
  margin: 1rem 0;
  margin-top: 3rem;
`;

const SubTitle = styled.h3`
  font-size: 1.6rem;
  color: #8990a3;
  font-weight: 500;
`;

ProFlowQuestionsSpecs.propTypes = {
  taggedQuestions: shape().isRequired,
  allQuestions: shape().isRequired,
  questionErrors: array,
  tag: string,
  focus: string,
  setFocus: func,
};
export default ProFlowQuestionsSpecs;
