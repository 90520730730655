import React, { useEffect, useState } from 'react';
import { array, shape, string as propString } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { convertLeadToOffer } from '../services/apiRouterService';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  Modal,
  Body,
  CheckBox_v2 as CheckBox,
  SearchSelectInput,
  ActionButton,
  // LoadingSpinner,
} from 'wg-fe-ui';
import LoadingButton from './LoadingButton';
import DateInput from './DateInput_v3';
import cogoToast from 'cogo-toast';

const CRMLeadSecondModal = ({
  modalState,
  retrievedData,
  calculatedData,
  distributionId,
  prevPayload,
  affinityName,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = modalState;
  const intervalOptions = [
    { value: 'ANNUAL', label: t('ANNUAL') },
    { value: 'HALF_ANNUAL', label: t('HALF_ANNUAL') },
    { value: 'QUARTERLY', label: t('QUARTERLY') },
  ];
  const history = useHistory();

  const [guaranteesNetPremium, setGuaranteesNetPremium] = useState();
  const [guaranteesCosts, setGuaranteesCosts] = useState();
  const [guaranteesTotals, setGuaranteesTotals] = useState();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({
    start_date: undefined,
    interval: undefined,
  });
  const [interval, setInterval] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newData = {
      quote_id: calculatedData?.id,
      guarantees: calculatedData?.guarantees,
      start_date: new Date(),
      payment_spread: null,
      send_mail_to_prospect: false,
      acknowledgements: retrievedData?.acknowledgements,
    };

    setData(newData);
  }, [calculatedData]);

  useEffect(() => {
    let totalNetPremium = 0;
    let totalCosts = 0;
    let totalPremium = 0;

    data?.guarantees
      ? data?.guarantees.map(item => {
          if (item?.taken || item?.mandatory) {
            totalNetPremium += item.net_premium;
            totalCosts += item.total_premium - item.net_premium;
            totalPremium += item.total_premium;
          }
        })
      : null;

    setGuaranteesNetPremium(totalNetPremium);
    setGuaranteesCosts(totalCosts);
    setGuaranteesTotals(totalPremium);
  }, [data]);

  useEffect(() => {
    if (errors.start_date === null && errors.interval === null) {
      let payload = {
        quote_id: data?.quote_id,
        guarantees: [],
        start_date: data?.start_date,
        commencement_date: data?.start_date,
        payment_spread: data?.payment_spread,
        acknowledgements: [],
        send_mail_to_prospect: data?.send_mail_to_prospect,
      };

      data?.guarantees.forEach(guarantee => {
        payload.guarantees.push(guarantee?.name);
      });

      data?.acknowledgements.forEach(ack => {
        payload.acknowledgements.push({ text: ack?.text, agreed: ack?.agreed });
      });

      convertLead(payload);
    } else {
      console.error('handleCalculateOffer -> errors', errors);
    }
  }, [errors]);

  const findIntervalInEnums = inter => {
    const result = intervalOptions.filter(obj => {
      return obj.value === inter;
    });

    return result;
  };

  async function convertLead(_payload) {
    setIsLoading(true);

    const closeLoadingToast = cogoToast.loading(t('Creating a new offer'), {
      hideAfter: 0,
    });

    const [response, status] = await convertLeadToOffer(
      distributionId,
      retrievedData?.id,
      _payload,
    );

    if (status === 201) {
      closeLoadingToast();
      cogoToast.success(t('The offer was successfully created'));

      history.push(
        `/sales-management/offer/${response?.offer_id}/${response?.revision}`,
      );
    } else {
      setIsLoading(false);
      cogoToast.error(t('Something went wrong while creating the offer'));
      console.error('convertLead -> response', response);
    }
  }

  const handleGuaranteeChange = (itemId, event) => {
    const elementPos = data?.guarantees
      .map(x => {
        return x.id;
      })
      .indexOf(itemId);

    let newData = Object.assign({}, data);
    newData.guarantees[elementPos].taken = event.value;
    setData(newData);
  };

  const handleStartDateChange = event => {
    const inputDate = moment(event.value, 'DD/MM/YYYY').format();

    let newData = Object.assign({}, data);
    newData.start_date = inputDate;
    setData(newData);
  };

  const handleIntervalChange = e => {
    const pickedInterval = findIntervalInEnums(e.value);
    setInterval(pickedInterval);

    let newData = Object.assign({}, data);
    newData.payment_spread = pickedInterval[0].value;
    setData(newData);
  };

  const handleAcknowledgementChange = (itemId, event) => {
    const elementPos = data?.acknowledgements
      .map(x => {
        return x.id;
      })
      .indexOf(itemId);

    let newData = Object.assign({}, data);
    newData.acknowledgements[elementPos].agreed = event.value;
    setData(newData);
  };

  const handleClientMailChange = event => {
    let newData = Object.assign({}, data);
    newData.send_mail_to_prospect = event.value;
    setData(newData);
  };

  const handleConvertToOffer = () => {
    const isLater = moment(0, 'HH').diff(data?.start_date, 'days') <= 0;

    /* Validation checks before running the query */
    interval === null
      ? setErrors(prevState => ({
          ...prevState,
          interval: t('Please select a payment interval'),
        }))
      : setErrors(prevState => ({
          ...prevState,
          interval: null,
        }));

    if (isLater) {
      setErrors(prevState => ({
        ...prevState,
        start_date: null,
      }));
    } else {
      setErrors(prevState => ({
        ...prevState,
        start_date: t('The start date can not be earlier than today'),
      }));
    }
  };

  return (
    <Modal
      canClose
      showModal={showModal}
      setShowModal={setShowModal}
      title={`${t('Offer calculated for')} ${affinityName}`}
      large
    >
      <StyledTable>
        <thead>
          <tr>
            <th></th>
            <th>
              <Body bold light>
                {t('Guarantee')}
              </Body>
            </th>
            <th>
              <Body bold light>
                {t('Net premium')}
              </Body>
            </th>
            <th>
              <Body bold light>
                {t('Taxes and fees')}
              </Body>
            </th>
            <th>
              <Body bold light>
                {t('Total premium')}
              </Body>
            </th>
          </tr>
        </thead>

        <tbody>
          {calculatedData?.guarantees.map(guarantee => (
            <tr key={guarantee?.id}>
              <td>
                <CheckBox
                  checked={guarantee?.mandatory ? true : guarantee?.taken}
                  disabled={guarantee?.mandatory}
                  onChange={e => {
                    handleGuaranteeChange(guarantee?.id, e);
                  }}
                ></CheckBox>
              </td>

              <td>
                <TableGuaranteeWrapper>
                  <Body>
                    {guarantee.name === 'family'
                      ? t('family_guarantee')
                      : t(guarantee?.name)}
                  </Body>
                </TableGuaranteeWrapper>
              </td>

              <td>
                {guarantee?.net_premium ? (
                  <Body>
                    {(
                      Math.round((guarantee?.net_premium + 0.00001) * 100) / 100
                    ).toLocaleString('pt-BR')}{' '}
                    EUR
                  </Body>
                ) : (
                  <Body>{t(`Included`)}</Body>
                )}
              </td>

              <td>
                {guarantee?.taxes_in_euro ? (
                  <Body>
                    {(
                      Math.round(
                        (guarantee?.total_premium -
                          guarantee?.net_premium +
                          0.00001) *
                          100,
                      ) / 100
                    ).toLocaleString('pt-BR')}{' '}
                    EUR
                  </Body>
                ) : (
                  <Body>{t('Included')}</Body>
                )}
              </td>

              <td>
                {guarantee?.total_premium ? (
                  <Body>
                    {(
                      Math.round((guarantee?.total_premium + 0.00001) * 100) /
                      100
                    ).toLocaleString('pt-BR')}{' '}
                    EUR
                  </Body>
                ) : (
                  <Body>{t(`Included`)}</Body>
                )}
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <td></td>
            <td></td>

            <td>
              <Body bold>
                {(
                  Math.round((guaranteesNetPremium + 0.00001) * 100) / 100
                ).toLocaleString('pt-BR')}{' '}
                EUR
              </Body>
            </td>

            <td>
              <Body bold>
                {(
                  Math.round((guaranteesCosts + 0.00001) * 100) / 100
                ).toLocaleString('pt-BR')}{' '}
                EUR
              </Body>
            </td>

            <td>
              <Body bold>
                {(
                  Math.round((guaranteesTotals + 0.00001) * 100) / 100
                ).toLocaleString('pt-BR')}{' '}
                EUR
              </Body>
            </td>
          </tr>
        </tfoot>
      </StyledTable>

      <Divider />

      <Row>
        <DateInput
          value={moment(data?.start_date).format('DD/MM/YYYY')}
          name="start_date"
          onChange={e => {
            handleStartDateChange(e);
          }}
          error={errors.start_date}
        >
          {t('Commencement date')}
        </DateInput>

        <SearchSelectInput
          initial={interval}
          name="payment_spread"
          onSelected={handleIntervalChange}
          options={intervalOptions}
          placeholder={t('Choose an option')}
          error={errors?.interval}
        >
          {t('Payment interval')}
        </SearchSelectInput>
      </Row>

      <Divider />

      <AcknowledgementContainer>
        {retrievedData?.acknowledgements?.map(ack => (
          <CheckBox
            checked={ack?.agreed}
            onChange={e => {
              handleAcknowledgementChange(ack?.id, e);
            }}
          >
            {ack?.text}
          </CheckBox>
        ))}
      </AcknowledgementContainer>

      <Modal.ModalActions position="right">
        <ClientCheckBox
          checked={false}
          onChange={e => {
            handleClientMailChange(e);
          }}
        >
          {t('Send an email to the customer')}
        </ClientCheckBox>

        {isLoading ? (
          <StyledLoadingButton></StyledLoadingButton>
        ) : (
          <ActionButton level="primary" onClick={handleConvertToOffer}>
            {t('Convert to offer')}
          </ActionButton>
        )}
      </Modal.ModalActions>
    </Modal>
  );
};

// const LoadingContainer = styled.div`
//   width: 100vw;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  td,
  th {
    padding: 0.5rem 3rem 0.5rem 0;
    vertical-align: middle;
  }

  tr {
    vertical-align: middle;
  }

  thead {
    border-bottom: 1px solid #e4e4e4;

    th {
      padding-bottom: 1rem;
    }
  }

  tfoot {
    border-top: 1px solid #e4e4e4;

    td {
      padding-top: 1rem;
    }
  }

  td:first-child {
    padding-right: 0;
  }

  tbody {
    tr:first-child td {
      padding-top: 1.5rem;
    }

    tr:last-child td {
      padding-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const TableGuaranteeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Divider = styled.div`
  width: 100%;
  background-color: #e4e4e4;
  margin: 2.5rem 0;
  height: 1px;
  display: ${({ editMode }) => (editMode ? 'none' : 'block')};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 15px;
  grid-template-areas: '. .';
`;

const AcknowledgementContainer = styled.div`
  display: flex;
  flex-flow: column;

  > label {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
`;

const ClientCheckBox = styled(CheckBox)`
  margin-right: 1.5rem;
`;

const StyledLoadingButton = styled(LoadingButton)`
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  height: 4rem;
  transition: 0.1s ease-in-out;
  display: flex;
  margin: 0;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
`;

CRMLeadSecondModal.propTypes = {
  modalState: array,
  retrievedData: shape(),
  calculatedData: shape(),
  distributionId: propString,
  prevPayload: shape(),
  affinityName: propString,
};

export default CRMLeadSecondModal;
