import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const Support = ({ title, color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.77 224.46">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M246.58,25.67a9.18,9.18,0,0,0-9.19,9.19l-5.64,61.73a12,12,0,0,1,.86,4.27,14,14,0,0,1-1.86,6.66l0,.18-.12,0a13.81,13.81,0,0,1-2.22,3.31l-26.85,26.85-3.69-3.68,24.73-24.73,2.12-2.12a8.75,8.75,0,0,0,2.37-4.85c0-.2.09-.4.12-.6a9.46,9.46,0,0,0-1-5.33,9,9,0,0,0-1.48-2.22,9.19,9.19,0,0,0-13,0l-22.39,22.39L188,117a47,47,0,0,0-22.33,12l-12.39,12a17.87,17.87,0,0,0-5.46,12.86v29.17h-.71a5.35,5.35,0,0,0-5.35,5.35v30.7a5.36,5.36,0,0,0,5.35,5.35h60a5.35,5.35,0,0,0,5.35-5.35v-30.7a5.34,5.34,0,0,0-5.35-5.35h-.41l0-14.61,44.55-44.55a15.17,15.17,0,0,0,4.46-10.75V34.86A9.19,9.19,0,0,0,246.58,25.67Z"
        />
        <path
          fill={color}
          d="M108.47,183.06h-.56V153.89A17.87,17.87,0,0,0,102.45,141l-12.4-12a46.89,46.89,0,0,0-22.33-12l-1.27-.28L44.06,94.36a8.78,8.78,0,0,0-14.47,2.22,9.37,9.37,0,0,0-1,5.33c0,.2.08.4.11.6a8.82,8.82,0,0,0,2.37,4.85l2.13,2.12,24.72,24.73-3.68,3.68L27.38,111a13.81,13.81,0,0,1-2.22-3.31l-.13,0v-.18a14,14,0,0,1-1.86-6.66A12,12,0,0,1,24,96.59L18.38,34.86A9.19,9.19,0,0,0,0,34.86v78.29A15.21,15.21,0,0,0,4.45,123.9L49,168.45l.05,14.61H48.5a5.34,5.34,0,0,0-5.35,5.35v30.7a5.35,5.35,0,0,0,5.35,5.35h60a5.35,5.35,0,0,0,5.35-5.35v-30.7A5.34,5.34,0,0,0,108.47,183.06Z"
        />
        <path
          fill={color}
          d="M177.38,71.53l-4.91-2.83A46.08,46.08,0,0,0,174,57.43a45.92,45.92,0,0,0-1.55-11.25l4.91-2.84a7.52,7.52,0,0,0,2.76-10.27l-5-8.7a7.52,7.52,0,0,0-10.27-2.76L160,24.41a45.83,45.83,0,0,0-19.58-11.16V7.52A7.52,7.52,0,0,0,132.91,0h-10a7.52,7.52,0,0,0-7.52,7.52v5.73A45.83,45.83,0,0,0,95.76,24.41l-4.84-2.8a7.51,7.51,0,0,0-10.26,2.76l-5,8.7a7.52,7.52,0,0,0,2.75,10.27l4.92,2.84a45.44,45.44,0,0,0-1.55,11.25A45.6,45.6,0,0,0,83.3,68.7l-4.92,2.83A7.52,7.52,0,0,0,75.63,81.8l5,8.7a7.51,7.51,0,0,0,10.26,2.76l4.84-2.79a45.91,45.91,0,0,0,19.58,11.15v5.73a7.52,7.52,0,0,0,7.52,7.52h10a7.52,7.52,0,0,0,7.52-7.52v-5.73A45.91,45.91,0,0,0,160,90.47l4.83,2.79a7.52,7.52,0,0,0,10.27-2.76l5-8.7A7.52,7.52,0,0,0,177.38,71.53Zm-49.5,3.55a17.65,17.65,0,1,1,17.65-17.65A17.65,17.65,0,0,1,127.88,75.08Z"
        />
      </g>
    </g>
  </Svg>
);

const Svg = styled.svg`
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
`;

Support.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Support.propTypes = {
  title: string,
  color: string,
};

export default Support;
