import React, { useState, useEffect } from 'react';
import { object, func } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  ActionButton,
  Section,
  Label,
  Text,
  SearchSelectInput,
  EditActionButton,
} from 'wg-fe-ui';

import useForm from '../hooks/useForm';
import * as Yup from 'yup';

import cogoToast from 'cogo-toast';

const CRMRiskObjectDetailTeacher = ({
  teacherData,
  onSubmitFunction,
  customerPageFunction,
}) => {
  /* INITS
  -------------------------------------------------- */
  const { t } = useTranslation();

  const [data, setData] = useState(teacherData);
  const [editMode, setEditMode] = useState(false);
  const [heavyMachinery, setHeavyMachinery] = useState();

  useEffect(() => {
    setData(teacherData);
  }, [teacherData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  const heavyMachineryOptions = [
    { value: true, label: t('Yes') },
    { value: false, label: t('No') },
  ];

  /* HELPER FUNCTIONS
  -------------------------------------------------- */
  const findHeavyMachineryInEnums = status => {
    const result = heavyMachineryOptions.filter(obj => {
      return obj.value === status;
    });

    return result;
  };

  /* FORM HANDLING
  -------------------------------------------------- */
  const ValidationSchema = Yup.object().shape({
    manipulates_heavy_machinery: Yup.string().nullable(),
  });

  const setInitialFormValues = () => {
    if (data) {
      setHeavyMachinery(
        findHeavyMachineryInEnums(teacherData.manipulates_heavy_machinery),
      );
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: ValidationSchema,
    mode: 'onChange',
  });

  const handleFormValues = async () => {
    cogoToast.error(
      t('The editing and saving of a risk object is not supported yet'),
    );
  };

  const editClickHandler = e => {
    setEditMode(!editMode);
    setInitialFormValues();

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const handleHeavyMachineryChange = e => {
    const selectedHeavyMachinery = findHeavyMachineryInEnums(e.value);
    setHeavyMachinery(selectedHeavyMachinery);
    handleChange({
      name: 'manipulates_heavy_machinery',
      value: e.value,
    });
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
    } else {
      const newValues = {
        manipulates_heavy_machinery: values?.manipulates_heavy_machinery,
      };

      onSubmitFunction(newValues);
      setData(newValues);
      setEditMode(false);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Section>
        <Section.Title>{t('Teacher information')}</Section.Title>
        <Section.Content background>
          <SectionContentInner>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.manipulates_heavy_machinery}
                  initial={heavyMachinery}
                  name="manipulates_heavy_machinery"
                  onSelected={handleHeavyMachineryChange}
                  options={heavyMachineryOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Does this teacher manipulate heavy machinery?')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>
                    {t('Does this teacher manipulate heavy machinery?')}
                  </Label>
                  <Text>
                    {teacherData?.manipulates_heavy_machinery
                      ? t('Yes')
                      : t('No')}
                  </Text>
                </>
              )}
            </TextWrapper>

            {editMode ? null : (
              <StyledEditActionButton onClick={editClickHandler}>
                {t('Edit')}
              </StyledEditActionButton>
            )}
          </SectionContentInner>

          {editMode ? (
            <SectionContentFooter>
              <ActionButton level="default" onClick={editClickHandler}>
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton>{t('Save')}</ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

const StyledEditActionButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* two items */
  :first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ div {
    width: 49%;
  }

  /* three items */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 49%;
  }
`;

const SectionContentInner = styled.div`
  /* padding-top: 1.5rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    /* margin-top: 1.5rem; */

    :nth-of-type(2n + 3) {
      flex: 0 0 calc(50% - 6rem);
      margin-left: 6rem;
    }
  }
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const Spacer = styled.div`
  width: 15px;
`;

CRMRiskObjectDetailTeacher.propTypes = {
  teacherData: object,
  onSubmitFunction: func,
  customerPageFunction: func,
};

export default CRMRiskObjectDetailTeacher;
