import React, { Component } from 'react';
import styled from 'styled-components';
import { array } from 'prop-types';

import { withRouter } from 'react-router';
import { shape } from 'prop-types';
import { getCurrentStep, getSkippedSteps } from '../../store/index';
import {
  getAllCoverages,
  getCurrentTheme,
  getSocialLogin,
} from '../../../../../store/index';
import { Link } from 'react-router-dom';

import Step from './Step';
import MarketIconFire from '../../../../../../assets/images/market-icon-fire';
import MarketIconPeople from '../../../../../../assets/images/market-icon-family';
import MarketIconCart from '../../../../../../assets/images/market-icon-cart';
import MarketIconCar from '../../../../../../assets/images/market-icon-car';
import StepIconLegal from '../../../../../../assets/images/step-icon-legal';

class ProgressBar extends Component {
  constructor(props, context) {
    super(props, context);
    this.coverages = getAllCoverages();
    this.state = {
      currentStep: 1,
      skippedSteps: getSkippedSteps(),
    };
  }

  componentDidMount = () => {
    this.CheckSteps();
    const newSkippedSteps = getSkippedSteps();

    const { skippedSteps } = this.state;
    if (newSkippedSteps !== skippedSteps) {
      this.setState({ skippedSteps: newSkippedSteps });
    }
  };

  componentDidUpdate = () => {
    this.CheckSteps();
  };

  componentDidUpdate = () => {
    const newSkippedSteps = getSkippedSteps();
    const { skippedSteps } = this.state;

    if (newSkippedSteps !== skippedSteps) {
      this.setState({ skippedSteps: newSkippedSteps });
    }
  };

  CheckSteps = () => {
    setTimeout(() => {
      const newCurrentStep = getCurrentStep();
      const { currentStep } = this.state;
      if (currentStep !== newCurrentStep) {
        this.setState({ currentStep: newCurrentStep });
      }
    }, 50);
  };

  handleGoBack = e => {
    e.preventDefault();
    const { history } = this.props;
    history.goBack();
  };

  render() {
    const { steps } = this.props;
    const { currentStep, skippedSteps } = this.state;
    const icons = [
      <MarketIconFire key="Fire" colors={getCurrentTheme()} />,
      <MarketIconPeople key="Family" colors={getCurrentTheme()} />,
      <MarketIconCar key="Car" colors={getCurrentTheme()} />,
      <StepIconLegal key="Legal" colors={getCurrentTheme()} />,
      <MarketIconCart key="Cart" colors={getCurrentTheme()} />,
    ];

    return (
      <StyledBar>
        {!getSocialLogin() ? (
          <GoDashboard
            data-test-id="underwriting_dashboard_nav"
            to="/dashboard"
            title="Dasbhoard"
          >
            <GoBackIconContainer>
              <GoBackIcon
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.755488"
                  y="0.755"
                  width="7.91858"
                  height="7.91858"
                  rx="2.83797"
                  stroke="white"
                  strokeWidth="1.51"
                />
                <rect
                  x="11.3269"
                  y="0.755"
                  width="7.91858"
                  height="7.91858"
                  rx="2.83797"
                  stroke="white"
                  strokeWidth="1.51"
                />
                <rect
                  x="0.755"
                  y="11.3264"
                  width="7.91858"
                  height="7.91858"
                  rx="2.83797"
                  stroke="white"
                  strokeWidth="1.51"
                />
                <rect
                  x="11.602"
                  y="11.3264"
                  width="7.91858"
                  height="7.91858"
                  rx="2.83797"
                  fill="white"
                  stroke="white"
                  strokeWidth="1.51"
                />
              </GoBackIcon>
            </GoBackIconContainer>
          </GoDashboard>
        ) : null}
        <GoBack
          data-test-id="underwriting_goback_nav"
          disable={false}
          onClick={this.handleGoBack}
        >
          <GoBackIconContainer>
            <GoBackIcon
              viewBox="0 0 15 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.9961 3.22314L3.30664 11.9127L11.6054 20.2115"
                stroke="white"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </GoBackIcon>
          </GoBackIconContainer>
        </GoBack>
        {steps.map(step => {
          if (step.id === steps.length) {
            return (
              <Step
                last
                key={step.id}
                skipped={skippedSteps[step.id]}
                currentStep={currentStep}
                content={step}
                icon={icons[step.id - 1]}
              />
            );
          }
          if (
            this.coverages[
              step.title === 'fire'
                ? 'home'
                : step.title === 'people'
                ? 'family'
                : step.title
            ]
          ) {
            return (
              <Step
                key={step.id}
                skipped={skippedSteps[step.id]}
                currentStep={getCurrentStep()}
                content={step}
                icon={icons[step.id - 1]}
              />
            );
          }
          return null;
        })}
      </StyledBar>
    );
  }
}

const GoDashboard = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disable ? `default` : 'pointer')};
  background-color: ${({ theme, disable }) =>
    disable ? `darkgrey` : theme.brand.secondary};
  border: none;
  width: 5.6rem;
  height: 5.6rem;
  font-size: 2.5rem;
  border-radius: 50%;
  padding: 0;
  margin-right: 2rem;

  & > div > svg {
    margin-left: 0;
  }

  :hover {
    background-color: ${({ theme, disable }) =>
      disable ? 'none' : theme.brand.lighter};
  }

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

const GoBackIconContainer = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  color: white;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoBackIcon = styled.svg`
  width: 100%;
  height: 100%;
  margin-left: -0.2rem;
`;

const GoBack = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disable ? `default` : 'pointer')};
  background-color: ${({ theme, disable }) =>
    disable ? `darkgrey` : theme.brand.secondary};
  border: none;
  width: 5.6rem;
  height: 5.6rem;
  font-size: 2.5rem;
  border-radius: 50%;
  padding: 0;
  margin-right: 5.5rem;

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

const StyledBar = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 88rem;
  width: 50%;
  margin: 2rem 0;
`;

ProgressBar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  steps: array.isRequired,
  history: shape().isRequired,
};

export default withRouter(ProgressBar);
