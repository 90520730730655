import React from 'react';
import { shape } from 'prop-types';

/* eslint-disable */

const PetsLock = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 653.93 477.55"
    style={{ width: `100%`, height: `100%` }}
  >
    <path
      style={{ fill: `${colors.brand.primary}` }}
      d="M524.93,188c0,29.95-16.33,63.44-32.12,95.83-5.12,10.51-10.38,21.29-15,31.83q-2.1,4.74-4,9.41c-3.7,9.15-7.14,18.22-10.47,27-17,44.81-31.68,83.5-69.52,104.86a18.49,18.49,0,0,1-9.26,2.48c-10.57,0-21.35-8.68-31.86-17.15-8.5-6.84-18.14-14.6-24.81-14.6-6.83,0-16.8,7.32-26.45,14.4-14.86,10.91-30.23,22.19-43.75,14.46-36.29-20.75-49.73-57.43-65.29-99.9-3.75-10.23-7.63-20.8-12-31.53q-2-4.89-4.22-9.86c-5-11.18-10.77-22.63-16.42-33.78-16-31.55-32.48-64.17-32.48-93.43,0-68.13,31.84-131.39,85.41-170.2q1.92-1.39,3.88-2.75c26.28-18.12,60.83-20,90.16-5a42.2,42.2,0,0,0,19.36,4.67,42.16,42.16,0,0,0,19.35-4.67c29.33-15,63.87-13.09,90.16,5q5.78,4,11.23,8.33C496,62.55,524.93,123.08,524.93,188Z"
    />
    <path
      style={{ fill: `${colors[`black`]}`, opacity: 0.2 }}
      d="M213,23l-36.8,292.23c-5-11.18-10.77-22.63-16.42-33.78-16-31.55-32.48-64.17-32.48-93.43,0-68.13,31.84-131.39,85.41-170.2A13.85,13.85,0,0,1,213,23Z"
    />
    <path
      style={{ fill: `${colors[`black`]}`, opacity: 0.2 }}
      d="M524.93,188c0,29.95-16.33,63.44-32.12,95.83-5.12,10.51-10.38,21.29-15,31.83L446.87,23.41C496,62.55,524.93,123.08,524.93,188Z"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M245,167.89h0a26.43,26.43,0,0,1,26.43,26.43v6.89a0,0,0,0,1,0,0H218.61a0,0,0,0,1,0,0v-6.89A26.43,26.43,0,0,1,245,167.89Z"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M532.17,328.55,645,186.87a13.73,13.73,0,0,0-1.57-18.76L468.09,10.79c-9.87-8.86-25.31,0-22.6,13l62.51,299A13.73,13.73,0,0,0,532.17,328.55Z"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M121.76,328.55,9,186.87a13.71,13.71,0,0,1,1.57-18.76L185.84,10.79c9.87-8.86,25.32,0,22.6,13l-62.51,299A13.73,13.73,0,0,1,121.76,328.55Z"
    />
    <path
      style={{
        fill: `none`,
        strokeWidth: `12px`,
        strokeLinecap: `round`,
        strokeMiterlimit: 10,
      }}
      d="M359.16,383.69v56.83a31,31,0,1,1-62,0V384.3"
    />
    <path
      style={{
        fill: `none`,
        strokeWidth: `12px`,
        strokeLinecap: `round`,
        strokeMiterlimit: 10,
      }}
      d="M359.16,383.69v56.83a31,31,0,1,1-62,0V384.3"
    />
    <line
      style={{
        strokeLinejoin: `round`,
        fill: `none`,
        strokeWidth: `12px`,
        strokeLinecap: `round`,
        stroke: `${colors[`secondary-dark`]}`,
      }}
      x1="328.14"
      y1="385.38"
      x2="328.14"
      y2="318.4"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M402.4,167.89h0a26.43,26.43,0,0,1,26.43,26.43v6.89a0,0,0,0,1,0,0H376a0,0,0,0,1,0,0v-6.89A26.43,26.43,0,0,1,402.4,167.89Z"
    />
    <path
      style={{
        fill: `none`,
        strokeWidth: `12px`,
        strokeMiterlimit: 10,
        stroke: `${colors.brand.dark}`,
      }}
      d="M121.76,328.55,9,186.87a13.71,13.71,0,0,1,1.57-18.76L185.84,10.79c9.87-8.86,25.32,0,22.6,13l-62.51,299A13.73,13.73,0,0,1,121.76,328.55Z"
    />
    <path
      style={{
        fill: `none`,
        strokeWidth: `12px`,
        strokeMiterlimit: 10,
        stroke: `${colors.brand.dark}`,
      }}
      d="M532.17,328.55,645,186.87a13.73,13.73,0,0,0-1.57-18.76L468.09,10.79c-9.87-8.86-25.31,0-22.6,13l62.51,299A13.73,13.73,0,0,0,532.17,328.55Z"
    />
    <path
      style={{
        fill: `none`,
        strokeWidth: `12px`,
        strokeLinecap: `round`,
        strokeMiterlimit: 10,
      }}
      d="M291.61,263.4h74.13a8.9,8.9,0,0,1,8.9,8.9v0a46,46,0,0,1-46,46h0a46,46,0,0,1-46-46v0A8.9,8.9,0,0,1,291.61,263.4Z"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M291.61,263.4h74.13a8.9,8.9,0,0,1,8.9,8.9v0a46,46,0,0,1-46,46h0a46,46,0,0,1-46-46v0A8.9,8.9,0,0,1,291.61,263.4Z"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M359.16,383.7v56.82a31,31,0,1,1-62.05,0V384.3l1.73,0,9.28,3.15a62.21,62.21,0,0,0,40,0Z"
    />
    <path
      style={{
        fill: `none`,
        strokeWidth: `12px`,
        strokeLinecap: `round`,
        strokeMiterlimit: 10,
      }}
      d="M278.73,377.46l20.11,6.82,9.28,3.15a62.21,62.21,0,0,0,40,0l11-3.73h0l18.38-6.23"
    />
  </svg>
);

PetsLock.propTypes = {
  colors: shape().isRequired,
};

export default PetsLock;
