import { isObjectEmpty } from './objectService';
import { authMe } from './apiRouterService';
import i18next from 'i18next';
import { getSocialLogin } from '../store';

let meData = {};

export function resetMeData() {
  meData = {};
}

export const setMeData = () => {
  return fetchData();
};

const fetchData = async () => {
  const [resp, status] = await authMe();
  if (status === 200) {
    meData = resp;
    if (
      i18next.language !== resp.me.language.toLowerCase() &&
      !getSocialLogin
    ) {
      i18next.changeLanguage(resp.me.language.toLowerCase());
    }
    return resp;
  }
};

export const getMeData = () => {
  if (isObjectEmpty(meData)) return setMeData();

  return new Promise(resolve => {
    resolve(meData);
  });
};
