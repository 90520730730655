import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { TextArea, ActionButton, LoadingSpinner } from 'wg-fe-ui';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import Layout from '../models/Marketplace/LayoutMarket/views/LayoutNoStep';
import {
  sendFeedbackWithMailer,
  getSignatureCallbackCheck,
} from '../services/apiRouterService';
import { getBrokerData, resetBrokerData } from '../services/brokerDataService';
import { ThemeContext } from '../services/context/ThemingContext';
import { resetMeData } from '../services/meDataService';

const SignatureThankYou = () => {
  const [feedback, setFeedback] = useState('');
  const [responseFeedback, setResponseFeedback] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { search } = useLocation();
  const { t } = useTranslation();
  const { setTheme } = useContext(ThemeContext);

  useEffect(() => {
    retrieveBrokerDataAndSetTheme();
  }, []);

  async function retrieveBrokerDataAndSetTheme() {
    resetBrokerData();
    resetMeData();
    const params = new URLSearchParams(search);
    const broker_id = params.get('broker');
    const signature_id = params.get('signature');
    // sessionStorage.setItem('broker_id', broker_id);
    const [callbackCheckResp, status] = await getSignatureCallbackCheck(
      signature_id,
    );
    const { theme } = await getBrokerData(broker_id);
    setTheme(theme?.name);
    console.log(status, callbackCheckResp);
    await setIsLoading(false);
  }

  async function handleCLick() {
    const feedbackText = `Hi,<br><br>I've got some feedback from a user for you!<br><br>The user said the following:<br>${feedback}`;
    let message;
    const payload = {
      data: { feedback: feedbackText },
    };

    const [, status] = await sendFeedbackWithMailer(payload);

    if (status === 200) {
      message = t(`Your feedback was sent correctly Thanks again`);
    } else {
      message = t(
        `Something went wrong with sending the feedback try again later`,
      );
    }

    setResponseFeedback(message);
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Layout>
      <Container>
        <Content>
          <Header>
            <H1>{t(`Thank you for signing online!`)}</H1>
            <H2>
              {t(
                `We have received the documents correctly Your broker will contact you with further steps to take`,
              )}
            </H2>
          </Header>
          <div>
            <H2>{t(`How was your conversation`)}</H2>
            <P>
              {t(
                `The developers of the software attach great importance to your opinion Based on your feedback, they will work day and night to make the experience even better for you Let us hear from you here`,
              )}
            </P>
            <TextArea
              name="feedback"
              value={feedback}
              onChange={({ value }) => {
                setFeedback(value);
              }}
            />
            <StyledActionButton onClick={handleCLick}>
              {t(`Send your feedback`)}
            </StyledActionButton>
            <Feedback>{responseFeedback}</Feedback>
          </div>
        </Content>
      </Container>
    </Layout>
  );
};

const StyledActionButton = styled(ActionButton)`
  width: 20rem;
  margin-top: 1rem;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  margin-bottom: 4rem;
`;

const H1 = styled.h1`
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;

const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;

const P = styled.p`
  font-size: 1.6rem;
  margin-bottom: 1rem;
`;

const Feedback = styled.p`
  font-size: 1.6rem;
  margin-top: 2rem;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 4rem;
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
`;

export default SignatureThankYou;
