import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import ChatBox from '../../../../components/ChatBox';
import {
  setNotice,
  setSkippedSteps,
  setCoverage,
  getCurrentStep,
  showErrorPopup,
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { postFamilyData, setInsuredData } from '../store';
import { useTranslation } from 'react-i18next';

const InsuranceQuestionFamily = ({ history }) => {
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const { t } = useTranslation();
  const content = t(`${getComponentName()}`, { returnObjects: true });
  const answerA = t(`${getComponentName()}.answers.0.title`);
  const answerB = t(`${getComponentName()}.answers.1.title`);
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const handleAnswer = answer => {
    addChatboxQuestion(content.question);
    if (isPending) return;
    setIsPending(true);
    switch (answer) {
      case answerA:
        setAnswer(1);
        addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
        history.push(getNextPath());
        // addAnswer(answer, this.content.key, true);
        break;
      case answerB:
        setInsuredData('already_insured_with', false);
        setAnswer(2);
        setNotice('family', false, null);
        postFamilyData()
          .then(() => {
            setSkippedSteps(getCurrentStep(), false);
            setCoverage('family', true);
            addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
            history.push(getNextPath('b'));
          })
          .catch(error => {
            showErrorPopup(true);
            console.error(error.response);
          });
        // if (this.coverages.home || this.coverages.family) {
        //   addAnswer(answer, this.content.key, false);
        // }
        break;
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <QuestionBox
          to={null}
          Answer1={answerA}
          Answer2={answerB}
          dataTestId1="underwriting_answer_yes"
          dataTestId2="underwriting_answer_no"
          shouldLoad={isPending ? answer : undefined}
          noLink={null}
          disabled={false}
          response={answer => handleAnswer(answer)}
        >
          {content.question}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

InsuranceQuestionFamily.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(InsuranceQuestionFamily);
