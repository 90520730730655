import React, { createContext, useContext, useReducer } from 'react';
import { node } from 'prop-types';
import { intersection, isEmpty } from 'lodash';
import {
  createCampaign,
  patchCampaign,
  postCampaignLogo,
} from '../../../../js/services/apiRouterService';
import { getThemeObject } from '../../../../js/services/themingService';

const CampaignCreationStateContext = createContext();
const CampaignCreationDispatchContext = createContext();

const initialState = {
  formData: {
    type: undefined,
    name: undefined,
    extend: undefined,
    insuranceTypes: undefined,
    insurances: undefined,
    shouldReceiveNotification: undefined,
    listOfNotificationReceivers: undefined,
    amountOfVk: undefined,
    endDate: undefined,
    language: undefined,
    assignedTo: undefined,
    theme: undefined,
    logo: undefined,
  },
  campaignId: undefined,
  status: 'idle',
  error: undefined,
};

function storeReducer(state, action) {
  switch (action.type) {
    case 'LOAD_FORMDATA': {
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
        status: 'edit',
      };
    }
    case 'FETCHING_CREATE': {
      return { ...state, creationStatus: 'fetching', error: undefined };
    }
    case 'FAILED_TO_CREATE': {
      return {
        ...state,
        creationStatus: 'error',
        error: action.payload,
      };
    }
    case 'CREATED': {
      return {
        ...state,
        creationStatus: 'succes',
        error: undefined,
        campaignId: action.payload,
      };
    }
    case 'PATCHED': {
      return { ...state, creationStatus: 'patched', error: undefined };
    }
    case 'RESET': {
      return initialState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function CampaignCreationProvider({ children }) {
  const [store, dispatch] = useReducer(storeReducer, initialState);

  return (
    <CampaignCreationStateContext.Provider value={store}>
      <CampaignCreationDispatchContext.Provider value={dispatch}>
        {children}
      </CampaignCreationDispatchContext.Provider>
    </CampaignCreationStateContext.Provider>
  );
}

function useCampaignCreationStore() {
  const context = useContext(CampaignCreationStateContext);
  if (context === undefined) {
    throw new Error('useCampaignStore must be used within a CampaignProvider');
  }
  return context;
}

function useCampaignCreationDispatch() {
  const context = useContext(CampaignCreationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useCampaignDispatch must be used within a CampaignProvider',
    );
  }
  return context;
}

function loadBackendCampaignFormData(data, dispatch) {
  if (data === undefined) return;

  const insuranceTypes = [
    ...new Set(
      data?.insurances?.map(({ insurance_type }) =>
        insurance_type?.toLowerCase(),
      ),
    ),
  ];

  const insurances = [
    ...new Set(
      data?.insurances?.map(({ insurance_company }) =>
        insurance_company?.toLowerCase(),
      ),
    ),
  ];

  function shouldReceiveNotification({
    send_email_if_lead_created,
    send_email_if_offer_created,
    send_email_if_prospect_created,
  }) {
    return !(
      isEmpty(send_email_if_lead_created) &&
      isEmpty(send_email_if_offer_created) &&
      isEmpty(send_email_if_prospect_created)
    );
  }

  function generateNotificationList({
    send_email_if_lead_created,
    send_email_if_offer_created,
    send_email_if_prospect_created,
  }) {
    return [
      ...send_email_if_lead_created,
      ...send_email_if_offer_created,
      ...send_email_if_prospect_created,
    ];
  }

  function findMaxVk(insurancesData) {
    return (
      insurancesData?.reduce((prev, current) =>
        prev?.vk_applied > current?.vk_applied ? prev : current,
      )?.vk_applied || 0
    );
  }

  const formData = {
    type: data?.type,
    name: data?.name,
    extend: data?.extend,
    insuranceTypes: insuranceTypes,
    insurances: insurances,
    shouldReceiveNotification: shouldReceiveNotification(data),
    listOfNotificationReceivers: generateNotificationList(data),
    amountOfVk: findMaxVk(data?.insurances),
    endDate: data?.end_date,
    language: data?.language,
    assignedTo: data?.assigned_to,
    theme: data?.theme?.name,
    logo: data?.logo,
  };

  dispatch({
    type: 'LOAD_FORMDATA',
    payload: formData,
  });
}

async function createCampaignFromFormData(data, dispatch) {
  const { values, affiliations } = data || {};
  dispatch({
    type: 'LOAD_FORMDATA',
    payload: { ...values },
  });
  const insurances = affiliations
    .map(({ affiliation_info }) => affiliation_info)
    .filter(({ name }) => {
      return values?.insurances?.includes(name);
    })
    .map(({ insurances, name }) => ({
      insurance_company: name,
      insurance_type: intersection(
        insurances.map(val => (val === 'home' ? 'residence' : val)),
        values?.insuranceTypes,
      ),
      vk_applied: values?.amountOfVk,
    }))
    .filter(({ insurance_type }) => insurance_type?.length > 0);

  function createBEInsuranceArray(_insurances) {
    let beInsurances = [];
    _insurances.forEach(insurance => {
      insurance?.insurance_type?.forEach(type => {
        const { insurance_company, vk_applied } = insurance || {};
        beInsurances.push({
          insurance_company,
          insurance_type: type?.toUpperCase(),
          vk_applied,
        });
      });
    });
    return beInsurances;
  }

  const { brand } = getThemeObject(values?.theme);

  const payload = {
    created_by: data?.broker?.id,
    type: values?.type,
    name: values?.name,
    insurances: createBEInsuranceArray(insurances),
    extend: values.extend,
    send_email_if_prospect_created: values?.listOfNotificationReceivers,
    send_email_if_lead_created: values?.listOfNotificationReceivers,
    send_email_if_offer_created: values?.listOfNotificationReceivers,
    language: values?.language,
    ...(values?.theme && {
      theme: {
        name: values?.theme,
        primary_color: brand?.primary,
        secondary_color: brand?.secondary,
      },
    }),
    end_date: values?.endDate,
    assigned_to: values?.assignedTo,
    active: true,
  };

  const b64toBlob = dataURI => {
    let byteString = window.atob(dataURI.split(',')[1].replace(/\s/g, ''));

    let mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    let arrayBuffer = new ArrayBuffer(byteString.length);
    let _ia = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    let dataView = new DataView(arrayBuffer);
    let blob = new Blob([dataView.buffer], { type: mimeString });

    return blob;
  };

  const [resp, status] = await createCampaign(data?.distribution?.id, payload);
  if (status === 201) {
    if (!values?.logo?.src) {
      dispatch({
        type: 'CREATED',
        payload: resp.id,
      });
    } else {
      const blobImage = b64toBlob(values?.logo?.src);

      let formData = new FormData();

      formData.append('file', blobImage, values?.logo?.alt);

      const [, logoStatus] = await postCampaignLogo(
        data?.distribution?.id,
        resp?.id,
        formData,
      );

      if (logoStatus === 200) {
        dispatch({
          type: 'CREATED',
          payload: resp.id,
        });
      } else {
        dispatch({
          type: 'FAILED_TO_CREATE',
          payload: resp,
        });
      }
    }
  } else {
    dispatch({
      type: 'FAILED_TO_CREATE',
      payload: resp,
    });
  }
}

async function disableCampaign(campaignId, distributionId, dispatch) {
  const [resp, status] = await patchCampaign(distributionId, campaignId, {
    active: false,
  });
  if (status === 200) {
    dispatch({
      type: 'PATCHED',
    });
  } else {
    dispatch({
      type: 'FAILED_TO_CREATE',
      payload: resp,
    });
  }
}

CampaignCreationProvider.propTypes = {
  children: node,
};

export {
  CampaignCreationProvider,
  useCampaignCreationStore,
  useCampaignCreationDispatch,
  loadBackendCampaignFormData,
  createCampaignFromFormData,
  disableCampaign,
};
