import React, { useContext, useState, useEffect } from 'react';
import { StatusIcon, Tabs } from 'wg-fe-ui';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import styled from 'styled-components';

import RequiredInformationForm from './ProflowOffersModalRequirednformationForm';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

const ProflowOffersModalRequirednformation = () => {
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [selectedTab, setSelectedTab] = useState([]);
  const { selectedOffers } = useContext(ProFlowStorageContext);
  const { t } = useTranslation();
  const {
    offersParameters: { requiredInformation },
  } = useContext(ProFlowOffersContext);

  useEffect(() => {
    const insuranceTypesSet = new Set();
    selectedOffers.forEach(({ insuranceType }) =>
      insuranceTypesSet.add(insuranceType.key),
    );
    setInsuranceTypes([...insuranceTypesSet]);
    setSelectedTab([...insuranceTypesSet][0]);
  }, [selectedOffers]);

  function computeStatusType(offers, item) {
    if (isEmpty(offers)) return 'warning';
    const [offerByType] = offers.filter(
      ({ insuranceType }) => insuranceType === item,
    );

    const { errors } = offerByType || {};
    if (!errors) return 'warning';
    if (isEmpty(errors)) return 'success';
    return 'warning';
  }

  return (
    <div>
      {insuranceTypes.length > 0 && (
        <Tabs defaultActive={insuranceTypes[0]} onSelect={setSelectedTab}>
          {insuranceTypes.map(item => (
            <Tabs.Item key={item} name={item} width="18rem">
              <TabTitle>
                <span>{t(item)}</span>
                <StatusIcon
                  type={computeStatusType(requiredInformation, item)}
                />
              </TabTitle>
            </Tabs.Item>
          ))}
        </Tabs>
      )}
      {selectedTab.length > 0 && (
        <RequiredInformationForm key={selectedTab} selectedTab={selectedTab} />
      )}
    </div>
  );
};

const TabTitle = styled.div`
  display: flex;
  justify-content: center;
  > span {
    margin-right: 1rem;
  }
`;

export default ProflowOffersModalRequirednformation;
