import React from 'react';
import styled, { css } from 'styled-components';
import { shape, func, array, string, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ActionButton, Alert, Section } from 'wg-fe-ui';

const ProFlowRiskAndInsuranceButton = ({
  chosenRisks,
  chosenGuarantees,
  chosenInsurances,
  proFlowCreationError,
  proFlowErrorMessage,
  saveAndRenderRiskObjects,
}) => {
  const { t } = useTranslation();

  const hasMinimumGuarantees = chosenRisks?.every(
    key => !_.isEmpty(chosenGuarantees[key]),
  );

  return (
    <ButtonContainer>
      <BottomWrapper>
        {!proFlowCreationError &&
          !hasMinimumGuarantees &&
          chosenInsurances.length > 0 && (
            <StyledAlert icon>
              {t('You must choose at least 1 guarantee for each risk object')}
            </StyledAlert>
          )}

        {chosenInsurances.length > 0 && proFlowCreationError && (
          <StyledAlert icon color={true}>
            {proFlowErrorMessage}
          </StyledAlert>
        )}
      </BottomWrapper>
      <ActionButton
        disabled={
          proFlowCreationError ||
          chosenInsurances.length === 0 ||
          !hasMinimumGuarantees
        }
        onClick={() => saveAndRenderRiskObjects()}
        data-test-id="ContinueButton"
      >
        {t('Next')}
      </ActionButton>
    </ButtonContainer>
  );
};

const StyledAlert = styled(Alert)`
  width: 90%;
  padding: 1rem 0;
  > div:last-child {
    font-size: 1.4rem;
  }
  ${({ color }) =>
    color &&
    css`
      > *:first-child {
        border: 1.5px solid #ff8000;
      }

      > * {
        color: #ff8000;
      }
    `}
`;

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled(Section)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  width: 100%;
  margin: auto;
  margin-bottom: 6rem;
`;

ProFlowRiskAndInsuranceButton.propTypes = {
  chosenRisks: array.isRequired,
  chosenGuarantees: shape({}).isRequired,
  chosenInsurances: array.isRequired,
  proFlowCreationError: bool,
  proFlowErrorMessage: string,
  saveAndRenderRiskObjects: func.isRequired,
};

export default ProFlowRiskAndInsuranceButton;
