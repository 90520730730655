import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const Middle = ({ colors }) => {
  const styles = {
    main: {
      height: `100%`,
    },
    cls1: {
      fill: `${colors.brand.lightest}`,
    },
    cls2: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
    cls3: {
      fill: '#fff',
    },
    cls4: {
      fill: `${colors.brand.secondary}`,
    },
    cls5: {
      fill: `${colors.brand.lightest}`,
    },
    cls6: {
      fill: `${colors.brand.dark}`,
    },
  };

  return (
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 239.5 407"
        style={styles.main}
      >
        <g id="door">
          <polyline
            style={styles.cls1}
            points="1 239.8 1 236 237.89 236 237.89 405.25 1 405.25 1 239.8"
          />
          <line style={styles.cls2} x1="238" y1="405" x2="238" y2="298.5" />
          <line
            style={styles.cls2}
            x1="237.89"
            y1="279.76"
            x2="237.89"
            y2="256.5"
          />
          <line style={styles.cls2} x1="1.5" y1="268.5" x2="1.5" y2="405.5" />
          <polyline
            style={styles.cls3}
            points="87 405.32 87 296.77 150.25 296.77 150.25 405.32"
          />
          <polyline
            style={styles.cls4}
            points="91.12 405.32 91.12 296.77 155.81 296.77 155.81 405.32"
          />
          <line
            style={styles.cls2}
            x1="98.28"
            y1="350.84"
            x2="106.23"
            y2="350.84"
          />
          <polyline
            style={styles.cls2}
            points="87 403.58 87 296.77 150.25 296.77 150.25 404.45"
          />
        </g>
        <g id="bath">
          <polygon
            style={styles.cls1}
            points="119.44 1 1 86 1 236 237.89 236 237.89 86 119.44 1"
          />
          <polyline
            style={styles.cls2}
            points="1.5 216.76 1.5 86.5 119.5 1.5 237.3 86 238 86.5 238 170.3 238 212.08"
          />
          <path
            style={styles.cls2}
            d="M738,234.76"
            transform="translate(-736.5 -18)"
          />
          <path
            style={styles.cls2}
            d="M738,423.5"
            transform="translate(-736.5 -18)"
          />
          <path
            style={styles.cls3}
            d="M894.7,187.29v.21a37.84,37.84,0,0,1-.23,4A39,39,0,0,1,860,226.05a40.19,40.19,0,0,1-4.28.23h-.22A38.71,38.71,0,0,1,842.57,224h0a38.69,38.69,0,0,1-13.87-8.61,38.86,38.86,0,0,1-10-15.79A38.56,38.56,0,0,1,817,191.5a37.69,37.69,0,0,1-.23-4v-.21a39,39,0,0,1,28.54-37.56,38.49,38.49,0,0,1,9.48-1.4l.75,0h.22a39.07,39.07,0,0,1,4.22.23H860a39.18,39.18,0,0,1,4,.64h0a39,39,0,0,1,30.74,38.11Z"
            transform="translate(-736.5 -18)"
          />
          <path
            style={styles.cls5}
            d="M854.75,148.33l-31.92,59.91a38.85,38.85,0,0,1-4.12-8.65l26.56-49.86A38.49,38.49,0,0,1,854.75,148.33Z"
            transform="translate(-736.5 -18)"
          />
          <path
            style={styles.cls5}
            d="M878.84,155.9,842.57,224h0a38.69,38.69,0,0,1-13.87-8.61L864,149.18h0A38.88,38.88,0,0,1,878.84,155.9Z"
            transform="translate(-736.5 -18)"
          />
          <path
            style={styles.cls6}
            d="M855.72,151.31a36.54,36.54,0,0,1,3.9.21l.19,0a35.92,35.92,0,0,1,31.89,35.75v.19a36,36,0,0,1-32,35.59,37.66,37.66,0,0,1-4,.21h-.2a35.94,35.94,0,0,1-35.58-32.12,35,35,0,0,1-.21-3.66v-.21a36.06,36.06,0,0,1,35.77-36h.22m0-3h-.22a39,39,0,0,0-38.77,39v.21a37.69,37.69,0,0,0,.23,4,39,39,0,0,0,38.54,34.78h.22a40.19,40.19,0,0,0,4.28-.23,39,39,0,0,0,34.47-34.55,37.84,37.84,0,0,0,.23-4v-.21A39,39,0,0,0,860,148.54h-.06a39.07,39.07,0,0,0-4.22-.23Z"
            transform="translate(-736.5 -18)"
          />
          <polyline
            style={styles.cls2}
            points="119.5 132 119.5 169.5 119.5 206.28"
          />
          <polyline
            style={styles.cls2}
            points="156.2 169.5 119.5 169.5 85.9 169.5 82.23 169.5"
          />
        </g>
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  z-index: 3;
  height: 17rem;
  position: relative;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 14%;
  }
`;

Middle.propTypes = {
  colors: shape().isRequired,
};
export default Middle;
