import React from 'react';

const TotalLossDefinition = () => (
  <svg viewBox="0 0 269.99 226.78">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'total_loss_definition'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M248.6 160.86h-83.17l.95-13.41c-.28 0-14.92-26.46-14.92-26.46C116 108 51.38 110.68 24.84 120.16 21.44 121.37 0 147.42 0 157.32l2 36.46a8.77 8.77 0 008.44 6.36l18.47.88.58 5.27a25.75 25.75 0 0050.41 0l.4-5.27h93.19l.89 5.27a25.75 25.75 0 0050.41 0l1-5.27 11.26-.88a8.75 8.75 0 008.42-6.36l4.59-16.17c.77-2.74.3-13.53-1.46-16.75zm-178 45.43a17.23 17.23 0 01-6 8.34 16.28 16.28 0 01-9.89 3.31 17.77 17.77 0 1115.87-11.65zm144.88 0a17.21 17.21 0 01-6 8.34 16.27 16.27 0 01-9.88 3.31A16.92 16.92 0 11216.53 201a16.54 16.54 0 01-1.07 5.29z"
        />
        <path
          className="prefix__cls-1"
          d="M205.05 96.83l-43.74 82.88-14.55-7.71c2.58-5.09 36-69.3 57.61-75.12a2.7 2.7 0 01.68-.05z"
        />
        <path
          className="prefix__cls-1"
          d="M258 67.28a39.09 39.09 0 10-60.71-48.11 16.75 16.75 0 10-15.4 29.52 25.35 25.35 0 0036.68 32.83 25.74 25.74 0 002.66 20.18 18 18 0 00-3.13 25.54 45.58 45.58 0 01-7 12.15c-3.66 4.21-9.62 8.62-12.68 11.12a1 1 0 00.67 1.72c5.26-.27 16.51-1.5 23.83-6.81a56.22 56.22 0 0011.39-11.5 18 18 0 0016-17.92 18.47 18.47 0 00-.2-2.59 25.78 25.78 0 008-46.13z"
        />
      </g>
    </g>
  </svg>
);

export default TotalLossDefinition;
