import React, { useState, useEffect } from 'react';
import { Tabs } from 'wg-fe-ui';
import { useContext } from 'react';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import { retrieveStorageById } from '../services/storeService';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { string, func, array } from 'prop-types';
const ProFlowOffersTabs = ({ offerTypes }) => {
  const [selectedTab, setSelectedTab] = useState();
  const [tabs, setTabs] = useState([]);
  const { selectedOffers } = useContext(ProFlowStorageContext);
  const history = useHistory();
  const { sessionId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    getSelectedRiskObjects();
  }, []);

  useEffect(() => {
    if (selectedTab) {
      redirectToTabDetails(selectedTab);
    }
  }, [selectedTab]);

  async function getSelectedRiskObjects() {
    const { risk_object } =
      (await retrieveStorageById('pro_flow', sessionId)) || {};
    setTabs(risk_object?.selected);
  }

  function redirectToTabDetails(selectedTab) {
    const tabKeys = {
      selectedOffers: 'selected-offers',
    };

    offerTypes.forEach(type => (tabKeys[type] = type));

    const tabKey = tabKeys[selectedTab];
    history.push(`/professional-flow/offers/${sessionId}/${tabKey}`);
  }

  return (
    <TabsContainer>
      {tabs?.length !== 0 && !!tabs && (
        <Tabs
          defaultActive={tabs[0]}
          onSelect={selected => setSelectedTab(selected)}
        >
          {tabs.map(tab => {
            return (
              <Tabs.Item key={tab} name={tab}>
                {t(tab)}
              </Tabs.Item>
            );
          })}
          <Tabs.Item name="selectedOffers" width="25rem" rightAlign>
            {t('Selected offers')}
            {selectedOffers.length !== 0 && ` [${selectedOffers.length}]`}
          </Tabs.Item>
        </Tabs>
      )}
    </TabsContainer>
  );
};
const TabsContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
`;

ProFlowOffersTabs.defaultProps = {
  offerTypes: [],
};

ProFlowOffersTabs.propTypes = {
  selectedTab: string,
  setSelectedTab: func,
  offerTypes: array,
};
export default ProFlowOffersTabs;
