import React from 'react';
import { string } from 'prop-types';

const Bicycle = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 262.46 139.23">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M210.17,34.66a51.16,51.16,0,0,0-22.07,5.13L171,12.36h9.06a6.18,6.18,0,1,0,0-12.36H159.89a6.16,6.16,0,0,0-5.24,9.43l5.07,8.14H95l-3.41-5.48h6a5.92,5.92,0,1,0,0-11.84H68.53a5.92,5.92,0,0,0,0,11.84h9.16l7.08,11.4L74.57,39.9a51,51,0,0,0-22.29-5.24,52.29,52.29,0,0,0,0,104.57C78.63,139.23,101,119,104,92.87H131.2a5.84,5.84,0,0,0,2.94-.81,2.75,2.75,0,0,0,.77-.65l.17-.14a4.59,4.59,0,0,0,1.15-1.2l34.29-55.15,7.08,11.37a51.72,51.72,0,0,0-19.71,40.65,52.29,52.29,0,1,0,52.28-52.28ZM90,81H62.93L77.26,58A37.87,37.87,0,0,1,90,81Zm30.51,0H104A52.11,52.11,0,0,0,84.64,46.11l7.1-11.42ZM52.28,125.36a38.42,38.42,0,0,1,0-76.84,37.33,37.33,0,0,1,14.94,3.2l-20,32.11a5.93,5.93,0,0,0,5,9H90A38.17,38.17,0,0,1,52.28,125.36ZM160,29.41,131.2,75.74,102.39,29.41Zm53.4,62.78a6.13,6.13,0,0,0,2-8.5l-20-32.08a37.39,37.39,0,0,1,14.72-3.09,38.42,38.42,0,1,1-38.42,38.42A38,38,0,0,1,185,58.17l19.94,32a6.17,6.17,0,0,0,5.24,2.94A6.07,6.07,0,0,0,213.42,92.19Z"
        />
      </g>
    </g>
  </svg>
);

Bicycle.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Bicycle.propTypes = {
  title: string,
  color: string,
};

export default Bicycle;
