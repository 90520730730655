import React from 'react';
import { string } from 'prop-types';

const HomeIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="33"
      fill="none"
      viewBox="0 0 30 33"
    >
      <path
        fill={color}
        d="M29.56 9.575L26 7.185v-5.36a1 1 0 00-1-1h-4a1 1 0 00-1 1v1.34l-4.45-3a1 1 0 00-1.1 0l-14 9.39a1 1 0 00-.45.84v20.62a1 1 0 001 1h6v-10a1 1 0 011-1h5a1 1 0 011 1v10h15a1 1 0 001-1v-20.6a1 1 0 00-.44-.84z"
      ></path>
    </svg>
  );
};

HomeIcon.defaultProps = {
  color: '#ccc',
};

HomeIcon.propTypes = {
  color: string,
};

export default HomeIcon;
