/* eslint-disable no-unused-expressions*/
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import ApiRoutes from '../store/ApiRoutes';
import LoadingButton from '../components/LoadingButton';
import { func } from 'prop-types';
import { getBrokerDistribution } from '../services/brokerDataService';

const TrialPopupOnBrokerLoginScreen = ({ startScreen, close }) => {
  const { t } = useTranslation();
  const [popupContentState, setPopupContentState] = useState(startScreen);
  const [sendEmailStatus, setSendEmailStatus] = useState('initial');
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const api = new ApiRoutes();
  useEffect(() => {
    switch (sendEmailStatus) {
      case `initial`:
        setShowLoadingButton(false);
        break;
      case `pending`:
        setShowLoadingButton(true);
        break;
      case `success`:
        setShowLoadingButton(false);
        setPopupContentState('accepted');
        break;
      case `error`:
        break;
      default:
        return;
    }
  }, [sendEmailStatus]);

  async function wantsToBuyTrial() {
    const { email } = getBrokerDistribution();
    const payload = {
      data: {
        trial_user: email,
      },
    };
    try {
      await api.postWantsToBuyTrial(payload);
      await setSendEmailStatus('success');
    } catch (error) {
      setSendEmailStatus('error');
    }
  }

  switch (popupContentState) {
    case 'cantUseCheckout':
    case 'cantUseSettings':
    case 'cantUseAcademy':
    case 'cantUseCar':
    case 'cantUseClaims':
      return (
        <ErrorPopup
          onClick={() => {
            popupContentState === 'expired'
              ? setPopupContentState('expiredAreYouSure')
              : setPopupContentState('trialAreYouSure');
          }}
        >
          <PopupContainer onClick={e => e.stopPropagation()}>
            <Close
              onClick={() => {
                popupContentState === 'expired'
                  ? setPopupContentState('expiredAreYouSure')
                  : setPopupContentState('trialAreYouSure');
              }}
            >
              x
            </Close>
            <Title>
              {t(`TrialPopupOnBrokerLoginScreen.${popupContentState}.title`)}
            </Title>
            <Text
              dangerouslySetInnerHTML={{
                __html: t(
                  `TrialPopupOnBrokerLoginScreen.${popupContentState}.body`,
                ),
              }}
            />
            <Button
              onClick={() => {
                setSendEmailStatus('pending');
                wantsToBuyTrial();
              }}
            >
              {t(
                `TrialPopupOnBrokerLoginScreen.${popupContentState}.AcceptButton`,
              )}
            </Button>
          </PopupContainer>
        </ErrorPopup>
      );
    case 'cantUseProFlow':
      return (
        <ErrorPopup
          onClick={() => {
            popupContentState === 'expired'
              ? setPopupContentState('expiredAreYouSure')
              : setPopupContentState('trialAreYouSure');
          }}
        >
          <PopupContainer onClick={e => e.stopPropagation()}>
            <Close
              onClick={() => {
                popupContentState === 'expired'
                  ? setPopupContentState('expiredAreYouSure')
                  : setPopupContentState('trialAreYouSure');
              }}
            >
              x
            </Close>
            <Title>
              {t(`TrialPopupOnBrokerLoginScreen.cantUseCheckout.title`)}
            </Title>
            <Text
              dangerouslySetInnerHTML={{
                __html: t(
                  `you can't create a new tarification during the trial period, but we can make sure that you can start working with Louise very soon!`,
                ),
              }}
            />
            <Button
              onClick={() => {
                setSendEmailStatus('pending');
                wantsToBuyTrial();
              }}
            >
              {t(`TrialPopupOnBrokerLoginScreen.cantUseCheckout.AcceptButton`)}
            </Button>
          </PopupContainer>
        </ErrorPopup>
      );
    case 'expired':
      return (
        <ErrorPopup onClick={() => setPopupContentState('expiredAreYouSure')}>
          <PopupContainer>
            <Close onClick={() => setPopupContentState('expiredAreYouSure')}>
              x
            </Close>
            <Title>{t('TrialPopupOnBrokerLoginScreen.expired.title')}</Title>
            <Text
              dangerouslySetInnerHTML={{
                __html: t('TrialPopupOnBrokerLoginScreen.expired.body'),
              }}
            />
            {!showLoadingButton ? (
              <Button
                onClick={() => {
                  setSendEmailStatus('pending');
                  wantsToBuyTrial();
                }}
              >
                {t('TrialPopupOnBrokerLoginScreen.expired.AcceptButton')}
              </Button>
            ) : (
              <LoadingButton long>
                {t('TrialPopupOnBrokerLoginScreen.expired.AcceptButton')}
              </LoadingButton>
            )}
          </PopupContainer>
        </ErrorPopup>
      );
    case 'expiredAreYouSure':
    case 'trialAreYouSure':
      return (
        <ErrorPopup onClick={() => setPopupContentState('close')}>
          <PopupContainer>
            <Close onClick={() => setPopupContentState('close')}>x</Close>
            <Title>
              {t(`TrialPopupOnBrokerLoginScreen.${popupContentState}.title`)}
            </Title>
            <Text>
              {t(`TrialPopupOnBrokerLoginScreen.${popupContentState}.body`)}
            </Text>
            <ButtonContainer>
              <Button secondary onClick={() => setPopupContentState('close')}>
                {t(
                  `TrialPopupOnBrokerLoginScreen.${popupContentState}.CancelButton`,
                )}
              </Button>
              <Button
                onClick={() => {
                  setSendEmailStatus('pending');
                  wantsToBuyTrial();
                }}
              >
                {t(
                  `TrialPopupOnBrokerLoginScreen.${popupContentState}.AcceptButton`,
                )}
              </Button>
            </ButtonContainer>
          </PopupContainer>
        </ErrorPopup>
      );
    case 'accepted':
      return (
        <ErrorPopup onClick={() => setPopupContentState('close')}>
          <PopupContainer>
            <Title>{t('TrialPopupOnBrokerLoginScreen.accepted.title')}</Title>
            <Text>{t('TrialPopupOnBrokerLoginScreen.accepted.body')}</Text>
            <Button onClick={() => setPopupContentState('close')}>
              {t('TrialPopupOnBrokerLoginScreen.accepted.AcceptButton')}
            </Button>
          </PopupContainer>
        </ErrorPopup>
      );
    case 'close':
      close(false);
      setPopupContentState(startScreen);
      setSendEmailStatus('initial');
      setShowLoadingButton(false);
      return null;
    default:
      return null;
  }
};

const Close = styled.span`
  font-size: 2.5rem;
  position: absolute;
  top: 1.6rem;
  left: 2.5rem;
  cursor: pointer;
`;

const PopupContainer = styled.div`
  position: relative;
  max-width: 100rem;
  background-color: white;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: 0.3s fade;
  padding: 4rem;

  @keyframes fade {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  background-color: ${({ theme, secondary }) =>
    secondary ? 'none' : theme.brand.primary};
  border-radius: 0.5rem;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  color: ${props => (props.secondary ? 'gray' : 'white')};
  border: 0;
  font-weight: bold;
  margin: 1rem;
  text-decoration: none;
  align-self: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
  z-index: 100;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  margin-top: 4rem;
  padding: 1rem 3rem;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }

  &:hover {
    background-color: ${({ theme, secondary }) =>
      secondary ? 'none' : theme.brand.lighter};
    color: ${props => (props.secondary ? 'black' : 'white')};
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  @media screen and (max-width: 1025px) {
    transform: scale(0.9);
    margin-bottom: 1rem;

    &:active {
      transform: scale(0.88);
    }
  }
`;

const ErrorPopup = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 900;
`;
const Text = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 3rem;
`;

TrialPopupOnBrokerLoginScreen.propTypes = {
  startScreen: string.isRequired,
  close: func.isRequired,
};

export default TrialPopupOnBrokerLoginScreen;
