import React from 'react';
import { string } from 'prop-types';

const LivingLessThanOneYearTogether = ({ color }) => {
  return (
    <svg
      width="22"
      height="29"
      viewBox="0 0 22 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.399 5.02579C17.7764 5.03891 18.881 3.91041 18.881 2.51946C18.881 1.12851 17.7634 0.0131226 16.399 0.0131226C15.0345 0.0131226 13.917 1.14163 13.917 2.51946C13.917 3.89728 15.0215 5.02579 16.399 5.02579Z"
        fill={color}
      />
      <path
        d="M21.9998 8.84433V15.5235C21.9998 16.2059 21.454 16.8095 20.7782 16.7964C20.4664 16.7964 20.1935 16.6783 19.9726 16.4814C19.9596 16.4814 19.9596 16.4814 19.9596 16.4946V27.4909C19.9596 28.3307 19.2839 29.0131 18.4522 29.0131C17.6205 29.0131 16.9448 28.3307 16.9448 27.4909V18.1348C16.9448 17.833 16.6979 17.5837 16.399 17.5837C16.1002 17.5837 15.8533 17.833 15.8533 18.1348V27.4909C15.8533 28.3307 15.1775 29.0131 14.3459 29.0131C13.5142 29.0131 12.8385 28.3307 12.8385 27.4909V16.4552C12.8255 16.4552 12.8255 16.4552 12.8255 16.4421C12.6306 16.652 12.3447 16.7832 12.0328 16.8095C11.3571 16.8489 10.7983 16.2584 10.7983 15.5629V8.84433C10.7983 7.25654 12.0198 5.59003 13.9041 5.59003H18.894C20.7653 5.59003 21.9998 7.25654 21.9998 8.84433Z"
        fill={color}
      />
      <path
        d="M7.67986 13.6602H6.61429V9.35608C6.61429 8.85744 6.22445 8.46378 5.73066 8.46378H3.4176C2.9238 8.46378 2.53396 8.85744 2.53396 9.35608V13.6602H1.4684C0.649734 13.6602 0 14.3294 0 15.143V26.3624C0 27.1891 0.662729 27.8452 1.4684 27.8452H7.67986C8.49852 27.8452 9.14826 27.176 9.14826 26.3624V15.143C9.14826 14.3294 8.49852 13.6602 7.67986 13.6602ZM3.4176 9.65789C3.4176 9.4873 3.54755 9.35608 3.71648 9.35608H5.43178C5.60071 9.35608 5.73066 9.4873 5.73066 9.65789V13.6602H3.4176V9.65789Z"
        fill={color}
      />
      <path
        d="M2.42993 29H2.32597C2.0011 29 1.74121 28.7375 1.74121 28.4095V26.9923H3.01469V28.4095C3.01469 28.7375 2.7548 29 2.42993 29Z"
        fill={color}
      />
      <path
        d="M6.8352 29H6.73124C6.40638 29 6.14648 28.7375 6.14648 28.4095V26.9923H7.41996V28.4095C7.41996 28.7375 7.16007 29 6.8352 29Z"
        fill={color}
      />
    </svg>
  );
};

LivingLessThanOneYearTogether.propTypes = {
  color: string,
};

export default LivingLessThanOneYearTogether;
