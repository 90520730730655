import React from 'react';

const Guest = () => (
  <svg viewBox="0 0 205.47 225.58">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'guestsmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M103.09 14.22l28 17.79 20 12.69V18.09h12v34.42l6 3.83 23.39 14.81v141.43H13V71.14l90.09-56.92M103.1 0a6.42 6.42 0 00-3.4 1L3 62.1a6.37 6.37 0 00-3 5.39v148.8a9.29 9.29 0 009.29 9.29h186.89a9.29 9.29 0 009.29-9.29V67.49a6.36 6.36 0 00-2.95-5.37L176 45.35V11.47a6.38 6.38 0 00-6.38-6.38h-25.18a6.38 6.38 0 00-6.38 6.38V21L106.52 1a6.41 6.41 0 00-3.42-1z"
        />
        <path
          className="prefix__cls-1"
          d="M44.46 105.91v70.16a8.69 8.69 0 008.7 8.69H65.5V97.21H53.16a8.7 8.7 0 00-8.7 8.7zM152.31 97.21H140v87.55h12.34a8.7 8.7 0 008.7-8.69v-70.16a8.71 8.71 0 00-8.73-8.7zM123.7 86.17a7.36 7.36 0 00-7.35-7.35H89.12a7.36 7.36 0 00-7.35 7.35v11h-4.84v87.55h51.61V97.21h-4.84zm-34.58 0h27.23v11H89.12z"
        />
      </g>
    </g>
  </svg>
);

export default Guest;
