import React from 'react';
import { ActionButton, Modal, TextArea } from 'wg-fe-ui';
import styled from 'styled-components';
import { array, func, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from '../hooks/useForm';
import DateInput from './DateInput_v3';
import * as Yup from 'yup';
import { string, date } from '../../constants/validationSchemas';
// import { isFuture } from 'date-fns';
import {
  parseIncDateToObject,
  parseObjectToNewDate,
  parseIncDateToIsoFormat,
} from '../services/dateService';

const CRMContractNewAddendumModal = ({
  modalState,
  createNewAddendum,
  previousData,
}) => {
  // const [validationSchema, setValidationSchema] = useState();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    addendum_commencement_date: date
      // .test(
      //   'addendum-commencement-date-in-future',
      //   t('Date cannot be in the past'),
      //   handleDateInFutureValidation,
      // )
      .test(
        'addendum-commencement-date-not-before-previous',
        t('Date cannot be before previous version'),
        handleAddendumCommencementDateValidation,
      ),
    reason_for_creation: string.notRequired,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
    change: true,
  });

  const [showModal, setShowModal] = modalState;

  // function handleDateInFutureValidation() {
  //   const { addendum_commencement_date } = this.parent;
  //   console.log(addendum_commencement_date);
  //   if (addendum_commencement_date) {
  //     const dateObject = parseIncDateToObject(addendum_commencement_date);
  //     const addendumCommencementDate = parseObjectToNewDate(dateObject);

  //     return isFuture(addendumCommencementDate);
  //   }
  //   return true;
  // }

  function handleAddendumCommencementDateValidation() {
    const { addendum_commencement_date } = this.parent;
    if (addendum_commencement_date) {
      const dateObject = parseIncDateToObject(addendum_commencement_date);
      const addendumCommencementDate = parseObjectToNewDate(dateObject);

      if (previousData?.addendum_commencement_date) {
        const previousAddendumCommencementDate = new Date(
          previousData?.addendum_commencement_date,
        );
        return addendumCommencementDate >= previousAddendumCommencementDate;
      } else {
        const previousAddendumCommencementDate = new Date(
          previousData?.commencement_date,
        );
        return addendumCommencementDate >= previousAddendumCommencementDate;
      }
    }
    return true;
  }

  const onSubmit = async val => {
    setShowModal(!showModal);
    values.addendum_commencement_date = parseIncDateToIsoFormat(
      values.addendum_commencement_date,
    );
    if (values?.reason_for_creation === '') {
      delete values.reason_for_creation;
    }
    createNewAddendum(values);
  };

  return (
    <Modal
      canClose
      modalElementClass="modal-full-width"
      showModal={showModal}
      setShowModal={setShowModal}
      title={t('Create a new addendum')}
      width={'100%'}
    >
      <Container>
        <DateInput
          error={errors.addendum_commencement_date}
          name="addendum_commencement_date"
          onChange={handleChange}
          value={values.addendum_commencement_date}
        >
          {t('Addendum commencement date')}
        </DateInput>
        <StyledTextArea
          error={errors?.reason_for_creation}
          name="reason_for_creation"
          type="string"
          value={values.reason_for_creation}
          onChange={handleChange}
        >
          {t('Reason for addendum')}
        </StyledTextArea>
        <Modal.ModalActions position="center">
          <ActionButton level="primary" onClick={() => handleSubmit(onSubmit)}>
            {t('Submit')}
          </ActionButton>
        </Modal.ModalActions>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100000;
`;

const StyledTextArea = styled(TextArea)`
  margin-top: 20px;
`;

CRMContractNewAddendumModal.propTypes = {
  modalState: array,
  createNewAddendum: func,
  previousData: object,
};

export default CRMContractNewAddendumModal;
