import React, { useState, useEffect } from 'react';
import { object, string as propString, func, bool } from 'prop-types';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';
import useForm from '../hooks/useForm';

import {
  updateProspectCustomerById,
  updateProspectCompanyById,
  getEnumByName,
} from '../services/apiRouterService';

import cogoToast from 'cogo-toast';

import {
  Title,
  Label,
  Section,
  IconActionPhone,
  IconActionMessage,
  Text,
  EditActionButton,
  TextInput,
  SearchSelectInput,
  ActionButton,
} from 'wg-fe-ui';

import DateInput from './DateInput_v3';

import {
  formatISODate,
  parseIncDateToIsoFormat,
} from '../services/dateService';
import { formatCRMAddress } from '../services/CRMService';

const CRMProspectClientData = ({
  prospectData,
  distributionId,
  onSubmitFunction,
  isCustomerPage,
  disableEdit,
  customerPageFunction,
}) => {
  /* Inits
  -------------------------------------------------- */
  const { t } = useTranslation();

  const [data, setData] = useState(prospectData);
  const [editMode, setEditMode] = useState(false);
  const [language, setLanguage] = useState('');
  const [professions, setProfessions] = useState({});
  const [profession, setProfession] = useState('');
  const [professionOptions, setProfessionOptions] = useState([]);

  const languageOptions = [
    { value: 'NL', label: t('Dutch') },
    { value: 'FR', label: t('French') },
    { value: 'EN', label: t('English') },
  ];

  useEffect(() => {
    setData(prospectData);
    retrieveProfessions();
  }, [prospectData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  useEffect(() => {
    let tempOptions = [];

    if (professions) {
      Object.entries(professions).map(profession => {
        const entry = { value: profession[1], label: t(profession[1]) };
        tempOptions.push(entry);
      });
    }

    setProfessionOptions(tempOptions);
  }, [professions]);

  /* Helper functions
  -------------------------------------------------- */
  const findLanguageInEnums = lang => {
    const result = languageOptions.filter(obj => {
      return obj.value === lang;
    });

    return result;
  };

  const findProfessionInEnums = prof => {
    const result = professionOptions.filter(obj => {
      return obj.value === prof;
    });

    return result;
  };

  async function retrieveProfessions() {
    const _professions = await getEnumByName('PROFESSION');
    setProfessions(_professions[0].items);
  }

  /* Form Handling
  -------------------------------------------------- */
  const ValidationSchema = Yup.object().shape({
    first_name: Yup.string().nullable(),

    last_name: Yup.string().nullable(),

    email: Yup.string()
      .email(t('Please enter a valid email address'))
      .nullable(),

    telephonenr: Yup.number()
      .typeError(t(`That doesn't look like a phone number`))
      .positive(t('A phone number can not be negative'))
      .nullable(),

    street: Yup.string().required(t('Required')),

    housenr: Yup.string().required(t('Required')),

    boxnr: Yup.string().nullable(),

    zipcode: Yup.number().required(t('Required')),

    city: Yup.string().required(t('Required')),

    birth: Yup.string(),

    iban: Yup.string().nullable(),

    national_register_nr: Yup.string().nullable(),

    cbe: Yup.string()
      .matches(/0[0-9]{9}/, t('A CBE should consist of 9 numbers.'))
      .nullable(),

    native_language: Yup.string().nullable(),

    profession: Yup.string().nullable(),

    rsz_nr: Yup.string().nullable(),
  });

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: ValidationSchema,
    mode: 'onChange',
  });

  const setInitialFormValues = () => {
    if (data) {
      Object.keys(data).map(name => {
        handleChange({ name, value: data[name] });
      });
      if (data?.address) {
        Object.keys(data.address).map(name => {
          handleChange({ name, value: data.address[name] });
        });
      }

      setLanguage(findLanguageInEnums(data.native_language));
      setProfession(findProfessionInEnums(data.profession));
    }
  };

  const handleLanguageChange = e => {
    const pickedLang = findLanguageInEnums(e.value);
    setLanguage(pickedLang);
    handleChange({ name: 'native_language', value: pickedLang[0].value });
  };

  const handleProfessionChange = e => {
    const pickedProfession = findProfessionInEnums(e.value);
    setProfession(pickedProfession);
    handleChange({ name: 'profession', value: pickedProfession[0].value });
  };

  async function handleFormValues() {
    if (data.type === 'CUSTOMER') {
      const birthStr = values?.birth.replace(/\//g, '');

      const payload = {
        native_language: values?.native_language,
        email: values?.email,
        telephonenr: values?.telephonenr,
        iban: values?.iban,
        address: {
          street: values?.street,
          zipcode: values?.zipcode,
          housenr: values?.housenr,
          country_code: values?.country_code,
          boxnr: values?.boxnr,
          city: values?.city,
          lat: values?.lat,
          lng: values?.lng,
          region: values?.region,
        },
        first_name: values?.first_name,
        last_name: values?.last_name,
        birth: birthStr,
        gender: values?.gender,
        profession: values?.profession,
      };

      const [resp, status] = await updateProspectCustomerById(
        distributionId,
        data.id,
        payload,
      );

      if (status === 200) {
        cogoToast.success(t('The prospect was successfully updated'));
        const newData = { ...data, ...payload };
        setData(newData);
        setEditMode(!editMode);
      } else if (status === 422) {
        cogoToast.error(
          t(
            'There was an issue with one of your form inputs. Please check your form and try again.',
          ),
        );
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
      } else {
        cogoToast.error(t('Something went wrong!'));
        console.log(
          `RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`,
        );
      }
    } else {
      const payload = {
        name: values?.name,
        native_language: values?.native_language,
        email: values?.email,
        telephonenr: values?.telephonenr,
        iban: values?.iban,
        address: {
          street: values?.street,
          zipcode: values?.zipcode,
          housenr: values?.housenr,
          country_code: values?.country_code,
          boxnr: values?.boxnr,
          city: values?.city,
          lat: values?.lat,
          lng: values?.lng,
          region: values?.region,
        },
        cbe: values?.cbe,
        rsz_nr: values?.rsz_nr,
      };

      const [resp, status] = await updateProspectCompanyById(
        distributionId,
        data.id,
        payload,
      );

      if (status === 200) {
        cogoToast.success(t('The prospect was successfully updated'));
        const newData = { ...data, ...payload };
        setData(newData);
        setEditMode(!editMode);
      } else if (status === 422) {
        cogoToast.error(
          t(
            'There was an issue with one of your form inputs. Please check your form and try again.',
          ),
        );
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
      } else {
        cogoToast.error(t('Something went wrong!'));
        console.log(
          `RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`,
        );
      }
    }
  }

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    } else {
      onSubmitFunction(values);
      setData(values);
      setEditMode(false);
      handleSubmit(handleFormValues);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    }
  };

  /* Click handlers
  -------------------------------------------------- */
  const emailClickHandler = (e, email) => {
    e.preventDefault();
    window.location.href = `mailto:${email}`;
  };

  const phoneClickHandler = (e, phonenr) => {
    e.preventDefault();
    window.location.href = `tel:${phonenr}`;
  };

  const editClickHandler = e => {
    setEditMode(!editMode);
    setInitialFormValues();

    if (customerPageFunction) {
      customerPageFunction(!editMode);
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Section>
        <Section.Title>{t('Prospect.Client information')}</Section.Title>

        <Section.Content background>
          <SectionContentHeader>
            <TextWrapper>
              {data?.type === 'CUSTOMER' ? (
                editMode ? (
                  <NameWrapper>
                    <NameInput
                      error={errors.first_name}
                      name="first_name"
                      placeholder={t('First name')}
                      type="text"
                      value={values.first_name}
                      onChange={handleChange}
                      data-test-id="customer_detail_prospect_first_name"
                    >
                      {t('First name')}
                    </NameInput>

                    <TextInput
                      error={errors.last_name}
                      name="last_name"
                      placeholder={t('Last name')}
                      type="text"
                      value={values.last_name}
                      onChange={handleChange}
                      data-test-id="customer_detail_prospect_last_name"
                    >
                      {t('Last name')}
                    </TextInput>
                  </NameWrapper>
                ) : (
                  <>
                    <Label>{t('Name')}</Label>
                    <StyledName>
                      {data?.first_name} {data?.last_name}
                    </StyledName>
                  </>
                )
              ) : editMode ? (
                <NameWrapper>
                  <NameInput
                    error={errors.name}
                    name="name"
                    placeholder={t('Company name')}
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_company_name"
                  >
                    {t('Company name')}
                  </NameInput>
                </NameWrapper>
              ) : (
                <>
                  <Label>{t('Company name')}</Label>
                  <StyledName>{data?.name}</StyledName>
                </>
              )}
            </TextWrapper>

            {editMode || isCustomerPage ? null : (
              <div>
                <ContactButton
                  onClick={e => phoneClickHandler(e, data?.telephonenr)}
                >
                  <StyledIconActionPhone size={30} />
                </ContactButton>
                <ContactButton onClick={e => emailClickHandler(e, data?.email)}>
                  <StyledIconActionMessage size={30} />
                </ContactButton>
              </div>
            )}

            {editMode || disableEdit ? null : (
              <StyledEditActionButton
                onClick={editClickHandler}
                data-test-id="customer_detail_prospect_edit"
              >
                {t('Edit')}
              </StyledEditActionButton>
            )}
          </SectionContentHeader>

          <SectionContentInner>
            <TextWrapper>
              {editMode ? (
                <TextInput
                  error={errors.email}
                  name="email"
                  placeholder={t('Email')}
                  type="email"
                  value={values.email}
                  onChange={handleChange}
                  data-test-id="customer_detail_prospect_email"
                >
                  {t('Email')}
                </TextInput>
              ) : (
                <>
                  <Label>Email</Label>
                  <Text>{data?.email ? data?.email : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <TextInput
                  error={errors.telephonenr}
                  name="telephonenr"
                  placeholder={t('Prospect.Phone number')}
                  type="telephonenr"
                  value={values.telephonenr}
                  onChange={handleChange}
                  data-test-id="customer_detail_prospect_phone"
                >
                  {t('Prospect.Phone number')}
                </TextInput>
              ) : (
                <>
                  <Label>{t('Prospect.Phone number')}</Label>
                  <Text>{data?.telephonenr ? data?.telephonenr : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <AddressWrapper>
                  <StreetInput
                    error={errors.street}
                    name="street"
                    placeholder={t('Street')}
                    type="street"
                    value={values.street}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_street"
                  >
                    {t('Street')}
                  </StreetInput>

                  <HouseNumberInput
                    error={errors.housenr}
                    name="housenr"
                    placeholder={t('House no')}
                    type="housenr"
                    value={values.housenr}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_housenr"
                  >
                    {t('House no')}
                  </HouseNumberInput>

                  <BoxNumberInput
                    error={errors.boxnr}
                    name="boxnr"
                    placeholder={t('Box no')}
                    type="boxnr"
                    value={values.boxnr}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_boxnr"
                  >
                    {t('Box no')}
                  </BoxNumberInput>

                  <ZipCodeInput
                    error={errors.zipcode}
                    name="zipcode"
                    placeholder={t('Zipcode')}
                    type="zipcode"
                    value={values.zipcode}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_zipcode"
                  >
                    {t('Zipcode')}
                  </ZipCodeInput>

                  <CityInput
                    error={errors.city}
                    name="city"
                    placeholder={t('City')}
                    type="city"
                    value={values.city}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_city"
                  >
                    {t('City')}
                  </CityInput>
                </AddressWrapper>
              ) : (
                <>
                  <Label>{t('Prospect.Address')}</Label>
                  <Text>{formatCRMAddress(data?.address)}</Text>
                </>
              )}
            </TextWrapper>

            {data?.type !== 'COMPANY' ? (
              <TextWrapper>
                {editMode ? (
                  <DateInput
                    error={errors.birth}
                    name="birth"
                    onChange={e =>
                      handleChange({
                        name: e.name,
                        value: parseIncDateToIsoFormat(e.value),
                      })
                    }
                    value={formatISODate(values.birth)}
                    data-test-id="customer_detail_prospect_birth"
                  >
                    {t('Birthdate')}
                  </DateInput>
                ) : (
                  <>
                    <Label>{t('Prospect.Date of birth')}</Label>
                    <Text>
                      {data?.birth ? formatISODate(data?.birth) : '-'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            ) : null}

            {isCustomerPage ? null : (
              <TextWrapper>
                {editMode ? (
                  <TextInput
                    error={errors.iban}
                    name="iban"
                    placeholder={t('Prospect.IBAN')}
                    type="text"
                    value={values.iban}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_iban"
                  >
                    {t('Prospect.IBAN')}
                  </TextInput>
                ) : (
                  <>
                    <Label>{t('Prospect.IBAN')}</Label>
                    <Text>{data?.iban ? data?.iban : '-'}</Text>
                  </>
                )}
              </TextWrapper>
            )}

            {data?.type !== 'COMPANY' ? (
              isCustomerPage ? null : (
                <TextWrapper>
                  {editMode ? (
                    <TextInput
                      error={errors.national_register_nr}
                      name="national_register_nr"
                      placeholder={t('Prospect.National registery no')}
                      type="text"
                      value={values.national_register_nr}
                      onChange={handleChange}
                      data-test-id="customer_detail_prospect_national_register_nr"
                    >
                      {t('Prospect.National registery no')}
                    </TextInput>
                  ) : (
                    <>
                      <Label>{t('Prospect.National registery no')}</Label>
                      <Text>
                        {data?.national_register_nr
                          ? data?.national_register_nr
                          : '-'}
                      </Text>
                    </>
                  )}
                </TextWrapper>
              )
            ) : (
              <TextWrapper>
                {editMode ? (
                  <TextInput
                    error={errors.cbe}
                    name="cbe"
                    placeholder={t('Prospect.CBE')}
                    type="text"
                    value={values.cbe}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_cbe"
                  >
                    {t('Prospect.CBE')}
                  </TextInput>
                ) : (
                  <>
                    <Label>{t('Prospect.CBE')}</Label>
                    <Text>{data?.cbe ? data?.cbe : '-'}</Text>
                  </>
                )}
              </TextWrapper>
            )}

            {data?.type !== 'CUSTOMER' && !isCustomerPage ? (
              <TextWrapper>
                {editMode ? (
                  <TextInput
                    error={errors.rsz_nr}
                    name="rsz_nr"
                    placeholder={t('Prospect.RSZ')}
                    type="text"
                    value={values.rsz_nr}
                    onChange={handleChange}
                    data-test-id="customer_detail_prospect_rsz_nr"
                  >
                    {t('Prospect.RSZ')}
                  </TextInput>
                ) : (
                  <>
                    <Label>{t('Prospect.RSZ')}</Label>
                    <Text>{data?.rsz_nr ? data?.rsz_nr : '-'}</Text>
                  </>
                )}
              </TextWrapper>
            ) : null}

            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.native_language}
                  initial={language}
                  name="native_language"
                  onSelected={handleLanguageChange}
                  options={languageOptions}
                  placeholder={t('Choose an option')}
                  data-test-id="customer_detail_prospect_language"
                  // value={}
                >
                  {t('Prospect.Native language')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Prospect.Native language')}</Label>
                  <Text>
                    {data?.native_language ? data?.native_language : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            {!isCustomerPage && data?.type !== 'COMPANY' ? (
              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.profession}
                    initial={profession}
                    name="profession"
                    onSelected={handleProfessionChange}
                    options={professionOptions}
                    placeholder={t('Choose an option')}
                    data-test-id="customer_detail_prospect_profession"
                    // value={}
                  >
                    {t('Prospect.Profession')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Prospect.Profession')}</Label>
                    <Text>{data?.profession ? t(data?.profession) : '-'}</Text>
                  </>
                )}
              </TextWrapper>
            ) : null}
          </SectionContentInner>

          {editMode ? (
            <SectionContentFooter>
              <ActionButton
                level="default"
                onClick={editClickHandler}
                data-test-id="customer_detail_prospect_btn_cancel"
              >
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton data-test-id="customer_detail_prospect_btn_save">
                {t('Save')}
              </ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

const StyledEditActionButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;

  @media screen and (max-width: 500px) {
    width: 4.5rem;
    height: 4.5rem;
    right: 1.8rem;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const NameInput = styled(TextInput)`
  margin-right: 20px;
`;

const StyledName = styled(Title)`
  text-transform: capitalize;
`;

const SectionContentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 24px;

  @media screen and (max-width: 500px) {
    padding: 12px 0;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;

  /* Fixes button placement and responsiveness */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 50%;
  }
`;

const SectionContentInner = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 15px;
  }

  @media screen and (max-width: 500px) {
    padding: 12px;

    > ${TextWrapper} {
      flex: 0 0 100%;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
`;

const ContactButton = styled.a`
  cursor: pointer;
  margin-left: 5px;
`;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
`;

const StreetInput = styled(TextInput)`
  flex: 0 0 60%;
  margin-right: 5%;
`;

const HouseNumberInput = styled(TextInput)`
  flex: 0 0 15%;
  margin-right: 5%;
`;

const BoxNumberInput = styled(TextInput)`
  flex: 0 0 15%;
`;

const ZipCodeInput = styled(TextInput)`
  flex: 0 0 47.5%;
  margin-right: 5%;
`;

const CityInput = styled(TextInput)`
  flex: 0 0 47.5%;
`;

const Spacer = styled.div`
  width: 15px;
`;

const StyledIconActionPhone = styled(IconActionPhone)`
  path {
    fill: ${({ theme }) => theme.brand.primary};
  }
`;

const StyledIconActionMessage = styled(IconActionMessage)`
  path {
    fill: ${({ theme }) => theme.brand.primary};
  }
`;

CRMProspectClientData.propTypes = {
  prospectData: object.isRequired,
  distributionId: propString,
  onSubmitFunction: func,
  isCustomerPage: bool,
  disableEdit: bool,
  customerPageFunction: func,
};

export default CRMProspectClientData;
