import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Layout from '../models/DashboardLayout/views/Layout';
// import { ProFlowStorageContextProvider } from '../services/context/ProFlowStorageContext.js';
import CustomerPage from './CustomerPage';

const CustomerRouter = () => {
  return (
    <StyledLayout showTop={false} hideSidebar hideMobileLayover>
      <Switch>
        <Route
          path={'/client-overview/:offerId/:revisionId'}
          component={CustomerPage}
        />
      </Switch>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

export default CustomerRouter;
