import ApiRoutes from '../../../../store/ApiRoutes';

const api = new ApiRoutes();

const answers = [''];
const data = {
  is_married: false,
  children: false,
  students: false,
  works_at_home: false,
  travels: false,
  shared_objects: false,
  living_less_than_one_year_together: false,
  previous_insurance_start_date: null,
  already_insured_with: null,
};

const dataById = {};

const addDataForIdFamily = id => {
  dataById[id] = JSON.parse(JSON.stringify(data));
};

const getAllAnswers = () => answers;

const getAnswer = key => data[key];

const setInsuredData = (key, value) => {
  const id = sessionStorage.getItem('id');
  if (value === false) {
    if (dataById[id].hasOwnProperty(key)) {
      delete dataById[id][key];
    }
    if (key === 'already_insured_with') {
      if (dataById[id].hasOwnProperty('previous_insurance_start_date')) {
        delete dataById[id].previous_insurance_start_date;
      }
    }
  } else if (key === 'previous_insurance_start_date' && value === null) {
    delete dataById[id].previous_insurance_start_date;
  } else {
    dataById[id][key] = value;
  }
};

const getInsuredData = () => {
  const id = sessionStorage.getItem('id');
  return {
    already_insured_with: dataById[id]?.already_insured_with,
    previous_insurance_start_date: dataById[id]?.previous_insurance_start_date,
  };
};

const updateTrait = (key, value) => {
  dataById[sessionStorage.getItem('id')][key] = value;
};

const addAnswer = (answer, key, status) => {
  dataById[sessionStorage.getItem('id')][key] = status;
  localStorage.setItem(
    'family',
    JSON.stringify(dataById[sessionStorage.getItem('id')]),
  );
  answers.push(answer);

  localStorage.setItem('familyAnswers', JSON.stringify(answers));
};

const getFamilyData = () => dataById[sessionStorage.getItem('id')];

const postFamilyData = () =>
  api.postFamilyData(JSON.stringify(dataById[sessionStorage.getItem('id')]));

const clearAnswer = from => {
  answers.splice(from, answers.length - 1);
};

export {
  getAllAnswers,
  addAnswer,
  updateTrait,
  clearAnswer,
  getAnswer,
  addDataForIdFamily,
  getInsuredData,
  getFamilyData,
  postFamilyData,
  setInsuredData,
};
