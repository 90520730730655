import React from 'react';
import { string } from 'prop-types';

const lane_support = ({ className, color }) => (
  <svg
    viewBox="0 0 400 306.09"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color}>
      <path d="m366.56 226.74h33.44v79.36h-33.44z" />
      <path d="m366.56 113.37h33.44v79.36h-33.44z" />
      <path d="m366.56 0h33.44v79.36h-33.44z" />
      <path d="m0 226.74h33.44v79.36h-33.44z" />
      <path d="m0 113.37h33.44v79.36h-33.44z" />
      <path d="m0 0h33.44v79.36h-33.44z" />
      <path d="m200 18.89a134.16 134.16 0 1 0 134.15 134.16 134.16 134.16 0 0 0 -134.15-134.16zm-18.88 229.9a8.18 8.18 0 0 1 -10.41 7.86 107.93 107.93 0 0 1 -68.6-58.82 8.17 8.17 0 0 1 7.4-11.62h35.9a35.68 35.68 0 0 1 35.71 35.69zm18.88-72.28a16.07 16.07 0 1 1 16.08-16.08 16.07 16.07 0 0 1 -16.08 16.08zm99 21.17a107.79 107.79 0 0 1 -72.12 59.87 8.16 8.16 0 0 1 -10-8v-27.65a35.7 35.7 0 0 1 35.71-35.69h39a8.15 8.15 0 0 1 7.41 11.47zm-2.17-47.9-49.1-19.78a127.46 127.46 0 0 0 -95.44 0l-49.09 19.78a8.17 8.17 0 0 1 -10.12-8.88 107.63 107.63 0 0 1 213.88 0 8.19 8.19 0 0 1 -10.14 8.88z" />
    </g>
  </svg>
);

lane_support.propTypes = {
  className: string,
  color: string,
};

export default lane_support;
