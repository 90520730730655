import React from 'react';
import styled from 'styled-components';
import { func, shape } from 'prop-types';
import { TextInput, MaskedInput } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';

import PhoneInput from './PhoneInputUseHook';
import FlowAddressContainer from './AddressInputContainer';

const CRMCustomerOverviewNewProspectModalCompany = ({
  errors,
  values,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <Form>
      <FlexWrapper>
        <TextInput
          name="companyName"
          error={errors?.companyName}
          onChange={val => handleChange(val)}
          value={values?.companyName}
          data-test-id="new_prospect_company_name"
        >
          <NameInput>{t('Company name')} *</NameInput>
        </TextInput>
        <MaskedInput
          error={errors.cbe}
          mask="9999.999.999"
          name="cbe"
          onChange={val => handleChange(val)}
          value={values.cbe}
          data-test-id="new_prospect_cbe"
        >
          {t('Company registration number')}
        </MaskedInput>
        <PhoneInput
          error={errors?.phone}
          value={values?.phone}
          name="phone"
          dataTestId="crm_add_prospect_telephone"
          handleChange={handleChange}
        >
          {t(`Telephone number`)}
        </PhoneInput>
        <TextInput
          name="email"
          error={errors?.email}
          onChange={val => handleChange(val)}
          value={values?.email}
          data-test-id="new_prospect_email"
        >
          <NameInput>{t('Email')}</NameInput>
        </TextInput>
      </FlexWrapper>
      <FlowAddressContainer
        title={t(`Address`)}
        errors={errors}
        values={values}
        handleChange={val => {
          return handleChange(val);
        }}
        data-test-id="new_prospect_address"
      />
    </Form>
  );
};

const Form = styled.form`
  flex-shrink: 0;
`;

const NameInput = styled.p`
  height: 1rem;
`;

const FlexWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
`;

CRMCustomerOverviewNewProspectModalCompany.propTypes = {
  errors: shape(),
  values: shape(),
  handleChange: func.isRequired,
};

export default CRMCustomerOverviewNewProspectModalCompany;
