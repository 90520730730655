/* stylelint-disable value-keyword-case */
import React, { useState } from 'react';
import styled from 'styled-components';
import { string, bool, func, object } from 'prop-types';
import { getNestedObject } from '../../../services/objectService';
import BrokerInfoPopup from './BrokerInfoPopup';

const BrokerLogoClickable = ({ data, logo }) => {
  const [showInfoPopup, setShowInfoPopup] = useState();
  return (
    <>
      {showInfoPopup ? (
        <BrokerInfoPopup
          data={data}
          show
          onClose={() => setShowInfoPopup(false)}
        />
      ) : null}
      <Logo
        onClick={() => setShowInfoPopup(true)}
        src={logo}
        alt={getNestedObject(data, ['distribution', 'name'])}
      />
    </>
  );
};

const Logo = styled.img`
  max-height: 7rem;
  height: 5rem;
  cursor: pointer;

  @media screen and (min-width: 1200px) {
    height: 7rem;
  }
`;

BrokerLogoClickable.defaultProps = {
  data: {},
  onClose: () => {},
  show: false,
};

BrokerLogoClickable.propTypes = {
  data: object,
  show: bool,
  className: string,
  onClose: func,
  logo: string,
};

export default BrokerLogoClickable;
