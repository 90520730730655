import React from 'react';
import { string } from 'prop-types';

const Garden = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 276.53 280.04">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M212.08,247.2a53.06,53.06,0,0,0-37.74,16.31,69,69,0,0,0-32.13-42.84V188.54l58.72-58.72h50.82A44.73,44.73,0,0,0,239.64,99.3h36.89a44.92,44.92,0,0,0-43.75-44.86,42.23,42.23,0,0,0-44.41-22.06,63.09,63.09,0,0,0-109.91-.56h0a44.82,44.82,0,0,0-40,24.76A44.84,44.84,0,0,0,6.89,99.3H76.06l48.26,48.26v66a67.75,67.75,0,0,0-16.53-2.26,68.49,68.49,0,0,0-60.14,36.05C26.45,247.89,7.81,261.56,0,280H176.57l84.25,0A52.59,52.59,0,0,0,212.08,247.2ZM142.21,99.3h31.66a44.68,44.68,0,0,0-12.11,30.52h13l-32.57,32.56Zm-40,0h22.11v22.11Z"
        />
      </g>
    </g>
  </svg>
);

Garden.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Garden.propTypes = {
  title: string,
  color: string,
};

export default Garden;
