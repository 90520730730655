import React from 'react';

const Theft = () => (
  <svg viewBox="0 0 185.95 227.52">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'theft'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M64.91 124.37a.41.41 0 00-.4-.3.39.39 0 00-.26.06.47.47 0 00-.18.19c-1.27 2.73-2.46 5.55-2.59 5.84-4.25 10-3.92 8.77-8.07 19-.37 1-.79 1.92-1.21 2.9a17.83 17.83 0 01-3.12-1.12c-10.22-4.16-20.21-8.35-30.42-12.51-3.88-1.62-7.7-1.95-11.34.33C2 142 0 147 0 152.92c.09 5.56 3.64 9 8.5 10.47 16.65 6.9 33.07 13.86 49.72 20.72 6.44 2.73 9.79 1.09 12.31-5.11 4.39-10.31 8.77-21.14 13.3-31.77l-6.35-5.18a40.78 40.78 0 01-12.57-17.68zM150.22 48.8a24.4 24.4 0 00.51-48.8C136.88 0 126 10.59 126 24.45a24.15 24.15 0 0024.22 24.35z"
        />
        <path
          className="prefix__cls-1"
          d="M173.8 82.31a9 9 0 00.57-2.29c-.13-6.28-3.52-10.47-8.58-13.46-4.44-2.49-8.73-1.46-12.31 1.35L151 64.68a16.05 16.05 0 00-5.06-5.06c-9.05-6-17.78-12.3-27-17.92l-3.48-2.23C90.09 23 47.84 23.05 27.25 48.29c-31.78 38.95 1.85 77.95 43.66 61.55 4.8-2.65 10.35-8.41 16.23-14.68 14.4-15.34 34.07-36.27 58.07-19.69l-7.12 5.41c-15.23-9-28.38 2.47-44.83 20-7.29 7.77-13.7 14.37-20.38 17.26 1.57 9.58 9.31 16.31 10.39 17.12l10.31 8.36c8.41 4.89 16.75 9.75 25.06 14.41 1.49 1 1.39 2.13 1.39 3.55q-.12 26.46-.23 52.9a13.43 13.43 0 00.15 3.69c1.39 6.11 6.24 9.52 13.89 9.32 7.88-.27 12.83-5.22 12.88-13-.09-20.72 0-41.57.09-62.43-.09-4.8-1.78-8.34-6-10.91-8.68-5-17.37-10.59-25.9-15.9a9.79 9.79 0 01-2.51-1.64l12-18.42a11.53 11.53 0 00.54 1.12A13.51 13.51 0 00144 109c7.28-5.53 15.52-11.76 21.53-16.37l8.35 14.09L186 80.07z"
        />
      </g>
    </g>
  </svg>
);

export default Theft;
