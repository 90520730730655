import React from 'react';
import { string } from 'prop-types';

const Legal = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.67 239.04">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M263.66,99.63a4.9,4.9,0,0,0-.75-2.62L216.83,24.46a4.9,4.9,0,0,0-4.14-2.28,5,5,0,0,0-.94.11H136a.06.06,0,0,1-.05-.06V5.86A5.86,5.86,0,0,0,130.08,0h0a5.86,5.86,0,0,0-5.86,5.86V22.23a.06.06,0,0,1-.06.06H51.92a5,5,0,0,0-.94-.11,4.9,4.9,0,0,0-4.14,2.28L.76,97A4.9,4.9,0,0,0,0,99.63H0v.15a51,51,0,0,0,102,0v-.15h0a4.82,4.82,0,0,0-.75-2.62l-40-62.91a.06.06,0,0,1,.05-.09h62.88a.06.06,0,0,1,.06.06V228.23a6.06,6.06,0,0,0,5.33,6.14,5.86,5.86,0,0,0,6.39-5.83V34.07s0-.06.05-.06h66.4a.06.06,0,0,1,.05.09L162.48,97a4.82,4.82,0,0,0-.75,2.62h0v.15a51,51,0,0,0,102,0v-.15Zm-172.53,0H10.82a.06.06,0,0,1-.05-.09L50.93,36.31a.07.07,0,0,1,.1,0L91.18,99.54A.06.06,0,0,1,91.13,99.63Zm81.35-.09,40.16-63.23a.07.07,0,0,1,.1,0L252.9,99.54a.06.06,0,0,1-.05.09H172.53A.06.06,0,0,1,172.48,99.54Z"
        />
        <rect fill={color} x="65.04" y="215.6" width="133.59" height="23.44" />
      </g>
    </g>
  </svg>
);

Legal.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Legal.propTypes = {
  title: string,
  color: string,
};

export default Legal;
