import React from 'react';

const GlassBreakage = () => (
  <svg viewBox="0 0 261.86 119.79">
    <title>{'glass_breakage'}</title>
    <path
      d="M259.55 50.26c-16.74-18-87-16.61-87-16.61L158.37 9.17a132.58 132.58 0 00-21.23-5.36l-5.52 10.51 8.09 17.12-24.26 34.24 8.08-30.82-10.78-17.12L111.06.63C78.72-1.47 44 1.86 26 8.29 22.42 9.56 0 36.81 0 47.15l2.11 38.12a9.17 9.17 0 008.82 6.67l19.31.92.62 5.5a26.92 26.92 0 0052.71 0l.42-5.5h97.44l.93 5.5a26.92 26.92 0 0052.71 0l1-5.5 11.77-.92a9.17 9.17 0 008.82-6.67l4.79-16.9c.9-3.06.27-15.77-1.9-18.11zM73.8 98.36a17.38 17.38 0 01-33.17 0 17.11 17.11 0 01-1.12-5.5 17.7 17.7 0 0135.4 0 17.37 17.37 0 01-1.11 5.5zm151.5 0a17.71 17.71 0 01-34.3-5.5 17.7 17.7 0 0135.4 0 17.11 17.11 0 01-1.1 5.5z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default GlassBreakage;
