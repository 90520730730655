import React, { useState } from 'react';
import {
  ActionButton,
  Body,
  DataBlock,
  H4,
  IconPencilFilled,
  LinkBox,
  RoundedButton,
  Modal,
  ButtonGroup,
} from 'wg-fe-ui';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CampaignUseInstructions from './CampaignUseInstructions';
import { useCampaignById, useCampaignStore } from '../context/CampaignContext';
import { format } from 'date-fns';
import { isEmpty, upperFirst } from 'lodash';
import {
  disableCampaign,
  loadBackendCampaignFormData,
  useCampaignCreationDispatch,
} from '../context/CampaignCreationContext';

const CampaignsAccordionContent = ({ id, setAddNewCampaignModalOpen }) => {
  const { t } = useTranslation();
  const campaignData = useCampaignById(id);
  const dispatch = useCampaignCreationDispatch();
  const {
    name,
    insurances,
    type,
    theme,
    logo,
    url,
    created_at: createdAt,
    created_by: createdBy,
    end_date: endDate,
    send_email_if_lead_created: leadEmails,
    send_email_if_offer_created: offerEmails,
    send_email_if_prospect_created: prospectEmails,
  } = campaignData;
  const { campaigns } = useCampaignStore();
  const [campaignInstructions, setCampaignInstructions] = useState();
  const [openInsuranceCompanies, setOpenInsuranceCompanies] = useState(false);

  function showInsuranceCompanies(_insurances, affiliations) {
    const uniqueInsurances = [
      ...new Set(insurances.map(({ insurance_company }) => insurance_company)),
    ];

    return affiliations
      .map(({ affiliation_info }) => affiliation_info)
      .filter(({ name }) => uniqueInsurances.includes(name));
  }

  function findMaxVk(insurancesData = []) {
    return (
      insurancesData?.reduce(
        (prev, current) =>
          prev?.vk_applied > current?.vk_applied ? prev : current,
        0,
      )?.vk_applied || 0
    );
  }

  function shouldReceiveAnEmail(leadList, offerList, prospectList) {
    if (isEmpty(leadList) && isEmpty(offerList) && isEmpty(prospectList)) {
      return t('No');
    } else {
      return t('Yes');
    }
  }

  const themeNames = {
    orange: 'orange',
    light_blue: 'light blue',
    ocean_blue: 'ocean blue',
    dark_blue: 'dark blue',
    light_red: 'light red',
    dark_red: 'dark red',
    pink_purple: 'pink purple',
    dark_green: 'dark green',
    light_green: 'light green',
    ocean_green: 'ocean green',
    dark_grey: 'dark grey',
  };

  return (
    <OpenAccordionContent>
      <Left>
        <DataBlock>
          <Body light smaller>
            {t('Created on')}
          </Body>
          <p>{format(new Date(createdAt), 'dd/MM/yyyy - HH:mm')}</p>
        </DataBlock>
        <DataBlock>
          <Body light smaller>
            {t('Created by')}
          </Body>
          <p>
            {
              campaigns.brokers.items.filter(
                ({ broker }) => broker.id === createdBy,
              )?.[0]?.broker?.name
            }
          </p>
        </DataBlock>
        <DataBlock>
          <Body light smaller>
            {t('Campaign type')}
          </Body>
          <p>{upperFirst(type.toLowerCase())}</p>
        </DataBlock>
        <DataBlock>
          <Body light smaller>
            {t('Receive email notifications?')}
          </Body>
          <p>{shouldReceiveAnEmail(leadEmails, offerEmails, prospectEmails)}</p>
        </DataBlock>
        <DataBlock>
          <Body light smaller>
            {t('How much VK is applied?')}
          </Body>
          <p>{findMaxVk(insurances) * 100}%</p>
        </DataBlock>
        <ActionButton
          onClick={() => {
            loadBackendCampaignFormData(campaignData, dispatch);
            setAddNewCampaignModalOpen(true);
          }}
        >
          <IconPencilFilled />
          {t('Edit campaign')}
        </ActionButton>
      </Left>
      <Right>
        <CampaignProperties>
          <H4>{t('Properties of the campaign')}</H4>
          <DataBlock>
            <Body light smaller>
              {t('Theme color')}
            </Body>
            <ColorTheme themeColor={theme.primary_color}>
              <RoundedButton />
              {upperFirst(themeNames[theme?.name])}
            </ColorTheme>
          </DataBlock>
          <DataBlock>
            <Body light smaller>
              {t('Logo')}
            </Body>
            <LogoUrlContainer>
              <IconPencilFilled />{' '}
              <a
                href={logo || campaigns?.distribution?.logos?.logo}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('Your campaign logo')}
              </a>
            </LogoUrlContainer>
          </DataBlock>
          <CampaignUrlContainer>
            <Body light smaller>
              {t('Campaign URL')}
            </Body>
            <LinkBox url={url} onClick={() => setCampaignInstructions(true)}>
              <Body light tiny>
                {t('Find out how to implement your campaign here')}
              </Body>
            </LinkBox>
            <Modal
              large
              title={t('How can I use my campaigns?')}
              canClose
              setShowModal={() =>
                setCampaignInstructions(!campaignInstructions)
              }
              showModal={campaignInstructions}
            >
              <CampaignUseInstructions id={id} />
            </Modal>
          </CampaignUrlContainer>
          {type === 'EMAIL' ? (
            <DataBlock>
              <Body light smaller>
                {t('End date of your campaign')}
              </Body>
              <p>{format(new Date(endDate), 'dd/MM/yyyy')}</p>
            </DataBlock>
          ) : (
            <LinkBox url="your-external-url.be" />
          )}
        </CampaignProperties>
        <Extras>
          <div>
            <H4>{t('Included insurance companies')}</H4>
            <InsuranceCompaniesContainer>
              {showInsuranceCompanies(insurances, campaigns.affiliations)
                ?.slice(0, 3)
                .map(insurance => {
                  return (
                    <StyledDiv
                      key={insurance.name}
                      clickable
                      onClick={() => setOpenInsuranceCompanies(true)}
                    >
                      <SubDiv>
                        <Image src={insurance?.logo} alt={insurance?.name} />
                      </SubDiv>
                    </StyledDiv>
                  );
                })}
              {showInsuranceCompanies(insurances, campaigns.affiliations)
                .length > 3 && (
                <OtherInsuranceCard
                  onClick={() => setOpenInsuranceCompanies(true)}
                >
                  <p>
                    +
                    {
                      showInsuranceCompanies(
                        insurances,
                        campaigns.affiliations,
                      ).slice(
                        3,
                        showInsuranceCompanies(
                          insurances,
                          campaigns.affiliations,
                        ).length,
                      )?.length
                    }
                  </p>
                  <span>{t('others')}</span>
                </OtherInsuranceCard>
              )}
              <Modal
                large
                canClose
                title={t('Insurance companies for ') + name}
                setShowModal={() =>
                  setOpenInsuranceCompanies(!openInsuranceCompanies)
                }
                showModal={openInsuranceCompanies}
              >
                <Container>
                  {showInsuranceCompanies(
                    insurances,
                    campaigns.affiliations,
                  )?.map(insurance => {
                    return (
                      <StyledDiv className="modal" key={insurance?.name}>
                        <SubDiv>
                          <Image src={insurance?.logo} alt={insurance?.name} />
                        </SubDiv>
                      </StyledDiv>
                    );
                  })}
                </Container>
              </Modal>
            </InsuranceCompaniesContainer>
          </div>
          <ButtonGroup className="buttonGroup">
            {type === 'WEBSITE' && (
              <ActionButton
                level="white"
                onClick={() => window.open(`https://${url}`, '_blank')}
              >
                {t('Visit external URL')}
              </ActionButton>
            )}
            <ActionButton
              level="white"
              onClick={() => window.open(`https://${url}`, '_blank')}
            >
              {t('Visit campaign URL')}
            </ActionButton>
            <ActionButton
              level="white"
              onClick={() =>
                disableCampaign(id, campaigns.distribution.id, dispatch)
              }
              className="disableBtn"
            >
              {t('Disable campaign')}
            </ActionButton>
          </ButtonGroup>
        </Extras>
      </Right>
    </OpenAccordionContent>
  );
};

const StyledDiv = styled.div`
  background-color: white;
  border: 1px solid #f0f1f3;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  ${props => props.clickable && 'cursor: pointer'};
  display: flex;
  flex-direction: column;
  height: 7rem;
  justify-content: space-between;
  opacity: 1;
  width: 7rem;
  &.modal {
    height: 13rem;
    width: 13rem;
  }
`;

const SubDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Image = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 90%;
  margin: auto;
  margin-left: auto;
  width: 75%;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  justify-content: center;
  justify-items: center;
`;

const InsuranceCompaniesContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-row-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
  margin-top: 1.5rem;
`;

const OtherInsuranceCard = styled.div`
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  align-items: center;
  background: white;
  width: 7rem;
  height: 7rem;
  border: 1.5px solid #f0f1f3;
  color: #8990a3;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-weight: bold;
    font-size: 2rem;
  }
  :hover {
    border: 1px solid #ff8000;
  }
`;

const LogoUrlContainer = styled.p`
  display: flex;
  align-items: center;
  svg {
    height: 2rem;
    width: 2rem;
    margin-right: 1rem;
    path {
      fill: #d5d5d5;
    }
  }
`;

const ColorTheme = styled.p`
  display: flex;
  align-items: center;
  > div {
    background: #${({ themeColor }) => themeColor};
    height: 1.2rem;
    margin-right: 1rem;
    width: 1.2rem;
  }
`;

const Extras = styled.div`
  padding: 3rem 3rem 3rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* stylelint-disable-next-line */
  button.disableBtn {
    color: #eb4d4b;
    &:hover {
      color: #ff8000;
    }
  }
  .buttonGroup {
    margin-top: 2rem;
  }
`;

const Left = styled.div`
  padding: 3rem;
  border-right: 1px solid #ddd;
  button {
    color: white;
    margin-top: 5rem;
    position: relative;
    width: 100%;
    svg {
      position: absolute;
      left: 1rem;
      path {
        fill: white;
      }
      @media screen and (max-width: 950px) {
        display: none;
      }
    }
  }
  div {
    height: fit-content;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
`;

const CampaignProperties = styled.div`
  padding: 3rem;
  > div {
    height: 4rem;
    margin-bottom: 1rem;
    justify-content: space-between;
  }
  h4 {
    margin-bottom: 1rem;
  }
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: 56% 44%;
`;

const CampaignUrlContainer = styled.div`
  /* stylelint-disable-next-line */
  height: fit-content !important;
  > div {
    margin: 1rem 0 2rem;
  }
`;

const OpenAccordionContent = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
`;

CampaignsAccordionContent.propTypes = {
  id: string.isRequired,
  setAddNewCampaignModalOpen: func.isRequired,
};

export default CampaignsAccordionContent;
