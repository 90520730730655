import React from 'react';
import styled from 'styled-components';
import { string, node } from 'prop-types';
// import PersonalData from '../store';
import { Field, ErrorMessage } from 'formik';

const PersonalInput = ({ dataTestId, type, name, children }) => (
  <>
    <StyledLabel htmlFor={name}>
      <StandardInput
        data-test-id={dataTestId}
        id={name}
        type={type}
        name={name}
        placeholder="&nbsp;"
      />
      <span>
        {children}{' '}
        <ErrorMss
          data-test-id={`${dataTestId}Error`}
          name={name}
          component="p"
        />
      </span>
      <Line />
    </StyledLabel>
  </>
);

const StyledLabel = styled.label`
  position: relative;
  width: 80%;
  line-height: 2.1rem;
  font-size: 1.5rem;
  margin-bottom: 3rem;

  & > span {
    display: flex;
    align-items: baseline;
    position: absolute;
    left: 0;
    top: -0.5rem;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }
`;

const StandardInput = styled(Field)`
  width: 100%;
  border: 0;
  line-height: 2.1rem;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.15s ease;

  &:not(:placeholder-shown) + span {
    color: #525252;
    transform: translateY(-18px) scale(0.9);
  }

  &:not(:-ms-input-placeholder) + span {
    color: #525252;
    transform: translateY(-18px) scale(0.9);
  }

  &:focus {
    outline: none;

    & + span {
      color: ${({ theme }) => theme.brand.primary};
      transform: translateY(-18px) scale(0.9);
    }
  }
`;

const Line = styled.div`
  height: 0.2rem;
  width: 100%;
  background-color: #bababa;
  border-radius: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 5%;
`;

const ErrorMss = styled(ErrorMessage)`
  font-size: 1.6rem;
  color: red;
  margin-left: 0.6rem;
`;

PersonalInput.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  dataTestId: string,
  children: node.isRequired,
  id: string,
};

export default PersonalInput;
