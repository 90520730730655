import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Section } from 'wg-fe-ui';

// images
import InteractiveMap from '../../../components/ToolkitAddressLookupInteractivemap';
// import gardenIcon from '../../../../assets/images/garden-icon';
// import solarIcon from '../../../../assets/images/solar-icon';
// import poolIcon from '../../../../assets/images/pool-icon';
// import shedIcon from '../../../../assets/images/shed-icon';
// import verandaIcon from '../../../../assets/images/veranda-icon';

const AddressDataTable = ({ addressData }) => {
  const { t } = useTranslation();
  // const content = t('ToolkitInfo.address.addressInfo', {
  //   returnObjects: true,
  // });
  const basicAddress = addressData.address;
  const parcel = addressData.parcel;
  const roomsData = parcel.main_building.room_estimation;

  const propertyTypes = {
    no_hazard: 'No hazard',
    low_hazard: 'Low hazard',
    high_hazard: 'High hazard',
  };

  const floodZoneHazard = propertyTypes[parcel.flood_zone_hazard];

  const buildingTypes = {
    open: 'Detached',
    closed: 'Terraced',
    halfopen: 'Semi-detached',
    apartment: 'Apartment',
  };

  const buildingIsOfType = buildingTypes[parcel.main_building.house_type];

  return (
    <CarDataWrapper>
      <Block>
        <LeftBlock>
          <InteractiveMap manual={false} />
          {basicAddress ? (
            <StreetName>
              <span>{t(`AdresCheck.address`)}</span>
              <h2>
                {basicAddress.street}
                {' '}
                {basicAddress.housenr}
                {basicAddress.boxnr ? `/${basicAddress.boxnr}` : ``} {', '}
                {basicAddress.zipcode} {' '}
                {basicAddress.city}
              </h2>
            </StreetName>
          ) : null}
        </LeftBlock>
        <RightBlock>
          {parcel ? (
            <Section className="parcel section">
              <Section.Title>{t(`Parcel`)}</Section.Title>
              <ParcelContent>
                <div>
                  <Label>{t(`Surface area`)}</Label>
                  <Value>{t(parcel.surface)} m²</Value>
                </div>
                <div>
                  <Label>{t(`Flood zone hazard`)}</Label>
                  <Value>{t(floodZoneHazard)}</Value>
                </div>
                <div>
                  <Label>{t(`Neighborhood density`)}</Label>
                  <Value>
                    {parcel.neighborhood_density} {t(`properties`)} 100m/rad
                  </Value>
                </div>
                <div>
                  <Label>{t(`Garden`)}</Label>
                  <Value>
                    {parcel.has_garden == true
                      ? t(`Present`)
                      : t(`Not present`)}
                  </Value>
                </div>
              </ParcelContent>
            </Section>
          ) : null}
          <Section className="main section">
            <Section.Title>{t(`Main building`)}</Section.Title>
            <ParcelContent>
              {parcel.main_building !== null ? (
                <>
                  <div>
                    <Label>{t(`Building type`)}</Label>
                    <Value>
                      {buildingIsOfType !== null ? t(buildingIsOfType) : '--'}
                    </Value>
                  </div>
                  <div>
                    <Label>{t(`Total surface area`)}</Label>
                    <Value>
                      {parcel.main_building.surface !== null
                        ? parcel.main_building.surface
                        : '--'}{' '}
                      m²
                    </Value>
                  </div>
                  <div>
                    <Label>{t(`Volume`)}</Label>
                    <Value>
                      {parcel.main_building.volume !== null
                        ? parcel.main_building.volume + ' m³'
                        : '--'}
                    </Value>
                  </div>
                  <div>
                    <Label>{t(`Reconstruction value`)}</Label>
                    <Value>
                      {parcel.main_building.reconstruction_value !== null
                        ? '€ ' +
                          parcel.main_building.reconstruction_value.toFixed(2)
                        : '--'}
                    </Value>
                  </div>
                </>
              ) : (
                <Value>{t(`No calculations available`)}</Value>
              )}
            </ParcelContent>
          </Section>
          <Section className="room section">
            <Section.Title>{t(`Room estimations`)}</Section.Title>
            <RoomEstimations>
              {roomsData !== null ? (
                Object.entries(roomsData).map((key, index) => {
                  const roomTypes = {
                    living_rooms: 'Living rooms',
                    kitchens: 'Kitchens',
                    bathrooms: 'Bathrooms',
                    garages: 'Garages',
                    other_rooms: 'Other rooms',
                    bedrooms: 'Bedrooms',
                  };
                  const roomTypeValue = roomTypes[key[0]];
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <div key={index}>
                      <Label>{t(roomTypeValue)}</Label>
                      <Value>{key[1]}</Value>
                    </div>
                  );
                })
              ) : (
                <Value>{t(`No calculations available`)}</Value>
              )}
            </RoomEstimations>
          </Section>
        </RightBlock>
      </Block>
    </CarDataWrapper>
  );
};

const RoomEstimations = styled(Section.Content)`
  display: grid;
  grid-template-columns: 25% 25% 25%;
  grid-template-rows: 60% 60%;
`;

const ParcelContent = styled(Section.Content)`
  display: grid;
  margin-top: 1rem;
  grid-template-rows: 35% 35% 35% 35%;
`;

const Label = styled.p`
  font-weight: normal;
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.font};
  height: 2.5rem;
  color: #a29c95;
  margin-right: 2rem;
`;

const Value = styled.p`
  font-size: 1.5rem;
`;

const LeftBlock = styled.div`
  width: 50%;
`;
const RightBlock = styled.div`
  .parcel {
    grid-area: parcel;
  }
  .main {
    grid-area: main;
    &:hover {
      cursor: pointer;
    }
  }
  .room {
    grid-area: room;
  }
  .section {
    margin-top: 4rem;
  }
  width: 50%;
  word-break: break-word;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 55% 35%;
  grid-template-areas:
    'parcel main'
    'room room';
  padding-left: 8%;
`;

const CarDataWrapper = styled.div``;

const Block = styled.div`
  display: flex;
  overflow: hidden;
`;
// const RightBlock = styled(RightSide)`
//   background-color: white;
// `;

const StreetName = styled.div`
  background: white;
  /* box-shadow: 0 0.4rem 1.3rem ; */
  border-radius: 0.8rem;
  padding: 1.24rem 1.8rem 1.75rem 1.8rem;
  /* width: 85%; */
  line-height: 1.6rem;
  text-transform: capitalize;
  width: 40%;
  z-index: 2;
  bottom: 5%;
  left: 5%;
  position: absolute;

  & span {
    font-size: 1.2rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.typo.interactive};
  }

  & h2 {
    color: #505050;
    font-weight: 900;
    font-size: 1.6rem;
  }
`;

AddressDataTable.propTypes = {
  addressData: shape().isRequired,
};

export default AddressDataTable;
