import React from 'react';
import { string } from 'prop-types';

const AssistanceVehicle = ({ title, color }) => (
  <svg viewBox="0 0 50 50">
    <title>{title}</title>
    <g id="prefix__icons">
      <path
        fill={color}
        d="M35.47 42.62l-8.59-7.78a.76.76 0 00-1 0l-3.53 2.72a2.62 2.62 0 01-3.62-.39L16.12 34A31.51 31.51 0 019.8 21.68l-1-4a2.55 2.55 0 011.83-3.1l4.32-1.19a.73.73 0 00.54-.79L14.18 1.21a.72.72 0 00-.59-.65A22.42 22.42 0 008.74 0a13.35 13.35 0 00-4 2C2.84 3.33-.51 7.67.07 16c.51 7.4 4.79 15.89 4.79 15.89S9.27 40.29 15 45.08c6.46 5.38 12 5.29 14.16 4.57a13.47 13.47 0 004-2 21.34 21.34 0 002.43-4.16.73.73 0 00-.12-.87z"
      />
      <path
        fill={color}
        d="M33.5 17a16.52 16.52 0 00-6.8 1.46c-.47.22-.93.45-1.37.7a16.42 16.42 0 00-5.93 5.77 16.45 16.45 0 00-2.11 5.47v.16c.38.51.76 1 1.17 1.5l2.4 2.88 3.24-2.51a3.8 3.8 0 012.3-.78 3.73 3.73 0 012.52 1L31 34.49v-.09a2.54 2.54 0 112.69 2.52l3.2 2.9a3.24 3.24 0 012.76-1.55h4.53a11.68 11.68 0 01-5.52 5.64 3.63 3.63 0 01-.29.84c-1.84 3.81-2.71 4.67-3 5l-.15.13A16.5 16.5 0 0033.5 17zM45 32.23L39.39 30a15.66 15.66 0 00-11.78 0L22 32.23a11.58 11.58 0 0123 0s0-.02 0 0z"
      />
      <g className="prefix__cls-2">
        <path className="prefix__cls-3" fill={color} d="M.05 50" />
      </g>
      <g className="prefix__cls-2">
        <path className="prefix__cls-3" fill={color} d="M35.83 36.65" />
      </g>
    </g>
  </svg>
);

AssistanceVehicle.defaultProps = {
  title: '',
  color: '#ffffff',
};

AssistanceVehicle.propTypes = {
  title: string,
  color: string,
};

export default AssistanceVehicle;
