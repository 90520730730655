import axios from 'axios';
import {
  getUserId,
  showErrorPopup,
  generateId,
  auth,
  getSocialLogin,
} from './index';
import i18next from 'i18next';
import { requestInterceptor } from '../services/apiMiddlewareService';
import { setMeData } from '../services/meDataService';

// ===================================== \\
//         API's INITIALIZATION          \\
// ===================================== \\

const headers = {
  'Content-Type': 'application/vnd.api+json',
  Accept: 'application/vnd.api+json',
};

const brokerApi = axios.create({
  timeout: 10000,
  withCredentials: true,
  data: {},
  headers,
});

const api = axios.create({
  withCredentials: true,
  timeout: 60000,
  headers,
});

const portalApi = axios.create({
  withCredentials: true,
  headers,
  timeout: 40000,
});

const docgenApi = axios.create({
  withCredentials: true,
  headers,
  timeout: 40000,
});

const authApi = axios.create({
  timeout: 10000,
  withCredentials: true,
  data: {},
  headers,
});

const mailApi = axios.create({
  timeout: 60000,
  withCredentials: true,
  data: {},
  headers,
});

const addressApi = axios.create({
  timeout: 10000,
  withCredentials: true,
  data: {},
  headers,
});

const carApi = axios.create({
  timeout: 10000,
  withCredentials: true,
  data: {},
  headers,
});

const bPostApi = axios.create({
  baseURL: `https://webservices-pub.bpost.be/ws/ExternalMailingAddressProofingCSREST_v1/address`,
  timeout: 10000,
});

requestInterceptor({
  axiosConfig: brokerApi,
  baseVersion: 'v1',
  apiPath: 'broker',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: api,
  baseVersion: 'v1',
  apiPath: 'marketplace',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: portalApi,
  baseVersion: 'v1',
  apiPath: 'portal',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: docgenApi,
  baseVersion: 'v1',
  apiPath: 'docgen',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: authApi,
  baseVersion: 'v1',
  apiPath: 'aut',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: mailApi,
  baseVersion: 'v1',
  apiPath: 'mailer',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: addressApi,
  baseVersion: 'v1',
  apiPath: 'address',
  apiVersion: 'v1',
});

requestInterceptor({
  axiosConfig: carApi,
  baseVersion: 'v1',
  apiPath: 'car',
  apiVersion: 'v1',
});

// ===================================== \\
//        REFRESH_TOKEN MIDDLEWARE       \\
// ===================================== \\

axios.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;

    if (
      error.response.status === 403 &&
      error.response.data.msg === 'PERMISSION_DENIED'
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return axios(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        auth.isAuthenticated = false;
        window.location.href = '/login';
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }

    return Promise.reject(error);
  },
);

authApi.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;

    if (
      error.response.status === 403 &&
      error.response.data.msg === 'PERMISSION_DENIED'
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      error.response.data.msg === 'AUTHENTICATION_EXPIRED'
    ) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return authApi(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        auth.isAuthenticated = false;
        window.location.href = '/login';
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }

    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;

    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      getSocialLogin()
    ) {
      auth.isAuthenticated = false;
      if (sessionStorage.getItem('broker_id')) {
        if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
          window.location.href = `/broker/${sessionStorage.getItem(
            'broker_id',
          )}?popup=expired`;
        } else {
          window.location.href = `/broker/${sessionStorage.getItem(
            'broker_id',
          )}?popup=error`;
        }
      } else {
        window.location.href = `/login`;
      }
      return;
    }

    //request new id if checkId fails and if not a session route

    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      !window.location.href.includes('session')
    ) {
      return generateId().then(res => {
        // replace original id with new id

        originalRequest.url = originalRequest.url.replace(
          originalRequest.url.split('api/check/id/')[1],
          res.id,
        );

        return api(originalRequest);
      });
    }

    if (
      error.response.status === 403 &&
      (error.response.data.msg === 'PERMISSION_DENIED' ||
        error.response.data.msg === 'INVALID_TOKEN')
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return api(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        if (
          error.response.data.msg === 'AUTHENTICATION_FAILED' ||
          error.response.data.msg === 'NOT_AUTHENTICATED' ||
          error.response.data.msg === 'ENVIRONMENT_MISMATCH' ||
          error.response.data.msg === 'INVALID_TOKEN'
        ) {
          auth.isAuthenticated = false;
          window.location.href = '/login';
        }
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }
    return Promise.reject(error);
  },
);

brokerApi.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;
    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      getSocialLogin()
    ) {
      auth.isAuthenticated = false;
      if (sessionStorage.getItem('broker_id')) {
        window.location.href = `/broker/${sessionStorage.getItem(
          'broker_id',
        )}?popup=error`;
      } else {
        window.location.href = `/login`;
      }
      return;
    }
    //request new id if checkId fails and if not a session route
    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      !window.location.href.includes('session')
    ) {
      return generateId().then(res => {
        // replace original id with new id
        originalRequest.url = originalRequest.url.replace(
          originalRequest.url.split('api/check/id/')[1],
          res.id,
        );

        return brokerApi(originalRequest);
      });
    }

    if (
      error.response.status === 403 &&
      (error.response.data.msg === 'PERMISSION_DENIED' ||
        error.response.data.msg === 'INVALID_TOKEN')
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return brokerApi(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        if (
          error.response.data.msg === 'AUTHENTICATION_FAILED' ||
          error.response.data.msg === 'NOT_AUTHENTICATED' ||
          error.response.data.msg === 'ENVIRONMENT_MISMATCH' ||
          error.response.data.msg === 'INVALID_TOKEN'
        ) {
          auth.isAuthenticated = false;
          window.location.href = '/login';
        }
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }
    return Promise.reject(error);
  },
);

carApi.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;

    //request new id if checkId fails and if not a session route
    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      !window.location.href.includes('session')
    ) {
      return generateId().then(res => {
        // replace original id with new id
        originalRequest.url = originalRequest.url.replace(
          originalRequest.url.split('api/check/id/')[1],
          res.id,
        );

        return carApi(originalRequest);
      });
    }

    if (
      error.response.status === 403 &&
      (error.response.data.msg === 'PERMISSION_DENIED' ||
        error.response.data.msg === 'INVALID_TOKEN')
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return carApi(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        if (
          error.response.data.msg === 'AUTHENTICATION_FAILED' ||
          error.response.data.msg === 'NOT_AUTHENTICATED' ||
          error.response.data.msg === 'ENVIRONMENT_MISMATCH' ||
          error.response.data.msg === 'INVALID_TOKEN'
        ) {
          auth.isAuthenticated = false;
          window.location.href = '/login';
        }
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }
    return Promise.reject(error);
  },
);

mailApi.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;

    //request new id if checkId fails and if not a session route
    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      !window.location.href.includes('session')
    ) {
      return generateId().then(res => {
        // replace original id with new id
        originalRequest.url = originalRequest.url.replace(
          originalRequest.url.split('api/check/id/')[1],
          res.id,
        );

        return mailApi(originalRequest);
      });
    }

    if (
      error.response.status === 403 &&
      (error.response.data.msg === 'PERMISSION_DENIED' ||
        error.response.data.msg === 'INVALID_TOKEN')
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return mailApi(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        if (
          error.response.data.msg === 'AUTHENTICATION_FAILED' ||
          error.response.data.msg === 'NOT_AUTHENTICATED' ||
          error.response.data.msg === 'ENVIRONMENT_MISMATCH' ||
          error.response.data.msg === 'INVALID_TOKEN'
        ) {
          auth.isAuthenticated = false;
          window.location.href = '/login';
        }
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }
    return Promise.reject(error);
  },
);

docgenApi.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;

    //request new id if checkId fails and if not a session route
    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      !window.location.href.includes('session')
    ) {
      return generateId().then(res => {
        // replace original id with new id
        originalRequest.url = originalRequest.url.replace(
          originalRequest.url.split('api/check/id/')[1],
          res.id,
        );

        return docgenApi(originalRequest);
      });
    }

    if (
      error.response.status === 403 &&
      (error.response.data.msg === 'PERMISSION_DENIED' ||
        error.response.data.msg === 'INVALID_TOKEN')
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return docgenApi(originalRequest);
          })
          .catch(() => {
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        if (
          error.response.data.msg === 'AUTHENTICATION_FAILED' ||
          error.response.data.msg === 'NOT_AUTHENTICATED' ||
          error.response.data.msg === 'ENVIRONMENT_MISMATCH' ||
          error.response.data.msg === 'INVALID_TOKEN'
        ) {
          auth.isAuthenticated = false;
          window.location.href = '/login';
        }
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }
    return Promise.reject(error);
  },
);

addressApi.interceptors.response.use(
  config => config,
  error => {
    const originalRequest = error.config;
    //request new id if checkId fails and if not a session route
    if (
      error.response.status >= 400 &&
      error.response.request.responseURL.includes('api/check/id/') &&
      !window.location.href.includes('session')
    ) {
      return generateId().then(res => {
        // replace original id with new id
        originalRequest.url = originalRequest.url.replace(
          originalRequest.url.split('api/check/id/')[1],
          res.id,
        );

        return addressApi(originalRequest);
      });
    }

    if (
      error.response.status === 403 &&
      (error.response.data.msg === 'PERMISSION_DENIED' ||
        error.response.data.msg === 'INVALID_TOKEN')
    ) {
      auth.isAuthenticated = false;
      window.location.href = '/login';
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (error.response.data.msg === 'AUTHENTICATION_EXPIRED') {
        return authApi
          .post('/auth/refresh', { headers })
          .then(() => {
            setMeData();
            return addressApi(originalRequest);
          })
          .catch(err => {
            console.error(err);
            auth.isAuthenticated = false;
            window.location.href = '/login';
          });
      } else {
        if (
          error.response.data.msg === 'AUTHENTICATION_FAILED' ||
          error.response.data.msg === 'NOT_AUTHENTICATED' ||
          error.response.data.msg === 'ENVIRONMENT_MISMATCH' ||
          error.response.data.msg === 'INVALID_TOKEN'
        ) {
          auth.isAuthenticated = false;
          window.location.href = '/login';
        }
      }
    } else {
      if (!originalRequest._retry) {
        originalRequest._retry = true;
      }
    }
    return Promise.reject(error);
  },
);

// ===================================== \\
//              API ROUTES               \\
// ===================================== \\

class ApiRoutes {
  getId = () =>
    api
      .get('/id')
      .then(response => response.data)
      .catch(error => console.error(error.response));

  getTheme = payload => api.get(`/theme/${payload}`);

  getSocialId = brokerId => api.get(`/id/${brokerId}`);

  getCampaignId = (brokerId, campaignId) =>
    api.post(`/id/${brokerId}`, { campaign_id: campaignId });

  getPublicBrokerInfo = brokerId => brokerApi.get(`/brokers/${brokerId}/info`);

  getAllBrokerInfo = () => brokerApi.get(`/brokers/info`);

  patchBrokerSettings = data => {
    return brokerApi.patch(`/brokers/settings`, data);
  };

  patchBrokerSettingsAsBroker = data => {
    return brokerApi.patch(`/brokers`, data);
  };

  patchMeSettingsAsBroker = data => {
    return authApi.patch(`/user`, data);
  };

  updatePassword = password => {
    return authApi.patch(`/user/me/password`, password);
  };

  postPersonalData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/personal/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        showErrorPopup(true);
        return null;
      });
  };

  getCarBrands = () => {
    while (api === undefined) {
      setTimeout(() => this.getCarBrands(), 500);
      return new Promise(() => {});
    }
    return api.get(`/info/car_manual/brands`).catch(err => {
      console.error(err.response);
      return null;
    });
  };

  getCarModels = brand =>
    api.get(`/info/car_manual/models?brand=${brand}`).catch(err => {
      console.error(err.response);
      return null;
    });

  getCarVersions = (brand, model) =>
    api
      .get(`/info/car_manual/versions?model=${model}&brand=${brand}`)
      .catch(err => {
        console.error(err.response);
        return null;
      });

  getCarBrandsToolkit = () =>
    carApi.get(`/brands`).catch(err => {
      console.error(err.response);
      return null;
    });

  getCarModelsToolkit = payload =>
    carApi.post(`/models`, payload).catch(err => {
      console.error(err.response);
      return null;
    });

  getCarVersionsToolkit = payload =>
    carApi.post(`/versions`, payload).catch(err => {
      console.error(err.response);
      return null;
    });

  getCarData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/info/car/${getUserId()}`, payload))
      .catch(err => {
        console.error(err);
        return null;
      });
  };

  getCarDataToolkit = payload =>
    carApi.get(`/license_plate/${payload}`).catch(err => {
      console.error(err.response);
      return null;
    });

  getManualCarData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/info/car_manual/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        return null;
      });
  };

  getManualCarDataToolkit = payload =>
    carApi.post(`/car`, payload).catch(err => {
      console.error(err.response);
      return null;
    });

  sendCarData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/car/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        showErrorPopup(true);
        return null;
      });
  };

  sendCarExtraData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/car_extra/${getUserId()}`, payload))
      .catch(err => {
        showErrorPopup(true);
        console.error(err.response);
        return null;
      });
  };

  postAddressInfo = payload => {
    let showError = true;
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => {
        showError = false;
        return api.post(`/info/address/${getUserId()}`, payload);
      })
      .catch(err => {
        console.error(err.response);
        showErrorPopup(showError);
        return null;
      });
  };

  postExtras = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/extra/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        showErrorPopup(true);
        return null;
      });
  };

  generateTerminationLetter = payload =>
    docgenApi.post(`/notice`, payload).catch(err => {
      console.error(err.response);
      return null;
    });

  generateRequiredInformation = payload =>
    docgenApi.post(`/information_requirement_sheet`, payload).catch(err => {
      console.error(err.response);
      return null;
    });

  generateCustomerSheet = payload =>
    docgenApi.post(`/customer_sheet`, payload).catch(err => {
      console.error(err.response);
      return null;
    });

  postAddressData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/home/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        return null;
      });
  };

  postAddressDataExtra = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/home_extra/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        return null;
      });
  };

  postAddressToolkit = payload => {
    return addressApi.post(`/info`, payload).catch(err => {
      console.error(err.response);
      return null;
    });
  };

  postFamilyData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/family/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        return err;
      });
  };

  postLegalData = payload => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/section/legal/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
        return err;
      });
  };

  postAuth = payload => {
    return authApi.post(`/auth`, payload);
  };

  getMe = () => authApi.get(`/auth/me`);

  getVerify = () => authApi.get(`/auth/verify`);

  getLogout = () => authApi.get(`/logout`);

  getInsurers = () => {
    if (api !== undefined) {
      let userId = getUserId();
      const url = window.location.href;
      if (url.includes('broker-history') || url.includes('review'))
        userId = sessionStorage.getItem('sessionId');
      if (!userId) {
        showErrorPopup(true);
        return null;
      }
      return api
        .get(`/check/id/${getUserId()}`)
        .then(() =>
          api.get(
            `/retrieve_insurance_companies/${i18next.language.toLowerCase()}`,
          ),
        )
        .catch(err => {
          console.error(err.response);
          return null;
        });
    } else {
      setTimeout(this.getInsurers(), 1000);
    }
  };

  getInsurersToolkit = () => {
    return api
      .get(`/retrieve_insurance_companies/${i18next.language.toLowerCase()}`)
      .catch(err => {
        console.error(err.response);
        return null;
      });
  };

  getPolicyPropositionPdf = insuranceId => {
    let userId = getUserId();
    const url = window.location.href;
    if (url.includes('broker-history') || url.includes('review'))
      userId = sessionStorage.getItem('sessionId');
    if (!userId) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${userId}`)
      .then(() =>
        api.post(
          `/generate_proposition/${i18next.language.toLowerCase()}/${userId}`,
          {
            insurance_id: insuranceId,
          },
        ),
      )
      .catch(err => {
        console.error(err.response);
        return null;
      });
  };

  getMatchingScores = (insurtype, id) => {
    const userId = id || getUserId();

    if (!userId) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${userId}`)
      .then(() =>
        api.get(
          `/get_matching_scores/${insurtype}/${i18next.language.toLowerCase()}/${userId}`,
        ),
      );
  };

  postPricing = payload => {
    let userId = getUserId();
    const url = window.location.href;
    if (url.includes('broker-history') || url.includes('review'))
      userId = sessionStorage.getItem('sessionId');

    if (!userId) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() =>
        api.post(
          `/pricing/${i18next.language.toLowerCase()}/${userId}`,
          payload,
        ),
      );
  };

  getAcceptances = payload =>
    api.post(
      `/retrieve_acceptances/${i18next.language.toLowerCase()}`,
      payload,
    );

  getBpostAddresses = payload => bPostApi.get(`/autocomplete?q=${payload}`);

  getAddressInfo = payload =>
    addressApi.get(
      `/search?q=${payload}&language_code=${i18next.language.toUpperCase()}&country_code=BE`,
    );

  getAddressStreets = payload => {
    if (payload.searchQuery) {
      return addressApi.get(
        `/streets?q=${payload.searchQuery}&q_city=${payload.city}`,
      );
    } else if (payload.city && !payload.searchQuery) {
      return addressApi.get(`/streets?q_city=${payload.city}`);
    }
  };

  getAddressCities = payload => {
    if (payload.zipcode && !payload.searchQuery) {
      return addressApi.get(`/cities?zipcode=${payload.zipcode}`);
    } else if (payload.searchQuery && !payload.zipcode) {
      return addressApi.get(`/cities?q=${payload.searchQuery}`);
    }
  };
  getRandomAddress = () => {
    return addressApi.get(`/random`);
  };

  postCheckout = payload => {
    let userId = getUserId();
    const url = window.location.href;
    if (url.includes('broker-history') || url.includes('review'))
      userId = sessionStorage.getItem('sessionId');

    if (!userId) {
      showErrorPopup(true);
      return null;
    }

    return api
      .get(`/check/id/${getUserId()}`)
      .then(() =>
        api.post(
          `/checkout/${i18next.language.toLowerCase()}/${userId}`,
          payload,
        ),
      )
      .catch(err => {
        console.error(err.response);
        return null;
      });
  };

  retrieveInformationRequirements = (insurType, payload) => {
    let userId = getUserId();
    const url = window.location.href;
    if (url.includes('broker-history') || url.includes('review'))
      userId = sessionStorage.getItem('sessionId');
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() =>
        api.post(
          `/retrieve_information_requirements/${insurType}/${i18next.language.toLowerCase()}/${userId}`,
          payload,
        ),
      )
      .catch(err => {
        console.error(err.response);
      });
  };

  checkEmail = email => authApi.post(`/email/validate`, email);

  postWantsToBuyTrial = email =>
    mailApi.post(`/mail/trial/wants_to_buy`, email).catch(err => {
      console.error(err.response);
      return null;
    });

  postWantsAnUpgrade = email =>
    mailApi
      .post(
        `/mail/feedback`,
        JSON.stringify({
          data: {
            feedback: `${email} Wants to upgrade to silver`,
          },
        }),
      )
      .catch(err => {
        console.error(err.response);
        return null;
      });

  postWantsSendEmailDia = () =>
    mailApi.post(`/mail/dia`, {}).catch(err => {
      console.error(err.response);
      return null;
    });

  postCompareMatrix = (insurType, payload) => {
    if (!getUserId()) {
      showErrorPopup(true);
      return null;
    }
    return api
      .get(`/check/id/${getUserId()}`)
      .then(() => api.post(`/compare/${insurType}/${getUserId()}`, payload))
      .catch(err => {
        console.error(err.response);
      });
  };

  /*
    CRM Routes
  */

  // Leads

  getAllLeadsByDistributionId = _distributionId =>
    brokerApi
      .get(`/distributions/${_distributionId}/leads`)
      .then(res => res.data);

  // Contracts

  getAllOffersByDistributionId = _distributionId => {
    brokerApi
      .get(`/distributions/${_distributionId}/offers`)
      .then(res => res.data);
  };

  getAllOffersByBrokerId = _brokerId => {
    brokerApi.get(`/brokers/${_brokerId}/offers`).then(res => console.log(res));
  };

  // Prospects AKA Customers

  getAllProspectsByDistributionId = _distributionId => {
    brokerApi
      .get(`/distributions/${_distributionId}/prospects`)
      .then(res => console.log(res));
  };

  /* 
    Session Routes 
  */

  getSessions = () =>
    api.get(`/sessions`).catch(err => {
      console.error(err.response);
    });

  getSession = sessionId => api.get(`/session/${sessionId}`);

  postSession = () => api.post(`/session/${getUserId()}`, {});

  deleteSession = sessionId =>
    api.delete(`/session/${sessionId}`, {
      headers: { 'Content-Type': 'application/vnd.api+json' },
      data: {},
    });
}

export default ApiRoutes;
