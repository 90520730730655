import React from 'react';
import { string } from 'prop-types';

const ecall = ({ className, color }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <g fill={color}>
      <path d="m369.35 318.76a15.75 15.75 0 0 0 -1.14-5.45q-1.26-2.67-14.08-9.06t-31.86-14.54l-19.07-8.49q-1.8-.89-6.9-3.93a53.21 53.21 0 0 0 -9-4.46 17.53 17.53 0 0 0 -7.37-1.13q-6.19.51-15.26 12.52t-17.77 24.15q-8.7 12.15-14.21 12.6a16.74 16.74 0 0 1 -5.78-.73 56.42 56.42 0 0 1 -5.52-1.8q-2.15-.87-6.12-2.79c-2.65-1.29-4.33-2.13-5.06-2.53q-35.65-16.12-62.29-38.73t-48.36-55.11q-.77-1-3.32-4.58t-3.75-5.58a56.72 56.72 0 0 1 -2.68-5.12 17.19 17.19 0 0 1 -1.67-5.59q-.36-4.47 6.11-12.11a135 135 0 0 1 14.4-14.52 117.22 117.22 0 0 0 14.35-14.9q6.45-8 6-13.14a17.65 17.65 0 0 0 -2.31-7.09 53.75 53.75 0 0 0 -5.87-8.18q-3.84-4.53-5-6.17-1.2-2-6-9.39t-9.82-14.91q-5.13-7.54-10.46-15.6a141.34 141.34 0 0 0 -9.75-13.24c-2.94-3.45-5-5.36-6.19-5.73a16.19 16.19 0 0 0 -5.6-.27q-16.48 1.35-34.11 10.42-15.24 8.54-24.89 34.79t-8 45.88a85.67 85.67 0 0 0 1.82 11.63q1.38 6.12 2.59 10.36a116.61 116.61 0 0 0 4 11.11q2.82 6.87 4.28 9.87t5.23 11q3.78 8 4.64 10 25.3 54.78 83.6 104.22t116.44 65.46q2.11.53 10.63 2.94t11.77 3.37q3.2 1 10.43 2.61a112.84 112.84 0 0 0 11.57 2.18c2.91.33 6.46.62 10.63.86a86.59 86.59 0 0 0 11.78-.1q19.62-1.62 44-15.39t30.26-30.21q6.04-18.88 4.68-35.4z" />
      <path d="m384.1 0h-184.29a15 15 0 0 0 -15 15v133.25a15 15 0 0 0 15 15h28.53l-.13 74.5a3.61 3.61 0 0 0 6.61 2l50.91-76.49h98.49a15 15 0 0 0 15-15v-133.16a15.12 15.12 0 0 0 -15.12-15.1zm-102.26 31.25h20.42v36a100.67 100.67 0 0 1 -.52 10.49c-.35 3.35-.81 7-1.39 10.7l-.47 3.06h-15.64l-.47-3.06c-.58-3.75-1-7.35-1.39-10.7a100.63 100.63 0 0 1 -.54-10.49zm21.26 89.91a12.11 12.11 0 0 1 -11.18 7.29 12.58 12.58 0 0 1 -4.77-.92 12 12 0 0 1 -3.9-2.55 11.74 11.74 0 0 1 -2.63-3.88 12.67 12.67 0 0 1 0-9.48 11.56 11.56 0 0 1 2.63-3.93 12.64 12.64 0 0 1 3.85-2.54 12.23 12.23 0 0 1 13.43 2.58 12.2 12.2 0 0 1 2.57 3.85 11.88 11.88 0 0 1 1 4.75 12.05 12.05 0 0 1 -1 4.83z" />
    </g>
  </svg>
);

ecall.propTypes = {
  className: string,
  color: string,
};

export default ecall;
