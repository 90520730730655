/* stylelint-disable value-keyword-case, declaration-no-important */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import i18next from 'i18next';
import regex from '../../../services/regexService';
import LoadingButton from '../../../components/LoadingButton';
import TerminationGeneratorInputField from '../../../components/TerminationGeneratorInputField';
import TerminationGeneratorTextArea from '../../../components/TerminationGeneratorTextArea';
import Button from '../../../components/Button';
import PhoneInput from '../../../components/PhoneInput';
import DateInputComponent from '../../../components/DateInputComponent';
import { getBrokerData } from '../../../services/brokerDataService';
import { getInsurances } from '../../../services/insurancesService';
import { getBrokerDistribution } from '../../../services/brokerDataService';
import { getInformationRequirementSheetLinkV2 } from '../../../services/docgenService';
import { getNestedObject } from '../../../services/objectService';
import { parseDateObjToIsoFormat } from '../../../services/dateService';
import { CheckBox_v2 as CheckBox } from 'wg-fe-ui';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const ToolkitRequiredInformationGenerator = () => {
  // ===================================== \\
  //        TODO: Refactor this view       \\
  // ===================================== \\
  const { t } = useTranslation();
  // const content = t('ToolkitInfo', { returnObjects: true });
  const [sendStep, setSendStep] = useState('initial');
  const [distribution, setDistribution] = useState({});
  const [documentPayload, setDocumentPayload] = useState();
  const [checkedItems, setCheckedItems] = useState({
    broker_gives_advise: true,
    independant_advice: true,
    prospect_follows_advice: true,
    no_other_contract: true,
  });
  const [options, setOptions] = useState([
    { value: 'loading', label: 'Loading ...' },
  ]);
  const [broker, setBroker] = useState('');
  const dateFormat = `DD-MM-YYYY`;
  const dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  useEffect(() => {
    initializeBrokerData();
  }, []);

  async function initializeBrokerData() {
    const brokerData = await getBrokerData();
    const distributionData = await getBrokerDistribution();
    setDistribution(distributionData);
    setBroker(brokerData);
  }

  useEffect(() => {
    if (sendStep !== 'pending' || documentPayload === undefined) {
      // Don't allow to submit when not clicked
      return;
    }
    downloadDocument(documentPayload);
  }, [documentPayload]);

  async function downloadDocument(payload) {
    const [downloadLink, fileName] = await getInformationRequirementSheetLinkV2(
      payload,
    );
    if (downloadLink !== null && fileName !== null) {
      console.log(downloadLink);
      console.log(fileName);
      const downloadButton = document.createElement('a');
      downloadButton.href = downloadLink;
      downloadButton.download = fileName;
      setSendStep('success');
      downloadButton.click();
    } else {
      setSendStep('error');
    }
  }

  const onClickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    distribution.logo = (getNestedObject(distribution, ['links']) || []).filter(
      item => item.rel === 'logo',
    )[0].href;

    const {
      street_required_infomation,
      zipcode_required_infomation,
      city_required_infomation,
      housenr_required_infomation,
      first_name_required_infomation,
      national_register_nr_required_infomation,
      birth_required_infomation,
      last_name_required_infomation,
      insurer_required_infomation,
      insurance_name_required_infomation,
      insurance_type_required_infomation,
      telephonenr_required_infomation,
      specific_needs_required_infomation,
      motivation_required_infomation,
      email_required_infomation,
      reason_for_contact_required_information,
    } = values;
    const {
      broker_gives_advise,
      independant_advice,
      prospect_follows_advice,
      no_other_contract,
    } = checkedItems;
    const address = {
      street: street_required_infomation,
      zipcode: zipcode_required_infomation,
      city: city_required_infomation,
      housenr: housenr_required_infomation.toLowerCase(),
      country_code: 'BE',
    };
    const payload = {
      broker: broker.broker,
      theme: broker.theme,
      distribution,
      prospect: {
        first_name: first_name_required_infomation,
        last_name: last_name_required_infomation,
        birth: parseDateObjToIsoFormat({
          day: birth_required_infomation.split('-')[0],
          month: birth_required_infomation.split('-')[1],
          year: birth_required_infomation.split('-')[2],
        }),
        national_register_nr: national_register_nr_required_infomation,
        address,
        telephonenr: telephonenr_required_infomation,
        email: email_required_infomation,
      },
      language: i18next.language.toUpperCase(),
      insurance: {
        name: insurance_name_required_infomation,
        company: insurer_required_infomation.value,
        type: insurance_type_required_infomation.value,
      },
      specific_needs: specific_needs_required_infomation,
      motivation:
        motivation_required_infomation &&
        motivation_required_infomation !== '' &&
        motivation_required_infomation !== null
          ? motivation_required_infomation
          : '',
      reason_for_contact: reason_for_contact_required_information,
      broker_gives_advise,
      independant_advice,
      prospect_follows_advice,
      no_other_contract,
    };

    setSendStep('pending');
    setDocumentPayload(payload);
  };

  const insuranceTypeOptions = t(`risk_objects`, { returnObjects: true });

  const SignupSchema = Yup.object().shape({
    first_name_required_infomation: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    last_name_required_infomation: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    national_register_nr_required_infomation: Yup.number()
      .min(10000000000, t(`Too short`))
      .max(99999999999, t(`Too long`))
      .nullable(),
    insurer_required_infomation: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .nullable(),
    insurance_name_required_infomation: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    birth_required_infomation: Yup.string()
      .required(t(`AdresInput.errors.invalid`))
      .matches(dateRegex, t(`AdresInput.errors.invalid`))
      .test('DOB', t(`AdresInput.errors.invalid`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`AdresInput.errors.invalid`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`Too young`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 18,
      )
      .test(
        'DOB',
        t(`Too old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 120,
      ),
    insurance_type_required_infomation: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    email_required_infomation: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .min(1, t(`AdresInput.errors.short`))
      .max(200, t(`AdresInput.errors.long`))
      .matches(regex.emailRegex, t(`Invalid email`)),
    // .test('useremail', t(`Invalid email`), () => validEmail),
    telephonenr_required_infomation: Yup.string()
      .test('Phone number', t(`AdresInput.errors.invalid`), value => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch {
              return false;
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required(t(`AdresInput.errors.invalid`)),
    street_required_infomation: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(150, t(`AdresInput.errors.long`))
      .matches(regex.street, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    specific_needs_required_infomation: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(650, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    motivation_required_infomation: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(650, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    reason_for_contact_required_information: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(650, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    housenr_required_infomation: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .matches(regex.houseNr, t(`AdresInput.errors.invalid`)),
    zipcode_required_infomation: Yup.number()
      .min(1000, t(`AdresInput.errors.short`))
      .max(9999, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    city_required_infomation: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(100, t(`AdresInput.errors.long`))
      .matches(regex.city, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
  });

  const disableHandler = (errors, touched) => {
    if (
      checkedItems['broker_gives_advise'] &&
      !touched.specific_needs_required_infomation
    ) {
      return true;
    }
    if (
      !checkedItems['broker_gives_advise'] &&
      errors &&
      errors['specific_needs_required_infomation']
    ) {
      delete touched.specific_needs_required_infomation;
      delete errors.specific_needs_required_infomation;
    }
    if (Object.entries(errors).length === 0) {
      if (!(Object.entries(touched).length === 0)) {
        return false;
      }
    }
    return true;
  };

  const handleChange = (setFieldValue, type, _selected) => {
    setFieldValue(type, _selected);
  };

  useEffect(() => {
    switch (sendStep) {
      case 'initial':
        break;
      case 'pending':
        break;
      case 'success':
        break;
      case 'error':
        break;
      default:
        break;
    }
  }, [sendStep]);

  useEffect(() => {
    getInsurances()
      .then(res => {
        const _options = [];
        Object.keys(res).forEach(key => {
          _options.push({ value: key, label: res[key] });
        });
        setOptions(_options);
      })
      .catch(err => {
        setOptions([{ value: `notfound`, label: `Fetch failed` }]);
        console.error(err);
      });
  }, []);

  const changeSelected = name => {
    const extraChanges = {};
    if (name.name === 'broker_gives_advise') {
      extraChanges['independant_advice'] = false;
      extraChanges['prospect_follows_advice'] = false;
    }
    setCheckedItems({
      ...checkedItems,
      ...extraChanges,
      [name.name]: !checkedItems[name.name],
    });
  };

  return (
    <>
      <TitleContainer>
        <Title>{t('Information requirement generator')}</Title>
      </TitleContainer>
      <SectionContainer data-test-id="dashboard_toolkit_documents_information_requirement_generator_section">
        <Section>
          <Formik
            initialValues={{
              insurance_name_required_infomation: ``,
              insurer_required_infomation: ``,
              insurance_type_required_infomation: ``,
              first_name_required_infomation: ``,
              last_name_required_infomation: ``,
              street_required_infomation: ``,
              housenr_required_infomation: ``,
              zipcode_required_infomation: ``,
              city_required_infomation: ``,
              email_required_infomation: ``,
              telephonenr_required_infomation: ``,
              birth_required_infomation: ``,
              national_register_nr_required_infomation: null,
              specific_needs_required_infomation: ``,
              motivation_required_infomation: ``,
              reason_for_contact_required_information: ``,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              onClickHandler(values);
            }}
          >
            {({
              errors,
              touched,
              setFieldValue,
              handleBlur,
              values,
              setFieldTouched,
            }) => {
              const disabled = disableHandler(errors, touched);
              return (
                <FormWrapper>
                  <InputWrapper>
                    <StyledLabel>
                      <p>{t(`Insurance type`)}</p>
                      <Input
                        onChange={_selected => {
                          handleChange(
                            setFieldValue,
                            'insurance_type_required_infomation',
                            _selected,
                          );
                          setFieldTouched(
                            'insurance_type_required_infomation',
                            true,
                          );
                        }}
                        options={insuranceTypeOptions}
                        onBlurResetsInput={false}
                        data-test-id="toolkit_documents_required_information_insurance_type"
                        isClearable
                        error={
                          errors['insurance_type_required_infomation'] &&
                          touched['insurance_type_required_infomation']
                        }
                        valid={
                          !errors['insurance_type_required_infomation'] &&
                          touched['insurance_type_required_infomation']
                        }
                        menuPlacement="bottom"
                        noOptionsMessage={() => 'Geen opties'}
                        classNamePrefix="Select"
                        value={values['insurance_type_required_infomation']}
                        placeholder={t('Insurance type')}
                      />
                    </StyledLabel>
                    <StyledLabel>
                      <p>{t(`Insurer`)}</p>
                      <Input
                        onChange={_selected => {
                          handleChange(
                            setFieldValue,
                            'insurer_required_infomation',
                            _selected,
                          );
                          setFieldTouched('insurer_required_infomation', true);
                        }}
                        options={options}
                        onBlurResetsInput={false}
                        data-test-id="toolkit_documents_required_information_insurance_name"
                        isClearable
                        error={
                          errors['insurer_required_infomation'] &&
                          touched['insurer_required_infomation']
                        }
                        valid={
                          !errors['insurer_required_infomation'] &&
                          touched['insurer_required_infomation']
                        }
                        menuPlacement="bottom"
                        noOptionsMessage={() => 'Geen opties'}
                        classNamePrefix="Select"
                        value={values['insurer_required_infomation']}
                        placeholder={t('Insurer')}
                      />
                    </StyledLabel>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_insurance_name"
                      error={
                        errors['insurance_name_required_infomation'] &&
                        touched['insurance_name_required_infomation']
                      }
                      valid={
                        !errors['insurance_name_required_infomation'] &&
                        touched['insurance_name_required_infomation']
                      }
                      stretch={true}
                      type="text"
                      name="insurance_name_required_infomation"
                    >
                      {t(`Insurance name`)}
                    </TerminationGeneratorInputField>
                    <Line />
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_first_name"
                      error={
                        errors['first_name_required_infomation'] &&
                        touched['first_name_required_infomation']
                      }
                      valid={
                        !errors['first_name_required_infomation'] &&
                        touched['first_name_required_infomation']
                      }
                      type="text"
                      name="first_name_required_infomation"
                    >
                      {t(`First name`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_last_name"
                      error={
                        errors['last_name_required_infomation'] &&
                        touched['last_name_required_infomation']
                      }
                      valid={
                        !errors['last_name_required_infomation'] &&
                        touched['last_name_required_infomation']
                      }
                      type="text"
                      name="last_name_required_infomation"
                    >
                      {t(`Last name`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_street"
                      error={
                        errors['street_required_infomation'] &&
                        touched['street_required_infomation']
                      }
                      valid={
                        !errors['street_required_infomation'] &&
                        touched['street_required_infomation']
                      }
                      type="text"
                      name="street_required_infomation"
                    >
                      {t(`Street`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_housenr"
                      error={
                        errors['housenr_required_infomation'] &&
                        touched['housenr_required_infomation']
                      }
                      valid={
                        !errors['housenr_required_infomation'] &&
                        touched['housenr_required_infomation']
                      }
                      type="text"
                      name="housenr_required_infomation"
                    >
                      {t(`Nr/Bus`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_zipcode"
                      error={
                        errors['zipcode_required_infomation'] &&
                        touched['zipcode_required_infomation']
                      }
                      valid={
                        !errors['zipcode_required_infomation'] &&
                        touched['zipcode_required_infomation']
                      }
                      type="number"
                      name="zipcode_required_infomation"
                    >
                      {t(`Zipcode`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_infomation_city"
                      error={
                        errors['city_required_infomation'] &&
                        touched['city_required_infomation']
                      }
                      valid={
                        !errors['city_required_infomation'] &&
                        touched['city_required_infomation']
                      }
                      type="text"
                      name="city_required_infomation"
                    >
                      {t(`City`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      dataTestId="toolkit_documents_required_information_email"
                      error={
                        errors['email_required_infomation'] &&
                        touched['email_required_infomation']
                      }
                      valid={
                        !errors['email_required_infomation'] &&
                        touched['email_required_infomation']
                      }
                      type="email"
                      name="email_required_infomation"
                    >
                      {t(`Email`)}
                    </TerminationGeneratorInputField>
                    <StyledPhoneInput
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      values={values}
                      name="telephonenr_required_infomation"
                      handleBlur={handleBlur}
                      dataTestId="toolkit_documents_required_information_telephonenr"
                    >
                      {t(`Telephone number`)}
                    </StyledPhoneInput>
                    <StyledLabel>
                      <p>{t(`Birthdate`)}</p>
                      <StyledDateInputComponent
                        classPrefix="birthDate_required_information"
                        dataTestId="toolkit_documents_required_information_birthdate"
                        errors={errors}
                        touched={touched}
                        value={values['birth_required_infomation']}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        type="text"
                        disabled={false}
                        name="birth_required_infomation"
                        id="birth_required_infomation"
                      />
                    </StyledLabel>
                    <TerminationGeneratorInputField
                      error={
                        errors['national_register_nr_required_infomation'] &&
                        touched['national_register_nr_required_infomation']
                      }
                      valid={
                        !errors['national_register_nr_required_infomation'] &&
                        touched['national_register_nr_required_infomation']
                      }
                      type="number"
                      name="national_register_nr_required_infomation"
                      dataTestId="toolkit_documents_required_information_national_register_nr"
                    >
                      {t(`Social security number (only numbers)`)}
                    </TerminationGeneratorInputField>
                    <Line />
                    <CheckBoxItem
                      checked={checkedItems['broker_gives_advise']}
                      disabled={false}
                      name="broker_gives_advise"
                      onChange={name => changeSelected(name)}
                    >
                      {t('Did the office give advise on this matter')}
                    </CheckBoxItem>
                    <CheckBoxItem
                      checked={checkedItems['independant_advice']}
                      name="independant_advice"
                      disabled={!checkedItems['broker_gives_advise']}
                      onChange={name => changeSelected(name)}
                    >
                      {t('Did you deliver independent advice to the customer')}
                    </CheckBoxItem>
                    <CheckBoxItem
                      checked={checkedItems['prospect_follows_advice']}
                      name="prospect_follows_advice"
                      disabled={!checkedItems['broker_gives_advise']}
                      onChange={name => changeSelected(name)}
                    >
                      {t('Did the customer follow the advice of your office')}
                    </CheckBoxItem>
                    <CheckBoxItem
                      checked={!checkedItems['no_other_contract']}
                      name="no_other_contract"
                      disabled={false}
                      onChange={name => changeSelected(name)}
                    >
                      {t('Is there another contract running for this risk')}
                    </CheckBoxItem>
                    <Line />
                    <TerminationGeneratorTextArea
                      error={
                        errors['reason_for_contact_required_information'] &&
                        touched['reason_for_contact_required_information']
                      }
                      valid={
                        !errors['reason_for_contact_required_information'] &&
                        touched['reason_for_contact_required_information']
                      }
                      name="reason_for_contact_required_information"
                      dataTestId="toolkit_documents_required_information_reason_for_contact"
                    >
                      {t(`Reason for contact`)}
                    </TerminationGeneratorTextArea>
                    {checkedItems['broker_gives_advise'] ? (
                      <TerminationGeneratorTextArea
                        error={
                          errors['specific_needs_required_infomation'] &&
                          touched['specific_needs_required_infomation']
                        }
                        valid={
                          !errors['specific_needs_required_infomation'] &&
                          touched['specific_needs_required_infomation']
                        }
                        disabled={!checkedItems['broker_gives_advise']}
                        name="specific_needs_required_infomation"
                        dataTestId="toolkit_documents_required_information_specific_needs"
                      >
                        {t(`Specific needs`)}
                      </TerminationGeneratorTextArea>
                    ) : (
                      ''
                    )}
                    <TerminationGeneratorTextArea
                      error={
                        errors['motivation_required_infomation'] &&
                        touched['motivation_required_infomation']
                      }
                      valid={
                        !errors['motivation_required_infomation'] &&
                        touched['motivation_required_infomation']
                      }
                      name="motivation_required_infomation"
                      dataTestId="toolkit_documents_required_information_motivation"
                    >
                      {t(`Motivation`)}
                    </TerminationGeneratorTextArea>
                  </InputWrapper>
                  <ButtonContainer>
                    {sendStep !== 'pending' ? (
                      <Button
                        dataTestId="toolkit_documents_required_information_submit"
                        type="submit"
                        disabled={disabled}
                      >
                        {t(`Generate`)}
                      </Button>
                    ) : (
                      <LoadingButton>{t(`Generating`)}</LoadingButton>
                    )}
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </Section>
      </SectionContainer>
    </>
  );
};

const SectionContainer = styled.div`
  padding-bottom: 8.5rem;
  position: relative;
`;

const StyledDateInputComponent = styled(DateInputComponent)`
  height: 3.5rem;
  max-width: 40rem;
  & label {
    margin-top: 0;
  }

  & input {
    height: 3.5rem;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  width: 47%;
  line-height: 2.1rem;
  font-size: 1.5rem;
  color: #a29c95;
  margin-bottom: 2.5rem;
  font-weight: normal;
  max-height: 6.1rem !important;

  & > p {
    display: flex;
    align-items: baseline;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    margin-bottom: 0.5rem;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  max-height: 6.1rem;
  position: relative;
  color: #a29c95;
  max-width: 40rem;
  font-weight: normal;
  width: 47%;

  & .react-tel-input input[type='text'],
  & .react-tel-input input[type='tel'] {
    box-shadow: none;
    border: 1px solid #ccc;
  }

  & .react-tel-input .flag-dropdown {
    border: 1px solid #ccc;
    box-shadow: none;
  }
`;

const Input = styled(Select)`
  width: 100%;
  max-width: 40rem;
  height: 3.5rem;
  & > input {
    background: white;
    /* Outline */
    box-shadow: none;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .Select {
    &__control {
      height: 100%;
      min-height: 3.5rem;
      border-radius: 3px;
      border-color: ${props =>
        props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
      z-index: 999;

      :hover {
        border-color: ${props =>
          props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
      }

      &--is-focused {
        border-color: ${props =>
          props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
        box-shadow: none;

        :hover {
          border-color: ${props =>
            props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
        }
      }
    }

    &__value-container {
      padding: 0 1rem;
      font-family: 2rem;
      font-size: 1.5rem;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  flex: 0 0 100%;
  max-width: 90rem;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  justify-content: space-between;

  & > input {
    margin-top: 0;
  }

  & > label {
    max-width: 40rem;
  }
`;

const ButtonContainer = styled.div`
  border-radius: 3rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 3rem;
  right: 0;
  padding: 0;
  flex-direction: column;

  & button {
    z-index: 1;
  }

  & > button,
  & > div {
    margin: 0;

    @media screen and (max-width: 1025px) {
      transform: scale(1);
    }
  }
`;

const Line = styled.span`
  width: 100%;
  height: 0.1rem;
  background-color: #ddd;
  margin-bottom: 2.4rem;
  margin-top: 1rem;
`;

const FormWrapper = styled(Form)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  text-align: left;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

const Section = styled.div`
  /* Outline */
  border: 1px solid #ccc;
  box-sizing: border-box;
  /* Button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4.69px;
  border-radius: 5px;
  margin: 2.35rem 0;
  padding: 3.5rem;
  padding-bottom: 1.5rem;
  background-color: white;
`;

const CheckBoxItem = styled(CheckBox)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
  min-width: 37rem;

  & div {
    min-height: 1.9rem;
    min-width: 1.9rem;
  }
`;

export default ToolkitRequiredInformationGenerator;
