import React, { useState, createContext, useReducer } from 'react';
import { node } from 'prop-types';
import { formatISO } from 'date-fns/esm';
import { addMonths } from 'date-fns';
import { useEffect } from 'react';
import i18n from 'i18next';

function offersParameterReducer(state, action) {
  switch (action.type) {
    case 'REMOVE_PARAMETERS':
      return {
        ...state,
        offers: state.offers.filter(({ id }) => id !== action.payload),
      };
    case 'ADD_PARAMETERS':
      return { ...state, offers: [...state.offers, action.payload] };
    case 'EDIT_PARAMETERS_BY_ID': {
      const { payload } = action;
      let { offers } = state;
      const index = offers.findIndex(({ id }) => id === payload.id);
      offers[index] = payload;
      return { ...state, offers };
    }
    case 'EDIT_ALL_PARAMETERS': {
      const { globalParameters } = action.payload;
      const { offers } = state;
      const updatedOffers = offers.map(({ ...rest }) => ({
        ...rest,
        offerValidityFrom: globalParameters.offerValidityFrom,
        offerValidityUntil: globalParameters.offerValidityUntil,
        commencement: globalParameters.commencement,
        mailToClient: globalParameters.mailToClient || false,
        assigned_to: globalParameters.assigned_to,
        language: globalParameters.language,
        exportToCRM: globalParameters.exportToCRM,
      }));
      return { ...state, offers: updatedOffers };
    }
    case 'INITIALIZE_REQUIRED_INFORMATION_BY_TYPE': {
      return {
        ...state,
        requiredInformation: [
          ...state.requiredInformation,
          { ...action.payload },
        ],
      };
    }
    case 'EDIT_REQUIRED_INFORMATION_BY_TYPE': {
      const { payload } = action;
      let { requiredInformation } = state;
      const index = requiredInformation.findIndex(
        ({ insuranceType }) => insuranceType === payload.insuranceType,
      );
      if (index === -1) return { ...state };
      requiredInformation[index] = payload;
      return { ...state, requiredInformation };
    }
    case 'INITIALIZE_TERMINATION_LETTER_BY_TYPE': {
      return {
        ...state,
        terminationLetter: [...state.terminationLetter, { ...action.payload }],
      };
    }
    case 'EDIT_TERMINATION_LETTER_BY_TYPE': {
      const { payload } = action;
      let { terminationLetter } = state;
      const index = terminationLetter.findIndex(
        ({ insuranceType }) => insuranceType === payload.insuranceType,
      );
      if (index === -1) return { ...state };
      terminationLetter[index] = payload;
      return { ...state, terminationLetter };
    }
    default:
      throw Error('Not a valid action!');
  }
}

export const ProFlowOffersContext = createContext({
  getOfferParameters: () => {},
  setOfferParameters: () => {},
  offersParameters: [],
  globalParameters: {},
  setGlobalParameters: () => {},
  createInitialOfferValitityDates: () => {},
  getRequireInformationGenerator: () => {},
  setRequireInformationGenerator: () => {},
});

export const ProFlowOffersContextProvider = ({ children }) => {
  const [globalParameters, setGlobalParameters] = useState(
    generateInitialOfferData(),
  );
  const [offersParameters, dispatchParameters] = useReducer(
    offersParameterReducer,
    {
      offers: [],
      requiredInformation: [],
      terminationLetter: [],
    },
  );

  function getOfferParameters(id) {
    return offersParameters.offers.filter(({ id: _id }) => _id === id);
  }

  function setOfferParameters(id, parameters) {
    const doesEntryExists =
      offersParameters.offers.findIndex(({ id: _id }) => _id === id) !== -1;

    if (doesEntryExists) {
      dispatchParameters({
        type: 'EDIT_PARAMETERS_BY_ID',
        payload: { id, ...parameters },
      });
    } else {
      dispatchParameters({
        type: 'ADD_PARAMETERS',
        payload: { id, ...parameters },
      });
    }
  }

  function removeOfferParameters(id) {
    dispatchParameters({
      type: 'REMOVE_PARAMETERS',
      payload: id,
    });
  }

  function createInitialOfferValitityDates({ id, ...rest }) {
    setOfferParameters(id, {
      id,
      ...rest,
      ...generateInitialOfferData(),
    });
  }

  function generateInitialOfferData() {
    const currentDate = new Date();
    return {
      offerValidityFrom: formatISO(currentDate),
      offerValidityUntil: formatISO(addMonths(currentDate, 1)),
      commencement: formatISO(currentDate),
      language: i18n.language.toUpperCase(),
    };
  }

  useEffect(() => {
    dispatchParameters({
      type: 'EDIT_ALL_PARAMETERS',
      payload: { globalParameters },
    });
  }, [globalParameters]);

  function getRequireInformationGenerator(insuranceType) {
    const { requiredInformation, offers } = offersParameters;
    const requiredInformationByInsuranceType = requiredInformation.filter(
      ({ insuranceType: _insuranceType }) => _insuranceType === insuranceType,
    );
    const offersByInsuranceType = offers.filter(
      ({ insuranceType: { key } }) => key === insuranceType,
    );

    // check if there is already prefilled data
    const hasAlreadyBeenFilledIn =
      requiredInformationByInsuranceType.length > 0;

    if (hasAlreadyBeenFilledIn) {
      return requiredInformationByInsuranceType;
    } else {
      dispatchParameters({
        type: 'INITIALIZE_REQUIRED_INFORMATION_BY_TYPE',
        payload: {
          insuranceType,
          doesBrokerGiveAdvice: true,
          didTheBrokeGivePersonalisedAdvice: true,
          didTheProspectFollowedAdvice: true,
          anotherInsuranceContract: false,
          reasonOfContract: '',
          motivation: generateMotivationFields(offersByInsuranceType),
          prospectAsksToInsure: '',
          errors: { startError: true },
        },
      });
      return undefined;
    }
  }

  function generateMotivationFields(offers) {
    const motivationIds = offers.map(
      ({ overwriteMotivation, id, motivation }) =>
        overwriteMotivation ? { id, motivation } : false,
    );
    const hasGeneralMotivation = motivationIds.includes(false);
    // console.log(motivationIds);
    const motivationFields = motivationIds
      .filter(val => val !== false)
      .map(({ id, motivation }) => ({
        [id]: motivation || '',
      }));
    // console.log(motivationFields);

    return {
      ...(hasGeneralMotivation && { general: '' }),
      ...Object.assign({}, ...motivationFields),
    };
  }

  function setRequireInformationGenerator(payload) {
    dispatchParameters({
      type: 'EDIT_REQUIRED_INFORMATION_BY_TYPE',
      payload,
    });
  }

  function getTerminationLetterGenerator(insuranceType) {
    const { terminationLetter } = offersParameters;
    const terminationLetterByInsuranceType = terminationLetter.filter(
      ({ insuranceType: _insuranceType }) => _insuranceType === insuranceType,
    );

    // check if there is already prefilled data
    const hasAlreadyBeenFilledIn = terminationLetterByInsuranceType.length > 0;

    if (hasAlreadyBeenFilledIn) {
      return terminationLetterByInsuranceType;
    } else {
      dispatchParameters({
        type: 'INITIALIZE_TERMINATION_LETTER_BY_TYPE',
        payload: {
          insuranceType,
          enableTermination: false,
          errors: { startError: true },
        },
      });
      return undefined;
    }
  }

  function setTerminationLetterGenerator(payload) {
    dispatchParameters({
      type: 'EDIT_TERMINATION_LETTER_BY_TYPE',
      payload,
    });
  }

  return (
    <ProFlowOffersContext.Provider
      value={{
        getOfferParameters,
        setOfferParameters,
        removeOfferParameters,
        offersParameters,
        globalParameters,
        setGlobalParameters,
        createInitialOfferValitityDates,
        getRequireInformationGenerator,
        setRequireInformationGenerator,
        getTerminationLetterGenerator,
        setTerminationLetterGenerator,
      }}
    >
      {children}
    </ProFlowOffersContext.Provider>
  );
};

ProFlowOffersContextProvider.propTypes = {
  children: node.isRequired,
};
