import React from 'react';
import LogRocket from 'logrocket';
import { node } from 'prop-types';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidCatch(error, errorInfo) {
    LogRocket.captureException({ error, errorInfo });
    if (process.env.NODE_ENV !== 'development') {
      if (!window.location.href.includes('login')) {
        window.location.href = '/#/dashboard?popup=error';
      }
    }
  }

  render() {
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
};

export default ErrorBoundary;
