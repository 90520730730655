import React from 'react';
import styled from 'styled-components';

import checkmark from '../../assets/images/checkmark.svg';
import LoadingDriveAssistIcons from './LoadingDriveAssistIcons';
import { getCurrentTheme } from '../store';
import { string, func } from 'prop-types';

const CheckBoxBorderedBlockWithIcon = ({
  values,
  setFieldValue,
  questions,
  dataTestId,
  objectKey,
}) => {
  return (
    <StyledLabelCheckbox
      data-test-id={dataTestId}
      htmlFor={objectKey}
      checked={values[objectKey]}
    >
      <BoundingBox>
        <StyledLoadingDriveAssistIcons
          type={`${objectKey}_sphere`}
          title={objectKey}
          colors={getCurrentTheme()}
        />
      </BoundingBox>
      <span
        dangerouslySetInnerHTML={{
          __html: questions[objectKey],
        }}
      />
      <StandardInputCheckbox
        id={objectKey}
        type="checkbox"
        name={objectKey}
        checked={values[objectKey]}
        onChange={e => {
          setFieldValue(objectKey, e.target.checked);
        }}
      />
      <CheckboxItem checked={values[objectKey]}>
        {values[objectKey] ? <img src={checkmark} alt="checkmark" /> : ''}
      </CheckboxItem>
    </StyledLabelCheckbox>
  );
};

const BoundingBox = styled.div`
  width: 4rem;
  height: 3.5rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLoadingDriveAssistIcons = styled(LoadingDriveAssistIcons)`
  width: 100%;
  height: 100%;
`;

const CheckboxItem = styled.div`
  width: 2rem;
  height: 2rem;
  margin-top: 0.3rem;
  background-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : 'none'};
  transition: all 0.1s ease;
  flex-grow: 0;
  flex-shrink: 0;
  border: 0.2rem solid #d3d4d8;
  border-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : '#D3D4D8'};
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const StandardInputCheckbox = styled.input`
  border-radius: 0.3rem;
  opacity: 0;
  margin-left: 1.5rem;
  margin-right: -1.6rem;
  cursor: pointer;
  &:focus + ${CheckboxItem} {
    outline: none;
    border-color: ${({ theme }) => theme.brand.lighter};
  }
`;

const StyledLabelCheckbox = styled.label`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  cursor: pointer;
  line-height: 1.5;
  background-color: ${props => (!props.disabled ? 'white' : 'none')};
  flex: 0 0 50%;
  color: #aeaeae;
  padding: 0.65rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  align-items: center;
  margin-bottom: 0.75rem;
  & > span a {
    font-size: 1.4rem;
    transition: color 0.1s ease;
    color: ${({ theme }) => theme.brand.primary};
    font-weight: bold;
  }
  & > span {
    font-size: 1.4rem;
    width: 90%;
    transition: color 0.1s ease;
    color: #aeaeae;

    & > span {
      font-weight: bold;
      color: ${props => (props.disabled ? '#aeaeae' : 'black')};
    }
  }
`;

CheckBoxBorderedBlockWithIcon.propTypes = {
  values: string,
  setFieldValue: func,
  questions: '',
  objectKey: '',
  className: '',
  dataTestId: string,
};

export default CheckBoxBorderedBlockWithIcon;
