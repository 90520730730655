import React from 'react';
import { string } from 'prop-types';

const Business = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.47 144.97">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M0,37.07v96A11.9,11.9,0,0,0,11.9,145H28.78V25.17H11.9A11.9,11.9,0,0,0,0,37.07Z"
        />
        <path
          fill={color}
          d="M147.57,25.17H130.69V145h16.88a11.9,11.9,0,0,0,11.9-11.9v-96A11.9,11.9,0,0,0,147.57,25.17Z"
        />
        <path
          fill={color}
          d="M108.43,10.07A10.08,10.08,0,0,0,98.36,0H61.11A10.08,10.08,0,0,0,51,10.07v15.1H44.43V145H115V25.17h-6.61Zm-47.32,0H98.36v15.1H61.11Z"
        />
      </g>
    </g>
  </svg>
);

Business.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Business.propTypes = {
  title: string,
  color: string,
};

export default Business;
