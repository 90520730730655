import React from 'react';
import styled from 'styled-components';
import { string, node } from 'prop-types';
// import PersonalData from '../store';
import { Field, ErrorMessage } from 'formik';

const PersonalInput = ({ type, name, children, className, dataTestId }) => (
  <>
    <StyledLabel className={className} htmlFor={name} name={name}>
      <p>{children}</p>
      <StandardInput
        data-test-id={dataTestId}
        id={name}
        type={type}
        name={name}
        placeholder="&nbsp;"
      />
      <ErrorMss name={name} component="p" />
    </StyledLabel>
  </>
);

const StyledLabel = styled.label`
  position: relative;
  width: ${props =>
    props.name === 'housenr' ? '12%' : props.name === 'street' ? '28%' : '20%'};
  line-height: 2.1rem;
  font-size: 1.5rem;
  color: #a29c95;
  font-weight: normal;

  & > p {
    display: flex;
    align-items: baseline;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    margin-bottom: 0.5rem;
  }
`;

const StandardInput = styled(Field)`
  width: 100%;
  border: 0;
  height: 3.5rem;
  font-size: 1.5rem;
  transition: all 0.15s ease;
  padding-left: 1rem;
  background: white;
  /* Button */
  box-shadow: 0 2.5px 8.8px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
`;

const ErrorMss = styled(ErrorMessage)`
  font-size: 1.1rem;
  color: red;
  margin-left: 0.6rem;
  text-align: right;
  width: 100%;
  position: absolute;
  bottom: -2.5rem;
  z-index: 21;
  margin-top: 0.5rem;
`;

PersonalInput.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  children: node.isRequired,
  dataTestId: string,
  className: string,
  // getInput: func.isRequired,
};

export default PersonalInput;
