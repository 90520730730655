/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { TextInput, SearchSelectInput } from 'wg-fe-ui';

import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import i18next from 'i18next';

import Layout from '../../LayoutFlow/views/App';
import { useDebouncedCallback } from 'use-debounce';

import regex from '../../../../services/regexService';
import { setAddressData, sendPersonalData, getPersonalData } from '../store';

import {
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { getStreets, getAddressCities } from '../../Woning/store/index';
import RightSide from '../../../../components/RightSide';
import LeftSide from '../../../../components/LeftSide';
import useForm from '../../../../../hooks/useForm';
import { capitalize } from '../../../../services/stringService';
import Button from '../../../../components/Button';
import AddressInputSearchFullAddress from './AddressInputSearchFullAddress';
import LouiseLeftSide from './LouiseLeftSide';

const AdresInput = ({ history }) => {
  const [btnText, setBtnText] = useState('Bevestig');
  const [sendStep, setSendStep] = useState('initial');
  const [manualInput, setManualInput] = useState({
    notSelect: false,
    allInputs: false,
  });

  const [tempPostCode, setTempPostCode] = useState(0);
  const [fullAddressInput, setFullAddressInput] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);

  const [citiesList, setCitiesList] = useState({});
  const [selected, setSelected] = useState({ city: {}, street: {} });
  const [defaultStreets, setDefaultStreets] = useState();
  const [defaultCity, setDedefaultCity] = useState();
  const [fullAddress, setFullAddress] = useState(true);

  const personalDataAddress = getPersonalData().billing_address;
  const { t } = useTranslation();
  const content = t(`${getComponentName()}`, { returnObjects: true });

  const SignupSchema = Yup.object().shape({
    streetName: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(150, t(`AdresInput.errors.long`))
      .matches(regex.street, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    streetNumber: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .max(20, t(`AdresInput.errors.long`))
      .matches(regex.houseNr, t(`AdresInput.errors.invalid`)),
    boxNumber: Yup.string()
      .nullable()
      .max(20, t(`AdresInput.errors.long`))
      .matches(regex.boxNr, t(`AdresInput.errors.invalid`)),
    postalCode: Yup.string().required(t(`AdresInput.errors.required`)),
    municipalityName: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(100, t(`AdresInput.errors.long`))
      .matches(regex.city, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
  });

  const {
    handleChange,
    errors,
    values,
    setValues,
    touched,
    handleSubmit,
    setErrors,
  } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  const fetchCitiesByValue = value => {
    if ((value && value.length >= 2) || (value && value.zipcode)) {
      let citiesOptionList = [];
      let listOfCities = [];

      return getAddressCities(value).then(res => {
        res.data.forEach(city => {
          listOfCities.push({
            city: city[`name_${i18next.language.toLowerCase()}`]
              ? city[`name_${i18next.language.toLowerCase()}`]
              : city.name,
            zipcode: city.zipcode,
          });
          citiesOptionList.push({
            value: city[`name_${i18next.language.toLowerCase()}`]
              ? city[`name_${i18next.language.toLowerCase()}`]
              : city.name,
            label: city[`name_${i18next.language.toLowerCase()}`]
              ? city[`name_${i18next.language.toLowerCase()}`]
              : city.name,
          });
        });
        if (listOfCities.length === 1) {
          setValues({
            postalCode: listOfCities[0].zipcode,
            municipalityName: listOfCities[0].city,
          });
        }
        setDedefaultCity(citiesOptionList);
        setCitiesList(listOfCities);
        return citiesOptionList;
      });
    }
  };

  const fetchCitiesByValues = (value, callback) => {
    if (value && value.length >= 2) {
      let citiesOptionList = [];
      let listOfCities = [];

      getAddressCities({ searchQuery: value }).then(res => {
        res.data.forEach(city => {
          listOfCities.push({
            city: city[`name_${i18next.language.toLowerCase()}`]
              ? city[`name_${i18next.language.toLowerCase()}`]
              : city.name,
            zipcode: city.zipcode,
          });
          citiesOptionList.push({
            value: city[`name_${i18next.language.toLowerCase()}`]
              ? city[`name_${i18next.language.toLowerCase()}`]
              : city.name,
            label: city[`name_${i18next.language.toLowerCase()}`]
              ? city[`name_${i18next.language.toLowerCase()}`]
              : city.name,
          });
        });
        setDedefaultCity(citiesOptionList);
        setCitiesList(listOfCities);
        callback(citiesOptionList);
      });
    }
  };

  const fetchStreetsCallback = (value, callback) => {
    if (value && value.length >= 2) {
      let streetsOptionList = [];
      let payload;
      if (value) {
        payload = {
          city: values['municipalityName'],
          searchQuery: value,
        };
      } else {
        payload = {
          city: values['municipalityName'],
        };
      }

      getStreets(payload).then(res => {
        res.data.forEach(street => {
          streetsOptionList.push({
            value: street[`name_${i18next.language.toLowerCase()}`]
              ? street[`name_${i18next.language.toLowerCase()}`]
              : street.name,
            label: street[`name_${i18next.language.toLowerCase()}`]
              ? street[`name_${i18next.language.toLowerCase()}`]
              : street.name,
          });
        });
        setDefaultStreets(streetsOptionList);
        callback(streetsOptionList);
      });
    }
  };

  useEffect(() => {
    if (values['postalCode'] === '') {
      setValues({});
      setSelected({ city: {}, street: {} });
      setDefaultStreets(null);
    }
    setSelected({
      city: {
        value: values['municipalityName'],
        label: values['municipalityName'],
      },
      street: {
        value: values['streetName'],
        label: values['streetName'],
      },
    });
  }, [values]);

  const onSubmit = async values => {
    if (Object.keys(errors).length === 0) {
      if (sendStep === 'pending') {
        // Don't allow to submit twice
        return;
      }
      setSendStep('pending');
      setAddressData(values);
      if (sendStep !== 'success') {
        sendPersonalData()
          .then(() => {
            setSendStep('success');
          })
          .catch(error => {
            setSendStep('error');
            console.error(error.response);
          });
      }
    }
  };

  const handleSelected = value => {
    let newValues;
    if (value.name === 'municipalityName') {
      const newCity = citiesList.filter(city => city.city === value.value);
      newValues = {
        postalCode: newCity[0].zipcode,
        [value.name]: value.value,
      };
    } else {
      newValues = { ...values, [value.name]: value.value };
    }
    setValues(newValues);
  };

  useEffect(() => {
    let btnTextTemp = `${t(`${getComponentName()}.btnText.pending`)} `;
    let _btnText;
    switch (sendStep) {
      case 'pending':
        _btnText = btnTextTemp;
        break;
      case 'success':
        _btnText = t(`${getComponentName()}.btnText.success`);

        addChatboxAnswer(
          t(`${getComponentName()}.interactiveMsg`, {
            streetName: ` ${values['streetName']
              .toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')} `,
            streetNumber: `${values['streetNumber']} ${
              values['boxNumber'] ? `/${values['boxNumber']}` : ``
            } `,
            cityName: ` ${values['municipalityName']
              .toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')} `,
          }),
        );
        history.push(getNextPath());
        break;
      case 'error':
        _btnText = t(`${getComponentName()}.btnText.error`);
        break;
      default:
        _btnText = t(`${getComponentName()}.btnText.default`);
        break;
    }

    if (_btnText !== btnText) {
      setBtnText(_btnText);
    }
  }, [sendStep]);
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
    addChatboxQuestion(
      t(`${getComponentName()}.question`, {
        firstName: getPersonalData().first_name,
      }),
    );

    personalDataAddress
      ? (setFullAddress(false),
        setFullAddressInput(true),
        fetchCitiesByValue({
          searchQuery: personalDataAddress['city'].substring(0, 3),
        }),
        setValues({
          postalCode: personalDataAddress.zipcode,
          municipalityName: capitalize(personalDataAddress.city),
          streetName: capitalize(personalDataAddress.street),
          streetNumber: personalDataAddress.housenr,
          boxNr: personalDataAddress.boxnr,
        }))
      : setFullAddress(true);
  }, []);

  const handlZipcodeValidation = () => {
    if (
      values['postalCode'] &&
      !values['postalCode'].toString().match(regex.zipcode)
    ) {
      setErrors({ postalCode: t(`AdresInput.errors.invalid`) });
    } else if (values['postalCode'] < 1000) {
      setErrors({ postalCode: t(`AdresInput.errors.short`) });
    } else if (values['postalCode'] > 9999) {
      setErrors({ postalCode: t(`AdresInput.errors.long`) });
    } else {
      if (tempPostCode !== values['postalCode']) {
        setTempPostCode(values['postalCode']);
        setValues({
          postalCode: values['postalCode'],
        });
        setDefaultStreets(null);
      }
      if (values['postalCode'] && values['postalCode'].length === 4) {
        fetchCitiesByValue({ zipcode: parseInt(values['postalCode']) });
      }
      setErrors({});
    }
  };

  const [debounceFetchCities] = useDebouncedCallback(fetchCitiesByValues, 500);
  const [debounceFetchStreets] = useDebouncedCallback(
    fetchStreetsCallback,
    500,
  );

  return (
    <Layout>
      <Container>
        <StyledLeftSideV2>
          <LouiseLeftSide
            question={t(`${getComponentName()}.question`, {
              firstName: getPersonalData().first_name,
            })}
          />
        </StyledLeftSideV2>
        <StyledRightSide>
          <FormWrapper>
            {fullAddress ? (
              <AddressInputSearchFullAddress
                setDisabled={() => setFullAddressInput(true)}
                addressValues={addressSettedValues => {
                  setValues(addressSettedValues);
                  fetchCitiesByValue({
                    searchQuery: addressSettedValues[
                      'municipalityName'
                    ].substring(0, 3),
                  });
                }}
                disabled={manualInput.allInputs}
                errors={fullAddresErrors => {
                  setErrors(fullAddresErrors);
                }}
                clickedSubmit={buttonClicked}
                fullAddressError={errors.fullAdrress}
              />
            ) : (
              <></>
            )}
            {!fullAddressInput && !manualInput.allInputs ? (
              <></>
            ) : (
              <AddressInput setCenter={fullAddress}>
                <Box>
                  <ZipcodeInput
                    error={errors.postalCode}
                    onChange={handleChange}
                    type="number"
                    name="postalCode"
                    max={9999}
                    min={1000}
                    dataTestId="underwriting_address_postalCode"
                    value={values ? values['postalCode'] : ''}
                    touched={touched}
                    onBlur={() => handlZipcodeValidation()}
                    placeholder={t(`AdresInput.manual.zipcode`)}
                  >
                    {t(`AdresInput.manual.zipcode`)}
                  </ZipcodeInput>
                  {manualInput.notSelect ? (
                    <TextInput
                      error={errors.municipalityName}
                      onChange={handleChange}
                      type="text"
                      name="municipalityName"
                      dataTestId="underwriting_address_city"
                      value={
                        values['municipalityName']
                          ? values['municipalityName']
                          : selected.city
                          ? selected.city.value
                          : null
                      }
                    >
                      {t(`AdresInput.manual.city`)}
                    </TextInput>
                  ) : (
                    <SelectInput
                      async
                      cacheOptions={false}
                      defaultOptions={defaultCity}
                      error={errors.municipalityName}
                      id="municipalityName"
                      name="municipalityName"
                      loadOptions={debounceFetchCities}
                      onSelected={value => handleSelected(value)}
                      data-test-id="underwriting_address_city"
                      value={selected.city}
                      noOptionsMessage={() => t('no cities found')}
                    >
                      {t(`AdresInput.manual.city`)}
                    </SelectInput>
                  )}
                </Box>
                {manualInput.notSelect ? (
                  <TextInput
                    error={errors.streetName}
                    onChange={handleChange}
                    type="text"
                    name="streetName"
                    dataTestId="underwriting_address_streetName"
                    value={values['streetName']}
                    placeholder={t(`AdresInput.manual.street`)}
                    disabled={!values['municipalityName']}
                  >
                    {t(`AdresInput.manual.street`)}
                  </TextInput>
                ) : (
                  <SelectInput
                    async
                    defaultOptions={defaultStreets}
                    cacheOptions={false}
                    error={errors.streetName}
                    id="streetName"
                    name="streetName"
                    loadOptions={debounceFetchStreets}
                    onSelected={value => handleSelected(value)}
                    data-test-id="underwriting_address_streetName"
                    disabled={!values['municipalityName']}
                    value={selected.street}
                  >
                    {t(`AdresInput.manual.street`)}
                  </SelectInput>
                )}{' '}
                <NumberBox>
                  <StreetNumberInput
                    error={errors.streetNumber}
                    onChange={handleChange}
                    type="text"
                    name="streetNumber"
                    dataTestId="underwriting_address_streetNumber"
                    disabled={!values['streetName']}
                    value={
                      values['streetNumber']
                        ? values['streetNumber']
                        : values['streetNumber']
                    }
                    placeholder={t(`AdresInput.manual.nr`)}
                  >
                    {t(`AdresInput.manual.nr`)}
                  </StreetNumberInput>
                  <TextInput
                    error={errors.boxNumber}
                    onChange={handleChange}
                    type="string"
                    name="boxNumber"
                    dataTestId="underwriting_address_boxNumber"
                    disabled={!values['streetName']}
                    value={values['boxNumber'] ? values['boxNumber'] : ''}
                    placeholder={t(`AdresInput.manual.boxnrPlaceholder`)}
                  >
                    {t(`AdresInput.manual.boxnrPlaceholder`)}
                  </TextInput>
                </NumberBox>
              </AddressInput>
            )}{' '}
          </FormWrapper>
          <ButtonContainer>
            {!manualInput.allInputs ? (
              <MyAddressNotFoundLink
                dataTestId="underwriting_personal_submit"
                type="button"
                onClick={() => {
                  setManualInput({ notSelect: true, allInputs: true });
                  setFullAddress(false);
                }}
              >
                {t(content.answers[0].title)}
              </MyAddressNotFoundLink>
            ) : null}
            <ConfirmButton
              dataTestId="underwriting_personal_submit"
              onClick={() => {
                setButtonClicked(true);
                handleSubmit(onSubmit);
              }}
              type="submit"
            >
              {t(content.answers[1].title)}
            </ConfirmButton>
          </ButtonContainer>
        </StyledRightSide>
      </Container>
    </Layout>
  );
};

const StyledLeftSideV2 = styled(LeftSide)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  padding: 6rem 2rem 2rem;
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;

const MyAddressNotFoundLink = styled.p`
  text-decoration: underline;
  color: ${({ theme }) => theme.brand.primary};
  cursor: pointer;
  line-height: 1.6rem;
`;
const StyledRightSide = styled(RightSide)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.3;
`;

const FormWrapper = styled.div`
  position: relative;
`;

const AddressInput = styled.div`
  height: ${props => (props.setCenter ? 75 : 95)}%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${props => (props.setCenter ? '6.5rem' : '2rem')};
`;

const SelectInput = styled(SearchSelectInput)`
  margin-top: 0.7rem;

  & > label {
    & > div {
      margin-top: 1.2rem;
      margin-bottom: 0.8rem;
    }
  }
`;

const ButtonContainer = styled.div`
  border-radius: 3rem;
  margin: 3rem 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 1;
`;

const ConfirmButton = styled(Button)`
  margin-bottom: 0;
  margin-left: auto;
  margin-right: 0;
`;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`;

const ZipcodeInput = styled(TextInput)`
  width: 50%;
  margin-right: 3rem;
  margin-bottom: 0;
`;

const StreetNumberInput = styled(TextInput)`
  width: 30%;
  margin-right: 3rem;
`;

const NumberBox = styled.div`
  display: flex;
  align-items: baseline;
`;

AdresInput.propTypes = {
  history: shape().isRequired,
};

export default withRouter(AdresInput);
