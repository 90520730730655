import * as Yup from 'yup';
import i18n from '../../i18n';
import regex from './regexService';

export const string_DEPRECATED = {
  required: Yup.string().required(i18n.t(`required`)),
  notRequired: Yup.string().nullable(),
};

export const number_DEPRECATED = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`)),
};

export const date_DEPRECATED = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
    i18n.t(`not a valid date`),
  )
  .required(i18n.t(`required`));

export const dateWithOutYear_DEPRECATED = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/,
    i18n.t(`not a valid date`),
  )
  .required(i18n.t(`required`));

export const firstName_DEPRECATED = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const lastName_DEPRECATED = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const companyName_DEPRECATED = Yup.string()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const cbe_DEPRECATED = Yup.string()
  .matches(/0[0-9]{9}/, i18n.t(`incorrect format`))
  .required(i18n.t(`required`));

export const email_DEPRECATED = Yup.string()
  .email('email')
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const password_DEPRECATED = Yup.string()
  .required(i18n.t(`required`))
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const year_DEPRECATED = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
};

export const licensePlate_DEPRECATED = Yup.string()
  .matches(/^[A-Za-z0-9 -]{1,20}$/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`));

export const vinNumber_DEPRECATED = Yup.string()
  .matches(/^[A-Z0-9]{17,17}$/, i18n.t(`incorrect format`))
  .required(i18n.t(`required`));

export const captcha_DEPRECATED = Yup.string(i18n.t(`required`))
  .matches(/[0-9a-zA-Z_-]{40}/)
  .nullable()
  .required(i18n.t(`required`));

export const website_DEPRECATED = Yup.string(i18n.t(`required`))
  .matches()
  .nullable()
  .required(i18n.t(`required`));

export const numberplate_DEPRECATED = Yup.string(i18n.t(`required`));

export const nationalRegisterNr_DEPRECATED = Yup.string(
  i18n.t(`required`),
).matches(/^\d{11}$/, i18n.t(`incorrect format`));

// NEW SYSTEM DYNAMIC LANGUAGES

export const string = messagesObject => {
  const { required } = messagesObject;
  return {
    required: Yup.string().required(required),
    notRequired: Yup.string().nullable(),
  };
};

export const number = messagesObject => {
  const { required, typeError } = messagesObject;
  return {
    required: Yup.number()
      .typeError(typeError)
      .required(required),
    notRequired: Yup.number()
      .nullable()
      .typeError(typeError),
  };
};

export const date = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
      matches,
    )
    .required(required);
};

export const dateReversed = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(
      // /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
      // /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
      /^\d{1,2}\/\d{1,2}\/\d{4}$/,
      matches,
    )
    .required(required);
};

export const dateDash = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(/^\d{4}-\d{1,2}-\d{1,2}$/, matches)
    .required(required);
};

export const dateWithOutYear = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/, matches)
    .required(required);
};

export const firstName = messagesObject => {
  const { min, max, required } = messagesObject;
  return Yup.string()
    .required(required)
    .min(1, min)
    .max(50, max);
};

export const lastName = messagesObject => {
  const { min, max, required } = messagesObject;
  return Yup.string()
    .required(required)
    .min(1, min)
    .max(50, max);
};

export const companyName = messagesObject => {
  const { min, max, required } = messagesObject;
  return Yup.string()
    .required(required)
    .min(1, min)
    .max(50, max);
};

export const cbe = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(/0[0-9]{9}/, matches)
    .required(required);
};

export const email = messagesObject => {
  const { email, min, max, required } = messagesObject;
  return Yup.string()
    .email(email)
    .required(required)
    .min(2, min)
    .max(100, max);
};

export const password = messagesObject => {
  const { min, max, required } = messagesObject;
  return Yup.string()
    .required(required)
    .min(2, min)
    .max(100, max);
};

export const year = messagesObject => {
  const { typeError, min, max, required } = messagesObject;
  return {
    required: Yup.number()
      .typeError(typeError)
      .required(required)
      .min(1885, min)
      .max(2030, max),
    notRequired: Yup.number()
      .nullable()
      .typeError(typeError)
      .min(1885, min)
      .max(2030, max),
  };
};

export const licensePlate = messagesObject => {
  const { matches, min, max } = messagesObject;
  return Yup.string()
    .matches(/^[A-Za-z0-9 -]{1,20}$/, matches)
    .min(1, min)
    .max(20, max);
};

export const vinNumber = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(/^[A-Z0-9]{17,17}$/, matches)
    .required(required);
};

export const captcha = messagesObject => {
  const { matches, required } = messagesObject;
  return Yup.string()
    .matches(/[0-9a-zA-Z_-]{40}/, matches)
    .nullable()
    .required(required);
};

export const website = messagesObject => {
  const { required } = messagesObject;
  return Yup.string()
    .matches()
    .nullable()
    .required(required);
};

export const numberplate = messagesObject => {
  const { required } = messagesObject;
  return Yup.string(required);
};

export const nationalRegisterNr = messagesObject => {
  const { required, matches } = messagesObject;
  return Yup.string(required).matches(/^\d{11}$/, matches);
};

export const streetName = messagesObject => {
  const { min, max, matches, required } = messagesObject;
  return Yup.string()
    .min(1, min)
    .max(150, max)
    .matches(regex.street, matches)
    .required(required);
};

export const streetNumber = messagesObject => {
  const { max, matches, required } = messagesObject;
  return Yup.string()
    .required(required)
    .max(20, max)
    .matches(regex.houseNr, matches);
};

export const boxNumber = messagesObject => {
  const { max, matches } = messagesObject;
  return Yup.string()
    .nullable()
    .max(20, max)
    .matches(regex.boxNr, matches);
};

export const postalCode = messagesObject => {
  const { required } = messagesObject;
  return Yup.string().required(required);
};

export const bonusMalus = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(-2, i18n.t(`bm too low`))
  .max(22, i18n.t(`bm too high`))
  .nullable()
  .required(i18n.t(`required`));

export const municipalityName = messagesObject => {
  const { min, max, matches, required } = messagesObject;
  return Yup.string()
    .min(1, min)
    .max(100, max)
    .matches(regex.city, matches)
    .required(required);
};
