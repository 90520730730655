import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tabs, LoadingSpinner } from 'wg-fe-ui';

import CRMRiskObjectTabPage from './CRMRiskObjectTabPage';

import { getRiskObjectsByCallType } from '../services/apiRouterService';

const CRMRiskObjectTabs = ({
  distributionId,
  riskObjects,
  prospectId,
  leadId,
  type,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRiskObjectPage, setSelectedRiskObjectPage] = useState();
  const [carRiskObjects, setCarRiskObjects] = useState();
  const [twoWheelerRiskObjects, setTwoWheelerRiskObjects] = useState();
  const [residenceRiskObjects, setResidenceRiskObjects] = useState();
  const [familyRiskObjects, setFamilyRiskObjects] = useState();
  const [teacherRiskObjects, setTeacherRiskObjects] = useState();

  useEffect(() => {
    if (riskObjects !== null && riskObjects !== undefined) {
      Object.keys(riskObjects).forEach(key => {
        if (riskObjects[key] > 0) {
          getRiskObjects(key, type);
        }
      });
    }
  }, []);

  useEffect(() => {
    renderRiskObjectPage();
  }, [
    carRiskObjects,
    twoWheelerRiskObjects,
    residenceRiskObjects,
    familyRiskObjects,
    teacherRiskObjects,
  ]);

  const getRiskObjects = async (riskType, type) => {
    let [response, status] = await getRiskObjectsByCallType(
      distributionId,
      prospectId && leadId ? leadId : prospectId || leadId,
      riskType,
      type,
    );

    if (status !== 200) {
      return console.error(response);
    }

    switch (riskType) {
      case 'cars':
        await setCarRiskObjects(response);
        setIsLoading(false);
        break;
      case 'two_wheelers':
        await setTwoWheelerRiskObjects(response);
        setIsLoading(false);
        break;
      case 'residences':
        await setResidenceRiskObjects(response);
        setIsLoading(false);
        break;
      case 'families':
        await setFamilyRiskObjects(response);
        setIsLoading(false);
        break;
      case 'teachers':
        await setTeacherRiskObjects(response);
        setIsLoading(false);
        break;
      default:
        break;
    }
  };

  const checkArraysInObjectIsEmpty = object => {
    if (object != null || undefined) {
      return (
        Object.keys(object).filter(function(key) {
          return object[key] > 0;
        }).length === 0
      );
    }
  };

  const renderRiskObjectPage = () => {
    if (selectedRiskObjectPage === 'cars' && carRiskObjects !== undefined) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={carRiskObjects}
          type="cars"
          prospectId={prospectId}
          leadId={leadId}
        />
      );
    } else if (
      selectedRiskObjectPage === 'two_wheelers' &&
      twoWheelerRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={twoWheelerRiskObjects}
          type="two_wheelers"
          prospectId={prospectId}
          leadId={leadId}
        />
      );
    } else if (
      selectedRiskObjectPage === 'residences' &&
      residenceRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={residenceRiskObjects}
          type="residences"
          prospectId={prospectId}
          leadId={leadId}
        />
      );
    } else if (
      selectedRiskObjectPage === 'families' &&
      familyRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={familyRiskObjects}
          type="families"
          prospectId={prospectId}
          leadId={leadId}
        />
      );
    } else if (
      selectedRiskObjectPage === 'teachers' &&
      teacherRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={teacherRiskObjects}
          type="teachers"
          prospectId={prospectId}
          leadId={leadId}
        />
      );
    }
  };

  if (riskObjects !== null && riskObjects !== undefined) {
    if (!checkArraysInObjectIsEmpty(riskObjects)) {
      return (
        <>
          <StyledTabsContainer>
            <Tabs onSelect={selected => setSelectedRiskObjectPage(selected)}>
              {Object.entries(riskObjects).map(([key, value]) => {
                if (value === 0) return null;

                return (
                  <Tabs.Item key={key} name={key}>
                    <span
                      data-test-id={`customer_detail_risk_object_tab_${key}`}
                    >
                      {t(key)}
                    </span>
                  </Tabs.Item>
                );
              })}
            </Tabs>
            {isLoading ? (
              <LoadingContainer data-test-id="loading_spinner">
                <LoadingSpinner />
              </LoadingContainer>
            ) : (
              renderRiskObjectPage()
            )}
          </StyledTabsContainer>
        </>
      );
    } else {
      return (
        <StyledRiskStatusMessage data-test-id="data-test-id={`customer_detail_risk_object_empty">
          {t('No risk objects found')}
        </StyledRiskStatusMessage>
      );
    }
  } else {
    return (
      <StyledRiskStatusMessage>
        {t('Something went wrong while retrieving the risk objects')}
      </StyledRiskStatusMessage>
    );
  }
};

const StyledRiskStatusMessage = styled.p`
  margin-top: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTabsContainer = styled.div`
  width: 100%;
`;

CRMRiskObjectTabs.propTypes = {
  distributionId: string.isRequired,
  prospectId: string.isRequired,
  riskObjects: shape({}).isRequired,
};

export default CRMRiskObjectTabs;
