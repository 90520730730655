import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ChromeDisclaimer = () => {
  const { t } = useTranslation();
  const [isClosed, setIsClosed] = useState(false);
  return (
    <Container closed={isClosed}>
      <p
        dangerouslySetInnerHTML={{
          __html: t(
            'For an optimal user experience, it is best to use Google Chrome',
          ),
        }}
      />
      <CloseButton
        onClick={() => {
          setIsClosed(true);
        }}
      >
        X
      </CloseButton>
    </Container>
  );
};

const Container = styled.div`
  background-color: ${({ theme }) => theme.brand.lighter};
  color: black;
  font-size: 1.8rem;
  height: 5rem;
  width: 100%;
  position: absolute;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: center;
  padding: 0 3rem;
  align-items: center;
  animation: ${({ closed }) => (closed ? 'close 0.5s' : 'open 1s')};
  animation-fill-mode: forwards;

  & > p > span {
    font-weight: bold;
  }

  @keyframes open {
    0% {
      transform: translateY(-5rem);
    }
    50% {
      transform: translateY(-5rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes close {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-5rem);
    }
  }
`;

const CloseButton = styled.a`
  text-decoration: none;
  color: black;
  position: absolute;
  right: 3rem;
  font-weight: bold;
  cursor: pointer;
`;

export default ChromeDisclaimer;
