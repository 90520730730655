import React, { useState, useEffect } from 'react';
import { node, string, func, bool } from 'prop-types';
import styled from 'styled-components';

const CheckBoxContainer = ({
  children,
  label,
  toggleAllCheckBoxes,
  defaultChecked,
  ...otherProps
}) => {
  const [CheckedAll, setCheckedAll] = useState(defaultChecked);

  useEffect(() => {
    setCheckedAll(defaultChecked);
  }, [defaultChecked]);

  const toggleCheckedAll = () => {
    toggleAllCheckBoxes(
      React.Children.toArray(children).map(item => item.props.name),
      !CheckedAll,
    );
    setCheckedAll(!CheckedAll);
  };

  return (
    <CheckBoxContainerWrapper {...otherProps}>
      <CheckBoxContainerHeader>
        <span className="title">
          {label}
          {label !== '' ? ' -' : ''}{' '}
        </span>
        <CheckBoxContainerToggler onClick={toggleCheckedAll}>
          {CheckedAll ? 'Deselect all' : 'Select all'}
        </CheckBoxContainerToggler>
      </CheckBoxContainerHeader>
      {children}
    </CheckBoxContainerWrapper>
  );
};

const CheckBoxContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font};
`;

const CheckBoxContainerHeader = styled.div`
  margin-bottom: 7px;

  .title {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.highlight};
  }
`;

const CheckBoxContainerToggler = styled.a`
  font-size: 1rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.typo.selected};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

CheckBoxContainer.propTypes = {
  children: node,
  label: string,
  toggleAllCheckBoxes: func,
  defaultChecked: bool,
};

export default CheckBoxContainer;
