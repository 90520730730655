/* stylelint-disable declaration-no-important */
import React from 'react';
import Section from './Section';
import styled from 'styled-components';

import MailIcon from '../../../../assets/images/dashboard/MailIcon';
import PhoneIcon from '../../../../assets/images/dashboard/PhoneIcon';
import { string, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCurrentTheme } from '../../../store';

const ClientInfo = ({
  name,
  street,
  housenr,
  zipcode,
  city,
  telephone,
  email,
  age,
  birth,
}) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Section.Subtitle>{t('History.personal.title')}</Section.Subtitle>
      <Section.Content>
        <Header>
          <div>
            <p>{t('History.personal.name')}</p>
            <span>{name}</span>
          </div>
          <ContactButtons>
            <a
              data-test-id="broker_history_detail_personal_email"
              href={`mailto:${email}`}
            >
              <MailIcon color={getCurrentTheme().brand.primary} />
            </a>
            <a
              data-test-id="broker_history_detail_personal_phone"
              href={`tel:${telephone}`}
            >
              <PhoneIcon color={getCurrentTheme().brand.primary} />
            </a>
          </ContactButtons>
        </Header>
        <Container>
          <div>
            <Label>{t('History.personal.address')}</Label>
            <Value>
              {street} {housenr}, {zipcode} {city}
            </Value>
          </div>
          <div>
            <Label>{t('History.personal.email')}</Label>
            <Value>{email}</Value>
          </div>
          <div>
            <Label>{t('History.personal.phone')}</Label>
            <Value>{telephone}</Value>
          </div>
          <div>
            <Label>{t('History.personal.age')}</Label>
            <Value>
              {age} {t('History.personal.years')} ({birth})
            </Value>
          </div>
        </Container>
      </Section.Content>
    </Section>
  );
};

const ContactButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;

  & > a {
    margin-right: 3.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  & > div {
    width: 45%;
    margin-bottom: 1.5rem;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};

  & > div {
    width: 45%;

    &:nth-child(2) {
      display: flex;
      justify-content: space-between;
    }
  }

  & p {
    font-size: 1.4rem;
  }

  & span {
    font-size: 2rem;
    font-weight: bold;
  }
`;

const Label = styled.p`
  color: ${({ theme }) => theme.typo.interactive};
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
`;

const Value = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.typo.highlight};
`;

ClientInfo.propTypes = {
  name: '',
  street: '',
  housenr: '',
  zipcode: '',
  city: '',
  telephone: '',
  email: '',
  age: 0,
  birth: '',
};

ClientInfo.propTypes = {
  name: string,
  street: string,
  housenr: string,
  zipcode: number,
  city: string,
  telephone: string,
  email: string,
  age: number,
  birth: string,
};

export default ClientInfo;
