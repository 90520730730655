import React from 'react';
import { shape } from 'prop-types';

const MarketIconFire = ({ colors }) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 72 84"
  >
    <path
      style={{
        fill: colors.brand.primary,
        stroke: colors.brand.dark,
        strokeWidth: 3,
        strokeLineJoin: `round`,
      }}
      d="M48.4,18.8c5.7,5.3,11.9,10.9,14.7,21.4 M48.4,18.8L48.4,18.8c-0.1-0.1-0.1-0.1-0.2-0.2c-5.1-4.7-10-9.2-11.5-16.9c-5.6,5.9-9.5,14.8-10,22.6c-0.3,5.5,0.6,11.6,1.4,15.6l0.4,2c-8.7,0.5-13.3-10.9-13.3-10.9c-1.3,1.5-1.8,2.3-2.9,4.5c-0.6,1.4-1.1,2.9-1.4,4.4c-1.9,8.4-0.3,17.4,4.9,24.2c4.6,6,12.7,11.6,26.9,9.9h0v0h0h0l0,0h0h0l0,0h0l0,0l0,0h0h0l0,0h0h0v0h0h0h0l0,0h0h0l0,0l0,0h0h0l0,0h0h0l0,0l0,0h0h0l0,0h0h0h0v0h0h0h0l0,0h0h0l0,0l0,0h0h0h0h0l0,0l0,0h0h0l0,0h0h0h0l0,0l0,0h0h0h0h0v0h0h0h0h0l0,0h0l0,0h0h0l0,0h0h0l0,0h0h0l0,0h0h0h0l0,0h0l0,0h0h0h0l0,0h0h0l0,0h0h0l0,0h0h0h0l0,0h0l0,0h0h0h0h0h0v0h0h0h0h0h0l0,0h0l0,0h0h0h0l0,0h0h0h0h0h0v0h0h0h0h0h0l0,0h0h0h0h0v0h0h0h0h0h0l0,0h0h0h0h0h0l0,0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0l0,0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0l0,0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0l0,0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0l0,0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0v0h0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0h0h0h0h0l0,0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0h0h0h0l0,0l0,0h0h0h0h0l0,0h0h0h0h0l0,0h0h0l0,0h0h0l0,0h0h0h0h0h0l0,0h0l0,0h0h0h0l0,0h0h0h0h0h0v0h0h0h0h0h0l0,0h0h0l0,0h0h0l0,0h0h0h0l0,0h0l0,0h0h0h0h0h0v0h0h0h0h0h0v0h0h0h0h0h0l0,0l0,0h0h0h0h0l0,0l0,0h0h0h0h0l0,0l0,0h0h0h0l0,0h0l0,0h0h0l0,0h0h0h0l0,0l0,0h0h0h0l0,0h0l0,0h0h0l0,0h0h0h0v0h0h0h0l0,0h0h0l0,0l0,0h0h0h0l0,0h0l0,0h0l0,0h0h0l0,0h0l0,0h0l0,0h0h0l0,0h0h0v0h0h0l0,0h0h0h0v0h0h0l0,0h0h0l0,0h0l0,0c0,0,0,0,0,0C57,71.4,66.5,57.4,63.8,43c-0.1-0.3-0.2-1-0.4-1.6c-0.1-0.3-0.2-0.6-0.2-0.8l-0.1-0.2l0-0.1l0,0"
    />
  </svg>
);

MarketIconFire.propTypes = {
  colors: shape().isRequired,
};

export default MarketIconFire;
