import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

import Section from './Section';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';
import suvcar from '../../assets/images/car/suv.jsx';
import sportscar from '../../assets/images/car/sportsCar.jsx';
import cabriocar from '../../assets/images/car/cabrio.jsx';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryAutomotiveCarOptions = ({ carOptions }) => {
  const { is_jeep, is_cabrio, is_sportscar } = carOptions || {};
  const { t } = useTranslation();

  const interestIcons = {
    sportsCar: sportscar,
    SUV: suvcar,
    Cabrio: cabriocar,
  };

  return (
    <Section>
      <Section.Subtitle>{t('Category')}</Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_automotive_section_car_category">
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('Cabrio')}
          checked={is_cabrio}
          disabled={true}
          icon={interestIcons['Cabrio']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('SUV')}
          checked={is_jeep}
          disabled={true}
          icon={interestIcons['SUV']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('Sports Car')}
          checked={is_sportscar}
          disabled={true}
          icon={interestIcons['sportsCar']}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

DashboardBrokerHistoryAutomotiveCarOptions.defaultProps = {};

DashboardBrokerHistoryAutomotiveCarOptions.propTypes = {
  carOptions: shape().isRequired,
};

export default DashboardBrokerHistoryAutomotiveCarOptions;
