import React from 'react';
import { string } from 'prop-types';

const Surround = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 342.7 233.46">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M291,233.46H342.7A31.11,31.11,0,0,0,293.65,208v15.88A19,19,0,0,1,291,233.46Z"
        />
        <path
          fill={color}
          d="M281,64.29,253.6,46.94V11.87a6.59,6.59,0,0,0-6.6-6.6H220.9a6.6,6.6,0,0,0-6.61,6.6v9.9L181.65,1a6.51,6.51,0,0,0-3.53-1,6.6,6.6,0,0,0-3.52,1L109.13,42.38a54.74,54.74,0,0,1-13.66,80.37,9.56,9.56,0,0,1,1.84,1.44l9,9a9.58,9.58,0,0,1,0,13.56l-23.61,23.6v18.23a56.77,56.77,0,0,1,21.9,44.85h91.89V162.4h49.86v71.06h28.07a9.47,9.47,0,0,0,6.14-2.37,30.88,30.88,0,0,1,3.47-12.1V69.84A6.58,6.58,0,0,0,281,64.29Z"
        />
        <path
          fill={color}
          d="M284.06,223.85V219a30.88,30.88,0,0,0-3.47,12.1A9.45,9.45,0,0,0,284.06,223.85Z"
        />
        <path
          fill={color}
          d="M61.83,69.84a16.12,16.12,0,0,1,7.54-13.68L94,40.61A45.24,45.24,0,0,0,65.71,30.5a45.65,45.65,0,0,0-7.44,90.7v66.07A47.15,47.15,0,0,0,0,233.46H64.51a19,19,0,0,1-2.68-9.61Z"
        />
        <path
          fill={color}
          d="M73.15,193.54V166.41L99.56,140l-9-9L73.15,148.34V121.2a45.79,45.79,0,0,0,38.28-45A45.15,45.15,0,0,0,101,47.54L74.49,64.27a6.59,6.59,0,0,0-3.07,5.57v154A9.61,9.61,0,0,0,81,233.46H95A47.39,47.39,0,0,0,73.15,193.54Z"
        />
        <path
          fill={color}
          d="M66.63,69.84a11.34,11.34,0,0,1,5.29-9.62l26-16.4A46.36,46.36,0,0,0,94,40.61L69.37,56.16a16.12,16.12,0,0,0-7.54,13.68v154a19,19,0,0,0,2.68,9.61h6a14.25,14.25,0,0,1-3.84-9.61Z"
        />
        <path
          fill={color}
          d="M71.42,223.85v-154a6.59,6.59,0,0,1,3.07-5.57L101,47.54a46.28,46.28,0,0,0-3.1-3.72L71.92,60.22a11.34,11.34,0,0,0-5.29,9.62v154a14.25,14.25,0,0,0,3.84,9.61H81A9.61,9.61,0,0,1,71.42,223.85Z"
        />
      </g>
    </g>
  </svg>
);

Surround.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Surround.propTypes = {
  title: string,
  color: string,
};

export default Surround;
