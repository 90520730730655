import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { node, bool } from 'prop-types';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { getBrokerDistribution } from '../../../../services/brokerDataService';
// import { getPublicBrokerData } from '../../../../services/brokerDataService';

const LayoutSignature = ({ children }) => {
  const [logo, setLogo] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    getBrokerLogo();
  }, [sessionStorage.getItem('broker_id')]);

  async function getBrokerLogo() {
    const { links } = await getBrokerDistribution();
    setLogo(links.filter(el => el.rel === 'logo_fe')[0].href);
  }

  return (
    <Background>
      <MainContainer>
        <Header>
          <Logo src={logo} alt="WeGroup" />
          <GoBackToDashboard
            to="/dashboard"
            data-test-id="underwriting_dashboard_nav"
            title="Dashboard"
          >
            {t('Go to dashboard')}
          </GoBackToDashboard>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </MainContainer>
    </Background>
  );
};

const GoBackToDashboard = styled(Link)`
  align-items: center;
  background-color: ${({ theme }) => theme.brand.primary};
  border-radius: 3px;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
  border: 1px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  font-weight: 550;
  height: 5rem;
  justify-content: center;
  text-decoration: none;
  width: 20rem;
`;

const Background = styled.main`
  background-color: ${({ theme }) => theme.brand.primary};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.font};
  font-size: 1.5rem;
  padding: 4rem 0;
  min-height: 100vh;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  max-height: 7rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 3.8rem;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  max-width: 120rem;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

LayoutSignature.defaultProps = {
  noMatches: false,
};

LayoutSignature.propTypes = {
  children: node.isRequired,
  noMatches: bool,
};

export default LayoutSignature;
