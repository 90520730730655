import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

import Section from './Section';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';

import Automatic_emergency_braking from '../../assets/images/drive_assist/automatic_emergency_braking';
import Adaptive_cruise_control from '../../assets/images/drive_assist/adaptive_cruise_control';
import Lane_support from '../../assets/images/drive_assist/lane_support';
import Blind_spot_check from '../../assets/images/drive_assist/blind_spot_check';
import Attention_assist from '../../assets/images/drive_assist/attention_assist';
import Ecall from '../../assets/images/drive_assist/ecall';
import Automatic_parking from '../../assets/images/drive_assist/automatic_parking';
import Parking_aid from '../../assets/images/drive_assist/parking_aid';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryAutomotiveCarOptions = ({ carOptions }) => {
  const {
    automaticEmergencyBraking,
    adaptiveCruiseControl,
    laneSupport,
    blindSpotCheck,
    attentionAssist,
    ecall,
    automaticParking,
  } = carOptions || {};
  const { t } = useTranslation();

  const interestIcons = {
    automaticEmergencyBraking: Automatic_emergency_braking,
    adaptiveCruiseControl: Adaptive_cruise_control,
    laneSupport: Lane_support,
    blindSpotCheck: Blind_spot_check,
    attentionAssist: Attention_assist,
    ecall: Ecall,
    automaticParking: Automatic_parking,
    parkingAid: Parking_aid,
  };

  return (
    <Section>
      <Section.Subtitle>
        {t('History.automotive.driverAssistance')}
      </Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_automotive_section_driver_assistance_systems">
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.automatic_emergency_braking')}
          checked={automaticEmergencyBraking}
          disabled={true}
          icon={interestIcons['automaticEmergencyBraking']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.adaptive_cruise_control')}
          checked={adaptiveCruiseControl}
          disabled={true}
          icon={interestIcons['adaptiveCruiseControl']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.lane_support')}
          checked={laneSupport}
          disabled={true}
          icon={interestIcons['laneSupport']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.blind_spot_check')}
          checked={blindSpotCheck}
          disabled={true}
          icon={interestIcons['blindSpotCheck']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.attention_assist')}
          checked={attentionAssist}
          disabled={true}
          icon={interestIcons['attentionAssist']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.ecall')}
          checked={ecall}
          disabled={true}
          icon={interestIcons['ecall']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.automatic_parking')}
          checked={automaticParking}
          disabled={true}
          icon={interestIcons['automaticParking']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('CheckCarExtra.questions.parking_aid')}
          checked={automaticParking}
          disabled={true}
          icon={interestIcons['parkingAid']}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

DashboardBrokerHistoryAutomotiveCarOptions.defaultProps = {};

DashboardBrokerHistoryAutomotiveCarOptions.propTypes = {
  carOptions: shape().isRequired,
};

export default DashboardBrokerHistoryAutomotiveCarOptions;
