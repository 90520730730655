/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { ActionButton, H1, H2, Pagination } from 'wg-fe-ui';
import { CircularProgressBar } from 'wg-fe-ui/dist/components/Loaders';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tabs from '../components/ProFlowOffersTabs';
import { retrieveStorageById, patchStorage } from '../services/storeService';
import ProFlowOffersRiskObject from '../components/ProFlowOffersRiskObject';
import ProFlowOffersSelectedOffers from '../components/ProFlowOffersSelectedOffers';
import { useContext } from 'react';
import { getMeData } from '../services/meDataService';
import { getBrokerData, getBrokerCRMName } from '../services/brokerDataService';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import { FlagsProvider } from '../hoc/FlagsProviderHoc.jsx';
import _ from 'lodash';

import {
  batchQuotesForSession,
  getInsuranceCompanyByKey,
  getInsurances,
  getGuarantees,
  getResultsFromFinishedBatchById,
  getAffinityByCompanyKey,
  getAffinityInsurancesByCompanyKey,
  getAffinityInsurancesByCompanyKeyAndId,
} from '../services/apiRouterService';

const ProFlowOffers = props => {
  //Constants
  const { t, i18n } = useTranslation();

  const offerTypes = ['car', 'family', 'legal', 'home'];
  const PAGE_LENGTH = 10;

  const { sessionId, type: riskObjectType } = useParams();
  const [error, setError] = useState();
  const [batchId, setBatchId] = useState('');
  const [progress, setProgress] = useState({ maxAmount: 0, currentAmount: 0 });
  const [brokerCRMName, setBrokerCRMName] = useState();
  const [quotes, setQuotes] = useState();
  const [sanitizedQuotes, setSanitizedQuotes] = useState();
  const [infoInsurances, setInfoInsurances] = useState({});
  const [retrievedData, setRetrievedData] = useState();
  const [connectionErrorMsg, setConnectionErrorMsg] = useState(
    t(
      'Weve encountered an issue connecting with the calculation API Please refresh the page or go through the flow by clicking the button below',
    ),
  );
  const [isFetchingBatch, setIsFetchingBatch] = useState(true);
  const [isPreFetching, setIsPreFetching] = useState(true);
  const [pagination, setPagination] = useState();
  const [filters, setFilters] = useState();
  const [selectedRiskObjects, setSelectedRiskObjects] = useState();

  const configCatClient = useContext(FlagsProvider);
  const { getAnswers } = useContext(ProFlowStorageContext);

  // This ref is used so that the component knows if it should still continue to do API calls
  const shouldCall = useRef(true);

  useEffect(() => {
    getSelectedRiskObjects();
    return () => {
      // If component is unrendered => No more API calls
      shouldCall.current = false;
    };
  }, []);

  useEffect(() => {
    if (!sessionId) return;
    (async () => {
      const answers = await getAnswers(sessionId);
      await batchQuotes(answers);
    })();
  }, [sessionId]);

  useEffect(() => {
    if (!batchId) return;
    retrieveInfoInsurances();
    retrieveBrokerCRMName();
    setTimeout(retrieveQuotes, 1500);
  }, [batchId]);

  useEffect(() => {
    if (!quotes) return;
    const quotesCopy = Object.assign({}, quotes);
    Object.keys(quotesCopy).forEach(riskType => {
      quotesCopy[riskType] = quotesCopy[riskType].map(quote =>
        restructureQuoteBeforePassingDown(quote, infoInsurances),
      );
    });
    setSanitizedQuotes(quotesCopy);
  }, [quotes]);

  useEffect(() => {
    if (!sanitizedQuotes) return;
    setIsPreFetching(false);
    setProgress({
      maxAmount: 1,
      currentAmount: 1,
    });
  }, [sanitizedQuotes]);

  async function getSelectedRiskObjects() {
    const { risk_object } =
      (await retrieveStorageById('pro_flow', sessionId)) || {};
    setSelectedRiskObjects(risk_object?.selected);
  }

  async function batchQuotes(_answers) {
    const { tags } = await retrieveStorageById('pro_flow', sessionId);
    const excludedTags = ['SPECS'];
    const quoteSpecs = Object.assign(
      {},
      ..._answers
        .filter(({ tag }) => excludedTags.includes(tag))
        .map(({ answer, field }) => ({ [field]: answer })),
    );
    const [resp, status] = await batchQuotesForSession(sessionId, {
      tags,
      quote_specifications: {
        currency: 'EUR',
        ...quoteSpecs,
      },
    });

    if (status === 200) {
      const { id } = resp;
      setBatchId(id);
    }

    if (status === 404) {
      setError(
        t(
          'Something went wrong We are aware of this issue and are fixing it as soon as possible',
        ),
      );
    }
  }

  async function retrieveBrokerCRMName() {
    const _brokerCRMName = await getBrokerCRMName();
    setBrokerCRMName(_brokerCRMName);
  }

  async function retrieveQuotes(riskObjectType = selectedRiskObjects[0]) {
    if (batchId) {
      const [resp, status] = await getResultsFromFinishedBatchById(
        batchId,
        `pagelen=${PAGE_LENGTH}&insurance_type=${riskObjectType}`,
      );
      if (status === 404) {
        setTimeout(retrieveQuotes, 1000);
        return;
      }
      const { items, quotes: _quotes, filter_info, pagination } = resp;
      const { total, pending } = _quotes || {};
      if (_quotes && !items) {
        setProgress({
          maxAmount: total,
          currentAmount: total - pending,
        });
        shouldCall.current && setTimeout(retrieveQuotes, 500);
      }
      if (items) {
        //Build quotes structure
        const _quotes = {
          car: [],
          family: [],
          legal: [],
          home: [],
        };

        //Build pagination structure
        const _pagination = Object.assign({}, _quotes);

        //Set first risk object quote data
        _quotes[selectedRiskObjects[0]] = items;
        _pagination[selectedRiskObjects[0]] = pagination;

        setIsFetchingBatch(false);

        //Set other first 10 risk objects
        await Promise.all(
          selectedRiskObjects
            .slice(1, selectedRiskObjects.length)
            .map(async riskObject => {
              const [resp, status] = await getResultsFromFinishedBatchById(
                batchId,
                `pagelen=${PAGE_LENGTH}&insurance_type=${riskObject}`,
              );
              _quotes[riskObject] = resp?.items;
              _pagination[riskObject] = {
                page: resp?.pagination?.page,
                pages: resp?.pagination?.pages,
              };
            }),
        );

        await createFilteringContent(filter_info);
        await setPagination(_pagination);
        await setQuotes(_quotes);
      }
    }
  }

  async function createFilteringContent({ filters, price_max, price_min }) {
    const riskObjects = Object.keys(filters);

    const initalFilterParameters = _.fromPairs(
      riskObjects.map(riskObject => [
        riskObject,
        {
          priceRange: {
            min: price_min,
            max: price_max,
            values: [price_min, price_max],
          },
          insurers: createInsurersBooleanObject(filters?.[riskObject]),
          insurances: createInsurancesBooleanObject(filters?.[riskObject]),
          guarantees: createGuaranteesBooleanObject(filters?.[riskObject]),
        },
      ]),
    );

    await setFilters(initalFilterParameters);
  }

  function createInsurersBooleanObject(filterInsurers = {}) {
    const insurers = Object.keys(filterInsurers);

    // return object of key insurers and true value
    return insurers.reduce(
      (prevInsurers, currInsurer) => ({ ...prevInsurers, [currInsurer]: true }),
      {},
    );
  }

  function createInsurancesBooleanObject(filterInsurers = {}) {
    let insurances = new Set();
    Object.entries(filterInsurers).forEach(([, insurance]) => {
      Object.keys(insurance).forEach(key => insurances.add(key));
    });

    // return object of key insurances and false value
    return [...insurances].reduce(
      (prevInsurance, currInsurance) => ({
        ...prevInsurance,
        [currInsurance]: true,
      }),
      {},
    );
  }

  function createGuaranteesBooleanObject(filterInsurers = {}) {
    let guarantees = new Set();
    Object.entries(filterInsurers).forEach(([, insurance]) => {
      Object.entries(insurance).forEach(([, guaranteeArr]) =>
        guaranteeArr.forEach(guarantee => guarantees.add(guarantee)),
      );
    });

    // return object of key guarantees and false value
    return [...guarantees].reduce(
      (prevInsurance, currInsurance) => ({
        ...prevInsurance,
        [currInsurance]: false,
      }),
      {},
    );
  }

  async function retrieveInfoInsurances() {
    // Extract the company tags and get all info about them
    let tempInsuranceInfo = {};
    const { insurances } = await retrieveStorageById('pro_flow', sessionId);
    const { me } = await getMeData();
    const isAffinity = !(await configCatClient.getValueAsync(
      'canAccessPage',
      true,
      {
        email: me?.email,
      },
    ));

    if (isAffinity) {
      const [insuranceCompanyResp] = await getAffinityByCompanyKey('callant');

      insurances?.map(async key => {
        const [affinityInfo] = insuranceCompanyResp?.items?.filter(
          ({ affinity_info }) => affinity_info.key === key,
        );

        tempInsuranceInfo[key] = {
          ...affinityInfo.affinity_info,
        };

        const [affinityInsurances] = await getAffinityInsurancesByCompanyKey(
          'callant',
          key,
        );

        affinityInsurances.items.map(async item => {
          if (item.insurance.type !== riskObjectType.toLowerCase()) return;

          const [guaranteesResp] = await getAffinityInsurancesByCompanyKeyAndId(
            'callant',
            key,
            item.insurance.id,
          );

          tempInsuranceInfo[key]['insurances'] = {};
          tempInsuranceInfo[key]['insurances'][
            item?.insurance?.type?.toLowerCase()
          ] = {
            id: item.insurance.id,
            names: item.insurance.names,
            guarantees: guaranteesResp.items.reduce((map, obj) => {
              map[obj.guarantee_info.key] = {
                id: obj.guarantee_info.id,
                names: obj.guarantee_info.names,
              };
              return map;
            }, {}),
          };
        });
      });
    } else {
      insurances?.map(async key => {
        const [insuranceCompanyResp] = await getInsuranceCompanyByKey(key);
        tempInsuranceInfo[key] = {
          ...insuranceCompanyResp.insurance_company,
          insurances: {},
        };
        let [insurancesResp, status] = await getInsurances(key);

        if (status !== 200) {
          if (status !== 404) {
            return console.error(insurancesResp);
          }
          if (insurancesResp.msg != null) {
            setConnectionErrorMsg(insurancesResp.msg);
          }
          // CTA: Go back to Pro-Flow (edited)
          setRetrievedData(null);
          return;
        }
        setRetrievedData(insurancesResp);
        insurancesResp.items.map(async item => {
          const [guaranteesResp] = await getGuarantees(key, item.insurance.id);
          tempInsuranceInfo[key][`insurances`][
            item.insurance.type.toLowerCase()
          ] = {
            id: item.insurance.id,
            names: item.insurance.names,
            guarantees: guaranteesResp.items.reduce((map, obj) => {
              map[obj.guarantee_info.key] = {
                id: obj.guarantee_info.id,
                names: obj.guarantee_info.names,
              };
              return map;
            }, {}),
            links: {
              ipid: item.insurance.ipid.nl,
              terms: item.insurance.terms.nl,
            },
          };
        });
      });
    }
    setInfoInsurances(tempInsuranceInfo);
  }

  function restructureQuoteBeforePassingDown(quote, _infoInsurances) {
    const insuranceDistribution = _infoInsurances?.[quote?.insurance_company];
    const { names: insuranceCompanyNames, insurances } =
      insuranceDistribution || {};

    if (quote?.insurance_type === 'home') {
      const { guarantees } = insurances?.['residence'] || {};
      const { links } = insurances?.['residence'] || {};
      if (!guarantees) {
        return;
      }

      const namedGuarantees = Object.entries(guarantees).map(
        ([key, { names }]) => ({
          key,
          name: names?.[i18n.language] || `No Name found`,
        }),
      );

      let sanitizedQuote = {
        id: quote?.id,
        insurance: {
          key: quote?.insurance,
          name: namedGuarantees?.filter(
            ({ key }) => key === quote?.insurance,
          )[0]?.name,
        },
        insuranceCompany: {
          key: quote?.insurance_company,
          name:
            insuranceCompanyNames?.[i18n.language] || quote?.insurance_company,
          logo: insuranceDistribution?.logo,
          website: insuranceDistribution?.website,
        },
        insuranceType: {
          key: quote?.insurance_type,
        },
        guarantees: namedGuarantees,
        links: links,
        options: quote?.options.map(option => ({
          key: option,
          name: namedGuarantees?.filter(({ key }) => key === option)[0]?.name,
        })),
        originalQuote: quote,
      };

      if (brokerCRMName) {
        sanitizedQuote = {
          ...sanitizedQuote,
          CRM: {
            key: brokerCRMName,
            name: brokerCRMName,
          },
        };
      }

      if (quote?.resp?.quote) {
        const {
          language,
          premium,
          promotions,
          quote: quoteDetail,
        } = quote?.resp;
        sanitizedQuote = {
          ...sanitizedQuote,
          language,
          premium,
          promotions,
          quote: {
            base: (() => {
              const { name: key, ...rest } = quoteDetail?.base;
              return {
                key,
                name: namedGuarantees?.filter(
                  ({ key: _key }) => key === _key,
                )[0]?.name,
                ...rest,
              };
            })(),
            details: quoteDetail?.details,
            options: quoteDetail?.options?.map(option => {
              const { name: key, ...rest } = option;
              return {
                key,
                name: namedGuarantees?.filter(
                  ({ key: _key }) => key === _key,
                )[0]?.name,
                ...rest,
              };
            }),
          },
        };
      } else {
        const { code, detail, domain, msg } = quote?.resp;
        sanitizedQuote = {
          ...sanitizedQuote,
          error: {
            code,
            detail: detail?.[i18n.language] || detail?.en,
            domain,
            msg,
          },
        };
      }

      return sanitizedQuote;
    } else {
      const { guarantees } = insurances?.[quote?.insurance_type] || {};
      const { links } = insurances?.[quote?.insurance_type] || {};
      if (!guarantees) return;

      const namedGuarantees = Object.entries(guarantees).map(
        ([key, { names }]) => ({
          key,
          name: names?.[i18n.language] || `No Name found`,
        }),
      );

      let sanitizedQuote = {
        id: quote?.id,
        insurance: {
          key: quote?.insurance,
          name: namedGuarantees?.filter(
            ({ key }) => key === quote?.insurance,
          )[0]?.name,
        },
        insuranceCompany: {
          key: quote?.insurance_company,
          name:
            insuranceCompanyNames?.[i18n.language] || quote?.insurance_company,
          logo: insuranceDistribution?.logo,
          website: insuranceDistribution?.website,
        },
        insuranceType: {
          key: quote?.insurance_type,
        },
        guarantees: namedGuarantees,
        links: links,
        options: quote?.options.map(option => ({
          key: option,
          name: namedGuarantees?.filter(({ key }) => key === option)[0]?.name,
        })),
        originalQuote: quote,
      };

      if (brokerCRMName) {
        sanitizedQuote = {
          ...sanitizedQuote,
          CRM: {
            key: brokerCRMName,
            name: brokerCRMName,
          },
        };
      }

      if (quote?.resp?.quote) {
        const {
          language,
          premium,
          promotions,
          quote: quoteDetail,
        } = quote?.resp;
        sanitizedQuote = {
          ...sanitizedQuote,
          language,
          premium,
          promotions,
          quote: {
            base: (() => {
              const { name: key, ...rest } = quoteDetail?.base;
              return {
                key,
                name: namedGuarantees?.filter(
                  ({ key: _key }) => key === _key,
                )[0]?.name,
                ...rest,
              };
            })(),
            details: quoteDetail?.details,
            options: quoteDetail?.options?.map(option => {
              const { name: key, ...rest } = option;
              return {
                key,
                name: namedGuarantees?.filter(
                  ({ key: _key }) => key === _key,
                )[0]?.name,
                ...rest,
              };
            }),
          },
        };
      } else {
        const { code, detail, domain, msg } = quote?.resp;
        sanitizedQuote = {
          ...sanitizedQuote,
          error: {
            code,
            detail: detail?.[i18n.language] || detail?.en,
            domain,
            msg,
          },
        };
      }

      return sanitizedQuote;
    }
  }

  return progress.maxAmount === undefined || progress.maxAmount === 0 ? (
    <LoadingWrapper>
      <CircularProgressBar currentItems={0} maxItems={0} relative>
        <H2>{t(`Calculating quotes`)}</H2>
        <DotH2>
          {t(`This can take a while`)}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </DotH2>
      </CircularProgressBar>
    </LoadingWrapper>
  ) : progress.maxAmount > progress.currentAmount ||
    isFetchingBatch ||
    isPreFetching ? (
    <LoadingWrapper>
      <CircularProgressBar
        currentItems={progress.currentAmount}
        maxItems={progress.maxAmount}
        relative
      >
        <H1>
          {progress.currentAmount}/{progress.maxAmount}
        </H1>
        <H2>
          {isPreFetching && !isFetchingBatch
            ? t(`Retrieving quotes`)
            : t(`Calculating quotes`)}
        </H2>
      </CircularProgressBar>
    </LoadingWrapper>
  ) : (
    <Container>
      <Title>{t('Select your offers')}</Title>
      <Tabs offerTypes={offerTypes} />
      <Switch>
        <Route
          path={`/professional-flow/offers/:sessionId/selected-offers`}
          component={ProFlowOffersSelectedOffers}
        />
        <Route
          path={`/professional-flow/offers/:sessionId/:type`}
          component={() => (
            <ProFlowOffersRiskObject
              batchId={batchId}
              error={error}
              quotes={sanitizedQuotes}
              setQuotes={setQuotes}
              infoInsurances={infoInsurances}
              retrievedData={retrievedData}
              connectionErrorMsg={connectionErrorMsg}
              pagination={pagination}
              setPagination={setPagination}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        />
      </Switch>
    </Container>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

const Container = styled.div`
  max-width: 1500px;
  width: 95%;
  margin: 0 auto;
`;

const Title = styled.h1`
  padding-top: 4rem;
  font-weight: 700;
  font-size: 3.6rem;
`;

const DotH2 = styled(H2)`
  text-align: center;
  > span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    :nth-child(2) {
      animation-delay: 0.2s;
    }
    :nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

export default ProFlowOffers;
