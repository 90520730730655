import React from 'react';
import { string } from 'prop-types';

const SpecialRoofingIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="26"
      fill="none"
      viewBox="0 0 14 26"
    >
      <path
        fill={color}
        d="M10.235 19.167v-4.829h.697a.999.999 0 00.996-.995V6.782a.999.999 0 00-.996-.996h-.697v-.558c0-.209-.15-.398-.358-.418a.398.398 0 00-.438.398v.578h-.697a.999.999 0 00-.996.996v6.56c0 .548.448.996.996.996h.697v3.336A3.078 3.078 0 006.67 15.94v1.762a3.087 3.087 0 002.768 3.067v3.923a.4.4 0 00.398.398.4.4 0 00.398-.398v-1.504a4.01 4.01 0 003.614-3.992v-2.3a4.029 4.029 0 00-3.614 2.27zM5.108.977H4.41V.42C4.41.21 4.26.02 4.052 0A.403.403 0 003.614.4v.577h-.697a.999.999 0 00-.995.996v11.37c0 .547.448.995.995.995h.697v4.83A4.006 4.006 0 000 16.897v2.299a4.01 4.01 0 003.614 3.992v1.504a.4.4 0 00.398.398.4.4 0 00.399-.398V14.338h.697a.999.999 0 00.995-.995V1.973a.999.999 0 00-.995-.996z"
      ></path>
    </svg>
  );
};

SpecialRoofingIcon.defaultProps = {
  color: `white`,
};

SpecialRoofingIcon.propTypes = {
  color: string,
};

export default SpecialRoofingIcon;
