import React from 'react';
import HomeQuestion from '../components/HomeQuestion';

// Content in content.json is required for this component
// questionNumber = subStep = contentBlock in content.json

const SecondExtraQuestion = () => (
  <HomeQuestion
    dataTestId1="underwriting_answer_yes"
    dataTestId2="underwriting_answer_no"
    questionNumber={1}
  />
);

export default SecondExtraQuestion;
