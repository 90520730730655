import {
  orange,
  lightBlue,
  oceanBlue,
  darkBlue,
  lightRed,
  darkRed,
  pinkPurple,
  darkGreen,
  lightGreen,
  oceanGreen,
  darkGrey,
  hillewaereBlue,
} from 'wg-fe-ui/dist/themes';

export const getThemeObject = name => {
  switch (name) {
    case 'orange':
      return orange();
    case 'light_blue':
      return lightBlue();
    case 'ocean_blue':
      return oceanBlue();
    case 'dark_blue':
      return darkBlue();
    case 'light_red':
      return lightRed();
    case 'dark_red':
      return darkRed();
    case 'pink_purple':
      return pinkPurple();
    case 'dark_green':
      return darkGreen();
    case 'light_green':
      return lightGreen();
    case 'ocean_green':
      return oceanGreen();
    case 'dark_grey':
      return darkGrey();
    case 'hillewaere_blue':
      return hillewaereBlue();
    default:
      return orange();
  }
};

export function retrieveAllThemes() {
  return {
    orange: orange(),
    lightBlue: lightBlue(),
    oceanBlue: oceanBlue(),
    darkBlue: darkBlue(),
    lightRed: lightRed(),
    darkRed: darkRed(),
    pinkPurple: pinkPurple(),
    darkGreen: darkGreen(),
    lightGreen: lightGreen(),
    oceanGreen: oceanGreen(),
    darkGrey: darkGrey(),
    hillewaereBlue: hillewaereBlue(),
  };
}
