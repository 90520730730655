import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import RightSide from '../../../components/RightSide';
import IconCheckbox from '../../../components/IconCheckBox';
import { ToggleInput } from 'wg-fe-ui';

// Images
import suvcar from '../../../../assets/images/car/suv.svg';
import sportscar from '../../../../assets/images/car/sportsCar.svg';
import cabriocar from '../../../../assets/images/car/cabrio.svg';
import Button from '../../../components/Button';
import moment from 'moment';
import i18next from 'i18next';
import InfoPopUp from '../../../components/InfoPopUp';

const CarDataTable = ({ carData }) => {
  const { t } = useTranslation();
  const content = t('ToolkitInfo.carInfo', { returnObjects: true });
  const [catalogueValue, setCatalogueValue] = useState(
    carData.value ? carData.value : null,
  );

  const [language, setlanguage] = useState(i18next.language);
  const [powerValue, setPowerValue] = useState(carData.kw ? carData.kw : null);
  const [toggleValues, setToggleValues] = useState([false, false]);

  useEffect(() => {
    if (i18next.language != language) {
      catalogueValue === carData.value
        ? t(`CheckCar.button_vat_exclu`)
        : t(`CheckCar.button_vat_inclu`);
      setlanguage(i18next.language);
    }
  }, []);

  useEffect(() => {
    setCatalogueValue(carData.value);
    setPowerValue(carData.kw);
  }, [carData]);

  const handleBTW = () => {
    let value = catalogueValue;
    if (catalogueValue === carData.value) {
      value = carData.value * 1.21;
      setCatalogueValue(value);
    } else {
      value = carData.value;
      setCatalogueValue(value);
    }
  };

  const handlePower = () => {
    let value = powerValue;
    if (carData.kw === powerValue) {
      value = carData.kw * 1.36;
      setPowerValue(value);
    } else {
      value = carData.kw;
      setPowerValue(value);
    }
  };

  const toggleValue = type => {
    // 0 = BIV
    // 1 = Road tax
    if (type === 0) {
      setToggleValues(toggleValues => [!toggleValues[0], toggleValues[1]]);
    } else if (type === 1) {
      setToggleValues(toggleValues => [toggleValues[0], !toggleValues[1]]);
    } else {
      console.error('Wrong type');
    }
  };

  return (
    <CarDataWrapper>
      <Block>
        <LeftSide>
          <RoundImage>
            <Img
              bg={
                carData.img
                  ? carData.img.replace(/\(/g, '\\(').replace(/\)/g, '\\)')
                  : null
              }
            />
            {carData.color_hex ? (
              <ColorButton color={carData.color_hex} />
            ) : null}
          </RoundImage>
        </LeftSide>
        <RightBlock>
          <CarName>
            <span>{t(`CheckCar.title`)}</span>
            <h2>{carData.name}</h2>
          </CarName>
          <Extra>
            <TitleForm>Extras</TitleForm>
            <SortCar>
              <ExtraSortCar
                checked={carData.is_jeep ? carData.is_jeep : null}
                name={t(`CheckCar.sortCar.SUV.key`)}
                disabled={true}
              >
                <IconExtra alt={t(`CheckCar.sortCar.SUV.label`)} src={suvcar} />
                <p>{t(`CheckCar.sortCar.SUV.label`)}</p>
              </ExtraSortCar>
              <ExtraSortCar
                checked={
                  carData && carData.is_sportscar ? carData.is_sportscar : null
                }
                name={t(`CheckCar.sortCar.sportsCar.key`)}
                disabled={true}
              >
                <IconExtra
                  alt={t(`CheckCar.sortCar.sportsCar.label`)}
                  src={sportscar}
                />
                <p>{t(`CheckCar.sortCar.sportsCar.label`)}</p>
              </ExtraSortCar>
              <ExtraSortCar
                checked={
                  carData && carData.is_cabrio ? carData.is_cabrio : null
                }
                name={t(`CheckCar.sortCar.cabrio.key`)}
                disabled={true}
              >
                <IconExtra
                  alt={t(`CheckCar.sortCar.cabrio.label`)}
                  src={cabriocar}
                />
                <p>{t(`CheckCar.sortCar.cabrio.label`)}</p>
              </ExtraSortCar>
            </SortCar>
          </Extra>
        </RightBlock>
      </Block>
      <TableWrapper>
        <Table>
          <tbody>
            <TableBlock>
              <TableRow>
                <TableKey>{content.brand}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_brand">
                  {carData.brand ? carData.brand : '--'}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.model}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_model">
                  {carData.model ? carData.model : '--'}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.version}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_version">
                  {carData.version ? carData.version : '--'}
                </TableValue>
              </TableRow>
              {/* <TableRow>
                <TableKey>{content.model_year}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_model_year">
                  {carData.model_year ? carData.model_year : '--'}
                </TableValue>
              </TableRow> */}
              <TableRow>
                <TableKey>{content.chassis_number}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_chassis_number">
                  {carData.chassis_number
                    ? carData.chassis_number
                    : t(`Checkout.nvt`)}
                </TableValue>
              </TableRow>
              <TableRow />
              <TableRow>
                <TableKey>{content.motor_type}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_motor_type">
                  {carData.motor_type
                    ? t(`ToolkitInfo.motor_types.${carData.motor_type}`)
                    : t(`Checkout.nvt`)}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.kw}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_kw">
                  {carData.kw ? (
                    <>
                      <span>{Math.round(powerValue)}</span>{' '}
                      <ChangeButtonPower
                        onClick={() => handlePower()}
                        type="button"
                      >
                        {powerValue === carData.kw
                          ? content.power_kw
                          : content.power_pk}{' '}
                      </ChangeButtonPower>
                    </>
                  ) : (
                    '--'
                  )}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.cc}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_cc">
                  {carData.cc ? `${carData.cc} CC` : '--'}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.co2}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_co2">
                  {carData.co2 ? `${carData.co2} g CO2/km` : '-- '}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.emission_standards}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_emission_standards">
                  {carData.emission_standards
                    ? `${carData.emission_standards}`
                    : '-- '}
                </TableValue>
              </TableRow>
            </TableBlock>
            <TableBlock>
              <TableRow>
                <TableKey>{content.value}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_catalogue_value">
                  {catalogueValue ? (
                    <>
                      <span>
                        {catalogueValue
                          .toFixed(2)
                          .replace('.', ',')
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
                        €
                      </span>
                      <ChangeButton onClick={() => handleBTW()} type="button">
                        {catalogueValue === carData.value
                          ? t(`CheckCar.button_vat_exclu`)
                          : t(`CheckCar.button_vat_inclu`)}
                      </ChangeButton>
                    </>
                  ) : (
                    '--'
                  )}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.registration_first}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_registration_first">
                  {carData.registration_first
                    ? moment(carData.registration_first)
                        .lang(i18next.language)
                        .format('DD MMMM YYYY')
                    : t(`Checkout.nvt`)}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.registration_last}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_registration_last">
                  {carData.registration_last
                    ? moment(carData.registration_last)
                        .lang(i18next.language)
                        .format('DD MMMM YYYY')
                    : t(`Checkout.nvt`)}
                </TableValue>
              </TableRow>
              <TableRow />
              <TableRow />
              <TableRow>
                <TableKey>
                  <InfoPopUp
                    title={content.seats}
                    info={content.seatsInfo}
                    buttonText={content.seats}
                    isLink={true}
                  />
                </TableKey>
                <TableValue data-test-id="toolkit_car_table_info_seats">
                  {carData.seats ? carData.seats : '--'}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{t('ToolkitInfo.category.title')}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_category">
                  {carData.category
                    ? t(`ToolkitInfo.category.${carData.category}`)
                    : '--'}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>{content.transmission_type}</TableKey>
                <TableValue data-test-id="toolkit_car_table_info_transmission_type">
                  {carData.transmission_type
                    ? t(carData.transmission_type)
                    : '-- '}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>
                  {carData?.taxation?.biv_with_particulate_filter ? (
                    <InfoPopUp
                      title={content.biv}
                      info={content.biv_particulate_filter_info}
                      buttonText={
                        toggleValues[0] ? content.biv_short : content.biv
                      }
                      isLink={true}
                    />
                  ) : (
                    content.biv
                  )}
                </TableKey>
                <TableValue data-test-id="toolkit_car_table_info_biv">
                  {carData?.taxation?.biv
                    ? toggleValues[0]
                      ? `${carData?.taxation?.biv_with_particulate_filter} €`
                      : `${carData?.taxation?.biv} €`
                    : '-- '}
                  {carData?.taxation?.biv_with_particulate_filter && (
                    <StyledToggleInput
                      onClick={() => toggleValue(0)}
                      value={toggleValues[0]}
                    />
                  )}
                </TableValue>
              </TableRow>
              <TableRow>
                <TableKey>
                  {carData?.taxation?.road_tax_with_particulate_filter ? (
                    <InfoPopUp
                      title={content.road_tax}
                      info={content.road_tax_particulate_filter_info}
                      buttonText={
                        toggleValues[1]
                          ? content.road_tax +
                            ' ' +
                            content.with_particulate_filter
                          : content.road_tax
                      }
                      isLink={true}
                    />
                  ) : (
                    content.road_tax
                  )}
                </TableKey>
                <TableValue data-test-id="toolkit_car_table_info_road_tax">
                  {carData?.taxation?.road_tax
                    ? toggleValues[1]
                      ? `${carData?.taxation?.road_tax_with_particulate_filter} €`
                      : `${carData?.taxation?.road_tax} €`
                    : '-- '}
                  {carData?.taxation?.road_tax_with_particulate_filter && (
                    <StyledToggleInput
                      onClick={() => toggleValue(1)}
                      value={toggleValues[1]}
                    />
                  )}
                </TableValue>
              </TableRow>
            </TableBlock>
          </tbody>
        </Table>
      </TableWrapper>
    </CarDataWrapper>
  );
};

const StyledToggleInput = styled(ToggleInput)`
  margin: 0;
  padding: 0;
  > input {
    margin: 0;
    padding: 0;
  }
`;

const LeftSide = styled.div`
  overflow: auto;
  display: flex;
  width: 45%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

const CarDataWrapper = styled.div`
  margin-top: 2.5rem;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 2rem;

  & tbody {
    display: flex;
  }
`;

const TableWrapper = styled.div`
  margin-left: 2rem;
  width: 100%;
`;

const TableBlock = styled.div`
  width: 48%;
`;

const TableKey = styled.td`
  padding: 0.8rem 1rem 0.8rem 3rem;
  width: 45%;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & a {
    font-size: 1.6rem;
  }
`;
const TableValue = styled.td`
  padding: 0.8rem 1rem 0.8rem 3rem;
  width: 55%;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
`;
const TableRow = styled.tr`
  display: flex;
  width: 100%;
  height: 4.5rem;
  border-bottom: solid 0.05rem ${({ theme }) => theme.ui.background};

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.ui.backgroundLight};
  }
`;

const Block = styled.div`
  display: flex;
`;
const RightBlock = styled(RightSide)`
  background-color: white;
`;

const ChangeButton = styled(Button)`
  width: 9rem;
  height: 2.7rem;
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  justify-content: center;
  min-width: 0;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
  align-self: center;
  z-index: 1;
`;

const ChangeButtonPower = styled(ChangeButton)`
  width: 6rem;
  min-width: 0;
  z-index: 1;
  justify-content: center;
`;

const RoundImage = styled.div`
  width: 85%;
  padding-top: 85%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const Img = styled.div`
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 0;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
`;
const ColorButton = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 8rem;
  height: 8rem;
  background-color: ${props => props.color};
  border: 0.5rem solid white;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
`;

const CarName = styled.div`
  background: white;
  box-shadow: 0 0.4rem 1.3rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  padding: 2rem 1.8rem;
  width: 85%;

  & span {
    font-size: 1.2rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.typo.interactive};
  }

  & h2 {
    color: #505050;
    font-weight: 900;
    font-size: 1.6rem;
  }
`;

const Extra = styled.div`
  margin-top: 3rem;
`;

const ExtraSortCar = styled(IconCheckbox)`
  font-size: 1.5rem;
`;

const SortCar = styled.div`
  display: flex;
  width: 102%;
  margin-top: 1.8rem;
  flex-flow: row wrap;

  & button:hover {
    background-color: none;
  }
`;

const IconExtra = styled.img`
  width: 3.2rem;
  margin-right: 1rem;
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.6rem;
  flex-shrink: 0;
`;

CarDataTable.propTypes = {
  carData: shape().isRequired,
};

export default CarDataTable;
