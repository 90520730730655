import React from 'react';
import { string } from 'prop-types';

const automatic_parking = ({ className, color }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <g fill={color}>
      <path d="m391.86 214.78a9.44 9.44 0 0 0 -11.71 6.43l-23.29 59.35a12.57 12.57 0 0 1 -.38 4.47 14.55 14.55 0 0 1 -3.75 6l-.07.18h-.13a14.44 14.44 0 0 1 -3.14 2.63l-34.23 18.81-2.58-4.7 31.52-17.32 2.71-1.49a9 9 0 0 0 3.73-4.1c.09-.19.2-.37.28-.56a9.58 9.58 0 0 0 .53-5.56 8.87 8.87 0 0 0 -.81-2.61 9.47 9.47 0 0 0 -12.84-3.73l-28.54 15.68-1.34-.08a48.21 48.21 0 0 0 -25.51 5.47l-15.67 8.26a18.37 18.37 0 0 0 -9.09 11.13l-8.37 28.81-.7-.2a5.5 5.5 0 0 0 -6.82 3.74l-8.81 30.32a5.5 5.5 0 0 0 3.74 6.82l59.24 17.22a5.49 5.49 0 0 0 6.81-3.75l8.82-30.32a5.5 5.5 0 0 0 -3.75-6.82l-.41-.12 4.25-14.41 56.79-31.21a15.64 15.64 0 0 0 7.48-9.34l22.48-77.32a9.45 9.45 0 0 0 -6.44-11.68z" />
      <path d="m8.14 214.78a9.44 9.44 0 0 1 11.71 6.43l23.29 59.35a12.57 12.57 0 0 0 .38 4.47 14.55 14.55 0 0 0 3.75 6l.07.18h.13a14.44 14.44 0 0 0 3.14 2.63l34.23 18.81 2.58-4.7-31.52-17.29-2.71-1.49a9 9 0 0 1 -3.73-4.1c-.09-.19-.2-.37-.28-.56a9.58 9.58 0 0 1 -.53-5.56 8.87 8.87 0 0 1 .81-2.61 9.47 9.47 0 0 1 12.84-3.73l28.54 15.68 1.34-.08a48.21 48.21 0 0 1 25.51 5.47l15.67 8.26a18.37 18.37 0 0 1 9.09 11.13l8.37 28.81.7-.2a5.5 5.5 0 0 1 6.82 3.74l8.81 30.32a5.5 5.5 0 0 1 -3.74 6.82l-59.24 17.22a5.49 5.49 0 0 1 -6.81-3.78l-8.82-30.32a5.5 5.5 0 0 1 3.75-6.82l.41-.12-4.25-14.41-56.79-31.18a15.64 15.64 0 0 1 -7.48-9.34l-22.48-77.32a9.45 9.45 0 0 1 6.44-11.71z" />
      <path d="m200 0a142.54 142.54 0 1 0 142.53 142.53 142.54 142.54 0 0 0 -142.53-142.53zm-20.06 244.25a8.69 8.69 0 0 1 -11.06 8.36 114.66 114.66 0 0 1 -72.88-62.5 8.68 8.68 0 0 1 7.87-12.34h38.13a37.91 37.91 0 0 1 37.94 37.92zm20.06-76.78a17.08 17.08 0 1 1 17.08-17.09 17.08 17.08 0 0 1 -17.08 17.09zm105.17 22.53a114.55 114.55 0 0 1 -76.62 63.62 8.67 8.67 0 0 1 -10.66-8.45v-29.48a37.93 37.93 0 0 1 37.94-37.92h41.41a8.66 8.66 0 0 1 7.93 12.23zm-2.3-50.89-52.16-21.11a135.44 135.44 0 0 0 -101.4 0l-52.15 21.06a8.68 8.68 0 0 1 -10.76-9.43 114.36 114.36 0 0 1 227.25 0 8.7 8.7 0 0 1 -10.78 9.43z" />
    </g>
  </svg>
);

automatic_parking.propTypes = {
  className: string,
  color: string,
};

export default automatic_parking;
