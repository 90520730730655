import React from 'react';
import { string } from 'prop-types';

const Surface = ({ color }) => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3685 16.7362V5.65998C23.3685 5.28084 23.22 4.91525 22.95 4.64443C22.68 4.37362 22.3155 4.22467 21.9375 4.22467H7.0875V1.43531C7.0875 0.636409 6.453 0 5.6565 0C4.86 0 4.2255 0.636409 4.2255 1.43531V4.22467H1.431C0.6345 4.22467 0 4.86108 0 5.65998C0 6.45888 0.6345 7.09529 1.431 7.09529H5.6565H20.5065V16.7362C20.5065 17.5351 21.141 18.1715 21.9375 18.1715C22.734 18.1715 23.3685 17.5216 23.3685 16.7362Z"
        fill={color}
      />
      <path
        d="M26.9993 21.9494C26.9993 21.1505 26.3648 20.514 25.5683 20.514H21.9503H7.10028V10.3721C7.10028 9.57322 6.46578 8.93681 5.66928 8.93681C4.87278 8.93681 4.23828 9.57322 4.23828 10.3721V21.9494C4.23828 22.3285 4.38678 22.6941 4.65678 22.9649C4.92678 23.2357 5.29128 23.3847 5.66928 23.3847H20.5193V25.5647C20.5193 26.3636 21.1538 27 21.9503 27C22.7468 27 23.3813 26.3636 23.3813 25.5647V23.3847H25.5548C26.3513 23.3847 26.9993 22.7347 26.9993 21.9494Z"
        fill={color}
      />
    </svg>
  );
};

Surface.propTypes = {
  color: string,
};

export default Surface;
