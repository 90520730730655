import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { object } from 'prop-types';

import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox.jsx';

import { getAddressData } from '../../User/store';
import {
  setAddressData,
  setAddressInfo,
  setManual,
  setAdress,
  setRoomLayout,
} from '../store';
import {
  getNextPath,
  getComponentName,
  setCoverage,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';
// questionNumber = subStep = contentBlock in content.json
// Content in content.json is required for this component

const InsureThisHouse = ({ history }) => {
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [resData, setResData] = useState({});
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const manualClickHandler = res => {
    const values = getAddressData();
    const tempResData = res ? res : resData;
    setAdress({
      manual: true,
      street: values.street,
      housenr: values.housenr,
      zipcode: parseInt(values.zipcode),
      city: values.city,
      country_code: 'BE',
    });
    setAddressInfo({
      surface: '',
      height: '',
      building: '',
      extra_data: tempResData.extra_address_data
        ? tempResData.extra_address_data
        : undefined,
      aerial_images: tempResData.aerial_images
        ? {
            blank: tempResData.aerial_images.blank
              ? {
                  ...tempResData.aerial_images.blank,
                }
              : undefined,
            marked: tempResData.aerial_images.marked
              ? {
                  ...tempResData.aerial_images.marked,
                }
              : undefined,
          }
        : undefined,
    });
    setRoomLayout({
      bathrooms: 1,
      bedrooms: 2,
      garages: 1,
      kitchens: 1,
      living_rooms: 1,
      other_rooms: 1,
      room_count: 7,
    });
    addChatboxAnswer(t(`${getComponentName()}.answers.0`));
    history.push(getNextPath());
  };

  addChatboxQuestion(
    t(`${getComponentName()}.question`, {
      streetName: capitalizeFirstLetter(getAddressData().street.toLowerCase()),
      houseNr: capitalizeFirstLetter(getAddressData().housenr.toLowerCase()),
      cityName: capitalizeFirstLetter(getAddressData().city.toLowerCase()),
    }),
  );

  const clickHandler = anwer => {
    if (isPending) return false;
    setIsPending(true);
    setCoverage('home', true);
    if (anwer === t(`${getComponentName()}.answers.0`)) {
      setAnswer(1);
      setAddressData(getAddressData())
        .then(res => {
          setAddressInfo(res.data);
          setAdress(res.data.address);
          setResData(res.data);
          addChatboxAnswer(t(`${getComponentName()}.answers.0`));
          if (res.data.extra_address_data.house_type) {
            setManual(false);
            history.push(getNextPath());
          } else {
            manualClickHandler(res.data);
          }
        })
        .catch(err => {
          console.error(err);
          manualClickHandler();
          addChatboxAnswer(t(`${getComponentName()}.answers.0`));
        });
    } else {
      setAnswer(2);
      addChatboxAnswer(t(`${getComponentName()}.answers.1`));
      history.push(getNextPath('b'));
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          Answer1={t(`${getComponentName()}.answers.0`)}
          Answer2={t(`${getComponentName()}.answers.1`)}
          dataTestId1="underwriting_answer_yes"
          dataTestId2="underwriting_answer_no"
          shouldLoad={isPending ? answer : undefined}
          response={answer => clickHandler(answer)}
        >
          {t(`${getComponentName()}.question`, {
            streetName: capitalizeFirstLetter(
              getAddressData().street.toLowerCase(),
            ),
            houseNr: `${capitalizeFirstLetter(
              getAddressData().housenr.toLowerCase(),
            )}${getAddressData().boxnr ? `/${getAddressData().boxnr}` : ``}`,
            cityName: capitalizeFirstLetter(
              getAddressData().city.toLowerCase(),
            ),
          })}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }

  & div {
    z-index: 10;
  }
`;

InsureThisHouse.propTypes = {
  history: object.isRequired,
};

export default withRouter(InsureThisHouse);
