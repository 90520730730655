import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { node, string, func, array, shape } from 'prop-types';

import { withRouter } from 'react-router';
import { addAnswer } from '../store';
import InfoPopUp from '../../../../components/InfoPopUp';

const CarQuestionBox = ({
  children,
  answers,
  handleAnswer,
  type,
  to,
  btnText,
  history,
  error,
  tooltip,
  dataTestId,
}) => {
  const [inputValue, setInputValue] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (inputValue) {
      if (error && error !== '') {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [inputValue]);

  const handleClick = _answer => {
    if (answers) {
      answers.forEach(answer => {
        if (answer.key === _answer) {
          addAnswer(answer.response);
        }
      });
    }

    handleAnswer(_answer);
    if (to) {
      history.push(to);
    }
  };

  return (
    <Container>
      <QuestionContainer>
        <Question>
          <p dangerouslySetInnerHTML={{ __html: children }} />
          {tooltip !== null ? (
            <InfoPopUp title={children} info={tooltip} />
          ) : null}
        </Question>
        {type === 'date' ? (
          <>
            <DateInput
              type={type}
              autoFocus
              correct={isDisabled}
              data-test-id={dataTestId[0]}
              onChange={e => {
                handleAnswer(e.currentTarget.value);
                setInputValue(e.currentTarget.value);
              }}
            />
          </>
        ) : null}
      </QuestionContainer>
      {answers ? (
        <Answers length={answers.length}>
          {answers.map((answer, i) => {
            return (
              <Answer
                left={i === 0 ? 'left' : answers.length < 2 ? 'fill' : 'right'}
                key={i}
                onClick={() => handleClick(answer.key)}
                data-test-id={dataTestId[i]}
              >
                {answer.title}
              </Answer>
            );
          })}
        </Answers>
      ) : (
        <Answer
          data-test-id={dataTestId[1]}
          disabled={isDisabled}
          onClick={() => handleClick(inputValue)}
        >
          {btnText}
        </Answer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 18rem;
  box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, 0.15);
  border-radius: 0 1.6rem 1.6rem;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  margin: auto;
  margin-top: 0;
  margin-bottom: 3rem;
  flex-shrink: 0;
  background-color: white;
  padding-bottom: 4.5rem;
`;

const QuestionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem;
`;

const DateInput = styled.input`
  /* dklqfj\ */
  border: none;

  border-bottom: ${props =>
    props.correct ? '0.3rem solid lightgrey' : '0.3rem solid orange'};
  font-size: 2.5rem;
  transition: 0.3s;
  text-align: center;
  height: auto;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
  &::-webkit-calendar-picker-indicator {
    display: none;
  }

  &:focus {
    outline: 0;
  }
`;

const Answers = styled.div`
  width: 100%;
  display: ${props => (props.length === 2 ? 'flex' : null)};
  position: absolute;
  bottom: 0;
`;

const Answer = styled.button`
  width: 50%;
  height: 6.5rem;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.primary};
  color: white;
  border: solid 0.2rem;
  border-radius: ${props =>
    props.left === 'left'
      ? '0 0 0 1.6rem'
      : props.left === 'right'
      ? '0 0 1.6rem 0'
      : props.left === 'fill'
      ? '0 0 1.6rem 1.6rem'
      : '0'};
  border-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.secondary};
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.brand.lighter : theme.brand.lighter};
    cursor: ${props => (props.disabled ? `default` : `pointer`)};
  }

  :focus {
    outline: none;
  }
`;

const Question = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

CarQuestionBox.defaultProps = {
  answers: null,
  type: null,
  btnText: null,
  to: null,
  error: null,
  tooltip: null,
  dataTestId: [],
};

CarQuestionBox.propTypes = {
  children: node.isRequired,
  answers: array,
  type: string,
  handleAnswer: func.isRequired,
  btnText: string,
  to: string,
  error: string,
  history: shape().isRequired,
  tooltip: string,
  dataTestId: array,
};

export default withRouter(CarQuestionBox);
