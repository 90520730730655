import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import * as Yup from 'yup';
import { shape } from 'prop-types';
import LogRocket from 'logrocket';
import { ThemeContext } from '../../../services/context/ThemingContext';
import { TextInput, ActionButton, LoadingSpinner } from 'wg-fe-ui';
import { auth } from '../../../services/apiRouterService';
import { formattedLoginExeptions } from '../../../services/authService';

// components
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// stores
import TrialPopupOnBrokerLoginScreen from '../../../components/TrialPopupOnBrokerLoginScreen.jsx';

// svg
import bg from '../../../../assets/images/loginBackground.svg';
import useForm from '../../../hooks/useForm';
import { setSocialLogin, setTrialAccount } from '../../../store';

import {
  getBrokerData,
  resetBrokerData,
  getBrokerDistribution,
} from '../../../services/brokerDataService';
import { getMeData, resetMeData } from '../../../services/meDataService';
import { detect } from 'detect-browser';
import ChromeDisclaimer from '../../../components/ChromeDisclaimer';
import { useFetchedCampaignData } from '../../../hooks/useFetchedCampaignData';
import { useFlowDispatch } from '../../../services/context/LouiseFlowContext';

const BrokerLogin = ({ history }) => {
  const [trialPopup, setTrialPopup] = useState(false);
  const [authError, setAuthError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const flowDispatch = useFlowDispatch();
  // console.log(context);

  const [campaignData, fetchCampaignData] = useFetchedCampaignData();
  const { t, i18n } = useTranslation();
  const content = t('Login', { returnObjects: true });
  const { setTheme } = useContext(ThemeContext);
  const browser = detect().name;
  const urlParams = new URLSearchParams(useLocation().search);
  sessionStorage.removeItem('broker_id');

  if (urlParams.has('lang')) {
    const toSetLanguage = urlParams.get('lang');
    const currentLanguage = i18n.language;
    if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
  }

  useEffect(() => {
    resetBrokerData();
    resetMeData();
    setSocialLogin(false);
    checkIsACampain();
  }, []);

  const SignupSchema = Yup.object().shape({
    email: Yup.string()
      .email(content.errors.email)
      .required(content.errors.required)
      .min(2, content.errors.short)
      .max(100, content.errors.long),
    password: Yup.string()
      .required(content.errors.required)
      .min(2, content.errors.short)
      .max(100, content.errors.long),
  });

  const { handleChange, values, handleSubmit, errors } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    const { status, data } = campaignData;
    if (status === 'fetched') {
      setIsLoading(false);

      const lang = data?.language?.toLowerCase();
      const shortId = data?.short_id;
      const brokerId = data?.assigned_to;

      const campaignUrl = `/session/broker/${brokerId}?campaignId=${shortId}&lang=${lang}`;
      history.push(campaignUrl);
    }
  }, [campaignData]);

  async function checkIsACampain() {
    const url = window.location.href;
    const isCampain = url.includes('campaigns');
    setIsLoading(isCampain);
    if (!isCampain) return;

    flowDispatch({ type: 'SET_FLOW_TYPE', payload: 'campaign' });

    // replace https:// to an empty sting so we can safely extract the campaigns short id
    const [campaignId] = url.replace(/(^\w+:|^)\/\//, '').split('.');

    fetchCampaignData(campaignId);
  }

  function setLanguage(lang) {
    i18next.changeLanguage(lang);
  }

  function onSubmit(data) {
    const { email, password } = data;
    authenticate(email, password);
  }

  async function authenticate(email, password) {
    const [resp, status] = await auth(email.toLowerCase(), password);

    if (status !== 200) {
      handleAuthError(resp, status);
      return;
    }

    LogRocket.identify('Email Login', {
      email,
    });

    await resetBrokerData();
    await resetMeData();

    const { theme } = (await getBrokerData()) || {};
    const { me } = (await getMeData()) || {};
    const distribution = (await getBrokerDistribution()) || {};
    console.log(distribution);

    const isTrail = me?.role === 'trial_broker';
    setTrialAccount(isTrail);
    setTheme(theme?.name);
    setSocialLogin(false);

    history.push('/dashboard');
  }

  function handleAuthError(resp, status) {
    const { msg } = resp;
    const formattedMsg = formattedLoginExeptions(msg, status);

    if (formattedMsg === 'userExpired') {
      setTrialPopup(true);
      return;
    }
    if (formattedMsg === 'environmentMismatch') {
      // go to master environment
      window.location.href = 'https://marketplace.wegroup.be';
      return;
    }

    setAuthError(t(formattedParsedMsg[formattedMsg]));
  }

  const formattedParsedMsg = {
    passwordMismatch: 'incorrect password',
    environmentMismatch: 'you are in the wrong environment',
    tryAgainLater: 'something went wrong, try again later',
    userNotFound: 'user not found',
  };

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(onSubmit);
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Container>
      {trialPopup ? (
        <TrialPopupOnBrokerLoginScreen
          close={bool => setTrialPopup(bool)}
          startScreen="expired"
        />
      ) : null}
      {browser !== 'chrome' ? <ChromeDisclaimer /> : null}
      <LeftSide>
        <LogoContainer>
          <Logo
            src="https://files.wegroup.be/logos/wegroup/wegroup-logo.svg"
            alt="WeGroup"
          />
        </LogoContainer>
        <LoginContainer>
          <Title>{content.title}</Title>
          <Description>{content.subTitle}</Description>
          <Form onSubmit={formSubmitHandler} data-test-id="broker_login_form">
            <InputWrapper>
              <FixedHeightInput
                data-test-id="broker_login_email"
                name="email"
                placeholder="hello@wegroup.be"
                type="email"
                error={errors.email}
                value={values.email}
                onChange={handleChange}
              >
                {content.inputs[0]}
              </FixedHeightInput>
              <FixedHeightInput
                data-test-id="broker_login_password"
                name="password"
                type="password"
                error={errors.password}
                value={values.password}
                onChange={handleChange}
              >
                {content.inputs[1]}
              </FixedHeightInput>
            </InputWrapper>
            <ForgotPassword
              data-test-id="broker_login_forgot_password"
              href="mailto:support@wegroup.be?subject=Wachtwoord vergeten"
            >
              {content.forgot}
            </ForgotPassword>
            <AuthenticationErrorContainer>
              {authError ? (
                <AuthenticationError data-test-id="broker_login_authentication_error">
                  {authError}
                </AuthenticationError>
              ) : null}
            </AuthenticationErrorContainer>
            <ButtonContainer>
              <ActionButton
                type="submit"
                level-
                value="Login"
                data-test-id="broker_login_submit"
              >
                {t('Sign in')}
              </ActionButton>
            </ButtonContainer>
          </Form>
        </LoginContainer>
        <Footer>
          <div>
            <LinkContainer>
              <a
                href="https://wegroup.be/terms"
                target="_blank"
                rel="noopener noreferrer"
                data-test-id="brokerlogin_footer_terms"
              >
                Terms
              </a>
              <a
                href="https://wegroup.be/terms"
                target="_blank"
                rel="noopener noreferrer"
                data-test-id="brokerlogin_footer_privacy"
              >
                Privacy
              </a>
              <a
                href="https://wegroup.be/terms"
                target="_blank"
                rel="noopener noreferrer"
                data-test-id="brokerlogin_footer_policy"
              >
                Policy &amp; Safety
              </a>
            </LinkContainer>
            <CopyRight>&copy; 2019 WeGroup NV. All rights reserved</CopyRight>
          </div>
          <LangSelector>
            <LangSelectorButton
              type="button"
              onClick={() => setLanguage('nl')}
              active={i18next.language === 'nl'}
            >
              NL
            </LangSelectorButton>
            <span> - </span>
            <LangSelectorButton
              type="button"
              onClick={() => setLanguage('en')}
              active={i18next.language === 'en'}
            >
              EN
            </LangSelectorButton>
            <span> - </span>
            <LangSelectorButton
              type="button"
              onClick={() => setLanguage('fr')}
              active={i18next.language === 'fr'}
            >
              FR
            </LangSelectorButton>
          </LangSelector>
        </Footer>
      </LeftSide>
      <RightSide>
        <BackgroundContainer>
          {/* <PricingButton
            onClick={() => console.log('pricing')}
            value="See our pricing"
            dataTestId="marketplace_login_SeePricing"
          >
            See our pricing
          </PricingButton> */}
          {/* <Quote>
            <h2>
              &quot;What do you mean, we spend 50% of our revenue on
              preparé?!&quot;
            </h2>
            <p>- A random member of the Board</p>
          </Quote> */}
          <LouiseImage src={bg} alt="Louise" draggable="false" />
        </BackgroundContainer>
      </RightSide>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  font-size: 2rem;
  line-height: 2.6rem;
  flex-wrap: nowrap;

  @media (orientation: portrait) {
    flex-flow: column-reverse wrap;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

// const PricingButton = styled(ActionButton)`
//   position: absolute;
//   right: 5rem;
//   top: 3rem;
// `;

const LeftSide = styled.div`
  width: 45vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 3rem 3rem 5rem;

  @media (orientation: portrait) {
    width: 100%;
    height: 100%;
    margin: auto;
  }
`;

const LogoContainer = styled.div`
  height: 7rem;
  padding-top: 3rem;
`;

const Logo = styled.img`
  height: 100%;
`;

const CopyRight = styled.p`
  color: ${({ theme }) => theme.typo.interactive};
  font-size: 1.2rem;
`;

const LouiseImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 105%;
  flex: 0 0 100%;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const BackgroundContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 0;
  bottom: 0;
  right: 0;
  top: 0;
  width: 100%;

  @media (orientation: portrait) {
    display: none;
  }
`;

const RightSide = styled.div`
  width: 55vw;
  display: flex;
  z-index: 0;
  justify-content: center;
  background: linear-gradient(
    115deg,
    ${({ theme }) => theme.brand.primary}0A 50%,
    ${({ theme }) => theme.brand.secondary}34 50%
  );
  background-repeat: no-repeat;
  flex-wrap: wrap;
  position: relative;

  @media (orientation: portrait) {
    width: 100%;
    position: initial;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  & a {
    text-decoration: none;
    font-size: 1.4rem;
    margin-bottom: 0.2rem;
    color: ${({ theme }) => theme.typo.highlight};
  }

  @media (orientation: portrait) {
    width: auto;
    margin: 0;
    flex: 0 0 100%;
    position: absolute;
    bottom: 2rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const ForgotPassword = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.typo.subTitle};
  border-bottom: 1px solid ${({ theme }) => theme.typo.subTitle};
  margin-bottom: 1rem;
  font-size: 1.2rem;
  margin-top: 0.5rem;
  align-self: flex-end;
`;

const InputWrapper = styled.div`
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;

  & input {
    & :focus {
      outline: none;
    }
  }
`;

const FixedHeightInput = styled(TextInput)`
  height: 9rem;

  /* & input:focus {
    border-color: #aeaeae;
  } */
`;

const LoginContainer = styled.div`
  margin-left: 10%;
  margin-right: 14%;
  display: flex;
  flex-direction: column;
`;

const AuthenticationErrorContainer = styled.div`
  height: 2rem;
  margin-bottom: 2rem;
`;

const AuthenticationError = styled.p`
  font-size: 1.4rem;
  color: #f74040;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 3rem;
  line-height: 4.2rem;
  margin-bottom: 2rem;
`;

const Description = styled.p`
  font-weight: normal;
  font-size: 2rem;
  line-height: 1.45;
  color: #76726e;
  margin-bottom: 6rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  z-index: 1;
  flex-wrap: wrap;
  min-width: 100%;

  & button {
    width: 20rem;
    font-size: 1.6rem;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 3rem;
`;

const LangSelector = styled.div`
  display: flex;
  align-self: end;
  align-items: baseline;
  z-index: 10;
  margin-top: auto;

  & span {
    color: ${({ theme }) => theme.typo.interactive};
    width: 1rem;
  }
`;

const LangSelectorButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  align-items: center;
  outline: none;
  display: flex;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.typo.interactive};
  font-weight: ${props => (props.active ? 'bold' : null)};
  width: 4rem;

  &:hover {
    font-weight: bold;
  }
`;

BrokerLogin.propTypes = {
  history: shape().isRequired,
};

export default withRouter(BrokerLogin);
