/* stylelint-disable value-keyword-case */
import React from 'react';
import styled from 'styled-components';
import { string, func, bool, node, number } from 'prop-types';
import { getCurrentTheme } from '../store';

const DashboardCheckboxWithIcon = ({
  className,
  icon,
  text,
  name,
  onChange,
  checked,
  disabled,
  wide,
  children,
  ...otherProps
}) => {
  const Icon = icon;
  return (
    <Container
      wide={wide}
      checked={checked}
      disabled={disabled}
      {...otherProps}
    >
      <input
        type="checkbox"
        className={className}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <Content>
        {icon ? (
          <Icon
            color={
              checked
                ? getCurrentTheme().brand.primary
                : disabled
                ? '#ccc'
                : '#c1c1c1'
            }
          />
        ) : null}
        {children}
        <Text>{text}</Text>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background: white;
  border: 1px solid #c1c1c1;
  color: #c1c1c1;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 2.5rem;
  border-radius: 2px;
  flex: 0 0 calc(${props => 100 / props.wide}% - 1.5rem);
  flex-grow: 0;
  width: calc(${props => 100 / props.wide}% - 1.5rem);
  min-width: 16.5rem;
  height: 7.5rem;
  display: flex;
  padding: 2rem 3.5rem 2rem 2rem;
  line-height: 1.5;
  align-items: center;
  transition: color 0.2s ease, transform 0.2s ease;

  ${({ disabled }) =>
    disabled
      ? `background: none; transition: none; border: 1px solid #ccc; cursor: default; box-sizing: border-box; color: #ccc;`
      : ``}

  ${({ checked, theme }) =>
    checked
      ? `background: ${theme.brand.lightest};border: 1px solid ${theme.brand.primary}; box-sizing: border-box; color: ${theme.brand.primary};`
      : ``}

  & > input {
    display: none;
  }

  &:active {
    ${({ disabled }) => (!disabled ? `transform: scale(0.95);` : ``)}
  }

  @media screen and (max-width: 1000px) {
    flex: 0 0 calc(33.33% - 2.5rem);
  }
`;

const Content = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  & > svg {
    margin-right: 2rem;
    height: 2rem;
    width: 3rem;
    object-fit: contain;
  }
`;

const Text = styled.p``;

DashboardCheckboxWithIcon.defaultProps = {
  icon: '',
  text: '',
  className: '',
  name: '',
  onChange: () => {},
  checked: false,
  disabled: false,
  wide: 1,
  children: '',
};

DashboardCheckboxWithIcon.propTypes = {
  icon: node,
  text: string,
  className: string,
  name: string,
  onChange: func,
  checked: bool,
  disabled: bool,
  wide: number,
  children: string,
};

export default DashboardCheckboxWithIcon;
