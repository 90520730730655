// this is the button that's being used by the design system
import React from 'react';
import styled from 'styled-components';
import { node, bool, string, func } from 'prop-types';

const Button = ({
  className,
  children,
  disabled,
  type,
  onClick,
  secondary,
  long,
  dataTestId,
  id,
}) => (
  <StyledButton
    id={id}
    data-test-id={dataTestId}
    long={long}
    className={className}
    disabled={disabled}
    type={type}
    secondary={secondary}
    onClick={() => onClick()}
  >
    {children}
  </StyledButton>
);

const StyledButton = styled.button`
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.ui.disabled : theme.brand.primary};
  min-width: 14rem;
  font-family: ${({ theme }) => theme.font};
  padding: 0 4rem;
  height: 5.1rem;
  border-radius: 0.5rem;
  display: flex;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  color: white;
  border: 0;
  font-weight: bold;
  text-decoration: none;
  align-self: flex-end;
  margin-bottom: 2rem;
  margin-right: 2rem;
  z-index: 11;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }

  &:hover {
    background-color: ${({ theme, secondary }) =>
      secondary ? theme.ui.interactive : theme.brand.lighter};
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.ui.disabled};
  }
`;

Button.defaultProps = {
  id: '',
  disabled: false,
  type: 'button',
  children: '',
  className: '',
  onClick: () => {},
  secondary: false,
  long: false,
};

Button.propTypes = {
  id: string,
  children: node,
  disabled: bool,
  type: string,
  onClick: func,
  className: string,
  dataTestId: string,
  secondary: bool,
  long: bool,
};

export default Button;
