import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { string, bool, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
const DashboardSettingsPasswordChecks = ({
  oldPassword,
  newPassword,
  confirmNewPassword,
  isTouched,
  isPasswordValid,
}) => {
  const { t } = useTranslation();
  const [oldAndNewNotSame, setOldAndNewNotSame] = useState(false);
  const [newAndReenteredSame, setNewAndReenteredSame] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const [oneUppercaseAndLowercase, setOneUppercaseAndLowercase] = useState(
    false,
  );
  const [specialCharacter, setSpecialCharacter] = useState(false);
  const [toShortOrLong, setToShortOrLong] = useState(false);
  const [sequenceNotAllowed, setSequenceNotAllowed] = useState(false);

  useEffect(() => {
    const checksArry = [
      oldAndNewNotSame,
      newAndReenteredSame,
      oneNumber,
      oneUppercaseAndLowercase,
      specialCharacter,
      toShortOrLong,
      sequenceNotAllowed,
    ];

    isPasswordValid(!checksArry.includes(false));
  }, [
    oldAndNewNotSame,
    newAndReenteredSame,
    oneNumber,
    oneUppercaseAndLowercase,
    specialCharacter,
    toShortOrLong,
    sequenceNotAllowed,
  ]);

  useEffect(() => {
    checkIfOldAndNewNotSame(oldPassword, newPassword);
  }, [newPassword, oldPassword]);

  useEffect(() => {
    checkIfNewAndReenteredSame(newPassword, confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    checkIfOneNumber(newPassword);
    checkIfOneUppercaseAndLowercase(newPassword);
    checkIfSpecialCharacter(newPassword);
    checkIfToShortOrLong(newPassword);
    checkIfSequenceNotAllowed(newPassword);
  }, [newPassword]);

  function checkIfOldAndNewNotSame(oldPassword, newPassword) {
    const doesMatch = oldPassword === newPassword;
    setOldAndNewNotSame(!doesMatch);
  }

  function checkIfNewAndReenteredSame(newPassword, confirmNewPassword) {
    let doesMatch = confirmNewPassword === newPassword;
    if (newPassword === '') doesMatch = false;
    setNewAndReenteredSame(doesMatch);
  }

  function checkIfOneNumber(newPassword) {
    const hasNumber = /\d/.test(newPassword);
    setOneNumber(hasNumber);
  }

  function checkIfOneUppercaseAndLowercase(newPassword) {
    const hasUppercaseAndLowercase =
      /[a-z]/.test(newPassword) && /[A-Z]/.test(newPassword);
    setOneUppercaseAndLowercase(hasUppercaseAndLowercase);
  }

  function checkIfSpecialCharacter(newPassword) {
    const hasSpecialCharacter = /[éèùàçâêîôûäëïöü?!@#$%^&*(),.~?":{}|<>/+\\;-]/.test(
      newPassword,
    );
    setSpecialCharacter(hasSpecialCharacter);
  }

  function checkIfToShortOrLong(newPassword) {
    const passwordLength = newPassword.split('').length;
    const MIN = 8;
    const MAX = 32;

    const isBetweenRange = passwordLength >= MIN && passwordLength <= MAX;
    setToShortOrLong(isBetweenRange);
  }

  function checkIfSequenceNotAllowed(newPassword) {
    let containsSequence = false;
    const notAllowedSequences = [
      '1234',
      '5678',
      'abcd',
      'qwerty',
      'azerty',
      'qwertz',
    ];

    if (newPassword === '') containsSequence = true;

    notAllowedSequences.forEach(sequence => {
      if (newPassword.includes(sequence)) containsSequence = true;
    });

    setSequenceNotAllowed(!containsSequence);
  }

  return (
    <div>
      <P>{t('The following requirements must be met')}</P>
      <ul>
        <Li touched={isTouched} completed={oldAndNewNotSame}>
          {t("Old and new password can't be the same")}
        </Li>
        <Li touched={isTouched} completed={newAndReenteredSame}>
          {t('New and Re-entered passord must be the same')}
        </Li>
        <Li touched={isTouched} completed={oneNumber}>
          {t('At least one number (0 - 9)')}
        </Li>
        <Li touched={isTouched} completed={oneUppercaseAndLowercase}>
          {t('At least one uppercase and lowercase character')}
        </Li>
        <Li touched={isTouched} completed={specialCharacter}>
          {t('At least one special character ()')}
        </Li>
        <Li touched={isTouched} completed={toShortOrLong}>
          {t('Too short or long password, min 9 max 30 characters')}
        </Li>
        <Li touched={isTouched} completed={sequenceNotAllowed}>
          {t(
            'Using a sequence that is not allowed (for example 1234, azerty, abcd,)',
          )}
        </Li>
      </ul>
    </div>
  );
};

const handleColorType = (completed, touched) => {
  switch (true) {
    case touched && completed:
      return '#2c7b2c';
    case touched && !completed:
      return '#ea3535';
    default:
      return 'black';
  }
};

const P = styled.p`
  font-size: 2rem;
  font-weight: 700;
  margin: 2.5rem 0 1.5rem;
`;

const Li = styled.li`
  margin-left: 3rem;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  color: ${({ completed, touched }) => handleColorType(completed, touched)};
  ::before {
    content: '\u25CF';
    position: absolute;
    margin-left: -1.5rem;
    padding-right: 1rem;
    color: ${({ completed, touched }) => handleColorType(completed, touched)};
  }
`;

DashboardSettingsPasswordChecks.defaultProps = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  isTouched: false,
  isPasswordValid: () => {},
};

DashboardSettingsPasswordChecks.propTypes = {
  oldPassword: string.isRequired,
  newPassword: string.isRequired,
  confirmNewPassword: string.isRequired,
  isTouched: bool.isRequired,
  isPasswordValid: func.isRequired,
};

export default DashboardSettingsPasswordChecks;
