/* eslint-disable  no-unused-expressions */
import ApiRoutes from './ApiRoutes';
import content from './content.json';
import { addDataForIdWoning } from '../models/Flow/Woning/store';
import { addDataForIdUser } from '../models/Flow/User/store';
import { addDataForIdCar } from '../models/Flow/Car/store';
import { addDataForIdLock } from '../models/Flow/Lock/store';
import { getThemeObject } from '../services/themingService';
import { addDataForIdFamily } from '../models/Flow/Gezin/store';
import { addDataForIdLegal } from '../models/Flow/Legal/store';
import { removeFromCheckout } from '../models/Marketplace/LayoutMarket/store';
// import Themes from '../../config/theme';

// const themes = new Themes();

// copy JSON so we can make changes to Flow
const contentFlow = { ...content };

let chatQuestions = {};
let chatAnswers = {};

let shownAnswers = {};
let shownQuestions = {};

let browserHistory = [];

// let brokerData;

let currentView;
let showError = false;
let showTrial = false;
let trialAccount = false;
const skippedSteps = {};

Object.keys(contentFlow).forEach((key, i) => {
  skippedSteps[i + 1] = false;
});

const notices = {
  home: {
    notice: false,
    noticeCompany: null,
    noticeStartDate: null,
    policyNumber: null,
  },
  family: {
    notice: false,
    noticeCompany: null,
    noticeStartDate: null,
    policyNumber: null,
  },
  car: {
    notice: false,
    noticeCompany: null,
    noticeStartDate: null,
    policyNumber: null,
  },
  legal: {
    notice: false,
    noticeCompany: null,
    noticeStartDate: null,
    policyNumber: null,
  },
};

const addBrowserHistory = path => {
  if (!browserHistory.includes(path)) {
    browserHistory.push(path);
  } else {
    browserHistory.length = browserHistory.indexOf(path) + 1;
  }
};

const getPreviousPath = () => {
  return browserHistory[browserHistory.length - 2];
};

const setBrowserHistory = list => (browserHistory = list);

const getBrowserHistory = () => browserHistory;

let coverages = {};
Object.keys(contentFlow).forEach(key => {
  coverages[key] = true;
});

coverages['marketplace'] = true;

const flow = {};
Object.keys(contentFlow).forEach(key => {
  flow[key] = true;
});
flow['marketplace'] = true;

const errorMessages = {
  USER_NOT_FOUND: 'Deze gebruiker werd niet gevonden',
  PASSWORD_MISMATCH: 'Je wachtwoord klopt niet',
  NO_BODY: 'No body provided',
  WRONG_BODY: 'Wrong body provided',
  APITOKEN_INCORRECT: 'Incorrect api token',
  CAPTCHA_INCORRECT: 'Incorrect captcha login',
  SYSTEMTOKEN_INCORRECT: 'Incorrect system token',
  HEADER_NOT_FOUND: 'No system token in header',
  URL_NOT_FOUND: 'Requested URL not found',
  METHOD_INCORRECT: 'Cannot use method on path',
  URI_DOESNT_EXIST: "URI doesn't exist",
  TOKEN_INVALID: 'Bearer token invalid',
  TOKEN_EXPIRED: 'Bearer token expired (30 min)',
  USER_NOT_ALLOWED: 'Je bent niet gemachtigd voor deze actie',
};

const updateShownChat = (type, key, secondkey, value) => {
  if (type === 'question') {
    const tempShownQuestions = { ...shownQuestions };
    if (tempShownQuestions[key] === undefined) {
      tempShownQuestions[key] = {};
    }
    tempShownQuestions[key][secondkey] = value;
    shownQuestions = tempShownQuestions;
    return tempShownQuestions;
  } else if (type === 'answer') {
    const tempShownAnswers = { ...shownAnswers };
    if (tempShownAnswers[key] === undefined) {
      tempShownAnswers[key] = {};
    }
    tempShownAnswers[key][secondkey] = value;
    shownAnswers = tempShownAnswers;
    return tempShownAnswers;
  }
};

const getShownAnswers = () => shownAnswers;

const getShownQuestions = () => shownQuestions;

const setFlow = (key, val) => {
  flow[key] = val;
};

const getFlow = () => flow;

const setCoverage = (key, value) => {
  coverages[key] = value;
  for (let i = 0; i < Object.keys(coverages).length; i++) {
    if (Object.keys(coverages)[i] === key) {
      setSkippedSteps(i + 1, !value);
    }
  }
};

const setNotice = (key, value, company) => {
  notices[key].notice = value;
  notices[key].noticeCompany = company ? company : null;
};

const setNoticeKey = (type, key, value) => {
  notices[type][key] = value;
};

const getAllCoverages = () => coverages;

const getCurrentStep = () => CalculateSteps().step;

const getSubStep = () => CalculateSteps().subStep;

const getSkippedSteps = () => skippedSteps;

const setSkippedSteps = (step, bool) => {
  skippedSteps[step] = bool;
};

const resetSkippedSteps = () => {
  Object.keys(skippedSteps).forEach(key => {
    skippedSteps[key] = false;
  });
};

const resetCoverages = () => {
  coverages = { ...coveragesBackup };
};

const getRouteByStep = (mainStep, sub) => {
  const key = Object.keys(contentFlow)[mainStep];
  return contentFlow[key][sub].route;
};

// API calls
const api = new ApiRoutes();

const generateId = () =>
  api
    .getId()
    .then(res => {
      sessionStorage.setItem('broker_id', res.broker_id);
      sessionStorage.setItem('id', res.id);
      return res;
    })
    .catch(err => {
      Promise.reject(err);
    });

const generateSocialId = (brokerId, history) => {
  return api
    .getSocialId(brokerId)
    .then(({ data }) => {
      addDataForIdWoning(data.id);
      addDataForIdUser(data.id);
      addDataForIdCar(data.id);
      addDataForIdLock(data.id);
      addDataForIdLegal(data.id);
      addDataForIdFamily(data.id);
      removeFromCheckout('fire');
      removeFromCheckout('people');
      removeFromCheckout('car');
      removeFromCheckout('legal');
      sessionStorage.setItem('id', data.id);
      sessionStorage.setItem('broker_id', brokerId);
    })
    .catch(err => {
      history.push(`/broker/${brokerId}?popup=error`);
      showErrorPopup(true);
      console.error(err);
    });
};

const generateCampaignId = (brokerId, campaignId) => {
  return api.getCampaignId(brokerId, campaignId).then(({ data }) => {
    addDataForIdWoning(data.id);
    addDataForIdUser(data.id);
    addDataForIdCar(data.id);
    addDataForIdLock(data.id);
    addDataForIdLegal(data.id);
    addDataForIdFamily(data.id);
    removeFromCheckout('fire');
    removeFromCheckout('people');
    removeFromCheckout('car');
    removeFromCheckout('legal');
    sessionStorage.setItem('id', data.id);
    sessionStorage.setItem('broker_id', brokerId);
  });
};

const getUserId = () => sessionStorage.getItem('id');

const auth = {
  isAuthenticated: false,
};

const logOut = history => {
  auth.isAuthenticated = false;
  auth.keepSignedIn = false;
  api
    .getLogout()
    .then(() => history.push('/login'))
    .catch(() => history.push('/login'));
};

const showErrorPopup = answer => {
  showError = answer;
};

const getErrorPopup = () => {
  return showError;
};

const showTrialPopup = answer => {
  showTrial = answer;
};

const getTrialPopup = () => {
  return showTrial;
};

const setTrialAccount = bool => {
  trialAccount = bool;
};

const getTrialAccount = () => {
  return trialAccount;
};

let thema = getThemeObject();

const setCurrentTheme = theme => {
  thema = theme;
};

const getCurrentTheme = () => thema;

const setFlowLogic = canSellItems => {
  let can_sell_data = {
    home: false,
    family: false,
    car: false,
    legal: false,
  };

  canSellItems.forEach(item => {
    can_sell_data[item] = true;
  });

  Object.keys(can_sell_data).forEach(sellKey => {
    if (
      can_sell_data[sellKey] === null ||
      can_sell_data[sellKey] === false ||
      can_sell_data[sellKey].length === 0
    ) {
      setFlow(sellKey, false);
      setCoverage(sellKey, false);
      coveragesBackup[sellKey] = false;
      Object.keys(contentFlow).forEach((key, i) => {
        if (key === sellKey) {
          setSkippedSteps(i + 1, true);
        }
      });
    } else {
      setFlow(sellKey, true);
      setCoverage(sellKey, true);
      coveragesBackup[sellKey] = true;
    }
  });
  setCoverage('lock', can_sell_data.home || can_sell_data.family);
  setFlow('lock', can_sell_data.home || can_sell_data.family);
  coveragesBackup.lock = can_sell_data.home || can_sell_data.family;
};

const pad = n => (parseInt(n) < 10 ? '0' + parseInt(n) : parseInt(n));

const getNextPath = letter => {
  let AllStepKeys = Object.keys(contentFlow);
  AllStepKeys.forEach(stepKey => {
    const mainStep = contentFlow[stepKey];
    mainStep.forEach(_subStep => {
      let route = _subStep.route;

      if (route.includes('/id')) {
        route = route.replace('/id', `/${window.location.hash.split('/')[3]}`);
      }

      if (`#${route}` === window.location.hash) {
        currentView = _subStep;
      }
    });
  });

  // check if next step is skipped
  if (letter === 'last') {
    const pageNumber = CalculateSteps();
    let stop = false;
    let nextStepNumber;
    for (let i = 0; i < AllStepKeys.length - pageNumber.step; i++) {
      const key = Object.keys(contentFlow)[pageNumber.step + i];
      if (stop === false) {
        if (coverages[key]) {
          stop = true;
          nextStepNumber = pageNumber.step + i;
        }
      }
    }
    return getRouteByStep(nextStepNumber, 0);
  } else if (letter) {
    return currentView.nextStep[letter];
  }
  return currentView.nextStep['a'];
};

const CalculateSteps = () => {
  let step;
  let subStep;
  let AllStepKeys = Object.keys(contentFlow);

  AllStepKeys.forEach((stepKey, i) => {
    const mainStep = contentFlow[stepKey];
    mainStep.forEach((_subStep, j) => {
      let route = _subStep.route;
      if (route.includes('/id')) {
        route = route.replace('/id', `/${window.location.hash.split('/')[3]}`);
      }

      if (`#${route}` === window.location.hash) {
        step = i + 1;
        subStep = j;
      }
    });
  });

  return { step, subStep };
};

const getStepNames = () => Object.keys(contentFlow);

const getContent = () => {
  return contentFlow;
};
const getComponentName = () => {
  let AllStepKeys = Object.keys(contentFlow);
  let component;

  AllStepKeys.forEach(stepKey => {
    const mainStep = contentFlow[stepKey];
    mainStep.forEach(_subStep => {
      if (`#${_subStep.route}` === window.location.hash) {
        component = _subStep;
      }
    });
  });
  return component.component;
};

const getComponentPath = name => {
  let AllStepKeys = Object.keys(contentFlow);
  let route;

  AllStepKeys.forEach(stepKey => {
    const mainStep = contentFlow[stepKey];
    mainStep.forEach(_subStep => {
      if (_subStep.component === name) {
        route = _subStep.route;
      }
    });
  });
  return route;
};

const getChatboxAnswers = () => chatAnswers;
const getChatboxQuestions = () => chatQuestions;

const resetChatboxAnswers = () => {
  chatAnswers = {};
  shownAnswers = {};
};
const resetChatboxQuestions = () => {
  chatQuestions = {};
  shownQuestions = {};
};

const addChatboxAnswer = answer => {
  if (!chatAnswers[getCurrentStep()]) {
    chatAnswers[getCurrentStep()] = {};
  }
  const today = new Date();
  chatAnswers[getCurrentStep()][getSubStep()] = {
    answer,
    timeStamp: `${pad(today.getHours())}:${pad(today.getMinutes())}`,
  };
};

const addChatboxQuestion = question => {
  if (!chatQuestions[getCurrentStep()]) {
    chatQuestions[getCurrentStep()] = {};
  }
  const today = new Date();
  chatQuestions[getCurrentStep()][getSubStep()] = {
    question,
    timeStamp: `${pad(today.getHours())}:${pad(today.getMinutes())}`,
  };
};

const removeHigherAnswers = showQuestion => {
  // delete higher answers
  Object.keys(chatAnswers).forEach(answerKey => {
    if (parseInt(answerKey) > getCurrentStep()) {
      delete chatAnswers[answerKey];
      delete shownAnswers[answerKey];
    } else if (parseInt(answerKey) === getCurrentStep()) {
      Object.keys(chatAnswers[answerKey]).forEach(subKey => {
        if (parseInt(subKey) >= getSubStep()) {
          delete chatAnswers[answerKey][subKey];
          delete shownAnswers[answerKey][subKey];
        }
      });
    }
  });

  // delete higher questions
  Object.keys(chatQuestions).forEach(answerKey => {
    if (parseInt(answerKey) > getCurrentStep()) {
      delete chatQuestions[answerKey];
      delete shownQuestions[answerKey];
    } else if (parseInt(answerKey) === getCurrentStep()) {
      Object.keys(chatQuestions[answerKey]).forEach(subKey => {
        if (parseInt(subKey) > getSubStep()) {
          delete chatQuestions[answerKey][subKey];
          delete shownQuestions[answerKey][subKey];
        } else if (parseInt(subKey) === getSubStep() && !showQuestion) {
          delete chatQuestions[answerKey][subKey];
          if (shownQuestions[answerKey]) {
            if (shownQuestions[answerKey][subKey]) {
              delete shownQuestions[answerKey][subKey];
            }
          }
        }
      });
    }
  });
};

let isSocialLogin = false;

// Social login
const setSocialLogin = bool => {
  isSocialLogin = bool;
};

const getSocialLogin = () => isSocialLogin;

let coveragesBackup = { ...coverages };

export {
  notices,
  // getBrokerData,
  // setBrokerData,
  generateId,
  updateShownChat,
  getShownAnswers,
  getShownQuestions,
  setNoticeKey,
  generateSocialId,
  generateCampaignId,
  getUserId,
  getCurrentStep,
  getSubStep,
  getSkippedSteps,
  setSkippedSteps,
  resetSkippedSteps,
  setNotice,
  auth,
  setBrowserHistory,
  getBrowserHistory,
  pad,
  getRouteByStep,
  logOut,
  setCurrentTheme,
  getCurrentTheme,
  // setOnlyTheme,
  // getThemes,
  showErrorPopup,
  getErrorPopup,
  showTrialPopup,
  getTrialPopup,
  setTrialAccount,
  getTrialAccount,
  setCoverage,
  addBrowserHistory,
  getPreviousPath,
  getAllCoverages,
  resetCoverages,
  getFlow,
  getNextPath,
  errorMessages,
  getStepNames,
  getContent,
  getComponentName,
  setFlowLogic,
  getComponentPath,
  getChatboxAnswers,
  getChatboxQuestions,
  addChatboxQuestion,
  addChatboxAnswer,
  resetChatboxAnswers,
  resetChatboxQuestions,
  removeHigherAnswers,
  setSocialLogin,
  getSocialLogin,
};
