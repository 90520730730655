const insuranceModuleNames = {
  vivium: 'V-Connect',
  allianz: 'ProLink',
  axa: 'Phoenix',
  baloise: 'MyBaloise',
  fidea: 'Fiona@Broker',
};

export function getInsuranceModuleName(insuranceKey) {
  if (Object.keys(insuranceModuleNames).includes(insuranceKey)) {
    return insuranceModuleNames[insuranceKey];
  } else {
    return null;
  }
}
