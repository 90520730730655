import { setLoggedIn } from './authService';
import { setMeData } from './meDataService';
import { BASE_URL } from '../../env.config';

let headers = {
  Accept: 'application/vnd.api+json',
};

export async function refresh() {
  const path = 'v1/api/aut/v1/api/auth/refresh';
  const [resp, status] = await request(path, 'POST', {});
  if (status > 201) {
    logout();
    return;
  }
  await setMeData();
  return [resp, status];
}

export async function request(
  path,
  method,
  payload = null,
  customHeaders = null,
) {
  let [resp, status] = await _request(path, method, payload, customHeaders);

  if (status === 401) {
    if (resp.msg === 'INVALID_TOKEN') {
      await logout();
    } else if (resp.msg === 'AUTHENTICATION_EXPIRED') {
      await refresh();
      [resp, status] = await _request(path, method, payload, customHeaders);
    }
  }

  return [resp, status];
}

export async function logout() {
  const path = 'v1/api/aut/v1/api/logout';
  const [resp, status] = await request(path, 'GET');
  if (status === 200) {
    setLoggedIn(false);
    window.location.href = '#/logout';
  } else {
    console.error(resp, status);
  }
}

async function _request(path, method, payload, customHeaders) {
  const url = BASE_URL + path;

  let req;

  if (customHeaders !== null) {
    customHeaders.Accept = 'application/vnd.api+json';
    headers = customHeaders;
  }

  if (payload == null) {
    req = await fetch(`${url}`, {
      method: method,
      headers: headers,
      credentials: 'include',
    });
  } else {
    req = await fetch(`${url}`, {
      method: method,
      headers: headers,
      body: payload instanceof FormData ? payload : JSON.stringify(payload),
      credentials: 'include',
    });
  }

  const resp = await req.json();
  return [resp, req.status];
}
