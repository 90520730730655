import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import Layout from '../../DashboardLayout/views/Layout';
import DashboardToolbarBrokerHistory from '../../../components/DashboardToolbarBrokerHistory';
import { shape } from 'prop-types';
import ClientInfo from '../components/ClientInfo';
import BrokerHistoryDetailInterests from '../components/BrokerHistoryDetailInterests';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import {
  setTabItems,
  getHistory,
  setInsurTypes,
} from '../../../services/brokerhistoryService';
import { capitalize } from '../../../services/stringService';
import { getNestedObject } from '../../../services/objectService';
import { useTranslation } from 'react-i18next';

const BrokerHistoryDetailPersonal = ({ match, history }) => {
  const { setNavTabs } = useContext(TopNavigationItems);
  const [filteredInsureTypes, setFilteredInsureTypes] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});
  const [extraInfo, setExtraInfo] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setInsurTypes(match.params.id).then(res => {
      setSessionData(res.sessionData);
      setFilteredInsureTypes(res.filteredData);
      const tmpItems = setTabItems(match.params.id, res.sessionData, t);
      setNavTabs(tmpItems ? tmpItems : []);
    });
  }, []);

  useEffect(() => {
    // safely check if it exists in object
    const { first_name, last_name, telephonenr, email, age, birth } =
      getNestedObject(sessionData, ['personal']) || {};

    const { street, housenr, zipcode, city } =
      getNestedObject(sessionData, ['personal', 'billing_address']) || {};

    setPersonalInfo({
      name: `${first_name} ${last_name}`,
      street: capitalize(street),
      housenr,
      zipcode,
      city: capitalize(city),
      telephone: telephonenr,
      email: email,
      age,
      birth: birth
        ? birth
            .split('/')
            .reverse()
            .join('/')
        : birth,
    });

    const { garden, pets, drone, bicycle, art, juwels, e_step, informatics } =
      getNestedObject(sessionData, ['extra']) || {};

    setExtraInfo({
      garden,
      pets,
      drone,
      bicycle,
      art,
      juwels,
      e_step,
      informatics,
    });
  }, [sessionData]);

  return (
    <>
      <DashboardToolbarBrokerHistory
        onClick={() => {
          return getHistory(
            match.params.id,
            filteredInsureTypes,
            history,
            t,
            sessionData,
          );
        }}
        dataTestId="dashboard_history_detail_calculate"
        btnText={t('History.goToMarket')}
      />
      <SectionWrapper data-test-id="dashboard_history_detail_client_data">
        <ClientInfo
          name={personalInfo.name}
          street={personalInfo.street}
          housenr={personalInfo.housenr}
          zipcode={personalInfo.zipcode}
          city={personalInfo.city}
          telephone={personalInfo.telephone}
          email={personalInfo.email}
          age={personalInfo.age}
          birth={personalInfo.birth}
        />
        <BrokerHistoryDetailInterests extraData={extraInfo} />
      </SectionWrapper>
    </>
  );
};

const BrokerHistoryDetailPersonalWrapper = ({ match, history }) => {
  return (
    <StyledLayout>
      <BrokerHistoryDetailPersonal match={match} history={history} />
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

BrokerHistoryDetailPersonal.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

BrokerHistoryDetailPersonalWrapper.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

export default withRouter(BrokerHistoryDetailPersonalWrapper);
