import React from 'react';
import styled from 'styled-components';
import Step from './Step';

import { withRouter } from 'react-router';
import { shape } from 'prop-types';
import {
  getCurrentStep,
  getSubStep,
  getCurrentTheme,
  auth,
  getSkippedSteps,
  getFlow,
  getStepNames,
  getPreviousPath,
  setBrowserHistory,
  getSocialLogin,
} from '../../../../../store/index';

import PhoneIcon from '../../../../../../assets/images/step-icon-phone';
import HouseIcon from '../../../../../../assets/images/step-icon-house';
import PeopleIcon from '../../../../../../assets/images/step-icon-people';
import LegalIcon from '../../../../../../assets/images/step-icon-legal';
import CarIcon from '../../../../../../assets/images/step-icon-car';
import LockIcon from '../../../../../../assets/images/step-icon-lock';
import CartIcon from '../../../../../../assets/images/market-icon-cart';
import BackIcon from '../../../../../../assets/images/go-back';
import DashboardIcon from '../../../../../../assets/images/dashboard-icon';
import { Link } from 'react-router-dom';

const ProgressBar = ({ history }) => {
  const skippedSteps = getSkippedSteps();
  let flow = getFlow();

  const icons = [
    <PhoneIcon key="Phone" colors={getCurrentTheme()} />,
    <HouseIcon key="House" colors={getCurrentTheme()} />,
    <PeopleIcon key="People" colors={getCurrentTheme()} />,
    <LockIcon key="Lock" colors={getCurrentTheme()} />,
    <CarIcon key="Car" colors={getCurrentTheme()} />,
    <LegalIcon key="Legal" colors={getCurrentTheme()} />,
    <CartIcon key="Cart" colors={getCurrentTheme()} />,
  ];

  const handleGoBack = e => {
    e.preventDefault();
    if (getPreviousPath()) {
      history.push(getPreviousPath());
    } else {
      history.push('/dashboard');
      setBrowserHistory([]);
    }
  };

  function shouldShowBackButton(_auth) {
    if (getSocialLogin() && getCurrentStep() === 1 && getSubStep() === 1)
      return false;
    return _auth.isAuthenticated;
  }

  function shouldDashboardButton(_auth) {
    return !getSocialLogin() && _auth.isAuthenticated;
  }

  return (
    <StepsContainer>
      {shouldDashboardButton(auth) && (
        <GoDashboard
          data-test-id="underwriting_dashboard_nav"
          to="/dashboard"
          title="Dasbhoard"
        >
          <GoBackIconContainer>
            <DashboardIcon />
          </GoBackIconContainer>
        </GoDashboard>
      )}
      {shouldShowBackButton(auth) && (
        <GoBack
          data-test-id="underwriting_goback_nav"
          title="Back"
          onClick={handleGoBack}
        >
          <GoBackIconContainer>
            <BackIcon />
          </GoBackIconContainer>
        </GoBack>
      )}
      {getStepNames().map((stepName, i) => {
        if (flow[Object.keys(flow)[i]]) {
          return (
            <Step
              key={i}
              skipped={skippedSteps[i + 1]}
              currentStep={getCurrentStep()}
              dataTestId={`underwriting_${stepName}_nav`}
              subStep={getSubStep()}
              content={{ id: i + 1, name: stepName }}
              icon={icons[i]}
            />
          );
        }
        return undefined;
      })}
    </StepsContainer>
  );
};

const StepsContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
`;

const GoDashboard = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disable ? `default` : 'pointer')};
  background-color: ${({ theme, disable }) =>
    disable ? `darkgrey` : theme.brand.secondary};
  border: none;
  width: 5.6rem;
  height: 5.6rem;
  font-size: 2.5rem;
  border-radius: 50%;
  padding: 0;
  margin-right: 2rem;

  & > div > svg {
    margin-left: 0;
  }

  :hover {
    background-color: ${({ theme, disable }) =>
      disable ? 'none' : theme.brand.lighter};
  }

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

const GoBackIconContainer = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  color: white;
  font-weight: bold;
  font-size: 2.5rem;
  padding: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoBack = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${props => (props.disable ? `default` : 'pointer')};
  background-color: ${({ theme, disable }) =>
    disable ? `darkgrey` : theme.brand.secondary};
  border: none;
  width: 5.6rem;
  height: 5.6rem;
  font-size: 2.5rem;
  border-radius: 50%;
  padding: 0;
  margin-right: 2rem;

  :hover {
    background-color: ${({ theme, disable }) =>
      disable ? 'none' : theme.brand.lighter};
  }

  @media screen and (max-width: 800px) {
    margin-right: 3.5rem;
  }
`;

ProgressBar.propTypes = {
  history: shape().isRequired,
};

export default withRouter(ProgressBar);
