import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';

const LeftSide = ({ children, className }) => {
  return (
    <LeftSideContainer className={className}>{children}</LeftSideContainer>
  );
};

const LeftSideContainer = styled.div`
  overflow: auto;
  display: flex;
  /* width: 45%; */
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

LeftSide.defaultProps = {
  className: null,
};

LeftSide.propTypes = {
  children: node.isRequired,
  className: string,
};

export default LeftSide;
