/* stylelint-disable declaration-no-important */
import React, { useState, useRef, useEffect } from 'react';

import styled from 'styled-components';
import { string, node, func, number, oneOfType } from 'prop-types';
import { isMobile } from 'react-device-detect';
import InfoPopUp from '../../../../components/InfoPopUp';

const AppartmentInput = ({
  type,
  name,
  children,
  placeholder,
  handleChange,
  infoTitle,
  value,
  incrValue,
  dataTestId,
  info,
}) => {
  const [error, setError] = useState();
  const [inputValue, setValue] = useState(
    isMobile ? placeholder.toString() : value,
  );
  const [focus, setFocus] = useState();
  const focusInput = useRef(null);
  let err;

  useEffect(() => {
    if (inputValue !== '') {
      switch (name) {
        case 'floor':
          if (inputValue < -1 || inputValue > 100) {
            const msg = 'Het verdiep moet tussen -1 en 100 liggen';
            setError(msg);
            err = true;
          } else {
            err = false;
            setError(null);
          }
          break;
        case 'surface':
          if (inputValue < 1 || inputValue > 625) {
            const msg = 'De opp. moet tussen 1 en 625m² liggen';
            setError(msg);
            err = true;
          } else {
            err = false;
            setError(null);
          }
          break;
        case 'price':
          if (inputValue < 200 || inputValue > 2500) {
            const msg = 'De prijs moet tussen €200 en €2500 liggen';
            setError(msg);
            err = true;
          } else {
            err = false;
            setError(null);
          }
          break;
        default:
          break;
      }
    }
    handleChange(name, inputValue, err);
  }, [inputValue]);

  const handleClick = () => {
    focusInput.current.focus();
    setFocus(true);
  };

  const changeValue = (e, value) => {
    const num = parseInt(inputValue) || 0;
    switch (e.target.attributes.type.nodeValue) {
      case 'minus':
        if (num - value === 0) {
          setValue('0');
        } else {
          setValue((num - value).toString());
        }
        break;
      case 'plus':
        if (num + value === 0) {
          setValue('0');
        } else {
          setValue((num + value).toString());
        }
        break;
    }
  };

  return (
    <>
      <StyledLabel htmlFor={name}>
        <Vraag focus={focus}>{children}</Vraag>
        <Flex onClick={handleClick}>
          {isMobile ? (
            <Counter type="minus" onClick={e => changeValue(e, incrValue)}>
              -
            </Counter>
          ) : null}
          <Input>
            {name === `price` ? <Icon>€</Icon> : null}
            <StandardInput
              ref={focusInput}
              id={name}
              type={type}
              data-test-id={dataTestId}
              name={name}
              placeholder={placeholder}
              value={inputValue ? inputValue : ''}
              onChange={e => {
                if (!isNaN(e.target.value)) {
                  setValue(e.target.value);
                }
              }}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            />
            {name === `habitableSurface` ? <Icon>m²</Icon> : null}
            {info !== null ? <InfoPopUp title={infoTitle} info={info} /> : null}
          </Input>
          {isMobile ? (
            <Counter type="plus" onClick={e => changeValue(e, incrValue)}>
              +
            </Counter>
          ) : null}
          <ErrorMss>{error}</ErrorMss>
        </Flex>
      </StyledLabel>
    </>
  );
};

const Counter = styled.div`
  background-color: ${({ theme, type }) =>
    type === 'minus' ? theme.brand.dark : theme.brand.secondary};
  width: 3rem;
  align-self: center;
  height: 3rem;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  margin: ${props => (props.type === 'minus' ? '0 1rem 0 0' : '0 0 0 1rem')};
  color: white;
  font-weight: 900;
  font-size: 2rem;
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 0;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:active {
    transform: scale(0.9);
  }
`;

const Input = styled.div`
  background-color: white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  border-radius: ${isMobile ? '14px' : '5px'};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  overflow: hidden;
  padding-left: ${isMobile ? '1.5rem' : '2rem'};
  padding-right: ${isMobile ? '0.5rem' : '2rem'};
`;

const StyledLabel = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  line-height: 2.1rem;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  justify-content: space-between;

  & > span {
    display: flex;
    margin-bottom: 0.5rem;
    flex: 0 0 55%;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }
`;

const Flex = styled.div`
  display: flex;
  flex: 0 0 45%;
  justify-content: space-between;
  cursor: text;
  height: 4rem;
  position: relative;
`;

const Icon = styled.i`
  margin-right: 0.5rem;
`;
const Vraag = styled.span`
  color: ${({ theme, focus }) => (focus ? theme.brand.primary : '#1E1C1C')};
  flex: 0 0 50%;
  display: flex;
  padding-right: 2rem;
  align-items: center;
  justify-content: center;
  font-weight: normal !important;

  & p {
    flex: 1 1 100%;
    max-width: 100%;
  }

  & > span {
    font-weight: bold;
  }
`;

const StandardInput = styled.input`
  border: 0;
  line-height: 2.1rem;
  font-family: ${({ theme }) => theme.font};
  font-size: 1.5rem;
  width: 55%;
  transition: all 0.15s ease;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  &::placeholder {
    opacity: 0.6;
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const ErrorMss = styled.p`
  position: absolute;
  font-size: 1.1rem;
  line-height: 1.1;
  color: red;
  right: 0;
  top: 4.5rem;
  max-width: 100% !important;
  cursor: default;
`;

AppartmentInput.defaultProps = {
  placeholder: null,
  error: null,
  touched: {},
  incrValue: 1,
  info: null,
  infoTitle: null,
};

AppartmentInput.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  children: node.isRequired,
  placeholder: string,
  incrValue: number,
  dataTestId: string,
  error: string,
  info: string,
  infoTitle: string,
  handleChange: func.isRequired,
  value: oneOfType([number, string]),
  // getInput: func.isRequired,
};

export default AppartmentInput;
