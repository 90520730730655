import React from 'react';
import styled from 'styled-components';
import { node, func, string, bool } from 'prop-types';

import checkmark from '../../assets/images/checkbox-checkmark.svg';

const Checkbox = ({
  onChange,
  className,
  dataTestId,
  children,
  checked,
  name,
}) => {
  return (
    <StyledLabel
      className={className}
      htmlFor={name}
      checked={checked}
      data-test-id={dataTestId}
    >
      <StandardInput
        id={name}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={e => onChange(e.target.checked)}
      />
      <CheckboxItem checked={checked}>
        {checked ? <img src={checkmark} alt="checkmark" /> : ''}
      </CheckboxItem>
      <span>{children}</span>
    </StyledLabel>
  );
};

const StyledLabel = styled.label`
  display: flex;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;
  line-height: 1.3;
  width: 80%;

  & > span a {
    font-size: 1.4rem;
    transition: color 0.1s ease;
    color: ${props => (props.checked ? '#00CD39' : '#525252')};
  }

  & > span {
    font-size: 1.4rem;
    width: 90%;
    margin-left: 1rem;
    transition: color 0.1s ease;
    color: ${props => (props.checked ? '#00CD39' : '#525252')};

    & > :first-child {
      color: ${props => (props.checked ? '#00CD39' : 'black')};
    }
  }

  & + label {
    margin-top: 1.5rem;
  }
`;

const CheckboxItem = styled.div`
  width: 1.7rem;
  height: 1.7rem;
  background-color: white;
  transition: border 0.1s ease;
  border: ${props =>
    props.error
      ? '0.2rem solid #F74040'
      : props.checked
      ? '0.2rem solid #00CD39'
      : '0.2rem solid #a29c95'};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StandardInput = styled.input`
  border-radius: 0.3rem;
  opacity: 0;
  margin-right: -1.6rem;
  &:focus + ${CheckboxItem} {
    outline: none;
    border-color: ${({ theme }) => theme.brand.lighter};
  }
`;

Checkbox.defaultProps = {
  checked: false,
  className: '',
  name: '',
};

Checkbox.propTypes = {
  children: node.isRequired,
  onChange: func.isRequired,
  checked: bool,
  className: string,
  name: string,
  dataTestId: string,
};

export default Checkbox;
