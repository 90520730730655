import React from 'react';
import FamilyQuestion from '../components/FamilyQuestion';

// Content in content.json is required for this component
// questionNumber = subStep = contentBlock in content.json

const FourthQuestion = () => (
  <FamilyQuestion
    dataTestId1="underwriting_answer_yes"
    dataTestId2="underwriting_answer_no"
    questionNumber={3}
  />
);

export default FourthQuestion;
