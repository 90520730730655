import React, { useState, useEffect } from 'react';
import { object, array, func, bool } from 'prop-types';
import {
  Section,
  Text,
  Label,
  EditActionButton,
  AddEntityButton,
  ActionButton,
  TextInput,
  SearchSelectInput,
} from 'wg-fe-ui';
import styled from 'styled-components';
import DateInput from './DateInput_v3';
import {
  formatISODate,
  parseIncDateToIsoFormat,
} from '../services/dateService';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useForm from '../hooks/useForm';
import _ from 'lodash';
import {
  firstName,
  lastName,
  date,
  street,
  city,
  boxNr,
  zipcode,
  housenr,
  number,
  string,
} from '../../constants/validationSchemas';

const CRMRiskObjectDetailCarDrivers = ({
  mainDriver,
  secondaryDrivers,
  onSubmitFunction,
  customerPageFunction,
  disableEditing,
}) => {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const [mainDriverState, setMainDriverState] = useState({});
  const [driversLicense, setDriversLicense] = useState('');
  const [driversProfession, setDriversProfession] = useState('');

  useEffect(() => {
    if (mainDriver != null) {
      setMainDriverState(mainDriver);
    }
  }, [mainDriver]);

  useEffect(() => {
    setInitialFormValues();
  }, [mainDriverState]);

  const driverLicenseOptions = [
    { value: 'PROVISIONAL', label: t('Provisional') },
    // { value: 'PROVISIONAL_CHILD_OWNER', label: t('Provisional child owner') },
    { value: 'DEFINITIVE', label: t('Definitive') },
    { value: 'NO_LICENSE', label: t('No license') },
  ];

  const driverProfessionOptions = [
    { value: 'RETIRED_PERSON', label: t('RETIRED_PERSON') },
    { value: 'OFFICIAL', label: t('OFFICIAL') },
    { value: 'LABOURER', label: t('LABOURER') },
    { value: 'TRADER', label: t('TRADER') },
    { value: 'CLERK', label: t('CLERK') },
    { value: 'HOUSE_PERSON', label: t('HOUSE_PERSON') },
    { value: 'TEACHER', label: t('TEACHER') },
    { value: 'STUDENT', label: t('STUDENT') },
    { value: 'SELF_EMPLOYED', label: t('SELF_EMPLOYED') },
    { value: 'LIBERAL_PROFESSION', label: t('LIBERAL_PROFESSION') },
    { value: 'OTHER_OCCUPATION', label: t('OTHER_OCCUPATION') },
  ];

  const driverLicenseTypeEnums = {
    PROVISIONAL: 'Provisional',
    // PROVISIONAL_CHILD_OWNER: 'Provisional child owner',
    DEFINITIVE: 'Definitive',
    NO_LICENSE: 'No license',
  };

  const driverProfession = {
    RETIRED_PERSON: t('RETIRED_PERSON'),
    OFFICIAL: t('OFFICIAL'),
    LABOURER: t('LABOURER'),
    TRADER: t('TRADER'),
    CLERK: t('CLERK'),
    HOUSE_PERSON: t('HOUSE_PERSON'),
    TEACHER: t('TEACHER'),
    STUDENT: t('STUDENT'),
    SELF_EMPLOYED: t('SELF_EMPLOYED'),
    LIBERAL_PROFESSION: t('LIBERAL_PROFESSION'),
    OTHER_OCCUPATION: t('OTHER_OCCUPATION'),
  };

  function parseAddressToString({ city, housenr, street, zipcode, boxnr }) {
    const fullHouseNr = boxnr ? `${housenr}/${boxnr}` : housenr;
    return `${street} ${fullHouseNr}, ${city} ${zipcode}`;
  }

  /* HELPER FUNCTIONS
  -------------------------------------------------- */
  const findDriverLicenseEnums = licenseType => {
    const result = driverLicenseOptions.filter(obj => {
      return obj.value === licenseType;
    });

    return result;
  };

  const findDriverProfessions = profession => {
    const result = driverProfessionOptions.filter(obj => {
      return obj.value === profession;
    });

    return result;
  };

  /* FORM HANDLING
  -------------------------------------------------- */
  const ToggleEditMode = e => {
    setEditMode(!editMode);

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const ValidationSchema = Yup.object().shape({
    first_name: firstName,
    last_name: lastName,
    street: street,
    housenr: housenr,
    zipcode: zipcode,
    boxnr: boxNr,
    city: city,
    issue_license_date: date,
    birth: date,
    number_of_accidents_last_five_years: number.required,
    driver_license_type: string.required,
    profession: string.required,
  });

  const setInitialFormValues = () => {
    if (mainDriverState != null) {
      Object.keys(mainDriverState).forEach(name => {
        handleChange({ name, value: mainDriverState[name] });
      });

      if (mainDriverState.address != null) {
        Object.keys(mainDriverState?.address).forEach(name => {
          handleChange({ name, value: mainDriverState?.address[name] });
        });
      }

      setDriversLicense(
        findDriverLicenseEnums(mainDriverState.driver_license_type),
      );
      setDriversProfession(findDriverProfessions(mainDriverState.profession));
    }
  };

  const handleDriversLicenseChange = e => {
    const selectedLicense = findDriverLicenseEnums(e.value);
    setDriversLicense(selectedLicense);
    handleChange({
      name: 'driver_license_type',
      value: selectedLicense[0].value,
    });
  };

  const handleDriversProfessionChange = e => {
    const selectedProfession = findDriverProfessions(e.value);
    setDriversProfession(selectedProfession);
    handleChange({
      name: 'profession',
      value: selectedProfession[0].value,
    });
  };

  const { values, handleChange, errors } = useForm({
    validationSchema: ValidationSchema,
  });

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    const newValues = {
      accident_statement:
        values?.accident_statement != null ? values?.accident_statement : null,
      address: {
        street: values?.street,
        zipcode: values?.zipcode,
        housenr: values?.housenr,
        country_code: 'BE',
        boxnr: values?.boxnr != null ? values?.boxnr : null,
        city: values?.city,
        lat: values?.lat != null ? values?.lat : null,
        lng: values?.lng != null ? values?.lng : null,
        region: values?.region != null ? values?.region : null,
      },
      birth: values?.birth,
      birth_place: null,
      driver_license_type: values?.driver_license_type,
      first_name: values?.first_name,
      home_to_work_distance:
        values?.home_to_work_distance != null
          ? values?.home_to_work_distance
          : null,
      issue_license_date: values?.issue_license_date,
      last_name: values?.last_name,
      number_of_accidents_last_five_years: isNaN(
        parseInt(values?.number_of_accidents_last_five_years),
      )
        ? null
        : values?.number_of_accidents_last_five_years,
      primary: true,
      profession: values?.profession,
      special_employment:
        values?.special_employment != null ? values?.special_employment : false,
    };

    onSubmitFunction(newValues);
    setMainDriverState(newValues);
    setEditMode(editMode => !editMode);
    if (customerPageFunction != null) {
      customerPageFunction(false);
    }
  };

  const addNewDriver = () => {
    setEditMode(editMode => !editMode);
  };

  if (_.isEmpty(mainDriverState) && !disableEditing) {
    if (!editMode) {
      return (
        <Section>
          <Section.Title>{t('Primary driver')}</Section.Title>
          <StyledAddEntityButton
            fullwidth
            icon="IconPlusFilled"
            name={t('Add driver')}
            onClick={() => addNewDriver()}
            otherProps={{}}
          />
        </Section>
      );
    } else {
      return (
        <Section>
          <Section.Title>{t('Primary driver')}</Section.Title>
          <form onSubmit={handleFormSubmit}>
            <Section.Content background>
              <SectionTop>
                <SectionItem>
                  <NameWrapper>
                    <NameInput
                      error={errors.first_name}
                      name="first_name"
                      placeholder={t('First name')}
                      type="text"
                      value={values.first_name}
                      onChange={handleChange}
                    >
                      {t('First name')}
                    </NameInput>
                    <StyledTextInput
                      error={errors.last_name}
                      name="last_name"
                      placeholder={t('Last name')}
                      type="text"
                      value={values.last_name}
                      onChange={handleChange}
                    >
                      {t('Last name')}
                    </StyledTextInput>
                  </NameWrapper>
                </SectionItem>
                <SectionItem>
                  <DateInput
                    name="birth"
                    onChange={e =>
                      handleChange({
                        name: e.name,
                        value: parseIncDateToIsoFormat(e.value),
                      })
                    }
                    value={values.birth}
                  >
                    {t('Birthdate')}
                  </DateInput>
                </SectionItem>
              </SectionTop>
              <SectionMain>
                <SectionMainItem>
                  <StyledSearchSelectInput
                    error={errors.driver_license_type}
                    initial={driversLicense}
                    name="driver_license_type"
                    onSelected={handleDriversLicenseChange}
                    options={driverLicenseOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('License type')}
                  </StyledSearchSelectInput>
                </SectionMainItem>
                <SectionMainItem>
                  <DateInput
                    error={errors.issue_license_date}
                    name="issue_license_date"
                    onChange={handleChange}
                    value={
                      values.issue_license_date ? values.issue_license_date : ''
                    }
                  >
                    {t('License issued at')}
                  </DateInput>
                </SectionMainItem>
                <SectionMainItem>
                  <AddressWrapper>
                    <StreetInput
                      error={errors.street}
                      name="street"
                      placeholder={t('Street')}
                      type="street"
                      value={values.street}
                      onChange={handleChange}
                    >
                      {t('Street')}
                    </StreetInput>

                    <HouseNumberInput
                      error={errors.housenr}
                      name="housenr"
                      placeholder={t('House no')}
                      type="housenr"
                      value={values.housenr}
                      onChange={handleChange}
                    >
                      {t('House no')}
                    </HouseNumberInput>

                    <BoxNumberInput
                      error={errors.boxnr}
                      name="boxnr"
                      placeholder={t('Box no')}
                      type="boxnr"
                      value={values.boxnr}
                      onChange={handleChange}
                    >
                      {t('Box no')}
                    </BoxNumberInput>

                    <ZipCodeInput
                      error={errors.zipcode}
                      name="zipcode"
                      placeholder={t('Zipcode')}
                      type="zipcode"
                      value={values.zipcode}
                      onChange={handleChange}
                    >
                      {t('Zipcode')}
                    </ZipCodeInput>

                    <CityInput
                      error={errors.city}
                      name="city"
                      placeholder={t('City')}
                      type="city"
                      value={values.city}
                      onChange={handleChange}
                    >
                      {t('City')}
                    </CityInput>
                  </AddressWrapper>
                </SectionMainItem>
                <SectionMainItem>
                  <StyledSearchSelectInput
                    error={errors.profession}
                    initial={driversProfession}
                    name="profession"
                    onSelected={handleDriversProfessionChange}
                    options={driverProfessionOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Profession')}
                  </StyledSearchSelectInput>
                </SectionMainItem>
                <SectionMainItem>
                  <StyledTextInput
                    error={errors.number_of_accidents_last_five_years}
                    name="number_of_accidents_last_five_years"
                    placeholder={0}
                    type="number"
                    value={values.number_of_accidents_last_five_years}
                    onChange={handleChange}
                  >
                    {t('Amount of claims in the last 5 years')}
                  </StyledTextInput>
                </SectionMainItem>
              </SectionMain>
              <SectionContentFooter>
                <ActionButton level="default" onClick={ToggleEditMode}>
                  {t('Cancel')}
                </ActionButton>
                <Spacer />
                <ActionButton submit>{t('Save')}</ActionButton>
              </SectionContentFooter>
            </Section.Content>
          </form>
        </Section>
      );
    }
  }

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Section>
          <Section.Title>{t('Primary driver')}</Section.Title>
          {!_.isEmpty(mainDriver) ? (
            <Section.Content background>
              {editMode || disableEditing ? null : (
                <StyledEditButton onClick={e => ToggleEditMode(e)}>
                  {t('Edit')}
                </StyledEditButton>
              )}
              <SectionTop>
                <SectionItem>
                  {editMode ? (
                    <NameWrapper>
                      <NameInput
                        error={errors.first_name}
                        name="first_name"
                        placeholder={t('First name')}
                        type="text"
                        value={values.first_name}
                        onChange={handleChange}
                      >
                        {t('First name')}
                      </NameInput>
                      <StyledTextInput
                        error={errors.last_name}
                        name="last_name"
                        placeholder={t('Last name')}
                        type="text"
                        value={values.last_name}
                        onChange={handleChange}
                      >
                        {t('Last name')}
                      </StyledTextInput>
                    </NameWrapper>
                  ) : (
                    <>
                      <Label>{t('Name')}</Label>
                      <SubTitle>
                        {mainDriverState?.first_name}{' '}
                        {mainDriverState?.last_name}
                      </SubTitle>
                    </>
                  )}
                </SectionItem>
                <SectionItem>
                  {editMode ? (
                    <DateInput
                      error={errors.birth}
                      name="birth"
                      onChange={e =>
                        handleChange({
                          name: e.name,
                          value: parseIncDateToIsoFormat(e.value),
                        })
                      }
                      value={formatISODate(values.birth)}
                    >
                      {t('Birthdate')}
                    </DateInput>
                  ) : (
                    <>
                      <Label>{t('Birthdate')}</Label>
                      <SubTitle>
                        {formatISODate(mainDriverState?.birth) || '-'}
                      </SubTitle>
                    </>
                  )}
                </SectionItem>
              </SectionTop>
              <SectionMain>
                <SectionMainItem>
                  {editMode ? (
                    <StyledSearchSelectInput
                      error={errors.driver_license_type}
                      initial={driversLicense}
                      name="driver_license_type"
                      onSelected={handleDriversLicenseChange}
                      options={driverLicenseOptions}
                      placeholder={t('Choose an option')}
                    >
                      {t('License type')}
                    </StyledSearchSelectInput>
                  ) : (
                    <>
                      <Label>{t('License type')}</Label>
                      <Text>
                        {t(
                          driverLicenseTypeEnums[
                            mainDriverState?.driver_license_type
                          ],
                        )}
                      </Text>
                    </>
                  )}
                </SectionMainItem>
                {mainDriverState?.driver_license_type === 'NO_LICENSE' ? (
                  ''
                ) : (
                  <SectionMainItem>
                    {editMode ? (
                      <DateInput
                        error={errors.issue_license_date}
                        name="issue_license_date"
                        onChange={e =>
                          handleChange({
                            name: e.name,
                            value: parseIncDateToIsoFormat(e.value),
                          })
                        }
                        value={
                          values.issue_license_date
                            ? formatISODate(values.issue_license_date)
                            : formatISODate(mainDriverState?.issue_license_date)
                        }
                      >
                        {t('License issued at')}
                      </DateInput>
                    ) : (
                      <>
                        <Label>{t('License issued at')}</Label>
                        <Text>
                          {formatISODate(mainDriverState?.issue_license_date) ||
                            '-'}
                        </Text>
                      </>
                    )}
                  </SectionMainItem>
                )}
                <SectionMainItem>
                  {editMode ? (
                    <AddressWrapper>
                      <StreetInput
                        error={errors.street}
                        name="street"
                        placeholder={t('Street')}
                        type="street"
                        value={values.street}
                        onChange={handleChange}
                      >
                        {t('Street')}
                      </StreetInput>

                      <HouseNumberInput
                        error={errors.housenr}
                        name="housenr"
                        placeholder={t('House no')}
                        type="housenr"
                        value={values.housenr}
                        onChange={handleChange}
                      >
                        {t('House no')}
                      </HouseNumberInput>

                      <BoxNumberInput
                        error={errors.boxnr}
                        name="boxnr"
                        placeholder={t('Box no')}
                        type="boxnr"
                        value={values.boxnr}
                        onChange={handleChange}
                      >
                        {t('Box no')}
                      </BoxNumberInput>

                      <ZipCodeInput
                        error={errors.zipcode}
                        name="zipcode"
                        placeholder={t('Zipcode')}
                        type="zipcode"
                        value={values.zipcode}
                        onChange={handleChange}
                      >
                        {t('Zipcode')}
                      </ZipCodeInput>

                      <CityInput
                        error={errors.city}
                        name="city"
                        placeholder={t('City')}
                        type="city"
                        value={values.city}
                        onChange={handleChange}
                      >
                        {t('City')}
                      </CityInput>
                    </AddressWrapper>
                  ) : (
                    <>
                      <Label>{t('Address')}</Label>
                      <Text>
                        {mainDriverState?.address
                          ? parseAddressToString(mainDriverState?.address || {})
                          : '-'}
                      </Text>
                    </>
                  )}
                </SectionMainItem>
                <SectionMainItem>
                  {editMode ? (
                    <StyledSearchSelectInput
                      error={errors.profession}
                      initial={driversProfession}
                      name="profession"
                      onSelected={handleDriversProfessionChange}
                      options={driverProfessionOptions}
                      placeholder={t('Choose an option')}
                    >
                      {t('Profession')}
                    </StyledSearchSelectInput>
                  ) : (
                    <>
                      <Label>{t('Profession')}</Label>
                      <Text>
                        {t(driverProfession[mainDriverState?.profession]) ||
                          '-'}
                      </Text>
                    </>
                  )}
                </SectionMainItem>
                <SectionMainItem>
                  {editMode ? (
                    <StyledTextInput
                      error={errors.number_of_accidents_last_five_years}
                      name="number_of_accidents_last_five_years"
                      placeholder={0}
                      type="number"
                      value={values.number_of_accidents_last_five_years}
                      onChange={handleChange}
                    >
                      {t('Amount of claims in the last 5 years')}
                    </StyledTextInput>
                  ) : (
                    <>
                      <Label>{t('Amount of claims in the last 5 years')}</Label>
                      <Text>
                        {mainDriverState?.number_of_accidents_last_five_years ||
                          '-'}
                      </Text>
                    </>
                  )}
                </SectionMainItem>
              </SectionMain>
              {editMode ? (
                <SectionContentFooter>
                  <ActionButton level="default" onClick={ToggleEditMode}>
                    {t('Cancel')}
                  </ActionButton>
                  <Spacer />
                  <ActionButton submit>{t('Save')}</ActionButton>
                </SectionContentFooter>
              ) : null}
            </Section.Content>
          ) : (
            <p>{t('No drivers found')}</p>
          )}
        </Section>
      </form>
      {secondaryDrivers?.length && secondaryDrivers?.length > 0 ? (
        <Section>
          <Section.Title>{t('Additional drivers')}</Section.Title>
          {secondaryDrivers?.map(driver => {
            return (
              <Section.Content background>
                <SectionGrid>
                  <div>
                    <Label>{t('Name')}</Label>
                    <Text>
                      {driver?.first_name} {driver?.last_name}
                    </Text>
                  </div>
                  <div>
                    <Label>{t('Birthdate')}</Label>
                    <Text>{formatISODate(driver?.birth) || '-'}</Text>
                  </div>
                  <div>
                    <Label>{t('License issued at')}</Label>
                    <Text>
                      {formatISODate(driver?.issue_license_date) || '-'}
                    </Text>
                  </div>
                  <div>
                    <Label>{t('License type')}</Label>
                    <Text>
                      {t(driverLicenseTypeEnums[driver?.driver_license_type])}
                    </Text>
                  </div>
                </SectionGrid>
              </Section.Content>
            );
          })}
        </Section>
      ) : null}

      {/* <Section>
        <Section.Title>{t('Additional drivers')}</Section.Title>
        <Section.Content background>
          <SectionGrid>
            <div>
              <Label>{t('Name')}</Label>
              <Text>Gilles Wegroup</Text>
            </div>
            <div>
              <Label>{t('Birthdate')}</Label>
              <Text>30/11/1994</Text>
            </div>
            <div>
              <Label>{t('License issued at')}</Label>
              <Text>01/01/2020</Text>
            </div>
            <div>
              <Label>{t('License type')}</Label>
              <Text>License type</Text>
            </div>
          </SectionGrid>
        </Section.Content>
      </Section> */}
    </>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  & > label {
    margin-right: 2rem;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  margin-right: 2rem;
`;

const StreetInput = styled(TextInput)`
  flex: 0 0 60%;
`;

const HouseNumberInput = styled(TextInput)`
  flex: 0 0 20%;
`;

const BoxNumberInput = styled(TextInput)`
  flex: 0 0 15%;
`;

const ZipCodeInput = styled(TextInput)`
  flex: 0 0 40%;
`;

const CityInput = styled(TextInput)`
  flex: 0 0 57%;
`;

const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 2rem;
`;

const NameInput = styled(TextInput)`
  margin-right: 1.6rem;
  height: auto;
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
  & > label {
    margin-right: 2rem;
  }
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: 30% 20% 20% 30%;

  @media screen and (max-width: 500px) {
    grid-template-columns: 100%;

    > div {
      margin: 7px 0;
    }
  }
`;

const SubTitle = styled.div`
  margin-top: 0.5rem;
  font-size: 2.1rem;
  font-weight: 700;
  color: #0e0e0e;
`;

const SectionItem = styled.div`
  width: 50%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const SectionMainItem = styled(SectionItem)`
  margin-top: 2rem;
`;

const SectionMain = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 500px) {
    padding: 12px;
  }
`;

const SectionTop = styled(SectionMain)`
  padding-bottom: 2rem;
  border-bottom: 1px solid #e4e4e4;

  @media screen and (max-width: 500px) {
    padding: 12px 0;
    margin: 0 12px;
    width: calc(100% - 24px);

    > ${SectionItem} {
      &:first-of-type {
        margin-bottom: 2rem;
      }
    }
  }
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;

  @media screen and (max-width: 500px) {
    justify-content: center;
    padding: 12px 0 0;
    margin: 12px 0;
  }
`;

const StyledEditButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;

  @media screen and (max-width: 500px) {
    width: 4.5rem;
    height: 4.5rem;
    right: 1.8rem;
    top: 2.5rem;
  }
`;

const Spacer = styled.div`
  width: 1.5rem;
`;

const StyledAddEntityButton = styled(AddEntityButton)`
  margin-top: 1.6rem;
`;

CRMRiskObjectDetailCarDrivers.propTypes = {
  mainDriver: array,
  secondaryDrivers: object,
  onSubmitFunction: func,
  customerPageFunction: func,
  disableEditing: bool,
};

export default CRMRiskObjectDetailCarDrivers;
