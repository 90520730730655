import React from 'react';
import { shape, number, string } from 'prop-types';
import styled from 'styled-components';

const MiniOmnium = ({ colors, height, title }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 201.19 246.16"
    height={height}
  >
    <title>{title}</title>
    <g id="icons">
      <path
        fill={colors}
        d="M197.67,164.48c-7-7.55-20.12-12.85-35.51-16.57q-5.15-1.23-10.6-2.25l-.75-.14c-3.39-.63-6.85-1.2-10.32-1.71-28.31-4.17-58-4.63-69.78-4.63-3.34,0-5.23,0-5.23,0L15.07,106.45Q7.42,105.7,0,105.32V213.59H80.23a40.93,40.93,0,0,0,80.13,0H180a13.93,13.93,0,0,0,13.4-10.12l7.27-25.67A13.93,13.93,0,0,0,197.67,164.48Zm-51.83,49.11a26.84,26.84,0,0,1-51.06,0,25.67,25.67,0,0,1-1.35-8.34,26.87,26.87,0,1,1,53.73,0A26.48,26.48,0,0,1,145.84,213.59Z"
      />
      <path
        fill={colors}
        d="M189.3,71.32l-31,59.8q-1.92-.39-3.87-.75c-3.78-.71-7.62-1.35-11.48-1.92L147.6,99a1.41,1.41,0,0,0-1.39-1.63H111.77a1.42,1.42,0,0,1-1.25-2.07L160.3.76a1.41,1.41,0,0,1,2.64.89l-11.28,66a1.41,1.41,0,0,0,1.39,1.65h35A1.41,1.41,0,0,1,189.3,71.32Z"
      />
    </g>
  </Svg>
);

const Svg = styled.svg`
  margin-left: -0.2rem;
  position: absolute;
  align-self: center;
`;

MiniOmnium.propTypes = {
  colors: shape(),
  title: string,
  height: number,
};

export default MiniOmnium;
