import React from 'react';
import { string } from 'prop-types';
const gardenIcon = ({ color }) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9043 16.4676C12.9427 16.4676 12.0647 16.8831 11.4305 17.5238C11.1334 16.3419 10.3597 15.3492 9.3241 14.7505V12.6705L13.1743 8.86868H16.5054C16.5054 8.10352 16.1998 7.41259 15.7124 6.89291H18.1304C18.1304 5.31229 16.8523 4.03223 15.2625 3.98792C14.7532 3.11619 13.8097 2.52346 12.7171 2.52346C12.5922 2.52346 12.4721 2.54501 12.3509 2.55938C11.633 1.31405 10.2918 0.463867 8.73717 0.463867C7.1971 0.463867 5.86682 1.29728 5.14408 2.52346H5.14287C3.99327 2.52346 3.0086 3.17965 2.52111 4.12683C1.32422 4.49803 0.451107 5.5889 0.451107 6.89291H4.98522L8.14904 10.017V14.2871C7.801 14.2009 7.44206 14.141 7.06614 14.141C5.35751 14.141 3.88777 15.0906 3.1238 16.4748C1.7341 16.5119 0.51174 17.3968 0 18.5931H2.55749C2.55749 18.5931 2.55749 18.5931 2.55749 18.5943H11.576L17.0996 18.5931C16.5879 17.3465 15.3498 16.4676 13.9043 16.4676ZM9.3241 6.89291H11.3989C10.9102 7.41259 10.6059 8.10352 10.6059 8.86748H11.4596L9.3241 10.9762V6.89291ZM6.70113 6.89291H8.15025V8.32384L6.70113 6.89291Z"
      fill={color}
    />
  </svg>
);

gardenIcon.defaultProps = {
  color: `white`,
};

gardenIcon.propTypes = {
  color: string,
};

export default gardenIcon;
