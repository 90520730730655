import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
import CarQuestionBox from '../components/CarQuestionBox';
import { useTranslation } from 'react-i18next';
import { setPayloadCar } from '../store';
import ChatBox from '../../../../components/ChatBox';
import {
  getComponentName,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

const MobileQuestion = () => {
  const { t } = useTranslation();
  const content = t(getComponentName(), { returnObjects: true });
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const checkAnswer = answer => {
    setPayloadCar('replacement_after_accident', answer);
    addChatboxQuestion(t(`${getComponentName()}.question`));
    if (answer) {
      addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
    } else {
      addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <CarQuestionBox
          answers={content.answers}
          handleAnswer={answer => checkAnswer(answer)}
          to={getNextPath()}
          dataTestId={['underwriting_answer_yes', 'underwriting_answer_no']}
        >
          {content.question}
        </CarQuestionBox>
      </Container>
    </Layout>
  );
};

MobileQuestion.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(MobileQuestion);
