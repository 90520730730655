import React from 'react';
import { shape } from 'prop-types';

const GsmLock = ({ colors }) => {
  const style = {
    cls1: {
      fill: colors.brand.dark,
    },
    cls2: {
      fill: colors.brand.primary,
    },
    cls3: {
      opacity: 0.2,
      fill: '#000',
    },
    cls4: {
      fill: colors.brand.primary,
    },
    cls5: {
      stroke: colors.brand.primary,
      strokeWidth: 2.19381,
      strokeMiterlimit: 10,
      strokeLinecap: 'round',
    },
    cls6: {
      opacity: 0.1,
      fill: '#000',
    },
    cls7: {
      opacity: 0.1,
      fill: 'white',
    },
  };

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 54 100"
      style={{ width: `100%`, height: `100%` }}
    >
      <path
        style={style.cls1}
        d="M53.6092 4.49143V95.8817C53.6092 98.1121 51.7993 99.922 49.5689 99.922H4.65077C2.4204 99.922 0.610504 98.1121 0.610504 95.8817V4.49143C0.610504 2.26106 2.4204 0.451172 4.65077 0.451172H49.5689C50.2819 0.451172 50.9584 0.63399 51.5434 0.963061C52.7683 1.65777 53.6092 2.97405 53.6092 4.49143Z"
      />
      <path
        style={style.cls2}
        d="M27.119 84.9495C24.1939 84.9495 21.799 87.3261 21.799 90.2694C21.799 93.1945 24.1756 95.5894 27.119 95.5894C30.0441 95.5894 32.439 93.2128 32.439 90.2694C32.439 87.3444 30.0441 84.9495 27.119 84.9495Z"
      />
      <path
        style={style.cls3}
        d="M27.9417 95.5163C27.6675 95.5529 27.3932 95.5894 27.119 95.5894C24.1939 95.5894 21.799 93.2128 21.799 90.2694C21.799 87.3444 24.1756 84.9495 27.119 84.9495C27.3932 84.9495 27.6675 84.9677 27.9417 85.0226C25.4005 85.4248 23.4444 87.6186 23.4444 90.2694C23.4444 92.9203 25.4005 95.1141 27.9417 95.5163Z"
      />
      <path
        style={style.cls4}
        d="M48.9839 10.9816V81.9878C48.9839 82.5363 48.5452 82.975 47.9967 82.975H6.33267C5.78422 82.975 5.34546 82.5363 5.34546 81.9878V10.9816C5.34546 10.4332 5.78422 9.99443 6.33267 9.99443H47.9967C48.5452 9.97614 48.9839 10.4332 48.9839 10.9816Z"
      />
      <path style={style.cls5} d="M15.181 6.26489H20.2816" />
      <path style={style.cls5} d="M23.8283 6.26489H38.9656" />
      <path
        style={style.cls6}
        d="M47.9967 9.97607H46.7353H6.33267C5.78422 9.97607 5.34546 10.4148 5.34546 10.9633V11.6763H46.7353V82.9567H47.9967C48.5452 82.9567 48.9839 82.5179 48.9839 81.9695V11.6763V10.9633C48.9839 10.4331 48.5452 9.97607 47.9967 9.97607Z"
      />
      <path
        style={style.cls7}
        d="M45.5835 0.451172L0.628799 40.1225V24.5282L27.9051 0.451172H45.5835Z"
      />
      <path
        style={style.cls7}
        d="M53.6092 4.49154V6.97785L0.628799 53.7425V45.9179L51.5434 0.981445C52.7683 1.65787 53.6092 2.97415 53.6092 4.49154Z"
      />
    </svg>
  );
};

GsmLock.propTypes = {
  colors: shape().isRequired,
};

export default GsmLock;
