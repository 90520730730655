import React from 'react';
import { string } from 'prop-types';
import Section from './Section';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryInsuranceInformation = ({ insurer, date }) => {
  const { t } = useTranslation();
  if (!date) date = '--/--/--';
  if (!insurer) insurer = '--';
  return (
    <SectionWrapper data-test-id="dashboard_history_detail_section_insurance_information">
      <Section>
        <Section.Subtitle>
          {t('History.previousInsurance.title')}
        </Section.Subtitle>
        <Section.Content>
          <Section.Split>
            <Section.SplitContent>
              <Section.Label>{t('History.previousInsurance.at')}</Section.Label>
              <Text>{insurer}</Text>
            </Section.SplitContent>
            <Section.SplitContent>
              <Section.Label>
                {t('History.previousInsurance.since')}
              </Section.Label>
              <Text>{date}</Text>
            </Section.SplitContent>
          </Section.Split>
        </Section.Content>
      </Section>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  margin-bottom: 5rem;
`;

const Text = styled.p`
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #050505;
`;

DashboardBrokerHistoryInsuranceInformation.defaultProps = {
  insurer: '--',
  date: '--/--/--',
};

DashboardBrokerHistoryInsuranceInformation.propTypes = {
  insurer: string,
  date: string,
};

export default DashboardBrokerHistoryInsuranceInformation;
