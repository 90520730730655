/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  IconCabrioFilled,
  IconCarFilled,
  IconSuvFilled,
  IconSportsCarFilled,
  SearchSelectInput,
  TextInput,
} from 'wg-fe-ui';

import useForm from '../hooks/useForm';
import { date } from '../services/validationSchemas';
import { retrieveLicensePlateV2 as retrieveLicensePlate } from '../services/apiRouterService';

import DateInput from './DateInput_v2';
import * as Yup from 'yup';
import { shape, func } from 'prop-types';
// import {debounce} from 'lodash';

import moment from 'moment';

const VehicleData = ({ vehicle, onChange }) => {
  const [metadata, setMetadata] = useState({});
  const [preFilledObject, setPreFilledObject] = useState({});
  const { t } = useTranslation();

  const initialRender = useRef(true);

  const CarSchema = Yup.object().shape({
    brand: Yup.string().required(t('required')),
    model: Yup.string().required(t('required')),
    model_year: Yup.number()
      .typeError(t('This must be a number'))
      .nullable(),
    chassis_number: Yup.string().nullable(),
    motorType: Yup.string()
      .nullable()
      .required(t('required')),
    kw: Yup.number()
      .typeError(t('This must be a number'))
      .nullable()
      .required(t('required')),
    cc: Yup.number()
      .typeError(t('This must be a number'))
      .nullable(),
    co2: Yup.number()
      .typeError(t('This must be a number'))
      .nullable(),
    registrationFirst: date({
      matches: t('format is not correct'),
      required: t('required'),
    }).required(t('required')),
    // registrationLast: date({
    //   matches: t('format is not correct'),
    //   required: t('required'),
    // }),
    seats: Yup.number()
      .typeError(t('This must be a number'))
      .nullable()
      .required(t('required')),
  });

  const { values, handleChange, handleSubmit, errors, setValues } = useForm({
    validationSchema: CarSchema,
    mode: 'onChange',
  });

  useEffect(() => {
    console.log(values);
    handleSubmit(onChange);
  }, [values]);

  useEffect(() => {
    setValues({});
    if (!vehicle) return;
    const { technical, ...rest } = vehicle;
    let _vehicle = { ...technical, ...rest };

    if (_vehicle.license_plate) {
      _vehicle.number_plate = _vehicle.license_plate;
    }

    if (_vehicle.price) {
      _vehicle.car_value = _vehicle.price;
    }

    if (_vehicle.vin) {
      _vehicle.chassis_number = _vehicle.vin;
    }

    if (_vehicle.amount_of_seats_incl_driver) {
      _vehicle.seats = _vehicle.amount_of_seats_incl_driver;
    }

    if (_vehicle.motor_type) {
      _vehicle.motorType = _vehicle.motor_type;
    }

    if (_vehicle.co2 === 0) {
      _vehicle.co2 = null;
    }

    if (_vehicle.category) {
      _vehicle.is_jeep = _vehicle.category === 'SUV';
      _vehicle.is_coupe = _vehicle.category === 'COUPE';
      _vehicle.is_cabrio = _vehicle.category === 'CABRIOLET';
    }

    if (_vehicle.registration_first && !_vehicle.registrationFirst) {
      const date = _vehicle.registration_first;
      _vehicle.registrationFirst = moment(date, 'YYYY-MM-DD').format(
        'DD/MM/YYYY',
      );
    }

    if (_vehicle.registration_last && !_vehicle.registrationLast) {
      const date = _vehicle.registration_last;
      _vehicle.registrationLast = moment(date, 'YYYY-MM-DD').format(
        'DD/MM/YYYY',
      );
    }

    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // Only do API call after data has been rearanged
      getVehicleAsyncVehicleData(_vehicle);
    }

    Object.entries(_vehicle).forEach(([key, val]) => {
      handleChange({ name: key, value: val });
    });
  }, [vehicle]);

  const getVehicleAsyncVehicleData = async _vehicle => {
    const [resp, status] = await retrieveLicensePlate(_vehicle.number_plate);
    if (status > 201) return;
    const { vehicle } = resp;
    handleChange({ name: 'name', value: vehicle?.name });
    const tempMetadata = {};
    if (!('images' in _vehicle)) {
      ['color_hex', 'color', 'images'].forEach(key => {
        if (key in vehicle) {
          tempMetadata[key] = vehicle[key];
        }
      });
    } else {
      ['color_hex', 'color', 'images'].forEach(key => {
        if (key in _vehicle) {
          tempMetadata[key] = _vehicle[key];
        }
      });
    }

    setMetadata({
      ...metadata,
      ...tempMetadata,
    });
    return resp?.vehicle;
  };

  const MotorTypes = {
    PET: t('Vehicles.motor_types.PET'),
    DIE: t('Vehicles.motor_types.DIE'),
    LPG: t('Vehicles.motor_types.LPG'),
    DIE_LPG: t('Vehicles.motor_types.DIE_LPG'),
    ELE: t('Vehicles.motor_types.ELE'),
    ELE_PET: t('Vehicles.motor_types.ELE_PET'),
    ELE_DIE: t('Vehicles.motor_types.ELE_DIE'),
    ELE_LPG: t('Vehicles.motor_types.ELE_LPG'),
    ELE_HYDRO: t('Vehicles.motor_types.ELE_HYDRO'),
    HYDRO: t('Vehicles.motor_types.HYDRO'),
    CNG: t('Vehicles.motor_types.CNG'),
    CNG_PET: t('Vehicles.motor_types.CNG_PET'),
    BIO: t('Vehicles.motor_types.BIO'),
    OTHER: t('Vehicles.motor_types.OTHER'),
  };

  return (
    <>
      <Label>{t('Vehicle information')}</Label>
      <WarningContainer>
        <InfoIcon>i</InfoIcon>
        {t(
          'The information below was filled in by us Please check all yellow boxes for correctness before proceeding',
        )}
      </WarningContainer>
      <VehicleDataContainer>
        {metadata?.images && (
          <VehicleImageContainer img={metadata?.images?.[0] || ''}>
            <VehicleTypeBox>
              <VehicleLabel>
                <VehicleName>{vehicle?.name}</VehicleName>
                <VehicleColor>
                  <ColorIndicator color={metadata?.color_hex} />
                  {metadata?.color}
                </VehicleColor>
              </VehicleLabel>
            </VehicleTypeBox>
          </VehicleImageContainer>
        )}
        <VehicleInformation>
          <VehicleInformationInputs>
            <TextInput
              error={errors.model_year}
              name="model_year"
              type="text"
              value={values.model_year}
              onChange={handleChange}
            >
              {t('Vehicles.Model year')}
            </TextInput>
            <StyledTextInput
              error={errors.kw}
              name="kw"
              type="text"
              value={values.kw}
              onChange={handleChange}
            >
              {t('Vehicles.Power')}
              <span>kW</span>
            </StyledTextInput>
            <SearchSelectInput
              error={errors.motorType}
              name="motorType"
              options={Object.entries(MotorTypes).map(([key, value]) => ({
                label: value,
                value: key,
              }))}
              onSelected={val => handleChange(val)}
              value={{
                label: MotorTypes?.[values?.motorType],
                key: values?.motorType,
              }}
              placeholder={t('Fuel')}
            >
              {t('Vehicles.Fuel type')}
            </SearchSelectInput>
            <TextInput
              error={errors.seats}
              name="seats"
              type="text"
              value={values.seats}
              onChange={handleChange}
            >
              {t('Vehicles.Seats')}
            </TextInput>
          </VehicleInformationInputs>
          <div>
            <Label>{t('Car type')}</Label>
            <CarType>
              <VehicleType
                selected={values?.is_coupe || false}
                onClick={e =>
                  handleChange({
                    name: 'is_coupe',
                    value: !e.currentTarget.selected,
                  })
                }
              >
                <span>
                  <IconCarFilled size="70%" />
                </span>
                <p>{t('Vehicles.category.COUPE')}</p>
              </VehicleType>
              <VehicleType
                selected={values?.is_cabrio || false}
                onClick={e =>
                  handleChange({
                    name: 'is_cabrio',
                    value: !e.currentTarget.selected,
                  })
                }
              >
                <span>
                  <IconCabrioFilled size="100%" />
                </span>
                <p>{t('Cabrio')}</p>
              </VehicleType>
              <VehicleType
                selected={values?.is_jeep || false}
                onClick={e =>
                  handleChange({
                    name: 'is_jeep',
                    value: !e.currentTarget.selected,
                  })
                }
              >
                <span>
                  <IconSuvFilled size="100%" />
                </span>
                <p>{t('SUV')}</p>
              </VehicleType>
              <VehicleType
                selected={values?.is_sportscar || false}
                onClick={e =>
                  handleChange({
                    name: 'is_sportscar',
                    value: !e.currentTarget.selected,
                  })
                }
              >
                <span>
                  <IconSportsCarFilled size="80%" />
                </span>
                <p>{t('Sports car')}</p>
              </VehicleType>
            </CarType>
          </div>
          <VehicleInformationInputs>
            <TextInput
              error={errors.chassis_number}
              name="chassis_number"
              type="text"
              value={values.chassis_number}
              onChange={handleChange}
            >
              {t('Vehicles.Chassis number')}
            </TextInput>
            <StyledTextInput
              error={errors.cc}
              name="cc"
              type="text"
              value={values.cc}
              onChange={handleChange}
            >
              {t('Vehicles.Displacement')}
              <span>CC</span>
            </StyledTextInput>
            <StyledTextInput
              error={errors.co2}
              name="co2"
              type="text"
              value={values.co2 ? values.co2 : null}
              onChange={handleChange}
            >
              {t('Vehicles.Emissions')}
              <span>CO2</span>
            </StyledTextInput>
            <DateInput
              error={errors.registrationFirst}
              name="registrationFirst"
              type="text"
              value={
                !values?.registrationFirst ? '' : values?.registrationFirst
              }
              onChange={({ name, value }) => {
                handleChange({ name, value });
              }}
            >
              {t('Vehicles.First registration')} *
            </DateInput>
          </VehicleInformationInputs>
        </VehicleInformation>
      </VehicleDataContainer>
    </>
  );
};

const StyledTextInput = styled(TextInput)`
  position: relative;
  span {
    background: #ff8000;
    border-radius: 3px;
    bottom: 0;
    color: white;
    font-weight: 600;
    padding: 1.5rem;
    position: absolute;
    right: 0;
  }
`;

const CarType = styled.div`
  column-gap: 2rem;
  display: grid;
  grid-template-columns: 48.75% 48.75%;
  row-gap: 2rem;
`;

const VehicleInformationInputs = styled.div`
  border-bottom: 2px solid #f0f1f3;
  display: grid;
  grid-template-columns: 48.25% 48.25%;
  column-gap: 3rem;
  margin-bottom: 2rem;
  padding-top: 2rem;
`;

const VehicleType = styled.div`
  cursor: pointer;
  background: ${({ selected }) => (selected ? '#FFEFDC' : '#fbfbfb')};
  border: ${({ selected }) =>
    selected ? '1px solid #FF8000;' : '1px solid rgba(137, 144, 163, 0.5)'};
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  p {
    align-self: center;
    color: ${({ selected }) =>
      selected ? '#FF8000' : 'rgba(137, 144, 163, 0.5)'};
    letter-spacing: 0.01em;
    margin-left: 1rem;
  }
  span {
    padding: 0.5rem;
    height: 8rem;
    width: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      path {
        fill: ${({ selected }) =>
          selected ? '#FF8000' : 'rgba(137, 144, 163, 0.5)'};
      }
    }
  }
`;

const ColorIndicator = styled.div`
  border-radius: 50%;
  background-color: ${props => props.color};
  height: 1.2rem;
  width: 1.2rem;
  border: 1px solid black;
  margin-right: 1rem;
`;

const VehicleTypeBox = styled.div`
  background: white;
  border-radius: 5px;
  bottom: 0;
  box-shadow: 0 0 34.29px rgba(0, 0, 0, 0.08);
  height: 8rem;
  margin: 1rem;
  position: absolute;
  opacity: 0.9;
`;
const VehicleLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 5rem 1.5rem 2rem;
  height: 100%;
`;

const VehicleName = styled.span`
  color: #050505;
  font-weight: 600;
  font-size: 16px;
`;

const VehicleColor = styled.span`
  color: #8990a3;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-top: 0.5rem;
  align-items: center;
`;

const InfoIcon = styled.a`
  align-items: center;
  align-self: center;
  background: #ffefdc;
  border: 0.5px solid #ff8000;
  border-radius: 50%;
  color: #ff8000;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  height: 1.6rem;
  margin-right: 1rem;
  width: 1.6rem;
  text-align: center;
`;

const Label = styled.h3`
  color: #0e0e0e;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

const VehicleDataContainer = styled.div`
  /* border: 1px solid #e4e4e4;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(32, 32, 35, 0.1); */
  margin-bottom: 2rem;
  margin-top: 1rem;
`;

const VehicleImageContainer = styled.div`
  background-image: url(${props => props.img});

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px 0 0 6px;
  min-height: 36rem;
  position: relative;
`;

const VehicleInformation = styled.div`
  /* display: grid;
  grid-template-rows: 55% 43%;
  grid-gap: 2rem;
  width: 50%; */
  /* padding: 3rem; */
`;

const WarningContainer = styled.div`
  background: #ffefdc;
  border: 1px solid #ff9b21;
  border-radius: 5px;
  box-sizing: border-box;
  color: #ff8000;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 2rem;
  padding: 2rem;
`;

VehicleData.propTypes = {
  vehicle: shape(),
  onChange: func.isRequired,
};
export default VehicleData;
