import React, { useState } from 'react';
import { shape, func, number } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { capitalize } from '../services/stringService';

const DashboardBrokerHistorySectionRow = ({
  historyData,
  removeSession,
  history,
  sortId,
  keyId,
}) => {
  const [hover, setHover] = useState(false);

  const toggleHover = () => {
    setHover(!hover);
  };

  const handleRowClick = () => {
    history.push(`/broker-history/${historyData.id}/personal`);
  };

  const handleRemoveSession = e => {
    removeSession(historyData.id);
    e.stopPropagation();
  };

  return (
    <Tr
      onClick={handleRowClick}
      data-test-id={`broker_history_row_${sortId}_${keyId}`}
    >
      <Td>
        {historyData.firstName} {historyData.lastName}
      </Td>
      <Td>{historyData.createdTimeOn}</Td>
      <Td>{capitalize(historyData.street)}</Td>
      <Td>{historyData.number}</Td>
      <Td>{historyData.zipcode}</Td>
      <Td>{capitalize(historyData.city)}</Td>
      <Td
        onClick={handleRemoveSession}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <svg
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.86035 1L1.10081 10.3355M1 1.09961L9.9641 10.2311"
            stroke={hover ? '#F74040' : '#505050'}
            strokeWidth="2.22575"
          />
        </svg>
      </Td>
    </Tr>
  );
};

const Tr = styled.tr`
  background-color: white;
  font-size: 1.6rem;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.brand.lightest};
  }
`;

const Td = styled.td`
  padding-top: 1.9rem;
  padding-bottom: 1.9rem;
  border-bottom: 1px solid #f0f1f3;

  :first-of-type {
    padding-left: 2.5rem;
  }

  :last-of-type {
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: left;
    width: 5rem;
  }
`;

DashboardBrokerHistorySectionRow.propTypes = {
  historyData: shape().isRequired,
  sortId: number,
  keyId: number,
  removeSession: func.isRequired,
  history: shape().isRequired,
};

export default withRouter(DashboardBrokerHistorySectionRow);
