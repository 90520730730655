import React, { useEffect } from 'react';
import styled from 'styled-components';

import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import { setTraits, getTraits, getAddressInfo } from '../store/index';

import {
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  getAllCoverages,
  addBrowserHistory,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';

const RoomsCheck = ({ history }) => {
  const { t } = useTranslation();
  const coverages = getAllCoverages();
  const content = t(`${getComponentName()}`, { returnObjects: true });

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const response = answer => {
    if (content.answers[0].title === answer) {
      setTraits('private_garage', true);
      addChatboxAnswer(content.answers[0].response);
    } else {
      setTraits('private_garage', false);
      addChatboxAnswer(content.answers[1].response);
    }
    addChatboxQuestion(content.question);

    if (
      getTraits()['is_owner'] &&
      getAddressInfo()['building'] === 'apartment'
    ) {
      history.push(getNextPath());
    } else {
      if (coverages.family) {
        history.push(getNextPath('c'));
      } else {
        history.push(getNextPath('b'));
      }
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          Answer1={t(`${getComponentName()}.answers.0.title`)}
          Answer2={t(`${getComponentName()}.answers.1.title`)}
          dataTestId1="underwriting_answer_yes"
          dataTestId2="underwriting_answer_no"
          disabled={false}
          response={res => response(res)}
        >
          {t(`${getComponentName()}.question`)}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }
`;

RoomsCheck.propTypes = {
  history: shape().isRequired,
};

export default withRouter(RoomsCheck);
