import React from 'react';
import { func, string, node } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { ActionButton } from 'wg-fe-ui';

const DashboardToolbarBrokerHistory = ({
  onClick,
  btnText,
  dataTestId,
  children,
}) => {
  return (
    <Wrapper>
      <Container>
        {children}
        <ActionButton onClick={onClick} dataTestId={dataTestId}>
          {btnText}
        </ActionButton>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: #f7f7f7;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 7rem;
  width: 90%;
`;

DashboardToolbarBrokerHistory.propTypes = {
  onClick: func.isRequired,
  btnText: string.isRequired,
  dataTestId: string,
  children: node,
};

export default withRouter(DashboardToolbarBrokerHistory);
