import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Layout from '../../DashboardLayout/views/Layout';
import Button from '../../../components/Button';
import LoadingButton from '../../../components/LoadingButton';

import TrialPopupOnBrokerLoginScreen from '../../../components/TrialPopupOnBrokerLoginScreen.jsx';
import regex from '../../../services/regexService';
import { getTrialAccount } from '../../../store/index';

import { postLicensePlate, adaptVehicleCarToToolkitFormat } from '../store';
import { getManualData } from '../store';
import ManualSearchCarLeft from '../../../components/ManualSearchCarLeft';
import ManualSearchCarRight from '../components/ToolkitManualSearchCarRight';
import LeftSide from '../../../components/LeftSide';
import RightSide from '../../../components/RightSide';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import CarDataTable from '../components/CarDataTable';
import UpgradePopupOnBrokerLoginScreen from '../../../components/UpgradePopupOnBrokerLoginScreen';
import { getMeData } from '../../../services/meDataService';

const ToolkitCarLookup = () => {
  const { t } = useTranslation();
  const content = t('ToolkitInfo', { returnObjects: true });
  const [sendManualStep, setSendManualStep] = useState('initial');
  const [isDisabledPlate, setIsDisabledPlate] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const [manualBtnText, setManualBtnText] = useState(content.btnText.default);
  const [errorManualText, setErrorManualText] = useState(null);
  const [manualCarData, setManualCarData] = useState(null);
  const [showTrial, setShowTrial] = useState(false);
  const { setNavTabs } = useContext(TopNavigationItems);
  const [carLogo, setCarLogo] = useState();
  const [carData, setCarData] = useState(null);
  const [btnText, setBtnText] = useState(content.btnText.default);
  const [sendStep, setSendStep] = useState('initial');
  const [plate, setPlate] = useState('');
  const [errorText, setErrorText] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [isBronze, setIsBronze] = useState(false);

  const carManualData = data => {
    setSelectedItems(data);
    setManualBtnText(content.btnText.default);
  };

  const logoCar = logo => {
    setCarLogo(logo);
  };

  useEffect(() => {
    setNavTabs([
      {
        title: t('Car lookup'),
        testId: 'toolkit_car_lookup_nav',
        to: '/toolkit/car-lookup',
      },
      {
        title: t('Address lookup'),
        testId: 'toolkit_address_lookup_nav',
        to: '/toolkit/address-lookup',
      },
      {
        title: t('Documents generator'),
        testId: 'toolkit_documents_generator_nav',
        to: '/toolkit/documents-generator',
      },
    ]);
  }, []);

  // Why you do this Leander 💩
  const exeptionEmails = [
    'test_qa_broker_bronze@wegroup.be',
    'info@kantoorvancaeneghem.be',
    'an@kantoorvancaeneghem.be',
  ];

  const onClickHandlerPlate = async () => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    if (getTrialAccount()) {
      setShowTrial(true);
      return;
    }

    setSendStep('pending');
    const { me } = await getMeData();

    if (me?.role === 'broker_bronze' && !exeptionEmails.includes(me?.email)) {
      setSendStep('initial');
      setIsBronze(true);
      return;
    }

    try {
      const res = await postLicensePlate(plate);
      const { vehicle } = res[0] || {};
      setSendStep('success');
      setCarData(adaptVehicleCarToToolkitFormat(vehicle));
      const element = document.getElementById('dataTable');
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    } catch (err) {
      setSendStep('error');
      console.error(err);
    }
  };

  useEffect(() => {
    // button is enabled when a version is selected
    if (selectedItems['version']) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedItems]);

  useEffect(() => {
    switch (sendManualStep) {
      case 'initial':
        break;
      case 'pending':
        setManualBtnText(content.btnText.pending);
        setIsDisabled(true);
        setErrorManualText(null);
        break;
      case 'success':
        setIsDisabled(true);
        setManualBtnText(content.btnText.success);
        break;
      case 'error':
        setErrorManualText(content.errorText.manual);
        setManualCarData(null);
        break;
      default:
        break;
    }
  }, [sendManualStep]);

  useEffect(() => {
    switch (sendStep) {
      case 'initial':
        break;
      case 'pending':
        setBtnText(content.btnText.pending);
        break;
      case 'success':
        setIsDisabledPlate(true);
        setBtnText(content.btnText.success);
        break;
      case 'error':
        setBtnText(content.btnText.default);
        setErrorText(content.errorText.default);
        setCarData(null);
        break;
      default:
        break;
    }
  }, [sendStep]);

  const handleChange = e => {
    setErrorText(null);
    const btnDisabled =
      !e.target.value.match(regex.numberPlate) ||
      e.target.value.length < 1 ||
      e.target.value.length > 15;
    if (!e.target.value.match(regex.numberPlate))
      setErrorText(content.errorText.invalid);
    if (e.target.value.length < 1) setErrorText(content.errorText.required);
    if (e.target.value.length > 15) setErrorText(content.errorText.long);
    setPlate(e.target.value.replace(/\s/g, ''));
    setIsDisabledPlate(btnDisabled);
    setBtnText(content.btnText.default);
  };

  const handleInvalid = e => {
    e.preventDefault();
  };

  const manualOnClickHandler = async () => {
    if (sendManualStep === 'pending') {
      return;
    }

    setSendManualStep('pending');
    const { me } = await getMeData();

    if (me?.role === 'broker_bronze' && !exeptionEmails.includes(me?.email)) {
      setSendManualStep('initial');
      setIsBronze(true);
      return;
    }

    try {
      const res = await getManualData(selectedItems);
      const { car } = res[0] || {};
      setManualCarData(adaptVehicleCarToToolkitFormat(car));
      const element = document.getElementById('dataTableManual');
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
      setSendManualStep('success');
    } catch (error) {
      setSendManualStep('error');
      console.error(error.response);
    }
  };

  return (
    <DashboardLayout>
      <div>
        {showTrial ? (
          <TrialPopupOnBrokerLoginScreen
            close={bool => setShowTrial(bool)}
            startScreen="cantUseCar"
          />
        ) : null}
        {isBronze ? (
          <UpgradePopupOnBrokerLoginScreen
            startScreen="upgrade_bronze"
            close={() => setIsBronze(false)}
          />
        ) : null}
        <TitleContainer>
          <Title>{t('Plate lookup')}</Title>
        </TitleContainer>
        <Section data-test-id="dashboard_toolkit_carlookup_section_platelookup">
          <InfoSection
            dangerouslySetInnerHTML={{ __html: content.plateSubTitle }}
          />
          <InputWrapper>
            <InputContainer>
              <CountryFlag>B</CountryFlag>
              <Input
                data-test-id="toolkit_plate_lookup_plate_input"
                onChange={handleChange}
                onKeyPress={handleChange}
                onInvalid={handleInvalid}
                value={plate}
                pattern="\d+"
                type="text"
              />
            </InputContainer>
            <ButtonContainerPlate data-test-id="dashboard_toolkit_carlookup_section_platelookup_button">
              {sendStep !== 'pending' ? (
                <Button
                  dataTestId="toolkit_plate_lookup_post_plate"
                  type="submit"
                  onClick={e => onClickHandlerPlate(e)}
                  disabled={isDisabledPlate}
                >
                  {btnText}
                </Button>
              ) : (
                <LoadingButton>{btnText}</LoadingButton>
              )}
            </ButtonContainerPlate>
            {errorText !== null ? <Error>{errorText}</Error> : null}
          </InputWrapper>
        </Section>
        {carData !== null ? (
          <DataSection
            data-test-id="toolkit_plate_lookup_car_data_plate"
            id="dataTable"
          >
            <CarDataTable carData={carData} />{' '}
          </DataSection>
        ) : null}
        <TitleContainer>
          <Title>{t('ToolkitNavigation.carLookup.title')}</Title>
        </TitleContainer>
        <Section data-test-id="dashboard_toolkit_carlookup_section_carlookup">
          <Container>
            <LeftBlock>
              <ManualSearchCarLeft
                carLogo={carLogo}
                brandData={selectedItems['brand']}
                modelData={selectedItems['model']}
                versionData={selectedItems['version']}
              />
            </LeftBlock>
            <Right>
              <ManualSearchCarRight
                handleData={data => carManualData(data)}
                handleLogo={logo => logoCar(logo)}
              />
              <ButtonContainer>
                {sendManualStep !== 'pending' ? (
                  <Button
                    onClick={() => manualOnClickHandler()}
                    disabled={isDisabled}
                    dataTestId="toolkit_car_lookup_post_manual_car"
                  >
                    {manualBtnText}
                  </Button>
                ) : (
                  <LoadingButton>{manualBtnText}</LoadingButton>
                )}
              </ButtonContainer>
              {errorManualText !== null ? <p>{errorManualText}</p> : null}
            </Right>
          </Container>
        </Section>
        {manualCarData !== null ? (
          <Section
            data-test-id="toolkit_car_lookup_car_data_manual"
            id="dataTableManual"
          >
            <CarDataTable carData={manualCarData} />
          </Section>
        ) : null}
      </div>
    </DashboardLayout>
  );
};

const Error = styled.p`
  position: absolute;
  bottom: -1rem;
  height: 2rem;
`;

const CountryFlag = styled.div`
  width: 5rem;
  height: 5rem;
  background: ${({ theme }) => theme.ui.background};
  border-radius: 0.5rem 0 0 0.5rem;
  border: #ccc 1px solid;
  text-align: center;
  font-weight: 900;
  line-height: 4.6rem;
  font-size: 2.5rem;
  color: ${({ theme }) => theme.typo.subTitle};

  & img {
    height: 3rem;
  }
`;

const Input = styled.input`
  font-weight: 900;
  height: 5rem;
  text-align: center;
  font-size: 2.5rem;
  border-radius: 0 0.5rem 0.5rem 0;
  text-transform: uppercase;
  width: 100%;
  transition: 0.4s;
  padding: 0 0.5em;
  position: relative;
  letter-spacing: 0.25rem;
  border: #ccc 1px solid;
  border-left: none;

  &::placeholder {
    color: ${({ theme }) => theme.brand.interactive};
    opacity: 1;
  }

  &:focus {
    color: ${({ theme }) => theme.brand.primary};
    border-color: ${({ theme }) => theme.brand.primary};
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.brand.primary};
    }
  }

  &[type='text'] {
    text-transform: uppercase;
  }
`;

const InfoSection = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  color: #76726e;
  width: 100%;

  & span {
    font-weight: bold;
  }
`;

const InputWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  position: relative;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  margin-right: 3.5rem;
`;

const DashboardLayout = styled(Layout)`
  width: 90%;
  max-width: 100rem;
`;

const LeftBlock = styled(LeftSide)`
  margin-top: 4rem;
  & p {
    text-align: left;
  }
`;

const Right = styled(RightSide)`
  background-color: white;
  width: 60%;
  padding-left: 2rem;
`;

const ButtonContainerPlate = styled.div`
  z-index: 2;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  z-index: 2;

  & button {
    margin-right: 0;
  }
`;

const Container = styled.section`
  display: flex;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;
  width: 100%;
`;

const TitleContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-top: 6rem;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

const Section = styled.div`
  /* Outline */
  border: 1px solid #ccc;
  box-sizing: border-box;
  /* Button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4.69px;
  border-radius: 5px;
  margin: 2.35rem 0;
  padding: 3.5rem;
  padding-bottom: 1.5rem;
  background-color: white;
`;

const DataSection = styled(Section)`
  padding: 1.8rem 3rem 1.4rem;
`;

export default ToolkitCarLookup;
