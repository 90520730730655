import React from 'react';
import Section from './Section';
import styled from 'styled-components';

import { object, func, bool, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'wg-fe-ui';

const PersonalInfo = ({ values, disabled, errors, handleChange }) => {
  const { t } = useTranslation();

  return (
    <Section>
      <Section.Title>{t('Settings.personalInfo.title')}</Section.Title>
      <Section.Content data-test-id="dashboard_settings_section_personal_info">
        <Inputs>
          <TextInput
            error={errors?.email}
            value={values?.email}
            type="email"
            name="email"
            dataTestId="dashboard_settings_email"
            disabled={disabled}
            onChange={handleChange}
          >
            {t('Settings.personalInfo.email')}
          </TextInput>
          <TextInput
            value={values?.phone}
            dataTestId="dashboard_settings_phone"
            name="phone"
            type="text"
            error={errors?.phone}
            disabled={disabled}
            onChange={handleChange}
          >
            {t('Settings.personalInfo.phone')}
          </TextInput>
        </Inputs>
      </Section.Content>
    </Section>
  );
};

const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: 47%;
    margin-top: 2rem;
  }
`;

PersonalInfo.defaultProps = {
  disabled: false,
};

PersonalInfo.propTypes = {
  values: object,
  disabled: bool,
  errors: shape(),
  handleChange: func,
  setValidEmail: func,
};

export default PersonalInfo;
