import React, { useState, useEffect, useContext } from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Layout from '../../DashboardLayout/views/Layout';
import Button from '../../../components/Button';
import LoadingButton from '../../../components/LoadingButton';
import { useDebouncedCallback } from 'use-debounce';
import {
  setMapsPhoto,
  setMarkedMapsPhoto,
  getFullAddress,
  cleanAddressData,
} from '../../Flow/Woning/store';
import AsyncSelect from 'react-select/async';
import { postAddressData } from '../store';
import { getNestedObject } from '../../../services/objectService';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import UpgradePopupOnBrokerLoginScreen from '../../../components/UpgradePopupOnBrokerLoginScreen';
import { Section } from 'wg-fe-ui';
import AddressProperty from '../components/AddressProperty';
import ExtraBuildingData from '../components/ExtraBuildingData';
import AddressExtras from '../components/AddressExtras';
import AddressAnnexes from '../components/AddressAnnexes';
import DistancesData from '../components/DistancesData';
import DemographicData from '../components/DemographicData';
import { getMeData } from '../../../services/meDataService';

const ToolkitAddressLookup = () => {
  // ===================================== \\
  //        TODO: Refactor this view       \\
  // ===================================== \\

  const { t } = useTranslation();
  const content = t('ToolkitInfo', { returnObjects: true });
  const [sendStep, setSendStep] = useState('initial');
  const [loading, setLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);
  const [btnText, setBtnText] = useState(content.btnText.default);

  const [errorText, setErrorText] = useState(null);
  const [selected, setSelected] = useState(null);
  const [addressData, setAddressData] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const { setNavTabs } = useContext(TopNavigationItems);
  const [isBronze, setIsBronze] = useState(false);

  // Why you do this Leander 💩
  const exeptionEmails = [
    'test_qa_broker_bronze@wegroup.be',
    'info@kantoorvancaeneghem.be',
    'an@kantoorvancaeneghem.be',
  ];

  useEffect(() => {
    setNavTabs([
      {
        title: t('Car lookup'),
        testId: 'toolkit_car_lookup_nav',
        to: '/toolkit/car-lookup',
      },
      {
        title: t('Address lookup'),
        testId: 'toolkit_address_lookup_nav',
        to: '/toolkit/address-lookup',
      },
      {
        title: t('Documents generator'),
        testId: 'toolkit_documents_generator_nav',
        to: '/toolkit/documents-generator',
      },
    ]);
  }, []);

  useEffect(() => {
    switch (sendStep) {
      case 'initial':
        break;
      case 'pending':
        setBtnText(content.address.btnText.pending);
        break;
      case 'success':
        setIsDisabled(true);
        setBtnText(content.address.btnText.success);
        break;
      case 'error':
        setBtnText(content.btnText.default);
        setErrorText(t(`AdresInput.errorMsg`));
        setAddressData(null);
        break;
      default:
        break;
    }
  }, [sendStep]);

  const fetchResults = (value, callback) => {
    if (hasNumber(value)) {
      setLoading(true);
      return getFullAddress(value).then(res => {
        const data = res.data;
        callback(cleanAddressData(data));
      });
    }
  };

  const [debounceFetchAddress] = useDebouncedCallback(fetchResults, 500);

  const hasNumber = myString => {
    return /\d/.test(myString);
  };

  const handleChange = _selected => {
    const _isDisabled = _selected === null;

    setSelected(_selected);
    setIsDisabled(_isDisabled);
  };

  const onClickHandler = async () => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    setSendStep('pending');

    const { me } = await getMeData();

    if (me?.role === 'broker_bronze' && !exeptionEmails.includes(me?.email)) {
      setSendStep('initial');
      setIsBronze(true);
      return;
    }

    try {
      const { data } = await postAddressData(selected.value);
      setAddressData(data);
      setMapsPhoto(
        getNestedObject(data, ['aerial_images', 'blank', 'satellite']),
      );
      setMarkedMapsPhoto(
        getNestedObject(data, ['aerial_images', 'marked', 'hybrid']),
      );
      setSendStep('success');
    } catch (error) {
      setSendStep('error');
      console.error(error.response);
    }
  };

  const handleInputChange = val => {
    const fullstringArr = [];

    val.split(' ').forEach(string => {
      const partialString = [];
      if (/\d/.test(string)) {
        string.split('').forEach(i => {
          if (i === 'w') {
            partialString.unshift(i);
          } else {
            partialString.push(i);
          }
        });

        fullstringArr.push(partialString.join(''));
      } else {
        fullstringArr.push(string);
      }
    });

    setInputValue(fullstringArr.join(' '));
  };
  return (
    <DashboardLayout>
      {isBronze ? (
        <UpgradePopupOnBrokerLoginScreen
          startScreen="upgrade_bronze"
          close={() => setIsBronze(false)}
        />
      ) : null}
      <div>
        <Section>
          <Section.Title>
            {t('ToolkitNavigation.addressLookup.title')}
          </Section.Title>
          <Section.Content background>
            <p
              dangerouslySetInnerHTML={{
                __html: content.address.addressSubTitle,
              }}
            />
            <InputWrapper
            // data-test-id="dashboard_toolkit_addresslookup_section_addresslookup_input"
            >
              <Input
                onChange={e => handleChange(e)}
                loadOptions={debounceFetchAddress}
                inputValue={inputValue}
                onBlurResetsInput={false}
                isClearable
                onInputChange={val => handleInputChange(val)}
                placeholder={t(`AdresInput.input.placeholder`)}
                classNamePrefix="Select"
                value={selected}
                loadingMessage={() =>
                  loading
                    ? t(`AdresInput.input.loading`)
                    : t(`AdresInput.input.placeholder`)
                }
                noOptionsMessage={() => t(`AdresInput.input.placeholder`)}
              />
              {sendStep !== 'pending' ? (
                <ButtonContainer>
                  <Button
                    type="submit"
                    onClick={e => onClickHandler(e)}
                    disabled={isDisabled}
                  >
                    {btnText}
                  </Button>
                </ButtonContainer>
              ) : (
                <ButtonContainer>
                  <LoadingButton>{btnText}</LoadingButton>
                </ButtonContainer>
              )}
            </InputWrapper>
            {errorText !== null && addressData === null ? (
              <p>{errorText}</p>
            ) : null}
          </Section.Content>
        </Section>
        {addressData !== null ? (
          <div>
            <AddressProperty addressData={addressData} />
            {addressData?.parcel?.properties ? (
              <AddressExtras addressData={addressData} />
            ) : null}
            <ExtraBuildingData addressData={addressData} />
            {addressData?.parcel !== null &&
            addressData?.parcel?.annexes !== null ? (
              <AddressAnnexes addressData={addressData} />
            ) : null}
            <DistancesData addressData={addressData} />
            <DemographicData addressData={addressData} />
          </div>
        ) : null}
      </div>
    </DashboardLayout>
  );
};

ToolkitAddressLookup.propTypes = {
  history: shape().isRequired,
};

const DashboardLayout = styled(Layout)`
  width: 90%;
  max-width: 100rem;
  padding-top: 6rem;
  font-family: ${({ theme }) => theme.font};
`;

const ButtonContainer = styled.div`
  height: auto;
  margin: 0;
  padding: 0;
  margin-left: 1rem;

  & button,
  > div {
    height: 5rem;
    margin: 0;
    margin-right: 0;
    margin-left: auto;
  }
`;

const InputWrapper = styled.form`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  align-items: center;
`;

const Input = styled(AsyncSelect)`
  width: 100%;
  height: 5rem;
  font-size: 1.6rem;
  transition: 0.4s;
  position: relative;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__control {
      height: 100%;
      border-radius: 0.5em;
      border-color: ${({ theme, hasValue }) =>
        hasValue ? theme.brand.primary : `#ccc`};
      z-index: 10;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      z-index: 11;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
      overflow: initial;
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 11;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 11;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

export default ToolkitAddressLookup;
