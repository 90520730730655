import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../../LayoutFlow/views/App';
import { useLocation, useHistory } from 'react-router-dom';
import {
  addBrowserHistory,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
} from '../../../../store';
import ChatBox from '../../../../components/ChatBox';
import QuestionBox from '../../../../components/QuestionBox';
import { useTranslation } from 'react-i18next';
import { setFamData } from '../store';

const LegalChildrenQuestion = () => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const handleAnswer = answer => {
    addChatboxQuestion(t('do you have dependent children?'));
    if (answer === t('Yes')) {
      addChatboxAnswer(t('i have dependent children'));
      setFamData('children', true);
      history.push(getNextPath('a'));
    } else {
      setFamData('children', false);
      addChatboxAnswer(t('i do not have dependent children'));
      history.push(getNextPath('b'));
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          response={res => handleAnswer(res)}
          Answer1={t('Yes')}
          Answer2={t('No')}
          dataTestId1="Yes"
          dataTestId2="No"
        >
          {t('do you have dependent children?')}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default LegalChildrenQuestion;
