import React, { useState, useEffect } from 'react';
import Section from '../components/Section';
import styled from 'styled-components';
import PhotoSettings from './PhotoSettingsCard';
import ChatThemePreview from './ChatThemePreview';
// import { retrieveAllThemes } from '../../../services/themingService.js';
import ThemeSelector from './ThemeSelector';
import { object, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'wg-fe-ui';
import { getBrokerData } from '../../../services/brokerDataService';

const Customization = ({ values, disabled, errors, handleChange }) => {
  const { t } = useTranslation();
  const [previewLogo, setPreviewLogo] = useState();

  useEffect(() => {
    setInitialAvatar();
  }, []);

  async function setInitialAvatar() {
    const { broker } = (await getBrokerData()) || {};
    const [{ href }] = broker?.links?.filter(
      ({ rel }) => rel === 'assistant_avatar',
    );
    setPreviewLogo(href);
  }

  const setAvatar = logo => {
    setPreviewLogo(logo.logo);
    handleChange({ name: 'logo', value: logo });
  };

  return (
    <Section>
      <Section.Title>{t('Settings.customization.title')}</Section.Title>
      <Section.Content data-test-id="dashboard_settings_customization_info">
        <SubTitle>{t('Settings.customization.assistant-title')}</SubTitle>
        <UpperContainer>
          <div className="view">
            <TextInput
              type="text"
              dataTestId="dashboard_settings_customization_assistant_name"
              name="assistant_name"
              value={values?.assistantName}
              error={errors?.assistantName}
              disabled={disabled}
            >
              {t('Settings.customization.name-input')}
            </TextInput>
            <PhotoSettings
              disabled={disabled}
              selectedLogo={logo => setAvatar(logo)}
            />
          </div>
          <Line />
          <div className="view preview">
            <ChatThemePreview
              name={values?.assistantName}
              theme={values?.theme}
              themeName={values?.theme}
              logo={previewLogo}
            />
          </div>
        </UpperContainer>
        <SubTitle>{t('Settings.customization.theme-title')}</SubTitle>
        {disabled ? (
          <Warning>
            {t(
              'The theme can not be changed in the Bronze Pack upgrade to silver to change the theme',
            )}
          </Warning>
        ) : null}

        <ThemeSelector
          handleChange={handleChange}
          themeName={values?.theme}
          disabled={disabled}
          data-test-id="dashboard_settings_customization_info_themeselector"
        />
      </Section.Content>
    </Section>
  );
};

const Warning = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  color: red;
`;

const UpperContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.5rem; /* space between lines */
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};
  margin-bottom: 2rem;

  .view {
    width: 48%;
  }

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Line = styled.div`
  width: 0.1rem;
  height: 24rem;
  background-color: ${({ theme }) => theme.ui.background};
`;
const SubTitle = styled.h3`
  color: black;
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 0.14rem;
  margin-bottom: 2.2rem;
`;

Customization.defaultProps = {
  disabled: false,
};

Customization.propTypes = {
  values: object,
  disabled: bool,
  errors: object,
  handleChange: func,
};

export default Customization;
