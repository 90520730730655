import React from 'react';
import Section from './Section';
import styled from 'styled-components';
import DashboardCheckboxWithIcon from '../../../components/DashboardCheckboxWithIcon';
import Garden from '../../../../assets/images/dashboard/intrests/Garden';
import Pets from '../../../../assets/images/dashboard/intrests/Pets';
import Drone from '../../../../assets/images/dashboard/intrests/Drone';
import Bicycle from '../../../../assets/images/dashboard/intrests/Bicycle';
import Art from '../../../../assets/images/dashboard/intrests/Art';
import ElectricStep from '../../../../assets/images/dashboard/intrests/ElectricStep';
import Informatics from '../../../../assets/images/dashboard/intrests/Informatics';
import Juwels from '../../../../assets/images/dashboard/intrests/Juwels';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';

const BrokerHistoryDetailInterests = ({ extraData }) => {
  const { t } = useTranslation();
  const { garden, pets, drone, bicycle, art, juwels, e_step, informatics } =
    extraData || {};
  const interestIcons = {
    garden: Garden,
    pets: Pets,
    drone: Drone,
    bicycle: Bicycle,
    art: Art,
    juwels: Juwels,
    e_step: ElectricStep,
    informatics: Informatics,
  };
  return (
    <Section>
      <Section.Subtitle>{t('History.personal.interests')}</Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_client_interests">
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.informatics')}
          checked={informatics}
          disabled={true}
          icon={interestIcons['informatics']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.e_step')}
          checked={e_step}
          disabled={true}
          icon={interestIcons['e_step']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.art')}
          checked={art}
          disabled={true}
          icon={interestIcons['art']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.pets')}
          checked={pets}
          disabled={true}
          icon={interestIcons['pets']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.bicycle')}
          checked={bicycle}
          disabled={true}
          icon={interestIcons['bicycle']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.juwels')}
          checked={juwels}
          disabled={true}
          icon={interestIcons['juwels']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.drone')}
          checked={drone}
          disabled={true}
          icon={interestIcons['drone']}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.personal.garden')}
          checked={garden}
          disabled={true}
          icon={interestIcons['garden']}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

BrokerHistoryDetailInterests.propTypes = {
  extraData: shape().isRequired,
};

export default BrokerHistoryDetailInterests;
