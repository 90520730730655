import React from 'react';
import { shape } from 'prop-types';

const HalfOpenBuildingIcon = ({ colors }) => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.50585 2.01345C8.34094 1.90788 8.1298 1.90788 7.96412 2.01191L0.294396 6.85124C0.147983 6.94372 0.0585938 7.104 0.0585938 7.27815V22.6631C0.0585938 23.2965 0.57258 23.8105 1.20601 23.8105H7.14498C7.77841 23.8105 8.29239 23.2965 8.29239 22.6631V7.64033C8.29239 6.78189 8.73317 5.98278 9.45907 5.52505L11.3555 4.32832C11.5374 4.2135 11.5381 3.94842 11.357 3.83283L8.50585 2.01345Z"
      fill={colors.brand.dark}
    />
    <path
      d="M29.7207 1.43525V4.48603C29.7207 4.84975 29.9064 5.18804 30.2131 5.38685L32.4147 6.79396C32.9449 7.13302 33.2662 7.71944 33.2662 8.34902V22.5973C33.2662 23.2307 32.7522 23.7447 32.1188 23.7447H24.4318V16.8894H19.0771V23.7447H11.3906C10.7572 23.7447 10.2432 23.2307 10.2432 22.5973V8.35749C10.2432 7.72329 10.5684 7.13456 11.1039 6.79627L21.2688 0.381843C21.6048 0.16993 22.0333 0.170701 22.3685 0.383385L26.3717 2.92712C26.6645 3.11283 27.0475 2.90246 27.0475 2.55569V1.43525C27.0475 1.01912 27.385 0.681604 27.8011 0.681604H28.9671C29.3832 0.681604 29.7207 1.01912 29.7207 1.43525Z"
      fill={'#fff'}
    />
  </svg>
);

HalfOpenBuildingIcon.propTypes = {
  colors: shape().isRequired,
};

export default HalfOpenBuildingIcon;
