import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import {
  // IconHeavyScooter,
  BackButton,
  Body,
  DataBlock,
  H1,
  H2,
  H3,
  H4,
  IconActionClose,
  IconCarFilled,
  IconFamilyFilled,
  IconIdFilled,
  IconLegalFilled,
  IconPropertyFilled,
  LoadingSpinner,
  ProspectInfoBar,
  RiskObjectDisplay,
  RiskObjectSelector,
  RoundedButton,
  Section,
  Title,
} from 'wg-fe-ui';
import { shape } from 'prop-types';
import { newStorageEntry, patchStorage } from '../services/storeService.js';
import ProFlowChooseRiskAndInsuranceDrivers from '../components/ProFlowChooseRiskAndInsuranceDrivers';
import moment from 'moment';
import { getMeData } from '../services/meDataService.js';
import { useHistory, useLocation } from 'react-router';
import { FlagsProvider } from '../hoc/FlagsProviderHoc.jsx';
import {
  getProspectById,
  getRiskObjectsByCallType,
  retrieveProFlowQuestionsProspect,
  getInfoAboutAddress,
} from '../services/apiRouterService.js';
import CRMProFlowAddRiskObjectModal from '../components/CRMProFlowAddRiskObjectModal';
import {
  getBrokerDistribution,
  getBrokerData,
} from '../services/brokerDataService.js';
import TrialPopupOnBrokerLoginScreen from '../components/TrialPopupOnBrokerLoginScreen.jsx';
import ProFlowInsuranceAndGuarantee from '../components/ProFlowInsuranceAndGuarantee.jsx';
import ProFlowRiskAndInsuranceButton from '../components/ProFlowRiskAndInsuranceButton.jsx';

const ChooseRiskAndInsurance = () => {
  const { t } = useTranslation();
  const [prospectData, setProspectData] = useState({});
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const configCatClient = useContext(FlagsProvider);
  const [riskOptions, setRiskOptions] = useState({});
  const [chosenRiskObjects, setChosenRiskObjects] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isProfFlowEnabled, setIsProfFlowEnabled] = useState(false);
  const [hasPiaEnabled, setHasPiaEnabled] = useState(false);
  const [showCarRiskObjectsModal, setShowCarRiskObjectsModal] = useState(false);
  const [showFamilyRiskObjectsModal, setShowFamilyRiskObjectsModal] = useState(
    false,
  );
  const [
    showResidenceRiskObjectsModal,
    setShowResidenceRiskObjectsModal,
  ] = useState(false);
  const [showLegalRiskObjectsModal, setShowLegalRiskObjectsModal] = useState(
    false,
  );
  // const [
  //   showTwoWheelerRiskObjectsModal,
  //   setShowTwoWheelerRiskObjectsModal,
  // ] = useState(false);
  // const [distributionId, setDistributionId] = useState();
  const location = useLocation();
  const [carRiskObjects, setCarRiskObjects] = useState([]);
  // const [twoWheelerRiskObjects, setTwoWheelerRiskObjects] = useState([]);
  const [legalRiskObjects, setLegalRiskObjects] = useState([]);
  const [residenceRiskObjects, setResidenceRiskObjects] = useState([]);
  const [chosenInsurances, setChosenInsurances] = useState([]);
  const [chosenGuarantees, setChosenGuarantees] = useState([]);
  const [familyRiskObjects, setFamilyRiskObjects] = useState([]);
  // const [teacherRiskObjects, setTeacherRiskObjects] = useState();
  const queryParams = queryString.parse(location?.search);
  const [showTrial, setShowTrial] = useState(false);
  const [proFlowCreationError, setProFlowCreationError] = useState(false);
  const [proFlowErrorMessage, setProFlowErrorMessage] = useState();

  const history = useHistory();

  useEffect(() => {
    if (queryParams.prospectId) {
      featureChecking();
      getProspect(queryParams.prospectId);
    }
  }, []);

  useEffect(() => {
    if (location?.state) {
      console.log('hi');
      console.log(location?.state);
      addToRiskObjects(location?.state?.type, location?.state?.riskObjectId);
    }
  }, [
    carRiskObjects,
    residenceRiskObjects,
    legalRiskObjects,
    familyRiskObjects,
  ]);

  useEffect(() => {
    console.log(chosenRiskObjects);
  }, [chosenRiskObjects]);

  const addToRiskObjects = async (type, id) => {
    switch (type) {
      case 'car': {
        if (carRiskObjects.length > 0) {
          const car = carRiskObjects.find(car => car.risk_object_id === id);
          await setChosenRiskObjects(prevValue => ({
            ...prevValue,
            car,
          }));
          await setIsLoading(false);
        }
        break;
      }
      case 'family': {
        if (familyRiskObjects.length > 0) {
          const family = familyRiskObjects.find(
            family => family.risk_object_id === id,
          );
          await setChosenRiskObjects(prevValue => ({
            ...prevValue,
            family,
          }));
          await setIsLoading(false);
        }
        break;
      }
      case 'legal': {
        if (legalRiskObjects.length > 0) {
          const legal = legalRiskObjects.find(
            legal => legal.risk_object_id === id,
          );
          await setChosenRiskObjects(prevValue => ({
            ...prevValue,
            legal,
          }));
          await setIsLoading(false);
        }
        break;
      }
      case 'residence': {
        if (residenceRiskObjects.length > 0) {
          const home = residenceRiskObjects.find(
            residence => residence.risk_object_id === id,
          );
          await setChosenRiskObjects(prevValue => ({
            ...prevValue,
            home,
          }));
          await setIsLoading(false);
        }
        break;
      }
      default:
        break;
    }
  };

  async function featureChecking() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};
    const canAccessPage = await configCatClient.getValueAsync(
      'canAccessPage',
      true,
      {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      },
    );
    setIsCallantFeature(!canAccessPage);

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    // TODO: Port this to a real feature flag
    setHasPiaEnabled(me?.email === 'piatestaccount@wegroup.be');
  }

  const getRiskObjects = async (riskType, type, id) => {
    let [response, status] = await getRiskObjectsByCallType(
      id,
      queryParams.prospectId,
      riskType,
      type,
    );

    if (status !== 200) {
      return console.error(response);
    }
    console.log(response);

    switch (riskType) {
      case 'cars': {
        await setCarRiskObjects(response.items);
        break;
      }
      // case 'two_wheelers':
      //   await setTwoWheelerRiskObjects(response.items);
      //   break;
      case 'residences':
        await setResidenceRiskObjects(response.items);
        break;
      case 'families':
        await setFamilyRiskObjects(response.items);
        break;
      case 'legal':
        await setLegalRiskObjects(response.items);
        break;
      default:
        break;
    }
  };

  const getAllRiskObjects = async ({ amount_of_risk_objects }, id) => {
    if (
      amount_of_risk_objects !== null &&
      amount_of_risk_objects !== undefined
    ) {
      await Promise.all(
        Object.keys(amount_of_risk_objects).map(async key => {
          if (amount_of_risk_objects[key] > 0) {
            return await getRiskObjects(key, 'prospects', id);
          } else {
            return new Promise(resolve => resolve());
          }
        }),
      );
    }

    if (!location?.state) {
      await setIsLoading(false);
    }
  };

  async function getProspect(prospectId) {
    const { id } = await getBrokerDistribution();
    let [response, status] = await getProspectById(id, prospectId);

    const { prospect } = response;
    setProspectData(prospect);
    setIsLoading(true);

    if (status !== 200) {
      return console.error(response);
    }
    await getAllRiskObjects(prospect, id);
  }

  const goBack = () => {
    if (queryParams.prospectId) {
      history.push(`/sales-management/customer/${queryParams.prospectId}`);
    }
  };

  // async function getTags() {
  //   setIsLoading(true);
  //   const qs = queryString.stringify({
  //     company: chosenInsurances,
  //     insurance_type: chosenRiskObjects,
  //   });

  //   const [resp] = await retrieveProFlowGuarantees(qs);
  //   if (resp?.code !== 400) {
  //     setGuarantees(resp);
  //   }

  //   setIsLoading(false);
  // }

  async function saveAndRenderRiskObjects() {
    if (!isProfFlowEnabled) {
      setShowTrial(true);
      return;
    }

    let options = {};

    if (chosenInsurances.includes('car')) {
      options.car = {
        drivers: riskOptions?.drivers,
        trailers: riskOptions?.trailers,
      };
    }

    const qs = queryString.stringify({
      company: chosenInsurances,
      insurance_type: Object.keys(chosenRiskObjects),
      drivers: riskOptions?.drivers,
      trailers: riskOptions?.trailers,
    });

    const tagsPayload = { tags: chosenGuarantees };

    const payloadChosenRisks = {
      prospect: prospectData,
      ...chosenRiskObjects,
      ...tagsPayload,
    };

    Object.keys(payloadChosenRisks).forEach(key => {
      if (payloadChosenRisks[key] === true) {
        delete payloadChosenRisks[key];
      }
    });

    const [resp] = await retrieveProFlowQuestionsProspect(
      qs,
      payloadChosenRisks,
    );

    const homeSearchIndex = resp.questions.findIndex(
      q => q?.field === 'home_search',
    );

    if (homeSearchIndex >= 0) {
      if (resp?.questions?.[homeSearchIndex]?.value?.address) {
        const addressData = resp?.questions?.[homeSearchIndex]?.value?.address;
        let [response, status] = await getInfoAboutAddress({
          street: addressData.street,
          zipcode: addressData.zipcode,
          housenr: addressData.housenr,
          country_code: 'BE',
          ...(addressData.boxnr !== undefined && {
            boxnr: addressData.boxnr,
          }),
        });
        if (status !== 200) {
          return console.error('Error Response: ', response);
        }

        resp.questions[homeSearchIndex] = {
          ...resp?.questions?.[homeSearchIndex],
          value: {
            ...resp?.questions?.[homeSearchIndex].value,
            annexes: response?.parcel?.annexes,
            links: response?.links,
          },
        };
      }
    }

    const payload = {
      risk_object: {
        selected: Object.keys(chosenRiskObjects),
        options: options,
        ids: Object.entries(chosenRiskObjects).map(([key, value]) => ({
          key,
          riskObjectId: value?.risk_object_id,
        })),
      },
      insurances: chosenInsurances,
      questions: resp.questions,
      prospectId: queryParams.prospectId,
      hasHouse: residenceRiskObjects.length > 0 ? true : false,
      ...tagsPayload,
    };

    await newStorageEntry('pro_flow', resp.id);
    await patchStorage('pro_flow', resp.id, payload);

    history.push(`/professional-flow/risk-objects/${resp.id}`);
  }

  const getRoomCount = rooms => {
    if (!rooms) return '-';
    let roomCount = 0;
    Object.values(rooms).forEach(amount => {
      roomCount += amount;
    });
    return roomCount;
  };

  function parseAddressToString({ city, housenr, street, zipcode, boxnr }) {
    if (!city || !housenr || !street || !zipcode) return '-';
    const fullHouseNr = boxnr ? `${housenr}/${boxnr}` : housenr;
    return `${street} ${fullHouseNr}, ${city} ${zipcode}`;
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <>
      <CRMProFlowAddRiskObjectModal
        title={t('Add a car risk object (only one risk object per type)')}
        riskObjects={carRiskObjects}
        modalState={[showCarRiskObjectsModal, setShowCarRiskObjectsModal]}
        type="car"
        setRiskObject={car => {
          setShowCarRiskObjectsModal(false);
          setChosenRiskObjects(prevValue => ({
            ...prevValue,
            car,
          }));
        }}
      />
      <CRMProFlowAddRiskObjectModal
        title={t('Add a legal risk object (only one risk object per type)')}
        riskObjects={legalRiskObjects}
        modalState={[showLegalRiskObjectsModal, setShowLegalRiskObjectsModal]}
        type="legal"
        setRiskObject={legal => {
          setShowLegalRiskObjectsModal(false);
          setChosenRiskObjects(prevValue => ({
            ...prevValue,
            legal,
          }));
        }}
      />
      <CRMProFlowAddRiskObjectModal
        title={t('Add a residence risk object (only one risk object per type)')}
        riskObjects={residenceRiskObjects}
        modalState={[
          showResidenceRiskObjectsModal,
          setShowResidenceRiskObjectsModal,
        ]}
        type="home"
        setRiskObject={home => {
          setShowResidenceRiskObjectsModal(false);
          setChosenRiskObjects(prevValue => ({
            ...prevValue,
            home,
          }));
        }}
      />
      <CRMProFlowAddRiskObjectModal
        title={t('Add a family risk object (only one risk object per type)')}
        riskObjects={familyRiskObjects}
        modalState={[showFamilyRiskObjectsModal, setShowFamilyRiskObjectsModal]}
        type="family"
        setRiskObject={family => {
          setShowFamilyRiskObjectsModal(false);
          setChosenRiskObjects(prevValue => ({
            ...prevValue,
            family,
          }));
        }}
      />
      {/* <CRMProFlowAddRiskObjectModal
        title={t(
          'Add a two wheeler risk object (only one risk object per type)',
        )}
        riskObjects={twoWheelerRiskObjects}
        modalState={[
          showTwoWheelerRiskObjectsModal,
          setShowTwoWheelerRiskObjectsModal,
        ]}
        type="two_wheeler"
        setRiskObject={two_wheeler => {
          setShowTwoWheelerRiskObjectsModal(false);
          setChosenRiskObjects(prevValue => ({
            ...prevValue,
            two_wheeler,
          }));
        }}
      /> */}
      <PageHeader>
        {queryParams.prospectId ? (
          <GoBack
            onClick={goBack}
            name={
              prospectData?.type === 'COMPANY' ? (
                <>
                  {t('Go back to')} {prospectData?.name}
                </>
              ) : (
                <>
                  {t('Go back to')} {prospectData?.first_name}{' '}
                  {prospectData?.last_name}
                </>
              )
            }
          />
        ) : null}
        <H1>{t('New tarification')} </H1>
        <H2>
          {t(
            'Create a new calculation with the selected risk objects and insurance companies',
          )}
        </H2>
      </PageHeader>
      {showTrial ? (
        <TrialPopupOnBrokerLoginScreen
          close={bool => setShowTrial(bool)}
          startScreen="cantUseProFlow"
        />
      ) : null}
      <StyledSection>
        <StyledSectionTitle>{t('Tarification for')} </StyledSectionTitle>
        <ProspectInfoBar icon={<IconIdFilled size={36} />}>
          <DataBlock>
            <Body light smaller>
              {t('Name')}
            </Body>
            <H4 larger>
              {prospectData?.type === 'COMPANY' ? (
                <>{prospectData?.name}</>
              ) : (
                <>
                  {prospectData?.first_name} {prospectData?.last_name}
                </>
              )}
            </H4>
          </DataBlock>
          {prospectData?.type !== 'COMPANY' ? (
            <DataBlock>
              <Body light smaller>
                {t('Date of birth')}
              </Body>
              <H4 larger>
                {prospectData?.birth
                  ? moment(prospectData?.birth).format('DD/MM/YYYY')
                  : '-'}
              </H4>
            </DataBlock>
          ) : null}

          <DataBlock>
            <Body light smaller>
              {t('Address')}
            </Body>
            <H4 larger>
              {prospectData?.address
                ? parseAddressToString(prospectData?.address)
                : '-'}
            </H4>
          </DataBlock>

          <DataBlock>
            <Body light smaller>
              {t('E-mail')}
            </Body>
            <H4 larger>{prospectData?.email ? prospectData?.email : '-'}</H4>
          </DataBlock>
        </ProspectInfoBar>
      </StyledSection>
      <StyledSection>
        <H3>{t('What risk objects would you like to tarifficate?')}</H3>
        <Body light>
          {t('Select which risico objects you would like to tarificate')}
        </Body>
        <Section.Content>
          <RiskObjectContainer>
            <StyledRiskObjectSelector
              badge={
                prospectData?.amount_of_risk_objects?.cars > 0
                  ? `${prospectData?.amount_of_risk_objects?.cars} ${t(
                      'available',
                    )}`
                  : null
              }
              label={t('InsuranceTypes.Car')}
              onClick={() => setShowCarRiskObjectsModal(true)}
              size="square"
            >
              <IconCarFilled color="black" size={50} />
            </StyledRiskObjectSelector>
            <StyledRiskObjectSelector
              badge={
                prospectData?.amount_of_risk_objects?.families > 0
                  ? `${prospectData?.amount_of_risk_objects?.families} ${t(
                      'available',
                    )}`
                  : null
              }
              label={t('InsuranceTypes.Family')}
              onClick={() => setShowFamilyRiskObjectsModal(true)}
              size="square"
            >
              <IconFamilyFilled color="black" size={50} />
            </StyledRiskObjectSelector>
            <StyledRiskObjectSelector
              badge={
                prospectData?.amount_of_risk_objects?.residences > 0
                  ? `${prospectData?.amount_of_risk_objects?.residences} ${t(
                      'available',
                    )}`
                  : null
              }
              label={t('InsuranceTypes.Property')}
              onClick={() => setShowResidenceRiskObjectsModal(true)}
              size="square"
            >
              <IconPropertyFilled color="black" size={50} />
            </StyledRiskObjectSelector>
            <StyledRiskObjectSelector
              badge={
                prospectData?.amount_of_risk_objects?.legal > 0
                  ? `${prospectData?.amount_of_risk_objects?.legal} ${t(
                      'available',
                    )}`
                  : null
              }
              label={t('InsuranceTypes.Legal')}
              onClick={() => setShowLegalRiskObjectsModal(true)}
              size="square"
            >
              <IconLegalFilled color="black" size={50} />
            </StyledRiskObjectSelector>
            {/* <StyledRiskObjectSelector
              amount={prospectData?.amount_of_risk_objects?.two_wheelers}
              label="Two-wheeler"
              onClick={() => setShowTwoWheelerRiskObjectsModal(true)}
              size="square"
            >
              <IconHeavyScooter color="black" size={50} />
            </StyledRiskObjectSelector> */}
          </RiskObjectContainer>
        </Section.Content>
      </StyledSection>
      {Object.keys(chosenRiskObjects).length > 0 && (
        <>
          <StyledSection>
            <Title>{t('Your chosen risk objects')} </Title>
            <RiskObjectDisplayContainer>
              {Object.keys(chosenRiskObjects).map(key =>
                chosenRiskObjects[key] === true ? (
                  <StyledRiskObjectDisplay
                    key={chosenRiskObjects[key].id}
                    hoverButton={
                      <RoundedButton
                        icon={<IconActionClose color="black" size={40} />}
                        iconType="fill"
                        size="large"
                        type="danger"
                      />
                    }
                    hoverContent={t(`Remove`)}
                    icon={
                      key === 'car' ? (
                        <IconCarFilled color="black" size={30} />
                      ) : key === 'family' ? (
                        <IconFamilyFilled color="black" size={30} />
                      ) : key === 'legal' ? (
                        <IconLegalFilled color="black" size={30} />
                      ) : key === 'home' ? (
                        <IconPropertyFilled color="black" size={30} />
                      ) : null
                    }
                    onClick={() => {
                      const temp = JSON.parse(
                        JSON.stringify(chosenRiskObjects),
                      );
                      delete temp[key];
                      setChosenRiskObjects(temp);
                    }}
                    title={t(key)}
                  ></StyledRiskObjectDisplay>
                ) : (
                  <StyledRiskObjectDisplay
                    key={chosenRiskObjects[key].id}
                    hoverButton={
                      <RoundedButton
                        icon={<IconActionClose color="black" size={40} />}
                        iconType="fill"
                        size="large"
                        type="danger"
                      />
                    }
                    hoverContent={t(`Remove`)}
                    icon={
                      key === 'car' ? (
                        <IconCarFilled color="black" size={30} />
                      ) : key === 'family' ? (
                        <IconFamilyFilled color="black" size={30} />
                      ) : key === 'legal' ? (
                        <IconLegalFilled color="black" size={30} />
                      ) : key === 'home' ? (
                        <IconPropertyFilled color="black" size={30} />
                      ) : null
                    }
                    onClick={() => {
                      const temp = JSON.parse(
                        JSON.stringify(chosenRiskObjects),
                      );
                      delete temp[key];
                      setChosenRiskObjects(temp);
                    }}
                    title={
                      key === 'car' || key === 'two_wheeler'
                        ? t(chosenRiskObjects[key]?.name)
                        : key === 'home'
                        ? parseAddressToString(chosenRiskObjects[key].address)
                        : key === 'family'
                        ? t(chosenRiskObjects[key].status)
                        : key
                    }
                  >
                    {key === 'car' ? (
                      <>
                        <DataBlock>
                          <Body light smaller>
                            {t('License plate')}
                          </Body>
                          <H4 larger>
                            {chosenRiskObjects[
                              key
                            ]?.number_plate?.toUpperCase() || '-'}
                          </H4>
                        </DataBlock>
                        <DataBlock>
                          <Body light smaller>
                            {t('Power')} (kW)
                          </Body>
                          <H4 larger>{chosenRiskObjects[key]?.kw || '-'}</H4>
                        </DataBlock>
                        <DataBlock>
                          <Body light smaller>
                            {t('Catalogue value')}
                          </Body>
                          <H4 larger>
                            {Number(
                              chosenRiskObjects[key]?.value,
                            ).toLocaleString('nl-BE', {
                              style: 'currency',
                              currency: 'EUR',
                            }) || '-'}
                          </H4>
                        </DataBlock>
                      </>
                    ) : key === 'family' ? (
                      <>
                        <DataBlock>
                          <Body light smaller>
                            {t('Household')}
                          </Body>
                          <H4 larger>
                            {t(chosenRiskObjects[key].status) || '-'}
                          </H4>
                        </DataBlock>
                      </>
                    ) : key === 'two_wheeler' ? (
                      <>
                        <DataBlock>
                          <Body light smaller>
                            {t('License plate')}
                          </Body>
                          <H4 larger>
                            {chosenRiskObjects[
                              key
                            ]?.number_plate?.toUpperCase() || '-'}
                          </H4>
                        </DataBlock>
                        <DataBlock>
                          <Body light smaller>
                            {t('Power')} (kW)
                          </Body>
                          <H4 larger>{chosenRiskObjects[key]?.kw || '-'}</H4>
                        </DataBlock>
                        <DataBlock>
                          <Body light smaller>
                            {t('Catalogue value')}
                          </Body>
                          <H4 larger>
                            {Number(
                              chosenRiskObjects[key]?.value,
                            ).toLocaleString('nl-BE', {
                              style: 'currency',
                              currency: 'EUR',
                            }) || '-'}
                          </H4>
                        </DataBlock>
                      </>
                    ) : key === 'home' ? (
                      <>
                        <DataBlock>
                          <Body light smaller>
                            {t('Number of rooms')}
                          </Body>
                          <H4 larger>
                            {getRoomCount(
                              chosenRiskObjects[key]?.main_building?.rooms,
                            )}
                          </H4>
                        </DataBlock>
                        <DataBlock>
                          <Body light smaller>
                            {t('Total surface area')}
                          </Body>
                          <H4 larger>
                            {chosenRiskObjects[key]?.main_building?.surface ? (
                              <>
                                {chosenRiskObjects[key]?.main_building?.surface}{' '}
                                m<sup>2</sup>
                              </>
                            ) : (
                              '-'
                            )}
                          </H4>
                        </DataBlock>
                      </>
                    ) : null}
                  </StyledRiskObjectDisplay>
                ),
              )}
            </RiskObjectDisplayContainer>
          </StyledSection>
          {Object.keys(chosenRiskObjects).includes('car') ||
          Object.keys(chosenRiskObjects).includes('two_wheelers') ? (
            <ProFlowChooseRiskAndInsuranceDrivers
              onChange={obj => setRiskOptions(obj)}
            />
          ) : null}
          <ProFlowInsuranceAndGuarantee
            riskOptions={riskOptions}
            chosenRisks={Object.keys(chosenRiskObjects)}
            chosenInsurances={chosenInsurances}
            setChosenInsurances={setChosenInsurances}
            chosenGuarantees={chosenGuarantees}
            setChosenGuarantees={setChosenGuarantees}
            isCallantFeature={isCallantFeature}
            hasPiaEnabled={hasPiaEnabled}
            setProFlowErrorMessage={setProFlowErrorMessage}
            setProFlowCreationError={setProFlowCreationError}
          />
        </>
      )}
      <ProFlowRiskAndInsuranceButton
        chosenRisks={Object.keys(chosenRiskObjects)}
        chosenGuarantees={chosenGuarantees}
        chosenInsurances={chosenInsurances}
        proFlowCreationError={proFlowCreationError}
        proFlowErrorMessage={proFlowErrorMessage}
        saveAndRenderRiskObjects={saveAndRenderRiskObjects}
      />
    </>
  );
};

const StyledRiskObjectDisplay = styled(RiskObjectDisplay)`
  min-height: 240px;
  sup {
    vertical-align: super;
    font-size: smaller;
  }
`;

const StyledRiskObjectSelector = styled(RiskObjectSelector)`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RiskObjectContainer = styled.div`
  display: grid;
  padding-top: 2rem;

  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 3rem;

  @media screen and (max-width: 865px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;
const RiskObjectDisplayContainer = styled.div`
  display: grid;
  padding-top: 2rem;

  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 3rem;

  @media screen and (max-width: 965px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

// const Button = styled.button`
//   background-color: #ff8000;
//   border-radius: 0.5rem;
//   min-width: 14rem;
//   display: flex;
//   font-size: 2rem;
//   justify-content: center;
//   align-items: center;
//   color: white;
//   border: 0;
//   font-weight: bold;
//   text-decoration: none;
//   align-self: center;
//   z-index: 1;
//   cursor: pointer;
//   transition: all 0.2s ease 0s;
//   margin-top: 4rem;
//   padding: 1rem 3rem;

//   &:active {
//     outline: none;
//     transform: scale(0.98);
//   }

//   &:focus {
//     border: 0 0 0;
//     outline: none;
//   }

//   &:hover {
//     opacity: 0.3;
//     color: white;
//   }

//   &:disabled {
//     cursor: default;
//     opacity: 0.3;
//     background-color: ${({ theme }) => theme.brand.lighter};
//   }

//   @media screen and (max-width: 1025px) {
//     transform: scale(0.9);
//     margin-bottom: 1rem;

//     &:active {
//       transform: scale(0.88);
//     }
//   }
// `;

const GoBack = styled(BackButton)`
  color: ${({ theme }) => theme.brand.primary};
  margin-bottom: 2rem;
  height: 2.5rem;
  cursor: pointer;
  padding-left: 0;
  min-width: 0;
  font-size: 1.6rem;

  & svg {
    height: 1.4rem;
    margin-right: 1.6rem;
  }

  & svg path {
    stroke: ${({ theme }) => theme.brand.primary};
  }
`;

const PageHeader = styled(Section)`
  margin: 6rem auto 4rem;
  padding: 0 3rem;
  width: 100%;
`;

const StyledSection = styled(Section)`
  transition: opacity 0.2s ease-in, max-height 2.5s ease-out;
  margin: auto;
  margin-bottom: 5vh;
  margin-top: 6rem;
  padding: 0 3rem;
  width: 100%;
`;

const StyledSectionTitle = styled(H3)`
  margin-bottom: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

ChooseRiskAndInsurance.propTypes = {
  match: shape(),
};

export default ChooseRiskAndInsurance;
