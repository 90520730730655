/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  RiskObjectSelector,
  IconCarFilled,
  IconFamilyFilled,
  IconLegalFilled,
  IconPropertyFilled,
  IconStatusCheck,
} from 'wg-fe-ui';

const RiskObject = ({ isSelected, onChange, name }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(isSelected);

  useEffect(() => {
    setValue(isSelected);
  }, [isSelected]);

  useEffect(() => {
    onChange({ name, value });
  }, [value]);

  function handleIconType(riskObject) {
    switch (riskObject) {
      case 'car':
        return <IconCarFilled color="black" size={50} />;
      case 'family':
        return <IconFamilyFilled color="black" size={50} />;
      case 'residence':
        return <IconPropertyFilled color="black" size={50} />;
      case 'legal':
        return <IconLegalFilled color="black" size={50} />;
      default:
        break;
    }
  }

  return (
    <StyledRiskObjectSelector
      label={t(name)}
      badge={value ? <IconStatusCheck /> : null}
      size="square"
      checked={value}
      onClick={() => setValue(!value)}
    >
      {handleIconType(name)}
    </StyledRiskObjectSelector>
  );
};

const StyledRiskObjectSelector = styled(RiskObjectSelector)`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  div:first-child {
    background-color: ${({ checked, theme }) =>
      checked ? theme.brand.lightest : `white`};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    text-align: center;
    border: ${({ checked, theme }) =>
      checked ? `1px solid ${theme.brand.primary}` : `1px solid #f0f1f3`};
    path {
      fill: ${({ checked, theme }) =>
        checked ? theme.brand.primary : '#c1c1c1'};
    }
    :hover {
      border: ${({ theme }) => `1px solid ${theme.brand.primary}`};
    }
  }

  div:nth-child(2) {
    padding: 0;
    svg {
      fill: white;
      padding-right: 0.2rem;
      path {
        fill: white;
      }
    }
  }
`;

export default RiskObject;
