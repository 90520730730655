import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { useTranslation } from 'react-i18next';

import Layout from '../../DashboardLayout/views/Layout';
// import DashboardToolbarBrokerHistory from '../../../components/DashboardToolbarBrokerHistory';
import DashboardBrokerHistorySections from '../../../components/DashboardBrokerHistorySections';
// import { getNestedObject } from '../../../services/objectService';
import {
  // getBrokerData,
  getBrokerAffiliations,
} from '../../../services/brokerDataService';
import ApiRoutes from '../../../store/ApiRoutes';
import { shape } from 'prop-types';
// import { generateId } from '../../../store/index';
// import { addDataForIdWoning } from '../../Flow/Woning/store';
// import { addDataForIdUser } from '../../Flow/User/store';
// import { addDataForIdCar } from '../../Flow/Car/store';
// import { addDataForIdLock } from '../../Flow/Lock/store';
import moment from 'moment';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import { withRouter } from 'react-router';
import DashboardBrokerHistorySearch from '../../../components/DashboardBrokerHistorySearch';
// import { addDataForIdFamily } from '../../Flow/Gezin/store';
// import { addDataForIdLegal } from '../../Flow/Legal/store';

const BrokerHistory = () => {
  const [brokerHistoryData, setBrokerHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState(false);
  const { t } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  let tempData = {};
  let toast;

  const api = new ApiRoutes();

  useEffect(() => {
    getAllData();
    setNavTabs([]);
  }, []);

  const generateNewId = async () => {
    const resp = await getBrokerAffiliations();
    console.log(resp);

    // return getBrokerData().then(resp => {
    //   if (getNestedObject(resp, ['broker', 'affiliations']).length >= 1) {
    //     return generateId().then(res => {
    //       addDataForIdWoning(res.id);
    //       addDataForIdUser(res.id);
    //       addDataForIdCar(res.id);
    //       addDataForIdFamily(res.id);
    //       addDataForIdLock(res.id);
    //       addDataForIdLegal(res.id);
    //       history.push('/session/user');
    //     });
    //   } else {
    //     cogoToast.error(
    //       t(
    //         'There are no companies linked to your account yet Feel free to adjust this in your settings',
    //       ),
    //     );
    //   }
    // });
  };

  const getAllData = () => {
    const brokerHistoryArr = [];
    api.getSessions().then(({ data }) => {
      const { items } = data;
      items.forEach(item => {
        brokerHistoryArr.push({
          id: item.id,
          firstName: item.client_firstname,
          lastName: item.client_lastname,
          createdTimeOn: parseTimeToMoment(item.created_at),
          street: item.street,
          number: item.housenr,
          zipcode: item.zipcode,
          city: item.city,
          createdDateOn: parseDateToMoment(item.created_at),
        });
      });
      setBrokerHistoryData(brokerHistoryArr);
    });
  };

  const parseDateToMoment = _date => {
    if (typeof _date === 'string') {
      return moment(_date).format('YYYY/MM/DD');
    } else {
      let { day, month, year } = _date;
      return `${year}/${month}/${day}`;
    }
  };

  const parseTimeToMoment = _date => {
    if (typeof _date === 'string') {
      return moment(_date).format('HH:mm');
    } else {
      let { hour, minute } = _date;
      return `${hour}:${minute}`;
    }
  };

  const undoDelete = (id, index) => {
    if (toast) {
      toast();
      toast = undefined;
    }
    setBrokerHistoryData(oldData => {
      const tempOldData = [...oldData];
      tempOldData[index] = tempData[id];
      delete tempData[id];
      return tempOldData;
    });
  };

  const deleteHistory = id => {
    const brokerHistoryDataTmp = [...brokerHistoryData];
    const index = brokerHistoryData.findIndex(item => item && item.id === id);
    tempData[id] = brokerHistoryData[index];
    if (brokerHistoryDataTmp[index]) {
      delete brokerHistoryDataTmp[index];
    }
    setBrokerHistoryData(brokerHistoryDataTmp);
    toast = cogoToast.info(
      <>
        <p>
          {t('Entry deleted for')} {brokerHistoryData[index].firstName}{' '}
          {brokerHistoryData[index].lastName}.
        </p>
        <div>
          <UndoButton onClick={() => undoDelete(id, index)}>
            {t('Undo')}
          </UndoButton>
        </div>
      </>,
      { hideAfter: 0 },
    );
    setTimeout(() => {
      if (toast) {
        toast();
        api
          .deleteSession(id)
          .then(() => {})
          .catch(err => {
            cogoToast.error(
              `${t(
                'Something went wrong while trying to delete the entry for',
              )} ${brokerHistoryData[index].firstName} 
            ${brokerHistoryData[index].lastName}`,
            );
            undoDelete(id, index);
            console.error(err);
          });
      }
    }, 5000);
  };

  return (
    <StyledLayout>
      <DashboardToolBar
        btnText={t('New conversation')}
        dataTestId="broker_history_underwriting"
        onClick={generateNewId}
      >
        <DashboardToolbarContainer>
          <StyledDashboardBrokerHistorySearch
            unFilteredData={brokerHistoryData}
            onFiltered={data => setFilteredData(data)}
            searchValue={value =>
              value.length > 0 ? setSearch(true) : setSearch(false)
            }
          />
        </DashboardToolbarContainer>
      </DashboardToolBar>
      <SectionWrapper>
        {filteredData.length === 0 && search ? (
          <Empty>{t('No results')}</Empty>
        ) : (
          <DashboardBrokerHistorySections
            brokerHistoryData={filteredData}
            removeSession={id => deleteHistory(id)}
          />
        )}
      </SectionWrapper>
    </StyledLayout>
  );
};

const DashboardToolBar = styled.div`
  background: #f7f7f7;
  width: 100%;
`;

const DashboardToolbarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 7rem;
  width: 90%;
`;

const StyledDashboardBrokerHistorySearch = styled(DashboardBrokerHistorySearch)`
  width: 60%;

  & input {
    background-color: #f7f7f7;
    padding: 0 4rem;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }
  }

  & img {
    position: absolute;
  }
`;

const Empty = styled.p`
  width: 90%;
  margin: 0 auto;
  margin-top: 2rem;
  color: ${({ theme }) => theme.typo.highlight};
`;

const UndoButton = styled.button`
  margin-top: 1rem;
  background-color: rgb(18, 113, 236);
  border: none;
  border-radius: 3px;
  padding: 0.5rem 1rem;
  padding-bottom: 0.65rem;
  cursor: pointer;
  color: white;
  outline: none;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: #4998ff;
  }
`;

const StyledLayout = styled(Layout)`
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

BrokerHistory.propTypes = {
  history: shape().isRequired,
};

export default withRouter(BrokerHistory);
