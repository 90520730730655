/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutMarket/views/Layout';
import { Formik, Form } from 'formik';
// import PopupContract from '../components/PopupContract';
import CheckoutItem from '../components/CheckoutItem';
import AcknowledgementCheckbox from '../components/AcknowledgementCheckbox';
import {
  getAllCoverages,
  getTrialAccount,
  addBrowserHistory,
} from '../../../../store/index';
import TrialPopupOnBrokerLoginScreen from '../../../../components/TrialPopupOnBrokerLoginScreen.jsx';
import {
  getCartSize,
  getCheckout,
  setCurrentStep,
  getCheckoutPriceData,
} from '../../LayoutMarket/store/index';
import {
  getFireName,
  clearInsurances,
  sendCheckout,
  setSignatureData,
  retrieveAcceptances,
  addInsurance,
  getOldInsurance,
} from '../store';
import AddToCart from '../../LayoutMarket/components/Buttons/AddToCart';
import { getPayloadCar } from '../../../Flow/Car/store';
import { getFamilyData } from '../../../Flow/Gezin/store';
import { getTraits } from '../../../Flow/Woning/store';
import { getLegalData } from '../../../Flow/Legal/store';
import { getInsurances } from '../../../../services/insurancesService';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';
import { Alert } from 'wg-fe-ui';
import CircleSpinner from '../../../../components/CircleSpinner';

const Checkout = ({ history }) => {
  const { t } = useTranslation();
  const coverages = getAllCoverages();
  const fireCard = getCheckout('fire');
  const firePriceData = getCheckoutPriceData('fire');
  const peopleCard = getCheckout('people');
  const carCard = getCheckout('car');
  const legalCard = getCheckout('legal');
  const flowStorage = useFlowStore();

  const carData = getPayloadCar();

  const homeData = getTraits();
  const familyData = getFamilyData();
  const legalData = getLegalData();

  let isValid = true;

  const [disabled, setDisabled] = useState(false);
  const [startDates, setStartDates] = useState({
    home: {},
    family: {},
    car: {},
    legal: {},
  });
  const [datesValid, setDatesValid] = useState(true);
  const [btnText, setBtnText] = useState('Afrekenen');
  const [acceptances, setAcceptances] = useState({
    car: [],
    home: [],
    family: [],
    legal: [],
    general: [],
  });
  const [requirements, setRequirements] = useState({
    car: ``,
    home: ``,
    family: ``,
    legal: ``,
  });
  const [acknowledgements, setAcknowledgements] = useState({});
  const [intervalTexts, setIntervalText] = useState({});
  const [oldFireInsurance, setOldFireInsurance] = useState(getOldInsurance(1));
  const [allInsurers, setAllInsureres] = useState({});
  const [oldPeopleInsurance, setOldPeopleInsurance] = useState(
    getOldInsurance(2),
  );
  const [oldCarInsurance, setOldCarInsurance] = useState(getOldInsurance(3));
  const [oldLegalInsurance, setOldLegalInsurance] = useState(
    getOldInsurance(4),
  );
  const setPreviousInsuranceData = () => {
    setOldFireInsurance(getOldInsurance(1));
    setOldPeopleInsurance(getOldInsurance(2));
    setOldCarInsurance(getOldInsurance(3));
    setOldLegalInsurance(getOldInsurance(4));
  };

  const [showTrial, setShowTrial] = useState(false);

  const [contractErrors, setContractErrors] = useState({});
  const acceptancesPayload = {
    home: coverages.home,
    family: coverages.family,
    car: coverages.car,
    legal: coverages.legal,
  };

  const clickHandler = values => {
    if (!getTrialAccount()) {
      acceptPurchase();
    } else {
      setShowTrial(true);
    }
  };

  useEffect(() => {
    const errorsContract = [];
    Object.keys(contractErrors).forEach(key => {
      Object.keys(contractErrors[key]).length !== 0
        ? errorsContract.push(true)
        : errorsContract.push(false);
    });
    setDatesValid(errorsContract.includes(true));
  }, [contractErrors]);

  const setRequirementsByName = (name, value) => {
    setRequirements(oldRequirements => {
      const tempRequirements = { ...oldRequirements };
      tempRequirements[name] = value;
      return tempRequirements;
    });
  };

  const disableHandler = (errors, touched, isValid, values) => {
    let nr_of_questions = (acceptances?.general || []).length;
    if (carCard) {
      nr_of_questions += Math.max((acceptances?.car || []).length - 1, 0);
    }
    return Object.keys(acknowledgements).length === nr_of_questions &&
      Object.keys(acknowledgements).length > 0
      ? false
      : true;
  };

  const [sendStep, setSendStep] = useState(false);

  const acceptPurchase = () => {
    const sendToProspect =
      flowStorage?.campaign?.extend === 'INTERNAL_OFFER' ? false : true;
    const generalAcks = Object.keys(acknowledgements).reduce(function(
      filtered,
      key,
    ) {
      if (!key.includes('primary_driver'))
        filtered[key] = acknowledgements[key];
      return filtered;
    },
    {});
    const homeAck = {
      text: acceptances?.home?.[0]?.short,
      agreed:
        firePriceData?.acceptedTerms === null
          ? true
          : firePriceData?.acceptedTerms,
      key: acceptances?.home?.[0]?.key,
    };
    const familyAck = {
      text: acceptances?.family?.[0]?.short,
      agreed:
        getCheckoutPriceData('people')?.acceptedTerms === null
          ? true
          : getCheckoutPriceData('people')?.acceptedTerms,
      key: acceptances?.family?.[0]?.key,
    };
    const carAck = {
      text: acceptances?.car?.[0]?.short,
      agreed:
        getCheckoutPriceData('car')?.acceptedTerms === null
          ? true
          : getCheckoutPriceData('car')?.acceptedTerms,
      key: acceptances?.car?.[0]?.key,
    };
    const legalAck = {
      text: acceptances?.legal?.[0]?.short,
      agreed:
        getCheckoutPriceData('legal')?.acceptedTerms === null
          ? true
          : getCheckoutPriceData('legal')?.acceptedTerms,
      key: acceptances?.legal?.[0]?.key,
    };
    clearInsurances();
    setSendStep('pending');
    coverages.home && fireCard
      ? addInsurance(
          fireCard.id,
          startDates.home.year,
          startDates.home.day,
          startDates.home.month,
          oldFireInsurance,
          requirements.home
            ? requirements.home.replace(/(?:\r\n|\r|\n)/g, '<br>')
            : '',
          sendToProspect,
          [homeAck, ...Object.values(generalAcks)],
        )
      : null;
    coverages.family && peopleCard
      ? addInsurance(
          peopleCard.id,
          startDates.family.year,
          startDates.family.day,
          startDates.family.month,
          oldPeopleInsurance,
          requirements.family
            ? requirements.family.replace(/(?:\r\n|\r|\n)/g, '<br>')
            : '',
          sendToProspect,
          [familyAck, ...Object.values(generalAcks)],
        )
      : null;
    coverages.car && carCard
      ? addInsurance(
          carCard.id,
          startDates.car.year,
          startDates.car.day,
          startDates.car.month,
          oldCarInsurance,
          requirements.car
            ? requirements.car.replace(/(?:\r\n|\r|\n)/g, '<br>')
            : '',
          sendToProspect,
          [carAck, ...Object.values(acknowledgements)],
        )
      : null;
    coverages.legal && legalCard
      ? addInsurance(
          legalCard.id,
          startDates.legal.year,
          startDates.legal.day,
          startDates.legal.month,
          oldLegalInsurance,
          requirements.legal
            ? requirements.legal.replace(/(?:\r\n|\r|\n)/g, '<br>')
            : '',
          sendToProspect,
          [legalAck, ...Object.values(generalAcks)],
        )
      : null;
    sendCheckout()
      .then(res => {
        setSignatureData(res.data);
        setSendStep('success');
      })
      .catch(err => {
        setSendStep('error');
        console.error(err.respons);
      });
  };

  const handleIntervalText = (name, text) => {
    const tempTexts = { ...intervalTexts };
    tempTexts[name] = text;
    setIntervalText(tempTexts);
  };

  useEffect(() => {
    switch (sendStep) {
      case 'pending':
        setDisabled(true);
        setBtnText(t('Checkout.btnText.pending'));
        break;
      case 'success':
        setDisabled(true);
        history.push(`/session/market/thankyou`);
        setBtnText(t('Checkout.btnText.success'));
        break;
      case 'error':
        setDisabled(false);
        setBtnText(t('Checkout.btnText.error'));
        break;
      default:
        setDisabled(false);
        setBtnText(t('Checkout.btnText.default'));
        break;
    }
  }, [sendStep]);

  const [cart, setCart] = useState(getCartSize());

  const handleClick = () => {
    setCart(cart - 1);
  };

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
    window.scrollTo(0, 0);
    setCurrentStep(5);
    getInsurances().then(res => setAllInsureres(res));
    if (acceptances.general.length === 0) {
      retrieveAcceptances(acceptancesPayload)
        .then(res => {
          setAcceptances(res.data);
        })
        .catch(error => console.error(error.response));
    }
  }, []);

  const changeStartDate = (year, month, day, type) => {
    const tempDates = { ...startDates };
    tempDates[type] = {
      day,
      month,
      year,
      type,
    };
    if (
      startDates[type].day !== tempDates[type].day ||
      startDates[type].month !== tempDates[type].month ||
      startDates[type].year !== tempDates[type].year
    ) {
      setStartDates(tempDates);
    }
  };

  const getTotalPriceOf = name => {
    switch (name) {
      case 'fire':
        return coverages.home && fireCard !== undefined
          ? 'discount_premium' in fireCard.details.quote &&
            'promotion_premium' in fireCard.details.quote
            ? fireCard.details.quote.total_premium -
              fireCard.details.quote.discount_premium.discount -
              fireCard.details.quote.promotion_premium.promotion
            : 'discount_premium' in fireCard.details.quote
            ? fireCard.details.quote.discount_premium.total_premium
            : 'promotion_premium' in fireCard.details.quote
            ? fireCard.details.quote.promotion_premium.total_premium
            : fireCard.details.quote.total_premium
          : 0;
      case 'family':
        return coverages.family && peopleCard !== undefined
          ? 'discount_premium' in peopleCard.details.quote &&
            'promotion_premium' in peopleCard.details.quote
            ? peopleCard.details.quote.total_premium -
              peopleCard.details.quote.discount_premium.discount -
              peopleCard.details.quote.promotion_premium.promotion
            : 'discount_premium' in peopleCard.details.quote
            ? peopleCard.details.quote.discount_premium.total_premium
            : 'promotion_premium' in peopleCard.details.quote
            ? peopleCard.details.quote.promotion_premium.total_premium
            : peopleCard.details.quote.total_premium
          : 0;

      case 'car':
        return coverages.car && carCard !== undefined
          ? 'discount_premium' in carCard.details.quote &&
            'promotion_premium' in carCard.details.quote
            ? carCard.details.quote.total_premium -
              carCard.details.quote.discount_premium.discount -
              carCard.details.quote.promotion_premium.promotion
            : 'discount_premium' in carCard.details.quote
            ? carCard.details.quote.discount_premium.total_premium
            : 'promotion_premium' in carCard.details.quote
            ? carCard.details.quote.promotion_premium.total_premium
            : carCard.details.quote.total_premium
          : 0;
      case 'legal':
        return coverages.legal && legalCard !== undefined
          ? 'discount_premium' in legalCard.details.quote &&
            'promotion_premium' in legalCard.details.quote
            ? legalCard.details.quote.total_premium -
              legalCard.details.quote.discount_premium.discount -
              legalCard.details.quote.promotion_premium.promotion
            : 'discount_premium' in legalCard.details.quote
            ? legalCard.details.quote.discount_premium.total_premium
            : 'promotion_premium' in legalCard.details.quote
            ? legalCard.details.quote.promotion_premium.total_premium
            : legalCard.details.quote.total_premium
          : 0;
      default:
    }
  };

  const getLowestInterval = () => {
    let monthly = 0;
    let quarterly = 0;
    let half_annual = 0;
    let annual = 0;
    Object.keys(intervalTexts).forEach(key => {
      switch (intervalTexts[key]) {
        case t('Checkout.monthly'):
          monthly++;
          break;
        case t('Checkout.quarterly'):
          quarterly++;
          break;
        case t('Checkout.half_annual'):
          half_annual++;
          break;
        case t('Checkout.year'):
          annual++;
          break;
        default:
      }
    });

    if (monthly > 0) {
      return t('Checkout.monthly');
    }
    if (quarterly > 0) {
      return t('Checkout.quarterly');
    }
    if (half_annual > 0) {
      return t('Checkout.half_annual');
    }
    if (annual > 0) {
      return t('Checkout.year');
    }
    // Default return
    return t('Checkout.year');
  };

  const priceToLowestInterval = (interval, price) => {
    if (!interval) interval = t('Checkout.year');
    if (!price) {
      return 0;
    }
    if (interval === getLowestInterval()) {
      return price;
    }

    let monthlyPrice;

    switch (interval) {
      case t('Checkout.monthly'):
        monthlyPrice = price;
        break;
      case t('Checkout.quarterly'):
        monthlyPrice = price / 3;
        break;
      case t('Checkout.half_annual'):
        monthlyPrice = price / 6;
        break;
      case t('Checkout.year'):
        monthlyPrice = price / 12;
        break;
      default:
    }

    switch (getLowestInterval()) {
      case t('Checkout.monthly'):
        return monthlyPrice;
      case t('Checkout.quarterly'):
        return monthlyPrice * 3;
      case t('Checkout.half_annual'):
        return monthlyPrice * 6;
      case t('Checkout.year'):
        return monthlyPrice * 12;
      default:
    }
  };

  return (
    <Layout>
      {showTrial ? (
        <TrialPopupOnBrokerLoginScreen
          close={bool => setShowTrial(bool)}
          startScreen="cantUseCheckout"
        />
      ) : null}
      <Header>
        <div>
          <h1>{t('Checkout.title')}</h1>
        </div>
      </Header>
      <Container>
        {coverages.home ? (
          <CheckoutItem
            setRequirements={value => setRequirementsByName('home', value)}
            requirements={requirements}
            getErrors={errors => {
              const tempErrors = { ...contractErrors };
              tempErrors.home = errors;
              setContractErrors(tempErrors);
            }}
            allInsurers={allInsurers}
            addInsuranceDate={(year, month, day, type) =>
              changeStartDate(
                parseInt(year),
                parseInt(month),
                parseInt(day),
                type,
              )
            }
            id={1}
            type="home"
            clicked={() => handleClick()}
            card={fireCard}
            priceData={firePriceData}
            onPriceChange={string => handleIntervalText('fire', string)}
            pdfs={fireCard !== undefined ? fireCard.pdfs : null}
            oldInsurance={oldFireInsurance}
            allData={homeData}
            dataIsSet={() => setPreviousInsuranceData()}
          >
            {getFireName()}
          </CheckoutItem>
        ) : null}
        {coverages.family ? (
          <CheckoutItem
            getErrors={errors => {
              const tempErrors = { ...contractErrors };
              tempErrors.family = errors;
              setContractErrors(tempErrors);
            }}
            setRequirements={value => setRequirementsByName('family', value)}
            requirements={requirements}
            allInsurers={allInsurers}
            addInsuranceDate={(year, month, day, type) =>
              changeStartDate(
                parseInt(year),
                parseInt(month),
                parseInt(day),
                type,
              )
            }
            id={2}
            type="family"
            clicked={() => handleClick()}
            card={peopleCard}
            priceData={getCheckoutPriceData('people')}
            onPriceChange={string => handleIntervalText('people', string)}
            pdfs={peopleCard !== undefined ? peopleCard.pdfs : null}
            oldInsurance={oldPeopleInsurance}
            allData={familyData}
            dataIsSet={() => setPreviousInsuranceData()}
          >
            {t('Checkout.insuranceNames.family')}
          </CheckoutItem>
        ) : null}
        {coverages.car ? (
          <CheckoutItem
            getErrors={errors => {
              const tempErrors = { ...contractErrors };
              tempErrors.car = errors;
              setContractErrors(tempErrors);
            }}
            setRequirements={value => setRequirementsByName('car', value)}
            requirements={requirements}
            allInsurers={allInsurers}
            addInsuranceDate={(year, month, day, type) =>
              changeStartDate(
                parseInt(year),
                parseInt(month),
                parseInt(day),
                type,
              )
            }
            id={3}
            type="car"
            clicked={() => handleClick()}
            card={carCard}
            priceData={getCheckoutPriceData('car')}
            onPriceChange={string => handleIntervalText('car', string)}
            pdfs={carCard !== undefined ? carCard.pdfs : null}
            oldInsurance={oldCarInsurance}
            allData={carData}
            dataIsSet={() => setPreviousInsuranceData()}
          >
            {t('Checkout.insuranceNames.car')}
          </CheckoutItem>
        ) : null}
        {coverages.legal ? (
          <CheckoutItem
            getErrors={errors => {
              const tempErrors = { ...contractErrors };
              tempErrors.legal = errors;
              setContractErrors(tempErrors);
            }}
            setRequirements={value => setRequirementsByName('legal', value)}
            requirements={requirements}
            allInsurers={allInsurers}
            addInsuranceDate={(year, month, day, type) =>
              changeStartDate(
                parseInt(year),
                parseInt(month),
                parseInt(day),
                type,
              )
            }
            id={4}
            type="legal"
            clicked={() => handleClick()}
            card={legalCard}
            priceData={getCheckoutPriceData('legal')}
            onPriceChange={string => handleIntervalText('legal', string)}
            pdfs={legalCard !== undefined ? legalCard.pdfs : null}
            oldInsurance={oldLegalInsurance}
            allData={legalData}
            dataIsSet={() => setPreviousInsuranceData()}
          >
            {t('legal aid')}
          </CheckoutItem>
        ) : null}
      </Container>
      <div>
        <FinishContainer>
          <Formik
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ errors, touched, values }) => {
              const disabledForQuestions = disableHandler(
                errors,
                touched,
                isValid,
                values,
              );
              const handleChange = e => {
                setAcknowledgements({
                  ...acknowledgements,
                  [e.value.key]: {
                    key: e.value.key,
                    text: e.name,
                    agreed: e.value.agreed,
                  },
                });
              };
              return (
                <FormWrapper>
                  <Confirmations>
                    {(acceptances?.car || []).length > 0 && carCard ? (
                      <>
                        <Title>
                          {t(`Questions in regards to the main driver`)}
                        </Title>
                        {acceptances.car.map((item, i) =>
                          item.key !== 'had_claims_last_five_years' ? (
                            <AcknowledgementCheckbox
                              itemKey={item.key}
                              key={i}
                              name={item.short}
                              checked={values[item.key]}
                              onChange={handleChange}
                              dataTestId={`checkout_checkbox_${i}`}
                            >
                              {item.short}
                            </AcknowledgementCheckbox>
                          ) : null,
                        )}
                      </>
                    ) : null}
                    <Title>{t(`General questions`)}</Title>
                    {acceptances.general.length > 0
                      ? acceptances.general.map((item, i) => (
                          <AcknowledgementCheckbox
                            itemKey={item.key}
                            key={i}
                            name={item?.long ? item.long : item.short}
                            checked={values[item.key]}
                            onChange={handleChange}
                            dataTestId={`checkout_checkbox_${i}`}
                          >
                            {item?.long ? item.long : item.short}
                          </AcknowledgementCheckbox>
                        ))
                      : null}
                  </Confirmations>
                  <Total>
                    <TotalTable>
                      {coverages.home &&
                      fireCard !== undefined &&
                      fireCard &&
                      fireCard !== null ? (
                        <TotalRow>
                          {/* verzekering */}
                          <p className="price-step">{getFireName()}</p>
                          <p className="price price-step">
                            € {getTotalPriceOf('fire').toFixed(2)} /
                            {intervalTexts.fire
                              ? intervalTexts.fire
                              : t('Checkout.year')}
                          </p>
                        </TotalRow>
                      ) : null}
                      {coverages.family &&
                      peopleCard !== undefined &&
                      peopleCard &&
                      peopleCard !== null ? (
                        <TotalRow>
                          {/* makelaar */}
                          <p className="price-step">
                            {t('Checkout.insuranceNames.family')}
                          </p>
                          <p className="price price-step">
                            €{getTotalPriceOf('family').toFixed(2)} /
                            {intervalTexts.people
                              ? intervalTexts.people
                              : t('Checkout.year')}
                          </p>
                        </TotalRow>
                      ) : null}
                      {coverages.car &&
                      carCard !== undefined &&
                      carCard &&
                      carCard !== null ? (
                        <TotalRow>
                          {/* auto */}
                          <p className="price-step">
                            {t('Checkout.insuranceNames.car')}
                          </p>
                          <p className="price price-step">
                            €{getTotalPriceOf('car').toFixed(2)} /
                            {intervalTexts.car
                              ? intervalTexts.car
                              : t('Checkout.year')}
                          </p>
                        </TotalRow>
                      ) : null}
                      {coverages.legal &&
                      legalCard !== undefined &&
                      legalCard &&
                      legalCard !== null ? (
                        <TotalRow>
                          {/* auto */}
                          <p className="price-step">{t('legal aid')}</p>
                          <p className="price price-step">
                            €{getTotalPriceOf('legal').toFixed(2)} /
                            {intervalTexts.legal
                              ? intervalTexts.legal
                              : t('Checkout.year')}
                          </p>
                        </TotalRow>
                      ) : null}
                      {/* totaal */}
                      <TotalRow>
                        <p className="total-premium">{t('Checkout.total')}</p>
                        <p className="price total-premium">
                          €{' '}
                          {(
                            priceToLowestInterval(
                              intervalTexts.fire,
                              getTotalPriceOf('fire'),
                            ) +
                            priceToLowestInterval(
                              intervalTexts.people,
                              getTotalPriceOf('family'),
                            ) +
                            priceToLowestInterval(
                              intervalTexts.car,
                              getTotalPriceOf('car'),
                            ) +
                            priceToLowestInterval(
                              intervalTexts.legal,
                              getTotalPriceOf('legal'),
                            )
                          ).toFixed(2) + ' '}
                          /{getLowestInterval()}
                        </p>
                      </TotalRow>
                    </TotalTable>
                    <StyledAlert icon>
                      {t(
                        `Please answer all questions on the left before continuing`,
                      )}
                    </StyledAlert>
                    <AddToCart
                      dataTestId="underwriting_underwrite"
                      typeButton={1}
                      disabled={disabled || disabledForQuestions || datesValid}
                    >
                      <span>{btnText}</span>
                      {sendStep === 'pending' ? (
                        <SpinnerContainer>
                          <CircleSpinner position="static" />
                        </SpinnerContainer>
                      ) : null}
                    </AddToCart>
                  </Total>
                </FormWrapper>
              );
            }}
          </Formik>
        </FinishContainer>
      </div>
    </Layout>
  );
};

const Header = styled.header`
  color: white;
  font-size: 1.6rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  line-height: 2rem;

  & h1 {
    font-weight: 900;
    font-size: 2.5rem;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  & div {
    width: 65%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 60vh;
  overflow: hidden;

  & article + article {
    margin-top: 3rem;
  }

  .checkout-spinner {
    positon: relative;
  }
`;

const FinishContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  justify-content: center;
  background-color: ${({ theme }) => theme.ui.background};
  position: absolute;
  left: 0;
  margin-top: 10rem;

  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 1.6rem;
  margin: 2rem 0;
`;

const FormWrapper = styled(Form)`
  width: 100%;
  max-width: 120rem;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.ui.background};
`;

const Confirmations = styled.div`
  display: block;
  flex-basis: 50%;
  padding-bottom: 2rem;
  margin-left: 2rem;
  padding-right: 2rem;
`;

const Total = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5rem 12% 10rem 6.5rem;

  @media screen and (max-width: 750px) {
    flex: 0 0 100%;
  }

  & .price {
    text-align: right;
    font-weight: bold;
  }

  & button {
    align-self: flex-end;
  }
`;

const TotalTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 3rem;
`;

const TotalRow = styled.div`
  display: flex;
  justify-content: space-between;
  color: #a29c95;

  &:last-child {
    margin-bottom: 0;
    border-top: 0.2rem solid #a29c95;
    padding-top: 3rem;
    font-weight: bold;
    color: black;
    font-size: 2rem;
  }

  & p {
    margin-bottom: 1.5rem;

    .price-step {
      color: #797979;
      font-weight: normal;
      font-size: 1.8rem;
    }

    .total-premium {
      font-size: 2rem;
    }
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 2rem;
`;

const SpinnerContainer = styled.span`
  margin-left: 1rem;
`;

Checkout.propTypes = {
  history: shape(),
};

export default withRouter(Checkout);
