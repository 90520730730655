import React from 'react';

const Children = () => (
  <svg viewBox="0 0 195.86 207.55">
    <defs>
      <style>
        {
          '.prefix__cls-1,.prefix__cls-2{fill:#fff}.prefix__cls-2{fill-rule:evenodd}'
        }
      </style>
    </defs>
    <title>{'children'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <circle className="prefix__cls-1" cx={45.97} cy={189.01} r={18.54} />
        <circle className="prefix__cls-1" cx={125.05} cy={189.01} r={18.54} />
        <path
          className="prefix__cls-1"
          d="M5 82h161a5 5 0 015 5v31.49a40 40 0 01-40 40H40a40 40 0 01-40-40V87a5 5 0 015-5z"
        />
        <path
          className="prefix__cls-2"
          d="M55.93 70.16H2a2 2 0 01-2-2.06 69.77 69.77 0 0112.89-38.48 2 2 0 012.92-.3l41.45 37.39a2 2 0 01-1.33 3.45zM70.19 2v56a2 2 0 01-3.3 1.47L23.73 20.49a2 2 0 010-3A70 70 0 0168.13 0a2 2 0 012.06 2z"
        />
        <path
          className="prefix__cls-1"
          d="M165.22 70.16a5.94 5.94 0 01-5.94-5.93V43.47a5.93 5.93 0 015.94-5.93h24.71a5.93 5.93 0 110 11.86h-18.78v14.83a5.94 5.94 0 01-5.93 5.93z"
        />
      </g>
    </g>
  </svg>
);

export default Children;
