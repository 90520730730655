import React from 'react';
import { SelectOffersAccordionBox } from 'wg-fe-ui';
import AccordionHeader from './CampaignAccordionHeader';
import AccordionContent from './CampaignAccordionContent';
import styled from 'styled-components';
import { func, string } from 'prop-types';

const CampaignsAccordionBox = ({ id, setAddNewCampaignModalOpen }) => {
  return (
    <Container>
      <SelectOffersAccordionBox>
        <SelectOffersAccordionBox.Header>
          <AccordionHeader id={id} />
        </SelectOffersAccordionBox.Header>
        <SelectOffersAccordionBox.Content>
          <Line />
          <AccordionContent
            setAddNewCampaignModalOpen={setAddNewCampaignModalOpen}
            id={id}
          />
        </SelectOffersAccordionBox.Content>
      </SelectOffersAccordionBox>
    </Container>
  );
};

const Line = styled.p`
  width: 100%;
  height: 0.1rem;
  background-color: #ddd;
`;

const Container = styled.div`
  margin-top: 2rem;
  border-radius: 12px;
  > div {
    /* stylelint-disable-next-line */
    max-width: none !important;
  }
  overflow-x: scroll;
`;

CampaignsAccordionBox.propTypes = {
  id: string.isRequired,
  setAddNewCampaignModalOpen: func.isRequired,
};

export default CampaignsAccordionBox;
