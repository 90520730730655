import React from 'react';

const Disabilities = () => (
  <svg viewBox="0 0 147 194.12">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'disabilitiesmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M142 153.49a9.17 9.17 0 01-4.45 1.13 11.28 11.28 0 01-9.69-6l-16.04-29.42a10.16 10.16 0 00-8.92-5.29H60a5.7 5.7 0 01-.82 0h-.41a14 14 0 01-14.5-13.09L41 51.53a14 14 0 0113.1-14.92l2.9-.17a14 14 0 0114.92 13l.86 13.6h29.88c3.82 0 6.91 3.45 6.91 7.68s-3.09 7.69-6.91 7.69H73.77l.73 11h35.75a12.12 12.12 0 013.87.63 11.37 11.37 0 017.88 5.82l23.55 43.07c2.89 5.37 1.35 11.87-3.55 14.56z"
        />
        <circle className="prefix__cls-1" cx={55.74} cy={16.29} r={16.29} />
        <path
          className="prefix__cls-1"
          d="M65.81 89.82l.03.39M65.59 194.12a65.56 65.56 0 01-46.7-111.61A7.9 7.9 0 0130.13 93.6a49.77 49.77 0 1075.54 64.4 7.89 7.89 0 0112.71 9.36 65.84 65.84 0 01-52.79 26.76z"
        />
      </g>
    </g>
  </svg>
);

export default Disabilities;
