import React from 'react';
import { shape, number, string } from 'prop-types';
import styled from 'styled-components';

const BaIcon = ({ colors, height, title }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 306.66 219.65"
    height={height}
  >
    <title>{title}</title>
    <g id="icons">
      <path
        fill={colors}
        d="M136.07,100.29a8,8,0,0,1-5.45-2.87L107.79,69.86a8.05,8.05,0,0,1,7-13.15l44.95,4.22L166.78,7A8.06,8.06,0,0,1,181.9,4.32l22.63,43.17,38.81-8.36a8.05,8.05,0,0,1,8.4,12.33L232.91,79.73a8.05,8.05,0,1,1-13.4-8.93l7.83-11.75-25.41,5.47a8.05,8.05,0,0,1-8.83-4.13L179.44,34.31l-4.79,36.41a8.06,8.06,0,0,1-8.74,7l-33.32-3.14L143,87.14A8,8,0,0,1,142,98.48,8,8,0,0,1,136.07,100.29Z"
      />
      <path
        fill={colors}
        d="M197.67,138A39.47,39.47,0,0,0,187,130.16c-6.83-3.62-15.41-6.48-24.83-8.76-3.43-.83-7-1.58-10.6-2.25-31.23-5.9-67.54-6.49-80.85-6.49-3.34,0-5.23,0-5.23,0L15.07,79.93Q7.42,79.18,0,78.81V187.08H80.23a40.93,40.93,0,0,0,80.13,0H180A13.92,13.92,0,0,0,193.35,177l7.27-25.67A13.93,13.93,0,0,0,197.67,138Zm-51.83,49.11a26.85,26.85,0,0,1-51.07,0,25.73,25.73,0,0,1-1.35-8.35,26.87,26.87,0,0,1,53.74,0A26.54,26.54,0,0,1,145.84,187.08Z"
      />
      <path
        fill={colors}
        d="M201.76,121a47.26,47.26,0,0,1,7.31,6.37,29.62,29.62,0,0,1,6.52,28.15l-7.29,25.73a28.9,28.9,0,0,1-2.38,5.82H216.2a39.77,39.77,0,0,0,77.86,0h12.6V84.41A294.35,294.35,0,0,0,201.76,121Zm78.17,66.07a26.11,26.11,0,0,1-49.63,0A25.68,25.68,0,0,1,229,179a26.13,26.13,0,0,1,52.25,0A25.68,25.68,0,0,1,279.93,187.08Z"
      />
    </g>
  </Svg>
);

const Svg = styled.svg`
  margin-left: -0.2rem;
  position: absolute;
  align-self: center;
`;

BaIcon.propTypes = {
  colors: shape(),
  height: number,
  title: string,
};

export default BaIcon;
