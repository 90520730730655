import React from 'react';
import { shape } from 'prop-types';

const MarketIconPeople = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 56.59 51.22"
  >
    <title>Family</title>
    <g id="People">
      <path
        style={{
          fill: `${colors.brand.primary}`,
          stroke: colors.brand.dark,
          strokeWidth: 2.5,
          strokeLineJoin: `round`,
        }}
        d="M30.65,45.84a6.11,6.11,0,0,1-4.77-2.09,3.91,3.91,0,0,1-.71-3.45A17.34,17.34,0,0,1,32.24,30L34,28.75l-1.91-1a12,12,0,0,1-6.3-8.5c-1.22-6.35,2.63-12.44,8.57-13.58a10.54,10.54,0,0,1,1.94-.19,11.51,11.51,0,0,1,11,9.64,11.92,11.92,0,0,1-2.91,10.45L43,27.17l2.07.51A17.42,17.42,0,0,1,55,34.59a3.9,3.9,0,0,1,.49,3.87,6.52,6.52,0,0,1-4.83,3.68L32,45.71a7.62,7.62,0,0,1-1.35.13Z"
        transform="translate(-0.44 -4.27)"
      />
      <path
        style={{
          fill: `${colors.brand.primary}`,
          stroke: colors.brand.dark,
          strokeWidth: 2.5,
          strokeLineJoin: `round`,
        }}
        d="M7.48,54.24a6.27,6.27,0,0,1-4.79-2,4.33,4.33,0,0,1-.93-3.6A20.8,20.8,0,0,1,10.42,35l1.77-1.2-1.91-1a14.31,14.31,0,0,1-7.5-10.12C1.33,15.13,5.91,7.88,13,6.52a13,13,0,0,1,2.33-.22c6.34,0,11.86,4.83,13.14,11.48A14.22,14.22,0,0,1,25,30.21l-1.46,1.56,2.07.51a21,21,0,0,1,12.56,9.35,4.28,4.28,0,0,1,.29,4.12,6.6,6.6,0,0,1-4.84,3.62L8.84,54.11a7.83,7.83,0,0,1-1.36.13Z"
        transform="translate(-0.44 -4.27)"
      />
      <path
        style={{
          fill: `${colors.brand.primary}`,
          stroke: colors.brand.dark,
          strokeWidth: 2.5,
          strokeLineJoin: `round`,
        }}
        d="M28.21,54a5.76,5.76,0,0,1-4.73-2.15,3.22,3.22,0,0,1-.29-3.15,12.77,12.77,0,0,1,4.67-6l1.75-1.19-1.88-1a9.18,9.18,0,0,1-3.47-12.84,7.66,7.66,0,0,1,5.07-3.53A7.41,7.41,0,0,1,30.74,24a8.51,8.51,0,0,1,8.1,7.17A8.91,8.91,0,0,1,36.72,39L35.28,40.5l2,.52a12.83,12.83,0,0,1,6.22,3.75,3.26,3.26,0,0,1,.8,3.38,6.28,6.28,0,0,1-4.77,3.77L29.52,53.85a7.17,7.17,0,0,1-1.31.12Z"
        transform="translate(-0.44 -4.27)"
      />
    </g>
  </svg>
);

MarketIconPeople.propTypes = {
  colors: shape().isRequired,
};

export default MarketIconPeople;
