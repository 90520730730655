import React, { useEffect, useContext, useState, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { shape } from 'prop-types';
import {
  SubNavigation,
  BorderedTitle,
  Text,
  OrderTable,
  Label,
  RiskObjectLabel,
  PhaseLabel,
  LoadingSpinner,
  IconHeavyScooter,
  IconCarFilled,
  IconStats,
  IconFamilyFilled,
  IconPropertyFilled,
  IconLegalFilled,
} from 'wg-fe-ui';
import {
  getBrokersByDistributionId,
  getContractRevisions,
} from '../../services/apiRouterService';
import { getBrokerDistribution } from '../../services/brokerDataService';
import { useTranslation } from 'react-i18next';
import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';
import { useLocation } from 'react-router';

const CRMContractRevisions = ({ match }) => {
  const refs = {};
  const { setNavTabs } = useContext(TopNavigationItems);
  const { t } = useTranslation();
  let history = useHistory();
  const location = useLocation();

  const [retrievedData, setRetrievedData] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  const [sections, setSections] = useState([
    {
      id: 1,
      label: `${t('Contracts and addendums')}`,
      subsections: [
        {
          id: 1,
          label: t('Contract'),
          type: 'CONTRACT',
        },
        {
          id: 2,
          label: t('Addendums'),
          type: 'ADDENDUM',
        },
      ],
    },
  ]);

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const revisionClickHandler = (e, contractCaseId, contractId) => {
    e.preventDefault();

    history.push(
      match.params.prospectId
        ? `/sales-management/customer/${match.params.prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`
        : `/sales-management/case/${contractCaseId}/contract/${contractId}`,
    );
  };

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  useEffect(() => {
    getRevisions(match.params.caseId);

    setNavTabs(
      setTabItems(
        match.params.caseId,
        t,
        match?.params?.prospectId ? 'contractDetailCustomer' : 'contractDetail',
        null,
        match.params.contractId,
        { ...location.state },
        match?.params?.prospectId,
      ),
    );
  }, [match.params.caseId]);

  async function getRevisions(contractCaseId) {
    const { id } = await getBrokerDistribution();
    let [response, status] = await getContractRevisions(id, contractCaseId);

    if (status !== 200) {
      return console.error(response);
    }

    const sortedItems = response.items.sort((a, b) => {
      var d1 = new Date(a.created_at);
      var d2 = new Date(b.created_at);
      if (d1 > d2) return -1;
      if (d1 < d2) return 1;
      return 0;
    });

    setRetrievedData(sortedItems);
    retrieveBrokersFromDistribution(id);
    setIsLoading(!isLoading);
  }

  const retrieveBrokersFromDistribution = async id => {
    const [resp, status] = await getBrokersByDistributionId(id);
    console.log(resp, status, brokers);
    setBrokers(resp.items.filter(item => item?.broker?.plan !== 'CALLANT'));
  };

  useEffect(() => {
    setSections([
      {
        id: 1,
        label: `${t('Contracts and addendums')}`,
        subsections: [
          {
            id: 1,
            label: t('Contract'),
            type: 'CONTRACT',
          },
          {
            id: 2,
            label: t('Addendums'),
            type: 'ADDENDUM',
          },
        ],
      },
    ]);
  }, [retrievedData]);

  const getRiskObjectIcon = key => {
    switch (key) {
      case 'cars':
        return <IconCarFilled />;
      case 'families':
        return <IconFamilyFilled />;
      case 'residences':
        return <IconPropertyFilled />;
      case 'teachers':
        return <IconStats />;
      case 'two_wheelers':
        return <IconHeavyScooter />;
      case 'legal':
        return <IconLegalFilled />;
      default:
        return '';
    }
  };

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  const formatDateWithoutTime = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear();
    return formattedDate;
  };

  const checkValuesInObjectIsZero = object => {
    if (object != null || undefined) {
      return Object.keys(object).every(function(key) {
        return object[key] === 0;
      });
    }
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>{/* <BackButton name="Prospects" /> */}</TopBarLeft>

          <TopBarRight>
            <TopBarAction></TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
        </Sidebar>

        <Content>
          {sections[0].subsections.map(subsection => (
            <Section ref={refs[1][1]} key={subsection.id}>
              <BorderedTitle>{subsection.label}</BorderedTitle>
              {retrievedData.length > 0 ? (
                <OrderTable>
                  <OrderTable.Head otherProps={{}}>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {`${subsection.label} nr`}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {t('Created on')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {subsection.type === 'ADDENDUM'
                        ? t('Addendum commencement date')
                        : t('Commencement date')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="15%">
                      {t('Created by')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="15%">
                      {t('Risk objects')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="15%">
                      {t('Status')}
                    </OrderTable.HeaderCell>
                  </OrderTable.Head>
                  <OrderTable.Body>
                    {retrievedData.map(contract => {
                      return contract?.type === subsection.type ? (
                        <OrderTable.Row
                          onClick={e =>
                            revisionClickHandler(
                              e,
                              contract?.contract_case_id,
                              contract?.id,
                            )
                          }
                          key={contract?.id}
                        >
                          <OrderTable.BodyCell>
                            <Label>
                              {contract?.type === 'CONTRACT'
                                ? contract?.policy_nr?.split(`/`)?.[0]
                                : contract?.revision}
                            </Label>
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            {formatDate(contract?.created_at)}
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            {subsection.type === 'ADDENDUM'
                              ? formatDateWithoutTime(
                                  contract?.addendum_commencement_date,
                                )
                              : formatDateWithoutTime(
                                  contract?.commencement_date,
                                )}
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            {contract?.created_by
                              ? brokers?.length > 0
                                ? brokers?.find(
                                    item =>
                                      item?.broker?.id === contract?.created_by,
                                  ).broker.name
                                : t('loading')
                              : ''}
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            <RiskObjectsWrapper>
                              {!checkValuesInObjectIsZero(
                                contract?.amount_of_risk_objects,
                              )
                                ? Object.keys(
                                    contract?.amount_of_risk_objects,
                                  ).map(key => {
                                    if (
                                      contract?.amount_of_risk_objects[key] ===
                                      0
                                    )
                                      return null;

                                    return (
                                      <RiskObjectLabel
                                        key={key}
                                        amount={
                                          contract?.amount_of_risk_objects[key]
                                        }
                                        icon={getRiskObjectIcon(key)}
                                      />
                                    );
                                  })
                                : '-'}
                            </RiskObjectsWrapper>
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            <PhaseLabel
                              otherProps={{}}
                              status={contract?.status}
                              text={t(`contracts.${contract?.status}`)}
                            />
                          </OrderTable.BodyCell>
                        </OrderTable.Row>
                      ) : (
                        ''
                      );
                    })}
                  </OrderTable.Body>
                </OrderTable>
              ) : (
                <TextCenter>
                  {t('There are no revisions for this offer')}
                </TextCenter>
              )}
            </Section>
          ))}
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 99999;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  visibility: hidden;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
  visibility: hidden;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 100rem;
`;

const TextCenter = styled(Text)`
  text-align: center;
`;

const RiskObjectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex: 0 0 auto;
    margin-right: 15px;
  }
`;

CRMContractRevisions.propTypes = {
  match: shape(),
};

export default CRMContractRevisions;
