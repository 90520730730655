import React from 'react';
import { useParams } from 'react-router';

import CRMContractLeadsOverview from './CRMContractLeadsOverview';
import CRMOffersOverview from './CRMOffersOverview';
import CRMContractsOverview from './CRMContractsOverview';

const CRMContractTypes = match => {
  const { type } = useParams();

  switch (type) {
    case 'leads':
      return <CRMContractLeadsOverview match={match} />;
    case 'offers':
      return <CRMOffersOverview match={match} />;
    case 'contracts':
      return <CRMContractsOverview match={match} />;
    default:
      break;
  }
};
export default CRMContractTypes;
