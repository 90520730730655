import React from 'react';

const Bicycle = () => (
  <svg viewBox="0 0 262.46 139.23">
    <title>{'bicyclematrix_family_'}</title>
    <path
      d="M210.17 34.66a51.16 51.16 0 00-22.07 5.13L171 12.36h9.06a6.18 6.18 0 100-12.36h-20.17a6.16 6.16 0 00-5.24 9.43l5.07 8.14H95l-3.41-5.48h6a5.92 5.92 0 100-11.84H68.53a5.92 5.92 0 000 11.84h9.16l7.08 11.4-10.2 16.41a51 51 0 00-22.29-5.24 52.29 52.29 0 000 104.57C78.63 139.23 101 119 104 92.87h27.2a5.84 5.84 0 002.94-.81 2.75 2.75 0 00.77-.65l.17-.14a4.59 4.59 0 001.15-1.2l34.29-55.15 7.08 11.37a51.72 51.72 0 00-19.71 40.65 52.29 52.29 0 1052.28-52.28zM90 81H62.93l14.33-23A37.87 37.87 0 0190 81zm30.51 0H104a52.11 52.11 0 00-19.36-34.89l7.1-11.42zm-68.23 44.36a38.42 38.42 0 010-76.84 37.33 37.33 0 0114.94 3.2l-20 32.11a5.93 5.93 0 005 9H90a38.17 38.17 0 01-37.72 32.53zM160 29.41l-28.8 46.33-28.81-46.33zm53.4 62.78a6.13 6.13 0 002-8.5l-20-32.08a37.39 37.39 0 0114.72-3.09 38.42 38.42 0 11-38.42 38.42A38 38 0 01185 58.17l19.94 32a6.17 6.17 0 005.24 2.94 6.07 6.07 0 003.24-.92z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default Bicycle;
