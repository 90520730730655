/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { shape, func, string } from 'prop-types';
import useForm from '../hooks/useForm';
import styled from 'styled-components';
import { object } from 'yup';
import { string as yupString, number } from '../../constants/validationSchemas';

import {
  DashedButton,
  RoundedButton,
  IconActionPlus,
  TextInput,
  ActionButton,
  IconShedFilled,
  RiskObjectDisplay,
  IconActionClose,
  DataBlock,
  H4,
  Body,
  IconPorch,
  IconOthersFilled,
  IconGarageFilled,
  SearchSelectInput,
  TertiaryButton,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';

const ProFlowAnnexesInput = ({
  annexesData,
  setAnnexesData,
  onChange,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const [addAnnexVisibe, setAddAnnexVisible] = useState(false);

  const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
    validationSchema: object().shape({
      annexBuildingType: yupString.required,
      groundSurfaceVal: number.required,
    }),
  });

  function handleIcons(type) {
    switch (type) {
      case 'garden_shed':
        return <IconShedFilled color="#CCCCCC" />;
      case 'carport':
        return <IconGarageFilled color="#CCCCCC" />;
      case 'gazebo':
        return <IconPorch color="#CCCCCC" />;
      case 'other':
        return <IconOthersFilled color="#fff" />;
      default:
        return <IconOthersFilled color="#fff" />;
    }
  }

  function removeAnnex(annedId) {
    onChange(annexesData.filter(element => element.annexId !== annedId));
  }

  function handleAddAnnex() {
    setAddAnnexVisible(true);
  }

  const annexTypes = [
    { value: 'GARDEN_SHED', label: t('GARDEN_SHED') },
    { value: 'CARPORT', label: t('CARPORT') },
    { value: 'GAZEBO', label: t('GAZEBO') },
    { value: 'OTHER', label: t('OTHER') },
  ];

  function handleAddAnnexValues() {
    const payload = {
      type: values['annexBuildingType'],
      surface: parseInt(values['groundSurfaceVal']),
    };
    onChange([...annexesData, payload]);
    resetForm();
  }

  return (
    <AnnexesInputContainer>
      {annexesData?.map(annex => {
        return (
          <RiskObjectDisplay
            key={annex.id}
            hoverButton={
              <RoundedButton
                icon={<IconActionClose color="black" size={40} />}
                iconType="fill"
                size="large"
                type="danger"
              />
            }
            hoverContent={t('Remove annex')}
            icon={handleIcons(annex.type)}
            onClick={() => removeAnnex(annex.annexId)}
            title={t(annex.type.toUpperCase())}
            data-test-id={`${dataTestId}_remove`}
          >
            <DataBlock>
              <Body light smaller>
                {t('Surface')} m<sup>2</sup>
              </Body>
              <H4 larger>{t(annex.surface)}</H4>
            </DataBlock>
          </RiskObjectDisplay>
        );
      })}
      {addAnnexVisibe && (
        <AddAnnexContainer>
          <TopContainer>
            {values['annexBuildingType'] ? (
              handleIcons(values['annexBuildingType']?.toLowerCase())
            ) : (
              <IconOthersFilled color="#fff" />
            )}
            <StyledAnnexSearchSelectInput
              isClearable
              name="annexBuildingType"
              noOptionsMessage={() => 'Geen opties'}
              onSelected={handleChange}
              options={annexTypes}
              placeholder={t('Annex type')}
              data-test-id={`${dataTestId}_building_type`}
              error={errors['annexBuildingType']}
            />
          </TopContainer>
          <BottomContainer>
            <GroundSurfaceInput
              name="groundSurfaceVal"
              onChange={handleChange}
              title={t('Ground surface area')}
              type="number"
              data-test-id={`${dataTestId}_ground_surface`}
              error={errors['groundSurfaceVal']}
            >
              <h3>{t('Ground surface area')}</h3>
              <span>
                m<sup>2</sup>
              </span>
            </GroundSurfaceInput>
            <ActionButtons>
              <ActionButton
                onClick={() =>
                  handleSubmit(() => {
                    setAddAnnexVisible(false);
                    handleAddAnnexValues();
                  })
                }
                data-test-id={`${dataTestId}_save`}
                fullwidth
              >
                {t('Save annex')}
              </ActionButton>
              <TertiaryButton
                onClick={() => {
                  resetForm();
                  setAddAnnexVisible(false);
                }}
                label={t('Cancel')}
              />
            </ActionButtons>
          </BottomContainer>
        </AddAnnexContainer>
      )}
      <DashedButton
        button={
          <RoundedButton
            icon={<IconActionPlus color="black" size={35} />}
            iconType="fill"
            size="large"
            type="primary"
          />
        }
        label={t('Add annex')}
        onClick={() => handleAddAnnex()}
        styleName="normal"
        data-test-id={`${dataTestId}_add`}
      />
    </AnnexesInputContainer>
  );
};

const ActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button:first-child {
    margin-bottom: 1rem;
  }
`;

const StyledAnnexSearchSelectInput = styled(SearchSelectInput)`
  min-height: unset;
  label {
    > div {
      margin: 0;
    }
  }
  > div {
    margin-bottom: 0;
  }
`;

const GroundSurfaceInput = styled(TextInput)`
  margin-bottom: 1rem;

  h3 {
    color: #8990a3;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.01em;
  }
  label {
    position: relative;
    svg {
      right: 6.5rem;
    }
  }

  span {
    border-left: 1px solid #ccc;
    color: #222;
    padding: 1.4rem;
    position: absolute;
    right: 0;
    top: 2.5rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const BottomContainer = styled.div`
  padding: 2.4rem;
`;

const TopContainer = styled.div`
  display: flex;
  max-height: 10rem;
  padding: 2.4rem;
  border-bottom: 1px solid #e4e4e4;

  > div {
    width: 100%;
  }

  > svg {
    background: #c4c4c4;
    border-radius: 5px;
    height: 4.35rem;
    margin-right: 1rem;
    padding: 0.75rem;
    width: 5rem;
    > path {
      fill: #ffffff;
    }
  }
`;

const AddAnnexContainer = styled.div`
  border: 0.1rem solid #f0f1f3;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.04),
    0 0.2rem 0.6rem rgba(0, 0, 0, 0.04), 0 1rem 2rem rgba(0, 0, 0, 0.04);
`;

const AnnexesInputContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1rem;
  margin-top: 2.4rem;

  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

ProFlowAnnexesInput.propTypes = {
  annexesData: shape({}).isRequired,
  setAnnexesData: func.isRequired,
  onChange: func.isRequired,
  dataTestId: string,
};

export default ProFlowAnnexesInput;
