import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import { shape } from 'prop-types';
import {
  setSkippedSteps,
  getNextPath,
  getCurrentStep,
  setCoverage,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { setTraits } from '../store';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';

const Home = () => {
  const { t } = useTranslation();
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const handleAnswer = answer => {
    setSkippedSteps(getCurrentStep(), false);
    setCoverage('home', true);
    addChatboxAnswer(
      t(`${getComponentName()}.response`, { answer: answer.toLowerCase() }),
    );
    addChatboxQuestion(t(`${getComponentName()}.question`));
    switch (answer) {
      case t(`${getComponentName()}.answers.0`):
        setTraits('is_owner', true);
        break;
      case t(`${getComponentName()}.answers.1`):
        setTraits('is_owner', false);
        break;
      default:
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          to={getNextPath()}
          Answer1={t(`${getComponentName()}.answers.0`)}
          Answer2={t(`${getComponentName()}.answers.1`)}
          response={answer => handleAnswer(answer)}
          dataTestId1="underwriting_home_owner"
          dataTestId2="underwriting_home_tenant"
        >
          {t(`${getComponentName()}.question`)}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

Home.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(Home);
