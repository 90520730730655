import React from 'react';
import { string } from 'prop-types';

const CarIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="22"
      fill="none"
      viewBox="0 0 48 22"
    >
      <path
        fill={color}
        d="M47.14 9.383a1.08 1.08 0 00-.78-.64L32 5.693a1.12 1.12 0 01-.64-.41l-2.91-3.85a.56.56 0 00-.33-.21L25.6.773a49.51 49.51 0 00-18.48.2l-1.28.26a1.1 1.1 0 00-.63.37C4.15 2.833 0 7.773 0 8.863c0 1.09.64 5.9.94 7.77a1.09 1.09 0 001.06.91h3.49a4.91 4.91 0 009.75 0h17.52a4.91 4.91 0 009.75 0h3.54a1.1 1.1 0 001.07-.83c.395-1.512.689-3.05.88-4.6a11 11 0 00-.86-2.73zm-36.78 10.33a2.72 2.72 0 01-2.67-2.17 3.19 3.19 0 010-.54 2.73 2.73 0 015.45 0 3.185 3.185 0 01-.05.54 2.73 2.73 0 01-2.73 2.17zm27.28 0a2.73 2.73 0 01-2.64-2.17 3.185 3.185 0 01-.05-.54 2.73 2.73 0 015.45 0c.015.18.015.36 0 .54a2.72 2.72 0 01-2.76 2.17z"
      ></path>
    </svg>
  );
};

CarIcon.defaultProps = {
  color: '#ccc',
};

CarIcon.propTypes = {
  color: string,
};

export default CarIcon;
