import React from 'react';

const SolarPanels = () => (
  <svg viewBox="0 0 203.4 213.9" width={203.4} height={213.9}>
    <style>{'.prefix__st0{fill:#fff}'}</style>
    <path
      className="prefix__st0"
      d="M50.6 138.5L0 140l41.9-28.5c.7 9.8 3.8 19 8.7 27zM41.9 102.4L0 73.9l50.6 1.5c-4.9 8-8 17.2-8.7 27zM78.9 162.5l-40.1 31L56 145.8c6.1 7.2 14 13 22.9 16.7zM38.8 20.4l40.1 31C70 55.1 62.1 60.9 56 68.1L38.8 20.4zM101.7 0l14.2 48.6c-4.5-1.1-9.3-1.7-14.2-1.7s-9.6.6-14.2 1.7L101.7 0zM101.7 166.9c4.9 0 9.6-.6 14.2-1.7l-14.2 48.6-14.2-48.6c4.6 1.2 9.3 1.7 14.2 1.7zM147.5 145.8l17.1 47.7-40.1-31c8.9-3.7 16.8-9.5 23-16.7zM124.5 51.4l40.1-31-17.1 47.7c-6.2-7.2-14.1-13-23-16.7zM161.5 111.5l41.9 28.5-50.6-1.5c4.9-8 8-17.2 8.7-27zM152.8 75.4l50.6-1.5-41.9 28.5c-.7-9.8-3.8-19-8.7-27z"
    />
    <circle className="prefix__st0" cx={101.7} cy={106.9} r={39.8} />
  </svg>
);

export default SolarPanels;
