import React from 'react';
import { string } from 'prop-types';
import {
  IconMobilityFilled,
  IconFireFilled,
  IconAssistanceCarFilled,
  IconAssistancePersonFilled,
  IconBikeFilled,
  IconSupportFilled,
  IconAllRisksFilled,
  IconPropertyExtraFilled,
  IconPoolFilled,
  IconGardenFilled,
  IconTheftFilled,
  IconLegalFilled,
  IconDriverFilled,
  IconCarFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconConsumerFilled,
  IconIncomeFilled,
  IconPassengerFilled,
  IconBaggageFilled,
  IconQualityFilled,
  IconHealthFilled,
  IconStats,
  IconInjuredFilled,
  IconBAPlusPlusFilled,
  IconBoughtCarFilled,
  IconWorkAccidentFilled,
  IconLaneControlFilled,
  IconPlusFilled,
  IconPedestrian,
  IconFurnitureFilled,
  IconBAPlusPlusXL,
  IconBAXL,
  IconBAPlusFilled,
  IconWebWorldFilled,
  IconTenPercentFilled,
  IconNaturalDisasterFilled,
  IconOthersFilled,
  IconGlassBreakageFilled,
} from 'wg-fe-ui';

const QuoteGuaranteeIcons = ({ type, color, className }) => {
  switch (type.toLowerCase()) {
    case 'fire':
      return <IconFireFilled color={color} className={className} />;
    case 'driver':
      return <IconDriverFilled color={color} className={className} size={23} />;
    case 'legal':
      return <IconLegalFilled color={color} className={className} />;
    case 'teacher':
      return <IconStats color={color} className={className} />;
    case 'vehicle':
      return <IconCarFilled color={color} className={className} />;
    case 'civil_liability':
      return <IconCarFilled color={color} className={className} />;
    case 'family':
      return <IconFamilyFilled color={color} className={className} />;
    case 'property':
      return <IconPropertyFilled color={color} className={className} />;
    case 'property_plus':
      return <IconPropertyExtraFilled color={color} className={className} />;
    case 'theft':
      return <IconTheftFilled color={color} className={className} />;
    case 'theft_plus':
      return <IconTheftFilled color={color} className={className} />;
    case 'leisure':
      return <IconTheftFilled color={color} className={className} />;
    case 'garden':
      return <IconGardenFilled color={color} className={className} />;
    case 'pool':
      return <IconPoolFilled color={color} className={className} />;
    case 'property+':
      return <IconPropertyExtraFilled color={color} className={className} />;
    case 'surround':
      return <IconPropertyExtraFilled color={color} className={className} />;
    case 'all_risk':
      return <IconAllRisksFilled color={color} className={className} />;
    case 'support':
      return <IconSupportFilled color={color} className={className} />;
    case 'bicycle':
      return <IconBikeFilled color={color} className={className} />;
    case 'assistance_vehicle':
      return (
        <IconAssistanceCarFilled
          color={color}
          className={className}
          size={25}
        />
      );
    case 'assistance_person':
      return (
        <IconAssistancePersonFilled
          color={color}
          className={className}
          size={25}
        />
      );
    case 'support_after_fire':
      return <IconFireFilled color={color} className={className} />;
    case 'mobility':
      return <IconMobilityFilled color={color} className={className} />;
    case 'consumer':
      return <IconConsumerFilled color={color} className={className} />;
    case 'income':
      return <IconIncomeFilled color={color} className={className} />;
    case 'passengers':
    case 'driver_and_passengers':
      return <IconPassengerFilled color={color} className={className} />;
    case 'business':
      return <IconBaggageFilled color={color} className={className} />;
    case 'premium':
    case 'civil_liability_max':
      return <IconQualityFilled color={color} className={className} />;
    case 'safety':
      return <IconHealthFilled color={color} className={className} />;
    case 'teaching_equipment':
      return <IconStats color={color} className={className} />;
    case 'bodily_injury_after_assault':
      return <IconInjuredFilled color={color} className={className} />;
    case 'accessories':
      return <IconBoughtCarFilled color={color} className={className} />;
    case 'accidental_damage_road_user':
      return <IconBAPlusPlusFilled color={color} className={className} />;
    case 'accidental_damage_driver':
      return <IconWorkAccidentFilled color={color} className={className} />;
    case 'assistance_road':
      return <IconLaneControlFilled color={color} className={className} />;
    case 'nature_protect':
      return <IconGardenFilled color={color} className={className} />;
    case 'extra':
      return <IconPlusFilled color={color} className={className} />;
    case 'legal_plus':
      return <IconLegalFilled color={color} className={className} />;
    case 'assistance_vehicle_person':
      return <IconPassengerFilled color={color} className={className} />;
    case 'mini_omnium_xl':
      return <IconBAXL color={color} className={className} />;
    case 'omnium_xl':
      return <IconBAPlusPlusXL color={color} className={className} />;
    case 'mini_omnium':
    case 'mini_omnium_catalogue_value':
    case 'mini_omnium_actual_value':
    case 'mini_omnium_0':
    case 'mini_omnium_6':
    case 'mini_omnium_12':
    case 'mini_omnium_24':
    case 'mini_omnium_36':
    case 'mini_omnium_ff':
    case 'mini_omnium_aw':
    case 'mini_omnium_aw_0':
    case 'mini_omnium_aw_6':
    case 'mini_omnium_aw_12':
    case 'mini_omnium_aw_24':
    case 'mini_omnium_aw_36':
    case 'mini_omnium_ff_0':
    case 'mini_omnium_ff_6':
    case 'mini_omnium_ff_12':
    case 'mini_omnium_ff_24':
    case 'mini_omnium_ff_36':
    case 'mini_omnium_theft_0':
    case 'mini_omnium_theft_6':
    case 'mini_omnium_theft_12':
    case 'mini_omnium_theft_24':
    case 'mini_omnium_theft_36':
      return <IconBAPlusFilled color={color} className={className} />;
    case 'omnium':
    case 'omnium_invoice_value':
    case 'omnium_catalogue_value':
    case 'omnium_actual_value':
    case 'omnium_ff':
    case 'omnium_aw':
    case 'omnium_0':
    case 'omnium_6':
    case 'omnium_12':
    case 'omnium_24':
    case 'omnium_36':
    case 'omnium_aw_0':
    case 'omnium_aw_6':
    case 'omnium_aw_12':
    case 'omnium_aw_24':
    case 'omnium_aw_36':
    case 'omnium_ff_0':
    case 'omnium_ff_6':
    case 'omnium_ff_12':
    case 'omnium_ff_24':
    case 'omnium_ff_36':
      return <IconBAPlusPlusFilled color={color} className={className} />;
    case 'content':
      return <IconFurnitureFilled color={color} className={className} />;
    case 'road_user':
      return <IconAssistanceCarFilled color={color} className={className} />;
    case 'full_assistance':
      return <IconBAPlusPlusFilled color={color} className={className} />;
    case 'private_life_bronze':
      return <IconPedestrian color={color} className={className} />;
    case 'private_life_silver':
      return <IconPedestrian color={color} className={className} />;
    case 'private_life_gold':
      return <IconPedestrian color={color} className={className} />;
    case 'bicycle_assistance':
      return <IconBikeFilled color={color} className={className} />;
    case 'webactive_bronze':
      return <IconWebWorldFilled color={color} className={className} />;
    case 'webactive_silver':
      return <IconWebWorldFilled color={color} className={className} />;
    case 'webactive_gold':
      return <IconWebWorldFilled color={color} className={className} />;
    case 'indirect_losses':
      return <IconTenPercentFilled color={color} className={className} />;
    case 'nature':
      return <IconNaturalDisasterFilled color={color} className={className} />;
    case 'no_exemption_window_breakage':
      return <IconGlassBreakageFilled color={color} className={className} />;
    case 'legal_assistance':
      return <IconLegalFilled color={color} className={className} />;
    case 'assistance':
      return <IconLaneControlFilled color={color} className={className} />;
    case 'building':
      return <IconPropertyFilled color={color} className={className} />;
    case 'vehicle_at_rest':
      return <IconCarFilled color={color} className={className} />;
    case 'civil_liability_family':
      return <IconFamilyFilled color={color} className={className} />;
    default:
      return <IconOthersFilled color={color} />;
  }
};

QuoteGuaranteeIcons.defaultProps = {
  type: '',
  color: '#FFFFFF',
  className: '',
};

QuoteGuaranteeIcons.propTypes = {
  type: string,
  color: string,
  className: string,
};

export default QuoteGuaranteeIcons;
