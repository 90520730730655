import React from 'react';
import { string } from 'prop-types';
const verandaIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.61 6.41246C18.7833 6.41246 18.94 6.34202 19.0533 6.22824C19.1671 6.11492 19.2375 5.9578 19.2375 5.78488C19.2375 5.43858 18.9563 5.1573 18.61 5.1573H17.8776H16.8315H15.0346C15.0219 5.14556 15.0084 5.13472 14.993 5.12524L8.03185 0.68387C7.98309 0.652717 7.92891 0.637817 7.87383 0.637817C7.81965 0.637817 7.76547 0.652266 7.71761 0.682516L0.67517 5.12614C0.590289 5.17987 0.538818 5.27333 0.538818 5.37447V17.298H6.2954V10.9685C6.2954 10.8642 6.34958 10.773 6.43175 10.7211C6.4769 10.6917 6.53108 10.675 6.58842 10.675H9.07887C9.24096 10.675 9.37189 10.806 9.37189 10.9681V17.2976H18.861V11.9361H17.8776V6.41246H18.61ZM15.1289 11.9361V6.41246H16.8315V11.9361H15.1289Z"
        fill={color}
      />
    </svg>
  );
};

verandaIcon.defaultProps = {
  color: `white`,
};

verandaIcon.propTypes = {
  color: string,
};

export default verandaIcon;
