/* stylelint-disable value-keyword-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Arrow from '../../../../../assets/images/arrow';
import { string, func, shape, number, bool } from 'prop-types';
// import { useDebouncedCallback } from 'use-debounce';
// import { getPrice } from '../store';
import InfoPopUp from '../../../../components/InfoPopUp';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import LoadingButton from '../../../../components/LoadingButton';
import { exportToInsurerById_V2 } from '../../../../services/apiRouterService';
import i18next from 'i18next';

const BigInsuranceCardManualDiscount = ({
  id,
  discounts,
  insuranceType,
  disabledButton,
  sortKey,
  discountData,
  price,
  disabledExport,
  insurer,
  discountPremium,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [exportMessage, setExportMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const [discountValue, setDiscountValue] = useState({
    vk: discountPremium.vk,
    ck: 0,
  });
  const insurersExport = ['baloise', 'vivium', 'fidea', 'allianz'];

  const max_vk = discounts
    ? Math.floor(discounts.max_vk * 100 * 100) / 100
    : 100;
  const max_ck = discounts
    ? Math.floor(discounts.max_ck * 100 * 100) / 100
    : 100;

  const handleChange = e => {
    disabledButton(true);
    let val = e.target.value;

    const max_val = discounts[`max_${e.target.name}`] * 100;

    // value can't exceed max_val
    if (max_val < val) {
      val = max_val;
    }

    setDiscountValue({
      ...discountValue,
      [e.target.name]: val,
    });
  };

  const exportToPartner = async () => {
    setLoadingExport(true);
    setExportMessage({});
    let refId = '';
    const [resp, status] = await exportToInsurerById_V2(price.quote_id);
    switch (status) {
      case 200:
        refId = resp?.external_ref;
        setExportMessage({
          error: false,
          message: `${t(
            `Checkout.card.parameters.export.${insurer.toLowerCase()}.success`,
            {
              ref: refId,
            },
          )}${
            insuranceType === 'home' && insurer.toLowerCase() === 'vivium'
              ? t(
                  `Checkout.card.parameters.export.${insurer.toLowerCase()}.warning`,
                  {
                    ref: refId,
                  },
                )
              : ''
          }`,
        });
        break;
      case 404:
        if (resp.detail) {
          if (resp.detail[i18next.language]) {
            setExportMessage({
              error: true,
              message: resp.detail[i18next.language],
            });
          } else {
            setExportMessage({
              error: true,
              message: t(
                `The configuration of your keys does not allow us to export it Please contact us for this`,
              ),
            });
          }
        } else {
          setExportMessage({
            error: true,
            message: t(
              `The configuration of your keys does not allow us to export it Please contact us for this`,
            ),
          });
        }
        break;
      default:
        setExportMessage({
          error: true,
          message: t(
            `At the moment it is not possible to save this quote We are aware of this problem and will solve it soon`,
          ),
        });
        break;
    }
    setLoadingExport(false);
  };

  const handleSetMaxValue = type => {
    if (type === 'vk' && max_vk === 0) return;
    if (type === 'ck' && max_ck === 0) return;
    if (insuranceType === 'family' && type === 'vk') {
      setDiscountValue({
        ...discountValue,
        vk: parseFloat(0),
      });
      return;
    }
    const max_val = discounts[`max_${type}`] * 100;

    setDiscountValue({
      ...discountValue,
      [type]: parseFloat(max_val).toFixed(2),
    });
  };

  function handleDiscountSubmit() {
    const ck = discountValue.ck / 100;
    const vk = discountValue.vk / 100;
    setLoading(true);

    discountData({ id, ck, vk });
  }

  useEffect(() => {
    if (discounts) {
      if (discounts.max_ck === 0 && discounts.max_ck === 0) {
        setDiscountValue({ vk: 0, ck: 0 });
      }
    }
  }, [discounts]);

  useEffect(() => {
    if (discountPremium) {
      setLoading(false);
      const { vk, ck } = discountPremium || {};

      const flooredVk = Math.floor(vk * 100 * 100) / 100 || 0;
      const flooredCk = Math.floor(ck * 100 * 100) / 100 || 0;

      setDiscountValue({ vk: flooredVk, ck: flooredCk });
    }
  }, [discountPremium]);

  return (
    <Container>
      <CollapsibleTrigger>
        <H3>{t('Checkout.card.parameters.title')}</H3>
        <RotatingArrow
          dataTestId={`underwriting_discount_arrow_${sortKey}`}
          isCollapsed={isCollapsed}
          colors="#707070"
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </CollapsibleTrigger>
      <WillCollapse isCollapsed={isCollapsed}>
        <CollapseContainer>
          <InputsContainer>
            <InfoButton
              title={t('Checkout.card.parameters.title')}
              info={t('Checkout.card.parameters.info')}
            />
            <InputContainer
              disabled={insuranceType === 'family' || max_vk <= 0}
            >
              <label
                disabled={insuranceType === 'family' || max_vk <= 0}
                htmlFor="vk"
                onClick={() => handleSetMaxValue('vk')}
              >
                VK:
              </label>
              <NumberInput
                min={0}
                disabled={insuranceType === 'family' || max_vk <= 0}
                max={max_vk}
                data-test-id={`underwriting_discount_vk_${sortKey}`}
                value={discountValue.vk}
                onChange={e => handleChange(e)}
                name="vk"
                // onBlur={e => handleOnBlur(e)}
              />
              <Percentage>%</Percentage>
            </InputContainer>
            <InputContainer disabled={max_ck <= 0}>
              <label
                htmlFor="ck"
                disabled={max_ck <= 0}
                onClick={() => handleSetMaxValue('ck')}
              >
                CK:
              </label>
              <NumberInput
                min={0}
                max={max_ck}
                data-test-id={`underwriting_discount_ck_${sortKey}`}
                disabled={max_ck <= 0}
                value={discountValue.ck}
                onChange={e => handleChange(e)}
                name="ck"
              />
              <Percentage>%</Percentage>
            </InputContainer>
            <StyledDiscountButton
              disabled={
                (max_ck <= 0 && (insuranceType === 'family' || max_vk <= 0)) ||
                loading
              }
              onClick={handleDiscountSubmit}
            >
              {t(`Apply`)}
            </StyledDiscountButton>
          </InputsContainer>
          {insurersExport.includes(insurer.toLowerCase()) &&
          (insuranceType.toLowerCase() === 'home' ||
            insuranceType.toLowerCase() === 'car' ||
            (insuranceType.toLowerCase() === 'family' &&
              insurer.toLowerCase() === 'allianz')) ? (
            loadingExport ? (
              <>
                <Span />
                <StyledLoading>{t(`Exporting`)}</StyledLoading>
              </>
            ) : (
              <>
                <Span />
                <StyledButton
                  disabled={disabledExport}
                  onClick={exportToPartner}
                >
                  {t(
                    `Checkout.card.parameters.export.${insurer.toLowerCase()}.button`,
                  )}
                </StyledButton>
              </>
            )
          ) : (
            ''
          )}
        </CollapseContainer>
        {exportMessage.message ? (
          <ExportMessage
            error={exportMessage.error}
            dangerouslySetInnerHTML={{ __html: exportMessage.message }}
          />
        ) : (
          ''
        )}
      </WillCollapse>
    </Container>
  );
};

const InputsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 66%;
  justify-content: space-between;
`;

const Span = styled.span`
  background-color: #0003;
  width: 0.2rem;
  height: 4rem;
  margin: 0 auto;
  border-radius: 13%;
`;

const ExportMessage = styled.p`
  ${props =>
    props.error ? 'background-color: #F67975;' : 'background-color: #d0ecd0;'}
  ${props => (props.error ? 'color: white;' : 'color: #155C11;')}
  ${props =>
    props.error ? 'border: 1px solid #D55653;' : 'border: 1px solid #155C11;'}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 3rem;
  text-align: center;
  margin-top: 2rem;
`;

const Percentage = styled.span`
  position: relative;
  margin-left: -6rem;
`;

const RotatingArrow = styled(Arrow)`
  transform: ${props => (props.isCollapsed ? 'rotate(180deg)' : '0')};
  cursor: pointer;
  padding: 1rem;
  height: 4rem;
  width: 5rem;
`;

const NumberInput = styled.input`
  background-color: white;
  border: ${props =>
    props.errors && props.touched
      ? '0.1rem solid red'
      : props.touched
      ? '0.1rem solid #49C562'
      : 'none'};
  border-radius: 0.3rem;
  width: 12rem;
  height: 3.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 4rem;
  font-size: 1.5rem;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.brand.primary};

    & + span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  justify-content: space-between;

  & label {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    color: #525252;
    font-weight: 700;
    font-size: 1.6rem;
    margin-right: 1rem;
  }
`;

const InfoButton = styled(InfoPopUp)`
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  font-size: 1.3rem;
  min-width: 10rem;
  padding: 0.5rem 2rem;
  margin: 0;
  height: 4rem;
  width: 22rem;
`;

const StyledDiscountButton = styled(Button)`
  font-size: 1.5rem;
  min-width: 10rem;
  padding: 1rem 2rem;
  margin: 0;
  height: 4rem;
`;

const StyledLoading = styled(LoadingButton)`
  font-size: 1.5rem;
  min-width: 10rem;
  padding: 1rem 2rem;
  margin: 0;
  height: 4rem;
  width: 22rem;
`;

const WillCollapse = styled.div`
  visibility: ${props => (props.isCollapsed ? 'collapse' : 'visible')};
  height: ${props => (props.isCollapsed ? '0' : 'auto')};
  overflow: hidden;
  padding: ${props =>
    props.isCollapsed ? '0' : '1.5rem 3.7rem 2.5rem 3.7rem;'};
`;

const CollapseContainer = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  /* justify-content: flex-end; */
`;

const CollapsibleTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3.7rem;
`;

const H3 = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
`;

const Container = styled.div`
  background-color: #e8e8e8;
  border-bottom: 1px solid ${({ theme }) => theme.typo.highlight};
`;

BigInsuranceCardManualDiscount.defaultProps = {
  // discounts: { max_ck: 0, max_vk: 0 },
  discountPremium: { vk: 0 },
};

BigInsuranceCardManualDiscount.propTypes = {
  id: string.isRequired,
  sortKey: number.isRequired,
  insuranceType: string.isRequired,
  discountData: func.isRequired,
  discounts: shape(),
  disabledButton: func,
  price: shape(),
  disabledExport: bool,
  insurer: string,
  discountPremium: shape(),
};

export default BigInsuranceCardManualDiscount;
