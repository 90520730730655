import React from 'react';
import styled from 'styled-components';
import { ActionButton } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import Layout from '../models/Marketplace/LayoutMarket/views/LayoutSignature';

const ThankYouForSigningLouise = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Header>
        <h1>{t('Thank you for signing online!')}</h1>
        <p>
          {t(
            'We have received the documents correctly Your broker will contact you with further steps to take',
          )}
        </p>
      </Header>
      <StyledActionButton onClick={() => window.close()}>
        {t('Close window')}
      </StyledActionButton>
    </Layout>
  );
};

const Header = styled.header`
  color: white;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 2rem;

  & h1 {
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

const StyledActionButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.brand.lightest};
  color: ${({ theme }) => theme.brand.primary};
`;

export default ThankYouForSigningLouise;
