import React from 'react';
import { string } from 'prop-types';

const Steerwheel = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C5.36895 0 0 5.36895 0 12C0 18.6311 5.36895 24 12 24C18.621 24 24 18.6311 24 12C24 5.36895 18.621 0 12 0ZM10.3072 20.5543C10.3072 21.0351 9.84641 21.3856 9.37563 21.2554C6.64107 20.4841 4.40735 18.5409 3.24541 15.9967C3.02504 15.5058 3.37563 14.9549 3.90651 14.9549H7.11185C8.87479 14.9549 10.3072 16.3873 10.3072 18.1502V20.5543ZM12 14.0935C11.2087 14.0935 10.5676 13.4524 10.5676 12.6611C10.5676 11.8698 11.2087 11.2287 12 11.2287C12.7913 11.2287 13.4424 11.8698 13.4424 12.6611C13.4424 13.4524 12.7913 14.0935 12 14.0935ZM20.8447 15.9866C19.6427 18.6311 17.2688 20.6344 14.394 21.3356C13.9332 21.4457 13.4925 21.0952 13.4925 20.6244V18.1502C13.4925 16.3873 14.9249 14.9549 16.6878 14.9549H20.1736C20.7045 14.9549 21.0651 15.5058 20.8447 15.9866ZM20.6544 11.6995L16.2671 9.92654C13.5326 8.82471 10.4674 8.82471 7.73289 9.92654L3.34558 11.6995C2.84474 11.8297 2.38397 11.419 2.44407 10.9082C2.98497 6.10017 7.06177 2.37396 12.01 2.37396C16.9583 2.37396 21.0351 6.11018 21.576 10.9082C21.616 11.419 21.1452 11.8297 20.6544 11.6995Z"
        fill={color}
      />
    </svg>
  );
};

Steerwheel.propTypes = {
  color: string,
};

export default Steerwheel;
