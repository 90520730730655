import React, { useState, useEffect, useRef } from 'react';
import { string, bool, func, shape } from 'prop-types';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import {
  CheckBox,
  IconCarFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconLegalFilled,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';

const OfferedPolicy = ({
  name,
  label,
  logo,
  disabled,
  onChange,
  insurances,
  allSelected,
  isLoading,
  chosenInsurances,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const canClick = useRef(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (chosenInsurances?.includes(name)) {
      setIsSelected(true);
    } else if (!chosenInsurances?.includes(name)) {
      setIsSelected(false);
    }
  }, [chosenInsurances]);

  function handleCheckboxClick(e) {
    e.preventDefault();
    if (disabled) {
      if (canClick.current) {
        cogoToast.info(t('No active connection with company'));
        canClick.current = false;
        setTimeout(() => (canClick.current = true), 3000);
      }
    } else if (!isLoading) {
      onChange({ name: name, value: !isSelected });
    }
  }

  function getIcon(name) {
    switch (name) {
      case 'car':
        return <IconCarFilled color="#e4e4e4" size={35} />;
      case 'family':
        return <IconFamilyFilled color="#e4e4e4" size={35} />;
      case 'home':
        return <IconPropertyFilled color="#e4e4e4" size={35} />;
      case 'legal':
        return <IconLegalFilled color="#e4e4e4" size={35} />;
      default:
        break;
    }
  }

  return (
    <StyledDiv
      onClick={handleCheckboxClick}
      checked={isSelected && !disabled}
      disabled={disabled}
      data-test-id={`offeredPolicy_${name}`}
    >
      <SubDiv>
        <StyledCheckBox
          name={name}
          checked={isSelected && !disabled}
          onClick={e => handleCheckboxClick(e)}
        />
        <Image src={logo} alt={label} onClick={handleCheckboxClick} />
      </SubDiv>
      <Icons checked={isSelected && !disabled}>
        {insurances.map(insurance => getIcon(insurance))}
      </Icons>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : '1px solid #f0f1f3'};
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: 14rem;
  background-color: ${({ checked, theme }) =>
    checked ? theme.brand.lightest : `white`};

  :hover {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
      0 16px 24px rgba(0, 0, 0, 0.06);
  }
`;

const SubDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 70%;
  padding: 1.5rem;
`;

const StyledCheckBox = styled(CheckBox)`
  div {
    border: 0;
    background-color: ${({ checked, theme }) =>
      checked ? theme.brand.primary : '#F0F1F3'};
  }
`;

const Image = styled.div`
  margin-left: auto;
  width: 75%;
  height: 90%;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: solid 1px
    ${({ checked, theme }) => (checked ? theme.brand.primary : '#F0F1F3')};
  padding-top: 5%;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  ${({ checked, theme }) =>
    `> svg {
        padding-left: 0.35rem;
        padding-right: 0.35rem;
        
        > path {
          fill: ${checked ? theme.brand.primary : '#e4e4e4'}
        }
      }


      :first-of-type {
        padding-right: 0.35rem;
        padding-left: 0;
      }

      :last-of-type {
        padding-left: 0.35rem;
        padding-right: 0;
      }
    }`}
`;

OfferedPolicy.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  logo: string.isRequired,
  disabled: bool.isRequiredRequir,
  onChange: func.isRequired,
  allSelected: bool.isRequired,
  insurances: shape([]).isRequired,
  isLoading: bool,
  chosenInsurances: shape([]).isRequired,
};

export default OfferedPolicy;
