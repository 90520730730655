import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import {
  TextArea,
  ToggleInput,
  SearchSelectInput,
  TextInput,
  Label,
} from 'wg-fe-ui';
import useForm from '../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import { object as yupObject, string, boolean } from 'yup';
import { useDebounce } from 'use-debounce';
import DateInput from './DateInput_v3';
import { format, formatISO } from 'date-fns';
import { getAllInsuranceCompanies } from '../services/apiRouterService';

const ProflowOffersModalTerminationLetterForm = ({ selectedTab }) => {
  const [insuranceCompanies, setInsuranceCompanies] = useState([]);
  const { t, i18n } = useTranslation();

  const {
    getTerminationLetterGenerator,
    setTerminationLetterGenerator,
    offersParameters,
  } = useContext(ProFlowOffersContext);

  const { handleChange, values, errors } = useForm({
    validationSchema: yupObject().shape({
      enableTermination: boolean(),
      reasonOfTermination: string().when('enableTermination', {
        is: true,
        then: string().required(t('required')),
      }),
      insuranceCompany: string().when('enableTermination', {
        is: true,
        then: string().required(t('required')),
      }),
      policyNumber: string().when('enableTermination', {
        is: true,
        then: string().required(t('required')),
      }),
    }),
    mode: 'onChange',
    initialValues: {
      enableTermination: false,
    },
  });

  const [debouncedValues] = useDebounce(values, 250, { leading: true });

  useEffect(() => {
    retrieveInsurances();
  }, []);

  useEffect(() => {
    if (selectedTab.length < 0) return;
    getTerminationLetterGenerator(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const { terminationLetter } = offersParameters || {};
    if (isEmpty(terminationLetter)) return;
    const [terminationLetterByType] = terminationLetter.filter(
      ({ insuranceType }) => selectedTab === insuranceType,
    );
    if (isEmpty(terminationLetterByType)) return;
    Object.entries(terminationLetterByType).forEach(([key, value]) => {
      handleChange({ name: key, value });
    });
  }, [offersParameters]);

  useEffect(() => {
    const { terminationLetter } = offersParameters || {};
    if (
      isEmpty(terminationLetter) ||
      isEqual({ ...values, errors }, terminationLetter[0])
    )
      return;
    setTerminationLetterGenerator({ ...values, errors });
  }, [debouncedValues]);

  function handleDateChange({ name, value }) {
    const [day, month, year] = value.split('/');
    const jsDate = new Date(year, month - 1, day);
    handleChange({ name, value: formatISO(jsDate) });
  }

  function handleDateValue(value) {
    if (new Date(value) !== 'Invalid Date' && !isNaN(new Date(value))) {
      return format(new Date(value), 'dd/MM/yyyy');
    }
  }

  async function retrieveInsurances() {
    const [resp, status] = await getAllInsuranceCompanies();
    if (status !== 200) return [{ label: 'recourses not found', value: '' }];

    const { items } = resp;

    setInsuranceCompanies(
      items.map(({ insurance_company }) => ({
        label: insurance_company.names[i18n.language],
        value: insurance_company.key,
      })),
    );
  }

  const reasonTypes = [
    { value: 'INSURANCE_CHANGED', label: t(`INSURANCE_CHANGED`) },
    { value: 'CONTRACT_TERMINATION', label: t(`CONTRACT_TERMINATION`) },
    { value: 'PREMIUM_INCREASE', label: t(`PREMIUM_INCREASE`) },
    { value: 'RISK_OBJECT_CHANGED', label: t(`RISK_OBJECT_CHANGED`) },
  ];

  return (
    <InputContainer>
      <CheckBoxContainer>
        <Label>
          {t('Create notice of termination for')} {t(selectedTab)}?
        </Label>
        <ToggleInput
          falseLabel={t('No')}
          trueLabel={t('Yes')}
          name="enableTermination"
          checked={values.enableTermination || false}
          onChange={({ checked }) =>
            handleChange({ name: 'enableTermination', value: checked })
          }
        />
      </CheckBoxContainer>
      {values.enableTermination && (
        <TextAreaContainer>
          <SearchSelectInput
            options={reasonTypes}
            name="reasonOfTermination"
            onSelected={({ value }) =>
              handleChange({ name: 'reasonOfTermination', value })
            }
            value={reasonTypes.filter(
              ({ value }) => value === values.reasonOfTermination,
            )}
            placeholder={t('Choose an option')}
            error={errors.reasonOfTermination}
          >
            {t('Reason for termination')}
          </SearchSelectInput>
          <SearchSelectInput
            options={insuranceCompanies}
            name="insuranceCompany"
            onSelected={({ value }) =>
              handleChange({ name: 'insuranceCompany', value })
            }
            value={insuranceCompanies?.filter(
              ({ value }) => value === values.insuranceCompany,
            )}
            placeholder={t('Choose an option')}
            error={errors.insuranceCompany}
          >
            {t('Insurance company')}
          </SearchSelectInput>
          <TextInput
            name="policyNumber"
            value={values.policyNumber}
            onChange={handleChange}
            error={errors.policyNumber}
          >
            {t('Policy number')}
          </TextInput>
          <DateInput
            name="expirationDate"
            onChange={handleDateChange}
            value={handleDateValue(values.expirationDate)}
          >
            {t('Main expiration date')}
          </DateInput>
          <StyledTextArea
            name="extraInformation"
            onChange={handleChange}
            value={values.extraInformation || ''}
          >
            {t('Additional information (optional)')}
          </StyledTextArea>
        </TextAreaContainer>
      )}
    </InputContainer>
  );
};

const StyledTextArea = styled(TextArea)`
  margin-bottom: 2rem;
  grid-column: span 2;
  & textarea {
    height: 8.5rem;
  }
`;

const CheckBoxContainer = styled.div`
  padding: 3rem 3rem 2rem;
  border-bottom: 2px solid #f0f1f3;
`;

const TextAreaContainer = styled.div`
  padding: 3rem 3rem 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: 1rem 2rem;
`;

const InputContainer = styled.div`
  height: 35vw;

  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

ProflowOffersModalTerminationLetterForm.propTypes = {
  selectedTab: string.isRequired,
};

export default ProflowOffersModalTerminationLetterForm;
