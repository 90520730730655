import React from 'react';
import styled from 'styled-components';
import { array, bool, object, propString } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getOfferRevisionDocumentById } from '../services/apiRouterService';
import FileSaver from 'file-saver';
import PDF from '../../assets/images/pdf-download.svg';

const OfferDocument = ({
  data,
  docInfo,
  distributionId,
  unsigned,
  unsignedDocs,
}) => {
  const { t } = useTranslation();

  async function fetchOfferDocumentUrl(
    offerId,
    distributionId,
    revisionId,
    documentId,
  ) {
    let [response, status] = await getOfferRevisionDocumentById(
      distributionId,
      offerId,
      revisionId,
      documentId,
    );

    if (status === 200) {
      return response;
    } else {
      console.error(response);
    }
  }

  const handleOfferDocumentClick = documentId => {
    fetchOfferDocumentUrl(
      data?.id,
      distributionId,
      data?.revision,
      documentId,
    ).then(resp => {
      FileSaver.saveAs(resp?.document?.link, resp?.document?.filename);
    });
  };

  return (
    <OfferDocumentContainer>
      {unsigned ? (
        <>
          <h4>
            {t(`document_types.${docInfo?.type}`)}
            <DocumentStatus status={false}>{t('Pending')}</DocumentStatus>
          </h4>
          <OfferDocuments onClick={() => handleOfferDocumentClick(docInfo?.id)}>
            <img src={PDF} alt="PDF" draggable="false" />
            {t('Unsigned version')}
          </OfferDocuments>
        </>
      ) : (
        <>
          <h4>
            {t(`document_types.${docInfo?.type}`)}
            <DocumentStatus status={true}>{t('Signed')}</DocumentStatus>
          </h4>
          <OfferDocuments onClick={() => handleOfferDocumentClick(docInfo?.id)}>
            <img src={PDF} alt="PDF" draggable="false" />

            {t('Signed version')}
          </OfferDocuments>
          {unsignedDocs?.map(unsigned => {
            if (unsigned.type === docInfo.type) {
              return (
                <OfferDocuments
                  onClick={() => handleOfferDocumentClick(unsigned?.id)}
                >
                  <img src={PDF} alt="PDF" draggable="false" />
                  {t('Unsigned version')}
                </OfferDocuments>
              );
            }
          })}
        </>
      )}
    </OfferDocumentContainer>
  );
};

const DocumentStatus = styled.span`
  align-self: center;
  color: ${({ status }) => (status ? '#37D66C' : '#FF9B21')};
`;

const OfferDocuments = styled.div`
  background-color: ${({ theme }) => theme.ui.background};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 1rem;
  position: relative;
  padding-left: 4rem;

  img {
    position: absolute;
    left: 1rem;
  }

  &:hover {
    box-shadow: 0 0.7px 3.3px rgba(0, 0, 0, 0.02),
      0 1.7px 8px rgba(0, 0, 0, 0.03), 0 3.1px 15.2px rgba(0, 0, 0, 0.04),
      0 5.6px 27px rgba(0, 0, 0, 0.04), 0 10.4px 50.6px rgba(0, 0, 0, 0.05),
      0 25px 121px rgba(0, 0, 0, 0.07);
    background-color: white;
  }
`;

const OfferDocumentContainer = styled.div`
  background: #f0f1f3;
  opacity: 0.85;
  height: fit-content;
  border-radius: 8px;
  padding: 2rem;
  h4 {
    display: flex;
    font-weight: 600;
    flex-direction: row;
    justify-content: space-between;
    color: #8990a3;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e4e4e4;
  }
`;

OfferDocument.propTypes = {
  data: object,
  docInfo: object,
  distributionId: propString,
  unsignedDocs: array,
  unsigned: bool,
};

export default OfferDocument;
