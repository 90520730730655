import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import { CheckBox_v2 as CheckBox, TextArea } from 'wg-fe-ui';
import useForm from '../hooks/useForm';
import { useTranslation } from 'react-i18next';
import { isEmpty, isEqual } from 'lodash';
import { object as yupObject, string as yupString } from 'yup';
import { useDebounce } from 'use-debounce';
import { string } from 'prop-types';

const ProflowOffersModalRequirednformationForm = ({ selectedTab }) => {
  const { t } = useTranslation();

  const {
    getRequireInformationGenerator,
    setRequireInformationGenerator,
    offersParameters,
  } = useContext(ProFlowOffersContext);

  const { handleChange, values, errors } = useForm({
    validationSchema: yupObject().shape({
      reasonOfContract: yupString().required(t('required')),
      general: yupString().test('general', t('required'), function(value) {
        const { motivation } = this.parent || {};
        if (motivation.general === undefined) return true;
        return value.length > 0;
      }),
      prospectAsksToInsure: yupString().test(
        'prospect-asks-to-insure',
        t('required'),
        function(value) {
          const { doesBrokerGiveAdvice } = this.parent;
          if (!doesBrokerGiveAdvice) return true;
          return value.length > 0;
        },
      ),
    }),
    mode: 'onChange',
    initialValues: {
      general: '',
    },
  });

  const [debouncedValues] = useDebounce(values, 250, { leading: true });

  useEffect(() => {
    if (selectedTab.length < 0) return;
    getRequireInformationGenerator(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const { requiredInformation } = offersParameters || {};
    if (isEmpty(requiredInformation)) return;
    const [requiredInformationByType] = requiredInformation.filter(
      ({ insuranceType }) => selectedTab === insuranceType,
    );
    if (isEmpty(requiredInformationByType)) return;
    Object.entries(requiredInformationByType).forEach(([key, value]) => {
      handleChange({ name: key, value });
    });
  }, [offersParameters]);

  useEffect(() => {
    if (
      !values.doesBrokerGiveAdvice &&
      (values.didTheBrokeGivePersonalisedAdvice ||
        values.didTheProspectFollowedAdvice)
    ) {
      handleChange({
        name: 'didTheBrokeGivePersonalisedAdvice',
        values: false,
      });
      handleChange({ name: 'didTheProspectFollowedAdvice', values: false });
    }
  }, [values, errors]);

  useEffect(() => {
    const { requiredInformation } = offersParameters || {};
    const [currentRequiredInformation] = requiredInformation.filter(
      ({ insuranceType }) => insuranceType === values.insuranceType,
    );
    if (
      isEmpty(requiredInformation) ||
      isEqual({ ...values, errors }, currentRequiredInformation)
    )
      return;
    setRequireInformationGenerator({ ...values, errors });
  }, [debouncedValues]);

  return (
    <InputContainer>
      <CheckBoxContainer>
        <StyledCheckBox
          name="doesBrokerGiveAdvice"
          onChange={handleChange}
          checked={values.doesBrokerGiveAdvice || false}
        >
          {t('Did the office give advise on this matter')}
        </StyledCheckBox>
        <StyledCheckBox
          name="didTheBrokeGivePersonalisedAdvice"
          onChange={handleChange}
          disabled={!values.doesBrokerGiveAdvice || false}
          checked={values.didTheBrokeGivePersonalisedAdvice || false}
        >
          {t('Did you deliver independent advice to the customer')}
        </StyledCheckBox>
        <StyledCheckBox
          name="didTheProspectFollowedAdvice"
          onChange={handleChange}
          disabled={!values.doesBrokerGiveAdvice || false}
          checked={values.didTheProspectFollowedAdvice || false}
        >
          {t('Did the customer follow the advice of your office')}
        </StyledCheckBox>
        <StyledCheckBox
          name="anotherInsuranceContract"
          onChange={handleChange}
          checked={values.anotherInsuranceContract || false}
        >
          {t('Is there another contract running for this risk')}
        </StyledCheckBox>
      </CheckBoxContainer>
      <TextAreaContainer>
        <StyledTextArea
          name="reasonOfContract"
          onChange={handleChange}
          value={values.reasonOfContract || ''}
          error={errors.reasonOfContract}
        >
          {t(`Reason for contact`)}
        </StyledTextArea>
        {Object.entries(values?.motivation || {}).map(([name, value]) => (
          <StyledTextArea
            key={name}
            name={name}
            onChange={handleChange}
            disabled={name !== 'general'}
            value={values[name] || value || ''}
            error={name === 'general' ? errors.general : ''}
          >
            {t('Motivation')}
            {Object.entries(values?.motivation || {}).length > 0 &&
            name !== 'general'
              ? ' - [Overwritten Field]'
              : null}
          </StyledTextArea>
        ))}
        {values.doesBrokerGiveAdvice && (
          <StyledTextArea
            name="prospectAsksToInsure"
            onChange={handleChange}
            value={values.prospectAsksToInsure || ''}
            error={errors.prospectAsksToInsure}
          >
            {t(`Specific needs`)}
          </StyledTextArea>
        )}
      </TextAreaContainer>
    </InputContainer>
  );
};
const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 1rem;
`;

const StyledTextArea = styled(TextArea)`
  margin-bottom: 2rem;
  & textarea {
    height: 8.5rem;
  }
`;

const CheckBoxContainer = styled.div`
  padding: 3rem 3rem 2rem;
  border-bottom: 2px solid #f0f1f3;
`;

const TextAreaContainer = styled.div`
  padding: 3rem 3rem 0;
`;

const InputContainer = styled.div`
  height: 35vw;

  overflow-y: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 4px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

ProflowOffersModalRequirednformationForm.propTypes = {
  selectedTab: string.isRequired,
};

export default ProflowOffersModalRequirednformationForm;
