import React, { useRef, useState, useEffect, useContext } from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CopyIcon from '../../../../assets/images/dashboard/copy-icon.svg';
import cogoToast from 'cogo-toast';
import Layout from '../../DashboardLayout/views/Layout';
import hljs from 'highlight.js/lib/highlight';
import xml from 'highlight.js/lib/languages/xml';
import 'highlight.js/styles/github.css';
import { getCurrentTheme } from '../../../store';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import { withRouter } from 'react-router';
import { getBrokerData } from '../../../services/brokerDataService';
import UpgradePopupOnBrokerLoginScreen from '../../../components/UpgradePopupOnBrokerLoginScreen';

const Academy = ({ history }) => {
  const htmlQuerySelector = document.querySelector('.html');
  const { t } = useTranslation();
  const btnColor = getCurrentTheme().brand.primary;
  const { setNavTabs } = useContext(TopNavigationItems);
  const btnText = 'Click me';
  const btnTextColor = '#ffffff';
  const content = t('CustomerLinks', { returnObjects: true });
  const [isBronze, setIsBronze] = useState(false);
  const [brokerId, setBrokerId] = useState();
  const inputRef = useRef(null);
  const codeInputRef = useRef(null);

  const handleCopyClick = e => {
    e.preventDefault();
    cogoToast.success(t('Your personal link has been copied'), {
      position: 'top-right',
    });
    inputRef.current.select();
    window.document.execCommand('copy');
    inputRef.current.blur();
  };

  const handleCopyClickCode = e => {
    e.preventDefault();
    cogoToast.success(t('Your personal button code has been copied'), {
      position: 'top-right',
    });
    codeInputRef.current.select();
    window.document.execCommand('copy');
    e.target.blur();
  };
  const currentDomain = window.location.hostname;
  let domainInCode = ``;
  if (currentDomain.includes('localhost'))
    domainInCode = 'http://localhost:3000';
  else if (currentDomain.includes('dev.qa'))
    domainInCode = 'https://marketplace.dev.qa.wegroup.be';
  else if (currentDomain.includes('dev.staging'))
    domainInCode = 'https://marketplace.dev.staging.wegroup.be';
  else domainInCode = 'https://marketplace.wegroup.be';
  const brokerSocialUrl = `${domainInCode}/broker/${brokerId}`;

  const buttonCode = `<a target="_blank" style="text-align:center; border-radius: 3px; display: inline-block; background-color: ${btnColor}; padding: 10px 10px; min-width: 140px; color: ${btnTextColor}; text-decoration: none;" href="${brokerSocialUrl}">
    ${btnText}
</a>`;

  useEffect(() => {
    checkIfBrokerIsBronze();
    initializeBrokerId();
    setNavTabs([]);
  }, []);

  useEffect(() => {
    if (htmlQuerySelector) {
      setHighlightColors();
    }
  }, [htmlQuerySelector]);

  async function initializeBrokerId() {
    const { broker } = await getBrokerData();
    setBrokerId(broker?.id);
  }

  async function checkIfBrokerIsBronze() {
    const { broker } = await getBrokerData();
    if (broker?.plan === 'BROKER_BRONZE') setIsBronze(false);
  }

  function setHighlightColors() {
    hljs.registerLanguage('xml', xml);
    hljs.initHighlightingOnLoad();
    hljs.highlightBlock(document.querySelector('.html'));
  }

  return (
    <DashboardLayout>
      {isBronze ? (
        <UpgradePopupOnBrokerLoginScreen
          startScreen="upgrade_bronze"
          close={() => history.replace('/dashboard')}
        />
      ) : null}
      <TitleContainer>
        <Title>{content.title}</Title>
      </TitleContainer>
      <Section data-test-id="dashboard_academy_section_personal_link">
        <InfoSection dangerouslySetInnerHTML={{ __html: content.subTitle }} />
        <InputLabel>{content.link}</InputLabel>
        <CopyInputFieldWrapper data-test-id="dashboard_academy_section_personal_link_input">
          <CopyInputField
            onClick={e => handleCopyClick(e)}
            ref={inputRef}
            type="text"
            value={isBronze ? '' : brokerSocialUrl}
            readonly
          />
          <img onClick={e => handleCopyClick(e)} src={CopyIcon} alt="Copy" />
        </CopyInputFieldWrapper>
        <TipLabel>Tip</TipLabel>
        <TipText>{content.tip}</TipText>
      </Section>
      <Section>
        <CodeSection data-test-id="dashboard_academy_section_custom_website">
          <Left>
            <InfoSection
              dangerouslySetInnerHTML={{
                __html: content.customButtonTitle,
              }}
            />
            <Textarea
              value={buttonCode}
              ref={codeInputRef}
              readOnly={true}
              data-test-id="dashboard_academy_section_custom_website_textarea"
            >
              {buttonCode}
            </Textarea>
            <CodeBox onClick={e => handleCopyClickCode(e)} className="html">
              {isBronze ? '' : buttonCode}
            </CodeBox>
            <img
              onClick={e => handleCopyClickCode(e)}
              src={CopyIcon}
              alt="Copy"
            />
          </Left>
          <ButtonContainer>
            <InfoSection>{t('Preview')}</InfoSection>
            <Button
              dangerouslySetInnerHTML={{ __html: buttonCode }}
              data-test-id="dashboard_academy_section_custom_website_button"
            />
          </ButtonContainer>
        </CodeSection>
        <TipLabel>Tip</TipLabel>
        <TipText>{content.tip}</TipText>
      </Section>
    </DashboardLayout>
  );
};

Academy.propTypes = {
  history: shape().isRequired,
};

const Textarea = styled.textarea`
  position: absolute;
  left: -9999;
  opacity: 0;
  z-index: -999;
`;

const Left = styled.div`
  width: 70%;
  position: relative;
`;

const CodeSection = styled.div`
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #ccc;
  margin-top: 2rem;
  padding-left: 3.5rem;
  height: calc(100% - 4rem);
  width: 100%;
`;

const ButtonContainer = styled.div`
  flex: 1 1 30%;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 3rem;
  padding-left: 3rem;

  & > p {
    padding-left: 3rem;
  }
`;

const DashboardLayout = styled(Layout)`
  width: 90%;
  max-width: 100rem;
`;

const InfoSection = styled.p`
  font-size: 1.5rem;
  line-height: 1.5;
  color: #76726e;
  width: 100%;

  & span {
    font-weight: bold;
  }
`;

const CodeBox = styled.code`
  width: 100%;
  display: block;
  border-radius: 5px;
  padding: 2rem;
  line-height: 1.5;
  margin-top: 2rem;
  border: 0.15rem solid #d3d4d8;
  cursor: pointer;
  background-color: #f7f7f7;
  white-space: pre-wrap;

  & + img {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
`;

const TitleContainer = styled.div`
  text-align: left;
  margin: 0 auto;
  margin-top: 6rem;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

const Section = styled.div`
  /* Outline */
  border: 1px solid #ccc;
  box-sizing: border-box;
  /* Button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4.69px;
  border-radius: 5px;
  margin: 2.35rem 0;
  padding: 3.5rem;
  padding-bottom: 3.5rem;
  background-color: white;
`;

const InputLabel = styled.label`
  font-size: 1.4rem;
  color: #0e0e0e;
  margin-top: 2rem;
  display: block;
  margin-bottom: 1rem;
`;

const CopyInputFieldWrapper = styled.div`
  display: flex;
  position: relative;
  height: 3.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1.4rem;

  & img {
    position: absolute;
    align-self: center;
    top: 1.3rem;
    right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    cursor: pointer;
  }
`;

const CopyInputField = styled.input`
  font-size: 1.4rem;
  background: #f7f7f7;
  border: 0.15rem solid #d3d4d8;
  padding: 1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  width: 100%;
  position: absolute;
  color: transparent;
  text-shadow: 0 0 0 #0e0e0e;
  transition: 0.2s ease-out;

  :focus {
    outline: none;
  }
`;

const TipLabel = styled.label`
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  color: #525252;
  margin-bottom: 0.4rem;
`;

const TipText = styled.p`
  font-weight: 300;
  font-size: 1.2rem;
  color: #353535;
`;

Academy.propTypes = {
  history: shape().isRequired,
};

export default withRouter(Academy);
