/* eslint-disable no-case-declarations */
import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
import BrokerLogoClickable from '../../../Dashboard/components/BrokerLogoClickable';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const LouiseLeftSide = ({ question }) => {
  const flowStore = useFlowStore();

  return (
    <>
      <BrokerLogoClickable
        logo={flowStore?.distribution?.logo}
        data={flowStore}
      />
      <LogoContainer>
        <RoundImage src={flowStore?.distribution?.avatar} alt="louise" />
      </LogoContainer>
      <TextBalloonContainer>
        <TextBalloon
          dangerouslySetInnerHTML={{
            __html: question,
          }}
        />
      </TextBalloonContainer>
    </>
  );
};

const TextBalloonContainer = styled.div`
  width: 90%;
  height: 15rem;
`;

const TextBalloon = styled.p`
  font-size: 2rem;
  font-weight: 700;
  color: white;
  background: ${({ theme }) => theme.brand.primary};
  line-height: 1.3;
  border-radius: 0 1rem 1rem;
  padding: 2rem 3rem;
  width: 100%;
`;

const LogoContainer = styled.div`
  display: block;
  border-radius: 50%;
  height: 25rem;
  overflow: hidden;
  position: relative;
  text-align: center;
  align-content: center;
  align-items: center;
  width: 25rem;
`;

const RoundImage = styled.img`
  height: 100%;
  width: 100%;
`;

LouiseLeftSide.propTypes = {
  question: string,
};

export default LouiseLeftSide;
