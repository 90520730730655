import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
import CarQuestionBox from '../components/CarQuestionBox';
import { useTranslation } from 'react-i18next';
import {
  getComponentName,
  getNextPath,
  getCurrentStep,
  setSkippedSteps,
  getSkippedSteps,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { setPayloadCar } from '../store';
//
import ChatBox from '../../../../components/ChatBox';

const CarPossession = ({ history }) => {
  const { t } = useTranslation();
  const content = t(getComponentName(), { returnObjects: true });
  const skippedSteps = getSkippedSteps();
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const checkAnswer = answer => {
    if (answer === 'no_car') {
      if (!skippedSteps[2] && !skippedSteps[3]) {
        setPayloadCar('vehicle_status', answer);
        setSkippedSteps(getCurrentStep(), true);
        history.push(getNextPath('last'));
      }
    } else {
      addChatboxQuestion(t(`${getComponentName()}.question`));
      setPayloadCar('vehicle_status', answer);
      if (answer === 'wants_car') {
        addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
      } else if (answer === 'has_car') {
        addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
      }
      history.push(getNextPath());
    }
  };

  return (
    <Layout>
      <Container skipped={skippedSteps}>
        <ChatBox showQuestion={true} />
        <CarQuestionBox
          answers={content.answers}
          btnText="Bevestig"
          handleAnswer={answer => checkAnswer(answer)}
          to={null}
          dataTestId={[
            'underwriting_car_to_be_bought',
            'underwriting_car_already_bought',
          ]}
        >
          {content.question}
        </CarQuestionBox>
      </Container>
    </Layout>
  );
};

CarPossession.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(CarPossession);
