import React from 'react';
import { AccordionBox } from 'wg-fe-ui';
import { shape, string, arrayOf, array } from 'prop-types';
import AccordionHeader from './ProFlowOffersAccordionBoxHeader';
import AccordionContent from './ProFlowOffersAccordionBoxContent';
import styled from 'styled-components';

const ProFlowOffersAccordionBox = ({
  id,
  riskObjectType,
  insurance,
  insuranceCompany,
  insuranceType,
  guarantees,
  options,
  language,
  links,
  premium,
  promotions,
  quote,
  CRM,
  error,
  originalQuote,
  fullCurrentQuote,
}) => {
  return (
    <Container>
      <AccordionBox>
        <AccordionBox.Header>
          <AccordionHeader
            logo={{
              src: insuranceCompany.logo,
              alt: `${insuranceCompany.name} logo`,
            }}
            insuranceName={insuranceCompany.name}
            insuranceType={insurance.name}
            options={options}
            totalPremium={premium?.total_premium?.toFixed(2)}
            interval={quote?.details?.payment_interval?.toUpperCase()}
            error={error}
          />
        </AccordionBox.Header>
        <AccordionBox.Content>
          <AccordionContent
            id={id}
            riskObjectType={riskObjectType}
            insurance={insurance}
            insuranceCompany={insuranceCompany}
            insuranceType={insuranceType}
            guarantees={guarantees}
            options={options}
            language={language}
            links={links}
            premium={premium}
            promotions={promotions}
            quote={quote}
            CRM={CRM}
            error={error}
            originalQuote={originalQuote}
            fullCurrentQuote={fullCurrentQuote}
          />
        </AccordionBox.Content>
      </AccordionBox>
    </Container>
  );
};

const Container = styled.div`
  > div {
    max-width: 100%;
  }
  margin-top: 2rem;
`;
ProFlowOffersAccordionBox.propTypes = {
  id: string.isRequired,
  riskObjectType: string.isRequired,
  insurance: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceCompany: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceType: shape({ key: string.isRequired }),
  guarantees: arrayOf(
    shape({ name: string.isRequired, key: string.isRequired }),
  ),
  options: arrayOf(shape({ name: string.isRequired, key: string.isRequired })),
  language: string.isRequired,
  links: arrayOf(shape({ ipid: string.isRequired, terms: string.isRequired })),
  premium: shape(),
  promotions: array,
  quote: shape(),
  CRM: shape({ name: string.isRequired, key: string.isRequired }),
  error: shape(),
  originalQuote: shape(),
  fullCurrentQuote: shape(),
};

export default ProFlowOffersAccordionBox;
