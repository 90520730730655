import React from 'react';
import { string } from 'prop-types';

const Drone = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="23"
    fill="none"
    viewBox="0 0 35 23"
  >
    <path
      fill={color}
      d="M26.584 21.869a.982.982 0 01-.982-.982v-3.44h-2.918a.982.982 0 01-.981-.982c0-.548.433-.981.981-.981h3.9c.535 0 .981.433.981.981v4.422a.974.974 0 01-.981.982z"
    />
    <path
      fill={color}
      d="M28.483 22.2h-3.849a1.17 1.17 0 01-1.172-1.172c0-.65.522-1.173 1.172-1.173h3.849c.65 0 1.172.523 1.172 1.173a1.17 1.17 0 01-1.172 1.172zM7.685 21.869a.982.982 0 01-.982-.982v-4.422c0-.535.434-.981.982-.981h3.9c.535 0 .98.433.98.981a.974.974 0 01-.98.982h-2.92v3.44a.982.982 0 01-.98.982z"
    />
    <path
      fill={color}
      d="M9.634 22.2H5.786a1.17 1.17 0 01-1.173-1.172c0-.65.523-1.173 1.173-1.173h3.848c.65 0 1.173.523 1.173 1.173A1.17 1.17 0 019.634 22.2zM28.56 0a.982.982 0 00-.982.981v7.34H6.678V.982A.982.982 0 005.697 0a.974.974 0 00-.982.981v8.322c0 .535.434.981.982.981H28.559a.982.982 0 00.982-.98V.98A.974.974 0 0028.559 0zM23.844 11.775v6.117c0 .357-.293.65-.65.65H11.19a.653.653 0 01-.65-.65v-6.117h13.305z"
    />
    <path
      fill={color}
      d="M5.569 11.763h.09c.993 0 1.809-.816 1.809-1.81v-2.79c0-.995-.816-1.81-1.81-1.81h-.09c-.993 0-1.809.815-1.809 1.81V9.94c0 1.007.803 1.823 1.81 1.823zM28.534 11.763h.089c.994 0 1.81-.816 1.81-1.81v-2.79c0-.995-.816-1.81-1.81-1.81h-.09c-.993 0-1.809.815-1.809 1.81V9.94a1.82 1.82 0 001.81 1.823zM10.208 3.275H1.02C.459 3.275 0 2.816 0 2.255v-.05c0-.561.459-1.02 1.02-1.02h9.188c.56 0 1.02.459 1.02 1.02v.05c0 .561-.46 1.02-1.02 1.02zM33.172 3.275h-9.188c-.56 0-1.02-.459-1.02-1.02v-.05c0-.561.46-1.02 1.02-1.02h9.188c.561 0 1.02.459 1.02 1.02v.05c0 .561-.459 1.02-1.02 1.02z"
    />
  </svg>
);

Drone.propTypes = {
  color: string.isRequired,
};

export default Drone;
