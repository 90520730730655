import moment from 'moment';
// import { getNestedObject } from './objectService';

export const createAndSaveSessionObject = () => {
  const sessionObject = {
    id: sessionStorage.getItem('id'),
    data: {},
    gdpr: {
      savePersonalData: false,
    },
    creationDate: moment().format(),
  };

  sessionStorage.setItem('tarificationData', JSON.stringify(sessionObject));
};

export const getSessionObject = () =>
  JSON.parse(sessionStorage.getItem('tarificationData')) || {};

export const setGdprSavePersonalData = boolean => {
  const sessionObject = getSessionObject();
  sessionObject.gdpr.savePersonalData = boolean;
  sessionStorage.setItem('tarificationData', JSON.stringify(sessionObject));
};
