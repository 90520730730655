import React from 'react';
import { shape, number } from 'prop-types';

const ResetIcon = ({ colors, width, height }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <title>Reset</title>
    <g>
      <path
        d="M16.8,9l-1.9-2.2c-0.3-0.4-0.9-0.4-1.3-0.1l-2.2,1.9c-0.4,0.3-0.4,0.9-0.1,1.3c0.3,0.4,0.9,0.4,1.3,0.1l0.6-0.5c-0.6,2.5-2.8,4.3-5.5,4.3C4.5,13.7,2,11.2,2,8.1C2,5,4.5,2.5,7.6,2.5c1.6,0,3.2,0.7,4.3,2c0.4,0.4,1,0.5,1.4,0.1c0.4-0.4,0.5-1,0.1-1.4c-1.4-1.7-3.6-2.6-5.7-2.6C3.4,0.5,0,3.9,0,8.1c0,4.2,3.4,7.6,7.6,7.6c3.6,0,6.7-2.6,7.4-6l0.4,0.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.4-0.1,0.6-0.2C17.1,9.9,17.1,9.3,16.8,9z"
        fill={colors}
      />
    </g>
  </svg>
);

ResetIcon.propTypes = {
  colors: shape().isRequired,
  width: number,
  height: number,
};

export default ResetIcon;
