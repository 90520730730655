import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { withRouter, useRouteMatch } from 'react-router-dom';

import {
  MainNavigation,
  IconActionPower,
  IconDashboard,
  IconUnderwritingFilled,
  IconFamilyFilled,
  IconActivePVFilled,
  IconAccidentFilled,
  IconClaimsFilled,
  IconSavingFilled,
  IconToolkitFilled,
  IconStudent,
  IconSettingsFilled,
  IconWebWorldFilled,
  IconHistoryFilled,
} from 'wg-fe-ui';

import { useTranslation } from 'react-i18next';

import { generateId, getTrialAccount } from '../../../store';
import { addDataForIdWoning } from '../../Flow/Woning/store';
import { addDataForIdUser } from '../../Flow/User/store';
import { addDataForIdCar } from '../../Flow/Car/store';
import { addDataForIdLock } from '../../Flow/Lock/store';
import { addDataForIdFamily } from '../../Flow/Gezin/store';
import { addDataForIdLegal } from '../../Flow/Legal/store';
import { shape, bool } from 'prop-types';

import {
  getBrokerAffiliations,
  getBrokerDistribution,
  getBrokerData,
} from '../../../services/brokerDataService';

import cogoToast from 'cogo-toast';
import { removeFromCheckout } from '../../Marketplace/LayoutMarket/store';
import { getMeData } from '../../../services/meDataService';
import { FlagsProvider } from '../../../hoc/FlagsProviderHoc';
import { BASE_URL } from '../../../../env.config';

const Navigation = ({ history, showLogo }) => {
  const { t } = useTranslation();
  const navContent = t('MainNavigation', { returnObjects: true });
  const configCatClient = useContext(FlagsProvider);
  const [isProfFlowEnabled, setIsProfFlowEnabled] = useState(false);
  const [isCrmEnabled, setIsCrmEnabled] = useState(false);
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const [isContractsEnabled, setIsContractsEnabled] = useState(null);
  const [canAccessPage, setCanAccessPage] = useState(false);
  const [logo, setLogo] = useState('');

  useEffect(() => {
    setFeatures();
    initializeBrokerLogo();
  }, []);

  async function setFeatures() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const emailConfig = {
      email: me?.email,
    };

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
    setIsCrmEnabled(
      await configCatClient.getValueAsync('crm', false, emailConfig),
    );
    setCanAccessPage(
      await configCatClient.getValueAsync('canAccessPage', false, emailConfig),
    );
    setIsCallantFeature(
      await configCatClient.getValueAsync('callantFeature', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
    setIsContractsEnabled(
      await configCatClient.getValueAsync('contracts', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  function getClaimsUrl() {
    switch (BASE_URL) {
      case 'https://api.phoenix.dev.qa.wegroup.be/':
        return 'https://claims.dev.qa.wegroup.be';
      case 'https://api.phoenix.dev.staging.wegroup.be/':
        return 'https://claims.dev.staging.wegroup.be';
      default:
        return 'https://claims.wegroup.be';
    }
  }

  async function initializeBrokerLogo() {
    const { links } = (await getBrokerDistribution()) || {};
    const [logoObject] = links?.filter(({ rel }) => rel === 'logo') || [];
    const { href: logoUrl } = logoObject || {};
    setLogo(logoUrl);
  }

  const generateNewId = () => {
    return generateId().then(res => {
      addDataForIdWoning(res.id);
      addDataForIdUser(res.id);
      addDataForIdCar(res.id);
      addDataForIdLock(res.id);
      addDataForIdFamily(res.id);
      addDataForIdLegal(res.id);
      removeFromCheckout('fire');
      removeFromCheckout('people');
      removeFromCheckout('car');
      removeFromCheckout('legal');
      return res;
    });
  };

  const clickHandler = async (e, link, generateId) => {
    e.preventDefault();

    if (link.includes('http://') || link.includes('https://')) {
      window.location.href = link;
    } else {
      if (generateId) {
        const { items } = (await getBrokerAffiliations()) || [];
        if (items.length >= 1) {
          generateNewId().then(() => {
            history.push(link);
          });
        } else {
          cogoToast.error(
            t(
              'There are no companies linked to your account yet Feel free to adjust this in your settings',
            ),
          );
        }
      } else {
        history.push(link);
      }
    }
  };

  return (
    <StyledNavigation paddingTop={!showLogo}>
      {showLogo && (
        <LogoContainer>
          <Logo src={logo} alt="WeGroup" />
        </LogoContainer>
      )}
      <MainNavigation.Item
        icon={<IconDashboard color="#C4C4C4" />}
        isActive={useRouteMatch('/dashboard')}
        onClick={e => clickHandler(e, '/dashboard')}
        data-test-id="marketplace_dashboard_nav_dashboard"
      >
        {navContent.Dashboard}
      </MainNavigation.Item>
      {canAccessPage && (
        <>
          <MainNavigation.Title>{navContent.Underwriting}</MainNavigation.Title>
          <MainNavigation.Item
            icon={<IconUnderwritingFilled color="#C4C4C4" />}
            onClick={e => clickHandler(e, '/session/user', generateId)}
            data-test-id="marketplace_dashboard_nav_underwriting"
          >
            {navContent.Insure}
          </MainNavigation.Item>
        </>
      )}
      {isProfFlowEnabled && (
        <MainNavigation.Item
          icon={<IconSavingFilled color="#C4C4C4" />}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          isActive={useRouteMatch('/professional-flow')}
          onClick={e => clickHandler(e, '/professional-flow')}
          data-test-id="marketplace_dashboard_nav_tarification"
        >
          {`${navContent.NewTarification}`}
        </MainNavigation.Item>
      )}
      {canAccessPage && (
        <MainNavigation.Item
          icon={<IconHistoryFilled color="#C4C4C4" />}
          // eslint-disable-next-line react-hooks/rules-of-hooks
          isActive={useRouteMatch('/broker-history')}
          onClick={e => clickHandler(e, '/broker-history')}
          data-test-id="marketplace_dashboard_nav_broker_history"
        >
          {navContent.PastConversations}
        </MainNavigation.Item>
      )}
      {isCrmEnabled && !getTrialAccount() && (
        <>
          <MainNavigation.Title>{`${navContent.Management}`}</MainNavigation.Title>
          <MainNavigation.Item
            icon={<IconFamilyFilled color="#C4C4C4" />}
            isActive={useRouteMatch('/sales-management/(customer|customers)')}
            onClick={e => clickHandler(e, '/sales-management/customers')}
            data-test-id="marketplace_dashboard_nav_portfolio"
          >
            {`${navContent.Portfolio}`}
          </MainNavigation.Item>
          <MainNavigation.Item
            icon={<IconClaimsFilled color="#C4C4C4" />}
            isActive={useRouteMatch(
              '/sales-management/contracts/(offers|leads)',
            )}
            onClick={e =>
              clickHandler(e, '/sales-management/contracts/offers/1')
            }
            data-test-id="marketplace_dashboard_nav_offers_and_leadss"
          >
            {`${navContent.Offers}`}
          </MainNavigation.Item>
          {isCallantFeature && (
            <>
              {isContractsEnabled ? (
                <MainNavigation.Item
                  icon={<IconActivePVFilled color="#C4C4C4" />}
                  isActive={useRouteMatch(
                    '/sales-management/contracts/contracts',
                  )}
                  onClick={e =>
                    clickHandler(e, '/sales-management/contracts/contracts')
                  }
                  data-test-id="marketplace_dashboard_nav_contracts"
                >
                  {`${navContent.Contracts}`}
                </MainNavigation.Item>
              ) : (
                ''
              )}
              <MainNavigation.Item
                icon={<IconWebWorldFilled color="#C4C4C4" />}
                isActive={useRouteMatch('/sales-management/affinities')}
                onClick={e => clickHandler(e, '/sales-management/affinities')}
                data-test-id="marketplace_dashboard_nav_affinities"
              >
                {navContent.Affinities}
              </MainNavigation.Item>
            </>
          )}
        </>
      )}

      {canAccessPage && (
        <>
          <MainNavigation.Title>{navContent.Claims}</MainNavigation.Title>
          <MainNavigation.Item
            icon={<IconAccidentFilled color="#C4C4C4" />}
            onClick={e => clickHandler(e, getClaimsUrl())}
            data-test-id="marketplace_dashboard_nav_claims"
          >
            {navContent.Cases}
          </MainNavigation.Item>
          <MainNavigation.Title>{navContent.Extras}</MainNavigation.Title>
          <MainNavigation.Item
            icon={<IconToolkitFilled color="#C4C4C4" />}
            isActive={useRouteMatch('/toolkit')}
            onClick={e => clickHandler(e, '/toolkit')}
            data-test-id="marketplace_dashboard_nav_toolkit"
          >
            {navContent.Toolkit}
          </MainNavigation.Item>
          <MainNavigation.Item
            icon={<IconStudent color="#C4C4C4" />}
            isActive={useRouteMatch('/academy')}
            onClick={e => clickHandler(e, '/academy')}
            data-test-id="marketplace_dashboard_nav_academy"
          >
            {navContent.Academy}
          </MainNavigation.Item>
        </>
      )}
      <MainNavigation.Title>{navContent.Account}</MainNavigation.Title>
      <MainNavigation.Item
        icon={<IconSettingsFilled color="#C4C4C4" />}
        isActive={useRouteMatch('/settings')}
        onClick={e => clickHandler(e, '/settings')}
        data-test-id="marketplace_dashboard_nav_settings"
      >
        {navContent.Settings}
      </MainNavigation.Item>
      <MainNavigation.Line />
      <MainNavigation.Terms
        href="https://wegroup.be/nl/privacy-beleid"
        data-test-id="marketplace_dashboard_nav_terms"
        target="_blank"
        rel="noopener noreferrer"
      >
        {navContent.Terms}
      </MainNavigation.Terms>
      <MainNavigation.Copy>
        &copy; {new Date().getFullYear()} WeGroup NV
        <br />
        {navContent.Rights}
      </MainNavigation.Copy>
      <MainNavigation.SignOutContainer>
        <MainNavigation.Line />
        <MainNavigation.Item
          icon={<IconActionPower color="#C4C4C4" />}
          data-test-id="marketplace_dashboard_nav_logout"
          to="/"
          onClick={() => (window.location.href = '#/logout')}
        >
          {navContent.SignOut}
        </MainNavigation.Item>
      </MainNavigation.SignOutContainer>
    </StyledNavigation>
  );
};
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  max-height: 10rem;
  padding: 2rem 6rem 2.5rem 2rem;
`;

const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledNavigation = styled(MainNavigation)`
  padding-top: ${({ paddingTop }) => (paddingTop ? '9.5rem' : '0rem')};
`;

Navigation.propTypes = {
  history: shape().isRequired,
  showLogo: bool.isRequired,
};

export default withRouter(Navigation);
