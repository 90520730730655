import React from 'react';
import { string } from 'prop-types';

const Juwels = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="24"
    fill="none"
    viewBox="0 0 19 24"
  >
    <path
      fill={color}
      d="M6.35 2.214H4.416L6.36 0h1.794L6.35 2.214zM8.132 2.214l.909-1.119.885 1.119H8.132zM11.697 2.214L9.927 0h1.747l1.946 2.214h-1.923zM7.398 6.594L6.21 3.588H4.38l2.657 3.006h.361zM9.204 6.594l1.177-3.006H7.689l1.189 3.006h.326zM11.033 6.594l2.633-3.006H11.86l-1.188 3.006h.361z"
    />
    <path
      fill={color}
      d="M9.017 5.942C4.043 5.942 0 9.996 0 14.97S4.043 24 9.017 24c4.975 0 9.018-4.054 9.018-9.03.012-4.974-4.043-9.028-9.018-9.028zm6.863 9.029a6.867 6.867 0 01-6.863 6.862 6.867 6.867 0 01-6.862-6.862c.012-3.787 3.088-6.862 6.862-6.862a6.867 6.867 0 016.863 6.862z"
    />
  </svg>
);

Juwels.propTypes = {
  color: string.isRequired,
};

export default Juwels;
