/* eslint-disable no-unused-expressions*/
import React, { useState } from 'react';
import Section from './Section';
import styled from 'styled-components';
import { func, bool, shape, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextInput } from 'wg-fe-ui';
import DashboardSettingsPasswordChecks from '../../../components/DashboardSettingsPasswordChecks';

const PasswordSettings = ({
  disabled,
  errors,
  handleChange,
  values,
  setFieldTouched,
  loggedInEmail,
  setErrors,
}) => {
  const [isPasswordTouched, setIsPasswordTouched] = useState(false);
  const { t } = useTranslation();

  return (
    <Section data-test-id="dashboard_settings_section_user_settings">
      <Section.Content data-test-id="dashboard_settings_section_user_settings_content">
        <div data-test-id="dashboard_settings_section_user_settings_content_change_password">
          <Section.Title>
            {t('Settings.PasswordSettings.change password')}
          </Section.Title>
          <InputsRow data-test-id="dashboard_settings_section_user_settings_content_change_password_loggedIn_info">
            <TextInput
              type="email"
              name="loggedInEmail"
              dataTestId="dashboard_settings_section_user_settings_content_change_password_loggedIn_email"
              disabled={true}
              value={loggedInEmail}
            >
              {t('Settings.PasswordSettings.logged in email')}
            </TextInput>
            <OldPassword
              error={errors.oldPassword}
              type="password"
              name="oldPassword"
              dataTestId="dashboard_settings_section_user_settings_content_change_password_oldPassword"
              disabled={disabled}
              value={values.oldPassword}
              onChange={handleChange}
              setFieldTouched={setFieldTouched}
            >
              {t('Settings.PasswordSettings.old password')}
            </OldPassword>
          </InputsRow>
          <InputsRow data-test-id="dashboard_settings_section_user_settings_content_change_password_form">
            <TextInput
              error={errors.newPassword}
              type="password"
              name="newPassword"
              data-test-id="dashboard_settings_section_user_settings_content_change_password_password"
              disabled={disabled}
              value={values.newPassword}
              onChange={handleChange}
              autoComplete="new-password"
              onFocus={() => {
                if (!isPasswordTouched) setIsPasswordTouched(true);
              }}
              setFieldTouched={setFieldTouched}
            >
              {t('Settings.PasswordSettings.password')}
            </TextInput>
            <TextInput
              data-test-id="dashboard_settings_section_user_settings_content_change_password_confirm_password"
              name="confirmPassword"
              type="password"
              error={errors.confirmPassword}
              disabled={disabled}
              value={values.confirmPassword}
              onChange={handleChange}
              autoComplete="new-password"
              setFieldTouched={setFieldTouched}
            >
              {t('Settings.PasswordSettings.confirm password')}
            </TextInput>
          </InputsRow>
          <DashboardSettingsPasswordChecks
            oldPassword={values.oldPassword}
            newPassword={values.newPassword}
            confirmNewPassword={values.confirmPassword}
            isTouched={isPasswordTouched}
            isPasswordValid={isValid => {
              isValid ? setErrors({}) : setErrors({ main: 'not valid' });
            }}
          />
        </div>
      </Section.Content>
    </Section>
  );
};

const InputsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  > div {
    width: 47%;
    margin-top: 2rem;
  }
`;

const OldPassword = styled(TextInput)`
  margin-top: 2rem;
  width: 47%;
`;

PasswordSettings.defaultProps = {
  disabled: false,
};

PasswordSettings.propTypes = {
  setPassword: func,
  canSave: func,
  disabled: bool,
  errors: shape(),
  handleChange: func,
  values: object,
  setFieldTouched: func,
  loggedInEmail: string,
  setErrors: func,
};

export default PasswordSettings;
