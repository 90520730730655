import React from 'react';
import { shape } from 'prop-types';

const BrokerBackground = ({ colors }) => (
  <svg
    width="150"
    height="75"
    viewBox="0 0 150 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M108.159 27.6711C97.4493 27.6711 88.7196 36.4009 88.7196 47.1105C88.7196 57.8202 97.4493 66.5499 108.159 66.5499C118.869 66.5499 127.598 57.8202 127.598 47.1105C127.598 36.4009 118.869 27.6711 108.159 27.6711ZM108.339 61.5101C100.401 61.5101 93.9394 55.0483 93.9394 47.1105C93.9394 39.1728 100.401 32.711 108.339 32.711C116.277 32.711 122.739 39.1728 122.739 47.1105C122.739 55.0483 116.277 61.5101 108.339 61.5101Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M108.159 66.5499C97.4313 66.5499 88.7196 57.8382 88.7196 47.1105C88.7196 36.3829 97.4313 27.6711 108.159 27.6711C118.887 27.6711 127.598 36.3829 127.598 47.1105C127.598 57.8382 118.887 66.5499 108.159 66.5499Z"
      stroke={colors.brand.secondary}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
    />
    <path
      d="M108.339 61.5098C100.401 61.5098 93.9395 55.048 93.9395 47.1102C93.9395 39.1725 100.401 32.7107 108.339 32.7107C116.277 32.7107 122.739 39.1725 122.739 47.1102C122.739 55.048 116.277 61.5098 108.339 61.5098Z"
      stroke={colors.brand.secondary}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
    />
    <path
      d="M35.2613 27.6711C24.5516 27.6711 15.8219 36.4009 15.8219 47.1105C15.8219 57.8202 24.5516 66.5499 35.2613 66.5499C45.9709 66.5499 54.7006 57.8202 54.7006 47.1105C54.7006 36.4009 45.9709 27.6711 35.2613 27.6711ZM35.4413 61.5101C27.5035 61.5101 21.0417 55.0483 21.0417 47.1105C21.0417 39.1728 27.5035 32.711 35.4413 32.711C43.379 32.711 49.8408 39.1728 49.8408 47.1105C49.8408 55.0483 43.379 61.5101 35.4413 61.5101Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M35.2613 66.5499C24.5336 66.5499 15.8219 57.8382 15.8219 47.1105C15.8219 36.3829 24.5336 27.6711 35.2613 27.6711C45.9889 27.6711 54.7006 36.3829 54.7006 47.1105C54.7006 57.8382 45.9889 66.5499 35.2613 66.5499Z"
      stroke={colors.brand.secondary}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
    />
    <path
      d="M35.4413 61.5098C27.5035 61.5098 21.0417 55.048 21.0417 47.1102C21.0417 39.1725 27.5035 32.7107 35.4413 32.7107C43.379 32.7107 49.8408 39.1725 49.8408 47.1102C49.8408 55.048 43.379 61.5098 35.4413 61.5098Z"
      stroke={colors.brand.secondary}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
    />
    <path
      d="M1.51245 67.1975H148.208"
      stroke={colors.brand.lighter}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M82.0598 73.4255H114.747"
      stroke={colors.brand.lighter}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M99.3392 15.1614H58.9306C58.3366 15.1614 57.8506 14.6754 57.8506 14.0814C57.8506 13.4874 58.3366 13.0015 58.9306 13.0015H99.3392C99.9332 13.0015 100.419 13.4874 100.419 14.0814C100.419 14.6754 99.9332 15.1614 99.3392 15.1614Z"
      fill={colors.brand.light}
    />
    <path
      d="M62.3504 47.0205C62.3504 47.6145 61.8644 48.1004 61.2704 48.1004H35.3512C34.9553 48.1004 34.5773 47.8664 34.3973 47.5065C34.2173 47.1465 34.2533 46.7145 34.4873 46.3905L58.0665 13.4516C58.4085 12.9656 59.0925 12.8576 59.5785 13.1996C60.0644 13.5416 60.1724 14.2255 59.8305 14.7115L37.4572 45.9405H61.2704C61.8644 45.9405 62.3504 46.4265 62.3504 47.0205Z"
      fill={colors.brand.light}
    />
    <path
      d="M99.2312 14.8195L75.472 40.5947L73.3661 42.8806C73.3661 42.8986 73.3481 42.8986 73.3301 42.9166C73.1141 43.1326 72.8441 43.2406 72.5741 43.2406C72.3041 43.2406 72.0521 43.1506 71.8361 42.9526C71.4041 42.5566 71.3681 41.8726 71.7821 41.4227L71.8181 41.3867L73.942 39.0827L97.6473 13.3796C98.0433 12.9476 98.7272 12.9116 99.1772 13.3256C99.6092 13.6855 99.6452 14.3695 99.2312 14.8195Z"
      fill={colors.brand.light}
    />
    <path
      d="M108.249 48.1003C107.781 48.1003 107.331 47.7763 107.205 47.3083L94.8933 1.85976C94.7313 1.28378 95.0733 0.689796 95.6493 0.527801C96.2252 0.365807 96.8192 0.707792 96.9812 1.28377L109.293 46.7323C109.455 47.3083 109.113 47.9023 108.537 48.0643C108.429 48.0823 108.339 48.1003 108.249 48.1003Z"
      fill={colors.brand.light}
    />
    <path
      d="M35.4413 50.5302C37.33 50.5302 38.8611 48.9991 38.8611 47.1103C38.8611 45.2216 37.33 43.6904 35.4413 43.6904C33.5525 43.6904 32.0214 45.2216 32.0214 47.1103C32.0214 48.9991 33.5525 50.5302 35.4413 50.5302Z"
      fill={colors.brand.primary}
    />
    <path
      d="M108.339 50.5302C110.228 50.5302 111.759 48.9991 111.759 47.1103C111.759 45.2216 110.228 43.6904 108.339 43.6904C106.45 43.6904 104.919 45.2216 104.919 47.1103C104.919 48.9991 106.45 50.5302 108.339 50.5302Z"
      fill={colors.brand.primary}
    />
    <path
      d="M95.9373 2.65163H86.0197C85.4257 2.65163 84.9397 2.16565 84.9397 1.57166C84.9397 0.977684 85.4257 0.491699 86.0197 0.491699H95.9373C96.5313 0.491699 97.0173 0.977684 97.0173 1.57166C97.0173 2.16565 96.5493 2.65163 95.9373 2.65163Z"
      fill={colors.brand.light}
    />
    <path
      opacity="0.2"
      d="M60.8205 45.9404H57.9406V48.1004H60.8205V45.9404Z"
      fill={colors.black}
    />
    <path
      opacity="0.2"
      d="M75.4721 40.5764L73.3661 42.8623C73.3661 42.8803 73.3481 42.8803 73.3301 42.8983L71.8002 41.3684L73.9241 39.0645L75.4721 40.5764Z"
      fill={colors.black}
    />
    <path
      d="M68.0203 53.8602C71.7481 53.8602 74.7701 50.8382 74.7701 47.1104C74.7701 43.3826 71.7481 40.3606 68.0203 40.3606C64.2925 40.3606 61.2705 43.3826 61.2705 47.1104C61.2705 50.8382 64.2925 53.8602 68.0203 53.8602Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M68.0203 53.8602C71.7481 53.8602 74.7701 50.8382 74.7701 47.1104C74.7701 43.3826 71.7481 40.3606 68.0203 40.3606C64.2925 40.3606 61.2705 43.3826 61.2705 47.1104C61.2705 50.8382 64.2925 53.8602 68.0203 53.8602Z"
      stroke={colors.brand.secondary}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
    />
    <path
      d="M68.2182 48.0642C68.1282 48.0822 68.0203 48.1002 67.9303 48.1002C67.4623 48.1002 67.0123 47.7762 66.8863 47.3083L56.8966 10.5714L56.4286 8.8795C56.3746 8.66351 56.3746 8.42951 56.4466 8.23152C56.5546 7.90753 56.8246 7.65554 57.1846 7.54755C57.7426 7.40355 58.3006 7.70954 58.4806 8.23152C58.4986 8.24952 58.4986 8.28552 58.4986 8.30352L59.1105 10.5714L68.9562 46.7323C69.1362 47.3083 68.7942 47.9022 68.2182 48.0642Z"
      fill={colors.brand.light}
    />
    <path
      opacity="0.2"
      d="M59.1285 10.5716H56.8966L56.4286 8.87967C56.3746 8.66368 56.3746 8.42968 56.4466 8.23169H58.4806C58.4986 8.24969 58.4986 8.28569 58.4986 8.30369L59.1285 10.5716Z"
      fill={colors.black}
    />
    <path
      d="M61.6664 8.19543H51.8747C50.6507 8.19543 49.6608 7.20546 49.6608 5.9815C49.6608 4.75754 50.6507 3.76758 51.8747 3.76758H61.6664C62.8903 3.76758 63.8803 4.75754 63.8803 5.9815C63.8803 7.20546 62.8903 8.19543 61.6664 8.19543Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M63.8803 5.9815C63.8803 6.0175 63.8803 6.0355 63.8803 6.0715C63.8623 6.64748 63.6103 7.16947 63.2323 7.54746C62.8363 7.94344 62.2784 8.19543 61.6664 8.19543H51.8747C50.6867 8.19543 49.7148 7.25946 49.6608 6.0715C49.6608 6.0355 49.6608 6.0175 49.6608 5.9815C49.6608 5.36952 49.9128 4.81154 50.3087 4.41555C50.7047 4.01957 51.2627 3.76758 51.8747 3.76758H61.6664C62.8903 3.76758 63.8803 4.75754 63.8803 5.9815Z"
      stroke={colors.brand.secondary}
      strokeWidth="2.15993"
      strokeMiterlimit="10"
    />
  </svg>
);
BrokerBackground.propTypes = {
  colors: shape().isRequired,
};

export default BrokerBackground;
