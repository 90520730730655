import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const Vehicle = ({ title, color }) => (
  <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305.43 139.83">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M302.75,58.73c-19.53-21-101.49-19.38-101.49-19.38L184.73,10.8C141.46-5,62.66-1.79,30.29,9.77,26.15,11.26,0,43,0,55.1L2.46,99.57a10.7,10.7,0,0,0,10.29,7.77l22.53,1.08.71,6.41a31.4,31.4,0,0,0,61.48,0l.5-6.41H211.63l1.08,6.41a31.4,31.4,0,0,0,61.48,0l1.21-6.41,13.72-1.08a10.68,10.68,0,0,0,10.28-7.77L305,79.86C306,76.28,305.28,61.46,302.75,58.73Zm-236,70.32a20.45,20.45,0,0,1-19.34-14.22h0a20.15,20.15,0,0,1-1.3-6.41,20.65,20.65,0,0,1,41.29,0,20.15,20.15,0,0,1-1.3,6.41h0A20.46,20.46,0,0,1,66.73,129.05Zm176.72,0a20.45,20.45,0,0,1-19.34-14.22h0a20.15,20.15,0,0,1-1.3-6.41,20.65,20.65,0,0,1,41.29,0,20.15,20.15,0,0,1-1.3,6.41h0A20.46,20.46,0,0,1,243.45,129.05Z"
        />
      </g>
    </g>
  </Svg>
);

const Svg = styled.svg`
  max-width: 3rem;
  max-height: 3rem;
  margin-right: 0.5rem;
`;

Vehicle.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Vehicle.propTypes = {
  title: string,
  color: string,
};

export default Vehicle;
