import React from 'react';
import { Section, OverviewTable } from 'wg-fe-ui';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import { getNestedObject } from '../../../services/objectService';

const ExtraBuildingData = ({ addressData }) => {
  const { t } = useTranslation();

  const mainBuildingData = addressData.parcel.main_building;

  const isApartmentValues = {
    true: 'Yes',
    false: 'No',
    null: '--',
  };
  const isApartment =
    isApartmentValues[mainBuildingData.is_appartment_building];

  const buildingTypes = {
    open: 'Detached',
    closed: 'Terraced',
    halfopen: 'Semi-detached',
    apartment: 'Apartment',
  };

  const buildingIsOfType = buildingTypes[mainBuildingData.house_type];

  return (
    <Section data-test-id="dashboard_toolkit_addresslookup_section_annexes">
      <Section.Title>{t('Main building (additional info)')}</Section.Title>
      <AdditionalInfoContent>
        <OverviewTable>
          <OverviewTable.Head>
            <StyledTableData width={'26%'}>
              {t('Building type')}
            </StyledTableData>
            <StyledTableData width={'26%'}>
              {t('Appartment building')}
            </StyledTableData>
            <StyledTableData width={'26%'}>
              {t('# of addresses')}
            </StyledTableData>
            <StyledTableData width={'26%'}>{t('Floors')}</StyledTableData>
          </OverviewTable.Head>
          <OverviewTable.Body>
            <StyledRow>
              <OverviewTable.BodyCell>
                {t(buildingIsOfType)}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>{t(isApartment)}</OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.number_of_addresses !== null
                  ? t(mainBuildingData.number_of_addresses)
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.floors !== null
                  ? t(mainBuildingData.floors)
                  : '--'}
              </OverviewTable.BodyCell>
            </StyledRow>
          </OverviewTable.Body>
          <br />
          <OverviewTable.Head>
            <StyledTableData width={'26%'}>{t('Volume')}</StyledTableData>
            <StyledTableData width={'26%'}>{t('Surface')}</StyledTableData>
            <StyledTableData width={'26%'}>{t('Outline')}</StyledTableData>
            <StyledTableData width={'26%'}>{t('Facade width')}</StyledTableData>
          </OverviewTable.Head>
          <OverviewTable.Body>
            <StyledRow>
              <OverviewTable.BodyCell>
                {mainBuildingData.volume !== null
                  ? mainBuildingData.volume + ' m³'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.surface !== null
                  ? mainBuildingData.surface + ' m²'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.outline !== null
                  ? mainBuildingData.outline + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.facade_width !== null
                  ? mainBuildingData.facade_width + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
            </StyledRow>
          </OverviewTable.Body>
          <br />
          <OverviewTable.Head>
            <StyledTableData width={'26%'}>{t('Height')}</StyledTableData>
            <StyledTableData width={'26%'}>{t('Wall height')}</StyledTableData>
            <StyledTableData width={'26%'}>{t('Roof height')}</StyledTableData>
            <StyledTableData width={'26%'}>{t('Roof surface')}</StyledTableData>
          </OverviewTable.Head>
          <OverviewTable.Body>
            <StyledRow>
              <OverviewTable.BodyCell>
                {mainBuildingData.height !== null
                  ? mainBuildingData.height + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.height_walls !== null
                  ? mainBuildingData.height_walls + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.height_roof !== null
                  ? mainBuildingData.height_roof + ' m'
                  : '--'}
              </OverviewTable.BodyCell>
              <OverviewTable.BodyCell>
                {mainBuildingData.surface_roof !== null
                  ? mainBuildingData.surface_roof + ' m²'
                  : '--'}
              </OverviewTable.BodyCell>
            </StyledRow>
          </OverviewTable.Body>
        </OverviewTable>
      </AdditionalInfoContent>
    </Section>
  );
};
const AdditionalInfoContent = styled(Section.Content)`
  padding: 1rem 0 0 4rem;
  border: 0.1rem solid #cccccc;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
  background-color: white;
  table {
    margin-bottom: 2rem;
  }
  tbody {
    box-shadow: none;
  }
`;

const StyledTableData = styled(OverviewTable.HeaderCell)`
  width: 26%;
  font-weight: 700;
  font-size: 1.6rem;
  color: #5b5550;
  padding-bottom: 0rem;
`;

const StyledRow = styled(OverviewTable.Row)`
  box-shadow: none;
  border: 0;
  &:hover * {
    background-color: white;
  }
  td {
    :first-of-type,
    :last-of-type {
      padding-left: 0;
    }
    border-bottom: 0;
    padding-left: 0;
  }
`;

ExtraBuildingData.propTypes = {
  addressData: object.isRequired,
};

export default ExtraBuildingData;
