import React from 'react';

const AnimalsDamage = () => (
  <svg viewBox="0 0 201.93 214.75">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'animals_damage'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M109.86 202.16l17.37 2v2.11a6.12 6.12 0 01-5.12 6L111.8 214a59.65 59.65 0 01-19.23 0l-10.31-1.69a6.13 6.13 0 01-5.13-6v-2.13l17.38-2a69.11 69.11 0 0115.35-.02zM131.39 146.59l-20.53-2.66a68.69 68.69 0 00-17.35 0L73 146.59a23.16 23.16 0 002.77 46.13l18.66-2.11a69.7 69.7 0 0115.55 0l18.66 2.11a23.16 23.16 0 002.77-46.13zm-49.26 28.5A5.89 5.89 0 1188 169.2a5.89 5.89 0 01-5.87 5.89zm39.36 0a5.89 5.89 0 115.89-5.89 5.89 5.89 0 01-5.89 5.89z"
        />
        <path
          className="prefix__cls-1"
          d="M201.08 51.77l-35.57-14.34a12.86 12.86 0 00-9.22-.15l-8.25 3a55 55 0 001.38-12c0-13-7.76-24-11-28a.82.82 0 00-1.42.49c-.28 4.3-1.3 14.53-4.82 22.59a48.84 48.84 0 01-10.05 14.88c-.69-.06-1.39-.1-2.09-.1H84.32a25.83 25.83 0 00-2.8.17 48.41 48.41 0 01-10.12-15C67.87 15.3 66.86 5.07 66.57.77a.81.81 0 00-1.45-.46c-3.2 4-11 14.94-11 28A55 55 0 0055.69 41l-10-3.68a12.86 12.86 0 00-9.22.15L.85 51.77a1.37 1.37 0 00-.37 2.31l15.33 13a34.05 34.05 0 0022 8h10.83l-2 3.39a41.07 41.07 0 00-4.22 31.11l9.21 35a33.7 33.7 0 0117.17-7.47l22.7-2.94a83.51 83.51 0 0121.31 0l22.7 2.94a33.66 33.66 0 0117.16 7.46l9.22-35a41.07 41.07 0 00-4.22-31.11l-2-3.39h8.4a34.05 34.05 0 0022-8l15.33-13a1.37 1.37 0 00-.32-2.3zM83.29 104.53a10 10 0 01-16.65 0 1.54 1.54 0 010-1.7 10 10 0 0116.65 0 1.54 1.54 0 010 1.7zm52 0a10 10 0 01-16.65 0 1.54 1.54 0 010-1.7 10 10 0 0116.65 0 1.54 1.54 0 010 1.7z"
        />
      </g>
    </g>
  </svg>
);

export default AnimalsDamage;
