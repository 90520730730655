import React from 'react';
import { string, shape } from 'prop-types';
import AdaptiveCruiseControlSphere from '../../assets/images/drive_assist/adaptive_cruise_control_sphere';
import AttentionAssistSphere from '../../assets/images/drive_assist/attention_assist_sphere';
import AutomaticEmergencyBrakingSphere from '../../assets/images/drive_assist/automatic_emergency_braking_sphere';
import AutomaticParkingSphere from '../../assets/images/drive_assist/automatic_parking_sphere';
import BlindSpotCheckSphere from '../../assets/images/drive_assist/blind_spot_check_sphere';
import EcallSphere from '../../assets/images/drive_assist/ecall_sphere';
import LaneSupportSphere from '../../assets/images/drive_assist/lane_support_sphere';
import ParkingAidSphere from '../../assets/images/drive_assist/parking_aid_sphere';

const LoadingDriveAssistIcons = ({ type, className, colors }) => {
  switch (type) {
    case 'adaptive_cruise_control_sphere':
      return (
        <AdaptiveCruiseControlSphere className={className} colors={colors} />
      );
    case 'attention_assist_sphere':
      return <AttentionAssistSphere className={className} colors={colors} />;
    case 'automatic_emergency_braking_sphere':
      return (
        <AutomaticEmergencyBrakingSphere
          className={className}
          colors={colors}
        />
      );
    case 'automatic_parking_sphere':
      return <AutomaticParkingSphere className={className} colors={colors} />;
    case 'blind_spot_check_sphere':
      return <BlindSpotCheckSphere className={className} colors={colors} />;
    case 'ecall_sphere':
      return <EcallSphere className={className} colors={colors} />;
    case 'lane_support_sphere':
      return <LaneSupportSphere className={className} colors={colors} />;
    case 'parking_aid_sphere':
      return <ParkingAidSphere className={className} colors={colors} />;
    default:
      return <p>[No Icon found]</p>;
  }
};

LoadingDriveAssistIcons.defaultProps = {
  type: '',
};

LoadingDriveAssistIcons.propTypes = {
  type: string,
  className: string,
  colors: shape(),
};

export default LoadingDriveAssistIcons;
