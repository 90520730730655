import React, { useState } from 'react';
import {
  ActionButton,
  RadioOption,
  Modal,
  TertiaryButton,
  IconFamilyFilled,
  IconBaggageFilled,
} from 'wg-fe-ui';
import cogoToast from 'cogo-toast';
import { array, func } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import CustomerForm from './CRMCustomerOverviewNewProspectModalCustomer';
import CompanyForm from './CRMCustomerOverviewNewProspectModalCompany';

import {
  firstName,
  lastName,
  companyName,
  emailNotRequired,
  nationalRegisterNr,
  dateNotRequired,
  cityNotRequired,
  streetnotRequired,
  housenrNotRequired,
  zipcodeNotRequired,
  cbeNotRequired,
} from '../../constants/validationSchemas';
import useForm from '../hooks/useForm';
import LoadingButton from '../components/LoadingButton';
import * as Yup from 'yup';
import { differenceInYears, isFuture } from 'date-fns';
import { createProspect } from '../services/apiRouterService';
import { getBrokerData } from '../services/brokerDataService';
import {
  parseIncDateToObject,
  parseObjectToNewDate,
} from '../services/dateService';

const CRMCustomerOverviewNewProspectModal = ({ getProspects, modalState }) => {
  const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  // const [validationSchema, setValidationSchema] = useState();
  const [showModal, setShowModal] = modalState;
  const { t } = useTranslation();

  function handleBirthValidation() {
    const { birth } = this.parent;
    if (birth) {
      const dateObject = parseIncDateToObject(birth);
      const birthDate = parseObjectToNewDate(dateObject);

      return differenceInYears(new Date(), birthDate) <= 150;
    }
    return true;
  }

  function handleFutureValidation() {
    const { birth } = this.parent;
    if (birth) {
      const dateObject = parseIncDateToObject(birth);
      const birthDate = parseObjectToNewDate(dateObject);

      return !isFuture(birthDate);
    }
    return true;
  }

  const validationSchemaCostumer = Yup.object().shape({
    firstName: firstName,
    lastName: lastName,
    email: emailNotRequired,
    municipalityName: cityNotRequired,
    streetName: streetnotRequired,
    streetNumber: housenrNotRequired,
    postalCode: zipcodeNotRequired,
    birth: dateNotRequired
      .test(
        'valid-birth',
        t('Customer must be younger than 150 years of age'),
        handleBirthValidation,
      )
      .test(
        'not-future',
        t('Date cant be in the future'),
        handleFutureValidation,
      ),
    phone: Yup.string()
      .test('Phone number', t(`Verify.errors.invalid`), value => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length <= 3) return true;
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch {
              return false;
            }
            return false;
          }
          return false;
        }
        return true;
      })
      .nullable(),
    national_register_nr: nationalRegisterNr,
  });

  const validationSchemaCompany = Yup.object().shape({
    companyName: companyName,
    email: emailNotRequired,
    municipalityName: cityNotRequired,
    cbe: cbeNotRequired,
    streetName: streetnotRequired,
    streetNumber: housenrNotRequired,
    postalCode: zipcodeNotRequired,
    phone: Yup.string()
      .test('Phone number', t(`Verify.errors.invalid`), value => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length <= 3) return true;
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch {
              return false;
            }
            return false;
          }
          return false;
        }
        return true;
      })
      .nullable(),
  });

  const {
    errors: costumerErrors,
    values: costumerValues,
    handleSubmit: costumerHandleSubmit,
    handleChange: costumerHandleChange,
    resetForm: customerHandleReset,
  } = useForm({
    validationSchema: validationSchemaCostumer,
  });

  const {
    errors: companyErrors,
    values: companyValues,
    handleSubmit: companyHandleSubmit,
    handleChange: companyHandleChange,
    resetForm: companyHandleReset,
  } = useForm({
    validationSchema: validationSchemaCompany,
  });

  async function onsubmit(values) {
    setLoading(true);
    const closeLoadingToast = cogoToast.loading(t('Adding prospect'), {
      hideAfter: 0,
    });
    const { distribution_id } = await getBrokerData();
    let birthObject = undefined;
    if (values?.birth) {
      birthObject = values.birth.split('/');
    }
    let payload;
    if (selected === 'customers') {
      payload = {
        first_name: values?.firstName,
        last_name: values?.lastName,
        email: values?.email,
        birth: birthObject
          ? new Date(
              birthObject[2],
              parseInt(birthObject[1]) - 1,
              birthObject[0],
            )
          : undefined,
        telephonenr: values?.phone
          ? values?.phone.replace(/ /g, '').length <= 3
            ? undefined
            : values?.phone.replace(/ /g, '')
          : undefined,
        national_register_nr: values?.national_register_nr,
        address: values?.municipalityName
          ? {
              street: values?.streetName,
              housenr: values?.streetNumber,
              zipcode: values?.postalCode,
              city: values?.municipalityName,
              boxnr: values?.boxNumber ? values?.boxNumber : undefined,
              country_code: 'BE',
            }
          : undefined,
      };
    }
    if (selected === 'companies') {
      payload = {
        name: values?.companyName,
        email: values?.email,
        cbe: values?.cbe ? values?.cbe.split('.').join('') : undefined,
        telephonenr: values?.phone
          ? values?.phone.replace(/ /g, '').length <= 3
            ? undefined
            : values?.phone.replace(/ /g, '')
          : undefined,
        address: values?.municipalityName
          ? {
              street: values?.streetName,
              housenr: values?.streetNumber,
              zipcode: values?.postalCode,
              city: values?.municipalityName,
              boxnr: values?.boxNumber ? values?.boxNumber : undefined,
              country_code: 'BE',
            }
          : undefined,
      };
    }

    if (!payload) return;
    const [resp, status] = await createProspect(
      distribution_id,
      selected,
      payload,
    );
    closeLoadingToast();
    if (status === 201) {
      getProspects();
      cogoToast.success(t('Prospect added successfully'));
      companyHandleReset();
      customerHandleReset();
      setShowModal(false);
    } else if (resp.msg != null || resp.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
    setLoading(false);
  }

  return (
    <Modal
      canClose
      modalElementClass="modal-full-width"
      showModal={showModal}
      setShowModal={setShowModal}
      title={t('Add a new prospect')}
      width={'100%'}
    >
      <Container>
        <ModalSelectInputGroup>
          <RadioOption
            icon={<IconFamilyFilled color="#C4C4C4" />}
            handleChange={({ name }) => setSelected(name)}
            otherProps={{}}
            checked={selected === 'customers'}
            name="customers"
            data-test-id="new_prospect_customers"
          >
            {t('New natural entity')}
          </RadioOption>
          <RadioOption
            icon={<IconBaggageFilled color="#C4C4C4" />}
            handleChange={({ name }) => setSelected(name)}
            otherProps={{}}
            checked={selected === 'companies'}
            name="companies"
            data-test-id="new_prospect_companies"
          >
            {t('New legal entity')}
          </RadioOption>
        </ModalSelectInputGroup>
        {selected === 'customers' ? (
          <CustomerForm
            errors={costumerErrors}
            values={costumerValues}
            handleChange={costumerHandleChange}
          />
        ) : null}
        {selected === 'companies' ? (
          <CompanyForm
            errors={companyErrors}
            values={companyValues}
            handleChange={companyHandleChange}
          />
        ) : null}
        <Modal.ModalActions position="right">
          <TertiaryButton
            label="Cancel"
            onClick={() => setShowModal(!showModal)}
            data-test-id="new_prospect_btn_cancel"
          />
          {!loading ? (
            <ActionButton
              disabled={!selected}
              level="primary"
              onClick={() => {
                if (selected === 'customers')
                  return costumerHandleSubmit(onsubmit);
                if (selected === 'companies')
                  return companyHandleSubmit(onsubmit);
              }}
              data-test-id="new_prospect_btn_save"
            >
              {t('Save')}
            </ActionButton>
          ) : (
            <StyledLoadingButton>{t('Saving')}</StyledLoadingButton>
          )}
        </Modal.ModalActions>
      </Container>
    </Modal>
  );
};

const StyledLoadingButton = styled(LoadingButton)`
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  height: 4rem;
  transition: 0.1s ease-in-out;
  display: flex;
  margin: 0;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding || '1rem 0.4rem'};
`;

const ModalSelectInputGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  .test {
    width: 100%;
  }
`;

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

CRMCustomerOverviewNewProspectModal.propTypes = {
  modalState: array,
  getProspects: func,
};

export default CRMCustomerOverviewNewProspectModal;
