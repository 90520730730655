import React, { useState, useContext, useEffect } from 'react';
import { shape, string, array, func } from 'prop-types';
import InputSelector from './ProFlowInputSelector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section, H4 } from 'wg-fe-ui';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import _ from 'lodash';

const ProFlowQuestionsHome = ({
  taggedQuestions,
  allQuestions,
  tag,
  questionErrors,
  focus,
  setFocus,
}) => {
  const [questionList, setQuestionList] = useState(taggedQuestions);
  const [annexesData, setAnnexesData] = useState([]);
  const [roomData, setRoomData] = useState({
    living_rooms: 0,
    kitchens: 0,
    bedrooms: 0,
    bathrooms: 0,
    storage_rooms: 0,
    other_rooms: 0,
    garages: 0,
  });
  const { answers } = useContext(ProFlowStorageContext);
  const { i18n } = useTranslation();

  const fullWidthSpans = [
    '6855cde6-9861-4c7e-b0b1-98dbc063a975',
    '9bd9dede-2613-4778-8494-c4579c97c566',
  ];
  const thirdWidthSpans = [];
  const halfSpanLonely = [];

  function getSpanLenght(id) {
    if (fullWidthSpans.includes(id) || halfSpanLonely.includes(id)) return 6;
    if (thirdWidthSpans.includes(id)) return 2;
    return 3;
  }

  function getElementwidth(id) {
    if (halfSpanLonely.includes(id)) return 'calc(50% - 10px);';
  }

  useEffect(() => {
    filterRequiredAnswers();
  }, [answers]);

  function roomsToArray(toBeShowedQuestions) {
    const indexPosition = _.findIndex(toBeShowedQuestions, item => {
      return item.rules.input_type === 'ROOM';
    });
    const roomsFilteredList = _.filter(toBeShowedQuestions, item => {
      return item.rules.input_type === 'ROOM';
    });
    const newQuestionList = _.filter(toBeShowedQuestions, item => {
      return item.rules.input_type !== 'ROOM';
    });
    const insertedQuestionList = [
      ...newQuestionList.slice(0, indexPosition),
      {
        rooms: roomsFilteredList,
        rules: {
          input_type: 'ROOMS',
        },
      },
      ...newQuestionList.slice(indexPosition),
    ];
    return insertedQuestionList;
  }

  function filterRequiredAnswers() {
    let toBeShowedQuestions = [];
    toBeShowedQuestions = taggedQuestions.filter(question => {
      const { required } = question;
      return showCriteria(required, answers);
    });
    const questions = roomsToArray(toBeShowedQuestions);
    setQuestionList(questions);
  }

  function showCriteria(required = [], _answers = []) {
    // show input if nothing is required
    if (required.length === 0) return true;
    const nulledRequires = required.filter(({ answer }) => answer === null);
    const generalRequires = required.filter(({ answer }) => answer !== null);

    const shouldShowQuestion =
      areAllNulledQuestionsAnswered(nulledRequires, answers) ||
      isOneOfQeneralQuestionsAnswered(generalRequires, answers);

    return shouldShowQuestion;
  }

  function areAllNulledQuestionsAnswered(required = [], _answers = []) {
    // if there are no nulled questions return true
    if (required.length === 0) return false;

    // return true if all nulled questions are answered
    return required
      .map(({ id }) => _answers.some(({ id: _id }) => id === _id))
      .includes(false);
  }

  function isOneOfQeneralQuestionsAnswered(required = [], _answers = []) {
    // if there are no questions return true
    if (required.length === 0) return false;
    // return true if id and answer matches
    return required.some(require =>
      _answers.some(
        ({ id, answer }) => require.id === id && answer === require.answer,
      ),
    );
  }

  return (
    questionList?.length > 0 && (
      <>
        <Title>{i18n.t(`Information from the ${tag}`)}</Title>
        <SubTitle>{i18n.t('Please fill in your personal data')}</SubTitle>
        <StyledSection background>
          {questionList.map(
            ({
              id,
              rules,
              title,
              error_messages,
              description,
              insurance_type_tags,
              tag,
              value,
              field,
              rooms,
            }) => {
              if (rules.input_type === 'ROOMS') {
                return (
                  _.find(answers, {
                    id: '69fa32ac-9ce4-4baf-ab55-e13c1d21ac3d',
                  })?.answer === 'LOCATION_SYSTEM' && (
                    <StyledDiv span={6}>
                      <H4>{i18n.t('Room layout of the house')}</H4>
                      <RoomsContainer>
                        {rooms.map(
                          ({
                            id,
                            rules,
                            title,
                            error_messages,
                            description,
                            insurance_type_tags,
                            tag,
                            value,
                            field,
                          }) => {
                            return (
                              <InputSelector
                                key={id}
                                typeTags={insurance_type_tags}
                                rules={rules}
                                questionErrors={questionErrors}
                                name={id}
                                errorMessages={error_messages}
                                title={title?.[i18n.language]}
                                tag={tag}
                                description={
                                  description
                                    ? description[i18n.language]
                                    : null
                                }
                                value={value}
                                field={field}
                                roomData={roomData}
                                setRoomData={setRoomData}
                                setFocus={setFocus}
                                focus={focus}
                              />
                            );
                          },
                        )}
                      </RoomsContainer>
                    </StyledDiv>
                  )
                );
              } else {
                return (
                  <StyledDiv
                    key={id}
                    span={getSpanLenght(id)}
                    width={getElementwidth(id)}
                  >
                    <InputSelector
                      typeTags={insurance_type_tags}
                      rules={rules}
                      questionErrors={questionErrors}
                      name={id}
                      errorMessages={error_messages}
                      title={title?.[i18n.language]}
                      tag={tag}
                      description={
                        description ? description[i18n.language] : null
                      }
                      value={value}
                      field={field}
                      annexesData={annexesData}
                      setAnnexesData={setAnnexesData}
                      roomData={roomData}
                      setRoomData={setRoomData}
                      focus={focus}
                      setFocus={setFocus}
                    />
                    {rules.input_type !== 'ROOM' && description && (
                      <InfoContainer>
                        <InfoIcon>i</InfoIcon>
                        <p>{description[i18n.language]}</p>
                      </InfoContainer>
                    )}
                  </StyledDiv>
                );
              }
            },
          )}
        </StyledSection>
      </>
    )
  );
};

const InfoIcon = styled.a`
  align-items: center;
  align-self: center;
  background-color: white;
  border: 0.5px solid #8990a3;
  border-radius: 50%;
  color: #8990a3;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  height: 1.6rem;
  width: 1.6rem;
  text-align: center;
`;

const InfoContainer = styled.p`
  color: #8990a3;
  display: flex;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 2rem;
  p {
    margin-left: 1rem;
    width: 100%;
  }
  svg > path {
    fill: #8990a3;
    margin-right: 2rem;
  }
`;

const StyledDiv = styled.div`
  grid-column: span ${({ span }) => span};
  width: ${({ width }) => width};
  h3 {
    color: #8990a3;
    font-weight: 600;
    letter-spacing: 0.01em;
    font-size: 14px;
  }
`;

const StyledSection = styled(Section.Content)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 36px;
`;

const Title = styled.h2`
  font-size: 2.1rem;
  color: #0e0e0e;
  font-weight: 700;
  margin: 1rem 0;
  margin-top: 3rem;
`;

const SubTitle = styled.h3`
  font-size: 1.6rem;
  color: #8990a3;
  font-weight: 500;
`;

const RoomsContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 2rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

ProFlowQuestionsHome.propTypes = {
  taggedQuestions: shape().isRequired,
  allQuestions: shape().isRequired,
  tag: string,
  questionErrors: array,
  focus: string,
  setFocus: func,
};
export default ProFlowQuestionsHome;
