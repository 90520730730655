import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';
import { func } from 'prop-types';

const MarketplaceLegalWarningPopup = ({ close, name }) => {
  const { t } = useTranslation();
  return (
    <ErrorPopup
      onClick={() => {
        close(name, false);
      }}
      data-test-id={'popup_warning_legal_aid'}
    >
      <PopupContainer onClick={e => e.stopPropagation()}>
        <Close
          onClick={() => {
            close(name, false);
          }}
          data-test-id={'close_popup_warning_legal_aid'}
        >
          x
        </Close>
        <Title>{t('Are you sure?')}</Title>
        <Text
          dangerouslySetInnerHTML={{
            __html: t('legalAidWarningText'),
          }}
        ></Text>
        <ButtonContainer>
          <Button
            secondary
            data-test-id={'marketplace_warning_legal_aid'}
            onClick={() => {
              close(name, false);
            }}
          >
            {t('No')}
          </Button>
          <Button
            data-test-id={'marketplace_warning_legal_aid'}
            onClick={() => {
              close(name, true);
            }}
          >
            {t('Yes')}
          </Button>
        </ButtonContainer>
      </PopupContainer>
    </ErrorPopup>
  );
};

const Close = styled.span`
  font-size: 2.5rem;
  position: absolute;
  top: 1.6rem;
  left: 2.5rem;
  cursor: pointer;
`;

const PopupContainer = styled.div`
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 0 20rem rgba(0, 0, 0, 0.4);
  width: 70rem;
  overflow: auto;
  animation: 0.3s fade;
  padding: 4rem;
  position: relative;

  @keyframes fade {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
`;

const Button = styled.button`
  background-color: ${({ theme, secondary }) =>
    secondary ? 'rgb(193, 193, 193)' : theme.brand.primary};
  border-radius: 0.5rem;
  min-width: 14rem;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  color: ${props => (props.secondary ? 'white' : 'white')};
  border: 0;
  font-weight: bold;
  margin: 1rem;
  text-decoration: none;
  align-self: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
  z-index: 100;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  margin-top: 4rem;
  padding: 1rem 3rem;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }

  &:hover {
    background-color: ${({ secondary, theme }) =>
      secondary ? 'rgb(150, 150, 150)' : theme.brand.lighter};
    color: ${props => (props.secondary ? 'white' : 'white')};
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  @media screen and (max-width: 1025px) {
    transform: scale(0.9);
    margin-bottom: 1rem;

    &:active {
      transform: scale(0.88);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ErrorPopup = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 5rem);
  border-radius: 3rem;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 900;
  text-align: center;
`;
const Text = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  width: 90%;
  margin: 0 auto;
  margin-top: 3rem;
  line-height: 1.5;
  text-align: center;

  & > span {
    font-weight: bold;
  }
`;

MarketplaceLegalWarningPopup.propTypes = {
  startScreen: string.isRequired,
  close: func.isRequired,
  name: string.isRequired,
};

export default MarketplaceLegalWarningPopup;
