import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

import Section from './Section';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';
import { useTranslation } from 'react-i18next';

import ConstructionIcon from '../../assets/images/construction-icon';
import PassiveHouseIcon from '../../assets/images/passive-house-icon';
import SpecialFlooringIcon from '../../assets/images/special-flooring-icon';
import SpecialRoofingIcon from '../../assets/images/special-roofing-icon';
import CellarIcon from '../../assets/images/cellar-icon';
import AtticIcon from '../../assets/images/attic-icon';

const DashboardBrokerHistoryPropertyQuestions = ({ questionInfo }) => {
  const {
    underConstruction,
    specialRoofing,
    isAtticUnhabited,
    passiveHouse,
    specialFlooring,
    isCellarUnhabited,
  } = questionInfo || {};
  const { t } = useTranslation();

  return (
    <Section>
      <Section.Subtitle>{t('History.property.facts.title')}</Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_property_section_statements_concering_residence">
        <DashboardCheckboxWithIcon
          wide={2}
          text={t('History.property.facts.underConstruction')}
          checked={underConstruction}
          disabled={true}
          icon={ConstructionIcon}
        />
        <DashboardCheckboxWithIcon
          wide={2}
          text={t('History.property.facts.passiveHouse')}
          checked={passiveHouse}
          disabled={true}
          icon={PassiveHouseIcon}
        />
        <DashboardCheckboxWithIcon
          wide={2}
          text={t('History.property.facts.specialFlooring')}
          checked={specialFlooring}
          disabled={true}
          icon={SpecialFlooringIcon}
        />
        <DashboardCheckboxWithIcon
          wide={2}
          text={t('History.property.facts.specialRoofing')}
          checked={specialRoofing}
          disabled={true}
          icon={SpecialRoofingIcon}
        />
        <DashboardCheckboxWithIcon
          wide={2}
          text={t('History.property.facts.isCellarUnhabited')}
          checked={isCellarUnhabited}
          disabled={true}
          icon={CellarIcon}
        />
        <DashboardCheckboxWithIcon
          wide={2}
          text={t('History.property.facts.isAtticUnhabited')}
          checked={isAtticUnhabited}
          disabled={true}
          icon={AtticIcon}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

DashboardBrokerHistoryPropertyQuestions.defaultProps = {};

DashboardBrokerHistoryPropertyQuestions.propTypes = {
  questionInfo: shape().isRequired,
};

export default DashboardBrokerHistoryPropertyQuestions;
