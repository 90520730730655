import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ErrorBoundaryHoc from './js/hoc/errorBoundaryHoc.jsx';
import GlobalStyle from './resetStyling';
import { ThemeContextProvider } from './js/services/context/ThemingContext';
import { Helmet } from 'react-helmet';
import i18next from 'i18next';
import './i18n';
import {
  SENTRY_DSN,
  PROJECT_NAME,
  RELEASE,
  ENVIRONMENT,
  ZENDESK_KEY,
} from './env.config';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import packageJson from '../package.json';
import Root from './config/Router';
import Zendesk, { ZendeskAPI } from 'react-zendesk';

const zendeskSettings = {
  webWidget: {
    color: {
      theme: '#FF8000',
      launcherText: '#ffffff',
    },
    position: { horizontal: 'right', vertical: 'bottom' },
    helpCenter: {
      originalArticleButton: true,
      title: {
        '*': 'Search for help',
        fr: "Souhaitez-vous de l'aide",
        nl: 'Help Center',
      },
      messageButton: {
        '*': 'Have your say',
        fr: 'Contactez-nous',
        nl: 'Contacteer ons ',
      },
      chatButton: {
        '*': 'Chat with us',
        fr: 'Waarmee kunnen we u helpen?',
        nl: 'Help Center',
      },
      searchPlaceholder: {
        '*': 'Search our help center',
        fr: "Cherchez dans le centre d'aide",
      },
    },
    chat: {
      hideWhenOffline: false,
      concierge: {
        avatarPath: 'http://files.wegroup.be/avatars/avatar-louise.svg',
        name: 'Louise',
        title: {
          '*': 'Live support',
          fr: 'Support live',
          nl: 'Live support',
        },
      },
      offlineForm: {
        greeting: {
          '*': "We aren't online right now, please leave a message",
          fr:
            'Nous ne sommes pas disponible en ce moment mais laissez-nous un message',
          nl: 'Wij zijn nu offline maar laat een bericht achter',
        },
      },
      profileCard: {
        avatar: true,
        rating: false,
        title: true,
      },
    },
    contactOptions: {
      enabled: true,
      contactButton: {
        '*': 'Call us',
        fr: 'Contactez-nous',
        nl: 'Contacteer ons',
      },
      chatLabelOnline: {
        '*': 'Chat with us',
        fr: 'Démarrer une conversation',
        nl: 'Waarmee kunnen we u helpen?',
      },
      chatLabelOffline: {
        '*': 'We will be back soon',
        fr: 'Nous revenons vers vous au plus vite',
        nl: 'Wij komen er speodig op terug',
      },
      contactFormLabel: {
        '*': 'Drop us a message',
        fr: 'Envoyez-nous un message',
        nl: 'Laat een bericht achter',
      },
    },
    contactForm: {
      title: {
        '*': 'Contact us',
        fr: 'Contactez-nous',
        nl: 'Contacteer ons',
      },
    },
    launcher: {
      chatLabel: {
        '*': '',
        fr: '',
        nl: '',
      },
      label: {
        '*': '',
        fr: '',
        nl: '',
      },
      // chatLabel: {
      //   '*': 'Chat now with us',
      //   fr: 'Démarrer une conversation',
      //   nl: 'Waarmee kunnen we u helpen?',
      // },
      // label: {
      //   '*': 'Get help',
      //   fr: "Souhaitez-vous de l'aide",
      //   nl: 'Wenst u hulp?',
      // },
    },
  },
};

(async () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      serverName: PROJECT_NAME,
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: RELEASE,
      ignoreErrors: [],
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
    });
    LogRocket.init('7l7lpz/broker-insurance-needs');
    setupLogRocketReact(LogRocket);
  }

  const init = Component => {
    render(
      <AppContainer>
        <ThemeContextProvider>
          <ErrorBoundaryHoc>
            {/* <FlagsProviderHoc> */}
            <GlobalStyle />
            <Component />
            <Helmet>
              <html lang={i18next.language} />
            </Helmet>
            <Zendesk
              zendeskKey={ZENDESK_KEY}
              {...zendeskSettings}
              onLoaded={() => ZendeskAPI('webWidget', 'hide')}
            />
            {/* </FlagsProviderHoc> */}
          </ErrorBoundaryHoc>
        </ThemeContextProvider>
      </AppContainer>,
      document.getElementById(`root`),
    );
  };

  init(Sentry.withProfiler(Root));

  Sentry.setTag('name', PROJECT_NAME);
  Sentry.setTag('wg-fe-ui-version', packageJson.dependencies['wg-fe-ui']);

  if (module.hot) {
    module.hot.accept(`./config/Router.jsx`, () => {
      const newApp = require(`./config/Router.jsx`).default;

      init(Sentry.withProfiler(newApp));
    });
  }
})();
