import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import Button from '../../../../components/Button';

// components
import Layout from '../../LayoutFlow/views/App';
import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';

// stores
import {
  getComponentName,
  getNextPath,
  addBrowserHistory,
  getCurrentTheme,
} from '../../../../store/index';
import { getAllCarData, addCarData, setPayloadCar } from '../store/index';

import DefaultCarIcon from '../../../../../assets/images/DefaultCarIcon';

import CheckBoxBorderedBlockWithIcon from '../../../../components/CheckBoxBorderedBlockWithIcon.jsx';
import { shape } from 'prop-types';
import { getMeData } from '../../../../services/meDataService';
import { useLocation } from 'react-router';

const CheckCarExtra = ({ history }) => {
  const { t } = useTranslation();
  const carData = getAllCarData();
  const [user, setUser] = useState();
  const location = useLocation();
  const questions = t(`${getComponentName()}.questions`, {
    returnObjects: true,
  });

  useEffect(() => {
    addBrowserHistory(location.pathname);

    if (!user) {
      getMeData().then(({ me }) => {
        setUser(me.email);
      });
    }
  }, []);

  const initialValues = {};
  Object.keys(questions).map(q => (initialValues[q] = carData[q] || false));

  const clickHandler = values => {
    Object.keys(values).forEach(key => {
      addCarData(key, values[key]);
    });

    //HIER EMAIL AANPASSEN NAAR DIA EMAIL
    if (user === 'dia_broker@wegroup.be') {
      setPayloadCar('km_per_year', 10000);
      setPayloadCar('replacement_after_accident', true);
      setPayloadCar('vehicle_usage', 'private');
      setPayloadCar('car_loan', false);
      history.push(getNextPath('b'));
    } else {
      history.push(getNextPath('a'));
    }
  };

  return (
    <Layout>
      <Container>
        <StyledLeftSide>
          <RoundImage>
            {carData.img ? (
              <Img
                bg={
                  carData.img
                    ? carData.img.replace(/\(/g, '\\(').replace(/\)/g, '\\)')
                    : null
                }
              />
            ) : (
              <StyledDefaultCarIcon colors={getCurrentTheme()} />
            )}
          </RoundImage>
          <CarName>
            <span>{t(`${getComponentName()}.title`)}</span>
            <h2>{carData.name}</h2>
            {carData.color_hex ? (
              <ColorButton color={carData.color_hex} />
            ) : null}
          </CarName>
        </StyledLeftSide>
        <RightSide>
          <TitleForm>{t(`${getComponentName()}.safetySystem`)}</TitleForm>
          <Formik
            initialValues={initialValues}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <FormWrapper>
                  {Object.keys(questions).map(key => (
                    <CheckBoxBorderedBlockWithIcon
                      dataTestId={`underwriting_car_extras_${key}`}
                      key={key}
                      values={values}
                      setFieldValue={setFieldValue}
                      objectKey={key}
                      questions={questions}
                    />
                  ))}
                  <ButtonContainer>
                    <CustomButton
                      dataTestId="underwriting_car_submit"
                      type="submit"
                    >
                      {t(`${getComponentName()}.next`)}
                    </CustomButton>
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </RightSide>
      </Container>
    </Layout>
  );
};

const StyledLeftSide = styled(LeftSide)`
  justify-content: center;
  width: 50%;
  /* margin-top: 10rem; */
`;

const CustomButton = styled(Button)`
  margin: 0;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;
`;

const RoundImage = styled.div`
  width: 75%;
  padding-top: 75%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Img = styled.div`
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 0;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
`;

const ColorButton = styled.div`
  background-color: ${props => props.color};
  border: 0.5rem solid white;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
  height: 6rem;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  width: 6rem;
`;

const CarName = styled.div`
  background: white;
  box-shadow: 0 0.4rem 1.3rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  margin-top: -10rem;
  padding: 2rem 1.8rem;
  position: relative;
  width: 85%;

  & span {
    font-size: 1.2rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.typo.interactive};
  }

  & h2 {
    color: #505050;
    font-weight: 900;
    font-size: 1.6rem;
  }
`;

const StyledDefaultCarIcon = styled(DefaultCarIcon)`
  position: absolute;
  left: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  right: 0;
  bottom: 0;
  top: 0;
`;

// const StandardInputCheckbox = styled.input`
//   border-radius: 0.3rem;
//   opacity: 0;
//   margin-left: 1.5rem;
//   margin-right: -1.6rem;
//   cursor: pointer;
//   &:focus + ${CheckboxItem} {
//     outline: none;
//     border-color: ${({ theme }) => theme.brand.lighter};
//   }
// `;

// const StyledLabelCheckbox = styled.label`
//   display: flex;
//   position: relative;
//   margin-top: 0.5rem;
//   cursor: pointer;
//   line-height: 1.5;
//   background-color: ${props => (!props.disabled ? 'white' : 'none')};
//   flex: 0 0 50%;
//   color: #aeaeae;
//   padding: 0.65rem 1rem;
//   border-radius: 3px;
//   border: 1px solid #ccc;
//   align-items: center;
//   margin-bottom: 0.75rem;
//   & > span a {
//     font-size: 1.4rem;
//     transition: color 0.1s ease;
//     color: ${({ theme }) => theme.brand.primary};
//     font-weight: bold;
//   }
//   & > span {
//     font-size: 1.4rem;
//     width: 90%;
//     transition: color 0.1s ease;
//     color: #aeaeae;

//     & > span {
//       font-weight: bold;
//       color: ${props => (props.disabled ? '#aeaeae' : 'black')};
//     }
//   }
// `;

// const CheckboxItem = styled.div`
//   width: 2rem;
//   height: 2rem;
//   margin-top: 0.3rem;
//   background-color: ${props =>
//     props.checked ? theme.brand.primary : 'none'};
//   transition: all 0.1s ease;
//   flex-grow: 0;
//   flex-shrink: 0;
//   border: 0.2rem solid #d3d4d8;
//   border-color: ${props =>
//     props.checked ? theme.brand.primary : '#D3D4D8'};
//   border-radius: 0.3rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;

//   & > img {
//     height: 100%;
//     width: 100%;
//     object-fit: contain;
//   }
// `;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.6rem;
  flex-shrink: 0;
`;

const FormWrapper = styled(Form)`
  /* display: flex; */
  width: 100%;
  flex-shrink: 0;
  position: relative;
  padding-bottom: 8rem;
`;

CheckCarExtra.propTypes = {
  history: shape().isRequired,
};

export default CheckCarExtra;
