import React from 'react';
import { string } from 'prop-types';

const Replacement = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192.5 243.37">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M186.18,182.37l-66.92,26.57a10,10,0,0,1-13-5.6L80.65,138.79V117.16H95.43a21.65,21.65,0,0,0,21.63-21.62V28.18A21.65,21.65,0,0,0,95.43,6.55H69.68a45.31,45.31,0,0,1,6.66-3.29A45.94,45.94,0,0,1,136.06,29l55.73,140.35A10,10,0,0,1,186.18,182.37Z"
        />
        <path
          fill={color}
          d="M36.41,227.24V117.16H68.65V155l-16.14,6.78a1.15,1.15,0,0,0,0,1.91l16.14,6.79v14.92l-16.14,6.78a1.16,1.16,0,0,0,0,1.92l16.14,6.78v26.35a16.12,16.12,0,1,1-32.24,0Z"
        />
        <path
          fill={color}
          d="M95.43,18.55H9.63A9.63,9.63,0,0,0,0,28.18V95.54a9.62,9.62,0,0,0,9.63,9.62h85.8a9.62,9.62,0,0,0,9.63-9.62V28.18A9.63,9.63,0,0,0,95.43,18.55ZM84.9,63a7.19,7.19,0,0,1-5.1,2.12H25.26a7.22,7.22,0,0,1,0-14.44H79.8A7.22,7.22,0,0,1,84.9,63Z"
        />
        <rect fill={color} x="30.41" y="95.48" width="44.24" height="9.68" />
      </g>
    </g>
  </svg>
);

Replacement.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Replacement.propTypes = {
  title: string,
  color: string,
};

export default Replacement;
