/* eslint-disable no-unused-expressions */
import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { func } from 'prop-types';
import { setCoverage } from '../../../store';

const CarportAddCarToCarFlowPopup = ({
  showPopup,
  setNextPath,
  buttonPopupClicked,
}) => {
  const { t } = useTranslation();

  return (
    <Popup>
      <PopupContainer>
        <Close
          onClick={() => {
            showPopup(false),
              buttonPopupClicked(true),
              setCoverage('car', false);
            setNextPath(true);
          }}
        >
          x
        </Close>
        <Title>{t('We see you have a carport')}</Title>
        <Text>
          {t('Would you like to add the car to your insurance calculation')}
        </Text>
        <ButtonContainer>
          <Button
            onClick={() => {
              setNextPath(true);
              setCoverage('car', true);
              showPopup(false);
              buttonPopupClicked(true);
            }}
          >
            {t('I would like to insure my car')}
          </Button>
          <Button
            onClick={() => {
              setNextPath(true);
              showPopup(false);
              buttonPopupClicked(true);
              setCoverage('car', false);
            }}
          >
            {t('I do not want to insure my car')}
          </Button>
        </ButtonContainer>
      </PopupContainer>
    </Popup>
  );
};

const Close = styled.span`
  font-size: 2.5rem;
  position: absolute;
  top: 1.6rem;
  left: 2.5rem;
  cursor: pointer;
`;

const PopupContainer = styled.div`
  position: relative;
  max-width: 100rem;
  background-color: white;
  border-radius: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: 0.3s fade;
  padding: 4rem;

  @keyframes fade {
    from {
      transform: scale(0);
    }

    to {
      transform: scale(1);
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  background-color: ${({ theme, secondary }) =>
    secondary ? 'none' : theme.brand.primary};
  border-radius: 0.5rem;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  color: ${props => (props.secondary ? 'gray' : 'white')};
  border: 0;
  font-weight: bold;
  margin: 1rem;
  text-decoration: none;
  align-self: center;
  margin-bottom: 1rem;
  margin-right: 2rem;
  z-index: 100;
  cursor: pointer;
  transition: all 0.2s ease 0s;
  margin-top: 4rem;
  padding: 1rem 3rem;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }

  &:hover {
    background-color: ${({ theme, secondary }) =>
      secondary ? 'none' : theme.brand.lighter};
    color: ${props => (props.secondary ? 'black' : 'white')};
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  @media screen and (max-width: 1025px) {
    transform: scale(0.9);
    margin-bottom: 1rem;

    &:active {
      transform: scale(0.88);
    }
  }
`;

const Popup = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 900;
`;
const Text = styled.p`
  font-size: 1.8rem;
  font-weight: 400;
  width: 80%;
  text-align: center;
  margin-top: 3rem;
`;

CarportAddCarToCarFlowPopup.propTypes = {
  showPopup: func.isRequired,
  buttonPopupClicked: func.isRequired,
  setNextPath: func.isRequired,
};

export default CarportAddCarToCarFlowPopup;
