import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';
import InputQuestionBox from '../../../../components/InputQuestionBox';

import {
  getComponentName,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  setSkippedSteps,
  getCurrentStep,
  getAllCoverages,
  setNoticeKey,
  addBrowserHistory,
  getFlow,
} from '../../../../store';
import {
  sendHomeData,
  postAddressDataExtra,
  setInsuredData,
  getInsuredData,
} from '../store/index';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import DateInput from '../../../../components/DateInput';

const OldInsuranceStartDateInput = ({ history }) => {
  const { t } = useTranslation();
  addChatboxQuestion(t(`${getComponentName()}.question`));
  const content = t(getComponentName(), { returnObjects: true });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [valuesHook, setValuesHook] = useState(null);
  const [valueChatMessage, setValueChatMessage] = useState(null);
  const [sendStep, setSendStep] = useState('initial');

  let dateFormat = `DD-MM-YYYY`;
  let dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  const SignupSchema = Yup.object().shape({
    insurance_date: Yup.string()
      .required(t(`OldInsurance.errors.invalid_date`))
      .nullable()
      .matches(dateRegex, t(`OldInsurance.errors.invalid_date`))
      .test('DOB', t(`OldInsurance.errors.invalid_date`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.future`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.future`),
        value => moment().diff(moment(value, dateFormat), 'month') >= 0,
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.future`),
        value => moment().diff(moment(value, dateFormat), 'day') >= 0,
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 80,
      ),
  });

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    let _isDisabled;
    switch (sendStep) {
      case 'pending':
        _isDisabled = true;
        break;
      case 'success':
        _isDisabled = true;
        if (valueChatMessage !== null) {
          addChatboxAnswer(
            t(`${getComponentName()}.response`, {
              startDate: `${valueChatMessage.day}/${valueChatMessage.month}/${valueChatMessage.year}`,
            }),
          );
        } else {
          addChatboxAnswer(t(`${getComponentName()}.responseNull`));
        }

        if (getAllCoverages().family && getFlow().family) {
          history.push(getNextPath('a'));
        } else {
          history.push(getNextPath('b'));
        }
        break;
      case 'error':
        _isDisabled = false;
        break;
      default:
        _isDisabled = false;
        break;
    }
    setIsDisabled(_isDisabled);
  }, [sendStep]);

  const disableHandler = (errors, touched) => {
    if (
      Object.entries(errors).length === 0 &&
      Object.entries(touched).length === 1
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleAnswer = answer => {
    if (answer === content.answers[0].title) {
      setIsPending(true);
      setAnswer(1);
      clickHandler(null);
    } else {
      setIsPending(true);
      setAnswer(2);
      clickHandler();
    }
  };

  const clickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    const tempValues = values ? values : valuesHook;
    let fixedDate;
    if (values !== null) {
      fixedDate = {
        day: parseInt(tempValues['insurance_date'].split('-')[0]),
        month: parseInt(tempValues['insurance_date'].split('-')[1]),
        year: parseInt(tempValues['insurance_date'].split('-')[2]),
      };
    } else {
      fixedDate = null;
    }
    setValueChatMessage(fixedDate);
    setInsuredData('previous_insurance_start_date', fixedDate);
    setNoticeKey('home', 'noticeStartDate', fixedDate);
    setSendStep('pending');
    sendHomeData()
      .then(() => {
        postAddressDataExtra()
          .then(() => {
            setSkippedSteps(getCurrentStep(), false);
            setSendStep('success');
          })
          .catch(error => {
            setSendStep('error');
            console.error(error.response);
          });
      })
      .catch(error => {
        setSendStep('error');
        console.error(error.response);
      });
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <InputQuestionBox
          question={t(`${getComponentName()}.question`)}
          Answer1={content.answers[0].title}
          Answer2={content.answers[1].title}
          dataTestId1="underwriting_answer_dontknow"
          dataTestId2="underwriting_answer_confirm"
          shouldLoad={isPending ? answer : undefined}
          mainDisabled={isDisabled}
          oneLine={true}
          response={answer => handleAnswer(answer)}
        >
          <Formik
            initialValues={{
              insurance_date: getInsuredData()
                ? getInsuredData().previous_insurance_start_date
                  ? `${getInsuredData().previous_insurance_start_date.day}-${
                      getInsuredData().previous_insurance_start_date.month
                    }-${getInsuredData().previous_insurance_start_date.year}`
                  : ''
                : '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ errors, touched, setFieldValue, setFieldTouched, values }) => {
              disableHandler(errors, touched);
              setValuesHook(values);
              if (!touched.insurance_date) {
                if (values.insurance_date) setFieldTouched('insurance_date');
              }
              return (
                <Form>
                  <DateInput
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    dataTestId="underwriting_home_insurance_startdate"
                    setFieldTouched={setFieldTouched}
                    type="text"
                    disabled={false}
                    name="insurance_date"
                  />
                </Form>
                /* <Answers>
                  <Answer disabled={isDisabled} type="submit">
                    {t(`${getComponentName()}.confirm`)}
                  </Answer>
                </Answers> */
              );
            }}
          </Formik>
        </InputQuestionBox>
      </Container>
    </Layout>
  );
};

OldInsuranceStartDateInput.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

OldInsuranceStartDateInput.propTypes = {
  history: shape().isRequired,
};

export default withRouter(OldInsuranceStartDateInput);
