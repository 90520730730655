import React, { useState } from 'react';
import styled from 'styled-components';
// import Modal from './Modal';
import {
  TypeLabel,
  IconActionClose,
  ActionButton,
  TertiaryButton,
  Modal,
  Alert,
} from 'wg-fe-ui';
import { shape, string, array, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Frame from '../../assets/images/Frame.svg';
import { useContext } from 'react';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import { useParams } from 'react-router';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
// import Modal from './Modal';

const ProFlowOffersAccordionBoxHeader = ({
  id,
  logo,
  insuranceName,
  insuranceType,
  options,
  totalPremium,
  error,
  interval,
  className,
  isQuoteLoading,
  selectedOffers,
  calculationError,
}) => {
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const { removeOfferParameters } = useContext(ProFlowOffersContext);
  const { removeSelectedOffers } = useContext(ProFlowStorageContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function removeOffer(_id) {
    removeOfferParameters(id);
    removeSelectedOffers(_id, sessionId);
  }

  return (
    <StyledAccordionBox selectedOffers={selectedOffers} className={className}>
      <Modal
        showModal={isModalOpen}
        setShowModal={setIsModalOpen}
        title="Remove offer?"
      >
        <RemoveOfferMsg>
          <img src={Frame} alt="Test" />
          <div>
            <h2>Are you sure?</h2>
            <p> You’re about to remove an offer from the selected offers</p>
          </div>
        </RemoveOfferMsg>
        <Modal.ModalActions position="center">
          <TertiaryButton
            label="Cancel"
            onClick={() => setIsModalOpen(false)}
          />

          <ActionButton level="primary" onClick={() => removeOffer(id)}>
            {t('Yes')}
          </ActionButton>
        </Modal.ModalActions>
        {/* <Modal.ModalActions position="center"> */}{' '}
        {/* <TertiaryButton
              label="Cancel"
              onClick={() => setRemoveOfferModalOpen(false)}
            />
            <ActionButton onClick={() => {}}>Primary</ActionButton> */}
        {/* </Modal.ModalActions> */}
      </Modal>
      {selectedOffers && (
        <RemoveOfferButton onClick={() => setIsModalOpen(true)}>
          <StyledIconActionClose />
        </RemoveOfferButton>
      )}
      <InsuranceLogo alt={logo?.alt} src={logo?.src} />
      <div>
        <InsuranceName>{insuranceName}</InsuranceName>
        <InsuranceType>{insuranceType}</InsuranceType>
      </div>
      <Guarantees>
        {options?.map(({ key, name }) => (
          <StyledTypeLabel key={key} text={name} />
        ))}
      </Guarantees>
      {isQuoteLoading ? (
        <DotH2>
          {t(`Calculating quote`)}
          <span>.</span>
          <span>.</span>
          <span>.</span>
        </DotH2>
      ) : (
        <p className="price">
          {!error && !calculationError ? (
            <>
              € {totalPremium}
              <span>/{t(interval)}</span>
            </>
          ) : calculationError != null ? (
            <ErrorMessage>{calculationError}</ErrorMessage>
          ) : (
            <>
              {t('No price')}
              <StyledAlert>
                {t(`Click on the arrow on the right for more information`)}
              </StyledAlert>
            </>
          )}
        </p>
      )}
    </StyledAccordionBox>
  );
};

const StyledAlert = styled(Alert)`
  margin-top: 1rem;
`;

const RemoveOfferMsg = styled.div`
  height: 75%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  div {
    h2 {
      font-weight: bold;
      font-size: 1.7rem;
      padding: 2rem 0;
    }
  }
`;

//remove offer modal styling
const RemoveOfferButton = styled.button`
  border: 0;
  background-color: white;
  cursor: pointer;
`;

const StyledIconActionClose = styled(IconActionClose)`
  position: absolute;
  cursor: pointer;
  left: 2rem;
  top: 3rem;
  path {
    fill: #aeaeae;
  }
`;

const ErrorMessage = styled.p`
  color: #f74040;
  font-size: 1.8rem;
  margin-left: 10rem;
`;

const DotH2 = styled.h2`
  font-size: 2rem;
  color: #5a5f6b;
  margin-left: auto;
  margin-right: 2rem;
  > span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    :nth-child(2) {
      animation-delay: 0.2s;
    }
    :nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;
const InsuranceName = styled.h1`
  font-weight: 700;
`;
const InsuranceType = styled.h2`
  color: #8990a3;
  margin-top: 0.25rem;
`;

const StyledTypeLabel = styled(TypeLabel)`
  border-radius: 5rem;
  margin: auto;
  margin-left: 0;
  height: 2.5rem;
  line-height: 2rem;
  margin-bottom: 0;
  margin-right: 0.5rem;

  &:nth-of-type(1n + 4) {
    margin-top: 0.5rem;
  }
`;

const StyledAccordionBox = styled.div`
  align-items: center;
  display: grid;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  grid-template-columns: ${({ selectedOffers }) =>
    selectedOffers ? '5% 15% 20% 40% 20%' : '15% 20% 45% 20%'};
  .price {
    font-weight: 700;
    font-size: 1.6rem;
    text-align: right;
  }
`;

const Guarantees = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InsuranceLogo = styled.img`
  max-width: 90%;
  max-height: 6rem;
`;

ProFlowOffersAccordionBoxHeader.defaultProps = {
  logo: {
    src: '',
    alt: '',
  },
  insuranceName: '',
  insuranceType: '',
  options: [],
  guarantees: {},
  totalPremium: 0,
  interval: 'ANNUAL',
  isQuoteLoading: false,
};

ProFlowOffersAccordionBoxHeader.propTypes = {
  id: string,
  logo: shape({
    src: string.isRequired,
    alt: string.isRequired,
  }),
  insuranceName: string.isRequired,
  insuranceType: string.isRequired,
  options: array,
  guarantees: shape().isRequired,
  error: shape(),
  totalPremium: string.isRequired,
  interval: string,
  className: string.isRequired,
  isQuoteLoading: bool,
  selectedOffers: bool,
  calculationError: string,
};

export default ProFlowOffersAccordionBoxHeader;
