import React, { useEffect, useState, useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { auth, setTrialAccount, getSocialLogin } from '../store/index';
import { func, bool } from 'prop-types';
import ApiRoutes from '../store/ApiRoutes';
import { getBrokerData } from '../services/brokerDataService';
import { getNestedObject } from '../services/objectService';
import { ThemeContext } from '../services/context/ThemingContext';
import { getMeData } from '../services/meDataService';
import i18next from 'i18next';
import { verifyInit } from '../services/apiRouterService.js';
import { LoadingSpinner } from 'wg-fe-ui';
import styled from 'styled-components';
import { logout } from '../services/httpSessionService.js';
import { useFetchedUserData } from '../hooks/useFetchedUserData';
import { FlagsProvider } from '../hoc/FlagsProviderHoc';
import { ZendeskAPI } from 'react-zendesk';

const api = new ApiRoutes();
const PrivateRouteHoc = ({
  component: Component,
  noSocial,
  noTrial,
  ...rest
}) => {
  const [authenticateUserCall, setAuthenticateUserCall] = useState('initial');
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const { setTheme } = useContext(ThemeContext);
  const userData = useFetchedUserData();
  const location = useLocation();
  const configCatClient = useContext(FlagsProvider);

  useEffect(() => {
    ZendeskAPI('webWidget', 'updatePath', {
      url: window.location.href,
    });
    window.Appcues.page();
  }, [location]);

  useEffect(() => {
    validateRoute();
  }, []);

  useEffect(() => {
    const { data, status } = userData;
    const { broker: brokerData, me, distribution } = data || {};
    const { broker } = brokerData || {};

    if (status !== 'fetched') return;

    window.Appcues.identify(broker?.id, {
      // createdAt: 1566932390, // Unix timestamp of user signup date
      // purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
      planTier: broker?.plan, // Current user’s plan tier
      role: broker?.is_admin ? 'Admin' : 'User', // Current user’s role or permissions
      accountId: broker?.id, // Current user's account ID
      name: broker?.name, // current user's name
      companyName: distribution?.name, // Current user’s company name
      email: me?.email, // Current user's email
      location: distribution.address.zipcode, // a zipcode, state, or country enables location-based targeting
      // version: '2.0', // users on different versions may need to see different content
      language: me.language, // for multi-language applications
      // renewalDate: 1577880288, // to remind users to renew
    });
  }, [userData]);

  async function validateRoute() {
    setAuthenticateUserCall('pending');

    if (noSocial && getSocialLogin()) {
      api.getLogout().then(() => {
        setAuthenticateUserCall('socialLogin');
      });
    } else {
      if (auth.isAuthenticated) {
        setAuthenticateUserCall('success');
      } else {
        try {
          await verifyInit();

          const { theme, broker } = (await getBrokerData()) || {};
          setTheme(theme?.name);

          const meData = await getMeData();
          const language = getNestedObject(meData, ['me', 'language']) || 'en';
          i18next.changeLanguage(language.toLowerCase());

          setIsCallantFeature(
            !(await configCatClient.getValueAsync('canAccessPage', true, {
              email: meData?.me?.email,
              custom: {
                plan: broker?.plan,
              },
            })),
          );

          if (getNestedObject(meData, ['me', 'role']) === 'trial_broker') {
            setTrialAccount(true);
            if (noTrial) {
              setAuthenticateUserCall('dashboard');
            }
          } else {
            setTrialAccount(false);
          }

          if (
            noSocial &&
            getNestedObject(meData, ['me', 'role']) === 'social_login'
          ) {
            setAuthenticateUserCall('socialLogin');
          } else {
            setAuthenticateUserCall('success');
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  const brokerId = sessionStorage.getItem('broker_id');

  switch (authenticateUserCall) {
    case 'initial':
    case 'pending':
      return <Route {...rest} render={() => <FullpageLoading />} />;
    case 'success': {
      auth.isAuthenticated = true;
      !isCallantFeature
        ? ZendeskAPI('webWidget', 'show')
        : ZendeskAPI('webWidget', 'hide');
      return (
        <Route
          {...rest}
          render={props => (
            // <LDProvider>
            <Component {...props} />
            // </LDProvider>
          )}
        />
      );
    }
    case 'dashboard':
      return <Redirect to="/dashboard" />;
    case 'socialLogin':
      logout();
      return <Redirect to={brokerId ? `/broker/${brokerId}` : '/login'} />;
    default:
      throw new Error('not a valid option');
  }
};

const FullpageLoading = () => (
  <LoadingContainer>
    <LoadingSpinner />
  </LoadingContainer>
);

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

PrivateRouteHoc.defaultProps = {
  noSocial: false,
};

PrivateRouteHoc.propTypes = {
  component: func.isRequired,
  noSocial: bool,
  noTrial: bool,
};

export default PrivateRouteHoc;
