import React from 'react';
import { string } from 'prop-types';

const PhoneIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="22"
    fill="none"
    viewBox="0 0 21 22"
  >
    <path
      fill={color}
      d="M14.459 21.973c-.513-.117-1.482-.117-2.28-.524C6.426 18.655 2.437 14.232.898 7.829.214 4.92 1.695 1.894 4.145.38 4.487.147 5-.085 5.285.031c1.88.698 2.45 2.503 3.248 4.074.513 1.048.057 1.92-.74 2.735-2.223 2.212-2.28 2.852-.4 5.355.627.873 1.31 1.688 2.108 2.444 2.052 1.98 2.679 2.038 4.787.233.968-.815 1.994-1.164 3.077-.407 1.367.99 2.963 1.804 3.305 3.725.056.29-.171.698-.4.99-1.367 1.629-3.133 2.735-5.811 2.793z"
    />
  </svg>
);

PhoneIcon.propTypes = {
  color: string.isRequired,
};

export default PhoneIcon;
