import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import Layout from '../../../js/models/DashboardLayout/views/Layout';
import { CampaignProvider } from './context/CampaignContext';
import { CampaignCreationProvider } from './context/CampaignCreationContext';
import CampaignBuilder from './pages/CampaignBuilder';

const CampaignsRouter = () => {
  return (
    <StyledLayout showTop={true}>
      <CampaignProvider>
        <CampaignCreationProvider>
          <Switch>
            <Route path="/campaigns" component={CampaignBuilder} />
          </Switch>
        </CampaignCreationProvider>
      </CampaignProvider>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

export default CampaignsRouter;
