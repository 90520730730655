import React from 'react';

const Estep = () => (
  <svg viewBox="0 0 236.47 185.8">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'e_step'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M34.34 117.11a34.35 34.35 0 1034.34 34.34 34.35 34.35 0 00-34.34-34.34zm0 47.4a13.06 13.06 0 1113.06-13.06 13.06 13.06 0 01-13.06 13.06zM202.13 117.11a34.35 34.35 0 1034.34 34.34 34.35 34.35 0 00-34.34-34.34zm0 47.4a13.06 13.06 0 1113.06-13.06 13.06 13.06 0 01-13.06 13.06z"
        />
        <path
          className="prefix__cls-1"
          d="M90.73 144.41L62.08 79.57 83.9 7.7A6 6 0 0078.19 0a6 6 0 00-5.71 4.23L32.64 135.44h12.47l11.65-38.37 24.62 55.72a6 6 0 005.46 3.56h96.87v-11.94z"
        />
        <path
          className="prefix__cls-1"
          d="M116.46 137.18h63.33v26.08h-63.33a11.5 11.5 0 01-11.5-11.5v-3.08a11.5 11.5 0 0111.5-11.5zM44.07 0h33.68v13.6H44.07a6 6 0 01-6-6V6a6 6 0 016-6z"
        />
      </g>
    </g>
  </svg>
);

export default Estep;
