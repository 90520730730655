import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const Garden = ({ colors }) => {
  const styles = {
    main: {
      height: '100%',
    },
    cls1: {
      fill: '#fff',
    },
    cls2: {
      fill: `${colors.brand.secondary}`,
    },
    cls3: {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
      fill: `none`,
      stroke: `${colors.brand.dark}`,
    },
    cls5: {
      strokeMiterlimit: '10',
      strokeWidth: '3px',
      fill: `none`,
      stroke: `${colors.brand.dark}`,
    },
    cls4: {
      fill: `${colors.brand.secondary}`,
    },
  };

  return (
    <SvgContainer>
      <svg
        id="garden"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 216.63 364.16"
        style={styles.main}
      >
        <defs>
          <style />
        </defs>
        <title>Garden</title>
        <path
          style={styles.cls1}
          d="M419.42,273.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79C468.85,51,485,71,490.23,100.67c6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09C488.62,281.25,473.79,371.49,443,370,418.14,368.8,420.31,313,419.42,273.41Z"
          transform="translate(-338.81 -60.34)"
        />
        <path
          style={styles.cls2}
          d="M426.42,273.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79C475.85,51,492,71,497.23,100.67c6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09C495.62,281.25,480.79,371.49,450,370,425.14,368.8,427.31,313,426.42,273.41Z"
          transform="translate(-338.81 -60.34)"
        />
        <path
          style={styles.cls3}
          d="M419.42,273.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79C468.85,51,485,71,490.23,100.67c6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09C488.62,281.25,473.79,371.49,443,370,418.14,368.8,420.31,313,419.42,273.41Z"
          transform="translate(-338.81 -60.34)"
        />
        <line
          style={styles.cls3}
          x1="103.33"
          y1="360.91"
          x2="103.33"
          y2="126.66"
        />
        <path
          style={styles.cls4}
          d="M555.44,423H345.77c-.7-5.06-1.15-13.62,2.92-17.82C359.5,394,392.5,404,409.5,403c29.72-1.75,79.41-21.48,111-14C549.77,395.93,554.72,414.72,555.44,423Z"
          transform="translate(-338.81 -60.34)"
        />
        <path
          style={styles.cls5}
          d="M550.44,423H340.77c-.7-5.06-1.15-13.62,2.92-17.82C354.5,394,387.5,404,404.5,403c29.72-1.75,79.41-21.48,111-14C544.77,395.93,549.72,414.72,550.44,423Z"
          transform="translate(-338.81 -60.34)"
        />
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  height: 10rem;
  position: absolute;
  z-index: 10;
  margin-top: -3rem;
  margin-left: -17rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 9%;
    margin-top: -10rem;
    margin-left: -17rem;
  }
`;

Garden.propTypes = {
  colors: shape().isRequired,
};
export default Garden;
