/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useEffect } from 'react';
import cogoToast from 'cogo-toast';
import { CheckBox } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../services/stringService';

const OfferedRisk = ({
  name,
  label,
  Logo,
  onChange,
  disabled,
  chosenRisks,
}) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (chosenRisks?.includes(name)) {
      setIsSelected(true);
    } else if (!chosenRisks?.includes(name)) {
      setIsSelected(false);
    }
  }, [chosenRisks]);

  function handleCheckboxClick(e) {
    e.preventDefault();
    if (disabled) {
      cogoToast.info(t('Risk object not yet possible'));
    } else {
      onChange({ name: name, value: !isSelected });
    }
  }

  return (
    <Container
      disabled={disabled}
      data-test-id={`offeredRisk_${name.toLowerCase()}`}
    >
      <StyledDiv
        disabled={disabled}
        onClick={handleCheckboxClick}
        checked={isSelected}
      >
        <StyledCheckBox
          name={name}
          checked={isSelected}
          onClick={e => handleCheckboxClick(e)}
        />
        <LogoContainer>
          <Logo color={isSelected ? themeContext.brand.primary : '#c1c1c1'} />
        </LogoContainer>
      </StyledDiv>
      <Label>{capitalize(t(label))}</Label>
    </Container>
  );
};

const Label = styled.label`
  margin-top: 1rem;
  font-size: 1.6rem;
  font-weight: 700;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -2rem;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

const StyledDiv = styled.div`
  transition: 0.2s ease;
  align-items: center;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : `1px solid #f0f1f3`};
  border-radius: 0.5rem;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 4px 8px rgba(0, 0, 0, 0.04);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  padding: 2rem 1.5rem;
  width: 100%;
  height: 11rem;
  background-color: ${({ checked, theme }) =>
    checked ? theme.brand.lightest : `white`};

  :hover {
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
      0 16px 24px rgba(0, 0, 0, 0.06);
  }
`;

const StyledCheckBox = styled(CheckBox)`
  /* justify-content: flex-end; */
  div {
    border: 0;
    background-color: ${({ checked, theme }) =>
      checked ? theme.brand.primary : '#F0F1F3'};
  }
`;

export default OfferedRisk;
