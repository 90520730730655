import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';
import InputQuestionBox from '../../../../components/InputQuestionBox';

import {
  getComponentName,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  setNoticeKey,
  addBrowserHistory,
  setSkippedSteps,
  setCoverage,
  showErrorPopup,
  getCurrentStep,
  getAllCoverages,
} from '../../../../store';
// import { postFamilyData, setInsuredData } from '../store/index';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import DateInput from '../../../../components/DateInput';
import {
  setInsuredData,
  postLegalData,
  postFamData,
  getInsuredData,
} from '../store';
import ApiRoutes from '../../../../store/ApiRoutes';
import {
  resetSkippedSteps,
  removeFromCheckout,
} from '../../../Marketplace/LayoutMarket/store';
import {
  resetMatchData,
  getMatchData,
  setMatchData,
} from '../../../Marketplace/Market/store';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const OldInsuranceStartDateInputLegal = ({ history }) => {
  const { t } = useTranslation();
  addChatboxQuestion(
    t(`Since when has your previous insurance been running continuously?`),
  );
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [valuesHook, setValuesHook] = useState(null);
  const [valueChatMessage, setValueChatMessage] = useState(null);
  const [sendStep, setSendStep] = useState('initial');
  const [finishedFetches, setFinishedFetches] = useState([]);
  const coverages = getAllCoverages();
  const flowStore = useFlowStore();
  const api = new ApiRoutes();

  let dateFormat = `DD-MM-YYYY`;
  let dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  const SignupSchema = Yup.object().shape({
    insurance_date: Yup.string()
      .required(t(`OldInsurance.errors.invalid_date`))
      .nullable()
      .matches(dateRegex, t(`OldInsurance.errors.invalid_date`))
      .test('DOB', t(`OldInsurance.errors.invalid_date`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.future`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.future`),
        value => moment().diff(moment(value, dateFormat), 'month') >= 0,
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.future`),
        value => moment().diff(moment(value, dateFormat), 'day') >= 0,
      )
      .test(
        'DOB',
        t(`OldInsurance.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 80,
      ),
  });

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    const trueCoverages = [];
    Object.keys(coverages).forEach(coverage => {
      switch (coverage) {
        case 'home':
        case 'family':
        case 'car':
        case 'legal':
          if (coverages[coverage]) trueCoverages.push(coverage);
          break;
        default:
      }
    });
    if (finishedFetches === trueCoverages.length) {
      setSendStep('success');
    }
  }, [finishedFetches]);

  useEffect(() => {
    let _isDisabled;
    switch (sendStep) {
      case 'pending':
        _isDisabled = true;
        break;
      case 'success':
        _isDisabled = true;
        if (valueChatMessage !== null) {
          addChatboxAnswer(
            t(`${getComponentName()}.response`, {
              startDate: `${valueChatMessage.day}/${valueChatMessage.month}/${valueChatMessage.year}`,
            }),
          );
        } else {
          addChatboxAnswer(t(`${getComponentName()}.responseNull`));
        }
        api.postSession().then(() => {
          if (coverages.home) {
            history.push(getNextPath('a'));
          } else if (coverages.family) {
            history.push(getNextPath('b'));
          } else if (coverages.car) {
            history.push(getNextPath('c'));
          } else {
            history.push(getNextPath('d'));
          }
        });
        break;
      case 'error':
        _isDisabled = false;
        break;
      default:
        _isDisabled = false;
        break;
    }
    setIsDisabled(_isDisabled);
  }, [sendStep]);

  const disableHandler = (errors, touched) => {
    if (
      Object.entries(errors).length === 0 &&
      Object.entries(touched).length === 1
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleAnswer = answer => {
    if (answer === t('I don t know')) {
      setIsPending(true);
      setAnswer(1);
      clickHandler(null);
    } else {
      setIsPending(true);
      setAnswer(2);
      clickHandler();
    }
  };

  const clickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    const tempValues = values ? values : valuesHook;
    let fixedDate;
    if (values !== null) {
      fixedDate = {
        day: parseInt(tempValues['insurance_date'].split('-')[0]),
        month: parseInt(tempValues['insurance_date'].split('-')[1]),
        year: parseInt(tempValues['insurance_date'].split('-')[2]),
      };
    } else {
      fixedDate = null;
    }
    setValueChatMessage(fixedDate);
    setInsuredData('previous_insurance_start_date', fixedDate);
    setNoticeKey('legal', 'noticeStartDate', fixedDate);
    setSendStep('pending');
    if (!coverages.family) {
      postFamData()
        .then(() => {
          handleSetData();
        })
        .catch(err => console.error(err));
    } else {
      handleSetData();
    }
  };

  const handleSetData = () => {
    setInsuredData('wants_independent_legal_aid', true);
    postLegalData()
      .then(() => {
        resetSkippedSteps();
        removeFromCheckout('fire');
        removeFromCheckout('people');
        removeFromCheckout('car');
        removeFromCheckout('legal');
        setSkippedSteps(getCurrentStep(), false);
        setCoverage('legal', true);
        resetMatchData();

        Object.keys(coverages).forEach(coverageKey => {
          switch (coverageKey) {
            case 'home':
            case 'family':
            case 'car':
            case 'legal':
              if (coverages[coverageKey]) {
                const { type, campaign } = flowStore || {};
                if (type === 'campaign' && campaign?.extend === 'LEAD') {
                  history.push('/thank-you-campaigns');
                  return;
                }
                getMatchData(coverageKey)
                  .then(res => {
                    setMatchData(
                      coverageKey === 'home' ? 'fire' : coverageKey,
                      res.data,
                    );
                    setFinishedFetches(finishedFetches.push(coverageKey));
                  })
                  .catch(err => {
                    // showErrorPopup(true);
                    console.error(err);
                  });
              }
              break;
            default:
          }
        });
      })
      .catch(error => {
        showErrorPopup(true);
        console.error(error.response);
      });
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <InputQuestionBox
          question={t(
            `Since when has your previous insurance been running continuously?`,
          )}
          Answer1={t('I don t know')}
          Answer2={t('Confirm')}
          shouldLoad={isPending ? answer : undefined}
          dataTestId1="underwriting_answer_dontknow"
          dataTestId2="underwriting_answer_confirm"
          mainDisabled={isDisabled}
          oneLine={true}
          response={answer => handleAnswer(answer)}
        >
          <Formik
            initialValues={{
              insurance_date: getInsuredData()
                ? getInsuredData().previous_insurance_start_date
                  ? `${getInsuredData().previous_insurance_start_date.day}-${
                      getInsuredData().previous_insurance_start_date.month
                    }-${getInsuredData().previous_insurance_start_date.year}`
                  : ''
                : '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ errors, touched, setFieldValue, setFieldTouched, values }) => {
              disableHandler(errors, touched);
              setValuesHook(values);
              if (!touched.insurance_date) {
                if (values.insurance_date) setFieldTouched('insurance_date');
              }
              return (
                <Form>
                  <DateInput
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    dataTestId="underwriting_family_insurance_startdate"
                    type="text"
                    disabled={false}
                    name="insurance_date"
                  />
                </Form>
              );
            }}
          </Formik>
        </InputQuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

OldInsuranceStartDateInputLegal.propTypes = {
  history: shape().isRequired,
};

export default withRouter(OldInsuranceStartDateInputLegal);
