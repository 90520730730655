import React from 'react';
import DateInput from './DateInput_v2';
// import { parseIncDateToObject, parseIncDate } from '../services/dateService';
import { func, string, bool } from 'prop-types';
import styled from 'styled-components';
const ProFlowInputTypeDateInput = ({
  error,
  name,
  handleChange,
  onBlur,
  prefilled,
  value,
  title,
  ...otherProps
}) => {
  return (
    <>
      <Label>{title}</Label>
      <StyledDateInput
        error={error}
        name={name}
        prefilled={prefilled}
        onChange={handleChange}
        onBlur={onBlur}
        value={value}
        {...otherProps}
      />
    </>
  );
};

const StyledDateInput = styled(DateInput)`
  & label {
    ${({ prefilled }) =>
      prefilled ? `border-color: #FFBC0F; background-color: #FFF9EA;` : ''}
  }
`;

const Label = styled.h3`
  color: #0e0e0e;
  font-weight: 700;
  font-size: 1.6rem;
`;

ProFlowInputTypeDateInput.propTypes = {
  error: string,
  name: string.isRequired,
  handleChange: func.isRequired,
  onBlur: func,
  value: string,
  prefilled: bool,
  title: string.isRequired,
};

export default ProFlowInputTypeDateInput;
