import React from 'react';
import { string } from 'prop-types';

const WorksAtHome = ({ color }) => {
  return (
    <svg
      width="22"
      height="25"
      viewBox="0 0 22 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.0375 1.55785L14.035 3.51064L16.1762 4.90392V2.30386V1.9857H17.4608V4.98072V5.77061L18.1032 6.1875L20.6083 7.81117V23.3238H1.39173V7.8002L11.0375 1.55785ZM11.0375 0C10.909 0 10.7805 0.0329122 10.6735 0.109707L0.321168 6.81283C0.128467 6.94448 0 7.1639 0 7.40525V23.7297C0 24.2892 0.449635 24.75 0.99562 24.75H21.0044C21.5504 24.75 22 24.2892 22 23.7297V7.40525C22 7.1639 21.8822 6.94448 21.6788 6.81283L18.8419 4.98072V1.26164C18.8419 0.87766 18.5314 0.559508 18.1567 0.559508H15.4589C15.0842 0.559508 14.7737 0.87766 14.7737 1.26164V2.30386L11.4015 0.109707C11.2944 0.0329122 11.1659 0 11.0375 0Z"
          fill={color}
        />
        <path
          d="M4.76416 11.6181V19.3195C4.76416 19.8461 5.18168 20.274 5.69555 20.274H7.01234V10.6636H5.69555C5.18168 10.6636 4.76416 11.0915 4.76416 11.6181Z"
          fill={color}
        />
        <path
          d="M16.3046 10.6636H14.9878V20.274H16.3046C16.8185 20.274 17.236 19.8461 17.236 19.3195V11.6181C17.236 11.0915 16.8185 10.6636 16.3046 10.6636Z"
          fill={color}
        />
        <path
          d="M13.2431 9.45682C13.2431 9.00702 12.8898 8.64499 12.4509 8.64499H9.53899C9.10006 8.64499 8.74678 9.00702 8.74678 9.45682V10.6636H8.23291V20.274H13.757V10.6636H13.2431V9.45682ZM9.53899 9.45682H12.4509V10.6636H9.53899V9.45682Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="22" height="24.75" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

WorksAtHome.propTypes = {
  color: string,
};

export default WorksAtHome;
