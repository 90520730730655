import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Tabs, LoadingSpinner } from 'wg-fe-ui';

import CRMRiskObjectTabPage from './CRMRiskObjectTabPageOffers';

// import { getRiskObjectsByCallType } from '../services/apiRouterService';

const CRMRiskObjectTabs = ({
  riskObjects,
  offerId,
  offerRevision,
  prospectId,
  contractId,
  contractCaseId,
  contractState,
  disableEdit,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRiskObjectPage, setSelectedRiskObjectPage] = useState();
  const [carRiskObjects, setCarRiskObjects] = useState();
  const [twoWheelerRiskObjects, setTwoWheelerRiskObjects] = useState();
  const [residenceRiskObjects, setResidenceRiskObjects] = useState();
  const [familyRiskObjects, setFamilyRiskObjects] = useState();
  const [teacherRiskObjects, setTeacherRiskObjects] = useState();

  useEffect(() => {
    if (riskObjects !== null && riskObjects !== undefined) {
      Object.keys(riskObjects).forEach(key => {
        if (riskObjects[key].length > 0) {
          setRiskObjects(riskObjects[key], key);
        }
      });
    }
  }, [riskObjects]);

  useEffect(() => {
    renderRiskObjectPage();
  }, [
    carRiskObjects,
    twoWheelerRiskObjects,
    residenceRiskObjects,
    familyRiskObjects,
    teacherRiskObjects,
  ]);

  const setRiskObjects = (riskObject, type) => {
    switch (type) {
      case 'cars':
        setCarRiskObjects(riskObject);
        setIsLoading(false);
        break;
      case 'two_wheelers':
        setTwoWheelerRiskObjects(riskObject);
        setIsLoading(false);
        break;
      case 'residences':
        setResidenceRiskObjects(riskObject);
        setIsLoading(false);
        break;
      case 'families':
        setFamilyRiskObjects(riskObject);
        setIsLoading(false);
        break;
      case 'teachers':
        setTeacherRiskObjects(riskObject);
        setIsLoading(false);
        break;
      default:
        break;
    }
  };

  const checkArraysInObjectIsEmpty = object => {
    if (object != null || undefined) {
      return (
        Object.keys(object).filter(function(key) {
          return object[key].length > 0;
        }).length === 0
      );
    }
  };

  const renderRiskObjectPage = () => {
    if (selectedRiskObjectPage === 'cars' && carRiskObjects !== undefined) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={carRiskObjects}
          riskType="cars"
          contractState={contractState}
          disableEdit={disableEdit}
          offerId={offerId}
          prospectId={prospectId}
          offerRevision={offerRevision}
          contractId={contractId}
          contractCaseId={contractCaseId}
        />
      );
    } else if (
      selectedRiskObjectPage === 'two_wheelers' &&
      twoWheelerRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={twoWheelerRiskObjects}
          riskType="two_wheelers"
          contractState={contractState}
          offerId={offerId}
          prospectId={prospectId}
          offerRevision={offerRevision}
          contractId={contractId}
          contractCaseId={contractCaseId}
          disableEdit={disableEdit}
        />
      );
    } else if (
      selectedRiskObjectPage === 'residences' &&
      residenceRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={residenceRiskObjects}
          riskType="residences"
          contractState={contractState}
          offerId={offerId}
          prospectId={prospectId}
          offerRevision={offerRevision}
          contractId={contractId}
          contractCaseId={contractCaseId}
          disableEdit={disableEdit}
        />
      );
    } else if (
      selectedRiskObjectPage === 'families' &&
      familyRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={familyRiskObjects}
          riskType="families"
          contractState={contractState}
          offerId={offerId}
          prospectId={prospectId}
          offerRevision={offerRevision}
          contractId={contractId}
          contractCaseId={contractCaseId}
          disableEdit={disableEdit}
        />
      );
    } else if (
      selectedRiskObjectPage === 'teachers' &&
      teacherRiskObjects !== undefined
    ) {
      return (
        <CRMRiskObjectTabPage
          riskObjects={teacherRiskObjects}
          riskType="teachers"
          contractState={contractState}
          offerId={offerId}
          prospectId={prospectId}
          offerRevision={offerRevision}
          contractId={contractId}
          contractCaseId={contractCaseId}
          disableEdit={disableEdit}
        />
      );
    }
  };

  if (riskObjects !== null && riskObjects !== undefined) {
    if (!checkArraysInObjectIsEmpty(riskObjects)) {
      return (
        <>
          <StyledTabsContainer>
            <Tabs onSelect={selected => setSelectedRiskObjectPage(selected)}>
              {Object.entries(riskObjects).map(([key, value]) => {
                if (value.length === 0) return null;

                return (
                  <Tabs.Item key={key} name={key}>
                    {t(key)}
                  </Tabs.Item>
                );
              })}
            </Tabs>
            {isLoading ? (
              <LoadingContainer>
                <LoadingSpinner />
              </LoadingContainer>
            ) : (
              renderRiskObjectPage()
            )}
          </StyledTabsContainer>
        </>
      );
    } else {
      return (
        <StyledRiskStatusMessage>
          {t('No risk objects found')}
          <RiskObjectSpacer />
        </StyledRiskStatusMessage>
      );
    }
  } else {
    return (
      <StyledRiskStatusMessage>
        {t('Something went wrong while retrieving the risk objects')}
      </StyledRiskStatusMessage>
    );
  }
};

const RiskObjectSpacer = styled.div`
  height: 15px;
`;

const StyledRiskStatusMessage = styled.p`
  margin-top: 2rem;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTabsContainer = styled.div`
  width: 100%;
`;

CRMRiskObjectTabs.propTypes = {
  distributionId: string.isRequired,
  prospectId: string.isRequired,
  riskObjects: shape({}).isRequired,
  contractState: shape({}),
};

export default CRMRiskObjectTabs;
