import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

const ProFlowOffersAccordionBoxParameters = ({ premium, quote }) => {
  const { t } = useTranslation();

  return (
    (premium || quote) && (
      <>
        <Title className="title">{t(`Parameters`)}</Title>
        <ParametersContainer>
          {(premium || quote?.details?.evaluation_type) && (
            <>
              <Values>
                <span className="type">{t(`Checkout.insured.bonusMalus`)}</span>
                <span>{premium?.bonus_malus}</span>
              </Values>
              <Values>
                <span className="type">{t(`Evaluation method`)}</span>
                <span>{t(quote?.details?.evaluation_type)}</span>
              </Values>
              <Values>
                <span className="type">
                  {t(`Checkout.insured.franchiseType`)}
                </span>
                <span>
                  {premium?.franchise?.type
                    ? t(
                        `Checkout.insured.franchiseTypes.${premium?.franchise?.type}`,
                      )
                    : `--`}
                </span>
              </Values>
              <Values>
                <span className="type">{t(`Checkout.insured.franchise`)}</span>
                <span>
                  {premium?.franchise?.value
                    ? [
                        `percentage_on_compensation`,
                        `percentage_on_car_value`,
                      ].includes(premium?.franchise?.type)
                      ? `${(premium?.franchise?.value * 100)?.toFixed(2)}%`
                      : `€ ${premium?.franchise?.value?.toFixed(2)}`
                    : `--`}
                </span>
              </Values>
            </>
          )}
          {quote && (
            <>
              {quote?.details?.insured_value?.value && (
                <ValueContainer>
                  <SelectContainer>
                    <span className="type">
                      {t(`Evaluation type building`)}
                    </span>
                    <ShadowBox>
                      {t(quote?.details?.insured_value?.evaluation_type)}
                    </ShadowBox>
                  </SelectContainer>
                  <Values>
                    <span className="type">
                      {t(`Checkout.insured.insured_value`)}
                    </span>
                    <span>
                      {quote?.details?.insured_value?.value.toLocaleString(
                        'nl-BE',
                        { style: 'currency', currency: 'EUR' },
                      )}
                    </span>
                  </Values>
                </ValueContainer>
              )}
              {quote?.details?.insured_content_value?.value && (
                <ValueContainer>
                  <SelectContainer>
                    <span className="type">
                      {t(`Evaluation type content value`)}
                    </span>
                    <ShadowBox>
                      {t(
                        quote?.details?.insured_content_value?.evaluation_type,
                      )}
                    </ShadowBox>
                  </SelectContainer>
                  <Values>
                    <span className="type">
                      {t(`Checkout.insured.insured_content_value`)}
                    </span>
                    <span>
                      {quote?.details?.insured_content_value?.value.toLocaleString(
                        'nl-BE',
                        { style: 'currency', currency: 'EUR' },
                      )}
                    </span>
                  </Values>
                </ValueContainer>
              )}
            </>
          )}
        </ParametersContainer>
      </>
    )
  );
};

const Title = styled.h3`
  color: black;
  font-weight: bold;
  font-size: 1.6rem;
  @media screen and (max-width: 1360px) {
    font-size: 1.4rem;
  }
  margin-bottom: 1rem;
`;

const Values = styled.span`
  display: flex;
  justify-content: space-between;
  line-height: 2rem;
  padding: 1rem;
  align-items: center;
  color: #8990a3;
  background: rgba(240, 241, 243, 0.4);
  border-radius: 0.8rem;
  @media screen and (max-width: 1360px) {
    font-size: 1.2rem;
    font-weight: 600;
    height: 3rem;
    padding: 0.5rem;
  }
  span {
    font-weight: 500;

    .type {
      color: #8990a3;
      font-size: 1.4rem;
      line-height: 1.8rem;
    }
  }
`;

const ShadowBox = styled.span`
  text-align: center;
  width: 100%;
  color: #0e0e0e;
  background-color: #f0f1f3;
  font-weight: bold;
  border-radius: 5px;
  padding: 1.2rem;
  margin-top: 1rem;
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  padding: 1rem;
  align-items: center;
  color: #8990a3;
  background: rgba(240, 241, 243, 0.4);
  border-radius: 0.8rem;
  @media screen and (max-width: 1360px) {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.5rem;
  }
`;

const ParametersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1.4rem;
  width: 100%;

  & > * {
    margin-bottom: 1rem;
  }

  p {
    color: black;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  button {
    background: white;
    color: black;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    margin: auto;
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 100%;
    width: 100%;
    height: 5rem;
    position: relative;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  }
`;

const ValueContainer = styled.div`
  & > * {
    margin-bottom: 1rem;
  }
`;

ProFlowOffersAccordionBoxParameters.propTypes = {
  premium: shape(),
  quote: shape(),
};

export default ProFlowOffersAccordionBoxParameters;
