import React from 'react';
import { Section, OverviewTable } from 'wg-fe-ui';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getNestedObject } from '../../../services/objectService';

const AddressAnnexes = ({ addressData }) => {
  const { t } = useTranslation();
  const content = t('ToolkitInfo.address.addressInfo', {
    returnObjects: true,
  });

  const mapValue = (annex, type) => {
    if (!annex) return '--';
    switch (type) {
      case 'surface':
        return `${annex} m²`;
      case 'height':
        return `${annex} m`;
      case 'type':
        return t(annexTypes[annex]);
      case 'volume':
        return `${annex} m³`;
      default:
        return annex;
    }
  };

  const annexTypes = {
    garden_shed: 'Garden shed',
    other: 'Other',
  };

  return (
    <Section data-test-id="dashboard_toolkit_addresslookup_section_annexes">
      <Section.Title>{t('Annexes')}</Section.Title>
      <Section.Content>
        {addressData.parcel.annexes.length > 0 ? (
          <OverviewTable>
            <OverviewTable.Head>
              {Object.keys(addressData.parcel.annexes[0]).map(key => {
                return (
                  <Head
                    key={key}
                    title
                    width="20%"
                    data-test-id={`dashboard_toolkit_addresslookup_section_annexes_overviewTable_headerCell_${key}`}
                  >
                    {getNestedObject(content, ['annexes', 'keys', key], '')}
                  </Head>
                );
              })}
            </OverviewTable.Head>
            <OverviewTable.Body>
              {addressData.parcel.annexes.map((annex, key) => {
                return (
                  <StyledRow
                    key={key}
                    data-test-id={`dashboard_toolkit_addresslookup_section_annexes_overviewTable_cell_${key}`}
                  >
                    {Object.keys(annex).map(key2 => {
                      return (
                        <OverviewTable.BodyCell
                          key={key2}
                          data-test-id={`dashboard_toolkit_addresslookup_section_annexes_overviewTable_bodyCell_${key}`}
                        >
                          {mapValue(annex[key2], key2)}
                        </OverviewTable.BodyCell>
                      );
                    })}
                  </StyledRow>
                );
              })}
            </OverviewTable.Body>
          </OverviewTable>
        ) : (
          <p>{t('No annexes available for this parcel')}</p>
        )}
      </Section.Content>
    </Section>
  );
};

const StyledRow = styled(OverviewTable.Row)`
  &:hover * {
    background-color: white;
  }
`;

const Head = styled(OverviewTable.HeaderCell)`
  color: #5b5550;
  font-size: 1.6rem;
  font-weight: 700;
  width: 20%;
`;

AddressAnnexes.propTypes = {
  addressData: object.isRequired,
};

export default AddressAnnexes;
