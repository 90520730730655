import React, { useEffect, useState, createRef, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Alert,
  SubNavigation,
  BackButton,
  ActionButton,
  LoadingSpinner,
} from 'wg-fe-ui';
import cogoToast from 'cogo-toast';
import Frame from '../../../assets/images/Frame.svg';

import FamilyInformation from '../../components/CRMRiskObjectDetailFamily';
import { cloneDeep, isEqual } from 'lodash';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';
import { getMeData } from '../../services/meDataService';
import { getBrokerData } from '../../services/brokerDataService';
import {
  getRiskObjectByTypeAndId,
  createRiskObjectRevision,
} from '../../services/apiRouterService';

const CRMRiskObjectDetailFamily = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const refs = {};
  const configCatClient = useContext(FlagsProvider);
  const [familyData, setFamilyData] = useState(
    location?.state?.updatedFamilyData ?? {},
  );
  const [isLoading, setIsLoading] = useState(true);
  const [updatedFamilyData, setUpdatedFamilyData] = useState(
    location?.state?.updatedFamilyData ?? {},
  );
  const [canEditOffers, setCanEditOffers] = useState(true);
  const [updateRequired, setUpdateRequired] = useState(false);
  const [isCallantFeature, setIsCallantFeature] = useState();
  const [isProFlowEnabled, setIsProfFlowEnabled] = useState();

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  const {
    id: riskObjectId,
    revision: riskObjectRevision,
    offerId,
    contractCaseId,
    contractId,
    prospectId,
    leadId,
    offerRevision,
    riskObjectType,
    editing,
  } = useParams();

  useEffect(() => {
    setFeatureToggles();
    if (location?.state?.updatedFamilyData) return setIsLoading(false);
    retrieveFamilyData();
  }, []);

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const emailConfig = {
      email: me?.email,
      custom: {
        plan: broker?.plan,
      },
    };

    if (offerId || contractId || prospectId) {
      setCanEditOffers(
        leadId
          ? false
          : await configCatClient.getValueAsync(
              offerId || contractId
                ? 'editOffers'
                : 'canEditProspectsAndRiskObjects',
              false,
              emailConfig,
            ),
      );
    }

    setIsCallantFeature(
      await configCatClient.getValueAsync('callantFeature', true, emailConfig),
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,

        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  async function retrieveFamilyData() {
    const { distribution_id } = await getBrokerData();
    const [response, status] = await getRiskObjectByTypeAndId(
      distribution_id,
      'families',
      riskObjectId,
      riskObjectRevision,
    );

    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      console.log('FAILED TO LOAD');
      setIsLoading(false);
      setFamilyData(null);
      return;
    }

    setFamilyData(response);
    setUpdatedFamilyData(response);
    setIsLoading(!isLoading);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  });

  const showUpdateMessage = () => {
    setUpdateRequired(true);
  };

  const familyInformationSave = values => {
    const payload = cloneDeep(values);
    payload.risk_object_id = updatedFamilyData.risk_object_id;
    payload.risk_object_revision_id = updatedFamilyData.risk_object_revision_id;

    setUpdatedFamilyData(payload);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (!isEqual(familyData, payload)) {
      showUpdateMessage();
    }
  };

  const [sections] = useState([
    {
      id: 1,
      label: `${t('Risk object')} - ${t('Family')}`,
      subsections: [
        {
          id: 1,
          label: t('Family information'),
        },
      ],
    },
  ]);

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleSaveFamilyOfferChanges = () => {
    const pathname =
      prospectId && offerId
        ? `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`
        : `/sales-management/offer/${offerId}/${offerRevision}`;
    history.push({
      pathname,
      state: {
        ...location.state,
        updatedFamilyData,
      },
    });
  };

  const handleSaveFamilyContractChanges = () => {
    const pathname = prospectId
      ? `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`
      : `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    history.push({
      pathname,
      state: { ...location.state, updatedFamilyData },
    });
  };

  const handleSaveFamilyChanges = async () => {
    const { distribution_id } = await getBrokerData();
    const tempUpdatedFamilyData = cloneDeep(updatedFamilyData);
    delete tempUpdatedFamilyData.id;
    delete tempUpdatedFamilyData.risk_object_id;
    delete tempUpdatedFamilyData.risk_object_revision_id;
    let [response, status] = await createRiskObjectRevision(
      distribution_id,
      leadId || prospectId,
      'families',
      riskObjectId,
      tempUpdatedFamilyData,
    );

    if (!(status < 204 && status >= 200)) {
      cogoToast.error(t('Something went wrong'));
      return console.error(response);
    }

    cogoToast.success(t('Risk object revision successfully saved'));

    setUpdateRequired(false);
    history.push({
      pathname:
        prospectId && leadId
          ? `/sales-management/customer/${prospectId}/contracts/lead/${leadId}/risk-object/${riskObjectType}/${riskObjectId}/${response.risk_object_revision_id}`
          : `/sales-management/customer/${prospectId}/risk-object/${riskObjectType}/${riskObjectId}/${response.risk_object_revision_id}`,
      state: { ...location.state },
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const startNewTarrification = () => {
    history.push({
      pathname: `/professional-flow/new-tarrification-prospect/`,
      search: `?prospectId=${prospectId}`,
      state: { type: 'family', riskObjectId },
    });
  };

  const backButtonHandler = () => {
    let pathname = '';
    if (prospectId && offerId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`;
    } else if (prospectId && contractId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`;
    } else if (prospectId && leadId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/lead/${leadId}`;
    } else if (prospectId) {
      pathname = `/sales-management/customer/${prospectId}`;
    } else if (contractId) {
      pathname = `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    } else if (leadId) {
      pathname = `/sales-management/lead/${leadId}`;
    } else {
      pathname = `/sales-management/offer/${offerId}/${offerRevision}`;
    }
    history.push({
      pathname,
      state: { ...location.state },
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (familyData == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Risk object not found')}</h1>
        <p>
          {`${t('We could not find the risk object with id')}: `}{' '}
          <strong> {riskObjectId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton onClick={backButtonHandler} name={t('Previous page')} />
          </TopBarLeft>
        </TopBarContainer>
        {!isCallantFeature && (
          <TopBarRight>
            <TopBarAction>
              <ActionButton
                onClick={startNewTarrification}
                disabled={!isProFlowEnabled}
              >
                {t('New tarification')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        )}
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{t(section.label)}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
        </Sidebar>

        <Content>
          {updateRequired && offerId && !contractId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object, would you like to save these changes and go back to the offer',
                )}
                <ActionButton onClick={() => handleSaveFamilyOfferChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          {updateRequired && contractId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object, would you like to save these changes and go back to the contract',
                )}
                <ActionButton onClick={() => handleSaveFamilyContractChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          {updateRequired &&
          (prospectId || leadId) &&
          !contractId &&
          !offerId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object would you like to save these changes',
                )}
                <ActionButton onClick={() => handleSaveFamilyChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          <section ref={refs[1][1]}>
            <FamilyInformation
              familyData={updatedFamilyData}
              initEdit={editing ?? false}
              disableEditing={!canEditOffers}
              onSubmitFunction={familyInformationValues =>
                familyInformationSave(familyInformationValues)
              }
            ></FamilyInformation>
          </section>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 99999;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const StyledAlertContent = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    margin-right: 0.78vw;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

export default CRMRiskObjectDetailFamily;
