import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Section from './Section';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';

import Children from '../../assets/images/dashboard/family/Children';
import IsMarried from '../../assets/images/dashboard/family/IsMarried';
import LivingLessThanOneYearTogether from '../../assets/images/dashboard/family/LivingLessThanOneYearTogether';
import SharedObjects from '../../assets/images/dashboard/family/SharedObjects';
import Students from '../../assets/images/dashboard/family/Students';
import Travels from '../../assets/images/dashboard/family/Travels';
import WorksAtHome from '../../assets/images/dashboard/family/WorksAtHome';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryFamilySituation = ({ familySituationData }) => {
  const {
    children,
    is_married,
    living_less_than_one_year_together,
    shared_objects,
    students,
    travels,
    works_at_home,
  } = familySituationData || {};
  const { t } = useTranslation();

  const interestIcons = {
    children: Children,
    is_married: IsMarried,
    living_less_than_one_year_together: LivingLessThanOneYearTogether,
    shared_objects: SharedObjects,
    students: Students,
    travels: Travels,
    works_at_home: WorksAtHome,
  };

  return (
    <Section>
      <Section.Subtitle>{t('History.liability.title')}</Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_liability_family_situation">
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.worksAtHome')}
          checked={works_at_home}
          disabled={true}
          icon={interestIcons['works_at_home']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.travels')}
          checked={travels}
          disabled={true}
          icon={interestIcons['travels']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.children')}
          checked={children}
          disabled={true}
          icon={interestIcons['children']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.students')}
          checked={students}
          disabled={true}
          icon={interestIcons['students']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.cohabitant')}
          checked={is_married}
          disabled={true}
          icon={interestIcons['is_married']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.livingLessThanOneYearTogether')}
          checked={!living_less_than_one_year_together}
          disabled={true}
          icon={interestIcons['living_less_than_one_year_together']}
        />
        <DashboardCheckboxWithIcon
          wide={3}
          text={t('History.liability.sharedObjects')}
          checked={shared_objects}
          disabled={true}
          icon={interestIcons['shared_objects']}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

DashboardBrokerHistoryFamilySituation.propTypes = {
  familySituationData: shape().isRequired,
};

export default DashboardBrokerHistoryFamilySituation;
