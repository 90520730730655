/* stylelint-disable no-descending-specificity, declaration-no-important, declaration-block-no-redundant-longhand-properties */
/* eslint-disable no-unused-expressions*/
// packages
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// components
import Layout from '../../LayoutFlow/views/App';
import Button from '../../../../components/Button';
import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';
import CheckBox from '../../../../components/GlobalCheckBox';
import InfoPopUp from '../../../../components/InfoPopUp';

// images
import suvcar from '../../../../../assets/images/car/suv.svg';
import sportscar from '../../../../../assets/images/car/sportsCar.svg';
import cabriocar from '../../../../../assets/images/car/cabrio.svg';
import DefaultCarIcon from '../../../../../assets/images/DefaultCarIcon';

// stores
import {
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
  getCurrentTheme,
} from '../../../../store/index';
import {
  getAllCarData,
  getPayloadCar,
  addCarData,
  setPayloadCar,
} from '../store/index';
import InputBox from '../../../../components/InputBox';
import DateInput from '../../../../components/DateInputComponent';
import IconCheckbox from '../../../../components/IconCheckBox';
import { getBrokerDistribution } from '../../../../services/brokerDataService';

const CheckCar = ({ history }) => {
  const { t } = useTranslation();
  const [sendStep, setSendStep] = useState('initial');
  const [btnText, setBtnText] = useState(
    t(`${getComponentName()}.btnText.default`),
  );
  let inputBtw = '';
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [carExtras, setcarExtras] = useState(getAllCarData());
  const carData = getAllCarData();
  const carData2 = getPayloadCar();

  const [btwButton, setBtwButton] = useState(true);
  const [btwText, setBtwText] = useState('');
  const [btwButtonInvoice, setBtwButtonInvoice] = useState(true);
  const [btwTextInvoice, setBtwTextInvoice] = useState('');
  const [btwButtonOptions, setBtwButtonOptions] = useState(true);
  const [btwTextOptions, setBtwTextOptions] = useState('');
  //const [changeInput, setChangeInput] = useState('');
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    if (btwButton) {
      setBtwText(t(`${getComponentName()}.button_vat_exclu`));
    } else {
      setBtwText(t(`${getComponentName()}.button_vat_inclu`));
    }
  }, [btwButton]);

  useEffect(() => {
    if (btwButtonInvoice) {
      setBtwTextInvoice(t(`${getComponentName()}.button_vat_exclu`));
    } else {
      setBtwTextInvoice(t(`${getComponentName()}.button_vat_inclu`));
    }
  }, [btwButtonInvoice]);

  useEffect(() => {
    if (btwButtonOptions) {
      setBtwTextOptions(t(`${getComponentName()}.button_vat_exclu`));
    } else {
      setBtwTextOptions(t(`${getComponentName()}.button_vat_inclu`));
    }
  }, [btwButtonOptions]);

  let SignupSchema;
  let dateFormat = `DD-MM-YYYY`;
  let dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  SignupSchema = Yup.object().shape({
    registration_first: Yup.string()
      .required(t(`${getComponentName()}.errors.invalid_date`))
      .matches(dateRegex, t(`${getComponentName()}.errors.invalid_date`))
      .test('DOB', t(`${getComponentName()}.errors.invalid_date`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`${getComponentName()}.errors.invalid_date`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`${getComponentName()}.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 120,
      ),
    value: Yup.string().when('onlyBa', {
      is: false,
      then: Yup.string()
        .required(t(`${getComponentName()}.errors.required`))
        .matches(/^[0-9 _ ,]*$/, t(`${getComponentName()}.errors.consists`))
        .test(
          'value',
          t(`${getComponentName()}.errors.notZero`),
          val => val !== 0,
        )
        .test('length', t(`${getComponentName()}.errors.minimumValue`), val =>
          val ? parseFloat(val.replace(',', '.').replace(' ', '')) >= 100 : 0,
        )
        .test('maxValue', t(`${getComponentName()}.errors.maximumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) < 1000000
            : 0,
        ),
    }),
    invoice_value: Yup.string().when('onlyBa', {
      is: false,
      then: Yup.string()
        .matches(/^[0-9 _ ,]*$/, t(`${getComponentName()}.errors.consists`))
        .test('value', t(`${getComponentName()}.errors.notZero`), val =>
          val ? val !== 0 : !val,
        )
        .test('length', t(`${getComponentName()}.errors.minimumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) >= 100
            : !val,
        )
        .test('maxValue', t(`${getComponentName()}.errors.maximumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) < 1000000
            : !val,
        ),
    }),
    options_value: Yup.string().when('onlyBa', {
      is: false,
      then: Yup.string()
        .matches(/^[0-9 _ ,]*$/, t(`${getComponentName()}.errors.consists`))
        .test('value', t(`${getComponentName()}.errors.notZero`), val =>
          val ? val !== 0 : !val,
        )
        .test('length', t(`${getComponentName()}.errors.minimumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) >= 100
            : !val,
        )
        .test('maxValue', t(`${getComponentName()}.errors.maximumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) < 1000000
            : !val,
        ),
    }),
    onlyBa: Yup.bool().isRequired,
  });

  useEffect(() => {
    let btnText;
    let errorMsg = null;

    const diaMunichAccounts = [
      'lander_broker@wegroup.be',
      'dia_broker@wegroup.be',
    ];

    switch (sendStep) {
      case 'pending':
        btnText = t(`${getComponentName()}.btnText.pending`);
        setIsDisabled(true);
        break;
      case 'success':
        btnText = t(`${getComponentName()}.btnText.success`);
        (async function() {
          const { email } = await getBrokerDistribution();
          if (diaMunichAccounts.includes(email)) {
            setPayloadCar('km_per_year', 10000);
            setPayloadCar('replacement_after_accident', true);
            setPayloadCar('vehicle_usage', 'private');
            setPayloadCar('car_loan', false);
            history.push(getNextPath('c'));
          } else {
            history.push(getNextPath('a'));
          }
        })();

        break;
      case 'error':
        errorMsg = t(`${getComponentName()}.errorMsg`);
        btnText = t(`${getComponentName()}.btnText.error`);
        break;
      default:
        btnText = t(`${getComponentName()}.btnText.default`);
        break;
    }
    setBtnText(btnText);
    setErrorMsg(errorMsg);
  }, [sendStep]);

  const clickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    Object.keys(values).forEach(valueKey => {
      let value;
      if (
        valueKey === 'registration_first' ||
        (valueKey === 'registration_last' && values[valueKey])
      ) {
        value = values[valueKey]
          .split('-')
          .reverse()
          .join('-');
      } else {
        value = values[valueKey];
      }
      if (!values.onlyBa) {
        addCarData('onlyBa', false);
      }
      if (
        valueKey === 'registration_first' ||
        valueKey === 'registration_last' ||
        valueKey === 'onlyBa'
      ) {
        addCarData(valueKey, value);
      } else {
        if (valueKey === 'value') {
          if (btwText === t(`${getComponentName()}.button_vat_exclu`)) {
            addCarData(
              valueKey,
              parseFloat(value.replace(',', '.').replace(' ', '')),
            );
          } else {
            addCarData(
              valueKey,
              parseFloat(
                (value.replace(',', '.').replace(' ', '') / 1.21).toFixed(2),
              ),
            );
          }
        } else if (valueKey === 'invoice_value') {
          if (btwTextInvoice === t(`${getComponentName()}.button_vat_exclu`)) {
            addCarData(
              valueKey,
              parseFloat(value.replace(',', '.').replace(' ', '')),
            );
          } else {
            addCarData(
              valueKey,
              parseFloat(
                (value.replace(',', '.').replace(' ', '') / 1.21).toFixed(2),
              ),
            );
          }
        } else if (valueKey === 'options_value') {
          if (btwTextOptions === t(`${getComponentName()}.button_vat_exclu`)) {
            addCarData(
              valueKey,
              parseFloat(value.replace(',', '.').replace(' ', '')),
            );
          } else {
            addCarData(
              valueKey,
              parseFloat(
                (value.replace(',', '.').replace(' ', '') / 1.21).toFixed(2),
              ),
            );
          }
        }
      }
    });

    addChatboxQuestion(
      t(`${getComponentName()}.question`, {
        name: carData.name,
      }),
    );

    addChatboxAnswer(
      t(`${getComponentName()}.interactiveMsg`, {
        registrationFirst: values['registration_first'],
        carValue: values['invoice_value']
          ? parseFloat(
              values['invoice_value'].replace(',', '.').replace(' ', ''),
            )
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
          : parseFloat(values['value'].replace(',', '.').replace(' ', ''))
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '),
        VAT:
          (values['value'] || values['invoice_value']) &&
          btwText === t(`${getComponentName()}.button_vat_exclu`)
            ? t(`${getComponentName()}.button_vat_exclu`).toLowerCase()
            : t(`${getComponentName()}.button_vat_inclu`).toLowerCase(),
      }),
    );
    setSendStep('success');
  };

  const disableHandler = (errors, touched) => {
    if (errors & touched) {
      if (
        Object.entries(errors).length === 0 &&
        Object.entries(touched).length
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else {
      if (Object.entries(errors).length === 0) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  };

  const handleBTW = (id, setFieldValue) => {
    let input = parseFloat(
      document
        .querySelector(`#${id}`)
        .value.replace(',', '.')
        .replace(/ /g, ''), // Replace ALL spaces in the string instead of the first
    );
    if (!isNaN(input)) {
      if (id === 'value') {
        if (btwText === t(`${getComponentName()}.button_vat_exclu`)) {
          setBtwButton(false);
          inputBtw = (input * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData('value', parseFloat((input * 1.21).toFixed(2)));
        } else {
          setBtwButton(true);
          inputBtw = (input / 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData({ value: parseFloat((input / 1.21).toFixed(2)) });
        }
      } else if (id === 'invoice_value') {
        if (btwTextInvoice === t(`${getComponentName()}.button_vat_exclu`)) {
          setBtwButtonInvoice(false);
          inputBtw = (input * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData('invoice_value', parseFloat((input * 1.21).toFixed(2)));
        } else {
          setBtwButtonInvoice(true);
          inputBtw = (input / 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData({
            invoice_value: parseFloat((input / 1.21).toFixed(2)),
          });
        }
      } else if (id === 'options_value') {
        if (btwTextOptions === t(`${getComponentName()}.button_vat_exclu`)) {
          setBtwButtonOptions(false);
          inputBtw = (input * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData('options_value', parseFloat((input * 1.21).toFixed(2)));
        } else {
          setBtwButtonOptions(true);
          inputBtw = (input / 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData({
            options_value: parseFloat((input / 1.21).toFixed(2)),
          });
        }
      }
    } else {
      if (id === 'value') {
        inputBtw = '';
        if (btwText === t(`CheckCar.button_vat_exclu`)) {
          setBtwButton(false);
        } else {
          setBtwButton(true);
        }
      } else if (id === 'invoice_value') {
        inputBtw = '';
        if (btwTextInvoice === t(`CheckCar.button_vat_exclu`)) {
          setBtwButtonInvoice(false);
        } else {
          setBtwButtonInvoice(true);
        }
      } else if (id === 'options_value') {
        inputBtw = '';
        if (btwTextOptions === t(`CheckCar.button_vat_exclu`)) {
          setBtwButtonOptions(false);
        } else {
          setBtwButtonOptions(true);
        }
      }
    }
    setFieldValue(id, inputBtw);
  };

  const checkValue = type => {
    let value, invoiceValue, registration_first, optionsValue;

    let inputValue = carData.value;
    let inputInvoice = carData.invoice_value;
    let inputOptions = carData.options_value;
    if (inputValue) {
      inputValue /= 1.21;
    }
    if (inputInvoice) {
      inputInvoice /= 1.21;
    }
    if (inputOptions) {
      inputOptions /= 1.21;
    }
    carData.invoice_value
      ? btwTextInvoice === t(`${getComponentName()}.button_vat_exclu`)
        ? (invoiceValue = (inputInvoice * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        : (invoiceValue = inputInvoice
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
      : (invoiceValue = '');
    carData.options_value
      ? btwTextOptions === t(`${getComponentName()}.button_vat_exclu`)
        ? (optionsValue = (inputOptions * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        : (optionsValue = inputOptions
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
      : (optionsValue = '');
    carData.value
      ? btwText === t(`${getComponentName()}.button_vat_exclu`)
        ? (value = (inputValue * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        : (value = inputValue
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
      : (value = '');
    if (carData2.vehicle_status === 'wants_car') {
      registration_first = moment().format(dateFormat);
    } else {
      if (carData) {
        registration_first = carData?.registration_first
          ?.split('-')
          ?.reverse()
          ?.join('-');
      }

      if (carData.registration_first === '') {
        registration_first = moment().format(dateFormat);
      }
    }

    switch (type) {
      case 'value':
        return value;
      case 'invoice_value':
        return invoiceValue;
      case 'registration_first':
        return registration_first;
      case 'options_value':
        return optionsValue;
    }
  };

  const onClickExtras = (name, checked) => {
    const tempExtras = { ...carExtras };
    tempExtras[name] = checked;
    addCarData(name, checked);
    setcarExtras(tempExtras);
  };

  const editHandler = values => {
    let inputValue = document.querySelector('#value');
    let inputValueInvoice = document.querySelector('#invoice_value');
    let inputValueOptions = document.querySelector('#options_value');
    let inputInvoice;
    let inputOptions;
    if (inputValueInvoice) {
      inputInvoice = parseFloat(
        inputValueInvoice.value.replace(',', '.').replace(' ', ''),
      );
      addCarData('invoice_value', inputInvoice);
    }
    if (inputValueOptions) {
      inputOptions = parseFloat(
        inputValueOptions.value.replace(',', '.').replace(' ', ''),
      );
      addCarData('options_value', inputOptions);
    }
    let input;
    if (inputValue) {
      input = parseFloat(inputValue.value.replace(',', '.').replace(' ', ''));
    }

    if (values.registration_first) {
      addCarData(
        'registration_first',
        values.registration_first
          .split('-')
          .reverse()
          .join('-'),
      );
    }
    if (input) {
      if (btwText === t(`${getComponentName()}.button_vat_exclu`)) {
        addCarData('value', input);
      } else {
        addCarData('value', parseFloat((input / 1.21).toFixed(2)));
      }
    }
    addCarData('onlyBa', values.onlyBa);
    history.push(getNextPath('b'));
  };

  return (
    <Layout>
      <Container>
        <StyledLeftSide>
          <RoundImage>
            {carData.img ? (
              <Img
                bg={
                  carData.img
                    ? carData.img.replace(/\(/g, '\\(').replace(/\)/g, '\\)')
                    : null
                }
              />
            ) : (
              <StyledDefaultCarIcon colors={getCurrentTheme()} />
            )}
          </RoundImage>
          <CarName>
            <span>{t(`${getComponentName()}.title`)}</span>
            <h2>{carData.name}</h2>
            {carData.color_hex ? (
              <ColorButton color={carData.color_hex} />
            ) : null}
          </CarName>
        </StyledLeftSide>
        <RightSide>
          <Info>
            <TitleForm>{t(`${getComponentName()}.specifications`)}</TitleForm>
            {t(`${getComponentName()}.info`) ? (
              <InfoPopUp
                info={t(`${getComponentName()}.info`)}
                title={t(`${getComponentName()}.info_title`)}
              />
            ) : null}
          </Info>
          <Specifications>
            <LeftSpecification>
              <div>
                <Label> {t(`ToolkitInfo.carInfo.motor_type`)}</Label>
                <Data>
                  {carData.motor_type
                    ? t(`ToolkitInfo.motor_types.${carData.motor_type}`)
                    : t(`Checkout.nvt`)}
                </Data>
              </div>
              {/* <div>
                <Label> {t(`${getComponentName()}.modelYear`)}</Label>
                <Data>
                  {carData.model_year ? carData.model_year : t(`Checkout.nvt`)}
                </Data>
              </div> */}
              <div>
                <Label> {t(`ToolkitInfo.carInfo.registration_last`)}</Label>
                <Data>
                  {carData.registration_last
                    ? carData.registration_last
                    : t(`Checkout.nvt`)}
                </Data>
              </div>
            </LeftSpecification>
            <RightSpecification>
              <div>
                <Label> {t(`ToolkitInfo.carInfo.seats`)}</Label>
                <Data>{carData.seats ? carData.seats : t(`Checkout.nvt`)}</Data>
                <InfoButton
                  title={null}
                  info={t(`The number of drivers is indicated incl driver`)}
                />
              </div>
              <div>
                <Label> {t(`ToolkitInfo.carInfo.kw`)}</Label>
                <Data>
                  {carData.kw ? `${carData.kw} KW` : t(`Checkout.nvt`)}
                </Data>
              </div>
              {/* <div>
                <Label> {t(`ToolkitInfo.carInfo.cc`)}</Label>
                <Data>
                  {carData.cc ? `${carData.cc} CC` : t(`Checkout.nvt`)}
                </Data>
              </div> */}
            </RightSpecification>
          </Specifications>
          <Formik
            initialValues={{
              registration_first: checkValue('registration_first'),
              value: checkValue('value'),
              invoice_value: checkValue('invoice_value'),
              options_value: checkValue('options_value'),
              onlyBa: getAllCarData().onlyBa ? getAllCarData().onlyBa : false,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => {
              disableHandler(errors, touched);
              return (
                <FormWrapper>
                  <InputWrapper>
                    <RegistrationContainer>
                      <FirstRegistration>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t(
                              `${getComponentName()}.registration_first_question`,
                            ),
                          }}
                        />
                        <StyledDateInput
                          errors={errors}
                          dataTestId="underwriting_car_first_registration"
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          disable={false}
                          type="text"
                          name="registration_first"
                          value={values['registration_first']}
                        />
                      </FirstRegistration>
                    </RegistrationContainer>
                    <FirstRegistration>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`${getComponentName()}.catalogue_question`),
                        }}
                      />
                      <BTWContainer>
                        {values.onlyBa ? (
                          <NVT>{t(`Checkout.nvt`)}</NVT>
                        ) : (
                          <>
                            <StyledInputBox
                              errors={errors}
                              touched={touched}
                              disabled={values.onlyBa}
                              dataTestId="underwriting_car_value"
                              type="text"
                              name="value"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            >
                              <EuroSign>&euro;</EuroSign>
                            </StyledInputBox>
                            <BTWButton
                              dataTestId="underwriting_car_vat_button"
                              onClick={() => handleBTW('value', setFieldValue)}
                              type="button"
                            >
                              {btwText}
                            </BTWButton>
                          </>
                        )}
                      </BTWContainer>
                    </FirstRegistration>
                    <OptionsQuestion>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`${getComponentName()}.options_question`),
                        }}
                      />
                      <BTWContainer>
                        {values.onlyBa ? (
                          <NVT>{t(`Checkout.nvt`)}</NVT>
                        ) : (
                          <>
                            <StyledInputBox
                              errors={errors}
                              touched={touched}
                              disabled={values.onlyBa}
                              dataTestId="underwriting_car_options_value"
                              type="text"
                              name="options_value"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            >
                              <EuroSign>&euro;</EuroSign>
                            </StyledInputBox>
                            <BTWButton
                              dataTestId="underwriting_car_vat_button"
                              onClick={() =>
                                handleBTW('options_value', setFieldValue)
                              }
                              type="button"
                            >
                              {btwTextOptions}
                            </BTWButton>
                          </>
                        )}
                      </BTWContainer>
                    </OptionsQuestion>
                    <FirstRegistration>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`${getComponentName()}.invoice_value`),
                        }}
                      />
                      <BTWContainer>
                        {values.onlyBa ? (
                          <NVT>{t(`Checkout.nvt`)}</NVT>
                        ) : (
                          <>
                            <StyledInputBox
                              errors={errors}
                              touched={touched}
                              disabled={values.onlyBa}
                              dataTestId="underwriting_car_value"
                              type="text"
                              name="invoice_value"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            >
                              <EuroSign>&euro;</EuroSign>
                            </StyledInputBox>
                            <BTWButton
                              dataTestId="underwriting_car_vat_button"
                              onClick={() =>
                                handleBTW('invoice_value', setFieldValue)
                              }
                              type="button"
                            >
                              {btwTextInvoice}
                            </BTWButton>
                          </>
                        )}
                      </BTWContainer>
                    </FirstRegistration>
                    <CheckBox
                      errors={errors}
                      type="checkbox"
                      touched={touched}
                      checked={values.onlyBa}
                      name="onlyBa"
                      dataTestId="underwriting_personal_agree1"
                    >
                      {t(
                        `I do not wish to receive proposals for an Omnium or Mini Omnium insurance`,
                      )}
                    </CheckBox>
                  </InputWrapper>
                  <Extra>
                    <TitleForm>Extras</TitleForm>
                    <SortCar>
                      <ExtraSortCar
                        dataTestId="underwriting_car_is_jeep"
                        checked={carExtras.is_jeep}
                        name={t(`${getComponentName()}.sortCar.SUV.key`)}
                        onCheck={(name, checked) =>
                          onClickExtras(name, checked)
                        }
                      >
                        <IconExtra
                          alt={t(`${getComponentName()}.sortCar.SUV.label`)}
                          src={suvcar}
                        />
                        <p>{t(`${getComponentName()}.sortCar.SUV.label`)}</p>
                      </ExtraSortCar>
                      <ExtraSortCar
                        dataTestId="underwriting_car_is_sportscar"
                        checked={carExtras.is_sportscar}
                        name={t(`${getComponentName()}.sortCar.sportsCar.key`)}
                        onCheck={(name, checked) =>
                          onClickExtras(name, checked)
                        }
                      >
                        <IconExtra
                          alt={t(
                            `${getComponentName()}.sortCar.sportsCar.label`,
                          )}
                          src={sportscar}
                        />
                        <p>
                          {t(`${getComponentName()}.sortCar.sportsCar.label`)}
                        </p>
                      </ExtraSortCar>
                      <ExtraSortCar
                        dataTestId="underwriting_car_is_cabrio"
                        checked={carExtras.is_cabrio}
                        name={t(`${getComponentName()}.sortCar.cabrio.key`)}
                        onCheck={(name, checked) =>
                          onClickExtras(name, checked)
                        }
                      >
                        <IconExtra
                          alt={t(`${getComponentName()}.sortCar.cabrio.label`)}
                          src={cabriocar}
                        />
                        <p>{t(`${getComponentName()}.sortCar.cabrio.label`)}</p>
                      </ExtraSortCar>
                    </SortCar>
                  </Extra>
                  <ButtonContainer>
                    {errorMsg ? <ErrorMsg>{errorMsg}</ErrorMsg> : null}
                    <EditButton
                      data-test-id="underwriting_car_manual"
                      type="button"
                      onClick={() => editHandler(values)}
                      secondary
                    >
                      {t(`${getComponentName()}.btnText.incorrect`)}
                    </EditButton>
                    <Button
                      disabled={isDisabled}
                      dataTestId="underwriting_car_submit"
                      type="submit"
                    >
                      {btnText}
                    </Button>
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </RightSide>
      </Container>
    </Layout>
  );
};

const EditButton = styled.p`
  text-decoration: underline;
  color: ${({ theme }) => theme.brand.primary};
  cursor: pointer;
  line-height: 2rem;
`;

const StyledInputBox = styled(InputBox)`
  label {
    width: 51%;
  }
  input {
    padding-left: 2.3rem;
  }

  & > div > p {
    align-self: flex-start !important;
  }

  & input:disabled {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none;
    color: black;
  }
`;

const StyledDateInput = styled(DateInput)`
  height: 30px;
  & > label {
    margin-top: 0;
  }
`;

const Specifications = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  flex-shrink: 0;
`;

const NVT = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 4rem;
`;

const RightSpecification = styled.div`
  width: 50%;

  & div {
    margin-bottom: 1.2rem;
  }
`;

const LeftSpecification = styled.div`
  width: 50%;
  margin-right: 1rem;

  & div {
    margin-bottom: 1.2rem;
  }
`;

const Label = styled.p`
  font-size: 1.4rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.typo.interactive};
`;

const Data = styled.p`
  font-weight: 900;
  display: inline-block;
  font-size: 1.5rem;
  color: #505050;
`;

const RegistrationContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & div {
    width: 100%;
  }

  & div + div {
    margin-left: 1rem;
  }
`;

const Extra = styled.div`
  margin-top: 1rem;
`;

const BTWButton = styled(Button)`
  width: 7.6rem;
  min-width: 0;
  height: 3.5rem;
  justify-content: center;
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0.5rem;
  padding: 0 !important;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
`;

const Img = styled.div`
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 0;
  background-image: url(${props => props.bg});
  background-size: cover;
  background-position: center;
`;

const ColorButton = styled.div`
  background-color: ${props => props.color};
  border: 0.5rem solid white;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
  height: 6rem;
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  width: 6rem;
`;

const StyledDefaultCarIcon = styled(DefaultCarIcon)`
  position: absolute;
  left: 0;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  right: 0;
  bottom: 0;
  top: 0;
`;

const CarName = styled.div`
  background: white;
  box-shadow: 0 0.4rem 1.3rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  margin-top: -10rem;
  padding: 2rem 1.8rem;
  position: relative;
  width: 85%;

  & span {
    font-size: 1.2rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.typo.interactive};
  }

  & h2 {
    color: #505050;
    font-weight: 900;
    font-size: 1.6rem;
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-weight: 500;
  margin-right: 2rem;
  font-size: 1.6rem;
`;

const FirstRegistration = styled.div`
  display: flex;
  font-size: 1.4rem;
  margin-bottom: 1.4rem;

  & p {
    line-height: 1.3;
    width: 46%;
    margin-right: 2rem;
    align-self: center;
    color: ${({ theme }) => theme.typo.interactive};

    & span {
      font-weight: 900;
      font-size: 1.4rem;
      color: #505050;
    }
  }

  & div {
    width: 50%;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;

    & div {
      width: 60%;

      & p {
        width: 100%;
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;

    & p {
      width: 100%;
      align-self: flex-start;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    & div {
      width: 100%;

      & div {
        width: 100%;

        & p {
          width: 100%;
        }
      }
    }
  }
`;

const OptionsQuestion = styled.div`
  display: flex;
  font-size: 1.4rem;
  margin-top: -2rem;
  margin-bottom: 1.4rem;

  & p {
    line-height: 1.3;
    width: 46%;
    margin-right: 2rem;
    align-self: center;
    color: ${({ theme }) => theme.typo.interactive};

    & span {
      font-weight: 900;
      font-size: 1.4rem;
      color: #505050;
    }
  }

  & div {
    width: 50%;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;

    & div {
      width: 60%;

      & p {
        width: 100%;
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;

    & p {
      width: 100%;
      align-self: flex-start;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    & div {
      width: 100%;

      & div {
        width: 100%;

        & p {
          width: 100%;
        }
      }
    }
  }
`;

const BTWContainer = styled.div`
  display: flex;
  flex-direction: column;

  & div {
    position: absolute;
    width: 48%;
    margin-bottom: 0;
    margin-top: 0;
  }

  & label {
    & input {
      -webkit-appearance: none;
    }
  }
`;

const EuroSign = styled.span`
  position: absolute;
  font-weight: bold;
  top: -1.75rem;
  left: 6%;

  @media screen and (max-width: 1050px) {
    top: -5.2rem;
    left: 75%;
  }
`;

const StyledLeftSide = styled(LeftSide)`
  justify-content: center;
  width: 50%;
  /* margin-top: 10rem; */
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    line-height: 2.1rem;
    word-wrap: wrap;
    font-size: 1.5rem;
    align-items: baseline;
    transition: all 0.2s ease;
  }
`;

const InfoButton = styled(InfoPopUp)`
  height: 2rem;
  width: 2rem;
  font-size: 1.4rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  border-radius: 3rem;
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  & button {
    :first-child {
      background-color: ${({ theme }) => theme.ui.interactive};
    }
    :last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.6rem;
  flex-shrink: 0;
`;

const FormWrapper = styled(Form)`
  /* display: flex; */
  width: 100%;
  flex-shrink: 0;
  position: relative;
  padding-bottom: 2rem;
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  width: 100%;

  & input {
    margin-top: 0;
  }
`;

const RoundImage = styled.div`
  width: 75%;
  padding-top: 75%;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const SortCar = styled.div`
  display: flex;
  flex-direction: row;
  width: 102%;
  margin-top: 1.8rem;
  flex-wrap: wrap;
`;

const ExtraSortCar = styled(IconCheckbox)`
  font-size: 1.5rem;
`;

const IconExtra = styled.img`
  width: 3.2rem;
  margin-right: 1rem;
`;

const Info = styled.p`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
`;

CheckCar.propTypes = {
  history: shape().isRequired,
};

export default withRouter(CheckCar);
