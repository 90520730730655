import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutFlow/views/App';

import {
  getRoomLayout,
  getTraits,
  setRoomLayout,
  getAddressInfo,
} from '../store/index';
import Counter from '../components/Counter';
import InteractiveApartment from '../components/house/InteractiveApartment';
import InteractiveHouse from '../components/house/InteractiveHouse';
import Button from '../../../../components/Button';
import {
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { useTranslation } from 'react-i18next';

const Rooms = ({ history }) => {
  const { t } = useTranslation();
  const [roomData, setRoomData] = useState(getRoomLayout());
  const [increaseDisabled, setIncreaseDisabled] = useState(() => {
    let roomCount = 0;
    Object.keys(roomData).forEach(room => {
      roomCount += roomData[room];
    });
    roomCount -= roomData.room_count;
    if (roomCount >= 13) {
      return true;
    }
    return false;
  });

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const [minLimit, setMinLimit] = useState(() => {
    let roomCount = 0;
    Object.keys(roomData).forEach(room => {
      roomCount += roomData[room];
    });
    roomCount -= roomData.room_count;
    if (roomCount <= 2) {
      return true;
    }
    return false;
  });

  const [maxLimit, setMaxLimit] = useState(() => {
    let roomCount = 0;
    Object.keys(roomData).forEach(room => {
      roomCount += roomData[room];
    });
    roomCount -= roomData.room_count;
    if (roomCount >= 13) {
      return true;
    }
    return false;
  });

  const [decreaseDisabled, setDecreaseDisabled] = useState(() => {
    let roomCount = 0;
    Object.keys(roomData).forEach(room => {
      roomCount += roomData[room];
    });
    roomCount -= roomData.room_count;
    if (roomCount >= 2 && roomCount <= 13) {
      return false;
    }
    return true;
  });

  const [errorMsg, setErrorMsg] = useState(() => {
    let roomCount = 0;
    Object.keys(roomData).forEach(room => {
      roomCount += roomData[room];
    });
    roomCount -= roomData.room_count;
    if (roomCount >= 2 && roomCount <= 13) {
      return null;
    }

    return t(`${getComponentName()}.errorMsgMax`);
  });

  const traits = getTraits();
  const rooms = t(`${getComponentName()}.rooms`, { returnObjects: true });
  addChatboxQuestion(t(`${getComponentName()}.question`));

  const inputChanged = (id, value) => {
    const variable = id;
    const prevRoomData = { ...roomData };
    prevRoomData[variable] = value;
    let roomCount = 0;
    setErrorMsg(null);
    Object.keys(prevRoomData).forEach(room => {
      roomCount += prevRoomData[room];
    });
    roomCount -= prevRoomData.room_count;
    if (roomCount < 2) {
      setErrorMsg(t(`${getComponentName()}.errorMsgMin`));
      setDecreaseDisabled(true);
    } else if (roomCount === 2) {
      setMinLimit(true);
      setDecreaseDisabled(true);
    } else if (roomCount > 2 && roomCount < 13) {
      setDecreaseDisabled(false);
      setIncreaseDisabled(false);
      setErrorMsg(null);
      setMaxLimit(false);
      setMinLimit(false);
    } else if (roomCount === 13) {
      setMaxLimit(true);
      setIncreaseDisabled(true);
    } else if (roomCount > 13) {
      setIncreaseDisabled(true);
      setErrorMsg(t(`${getComponentName()}.errorMsgMax`));
    }
    prevRoomData.room_count = roomCount;
    setRoomData(prevRoomData);
  };

  const buttonsClicked = direction => {
    if (direction === 'increase' && maxLimit) {
      setErrorMsg(t(`${getComponentName()}.errorMsgMax`));
    }
    if (direction === 'decrease' && minLimit) {
      setErrorMsg(t(`${getComponentName()}.errorMsgMin`));
    }
  };

  const getRoomLabel = key => {
    const rooms = t(`${getComponentName()}.roomlabels`, {
      returnObjects: true,
    });
    const roomKeys = Object.keys(rooms);
    const roomsText = [];
    const data = getRoomLayout();

    roomKeys.forEach(roomKey => {
      const room = rooms[roomKey];
      const amount =
        data[roomKey] === 0 ? t(`${getComponentName()}.none`) : data[roomKey];
      const word = data[roomKey] === 1 ? room[0] : room[1];
      const label = `<span>${amount}</span> ${word}`;
      roomsText[roomKey] = label;
    });

    return roomsText[key];
  };

  const onClickHandler = () => {
    setRoomLayout(roomData);
    addChatboxAnswer(
      t(`${getComponentName()}.message`, {
        living_rooms: getRoomLabel('living_rooms'),
        bathrooms: getRoomLabel('bathrooms'),
        garages: getRoomLabel('garages'),
        kitchens: getRoomLabel('kitchens'),
        bedrooms: getRoomLabel('bedrooms'),
        other_rooms: getRoomLabel('other_rooms'),
      }),
    );
    history.push(getNextPath());
  };

  return (
    <Layout>
      <Container>
        <h1>{t(`${getComponentName()}.title`)}</h1>
        {getAddressInfo().building === 'apartment' ? (
          <InteractiveApartment roomData={roomData} />
        ) : (
          <InteractiveHouse
            buildingType={getAddressInfo().building}
            roomData={roomData}
            traits={traits}
          />
        )}
        <CounterContainer>
          {rooms.map(extra => (
            <Counter
              key={extra.key}
              buttonTouched={buttonsClicked}
              increaseDisabled={increaseDisabled}
              decreaseDisabled={decreaseDisabled}
              id={extra.key}
              ifChanged={(id, value) => inputChanged(id, value)}
              info={extra.info}
            >
              {extra.text}
            </Counter>
          ))}
          <ErrorMsg>{errorMsg !== null ? errorMsg : null}</ErrorMsg>
          <ButtonContainer>
            <Button
              dataTestId="underwriting_home_submit"
              onClick={() => onClickHandler()}
            >
              {t(`${getComponentName()}.btnText`)}
            </Button>
          </ButtonContainer>
        </CounterContainer>
      </Container>
    </Layout>
  );
};

const ErrorMsg = styled.div`
  color: red;
  font-size: 1.4rem;
  width: 100%;
  line-height: 1.35;
  padding-right: 25rem;
  text-align: left;
  font-weight: 700;
  transform: scale(0.9);
  margin-right: 1rem;
  flex: 0 0 100%;
  margin-top: 2rem;
  min-height: 3.7rem;

  @media screen and (max-width: 840px) {
    padding-left: 2rem;
    padding-right: 25rem;
    text-align: left;
  }
`;

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem 0;
  padding-top: 4rem;
  position: relative;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }

  & h1 {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
  }
`;

const CounterContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 95%;
  max-width: 95%;
  margin: 1rem auto;
  justify-content: center;
  position: relative;
  padding: 0 10%;

  @media screen and (max-width: 1025px) {
    width: 85%;
    max-width: 90%;
  }

  @media screen and (max-width: 900px) {
    padding: 0;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 10%;
  bottom: 0;
  padding: 0;
  border-radius: 3rem;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 2rem;
  & button {
    margin: 0;
  }
`;

Rooms.propTypes = {
  history: shape().isRequired,
};

export default withRouter(Rooms);
