import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
// import QuestionBox from '../../../../components/QuestionBox';
import QuestionBox from '../../../../components/QuestionBox';
import { shape } from 'prop-types';
import {
  getAllCoverages,
  setSkippedSteps,
  getCurrentStep,
  setNotice,
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
  getFlow,
} from '../../../../store';
import {
  addAnswer,
  sendHomeData,
  postAddressDataExtra,
  setInsuredData,
  getTraits,
  getAddressInfo,
} from '../store';
import { setFireName } from '../../../Marketplace/Market/store';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';

const InsuranceQuestionHome = ({ history }) => {
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const { t } = useTranslation();
  const coverages = getAllCoverages();
  const answerA = t(`${getComponentName()}.answers.0.title`);
  const answerB = t(`${getComponentName()}.answers.1.title`);
  const question = t(`${getComponentName()}.question`);
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const calculateInsuranceName = () => {
    const traits = getTraits();
    const adressInfo = getAddressInfo();

    if (traits.is_owner) {
      if (adressInfo.building === 'apartment' && traits.trustee) {
        setFireName(t('Checkout.insuranceNames.fire.1'));
      } else {
        setFireName(t('Checkout.insuranceNames.fire.0'));
      }
    } else {
      setFireName(t('Checkout.insuranceNames.fire.2'));
    }
  };

  const handleAnswer = answer => {
    if (isPending) return false;
    calculateInsuranceName();
    setIsPending(true);
    addChatboxQuestion(t(`${getComponentName()}.question`));
    switch (answer) {
      case answerA:
        addAnswer(answer, 'fire_insurance', true);
        setAnswer(1);
        addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
        history.push(getNextPath('a'));
        break;
      case answerB:
        setAnswer(2);
        setInsuredData('already_insured_with', false);
        setNotice('home', false, null);
        sendHomeData()
          .then(() => {
            postAddressDataExtra()
              .then(() => {
                setSkippedSteps(getCurrentStep(), false);
                addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
                console.log(coverages, getFlow());
                if (coverages.family && getFlow().family) {
                  history.push(getNextPath('b'));
                } else {
                  history.push(getNextPath('c'));
                }
              })
              .catch(error => {
                console.error(error.response);
              });
          })
          .catch(error => {
            console.error(error.response);
          });
        break;
    }
  };
  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <QuestionBox
          to={null}
          Answer1={answerA}
          Answer2={answerB}
          dataTestId1="underwriting_answer_yes"
          dataTestId2="underwriting_answer_no"
          shouldLoad={isPending ? answer : undefined}
          noLink={null}
          response={answer => handleAnswer(answer)}
        >
          {question}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

InsuranceQuestionHome.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(InsuranceQuestionHome);
