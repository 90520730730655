import React from 'react';
import { shape, bool } from 'prop-types';

const CustomerLinks = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };

  return (
    <svg
      width="60"
      height="61"
      viewBox="0 0 60 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30.2012" r="30" fill={colorsDict.secondaryColor} />
      <path
        d="M40.7821 21.9844C40.6624 21.5803 40.4932 21.1879 40.2745 20.8171C40.2304 20.7433 40.1854 20.6695 40.1368 20.5975C39.9937 20.3806 39.8326 20.1718 39.6534 19.9729C39.594 19.9072 39.5319 19.8415 39.468 19.7785C39.4068 19.7173 39.3438 19.657 39.2799 19.5994C39.0882 19.4257 38.8848 19.2673 38.6724 19.125C38.6013 19.0773 38.5293 19.0323 38.4573 18.9882C38.3115 18.9009 38.1621 18.8208 38.0091 18.7488C37.9326 18.7128 37.8552 18.6786 37.7769 18.6462C37.6203 18.5814 37.461 18.5247 37.2981 18.4752C36.5672 18.2547 35.7797 18.1935 34.9796 18.3096C33.8267 18.477 32.779 19.0764 31.9546 19.9L25.5653 26.2893C24.083 27.7716 23.6797 29.9299 24.3548 31.7812C24.5024 32.1854 24.7013 32.5751 24.9515 32.9396C25.1306 33.2006 25.334 33.449 25.5662 33.6803C25.8047 33.9188 26.063 34.1312 26.3366 34.3157C26.7002 34.5614 27.0908 34.7594 27.5022 34.9052C28.1673 35.1411 28.8846 35.2428 29.6199 35.1942C30.9475 35.1069 32.1769 34.4606 33.1183 33.5201L34.8761 31.7623C35.0939 31.5445 35.102 31.198 34.8995 30.9658C34.7186 30.7597 34.4882 30.5329 34.2776 30.3574C34.0445 30.1621 33.7043 30.1729 33.4891 30.3871L31.5775 32.2988C30.9628 32.9135 30.1401 33.2528 29.2626 33.2528C29.2275 33.2528 29.1924 33.251 29.1573 33.2501C28.6623 33.2348 28.188 33.1115 27.7605 32.8919C27.4635 32.7389 27.1889 32.5409 26.9477 32.2988C26.7146 32.0657 26.522 31.8037 26.3717 31.5193C26.1485 31.0972 26.0198 30.6283 25.9982 30.1387C25.9955 30.0874 25.9946 30.0361 25.9946 29.9839C25.9946 29.1064 26.333 28.2846 26.9486 27.669L33.3892 21.2284C33.8672 20.7505 34.4657 20.3887 35.1299 20.2636C36.47 20.0125 37.7679 20.5543 38.5158 21.6784C39.333 22.907 39.0441 24.8321 38.001 25.8753L37.5204 26.3559C37.2909 26.5854 37.2927 26.958 37.5276 27.1821C37.7067 27.3531 37.9065 27.552 38.0784 27.7302C38.3034 27.9633 38.6742 27.9651 38.9028 27.7356L39.4716 27.1668C40.522 26.1156 41.0314 24.725 40.9981 23.3453C40.9846 22.8881 40.9135 22.4291 40.7821 21.9844Z"
        fill={colorsDict.iconColor}
      />
      <path
        d="M37.5317 29.0594C37.3526 28.7984 37.1491 28.55 36.9169 28.3178C36.6766 28.0775 36.4183 27.866 36.1465 27.6824C35.7793 27.434 35.3878 27.2378 34.981 27.0929C34.4131 26.8913 33.8172 26.7896 33.2214 26.7896C31.8831 26.7896 30.5447 27.299 29.5259 28.3178L27.6088 30.2349C27.391 30.4527 27.3829 30.7992 27.5854 31.0305C27.7664 31.2375 27.9977 31.4634 28.2083 31.6398C28.4414 31.8342 28.7816 31.8243 28.9958 31.6092L30.9066 29.6984C31.5447 29.0603 32.3826 28.7408 33.2214 28.7408C33.2565 28.7408 33.2925 28.7444 33.3276 28.7462C33.8082 28.7615 34.2862 28.8794 34.7236 29.1053C35.0161 29.2565 35.2915 29.4536 35.5363 29.6984C35.7721 29.9343 35.9638 30.198 36.1123 30.4779C36.3409 30.9099 36.4642 31.3824 36.4858 31.8585C36.5272 32.7477 36.2131 33.6505 35.5363 34.3273L29.0246 40.839C28.3865 41.4771 27.5476 41.7966 26.7097 41.7966C25.8718 41.7966 25.033 41.4771 24.3948 40.839C23.6397 40.0839 23.3013 39.0164 23.4948 37.9175C23.6145 37.2353 23.9853 36.6197 24.4749 36.1291L24.9646 35.6395C25.1941 35.41 25.1923 35.0374 24.9574 34.8133C24.7783 34.6423 24.5784 34.4434 24.4065 34.2652C24.1815 34.0321 23.8107 34.0303 23.5821 34.2598L23.1555 34.6864C22.2474 35.5945 21.6183 36.7745 21.5085 38.0534C21.3734 39.6267 21.9207 41.1261 23.0142 42.2196C24.033 43.2385 25.3714 43.7479 26.7097 43.7479C28.0481 43.7479 29.3864 43.2385 30.4061 42.2196L36.9178 35.7079C38.4002 34.2256 38.8034 32.0673 38.1284 30.216C37.9808 29.8136 37.7819 29.4239 37.5317 29.0594Z"
        fill={colorsDict.iconColor}
      />
    </svg>
  );
};

CustomerLinks.defaultProps = {
  disabled: false,
};

CustomerLinks.propTypes = {
  colors: shape().isRequired,
  disabled: bool,
};

export default CustomerLinks;
