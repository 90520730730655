/* stylelint-disable value-keyword-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Drawer from 'react-drag-drawer';
import { shape, func, string, number } from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';

import Button from '../../../../components/Button';
import InputBox from '../../../../components/InputBox';
import DateInput from '../../../../components/DateInput';
import SelectInput from '../../../../components/SelectInput';
import { setOldInsurance } from '../store';
//import GlobalCheckbox from '../../../../components/GlobalCheckBox';
import { pad, setNoticeKey, notices } from '../../../../store';
import regex from '../../../../services/regexService';
// import { setInsuredData } from '../../../Flow/Gezin/store';

const TerminationPopUp = ({
  toggleDrawer,
  next,
  insuranceCompanies,
  data,
  history,
  isurance_name,
  type,
  setFieldValue,
  dataIsSet,
}) => {
  const { t } = useTranslation();
  const [sendStep, setSendStep] = useState('initial');
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const insuranceTypes = {
    1: 'home',
    2: 'family',
    3: 'car',
    4: 'legal',
  };
  const [selectedItems, setSelectedItems] = useState({
    insurance_company: notices[insuranceTypes[type]]['noticeCompany'],
  });
  const [btnText, setBtnText] = useState(t(`CheckCarManual.btnText.default`));
  let SignupSchema;
  SignupSchema = Yup.object().shape({
    insurance: Yup.string()
      .matches(regex.insuranceName, t(`CheckCar.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    policy_number: Yup.string().required(t(`AdresInput.errors.required`)),
    termination_date: Yup.string()
      .nullable()
      .matches(regex.dayMonth, t(`CheckCar.errors.invalid_date`)),
  });

  useEffect(() => {
    let btnText;
    let errorMsg = null;

    switch (sendStep) {
      case 'pending':
        btnText = t(`CheckCarManual.btnText.pending`);
        setIsDisabled(true);
        break;
      case 'success':
        btnText = t(`CheckCarManual.btnText.success`);
        if (next !== null) {
          history.push(next);
        }
        break;
      case 'error':
        errorMsg = t(`CheckCarManual.errorMsg`);
        btnText = t(`CheckCarManual.btnText.error`);
        break;
      default:
        btnText = t(`CheckCarManual.btnText.default`);
        break;
    }
    setBtnText(btnText);
    setErrorMsg(errorMsg);
  }, [sendStep]);

  const clickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    let noticeValues = {};
    Object.values(selectedItems).forEach(key => {
      const insure = Object.keys(insuranceCompanies).find(
        insurance => insuranceCompanies[insurance] === key,
      );

      if (values['termination_date'] == '') {
        noticeValues = {
          insurance: {
            name: values['insurance'],
            company_tag: insure,
          },
          policy_number: values['policy_number'],
        };
      } else {
        noticeValues = {
          insurance: {
            name: values['insurance'],
            company_tag: insure,
          },
          policy_number: values['policy_number'],
          termination_date: {
            day: parseInt(values['termination_date'].split('/')[0]),
            month: parseInt(values['termination_date'].split('/')[1]),
          },
        };
      }
    });
    const startDate = noticeValues.termination_date;
    startDate.year = new Date().getFullYear();
    // setInsuredData('already_insured_with', noticeValues.insurance.name);
    setNoticeKey(insuranceTypes[type], 'policyNumber', values['policy_number']);
    setNoticeKey(insuranceTypes[type], 'noticeStartDate', startDate);
    if (setFieldValue) setFieldValue(noticeValues.termination_date);
    setNoticeKey(
      insuranceTypes[type],
      'noticeCompany',
      selectedItems['insurance_company'],
    );
    setOldInsurance(type, noticeValues);
    if (next === null) {
      dataIsSet();
      toggleDrawer(false);
    }
    setSendStep('success');
  };

  const disableHandler = (errors, touched, values) => {
    if (
      values.policy_number != '' &&
      values.insurance != '' &&
      selectedItems['insurance_company'] != undefined
    ) {
      if (errors & touched) {
        if (
          Object.entries(errors).length === 0 &&
          Object.entries(touched).length
        ) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  };

  const checkValue = () => {
    let insurance;

    if (isurance_name !== null || isurance_name !== '') {
      insurance = isurance_name;
    } else {
      insurance = '';
    }
    return insurance;
  };

  const handleSelected = (name, value) => {
    let items = {};
    items[name] = value;
    setSelectedItems(items);
  };

  useEffect(() => {
    let items = { ...selectedItems };
    if (!selectedItems['insurance_company']) {
      if (
        data.already_insured_with &&
        data.already_insured_with !== undefined
      ) {
        const insurance = Object.values(insuranceCompanies).find(
          company => data.already_insured_with === company,
        );

        items['insurance_company'] = insurance;
        setSelectedItems(items);
      }
    }
  }, [selectedItems]);

  const closePopUp = () => {
    let noticeValues = {};
    if (next !== null) {
      history.push(next);
    } else {
      toggleDrawer(false);
    }
    setOldInsurance(type, noticeValues);
  };

  return (
    <>
      <Drawer open={true} onRequestClose={() => closePopUp()}>
        <InfoContainer onClick={e => e.stopPropagation()}>
          <InfoHeader>
            <h2>
              <span>{t(`Checkout.termination.title`)}</span>
              <br />
              {t(`Checkout.termination.info`)}
            </h2>
            <CloseButton
              data-test-id="close_popup"
              onClick={() => closePopUp()}
            >
              X
            </CloseButton>
          </InfoHeader>
          <Formik
            initialValues={{
              insurance: checkValue('insurance'),
              policy_number: notices[insuranceTypes[type]].policyNumber
                ? notices[insuranceTypes[type]].policyNumber
                : '',
              termination_date: notices[insuranceTypes[type]].noticeStartDate
                ? `${pad(
                    parseInt(notices[insuranceTypes[type]].noticeStartDate.day),
                  )}/${pad(
                    parseInt(
                      notices[insuranceTypes[type]].noticeStartDate.month,
                    ),
                  )}`
                : '',
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => {
              disableHandler(errors, touched, values);
              return (
                <FormWrapper>
                  <SelectInput
                    name="insurance_company"
                    data={
                      insuranceCompanies
                        ? Object.values(insuranceCompanies)
                        : ['test']
                    }
                    selected={(name, value) => handleSelected(name, value)}
                    initial={selectedItems['insurance_company']}
                  >
                    {t(`Checkout.termination.inputs.0`)}
                  </SelectInput>
                  <InputBox
                    errors={errors}
                    touched={touched}
                    type="text"
                    name="insurance"
                    dataTestId="underwriting_termination_insurance"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  >
                    {t(`Checkout.termination.inputs.1`)}
                  </InputBox>

                  <InputBox
                    errors={errors}
                    touched={touched}
                    type="text"
                    name="policy_number"
                    dataTestId="underwriting_termination_policy_number"
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                  >
                    {t(`Checkout.termination.inputs.2`)}
                  </InputBox>
                  <DateInput
                    errors={errors}
                    touched={touched}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    type="text"
                    disabled={false}
                    dataTestId="underwriting_termination_termination_date"
                    name="termination_date"
                    customDateMask={[/\d/, /\d/, '/', /\d/, /\d/]}
                    newPlaceholder="dd/mm"
                  >
                    {t(`Checkout.termination.inputs.3`)}
                  </DateInput>
                  <ButtonContainer>
                    {errorMsg ? <ErrorMsg>{errorMsg}</ErrorMsg> : null}
                    <Button
                      disabled={isDisabled}
                      dataTestId="underwriting_termination_submit"
                      type="submit"
                    >
                      {btnText}
                    </Button>
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </InfoContainer>
      </Drawer>
    </>
  );
};

const InfoContainer = styled.div`
  color: white;
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 45rem;
  overflow: auto;
  z-index: 9999;
`;

const InfoHeader = styled.div`
  background-color: ${({ theme }) => theme.brand.secondary};
  padding: 2.2rem 2.2rem 2.2rem 5.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    line-height: 1.3;
    width: 90%;

    & span {
      font-weight: bold;
    }
  }
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: white;
  font-weight: 900;

  &:hover {
    color: ${({ theme }) => theme.brand.lighter};
  }
`;

const FormWrapper = styled(Form)`
  width: 100%;
  position: relative;
  padding: 2.5rem 5.2rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;

  & button {
    margin: 0;
    margin-top: 2rem;
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-weight: 500;
  margin-right: 2rem;
  font-size: 1.6rem;
`;

TerminationPopUp.defaultProps = {
  insuranceCompanies: {},
  data: {},
};

TerminationPopUp.propTypes = {
  toggleDrawer: func,
  dataIsSet: func,
  next: string,
  data: shape(),
  insuranceCompanies: shape(),
  history: shape().isRequired,
  isurance_name: string,
  type: number,
  setFieldValue: func,
};

export default withRouter(TerminationPopUp);
