export const capitalize = s => {
  if (!s) return '';
  const l = s.toLowerCase();
  return l && l[0].toUpperCase() + l.slice(1);
};

export const stringLength = string => {
  if (typeof string === 'string') {
    return string.length;
  }

  return 0;
};
