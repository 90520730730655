import React, { useState, createContext, useEffect } from 'react';
import { node } from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as ExternalThemeProvider } from 'wg-fe-ui';
import { getThemeObject } from '../themingService';
import { setCurrentTheme } from '../../store';

export const ThemeContext = createContext({
  theme: {},
  setTheme: () => {},
});

export const ThemeContextProvider = ({ children }) => {
  const [theme, setTheme] = useState('');

  const [themeObject, setThemeObject] = useState(getThemeObject(theme));

  useEffect(() => {
    if (getThemeObject(theme)) {
      setCurrentTheme(getThemeObject(theme));
      setThemeObject(getThemeObject(theme));
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ themeObject, setTheme }}>
      <ExternalThemeProvider theme={themeObject}>
        <ThemeProvider theme={themeObject}>{children}</ThemeProvider>
      </ExternalThemeProvider>
    </ThemeContext.Provider>
  );
};

ThemeContextProvider.propTypes = {
  children: node.isRequired,
};
