/* stylelint-disable value-keyword-case, declaration-no-important */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import i18next from 'i18next';
import regex from '../../../services/regexService';
import LoadingButton from '../../../components/LoadingButton';
import TerminationGeneratorInputField from '../../../components/TerminationGeneratorInputField';
import TerminationGeneratorTextArea from '../../../components/TerminationGeneratorTextArea';
import Button from '../../../components/Button';
import DateInputComponent from '../../../components/DateInputComponent';
import { getBrokerData } from '../../../services/brokerDataService';
import PhoneInput from '../../../components/PhoneInput';
import { CheckBox_v2 as CheckBox } from 'wg-fe-ui';
import { getBrokerDistribution } from '../../../services/brokerDataService';
import { getCustomerSheetLink } from '../../../services/docgenService';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const ToolkitCustomerSheetGenerator = () => {
  // ===================================== \\
  //        TODO: Refactor this view       \\
  // ===================================== \\

  const { t } = useTranslation();
  // const content = t('ToolkitInfo', { returnObjects: true });
  const [sendStep, setSendStep] = useState('initial');
  const [broker, setBroker] = useState({});
  const [distribution, setDistribution] = useState({});
  const [documentPayload, setDocumentPayload] = useState();
  const [checkedItems, setCheckedItems] = useState({
    no_commercial_info: false,
    process_medical_records: false,
    access_all_other_insurances: false,
    access_other_insurances: false,
  });

  const dateFormat = `DD-MM-YYYY`;
  const dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  useEffect(() => {
    initializeBrokerData();
  }, []);

  async function initializeBrokerData() {
    const brokerData = await getBrokerData();
    const distributionData = await getBrokerDistribution();
    setDistribution(distributionData);
    setBroker(brokerData);
  }

  useEffect(() => {
    if (sendStep !== 'pending' || documentPayload === undefined) {
      // Don't allow to submit when not clicked
      return;
    }
    downloadDocument(documentPayload);
  }, [documentPayload]);

  async function downloadDocument(payload) {
    const [downloadLink, fileName] = await getCustomerSheetLink(payload);
    if (downloadLink !== null && fileName !== null) {
      console.log(downloadLink);
      console.log(fileName);
      const downloadButton = document.createElement('a');
      downloadButton.href = downloadLink;
      downloadButton.download = fileName;
      setSendStep('success');
      downloadButton.click();
    } else {
      setSendStep('error');
    }
  }

  const onClickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    distribution.logo = distribution.links[0].href;
    const {
      street_customer_sheet,
      zipcode_customer_sheet,
      city_customer_sheet,
      housenr_customer_sheet,
      first_name_customer_sheet,
      national_register_nr_customersheet,
      birthday_customer_sheet,
      last_name_customer_sheet,
      telephonenr_customer_sheet,
      email_customer_sheet,
      know_our_office_customer_sheet,
      access_what_insurances_customer_sheet,
    } = values;
    const billing_address = {
      street: street_customer_sheet,
      zipcode: zipcode_customer_sheet,
      city: city_customer_sheet,
      housenr: housenr_customer_sheet.toLowerCase(),
      country_code: 'BE',
    };
    const payload = {
      broker: broker.broker,
      theme: broker.theme,
      distribution,
      client: {
        first_name: first_name_customer_sheet,
        last_name: last_name_customer_sheet,
        birth: {
          day: birthday_customer_sheet.split('-')[0],
          month: birthday_customer_sheet.split('-')[1],
          year: birthday_customer_sheet.split('-')[2],
        },
        telephonenr: telephonenr_customer_sheet,
        email: email_customer_sheet,
        billing_address,
        national_register_nr: national_register_nr_customersheet,
      },
      know_our_office: know_our_office_customer_sheet,
      acceptations: {
        no_commercial_info: checkedItems['no_commercial_info'],
        process_medical_records: checkedItems['process_medical_records'],
        access_all_other_insurances:
          checkedItems['access_all_other_insurances'],
        access_other_insurances: checkedItems['access_other_insurances'],
      },
      access_what_insurances: access_what_insurances_customer_sheet,
      language: i18next.language.toUpperCase(),
    };

    setSendStep('pending');
    setDocumentPayload(payload);
  };

  const SignupSchema = Yup.object().shape({
    first_name_customer_sheet: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    last_name_customer_sheet: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    national_register_nr_customersheet: Yup.number()
      .min(10000000000, t(`Too short`))
      .max(99999999999, t(`Too long`))
      .required(t(`AdresInput.errors.required`)),
    birthday_customer_sheet: Yup.string()
      .required(t(`AdresInput.errors.invalid`))
      .matches(dateRegex, t(`AdresInput.errors.invalid`))
      .test('DOB', t(`AdresInput.errors.invalid`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`AdresInput.errors.invalid`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`Too young`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 18,
      )
      .test(
        'DOB',
        t(`Too old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 120,
      ),
    email_customer_sheet: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .min(1, t(`AdresInput.errors.short`))
      .max(200, t(`AdresInput.errors.long`))
      .matches(regex.emailRegex, t(`Invalid email`)),
    // .test('useremail', t(`Invalid email`), () => validEmail),
    telephonenr_customer_sheet: Yup.string()
      .test('Phone number', t(`AdresInput.errors.invalid`), value => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch {
              return false;
            }
            return false;
          }
          return false;
        }
        return false;
      })
      .required(t(`AdresInput.errors.invalid`)),
    street_customer_sheet: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(150, t(`AdresInput.errors.long`))
      .matches(regex.street, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    housenr_customer_sheet: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .matches(regex.houseNr, t(`AdresInput.errors.invalid`)),
    zipcode_customer_sheet: Yup.number()
      .min(1000, t(`AdresInput.errors.short`))
      .max(9999, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    city_customer_sheet: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(100, t(`AdresInput.errors.long`))
      .matches(regex.city, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    access_what_insurances_customer_sheet: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
  });

  const disableHandler = (errors, touched) => {
    if (
      checkedItems['access_other_insurances'] &&
      !touched.access_what_insurances_customer_sheet
    ) {
      return true;
    }
    if (
      !checkedItems['access_other_insurances'] &&
      errors &&
      errors['access_what_insurances_customer_sheet']
    ) {
      delete touched.access_what_insurances_customer_sheet;
      delete errors.access_what_insurances_customer_sheet;
    }

    if (Object.entries(errors).length === 0) {
      if (!(Object.entries(touched).length === 0)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    switch (sendStep) {
      case 'initial':
        break;
      case 'pending':
        break;
      case 'success':
        break;
      case 'error':
        break;
      default:
        break;
    }
  }, [sendStep]);

  const changeSelected = name => {
    setCheckedItems({ ...checkedItems, [name.name]: !checkedItems[name.name] });
  };

  return (
    <>
      <TitleContainer>
        <Title>{t('ToolkitNavigation.customerSheetGenerator.title')}</Title>
      </TitleContainer>
      <SectionContainer data-test-id="dashboard_toolkit_documents_customer_sheet_generator_section_generate">
        <Section>
          <Formik
            initialValues={{
              first_name_customer_sheet: ``,
              last_name_customer_sheet: ``,
              birthday_customer_sheet: ``,
              telephonenr_customer_sheet: ``,
              email_customer_sheet: ``,
              street_customer_sheet: ``,
              zipcode_customer_sheet: ``,
              housenr_customer_sheet: ``,
              city_customer_sheet: ``,
              national_register_nr_customer_sheet: ``,
              know_our_office_customer_sheet: ``,
              access_what_insurances_customer_sheet: ``,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              onClickHandler(values);
            }}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              setFieldTouched,
              handleBlur,
            }) => {
              const disabled = disableHandler(errors, touched);
              return (
                <FormWrapper>
                  <InputWrapper>
                    <InputField
                      dataTestId="toolkit_documents_customer_sheet__generator_first_name"
                      error={
                        errors['first_name_customer_sheet'] &&
                        touched['first_name_customer_sheet']
                      }
                      valid={
                        !errors['first_name_customer_sheet'] &&
                        touched['first_name_customer_sheet']
                      }
                      type="text"
                      name="first_name_customer_sheet"
                    >
                      {t(`First name`)}
                    </InputField>
                    <InputField
                      error={
                        errors['last_name_customer_sheet'] &&
                        touched['last_name_customer_sheet']
                      }
                      valid={
                        !errors['last_name_customer_sheet'] &&
                        touched['last_name_customer_sheet']
                      }
                      type="text"
                      name="last_name_customer_sheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_last_name"
                    >
                      {t(`Last name`)}
                    </InputField>
                    <InputField
                      error={
                        errors['street_customer_sheet'] &&
                        touched['street_customer_sheet']
                      }
                      valid={
                        !errors['street_customer_sheet'] &&
                        touched['street_customer_sheet']
                      }
                      type="text"
                      name="street_customer_sheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_street"
                    >
                      {t(`Street`)}
                    </InputField>
                    <InputField
                      error={
                        errors['housenr_customer_sheet'] &&
                        touched['housenr_customer_sheet']
                      }
                      valid={
                        !errors['housenr_customer_sheet'] &&
                        touched['housenr_customer_sheet']
                      }
                      type="text"
                      name="housenr_customer_sheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_housenr"
                    >
                      {t(`Nr/Bus`)}
                    </InputField>
                    <InputField
                      error={
                        errors['zipcode_customer_sheet'] &&
                        touched['zipcode_customer_sheet']
                      }
                      valid={
                        !errors['zipcode_customer_sheet'] &&
                        touched['zipcode_customer_sheet']
                      }
                      type="number"
                      name="zipcode_customer_sheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_zipcode"
                    >
                      {t(`Zipcode`)}
                    </InputField>
                    <InputField
                      error={
                        errors['city_customer_sheet'] &&
                        touched['city_customer_sheet']
                      }
                      valid={
                        !errors['city_customer_sheet'] &&
                        touched['city_customer_sheet']
                      }
                      type="text"
                      name="city_customer_sheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_city"
                    >
                      {t(`City`)}
                    </InputField>
                    <InputField
                      error={
                        errors['email_customer_sheet'] &&
                        touched['email_customer_sheet']
                      }
                      valid={
                        !errors['email_customer_sheet'] &&
                        touched['email_customer_sheet']
                      }
                      type="email"
                      name="email_customer_sheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_email"
                    >
                      {t(`Email`)}
                    </InputField>
                    <StyledPhoneInput
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      values={values}
                      name="telephonenr_customer_sheet"
                      handleBlur={handleBlur}
                      dataTestId="toolkit_documents_customer_sheet__generator_telephonenr"
                    >
                      {t(`Telephone number`)}
                    </StyledPhoneInput>
                    <StyledLabel>
                      <p>{t(`Birthdate`)}</p>
                      <StyledDateInputComponent
                        classPrefix="birthday_customer_sheet"
                        dataTestId="toolkit_documents_customer_sheet__generator_birthdate"
                        errors={errors}
                        touched={touched}
                        value={values['birthday_customer_sheet']}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        type="text"
                        disabled={false}
                        name="birthday_customer_sheet"
                        id="birthday_customer_sheet"
                      />
                    </StyledLabel>
                    <InputField
                      id="national_register_nr_customer_sheet"
                      error={
                        errors['national_register_nr_customersheet'] &&
                        touched['national_register_nr_customersheet']
                      }
                      valid={
                        !errors['national_register_nr_customersheet'] &&
                        touched['national_register_nr_customersheet']
                      }
                      type="number"
                      name="national_register_nr_customersheet"
                      dataTestId="toolkit_documents_customer_sheet__generator_national_register_nr"
                    >
                      {t(`Social security number (only numbers)`)}
                    </InputField>
                    <Line />
                    <KnowOfficeInput
                      type="text"
                      name="know_our_office_customer_sheet"
                    >
                      {t(`How do you know our office`)}
                    </KnowOfficeInput>
                    <CheckBoxItem
                      dataTestId="toolkit_documents_customer_sheet__generator_no_commercial_info"
                      checked={checkedItems['no_commercial_info']}
                      disabled={false}
                      name="no_commercial_info"
                      onChange={name => changeSelected(name)}
                    >
                      {t(
                        'You do not wish to receive commercial information from our office',
                      )}
                    </CheckBoxItem>
                    <CheckBoxItem
                      dataTestId="toolkit_documents_customer_sheet__generator_process_medical_records"
                      checked={checkedItems['process_medical_records']}
                      disabled={false}
                      name="process_medical_records"
                      onChange={name => changeSelected(name)}
                    >
                      {t(
                        'You allow the processing of medical data within the framework of insurance files',
                      )}
                    </CheckBoxItem>
                    <CheckBoxItem
                      dataTestId="toolkit_documents_customer_sheet__generator_access_all_other_insurances"
                      checked={checkedItems['access_all_other_insurances']}
                      disabled={
                        checkedItems['access_other_insurances'] ? true : false
                      }
                      name="access_all_other_insurances"
                      onChange={name => changeSelected(name)}
                    >
                      {t(
                        'You give permission to consult all insurance files (including claims management)',
                      )}
                    </CheckBoxItem>
                    <CheckBoxItem
                      dataTestId="toolkit_documents_customer_sheet__generator_access_other_insurances"
                      checked={checkedItems['access_other_insurances']}
                      disabled={
                        checkedItems['access_all_other_insurances']
                          ? true
                          : false
                      }
                      name="access_other_insurances"
                      onChange={name => changeSelected(name)}
                    >
                      {t(
                        'You give permission to consult the following insurance files (including claims management)',
                      )}
                    </CheckBoxItem>
                    {checkedItems['access_other_insurances'] ? (
                      <WhatInsurances
                        error={
                          checkedItems['access_other_insurances'] &&
                          errors['access_what_insurances_customer_sheet'] &&
                          touched['access_what_insurances_customer_sheet']
                        }
                        valid={
                          checkedItems['access_other_insurances'] &&
                          !errors['access_what_insurances_customer_sheet'] &&
                          touched['access_what_insurances_customer_sheet']
                        }
                        name="access_what_insurances_customer_sheet"
                        dataTestId="toolkit_documents_customer_sheet_access_what_insurances"
                        disabled={
                          checkedItems['access_all_other_insurances']
                            ? true
                            : false
                        }
                        placeholder={t(`Example Familis from AG Insurance`)}
                      />
                    ) : null}
                  </InputWrapper>
                  <ButtonContainer>
                    {sendStep !== 'pending' ? (
                      <Button
                        dataTestId="toolkit_termination_generator_submit"
                        type="submit"
                        disabled={disabled}
                      >
                        {t(`Generate`)}
                      </Button>
                    ) : (
                      <LoadingButton>{t(`Generating`)}</LoadingButton>
                    )}
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </Section>
      </SectionContainer>
    </>
  );
};

const SectionContainer = styled.div`
  padding-bottom: 8.5rem;
  position: relative;
`;

const StyledDateInputComponent = styled(DateInputComponent)`
  height: 3.5rem;
  max-width: 40rem;
  & label {
    margin-top: 0;
  }

  & input {
    height: 3.5rem;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  width: 47%;
  line-height: 2.1rem;
  font-size: 1.5rem;
  color: #a29c95;
  margin-bottom: 2.5rem;
  font-weight: normal;
  max-height: 6.1rem !important;

  & > p {
    display: flex;
    align-items: baseline;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    margin-bottom: 0.5rem;
  }
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  flex: 0 0 100%;
  max-width: 90rem;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  justify-content: space-between;

  & > input {
    margin-top: 0;
  }
`;

const KnowOfficeInput = styled(TerminationGeneratorInputField)`
  max-width: 50rem !important;
  width: 80%;
`;

const InputField = styled(TerminationGeneratorInputField)`
  max-width: 40rem;
`;

const CheckBoxItem = styled(CheckBox)`
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;

  & div {
    min-height: 1.9rem;
    min-width: 1.9rem;
  }
`;

const ButtonContainer = styled.div`
  border-radius: 3rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 3rem;
  right: 0;
  padding: 0;
  flex-direction: column;

  & button {
    z-index: 1;
  }

  & > button,
  & > div {
    margin: 0;

    @media screen and (max-width: 1025px) {
      transform: scale(1);
    }
  }
`;

const WhatInsurances = styled(TerminationGeneratorTextArea)`
  margin-left: 2.9rem;

  & > div {
    & > textarea {
      min-height: 5rem;
    }
  }
`;

const FormWrapper = styled(Form)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const Line = styled.span`
  width: 100%;
  height: 0.1rem;
  background-color: #ddd;
  margin-bottom: 2.4rem;
  margin-top: 1rem;
`;

const TitleContainer = styled.div`
  text-align: left;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

const StyledPhoneInput = styled(PhoneInput)`
  max-height: 6.1rem;
  position: relative;
  color: #a29c95;
  max-width: 40rem;
  font-weight: normal;
  width: 47%;

  & .react-tel-input input[type='text'],
  & .react-tel-input input[type='tel'] {
    box-shadow: none;
    border: 1px solid #ccc;
  }

  & .react-tel-input .flag-dropdown {
    border: 1px solid #ccc;
    box-shadow: none;
  }
`;

const Section = styled.div`
  /* Outline */
  border: 1px solid #ccc;
  box-sizing: border-box;
  /* Button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4.69px;
  border-radius: 5px;
  margin: 2.35rem 0;
  padding: 3.5rem;
  padding-bottom: 1.5rem;
  background-color: white;
`;

export default ToolkitCustomerSheetGenerator;
