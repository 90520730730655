import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StripedTable } from 'wg-fe-ui';

import { shape, func, bool, string, array } from 'prop-types';
import TableRow from './ProFlowOffersAccordionBoxTableRow.jsx';
import { useEffect } from 'react';

const ProFlowOffersAccordionBoxTable = ({
  premium,
  quote,
  currentQuote,
  onChange,
  canEdit,
  insuranceKey,
  insuranceTypeKey,
  isChecked,
  optionsState,
  className,
  insuranceCompany,
  sendModificationsUp,
  discounts,
}) => {
  const [baseType, setBaseType] = useState('base');
  const [mods, setMods] = useState();
  const { t } = useTranslation();

  const getVkFromDiscount = (name, prop) => {
    if (discounts?.length > 0) {
      const vkObject = discounts.find(discount => discount.name === name);
      if (vkObject) {
        return vkObject[prop].toFixed(2);
      } else {
        return 0.0;
      }
    } else {
      return 0.0;
    }
  };

  function handleModifications(modifications) {
    setMods(modifications);
  }

  useEffect(() => {
    sendModificationsUp(mods);
  }, [mods]);

  useEffect(() => {
    switch (insuranceTypeKey) {
      case 'car':
        setBaseType('vehicle');
        break;
      case 'family':
        setBaseType('family');
        break;
      case 'legal':
        setBaseType('legal');
        break;
      case 'home':
        setBaseType('home');
        break;
      default:
        setBaseType('base');
        break;
    }
  }, [insuranceTypeKey]);

  return (
    <TableContainer>
      <StripedTable className={className}>
        <StripedTable.Head>
          <StripedTable.HeaderCell width="5%" />
          <StripedTable.HeaderCell width="5%" />
          <StripedTable.HeaderCell width="27%">
            {t(`Guarantee`)}
          </StripedTable.HeaderCell>
          <StripedTable.HeaderCell>{t(`Net premium`)}</StripedTable.HeaderCell>
          <StripedTable.HeaderCell>{t(`Taxes`)}</StripedTable.HeaderCell>
          <StripedTable.HeaderCell>
            {t(`Total premium`)}
          </StripedTable.HeaderCell>
          <StripedTable.HeaderCell>{t(`Commission`)}</StripedTable.HeaderCell>
          {quote?.details?.max_vk > 0 && (
            <StripedTable.HeaderCell>VK</StripedTable.HeaderCell>
          )}
        </StripedTable.Head>
        <StripedTable.Body>
          <TableRow
            type={baseType === 'home' ? currentQuote?.base?.key : baseType}
            name={quote?.base?.name}
            net_premium={quote?.base?.net_premium}
            taxes={quote?.base?.taxes}
            total_premium={quote?.base?.total_premium}
            commission={quote?.base?.commission}
            vk={getVkFromDiscount(quote?.base?.key, 'applied')}
            max_vk={getVkFromDiscount(quote?.base?.key, 'max')}
            quote_max_vk={quote?.details?.max_vk}
          />
          {quote?.options?.map(option => (
            <TableRow
              sendModifications={handleModifications}
              insuranceType={insuranceTypeKey}
              insuranceCompanyName={insuranceCompany.key}
              key={canEdit ? `${option.key}_edit` : option.key}
              type={option.key}
              name={option.name}
              net_premium={option.net_premium}
              taxes={option.taxes}
              total_premium={option.total_premium}
              commission={option.commission}
              canEdit={canEdit}
              onChange={onChange}
              isChecked={
                !canEdit ||
                optionsState?.[option.key] ||
                insuranceKey === option.key
              }
              disabled={!(canEdit && insuranceKey !== option.key)}
              vk={getVkFromDiscount(option.name, 'applied')}
              max_vk={getVkFromDiscount(option.name, 'max')}
              quote_max_vk={quote?.details?.max_vk}
            />
          ))}
        </StripedTable.Body>
        <StripedTable.Footer>
          <StripedTable.FooterCell width="unset" />
          <StripedTable.FooterCell width="unset" />
          <StripedTable.FooterCell width="unset">
            {t(`Checkout.total`)}
          </StripedTable.FooterCell>
          <StripedTable.FooterCell width="unset">
            € {premium?.net_premium?.toFixed(2)}
          </StripedTable.FooterCell>
          <StripedTable.FooterCell width="unset">
            € {premium?.taxes_in_euro?.toFixed(2)}
          </StripedTable.FooterCell>
          <StripedTable.FooterCell width="unset">
            € {premium?.total_premium?.toFixed(2)}
          </StripedTable.FooterCell>
          <StripedTable.FooterCell width="unset" />
          <StripedTable.FooterCell width="unset" />
        </StripedTable.Footer>
      </StripedTable>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  @media screen and (max-width: 1360px) {
    th {
      padding-bottom: 1rem;
      padding-right: 0.75rem;
      font-weight: 600;
      font-size: 1.2rem;
    }
  }
`;

ProFlowOffersAccordionBoxTable.defaultProps = {
  onChange: () => {},
  sendModificationsUp: () => {},
  canEdit: false,
  vk: false,
};

ProFlowOffersAccordionBoxTable.propTypes = {
  premium: shape(),
  quote: shape(),
  currentQuote: shape(),
  onChange: func,
  canEdit: bool,
  isChecked: bool,
  optionsState: shape(),
  insuranceKey: string,
  className: string,
  discounts: array,
  insuranceTypeKey: string,
  insuranceCompany: string,
  sendModificationsUp: func,
};

export default ProFlowOffersAccordionBoxTable;
