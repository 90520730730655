import React, { Component } from 'react';
import { number, object, bool, node } from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom';
import checkedImage from '../../../../../../assets/images/checkmark.svg';
import cross from '../../../../../../assets/images/skipped-cross.svg';
import routes from '../../../../../store/content.json';
import {
  getCurrentStep,
  getCartSize,
  getCheckout,
  setSkippedSteps,
} from '../../store/index';

class Step extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = { clickable: false };
    this.props = props;
  }

  checkStepPassed = () => {
    const { content, skipped } = this.props;

    this.circle = <Circle />;

    if (getCurrentStep() > content.id && skipped === false) {
      this.checkmark = <CheckedIcon src={checkedImage} alt="checked" />;
      this.circle = <Circle checked />;
    }

    if (getCurrentStep() === content.id && skipped === false) {
      this.checkmark = '';
      this.circle = <Circle checked={false} active />;
    }
    if (skipped) {
      this.checkmark = <Cross src={cross} alt="checked" />; // Will be a cross later
      this.circle = <Circle checked />;
    }

    if (content.id === 1) {
      if (getCheckout('fire') && skipped === false) {
        this.checkmark = <CheckedIcon src={checkedImage} alt="checked" />;
        this.circle = <Circle checked />;
      }
    }

    if (content.id === 2) {
      if (getCheckout('people') && skipped === false) {
        this.checkmark = <CheckedIcon src={checkedImage} alt="checked" />;
        this.circle = <Circle checked />;
      }
    }
    if (content.id === 3) {
      if (getCheckout('car') && skipped === false) {
        this.checkmark = <CheckedIcon src={checkedImage} alt="checked" />;
        this.circle = <Circle checked />;
      }
    }
    if (content.id === 4) {
      if (getCheckout('legal') && skipped === false) {
        this.checkmark = <CheckedIcon src={checkedImage} alt="checked" />;
        this.circle = <Circle checked />;
      }
    }
    if (content.id === 5) {
      this.checkmark = '';
      if (getCurrentStep() === 5) {
        this.circle = <Circle checked={false} active />;
      } else {
        this.circle = <Circle checked={false} />;
      }
    }
  };

  checkMiniProgress = () => {
    const { content, currentStep } = this.props;
    if (currentStep > content.id) {
      this.progressPercent = '100%';
    }
    if (currentStep < content.id) {
      this.progressPercent = '0%';
    }
  };

  checkClickable = () => {
    const { skipped } = this.props;
    const { clickable } = this.state;
    if (skipped !== clickable) {
      this.setState({ clickable: skipped });
    }
  };

  handleLinked = () => {
    const { last, content } = this.props;
    const cards = [
      getCheckout('fire'),
      getCheckout('people'),
      getCheckout('car'),
      getCheckout('legal'),
    ];
    if (!last) {
      switch (getCurrentStep()) {
        case 1:
          for (let i = 1; i < content.id; i++) {
            setSkippedSteps(i, !cards[i - 1]);
          }
          break;
        case 2:
          for (let i = 1; i < content.id; i++) {
            setSkippedSteps(i, !cards[i - 1]);
          }
          break;
        default:
          break;
      }
    } else {
      setSkippedSteps(1, !cards[0]);
      setSkippedSteps(2, !cards[1]);
      setSkippedSteps(3, !cards[2]);
      setSkippedSteps(4, !cards[3]);
    }
  };

  render() {
    this.checkClickable();
    this.checkStepPassed();
    this.checkMiniProgress();
    const { icon, content, last } = this.props;

    let changeIdIfReview = 0;
    if (window.location.href.includes('review')) changeIdIfReview = 5; // 5 = amount of steps

    const { route } = routes.marketplace[content.id - 1 + changeIdIfReview];

    return (
      <ProgressStep>
        <Link
          data-test-id={`underwriting_${content.title}_nav`}
          to={route}
          onClick={() => this.handleLinked()}
        >
          {last ? <CartSize>{getCartSize()}</CartSize> : ''}
          {this.checkmark}
          <StepIcon alt={content.title}>{icon}</StepIcon>
          {this.circle}
        </Link>
        <MiniProgressBar>
          <Progress percent={this.progressPercent} />
        </MiniProgressBar>
      </ProgressStep>
    );
  }
}

const ProgressStep = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:last-of-type {
    width: 5rem;

    & > div:last-child {
      display: none;
    }
  }
`;
const Circle = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  color: white;
  border: solid 5px ${({ theme }) => theme.brand.secondary};
  background-color: ${({ theme, checked, active }) =>
    checked || active ? theme.brand.secondary : theme.brand.primary};
  border-radius: 4rem;
  font-weight: bold;
  font-size: 2.5rem;
  transition: 0.3s;
`;

const StepIcon = styled.div`
  width: 5.6rem;
  height: 5.6rem;
  position: absolute;
  margin-left: -1rem;
  color: white;
  font-weight: bold;
  font-size: 2.5rem;
  cursor: pointer;
`;
const CheckedIcon = styled.img`
  width: 5.6rem;
  height: 5.6rem;
  position: absolute;
  z-index: 10;
  margin-left: 0.5rem;
  margin-top: -0.5rem;
`;
const Cross = styled.img`
  width: 4rem;
  height: 4rem;
  position: absolute;
  z-index: 10;
  margin-left: 1.5rem;
  margin-top: 1.5rem;
`;
const MiniProgressBar = styled.div`
  background-color: ${({ theme }) => theme.brand.secondary};
  border-radius: 1rem;
  width: 50%;
  height: 7px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
`;
const Progress = styled.div`
  background-color: white;
  border-radius: 1rem;
  width: ${props => props.percent};
  transition: 0.3s;
  height: 7px;
`;

const CartSize = styled.p`
  background-color: white;
  color: black;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  text-decoration: none;
  position: absolute;
  z-index: 11;
  margin-left: 3.5rem;
  margin-top: -1rem;
  font-weight: bold;
`;

Step.defaultProps = {
  last: false,
  skipped: false,
};

Step.propTypes = {
  currentStep: number.isRequired,
  content: object.isRequired,
  icon: node.isRequired,
  skipped: bool,
  last: bool,
};

export default Step;
