import React from 'react';
import { shape } from 'prop-types';

const ApartmentBuildingIcon = () => (
  <svg
    version="1.1"
    width="33"
    height="44"
    viewBox="0 0 33 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.28081 4.64305V37.1007C5.28081 37.4071 5.52866 37.6549 5.83499 37.6549H13.1354C13.2881 37.6549 13.4129 37.531 13.4129 37.3774V29.3753C13.4129 29.2226 13.5368 29.0978 13.6904 29.0978H18.9565C19.1092 29.0978 19.234 29.2217 19.234 29.3753V37.3774C19.234 37.5301 19.3579 37.6549 19.5115 37.6549H26.8111C27.1174 37.6549 27.3652 37.4071 27.3652 37.1007V4.64305C27.3652 4.33672 27.1174 4.08887 26.8111 4.08887H5.83586C5.52866 4.08887 5.28081 4.33672 5.28081 4.64305ZM20.879 14.2708H23.7215C23.8664 14.2708 23.9833 14.1538 23.9833 14.009V9.82074C23.9833 9.67587 23.8664 9.55892 23.7215 9.55892H20.879C20.7341 9.55892 20.6172 9.67587 20.6172 9.82074V14.009C20.6172 14.1538 20.7341 14.2708 20.879 14.2708ZM17.744 14.2708H14.9015C14.7567 14.2708 14.6397 14.1538 14.6397 14.009V9.82074C14.6397 9.67587 14.7567 9.55892 14.9015 9.55892H17.744C17.8889 9.55892 18.0058 9.67587 18.0058 9.82074V14.009C18.0058 14.1538 17.8889 14.2708 17.744 14.2708ZM8.92408 14.2708H11.7666C11.9114 14.2708 12.0284 14.1538 12.0284 14.009V9.82074C12.0284 9.67587 11.9114 9.55892 11.7666 9.55892H8.92408C8.7792 9.55892 8.66226 9.67587 8.66226 9.82074V14.009C8.66226 14.1538 8.78008 14.2708 8.92408 14.2708ZM23.7215 23.6936H20.879C20.7341 23.6936 20.6172 23.5767 20.6172 23.4318V19.2436C20.6172 19.0987 20.7341 18.9818 20.879 18.9818H23.7215C23.8664 18.9818 23.9833 19.0987 23.9833 19.2436V23.4318C23.9833 23.5767 23.8664 23.6936 23.7215 23.6936ZM14.9015 23.6936H17.744C17.8889 23.6936 18.0058 23.5767 18.0058 23.4318V19.2436C18.0058 19.0987 17.8889 18.9818 17.744 18.9818H14.9015C14.7567 18.9818 14.6397 19.0987 14.6397 19.2436V23.4318C14.6397 23.5767 14.7567 23.6936 14.9015 23.6936ZM11.7666 23.6936H8.92408C8.78008 23.6936 8.66226 23.5767 8.66226 23.4318V19.2436C8.66226 19.0987 8.7792 18.9818 8.92408 18.9818H11.7666C11.9114 18.9818 12.0284 19.0987 12.0284 19.2436V23.4318C12.0284 23.5767 11.9114 23.6936 11.7666 23.6936Z"
      fill={'#fff'}
    />
  </svg>
);

ApartmentBuildingIcon.propTypes = {
  colors: shape().isRequired,
};

export default ApartmentBuildingIcon;
