import React from 'react';
import { array, func } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

import DashboardBrokerHistorySection from './DashboardBrokerHistorySection';

const DashboardBrokerHistorySections = ({
  brokerHistoryData,
  removeSession,
}) => {
  const getAllDates = brokerHistoryData.map(a => a.createdDateOn);

  const uniqueDates = getAllDates.filter((v, i) => {
    return getAllDates.indexOf(v) === i;
  });

  const sortUniqueDates = uniqueDates.sort(
    (a, b) => new moment(b, 'YYYYMMDD') - new moment(a, 'YYYYMMDD'),
  );

  return (
    <Container>
      {sortUniqueDates.map((date, key) => {
        return (
          <DashboardBrokerHistorySection
            sortId={key}
            key={date}
            date={date}
            brokerHistoryData={brokerHistoryData}
            removeSession={removeSession}
          />
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

DashboardBrokerHistorySections.defaultProps = {
  brokerHistoryData: [],
};
DashboardBrokerHistorySections.propTypes = {
  brokerHistoryData: array,
  removeSession: func.isRequired,
};

export default DashboardBrokerHistorySections;
