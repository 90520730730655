import React, { useState, useEffect, useRef } from 'react';
import { CheckBox_v2 as CheckBox, StripedTable, SubLabel } from 'wg-fe-ui';
import QuoteGuaranteeIcons from './QuoteGuaranteeIcons.jsx';
import { string, number, bool, func, any } from 'prop-types';
import styled from 'styled-components';
import QuickActionSubMenu from './QuickActionSubmenu';
import { useTranslation } from 'react-i18next';

const ProFlowOffersAccordionBoxTableRow = ({
  type,
  name,
  net_premium,
  taxes,
  total_premium,
  commission,
  vk,
  max_vk,
  canEdit,
  isChecked,
  onChange,
  disabled,
  insuranceType,
  insuranceCompanyName,
  sendModifications,
  quote_max_vk,
}) => {
  const { t } = useTranslation();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(isChecked);
  const [
    overwriteDriverInsuranceType,
    setOverwriteDriverInsuranceType,
  ] = useState();

  const initialRender = useRef(true);

  useEffect(() => {
    setIsCheckboxChecked(isChecked);
  }, [isChecked]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      onChange({ key: type, value: isCheckboxChecked });
    }
  }, [isCheckboxChecked]);

  useEffect(() => {
    if (overwriteDriverInsuranceType) {
      const carInsurancemodifications = [
        {
          name: 'driver',
          formula:
            overwriteDriverInsuranceType === 'Driver Safe' ? 'SAFE' : 'Select',
        },
      ];

      sendModifications(carInsurancemodifications);
    }
  }, [overwriteDriverInsuranceType]);

  return (
    <StripedTable.Row>
      <StripedTable.BodyCell>
        <CheckBox
          name={`${type}Edit`}
          disabled={disabled}
          onClick={e => {
            e.preventDefault();
            if (disabled) return;
            setIsCheckboxChecked(!isCheckboxChecked);
          }}
          onChange={() => {}}
          checked={isCheckboxChecked}
        />
      </StripedTable.BodyCell>
      <StripedTable.BodyCell>
        <IconContainer>
          <QuoteGuaranteeIcons type={type} color="#8990A3" />
        </IconContainer>
      </StripedTable.BodyCell>
      {canEdit &&
      insuranceType === 'car' &&
      insuranceCompanyName === 'baloise' &&
      (name === 'Driver Select' || name === 'Bestuurder Select') ? (
        <StripedTable.BodyCell>
          <StyledQuickActionSubMenu
            name={
              overwriteDriverInsuranceType != null
                ? t(overwriteDriverInsuranceType)
                : name
            }
          >
            <StyledSubMenu>
              {['Driver Safe', 'Driver Select'].map(item => (
                <QuickActionSubMenu.SubMenuItem
                  key={item}
                  label={<SubLabel>{t(item)}</SubLabel>}
                  onClick={() => setOverwriteDriverInsuranceType(item)}
                  type=""
                />
              ))}
            </StyledSubMenu>
          </StyledQuickActionSubMenu>
        </StripedTable.BodyCell>
      ) : (
        <StripedTable.BodyCell>{name}</StripedTable.BodyCell>
      )}
      <StripedTable.BodyCell>€ {net_premium?.toFixed(2)}</StripedTable.BodyCell>
      <StripedTable.BodyCell>
        € {(taxes * net_premium)?.toFixed(2)}
      </StripedTable.BodyCell>
      <StripedTable.BodyCell>
        € {total_premium?.toFixed(2)}
      </StripedTable.BodyCell>
      <StripedTable.BodyCell>
        {commission === undefined ? '' : (commission * 100)?.toFixed(2) + '%'}
      </StripedTable.BodyCell>
      {quote_max_vk > 0 && (
        <StripedTable.BodyCell>{(vk * 100)?.toFixed(2)}%</StripedTable.BodyCell>
      )}
    </StripedTable.Row>
  );
};

const StyledQuickActionSubMenu = styled(QuickActionSubMenu)`
  > div {
    margin-top: -90px;
  }
`;

const StyledSubMenu = styled.div`
  > div {
    top: unset !important;
    bottom: 30px !important;
  }
`;

const IconContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 1rem;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

ProFlowOffersAccordionBoxTableRow.defaultProps = {
  canEdit: false,
  disabled: true,
  isChecked: true,
  onChange: () => {},
  vk: false,
};

ProFlowOffersAccordionBoxTableRow.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  net_premium: number.isRequired,
  taxes: number.isRequired,
  total_premium: number.isRequired,
  commission: number.isRequired,
  canEdit: bool,
  isChecked: bool,
  onChange: func,
  disabled: bool,
  vk: any,
  max_vk: number,
  insuranceType: string,
  insuranceCompanyName: string,
  sendModifications: func,
  quote_max_vk: number,
};

export default ProFlowOffersAccordionBoxTableRow;
