import React from 'react';
import styled from 'styled-components';
import { node, string, func, bool, number } from 'prop-types';
import Circlespinner from './CircleSpinner';
import { Link } from 'react-router-dom';
import InfoPopUp from './InfoPopUp';

const QuestionBox = ({
  children,
  Answer1,
  Answer2,
  dataTestId1,
  dataTestId2,
  className,
  to,
  noLink,
  disabled,
  response,
  shouldLoad,
  tooltip,
}) => {
  const path = to === null ? '/' : to;
  const left = true; // workaround for error msg

  const handleClick = e => {
    if (to === null) {
      e.preventDefault();
    }
    response(e.currentTarget.innerHTML);
  };

  return (
    <Container className={className}>
      <Question>
        <p dangerouslySetInnerHTML={{ __html: children }} />
        {tooltip !== null ? (
          <InfoPopUp title={children} info={tooltip} />
        ) : null}
      </Question>
      {Answer1 || Answer2 ? (
        <Answers>
          <Answer
            disabled={disabled || shouldLoad === 2}
            left={left ? 1 : 0}
            to={path}
            onClick={e => (disabled ? e.preventDefault() : handleClick(e))}
            data-test-id={dataTestId1}
          >
            {Answer1}
            {shouldLoad === 1 ? (
              <LoadingContainer>
                <Circlespinner />
              </LoadingContainer>
            ) : null}
          </Answer>
          <Answer
            left={left ? 0 : 1}
            to={noLink !== null ? noLink : path}
            onClick={e => {
              if (noLink === 'stay') {
                e.preventDefault();
              }
              handleClick(e);
            }}
            data-test-id={dataTestId2}
            disabled={noLink === 'stay' || shouldLoad === 1}
          >
            {Answer2}
            {shouldLoad === 2 ? (
              <LoadingContainer>
                <Circlespinner />
              </LoadingContainer>
            ) : null}
          </Answer>
        </Answers>
      ) : null}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  height: 18rem;
  box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, 0.15);
  border-radius: 0 1.6rem 1.6rem;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  margin: auto;
  margin-top: 0;
  margin-bottom: 3rem;
  flex-shrink: 0;
  background-color: white;
  padding-bottom: 4.5rem;
`;

const Answers = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
`;

const LoadingContainer = styled.div`
  position: absolute;
  right: 2rem;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
`;

const Answer = styled(Link)`
  width: 50%;
  height: 6.5rem;
  display: flex;
  position: relative;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.primary};
  color: white;
  border: solid 0.2rem;
  border-radius: ${props => (props.left ? '0 0 0 1.6rem' : '0 0 1.6rem 0')};
  border-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.secondary};
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.brand.lighter : theme.brand.lighter};
    cursor: ${props => (props.disabled ? `default` : `pointer`)};
  }

  :focus {
    outline: none;
  }
`;

const Question = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 3rem;
  align-self: flex-start;

  & span {
    font-weight: bold;
  }
`;

QuestionBox.defaultProps = {
  to: null,
  disabled: false,
  Answer1: null,
  Answer2: null,
  noLink: null,
  tooltip: null,
  className: null,
  shouldLoad: undefined,
  dataTestId1: '',
  dataTestId2: '',
  response: () => {},
};

QuestionBox.propTypes = {
  children: node.isRequired,
  Answer1: string,
  Answer2: string,
  to: string,
  response: func,
  disabled: bool,
  className: string,
  noLink: string,
  tooltip: string,
  dataTestId1: string,
  dataTestId2: string,
  shouldLoad: number,
};

export default QuestionBox;
