import React, { useEffect } from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { shape, string, arrayOf, array } from 'prop-types';
import Modal from './Modal';
import { useModal } from '../hooks/useModal';
import ProFlowOffersEditQuoteModal from './ProFlowOffersEditQuoteModal';
import Actions from './ProFlowOffersAccordionBoxActions';
import Table from './ProFlowOffersAccordionBoxTable';
import Parameters from './ProFlowOffersAccordionBoxParameters';

const ProFlowOffersAccordionBoxContent = ({
  id,
  riskObjectType,
  insurance,
  insuranceCompany,
  insuranceType,
  guarantees,
  options,
  language,
  links,
  premium,
  promotions,
  quote,
  CRM,
  error,
  originalQuote,
  fullCurrentQuote,
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useModal();

  function computeErrorColor(code) {
    switch (code) {
      case 422:
        return '#00BFFF';
      default:
        return '#DC143C';
    }
  }

  function computeErrorBgColor(code) {
    switch (code) {
      case 422:
        return '#E8F7FF';
      default:
        return '#FFE1E1';
    }
  }

  function computeErrorMss(code, msg) {
    switch (code) {
      case 422:
        return msg.toString() || t('No acceptation by the company');
      case 503:
        return t('No connection with the insurer API');
      default:
        return t(
          'Something went wrong We are aware of this issue and are fixing it as soon as possible',
        );
    }
  }

  function disableScrollOnBackground() {
    const bodyEl = document.querySelector('body');
    if (isModalOpen) {
      bodyEl.style.height = '100%';
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.height = 'unset';
      bodyEl.style.overflow = 'unset';
    }
  }

  useEffect(() => {
    disableScrollOnBackground();
  }, [isModalOpen]);

  return (
    <>
      <Modal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
        <ProFlowOffersEditQuoteModal
          id={id}
          insurance={insurance}
          insuranceCompany={insuranceCompany}
          insuranceType={insuranceType}
          guarantees={guarantees}
          options={options}
          premium={premium}
          quote={quote}
          CRM={CRM}
          onClose={() => setIsModalOpen(false)}
          fullCurrentQuote={fullCurrentQuote}
        />
      </Modal>
      {!error && (
        <OpenAccordion>
          <GridLeft>
            <Table
              insuranceCompany={insuranceCompany}
              premium={premium}
              quote={quote}
              currentQuote={fullCurrentQuote?.quote}
              insuranceTypeKey={riskObjectType}
            />
            <BottomGridContainer>
              {riskObjectType === 'car' && (
                <Parameters premium={premium} quote={quote} />
              )}
              {riskObjectType === 'home' && <Parameters quote={quote} />}
            </BottomGridContainer>
          </GridLeft>
          <Actions
            id={id}
            riskObjectType={riskObjectType}
            insuranceCompany={insuranceCompany}
            insuranceType={insuranceType}
            insurance={insurance}
            links={links}
            options={options}
            premium={premium}
            guarantees={guarantees}
            quote={quote}
            CRM={CRM}
            setIsModalOpen={setIsModalOpen}
            originalQuote={originalQuote}
          />
        </OpenAccordion>
      )}
      {error && (
        <ErrorContainer
          color={computeErrorColor(error.code)}
          exceptionErrorBGColor={computeErrorBgColor(error.code)}
        >
          {computeErrorMss(error.code, error.detail)}
        </ErrorContainer>
      )}
    </>
  );
};

const ErrorContainer = styled.div`
  text-align: center;
  padding: 2rem;
  color: ${({ color }) => color};
  background-color: ${({ exceptionErrorBGColor }) => exceptionErrorBGColor};
  border: ${({ color }) => '1px solid' + color};
`;

const OpenAccordion = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 70% calc(30% - 3rem);
  min-height: 50rem;
  @media screen and (max-width: 1360px) {
    grid-gap: 2rem;
    grid-template-columns: 70% calc(30% - 2rem);
  }
`;

const GridLeft = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

const BottomGridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  padding-top: 3rem;
  border-top: 1px solid #f0f1f3;
`;

ProFlowOffersAccordionBoxContent.propTypes = {
  id: string.isRequired,
  riskObjectType: string.isRequired,
  insurance: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceCompany: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceType: shape({ key: string.isRequired }),
  guarantees: arrayOf(
    shape({ name: string.isRequired, key: string.isRequired }),
  ),
  options: arrayOf(shape({ name: string.isRequired, key: string.isRequired })),
  language: string.isRequired,
  links: shape({}),
  premium: shape(),
  promotions: array,
  quote: shape(),
  CRM: shape({ name: string.isRequired, key: string.isRequired }),
  error: shape(),
  originalQuote: shape(),
  fullCurrentQuote: shape(),
};

export default ProFlowOffersAccordionBoxContent;
