/* stylelint-disable no-descending-specificity */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
// import CarQuestionBox from '../components/CarQuestionBox';
import { useTranslation } from 'react-i18next';
import {
  getComponentName,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

import { addCarData } from '../store';
import ChatBox from '../../../../components/ChatBox';
import { getAllCarData } from '../store';
import InfoPopUp from '../../../../components/InfoPopUp';

const OptionQuestion = ({ history }) => {
  const { t } = useTranslation();
  addChatboxQuestion(t(`${getComponentName()}.question`));
  let CarValue = parseInt(getAllCarData().value);
  if (CarValue === null) {
    CarValue = 256743;
  }
  const location = useLocation();
  const content = t(getComponentName(), { returnObjects: true });
  let maxCarOptions = Math.ceil(CarValue / 2000) * 1000;

  // calculate the max step value
  // Bjorn his function, but if max_options value is < 1, it defaults to minimum 500 euro steps
  let maxStepValue =
    (Math.floor(maxCarOptions / 10000) < 1
      ? 1
      : Math.floor(maxCarOptions / 10000)) * 500;

  // change maxcaroptions so the max can be met with the step calculated above
  maxCarOptions = Math.ceil(maxCarOptions / maxStepValue) * maxStepValue;
  const [value, setValue] = useState(
    Math.round((maxCarOptions * 0.4) / 1000) * 1000,
  );

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  // Set default value
  // value = Math.round((maxCarOptions * 0.4) / 1000) * 1000;

  const handleChange = e => {
    const value = e.currentTarget.value;
    setValue(value);
  };

  const handleClick = () => {
    addChatboxAnswer(
      t(`${getComponentName()}.response`, { optionsValue: value }),
    );
    addCarData('options_value', parseInt(value));
    history.push(getNextPath());
  };

  return (
    <Layout>
      <Container>
        <StyledChatBox showQuestion={true} />
        <QuestionContainer>
          <Info>
            {content.info ? (
              <InfoPopUp title={content.question} info={content.info} />
            ) : null}
          </Info>
          <Question>
            <p dangerouslySetInnerHTML={{ __html: content.question }} />
            {value === 0 ? (
              <p>{content.noOptions}</p>
            ) : (
              <p>
                €{value} {content.numberOptions}
              </p>
            )}
          </Question>
          <SliderContainer>
            <div>
              <p>{content.noOptions}</p>
              <p>€{maxCarOptions}</p>
            </div>
            <CustomInput
              type="range"
              data-test-id="underwriting_car_options_value"
              min="0"
              max={maxCarOptions}
              step={maxStepValue}
              value={value}
              onChange={e => handleChange(e)}
            />
          </SliderContainer>
          <StyledButton
            data-test-id="underwriting_car_submit"
            onClick={() => handleClick()}
          >
            {t(`DistanceQuestion.confirm`)}
          </StyledButton>
        </QuestionContainer>
      </Container>
    </Layout>
  );
};

const SliderContainer = styled.div`
  width: 85%;
  margin: 3rem;
  margin-top: 1rem;

  & div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;

    & p {
      width: 33.3%;
      color: grey;

      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
`;

const StyledChatBox = styled(ChatBox)`
  height: calc(60% - 3rem);
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 80%;
  box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, 0.15);
  border-radius: 0 1.6rem 1.6rem;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  margin: auto;
  margin-top: 0;
  margin-bottom: 3rem;
  flex-shrink: 0;
  background-color: white;
  padding-bottom: 4.5rem;
  height: 24rem;
`;

const Question = styled.div`
  display: flex;
  padding-bottom: 1rem;
  justify-content: space-between;
  width: 85%;
  margin: 2rem 3rem 0;
  position: relative;

  & > p:first-child {
    flex: 1 1 100%;
  }

  & > p:nth-child(2) {
    font-weight: bold;
    margin-left: 2rem;
    flex-grow: 0;
    font-size: 1.6rem;
    flex-shrink: 0;
    color: ${({ theme }) => theme.brand.primary};
    align-self: center;
  }
`;

const Info = styled.p`
  position: absolute;
  right: 2rem;
  top: 0.5rem;
`;

const CustomInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin-top: 1rem;
  width: 100%;
  height: 1.5rem;
  background: lightgrey;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10rem;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2.5rem;
    height: 2.5rem;
    background: ${({ theme }) => theme.brand.primary};
    cursor: pointer;
    border-radius: 10rem;
  }

  ::-moz-range-thumb {
    width: 2.5rem;
    height: 2.5rem;
    background: ${({ theme }) => theme.brand.primary};
    cursor: pointer;
    border-radius: 10rem;
  }
`;

const StyledButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 5.5rem;
  font-size: 2rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.primary};
  color: white;
  border: solid 0.2rem;
  border-radius: 0 0 1.6rem 1.6rem;
  border-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.secondary};
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.brand.lighter : theme.brand.lighter};
    cursor: ${props => (props.disabled ? `default` : `pointer`)};
  }
  &:focus {
    outline: none;
  }
`;

OptionQuestion.propTypes = {
  history: shape().isRequired,
};

export default withRouter(OptionQuestion);
