import React, { useState, useEffect } from 'react';
import Section from '../components/Section';
import styled from 'styled-components';
import { object } from 'prop-types';
import Bronze from '../../../../assets/images/dashboard/bronze_pkg_icon.svg';
import Silver from '../../../../assets/images/dashboard/silver_pkg_icon.svg';
import Gold from '../../../../assets/images/dashboard/gold_pkg_icon.svg';
import { useTranslation } from 'react-i18next';
import { getNestedObject } from '../../../services/objectService';
import { getMeData } from '../../../services/meDataService';
import { LoadingSpinner } from 'wg-fe-ui';

const CompanyInfo = ({ distribution }) => {
  const { name, address, website, cbe } = distribution || {};
  const [licenseIcon, setLicenseIcon] = useState();
  const [licenseText, setLicenseText] = useState();
  const { t } = useTranslation();

  const CheckLicense = () => {
    getMeData().then(data => {
      const role = getNestedObject(data, ['me', 'role']);
      switch (role) {
        case 'broker_bronze':
          setLicenseIcon(Bronze);
          setLicenseText(
            t('Settings.companyInfo.license-options.broker_bronze'),
          );
          break;
        case 'broker_silver':
          setLicenseIcon(Silver);
          setLicenseText(
            t('Settings.companyInfo.license-options.broker_silver'),
          );
          break;
        case 'broker_gold':
          setLicenseIcon(Gold);
          setLicenseText(t('Settings.companyInfo.license-options.broker_gold'));
          break;
        case 'trial_broker':
          setLicenseIcon(Bronze);
          setLicenseText(t('Trial'));
          break;
      }
    });
  };

  useEffect(() => {
    if (!licenseIcon) {
      CheckLicense();
    }
  }, [licenseIcon]);

  if (!distribution) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <Section>
      <Section.Title>{t('Settings.companyInfo.title')}</Section.Title>
      <Section.Content data-test-id="dashboard_settings_section_company_info">
        <Header>
          <div>
            <p>{t('Settings.companyInfo.company')}</p>
            <span>{name}</span>
          </div>
          <div>
            <div>
              <p>{t('Settings.companyInfo.license')}</p>
              <span>{licenseText}</span>
            </div>
            <LicenseIcon src={licenseIcon} alt={licenseText} />
          </div>
        </Header>
        <Container>
          <div>
            <Label>{t('Settings.companyInfo.adress')}</Label>
            <Value>
              {`${address?.street} ${address?.housenr}, ${address?.zipcode} ${address?.city}`}
            </Value>
          </div>
          <div>
            <Label>{t('Settings.companyInfo.website')}</Label>
            <Value>{website}</Value>
          </div>
          <div>
            <Label>{t('Settings.companyInfo.fsma')}</Label>
            <Value>{cbe}</Value>
          </div>
          <div>
            <Label>{t('company number')}</Label>
            <Value>{cbe}</Value>
          </div>
        </Container>
      </Section.Content>
    </Section>
  );
};

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};

  & div {
    width: 45%;

    &:nth-child(2) {
      display: flex;
      justify-content: space-between;
    }
  }

  & p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.typo.interactive};
  }

  & span {
    font-size: 2rem;
    font-weight: bold;
    color: ${({ theme }) => theme.typo.highlight};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &div {
    width: 45%;
    margin-bottom: 1.5rem;
  }
`;

const Label = styled.p`
  color: ${({ theme }) => theme.typo.interactive};
  font-size: 1.4rem;
  margin-bottom: 0.7rem;
`;

const Value = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.typo.highlight};
`;

const LicenseIcon = styled.img`
  width: 5rem;
  height: 5rem;
  align-self: center;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  /* display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; */
`;

CompanyInfo.propTypes = {
  distribution: object,
};

export default CompanyInfo;
