import React from 'react';
import { string } from 'prop-types';

const License = ({ color }) => {
  return (
    <svg
      width="26"
      height="33"
      viewBox="0 0 26 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.149 24.7161L16.1131 28.3225C15.4243 28.5937 14.6409 28.2547 14.3573 27.5633L10.8861 18.8184V15.8899H12.8851C14.4923 15.8899 15.8025 14.5748 15.8025 12.9614V3.82334C15.8025 2.20994 14.4923 0.894823 12.8851 0.894823H9.40039C9.68403 0.71857 9.98117 0.569433 10.3053 0.447412C11.0617 0.149137 11.8316 0 12.6014 0C15.0731 0 17.4097 1.49137 18.3822 3.9318L25.9188 22.9536C26.189 23.6586 25.8513 24.4449 25.149 24.7161Z"
        fill={color}
      />
      <path
        d="M4.92969 30.817V15.8897H9.27878V21.0282L7.10423 21.9501C7.00969 21.9908 7.00969 22.1671 7.10423 22.2077L9.27878 23.1297V25.1498L7.10423 26.0717C7.00969 26.1124 7.00969 26.2887 7.10423 26.3293L9.27878 27.2513V30.8306C9.27878 32.0372 8.30631 33.0134 7.10423 33.0134C5.90216 32.9998 4.92969 32.0237 4.92969 30.817Z"
        fill={color}
      />
      <path
        d="M12.8852 2.50822H1.29662C0.58078 2.50822 0 3.09121 0 3.80978V12.9478C0 13.6664 0.58078 14.2494 1.29662 14.2494H12.8852C13.601 14.2494 14.1818 13.6664 14.1818 12.9478V3.80978C14.1953 3.10477 13.601 2.50822 12.8852 2.50822ZM11.467 8.5415C11.2914 8.71775 11.0483 8.82621 10.7782 8.82621H3.41714C2.87688 8.82621 2.44468 8.39236 2.44468 7.85004C2.44468 7.57888 2.55273 7.33484 2.72831 7.15859C2.9039 6.98233 3.14701 6.87387 3.41714 6.87387H10.7782C11.3184 6.87387 11.7507 7.30772 11.7507 7.85004C11.7507 8.10764 11.6426 8.36524 11.467 8.5415Z"
        fill={color}
      />
      <path
        d="M10.0758 12.9478H4.10596V14.2629H10.0758V12.9478Z"
        fill={color}
      />
    </svg>
  );
};

License.propTypes = {
  color: string,
};

export default License;
