/* eslint-disable no-case-declarations */
/* eslint-disable no-sequences */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import AsyncSelect from 'react-select/async';

import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutFlow/views/App';
import regex from '../../../../services/regexService';

import {
  getNextPath,
  getComponentName,
  addChatboxQuestion,
  addChatboxAnswer,
  addBrowserHistory,
} from '../../../../store';
import {
  setAddressData,
  setAddressInfo,
  setAdress,
  setRoomLayout,
  cleanAddressData,
  getFullAddress,
} from '../store';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';
import AlternativeForm from '../../../../components/AlternativeForm';
import InputQuestionBox from '../../../../components/InputQuestionBox';

const AdresInput = ({ history }) => {
  const [selected, setSelected] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [resData, setResData] = useState({});
  const [manualInput, setManualInput] = useState(false);
  const [btnText, setBtnText] = useState('Bevestig');
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const [sendStep, setSendStep] = useState('initial');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const content = t(`${getComponentName()}`, { returnObjects: true });

  useEffect(() => {
    let btnTextTemp = `${t(`${getComponentName()}.btnText.pending`)} `;
    let _btnText;
    let _isDisabled = true;
    switch (sendStep) {
      case 'pending':
        _isDisabled = true;
        _btnText = btnTextTemp;
        break;
      case 'success':
        _btnText = t(`${getComponentName()}.btnText.success`);
        _isDisabled = true;
        // _error = false;
        const { streetName, streetNumber, municipalityName } = selected.value;
        addChatboxQuestion(content.question);
        addChatboxAnswer(
          t(`${getComponentName()}.interactiveMsg`, {
            streetName: ` ${streetName
              .toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')} `,
            streetNumber: streetNumber,
            cityName: ` ${municipalityName
              .toLowerCase()
              .split(' ')
              .map(s => s.charAt(0).toUpperCase() + s.substring(1))
              .join(' ')}`,
          }),
        );
        history.push(getNextPath());

        break;
      case 'error':
        _isDisabled = false;
        manualClickHandler(selected.value);
        _btnText = t(`${getComponentName()}.btnText.error`);
        break;
      default:
        _btnText = t(`${getComponentName()}.btnText.default`);
        _isDisabled = true;
        break;
    }

    if (_btnText !== btnText) {
      setBtnText(_btnText);
      setIsDisabled(_isDisabled);
    }
  }, [sendStep]);

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const handleChange = _selected => {
    const _isDisabled = _selected === null;
    setSelected(_selected);
    setIsDisabled(_isDisabled);
  };

  const fetchResults = value => {
    if (hasNumber(value)) {
      setLoading(true);
      return getFullAddress(value).then(res => {
        const data = res.data;
        return cleanAddressData(data);
      });
    }
  };

  const hasNumber = myString => {
    return /\d/.test(myString);
  };

  const handleAnswer = answer => {
    if (answer === content.answers[0].title) {
      setManualInput(true);
    } else {
      if (!isDisabled) {
        setIsPending(true);
        setAnswer(2);
        clickHandler();
      }
    }
  };

  const clickHandler = () => {
    // if (!error) {
    const {
      streetName,
      streetNumber,
      postalCode,
      municipalityName,
      latitude,
      boxNumber,
      longitude,
    } = selected.value;

    const payload = {
      street: streetName.toLowerCase(),
      housenr: streetNumber,
      boxnr: boxNumber,
      zipcode: parseInt(postalCode),
      city: municipalityName,
      country_code: 'BE',
      lat: latitude,
      lng: longitude,
    };

    // # This checks if the adres returned by the bpost api matched the regexes, if not, show alternativeForm.
    if (streetName)
      if (!streetName.toLowerCase().match(regex.street))
        return setManualInput(true), setIsPending(false), setAnswer(null);
    if (streetNumber)
      if (!streetNumber.match(regex.houseNr))
        return setManualInput(true), setIsPending(false), setAnswer(null);
    if (municipalityName)
      if (!municipalityName.toLowerCase().match(regex.city))
        return setManualInput(true), setIsPending(false), setAnswer(null);

    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    setSendStep('pending');
    setAddressData(payload)
      .then(res => {
        setResData(res.data);
        setAddressInfo(res.data);
        if (res.data.extra_address_data.house_type) {
          setSendStep('success');
        } else {
          setSendStep('error');
        }
      })
      .catch(error => {
        setSendStep('error');
        console.error(error.response);
      });
    // } else {
    //   setSendStep('success');
    // }
  };

  const manualInputLookup = values => {
    const {
      streetName,
      streetNumber,
      postalCode,
      municipalityName,
      latitude,
      longitude,
    } = values;
    setSelected({ value: values, label: 'Manually inputted' });

    const payload = {
      street: streetName,
      housenr: streetNumber.toLowerCase().split('/')[0],
      boxnr: streetNumber.toLowerCase().split('/')[1],
      zipcode: parseInt(postalCode),
      city: municipalityName,
      country_code: 'BE',
      lat: latitude,
      long: longitude,
    };
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    setSendStep('pending');
    setAddressData(payload)
      .then(res => {
        setResData(res.data);
        setAddressInfo(res.data);
        if (res.data.extra_address_data.house_type) {
          setSendStep('success');
        } else {
          setSendStep('error');
        }
      })
      .catch(error => {
        setSendStep('error');
        console.error(error.response);
      });
  };

  const manualClickHandler = values => {
    setAdress({
      manual: true,
      street: values.streetName,
      housenr: values.streetNumber.split('/')[0],
      boxnr: values.streetNumber.split('/')[1],
      zipcode: parseInt(values.postalCode),
      city: values.municipalityName,
      country_code: 'BE',
    });
    setAddressInfo({
      surface: '',
      height: '',
      building: '',
      extra_data: resData.extra_address_data
        ? resData.extra_address_data
        : undefined,
      aerial_images: resData.aerial_images
        ? {
            blank: resData.aerial_images.blank
              ? {
                  ...resData.aerial_images.blank,
                }
              : undefined,
            marked: resData.aerial_images.marked
              ? {
                  ...resData.aerial_images.marked,
                }
              : undefined,
          }
        : undefined,
    });
    setRoomLayout({
      bathrooms: 1,
      bedrooms: 2,
      garages: 1,
      kitchens: 1,
      living_rooms: 1,
      other_rooms: 1,
      room_count: 7,
    });
    addChatboxQuestion(content.question);
    addChatboxAnswer(
      t(`${getComponentName()}.interactiveMsg`, {
        streetName: ` ${values.streetName
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')} `,
        streetNumber: `${values.streetNumber}${
          values.boxNumber ? `/${values.boxNumber}` : ``
        }`,
        cityName: ` ${values.municipalityName
          .toLowerCase()
          .split(' ')
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ')}`,
      }),
    );
    history.push(getNextPath());
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={false} />
        <InputQuestionBox
          question={t(`${getComponentName()}.question`)}
          Answer1={content.answers[0].title}
          Answer2={content.answers[1].title}
          shouldLoad={isPending ? answer : undefined}
          mainDisabled={isDisabled}
          dataTestId1="underwriting_home_address_unknown"
          dataTestId2="underwriting_home_address_submit"
          response={answer => handleAnswer(answer)}
        >
          {manualInput ? (
            <AlternativeForm
              question={t(`${getComponentName()}.question`)}
              loading={sendStep === 'pending'}
              toggleManual={() => setManualInput(!manualInput)}
              manualClickHandler={values => manualInputLookup(values)}
            />
          ) : (
            <Input
              onChange={e => handleChange(e)}
              loadOptions={e => fetchResults(e)}
              onBlurResetsInput={false}
              isClearable
              menuPlacement="top"
              loadingMessage={() =>
                loading
                  ? t(`AdresInput.input.loading`)
                  : t(`AdresInput.input.placeholder`)
              }
              noOptionsMessage={() =>
                t(`${getComponentName()}.input.placeholder`)
              }
              classNamePrefix="Select"
              value={selected}
              placeholder={t(`${getComponentName()}.input.placeholder`)}
            />
          )}
        </InputQuestionBox>
        {/* ) : (
            <AlternativeForm
              manualClickHandler={values => manualClickHandler(values)}
            />
          )} */}
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  overflow: hidden;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;

const Input = styled(AsyncSelect)`
  width: 100%;
  z-index: 10;
  height: 4rem;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__control {
      height: 100%;
      border-radius: 0.5em;
      border-color: ${({ theme, hasValue }) =>
        hasValue ? theme.brand.primary : `#ccc`};
      z-index: 999;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

AdresInput.propTypes = {
  history: shape().isRequired,
};

export default withRouter(AdresInput);
