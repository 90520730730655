import React from 'react';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Smallest from '../../../../../../assets/images/apartment/Smallest';
import Second from '../../../../../../assets/images/apartment/Second';
import Third from '../../../../../../assets/images/apartment/Third';
import Fourth from '../../../../../../assets/images/apartment/Fourth';
import Largest from '../../../../../../assets/images/apartment/Largest';

import { getCurrentTheme } from '../../../../../store/index';

const InteractiveApartment = props => {
  const { roomData } = props;
  return (
    <Container>
      <BuildingContainer>
        {roomData.room_count <= 1 ? (
          <Smallest colors={getCurrentTheme()} alt="small" />
        ) : roomData.room_count === 2 ? (
          <Second colors={getCurrentTheme()} alt="Middle" />
        ) : roomData.room_count === 3 ? (
          <Third colors={getCurrentTheme()} alt="Middle" />
        ) : roomData.room_count === 4 ? (
          <Fourth colors={getCurrentTheme()} alt="Middle" />
        ) : roomData.room_count >= 5 ? (
          <Largest colors={getCurrentTheme()} alt="Middle" />
        ) : (
          <Largest colors={getCurrentTheme()} alt="Largest" />
        )}
      </BuildingContainer>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 2rem;
  width: 70rem;
  height: 20rem;
  line-height: 2.6rem;
  transform: scale(1);

  & span {
    font-weight: bold;
  }

  @media screen and (max-width: 1025px) {
    transform: scale(0.8);
  }
`;

const BuildingContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  justify-content: center;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

InteractiveApartment.propTypes = {
  roomData: shape().isRequired,
};

export default InteractiveApartment;
