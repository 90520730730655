import React from 'react';
import { string } from 'prop-types';

const SUV = ({ color }) => (
  <svg viewBox="0 0 286 142.13">
    <title>{'Asset 6'}</title>
    <path
      d="M280.5 65.8l-6.92-20.4c-4.43-2.84-88.35-12.95-88.35-12.95L164.82 5.3a6.55 6.55 0 00-5.5-3.72c-22.88-.53-139.27-3.37-140.51.18C13.31 16.66 2.84 55 0 70.24l6.56 29.81a9.86 9.86 0 009.44 7.09h15a36.35 36.35 0 0072.64 0h90.88a36.35 36.35 0 0072.64 0h3.22a9.54 9.54 0 009.4-7.09L286 83.19V65.8zM67.33 127.13a21.36 21.36 0 01-21.28-20c0-.45-.07-.91-.07-1.37a21.36 21.36 0 1142.71 0c0 .46 0 .92-.07 1.37a21.38 21.38 0 01-21.29 20zm163.52 0a21.38 21.38 0 01-21.29-20c0-.45-.07-.91-.07-1.37a21.36 21.36 0 0142.72 0c0 .46 0 .92-.07 1.37a21.38 21.38 0 01-21.29 20z"
      fill={color}
      data-name="Layer 2"
    />
  </svg>
);

SUV.defaultProps = {
  color: '#fff',
};

SUV.propTypes = {
  color: string,
};

export default SUV;
