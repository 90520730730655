import React from 'react';
import { TextInput, Tooltip } from 'wg-fe-ui';
import styled from 'styled-components';
import { string, bool } from 'prop-types';

const ProFlowInputTypeTextInput = ({
  description,
  title,
  type,
  ...otherProps
}) => {
  return (
    <>
      <Label>
        {title}{' '}
        {description && (
          <StyledTooltip toggleIcon="?">{description}</StyledTooltip>
        )}
      </Label>
      <StyledTextInput
        type={type}
        onKeyDown={e =>
          type === 'number' && e.keyCode === 69 && e.preventDefault()
        }
        {...otherProps}
      />
    </>
  );
};

const StyledTextInput = styled(TextInput)`
  > * {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }
  }

  & input {
    ${({ prefilled }) =>
      prefilled ? `border-color: #FFBC0F; background-color: #FFF9EA;` : ''}
  }
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 1rem;
  div {
    width: 40rem;
  }
`;

const Label = styled.label`
  align-items: center;
  color: #8990a7;
  display: flex;
  flex-direction: row;
  font-weight: 600;
  font-size: 1.4rem;
`;

ProFlowInputTypeTextInput.propTypes = {
  title: string.isRequired,
  description: string,
  prefilled: bool,
  type: string,
};
export default ProFlowInputTypeTextInput;
