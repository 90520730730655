import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import {
  ActionButton,
  H4,
  Body,
  DropDown,
  IconActionMessage,
  IconActionPlus,
  TextInput,
  IconWebWorldFilled,
  ToggleInput,
  SearchSelectInput,
  ThemePicker,
  Uploader,
  Upload,
  Alert,
} from 'wg-fe-ui';
import DateInput from '../../../../js/components/DateInput_v3';

import 'react-multi-email/style.css';

import { ReactMultiEmail, isEmail } from 'react-multi-email';
import { useTranslation } from 'react-i18next';
import { getBrokerDistribution } from '../../../../js/services/brokerDataService';
import { getBrokersByDistributionId } from '../../../../js/services/apiRouterService';
import RiskObject from './RiskObject';
import InsuranceCompanySelector from './InsuranceCompanySelector';
import checkmark from '../../../../assets/images/checkmark.svg';
import { useCampaignCreationStore } from '../context/CampaignCreationContext';
import useForm from '../../../../js/hooks/useForm';
import { isEqual } from 'lodash';
import { useCampaignStore } from '../context/CampaignContext';
import { format } from 'date-fns';
import useLockBodyScroll from '../hooks/useLockBodyScroll';

const UNAVAILABLE_INSURNACES = ['axa'];
const NewCampaignModal = ({
  setAddNewCampaignModalOpen,
  sendSuccess,
  initialType,
}) => {
  useLockBodyScroll();
  const campaignCreationStore = useCampaignCreationStore();
  const campaignStore = useCampaignStore();
  const { t } = useTranslation();
  const [brokers, setBrokers] = useState([]);
  const [allBrokers, setAllBrokers] = useState([]);
  const [brokerNames, setBrokerNames] = useState();
  const [logo, setLogo] = useState({ src: '', alt: '' });

  const { affiliations, distribution } = campaignStore?.campaigns || {};

  const { handleChange, values } = useForm({});

  const LANGUAGES = [
    { label: t('Dutch'), value: 'NL' },
    { label: t('English'), value: 'EN' },
    { label: t('French'), value: 'FR' },
  ];

  const themeKeys = {
    orange: 'orange',
    licght_blue: 'Light blue',
    ocean_blue: 'Ocean blue',
    dark_blue: 'Dark blue',
    light_red: 'Light red',
    dark_red: 'Dark red',
    pink_purple: 'Pink purple',
    dark_green: 'Dark green',
    light_green: 'Light green',
    dark_grey: 'Dark grey',
  };

  useEffect(() => {
    if (!initialType) return;
    handleChange({ name: 'type', value: initialType.toUpperCase() });
  }, [initialType]);

  useEffect(() => {
    const { status, formData } = campaignCreationStore;
    if (status !== 'edit') return;

    Object.entries(formData).forEach(([key, value]) => {
      handleChange({ name: key, value });
    });
  }, [campaignCreationStore]);

  useEffect(() => {
    console.log(values);
  }, [values]);

  const riskObjectTypes = ['car', 'family', 'residence', 'legal'];

  function handleRisk({ value: isSelected, name }, activeRiskObjects) {
    let value = [];
    if (isSelected) {
      value = [...activeRiskObjects, name];
    } else {
      value = activeRiskObjects?.filter(riskObject => riskObject !== name);
    }
    handleChange({ name: 'insuranceTypes', value: [...new Set(value)] });
  }

  function handleCompanyChange(
    { value: isSelected, name },
    selectedInsurances,
  ) {
    let value = [];
    if (isSelected) {
      value = [...selectedInsurances, name];
    } else {
      value = selectedInsurances?.filter(riskObject => riskObject !== name);
    }
    handleChange({ name: 'insurances', value: [...new Set(value)] });
  }

  useEffect(() => {
    getBrokerAffiliationData();
  }, []);

  const getBrokerAffiliationData = async () => {
    const { id } = await getBrokerDistribution();
    retrieveBrokersFromDistribution(id);
  };

  const retrieveBrokersFromDistribution = async id => {
    const [resp] = await getBrokersByDistributionId(id);
    const sortedAllBrokers = resp.items.sort((b1, b2) => {
      if (b1?.broker?.name > b2?.broker?.name) return 1;
      if (b1?.broker?.name < b2?.broker?.name) return -1;
      return 0;
    });
    setAllBrokers(sortedAllBrokers);
    const sortedBrokers = sortedAllBrokers.filter(
      item => item.broker.plan !== 'CALLANT',
    );
    setBrokers(sortedBrokers);
  };

  useEffect(() => {
    let arr = [];
    brokers?.map(bro => {
      arr.push({
        label: bro.broker.name,
        value: bro.broker.name,
        id: bro.broker.id,
      });
    });
    setBrokerNames(arr);
  }, [brokers, allBrokers]);

  const leadOptionTypes = [
    {
      text: t('Stop the flow right before the tarification'),
      title: t('Leads'),
      value: 'LEAD',
    },
    {
      text: t(
        'Create the offers in the backoffice, it is up to broker to contact the customer',
      ),
      title: t('Internal offers'),
      value: 'INTERNAL_OFFER',
    },
    {
      text: t(
        'Create the offer in the backoffice The customer will receive the offer, ipid, terms, via email The broker and customer have to contact eachother to take further actions',
      ),
      title: t('External offers'),
      value: 'EXTERNAL_OFFER',
    },
  ];

  function handleSubmit(_values) {
    sendSuccess(_values);
  }

  return (
    <Container>
      <H4>{t('What type of campaign would you like to add?')}</H4>
      <CampaignTypeContainer>
        <CampaignType
          selected={values.type === 'EMAIL'}
          onClick={() => handleChange({ name: 'type', value: 'EMAIL' })}
        >
          <Header>
            <H4>
              <IconActionMessage />
              {t('Email campaign')}
            </H4>
            <Body light>{t('Free')}</Body>
          </Header>
          <CampaignTypeContent>
            <Body light>
              {t(
                'An email campaign allows you to generate a unique URL you can send to your customers by email This will allow you to create custom Louise flows and campaigns tailored to specific insurance types or insurance companies',
              )}
            </Body>
            <BulletPoints>
              <p>
                <IconActionPlus />
                {t('Insurance companies of your choosing')}
              </p>
              <p>
                <IconActionPlus />
                {t('Insurance types of your choosing')}
              </p>
              <p>
                <IconActionPlus />
                {t('Email campaigns can run for max. 1 month')}
              </p>
            </BulletPoints>
            {values.type === 'EMAIL' && (
              <SelectedButton icon={checkmark}>{t('Selected')}</SelectedButton>
            )}
          </CampaignTypeContent>
        </CampaignType>
        <CampaignType
          selected={values.type === 'WEBSITE'}
          onClick={() => handleChange({ name: 'type', value: 'WEBSITE' })}
        >
          <Header>
            <H4>
              <IconWebWorldFilled />
              {t('Website')}
            </H4>
            <Body light>€30 / {t('month')}</Body>
          </Header>
          <CampaignTypeContent>
            <Body light>
              {t(
                'Website campaigns allow you to get a unique way of getting leads from external parties, fully configurable and adaptable to insurance types, companies, theme and logo',
              )}
            </Body>
            <BulletPoints>
              <p>
                <IconActionPlus />
                {t('Implement however you prefer')}
              </p>
              <p>
                <IconActionPlus />
                {t('Custom logo')}
              </p>
              <p>
                <IconActionPlus />
                {t('Custom theming')}
              </p>
              <p>
                <IconActionPlus />
                {t('No time restrictions')}
              </p>
            </BulletPoints>
            {values.type === 'WEBSITE' && (
              <SelectedButton icon={checkmark}>{t('Selected')}</SelectedButton>
            )}
          </CampaignTypeContent>
        </CampaignType>
      </CampaignTypeContainer>
      <BasicQs>
        <H4>{t('Information about your campaign')}</H4>
        <TextInput value={values.name} name="name" onChange={handleChange}>
          {t('Name of your campaign')}
        </TextInput>
        <StyledDropDown
          options={leadOptionTypes}
          placeholder={t('Choose an option')}
          onValueChange={([val]) =>
            handleChange({ name: 'extend', value: val?.value })
          }
          value={values?.extend}
          setValue={values?.extend}
          key={values?.extend || 'noValue'}
        >
          {t('Type of campaign')}
        </StyledDropDown>
      </BasicQs>
      <InsuranceTypeContainer>
        <H4>
          <p>{t('Which insurance types would you like to include?')}</p>
          <span
            onClick={() =>
              handleChange({
                name: 'insuranceTypes',
                value: isEqual(
                  values.insuranceTypes?.sort(),
                  riskObjectTypes?.sort(),
                )
                  ? []
                  : riskObjectTypes?.sort(),
              })
            }
          >
            {isEqual(values.insuranceTypes?.sort(), riskObjectTypes?.sort())
              ? t('Deselect all')
              : t('Select all')}
          </span>
        </H4>
        <div>
          {riskObjectTypes?.map(riskObj => {
            return (
              <RiskObject
                isSelected={values?.insuranceTypes?.includes(riskObj)}
                key={riskObj}
                name={riskObj}
                onChange={value => handleRisk(value, values?.insuranceTypes)}
              />
            );
          })}
        </div>
      </InsuranceTypeContainer>
      {values?.insuranceTypes?.length > 0 && values?.extend !== 'LEAD' && (
        <InsuranceTypeContainer>
          <H4>
            <p>{t('Which insurance companies would you like to include?')}</p>
            <span
              onClick={() =>
                handleChange({
                  name: 'insurances',
                  value: isEqual(
                    values?.insurances?.sort(),
                    affiliations
                      ?.filter(
                        ({ affiliation_info }) =>
                          !UNAVAILABLE_INSURNACES.includes(
                            affiliation_info?.name,
                          ),
                      )
                      .map(({ affiliation_info }) => affiliation_info.name)
                      ?.sort(),
                  )
                    ? []
                    : affiliations
                        ?.filter(
                          ({ affiliation_info }) =>
                            !UNAVAILABLE_INSURNACES.includes(
                              affiliation_info?.name,
                            ),
                        )
                        .map(({ affiliation_info }) => affiliation_info.name)
                        ?.sort(),
                })
              }
            >
              {isEqual(
                values?.insurances?.sort(),
                affiliations
                  ?.filter(
                    ({ affiliation_info }) =>
                      !UNAVAILABLE_INSURNACES.includes(affiliation_info?.name),
                  )
                  .map(({ affiliation_info }) => affiliation_info.name)
                  ?.sort(),
              )
                ? t('Deselect all')
                : t('Select all')}
            </span>
          </H4>
          <div>
            {affiliations
              ?.filter(({ affiliation_info }) => {
                if (UNAVAILABLE_INSURNACES.includes(affiliation_info?.name))
                  return false;
                return (
                  affiliation_info?.insurances?.filter(insurnace => {
                    if (
                      insurnace === 'home' &&
                      values?.insuranceTypes?.includes('residence')
                    )
                      return true;
                    return values?.insuranceTypes?.includes(insurnace);
                  }).length > 0
                );
              })
              ?.map(({ affiliation_info }) => {
                const { name, label, logo } = affiliation_info || {};

                return (
                  <InsuranceCompanySelector
                    key={name}
                    logo={logo}
                    label={label}
                    name={name}
                    isSelected={values?.insurances?.includes(name)}
                    onChange={value =>
                      handleCompanyChange(value, values?.insurances)
                    }
                  />
                );
              })}
          </div>
        </InsuranceTypeContainer>
      )}
      <InsuranceTypeContainer>
        <H4>{t('Configuration of your campaign')}</H4>
        <CampaignInfoInputs>
          <div>
            <StyledLabel>
              {t(
                'Would you like to receive notifications about this campaign?',
              )}
            </StyledLabel>
            <ToggleInput
              checked={!!values?.shouldReceiveNotification}
              onChange={() =>
                handleChange({
                  name: 'shouldReceiveNotification',
                  value: !values?.shouldReceiveNotification,
                })
              }
              falseLabel={t('No')}
              trueLabel={t('Yes')}
            />
          </div>
          <div>
            <StyledLabel>{t('Who should receive notifications?')}</StyledLabel>
            {values?.shouldReceiveNotification ? (
              <EmailInput
                emails={values?.listOfNotificationReceivers}
                onChange={emails =>
                  handleChange({
                    name: 'listOfNotificationReceivers',
                    value: emails,
                  })
                }
                style={{
                  minHeight: 'auto',
                  marginTop: '1.4rem',
                  border: '0.1rem solid #d3d4d8',
                  borderRadius: '0.3rem',
                  marginBottom: '1rem',
                }}
                validateEmail={email => {
                  return isEmail(email);
                }}
                getLabel={(email, i, removeEmail) => (
                  <div data-tag key={i}>
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(i)}>
                      ×
                    </span>
                  </div>
                )}
              />
            ) : (
              <StyledTextInput name="disabledPlaceholder" disabled />
            )}

            <Alert icon>
              {t(
                'You can enter one or more emails here, use a space or a comma to add multiple email addresses',
              )}
            </Alert>
          </div>
          <PercentageInput
            type="number"
            name="amountOfVk"
            value={values.amountOfVk * 100}
            onChange={({ name, value }) =>
              handleChange({ name, value: value / 100 })
            }
          >
            {t('How much VK should be applied?')}
            <span>%</span>
          </PercentageInput>
          {values.type === 'EMAIL' && (
            <DateInput
              value={
                values?.endDate
                  ? format(new Date(values?.endDate), 'dd/MM/yyyy')
                  : ''
              }
              name="date"
            >
              {t('When should this campaign end?')}
            </DateInput>
          )}
          <SearchSelectInput
            placeholder={t('Choose an option')}
            options={LANGUAGES}
            value={
              LANGUAGES.filter(({ value }) => value === values.language)[0]
            }
            onSelected={({ value }) =>
              handleChange({ name: 'language', value })
            }
          >
            {t('The default language of your campaign')}
          </SearchSelectInput>
          <SearchSelectInput
            placeholder={t('Choose an option')}
            options={brokerNames}
            value={brokerNames?.filter(({ id }) => id === values.assignedTo)[0]}
            onSelected={({ value }) => {
              const [brokerId] = brokerNames?.filter(
                ({ value: _value }) => _value === value,
              );
              handleChange({ name: 'assignedTo', value: brokerId.id });
            }}
          >
            {t('Who should be assigned to this campaign?')}
          </SearchSelectInput>
        </CampaignInfoInputs>
      </InsuranceTypeContainer>
      {values?.type === 'WEBSITE' && (
        <InsuranceTypeContainer>
          <H4>{t('Customization')}</H4>
          <CampaignInfoInputs>
            <div>
              <StyledLabel>{t('Theme color')}</StyledLabel>
              <ThemePicker
                className="theme"
                activeTheme={themeKeys[values?.theme]}
                onChange={theme => {
                  handleChange({
                    name: 'theme',
                    value: themeKeys[theme?.name],
                  });
                }}
                themes={{
                  orange: '#ff8000',
                  'Light blue': '#00a3c8',
                  'Ocean blue': '#0077aa',
                  'Dark blue': '#3153b4',
                  'Light red': '#FA6864',
                  'Dark red': '#FA4A43',
                  'Pink purple': '#D61D70',
                  'Dark green': '#62962D',
                  'Light green': '#BAD055',
                  'Ocean green': '#5CADA8',
                  'Dark grey': '#424242',
                }}
              />
            </div>
            <UploaderContainer>
              <StyledUploader
                icon={<Upload color="black" size={30} />}
                label={t('Upload or drop file here')}
                supported={[
                  {
                    extension: 'jpeg',
                    type: 'image',
                  },
                  {
                    extension: 'png',
                    type: 'image',
                  },
                ]}
              >
                {({ files }) => {
                  const logoObj = {
                    src: files?.[0]?.data,
                    alt: files?.[0]?.name,
                  };
                  if (!isEqual(logoObj, logo)) {
                    handleChange({ name: 'logo', value: logoObj });
                    setLogo(logoObj);
                  }
                }}
              </StyledUploader>

              <ImgPreview>
                <img
                  src={logo.src || distribution?.logos?.logo}
                  alt={logo.alt || 'distribution logo'}
                />
              </ImgPreview>
            </UploaderContainer>
          </CampaignInfoInputs>
        </InsuranceTypeContainer>
      )}
      <ButtonContainer>
        <ActionButton onClick={() => {}} level="white">
          {t('Cancel')}
        </ActionButton>
        <ActionButton onClick={() => handleSubmit(values)} level="primary">
          {t('Create campaign')}
        </ActionButton>
      </ButtonContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  display: flex;
`;

const ImgPreview = styled.div`
  position: absolute;
  top: 6px;
  left: 45px;
  width: 113px;
  height: 113px;
  background: white;
  padding: 1rem;
  border: 1px solid #f0f1f3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const UploaderContainer = styled.div`
  position: absolute;
  width: 50%;
  right: 0;
`;

const StyledUploader = styled(Uploader)`
  label {
    width: 42rem;
    position: absolute;
    height: 12.6rem;
    padding-left: 11rem;
    background: white;
    border: 1px solid #e4e4e4;
    box-sizing: border-box;
    border-radius: 5px;
  }
  > div {
    position: absolute;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: unset;
  margin-bottom: 1rem;
`;

const EmailInput = styled(ReactMultiEmail)`
  > input {
    font-size: 1.5rem;
  }

  > div[data-tag='true'] {
    font-size: 1.5rem;
    line-height: 1.3;
  }
`;

const BulletPoints = styled.div`
  margin-top: 4rem;
  svg {
    height: 2rem;
    path {
      fill: #ff8000;
    }
  }
  p {
    align-items: center;
    color: #8990a3;
    display: flex;
    font-size: 1.4rem;
  }
`;

const BasicQs = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 2rem;
  > h4 {
    grid-column: span 6;
    border-top: 2px solid #f0f1f3;
    margin-top: 2rem;
    padding: 2rem 0;
  }

  > div {
    grid-column: span 3;
  }
`;

const PercentageInput = styled(TextInput)`
  label {
    position: relative;
    svg {
      right: 5rem;
    }
  }

  span {
    border-left: 1px solid #ccc;
    color: #222;
    padding: 1.4rem;
    position: absolute;
    right: 0;
    top: 2.5rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  font-size: 1.4rem;
  color: #5b5550;
  line-height: 1.2rem;
`;

const StyledDropDown = styled(DropDown)`
  label {
    width: 100%;
    font-size: 1.4rem;
    color: #5b5550;
    margin-bottom: 1rem;
    line-height: 1.2rem;
  }
  div {
    color: ${({ valueSet }) => (valueSet ? 'black' : 'hsl(0, 0%, 50%)')};
  }
`;

const InsuranceTypeContainer = styled.div`
  > div {
    display: grid;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 2rem;
  }
  > h4 {
    display: flex;
    justify-content: space-between;
    border-top: 2px solid #f0f1f3;
    margin-top: 2rem;
    padding: 2rem 0;
    span {
      border-bottom: 1px solid;
      color: ${({ theme }) => theme.brand.primary};
      cursor: pointer;
      padding-bottom: 0.2rem;
    }
  }
`;

const CampaignInfoInputs = styled.div`
  column-gap: 36px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  div {
    grid-column: span 3;
  }
  .theme {
    margin-top: 1rem;
  }
`;

const SelectedButton = styled(ActionButton)`
  border-radius: 9999px;
  bottom: 2rem;
  height: 3rem;
  min-width: 12rem;
  position: absolute;
  right: 2rem;
  padding: 1rem 1.5rem 1rem 1rem;
  img {
    height: 1.5rem;
  }
`;

const CampaignTypeContent = styled.div`
  height: 25rem;
  padding: 2rem;
  position: relative;
`;

const Header = styled.div`
  border-bottom: 2px solid #f0f1f3;
  display: flex;
  height: 5rem;
  justify-content: space-between;
  h4 {
    align-items: center;
    display: flex;
    svg {
      margin: 1.5rem;
    }
  }
  p {
    align-items: center;
    display: flex;
    margin-right: 2rem;
  }
`;

const CampaignTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 1rem;
  margin: 2rem 0;
`;

const CampaignType = styled.div`
  background: white;
  border: ${({ selected }) =>
    selected ? '2px solid #ff8000' : '2px solid #f0f1f3'};
  box-sizing: border-box;
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  border-radius: 5px;
  height: 30rem;
  width: 100%;
  &:hover {
    border: 2px solid #ff8000;
  }
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 100000;
`;

NewCampaignModal.propTypes = {
  initialType: string,
  setAddNewCampaignModalOpen: func,
  sendSuccess: func,
};

export default NewCampaignModal;
