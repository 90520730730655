import React from 'react';
import { string } from 'prop-types';

const Theft = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234.45 270.7">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M220.46,102.33H205.3a3.82,3.82,0,0,0-3.27,1.92,3.84,3.84,0,0,0-.51,1.9,3.89,3.89,0,0,0,.51,1.9l2.21,3.86a2.76,2.76,0,0,0,1,1,2.7,2.7,0,0,0,1.37.36H219a2.7,2.7,0,0,0,1.42-.32,2.81,2.81,0,0,0,1.06-1l2.24-3.89a4,4,0,0,0,.47-1.89,3.87,3.87,0,0,0-.51-1.88,3.82,3.82,0,0,0-3.23-1.93Z"
        />
        <path
          fill={color}
          d="M83.92,213c5.21-12.26,10.43-25.15,15.82-37.8L92.18,169a48.6,48.6,0,0,1-15-21,.58.58,0,0,0-.18-.26.54.54,0,0,0-.3-.1.57.57,0,0,0-.31.07.66.66,0,0,0-.21.23c-1.52,3.25-2.93,6.6-3.08,7-5.05,11.88-4.66,10.43-9.61,22.59-.44,1.17-.94,2.28-1.43,3.44a21.12,21.12,0,0,1-3.72-1.33c-12.15-4.94-24-9.94-36.19-14.88-4.61-1.93-9.16-2.33-13.49.39C2.34,169,.05,175,0,182c.11,6.61,4.33,10.65,10.11,12.45,19.81,8.21,39.35,16.49,59.16,24.65C76.93,222.3,80.92,220.35,83.92,213Z"
        />
        <path
          fill={color}
          d="M195.77,155.93l-4,19.31a17.38,17.38,0,0,0,17,20.87h8.35a17.37,17.37,0,0,0,17-20.87L230,155.93c-.83-4-4.77-6.89-9.39-6.89H205.15C200.54,149,196.6,151.92,195.77,155.93Z"
        />
        <path
          fill={color}
          d="M142.81,192.28q-.15,31.46-.27,62.93a16.57,16.57,0,0,0,.17,4.39c1.65,7.27,7.43,11.32,16.54,11.09,9.37-.32,15.26-6.21,15.32-15.42-.11-24.65,0-49.46.11-74.27-.11-5.72-2.12-9.93-7.16-13-10.33-6-20.66-12.6-30.82-18.92a11.36,11.36,0,0,1-3-2c7.93-12.45,16-24.59,24.3-37.14a5.23,5.23,0,0,0,.28,1.51c.06,7.93-.11,16.2-.06,24.14.06,5.66,2.78,8.38,8.17,8.43,17.14,0,34.57-.05,51.73,0,7.58.11,13-5.27,13-12.54s-5.22-12.2-13.15-12.15c-10.83,0-21.26,0-32.08,0a22.07,22.07,0,0,1-3.45.1,31.53,31.53,0,0,0,.06-3.82c-.06-9.56-.06-19.05-.11-28.54,0-7-2.71-12.27-8.78-16.15-10.76-7.1-21.14-14.63-32.08-21.31A22.32,22.32,0,0,0,131,46.29c-19.5-.38-39.18-.33-58.55-.44-4.38.17-7.27,1.66-9.56,5.41C56.94,61,51.07,70.63,45.12,80.35c-4.21,6.76-2.7,14.42,4.4,17.86,6,3.88,13.48,1.83,17.69-4.94S75.72,79.68,79.93,72.9c1.22-1.78,2.21-2.5,4.44-2.44,11.87.27,23.87.16,35.64.16a16.21,16.21,0,0,1,2.6.23,26.94,26.94,0,0,0-2.11,3.38l-30,48.3c-12.71,20.4,6.66,37,8.55,38.43l12.26,9.94c10,5.82,19.93,11.6,29.82,17.14C142.93,189.27,142.81,190.58,142.81,192.28Z"
        />
        <path
          fill={color}
          d="M179.34,0c-16.48.05-29.48,12.59-29.48,29.09a28.72,28.72,0,0,0,28.87,29A29.12,29.12,0,0,0,208,29.36C208.15,13.1,195.15,0,179.34,0Z"
        />
      </g>
    </g>
  </svg>
);

Theft.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Theft.propTypes = {
  title: string,
  color: string,
};

export default Theft;
