import React from 'react';
import styled from 'styled-components';
import { string, number, bool, node } from 'prop-types';
import CircleSpinner from './CircleSpinner';

const Button = ({ className, size, color, sizeUnit, children, long }) => (
  <StyledButton long={long} className={className} disabled={true}>
    <Text>{children}</Text>
    <StyledCircleSpinner size={size} color={color} sizeUnit={sizeUnit} />
  </StyledButton>
);

const Text = styled.p`
  display: inline-block;
  font-weight: bold;
  font-family: ${({ theme }) => theme.font};
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  right: 1rem;
`;

const StyledButton = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.brand.lighter};
  min-width: 14rem;
  padding: 0 4rem;
  height: 5.1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  display: flex;
  font-size: 1.8rem;
  justify-content: flex-start;
  align-items: center;
  color: white;
  border: 0;
  font-weight: bold;
  text-decoration: none;
  align-self: flex-end;
  margin-bottom: 2rem;
  z-index: 100;
  cursor: default;
  user-select: none;
  transition: all 0.2s ease 0s;

  &:focus {
    border: 0 0 0;
    outline: none;
  }
  & p {
    text-align: left;
  }
`;

Button.defaultProps = {
  className: '',
  size: 20,
  color: '#fff',
  sizeUnit: 'px',
  text: '',
  long: false,
};

Button.propTypes = {
  className: string,
  size: number,
  color: string,
  sizeUnit: string,
  text: string,
  long: bool,
  children: node,
};

export default Button;
