import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { func, array, bool } from 'prop-types';
import _ from 'lodash';
import queryString from 'query-string';
import i18n from 'i18next';

import {
  retrieveAvailableAffiliations,
  retrieveProFlowGuarantees,
  retrieveProFlowQuestionsNumber,
  retrieveInsuranceCompanies,
} from '../services/apiRouterService.js';
import { getBrokerAffiliations } from '../services/brokerDataService.js';
import {
  Section,
  H3,
  Body,
  TertiaryButton,
  IconCarFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconLegalFilled,
  LoadingSpinner,
} from 'wg-fe-ui';
import OfferedPolicy from './OfferedPolicy.jsx';
import OfferedGuarantee from './OfferedGuarantee.jsx';

const ProFlowInsuranceAndGuarantee = ({
  riskOptions,
  chosenRisks,
  chosenInsurances,
  setChosenInsurances,
  chosenGuarantees,
  setChosenGuarantees,
  isCallantFeature,
  hasPiaEnabled,
  setProFlowErrorMessage,
  setProFlowCreationError,
}) => {
  const [afilliationResults, setAfilliationResults] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState([]);
  const [newAffiliationResults, setNewAfilliationResults] = useState([]);
  const [insurancesNames, setInsurancesNames] = useState([]);
  const [guarantees, setGuarantees] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tagsError, setTagsError] = useState();

  const prevChosenRisks = usePrevious({ chosenRisks });

  const [
    mountingInsurancesAnimation,
    setMountingInsurancesAnimation,
  ] = useState({
    opacity: 0,
  });
  const [
    mountingGuaranteesAnimation,
    setMountingGuaranteesAnimation,
  ] = useState({
    opacity: 0,
  });
  const { t } = useTranslation();

  const PIA = {
    affiliation_info: {
      insurances: ['car'],
      label: 'PIA',
      logo: 'https://files.wegroup.be/logos/insurances/pia.png',
      name: 'pia',
      needs_verification: false,
      required_integrations: [],
      required_nrs: ['nr'],
    },
    disabled: false,
  };
  const AFFINITIES_COMPANIES = [
    {
      affiliation_info: {
        insurances: ['car'],
        label: 'Honda',
        logo: 'https://files.wegroup.be/logos/affinity/honda.svg',
        name: 'honda',
        needs_verification: false,
        required_integrations: [],
        required_nrs: ['nr'],
      },
      disabled: false,
    },
  ];

  let icons = [
    { name: 'car', icon: IconCarFilled },
    { name: 'family', icon: IconFamilyFilled },
    { name: 'legal', icon: IconLegalFilled },
    { name: 'home', icon: IconPropertyFilled },
  ];

  // Custom hook used to store the previous value of a state
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    getBrokerAffiliationData();
  }, []);

  useEffect(() => {
    if (chosenRisks.length > 0) {
      setMountingInsurancesAnimation({ opacity: 1 });
    } else {
      setMountingInsurancesAnimation({ opacity: 0 });
    }

    // Check to see if object has changed: Otherwise it loops

    if (!_.isEqual(prevChosenRisks?.chosenRisks, chosenRisks)) {
      getNumberOfQuestions();
      getInsuranceCompanies(filterAfilliations());
      setProFlowCreationError(false);
      if (chosenRisks.length !== 0) {
        getTags();
      }

      const filteredChosenInsurances = chosenInsurances.filter(insurance => {
        const supportedInsurances = newAffiliationResults.find(
          affiliation => affiliation?.affiliation_info?.name === insurance,
        );
        return supportedInsurances?.affiliation_info?.insurances.some(
          supportedInsurance => chosenRisks.includes(supportedInsurance),
        );
      });

      setChosenInsurances(filteredChosenInsurances);
    }

    // If chosen risks was reset, also reset insurances
    if (chosenRisks.length === 0) {
      setChosenInsurances([]);
    }

    // Checks a very rare case:
    // Makes sure that if chosenRisks are changed that the insurances for that risk type
    // Are removed from the list of chosenInsuranes
  }, [chosenRisks]);

  useEffect(() => {
    // Set the amount of questions that are going to be asked
    getNumberOfQuestions();
    if (chosenInsurances.length > 0) {
      setMountingGuaranteesAnimation({ opacity: 1 });
      getTags();
    } else {
      setMountingGuaranteesAnimation({ opacity: 0 });
    }

    // If chosen insurances was reset, also reset guarantees
    if (chosenInsurances.length === 0) {
      setChosenGuarantees([]);
    }
  }, [chosenInsurances]);

  useEffect(() => {
    if (_.isEmpty(chosenGuarantees)) {
      setProFlowCreationError(false);
    }
  }, [chosenGuarantees]);

  useEffect(() => {
    if (guarantees?.tags) {
      _.forEach(Object.keys(guarantees.tags), key => {
        // Select mandatory guarantees
        let mandatoryGuarantees = getMandatoryGuarantees(key);

        if (_.isEmpty(chosenGuarantees[key])) {
          setChosenGuarantees(chosenGuarantees => ({
            ...chosenGuarantees,
            [key]: [...mandatoryGuarantees],
          }));
        }

        // Create array so that .includes can easily be used
        const guaranteesArray = guarantees.tags[key].map(obj => obj.tag);

        // Filter guarantees that can't be chosen anymore after insurer change
        setChosenGuarantees(chosenGuarantees => ({
          ...chosenGuarantees,
          [key]: chosenGuarantees[key]?.filter(chosenGuarantee =>
            guaranteesArray.includes(chosenGuarantee),
          ),
        }));
      });
    }
  }, [guarantees]);

  useEffect(() => {
    if (chosenRisks.length === 0) return;

    const toBeSetAfilliations = filterAfilliations();

    getInsuranceCompanies(toBeSetAfilliations);
  }, [afilliationResults, isCallantFeature]);

  async function getTags() {
    setIsLoading(true);
    const qs = queryString.stringify({
      company: chosenInsurances,
      insurance_type: chosenRisks,
    });

    const [resp, status] = await retrieveProFlowGuarantees(qs);
    if (status === 200) {
      setGuarantees(resp);
      _.forEach(Object.keys(resp.tags), insuranceType => {
        // Make sure that chosenGuarantees are cleared
        // Otherwise 'Next' button behaviour is invalid
        if (_.isEmpty(resp.tags[insuranceType])) {
          setChosenGuarantees(chosenGuarantees => ({
            ...chosenGuarantees,
            [insuranceType]: [],
          }));
        }
      });
      setTagsError('');
    } else {
      setTagsError(t('There was a problem while fetching the guarantees'));
    }

    setIsLoading(false);
  }

  const getBrokerAffiliationData = async () => {
    const [
      affiliationsResult,
      affiliationsStatus,
    ] = await retrieveAvailableAffiliations();
    const brokerAffiliationsResult = await getBrokerAffiliations();
    if (affiliationsStatus !== 200) return;
    const brokerAffiliations = brokerAffiliationsResult?.items?.map(item => {
      return item?.affiliation?.name;
    });
    // TODO: Remove the aedes and optimco fix here when active
    setAfilliationResults(
      affiliationsResult?.items?.map(item => {
        item.disabled = !brokerAffiliations?.includes(
          item?.affiliation_info?.name,
        );
        return item;
      }),
    );
  };

  async function getInsuranceCompanies(toBeSetAfilliations) {
    const [resp] = await retrieveInsuranceCompanies();

    // Filters the affiliations for the risks that are not offered by WeGroup
    toBeSetAfilliations = toBeSetAfilliations.map(affiliation => {
      affiliation.affiliation_info.insurances = resp?.companies.find(
        company => company.name === affiliation?.affiliation_info?.name,
      ).insurance_types;
      return affiliation;
    });

    // Changes the insurances so that the icons are shown correctly
    // By icons is meant => The icons under each insurance company logo for the risks that they support
    toBeSetAfilliations = toBeSetAfilliations.filter(item => {
      const company = resp?.companies.find(
        company => company.name === item?.affiliation_info?.name,
      );
      return company.insurance_types.some(type => {
        return chosenRisks.includes(type);
      });
    });
    setNewAfilliationResults(toBeSetAfilliations);
    setInsurancesNames(
      _.map(
        toBeSetAfilliations.filter(affiliation => !affiliation.disabled),
        affiliation => affiliation?.affiliation_info?.name,
      ),
    );
  }

  async function getNumberOfQuestions() {
    if (chosenInsurances.length !== 0) {
      const qs = queryString.stringify({
        company: chosenInsurances,
        insurance_type: chosenRisks,
        drivers: riskOptions?.drivers,
        trailers: riskOptions?.trailers,
      });

      const [resp] = await retrieveProFlowQuestionsNumber(qs);
      setProFlowCreationError(true);

      if (resp.code !== 400) {
        setNumberOfQuestions(resp?.questions?.length);
        setProFlowCreationError(false);
      } else {
        setProFlowErrorMessage(resp?.detail[i18n.language]);
      }
    } else {
      setNumberOfQuestions(0);
    }
  }

  function filterAfilliations() {
    let toBeSetAfilliations = afilliationResults.filter(
      ({ affiliation_info }) =>
        affiliation_info.insurances.some(insurance => {
          return chosenRisks.includes(insurance);
        }),
    );

    if (hasPiaEnabled) {
      toBeSetAfilliations.push(PIA);
    }

    if (isCallantFeature) {
      toBeSetAfilliations = AFFINITIES_COMPANIES;
    }

    return toBeSetAfilliations;
  }

  function getMandatoryGuarantees(key) {
    let mandatoryGuarantees = [];
    _.forEach(_.filter(guarantees.tags[key], { mandatory: true }), item =>
      mandatoryGuarantees.push(item.tag),
    );
    return mandatoryGuarantees;
  }

  function selectAllGuarantees(insuranceType) {
    // Check if all options are already selected
    if (
      chosenGuarantees[insuranceType]?.length ===
      guarantees.tags[insuranceType].length
    ) {
      setChosenGuarantees(chosenGuarantees => ({
        ...chosenGuarantees,
        [insuranceType]: getMandatoryGuarantees(insuranceType),
      }));
    } else {
      _.forEach(guarantees.tags[insuranceType], guarantee => {
        if (!chosenGuarantees[insuranceType]?.includes(guarantee.tag)) {
          if (!chosenGuarantees[insuranceType]) {
            chosenGuarantees[insuranceType] = [];
          }
          setChosenGuarantees(chosenGuarantees => ({
            ...chosenGuarantees,
            [insuranceType]: [
              ...chosenGuarantees[insuranceType],
              guarantee.tag,
            ],
          }));
        }
      });
    }
  }

  function selectAllChosenInsurances() {
    if (chosenInsurances?.length === insurancesNames.length) {
      setChosenInsurances([]);
    } else {
      setChosenInsurances(insurancesNames);
    }
  }

  function handleInsurancesChange({ value, name }) {
    let arr = [];
    if (value) {
      if (!chosenInsurances.includes(name)) arr = [...chosenInsurances, name];
    } else {
      //remove the item that matches name
      if (chosenInsurances.includes(name))
        arr = chosenInsurances.filter(insurance => insurance !== name);
    }
    setChosenInsurances(arr);
  }

  function handleGuaranteeChange({ name, addOption, insuranceType }) {
    if (addOption) {
      // Will add it to the selected options only if found
      if (!chosenGuarantees[insuranceType]?.includes(name)) {
        if (!chosenGuarantees[insuranceType]) {
          chosenGuarantees[insuranceType] = [];
        }
        setChosenGuarantees(chosenGuarantees => ({
          ...chosenGuarantees,
          [insuranceType]: [...chosenGuarantees[insuranceType], name],
        }));
      }
    } else {
      // Will remove it from the selected options only if found
      if (chosenGuarantees[insuranceType]?.includes(name)) {
        setChosenGuarantees(chosenGuarantees => ({
          ...chosenGuarantees,
          [insuranceType]: _.filter(chosenGuarantees[insuranceType], item => {
            return item !== name;
          }),
        }));
      }
    }
  }

  function getIconElement(key) {
    const iconElement = _.find(icons, { name: key })?.icon;
    return iconElement === undefined ? IconPropertyFilled : iconElement;
  }

  return (
    <div>
      {chosenRisks.length !== 0 && (
        <StyledSection style={mountingInsurancesAnimation}>
          <TitleContainer>
            <div>
              <H3>
                {!isCallantFeature
                  ? t('What insurance companies do you want to tarifficate?')
                  : t('To what affinity do you belong?')}
              </H3>
              <Body light>
                <BodyText>
                  {t(
                    'Choose which policies you will be offering to your clients This section will have 14 questions and will take approximately 6 minutes minutes to complete',
                    { numberOfQuestions },
                  )}
                </BodyText>
              </Body>
            </div>
            <ButtonWrapper>
              <StyledTertiaryButton
                label={
                  insurancesNames.length === chosenInsurances?.length
                    ? t('Deselect all')
                    : t('Select all')
                }
                onClick={() => selectAllChosenInsurances()}
              />
            </ButtonWrapper>
          </TitleContainer>

          <StyledContent>
            <OfferedPoliciesContainer>
              {newAffiliationResults.map(({ affiliation_info, disabled }) => {
                const { name, label, logo, insurances } =
                  affiliation_info || {};
                return (
                  <OfferedPolicy
                    //allSelected={allSelected}
                    key={name}
                    logo={logo}
                    label={label}
                    name={name}
                    disabled={disabled}
                    insurances={insurances}
                    onChange={value => handleInsurancesChange(value)}
                    isLoading={isLoading}
                    chosenInsurances={chosenInsurances}
                  />
                );
              })}
            </OfferedPoliciesContainer>
          </StyledContent>
        </StyledSection>
      )}
      {chosenInsurances.length !== 0 &&
        chosenRisks.length !== 0 &&
        (tagsError ? (
          <StyledSection>
            <Error>{tagsError}</Error>
          </StyledSection>
        ) : (
          !_.isEmpty(guarantees) && (
            <StyledSection style={mountingGuaranteesAnimation}>
              <H3>{t('What guarantees do you want')}</H3>
              <Body light>
                <BodyText>
                  {t(
                    'Choose which guarantees you want to offer to your customers',
                  )}
                </BodyText>
              </Body>
              <StyledContent>
                {_.map(Object.keys(guarantees.tags), key => {
                  return (
                    !_.isEmpty(guarantees.tags[key]) && (
                      <RiskObjectGuarantees id="guarantees">
                        <TitleContainer>
                          <IconAndTitleContainer
                            active={
                              chosenGuarantees[key]?.length ===
                              guarantees.tags[key].length
                            }
                            onClick={() => selectAllGuarantees(key)}
                          >
                            <IconWrapper>
                              {React.createElement(getIconElement(key))}
                            </IconWrapper>
                            <GuaranteesTitle>{t(key)}</GuaranteesTitle>
                          </IconAndTitleContainer>
                          <ButtonWrapper>
                            <StyledTertiaryButton
                              label={
                                chosenGuarantees[key]?.length ===
                                guarantees.tags[key]?.length
                                  ? t('Deselect all')
                                  : t('Select all')
                              }
                              onClick={() => selectAllGuarantees(key)}
                              otherProps={{}}
                            />
                          </ButtonWrapper>
                        </TitleContainer>
                        <GuaranteesContainer>
                          {_.map(guarantees.tags[key], guarantee => {
                            // Special name if name is the same as another
                            // and another icon is needed
                            if (
                              key === 'family' &&
                              guarantee.tag === 'CIVIL_LIABILITY'
                            ) {
                              guarantee.adjustedName = 'civil_liability_family';
                            }
                            return (
                              !_.isEmpty(guarantees.tags[key]) && (
                                <OfferedGuarantee
                                  key={guarantee.tag}
                                  guarantee={guarantee}
                                  handleChange={handleGuaranteeChange}
                                  chosenGuarantees={chosenGuarantees}
                                  insuranceType={key}
                                />
                              )
                            );
                          })}
                        </GuaranteesContainer>
                      </RiskObjectGuarantees>
                    )
                  );
                })}
              </StyledContent>
            </StyledSection>
          )
        ))}

      {isLoading && (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      )}
    </div>
  );
};

const Error = styled.div`
  display: flex;
  justify-content: center;
  color: red;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 3rem 3rem 6rem;
`;

const StyledSection = styled(Section)`
  transition: opacity 0.2s ease-in, max-height 2.5s ease-out;
  margin: auto;
  padding: 0 3rem;
  margin-bottom: 5vh;
  margin-top: 6rem;
  width: 100%;
`;

const BodyText = styled.div`
  display: flex;
  align-items: baseline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 20%;

  @media screen and (max-width: 900px) {
    width: 30%;
  }
`;

const StyledTertiaryButton = styled(TertiaryButton)`
  outline: none;
`;

const StyledContent = styled(Section.Content)`
  ${props =>
    props.background
      ? `
    box-shadow: 0 2px 4px rgba(32, 32, 35, 0.1);
    border: 1px solid #e4e4e4;
  `
      : null}
`;

const OfferedPoliciesContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media screen and (max-width: 965px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  p {
    text-align: center;
  }
`;

const RiskObjectGuarantees = styled.div`
  margin-top: 3rem;
  &:first-child {
    margin-top: 0;
  }
`;

const IconAndTitleContainer = styled.div`
  display: flex;
  align-items: center;
  > h3 {
    color: ${({ theme, active }) =>
      active ? theme.brand.primary : ' #c4c4c4'};
    transition: 0.2s ease color;
  }
  > div {
    background-color: ${({ theme, active }) =>
      active ? theme.brand.primary : ' #c4c4c4'};
    transition: 0.2s ease background-color;
  }

  &:hover {
    > div {
      background-color: ${({ theme }) => theme.brand.secondary};
    }

    > h3 {
      color: ${({ theme }) => theme.brand.secondary};
    }
    cursor: pointer;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  padding: 0.8rem;
  margin-right: 1.8rem;
  background-color: #c4c4c4;
  border-radius: 0.5rem;

  svg path {
    fill: white;
  }
`;

const GuaranteesTitle = styled(H3)`
  color: #c4c4c4;
`;

const GuaranteesContainer = styled.div`
  width: 100%;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;

  @media screen and (max-width: 1240px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media screen and (max-width: 660px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

ProFlowInsuranceAndGuarantee.propTypes = {
  riskOptions: array.isRequired,
  chosenRisks: array.isRequired,
  chosenInsurances: array.isRequired,
  chosenGuarantees: array.isRequired,
  setChosenInsurances: func.isRequired,
  setChosenGuarantees: func.isRequired,
  isCallantFeature: bool.isRequired,
  hasPiaEnabled: bool.isRequired,
  setProFlowErrorMessage: func.isRequired,
  setProFlowCreationError: func.isRequired,
};

export default ProFlowInsuranceAndGuarantee;
