import React from 'react';
import { string } from 'prop-types';

const Garden = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="27"
    fill="none"
    viewBox="0 0 26 27"
  >
    <path
      fill={color}
      d="M19.94 23.83c-1.383 0-2.633.619-3.548 1.58a6.636 6.636 0 00-3.024-4.13v-3.1l5.524-5.664h4.776a4.352 4.352 0 00-1.138-2.94H26c0-2.357-1.83-4.256-4.118-4.325-.736-1.293-2.086-2.185-3.649-2.185-.178 0-.345.034-.524.057C16.682 1.27 14.752 0 12.52 0c-2.21 0-4.117 1.247-5.155 3.066-1.652 0-3.058.973-3.76 2.391-1.72.55-2.969 2.174-2.969 4.119h6.506l4.541 4.656v6.361c-.502-.126-1.015-.217-1.55-.217-2.456 0-4.554 1.418-5.658 3.478C2.488 23.9.736 25.227 0 27h24.527c-.736-1.865-2.51-3.17-4.586-3.17zM13.369 9.577h2.98a4.374 4.374 0 00-1.139 2.94h1.228l-3.058 3.146-.01-6.086zm-3.76 0h2.075v2.128L9.608 9.576z"
    />
  </svg>
);

Garden.propTypes = {
  color: string.isRequired,
};

export default Garden;
