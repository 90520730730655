import React, { useEffect, useContext } from 'react';
import Layout from '../../DashboardLayout/views/Layout';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import i18next from 'i18next';
import AffinitiesSection from '../components/AffinitiesSection';

const SettingsUserSettings = () => {
  const { t } = useTranslation();

  const { setNavTabs } = useContext(TopNavigationItems);
  useEffect(() => {
    setNavTabs([
      {
        title: t('Account settings'),
        testId: 'settings_account_settings',
        to: '/settings/account-settings',
      },
      {
        title: t('Change password'),
        testId: 'settings_user_settings',
        to: '/settings/user-settings',
      },
      {
        title: t('Edit affinities'),
        testId: 'settings_affinity_settings',
        to: '/settings/affinity-settings',
      },
    ]);
  }, [i18next.language]);

  return (
    <StyledLayout>
      <AffinitiesSection />
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-top: 7rem; /* same as header height */
  width: 90%;
  max-width: 100rem;

  .content {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
`;

SettingsUserSettings.propTypes = {
  history: shape().isRequired,
};

export default SettingsUserSettings;
