import React from 'react';

const art = () => (
  <svg viewBox="0 0 185 185">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'artmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M129.71 71v.08a15.81 15.81 0 01-31.62-.21 15.63 15.63 0 011.68-7.09 15.94 15.94 0 0120.38-7.52A15.37 15.37 0 01129.71 71zM137.14 107.29h6.52a2 2 0 012 2v34.37a2 2 0 01-2 2H41.34a2 2 0 01-2-2V90.6a2 2 0 012-2h13.29a73.69 73.69 0 0152.63 22 6.3 6.3 0 007 1.32 58.46 58.46 0 0122.91-4.67z"
        />
        <path
          className="prefix__cls-1"
          d="M161.74 163.74H23.26a2 2 0 01-2-2V23.26a2 2 0 012-2h138.48a2 2 0 012 2v138.48a2 2 0 01-2 2zM0 5v175a5 5 0 005 5h175a5 5 0 005-5V5a5 5 0 00-5-5H5a5 5 0 00-5 5z"
        />
      </g>
    </g>
  </svg>
);

export default art;
