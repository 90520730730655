import React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import AnimalsDamage from '../../../../../assets/images/matrix/animals_damage';
import Art from '../../../../../assets/images/matrix/art';
import Bicycle from '../../../../../assets/images/matrix/bicycle';
import CarValue from '../../../../../assets/images/matrix/car_value';
import Children from '../../../../../assets/images/matrix/children';
import Coverage from '../../../../../assets/images/matrix/coverage';
import CoverageAbroad from '../../../../../assets/images/matrix/coverage_abroad';
import Disabilities from '../../../../../assets/images/matrix/disabilities';
import Drone from '../../../../../assets/images/matrix/drone';
import EStep from '../../../../../assets/images/matrix/e_step';
import GardenContent from '../../../../../assets/images/matrix/garden_content';
import GardenHouse from '../../../../../assets/images/matrix/garden_house';
import GlassBreakage from '../../../../../assets/images/matrix/glass_breakage';
import Guests from '../../../../../assets/images/matrix/guests';
import Informatics from '../../../../../assets/images/matrix/informatics';
import Juwels from '../../../../../assets/images/matrix/juwels';
import LegalCoverage from '../../../../../assets/images/matrix/legal_coverage';
import LegalTotal from '../../../../../assets/images/matrix/legal_total';
import Rating_0 from '../../../../../assets/images/stars/rating_0.svg';
import Rating_1 from '../../../../../assets/images/stars/rating_1.svg';
import Rating_2 from '../../../../../assets/images/stars/rating_2.svg';
import Rating_3 from '../../../../../assets/images/stars/rating_3.svg';
import Rating_4 from '../../../../../assets/images/stars/rating_4.svg';
import Rating_5 from '../../../../../assets/images/stars/rating_5.svg';
import NatureDamage from '../../../../../assets/images/matrix/nature_damage';
import Pets from '../../../../../assets/images/matrix/pets';
import Plantation from '../../../../../assets/images/matrix/plantation';
import PrivateGarage from '../../../../../assets/images/matrix/private_garage';
import Quality from '../../../../../assets/images/matrix/quality';
import ReplacementCar from '../../../../../assets/images/matrix/replacement_car';
import SharedObjects from '../../../../../assets/images/matrix/shared_objects';
import SolarPanels from '../../../../../assets/images/matrix/solar_panels';
import Students from '../../../../../assets/images/matrix/students';
import SwimmingPool from '../../../../../assets/images/matrix/swimming_pool';
import Theft from '../../../../../assets/images/matrix/theft';
import TotalLossDefinition from '../../../../../assets/images/matrix/total_loss_definition';
import TransportationPlane from '../../../../../assets/images/matrix/transportation_plane';
import Travels from '../../../../../assets/images/matrix/travels';
import Vehicle from '../../../../../assets/images/matrix/vehicle';
import WorksAtHome from '../../../../../assets/images/matrix/works_at_home';
import Consumer from '../../../../../assets/images/extentions/CONSUMER';
import Income from '../../../../../assets/images/extentions/INCOME';
import Mobility from '../../../../../assets/images/extentions/MOBILITY';
import Property from '../../../../../assets/images/extentions/PROPERTY';

const LoadingCompareIcons = ({ type }) => {
  switch (type) {
    case 'animals_damage':
      return <AnimalsDamage />;
    case 'art':
      return <Art />;
    case 'bicycle':
      return <Bicycle />;
    case 'car_value':
      return <CarValue />;
    case 'children':
      return <Children />;
    case 'coverage':
      return <Coverage />;
    case 'coverage_abroad':
      return <CoverageAbroad />;
    case 'disabilities':
      return <Disabilities />;
    case 'drone':
      return <Drone />;
    case 'e_step':
      return <EStep />;
    case 'garden_content':
      return <GardenContent />;
    case 'garden_house':
      return <GardenHouse />;
    case 'glass_breakage':
      return <GlassBreakage />;
    case 'guests':
      return <Guests />;
    case 'informatics':
      return <Informatics />;
    case 'juwels':
      return <Juwels />;
    case 'legal_coverage':
      return <LegalCoverage />;
    case 'legal_total':
      return <LegalTotal />;
    case 1:
      return <Img src={Rating_1} alt="" />;
    case 2:
      return <Img src={Rating_2} alt="" />;
    case 3:
      return <Img src={Rating_3} alt="" />;
    case 4:
      return <Img src={Rating_4} alt="" />;
    case 5:
      return <Img src={Rating_5} alt="" />;
    case 0:
      return <Img src={Rating_0} alt="" />;
    case 'nature_damage':
      return <NatureDamage />;
    case 'pets':
      return <Pets />;
    case 'plantation':
      return <Plantation />;
    case 'private_garage':
      return <PrivateGarage />;
    case 'quality':
      return <Quality />;
    case 'replacement_car':
      return <ReplacementCar />;
    case 'shared_objects':
      return <SharedObjects />;
    case 'solar_panels':
      return <SolarPanels />;
    case 'students':
      return <Students />;
    case 'swimming_pool':
      return <SwimmingPool />;
    case 'theft':
      return <Theft />;
    case 'total_loss_definition':
      return <TotalLossDefinition />;
    case 'transportation_plane':
      return <TransportationPlane />;
    case 'travels':
      return <Travels />;
    case 'vehicle':
      return <Vehicle />;
    case 'works_at_home':
    case 'private_life':
      return <WorksAtHome />;
    case 'consumer':
      return <Consumer color="white" />;
    case 'income':
      return <Income color="white" />;
    case 'mobility':
      return <Mobility color="white" />;
    case 'property':
      return <Property color="white" />;
    default:
      return <p>[No Icon found]</p>;
  }
};

const Img = styled.img`
  vertical-align: middle;
`;

LoadingCompareIcons.defaultProps = {
  type: '',
};

LoadingCompareIcons.propTypes = {
  type: string,
};

export default LoadingCompareIcons;
