import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { string, func } from 'prop-types';
import { retrieveAcceptances } from '../store';
import Loading from '../../../../components/Loading';
import AcknowledgementCheckbox from './AcknowledgementCheckbox';

const CardCheckBox = ({ coverage, accepted, id, dataTestId }) => {
  const [checkContent, setCheckContent] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (Object.keys(checkContent).length === 0) {
      const acceptancesPayload = {};
      acceptancesPayload[coverage] = true;
      try {
        retrieveAcceptances(acceptancesPayload)
          .then(res => {
            setCheckContent(res.data[coverage][0]);
          })
          .catch(error => console.error(error.response));
      } catch (error) {
        setCheckContent('Test data');
      }
    }
  }, [checkContent]);

  const handleChange = e => {
    setIsChecked(e.value.agreed);
    accepted(e.value.agreed);
  };

  return (
    <div>
      <StyledLabel htmlFor={id} checked={isChecked}>
        {Object.keys(checkContent).length === 0 ? (
          <Loading noPrices width="30rem" height="7.5rem" />
        ) : (
          <>
            <div>
              <AcknowledgementCheckbox
                itemKey={checkContent.key}
                key={id}
                name={checkContent.title}
                checked={isChecked}
                onChange={handleChange}
                dataTestId={`checkbox_${dataTestId}`}
              >
                {checkContent.short}
              </AcknowledgementCheckbox>
            </div>
          </>
        )}
      </StyledLabel>
    </div>
  );
};

const StyledLabel = styled.label`
  display: flex;
  position: relative;
  margin-top: 1rem;
  align-items: center;
  cursor: pointer;

  & > div:last-child {
    font-size: 1.2rem;
    line-height: 1.5rem;
    width: 90%;
    color: ${props =>
      props.error ? '#F74040' : props.checked ? '#00CD39' : '#525252'};

    & h1 {
      font-weight: bold;
      font-size: 1.5rem;
      color: ${props =>
        props.error ? '#F74040' : props.checked ? '#00CD39' : 'black'};
      margin-bottom: 0.5rem;
    }
  }

  & + label {
    margin-top: 1.5rem;
  }
`;

CardCheckBox.propTypes = {
  coverage: string.isRequired,
  accepted: func.isRequired,
  id: string.isRequired,
  dataTestId: string,
};

export default CardCheckBox;
