import React from 'react';
import { string } from 'prop-types';

const SharedObjects = ({ color }) => {
  return (
    <svg
      width="30"
      height="32"
      viewBox="0 0 30 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M24.3151 10.7864L22.2408 12.8112C22.1512 12.8987 22.0103 12.8987 21.9335 12.8112L20.9732 11.8738C20.9092 11.8113 20.7811 11.8488 20.7811 11.9488V22.2478C20.7811 22.3728 20.6787 22.4603 20.5635 22.4603H9.65438C9.52634 22.4603 9.43671 22.3603 9.43671 22.2478V11.9488C9.43671 11.8488 9.32147 11.7988 9.24465 11.8738L8.28434 12.8112C8.19471 12.8987 8.05387 12.8987 7.97704 12.8112L5.88998 10.7864C5.80035 10.6989 5.80035 10.5615 5.88998 10.4865L8.95015 7.49926C9.25745 7.19929 9.66718 7.0368 10.1025 7.0368H12.3688C12.7018 8.19919 13.8029 9.06161 15.0961 9.06161C16.3893 9.06161 17.4905 8.21169 17.8234 7.0368H20.1025C20.5379 7.0368 20.9476 7.19929 21.2549 7.49926L24.3151 10.4865C24.4047 10.574 24.4047 10.6989 24.3151 10.7864Z"
          fill={color}
        />
        <path
          d="M14.9936 0C6.73495 0 0 6.57436 0 14.6361C0 20.9355 4.11012 26.5099 10.2177 28.5222C10.5634 28.6347 10.9475 28.4472 11.0627 28.1098C11.178 27.7723 10.9859 27.3973 10.6402 27.2849C5.08323 25.46 1.34443 20.373 1.34443 14.6361C1.34443 7.28679 7.47759 1.29987 15.0064 1.29987C22.5352 1.29987 28.6684 7.28679 28.6684 14.6361C28.6684 18.0233 27.3624 21.2479 25.0064 23.7227C22.7785 26.06 19.7951 27.5348 16.5557 27.8848L17.2343 26.7599C17.4264 26.4474 17.3111 26.0475 16.991 25.86C16.6709 25.6725 16.2612 25.785 16.0691 26.0975L14.5711 28.5847C14.379 28.8972 14.4942 29.2972 14.8143 29.4846L17.3624 30.947C17.4648 31.0095 17.5672 31.0345 17.6825 31.0345C17.9129 31.047 18.1562 30.922 18.2843 30.7095C18.4763 30.3971 18.3611 29.9971 18.041 29.8096L16.9142 29.1597C20.3841 28.7347 23.5723 27.1349 25.9795 24.6101C28.5659 21.8979 30 18.3482 30 14.6361C30 6.57436 23.265 0 14.9936 0Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="30" height="31.0345" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

SharedObjects.propTypes = {
  color: string,
};

export default SharedObjects;
