import React, { useState, useContext, useEffect } from 'react';
import { shape, string, array, func } from 'prop-types';
import InputSelector from './ProFlowInputSelector';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Section } from 'wg-fe-ui';
// import InfoPopUp from './InfoPopUp';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';

const ProFlowQuestionsDriver = ({
  taggedQuestions,
  allQuestions,
  tag,
  questionErrors,
  focus,
  setFocus,
}) => {
  const [driverList, setDriverList] = useState([]);
  const { answers } = useContext(ProFlowStorageContext);
  const { i18n } = useTranslation();

  const fullWidthSpans = [
    '03a71f4d-d352-4098-90da-eaac1b23fbc9',
    '03a71f4d-d352-4098-90da-eaac1b23fbb0',
    'eef5c60f-f6a4-4eb2-b38b-cc39543d3d7d',
  ];
  const thirdWidthSpans = [];
  const halfSpanLonely = [];

  function getSpanLenght(id) {
    if (fullWidthSpans.includes(id) || halfSpanLonely.includes(id)) return 6;
    if (thirdWidthSpans.includes(id)) return 2;
    return 3;
  }

  function getElementwidth(id) {
    if (halfSpanLonely.includes(id)) return 'calc(50% - 10px);';
  }

  useEffect(() => {
    filterSteps();
  }, [answers]);

  function filterSteps() {
    const requiredAnswers = filterRequiredAnswers();
    const filteredDrivers = filterNumberOfDrivers(requiredAnswers);
    setDriverList(filteredDrivers);
  }

  function filterRequiredAnswers() {
    return taggedQuestions.filter(question => {
      const { required } = question;

      return showCriteria(required, answers);
    });
  }

  function showCriteria(required = [], _answers = []) {
    // show input if nothing is required
    if (required.length === 0) return true;
    const nulledRequires = required.filter(({ answer }) => answer === null);
    const generalRequires = required.filter(({ answer }) => answer !== null);

    const shouldShowQuestion =
      areAllNulledQuestionsAnswered(nulledRequires, answers) ||
      isOneOfQeneralQuestionsAnswered(generalRequires, answers);

    return shouldShowQuestion;
  }

  function areAllNulledQuestionsAnswered(required = [], _answers = []) {
    // if there are no nulled questions return true
    if (required.length === 0) return false;

    // return true if all nulled questions are not answered
    return required
      .map(({ id }) => allQuestions.some(({ id: _id }) => id !== _id))
      .includes(false);
  }

  function isOneOfQeneralQuestionsAnswered(required = [], _answers = []) {
    // if there are no questions return true
    if (required.length === 0) return false;
    // return true if id and answer matches
    return required.some(require =>
      _answers.some(
        ({ id, answer }) => require.id === id && answer === require.answer,
      ),
    );
  }

  function filterNumberOfDrivers(_taggedQuestions) {
    const driverIndex = new Set(_taggedQuestions.map(({ index }) => index));
    return [...driverIndex].map(_index =>
      _taggedQuestions.filter(({ index }) => index === _index),
    );
  }

  return (
    driverList?.length > 0 && (
      <>
        <Title data-test-id={`${tag}-title`}>
          {i18n.t(`Information from the ${tag}`)}
        </Title>
        {driverList.map((driver, i) => {
          return (
            <DriverBlock key={i}>
              <SubTitle>
                {i === 0
                  ? i18n.t('Main driver')
                  : `${i18n.t('Additional driver')} (${i})`}
              </SubTitle>
              <StyledSection background>
                {driver.map(
                  ({
                    id,
                    rules,
                    title,
                    error_messages,
                    insurance_type_tags,
                    field,
                    tag,
                  }) => {
                    return (
                      <StyledDiv
                        key={id}
                        span={getSpanLenght(id)}
                        width={getElementwidth(id)}
                      >
                        <InputSelector
                          key={id}
                          typeTags={insurance_type_tags}
                          rules={rules}
                          field={field}
                          questionErrors={questionErrors}
                          name={id}
                          errorMessages={error_messages}
                          title={title?.[i18n.language]}
                          tag={tag}
                          focus={focus}
                          setFocus={setFocus}
                        />
                      </StyledDiv>
                    );
                  },
                )}
              </StyledSection>
            </DriverBlock>
          );
        })}
      </>
    )
  );
};

const DriverBlock = styled.div`
  margin-bottom: 2rem;
`;

const StyledDiv = styled.div`
  margin-top: 2rem;
  grid-column: span ${({ span }) => span};
  width: ${({ width }) => width};
  h3 {
    color: #8990a3;
    font-weight: 600;
    letter-spacing: 0.01em;
    font-size: 14px;
  }
`;

const StyledSection = styled(Section.Content)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 36px;
`;

const Title = styled.h2`
  font-size: 2.1rem;
  color: #0e0e0e;
  font-weight: 700;
  margin: 1rem 0;
  margin-top: 3rem;
`;

const SubTitle = styled.h3`
  font-size: 1.6rem;
  color: #8990a3;
  font-weight: 500;
`;

ProFlowQuestionsDriver.propTypes = {
  taggedQuestions: shape().isRequired,
  allQuestions: shape().isRequired,
  tag: string,
  questionErrors: array,
  focus: string,
  setFocus: func,
};

export default ProFlowQuestionsDriver;
