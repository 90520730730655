import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import Layout from '../../DashboardLayout/views/Layout';
import DashboardToolbarBrokerHistory from '../../../components/DashboardToolbarBrokerHistory';
import { shape } from 'prop-types';
import DashboardBrokerHistoryAutomotiveCarInformation from '../../../components/DashboardBrokerHistoryAutomotiveCarInformation';
import DashboardBrokerHistoryAutomotiveCarOptions from '../../../components/DashboardBrokerHistoryAutomotiveCarOptions';
import DashboardBrokerHistoryAutomotiveDrivers from '../../../components/DashboardBrokerHistoryAutomotiveDrivers';
import DashboardBrokerHistoryAutomotiveCarCategory from '../../../components/DashboardBrokerHistoryAutomotiveCarCategory';
import DashboardBrokerHistoryInsuranceInformation from '../../../components/DashboardBrokerHistoryInsuranceInformation';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import {
  setTabItems,
  getHistory,
  setInsurTypes,
} from '../../../services/brokerhistoryService';
import { useTranslation } from 'react-i18next';
import { getNestedObject } from '../../../services/objectService';
import DashboardBrokerHistoryConversationInformation from '../../../components/DashboardBrokerHistoryConversationInformation';

const BrokerHistoryDetailAutomotive = ({ match, history }) => {
  const { setNavTabs } = useContext(TopNavigationItems);
  const [filteredInsureTypes, setFilteredInsureTypes] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [carInformation, setCarInformation] = useState({});
  const [carOptions, setCarOptions] = useState({});
  const [drivers, setDrivers] = useState({});
  const [insuranceInformation, setInsuranceInformation] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setInsurTypes(match.params.id, setSessionData).then(res => {
      setSessionData(res.sessionData);
      setFilteredInsureTypes(res.filteredData);
      const tmpItems = setTabItems(match.params.id, res.sessionData, t);
      setNavTabs(tmpItems);
    });
  }, []);

  useEffect(() => {
    const carExtra = getNestedObject(sessionData, ['car_extra']) || {};
    const car = getNestedObject(sessionData, ['car']) || {};
    setCarInformation({
      is_cabrio: carExtra.is_cabrio,
      is_jeep: carExtra.is_jeep,
      is_sportscar: carExtra.is_sportscar,
      carName: carExtra.name,
      catalogValue: carExtra.value,
      brand: carExtra.brand,
      version: carExtra.version,
      seats: carExtra.seats,
      carAge: carExtra.car_age,
      model: carExtra.model,
      category: carExtra.category,
      valueExtraOptions: carExtra.options_value,
      licensePlate: carExtra.number_plate,
      registrationFirst: carExtra.registration_first
        ? carExtra.registration_first
            .split('-')
            .reverse()
            .join('/')
        : carExtra.registration_first,
      chassisNumber: carExtra.chassis_number,
      registrationLast: carExtra.registration_last
        ? carExtra.registration_last
            .split('-')
            .reverse()
            .join('/')
        : carExtra.registration_last,
      fuel: carExtra.motor_type,
      cylinderCapacity: carExtra.cc,
      power: carExtra.kw,
      emission: carExtra.co2,
      annualDistance: car.km_per_year,
      carLoan: car.car_loan,
      usage: car.vehicle_usage,
      modelYear: carExtra.model_year,
    });
  }, [sessionData]);

  useEffect(() => {
    const carExtra = getNestedObject(sessionData, ['car_extra']) || {};

    setCarOptions({
      is_jeep: carExtra.is_jeep,
      is_cabrio: carExtra.is_cabrio,
      is_sportscar: carExtra.is_sportscar,
      automaticEmergencyBraking: carExtra.automatic_emergency_braking,
      adaptiveCruiseControl: carExtra.adaptive_cruise_control,
      laneSupport: carExtra.lane_support,
      blindSpotCheck: carExtra.blind_spot_check,
      attentionAssist: carExtra.attention_assist,
      ecall: carExtra.ecall,
      automaticParking: carExtra.automatic_parking,
      parking_aid: carExtra.parking_aid,
    });
  }, [sessionData]);

  useEffect(() => {
    const car = getNestedObject(sessionData, ['car']) || {};
    const accidents =
      getNestedObject(car, [
        'primary_driver',
        'accident_statement',
        'accidents',
      ]) || [];
    const accidentCount = accidents.length;
    const { first_name, last_name, birth } =
      getNestedObject(sessionData, ['personal']) || {};
    setDrivers({
      name: `${first_name} ${last_name}`,
      dateOfBirth: birth
        ? birth
            .split('/')
            .reverse()
            .join('/')
        : birth,
      licenseIssuesDate: car.drivers_license
        ? car.drivers_license
            .split('/')
            .reverse()
            .join('/')
        : car.drivers_license,
      claimLast5Years: accidentCount,
    });
  }, [sessionData]);

  useEffect(() => {
    const { already_insured_with } =
      getNestedObject(sessionData, ['car']) || {};

    const { day, month, year } =
      getNestedObject(sessionData, ['car', 'previous_insurance_start_date']) ||
      {};

    const date =
      (day || month || year) !== undefined
        ? `${day}/${month}/${year}`
        : undefined;

    setInsuranceInformation({
      insurer: already_insured_with,
      date,
    });
  }, [sessionData]);

  return (
    <StyledLayout>
      <DashboardToolbarBrokerHistory
        onClick={() =>
          getHistory(
            match.params.id,
            filteredInsureTypes,
            history,
            t,
            sessionData,
          )
        }
        dataTestId="dashboard_history_detail_calculate"
        btnText={t('History.goToMarket')}
      />
      <SectionWrapper>
        <DashboardBrokerHistoryConversationInformation
          sessionData={sessionData}
        />
        <DashboardBrokerHistoryAutomotiveCarInformation
          carInformation={carInformation}
        />
        <DashboardBrokerHistoryAutomotiveCarCategory carOptions={carOptions} />
        <DashboardBrokerHistoryAutomotiveCarOptions carOptions={carOptions} />
        <DashboardBrokerHistoryAutomotiveDrivers drivers={drivers} />
        <DashboardBrokerHistoryInsuranceInformation
          insurer={insuranceInformation.insurer}
          date={insuranceInformation.date}
        />
      </SectionWrapper>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

BrokerHistoryDetailAutomotive.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

export default withRouter(BrokerHistoryDetailAutomotive);
