/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { withRouter, useLocation } from 'react-router';
import { shape, bool } from 'prop-types';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import i18n from '../../../../../i18n';

import regex from '../../../../services/regexService';
// components
import Layout from '../../LayoutFlow/views/App';
import Button from '../../../../components/Button';
import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';
// stores
import {
  getNextPath,
  addChatboxQuestion,
  addChatboxAnswer,
  resetChatboxAnswers,
  addBrowserHistory,
  resetSkippedSteps,
  resetCoverages,
  resetChatboxQuestions,
  setSocialLogin,
  setFlowLogic,
} from '../../../../store/index';

import { setPersonalData, getPersonalData } from '../store/index';

import InputBox from '../../../../components/InputBox';
import PhoneInput from '../../../../components/PhoneInput';
import DateInput from '../../../../components/DateInput';

import { createAndSaveSessionObject } from '../../../../services/sessionStorageService';

import LouiseLeftSide from './LouiseLeftSide';

import { captchaAuth } from '../../../../services/apiRouterService';

import {
  validateCampaignLogin,
  validateSocialLogin,
} from '../../../Login/store';
// import { ThemeContext } from '../../../../services/context/ThemingContext';
import { getBrokerData } from '../../../../services/brokerDataService';
import LanguageSelector from '../../../../components/LanguageSelector';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

import { Alert } from 'wg-fe-ui';

import { differenceInCalendarDays } from 'date-fns';

import qs from 'query-string';
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

const Verify = ({ history, location, isSocialUser }) => {
  const [emailPending, setEmailPending] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const personalData = getPersonalData() || {};
  const urlParams = new URLSearchParams(useLocation().search);
  const { t } = useTranslation();
  const flowStore = useFlowStore();
  const btnText = t(`Verify.btnText`);
  let isValid = true;
  let dateRegex;
  let dateFormat;

  dateFormat = `DD-MM-YYYY`;
  dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  if (urlParams.has('lang') && isSocialUser) {
    const toSetLanguage = urlParams.get('lang');
    const currentLanguage = i18n.language;
    if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
    // history.push(location.pathname);
  }

  useEffect(() => {
    resetChatboxAnswers();
    resetChatboxQuestions();
  }, []);

  function computeAmountOfDaysLeft(date) {
    return differenceInCalendarDays(new Date(date), new Date());
  }

  useEffect(() => {
    const { canSell, campaign } = flowStore || {};

    if (campaign) {
      const { active, endDate } = campaign || {};

      if (
        !active ||
        !(computeAmountOfDaysLeft(endDate) > 7 || endDate === null)
      )
        history.push('/campaign-expired');
    }
    if (canSell) {
      setFlowLogic(canSell);
    }
  }, [flowStore]);

  async function setAssistantNameAndTheme() {
    const { broker } = (await getBrokerData()) || {};
    addChatboxQuestion(
      t(`Verify.question`, {
        assistantName: broker?.assistant_name,
      }),
    );
  }

  // Setting the broker_id here again, to trigger the rerender for the theme to work
  useEffect(() => {
    setAssistantNameAndTheme();
  }, [sessionStorage.getItem('broker_id')]);

  // Setting the initial broker_id here already before the render, so it can be used further on for Louise avatar rendering
  if (isSocialUser) {
    setSocialLogin(true);
    sessionStorage.setItem('broker_id', location.pathname.split('/')[3]);
  }

  // TODO: Finish correct validation and regex + date 18 - 75 year old
  let SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, t(`Verify.errors.short`))
      .max(50, t(`Verify.errors.long`))
      .matches(regex.firstName, t(`Verify.errors.name`))
      .required(t(`Verify.errors.required`)),
    lastName: Yup.string()
      .min(2, t(`Verify.errors.short`))
      .max(50, t(`Verify.errors.long`))
      .matches(regex.lastName, t(`Verify.errors.name`))
      .required(t(`Verify.errors.required`)),
    useremail: Yup.string()
      .required(t(`Verify.errors.required`))
      .min(1, t(`Verify.errors.short`))
      .max(200, t(`Verify.errors.long`))
      .matches(regex.emailRegex, t(`Verify.errors.email`))
      .test('useremail', t(`Verify.errors.email`), () => validEmail),
    birth: Yup.string()
      .required(t(`Verify.errors.invalid_date`))
      .matches(dateRegex, t(`Verify.errors.invalid_date`))
      .test('DOB', t(`Verify.errors.invalid_date`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`Verify.errors.invalid_date`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`Verify.errors.young`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 18,
      )
      .test(
        'DOB',
        t(`Verify.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 120,
      ),
    telephone: Yup.string()
      .test('Phone number', t(`Verify.errors.invalid`), value => {
        if (value) {
          const input = value.replace(/ /g, '');
          if (input.length >= 8) {
            try {
              const number = phoneUtil.parseAndKeepRawInput(input);
              if (phoneUtil.isValidNumber(number)) {
                return true;
              }
            } catch {
              return false;
            }
            return false;
          } else if (input.length <= 3) {
            return true;
          }
          return false;
        }
        return true;
      })
      .nullable(),
    captcha: Yup.string().test('Captcha', 'Captcha not valid', value => {
      if (isSocialUser) {
        if (!value) {
          return false;
        }
      }
      return true;
    }),
  });

  useEffect(() => {
    resetChatboxAnswers();
    addBrowserHistory(location.pathname);
    resetSkippedSteps();
    resetCoverages();
    createAndSaveSessionObject();
  }, []);

  const clickHandler = async values => {
    if (isSocialUser) {
      const [, status] = await captchaAuth({
        captcha: values['captcha'],
        email: values['useremail'],
        firstName: values['firstName'],
        lastName: values['lastName'],
        askBrokerId: sessionStorage.getItem('broker_id'),
      });
      if (status === 200) {
        if (flowStore.type === 'campaign') {
          const { campaignId } = qs.parse(history?.location?.search);
          validateCampaignLogin(
            sessionStorage.getItem('broker_id'),
            campaignId,
          ).then(() => {
            setFilledInData(values);
          });
        } else {
          validateSocialLogin(sessionStorage.getItem('broker_id')).then(() => {
            setFilledInData(values);
          });
        }
      }
    } else {
      setFilledInData(values);
    }
  };

  function setFilledInData(values) {
    const formattedDate = values['birth']
      .split('-')
      .reverse()
      .join('-');

    let formattedTel = values['telephone'].replace(/ /g, '');

    values['birth'] = formattedDate;
    if (formattedTel.length <= 3) {
      formattedTel = null;
    }
    values['telephone'] = formattedTel;
    delete values.captcha;
    setPersonalData(values);
    addChatboxAnswer(
      t(`Verify.interactiveMsg`, {
        firstName: values['firstName'],
        lastName: values['lastName'],
      }),
    );
    history.push(getNextPath());
  }

  const disableHandler = (
    errors,
    touched,
    isValid,
    values,
    setFieldTouched,
  ) => {
    if (Object.entries(errors).length === 0) {
      if (isValid && !(Object.entries(touched).length === 0)) {
        return false;
      } else if (
        !!personalData.first_name &&
        !!personalData.last_name &&
        !!personalData.birth &&
        !!personalData.email &&
        !!personalData.telephonenr
      ) {
        setFieldTouched('firstName', true);
        setFieldTouched('lastName', true);
        setFieldTouched('birth', true);
        setFieldTouched('useremail', true);
        setFieldTouched('telephone', true);
        return false;
      }
    }
    return true;
  };

  let birthDate = ``;

  if (personalData.birth) {
    birthDate = personalData.birth
      .split('/')
      .reverse()
      .join('-');
  }

  return (
    <Layout>
      <Container>
        {isSocialUser ? <LanguageSelector /> : null}
        <StyledLeftSideV2>
          <LouiseLeftSide question={t(`Verify.louiseText`)} />
        </StyledLeftSideV2>
        <RightSide>
          <Formik
            initialValues={{
              firstName: personalData.first_name,
              lastName: personalData.last_name,
              birth: birthDate,
              useremail: personalData.email,
              telephone: personalData.telephonenr
                ? personalData.telephonenr
                : ``,
            }}
            enableReinitialize
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
          >
            {({
              errors,
              touched,
              values,
              setFieldValue,
              setFieldTouched,
              handleBlur,
              setFieldError,
            }) => {
              const disabled = disableHandler(
                errors,
                touched,
                isValid,
                values,
                setFieldTouched,
              );
              return (
                <FormWrapper>
                  <InputWrapper>
                    <NameInputs>
                      <Firstname
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        type="text"
                        name="firstName"
                        dataTestId="underwriting_personal_firstname"
                      >
                        {t(`Verify.inputFields.0`)}
                      </Firstname>
                      <Lastname
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        type="text"
                        name="lastName"
                        dataTestId="underwriting_personal_lastname"
                      >
                        {t(`Verify.inputFields.1`)}
                      </Lastname>
                    </NameInputs>
                    <DateInput
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      type="text"
                      name="birth"
                      dataTestId="underwriting_personal_birth"
                    >
                      {t(`Verify.inputFields.2`)}
                    </DateInput>
                    <StyledInputBox
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      type="text"
                      setValidEmail={setValidEmail}
                      setPending={setEmailPending}
                      setFieldTouched={setFieldTouched}
                      pending={emailPending}
                      invalidError={t(`Verify.errors.email`)}
                      name="useremail"
                      dataTestId="underwriting_personal_email"
                    >
                      {t(`Verify.inputFields.3`)}
                    </StyledInputBox>
                    <PhoneInput
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      values={values}
                      name="telephone"
                      dataTestId="underwriting_personal_telephone"
                      handleBlur={handleBlur}
                    >
                      {t(`Verify.inputFields.4`)}
                    </PhoneInput>
                    <StyledAlert icon type="normal">
                      {t(`Verify.checkboxes.0`)}
                    </StyledAlert>
                  </InputWrapper>
                  <ButtonContainer>
                    {isSocialUser ? (
                      <ReCAPTCHA
                        sitekey="6LfXtssUAAAAAHPd30jLlGBYw2BjuyOu_QAqcRDq"
                        onChange={e => setFieldValue('captcha', e)}
                        hl={i18n.language}
                      />
                    ) : null}
                    <Button
                      dataTestId="underwriting_personal_submit"
                      disabled={disabled || emailPending}
                      type="submit"
                    >
                      {btnText}
                    </Button>
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </RightSide>
      </Container>
    </Layout>
  );
};

const StyledLeftSideV2 = styled(LeftSide)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  align-items: center;
  padding: 6rem 2rem 2rem;
`;

const StyledInputBox = styled(InputBox)`
  width: 100%;
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;

const ButtonContainer = styled.div`
  border-radius: 3rem;
  margin: 3rem 0 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 1;

  & button {
    margin: 0;
    margin-left: auto;
  }
`;

const FormWrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

const Firstname = styled(StyledInputBox)`
  width: 38%;
`;

const Lastname = styled(StyledInputBox)`
  width: 58%;
`;

const NameInputs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledAlert = styled(Alert)`
  margin-top: 1rem;
  text-align: justify;
  > div {
    font-size: 1.2rem;
  }
`;

Verify.defaultProps = {
  isSocialUser: false,
};

Verify.propTypes = {
  history: shape().isRequired,
  location: shape(),
  isSocialUser: bool,
};

export default withRouter(Verify);
