import React from 'react';

const Drone = () => (
  <svg viewBox="0 0 268.31 174.19">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'drone'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M208.62 171.63a7.68 7.68 0 01-7.68-7.68V137H178a7.68 7.68 0 010-15.36h30.63a7.68 7.68 0 017.68 7.68V164a7.68 7.68 0 01-7.69 7.63z"
        />
        <path
          className="prefix__cls-1"
          d="M223.48 174.19h-30.17a9.22 9.22 0 110-18.44h30.17a9.22 9.22 0 010 18.44zM60.28 171.63a7.68 7.68 0 01-7.69-7.63v-34.73a7.68 7.68 0 017.69-7.68H90.9a7.68 7.68 0 010 15.36H68v27a7.68 7.68 0 01-7.72 7.68z"
        />
        <path
          className="prefix__cls-1"
          d="M75.59 174.19H45.41a9.22 9.22 0 010-18.44h30.18a9.22 9.22 0 010 18.44zM224.13 0a7.68 7.68 0 00-7.68 7.68V65.3H52.38V7.68a7.68 7.68 0 00-15.38 0V73a7.68 7.68 0 007.68 7.68h179.45a7.68 7.68 0 007.68-7.68V7.68A7.68 7.68 0 00224.13 0zM187.15 92.44v47.95a5.12 5.12 0 01-5.12 5.12H87.81a5.12 5.12 0 01-5.12-5.12V92.44z"
        />
        <rect
          className="prefix__cls-1"
          x={29.48}
          y={42.04}
          width={29.11}
          height={50.22}
          rx={14.21}
          transform="rotate(180 44.035 67.15)"
        />
        <rect
          className="prefix__cls-1"
          x={209.72}
          y={42.04}
          width={29.11}
          height={50.22}
          rx={14.21}
          transform="rotate(180 224.275 67.15)"
        />
        <rect
          className="prefix__cls-1"
          y={9.31}
          width={88.07}
          height={16.39}
          rx={8}
        />
        <rect
          className="prefix__cls-1"
          x={180.24}
          y={9.31}
          width={88.07}
          height={16.39}
          rx={8}
        />
      </g>
    </g>
  </svg>
);

export default Drone;
