import React from 'react';
import { useParams } from 'react-router';

import CarDetail from './CRMRiskObjectDetailCar';
import ResidenceDetail from './CRMRiskObjectDetailResidence';

import TwoWheelerDetail from './CRMRiskObjectDetailTwoWheeler.js';
import TeacherDetail from './CRMRiskObjectDetailTeacher.js';
import LegalDetail from './CRMRiskObjectDetailLegal';
import FamilyDetail from './CRMRiskObjectDetailFamily';

const CRMRiskObjectTypes = () => {
  const { riskObjectType } = useParams();

  switch (riskObjectType) {
    case 'car':
    case 'van':
      return <CarDetail />;
    case 'residence':
      return <ResidenceDetail />;
    case 'heavy_scooter':
    case 'light_scooter':
    case 'two_wheeler':
    case 'motorcycle':
    case 'moped':
      return <TwoWheelerDetail />;
    case 'teacher':
      return <TeacherDetail />;
    case 'legal':
      return <LegalDetail />;
    case 'family':
      return <FamilyDetail />;
    default:
      return <div>not supported</div>;
  }
};
export default CRMRiskObjectTypes;
