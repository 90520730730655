import React from 'react';

const Pets = () => (
  <svg viewBox="0 0 247.27 178.81">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'petsmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M166.72 7.71l-1.38-1A32.41 32.41 0 00132 4.89 19.17 19.17 0 01123.22 7a19.17 19.17 0 01-8.76-2.12A32.41 32.41 0 0081.1 6.72a2.9 2.9 0 01-.29.19L59.37 109.52c3 5.84 5.92 11.69 8.22 17.41 8.76 21.63 14.74 45.69 29.65 51 4.72 1.69 15.44-1.67 21.29-3.77a11.47 11.47 0 018 .12c6.12 2.35 17.62 6.17 22.38 3.81 16.48-8.13 21.92-29.39 30.72-51.14 2.37-5.86 5.36-11.88 8.27-17.88zM96.83 83.34a8.6 8.6 0 118.6-8.6 8.61 8.61 0 01-8.6 8.6zm21.75 62.44v-17.46a17.81 17.81 0 01-12.71-17.06 3.43 3.43 0 013.46-3.45h28.72a3.48 3.48 0 013.46 3.48 17.84 17.84 0 01-12.82 17.06v17.43a5.06 5.06 0 01-5.06 5.06 5.06 5.06 0 01-5.05-5.06zm31.86-62.44a8.6 8.6 0 118.6-8.6 8.61 8.61 0 01-8.6 8.6zM208.9 106.51l37.38-47a4.55 4.55 0 00-.52-6.22l-58.1-52.11a4.56 4.56 0 00-7.5 4.32l20.72 99.11a4.56 4.56 0 008.02 1.9zM38.37 106.51L1 59.55a4.55 4.55 0 01.52-6.22L59.61 1.18A4.55 4.55 0 0167.1 5.5l-20.72 99.11a4.55 4.55 0 01-8.01 1.9z"
        />
      </g>
    </g>
  </svg>
);

export default Pets;
