import React from 'react';
import { shape } from 'prop-types';

const BrokerBackground = ({ colors }) => (
  <svg
    width="153"
    height="87"
    viewBox="0 0 153 87"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M83.6002 85.7197H116.872"
      stroke={colors.brand.lighter}
      strokeWidth="2.19859"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.404 58.1091C30.7609 58.1091 26.1989 62.6895 26.1989 68.3142C26.1989 73.9573 30.7792 78.5193 36.404 78.5193C42.0287 78.5193 46.6091 73.9389 46.6091 68.3142C46.6274 62.6895 42.047 58.1091 36.404 58.1091ZM36.404 72.2167C34.2603 72.2167 32.5198 70.4762 32.5198 68.3326C32.5198 66.1889 34.2603 64.4484 36.404 64.4484C38.5476 64.4484 40.2881 66.1889 40.2881 68.3326C40.2881 70.4762 38.5476 72.2167 36.404 72.2167Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M114.216 58.1091C108.572 58.1091 104.01 62.6895 104.01 68.3142C104.01 73.9573 108.591 78.5193 114.216 78.5193C119.859 78.5193 124.421 73.9389 124.421 68.3142C124.421 62.6895 119.859 58.1091 114.216 58.1091ZM114.216 72.2167C112.072 72.2167 110.331 70.4762 110.331 68.3326C110.331 66.1889 112.072 64.4484 114.216 64.4484C116.359 64.4484 118.1 66.1889 118.1 68.3326C118.1 70.4762 116.359 72.2167 114.216 72.2167Z"
      fill={colors.brand.secondary}
    />
    <path
      d="M128.213 69.4868C127.609 69.4868 127.114 68.9921 127.114 68.3875C127.114 61.2787 121.324 55.4891 114.216 55.4891C107.107 55.4891 101.317 61.2787 101.317 68.3875C101.317 68.9921 100.822 69.4868 100.218 69.4868C99.6133 69.4868 99.1186 68.9921 99.1186 68.3875C99.1186 60.0695 105.898 53.2905 114.216 53.2905C122.534 53.2905 129.312 60.0695 129.312 68.3875C129.312 68.9921 128.818 69.4868 128.213 69.4868Z"
      fill={colors.brand.primary}
    />
    <path
      d="M50.4016 69.4868C49.7969 69.4868 49.3023 68.9921 49.3023 68.3875C49.3023 61.2787 43.5127 55.4891 36.4039 55.4891C29.2951 55.4891 23.5055 61.2604 23.5055 68.3875C23.5055 68.9921 23.0108 69.4868 22.4062 69.4868C21.8016 69.4868 21.3069 68.9921 21.3069 68.3875C21.3069 60.0695 28.0859 53.2905 36.4039 53.2905C44.7219 53.2905 51.5009 60.0695 51.5009 68.3875C51.5009 68.9921 51.0062 69.4868 50.4016 69.4868Z"
      fill={colors.brand.primary}
    />
    <path
      d="M57.3455 2.998L56.4477 5.98441L37.4666 68.5342C37.32 69.0105 36.8803 69.322 36.4223 69.322C36.3123 69.322 36.2024 69.3037 36.0925 69.267C35.5062 69.0838 35.1764 68.4792 35.3596 67.8929L54.1575 5.98441L55.2568 2.37506C55.44 1.78877 56.0446 1.45898 56.6309 1.6422C57.1989 1.80709 57.5287 2.43003 57.3455 2.998Z"
      fill={colors.brand.primary}
    />
    <path
      d="M115.077 68.2044C115.077 68.809 114.582 69.3037 113.977 69.3037H58.9028C58.4631 69.3037 58.0783 69.0472 57.8951 68.6442L44.5753 38.5052C44.3372 37.9556 44.5753 37.296 45.1433 37.0578C45.6929 36.8196 46.3525 37.0578 46.5907 37.6258L59.6173 67.1235H113.977C114.582 67.1052 115.077 67.5998 115.077 68.2044Z"
      fill={colors.brand.primary}
    />
    <path
      d="M1.61127 79.3806H150.932"
      stroke={colors.brand.lighter}
      strokeWidth="2.19859"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.2"
      d="M57.3454 2.998L56.4477 5.98441H54.1575L55.2568 2.37506C55.44 1.78877 56.0446 1.45898 56.6309 1.6422C57.1988 1.80709 57.5286 2.43003 57.3454 2.998Z"
      fill={colors.black}
    />
    <path
      d="M56.3011 4.5188H41.8271C40.8194 4.5188 39.9949 3.69433 39.9949 2.68665C39.9949 1.67896 40.8194 0.854492 41.8271 0.854492H56.3011C57.3088 0.854492 58.1333 1.67896 58.1333 2.68665C58.1333 3.69433 57.3088 4.5188 56.3011 4.5188Z"
      fill={colors.brand.light}
    />
    <path
      opacity="0.2"
      d="M65.8283 67.105H61.0647V69.3036H65.8283V67.105Z"
      fill={colors.black}
    />
    <path
      d="M115.37 68.2044C115.37 68.6075 115.315 68.9922 115.205 69.377C115.003 70.1098 114.619 70.7694 114.087 71.3007C113.299 72.0886 112.2 72.5832 111.009 72.5832H67.8437C65.8466 72.5832 64.1611 71.2274 63.648 69.3953C63.5381 69.0289 63.4832 68.6258 63.4832 68.2227C63.4832 67.0135 63.9778 65.9325 64.7657 65.1447C65.5535 64.3569 66.6345 63.8622 67.8437 63.8622H110.991C113.409 63.8439 115.37 65.8043 115.37 68.2044Z"
      fill={colors.brand.light}
    />
  </svg>
);

BrokerBackground.propTypes = {
  colors: shape().isRequired,
};

export default BrokerBackground;
