import React from 'react';
import { string } from 'prop-types';

const Engine = ({ color }) => {
  return (
    <svg
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3952 0H6.17941C5.75809 0 5.47721 0.357143 5.47721 0.714286C5.47721 1.14286 5.82831 1.42857 6.17941 1.42857H8.42647V2.78571H6.17941C5.75809 2.78571 5.47721 3.14286 5.47721 3.5V4.71429H3.37059C2.94926 4.71429 2.66838 5.07143 2.66838 5.42857V9.21428H1.40441V5.5C1.40441 5.07143 1.05331 4.78571 0.702206 4.78571C0.280882 4.78571 0 5.14286 0 5.5V14.6429C0 15.0714 0.351103 15.3571 0.702206 15.3571C1.12353 15.3571 1.40441 15 1.40441 14.6429V10.7143H2.66838V14.5714C2.66838 15 3.01948 15.2857 3.37059 15.2857H8.98823L10.9544 17.7143C11.0949 17.8571 11.3055 18 11.5162 18H18.1871C18.6085 18 18.8893 17.6429 18.8893 17.2857V14.4286H19.6618V15.8571C19.6618 16.2857 20.0129 16.5714 20.364 16.5714H22.1897C22.4706 16.5714 22.7515 16.3571 22.8919 16.0714C25.3496 9.64286 22.9621 5.64286 22.8919 5.5C22.7515 5.28571 22.5408 5.14286 22.3301 5.14286H20.364C19.9426 5.14286 19.6618 5.5 19.6618 5.85714V7.14286H18.8893L14.8868 2.85714H12.2184V1.42857H14.3952C14.8165 1.42857 15.0974 1.07143 15.0974 0.714286C15.0974 0.357143 14.8165 0 14.3952 0Z"
        fill={color}
      />
    </svg>
  );
};

Engine.propTypes = {
  color: string,
};

export default Engine;
