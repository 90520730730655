import React, { useEffect, useContext } from 'react';
import { validateSocialLogin } from '../store/index';
import { shape } from 'prop-types';
import { withRouter } from 'react-router';
import { googleAuth } from '../../../services/apiRouterService';
import { ThemeContext } from '../../../services/context/ThemingContext';

const paramsToObject = entries => {
  let result = {};

  for (let entry of entries) {
    const [key, value] = entry;
    result[key] = value;
  }
  return result;
};

const GoogleCallBack = ({ history }) => {
  const urlParams = new URLSearchParams(window.location.search.substring(1));
  const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
  const { setTheme } = useContext(ThemeContext);

  const params = paramsToObject(entries);

  useEffect(() => {
    sendToAuth();
  }, []);

  const sendToAuth = async () => {
    const [resp, status] = await googleAuth(
      params.code,
      'social_login',
      sessionStorage.getItem('broker_id'),
    );
    if (status !== 200) {
      console.error(resp);
      history.push(`broker/${sessionStorage.getItem('broker_id')}?popup=error`);
      return;
    } else {
      validateSocialLogin(sessionStorage.getItem('broker_id'), setTheme)
        .then(() => {
          history.push('/session/user');
        })
        .catch(err => {
          console.error(err);
          history.push(
            `broker/${sessionStorage.getItem('broker_id')}?popup=error`,
          );
        });
    }
  };

  return <div />;
};

GoogleCallBack.propTypes = {
  history: shape().isRequired,
};

export default withRouter(GoogleCallBack);
