/* stylelint-disable value-keyword-case, declaration-no-important */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import i18next from 'i18next';
import regex from '../../../services/regexService';
import LoadingButton from '../../../components/LoadingButton';
import TerminationGeneratorInputField from '../../../components/TerminationGeneratorInputField';
import Button from '../../../components/Button';
import DateInputComponent from '../../../components/DateInputComponent';
import { getBrokerData } from '../../../services/brokerDataService';
import { getInsurances } from '../../../services/insurancesService';
import { getBrokerDistribution } from '../../../services/brokerDataService';
import { getNestedObject } from '../../../services/objectService';
import { getTerminationLetterLinkV2 } from '../../../services/docgenService';
import { parseDateObjToIsoFormat } from '../../../services/dateService';

const ToolkitTerminationGenerator = () => {
  // ===================================== \\
  //        TODO: Refactor this view       \\
  // ===================================== \\

  const { t } = useTranslation();
  // const content = t('ToolkitInfo', { returnObjects: true });
  const [sendStep, setSendStep] = useState('initial');
  const [distribution, setDistribution] = useState({});
  const [documentPayload, setDocumentPayload] = useState();
  const [options, setOptions] = useState([
    { value: 'loading', label: 'Loading ...' },
  ]);
  const [broker, setBroker] = useState({});

  useEffect(() => {
    initializeBrokerData();
  }, []);

  async function initializeBrokerData() {
    const brokerData = await getBrokerData();
    const distributionData = await getBrokerDistribution();
    setDistribution(distributionData);
    setBroker(brokerData);
  }

  useEffect(() => {
    if (sendStep !== 'pending' || documentPayload === undefined) {
      // Don't allow to submit when not clicked
      return;
    }
    downloadDocument(documentPayload);
  }, [documentPayload]);

  async function downloadDocument(payload) {
    const [downloadLink, fileName] = await getTerminationLetterLinkV2(payload);
    if (downloadLink !== null && fileName !== null) {
      console.log(downloadLink);
      console.log(fileName);
      const downloadButton = document.createElement('a');
      downloadButton.href = downloadLink;
      downloadButton.download = fileName;
      setSendStep('success');
      downloadButton.click();
    } else {
      setSendStep('error');
    }
  }

  const onClickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    distribution.logo = (getNestedObject(distribution, ['links']) || []).filter(
      item => item.rel === 'logo',
    )[0].href;

    const {
      street_termination,
      zipcode_termination,
      city_termination,
      housenr_termination,
      first_name_termination,
      last_name_termination,
      policy_number_termination,
      termination_date_termination,
      insurer_termination,
      prod_nr,
      notice_type,
      new_insurer_termination,
      insurance_type,
    } = values;
    const address = {
      street: street_termination,
      zipcode: zipcode_termination,
      city: city_termination,
      housenr: housenr_termination.toLowerCase(),
      country_code: 'BE',
    };
    const payload = {
      broker: broker.broker,
      theme: broker.theme,
      distribution,
      prospect: {
        first_name: first_name_termination,
        last_name: last_name_termination,
        address,
      },
      policy_nr: policy_number_termination,
      main_expiry_date: parseDateObjToIsoFormat({
        day: parseInt(termination_date_termination.split('-')[0]),
        month: parseInt(termination_date_termination.split('-')[1]),
        year: parseInt(termination_date_termination.split('-')[2]),
      }),
      language: i18next.language.toUpperCase(),
      previous_insurance_company_key: insurer_termination.value,
      insurance_company_key:
        notice_type.value !== 'MANDATE'
          ? notice_type.value === 'CONTRACT_TERMINATION'
            ? null
            : new_insurer_termination.value
          : insurer_termination.value,
      prod_nr,
      insurance_type: insurance_type.value,
      notice_type:
        notice_type.value === 'MANDATE'
          ? 'INSURANCE_CHANGED'
          : notice_type.value,
    };

    setSendStep('pending');
    setDocumentPayload(payload);
  };

  let dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)((20)[2-9][0-9])$/;

  const SignupSchema = Yup.object().shape({
    first_name_termination: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    last_name_termination: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    insurer_termination: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .nullable(),
    new_insurer_termination: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .nullable(),
    insurance_type: Yup.string().required(t(`AdresInput.errors.required`)),
    notice_type: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .nullable(),
    prod_nr: Yup.string().required(t(`AdresInput.errors.required`)),
    termination_date_termination: Yup.string()
      .required(t(`Checkout.contract.errors.required`))
      .matches(dateRegex, t(`Checkout.contract.errors.invalid_date`))
      .test('notDash', value => {
        if (value !== '-') {
          return true;
        } else {
          return t(`Checkout.contract.errors.invalid_date`);
        }
      }),
    policy_number_termination: Yup.string().required(
      t(`AdresInput.errors.required`),
    ),
    street_termination: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(150, t(`AdresInput.errors.long`))
      .matches(regex.street, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    housenr_termination: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .matches(regex.houseNr, t(`AdresInput.errors.invalid`)),
    zipcode_termination: Yup.number()
      .min(1000, t(`AdresInput.errors.short`))
      .max(9999, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    city_termination: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(100, t(`AdresInput.errors.long`))
      .matches(regex.city, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
  });

  const disableHandler = (errors, touched, values) => {
    console.log(values);
    if (
      values?.notice_type?.value === 'MANDATE' &&
      (!touched.prod_nr || values?.prod_nr === '')
    ) {
      return true;
    }
    if (
      !['MANDATE', 'CONTRACT_TERMINATION'].includes(
        values?.notice_type?.value,
      ) &&
      (!touched.new_insurer_termination ||
        values?.new_insurer_termination === '')
    ) {
      return true;
    }

    if (values?.notice_type?.value !== 'MANDATE' && errors && errors?.prod_nr) {
      delete touched?.prod_nr;
      delete errors?.prod_nr;
    }
    if (
      ['MANDATE', 'CONTRACT_TERMINATION'].includes(
        values?.notice_type?.value,
      ) &&
      errors &&
      errors?.new_insurer_termination
    ) {
      delete touched?.new_insurer_termination;
      delete errors?.new_insurer_termination;
    }

    if (Object.entries(errors).length === 0) {
      if (!(Object.entries(touched).length === 0)) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    switch (sendStep) {
      case 'initial':
        break;
      case 'pending':
        break;
      case 'success':
        break;
      case 'error':
        break;
      default:
        break;
    }
  }, [sendStep]);

  useEffect(() => {
    getInsurances()
      .then(res => {
        const _options = [];
        Object.keys(res).forEach(key => {
          _options.push({ value: key, label: res[key] });
        });
        setOptions(_options);
      })
      .catch(err => {
        setOptions([{ value: `notfound`, label: `Fetch failed` }]);
        console.error(err);
      });
  }, []);

  const insuranceTypeOptions = t(`risk_objects`, { returnObjects: true });

  const reasonTypes = [
    { value: 'INSURANCE_CHANGED', label: t(`INSURANCE_CHANGED`) },
    { value: 'MANDATE', label: t(`MANDATE`) },
    { value: 'CONTRACT_TERMINATION', label: t(`CONTRACT_TERMINATION`) },
    { value: 'PREMIUM_INCREASE', label: t(`PREMIUM_INCREASE`) },
    { value: 'RISK_OBJECT_CHANGED', label: t(`RISK_OBJECT_CHANGED`) },
  ];

  return (
    <>
      <TitleContainer>
        <Title>{t('ToolkitNavigation.terminationGenerator.title')}</Title>
      </TitleContainer>
      <SectionContainer data-test-id="dashboard_toolkit_documents_termination_generator_section_generate">
        <Section>
          <Formik
            initialValues={{
              first_name_termination: ``,
              insurance_type: ``,
              last_name_termination: ``,
              termination_date_termination: ``,
              insurer_termination: ``,
              notice_type: ``,
              prod_nr: ``,
              new_insurer_termination: ``,
              policy_number_termination: ``,
              street_termination: ``,
              housenr_termination: ``,
              zipcode_termination: ``,
              city_termination: ``,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              onClickHandler(values);
            }}
          >
            {({ errors, touched, setFieldValue, values, setFieldTouched }) => {
              const disabled = disableHandler(errors, touched, values);
              return (
                <FormWrapper>
                  <InputWrapper>
                    <StyledLabel>
                      <p>{t(`Insurance type`)}</p>
                      <Input
                        onChange={_selected => {
                          setFieldValue('insurance_type', _selected);
                          setFieldTouched('insurance_type', true);
                        }}
                        options={insuranceTypeOptions}
                        onBlurResetsInput={false}
                        data-test-id="toolkit_documents_required_information_insurance_type"
                        isClearable
                        error={
                          errors['insurance_type'] && touched['insurance_type']
                        }
                        valid={
                          !errors['insurance_type'] && touched['insurance_type']
                        }
                        menuPlacement="bottom"
                        noOptionsMessage={() => 'Geen opties'}
                        classNamePrefix="Select"
                        value={values['insurance_type_required_infomation']}
                        placeholder={t('Insurance type')}
                      />
                    </StyledLabel>
                    <StyledLabel>
                      <p>{t(`Reason for notice`)}</p>
                      <Input
                        onChange={_selected => {
                          setFieldValue('notice_type', _selected);
                          setFieldTouched('notice_type', true);
                        }}
                        name="notice_type"
                        options={reasonTypes}
                        onBlurResetsInput={false}
                        data-test-id="toolkit_documents_termination_generator_notice_type"
                        isClearable
                        error={errors['notice_type'] && touched['notice_type']}
                        valid={!errors['notice_type'] && touched['notice_type']}
                        menuPlacement="bottom"
                        noOptionsMessage={() => 'Geen opties'}
                        classNamePrefix="Select"
                        value={values['notice_type']}
                        placeholder=""
                      />
                    </StyledLabel>
                    <StyledLabel>
                      <p>{t(`Insurance company`)}</p>
                      <Input
                        onChange={_selected => {
                          setFieldValue('insurer_termination', _selected);
                          setFieldTouched('insurer_termination', true);
                        }}
                        name="insurer_termination"
                        options={options.filter(
                          option =>
                            option.value !==
                            values?.new_insurer_termination?.value,
                        )}
                        onBlurResetsInput={false}
                        data-test-id="toolkit_documents_termination_generator_insurer"
                        isClearable
                        error={
                          errors['insurer_termination'] &&
                          touched['insurer_termination']
                        }
                        valid={
                          !errors['insurer_termination'] &&
                          touched['insurer_termination']
                        }
                        menuPlacement="bottom"
                        noOptionsMessage={() => 'Geen opties'}
                        classNamePrefix="Select"
                        value={values['insurer_termination']}
                        placeholder=""
                      />
                    </StyledLabel>
                    <TerminationGeneratorInputField
                      error={
                        errors['policy_number_termination'] &&
                        touched['policy_number_termination']
                      }
                      valid={
                        !errors['policy_number_termination'] &&
                        touched['policy_number_termination']
                      }
                      type="text"
                      name="policy_number_termination"
                      data-test-id="toolkit_documents_termination_generator_policy_number"
                    >
                      {t(`Policy number`)}
                    </TerminationGeneratorInputField>
                    <StyledLabel>
                      <p>{t(`Termination date`)}</p>
                      <StyledDateInputComponent
                        classPrefix="termination_date_termination"
                        dataTestId="toolkit_documents_termination_generator_termination_date"
                        errors={errors}
                        touched={touched}
                        noYear={false}
                        value={values['termination_date_termination']}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        type="text"
                        disabled={false}
                        name="termination_date_termination"
                        id="termination_date_termination"
                      />
                    </StyledLabel>
                    {!['MANDATE', 'CONTRACT_TERMINATION'].includes(
                      values?.notice_type?.value,
                    ) ? (
                      <StyledLabel>
                        <p>{t(`New insurer`)}</p>
                        <Input
                          onChange={_selected => {
                            setFieldValue('new_insurer_termination', _selected);
                            setFieldTouched('new_insurer_termination', true);
                          }}
                          name="new_insurer_termination"
                          options={options.filter(
                            option =>
                              option.value !==
                              values?.insurer_termination?.value,
                          )}
                          onBlurResetsInput={false}
                          data-test-id="toolkit_documents_termination_generator_new_insurer"
                          isClearable
                          error={
                            errors['new_insurer_termination'] &&
                            touched['new_insurer_termination']
                          }
                          valid={
                            !errors['new_insurer_termination'] &&
                            touched['new_insurer_termination']
                          }
                          menuPlacement="bottom"
                          noOptionsMessage={() => 'Geen opties'}
                          classNamePrefix="Select"
                          value={values['new_insurer_termination']}
                          placeholder=""
                        />
                      </StyledLabel>
                    ) : (
                      ''
                    )}
                    {values?.notice_type?.value === 'MANDATE' ? (
                      <TerminationGeneratorInputField
                        error={errors['prod_nr'] && touched['prod_nr']}
                        valid={!errors['prod_nr'] && touched['prod_nr']}
                        type="text"
                        name="prod_nr"
                        data-test-id="toolkit_documents_termination_generator_prod_nr"
                      >
                        {t(`Producer number`)}
                      </TerminationGeneratorInputField>
                    ) : (
                      ''
                    )}
                    <Line />
                    <TerminationGeneratorInputField
                      error={
                        errors['first_name_termination'] &&
                        touched['first_name_termination']
                      }
                      valid={
                        !errors['first_name_termination'] &&
                        touched['first_name_termination']
                      }
                      type="text"
                      name="first_name_termination"
                      data-test-id="toolkit_documents_termination_generator_first_name"
                      id="first_name_termination"
                    >
                      {t(`First name`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      error={
                        errors['last_name_termination'] &&
                        touched['last_name_termination']
                      }
                      valid={
                        !errors['last_name_termination'] &&
                        touched['last_name_termination']
                      }
                      type="text"
                      name="last_name_termination"
                      data-test-id="toolkit_documents_termination_generator_last_name"
                    >
                      {t(`Last name`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      error={
                        errors['street_termination'] &&
                        touched['street_termination']
                      }
                      valid={
                        !errors['street_termination'] &&
                        touched['street_termination']
                      }
                      type="text"
                      name="street_termination"
                      data-test-id="toolkit_documents_termination_generator_street"
                    >
                      {t(`Street`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      error={
                        errors['housenr_termination'] &&
                        touched['housenr_termination']
                      }
                      valid={
                        !errors['housenr_termination'] &&
                        touched['housenr_termination']
                      }
                      type="text"
                      name="housenr_termination"
                      data-test-id="toolkit_documents_termination_generator_housenr"
                    >
                      {t(`Nr/Bus`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      error={
                        errors['zipcode_termination'] &&
                        touched['zipcode_termination']
                      }
                      valid={
                        !errors['zipcode_termination'] &&
                        touched['zipcode_termination']
                      }
                      type="number"
                      name="zipcode_termination"
                      data-test-id="toolkit_documents_termination_generator_zipcode"
                    >
                      {t(`Zipcode`)}
                    </TerminationGeneratorInputField>
                    <TerminationGeneratorInputField
                      error={
                        errors['city_termination'] &&
                        touched['city_termination']
                      }
                      valid={
                        !errors['city_termination'] &&
                        touched['city_termination']
                      }
                      type="text"
                      name="city_termination"
                      data-test-id="toolkit_documents_termination_generator_city"
                    >
                      {t(`City`)}
                    </TerminationGeneratorInputField>
                  </InputWrapper>
                  <ButtonContainer>
                    {sendStep !== 'pending' ? (
                      <Button
                        dataTestId="toolkit_documents_termination_generator_submit"
                        type="submit"
                        disabled={disabled}
                      >
                        {t(`Generate`)}
                      </Button>
                    ) : (
                      <LoadingButton>{t(`Generating`)}</LoadingButton>
                    )}
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </Section>
      </SectionContainer>
    </>
  );
};

const SectionContainer = styled.div`
  padding-bottom: 8.5rem;
  position: relative;
`;

const StyledDateInputComponent = styled(DateInputComponent)`
  height: 3.5rem;
  max-width: 40rem;
  & label {
    margin-top: 0;
  }

  & input {
    height: 3.5rem;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  width: 47%;
  line-height: 2.1rem;
  font-size: 1.5rem;
  color: #a29c95;
  margin-bottom: 2.5rem;
  font-weight: normal;
  max-height: 6.1rem !important;

  & > p {
    display: flex;
    align-items: baseline;
    transform-origin: 0 0;
    transition: all 0.2s ease;
    margin-bottom: 0.5rem;
  }
`;

const Input = styled(Select)`
  width: 100%;
  max-width: 40rem;
  height: 3.5rem;
  & > input {
    background: white;
    /* Outline */
    box-shadow: none;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .Select {
    &__control {
      height: 100%;
      min-height: 3.5rem;
      border-radius: 3px;
      border-color: ${props =>
        props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};

      :hover {
        border-color: ${props =>
          props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
      }

      &--is-focused {
        border-color: ${props =>
          props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
        box-shadow: none;

        :hover {
          border-color: ${props =>
            props.error ? 'red' : props.valid ? '#49c562' : '#ccc'};
        }
      }
    }

    &__value-container {
      padding: 0 1rem;
      font-family: 2rem;
      font-size: 1.5rem;
      /* text-transform: uppercase; */

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  flex: 0 0 100%;
  max-width: 90rem;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
  justify-content: space-between;

  & > input {
    margin-top: 0;
  }

  & > label {
    max-width: 40rem;
  }
`;

const ButtonContainer = styled.div`
  border-radius: 3rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 3rem;
  right: 0;
  padding: 0;
  flex-direction: column;

  & button {
    z-index: 1;
  }

  & > button,
  & > div {
    margin: 0;

    @media screen and (max-width: 1025px) {
      transform: scale(1);
    }
  }
`;

const Line = styled.span`
  width: 100%;
  height: 0.1rem;
  background-color: #ddd;
  margin-bottom: 2.4rem;
  margin-top: 1rem;
`;

const FormWrapper = styled(Form)`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  text-align: left;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 2rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

const Section = styled.div`
  /* Outline */
  border: 1px solid #ccc;
  box-sizing: border-box;
  /* Button */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4.69px;
  border-radius: 5px;
  margin: 2.35rem 0;
  padding: 3.5rem;
  padding-bottom: 1.5rem;
  background-color: white;
`;

export default ToolkitTerminationGenerator;
