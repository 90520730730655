import React from 'react';
import { string } from 'prop-types';

const FamilyIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      fill="none"
      viewBox="0 0 36 35"
    >
      <path
        fill={color}
        d="M24 24h7a5 5 0 015 5v6H19v-6a5 5 0 015-5zM27.5 22a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM21.18 21.52A8 8 0 0016 29v6H0V24a8 8 0 018-8h10a8.14 8.14 0 012.11.28A6.15 6.15 0 0020 17.5a7.38 7.38 0 001.18 4.02zM13 14a7 7 0 100-14 7 7 0 000 14z"
      ></path>
    </svg>
  );
};

FamilyIcon.defaultProps = {
  color: '#ccc',
};

FamilyIcon.propTypes = {
  color: string,
};

export default FamilyIcon;
