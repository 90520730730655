/* LIBRARIES & SERVICES
-------------------------------------------------- */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatISODate } from '../services/dateService';
import cogoToast from 'cogo-toast';
import {
  getOfferAsCustomerById,
  createOfferRevisionAsCustomer,
  calculateQuoteAsCustomer,
  createCarRevisionAsCustomer,
  getAffinityByCompanyKey,
} from '../services/apiRouterService';
import moment from 'moment';

import queryString from 'query-string';

import { ZendeskAPI } from 'react-zendesk';

/* COMPONENTS
-------------------------------------------------- */
import {
  H1,
  H2,
  H3,
  H4,
  Body,
  Modal,
  ActionButton,
  TertiaryButton,
  Alert,
  LoadingSpinner,
  CheckBox_v2 as CheckBox,
  TextInput,
  SearchSelectInput,
  Section_v2 as Section,
  MobileMenu,
} from 'wg-fe-ui';

import CRMProspectClientData from '../components/CRMProspectClientData';
import CarInformation from '../components/CRMRiskObjectDetailCarInformation';
import CarDrivers from '../components/CRMRiskObjectDetailCarDrivers';
import TwoWheelerInformation from '../components/CRMRiskObjectDetailTwoWheelerInformation';
import PropertyInformation from '../components/CRMRiskObjectDetailResidenceInformation';
import PropertyRooms from '../components/CRMRiskObjectDetailResidenceRooms';
import PropertyAnnexes from '../components/CRMRiskObjectDetailResidenceAnnexes';
import FamilyInformation from '../components/CRMRiskObjectDetailFamily';
import TeacherInformation from '../components/CRMRiskObjectDetailTeacher';

const CustomerPage = ({ match, location }) => {
  /* INITS
  -------------------------------------------------- */
  const distId = '12aece4f-7630-453b-8543-c66daf76cb5a';
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const urlParams = new URLSearchParams(useLocation().search);

  if (urlParams.has('lang')) {
    const toSetLanguage = urlParams.get('lang');
    const currentLanguage = i18n.language;
    if (toSetLanguage !== currentLanguage) i18n.changeLanguage(toSetLanguage);
  }

  /* STATE
  -------------------------------------------------- */
  const [isLoading, setIsLoading] = useState(true);
  const [pricingModal, setPricingModal] = useState(false);
  const [data, setData] = useState();
  const [originalData, setOriginalData] = useState();
  const [intervalOptions, setIntervalOptions] = useState([
    { value: 'ANNUAL', label: t('ANNUAL') },
    { value: 'HALF_ANNUAL', label: t('HALF_ANNUAL') },
    { value: 'QUARTERLY', label: t('QUARTERLY') },
  ]);
  const [
    guaranteesAsTheCustomerSeesThem,
    setGuaranteesAsTheCustomerSeesThem,
  ] = useState([]);
  const [wereRiskObjectsEdited, setWereRiskObjectsEdited] = useState(false);
  const [wereGuaranteesEdited, setwereGuaranteesEdited] = useState(false);
  const [wasCarEdited, setWasCarEdited] = useState(false);
  const [wasFamilyEdited, setWasFamilyEdited] = useState(false);
  const [wasResidenceEdited, setWasResidenceEdited] = useState(false);
  const [wasTwoWheelerEdited, setWasTwoWheelerEdited] = useState(false);
  const [wasTeacherEdited, setWasTeacherEdited] = useState(false);
  const [guaranteesNetPremium, setGuaranteesNetPremium] = useState();
  const [guaranteesCosts, setGuaranteesCosts] = useState();
  const [guaranteesTotals, setGuaranteesTotals] = useState();
  const [commencementDateError, setCommencementDateError] = useState();
  const [bonusMalusError, setBonusMalusError] = useState();
  const [token, setToken] = useState();
  const [interval, setInterval] = useState('');
  const [insuranceLogo, setInsuranceLogo] = useState('');
  const [newRevisionIds, setNewRevisionIds] = useState({ cars: [] });
  const [riskWasNotAccepted, setRiskWasNotAccepted] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isBeingEdited, setIsBeingEdited] = useState(false);

  /* LIFE CYCLES
  -------------------------------------------------- */
  useEffect(() => {
    if (queryString.parse(location.search).token) {
      setToken(queryString.parse(location.search).token);
    }

    getOffer(
      match.params.offerId,
      match.params.revisionId,
      queryString.parse(location.search).token,
    ).then(resp => {
      setData(resp);
      setOriginalData(resp);
    });

    return () => {
      setIsLoading(true);
      setWereRiskObjectsEdited(false);
    };
  }, [match.params.offerId, match.params.revisionId]);

  useEffect(() => {
    console.log(data);

    let totalNetPremium = 0;
    let totalCosts = 0;
    let totalPremium = 0;

    data?.guarantees.map(item => {
      if (item.taken) {
        totalNetPremium += item.net_premium;
        totalCosts += item.total_premium - item.net_premium;
        totalPremium += item.total_premium;
      }
    });

    if (data?.insurance?.affinity === 'doceo') {
      setIntervalOptions([
        { value: 'ANNUAL', label: t('ANNUAL') },
        { value: 'HALF_ANNUAL', label: t('HALF_ANNUAL') },
        { value: 'QUARTERLY', label: t('QUARTERLY') },
        {
          value: 'MONTHLY',
          label: t('MONTHLY'),
        },
      ]);
    } else {
      setInterval(findIntervalInEnums(data?.payment_spread));
    }

    setGuaranteesNetPremium(totalNetPremium);
    setGuaranteesCosts(totalCosts);
    setGuaranteesTotals(totalPremium);
    setGuaranteesAsTheCustomerSeesThem(
      parseGuaranteesForCustomers(data?.guarantees),
    );
  }, [data]);

  useEffect(() => {
    if (data?.payment_spread) {
      setInterval(findIntervalInEnums(data?.payment_spread));
    }
  }, [intervalOptions]);

  useEffect(() => {
    /** DISABLES THE ZENDESK CHAT ON THE CUSTOMER PAGE. */
    ZendeskAPI('webWidget', 'hide');
  }, []);

  /* HANDLERS & FUNCTIONS
  -------------------------------------------------- */
  async function getOffer(_offerId, _revisionId, _token) {
    let [response, status] = await getOfferAsCustomerById(
      _offerId,
      _revisionId,
      _token,
    );

    if (status !== 200) {
      return console.error(response);
    } else {
      if (response.insurance.company === 'callant') {
        const [logoResp, logoStatus] = await getAffinityByCompanyKey(
          response.insurance.company,
        );

        if (logoStatus !== 200) {
          return console.error(logoResp);
        } else {
          getLogoFromAffinity(logoResp.items, response.insurance.affinity);
        }
      }

      setIsLoading(false);
      return response;
    }
  }

  const getLogoFromAffinity = (object, value) => {
    object.forEach(affinity => {
      const { affinity_info } = affinity;
      if (
        Object.keys(affinity_info).some(key => affinity_info[key] === value)
      ) {
        setInsuranceLogo(affinity_info.logo);
      }
    });
  };

  const findIntervalInEnums = inter => {
    const result = intervalOptions.filter(obj => {
      return obj.value === inter;
    });

    return result;
  };

  const handleIntervalChange = e => {
    const pickedInterval = findIntervalInEnums(e.value);
    setInterval(pickedInterval);

    let newData = Object.assign({}, data);
    newData.payment_spread = pickedInterval[0].value;
    setData(newData);
    setwereGuaranteesEdited(true);
  };

  const prospectEditSave = prospectValues => {
    let newData = Object.assign({}, data);
    newData.prospect = prospectValues;
    setData(newData);
  };

  const carRiskObjectSave = (carValues, index) => {
    setWereRiskObjectsEdited(true);
    setWasCarEdited(true);
    setRiskWasNotAccepted(false);

    carValues.vat_regime = carValues.vat_regime / 100;

    let newData = Object.assign({}, data);
    newData.risk_objects.cars[index] = carValues;
    setData(newData);
  };

  const carDriverSave = (driverValues, carIndex) => {
    setWereRiskObjectsEdited(true);
    setWasCarEdited(true);
    setRiskWasNotAccepted(false);

    let newData = Object.assign({}, data);
    newData.risk_objects.cars[carIndex].drivers[0] = driverValues;
    setData(newData);
  };

  const propertyRiskObjectSave = (propertyValues, index) => {
    setWereRiskObjectsEdited(true);
    setWasResidenceEdited(true);
    setRiskWasNotAccepted(false);

    let newData = Object.assign({}, data);
    newData.risk_objects.residences[index] = propertyValues;
    setData(newData);
  };

  const familyRiskObjectSave = (familyValues, index) => {
    setWereRiskObjectsEdited(true);
    setWasFamilyEdited(true);
    setRiskWasNotAccepted(false);

    let newData = Object.assign({}, data);
    newData.risk_objects.families[index] = familyValues;
    setData(newData);
  };

  const twoWheelerRiskObjectSave = (twoWheelerValues, index) => {
    setWereRiskObjectsEdited(true);
    setWasTwoWheelerEdited(true);
    setRiskWasNotAccepted(false);

    let newData = Object.assign({}, data);
    newData.risk_objects.two_wheelers[index] = twoWheelerValues;
    setData(newData);
  };

  const teacherRiskObjectSave = (teacherValues, index) => {
    setWereRiskObjectsEdited(true);
    setWasTeacherEdited(true);
    setRiskWasNotAccepted(false);

    let newData = Object.assign({}, data);
    newData.risk_objects.teachers[index] = teacherValues;
    setData(newData);
  };

  const handleGuaranteeChange = (itemId, event) => {
    const elementPos = data?.guarantees
      .map(x => {
        return x.id;
      })
      .indexOf(itemId);

    let newData = Object.assign({}, data);
    newData.guarantees[elementPos].taken = event.value;
    setData(newData);
    setwereGuaranteesEdited(true);
  };

  const handleAcknowledgementChange = (itemId, event) => {
    const elementPos = data?.acknowledgements
      .map(x => {
        return x.id;
      })
      .indexOf(itemId);

    let newData = Object.assign({}, data);
    newData.acknowledgements[elementPos].agreed = event.value;
    setData(newData);
    setWereRiskObjectsEdited(true);
  };

  const handleChangeCommencementDate = e => {
    if (e.value.length === 10) {
      const newCommencementDate = moment(e.value, 'DD/MM/YYYY').toDate();
      const today = new Date();

      if (newCommencementDate <= today) {
        setCommencementDateError(
          t(
            `The commencement date can not be earlier or equal to today's date`,
          ),
        );
      } else {
        setCommencementDateError(null);
        let newData = Object.assign({}, data);
        newData.commencement_date = moment(e.value, 'DD/MM/YYYY').toDate();
        setData(newData);
        setwereGuaranteesEdited(true);
      }
    } else {
      setCommencementDateError(
        t('Please format your date as follows DD/MM/YYYY'),
      );
    }
  };

  const handleChangeBonusMalus = e => {
    const newBonusMalus = parseInt(e.value);

    if (e.value === '') {
      setBonusMalusError(t('The bonus malus can not be empty'));
      setwereGuaranteesEdited(false);
    } else {
      if (isNaN(newBonusMalus)) {
        setBonusMalusError(t('A bonus malus can not contain any letters'));
        setwereGuaranteesEdited(false);
      } else {
        if (data?.risk_objects?.cars[0].used_for === 'PRIVATE') {
          if (newBonusMalus < -2 || newBonusMalus > 11) {
            setBonusMalusError(
              t('A bonus malus can not be lower than -2 or higher than 11'),
            );
            setwereGuaranteesEdited(false);
          } else {
            setBonusMalusError(null);
            let newData = Object.assign({}, data);
            newData.premium.bonus_malus = newBonusMalus;
            setData(newData);
            setwereGuaranteesEdited(true);
            setRiskWasNotAccepted(false);
          }
        } else {
          if (newBonusMalus < -2 || newBonusMalus > 14) {
            setBonusMalusError(
              t('A bonus malus can not be lower than -2 or higher than 14'),
            );
            setwereGuaranteesEdited(false);
          } else {
            setBonusMalusError(null);
            let newData = Object.assign({}, data);
            newData.premium.bonus_malus = newBonusMalus;
            setData(newData);
            setwereGuaranteesEdited(true);
            setRiskWasNotAccepted(false);
          }
        }
      }
    }
  };

  const handleEditModeChange = active => {
    console.log('CustomerPage -> active', active);
    setIsBeingEdited(active);
  };

  const handleAcceptRecalculation = () => {
    if (riskWasNotAccepted) return;
    updateRiskObjects();
    setIsLoading(true);

    setTimeout(() => {
      handleCreateOfferRevision();
    }, 1000);
  };

  function calculateAge(isoStamp) {
    if (!isoStamp) return null;
    var ageDifMs = Date.now() - new Date(isoStamp);
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  const isPersonAccepted = (prospect, risk_objects) => {
    // CAR CHECKS
    if (risk_objects?.cars.length > 0) {
      const primaryDriver = risk_objects?.cars?.[0]?.drivers?.[0];
      const car = risk_objects?.cars?.[0];
      const age = calculateAge(primaryDriver?.birth);
      const driverLicenseAge = calculateAge(primaryDriver?.issue_license_date);
      const bm = data?.premium?.bonus_malus;
      const claimsInFault = primaryDriver?.number_of_accidents_last_five_years;
      // Checks
      if (calculateAge(prospect?.birth) && calculateAge(prospect?.birth) < 18) {
        setRejectionReason(`Age < 18`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (claimsInFault > 2) {
        setRejectionReason(t(`No of claims last 5 years > 2`));
        setRiskWasNotAccepted(true);
        return false;
      } else if (data?.risk_objects?.cars?.[0]?.kw > 200) {
        setRejectionReason(t(`Car KW > 200`));
        setRiskWasNotAccepted(true);
        return false;
      } else if (primaryDriver?.driver_license_type === 'PROVISIONAL') {
        setRejectionReason(t(`Main driver has provisional license`));
        setRiskWasNotAccepted(true);
        return false;
      } else if (
        age &&
        age < 25 &&
        car?.kw > 85 &&
        data?.insurance?.affinity !== 'honda'
      ) {
        setRejectionReason(`Age < 25 & Car KW > 85`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (
        age &&
        age < 25 &&
        car?.kw > 90 &&
        data?.insurance?.affinity === 'honda'
      ) {
        setRejectionReason(`Age < 25 & Car KW > 90`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (age && age < 25 && claimsInFault > 0) {
        setRejectionReason(`Age < 25 & No of claims last 5 years > 0`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (
        age &&
        age < 30 &&
        driverLicenseAge < 1 &&
        data?.insurance?.affinity !== 'honda' &&
        car?.kw > 85
      ) {
        setRejectionReason(
          `Age < 30 & Less than one year driver license & Car KW > 85`,
        );
        setRiskWasNotAccepted(true);
        return false;
      } else if (
        age &&
        age < 30 &&
        driverLicenseAge < 1 &&
        data?.insurance?.affinity === 'honda' &&
        car?.kw > 90
      ) {
        setRejectionReason(
          `Age < 30 & Less than one year driver license & Car KW > 90`,
        );
        setRiskWasNotAccepted(true);
        return false;
      } else if (age && age < 30 && driverLicenseAge < 1 && claimsInFault > 0) {
        setRejectionReason(
          `Age < 30 & Less than one year driver license & No. of claims last year > 0`,
        );
        setRiskWasNotAccepted(true);
        return false;
      } else if (bm > 11) {
        setRejectionReason(`Bonus malus > 11`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (age && age >= 65 && bm > 3) {
        setRejectionReason(`Age > 65 & Bonus malus > 3`);
        setRiskWasNotAccepted(true);
        return false;
      }
    }
    // TWO WHEELER CHECKS
    if (risk_objects?.two_wheelers.length > 0) {
      const primaryDriver = risk_objects?.two_wheelers?.[0]?.drivers?.[0];
      const age = calculateAge(primaryDriver?.birth);
      const driverLicenseAge = calculateAge(primaryDriver?.issue_license_date);
      const claimsInFault = primaryDriver?.number_of_accidents_last_five_years;
      // Checks
      if (calculateAge(prospect?.birth) && calculateAge(prospect?.birth) < 18) {
        setRejectionReason(`Age < 18`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (claimsInFault > 2) {
        setRejectionReason(t(`No. of claims last 5 years > 2`));
        setRiskWasNotAccepted(true);
        return false;
      } else if (primaryDriver?.driver_license_type === 'PROVISIONAL') {
        setRejectionReason(t(`Main driver has provisional license`));
        setRiskWasNotAccepted(true);
        return false;
      } else if (age && age < 25 && claimsInFault > 0) {
        setRejectionReason(`Age < 25 & No. of claims last 5 years > 0`);
        setRiskWasNotAccepted(true);
        return false;
      } else if (age && age < 30 && driverLicenseAge < 1 && claimsInFault > 0) {
        setRejectionReason(
          `Age < 30 & Less than one year driver license & No. of claims last year > 0`,
        );
        setRiskWasNotAccepted(true);
        return false;
      }
    }
    return true;
  };

  /**
   * Async function to create new offer revisions and prepare the correct payload.
   * @param {boolean} active Passing false to the function will make it so that it does not actually create a new offer revision, rather it will log the payload to the console.
   */
  async function handleCreateOfferRevision(active = true) {
    let guarantees = [];
    let acknowledgements = [];
    let cars = [];
    let families = [];
    let residences = [];
    let twoWheelers = [];
    let teachers = [];

    data?.guarantees.map(item => {
      if (item?.taken === true) {
        guarantees.push(item.name);
      }
    });

    data?.acknowledgements.map(item => {
      const ack = {
        text: item.text,
        agreed: item.agreed,
        key: item.key,
        id: item.id,
      };

      acknowledgements.push(ack);
    });

    if (wasCarEdited) {
      cars = newRevisionIds?.cars;
    } else {
      data?.risk_objects?.cars.map(item => {
        const revId = item.risk_object_revision_id;
        cars.push(revId);
      });
    }

    data?.risk_objects?.families.map(item => {
      const revId = item.risk_object_revision_id;
      families.push(revId);
    });

    data?.risk_objects?.residences.map(item => {
      const revId = item.risk_object_revision_id;
      residences.push(revId);
    });

    data?.risk_objects?.two_wheelers.map(item => {
      const revId = item.risk_object_revision_id;
      twoWheelers.push(revId);
    });

    data?.risk_objects?.teachers.map(item => {
      const revId = item.risk_object_revision_id;
      teachers.push(revId);
    });

    const payload = {
      quote_id: data?.quote_id,
      guarantees: guarantees,
      acknowledgements: acknowledgements,
      payment_spread: data?.payment_spread,
      end_date: data?.end_date,
      risk_object_revisions: {
        cars: cars,
        families: families,
        residences: residences,
        two_wheelers: twoWheelers,
        teachers: teachers,
      },
      commencement_date: data?.commencement_date,
      send_mail_to_prospect: true,
      created_at: new Date().toISOString(),
    };

    if (active) {
      const [resp, status] = await createOfferRevisionAsCustomer(
        match.params.offerId,
        payload,
        token,
      );

      if (status === 201) {
        setIsLoading(true);
        cogoToast.success(
          t('The offer adjustments have been successfully saved'),
        );
        history.push(
          `/client-overview/${match.params.offerId}/${resp.revision}?token=${
            queryString.parse(location.search).token
          }`,
        );
      } else if (status === 422) {
        cogoToast.error(
          t(
            'There was an issue with one of your form inputs. Please check your form and try again.',
          ),
        );
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
        return console.error('ERROR', resp);
      } else {
        cogoToast.error(t('Something went wrong!'));
        console.error(
          `RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`,
        );
      }
    } else {
      cogoToast.success('Should create new revision');
      console.log('handleCreateOfferRevision -> payload', payload);
    }
  }

  const parseGuaranteesForCustomers = guaranteesToParse => {
    if (!guaranteesToParse) return [];
    // If assistance_road, you have to add the premium to the civil_liability and skip that guarantee
    const assistance_road_arr = guaranteesToParse.filter(guar => {
      return guar.name === 'assistance_road';
    });
    // If accessories, you have to add the premium to the mini_omnium or omnium and skip that guarantee
    const accessories_arr = guaranteesToParse.filter(guar => {
      return guar.name === 'accessories';
    });
    // If driver and honda car, then you have to add that one to the civil_liability as well, but not skip the guarantee, put all on 0 and should show 'included'
    const driver_arr = guaranteesToParse.filter(guar => {
      return guar.name === 'driver' && data?.insurance?.affinity === 'honda';
    });
    // Loop over all and adjust where necessary
    const parsedGuarantees = [];
    guaranteesToParse.forEach(originalGuar => {
      const guar = { ...originalGuar };
      if (guar.name === 'civil_liability') {
        if (assistance_road_arr?.length > 0) {
          const guarToParse = assistance_road_arr?.[0];
          guar.net_premium += guarToParse.net_premium;
          guar.taxes_in_euro += guarToParse.taxes_in_euro;
          guar.total_premium += guarToParse.total_premium;
        }
        if (driver_arr?.length > 0) {
          const guarToParse = driver_arr?.[0];
          guar.net_premium += guarToParse.net_premium;
          guar.taxes_in_euro += guarToParse.taxes_in_euro;
          guar.total_premium += guarToParse.total_premium;
        }
      }
      if (['mini_omnium', 'omnium'].includes(guar.name)) {
        if (accessories_arr?.length > 0) {
          const guarToParse = accessories_arr?.[0];
          guar.net_premium += guarToParse.net_premium;
          guar.taxes_in_euro += guarToParse.taxes_in_euro;
          guar.total_premium += guarToParse.total_premium;
        }
      }
      if (!['assistance_road', 'accessories'].includes(guar.name)) {
        if (guar.name === 'driver' && data?.insurance?.affinity === 'honda') {
          guar.net_premium = 0;
          guar.taxes_in_euro = 0;
          guar.total_premium = 0;
          parsedGuarantees.push(guar);
        } else {
          parsedGuarantees.push(guar);
        }
      }
    });
    return parsedGuarantees;
  };

  async function handleRecalc() {
    let cars = JSON.parse(JSON.stringify(data?.risk_objects?.cars));
    let families = JSON.parse(JSON.stringify(data?.risk_objects?.families));
    let residences = JSON.parse(JSON.stringify(data?.risk_objects?.residences));
    let twoWheelers = JSON.parse(
      JSON.stringify(data?.risk_objects?.two_wheelers),
    );
    let teachers = JSON.parse(JSON.stringify(data?.risk_objects?.teachers));

    cars.forEach(item => {
      let carItem = item;
      carItem.id = carItem.risk_object_id;
      delete carItem.created_at;
      delete carItem.risk_object_revision_id;
      delete carItem.risk_object_id;
      delete carItem.previous_insurances;
    });

    families.forEach(item => {
      let familyItem = item;
      familyItem.id = familyItem.risk_object_id;
      // TODO: Hardcoded for now, remove below when fix in broker-api
      familyItem.birth = '1980-06-15T15:58:08.820072+00:00';
      delete familyItem.created_at;
      delete familyItem.risk_object_revision_id;
      delete familyItem.risk_object_id;
      delete familyItem.previous_insurances;
    });

    residences.forEach(item => {
      let residenceItem = item;
      residenceItem.id = residenceItem.risk_object_id;
      delete residenceItem.created_at;
      delete residenceItem.risk_object_revision_id;
      delete residenceItem.risk_object_id;
      delete residenceItem.previous_insurances;
    });

    twoWheelers.forEach(item => {
      let twoWheelerItem = item;
      twoWheelerItem.id = twoWheelerItem.risk_object_id;
      delete twoWheelerItem.created_at;
      delete twoWheelerItem.risk_object_revision_id;
      delete twoWheelerItem.risk_object_id;
      delete twoWheelerItem.previous_insurances;
    });

    teachers.forEach(item => {
      let teacherItem = item;
      teacherItem.id = teacherItem.risk_object_id;
      delete teacherItem.created_at;
      delete teacherItem.risk_object_revision_id;
      delete teacherItem.risk_object_id;
      delete teacherItem.previous_insurances;
    });

    const payload = {
      risk_objects: {
        cars: cars,
        families: families,
        residences: residences,
        two_wheelers: twoWheelers,
        teachers: teachers,
      },
      quote_specifications: {
        bonus_malus:
          data?.premium?.bonus_malus === null ? 0 : data?.premium?.bonus_malus,
      },
    };

    // Check wheter or not the person should be accepted, if not, show an error message and return
    if (!isPersonAccepted(data?.prospect, payload.risk_objects)) {
      setRiskWasNotAccepted(true);
      return;
    }

    const [resp, status] = await calculateQuoteAsCustomer(
      data?.id,
      data?.revision,
      payload,
      token,
    );

    if (status === 200) {
      let newData = Object.assign({}, data);
      let newGuarantees = [];
      console.log(resp);

      // What guarantees were already taken previously?
      const takenBefore = data.guarantees.reduce(function(map, obj) {
        if (obj.taken) {
          map[obj.name] = obj;
        }
        return map;
      }, {});

      // Set the taken parameter based on mandatory or the taken before check
      resp.guarantees.forEach(item => {
        if (item.mandatory || item.name in takenBefore) {
          item.taken = true;
        }

        newGuarantees.push(item);
      });

      newData.guarantees = newGuarantees;
      newData.quote_id = resp.id;
      setData(newData);
      setPricingModal(!pricingModal);
      setwereGuaranteesEdited(false);
      setWereRiskObjectsEdited(false);
    } else if (status === 422) {
      cogoToast.error(
        t(
          'There was an issue with one of your form inputs. Please check your form and try again.',
        ),
      );
    } else if (resp.msg != null) {
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
      return console.error('ERROR', resp);
    } else {
      cogoToast.error(t('Something went wrong!'));
      console.log(`RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`);
    }
  }

  async function createCarRevision(riskObjectId, payload) {
    let [response, status] = await createCarRevisionAsCustomer(
      riskObjectId,
      payload,
      token,
    );

    if (status !== 201) {
      return console.error(response);
    } else {
      return response;
    }
  }

  async function updateRiskObjects() {
    if (wasCarEdited) {
      data?.risk_objects?.cars.map((item, index) => {
        const payload = {
          motor_type: item?.motor_type,
          registration_first: item?.registration_first,
          drivers: item?.drivers,
          number_plate: item?.number_plate,
          kw: item?.kw,
          cc: item?.cc,
          model: item?.model,
          model_year: item?.model_year,
          version: item?.version,
          brand: item?.brand,
          name: item?.name,
          chassis_number: item?.chassis_number,
          license_plate_type: item?.license_plate_type,
          country_of_registration: item?.country_of_registration,
          is_second_hand: item?.is_second_hand,
          age: item?.age,
          registration_last: item?.registration_last,
          invoice_value: item?.invoice_value,
          options_value: item?.options_value,
          value: item?.value,
          drive_assistance_system: item?.drive_assistance_system,
          category: item?.category,
          average_km_per_year: item?.average_km_per_year,
          used_for: item?.used_for,
          febiacid: item?.febiacid,
          co2: item?.co2,
          is_sportscar: item?.is_sportscar,
          is_jeep: item?.is_jeep,
          is_cabrio: item?.is_cabrio,
          is_coupe: item?.is_coupe,
          seats: item?.seats,
          vat_regime: item?.vat_regime,
          vehicle_type: item?.vehicle_type,
          kwh: item?.kwh,
        };

        createCarRevision(item?.risk_object_id, payload).then(resp => {
          let newRevs = Object.assign({}, newRevisionIds);
          newRevs.cars[index] = resp?.risk_object_revision_id;
          setNewRevisionIds(newRevs);
        });
      });
    }

    console.log(
      wasFamilyEdited,
      wasResidenceEdited,
      wasTwoWheelerEdited,
      wasTeacherEdited,
    );
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <>
      <TopBar>
        <TopBarContainer>
          <img src={insuranceLogo} alt={data?.insurance?.affinity} />
        </TopBarContainer>
      </TopBar>

      <MainPage>
        <Modal showModal={pricingModal} setShowModal={setPricingModal} large>
          <DesktopH1>{t('Your new offer')}</DesktopH1>

          <PricingModalContent>
            <DesktopAlert icon type="normal">
              {t(
                'Below you can find your new prices after recalculation Please check if everything is correct and save your new offer or go back to edit more data',
              )}
            </DesktopAlert>

            <Spacer medium desktop />

            <H3>{t('Your guarantees')}</H3>

            <Divider />

            <StyledTable>
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <Body bold light>
                      {t('Guarantee')}
                    </Body>
                  </th>
                  <th>
                    <Body bold light>
                      {t('Net premium')}
                    </Body>
                  </th>
                  <th>
                    <Body bold light>
                      {t('Taxes and fees')}
                    </Body>
                  </th>
                  <th>
                    <Body bold light>
                      {t('Total premium')}
                    </Body>
                  </th>
                </tr>
              </thead>

              <tbody>
                {guaranteesAsTheCustomerSeesThem.map(item => {
                  return (
                    <tr key={item?.id}>
                      <td>
                        <CheckBox
                          checked={item?.taken}
                          disabled={item?.mandatory}
                          onChange={e => {
                            handleGuaranteeChange(item?.id, e);
                          }}
                        ></CheckBox>
                      </td>

                      <td>
                        <TableGuaranteeWrapper>
                          <Body>
                            {item.name === 'family'
                              ? t('family_guarantee')
                              : t(item?.name)}
                          </Body>
                        </TableGuaranteeWrapper>
                      </td>

                      <td>
                        {item?.net_premium ? (
                          <Body>
                            {(
                              Math.round((item?.net_premium + 0.00001) * 100) /
                              100
                            ).toLocaleString('pt-BR')}{' '}
                            EUR
                          </Body>
                        ) : (
                          <Body>{t(`Included`)}</Body>
                        )}
                      </td>

                      <td>
                        {item?.taxes_in_euro ? (
                          <Body>
                            {(
                              Math.round(
                                (item?.total_premium -
                                  item?.net_premium +
                                  0.00001) *
                                  100,
                              ) / 100
                            ).toLocaleString('pt-BR')}{' '}
                            EUR
                          </Body>
                        ) : (
                          <Body>{t('Included')}</Body>
                        )}
                      </td>

                      <td>
                        {item?.total_premium ? (
                          <Body>
                            {(
                              Math.round(
                                (item?.total_premium + 0.00001) * 100,
                              ) / 100
                            ).toLocaleString('pt-BR')}{' '}
                            EUR
                          </Body>
                        ) : (
                          <Body>{t(`Included`)}</Body>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <tfoot>
                <tr>
                  <td></td>
                  <td></td>

                  <td>
                    <Body bold>
                      {(
                        Math.round((guaranteesNetPremium + 0.00001) * 100) / 100
                      ).toLocaleString('pt-BR')}{' '}
                      EUR
                    </Body>
                  </td>

                  <td>
                    <Body bold>
                      {(
                        Math.round((guaranteesCosts + 0.00001) * 100) / 100
                      ).toLocaleString('pt-BR')}{' '}
                      EUR
                    </Body>
                  </td>

                  <td>
                    <Body bold>
                      {(
                        Math.round((guaranteesTotals + 0.00001) * 100) / 100
                      ).toLocaleString('pt-BR')}{' '}
                      EUR
                    </Body>
                  </td>
                </tr>
              </tfoot>
            </StyledTable>

            <ModalTable>
              {guaranteesAsTheCustomerSeesThem.map(item => (
                <div key={item?.id}>
                  <CheckBox
                    checked={item?.taken}
                    disabled={item?.mandatory}
                    onChange={e => {
                      handleGuaranteeChange(item?.id, e);
                    }}
                  ></CheckBox>

                  <MobileTableContent>
                    <H4>
                      {item.name === 'family'
                        ? t('family_guarantee')
                        : t(item?.name)}
                    </H4>

                    {/* <Spacer small />
                    <H4>{t('Net premium')}</H4>
                    <Body light>
                      {(
                        Math.round((item?.net_premium + 0.00001) * 100) / 100
                      ).toLocaleString('pt-BR')}{' '}
                      EUR
                    </Body>

                    <Spacer small />
                    <H4>{t('Taxes and fees')}</H4>
                    <Body light>
                      {(
                        Math.round(
                          (item?.total_premium - item?.net_premium + 0.00001) *
                            100,
                        ) / 100
                      ).toLocaleString('pt-BR')}{' '}
                      EUR
                    </Body> */}

                    <Spacer small />
                    <H4>{t('Total premium')}</H4>
                    {item?.total_premium ? (
                      <Body light bold>
                        {(
                          Math.round((item?.total_premium + 0.00001) * 100) /
                          100
                        ).toLocaleString('pt-BR')}{' '}
                        EUR
                      </Body>
                    ) : (
                      <Body light bold>
                        {t(`Included`)}
                      </Body>
                    )}
                  </MobileTableContent>
                </div>
              ))}
            </ModalTable>

            <Divider />

            <PricingModalTotals>
              <H2>{t('Your new total price')}</H2>
              <NewPrice>
                {(
                  Math.round((guaranteesTotals + 0.00001) * 100) / 100
                ).toLocaleString('pt-BR')}{' '}
                EUR <span>/ {t('ANNUAL')}</span>
              </NewPrice>
            </PricingModalTotals>
          </PricingModalContent>

          <PricingModalFooter>
            <ActionButton
              fullwidth
              onClick={() => {
                handleAcceptRecalculation();
                setPricingModal(!pricingModal);
              }}
            >
              {t('Accept your new offer')}
            </ActionButton>

            <Spacer medium />

            <TertiaryButton
              label={t('Go back')}
              onClick={() => {
                setPricingModal(!pricingModal);
              }}
            />
          </PricingModalFooter>
        </Modal>

        <MainContent>
          <HeaderSection>
            <H1>{t('Your tarification')}</H1>
            <H2>{t('CustomerPage.Intro')}</H2>
          </HeaderSection>

          {riskWasNotAccepted ? (
            <DesktopAlert icon type="error">
              {t('No calculation was possible')}: {rejectionReason}
            </DesktopAlert>
          ) : (
            ''
          )}

          <InfoSection>
            <H3>{t('Offer data')}</H3>
            <Body light>
              {t('Check and edit any information regarding your offer below')}.
            </Body>

            <Section>
              <PricingModalContent>
                <PricingModalHeader>
                  <CommencementDateInput
                    error={commencementDateError}
                    name="commencement_date"
                    onChange={e => handleChangeCommencementDate(e)}
                    value={formatISODate(data?.commencement_date)}
                    placeholder="DD/MM/YYYY"
                  >
                    {t('Commencement date')}
                  </CommencementDateInput>

                  <SearchSelectInput
                    initial={interval}
                    name="payment_spread"
                    onSelected={handleIntervalChange}
                    options={intervalOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Payment interval')}
                  </SearchSelectInput>

                  {data?.risk_objects?.cars.length > 0 ||
                  data?.premium?.bonus_malus != null ? (
                    <TextInput
                      error={bonusMalusError}
                      name="bonus_malus"
                      onChange={e => handleChangeBonusMalus(e)}
                      value={data?.premium?.bonus_malus}
                    >
                      {t('Bonus malus')}
                    </TextInput>
                  ) : null}
                </PricingModalHeader>

                <Divider />

                <H3>{t('Your guarantees')}</H3>

                <Divider />

                <StyledTable>
                  <thead>
                    <tr>
                      <th></th>
                      <th>
                        <Body bold light>
                          {t('Guarantee')}
                        </Body>
                      </th>
                      <th>
                        <Body bold light>
                          {t('Net premium')}
                        </Body>
                      </th>
                      <th>
                        <Body bold light>
                          {t('Taxes and fees')}
                        </Body>
                      </th>
                      <th>
                        <Body bold light>
                          {t('Total premium')}
                        </Body>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {guaranteesAsTheCustomerSeesThem.map(item => {
                      return (
                        <tr key={item?.id}>
                          <td>
                            <CheckBox
                              checked={item?.taken}
                              disabled={item?.mandatory}
                              onChange={e => {
                                handleGuaranteeChange(item?.id, e);
                              }}
                            ></CheckBox>
                          </td>

                          <td>
                            <TableGuaranteeWrapper>
                              <Body>
                                {item.name === 'family'
                                  ? t('family_guarantee')
                                  : t(item?.name)}
                              </Body>
                            </TableGuaranteeWrapper>
                          </td>

                          <td>
                            {item?.net_premium ? (
                              <Body>
                                {(
                                  Math.round(
                                    (item?.net_premium + 0.00001) * 100,
                                  ) / 100
                                ).toLocaleString('pt-BR')}{' '}
                                EUR
                              </Body>
                            ) : (
                              <Body>{t(`Included`)}</Body>
                            )}
                          </td>

                          <td>
                            {item?.taxes_in_euro ? (
                              <Body>
                                {(
                                  Math.round(
                                    (item?.total_premium -
                                      item?.net_premium +
                                      0.00001) *
                                      100,
                                  ) / 100
                                ).toLocaleString('pt-BR')}{' '}
                                EUR
                              </Body>
                            ) : (
                              <Body>{t('Included')}</Body>
                            )}
                          </td>

                          <td>
                            {item?.total_premium ? (
                              <Body>
                                {(
                                  Math.round(
                                    (item?.total_premium + 0.00001) * 100,
                                  ) / 100
                                ).toLocaleString('pt-BR')}{' '}
                                EUR
                              </Body>
                            ) : (
                              <Body>{t(`Included`)}</Body>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>

                      <td>
                        <Body bold>{t('Total')}</Body>
                      </td>

                      <td>
                        <Body bold>
                          {(
                            Math.round((guaranteesNetPremium + 0.00001) * 100) /
                            100
                          ).toLocaleString('pt-BR')}{' '}
                          EUR
                        </Body>
                      </td>

                      <td>
                        <Body bold>
                          {(
                            Math.round((guaranteesCosts + 0.00001) * 100) / 100
                          ).toLocaleString('pt-BR')}{' '}
                          EUR
                        </Body>
                      </td>

                      <td>
                        <Body bold>
                          {(
                            Math.round((guaranteesTotals + 0.00001) * 100) / 100
                          ).toLocaleString('pt-BR')}{' '}
                          EUR
                        </Body>
                      </td>
                    </tr>
                  </tfoot>
                </StyledTable>

                <MobileTable>
                  {guaranteesAsTheCustomerSeesThem.map(item => (
                    <div key={item?.id}>
                      <CheckBox
                        checked={item?.taken}
                        disabled={item?.mandatory}
                        onChange={e => {
                          handleGuaranteeChange(item?.id, e);
                        }}
                      ></CheckBox>

                      <MobileTableContent>
                        <H4>
                          {item.name === 'family'
                            ? t('family_guarantee')
                            : t(item?.name)}
                        </H4>

                        <Spacer small />
                        <H4>{t('Net premium')}</H4>
                        {item?.net_premium ? (
                          <Body light>
                            {(
                              Math.round((item?.net_premium + 0.00001) * 100) /
                              100
                            ).toLocaleString('pt-BR')}{' '}
                            EUR
                          </Body>
                        ) : (
                          <Body light>{t(`Included`)}</Body>
                        )}

                        <Spacer small />
                        <H4>{t('Taxes and fees')}</H4>
                        {item?.taxes_in_euro ? (
                          <Body light>
                            {(
                              Math.round(
                                (item?.total_premium -
                                  item?.net_premium +
                                  0.00001) *
                                  100,
                              ) / 100
                            ).toLocaleString('pt-BR')}{' '}
                            EUR
                          </Body>
                        ) : (
                          <Body light>{t('Included')}</Body>
                        )}

                        <Spacer small />
                        <H4>{t('Total premium')}</H4>
                        {item?.total_premium ? (
                          <Body light bold>
                            {(
                              Math.round(
                                (item?.total_premium + 0.00001) * 100,
                              ) / 100
                            ).toLocaleString('pt-BR')}{' '}
                            EUR
                          </Body>
                        ) : (
                          <Body light bold>
                            {t(`Included`)}
                          </Body>
                        )}
                      </MobileTableContent>
                    </div>
                  ))}
                </MobileTable>

                <Spacer medium />

                <H3>{t('Acknowledgements')}</H3>

                <Spacer small />

                {data?.acknowledgements.map(item => {
                  return (
                    <>
                      <Spacer small />
                      <CheckBox
                        key={item?.id}
                        checked={item?.agreed}
                        onChange={e => {
                          handleAcknowledgementChange(item?.id, e);
                        }}
                      >
                        {item?.text}
                      </CheckBox>
                    </>
                  );
                })}

                <Spacer medium />
              </PricingModalContent>
            </Section>
          </InfoSection>

          <InfoSection>
            <H3>{t('Prospect.Client information')}</H3>
            <Body light>
              {t(
                'Please check and confirm if all details are correct regarding your personal information',
              )}
              .
            </Body>

            <CRMProspectClientData
              distributionId={distId}
              prospectData={data?.prospect}
              onSubmitFunction={prospectValues =>
                prospectEditSave(prospectValues)
              }
              isCustomerPage
              customerPageFunction={editValue =>
                handleEditModeChange(editValue)
              }
            />
          </InfoSection>

          {data?.risk_objects?.cars.length > 0 ? (
            <InfoSection>
              <Divider />
              <H3>{t('Vehicle information')}</H3>
              <Body light>
                {t(
                  'Please check and confirm if all details are correct regarding your vehicle',
                )}
                .
              </Body>

              {data?.risk_objects?.cars.map((item, index) => {
                return (
                  <>
                    <CarInformation
                      carData={item}
                      isCustomerPage
                      onSubmitFunction={carValues =>
                        carRiskObjectSave(carValues, index)
                      }
                      customerPageFunction={editValue =>
                        handleEditModeChange(editValue)
                      }
                    />

                    <CarDrivers
                      mainDriver={item?.drivers[0]}
                      onSubmitFunction={driverValues =>
                        carDriverSave(driverValues, index)
                      }
                      customerPageFunction={editValue =>
                        handleEditModeChange(editValue)
                      }
                    />
                  </>
                );
              })}
            </InfoSection>
          ) : null}

          {data?.risk_objects?.residences.length > 0 ? (
            <InfoSection>
              <Divider />
              <H3>{t('Property information')}</H3>
              <Body light>
                {t(
                  'Please check and confirm if all details are correct regarding your property',
                )}
                .
              </Body>

              {data?.risk_objects?.residences.map((item, index) => {
                return (
                  <>
                    <section>
                      <PropertyInformation
                        homeData={item}
                        onSubmitFunction={propertyValues =>
                          propertyRiskObjectSave(propertyValues, index)
                        }
                        customerPageFunction={editValue =>
                          handleEditModeChange(editValue)
                        }
                      />
                    </section>

                    <section>
                      <PropertyRooms
                        homeData={item}
                        onSubmitFunction={propertyValues =>
                          propertyRiskObjectSave(propertyValues, index)
                        }
                        customerPageFunction={editValue =>
                          handleEditModeChange(editValue)
                        }
                      />
                    </section>

                    <section>
                      <PropertyAnnexes homeData={item} />
                    </section>
                  </>
                );
              })}
            </InfoSection>
          ) : null}

          {data?.risk_objects?.families.length > 0 ? (
            <InfoSection>
              <Divider />
              <H3>{t('Family information')}</H3>
              <Body light>
                {t(
                  'Please check and confirm if all details are correct regarding your family',
                )}
                .
              </Body>

              {data?.risk_objects?.families.map((item, index) => {
                return (
                  <FamilyInformation
                    key={index}
                    familyData={item}
                    onSubmitFunction={familyValues =>
                      familyRiskObjectSave(familyValues, index)
                    }
                    isCustomerPage
                    customerPageFunction={editValue =>
                      handleEditModeChange(editValue)
                    }
                  />
                );
              })}
            </InfoSection>
          ) : null}

          {data?.risk_objects?.two_wheelers.length > 0 ? (
            <InfoSection>
              <Divider />
              <H3>{t('Two wheeler information')}</H3>
              <Body light>
                {t(
                  'Please check and confirm if all details are correct regarding your two wheeler',
                )}
                .
              </Body>

              {data?.risk_objects?.two_wheelers.map((item, index) => {
                return (
                  <TwoWheelerInformation
                    twoWheelerData={item}
                    key={index}
                    isCustomerPage
                    onSubmitFunction={twoWheelerValues =>
                      twoWheelerRiskObjectSave(twoWheelerValues, index)
                    }
                    customerPageFunction={editValue =>
                      handleEditModeChange(editValue)
                    }
                  />
                );
              })}
            </InfoSection>
          ) : null}

          {data?.risk_objects?.teachers.length > 0 ? (
            <InfoSection>
              <Divider />
              <H3>{t('Teacher information')}</H3>
              <Body light>
                {t(
                  'Please check and confirm if all details are correct regarding the teacher information',
                )}
                .
              </Body>

              {data?.risk_objects?.teachers.map((item, index) => {
                return (
                  <TeacherInformation
                    teacherData={item}
                    key={index}
                    onSubmitFunction={teacherValues =>
                      teacherRiskObjectSave(teacherValues, index)
                    }
                    customerPageFunction={editValue =>
                      handleEditModeChange(editValue)
                    }
                  />
                );
              })}
            </InfoSection>
          ) : null}

          {wereRiskObjectsEdited || wereGuaranteesEdited ? (
            <>
              {isBeingEdited ? (
                <Alert icon type="normal">
                  {t(
                    'Please save all your changes before requesting a recalculation',
                  )}
                  .
                </Alert>
              ) : (
                <Alert icon type="warning">
                  {t(
                    'In order to apply all your changes and recalculate your offer, please click the button below',
                  )}
                  .
                </Alert>
              )}

              <Spacer medium />

              {riskWasNotAccepted ? (
                <>
                  <DesktopAlert icon type="error">
                    {t('No calculation was possible')}: {rejectionReason}
                  </DesktopAlert>
                  <Spacer medium />
                </>
              ) : (
                ''
              )}

              <ActionButton
                fullwidth
                onClick={() => {
                  handleRecalc();
                }}
                disabled={isBeingEdited}
              >
                {t('Request recalculation')}
              </ActionButton>

              <Spacer medium />
            </>
          ) : null}
        </MainContent>

        <Sidebar>
          <SideTitle>{t('CustomerPage.Package')}</SideTitle>

          <PriceWrapper>
            <TotalPrice>
              {(
                Math.round((data?.premium?.total_premium + 0.00001) * 100) / 100
              ).toLocaleString('pt-BR')}{' '}
              EUR
              <span className="smaller">/{t('ANNUAL')}</span>
            </TotalPrice>

            <InfoButton
              onClick={() => {
                riskWasNotAccepted ? null : setPricingModal(!pricingModal);
              }}
            >
              i
            </InfoButton>
          </PriceWrapper>

          <Divider />

          <SideBarButtons>
            <Body light>{t('Facturation period')}</Body>
            <H4>{t(`spread.${originalData?.payment_spread}`)}</H4>
            <Divider />

            <Body light>{'Guarantees'}</Body>
            <Spacer small />
            {guaranteesAsTheCustomerSeesThem.map(item => (
              <>
                <CheckBox disabled checked={item?.taken}>
                  {item.name === 'family'
                    ? t('family_guarantee')
                    : t(item?.name)}
                </CheckBox>
              </>
            ))}

            <Divider />
          </SideBarButtons>

          {wereRiskObjectsEdited || wereGuaranteesEdited ? (
            <>
              {isBeingEdited ? (
                <Alert icon type="normal">
                  {t(
                    'Please save all your changes before requesting a recalculation',
                  )}
                  .
                </Alert>
              ) : (
                <Alert icon type="warning">
                  {t(
                    'In order to apply all your changes and recalculate your offer, please click the button below',
                  )}
                  .
                </Alert>
              )}

              <Spacer medium />

              <ActionButton
                fullwidth
                onClick={() => {
                  handleRecalc();
                }}
                disabled={isBeingEdited}
              >
                {t('Request recalculation')}
              </ActionButton>

              <Spacer medium />
            </>
          ) : null}

          <Body light>
            {t('The contract of your chosen package can start on')}
            <b> {formatISODate(data?.commencement_date)}</b>.{' '}
            {t('This offer is valid from')}
            <b> {formatISODate(data?.start_date)}</b> {t('until')}
            <b> {formatISODate(data?.end_date)}</b>.
          </Body>
        </Sidebar>
      </MainPage>

      <StyledMobileMenu>
        <StyledMobileMenu.Header>
          <MobileMenuHeader>
            <TotalPrice>
              {(
                Math.round((data?.premium?.total_premium + 0.00001) * 100) / 100
              ).toLocaleString('pt-BR')}{' '}
              EUR
              <span className="smaller">
                {' '}
                / {t(data?.payment_spread).toLowerCase()}
              </span>
            </TotalPrice>

            {wereRiskObjectsEdited || wereGuaranteesEdited ? (
              <ActionButton
                onClick={() => {
                  handleRecalc();
                }}
                disabled={isBeingEdited}
              >
                {t('Request recalculation')}
              </ActionButton>
            ) : null}
          </MobileMenuHeader>
        </StyledMobileMenu.Header>

        <StyledMobileMenu.Content>
          <SideTitle>{t('CustomerPage.Package')}</SideTitle>

          <PriceWrapper>
            <TotalPrice>
              {(
                Math.round((data?.premium?.total_premium + 0.00001) * 100) / 100
              ).toLocaleString('pt-BR')}{' '}
              EUR
              <span className="smaller">
                /{t(data?.payment_spread).toLowerCase()}
              </span>
            </TotalPrice>

            <InfoButton
              onClick={() => {
                setPricingModal(!pricingModal);
              }}
            >
              i
            </InfoButton>
          </PriceWrapper>

          <Divider />

          <SideBarButtons>
            <Body light>{t('Facturation period')}</Body>
            <H4>{t(`spread.${data?.payment_spread}`)}</H4>
            <Divider />

            <Body light>{'Guarantees'}</Body>
            <Spacer small />
            {guaranteesAsTheCustomerSeesThem.map(item => (
              <>
                <CheckBox disabled checked={item?.taken}>
                  {item.name === 'family'
                    ? t('family_guarantee')
                    : t(item?.name)}
                </CheckBox>
              </>
            ))}

            <Divider />
          </SideBarButtons>
        </StyledMobileMenu.Content>

        {wereRiskObjectsEdited || wereGuaranteesEdited ? (
          <StyledMobileMenu.Footer>
            <Alert icon type="warning">
              {t(
                'In order to apply all your changes and recalculate your offer, please click the button at the top',
              )}
              .
            </Alert>
          </StyledMobileMenu.Footer>
        ) : null}
      </StyledMobileMenu>

      {wereRiskObjectsEdited || wereGuaranteesEdited ? (
        <MobileEditAlert icon type="warning">
          {t(
            'In order to apply all your changes and recalculate your offer, please click the button below',
          )}
          .
        </MobileEditAlert>
      ) : null}
    </>
  );
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainPage = styled.div`
  width: 100%;
  height: 100%;
  padding: 12rem 0 4rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 950px) {
    padding: 4rem 0 12rem;
  }

  @media screen and (max-width: 450px) {
    padding: 0 0 4rem;
  }
`;

const MainContent = styled.div`
  flex: 0 0 100rem;
  margin-right: 13rem;

  @media screen and (max-width: 1550px) {
    margin-right: 5rem;
  }

  @media screen and (max-width: 1400px) {
    margin-right: 1rem;
    flex: 0 0 80rem;
  }

  @media screen and (max-width: 950px) {
    flex: 0 0 90%;
  }

  @media screen and (max-width: 450px) {
    max-width: calc(100vw - 4rem);
  }
`;

const Sidebar = styled.div`
  flex: 0 0 38rem;
  position: sticky;
  top: 12rem;
  z-index: 10;
  padding: 3rem;
  background-color: ${({ editMode }) => (editMode ? 'white' : '')};
  border-radius: 5px;

  @media screen and (max-width: 1550px) {
    flex: 0 0 34rem;
  }

  @media screen and (max-width: 950px) {
    display: none;
    visibility: hidden;
  }
`;

const HeaderSection = styled.div`
  margin-bottom: 3rem;

  h1 {
    margin-bottom: 2rem;
  }
`;

const InfoSection = styled.div`
  margin-bottom: 6rem;
  margin-top: 3rem;

  > h3 {
    margin-bottom: 5px;
  }

  > p {
    margin-bottom: 2rem;
  }
`;

const Spacer = styled.div`
  height: ${({ small, medium, large }) =>
    small ? '10px' : medium ? '20px' : large ? '30px' : '6px'};

  @media screen and (max-width: 500px) {
    display: ${({ desktop }) => (desktop ? 'none' : '')};
  }
`;

const PricingModalContent = styled.div`
  padding-top: 2rem;

  @media screen and (max-width: 500px) {
    padding-top: 0;
  }
`;

const PricingModalFooter = styled.div`
  background: #f6f6f6;
  border-radius: 6px;
  padding: 2rem;
  margin-top: 3.2rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const SideTitle = styled(H1)`
  margin-bottom: 2.5rem;
`;

const PriceWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const TotalPrice = styled(H1)`
  margin-top: 0.5rem;

  .smaller {
    font-size: 2.1rem;
    line-height: 130%;
  }

  @media screen and (max-width: 950px) {
    font-size: 2.4rem;

    .smaller {
      font-size: 1.8rem;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  background-color: #e4e4e4;
  margin: 2.5rem 0;
  height: 1px;
  display: ${({ editMode }) => (editMode ? 'none' : 'block')};
`;

const SideBarButtons = styled.div`
  display: ${({ editMode }) => (editMode ? 'none' : 'block')};
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  td,
  th {
    padding: 0.5rem 3rem 0.5rem 0;
    vertical-align: middle;
  }

  tr {
    vertical-align: middle;
  }

  thead {
    border-bottom: 1px solid #e4e4e4;

    th {
      padding-bottom: 1rem;
    }
  }

  tfoot {
    border-top: 1px solid #e4e4e4;

    td {
      padding-top: 1rem;
    }
  }

  td:first-child {
    padding-right: 0;
  }

  tbody {
    tr:first-child td {
      padding-top: 1.5rem;
    }

    tr:last-child td {
      padding-bottom: 1.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const TableGuaranteeWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const InfoButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid ${({ theme }) => theme.brand.primary};
  border-radius: 9999px;
  color: ${({ theme }) => theme.brand.primary};
  margin-bottom: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
`;

const CommencementDateInput = styled(TextInput)`
  margin-bottom: 0;

  @media screen and (max-width: 500px) {
    margin-bottom: 15px;
  }
`;

const PricingModalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;

  /* stylelint-disable-next-line selector-max-universal */
  > * {
    flex: 0 0 32%;
    margin-right: 1%;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    /* stylelint-disable-next-line selector-max-universal */
    > * {
      flex: 0 0 100%;
      margin-right: 0;
    }
  }
`;

const TopBar = styled.div`
  height: 8rem;
  position: fixed;
  border-bottom: 0.2px solid #e2e2e2;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 6rem;
  }

  @media screen and (max-width: 950px) {
    display: none;
  }
`;

const TopBarContainer = styled.div`
  width: 151rem;
`;

const PricingModalTotals = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 500px) {
    flex-flow: column;
    justify-content: center;
  }
`;

const NewPrice = styled(H3)`
  > span {
    font-size: 1.6rem;
  }
`;

const StyledMobileMenu = styled(MobileMenu)`
  position: fixed;
  z-index: 1;

  @media screen and (min-width: 950px) {
    display: none;
  }
`;

const MobileMenuHeader = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
`;

const MobileEditAlert = styled(Alert)`
  position: fixed;
  bottom: 9.5rem;
  left: 1.5rem;
  width: calc(100vw - 3rem);

  @media screen and (min-width: 950px) {
    display: none;
  }
`;

const MobileTable = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 2rem;

    > label {
      flex: 0 0 3rem;
    }

    &:first-of-type {
      padding-top: 0;
    }
  }

  @media screen and (min-width: 500px) {
    display: none;
  }
`;

const MobileTableContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 2rem;
  flex: 1 0 auto;
`;

const ModalTable = styled(MobileTable)`
  max-height: 30vh;
  overflow-y: scroll;
`;

const DesktopAlert = styled(Alert)`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const DesktopH1 = styled(H1)`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

CustomerPage.propTypes = {
  match: shape(),
  location: shape(),
};

export default CustomerPage;
