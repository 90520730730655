import React from 'react';
import { string, number, array, shape } from 'prop-types';
import styled from 'styled-components';
import LoadingExtentionIcons from './LoadingExtentionIcons';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

const CompareMatrixTableHeaderCell = ({
  insurerName,
  insuranceName,
  insurerLogo,
  score,
  premium,
  selectedPackages,
  packages,
}) => {
  const { t } = useTranslation();
  return (
    <ul>
      <PacketWrapper>
        <InsurerName>{insurerName}</InsurerName>
        <InsuranceName>{insuranceName.toLowerCase()}</InsuranceName>
      </PacketWrapper>
      <LogoContainer>
        {insurerLogo ? (
          <BoundingBoxLogo img={insurerLogo} />
        ) : (
          <FakeLogo>{insurerName}</FakeLogo>
        )}
      </LogoContainer>
      <Score>{score.toFixed(2)}%</Score>
      {premium ? (
        <YearlyPremium>
          <Premium>€{premium.toFixed(2)}</Premium>
          <Yearly>{t(`Checkout.aYear`)}</Yearly>
        </YearlyPremium>
      ) : (
        <YearlyPremium>
          <Nvt>{t('Checkout.nvt')}</Nvt>
        </YearlyPremium>
      )}
      <li>
        <PackagesWrapper>
          {selectedPackages.length === 0 ? (
            <Nvt>{t('Checkout.nvt')}</Nvt>
          ) : (
            selectedPackages.map(name => {
              const packageType = packages.filter(pack => pack.type === name)[0]
                .type;
              const packageLabel = packages.filter(
                pack => pack.type === name,
              )[0].name;
              return (
                <div key={name}>
                  <ReactTooltip effect="solid" id={packageLabel}>
                    {packageLabel}
                  </ReactTooltip>
                  <Package data-tip data-for={packageLabel}>
                    <LoadingExtentionIcons
                      type={packageType}
                      title={packageLabel}
                    />
                  </Package>
                </div>
              );
            })
          )}
        </PackagesWrapper>
      </li>
    </ul>
  );
};

const PackagesWrapper = styled.ul`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0.5rem 0;
`;

const Package = styled.li`
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  border-radius: 50%;
  padding: 0.5rem;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);

  @media print {
    height: 2.5rem;
    width: 2.5rem;
    box-shadow: none;
  }

  & svg {
    width: 2.5rem;
    height: 2rem;
  }
`;

const Nvt = styled.p`
  font-weight: 900;
  font-size: 1.6rem;
  color: #a29c95;
  line-height: 1.5;
  @media print {
    color: black;
  }
`;

const Premium = styled.p`
  font-weight: 900;
  font-size: 1.6rem;
  color: #a29c95;
  line-height: 1.5;
  @media print {
    color: black;
  }
`;

const Yearly = styled.p`
  font-size: 1.2rem;
  color: #a29c95;
  text-transform: uppercase;
  @media print {
    color: black;
  }
`;

const YearlyPremium = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 5.5rem;
`;

const Score = styled.li`
  font-weight: 900;
  font-size: 1.8rem;
  text-align: center;
  height: 2rem;
`;

const LogoContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  margin: 2rem 0;
`;

const FakeLogo = styled.div`
  padding: 2rem 1rem;
  background-color: ${({ theme }) => theme.brand.primary};
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2.5rem;
  font-weight: 900;
`;

const BoundingBoxLogo = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

const InsurerName = styled.p`
  color: #aeaeae;
  font-size: 1.5rem;
  line-height: 1.3;
  @media print {
    font-size: 1.2rem;
  }
`;

const InsuranceName = styled.p`
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;

  @media print {
    font-size: 1.2rem;
  }
`;

const PacketWrapper = styled.li`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
`;

CompareMatrixTableHeaderCell.propTypes = {
  id: string.isRequired,
  insurerName: string.isRequired,
  insuranceName: string.isRequired,
  insurerLogo: string.isRequired,
  score: number.isRequired,
  premium: number.isRequired,
  selectedPackages: array.isRequired,
  packages: shape().isRequired,
};

export default CompareMatrixTableHeaderCell;
