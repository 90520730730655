import React from 'react';
import { shape, bool } from 'prop-types';

const OffersDashboardIcon = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };

  return (
    <svg
      width="50"
      height="50"
      viewBox="-5 -2 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="40" fill={colorsDict.secondaryColor} />
      <g>
        <path
          d="M40 19V16C40 15.4696 39.7893 14.9609 39.4142 14.5858C39.0391 14.2107 38.5304 14 38 14H27C27 13.2044 26.6839 12.4413 26.1213 11.8787C25.5587 11.3161 24.7956 11 24 11H10C9.46957 11 8.96086 11.2107 8.58579 11.5858C8.21071 11.9609 8 12.4696 8 13V40C8 40.5304 8.21071 41.0391 8.58579 41.4142C8.96086 41.7893 9.46957 42 10 42H40C40.5304 42 41.0391 41.7893 41.4142 41.4142C41.7893 41.0391 42 40.5304 42 40V21C42 20.4696 41.7893 19.9609 41.4142 19.5858C41.0391 19.2107 40.5304 19 40 19ZM11 38V15C11 14.7348 11.1054 14.4804 11.2929 14.2929C11.4804 14.1054 11.7348 14 12 14H24C24 14.7956 24.3161 15.5587 24.8787 16.1213C25.4413 16.6839 26.2044 17 27 17H36C36.2652 17 36.5196 17.1054 36.7071 17.2929C36.8946 17.4804 37 17.7348 37 18V19H16C15.4696 19 14.9609 19.2107 14.5858 19.5858C14.2107 19.9609 14 20.4696 14 21V39H12C11.7348 39 11.4804 38.8946 11.2929 38.7071C11.1054 38.5196 11 38.2652 11 38Z"
          fill={colorsDict.iconColor}
        />
      </g>
    </svg>
  );
};

OffersDashboardIcon.propTypes = {
  colors: shape().isRequired,
  disabled: bool,
};

export default OffersDashboardIcon;
