import React, { useEffect, useState } from 'react';
import {
  Section,
  IconDriverFilled,
  IconSupportFilled,
  H3,
  Body,
  RoomInput as CounterInput,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// import ProFlowChooseRiskAndInsuranceDriversCounter from './ProFlowChooseRiskAndInsuranceDriversCounter';
import { func } from 'prop-types';

const ProFlowChooseRiskAndInsuranceDrivers = ({ onChange }) => {
  const { t } = useTranslation();
  const [riskOptions, setRiskOptions] = useState({ drivers: 1, trailers: 0 });
  const [mountingAnimation, setMountingAnimation] = useState({
    opacity: 0,
  });

  useEffect(() => {
    setMountingAnimation({ opacity: 1 });
  }, []);

  useEffect(() => {
    onChange(riskOptions);
  }, [riskOptions]);

  return (
    <StyledSection style={mountingAnimation}>
      <H3>{t(`Drivers and trailers`)}</H3>
      <Body light>
        {t('Select the amount of drivers and trailers that are included')}
      </Body>
      <StyledContent data-test-id="proFlow_drivers_and_trailers">
        <CounterInput
          icon={<IconDriverFilled size={50} />}
          label={t(`drivers`)}
          value={riskOptions.drivers}
          maxValue={6}
          minValue={1}
          onInputChange={count =>
            setRiskOptions({ ...riskOptions, drivers: count })
          }
        />
        <CounterInput
          /* REMOVE WHEN TRAILERS ARE FIXED -> ALSO REMOVE CSS  */
          className="hidden"
          /******************** */
          icon={<IconSupportFilled size={50} />}
          label={t(`trailers`)}
          value={riskOptions.trailers}
          maxValue={6}
          minValue={0}
          onInputChange={count =>
            setRiskOptions({ ...riskOptions, trailers: count })
          }
        />
        {/* <ProFlowChooseRiskAndInsuranceDriversCounter
          defaultCount={1}
          max={6}
          min={1}
          Icon={IconDriverFilled}
          title={t(`drivers`)}
          onChange={count => setRiskOptions({ ...riskOptions, drivers: count })}
        />
        <ProFlowChooseRiskAndInsuranceDriversCounter
          defaultCount={0}
          max={6}
          min={0}
          Icon={IconSupportFilled}
          title={t(`trailers`)}
          onChange={count =>
            setRiskOptions({ ...riskOptions, trailers: count })
          }
        /> */}
      </StyledContent>
    </StyledSection>
  );
};

const StyledContent = styled(Section.Content)`
  /* REMOVE WHEN TRAILERS ARE FIXED */
  .hidden {
    display: none;
  }
  ${props =>
    props.background
      ? `
    box-shadow: 0 2px 4px rgba(32, 32, 35, 0.1);
    border: 1px solid #e4e4e4;
  `
      : null}
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 2rem;

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const StyledSection = styled(Section)`
  transition: opacity 0.2s ease-in, max-height 2.5s ease-out;
  margin: auto;
  margin-bottom: 5vh;
  margin-top: 6rem;
  padding: 0 3rem;
  width: 100%;
  ${'' /* p {
    color: gray;
    line-height: 2rem;
    margin-bottom: 3vh;
    margin-top: 2vh;
  } */}
`;

ProFlowChooseRiskAndInsuranceDrivers.propTypes = {
  onChange: func.isRequired,
};

export default ProFlowChooseRiskAndInsuranceDrivers;
