import React, { useEffect, useState } from 'react';
import { array, shape, string as propString } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {
  getAllAffinities,
  calculateQuoteAsLead,
} from '../services/apiRouterService';

import { Modal, ActionButton, SearchSelectInput, TextInput } from 'wg-fe-ui';
import CRMLeadSecondaryModal from './CRMLeadSecondModal';
import LoadingButton from './LoadingButton';
import cogoToast from 'cogo-toast';

const CRMLeadConvertModal = ({ modalState, data, distributionId }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = modalState;
  const [loading, setLoading] = useState(false);

  const [initialAffinity, setInitialAffinity] = useState('');
  const [initialInsuranceType, setInitialInsuranceType] = useState('');

  const [selectedAffinityName, setSelectedAffinityName] = useState('');
  const [enableBonusMalus, setEnableBonusMalus] = useState(true);

  const [affinities, setAffinities] = useState([]);
  const [insuranceTypes, setInsuranceTypes] = useState([]);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [payload, setPayload] = useState({});
  const [calculatedData, setCalculatedData] = useState(null);

  const insuranceNames = [
    { label: t('MarketCar.OMNIUM'), value: 'OMNIUM' },
    { label: t('MarketCar.MINI_OMNIUM'), value: 'MINI_OMNIUM' },
    { label: t('No omnium'), value: 'CIVIL_LIABILITY' },
  ];

  const [selectedAffinity, setSelectedAffinity] = useState(null);
  const [selectedInsuranceType, setSelectedInsuranceType] = useState(null);
  const [selectedInsuranceName, setSelectedInsuranceName] = useState(null);
  const [bonusMalus, setBonusMalus] = useState(null);

  useEffect(() => {
    fetchAffinities();
    setPossibleInsuranceTypes();
  }, []);

  useEffect(() => {
    if (
      errors.affinity === null &&
      errors.insuranceType === null &&
      errors.insuranceName === null &&
      errors.bonusMalus === null
    ) {
      setLoading(true);
      calcQuote(payload);
    } else {
      console.error('handleCalculateOffer -> errors', errors);
    }
  }, [errors]);

  async function fetchAffinities() {
    const [response, status] = await getAllAffinities();

    if (status === 200) {
      let newAffinities = [];

      response.items.forEach(item => {
        if (
          data?.risk_objects?.two_wheelers.length > 0 &&
          item?.affinity_info?.key === 'valckenier'
        ) {
          return null;
        } else {
          const affinity = {
            label: t(`${item?.affinity_info?.names?.en}`),
            value: item?.affinity_info?.key,
          };

          newAffinities.push(affinity);
        }
      });

      newAffinities.map(affinity => {
        if (
          data?.origin?.toLowerCase().includes('work') &&
          affinity.value === 'at_work'
        ) {
          setInitialAffinity(affinity);
          setSelectedAffinity(affinity.value);
          setSelectedAffinityName(affinity.label);
        } else if (
          data?.origin?.toLowerCase().includes('honda') &&
          affinity.value === 'honda'
        ) {
          setInitialAffinity(affinity);
          setSelectedAffinity(affinity.value);
          setSelectedAffinityName(affinity.label);
        } else if (
          data?.origin?.toLowerCase().includes('de lijn') &&
          affinity.value === 'de_lijn'
        ) {
          setInitialAffinity(affinity);
          setSelectedAffinity(affinity.value);
          setSelectedAffinityName(affinity.label);
        } else if (
          data?.origin?.toLowerCase().includes('doceo') &&
          affinity.value === 'doceo'
        ) {
          setSelectedAffinity(affinity.value);
          setInitialAffinity(affinity);
          setSelectedAffinityName(affinity.label);
        } else if (
          data?.origin?.toLowerCase().includes('agoria') &&
          affinity.value === 'agoria'
        ) {
          setInitialAffinity(affinity);
          setSelectedAffinity(affinity.value);
          setSelectedAffinityName(affinity.label);
        } else if (
          data?.origin?.toLowerCase().includes('valckenier') &&
          affinity.value === 'valckenier'
        ) {
          setInitialAffinity(affinity);
          setSelectedAffinity(affinity.value);
          setSelectedAffinityName(affinity.label);
        } else if (
          data?.origin?.toLowerCase().includes('motoverzekeringen') &&
          affinity.value === 'motor_insurance'
        ) {
          setInitialAffinity(affinity);
          setSelectedAffinity(affinity.value);
          setSelectedAffinityName(affinity.label);
        }
      });

      setAffinities(newAffinities);
    } else {
      return null;
    }
  }

  async function calcQuote(_payload) {
    setLoading(true);
    const [response, status] = await calculateQuoteAsLead(
      distributionId,
      data?.id,
      _payload,
    );
    setLoading(false);

    if (status !== 200 && response.msg != null) {
      const { hide } = cogoToast.error(
        t(
          response?.detail?.[i18n.language]
            ? response?.detail?.[i18n.language]
            : response.msg,
          {
            onClick: () => {
              hide();
            },
          },
        ),
      );
      return console.error('DEBUG RECALC RESPONSE', status);
    } else {
      setCalculatedData(response);
      setShowModal(false);
      setShowSecondaryModal(true);
    }
  }

  const setPossibleInsuranceTypes = () => {
    let possibleInsuranceTypes = [];

    if (data?.risk_objects?.cars.length > 0) {
      possibleInsuranceTypes.push({ label: t('Car insurance'), value: 'CAR' });
      setBonusMalus(
        data?.risk_objects?.cars?.[0]?.drivers?.[0]?.previous_bonus_malus,
      );
    } else if (data?.risk_objects?.two_wheelers.length > 0) {
      possibleInsuranceTypes.push({
        label: t('Two wheeler insurance'),
        value: 'TWO_WHEELER',
      });
      setEnableBonusMalus(false);
      setBonusMalus(null);
    }

    setInsuranceTypes(possibleInsuranceTypes);
    setInitialInsuranceType(possibleInsuranceTypes?.[0]);
    setSelectedInsuranceType(possibleInsuranceTypes?.[0]?.value);
  };

  /* FORM HANDLERS
  -------------------------------------------------- */
  const handleAffinityChange = event => {
    setSelectedAffinity(event.value);
    setSelectedAffinityName(event.label);
  };

  const handleInsuranceTypeChange = event => {
    setSelectedInsuranceType(event.value);
  };

  const handleInsuranceChange = event => {
    setSelectedInsuranceName(event.value);
  };

  const handleBonusMalusChange = event => {
    setBonusMalus(parseInt(event.value));
  };

  const checkFirstForm = () => {
    selectedAffinity === null
      ? setErrors(prevState => ({
          ...prevState,
          affinity: t('Please select an affinity'),
        }))
      : setErrors(prevState => ({ ...prevState, affinity: null }));

    selectedInsuranceName === null
      ? setErrors(prevState => ({
          ...prevState,
          insuranceName: t('Please select an insurance'),
        }))
      : setErrors(prevState => ({ ...prevState, insuranceName: null }));

    selectedInsuranceType === null
      ? setErrors(prevState => ({
          ...prevState,
          insuranceType: t('Please select an insurance type'),
        }))
      : setErrors(prevState => ({ ...prevState, insuranceType: null }));

    if (bonusMalus === null && enableBonusMalus) {
      setErrors(prevState => ({
        ...prevState,
        bonusMalus: t('Please enter a bonus malus'),
      }));
    } else if (
      (parseInt(bonusMalus) < -2 || parseInt(bonusMalus) > 22) &&
      enableBonusMalus
    ) {
      setErrors(prevState => ({
        ...prevState,
        bonusMalus: t(
          'A bonus malus can not be lower than -2 or be higher than 22',
        ),
      }));
    } else {
      setErrors(prevState => ({ ...prevState, bonusMalus: null }));
    }
  };

  const handleCalculateOffer = () => {
    const cars = [];
    const twoWheelers = [];

    checkFirstForm();

    data?.risk_objects?.cars.forEach(car => {
      delete car.created_at;
      delete car.previous_insurances;
      delete car.risk_object_id;
      delete car.risk_object_revision_id;
      cars.push(car);
    });

    data?.risk_objects?.two_wheelers.forEach(twoWheeler => {
      delete twoWheeler.created_at;
      delete twoWheeler.previous_insurances;
      delete twoWheeler.risk_object_id;
      delete twoWheeler.risk_object_revision_id;
      twoWheelers.push(twoWheeler);
    });

    const newPayload = {
      risk_objects: {
        cars: cars,
        residences: data?.risk_objects?.residences,
        families: data?.risk_objects?.families,
        two_wheelers: data?.risk_objects?.two_wheelers,
        teachers: data?.risk_objects?.teachers,
      },
      quote_specifications: {
        save_quote: false,
        payment_interval: 'ANNUAL',
        bonus_malus: bonusMalus,
      },
      insurance: {
        name: selectedInsuranceName,
        company: 'callant',
        type: selectedInsuranceType,
        affinity: selectedAffinity,
      },
    };

    setPayload(newPayload);
  };

  return (
    <>
      <Modal
        canClose
        showModal={showModal}
        setShowModal={setShowModal}
        title={t('Convert lead to offer')}
        large
      >
        <ModalContainer>
          <Row>
            <SearchSelectInput
              error={errors?.affinity}
              initial={initialAffinity}
              name="affinity"
              onSelected={e => handleAffinityChange(e)}
              options={affinities}
              placeholder={t('Choose an option')}
            >
              {t('Affinity')}
            </SearchSelectInput>

            <SearchSelectInput
              error={errors?.insuranceType}
              initial={initialInsuranceType}
              name="insurance_type"
              onSelected={e => handleInsuranceTypeChange(e)}
              options={insuranceTypes}
              placeholder={t('Choose an option')}
            >
              {t('Insurance type')}
            </SearchSelectInput>

            <SearchSelectInput
              error={errors?.insuranceName}
              name="insurance"
              onSelected={e => handleInsuranceChange(e)}
              options={insuranceNames}
              placeholder={t('Choose an option')}
            >
              {t('Insurance')}
            </SearchSelectInput>

            <TextInput
              error={errors?.bonusMalus}
              name="bonus_malus"
              placeholder={t('Bonus malus')}
              type="number"
              value={bonusMalus}
              disabled={!enableBonusMalus}
              onChange={e => handleBonusMalusChange(e)}
            >
              {t('Bonus malus')}
            </TextInput>
          </Row>
        </ModalContainer>

        <Modal.ModalActions position="right">
          {!loading ? (
            <ActionButton
              disabled={false}
              level="primary"
              onClick={handleCalculateOffer}
            >
              {t('Calculate quote')}
            </ActionButton>
          ) : (
            <StyledLoadingButton>{t('Calculating quote')}</StyledLoadingButton>
          )}
        </Modal.ModalActions>
      </Modal>

      <CRMLeadSecondaryModal
        modalState={[showSecondaryModal, setShowSecondaryModal]}
        retrievedData={data}
        calculatedData={calculatedData}
        distributionId={distributionId}
        prevPayload={payload}
        affinityName={selectedAffinityName}
      />
    </>
  );
};

const StyledLoadingButton = styled(LoadingButton)`
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  height: 4rem;
  transition: 0.1s ease-in-out;
  display: flex;
  margin: 0;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
`;

const ModalContainer = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 15px;
  grid-template-areas: '. .';
`;

CRMLeadConvertModal.propTypes = {
  modalState: array,
  data: shape(),
  revisionId: propString,
  distributionId: propString,
};

export default CRMLeadConvertModal;
