import React from 'react';
import { string } from 'prop-types';

const AssistancePerson = ({ title, color }) => (
  <svg viewBox="0 0 50 50">
    <title>{title}</title>
    <g id="prefix__icons">
      <path
        className="prefix__cls-1"
        fill={color}
        d="M38.64 41.44l-8.35-7.57a.75.75 0 00-.95 0l-3.43 2.65a2.55 2.55 0 01-3.52-.38L19.83 33a30.6 30.6 0 01-6.15-12l-1-3.86a2.5 2.5 0 011.77-3l4.2-1.15a.73.73 0 00.54-.77L17.94 1.17a.7.7 0 00-.58-.62A21 21 0 0012.65 0a13 13 0 00-3.92 2C6.91 3.24 3.65 7.45 4.22 15.54 4.72 22.73 8.88 31 8.88 31s4.29 8.19 9.88 12.85C25 49.06 30.42 49 32.52 48.27a13.32 13.32 0 003.92-2 21.33 21.33 0 002.37-4 .72.72 0 00-.17-.83z"
      />
      <path d="M40 36.65a.09.09 0 01-.06 0" fill={color} opacity={0.7} />
      <path
        fill={color}
        d="M36.13 19.44a4.86 4.86 0 10-4.87-4.86 4.85 4.85 0 004.87 4.86zM45.85 25v23.61A1.4 1.4 0 0144.46 50h-8.77a10 10 0 002.7-1.71c.28-.28 1.14-1.12 2.92-4.82a3.48 3.48 0 00-.81-4.1l-8.35-7.55a3.49 3.49 0 00-4.51-.18l-1.24 1V25a4.16 4.16 0 014.17-4.17h11.11A4.16 4.16 0 0145.85 25z"
      />
    </g>
  </svg>
);

AssistancePerson.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

AssistancePerson.propTypes = {
  title: string,
  color: string,
};

export default AssistancePerson;
