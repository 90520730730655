import React, {
  useState,
  useEffect,
  createRef,
  useContext,
  useLayoutEffect,
} from 'react';
import { shape } from 'prop-types';
import _, { cloneDeep, omitBy, isNil } from 'lodash';
import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import cogoToast from 'cogo-toast';
import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { getBrokerCRMName } from '../../services/brokerDataService';
import DateInputV2 from '../../components/DateInput_v2';
import styled from 'styled-components';
import Frame from '../../../assets/images/Frame.svg';
import { isFuture } from 'date-fns';

import {
  SubNavigation,
  Label,
  ActionButton,
  Section,
  SubTitle,
  TextBold,
  TextArea,
  // RiskObjectBar,
  // TextArea,
  CommentBox,
  PhaseLabel,
  BackButton,
  IconDocumentFilled,
  LoadingSpinner,
  Text,
  CheckBox_v2 as CheckBox,
  StripedTable,
  H1,
  Alert,
  SearchSelectInput,
  TextInput,
  SubLabel,
} from 'wg-fe-ui';

import useForm from '../../hooks/useForm';
import * as Yup from 'yup';
import moment from 'moment';

import {
  formatISODate,
  parseIncDateToIsoFormat,
} from '../../services/dateService';
import { getBrokerDistribution } from '../../services/brokerDataService';
import {
  getContractById,
  exportContractToCRM,
  getAffinityByCompanyKey,
  createContractRevision,
  createContractRevisionManual,
  calculateContractQuote,
  createRiskObjectRevision,
  updateContractById,
  getBrokersByDistributionId,
  getNotesByContractsId,
  appendNoteToContracts,
  deleteContractsNoteById,
  getAffinityInsurancesByCompanyKey,
  getAffinityInsurancesByCompanyKeyAndId,
} from '../../services/apiRouterService';

import { setTabItems } from '../../services/CRMService';

import CRMProspectClientData from '../../components/CRMProspectClientData';
import QuoteGuaranteeIcons from '../../components/QuoteGuaranteeIcons';
import CRMRiskObjectTabsOffers from '../../components/CRMRiskObjectTabsOffers';
import ExportModal from '../../components/CRMExportModal';
import ContractCancellationModal from '../../components/CRMContractCancellationModal';
import ContractNewAddendumModal from '../../components/CRMContractNewAddendumModal';

import PDF from '../../../assets/images/pdf-download.svg';
import QuickActionSubMenu from '../../components/QuickActionSubmenu';
import QuickActionSubMenuStatus from '../../components/QuickActionSubmenuStatus';
import DateInput from '../../components/DateInput_v3';
import FileSaver from 'file-saver';

// import * as Yup from 'yup';
// import useForm from '../hooks/useForm';

// const mockNotes = [
//   {
//     id: 1,
//     private: false,
//     created_at: '27/02/2020',
//     author: 'Schauwers P.',
//     text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eget lectus curabitur neque. Adipiscing donec eu turpis facilisis. Id mauris non est vitae nibh. Proin blandit nisl malesuada tellus cursus. Lorem risus, cras cras cras rutrum. Ut elementum aliquam vivamus tristique ut cursus nibh.`,
//   },
//   {
//     id: 1337,
//     private: false,
//     created_at: '31/12/2019',
//     author: 'Israël A.',
//     text: `Phasellus ullamcorper ipsum rutrum nunc. Praesent egestas tristique nibh. Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Nulla consequat massa quis enim. Sed magna purus, fermentum eu, tincidunt eu, varius ut, felis.`,
//   },
// ];

const CRMContractDetail = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const history = useHistory();
  const location = useLocation();
  const [tempGuarantees, setTempGuarantees] = useState(
    location?.state?.tempGuarantees ?? [],
  );

  const refs = {};
  const [data, setData] = useState(cloneDeep(location?.state?.data));
  const [contractHasRecalculated, setContractHasRecalculated] = useState(
    location?.state?.hasRecalculated ?? false,
  );
  const [brokerCRMName, setBrokerCRMName] = useState(
    location?.state?.brokerCRMName,
  );
  const [distributionId, setDistributionId] = useState(
    location?.state?.distributionId,
  );
  const [insuranceLogo, setInsuranceLogo] = useState(
    location?.state?.insuranceLogo ?? 'https://via.placeholder.com/125x30',
  );
  const [isBeingEdited, setIsBeingEdited] = useState(
    location?.state?.isBeingEdited ?? false,
  );

  const [editContractMode, setEditContractMode] = useState(
    location?.state?.editMode || false,
  );
  const [brokers, setBrokers] = useState([]);
  const [paymentSpread, setPaymentSpread] = useState(
    location?.state?.paymentSpread ?? null,
  );
  const [commencementDate, setCommencementDate] = useState(
    location?.state?.commencementDate ?? null,
  );

  const [commencementDateError, setCommencementDateError] = useState(
    location?.state?.commencementDateError ?? [],
  );
  const [endDateError, setEndDateError] = useState(
    location?.state?.endDateError ?? [],
  );
  const [guaranteeCommencementDates, setGuaranteeCommencementDates] = useState(
    location?.state?.guaranteeCommencementDates ?? [],
  );
  const [initialGuarantees, setInitialGuarantees] = useState(
    location?.state?.initialGuarantees ?? [],
  );
  const [guaranteeEndDates, setGuaranteeEndDates] = useState(
    location?.state?.guaranteeEndDates ?? [],
  );
  const [expiryDate, setExpiryDate] = useState(
    location?.state?.expiryDate ?? '',
  );
  const [bonusMalus, setBonusMalus] = useState(
    location?.state?.bonusMalus ?? null,
  );

  const checkRiskObjectUpdated = () => {
    return (
      (location.state?.updatedCarData ||
        location.state?.updatedTwoWheelerData ||
        location.state?.updatedResidenceData ||
        location.state?.updatedFamilyData ||
        location.state?.updatedTeacherData) ??
      false
    );
  };
  // put statement in function
  const [updateContractRequired, setUpdateContractRequired] = useState(
    checkRiskObjectUpdated(),
  );

  // put statement in function
  const [updateRecalcRequired, setUpdateRecalcRequired] = useState(
    checkRiskObjectUpdated(),
  );
  const [updatedData, setUpdatedData] = useState(
    cloneDeep(location.state?.updatedData),
  );
  const [updatedCarData] = useState(location.state?.updatedCarData ?? []);
  const [updatedTwoWheelerData] = useState(
    location.state?.updatedTwoWheelerData ?? [],
  );
  const [updatedResidenceData] = useState(
    location.state?.updatedResidenceData ?? [],
  );
  const [updatedFamiliesData] = useState(
    location.state?.updatedFamilyData ?? [],
  );
  const [updatedTeachersData] = useState(
    location.state?.updatedTeacherData ?? [],
  );
  const [notes, setNotes] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [showCreateAddendumModal, setShowCreateAddendumModal] = useState(false);
  // const [newQuoteData, setNewQuoteData] = useState();

  const [totalNetPrice, setTotalNetPrice] = useState();
  const [totalTaxPrice, setTotalTaxPrice] = useState();
  const [totalPremiumPrice, setTotalPremiumPrice] = useState();

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  const [contractType, setContractType] = useState(t('Contract'));
  const [insuranceCompanyName, setInsuranceCompanyName] = useState('');
  const [insuranceName, setInsuranceName] = useState('');
  const [guaranteeNames, setGuaranteeNames] = useState({});
  const [termsLink, setTermsLink] = useState('');
  const [ipidLink, setIpidLink] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [overwriteInsuranceWith, setOverwriteInsuranceWith] = useState();
  const [errorRecalcing, setErrorRecalcing] = useState(true);
  const [sections, setSections] = useState([
    {
      id: 1,
      label: contractType,
      subsections: [
        {
          id: 1,
          label: t('Contract information', {
            type: contractType.toLowerCase(),
          }),
        },
        {
          id: 2,
          label: t('Prospect.Client information'),
        },
        {
          id: 3,
          label: t('Risk objects'),
        },
      ],
    },
  ]);

  useEffect(() => {
    if (distributionId !== undefined) {
      getNotes(distributionId, match.params.contractId);
    }
  }, [distributionId]);

  const paymentSpreadOptions = [
    { value: 'ANNUAL', label: t(`spread.ANNUAL`) },
    { value: 'HALF_ANNUAL', label: t(`spread.HALF_ANNUAL`) },
    { value: 'QUARTERLY', label: t(`spread.QUARTERLY`) },
    { value: 'MONTHLY', label: t(`spread.MONTHLY`) },
  ];

  const FindPaymentSpreadInEnums = paymentSpread => {
    const result = paymentSpreadOptions.filter(obj => {
      return obj.value === paymentSpread;
    });
    return result;
  };

  // const [Notes, setNotes] = useState(mockNotes);
  const [CommentSchema] = useState(
    Yup.object().shape({
      comment: Yup.string().nullable(),
    }),
  );

  const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
    validationSchema: CommentSchema,
    change: () => {},
  });

  const getCurrentDate = () => {
    return new Date().toISOString();
  };

  // const handleFormValues = formValues => {
  //   setNotes(Notes => [
  //     {
  //       id: 3,
  //       created_at: getCurrentDate(),
  //       private: formValues.private,
  //       author: 'Current user',
  //       text: formValues.comment,
  //     },
  //     ...Notes,
  //   ]);

  //   resetForm();
  //   values.private = false;
  // };

  const handlePrivateChange = () => {
    values.private = !values.private;
  };

  // const riskObjClickHandler = e => {
  //   e.preventDefault();
  //   history.push(`/sales-management/risk-object/motorcycle/:id`);
  // };

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const retrieveBrokersFromDistribution = async id => {
    const [resp] = await getBrokersByDistributionId(id);
    const sortedBrokers = resp.items
      .filter(item => item.broker.plan !== 'CALLANT')
      .sort((b1, b2) => {
        if (b1?.broker?.name > b2?.broker?.name) return 1;
        if (b1?.broker?.name < b2?.broker?.name) return -1;
        return 0;
      });
    setBrokers(sortedBrokers);
  };

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  const formatDateWithoutTime = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear();
    return formattedDate;
  };

  const setDataEditedRiskObject = () => {
    let newTotalNetPremium = 0;
    let newTotalTaxes = 0;
    let newTotalPremium = 0;
    for (var i = 0; i < tempGuarantees.length; i++) {
      if (tempGuarantees[i]?.taken) {
        newTotalNetPremium += tempGuarantees[i].net_premium;
        newTotalTaxes += tempGuarantees[i].taxes_in_euro;
        newTotalPremium += tempGuarantees[i].total_premium;
      }
    }
    setTotalNetPrice(newTotalNetPremium);
    setTotalTaxPrice(newTotalTaxes);
    setTotalPremiumPrice(newTotalPremium);
  };

  const checkUpdatedRiskObjects = () => {
    if (location?.state?.updatedCarData && updatedData) {
      const riskObjectIndex = updatedData?.risk_objects?.cars?.findIndex(
        obj =>
          obj.risk_object_id ===
          location?.state?.updatedCarData?.risk_object_id,
      );
      if (riskObjectIndex > -1) {
        setUpdatedData(prevValue => {
          prevValue.risk_objects.cars[riskObjectIndex] =
            location?.state?.updatedCarData;
          return prevValue;
        });
      }
    }
    if (location?.state?.updatedTwoWheelerData && updatedData) {
      const riskObjectIndex = updatedData?.risk_objects?.two_wheelers?.findIndex(
        obj =>
          obj.risk_object_id ===
          location?.state?.updatedTwoWheelerData?.risk_object_id,
      );
      if (riskObjectIndex > -1) {
        setUpdatedData(prevValue => {
          prevValue.risk_objects.two_wheelers[riskObjectIndex] =
            location?.state?.updatedTwoWheelerData;
          return prevValue;
        });
      }
    }
  };

  useEffect(() => {
    if (
      location?.state?.updateRequired ||
      location?.state?.updateRecalcRequired ||
      location?.state?.editMode
    ) {
      setDataEditedRiskObject();
      setIsLoading(false);
    } else {
      getContract(match.params.contractId);
      retrieveBrokerCRMName();
    }
    setNavTabs(
      setTabItems(
        match.params.caseId,
        t,
        match?.params?.prospectId ? 'contractDetailCustomer' : 'contractDetail',
        null,
        match.params.contractId,
        { ...location.state },
        match?.params?.prospectId,
      ),
    );
    // retrieveBrokerCRMKey();
  }, []);

  useEffect(() => {
    checkUpdatedRiskObjects();
    setInitialFormValues();
  }, [updatedData]);

  async function retrieveBrokerCRMName() {
    const _brokerCRMName = await getBrokerCRMName();
    setBrokerCRMName(_brokerCRMName);
  }

  async function getNotes(distribution, contract) {
    let [response, status] = await getNotesByContractsId(
      distribution,
      contract,
    );

    setNotes(response?.items || []);

    if (status !== 200) {
      return console.error(response);
    }
  }

  const deleteNoteHandler = async noteId => {
    let [response, status] = await deleteContractsNoteById(
      distributionId,
      match.params.contractId,
      noteId,
    );

    if (status === 200) {
      setNotes(notes.filter(object => object.note.id !== noteId));
      cogoToast.success(t('Note successfully deleted'));
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  // async function retrieveBrokerCRMKey() {
  //   const { broker } = await getBrokerCRMKey();
  //   setBrokerCRMKey(broker.crm);
  // }

  async function getContract(contractId) {
    const { id } = await getBrokerDistribution();

    setDistributionId(id);

    let [response, status] = await getContractById(id, contractId);

    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      console.log('FAILED TO LOAD');
      if (response.msg != null || response.detail != null) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(response.msg)}</strong>
            <p>{t(response.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
      } else {
        cogoToast.error(
          <ToasterContent>{t('Could not find the contract')}</ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
      }
      setIsLoading(false);
      setData(null);
      return;
    }

    retrieveBrokersFromDistribution(id);
    response.quote_id = null;
    setData(cloneDeep(response));
    setInitialGuarantees(response.guarantees);
    setTempGuarantees(response.guarantees);
    setUpdatedData(cloneDeep(response));
    setTotalNetPrice(response.premium.net_premium);
    setTotalTaxPrice(response.premium.taxes_in_euro);
    setTotalPremiumPrice(response.premium.total_premium);
    setPaymentSpread(FindPaymentSpreadInEnums(response?.payment_spread));
    setCommencementDate(response?.commencement_date);
    setExpiryDate(formatISODate(response?.main_expiry_date));
    setBonusMalus(response.premium?.bonus_malus);
    const commencement_date_per_guarantees = [];
    const end_date_per_guarantees = [];
    response.guarantees.forEach(guarantee => {
      if (guarantee.taken) {
        commencement_date_per_guarantees.push({
          guarantee: guarantee.name,
          commencement_date: guarantee.commencement_date,
        });
        end_date_per_guarantees.push({
          guarantee: guarantee.name,
          end_date: guarantee.end_date,
        });
      }
    });
    setGuaranteeCommencementDates(commencement_date_per_guarantees);
    setGuaranteeEndDates(end_date_per_guarantees);

    if (response.insurance.company === 'callant') {
      const [logoResp, logoStatus] = await getAffinityByCompanyKey(
        response.insurance.company,
      );

      if (logoStatus !== 200) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(logoResp.msg)}</strong>
            <p>{t(logoResp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
        return console.error(logoResp);
      }

      getLogoFromAffinity(logoResp.items, response.insurance.affinity);
      // Get All Affinity Insurances By Company Key
      const [
        insurancesResp,
        insurancesStatus,
      ] = await getAffinityInsurancesByCompanyKey(
        response.insurance.company,
        response.insurance.affinity,
      );

      if (insurancesStatus !== 200) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(insurancesResp.msg)}</strong>
            <p>{t(insurancesResp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
        return console.error(insurancesResp);
      }

      // Search for the proper insurance ID from that group
      let insuranceId = null;
      insurancesResp?.items.forEach(item => {
        if (item?.insurance?.type === response.insurance.type) {
          insuranceId = item?.insurance?.id;
          setInsuranceName(item?.insurance?.names?.[i18n.language]);
          setTermsLink(item?.insurance?.terms?.[i18n.language]);
          setIpidLink(item?.insurance?.ipid?.[i18n.language]);
        }
      });

      if (insuranceId) {
        // Retrieve the information from the insurance ID
        const [
          insuranceResp,
          insuranceStatus,
        ] = await getAffinityInsurancesByCompanyKeyAndId(
          response.insurance.company,
          response.insurance.affinity,
          insuranceId,
        );

        if (insuranceStatus !== 200) {
          cogoToast.error(
            <ToasterContent>
              <strong>{t(insurancesResp.msg)}</strong>
              <p>{t(insurancesResp.detail)}</p>
            </ToasterContent>,
            {
              onClick: hide => {
                hide();
              },
              hideAfter: 15,
            },
          );
          return console.error(insuranceResp);
        }

        const guaranteeNamesDict = {};
        insuranceResp?.items.forEach(item => {
          guaranteeNamesDict[item?.guarantee_info?.key] =
            item?.guarantee_info?.names;
        });

        setGuaranteeNames(guaranteeNamesDict);
      }
    }

    setIsLoading(!isLoading);
  }

  const getLogoFromAffinity = (object, value) => {
    object.forEach(affinity => {
      const { affinity_info } = affinity;
      if (
        Object.keys(affinity_info).some(key => affinity_info[key] === value)
      ) {
        setInsuranceLogo(affinity_info.logo);
        setInsuranceCompanyName(affinity_info?.names?.[i18n.language]);
      }
    });
  };

  const exportClickHandler = async () => {
    setIsExporting(true);
    cogoToast.loading(t('Exporting'), {
      hideAfter: 7,
    });

    let [response, status] = await exportContractToCRM(
      distributionId,
      match.params.contractId,
    );

    if (status === 200) {
      setIsExporting(false);
      cogoToast.success(t('The offer has been exported successfully'));
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong!'));
      return console.error('ERROR', response);
    }
  };

  const submitContractCancellation = async values => {
    values.termination = {
      date_for_termination: parseIncDateToIsoFormat(
        values.date_for_termination,
      ),
      reason: values?.reason,
      reason_for_termination: values?.reason_for_termination,
    };
    values.revision_type = 'CONTRACT_TERMINATION';
    delete values?.reason;
    delete values?.reason_for_termination;
    delete values?.date_for_termination;
    cogoToast.loading(t('Terminating contract'), {
      hideAfter: 7,
    });
    handleCreateNewContract(values);
  };

  useEffect(() => {
    const typeLabel = data?.type === 'CONTRACT' ? t('Contract') : t('Addendum');
    setContractType(typeLabel);
    setSections([
      {
        id: 1,
        label: `${typeLabel} | ${data?.policy_nr}`,
        subsections: [
          {
            id: 1,
            label: t('Contract information', { type: typeLabel.toLowerCase() }),
          },
          {
            id: 2,
            label: t('Prospect.Client information'),
          },
          {
            id: 3,
            label: t('Risk objects'),
          },
        ],
      },
    ]);
  }, [data]);

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  /**
   * Helper functions
   */

  const isObjectSame = (obj1, obj2) => {
    // console.log('DEBUG EDIT MODE 1', obj1);
    // console.log('DEBUG EDIT MODE 2', obj2);
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          switch (key) {
            case 'main_expiry_date':
            case 'commencement_date':
            case 'end_date':
              base[key] = new Date(base[key]).toISOString();
              if (value !== base[key]) result[key] = value;
              break;
            default:
              result[key] =
                _.isObject(value) && _.isObject(base[key])
                  ? changes(value, base[key])
                  : value;
              break;
          }
        }
      });
    }
    // console.log('DEBUG EDIT MODE DIFFERENCE', changes(obj2, obj1));
    changes(obj2, obj1);
    return _.isEqual(obj1, obj2);
  };

  /**
   * Edit functionality
   */

  const ToggleEditMode = e => {
    setEditContractMode(!editContractMode);
    if (!isObjectSame(data, updatedData)) {
      showUpdateOfferMessage();
    }
  };

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleNoteFormValues);
  }

  const handleNoteFormValues = async formValues => {
    const payload = {
      created_at: new Date(),
      private: formValues.private,
      author: match.params.contractId,
      text: formValues.comment,
    };

    const [resp, status] = await appendNoteToContracts(
      distributionId,
      match.params.contractId,
      payload,
    );

    if (status >= 400) return;
    setNotes(notes => [
      {
        note: {
          id: resp?.id,
          created_at: new Date(),
          private: formValues.private,
          author: match.params.contractId,
          text: formValues.comment,
        },
      },
      ...notes,
    ]);
    resetForm();
    values.private = false;
  };

  const handleFormValues = async formValues => {
    setEditContractMode(!editContractMode);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    if (!isObjectSame(data, updatedData)) {
      console.log('DEBUG CONTRACT EDIT SOMETHING CHANGED');
      showUpdateOfferMessage();
    }
  };

  const setInitialFormValues = () => {
    if (updatedData) {
      Object.keys(updatedData).forEach(name => {
        handleChange({ name, value: updatedData[name] });
      });
    }
  };

  const handleFormSubmit = e => {
    if (e != null) {
      e.preventDefault();
    }

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    handleSubmit(handleFormValues);
  };

  const handlePaymentSpreadChange = e => {
    const selectedPaymentSpread = FindPaymentSpreadInEnums(e.value);
    setPaymentSpread(selectedPaymentSpread);
    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.payment_spread = selectedPaymentSpread[0].value;
    setUpdatedData(newData);
  };

  const handleCommencementDateChange = e => {
    // console.log('DEBUG EDIT MODE COMMENCEMENT 1', e.value);
    const isoFormattedDate = parseIncDateToIsoFormat(e.value);
    // console.log('DEBUG EDIT MODE COMMENCEMENT 2', isoFormattedDate);
    setCommencementDate(isoFormattedDate);
    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.commencement_date = isoFormattedDate;
    // console.log('DEBUG EDIT MODE COMMENCEMENT 3', newData.commencement_date);

    setUpdatedData(newData);
  };

  const handleExpiryDateChange = e => {
    // console.log('DEBUG EDIT MODE EXPIRYDATE 1', e.value);
    const today = moment();
    let isoFormattedDate;
    if (
      moment(parseIncDateToIsoFormat(`${e.value}/${today.year()}`)).isBefore(
        today,
      )
    ) {
      isoFormattedDate = parseIncDateToIsoFormat(
        `${e.value}/${today.year() + 1}`,
      );
    } else {
      isoFormattedDate = parseIncDateToIsoFormat(`${e.value}/${today.year()}`);
    }
    // console.log('DEBUG EDIT MODE EXPIRYDATE 2', isoFormattedDate);
    setExpiryDate(e.value);
    let newData = JSON.parse(JSON.stringify(updatedData));
    newData.main_expiry_date = isoFormattedDate;
    // console.log('DEBUG EDIT MODE EXPIRYDATE 3', newData.main_expiry_date);
    setUpdatedData(newData);
  };

  const handleGuaranteeDateChange = (guaranteeId, type, event) => {
    const isoFormattedDate = parseIncDateToIsoFormat(event.value);

    const elementPos = updatedData?.guarantees
      .map(x => {
        return x.id;
      })
      .indexOf(guaranteeId);

    let newData = JSON.parse(JSON.stringify(updatedData));

    if (type === 'commencement_date') {
      newData.guarantees[elementPos].commencement_date = isoFormattedDate;
      setUpdatedData(newData);
      setTempGuarantees(newData.guarantees);
      const newCommencementDates = newData.guarantees
        .filter(guarantee => guarantee.taken)
        .map(guarantee => {
          return {
            guarantee: guarantee.name,
            commencement_date: guarantee.commencement_date,
          };
        });
      setGuaranteeCommencementDates(newCommencementDates);
    } else if (type === 'end_date') {
      newData.guarantees[elementPos].end_date = isoFormattedDate;
      setUpdatedData(newData);
      setTempGuarantees(newData.guarantees);
      const newEndDates = newData.guarantees
        .filter(guarantee => guarantee.taken)
        .map(guarantee => {
          return {
            guarantee: guarantee.name,
            end_date: guarantee.end_date,
          };
        });
      setGuaranteeEndDates(newEndDates);
    }
  };

  const handleBonusMalusChange = e => {
    setBonusMalus(e.value);
    setUpdateRecalcRequired(true);
    console.log('DEBUG CONTRACT EDIT BONUS MANUS CHANGED');
  };

  const handleGuaranteesChange = (guaranteeId, event) => {
    const elementPos = updatedData?.guarantees
      .map(x => {
        return x.id;
      })
      .indexOf(guaranteeId);

    let newData = cloneDeep(updatedData);
    let guaranteeData = cloneDeep(tempGuarantees);
    newData.guarantees[elementPos].taken = event.value;
    guaranteeData[elementPos].taken = event.value;

    if (!event.value) {
      // Unchecked, not taken anymore
      let net = totalNetPrice;
      let tax = totalTaxPrice;
      let total = totalPremiumPrice;
      setTotalNetPrice((net -= newData.guarantees[elementPos].net_premium));
      setTotalTaxPrice((tax -= newData.guarantees[elementPos].taxes_in_euro));
      setTotalPremiumPrice(
        (total -= newData.guarantees[elementPos].total_premium),
      );

      newData.guarantees[elementPos].end_date = getCurrentDate();
      setGuaranteeCommencementDates(guaranteeCommencementDates =>
        guaranteeCommencementDates.filter(
          item => item.guarantee !== newData.guarantees[elementPos].name,
        ),
      );
      setGuaranteeEndDates(guaranteeEndDates =>
        guaranteeEndDates.filter(
          item => item.guarantee !== newData.guarantees[elementPos].name,
        ),
      );
    } else {
      // Checked, and thus taken
      let net = totalNetPrice;
      let tax = totalTaxPrice;
      let total = totalPremiumPrice;
      setTotalNetPrice((net += newData.guarantees[elementPos].net_premium));
      setTotalTaxPrice((tax += newData.guarantees[elementPos].taxes_in_euro));
      setTotalPremiumPrice(
        (total += newData.guarantees[elementPos].total_premium),
      );

      newData.guarantees[elementPos].end_date = null;
      if (!newData.guarantees[elementPos].commencement_date) {
        if (updatedData.guarantees[elementPos].commencement_date) {
          newData.guarantees[elementPos].commencement_date =
            updatedData.guarantees[elementPos].commencement_date;
        } else {
          newData.guarantees[elementPos].commencement_date = getCurrentDate();
        }
      }
      setGuaranteeCommencementDates(guaranteeCommencementDates => [
        ...guaranteeCommencementDates,
        {
          guarantee: newData.guarantees[elementPos].name,
          commencement_date: newData.guarantees[elementPos].commencement_date,
        },
      ]);
      setGuaranteeEndDates(guaranteeEndDates => [
        ...guaranteeEndDates,
        {
          guarantee: newData.guarantees[elementPos].name,
          end_date: newData.guarantees[elementPos].end_date,
        },
      ]);
    }

    console.log('DEBUG CONTRACT EDIT GUARANTEES', newData);
    setTempGuarantees(guaranteeData);
    setUpdatedData(newData);
  };

  const backButtonHandler = () => {
    let pathname = '';
    if (match?.params?.prospectId) {
      pathname = `/sales-management/customer/${match.params.prospectId}/contracts`;
    } else {
      pathname = `/sales-management/contracts/contracts/${location?.state
        ?.page || 1}`;
    }
    history.push({
      pathname,
      state: {
        ...location.state,
      },
    });
  };

  const handleGuaranteePriceChange = (guaranteeIndex, input) => {
    const { name, value } = input;
    setTempGuarantees(prevValue => {
      const temp = cloneDeep(prevValue);
      let price = parseFloat(value);
      switch (name) {
        case 'net_premium':
          temp[guaranteeIndex].total_premium =
            price + price * parseFloat(temp[guaranteeIndex].taxes);
          temp[guaranteeIndex].taxes_in_euro =
            price * parseFloat(temp[guaranteeIndex].taxes);

          break;
        case 'total_premium':
          temp[guaranteeIndex].net_premium =
            price / parseFloat(1 + temp[guaranteeIndex].taxes);
          temp[guaranteeIndex].taxes_in_euro =
            temp[guaranteeIndex].net_premium *
            parseFloat(temp[guaranteeIndex].taxes);
          break;
        case 'commission':
          price /= 100;
          break;
        default:
          break;
      }
      temp[guaranteeIndex][name] = price;
      let newTotalNetPremium = 0;
      let newTotalTaxes = 0;
      let newTotalPremium = 0;
      for (var i = 0; i < temp.length; i++) {
        if (temp[i]?.taken) {
          newTotalNetPremium += temp[i].net_premium;
          newTotalTaxes += temp[i].taxes_in_euro;
          newTotalPremium += temp[i].total_premium;
        }
      }
      setTotalNetPrice(newTotalNetPremium);
      setTotalTaxPrice(newTotalTaxes);
      setTotalPremiumPrice(newTotalPremium);
      return temp;
    });
  };

  const handleEditModeChange = active => {
    setIsBeingEdited(active);
  };

  const showUpdateOfferMessage = () => {
    console.log('DEBUG CONTRACT EDIT UPDATE CONTRACT REQUIRED');
    setUpdateContractRequired(true);
  };

  const handleCreateNewContract = async extraValues => {
    let guarantees = [];
    let acknowledgements = [];
    const tempUpdatedData = cloneDeep(updatedData);
    setUpdateContractRequired(false);

    // First, create the new risk object revisions
    const updatedRiskObjects = {
      cars: tempUpdatedData?.risk_objects?.cars.map(item => {
        delete item.risk_object_revision_id;
        return item;
      }),
      families: tempUpdatedData?.risk_objects?.families.map(item => {
        delete item.risk_object_revision_id;
        return item;
      }),
      residences: tempUpdatedData?.risk_objects?.residences.map(item => {
        delete item.risk_object_revision_id;
        return item;
      }),
      two_wheelers: tempUpdatedData?.risk_objects?.two_wheelers.map(item => {
        delete item.risk_object_revision_id;
        return item;
      }),
      teachers: tempUpdatedData?.risk_objects?.teachers.map(item => {
        delete item.risk_object_revision_id;
        return item;
      }),
    };

    // TODO: Needs to have a better fallback here to catch errors
    const risk_object_revisions = {};
    for (var riskType in updatedRiskObjects) {
      if (updatedRiskObjects.hasOwnProperty(riskType)) {
        if (
          updatedRiskObjects[riskType] &&
          updatedRiskObjects[riskType].length > 0 &&
          !isObjectSame(
            updatedRiskObjects[riskType][0],
            data?.risk_objects?.[riskType][0],
          )
        ) {
          delete updatedRiskObjects[riskType][0].created_at;
          delete updatedRiskObjects[riskType][0].id;
          delete updatedRiskObjects[riskType][0].risk_object_id;
          delete updatedRiskObjects[riskType][0].risk_object_revision_id;

          const [resp, status] = await createRiskObjectRevision(
            distributionId,
            data.prospect.id,
            riskType,
            data.risk_objects[riskType][0].risk_object_id,
            updatedRiskObjects[riskType][0],
          );
          if (status === 201) {
            risk_object_revisions[riskType] = [resp?.risk_object_revision_id];
          } else if (status === 422) {
            cogoToast.error(
              <ToasterContent>
                <strong>{t(resp.msg)}</strong>
                <p>{t(resp.detail)}</p>
              </ToasterContent>,
              {
                onClick: hide => {
                  hide();
                },
                hideAfter: 15,
              },
            );
          }
        } else if (
          data?.risk_objects?.[riskType] &&
          data?.risk_objects?.[riskType]?.length > 0 &&
          isObjectSame(
            updatedRiskObjects[riskType][0],
            data?.risk_objects?.[riskType][0],
          )
        ) {
          // If nothing has been changed to the risk objects, just take the original ids
          risk_object_revisions[riskType] = [
            updatedRiskObjects[riskType][0]?.risk_object_revision_id,
          ];
        }
      }
    }

    // Update the guarantee commencement dates if needed
    updatedData?.guarantees?.forEach(guarantee => {
      if (guarantee?.taken === true) {
        guarantees.push(guarantee.name);
      }
    });

    console.log('DEBUG CONTRACT EDIT GUARANTEES', updatedData?.guarantees);
    console.log('DEBUG CONTRACT EDIT GUARANTEES ARRAY', guarantees);
    console.log(
      'DEBUG CONTRACT EDIT GUARANTEES COMMENCEMENT DATES',
      guaranteeCommencementDates,
    );

    updatedData.acknowledgements.forEach(acknowledgement => {
      const ack = {
        text: acknowledgement.text,
        agreed: acknowledgement.agreed,
        key: acknowledgement.key,
        id: acknowledgement.id,
      };

      acknowledgements.push(ack);
    });

    // TODO: Need to add support here for a different main_expiry_date, starting dates, commencement dates per gaurantee
    let payload = {
      quote_id: contractHasRecalculated ? data?.quote_id : undefined,
      guarantees: guarantees,
      risk_object_revisions,
      payment_spread: paymentSpread?.[0]?.value,
      main_expiry_date: updatedData?.main_expiry_date,
      export_to_crm: true,
      commencement_date: commencementDate,
      commencement_date_per_guarantees: guaranteeCommencementDates,
      end_date_per_guarantees: guaranteeEndDates,
    };

    let payloadManual = {
      risk_object_revisions,
      policy_costs: data?.premium?.policy_costs,
      payment_spread: paymentSpread?.[0]?.value,
      main_expiry_date: updatedData?.main_expiry_date,
      export_to_crm: true,
      manual_guarantees: cloneDeep(tempGuarantees),
      bonus_malus: bonusMalus,
      commencement_date: extraValues?.commencement_date || commencementDate,
    };

    for (var i = 0; i < payloadManual.manual_guarantees.length; i++) {
      payloadManual.manual_guarantees[i].commencement_date =
        payloadManual?.manual_guarantees[i]?.commencement_date ||
        commencementDate;
      delete payloadManual?.manual_guarantees?.[i]?.contract_id;
      delete payloadManual?.manual_guarantees?.[i]?.offer_revision_id;
      delete payloadManual?.manual_guarantees?.[i]?.guarantee_info_id;
      delete payloadManual?.manual_guarantees?.[i]?.formula;
    }

    if (extraValues) {
      payload = {
        ...payload,
        ...extraValues,
      };
      payloadManual = {
        ...payloadManual,
        ...extraValues,
      };
    }

    // Only show the toaster if it is not a termination, otherwise, there are two toasters
    let closeCreatingAddendumToaster = () => {};
    if (!payload?.termination) {
      closeCreatingAddendumToaster = cogoToast.loading(
        t('Creating new contract addendum'),
        {
          hideAfter: 0,
        },
      );
    }

    const tempData = cloneDeep(data);
    const tempTempGuarantees = cloneDeep(tempGuarantees);

    const [resp, status] = isObjectSame(
      tempData.guarantees.map(g => {
        delete g.taken;
        delete g.commencement_date;
        delete g.end_date;
        return g;
      }),
      tempTempGuarantees.map(g => {
        delete g.taken;
        delete g.commencement_date;
        delete g.end_date;
        return g;
      }),
    )
      ? await createContractRevision(
          distributionId,
          match.params.caseId,
          match.params.contractId,
          payload,
        )
      : await createContractRevisionManual(
          distributionId,
          match.params.caseId,
          match.params.contractId,
          payloadManual,
        );

    closeCreatingAddendumToaster();
    if (status === 201) {
      cogoToast.success(
        t('The contract adjustments have been successfully saved'),
      );
      setContractHasRecalculated(false);
      console.log('DEBUG', resp);
      if (match.params.prospectId) {
        history.push(
          `/sales-management/customer/${match.params.prospectId}/contracts/case/${match.params.caseId}/contract/${resp.id}`,
        );
      } else {
        history.push(
          `/sales-management/case/${match.params.caseId}/contract/${resp.id}`,
        );
      }
    } else if (resp.msg != null || resp.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', resp);
    } else {
      cogoToast.error(t('Something went wrong'));
      console.log(
        `DEBUG RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`,
      );
    }
  };

  const updateStatus = async status => {
    if (status === 'TERMINATED') {
      setShowCancellationModal(true);
    } else {
      const [resp, respStatus] = await updateContractById(
        distributionId,
        match.params.caseId,
        { status },
      );
      if (respStatus === 201) {
        cogoToast.success(t('Contract status has been changed'));
        setData(prevValue => ({
          ...prevValue,
          status,
        }));
      } else if (resp.msg != null || resp.detail != null) {
        cogoToast.error(
          <ToasterContent>
            <strong>{t(resp.msg)}</strong>
            <p>{t(resp.detail)}</p>
          </ToasterContent>,
          {
            onClick: hide => {
              hide();
            },
            hideAfter: 15,
          },
        );
      } else {
        cogoToast.error(t('Something went wrong'));
      }
    }
  };

  const updateAssignedTo = async broker => {
    const tempData = JSON.parse(JSON.stringify(data));
    setData(prevValue => ({
      ...prevValue,
      assigned_to: broker,
    }));
    const [resp, respStatus] = await updateContractById(
      distributionId,
      match.params.caseId,
      { assigned_to: broker },
    );
    if (respStatus === 201) {
      cogoToast.success(t('Contract was successfully assigned'));
    } else if (resp.msg != null || resp.detail != null) {
      setData(tempData);
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const handleCalculateNewQuote = async () => {
    setUpdateRecalcRequired(false);
    setContractHasRecalculated(true);
    const tempLocalGuarantees = cloneDeep(tempGuarantees);
    //Check if there were changes made and applies them, if not use data from offer

    let carsArray = [];
    let twoWheelersArray = [];
    let residencesArray = [];
    let teachersArray = [];
    let familiesArray = [];

    if (updatedCarData.length === 0) {
      if (updatedData.risk_objects.cars.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(updatedData.risk_objects.cars),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        carsArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(JSON.stringify(updatedCarData));
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      carsArray.push(_updatedRiskModelData);
      await setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            cars: [updatedCarData],
          },
        };
      });
    }

    if (updatedTwoWheelerData.length === 0) {
      if (updatedData.risk_objects.two_wheelers.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(updatedData.risk_objects.two_wheelers),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        twoWheelersArray.push(...payload);
      }
    } else {
      delete updatedTwoWheelerData.drive_assistance_system;
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedTwoWheelerData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      twoWheelersArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            two_wheelers: [updatedTwoWheelerData],
          },
        };
      });
    }

    if (updatedResidenceData.length === 0) {
      if (updatedData.risk_objects.residences.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(updatedData.risk_objects.residences),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        residencesArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedResidenceData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      residencesArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            residences: [updatedResidenceData],
          },
        };
      });
    }

    if (updatedTeachersData.length === 0) {
      if (updatedData.risk_objects.teachers.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(updatedData.risk_objects.teachers),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        teachersArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedTeachersData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      teachersArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            teachers: [updatedTeachersData],
          },
        };
      });
    }

    if (updatedFamiliesData.length === 0) {
      if (updatedData.risk_objects.families.length > 0) {
        const payload = JSON.parse(
          JSON.stringify(updatedData.risk_objects.families),
        );
        // TODO: Should iterate over them
        if (payload?.length > 0) {
          delete payload?.[0]?.created_at;
          delete payload?.[0]?.previous_insurances;
          delete payload?.[0]?.risk_object_id;
          delete payload?.[0]?.risk_object_revision_id;
        }
        familiesArray.push(...payload);
      }
    } else {
      // Needs a hard copy, to not delete all params effectively
      const _updatedRiskModelData = JSON.parse(
        JSON.stringify(updatedFamiliesData),
      );
      delete _updatedRiskModelData.created_at;
      delete _updatedRiskModelData.previous_insurances;
      delete _updatedRiskModelData.risk_object_id;
      delete _updatedRiskModelData.risk_object_revision_id;
      familiesArray.push(_updatedRiskModelData);
      setUpdatedData(updatedData => {
        return {
          ...updatedData,
          risk_objects: {
            ...updatedData.risk_objects,
            families: [updatedFamiliesData],
          },
        };
      });
    }

    const payload = {
      risk_objects: {
        cars: carsArray,
        residences: residencesArray,
        families: familiesArray,
        two_wheelers: twoWheelersArray,
        teachers: teachersArray,
      },
      quote_specifications: {
        bonus_malus: bonusMalus,
      },
      // prospect_id: updatedData.prospect.id,
    };

    if (overwriteInsuranceWith) {
      payload.overwrite_insurance_with = overwriteInsuranceWith;
    }

    const [response, status] = await calculateContractQuote(
      distributionId,
      match.params.contractId,
      payload,
    );

    cogoToast.loading(t('Calculating quote'));

    if (status !== 200 && (response.msg != null || response.detail)) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response?.detail?.[i18n.language] ?? response?.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      setErrorRecalcing(errorRecalcing => true);
      setOverwriteInsuranceWith(overwriteInsuranceWith => null);
      setUpdatedData(cloneDeep(data));
      return console.error('ERROR', response);
    } else if (status !== 200) {
      cogoToast.error(t('Something went wrong'));
      return console.error('DEBUG RECALC RESPONSE', status);
    }
    setErrorRecalcing(errorRecalcing => false);

    const newGuarantees = response.guarantees.map(guarantee => {
      const guaranteeIndexPrevious = tempLocalGuarantees.findIndex(
        item => item.name === guarantee.name,
      );
      guarantee.taken = tempLocalGuarantees?.[guaranteeIndexPrevious]?.taken;
      guarantee.commencement_date =
        tempLocalGuarantees?.[guaranteeIndexPrevious]?.commencement_date;
      guarantee.end_date =
        tempLocalGuarantees?.[guaranteeIndexPrevious]?.end_date;
      return guarantee;
    });

    // Set the taken parameter based on mandatory or the taken before check
    let newTotalNetPremium = 0;
    let newTotalTaxes = 0;
    let newTotalPremium = 0;
    for (var i = 0; i < response.guarantees.length; i++) {
      if (response.guarantees[i]?.taken) {
        newTotalNetPremium += response.guarantees[i].net_premium;
        newTotalTaxes += response.guarantees[i].taxes_in_euro;
        newTotalPremium += response.guarantees[i].total_premium;
      }
    }

    setData({
      ...data,
      guarantees: newGuarantees,
      quote_id: response.id,
    });
    setTempGuarantees(newGuarantees);
    setTotalNetPrice(newTotalNetPremium);
    setTotalTaxPrice(newTotalTaxes);
    setTotalPremiumPrice(newTotalPremium);
    await setUpdatedData(updatedData => {
      return {
        ...updatedData,
        guarantees: newGuarantees,
        quote_id: response.id,
      };
    });

    if (bonusMalus !== updatedData.premium.bonus_malus) {
      showUpdateOfferMessage();
    }
  };

  useEffect(() => {
    if (overwriteInsuranceWith) {
      console.log('DEBUG CONTRACT EDIT INSURANCE CHANGED');
      setUpdateRecalcRequired(true);
      setUpdateContractRequired(true);
      setGuaranteeCommencementDates(prevValue => {
        const tempValue = JSON.parse(JSON.stringify(prevValue));
        const keyList = prevValue.map(guarantee => guarantee.guarantee);
        switch (overwriteInsuranceWith) {
          case 'mini_omnium':
            if (keyList.includes('omnium')) {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'omnium',
              );
              tempValue[index].guarantee = overwriteInsuranceWith;
            } else {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'civil_liability',
              );
              tempValue.push({
                guarantee: overwriteInsuranceWith,
                commencement_date: tempValue[index].commencement_date,
              });
            }
            break;
          case 'omnium':
            if (keyList.includes('mini_omnium')) {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'mini_omnium',
              );
              tempValue[index].guarantee = overwriteInsuranceWith;
            } else {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'civil_liability',
              );
              tempValue.push({
                guarantee: overwriteInsuranceWith,
                commencement_date: tempValue[index].commencement_date,
              });
            }
            break;
          default:
            break;
        }
        return tempValue;
      });
      setGuaranteeEndDates(prevValue => {
        const tempValue = JSON.parse(JSON.stringify(prevValue));
        const keyList = prevValue.map(guarantee => guarantee.guarantee);
        switch (overwriteInsuranceWith) {
          case 'mini_omnium':
            if (keyList.includes('omnium')) {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'omnium',
              );
              tempValue[index].guarantee = overwriteInsuranceWith;
            } else {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'civil_liability',
              );
              tempValue.push({
                guarantee: overwriteInsuranceWith,
                end_date: tempValue[index].end_date,
              });
            }
            break;
          case 'omnium':
            if (keyList.includes('mini_omnium')) {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'mini_omnium',
              );
              tempValue[index].guarantee = overwriteInsuranceWith;
            } else {
              const index = prevValue.findIndex(
                guarantee => guarantee.guarantee === 'civil_liability',
              );
              tempValue.push({
                guarantee: overwriteInsuranceWith,
                end_date: tempValue[index].end_date,
              });
            }
            break;
          default:
            break;
        }
        return tempValue;
      });
    }
  }, [overwriteInsuranceWith]);

  const handleContractDocumentClick = (URL, filename) => {
    FileSaver.saveAs(URL, filename);
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (data == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Contract not found')}</h1>
        <p>
          {`${t('We could not find the contract with id')}: `}{' '}
          <strong> {match.params.contractId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton onClick={backButtonHandler} name={t('Previous page')} />
          </TopBarLeft>

          <TopBarRight>
            <TopBarAction></TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <StyledSubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </StyledSubNavigation>
          ))}
          <ExportModal
            modalState={[showExportModal, setShowExportModal]}
            proceedExport={exportClickHandler}
            type="Contract"
            // data={data}
            // revisionId={match.params.revision}
            // distributionId={distributionId}
          />
          <ActionButton
            onClick={e => {
              updatedData.amount_of_crm_exports > 0
                ? setShowExportModal(true)
                : exportClickHandler(e);
            }}
            level="secondary"
            disabled={brokerCRMName == null || editContractMode || isExporting}
          >
            {brokerCRMName || brokerCRMName !== 'None'
              ? `${t(`Export to`)} ${brokerCRMName}`
              : t(`No crm found`)}
          </ActionButton>
        </Sidebar>

        <Content>
          <section ref={refs[1][1]}>
            <ContractHeader>
              <section>
                <ContractHeaderItem>
                  <Label>{t('Policy nr')}</Label>
                  <TextBold>
                    {data?.policy_nr
                      ? `${data?.policy_nr.split(`/`)?.[0]}`
                      : `-`}
                  </TextBold>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Status')}</Label>
                  <QuickActionSubMenuStatus
                    status={data?.status}
                    label={t(`contracts.${data?.status}`)}
                  >
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="PENDING">
                          {t('contracts.PENDING')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('PENDING')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="ACCEPTED">
                          {t('contracts.ACCEPTED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('ACCEPTED')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="REJECTED">
                          {t('contracts.REJECTED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('REJECTED')}
                      type=""
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="TERMINATED">
                          {t('contracts.TERMINATED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('TERMINATED')}
                      type=""
                    />
                  </QuickActionSubMenuStatus>
                  {/* <PhaseLabel status="offer-send">
                    <TextBold>{data.status ? t(data?.status) : `-`}</TextBold>
                  </PhaseLabel> */}
                </ContractHeaderItem>

                <ContractCancellationModal
                  modalState={[showCancellationModal, setShowCancellationModal]}
                  terminateContract={submitContractCancellation}
                  type="Contract"
                />

                <ContractNewAddendumModal
                  modalState={[
                    showCreateAddendumModal,
                    setShowCreateAddendumModal,
                  ]}
                  createNewAddendum={handleCreateNewContract}
                  previousData={data}
                />

                <ContractHeaderItem>
                  <Label>{t('Created on')}</Label>
                  <TextBold>{formatDate(data?.created_at)}</TextBold>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Created by')}</Label>
                  <Text>
                    {data?.created_by
                      ? brokers?.length > 0
                        ? brokers?.find(
                            item => item?.broker?.id === data?.created_by,
                          )?.broker?.name
                        : t('loading')
                      : t('Client')}
                  </Text>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Assigned to')}</Label>
                  <QuickActionSubMenu
                    name={
                      data?.assigned_to
                        ? brokers?.length > 0
                          ? brokers?.find(
                              item => item?.broker?.id === data?.assigned_to,
                            )?.broker?.name
                          : t('loading')
                        : t('No one')
                    }
                  >
                    {brokers?.map(item => (
                      <QuickActionSubMenu.SubMenuItem
                        key={item.broker.id}
                        label={<SubLabel>{item?.broker?.name}</SubLabel>}
                        // TODO: Chane the hardcoded ID here to a mapping of all brokers in the distribution!
                        onClick={() => updateAssignedTo(item?.broker?.id)}
                        type=""
                      />
                    ))}
                    <QuickActionSubMenu.SubMenuItem
                      label={<SubLabel>{t('No one')}</SubLabel>}
                      onClick={() => updateAssignedTo(null)}
                      type=""
                    />
                  </QuickActionSubMenu>
                  {/* <PhaseLabel status="offer-send">
                    <TextBold>{data.status ? t(data?.status) : `-`}</TextBold>
                  </PhaseLabel> */}
                </ContractHeaderItem>
              </section>

              {editContractMode ? (
                <section>
                  <ActionButton
                    disabled={
                      commencementDateError.length > 0 ||
                      Object.keys(endDateError).length > 0
                    }
                    onClick={e => handleFormSubmit(e)}
                  >
                    {t(`Save`)}
                  </ActionButton>
                </section>
              ) : (
                <section>
                  <ActionButton onClick={e => ToggleEditMode(e)}>
                    {t(`Edit contract`)}
                  </ActionButton>
                </section>
              )}
            </ContractHeader>

            {
              // GUARANTEES CHANGED
              // Upd Contr    UpdRecalc      ErrorRecalc
              // T            F              T
            }
            {
              // OMNIUM / BA CHANGED
              // Upd Contr    UpdRecalc      ErrorRecalc
              // T            T              T
            }
            {
              //BONUS MALUS CHANGED
              // Upd Contr    UpdRecalc      ErrorRecalc
              // T            T              T
            }

            {data?.addendum_commencement_date &&
            isFuture(new Date(data?.addendum_commencement_date)) &&
            !updateContractRequired &&
            !updateRecalcRequired ? (
              <StyledAlert icon type="warning">
                <StyledAlertContent>
                  {t(
                    'Please be advised that this addendum is not yet active Its commencement date only starts on date',
                    {
                      commencementDate: formatDateWithoutTime(
                        data.addendum_commencement_date,
                      ),
                    },
                  )}
                </StyledAlertContent>
              </StyledAlert>
            ) : null}

            {updateContractRequired &&
            !updateRecalcRequired &&
            (errorRecalcing || !errorRecalcing) ? (
              <StyledAlert icon type="normal">
                <StyledAlertContent>
                  {t(
                    'You have made changes to the contract, would you like to save these',
                  )}
                  <ActionButton
                    onClick={() => setShowCreateAddendumModal(true)}
                    disabled={editContractMode || isBeingEdited ? true : false}
                  >
                    {t(`Save changes`)}
                  </ActionButton>
                </StyledAlertContent>
              </StyledAlert>
            ) : null}

            {updateRecalcRequired ? (
              <StyledAlert icon type="normal">
                <StyledAlertContent>
                  {t(
                    'Some risk objects have changed Please recalculate your contract',
                  )}
                  <ActionButton
                    onClick={() => handleCalculateNewQuote()}
                    disabled={editContractMode || isBeingEdited ? true : false}
                  >
                    {t(`Re-calculate`)}
                  </ActionButton>
                </StyledAlertContent>
              </StyledAlert>
            ) : null}
            <form onSubmit={e => handleFormSubmit(e)}>
              <Section>
                <Section.Title>
                  {t('Contract information', {
                    type: contractType.toLowerCase(),
                  })}
                </Section.Title>

                <Section.Content background>
                  <OfferHeader>
                    <OfferInfoLogo>
                      <img
                        src={insuranceLogo}
                        alt={`${data.insurance?.company}'s logo`}
                        height="35"
                      />
                    </OfferInfoLogo>

                    <OfferHeaderName>
                      <h3>{insuranceCompanyName}</h3>
                      <h4>{insuranceName}</h4>
                    </OfferHeaderName>

                    <OfferPrice>
                      {totalPremiumPrice !== undefined
                        ? totalPremiumPrice.toLocaleString('nl-BE', {
                            style: 'currency',
                            currency: 'EUR',
                          })
                        : null}
                      <span>/{t(`ANNUAL`).toLowerCase()}</span>
                      {/* {data?.payment_spread !== undefined ? (
                        <span>/{t(data?.payment_spread).toLowerCase()}</span>
                      ) : null} */}
                    </OfferPrice>
                  </OfferHeader>

                  <OfferGuarantees>
                    <StripedTable>
                      <StripedTable.Head>
                        <StripedTable.HeaderCell width="5%" />
                        <StripedTable.HeaderCell width="5%" />
                        <StyledHeaderCell width="unset">
                          {t(`Guarantee`)}
                        </StyledHeaderCell>
                        <StyledHeaderCell width="unset">
                          {t(`Net premium`)}
                        </StyledHeaderCell>
                        <StyledHeaderCell width="unset">
                          {t(`Taxes`)}
                        </StyledHeaderCell>
                        <StyledHeaderCell width="unset">
                          {t(`Total premium`)}
                        </StyledHeaderCell>
                        <StyledHeaderCell width="unset">
                          {t(`Commission`)}
                        </StyledHeaderCell>
                        <StyledHeaderCell width="unset">
                          {t('commencement_date_guarantee')}
                        </StyledHeaderCell>
                        <StyledHeaderCell width="unset">
                          {t('end_date_guarantee')}
                        </StyledHeaderCell>
                        {/* <StyledHeaderCell width="unset">
                  VK
                </StyledHeaderCell> */}
                      </StripedTable.Head>
                      <StripedTable.Body>
                        {tempGuarantees.map((guarantee, i) => (
                          <StripedTable.Row key={i + 1}>
                            <StripedTable.BodyCell>
                              {editContractMode ? (
                                <CheckBox
                                  disabled={
                                    guarantee?.mandatory ||
                                    initialGuarantees[i].taken
                                  }
                                  checked={guarantee?.taken}
                                  onChange={e => {
                                    handleGuaranteesChange(guarantee?.id, e);
                                  }}
                                />
                              ) : (
                                <CheckBox disabled checked={guarantee?.taken} />
                              )}
                            </StripedTable.BodyCell>
                            <StripedTable.BodyCell>
                              <QuoteGuaranteeIcons
                                type={
                                  guarantee?.name === 'civil_liability' &&
                                  data?.insurance?.type === 'FAMILY'
                                    ? 'family'
                                    : guarantee?.name
                                }
                                color="#8990A3"
                                size={25}
                              />
                            </StripedTable.BodyCell>
                            <StyledBodyCell>
                              {editContractMode &&
                              (['omnium', 'mini_omnium'].includes(
                                guarantee?.name,
                              ) ||
                                (guarantee?.name === 'civil_liability' &&
                                  !updatedData?.guarantees
                                    .map(guarantee => guarantee?.name)
                                    .includes('mini_omnium') &&
                                  !updatedData?.guarantees
                                    .map(guarantee => guarantee?.name)
                                    .includes('omnium'))) &&
                              ['CAR', 'TWO_WHEELER'].includes(
                                data?.insurance?.type,
                              ) ? (
                                <QuickActionSubMenu
                                  name={
                                    guarantee?.name in guaranteeNames
                                      ? guaranteeNames[
                                          overwriteInsuranceWith
                                            ? overwriteInsuranceWith
                                            : guarantee?.name
                                        ]?.[i18n.language]
                                      : t(
                                          `anon_pack_types.${
                                            overwriteInsuranceWith
                                              ? overwriteInsuranceWith
                                              : guarantee?.name
                                          }`,
                                        )
                                  }
                                >
                                  {['omnium', 'mini_omnium'].map(item => (
                                    <QuickActionSubMenu.SubMenuItem
                                      key={item}
                                      label={<SubLabel>{t(item)}</SubLabel>}
                                      onClick={() =>
                                        setOverwriteInsuranceWith(item)
                                      }
                                      type=""
                                    />
                                  ))}
                                </QuickActionSubMenu>
                              ) : (['omnium', 'mini_omnium'].includes(
                                  guarantee?.name,
                                ) ||
                                  (guarantee?.name === 'civil_liability' &&
                                    !updatedData?.guarantees
                                      .map(guarantee => guarantee?.name)
                                      .includes('mini_omnium') &&
                                    !updatedData?.guarantees
                                      .map(guarantee => guarantee?.name)
                                      .includes('omnium'))) &&
                                ['CAR', 'TWO_WHEELER'].includes(
                                  data?.insurance?.type,
                                ) ? (
                                guarantee?.name in guaranteeNames ? (
                                  guaranteeNames[
                                    overwriteInsuranceWith
                                      ? overwriteInsuranceWith
                                      : guarantee?.name
                                  ]?.[i18n.language]
                                ) : (
                                  t(
                                    `anon_pack_types.${
                                      overwriteInsuranceWith
                                        ? overwriteInsuranceWith
                                        : guarantee?.name
                                    }`,
                                  )
                                )
                              ) : guarantee?.name in guaranteeNames ? (
                                guaranteeNames[guarantee?.name]?.[i18n.language]
                              ) : (
                                t(
                                  guarantee?.name === 'family'
                                    ? 'family_guarantee'
                                    : guarantee?.name,
                                )
                              )}
                            </StyledBodyCell>
                            <StyledBodyCell>
                              {editContractMode ? (
                                <StyledGuaranteeTextInput
                                  error={errors.net_premium}
                                  name="net_premium"
                                  type="number"
                                  disabled={!guarantee?.taken}
                                  value={
                                    Math.round(
                                      (guarantee?.net_premium +
                                        Number.EPSILON) *
                                        100,
                                    ) / 100
                                  }
                                  onChange={e =>
                                    handleGuaranteePriceChange(i, e)
                                  }
                                  symbol="€"
                                  symbolSide="left"
                                  symbolText
                                />
                              ) : (
                                <>
                                  &euro;{' '}
                                  {guarantee?.net_premium?.toLocaleString(
                                    'nl-BE',
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )}
                                </>
                              )}
                            </StyledBodyCell>
                            <StyledBodyCell>
                              <>
                                &euro;{' '}
                                {guarantee?.taxes_in_euro?.toLocaleString(
                                  'nl-BE',
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  },
                                )}
                              </>
                            </StyledBodyCell>
                            <StyledBodyCell>
                              {editContractMode ? (
                                <StyledGuaranteeTextInput
                                  error={errors.total_premium}
                                  name="total_premium"
                                  disabled={!guarantee?.taken}
                                  type="number"
                                  value={
                                    Math.round(
                                      (guarantee?.total_premium +
                                        Number.EPSILON) *
                                        100,
                                    ) / 100
                                  }
                                  onChange={e =>
                                    handleGuaranteePriceChange(i, e)
                                  }
                                  symbol="€"
                                  symbolSide="left"
                                  symbolText
                                />
                              ) : (
                                <>
                                  &euro;{' '}
                                  {guarantee?.total_premium?.toLocaleString(
                                    'nl-BE',
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    },
                                  )}
                                </>
                              )}
                            </StyledBodyCell>
                            <StyledBodyCell>
                              {editContractMode ? (
                                <StyledGuaranteeTextInput
                                  error={errors.commission}
                                  disabled={!guarantee?.taken}
                                  name="commission"
                                  type="number"
                                  value={guarantee?.commission * 100}
                                  onChange={e =>
                                    handleGuaranteePriceChange(i, e)
                                  }
                                  symbol="%"
                                  symbolSide="right"
                                  symbolText
                                />
                              ) : (
                                <>
                                  {(
                                    guarantee?.commission * 100
                                  )?.toLocaleString('nl-BE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                  %
                                </>
                              )}
                            </StyledBodyCell>
                            <StyledBodyCell>
                              {editContractMode ? (
                                <StyledDateInput
                                  name={guarantee?.name}
                                  disabled={!guarantee?.taken}
                                  onChange={e => {
                                    handleGuaranteeDateChange(
                                      guarantee?.id,
                                      'commencement_date',
                                      e,
                                    );
                                  }}
                                  error={
                                    commencementDateError.includes(
                                      guarantee?.id,
                                    ) && t('not a valid date')
                                  }
                                  value={
                                    guarantee?.commencement_date == null
                                      ? ''
                                      : formatISODate(
                                          guarantee?.commencement_date,
                                        )
                                  }
                                  onBlur={e => {
                                    // console.log(
                                    //   'EVENT BRAKKA',
                                    //   e.currentTarget.childNodes.values(),
                                    // );
                                    let date = [];
                                    for (var value of e.currentTarget.childNodes.values()) {
                                      date.push(value?.value);
                                    }
                                    const dateEntries = date.filter(
                                      val => !!val,
                                    );
                                    if (dateEntries.length !== 3) {
                                      setCommencementDateError(arr => [
                                        ...arr,
                                        guarantee?.id,
                                      ]);
                                    } else {
                                      setCommencementDateError(arr =>
                                        arr.filter(
                                          _id => _id !== guarantee?.id,
                                        ),
                                      );
                                    }
                                  }}
                                ></StyledDateInput>
                              ) : guarantee?.commencement_date == null ? (
                                '-'
                              ) : (
                                moment(guarantee?.commencement_date).format(
                                  'DD/MM/YYYY',
                                )
                              )}
                            </StyledBodyCell>
                            <StyledBodyCell>
                              {editContractMode ? (
                                <StyledDateInput
                                  name={guarantee?.name}
                                  disabled={!guarantee?.taken}
                                  error={
                                    endDateError[guarantee?.id] || undefined
                                  }
                                  validate={val => {
                                    let parsedDate = parseIncDateToIsoFormat(
                                      val,
                                    );
                                    let date = val?.split('/');
                                    const dateEntries = date.filter(
                                      val => !!val,
                                    );
                                    // Check if date is entered, if not delete error
                                    if (dateEntries.length === 0) {
                                      setEndDateError(arr => {
                                        delete arr[guarantee?.id];
                                        return arr;
                                      });
                                      return false;
                                    }
                                    // check if date is entered fully
                                    if (dateEntries.length !== 3) {
                                      setEndDateError(arr => ({
                                        ...arr,
                                        [guarantee?.id]: t('not a valid date'),
                                      }));
                                      return false;
                                    } else if (
                                      moment(parsedDate).isBefore(
                                        guarantee?.commencement_date,
                                      ) // Check if commencement date is not after end date
                                    ) {
                                      setEndDateError(arr => ({
                                        ...arr,
                                        [guarantee?.id]: t(
                                          'End date cant be before start date',
                                        ),
                                      }));
                                      return false;
                                    } else {
                                      // if all checks succeeded, delete error
                                      setEndDateError(arr => {
                                        delete arr[guarantee?.id];
                                        return arr;
                                      });
                                      return true;
                                    }
                                  }}
                                  onChange={e => {
                                    handleGuaranteeDateChange(
                                      guarantee?.id,
                                      'end_date',
                                      e,
                                    );
                                  }}
                                  value={
                                    guarantee?.end_date == null
                                      ? ''
                                      : formatISODate(guarantee?.end_date)
                                  }
                                ></StyledDateInput>
                              ) : guarantee?.end_date == null ? (
                                '-'
                              ) : (
                                moment(guarantee?.end_date).format('DD/MM/YYYY')
                              )}
                            </StyledBodyCell>
                          </StripedTable.Row>
                        ))}
                      </StripedTable.Body>
                      <StripedTable.Footer>
                        <StripedTable.FooterCell width="unset" />
                        <StripedTable.FooterCell width="unset" />
                        <StyledFooterCell width="unset">
                          {t(`Checkout.total`)}
                        </StyledFooterCell>
                        <StyledFooterCell width="unset">
                          &euro; {totalNetPrice?.toFixed(2)}
                        </StyledFooterCell>
                        <StyledFooterCell width="unset">
                          &euro; {totalTaxPrice?.toFixed(2)}
                        </StyledFooterCell>
                        <StyledFooterCell width="unset">
                          &euro; {totalPremiumPrice?.toFixed(2)}
                        </StyledFooterCell>
                        <StyledFooterCell width="unset" />
                        <StyledFooterCell width="unset" />
                      </StripedTable.Footer>
                    </StripedTable>
                  </OfferGuarantees>

                  {data.description ? (
                    <DescriptionWrapper>
                      <Label>{t('Description')}</Label>
                      <Text>{data.description}</Text>
                    </DescriptionWrapper>
                  ) : null}

                  <SectionContentInner>
                    <TextWrapper>
                      <Label>{t('General documents')}</Label>
                      <DownloadableDoc href={termsLink} target={'_blank'}>
                        <img src={PDF} alt="PDF" draggable="false" />
                        <Text>{t('Checkout.card.termsPDF')}</Text>
                      </DownloadableDoc>
                      <DownloadableDoc href={ipidLink} target={'_blank'}>
                        <img src={PDF} alt="PDF" draggable="false" />
                        <Text>{t('Checkout.card.IPID')}</Text>
                      </DownloadableDoc>
                    </TextWrapper>

                    {/* <TextWrapper>
                    <Label>External reference</Label>
                    <Text>5d4fg1dfg474df65</Text>
                  </TextWrapper> */}

                    <TextWrapper>
                      <Label> </Label>
                      <Text> </Text>
                    </TextWrapper>

                    <TextWrapper>
                      {editContractMode ? (
                        <DateInput
                          error={errors.registration_first}
                          name="registration_first"
                          onChange={handleCommencementDateChange}
                          value={formatISODate(commencementDate)}
                        >
                          {t('Commencement date')}
                        </DateInput>
                      ) : (
                        <>
                          <Label>{t('Commencement date')}</Label>
                          <Text>{formatISODate(commencementDate)}</Text>
                        </>
                      )}
                    </TextWrapper>

                    <TextWrapper>
                      {editContractMode ? (
                        <DateInputV2
                          error={errors.main_expiry_date}
                          includeYear={false}
                          name="main_expiry_date"
                          onChange={handleExpiryDateChange}
                          value={expiryDate}
                        >
                          {t('Expiry date contract')}
                        </DateInputV2>
                      ) : (
                        <>
                          <Label>{t('Expiry date contract')}</Label>
                          <Text>
                            {expiryDate
                              .split('/')
                              .splice(0, 2)
                              .join('/')}
                          </Text>
                        </>
                      )}
                    </TextWrapper>

                    <TextWrapper>
                      {editContractMode ? (
                        <SearchSelectInput
                          error={errors.payment_spread}
                          initial={paymentSpread}
                          name="payment_spread"
                          onSelected={handlePaymentSpreadChange}
                          options={paymentSpreadOptions}
                          placeholder={t('Choose an option')}
                        >
                          {t('Payment cycle')}
                        </SearchSelectInput>
                      ) : (
                        <>
                          <Label>{t('Payment cycle')}</Label>
                          <Text>{`${t(
                            `spread.` + paymentSpread?.[0]?.value,
                          )}`}</Text>
                        </>
                      )}
                    </TextWrapper>

                    <TextWrapper>
                      {editContractMode ? (
                        ''
                      ) : (
                        <>
                          <Label>{t('Language')}</Label>
                          <Text>{`${t(
                            data.language === `NL` ? `Dutch` : `French`,
                          )}`}</Text>
                        </>
                      )}
                    </TextWrapper>

                    {data?.type === 'ADDENDUM' ? (
                      <>
                        <OnlyTopDivider />
                        <TextWrapper>
                          <Label>{t('Addendum commencement date')}</Label>
                          <Text>
                            {data?.addendum_commencement_date
                              ? formatISODate(data?.addendum_commencement_date)
                              : formatISODate(data?.commencement_date)}
                          </Text>
                        </TextWrapper>
                        <TextWrapper>
                          <Label>{t('Note with addendum creation')}</Label>
                          <Text>{data?.reason_for_creation}</Text>
                        </TextWrapper>
                      </>
                    ) : null}
                  </SectionContentInner>

                  <Divider />

                  <OfferEvaluation>
                    {data.insurance.type === 'CAR' ||
                    data.insurance.type === 'TWO_WHEELER' ? (
                      editContractMode ? (
                        <StyledTextInput
                          error={errors.bonus_malus}
                          name="bonus_malus"
                          placeholder={t('Checkout.insured.bonusMalus')}
                          type="number"
                          value={bonusMalus}
                          onChange={handleBonusMalusChange}
                        >
                          {t('Checkout.insured.bonusMalus')}
                        </StyledTextInput>
                      ) : (
                        <>
                          <EvaluationMethod>
                            <h5>{t(`Checkout.insured.bonusMalus`)}</h5>
                            <EvaluationWrapper>
                              <h4>{bonusMalus != null ? bonusMalus : '-'}</h4>
                            </EvaluationWrapper>
                          </EvaluationMethod>
                        </>
                      )
                    ) : null}
                    {/* data.insurance.type === 'RESIDENCE' ? (
                      <>
                        <EvaluationMethod>
                          <h5>{t('Evaluation method')}</h5>
                          <EvaluationWrapper>
                            <h4>Company raster</h4>
                          </EvaluationWrapper>
                        </EvaluationMethod>

                        <ValueBlock>
                          <h5>Building value</h5>
                          <h4>&euro;&nbsp;350.000</h4>
                        </ValueBlock>

                        <ValueBlock>
                          <h5>Content value</h5>
                          <h4>&euro;&nbsp;135.000</h4>
                        </ValueBlock>
                      </>
                    ) :  */}
                  </OfferEvaluation>

                  {(data?.acknowledgements || []).length > 0 ? (
                    <section>
                      <ContractTitle>{t('Acknowledgements')}</ContractTitle>

                      <ContractAcknowledgements>
                        {data?.acknowledgements.map(acknowledgement => (
                          <div key={acknowledgement?.id}>
                            <CheckBox
                              disabled
                              checked={acknowledgement?.agreed}
                              name={acknowledgement?.id}
                            >
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: acknowledgement?.text,
                                }}
                              ></p>
                            </CheckBox>
                          </div>
                        ))}
                      </ContractAcknowledgements>
                    </section>
                  ) : null}

                  {data?.documents ? (
                    <>
                      <Divider />
                      <section>
                        <ContractTitle>{t('Contract documents')}</ContractTitle>
                        {data?.documents?.map(document => {
                          return (
                            <ContractDocument
                              key={document.id}
                              onClick={() => {
                                handleContractDocumentClick(
                                  document.link,
                                  document.filename,
                                );
                              }}
                            >
                              <img src={PDF} alt="PDF" draggable="false" />
                              {t(`document_types.${document.type}`)}
                            </ContractDocument>
                          );
                        })}
                      </section>
                    </>
                  ) : null}

                  {/* <section>
                  <ContractTitle>Status of documents</ContractTitle>

                  <OfferDocuments>
                    <OfferDocument>
                      <h4>Offerte</h4>
                      <h5>Pending</h5>
                    </OfferDocument>

                    <OfferDocument signed>
                      <h4>Offerte</h4>
                      <h5>Signed</h5>
                    </OfferDocument>

                    <OfferDocument>
                      <h4>Offerte</h4>
                      <h5>Pending</h5>
                    </OfferDocument>

                    <OfferDocument>
                      <h4>Offerte</h4>
                      <h5>Pending</h5>
                    </OfferDocument>
                  </OfferDocuments>
                </section> */}
                  {editContractMode ? (
                    <SectionContentFooter>
                      <ActionButton level="default" onClick={ToggleEditMode}>
                        {t('Cancel')}
                      </ActionButton>
                      <Spacer />
                      <ActionButton
                        submit
                        disabled={commencementDateError.length > 0}
                      >
                        {t('Save')}
                      </ActionButton>
                    </SectionContentFooter>
                  ) : null}
                </Section.Content>
              </Section>
            </form>
          </section>

          <section ref={refs[1][2]}>
            <CRMProspectClientData
              prospectData={updatedData.prospect}
              distributionId={distributionId}
              customerPageFunction={editValue =>
                handleEditModeChange(editValue)
              }
            />
          </section>
          <section ref={refs[1][3]}>
            <SubTitle>{t('Risk objects')}</SubTitle>
            <CRMRiskObjectTabsOffers
              riskObjects={updatedData?.risk_objects}
              contractState={omitBy(
                {
                  editMode: editContractMode,
                  hasRecalculated: contractHasRecalculated,
                  isBeingEdited,
                  tempGuarantees,
                  guaranteeEndDates,
                  expiryDate,
                  data: cloneDeep(data),
                  bonusMalus,
                  paymentSpread,
                  updateRequired: updateContractRequired,
                  distributionId,
                  updateRecalcRequired,
                  insuranceLogo,
                  updatedData: cloneDeep(updatedData),
                  updatedTeachersData,
                  updatedCarData:
                    Object.keys(updatedCarData).length > 0
                      ? updatedCarData
                      : undefined,
                  updatedResidenceData:
                    Object.keys(updatedResidenceData).length > 0
                      ? updatedResidenceData
                      : undefined,
                  updatedFamiliesData:
                    Object.keys(updatedFamiliesData).length > 0
                      ? updatedFamiliesData
                      : undefined,
                  updatedTwoWheelerData:
                    Object.keys(updatedTwoWheelerData).length > 0
                      ? updatedTwoWheelerData
                      : undefined,
                  commencementDate,
                  commencementDateError,
                  initialGuarantees,
                  totalNetPrice,
                  totalTaxPrice,
                  totalPremiumPrice,
                  contractType,
                  insuranceCompanyName,
                  insuranceName,
                  guaranteeNames,
                  termsLink,
                  ipidLink,
                  overwriteInsuranceWith,
                  endDateError,
                  guaranteeCommencementDates,
                },
                isNil,
              )}
              contractCaseId={data.contract_case_id}
              contractId={data.id}
              prospectId={match.params.prospectId}
            />

            {/* <p>
              We zijn hard aan het werk om dit onderdeel zo snel mogelijk aan te
              bieden.
            </p> */}
          </section>
          <section ref={refs[1][4]}>
            <Section>
              <Section.Title>{t('Notes')}</Section.Title>

              <Section.Content background>
                <form onSubmit={formSubmitHandler}>
                  <TextArea
                    maxLength={-1}
                    name="comment"
                    error={errors.comment}
                    onChange={val => handleChange(val)}
                    value={values.comment ? values.comment : ''}
                    data-test-id="quote_note_inputfield"
                  >
                    {t('Add a new note')}
                  </TextArea>

                  <NoteButtonWrapper>
                    <CheckBox
                      name="private"
                      onChange={handlePrivateChange}
                      checked={values.private}
                      data-test-id="quote_note_checkbox_private"
                    >
                      {t('Private note?')}
                    </CheckBox>

                    <ActionButton
                      type="submit"
                      value="Submit"
                      disabled={errors.comment ? true : false}
                      data-test-id="quote_note_btn_add"
                    >
                      {t('Add a new note')}
                    </ActionButton>
                  </NoteButtonWrapper>
                </form>

                {notes
                  .slice(0)
                  .reverse()
                  .map((noteObject, i) => {
                    const { note } = noteObject;
                    return (
                      <>
                        <Divider />
                        <div data-test-id={`quote_comment_${i}`}>
                          <CommentBox
                            icon={<IconDocumentFilled color="#CCCCCC" />}
                            id={note.id}
                            title={`${formatISODate(note.created_at)} - ${t(
                              `Added by`,
                            )} ${
                              note?.author
                                ? brokers?.length > 0
                                  ? brokers?.find(
                                      item => item?.broker?.id === note?.author,
                                    )?.broker?.name
                                  : t('loading')
                                : t('Client')
                            }`}
                            key={note.id}
                            deleteHandler={() => deleteNoteHandler(note.id)}
                          >
                            {note.text}
                          </CommentBox>
                        </div>
                      </>
                    );
                  })}
              </Section.Content>
            </Section>
          </section>

          {/* <Section>
            <Section.Title>Documents</Section.Title>

            <Section.Content>
              <AddEntityButton
                fullwidth
                icon="Upload"
                name="Click or drag files here to upload them"
              />
            </Section.Content>
          </Section> */}

          {/* <section ref={refs[1][4]}>
            <Section>
              <Section.Title>Sticky notes</Section.Title>

              <Section.Content background>
                <form onSubmit={formSubmitHandler}>
                  <TextArea
                    maxLength={-1}
                    name="comment"
                    error={errors.comment}
                    onChange={val => handleChange(val)}
                    value={values.comment ? values.comment : ''}
                  >
                    Add a new note
                  </TextArea>

                  <NoteButtonWrapper>
                    <CheckBox
                      name="private"
                      onChange={handlePrivateChange}
                      checked={values.private}
                    >
                      Private note?
                    </CheckBox>

                    <ActionButton
                      type="submit"
                      value="Submit"
                      disabled={errors.comment ? true : false}
                    >
                      {t('Add a new note')}
                    </ActionButton>
                  </NoteButtonWrapper>
                </form>

                {Notes.map(note => {
                  return (
                    <>
                      <Divider />

                      <CommentBox
                        icon={<IconDocumentFilled color="#ccc" />}
                        id={note.id}
                        title={`${note.created_at} - Added by ${note.author}`}
                        key={note.id}
                      >
                        {note.text}
                      </CommentBox>
                    </>
                  );
                })}
              </Section.Content>
            </Section>
          </section> */}
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

const ContractDocument = styled.a`
  background-color: ${({ theme }) => theme.ui.background};
  border-radius: 8px;
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem;
  transition: box-shadow 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
  margin-top: 1rem;
  position: relative;
  padding-left: 4rem;
  max-width: 30%;

  img {
    position: absolute;
    left: 1rem;
  }

  &:hover {
    box-shadow: 0 0.7px 3.3px rgba(0, 0, 0, 0.02),
      0 1.7px 8px rgba(0, 0, 0, 0.03), 0 3.1px 15.2px rgba(0, 0, 0, 0.04),
      0 5.6px 27px rgba(0, 0, 0, 0.04), 0 10.4px 50.6px rgba(0, 0, 0, 0.05),
      0 25px 121px rgba(0, 0, 0, 0.07);
    background-color: white;
  }
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const DescriptionWrapper = styled.div`
  margin-top: 2rem;
`;

const NoteButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  > button {
    margin-left: 35px;
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const StyledAlertContent = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const Spacer = styled.div`
  width: 15px;
`;

const StyledSubNavigation = styled(SubNavigation)`
  margin-bottom: 0.5rem;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OfferHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2rem;
`;

const OfferInfoLogo = styled.div`
  max-height: 3.5rem;
  flex: 0 1 auto;

  > img {
    max-height: 100%;
  }
`;

const OfferHeaderName = styled.div`
  flex: 1 0 auto;
  margin: 0 4rem;
  text-transform: capitalize;

  h3 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 130%;
    color: ${({ theme }) => theme.typo.title};
  }

  h4 {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.subTitle};
  }
`;

const OfferPrice = styled(H1)`
  flex: 0 1 auto;
  font-family: ${({ theme }) => theme.font};
  font-size: 3.6rem;
  color: ${({ theme }) => theme.typo.title};

  span {
    font-size: 2.1rem;
  }
`;

const OfferGuarantees = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.ui.outline};
  border-bottom: 1px solid ${({ theme }) => theme.ui.outline};
  padding: 2.4rem 0;
  overflow-x: scroll;
`;

const DownloadableDoc = styled.a`
  display: flex;
  margin-top: 1.2rem;
  cursor: pointer;

  img {
    margin-right: 1.2rem;
    max-height: 2rem;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const OfferEvaluation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  h5 {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.4rem;
    line-height: 2rem;
    color: ${({ theme }) => theme.typo.subTitle};
    font-weight: bold;
  }

  h4 {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }
`;

const EvaluationMethod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 0 0 31%;
  background-color: ${({ theme }) => theme.ui.background};
  padding: 1.2rem;
  border-radius: 8px;

  h4,
  h5 {
    text-align: center;
  }
`;

const EvaluationWrapper = styled.div`
  background: #f0f1f3;
  border-radius: 5px;
  padding: 1.2rem;
  margin-top: 0.6rem;
`;

// const ValueBlock = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: flex-start;
//   flex: 0 0 31%;
//   background-color: ${({ theme }) => theme.ui.background};
//   padding: 1.6rem;
//   border-radius: 8px;

//   h5 {
//     margin-bottom: 0.5rem;
//   }
// `;

// const OfferDocuments = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   margin-top: 2.4rem;

//   > div {
//     flex: 0 0 calc(25% - 14px);
//   }
// `;

// const OfferDocument = styled.div`
//   background-color: ${({ theme }) => theme.ui.background};
//   border-radius: 8px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1.2rem;

//   h4 {
//     font-size: 1.4rem;
//     font-weight: bold;
//     color: ${({ theme }) => theme.typo.text};
//   }

//   h5 {
//     font-size: 1.4rem;
//     font-weight: bold;
//     color: ${({ theme, signed }) =>
//       signed ? theme.status.succes : theme.status.warning};
//   }
// `;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  visibility: hidden;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    height: unset;
    line-height: 1.6rem;
    margin-right: 0.78vw;
    min-height: 4rem;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

// const SubTitleSpacer = styled.div`
//   height: 20px;
// `;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionContentInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 15px;
  }
`;

// const RiskObjectSpacer = styled.div`
//   height: 15px;
// `;

// const NoteButtonWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin-top: 20px;

//   > button {
//     margin-left: 35px;
//   }
// `;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.ui.outline};
  margin: 25px 0;
`;

const OnlyTopDivider = styled(Divider)`
  margin-bottom: 0;
`;

const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.ui.outline};
  margin-bottom: 15px;

  section {
    display: flex;
    align-items: center;
  }
`;

const ContractHeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;

  p:first-child {
    margin-bottom: 0.5rem;
  }
`;

const ContractTitle = styled.h2`
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 130%;
  color: ${({ theme }) => theme.typo.title};
`;

const ContractAcknowledgements = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 2rem;

  > div {
    margin: 0.5rem 0;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
`;

const StyledDateInput = styled(DateInput)`
  & > div > p {
    line-height: 0.9;
  }

  & > label > div {
    margin-top: 2rem;
    height: 3rem;
  }
`;

const StyledGuaranteeTextInput = styled(TextInput)`
  height: auto;
  & > label > input {
    height: 3rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 1.4rem;
    margin-top: 0;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  & > label > div {
    font-size: 1.4rem;
    width: 1.5rem;
    padding-top: 4px;
    display: flex;
    align-items: center;
    height: 3rem;
  }
`;

const StyledBodyCell = styled(StripedTable.BodyCell)`
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  min-width: 95px;
`;

const StyledHeaderCell = styled(StripedTable.HeaderCell)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const StyledFooterCell = styled(StripedTable.FooterCell)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

CRMContractDetail.propTypes = {
  match: shape(),
};

export default CRMContractDetail;
