import React, { useEffect, useState, useRef } from 'react';
import { array, shape, string } from 'prop-types';
import CompareMatrixTableHeaderCell from './CompareMatrixTableHeaderCell';
import LoadingCompareIcons from './LoadingCompareIcons';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const CompareMatrix = ({ comparisonList, packs, insurType }) => {
  const { t } = useTranslation();
  const [combinedPacks, setCombinedPacks] = useState([]);
  const [categoryTitles, setCategoryTitles] = useState([]);
  const [tableHeadHeight, setTableHeadHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const theadRef = useRef(null);
  let evenNumbers = 0;
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    switch (insurType) {
      case 'home':
        setCategoryTitles([
          {
            Property: ['solar_panels', 'private_garage'],
          },
          {
            Garden: [
              'plantation',
              'garden_content',
              'garden_house',
              'swimming_pool',
            ],
          },
          {
            Content: [
              'juwels',
              'art',
              'bicycle',
              'informatics',
              'pets',
              'vehicle',
            ],
          },
          {
            Others: [
              'works_at_home',
              'travels',
              'students',
              'guests',
              'disabilities',
            ],
          },
        ]);
        break;
      case 'family':
        setCategoryTitles([
          {
            General: [
              'quality',
              'children',
              'e_step',
              'drone',
              'shared_objects',
            ],
          },
          {
            LegalAid: ['legal_coverage', 'legal_total'],
          },
        ]);
        break;
      case 'car':
        setCategoryTitles([
          {
            General: ['quality', 'car_value', 'total_loss_definition'],
          },
          {
            Coverages: [
              'coverage',
              'theft',
              'animals_damage',
              'nature_damage',
              'glass_breakage',
            ],
          },
          {
            Others: ['coverage_abroad', 'replacement_car'],
          },
        ]);
        break;
      case 'legal':
        setCategoryTitles([
          {
            General: [
              'consumer',
              'income',
              'mobility',
              'private_life',
              'property',
              'vehicle',
            ],
          },
        ]);
        break;
      default:
        return;
    }
  }, [insurType]);

  useEffect(() => {
    setTableHeadHeight(theadRef.current.clientHeight);
  });

  // add comparison data with corresponding pack

  useEffect(() => {
    packs.forEach(pack => {
      Object.keys(comparisonList).map(key => {
        // check if id's match and it doesn't already exist
        if (pack.id === key && !combinedPacks.some(e => e.id === key)) {
          pack.comparison = comparisonList[key];
          let list = [...subCategories];
          Object.keys(pack.comparison).map(value => {
            if (!list.includes(value)) {
              list.push(value);
            }
          });
          setSubCategories(list);
          setCombinedPacks([...combinedPacks, pack]);
        }
      });
    });
  });

  return (
    <Table>
      <Thead ref={theadRef} scroll={scrollHeight}>
        <tr>
          <EmptyDataCell />
          {combinedPacks.map(pack => {
            return (
              <TableHead
                scope="col"
                numberPacks={combinedPacks.length}
                key={pack.id}
              >
                <CompareMatrixTableHeaderCell
                  id={pack.id}
                  insurerName={pack.insurerName}
                  insuranceName={pack.insuranceName}
                  insurerLogo={pack.insurerLogo}
                  score={pack.score}
                  premium={pack.premium}
                  selectedPackages={pack.selectedPackages}
                  packages={pack.packages}
                />
              </TableHead>
            );
          })}
          {Array.from({ length: 5 - combinedPacks.length }).map((key, i) => {
            return <EmptyDataCell short key={i} />;
          })}
        </tr>
      </Thead>
      <Tbody
        onScroll={e => setScrollHeight(e.target.scrollTop)}
        tableHeadHeight={tableHeadHeight}
      >
        {categoryTitles.map((category, i) => (
          <>
            <tr key={i}>
              <SidebarCategory scope="row">
                {t(`Checkout.matrix_category.${Object.keys(category)}`)}
              </SidebarCategory>
            </tr>
            {category[Object.keys(category)].map((item, categoryIndex) => {
              if (subCategories.find(subcat => subcat === item) != undefined) {
                evenNumbers++;
                return (
                  <DataRow greyBackground={evenNumbers % 2} key={item}>
                    <Sidebar>
                      <Icon>
                        <LoadingCompareIcons type={item} />
                      </Icon>
                      <IconLabel>
                        {insurType === 'legal' && item === 'vehicle'
                          ? t('vehicle')
                          : t(`Checkout.matrix_subCategory.${item}`)}
                      </IconLabel>
                    </Sidebar>
                    {combinedPacks.map((pack, i) => {
                      return (
                        <DataCell numberPacks={combinedPacks.length} key={i}>
                          {categoryIndex !== 0 ? <Seperator /> : null}
                          {pack.comparison[item] === undefined ||
                          pack.comparison[item] === 'none' ||
                          pack.comparison[item] === 0 ? (
                            <NotCovered>{t('Not covered')}</NotCovered>
                          ) : (
                            <LoadingCompareIcons type={pack.comparison[item]} />
                          )}
                        </DataCell>
                      );
                    })}
                    {Array.from({ length: 5 - combinedPacks.length }).map(
                      (key, i) => {
                        return <EmptyDataCell short key={i} />;
                      },
                    )}
                  </DataRow>
                );
              }
            })}
          </>
        ))}
      </Tbody>
    </Table>
  );
};

const NotCovered = styled.p`
  font-weight: 900;
  color: ${({ theme }) => theme.typo.interactive};
  font-size: 1.6rem;
  @media print {
    color: black;
  }
`;

const Icon = styled.div`
  background-color: ${({ theme }) => theme.brand.secondary};
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  & svg {
    width: 3.5rem;
    height: 3.5rem;
  }
`;

const Seperator = styled.span`
  background-color: #9a9a9a4b;
  height: 0.1rem;
  width: 80%;
  position: absolute;
  top: -0.05rem;
  left: 10%;

  ::after {
    content: '';
  }
`;

const DataRow = styled.tr`
  background-color: ${props => (props.greyBackground ? '#9a9a9a15' : 'white')};

  @media print {
    page-break-inside: avoid;
    page-break-after: auto;
    background-color: ${props => (props.greyBackground ? '#F7F7F7' : 'white')};
  }
`;

const DataCell = styled.td`
  width: 16vw;
  padding: 1rem;
  border-left: 0.1rem solid #9a9a9a4b;
  text-align: center;
  position: relative;

  & img {
    width: 9.5rem;
  }
`;

const EmptyDataCell = styled.td`
  width: ${props => (props.short ? '16vw' : 'calc(20vw + 0.1rem)')};
  border-left: ${props => (props.short ? '0.1rem solid #9a9a9a4b' : 'none')};

  @media print {
    width: ${props => (props.short ? '0' : 'calc(16vw + 0.1rem)')};
  }
`;

const SidebarCategory = styled.th`
  font-weight: 900;
  font-size: 1.8rem;
  text-align: left;
  padding: 3rem 2rem;
`;

const IconLabel = styled.label`
  margin-top: 1rem;
  font-size: 1.5rem;
  color: #aeaeae;
  line-height: 132%;
  @media print {
    color: black;
    max-height: 4rem;
  }
`;

const Sidebar = styled.th`
  width: calc(20vw - 0.4rem);
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TableHead = styled.th`
  width: 16vw;
  padding: 1.2rem;
  border-left: 0.1rem solid #9a9a9a4b;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  background-color: white;
  @media print {
    height: 100vh;
  }
`;

const Tbody = styled.tbody`
  display: block;
  width: 100%;
  overflow: auto;
  height: calc(90vh - ${props => props.tableHeadHeight}px);

  @media print {
    display: table-row-group;
    height: auto;
    overflow: hidden;
    width: 100%;
    page-break-inside: avoid;
    page-break-after: auto;

    & tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
  }
`;

const Thead = styled.thead`
  @media print {
    display: table-header-group;
  }
  & tr {
    transition: box-shadow 0.15s;
    display: block;
    width: 100%;
    position: relative;
    background-color: white;
    @media print {
      page-break-inside: avoid;
      page-break-after: auto;
      display: table-row;
    }
    box-shadow: ${props =>
      props.scroll > 10
        ? '0 0.5rem 2rem rgba(0, 0, 0, 0.1)'
        : '0 0.1rem .1rem rgba(0, 0, 0, 0.1)'};
  }
`;

CompareMatrix.propTypes = {
  comparisonList: shape().isRequired,
  packs: array.isRequired,
  insurType: string.isRequired,
};

export default CompareMatrix;
