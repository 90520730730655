import React from 'react';
import { string } from 'prop-types';

const Art = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    fill="none"
    viewBox="0 0 25 25"
  >
    <path
      fill={color}
      d="M17.527 9.595a2.138 2.138 0 01-1.162 1.878 2.15 2.15 0 01-2.297-.23c-.5-.392-.811-1-.811-1.675 0-.338.08-.676.23-.96.459-.932 1.58-1.473 2.756-1.013.798.31 1.298 1.135 1.284 2zM18.527 14.5h.878c.149 0 .27.122.27.27v4.649c0 .149-.121.27-.27.27H5.581a.271.271 0 01-.27-.27v-7.176c0-.148.121-.27.27-.27h1.797c1.717 0 3.338.432 4.744 1.203a9.89 9.89 0 012.364 1.784.87.87 0 00.946.175 7.883 7.883 0 013.095-.635z"
    />
    <path
      fill={color}
      d="M21.851 22.122H3.15a.271.271 0 01-.27-.27V3.148c0-.149.121-.27.27-.27h18.716c.149 0 .27.121.27.27v18.716a.291.291 0 01-.284.257zM0 .676v23.648A.67.67 0 00.676 25h23.648a.67.67 0 00.676-.676V.676A.67.67 0 0024.324 0H.676A.67.67 0 000 .676z"
    />
  </svg>
);

Art.propTypes = {
  color: string.isRequired,
};

export default Art;
