import React from 'react';
import { string } from 'prop-types';

const MailIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="18"
    fill="none"
    viewBox="0 0 26 18"
  >
    <path
      fill={color}
      d="M0 17.255V1.505C3.034 4.398 5.857 7.073 8.68 9.79c.422.388.844.82 1.265 1.208 2.275 2.158 3.835 2.158 6.152-.043 3.245-3.063 6.448-6.17 9.86-9.45v15.75H0z"
    />
    <path
      fill={color}
      d="M1.432.506h23.134c-1.854 1.769-3.497 3.322-5.14 4.919-1.686 1.596-3.372 3.236-5.058 4.832-.8.777-1.685.95-2.528.173C8.47 7.237 5.098 4 1.432.506z"
    />
  </svg>
);

MailIcon.propTypes = {
  color: string.isRequired,
};

export default MailIcon;
