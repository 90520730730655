import React from 'react';
import { string } from 'prop-types';

const AtticIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      fill="none"
      viewBox="0 0 20 22"
    >
      <path
        fill={color}
        d="M12.642 13.717a.68.68 0 01-.973 0l-.974-.976v3.913c0 .38-.31.692-.69.692a.694.694 0 01-.692-.692v-3.922l-.973.975a.682.682 0 01-.973.069.686.686 0 01-.068-.976c.02-.03.049-.049.068-.068l2.141-2.156a.68.68 0 01.973 0l2.161 2.156a.696.696 0 010 .985z"
      ></path>
      <path
        fill={color}
        d="M19.708 6.06l-2.579-1.64V1.115a.628.628 0 00-.623-.625h-2.453a.628.628 0 00-.622.625v.936L10.365.1a.623.623 0 00-.662 0L.292 6.059A.618.618 0 000 6.588v14.517a.91.91 0 00.905.907h18.19a.91.91 0 00.905-.907V6.577a.602.602 0 00-.292-.517zm-.973 14.673H1.265V7.006h17.47v13.727z"
      ></path>
    </svg>
  );
};

AtticIcon.defaultProps = {
  color: `white`,
};

AtticIcon.propTypes = {
  color: string,
};

export default AtticIcon;
