import React, { useState, useEffect, createRef, useContext } from 'react';
import i18n from '../../../i18n';

import { AFFINITY_BASE_URL } from '../../../env.config';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  getAllAffinities,
  getAffinityInsurancesByCompanyKey,
  retrieveAffinitiesById,
} from '../../services/apiRouterService';

import {
  SubNavigation,
  Section,
  LoadingSpinner,
  IconCarFilled,
  IconMotor,
  IconSixWheeler,
  IconPropertyFilled,
  IconLegalFilled,
  IconStats,
  IconFlightFilled,
  SearchSelectInput,
} from 'wg-fe-ui';
import { getMeData } from '../../services/meDataService';
import { getBrokerData } from '../../services/brokerDataService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';

const CRMAffinitiesOverview = match => {
  const [isLoading, setIsLoading] = useState(true);
  const [retrievedData, setRetrievedData] = useState();
  const [sections, setSections] = useState([]);
  const [affinities, setAffinities] = useState([]);
  const [userId, setUserId] = useState(null);
  const [siteLanguage, setSiteLanguage] = useState(i18n.language);
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const [isCallantEmployee, setIsCallantEmployee] = useState(false);
  const configCatClient = useContext(FlagsProvider);

  const { t } = useTranslation();
  const refs = {};

  const translatedInsuranceTypes = t('InsuranceTypes', { returnObjects: true });

  console.log('DEBUG BASE_URL: ', AFFINITY_BASE_URL);

  const insuranceTypes = {
    CAR: {
      label: translatedInsuranceTypes.Car,
      icon: <IconCarFilled size={25} />,
    },

    TWO_WHEELER: {
      label: translatedInsuranceTypes.Bike,
      icon: <IconMotor size={25} />,
    },

    TEACHER: {
      label: translatedInsuranceTypes.Teacher,
      icon: <IconStats size={25} />,
    },

    HOME: {
      label: translatedInsuranceTypes.Property,
      icon: <IconPropertyFilled size={25} />,
    },

    LEGAL: {
      label: translatedInsuranceTypes.Legal,
      icon: <IconLegalFilled size={25} />,
    },

    '6_WHEELER': {
      label: translatedInsuranceTypes.Sixwheeler,
      icon: <IconSixWheeler size={25} />,
    },

    TRAVEL: {
      label: translatedInsuranceTypes.Travel,
      icon: <IconFlightFilled size={25} />,
      url: 'http://callantatwork.affinity-europ-assistance.be/nl',
    },
  };

  const languageOptions = [
    {
      value: 'nl',
      label: t('Dutch'),
    },
    {
      value: 'fr',
      label: t('French'),
    },
  ];

  useEffect(() => {
    setFeature();
  }, []);

  async function setFeature() {
    const { me, id } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const emailConfig = {
      email: me?.email,
      custom: {
        plan: broker?.plan,
      },
    };

    setIsCallantFeature(
      await configCatClient.getValueAsync('callantFeature', true, emailConfig),
    );

    setIsCallantEmployee(me?.email.includes('@callant.be'));

    setUserId(id);
  }

  useEffect(() => {
    if (userId) {
      filterBrokerAffinities();
    }
  }, [userId]);

  async function filterBrokerAffinities() {
    let [brokerAffinities, statusAff] = await retrieveAffinitiesById(userId);

    let [response, status] = await getAllAffinities();

    if (status !== 200) {
      return console.error('Error Response: ', response);
    }

    const sortedItems = await response.items.sort((a1, a2) => {
      if (a1?.affinity_info?.names?.nl > a2?.affinity_info?.names?.nl) return 1;
      if (a1?.affinity_info?.names?.nl < a2?.affinity_info?.names?.nl)
        return -1;
      return 0;
    });

    const filteredItems = [];

    if (statusAff !== 200) {
      return console.error('Error Response: ', brokerAffinities);
    } else {
      const affinitiesToDisplay = brokerAffinities?.items || [];
      affinitiesToDisplay.forEach(affinityTD => {
        const companyKey = affinityTD?.affinity?.company_key;
        const keys = affinityTD?.affinity?.affinity_keys || [];
        sortedItems.forEach(item => {
          if (
            keys.includes(item?.affinity_info?.key) &&
            item?.affinity_info?.company_key === companyKey
          ) {
            filteredItems.push(item);
          }
        });
      });
    }

    setRetrievedData(filteredItems);
    setIsLoading(!isLoading);
  }

  async function setInsurancesFromAffities(companyKey, affinityKey) {
    let [response, status] = await getAffinityInsurancesByCompanyKey(
      companyKey,
      affinityKey,
    );

    if (status !== 200) {
      return console.error('Error Response: ', response);
    }

    return response.items;
  }

  useEffect(() => {
    const newAffinities = retrievedData;

    if (newAffinities != null) {
      newAffinities.forEach(async (affinity, i) => {
        //Add insurances to affinity object
        newAffinities[i] = {
          ...newAffinities[i],
          insurances: await setInsurancesFromAffities(
            newAffinities[i]?.affinity_info?.company_key,
            newAffinities[i]?.affinity_info?.key,
          ),
        };

        if (
          affinity?.affinity_info?.key === 'de_lijn' ||
          affinity?.affinity_info?.key === 'at_work' ||
          affinity?.affinity_info?.key === 'doceo' ||
          affinity?.affinity_info?.key === 'agoria'
        ) {
          newAffinities[i].insurances.push({
            insurance: {
              id: null,
              type: 'HOME',
              names: {
                nl: 'Woningen',
                fr: 'Résidences',
                de: null,
                en: 'Residences',
              },
            },
          });
          newAffinities[i].insurances.push({
            insurance: {
              id: null,
              type: '6_WHEELER',
              names: {
                nl: 'Zeswielers',
                fr: 'Six roues',
                de: null,
                en: 'Six wheelers',
              },
            },
          });
          newAffinities[i].insurances.push({
            insurance: {
              id: null,
              type: 'TRAVEL',
              names: {
                nl: 'Reizen',
                fr: 'Voyager',
                de: null,
                en: 'Travel',
              },
            },
          });
        }

        if (affinity?.affinity_info?.key === 'motor_insurance') {
          newAffinities[i].insurances.push({
            insurance: {
              id: null,
              type: '6_WHEELER',
              names: {
                nl: 'Zeswielers',
                fr: 'Six roues',
                de: null,
                en: 'Six wheelers',
              },
            },
          });
        }
        setAffinities(prevValue => {
          return [...prevValue, newAffinities[i]].sort((a1, a2) => {
            if (a1?.affinity_info?.key === 'motor_insurance') return -100;
            if (a2?.affinity_info?.key === 'motor_insurance') return 100;
            if (a1?.affinity_info?.names?.nl > a2?.affinity_info?.names?.nl)
              return 1;
            if (a1?.affinity_info?.names?.nl < a2?.affinity_info?.names?.nl)
              return -1;
            return 0;
          });
        });
      });
    }
  }, [retrievedData]);

  useEffect(() => {
    const subSections = [];

    affinities.forEach((affinity, i) => {
      if (affinity?.affinity_info?.company_key === 'callant') {
        subSections[i] = {
          id: i + 1,
          label: affinity?.affinity_info?.names?.[i18n.language],
        };
      }
    });

    const sortedSubSections = subSections.sort((s1, s2) => {
      if (s1?.label?.toLowerCase()?.includes('motor')) return -100;
      if (s2?.label?.toLowerCase()?.includes('motor')) return 100;
      if (s1?.label > s2?.label) return 1;
      if (s1?.label < s2?.label) return -1;
      return 0;
    });

    setSections([
      { id: 1, label: t('Websites'), subsections: sortedSubSections },
    ]);
  }, [affinities]);

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <>
      {isCallantFeature ? (
        <MainContainer>
          <MainContent>
            <Sidebar>
              {sections.map(section => (
                <SubNavigation key={section.id}>
                  <SubNavigation.Section>{section.label}</SubNavigation.Section>
                  <SubNavigation.SectionContainer
                    active={section.id === currentSection.section}
                  >
                    {section.subsections.map(subsection => (
                      <SubNavigation.SubSection
                        style={{ width: '20rem' }}
                        key={subsection.id}
                        onClick={() =>
                          handleSideBarClick(section.id, subsection.id)
                        }
                        active={
                          currentSection.section === section.id &&
                          currentSection.subsection === subsection.id
                        }
                      >
                        {subsection.label}
                      </SubNavigation.SubSection>
                    ))}
                  </SubNavigation.SectionContainer>
                </SubNavigation>
              ))}
            </Sidebar>

            <Content>
              <Section>
                <Section.Title>
                  {t('Settings.languageSettings.title')}
                </Section.Title>
                <Section.Content
                  background
                  data-test-id="dashboard_settings_section_user_settings_content_language"
                  id="changelanguageSection"
                >
                  <SelectLanguage
                    id="changelanguage"
                    name="language"
                    options={languageOptions}
                    onSelected={l => {
                      setSiteLanguage(l.value);
                    }}
                    initial={
                      languageOptions.filter(
                        lang => lang.value === siteLanguage.toLowerCase(),
                      )?.[0]
                    }
                    data-test-id="dashboard_settings_section_user_settings_content_language_select"
                    autoComplete="off"
                  >
                    {''}
                  </SelectLanguage>
                </Section.Content>
              </Section>
              {affinities.length > 0
                ? affinities.map((affinity, i) => {
                    return (
                      <section ref={refs[1][i + 1]} key={i}>
                        <Section>
                          <Section.Title>
                            {affinity?.affinity_info?.names?.[i18n.language]}
                          </Section.Title>

                          <Section.Content background>
                            <AffinityHeader>
                              <img
                                src={affinity?.affinity_info?.logo}
                                alt={`${
                                  affinity?.affinity_info?.names?.[
                                    i18n.language
                                  ]
                                } logo`}
                              />

                              <h2>
                                {
                                  affinity?.affinity_info?.names?.[
                                    i18n.language
                                  ]
                                }
                              </h2>
                            </AffinityHeader>

                            <Insurances>
                              {affinity?.insurances?.length > 0
                                ? affinity.insurances.map(
                                    ({ insurance }, i) => {
                                      let name = insurance.names[i18n.language];

                                      return (
                                        <Insurance
                                          key={i}
                                          target="_blank"
                                          href={
                                            insurance.type === 'TRAVEL'
                                              ? `http://callantatwork.affinity-europ-assistance.be/${siteLanguage}`
                                              : `${AFFINITY_BASE_URL}#/${affinity?.affinity_info?.key.replace(
                                                  '_',
                                                  '-',
                                                )}/${insurance.type
                                                  .replace('_', '-')
                                                  .toLowerCase()}/verify-user?lang=${siteLanguage}&refId=${userId}${
                                                  isCallantEmployee
                                                    ? '&moderator=true'
                                                    : ''
                                                }`
                                          }
                                        >
                                          <IconWrapper>
                                            {
                                              insuranceTypes[insurance.type]
                                                .icon
                                            }
                                          </IconWrapper>

                                          <p>{name}</p>
                                        </Insurance>
                                      );
                                    },
                                  )
                                : null}
                            </Insurances>

                            {/* <Insurances>
                          {affinity.affinity_info.insurances.map(
                            (insurance, i) => {
                              let name = insurance;
                              console.log(name);
                              if (name === '6_wheeler') {
                                name = 'six_wheeler';
                              }

                              return (
                                <Insurance
                                  key={i}
                                  target="_blank"
                                  href={
                                    name === 'travel'
                                      ? `http://callantatwork.affinity-europ-assistance.be/nl`
                                      : `${AFFINITY_BASE_URL}${affinity?.affinity_info?.key.replace(
                                          '_',
                                          '-',
                                        )}/${insurance.replace(
                                          '_',
                                          '-',
                                        )}/verify-user?lang=${i18n.language}`
                                  }
                                >
                                  <IconWrapper>
                                    {insuranceTypes[name].icon}
                                  </IconWrapper>

                                  <p>{insuranceTypes[name].label}</p>
                                </Insurance>
                              );
                            },
                          )}
                        </Insurances> */}
                          </Section.Content>
                        </Section>
                      </section>
                    );
                  })
                : null}
            </Content>
          </MainContent>
        </MainContainer>
      ) : (
        ''
      )}
    </>
  );
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 150px;
  margin-right: 32px;
  flex: 0 0 220px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 110rem;
  width: 100%;
`;

const AffinityHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.ui.interactive};

  img {
    max-height: 5rem;
    max-width: 10rem;
    margin-right: 2.4rem;
  }

  h2 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.title};
  }
`;

const Insurances = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`;

const Insurance = styled.a`
  display: flex;
  flex: 0 0 calc(25% - 1.6rem);
  margin: 0.8rem 1.6rem 0.8rem 0;
  padding: 1.6rem;
  align-items: center;
  background: white;
  border: 1px solid ${({ theme }) => theme.ui.outline};
  box-sizing: border-box;
  box-shadow: 0 6px 16px rgba(181, 181, 181, 0.05);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  text-decoration: none;

  p {
    font-weight: bold;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.typo.subTitle};
    transition: 0.2s ease-in-out;
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.brand.lighter};
    box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.15);

    p {
      color: ${({ theme }) => theme.typo.title};
    }
  }
`;

const IconWrapper = styled.div`
  margin-right: 1.6rem;
  background-color: ${({ theme }) => theme.brand.lightest};
  border-radius: 5px;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg path {
    fill: ${({ theme }) => theme.brand.primary};
    stroke: ${({ theme }) => theme.brand.primary};
  }
`;

const SelectLanguage = styled(SearchSelectInput)`
  & .Select {
    &__option {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.typo.interactive};

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
        color: white;
      }

      &--is-focused {
        color: white;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background-color: ${({ theme }) => theme.brand.light};
        color: black;
      }
    }
  }
`;

export default CRMAffinitiesOverview;
