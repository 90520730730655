import React, { useState } from 'react';
import { array, string, bool, shape, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';

import { Modal, SearchSelectInput, TextArea, ActionButton } from 'wg-fe-ui';

import { getEnumByName, changeOfferStatus } from '../services/apiRouterService';

const CRMOfferRejectionModal = ({
  data,
  setData,
  canClose,
  modalState,
  distributionId,
  offerData,
  rejectionStatus,
}) => {
  const [showRejectionModal, setShowRejectionModal] = modalState;
  const [reasonOfCancellation, setReasonOfCancellation] = useState({});
  const [cancellationDisc, setCancellationDisc] = useState();
  const { t } = useTranslation();

  async function loadReasonOfRejection() {
    const [resp, status] = await getEnumByName(
      'OFFER_REJECTION_CANCELLATION_REASON',
    );
    if (status !== 200) return [{ label: 'Api failed', value: undefined }];
    const { items } = resp;

    return items.map(item => ({ label: t(item), value: item }));
  }

  async function handleCancellation(reason, discription, status) {
    const tempData = JSON.parse(JSON.stringify(data));
    const [resp, respStatus] = await changeOfferStatus(
      distributionId,
      offerData.offer,
      offerData.revision,
      {
        status,
        reject_cancel_reason: discription || null,
        reject_cancel_reason_type: reason,
      },
    );
    if (respStatus === 200) {
      setShowRejectionModal(false);
      cogoToast.success(t('Offer status has been changed'));
      setData(prevValue => {
        const temp = JSON.parse(JSON.stringify(prevValue));
        temp[offerData.index].status = status;
        return temp;
      });
    } else if (resp.msg != null || resp.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
    } else {
      setData(tempData);
      cogoToast.error(t('Something went wrong'));
    }
  }

  return (
    <Modal
      canClose={canClose}
      modalElementClass="modal-full-width"
      showModal={showRejectionModal}
      setShowModal={setShowRejectionModal}
      title={t('Reason of cancellation')}
      width={'100%'}
    >
      <SearchSelectInput
        async
        cacheOptions
        loadOptions={loadReasonOfRejection}
        onSelected={({ value }) => setReasonOfCancellation(value)}
        placeholder={t('Choose your option')}
      ></SearchSelectInput>
      <StyledTextArea onChange={({ value }) => setCancellationDisc(value)}>
        {t('A description why the offer was canceled')}
      </StyledTextArea>
      <Modal.ModalActions position="center">
        <ActionButton
          disabled={!reasonOfCancellation || !cancellationDisc}
          level="primary"
          onClick={() =>
            handleCancellation(
              reasonOfCancellation,
              cancellationDisc,
              rejectionStatus,
            )
          }
        >
          {t('Submit')}
        </ActionButton>
      </Modal.ModalActions>
    </Modal>
  );
};

const StyledTextArea = styled(TextArea)`
  min-width: 60rem;
`;

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

CRMOfferRejectionModal.defaultProps = {
  canClose: false,
};

CRMOfferRejectionModal.propTypes = {
  canClose: bool,
  data: shape().isRequired,
  setData: func.isRequired,
  modalState: array.isRequired,
  distributionId: string.isRequired,
  rejectionStatus: string.isRequired,
  offerData: shape({
    offer: string.isRequired,
    revision: string.isRequired,
    index: number.isRequired,
  }).isRequired,
};

export default CRMOfferRejectionModal;
