import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { shape, string, func, number, object } from 'prop-types';
import { withRouter } from 'react-router';
import AddToCart from '../../LayoutMarket/components/Buttons/AddToCart';
import { useTranslation } from 'react-i18next';
import { setSkippedSteps } from '../../LayoutMarket/store/index';
import { notices, getSocialLogin } from '../../../../store';
import { Form, Formik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import * as Yup from 'yup';
import moment from 'moment';
import pdfIcon from '../../../../../assets/images/pdf-icon.svg';
import LoadingExtentionIcons from './LoadingExtentionIcons';
import DateInputComponent from '../../../../components/DateInputComponent';
import TerminationPopUp from './TerminationPopUp';
import { Link } from 'react-router-dom';
import { getPrice, retrieveRequirements } from '../store';
import i18next from 'i18next';
import Loading from '../../../../components/Loading';
import InfoPopUp from '../../../../components/InfoPopUp';
import { getNestedObject } from '../../../../services/objectService';
import MarketplaceCheckoutItemContractLength from '../../../../components/MarketplaceCheckoutItemContractLength';
import InformationRequirements from './CheckoutItemInformationRequirements';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const CheckoutItem = props => {
  const { t } = useTranslation();
  const {
    card,
    onPriceChange,
    children,
    history,
    clicked,
    id,
    type,
    addInsuranceDate,
    getErrors,
    pdfs,
    oldInsurance,
    allData,
    dataIsSet,
    allInsurers,
    priceData,
    requirements,
    setRequirements,
  } = props;
  const content = t('Checkout.contract', { returnObjects: true });
  const [tempErrors, setTempErrors] = useState({});
  const [displayedCard, setDisplayedCard] = useState(card);
  const [documenten, setDocumenten] = useState(pdfs);
  let outsideSetFieldValueLocal = null;
  const [togglen, setToggle] = useState(false);
  const [intervalText, setIntervalText] = useState(t('Checkout.year'));
  const [loadPrice, setLoadPrice] = useState(false);
  const [exceptionError, setExceptionError] = useState();
  let dateFormat = `DD-MM-YYYY`;
  let dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;
  const flowStore = useFlowStore();

  const SignupSchema = Yup.object().shape({
    contract: Yup.string()
      .required(t(`Checkout.contract.errors.invalid_date`))
      .nullable()
      .matches(dateRegex, t(`Checkout.contract.errors.invalid_date`))
      .test('DOB', t(`Checkout.contract.errors.invalid_date`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`Checkout.contract.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 0,
      )
      .test(
        'DOB',
        t(`Checkout.contract.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'month') <= 0,
      )
      .test('DOB', t(`Checkout.contract.errors.far`), value => {
        return moment().diff(moment(value, dateFormat), 'month') >= -15;
      })
      .test(
        'DOB',
        t(`Checkout.contract.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'day') <= 0,
      ),
  });

  const resetCard = () => {
    setDisplayedCard(null);
    setDocumenten(null);
    clicked();
    setSkippedSteps(id, true);
  };

  const showFranchise = (type, value) => {
    switch (type) {
      case 'fixed':
        return `€${value} ${t('Checkout.insured.franchiseTypes.fixed')}`;
      case 'variable':
        return `€${value} ${t('Checkout.insured.franchiseTypes.variable')}`;
      case 'percentage_on_compensation':
        return `${value * 100}% ${t(
          'Checkout.insured.franchiseTypes.percentage_on_compensation',
        )}`;
      case 'percentage_on_car_value':
        return `${value * 100}% ${t(
          'Checkout.insured.franchiseTypes.percentage_on_car_value',
        )} `;
      case 'english':
        return `€${value} ${t('Checkout.insured.franchiseTypes.english')}`;
      case 'identity':
        return `€${value} ${t('Checkout.insured.franchiseTypes.identity')}`;
    }
  };

  const [debouncedCallback] = useDebouncedCallback(({ value }) => {
    setExceptionError(null);
    getPrice(
      priceData.id,
      priceData.ck,
      priceData.vk,
      priceData.franchise,
      priceData.insuredValues,
      value,
    )
      .then(res => {
        const tempCard = { ...displayedCard };
        const obj = res.data.quotes[Object.keys(res.data.quotes)[0]];
        if (card) {
          retrieveRequirements(type, card.id).then(({ data }) => {
            const newData = data.information_requirements.join('\n');
            setRequirements(newData);
          });
        }
        if (obj.franchise) {
          obj.franchise = showFranchise(
            obj.franchise.type,
            obj.franchise.value,
          );
        }

        Object.keys(obj).forEach(key => {
          tempCard.details.quote[key] = obj[key];
        });

        // check if there is a promotion price, then add it to card

        if (obj.promotion_premium) {
          tempCard.details.quote.promotion_premium.promotion =
            tempCard.details.quote.total_premium -
            tempCard.details.quote.promotion_premium.total_premium;
        }

        /* Check if there is a discount,
        when there is a promotion you calculate the discount on the total promotion price,
        add data to card*/

        if (obj.discount_premium) {
          tempCard.details.quote.discount_premium.discount = obj.promotion_premium
            ? obj.promotion_premium.total_premium -
              obj.discount_premium.total_premium
            : obj.total_premium - obj.discount_premium.total_premium;
        }
        setDisplayedCard(tempCard);
        switch (value) {
          case 'annual':
            setIntervalText(t('Checkout.year'));
            onPriceChange(t('Checkout.year'));
            break;
          case 'half_annual':
            setIntervalText(t('Checkout.half_annual'));
            onPriceChange(t('Checkout.half_annual'));
            break;
          case 'quarterly':
            setIntervalText(t('Checkout.quarterly'));
            onPriceChange(t('Checkout.quarterly'));
            break;
          case 'monthly':
            setIntervalText(t('Checkout.monthly'));
            onPriceChange(t('Checkout.monthly'));
            break;
        }
        setLoadPrice(false);
        setExceptionError(null);
      })
      .catch(err => handleExceptionError(err));
  }, 1500);

  // const clickHandler = values => {
  //   props.addInsuranceDate(year, month, day);
  // };

  const handleExceptionError = err => {
    const errData = err.response.data;
    if (
      errData.code >= 400 &&
      errData.code < 500 &&
      errData.msg === 'QUOTE_ERROR'
    ) {
      if (errData.detail[i18next.language]) {
        setExceptionError(errData.detail[i18next.language]);
      } else {
        setExceptionError(errData.detail.en);
      }
    } else {
      setExceptionError(
        t(`Checkout.card.noPrice`, {
          InsuranceCompany: displayedCard.company,
        }),
      );
    }
  };

  const goTo = () => {
    const subPath = window.location.href.includes('session')
      ? 'session'
      : 'review';
    switch (id) {
      case 1:
        history.push(`/${subPath}/market/fire`);
        break;
      case 2:
        history.push(`/${subPath}/market/family`);
        break;
      case 3:
        history.push(`/${subPath}/market/car`);
        break;
      case 4:
        history.push(`/${subPath}/market/legal`);
        break;
      default:
        break;
    }
  };

  const getEditLink = () => {
    const packNames = [];
    for (const pack in displayedCard.details.extensions) {
      packNames.push(displayedCard.details.extensions[pack].type);
    }
    const subPath = window.location.href.includes('session')
      ? 'session'
      : 'review';
    switch (id) {
      case 1:
        return `/${subPath}/market/fire/${card.company}/${
          card.scrollId
        }/${packNames.join('&')}`;
      case 2:
        return `/${subPath}/market/family/${card.company}/${
          card.scrollId
        }/${packNames.join('&')}`;
      case 3:
        return `/${subPath}/market/car/${card.company}/${
          card.scrollId
        }/${packNames.join('&')}${
          card.activeItem ? `/${card.activeItem}` : ``
        }`;
      case 4:
        return `/${subPath}/market/legal/${card.company}/${
          card.scrollId
        }/${packNames.join('&')}`;
      default:
        break;
    }
  };

  useEffect(() => {
    if (card) {
      retrieveRequirements(type, card.id).then(({ data }) => {
        const newData = data.information_requirements.join('\n');
        setRequirements(newData);
      });
    }
  }, []);

  const handleTerminationToggle = () => {
    setToggle(!togglen);
  };

  const checkValue = () => {
    let contract;

    if (oldInsurance) {
      if (oldInsurance['termination_date']) {
        const contractDay = oldInsurance['termination_date']['day'];
        const contractMonth = oldInsurance['termination_date']['month'];

        let yearContract;
        let monthContract;
        let dayContract;
        if (contractMonth - moment().month() - 1 > 3) {
          yearContract = moment().year();
          monthContract = contractMonth - 1;
          dayContract = contractDay;
        } else if (contractMonth - moment().month() - 1 < 3) {
          yearContract = moment().year() + 1;
          monthContract = contractMonth - 1;
          dayContract = contractDay;
        } else if (contractMonth - moment().month() - 1 == 3) {
          if (contractDay > moment().date()) {
            yearContract = moment().year();
            monthContract = contractMonth - 1;
            dayContract = contractDay;
          } else {
            yearContract = moment().year() + 1;
            monthContract = contractMonth - 1;
            dayContract = contractDay;
          }
        }
        contract = moment()
          .date(dayContract)
          .month(monthContract)
          .year(yearContract)
          .format('DD-MM-YYYY');
      } else {
        contract = moment()
          .add(1, 'day')
          .format('DD-MM-YYYY');
      }
    } else {
      contract = moment()
        .add(1, 'day')
        .format('DD-MM-YYYY');
    }
    return contract;
  };

  const checkValueTest = date => {
    let contract;
    if (date) {
      const contractDay = date['day'];
      const contractMonth = date['month'];

      let yearContract;
      let monthContract;
      let dayContract;
      if (contractMonth - moment().month() - 1 > 3) {
        yearContract = moment().year();
        monthContract = contractMonth - 1;
        dayContract = contractDay;
      } else if (contractMonth - moment().month() - 1 < 3) {
        yearContract = moment().year() + 1;
        monthContract = contractMonth - 1;
        dayContract = contractDay;
      } else if (contractMonth - moment().month() - 1 == 3) {
        if (contractDay > moment().date()) {
          yearContract = moment().year();
          monthContract = contractMonth - 1;
          dayContract = contractDay;
        } else {
          yearContract = moment().year() + 1;
          monthContract = contractMonth - 1;
          dayContract = contractDay;
        }
      }
      contract = moment()
        .date(dayContract)
        .month(monthContract)
        .year(yearContract)
        .format('DD-MM-YYYY');
    } else {
      contract = moment()
        .add(1, 'day')
        .format('DD-MM-YYYY');
    }
    return contract;
  };

  const switchType = type => {
    switch (type) {
      case 'home':
        return 1;
      case 'family':
        return 2;
      case 'car':
        return 3;
      case 'legal':
        return 4;
    }
  };

  if (displayedCard && displayedCard !== null) {
    return (
      <CardContainer data-test-id={`underwriting_insurance_container`}>
        <MatchContainer data-test-id={`underwriting_match_insurance`}>
          <p>{children}</p>
          <BtnContainer>
            {!getSocialLogin() ? (
              <EditPackage
                data-test-id={`underwriting_checkout_edit_${type}`}
                to={getEditLink()}
              >
                {t('Edit')}
              </EditPackage>
            ) : null}
            <AddToCart
              id={id}
              dataTestId={`underwriting_checkout_delete_${type}`}
              card={displayedCard}
              clicked={() => resetCard()}
              remove={true}
              white
              insuranceCompanies={allInsurers}
              typeButton={2}
            >
              {t('Checkout.card.deleteBtn')}
            </AddToCart>
          </BtnContainer>
        </MatchContainer>
        <InfoContainer>
          <Header data-test-id={`underwriting_insurance`}>
            <div>
              <InsuranceInfo data-test-id={`underwriting_insurance_info`}>
                <LinkInsurance
                  target="_blank"
                  rel="noopener noreferrer"
                  href={displayedCard.website}
                  data-test-id={`underwriting_insurance_info_logo`}
                >
                  {displayedCard.logo ? (
                    flowStore.type === 'campaign' ? (
                      <Img
                        src={flowStore?.distribution?.logo}
                        alt="insurance logo"
                      />
                    ) : (
                      <Img src={displayedCard.logo} alt="insurance logo" />
                    )
                  ) : (
                    <FakeLogo>{displayedCard.company}</FakeLogo>
                  )}
                </LinkInsurance>
                <div>
                  <p className="company">{displayedCard.company}</p>

                  <h1>{displayedCard.packagename}</h1>
                  {!getSocialLogin() ? (
                    <PDFFile
                      data-test-id={`underwriting_insurance_info_pdf_files`}
                    >
                      <PDFLink
                        href={displayedCard.pdfs.terms}
                        target="_blank"
                        data-test-id={`underwriting_insurance_info_pdf_files_terms`}
                      >
                        {documenten.terms ? (
                          <img src={pdfIcon} alt="Donwload pdf" />
                        ) : null}
                        {t('Checkout.card.termsPDF')}
                      </PDFLink>
                      <PDFLink
                        href={displayedCard.pdfs.ipid}
                        target="_blank"
                        data-test-id={`underwriting_insurance_info_pdf_files_ipid`}
                      >
                        {documenten.ipid ? (
                          <img src={pdfIcon} alt="Donwload pdf" />
                        ) : null}
                        {t('Checkout.card.IPID')}
                      </PDFLink>
                    </PDFFile>
                  ) : null}
                </div>
              </InsuranceInfo>
              {notices[type]?.notice && oldInsurance ? (
                <CancelContainer data-test-id={`underwriting_cancel`}>
                  <h2>{t('Checkout.card.cancelation.title')}</h2>
                  {Object.entries(oldInsurance).length !== 0 ? (
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `Checkout.card.cancelation.info_withTermination`,
                            {
                              insurance_company:
                                allInsurers[
                                  oldInsurance['insurance']['company_tag']
                                ],
                              expiration_data: oldInsurance['termination_date']
                                ? `${oldInsurance['termination_date']['day']}/${oldInsurance['termination_date']['month']}`
                                : `(${t(
                                    `Checkout.card.cancelation.not_specified`,
                                  )})`,
                              policy_number: oldInsurance['policy_number'],
                            },
                          ),
                        }}
                      />{' '}
                      {togglen ? (
                        <>
                          <ClickHere
                            data-test-id={`underwriting_termination_edit_${type}`}
                            onClick={() => handleTerminationToggle()}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  `Checkout.card.cancelation.info_inside_click_edit`,
                                ),
                              }}
                            />
                          </ClickHere>
                          <TerminationPopUp
                            data-test-id={`underwriting_termination_popup`}
                            toggle={togglen}
                            toggleDrawer={() => setToggle(!togglen)}
                            dataIsSet={() => dataIsSet()}
                            setFieldValue={date => {
                              outsideSetFieldValueLocal(
                                'contract',
                                checkValueTest(date),
                              );
                            }}
                            next={null}
                            data={allData}
                            insuranceCompanies={allInsurers}
                            isurance_name={t(`Coverages.${type}`)}
                            type={switchType(type)}
                          />
                        </>
                      ) : (
                        <ClickHere
                          data-test-id={`underwriting_termination_edit_${type}`}
                          onClick={() => handleTerminationToggle()}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `Checkout.card.cancelation.info_inside_click_edit`,
                              ),
                            }}
                          />
                        </ClickHere>
                      )}
                    </p>
                  ) : (
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `Checkout.card.cancelation.info_before_click_without`,
                            {
                              insurance_company: allData['already_insured_with']
                                ? allData['already_insured_with']
                                : `(${t(
                                    `Checkout.card.cancelation.not_specified`,
                                  )})`,
                            },
                          ),
                        }}
                      />
                      {togglen ? (
                        <>
                          <ClickHere
                            data-test-id={`underwriting_termination_edit_${type}`}
                            onClick={() => handleTerminationToggle()}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  `Checkout.card.cancelation.info_inside_click_without`,
                                ),
                              }}
                            />
                          </ClickHere>
                          <TerminationPopUp
                            data-test-id={`underwriting_termination_popup`}
                            toggle={togglen}
                            toggleDrawer={() => setToggle(!togglen)}
                            dataIsSet={() => dataIsSet()}
                            next={null}
                            data={allData}
                            setFieldValue={date => {
                              outsideSetFieldValueLocal(
                                'contract',
                                checkValueTest(date),
                              );
                            }}
                            insuranceCompanies={allInsurers}
                            isurance_name={t(`Coverages.${type}`)}
                            type={switchType(type)}
                          />
                        </>
                      ) : (
                        <ClickHere
                          data-test-id={`underwriting_termination_edit_${type}`}
                          onClick={() => handleTerminationToggle()}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `Checkout.card.cancelation.info_inside_click_without`,
                              ),
                            }}
                          />
                        </ClickHere>
                      )}

                      <span
                        dangerouslySetInnerHTML={{
                          __html: t(
                            `Checkout.card.cancelation.info_after_click_without`,
                          ),
                        }}
                      />
                    </p>
                  )}
                </CancelContainer>
              ) : null}
            </div>
            {exceptionError ? (
              <>
                <CantCalculate data-test-id={`underwriting_can_not_calculate`}>
                  <div>
                    {exceptionError.substring(0, 60)}
                    {exceptionError.length > 60 ? '...' : null}{' '}
                  </div>
                </CantCalculate>
                {exceptionError ? (
                  exceptionError.length > 60 ? (
                    <InfoPopUp
                      data-test-id={`underwriting_info_popup`}
                      title={t('Acceptance company')}
                      info={exceptionError}
                    />
                  ) : null
                ) : null}
              </>
            ) : loadPrice ? (
              <PriceCalculation data-test-id={`underwriting_price_calculation`}>
                <Loading width="100%" height="auto" />
              </PriceCalculation>
            ) : (
              <PriceCalculation data-test-id={`underwriting_price_calculation`}>
                <PriceListItem
                  data-test-id={`underwriting_price_calculation_netto`}
                >
                  <h2>{t('Checkout.card.nettoBonus')}</h2>
                  <p>
                    €{displayedCard.details.quote.net_premium.toFixed(2)} /
                    {intervalText}
                  </p>
                </PriceListItem>
                <PriceListItem
                  data-test-id={`underwriting_price_calculation_costs`}
                >
                  <h2>{t('Checkout.card.costs')}</h2>
                  <p>
                    €{displayedCard.details.quote.taxes_in_euro.toFixed(2)} /
                    {intervalText}
                  </p>
                </PriceListItem>
                {displayedCard.details.quote.policy_costs &&
                displayedCard.details.quote.policy_costs > 0 ? (
                  <PriceListItem
                    data-test-id={`underwriting_price_calculation_policy_costs`}
                  >
                    <h2>{t('Checkout.card.policy_costs')}</h2>
                    <p>
                      €{displayedCard.details.quote.policy_costs.toFixed(2)}
                    </p>
                  </PriceListItem>
                ) : null}
                {displayedCard.details.quote.promotion_premium ||
                displayedCard.details.quote.discount_premium ? (
                  <PriceListTempTotal
                    data-test-id={`underwriting_price_calculation_temp_total`}
                  >
                    <h2>{t('Checkout.card.totalBonus')}</h2>
                    <p>
                      € {displayedCard.details.quote.total_premium.toFixed(2)} /
                      {intervalText}
                    </p>
                  </PriceListTempTotal>
                ) : (
                  <PriceListItemTotal
                    data-test-id={`underwriting_price_calculation_total`}
                  >
                    <h2>{t('Checkout.card.totalBonus')}</h2>
                    <p>
                      € {displayedCard.details.quote.total_premium.toFixed(2)} /
                      {intervalText}
                    </p>
                  </PriceListItemTotal>
                )}
                {displayedCard.details.quote.promotion_premium ? (
                  <PriceListItem
                    data-test-id={`underwriting_price_calculation_promotion`}
                  >
                    <h2>{t('Checkout.card.sale.promotion')}</h2>
                    <p>
                      - €
                      {displayedCard.details.quote.promotion_premium.promotion.toFixed(
                        2,
                      )}{' '}
                      /{intervalText}
                    </p>
                  </PriceListItem>
                ) : null}
                {displayedCard.details.quote.discount_premium ? (
                  <PriceListItem
                    data-test-id={`underwriting_price_calculation_discount`}
                  >
                    <h2>{t('Checkout.card.sale.discount')}</h2>
                    <p>
                      - €
                      {displayedCard.details.quote.discount_premium.discount.toFixed(
                        2,
                      )}{' '}
                      /{intervalText}
                    </p>
                  </PriceListItem>
                ) : null}
                {displayedCard.details.quote.promotion_premium ||
                displayedCard.details.quote.discount_premium ? (
                  <PriceListItemTotal
                    data-test-id={`underwriting_price_calculation_total`}
                  >
                    <h2>{t('Checkout.card.totalBonus')}</h2>
                    <p>
                      €
                      {displayedCard.details.quote.promotion_premium &&
                      !displayedCard.details.quote.discount_premium
                        ? displayedCard.details.quote.promotion_premium.total_premium.toFixed(
                            2,
                          )
                        : displayedCard.details.quote.discount_premium &&
                          !displayedCard.details.quote.promotion_premium
                        ? displayedCard.details.quote.discount_premium.total_premium.toFixed(
                            2,
                          )
                        : displayedCard.details.quote.discount_premium &&
                          displayedCard.details.quote.promotion_premium
                        ? (
                            displayedCard.details.quote.total_premium -
                            displayedCard.details.quote.discount_premium
                              .discount -
                            displayedCard.details.quote.promotion_premium
                              .promotion
                          ).toFixed(2)
                        : displayedCard.details.quote.total_premium.toFixed(
                            2,
                          )}{' '}
                      /{intervalText}
                    </p>
                  </PriceListItemTotal>
                ) : null}
              </PriceCalculation>
            )}
          </Header>
          {type === 'home' ? (
            <InsuredAmountContainer data-test-id={`underwriting_insured_value`}>
              <div>
                <p>{t('Checkout.insured.insured_value')}</p>
                {displayedCard.details.quote.insured_value &&
                displayedCard.details.quote.total_premium ? (
                  displayedCard.details.quote.insured_value.value ? (
                    <Amount>
                      {displayedCard.details.quote.insured_value.value
                        .toFixed(2)
                        .replace('.', ',')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
                      &nbsp; &nbsp;€
                    </Amount>
                  ) : (
                    <Amount>&nbsp;</Amount>
                  )
                ) : (
                  <NVT>{t('Checkout.nvt')}</NVT>
                )}
              </div>
              <div>
                <p>{t('Checkout.insured.insured_content_value')}</p>
                {displayedCard.details.quote.insured_content_value &&
                displayedCard.details.quote.total_premium ? (
                  displayedCard.details.quote.insured_content_value.value ? (
                    <Amount>
                      {displayedCard.details.quote.insured_content_value.value
                        .toFixed(2)
                        .replace('.', ',')
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')}{' '}
                      &nbsp; &nbsp;€
                    </Amount>
                  ) : (
                    <Amount>&nbsp;</Amount>
                  )
                ) : (
                  <NVT>{t('Checkout.nvt')}</NVT>
                )}
              </div>
            </InsuredAmountContainer>
          ) : null}
          {type === 'car' ? (
            <InsuredAmountContainer data-test-id={`underwriting_insured_value`}>
              <div>
                <p>{t('Checkout.insured.bonusMalus')}</p>
                {displayedCard.details.quote.bonus_malus != null ? (
                  <Amount>{displayedCard.details.quote.bonus_malus}</Amount>
                ) : (
                  <NVT>{t('Checkout.nvt')}</NVT>
                )}
              </div>
              <div>
                <p>{t('Checkout.insured.franchise')}</p>
                {getNestedObject(displayedCard, [
                  'details',
                  'quote',
                  'franchise',
                ]) ? (
                  <Amount>
                    {getNestedObject(displayedCard, [
                      'details',
                      'quote',
                      'franchise',
                    ])}
                  </Amount>
                ) : (
                  <NVT>{t('Checkout.nvt')}</NVT>
                )}
              </div>
            </InsuredAmountContainer>
          ) : null}
          <PackagesContainer data-test-id={`underwriting_packages`}>
            {displayedCard.details.extensions.length <= 0 && type !== 'car' ? (
              <h2>{t(`Checkout.card.extentions.checkout_extention_empty`)}</h2>
            ) : (
              <h2>{t(`Checkout.card.extentions.checkout_extention_title`)}</h2>
            )}
            <div>
              {displayedCard.details.extensions.map((pack, i) => (
                <Package
                  key={i}
                  name={pack.name}
                  data-test-id={`underwriting_packages_package_${id}`}
                >
                  <LoadingExtentionIcons type={pack.type} />
                  <span>{pack.name}</span>
                </Package>
              ))}
            </div>
          </PackagesContainer>
        </InfoContainer>
        <Contract>
          <Left>
            <MarketplaceCheckoutItemContractLength
              debouncedCallback={debouncedCallback}
              id={id}
              displayedCard={displayedCard}
              setLoadPrice={setLoadPrice}
              setExceptionError={setExceptionError}
              data-test-id={`underwriting_contract_length`}
            />
          </Left>
          <Right data-test-id={`underwriting_contract_strat_date`}>
            <Formik
              initialValues={{
                contract: checkValue('contract'),
              }}
              validationSchema={SignupSchema}
            >
              {({
                errors,
                touched,
                values,
                setFieldTouched,
                setFieldValue,
              }) => {
                // push the setfieldvalue function to a global variable so I can use it to update the start date from the terminationpopup
                if (!outsideSetFieldValueLocal) {
                  outsideSetFieldValueLocal = setFieldValue;
                }
                if (tempErrors !== errors) {
                  getErrors(errors);
                  setTempErrors(errors);
                }
                if (moment(values['contract'], 'DD-MM-YYYY').isValid()) {
                  const year = moment(values['contract'], 'DD-MM-YYYY').format(
                    'YYYY',
                  );
                  const month = moment(values['contract'], 'DD-MM-YYYY').format(
                    'MM',
                  );
                  const day = moment(values['contract'], 'DD-MM-YYYY').format(
                    'DD',
                  );
                  addInsuranceDate(year, month, day, type);
                }

                return (
                  <Box>
                    <Question
                      dangerouslySetInnerHTML={{
                        __html: content.question,
                      }}
                    />
                    <DateInputComponent
                      classPrefix={type}
                      errors={errors}
                      touched={touched}
                      dataTestId={`underwriting_${type}_start_date`}
                      value={values['contract']}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      type="text"
                      disabled={false}
                      name="contract"
                      data-test-id={`underwriting_contract_strat_date_dateInput`}
                    />
                  </Box>
                );
              }}
            </Formik>
          </Right>
        </Contract>
        {!getSocialLogin() ? (
          <InformationRequirements
            sortKey={id}
            type={type}
            id={card.id}
            requirements={requirements[type]}
            setRequirements={setRequirements}
            data-test-id={`underwriting_requirements`}
            //specificNeedsData={data => console.log(data)}
          />
        ) : null}
      </CardContainer>
    );
  }

  return (
    <CardContainer data-test-id={`underwriting_insurance_container`}>
      <MatchContainerAdd data-test-id={`underwriting_add_insurance`}>
        <p>{children}</p>
        <AddToCart
          card={displayedCard}
          insuranceCompanies={allInsurers}
          typeButton={3}
          clicked={() => goTo()}
          data-test-id={`underwriting_add_insurance_add_button`}
        >
          {t('Checkout.card.addBtn')}
        </AddToCart>
      </MatchContainerAdd>
      <InfoContainer flex>
        <p
          dangerouslySetInnerHTML={{
            __html: t('Checkout.empty', { name: children.toLowerCase() }),
          }}
        />
      </InfoContainer>
    </CardContainer>
  );
};

const CantCalculate = styled.div`
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  color: red;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
`;

const EditPackage = styled(Link)`
  background-color: white;
  height: 4rem;
  margin-right: 2rem;
  width: 20rem;
  max-width: 25rem;
  border-radius: ${props => (props.type !== 1 ? '2.5rem' : '0.5rem')};
  display: flex;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.brand.primary};
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  font-family: ${({ theme }) => theme.font};

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }
`;

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease 0s;
  background-color: white;
  font-size: 1.6rem;
  box-shadow: 0 0.2rem 0.7rem rgba(0, 0, 0, 0.1);
  border-radius: 3.3rem;
  overflow: hidden;
  width: 90rem;
  justify-content: center;

  & strong {
    font-weight: bold;
  }
`;

const CancelContainer = styled.div`
  color: ${({ theme }) => theme.typo.subTitle};
  margin-right: 3.5rem;
  margin-top: 4rem;

  & h2 {
    color: #292929;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  & p {
    font-size: 1.4rem;
    line-height: 1.3;

    & strong {
      font-weight: bold;
    }
  }
`;

const FakeLogo = styled.div`
  padding: 3rem 2rem;
  margin-right: 2rem;
  background-color: ${({ theme }) => theme.brand.primary};
  height: 6rem;
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3.5rem;
  font-weight: 900;
`;

const MatchContainer = styled.div`
  padding: 0 4rem;
  height: 6.4rem;
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  font-size: 2rem;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 3.3rem 3.3rem 0 0;

  & span {
    font-weight: 900;
    font-size: 2.5rem;
  }
`;

const ClickHere = styled.span`
  text-decoration: underline;
  color: ${({ theme }) => theme.brand.secondary};
  cursor: pointer;
`;

const InsuranceInfo = styled.div`
  display: flex;
  margin-right: 0.5rem;
`;

const MatchContainerAdd = styled(MatchContainer)`
  background-color: ${({ theme }) => theme.ui.interactive};
`;

const InfoContainer = styled.div`
  padding: 2.5rem 3rem;
`;

const Left = styled.div`
  align-items: center;
  padding: 2.3rem 0 2.3rem 4.5rem;
`;

const Right = styled.div`
  margin: 2.3rem 4.5rem 2.3rem 0;
  width: 24.5rem;

  & p {
    color: ${({ theme }) => theme.brand.interactive};
  }

  & .price {
    text-align: right;
  }
`;

const PriceCalculation = styled.div`
  display: flex;
  flex-direction: column;
  width: 30rem;
  margin-top: 1.5rem;
  margin-left: ${props => (props.social ? 'auto' : null)};

  & p {
    color: ${({ theme }) => theme.brand.interactive};
  }

  & .price {
    text-align: right;
  }
`;

const Header = styled.header`
  display: flex;
  margin-bottom: 2.5rem;
  justify-content: space-between;

  & .company {
    color: grey;
    font-size: 2.2rem;
    align-self: flex-end;
  }

  & h1 {
    font-size: 2.2rem;
    margin-top: 0.5rem;
    font-weight: bold;
    text-transform: capitalize;
  }

  & li {
    font-size: 2rem;
    line-height: 2.6rem;
  }
`;

const InsuredAmountContainer = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  color: ${({ theme }) => theme.typo.interactive};
`;

const Amount = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.typo.text};
  font-weight: 900;
  margin: 1.7rem 0 2.7rem;
`;

const NVT = styled(Amount)`
  color: ${({ theme }) => theme.typo.interactive};
  font-size: 1.6rem;
`;

const PDFFile = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 2rem;
  width: 33rem;
`;

const PDFLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
  padding: 0.9rem 1.2rem;
  background-color: ${({ theme }) => theme.brand.primary};
  font-size: 1.6rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  width: auto;

  & img {
    margin-right: 1.3rem;
    max-height: 3rem;
    height: 100%;
    flex: 0;
  }
`;

const PackagesContainer = styled.div`
  margin-bottom: 2.6rem;

  & h2 {
    color: ${({ theme }) => theme.brand.interactive};
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
  }

  & span {
    align-self: center;
  }

  & div {
    display: flex;
    flex-flow: wrap;
  }
`;

const PriceListItem = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.brand.interactive};
  font-size: 1.4rem;
  margin-bottom: 0.5rem;

  & h2 {
    font-size: 1.6rem;
    font-weight: normal;
    color: grey;
    margin-bottom: 0.2rem;
  }

  & > h2:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }

  & p {
    white-space: nowrap;
  }
`;

const PriceListItemTotal = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: #292929;
  border-top: 1px solid ${({ theme }) => theme.brand.interactive};
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
  padding-top: 1rem;
  & p {
    color: #292929;
    text-align: right;
  }

  @media screen and (max-width: 750px) {
    font-size: 2rem;
  }
`;

const PriceListTempTotal = styled(PriceListItemTotal)`
  color: ${({ theme }) => theme.brand.interactive};
  border-top: 1px solid ${({ theme }) => theme.ui.outline};

  & p {
    color: ${({ theme }) => theme.brand.interactive};
  }
`;

const LinkInsurance = styled.a`
  text-decoration: none;
  align-self: center;
  width: 20rem;
  margin-right: 1.8rem;
`;

const Img = styled.img`
  margin-right: 3rem;
  user-select: none;
  -webkit-user-drag: none;
  max-width: 20rem;
  max-height: 10rem;
  &[src*='svg'] {
    height: auto;
    width: auto;
  }
`;

const Package = styled.button`
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  border-radius: 20rem;
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  transition: 0.2s;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.font};
  height: 4rem;

  display: flex;
  justify-content: center;

  & span {
    margin-left: 0.7rem;
  }

  & svg {
    width: 2.6rem;
    height: 2.6rem;
    content: fit;
  }

  &:focus {
    outline: none;
  }
`;

const Contract = styled.div`
  background-color: ${({ theme }) => theme.ui.background};
  display: flex;
  justify-content: space-between;
`;

const Box = styled(Form)`
  width: 100%;

  & .error {
    margin: 0.5rem;
    color: red;
  }

  & input {
    height: 3.5rem;

    & img {
      bottom: 1.5rem;
    }
  }
`;

const Question = styled.p`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.brand.interactive};
`;

CheckoutItem.defaultProps = {
  clicked: () => {},
  card: null,
  setRequirements: () => {},
  requirements: '',
};

CheckoutItem.propTypes = {
  card: shape(),
  children: string.isRequired,
  checkboxes: shape(),
  history: shape().isRequired,
  clicked: func,
  id: number.isRequired,
  type: string.isRequired,
  getErrors: func.isRequired,
  addInsuranceDate: func.isRequired,
  pdfs: shape(),
  oldInsurance: object,
  allData: object,
  insurance: string,
  allInsurers: shape(),
  dataIsSet: func,
  priceData: object,
  onPriceChange: func,
  setRequirements: func,
  requirements: string,
};

export default withRouter(CheckoutItem);
