import React from 'react';
const DashboardIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.755488"
        y="0.755"
        width="7.91858"
        height="7.91858"
        rx="2.83797"
        stroke="white"
        strokeWidth="1.51"
      />
      <rect
        x="11.3269"
        y="0.755"
        width="7.91858"
        height="7.91858"
        rx="2.83797"
        stroke="white"
        strokeWidth="1.51"
      />
      <rect
        x="0.755"
        y="11.3264"
        width="7.91858"
        height="7.91858"
        rx="2.83797"
        stroke="white"
        strokeWidth="1.51"
      />
      <rect
        x="11.602"
        y="11.3264"
        width="7.91858"
        height="7.91858"
        rx="2.83797"
        fill="white"
        stroke="white"
        strokeWidth="1.51"
      />
    </svg>
  );
};
export default DashboardIcon;
