import React, { useState } from 'react';
import { SearchSelectInput } from 'wg-fe-ui';
import { useEffect } from 'react';
import { shape, string, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const ProFlowInputTypeSelectInput = ({
  enums,
  error,
  name,
  handleChange,
  handleSubmit,
  prefilled,
  values,
  title,
  ...otherProps
}) => {
  const { i18n } = useTranslation();
  const [value, setValue] = useState();

  const options = enums.map(option => {
    const [value] = Object.keys(option);
    const label = option[value]?.[i18n.language];
    return { label, value };
  });

  useEffect(() => {
    setValue(options.filter(item => item.value === values)[0]);
    if (values) {
      handleSubmit(test => {
        console.log(test);
      });
    }
  }, [values]);

  return (
    <>
      <Label>{title}</Label>
      <StyledSearchSelectInput
        error={error}
        name={name}
        value={value}
        prefilled={prefilled}
        placeholder={i18n.t('Choose your option')}
        options={options}
        onSelected={handleChange}
        {...otherProps}
      />
    </>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  & .Select__control {
    ${({ prefilled }) =>
      prefilled ? `border-color: #FFBC0F; background-color: #FFF9EA;` : ''}
  }
`;

const Label = styled.label`
  color: #8990a7;
  font-weight: 600;
  font-size: 1.4rem;
`;

ProFlowInputTypeSelectInput.propTypes = {
  enums: shape().isRequired,
  error: string,
  prefilled: bool,
  name: string.isRequired,
  handleChange: func.isRequired,
  handleSubmit: func.isRequired,
  values: shape(),
  title: string.isRequired,
};

export default ProFlowInputTypeSelectInput;
