import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { string } from 'prop-types';

import DefaultCarIcon from '../../assets/images/DefaultCarIcon';
import { getCurrentTheme } from '../store';

const ManualSearchCarLeft = ({
  carLogo,
  brandData,
  modelData,
  versionData,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <RoundImage>
        {carLogo ? (
          <Img src={carLogo} />
        ) : (
          <DefaultCarIcon colors={getCurrentTheme()} />
        )}
      </RoundImage>

      <CarName
        show={brandData !== null && brandData !== undefined && brandData !== ''}
      >
        <p>{t(`CheckCar.title`)}</p>
        <h2>
          {brandData ? <span>{brandData} </span> : null}
          {modelData ? <span>{modelData} </span> : null}
          {versionData ? <span>{versionData}</span> : null}
        </h2>
      </CarName>
    </>
  );
};

const Img = styled.img`
  object-fit: contain;
  height: 26rem;
  width: 29rem;
  display: flex;
  z-index: 0;
`;

const RoundImage = styled.div`
  width: 33rem;
  height: 33rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  background-color: white;

  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const CarName = styled.div`
  background: white;
  box-shadow: 0 0.4rem 1.3rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  padding: 2rem 1.8rem;
  width: 85%;
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.3s;
  text-align: left;
  opacity: ${props => (props.show ? 1 : 0)};

  & p {
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.5;
    color: ${({ theme }) => theme.typo.interactive};
  }

  & h2 {
    color: #505050;
    font-weight: 900;
    font-size: 1.6rem;
  }
`;

ManualSearchCarLeft.propTypes = {
  carLogo: string,
  brandData: string,
  modelData: string,
  versionData: string,
};

export default ManualSearchCarLeft;
