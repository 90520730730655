import React from 'react';

const CheckmarkOrange = () => (
  <svg
    width="16"
    height="13.5"
    viewBox="0 0 31 27"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.6436 3.448L11.3334 22.8409L4.20986 13.4032"
      stroke="#FF8000"
      strokeWidth="4.42401"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="#fff"
    />
  </svg>
);

export default CheckmarkOrange;
