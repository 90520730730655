import React, { useContext, useRef, useEffect, useState } from 'react';
import { string, shape, array, object, func, number } from 'prop-types';
import useForm from '../hooks/useForm';
import * as yup from 'yup'; // for everything
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import {
  parseIncDate,
  formatISODate,
  parseIncDateToIsoFormat,
} from '../services/dateService.js';
import { validateInputCall } from '../services/apiRouterService';
import { useParams } from 'react-router';

import TextInput from './ProFlowInputTypeTextInput.jsx';
import PhoneInput from './ProFlowInputTypePhoneInput.jsx';
import SelectInput from './ProFlowInputTypeSelectInput';
import DateInput from './ProFlowInputTypeDateInput';
import Radio from './ProFlowInputTypeRadio';
import CheckBox from './ProFlowInputTypeCheckBox';
import Address from './ProFlowInputSpecialTypeAddress';
import { default as LicenseLookup } from './LicenseLookup_v2';
import AccidentsInput from './ProFlowAccidentsInput';
import ProFlowInputTypeAddress from './ProFlowInputTypeAddress';
import { isObjectEmpty } from '../services/objectService';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import ProFlowRoomsInput from './ProFlowRoomsInput';
import ProFlowAnnexesInput from './ProFlowAnnexesInput';
import _ from 'lodash';

const ProFlowInputSelector = ({
  typeTags,
  rules,
  validator,
  name,
  errorMessages,
  questionErrors,
  title,
  description,
  field,
  tag,
  vehicle,
  setVehicle,
  annexesData,
  setAnnexesData,
  value,
  roomData,
  setRoomData,
  focus,
  setFocus,
}) => {
  const { i18n } = useTranslation();
  const { enum: enums, input_type: inputType, symbol } = rules || {};
  const [prefilled, setPrefilled] = useState(false);
  const formatedTitle = rules.mandatory ? `${title} *` : title;
  const { addAnswer, removeAnswer, answers } = useContext(
    ProFlowStorageContext,
  );
  const { sessionId } = useParams();

  const inputRef = useRef();

  const validate = async (val, resolve) => {
    const [, status] = await validateInputCall(
      validator.method,
      validator.url,
      {
        [validator.body]: val,
      },
    );
    return resolve(status === 200);
  };

  const [debounceValidate] = useDebouncedCallback(validate, 500);

  let { handleChange, handleSubmit, errors, values } = useForm({
    validationSchema: createYupSchema(),
    change: () => {},
  });

  useEffect(() => {
    const answerIndex = answers.findIndex(item => item.id === name);
    if (answerIndex !== -1) {
      handleChange({ name, value: answers[answerIndex].answer });
    }
    if (answers[answerIndex]?.prefilled) {
      setPrefilled(true);
    }
  }, [answers]);

  useEffect(() => {
    if (questionErrors.includes(name)) handleSubmit(() => {});
  }, [questionErrors]);

  function createYupSchema() {
    const yupNodeObject = [];

    if (isObjectEmpty(rules) && !validator) return yup.object();

    Object.entries(rules).forEach(([key, val]) => {
      // date_type needs to be set first
      if (key === 'data_type') {
        let yupKey = val.toLowerCase();
        if (inputType === 'DATE') {
          yupKey = 'date';
        }
        yupNodeObject.splice(0, 0, { [yupKey]: [] });
      }
      const propery = matchRuleKeyWithYup(key, val);
      if (propery) yupNodeObject.push(propery);
    });

    // Correct type error for number
    if (getDataType() === 'number') {
      yupNodeObject.push({ typeError: [i18n.t('You must specify a number')] });
    }

    // If contains validator
    if (validator) {
      switch (validator.body) {
        case 'telephonenr':
          yupNodeObject.push({
            test: [
              validator?.body,
              errorMessages?.regex?.[i18n.language] ||
                i18n.t('Invalid telephone number'),
              val => {
                if (val && val !== '32' && focus === 'telephonenr') {
                  return new Promise(resolve => debounceValidate(val, resolve));
                } else {
                  return true;
                }
              },
            ],
          });
          break;
        case 'cbe':
          yupNodeObject.push({
            test: [
              validator?.body,
              errorMessages?.regex?.[i18n.language] ||
                i18n.t('Company number is not valid'),
              val => {
                if (val && focus === 'cbe') {
                  return new Promise(resolve => debounceValidate(val, resolve));
                } else {
                  return true;
                }
              },
            ],
          });
          break;
        case 'email':
          yupNodeObject.push({
            test: [
              validator?.body,
              errorMessages?.regex?.[i18n.language] ||
                i18n.t('There was an error in the input field'),
              val => {
                if (val && focus === 'email') {
                  return new Promise(resolve => debounceValidate(val, resolve));
                } else {
                  return true;
                }
              },
            ],
          });
          break;
        default:
          break;
      }
    }

    // First name and last name no numbers
    if (field === 'first_name' || field === 'last_name') {
      yupNodeObject.push({
        matches: [
          new RegExp('^([^0-9]*)$'),
          i18n.t('A name should not contain numbers'),
        ],
      });
    }

    yupNodeObject.push({ nullable: [] });

    const schema = yup.object().shape({
      [name]: createYupNode(yupNodeObject),
    });

    return schema;
  }

  function matchRuleKeyWithYup(ruleKey, val) {
    const optionList = [];
    if (val === undefined || val === null || val === false) return null;
    let errorMsg;
    //need to create validation for the percentage input
    // if new value comes in is percentage => less than 100 and more than 0

    switch (ruleKey) {
      case 'mandatory':
        if (val === false) return null;
        errorMsg = errorMessages?.mandatory?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { required: optionList };
      case 'gt':
        optionList.push(val);
        errorMsg = errorMessages?.gt?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { moreThan: optionList };
      case 'lt':
        optionList.push(val);
        errorMsg = errorMessages?.lt?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { lessThan: optionList };
      case 'ge':
        if (inputType === 'DATE') {
          optionList.push(parseIncDate(val));
        } else {
          optionList.push(val);
        }
        errorMsg = errorMessages?.ge?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { min: optionList };
      case 'le':
        if (inputType === 'DATE') {
          optionList.push(parseIncDate(val));
        } else {
          optionList.push(val);
        }
        errorMsg = errorMessages?.le?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { max: optionList };
      case 'min_length':
        optionList.push(val);
        errorMsg = errorMessages?.min_length?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { min: optionList };
      case 'max_length':
        optionList.push(val);
        errorMsg = errorMessages?.max_length?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { max: optionList };
      case 'regex':
        optionList.push(new RegExp('(^$|' + val.js + ')'));
        errorMsg = errorMessages?.regex?.[i18n.language];
        if (errorMsg) optionList.push(errorMsg);
        return { matches: optionList };
      default:
        return null;
    }
  }

  function getDataType() {
    switch (rules.data_type) {
      case 'NUMBER':
        return 'number';
      default:
        return 'text';
    }
  }

  function createYupNode(arr) {
    if (!arr) return [];
    return arr.reduce((prevResult, obj) => {
      const [key, val] = Object.entries(obj)[0];
      return prevResult?.[key](...val);
    }, yup);
  }

  function saveChangesString(valueObject = {}) {
    const [entries] = Object.entries(valueObject);
    const [id] = entries || [];
    let [, value] = entries || [];

    if (rules.input_type === 'PERCENTAGE') {
      value = parseFloat(value);
    } else if (rules.input_type === 'CURRENCY') {
      value = parseFloat(value);
    } else if (rules.data_type === 'NUMBER') {
      value = parseFloat(value);
    }

    // Clear out the answer if input is back on default value
    // Default => Empty/undefined for normal input or +32 for phone number
    if (value === undefined || value === '' || value === null) {
      // Should not remove answer for number 0
      removeAnswer(id, sessionId);
    } else {
      addAnswer(
        id,
        typeof value === 'string' ? value?.trim() : value,
        sessionId,
        typeTags,
        rules.input_type,
        field,
        tag,
      );
    }
  }

  function saveChangesPhone(valueObject = {}) {
    const { name, value } = valueObject || {};
    if (!value || value === '+32') {
      removeAnswer(name, sessionId);
    } else {
      addAnswer(
        name,
        value?.trim(),
        sessionId,
        typeTags,
        rules.input_type,
        field,
        tag,
      );
    }
  }

  function saveChangesDate(valueObject = {}) {
    const [entries] = Object.entries(valueObject);
    const [id, value] = entries || [];
    const dataObj = value;
    addAnswer(id, dataObj, sessionId, typeTags, rules.input_type, field, tag);
  }

  function saveChangesRadio(valueObject = {}) {
    const { name: id, value } = valueObject;
    addAnswer(id, value, sessionId, typeTags, rules.input_type, field, tag);
  }

  function saveChangesCheckbox(valueObject = {}) {
    const { name: id, value } = valueObject;
    addAnswer(id, value, sessionId, typeTags, rules.input_type, field, tag);
  }

  function saveChangesAddress(id, valueObject = {}) {
    let payload = {
      municipalityName: valueObject?.municipalityName,
      postalCode: valueObject?.postalCode,
      streetName: valueObject?.streetName,
      streetNumber: valueObject?.streetNumber,
      boxNumber: valueObject?.boxNumber,
      country_code: 'BE',
    };

    addAnswer(id, payload, sessionId, typeTags, rules.input_type, field, tag);
  }

  function saveChangesAnnexes(id, valueObject = {}) {
    const upperCasedAnnexes = _.forEach(valueObject, item => {
      item.type = item.type.toUpperCase();
    });
    setAnnexesData(addIdToArrayObject(upperCasedAnnexes));
    const filteredAnnexes = _.map(upperCasedAnnexes, item => {
      return _.omit(item, 'annexId');
    });
    addAnswer(
      id,
      filteredAnnexes,
      sessionId,
      typeTags,
      rules.input_type,
      field,
      tag,
    );
  }

  function addIdToArrayObject(arr) {
    return arr.map(function(obj, index) {
      return Object.assign({}, obj, { annexId: index });
    });
  }

  function saveChangesRoom(id, valueObject = {}) {
    addAnswer(
      id,
      valueObject,
      sessionId,
      typeTags,
      rules.input_type,
      field,
      tag,
    );
  }

  function saveChangesCar(id, valueObject = {}) {
    Object.keys(valueObject).forEach(
      key =>
        typeof valueObject[key] === 'string' &&
        (valueObject[key] = valueObject[key].trim()),
    );
    if (id === '8ee6cd72-e2b0-4489-ba7f-2533ce6bbcd0') {
      // Catalogue value is a seperate answer so it has to be updated seperately
      addAnswer(
        '278feef9-018c-4c70-9873-b6851e870601',
        valueObject.car_value,
        sessionId,
        typeTags,
        'CURRENCY',
        'catalogue_value',
        tag,
      );
    }
    addAnswer(
      id,
      valueObject,
      sessionId,
      typeTags,
      rules.input_type,
      field,
      tag,
    );
  }

  function saveAccidents(valueObject = {}) {
    const { name: id, value } = valueObject;
    addAnswer(id, value, sessionId, typeTags, rules.input_type, field, tag);
  }

  function saveChangesHome(id, valueObject = {}) {
    addAnswer(id, valueObject, sessionId, typeTags, field, tag);
  }

  function handlePercentChange(val, name) {
    handleChange({ name: name, value: val.value });
  }

  function handleCurrency(val = 0, name) {
    let newVal = parseFloat(val.value).toFixed(2);
    handleChange({ name: name, value: newVal });
  }

  switch (inputType) {
    case 'INPUT':
      return (
        <TextInput
          value={values[name]}
          title={formatedTitle}
          prefilled={prefilled}
          onBlur={() => {
            saveChangesString(values);
            handleSubmit(() => {});
          }}
          name={name}
          onChange={val => {
            handleChange(val);
            if (prefilled) setPrefilled(false);
          }}
          error={errors[name]}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          type={getDataType()}
          onFocus={() => setFocus(field)}
        />
      );
    case 'SELECT':
      return (
        <SelectInput
          title={formatedTitle}
          values={values[name]}
          prefilled={prefilled}
          handleSubmit={() => handleSubmit(saveChangesString)}
          enums={enums}
          name={name}
          handleChange={val => {
            handleChange(val);
            if (prefilled) setPrefilled(false);
          }}
          error={errors[name]}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'DATE':
      return (
        <DateInput
          title={formatedTitle}
          prefilled={prefilled}
          onBlur={() => handleSubmit(saveChangesDate)}
          error={errors[name]}
          value={values[name] && formatISODate(values[name])}
          name={name}
          handleChange={({ name, value }) => {
            if (value.length >= 10) {
              value = parseIncDateToIsoFormat(value);
              handleChange({ name, value });
              if (prefilled) setPrefilled(false);
            }
          }}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'RADIO':
      return (
        <Radio
          title={formatedTitle}
          enums={enums}
          error={errors[name]}
          value={values[name]}
          name={name}
          tag={tag}
          field={field}
          handleChange={val => {
            handleChange(val);
            saveChangesRadio(val);
            if (prefilled) setPrefilled(false);
          }}
          onFocus={() => setFocus(field)}
        />
      );
    case 'CHECKBOX':
      return (
        <CheckBox
          title={formatedTitle}
          enums={enums}
          error={errors[name]}
          value={values[name] && values[name]}
          name={name}
          handleChange={val => {
            handleChange(val);
            saveChangesCheckbox(val);
            if (prefilled) setPrefilled(false);
          }}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'ADDRESS':
      return (
        <Address
          onSubmit={val => {
            saveChangesAddress(name, val);
            if (prefilled) setPrefilled(false);
          }}
          title={formatedTitle}
          value={values[name]}
          prefilled={prefilled}
          error={errors[name]}
          name={name}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'TELEPHONE_NUMBER':
      return (
        <PhoneInput
          handleChange={val => {
            handleChange(val);
            if (prefilled) setPrefilled(false);
          }}
          onBlur={val => {
            saveChangesPhone(val);
            handleSubmit(() => {});
          }}
          value={values[name]}
          prefilled={prefilled}
          error={errors[name]}
          title={formatedTitle}
          name={name}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'CAR':
      return (
        <LicenseLookup
          ref={inputRef}
          error={errors[name]}
          onSubmit={val => {
            saveChangesCar(name, val);
            if (prefilled) setPrefilled(false);
          }}
          value={values[name]}
          vehicle={vehicle}
          setVehicle={setVehicle}
          dataTestId={`proFlow_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'ACCIDENTS':
      return (
        <AccidentsInput
          ref={inputRef}
          onSubmit={val => {
            saveAccidents(val);
            if (prefilled) setPrefilled(false);
          }}
          title={formatedTitle}
          error={errors[name]}
          name={name}
          value={values[name]}
          dataTestId={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'HOME':
      return (
        <ProFlowInputTypeAddress
          onSubmit={val => {
            saveChangesHome(name, val);
            if (prefilled) setPrefilled(false);
          }}
          value={values[name]}
          error={errors[name]}
          annexesData={annexesData}
          setAnnexesData={setAnnexesData}
          saveChangesAnnexes={val => {
            saveChangesAnnexes('9bd9dede-2613-4778-8494-c4579c97c566', val);
          }}
          roomData={roomData}
          setRoomData={setRoomData}
          dataTestId={`proFlow_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'PERCENTAGE':
      return (
        <StyledNumbersInputWithSymbol
          ref={inputRef}
          description={description != null && description}
          title={formatedTitle}
          onBlur={() => {
            saveChangesString(values);
            handleSubmit(() => {});
          }}
          max={100}
          min={0}
          name={name}
          type="number"
          step="1"
          error={errors[name]}
          value={values[name] * 100}
          onChange={val => {
            if (prefilled) setPrefilled(false);
            val.value /= 100;
            handlePercentChange(val, name);
          }}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        >
          <span>%</span>
        </StyledNumbersInputWithSymbol>
      );
    case 'CURRENCY':
      return (
        <StyledNumbersInputWithSymbol
          description={description != null && description}
          title={formatedTitle}
          onBlur={() => {
            saveChangesString(values);
            handleSubmit(() => {});
          }}
          name={name}
          type="number"
          step=".01"
          placeholder="0.00"
          onChange={val => {
            handleCurrency(val, name);
          }}
          value={
            (name === '278feef9-018c-4c70-9873-b6851e870601' &&
              vehicle?.car_value) ||
            (name === '34293d29-158c-4334-a6ae-1623f0c21f01' &&
              vehicle?.invoice_value)
          }
          error={errors[name]}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        >
          <span>{symbol}</span>
        </StyledNumbersInputWithSymbol>
      );
    case 'ANNEXES':
      return (
        <ProFlowAnnexesInput
          field={field}
          title={formatedTitle}
          name={name}
          description={description}
          annexesData={annexesData}
          setAnnexesData={setAnnexesData}
          onChange={val => {
            saveChangesAnnexes(name, val);
          }}
          dataTestId={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    case 'ROOM':
      return (
        <ProFlowRoomsInput
          field={field}
          title={formatedTitle}
          value={value}
          name={name}
          description={description}
          roomData={roomData}
          setRoomData={setRoomData}
          onChange={val => {
            saveChangesRoom(name, val);
          }}
          data-test-id={`proFlow_${tag.toLowerCase()}_${field}`}
          onFocus={() => setFocus(field)}
        />
      );
    default:
      return `cant find ${inputType}`;
  }
};

const StyledNumbersInputWithSymbol = styled(TextInput)`
  label {
    position: relative;
    svg {
      right: 5rem;
    }
  }

  span {
    border-left: 1px solid #ccc;
    color: #222;
    padding: 1.4rem;
    position: absolute;
    right: 0;
    top: 1.5rem;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

ProFlowInputSelector.defaultProps = {
  rules: {},
  name: '',
};

ProFlowInputSelector.propTypes = {
  rules: shape().isRequired,
  errorMessages: shape().isRequired,
  name: string.isRequired,
  title: string.isRequired,
  typeTags: array,
  questionErrors: array,
  tag: string.isRequired,
  description: string,
  vehicle: object,
  setVehicle: func,
  value: number,
  rooms: array,
  focus: string,
  setFocus: func,
};

export default ProFlowInputSelector;
