import React, { useState, useEffect, useContext } from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';

import Block from '../components/LinkBlock';
import Layout from '../../DashboardLayout/views/Layout';
import TrialPopupOnBrokerLoginScreen from '../../../components/TrialPopupOnBrokerLoginScreen';

import { generateId, getTrialAccount } from '../../../store/index';
import { addDataForIdWoning } from '../../Flow/Woning/store';
import { addDataForIdUser } from '../../Flow/User/store';
import { addDataForIdCar } from '../../Flow/Car/store';
import { addDataForIdLock } from '../../Flow/Lock/store';

import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import { getNestedObject } from '../../../services/objectService';
import UpgradePopupOnBrokerLoginScreen from '../../../components/UpgradePopupOnBrokerLoginScreen';
import {
  getBrokerDistribution,
  getBrokerData,
} from '../../../services/brokerDataService';
import ApiRoutes from '../../../store/ApiRoutes';
import { addDataForIdFamily } from '../../Flow/Gezin/store';
import { addDataForIdLegal } from '../../Flow/Legal/store';
import { removeFromCheckout } from '../../Marketplace/LayoutMarket/store';

import { FlagsProvider } from '../../../hoc/FlagsProviderHoc.jsx';
import { getMeData } from '../../../services/meDataService';
import LogRocket from 'logrocket';
import { useHistory } from 'react-router';

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const content = t('Dashboard', { returnObjects: true });
  const [showTrial, setShowTrial] = useState(false);
  const [isBronze, setIsBronze] = useState(false);
  const [isDia, setIsDia] = useState(false);
  const [canAccessPage, setCanAccessPage] = useState(false);
  const configCatClient = useContext(FlagsProvider);
  const [isProfFlowEnabled, setIsProfFlowEnabled] = useState(false);
  const [isContractsEnabled, setIsContractsEnabled] = useState(false);

  const api = new ApiRoutes();

  // show popup if user is on internet explorer
  useEffect(() => {
    var ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    var msie = ua.indexOf('MSIE '); // IE 10 or older
    var trident = ua.indexOf('Trident/'); //IE 11

    if (msie > 0 || trident > 0) {
      cogoToast.warn(<ToastContainer>{content.ieWarning}</ToastContainer>, {
        hideAfter: 10,
      });
    }
    if (window.location.href.includes('campaigns')) history.push('/logout');
    setFeature();
  }, []);

  async function setFeature() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const _canAccesPage = await configCatClient.getValueAsync(
      'canAccessPage',
      false,
      {
        email: me?.email,
      },
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
      }),
    );

    setIsContractsEnabled(
      await configCatClient.getValueAsync('contracts', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setCanAccessPage(_canAccesPage);
  }

  const { setNavTabs } = useContext(TopNavigationItems);
  useEffect(() => {
    setNavTabs([]);
    initializeDiaAccount();
    initializeLogRocket();
  }, []);

  async function initializeLogRocket() {
    const { me } = await getMeData();
    LogRocket.identify('Email Login', {
      email: me.email,
    });
  }

  async function initializeDiaAccount() {
    const { email } = (await getBrokerDistribution()) || {};

    setIsDia(email === 'dia_broker@wegroup.be');
  }

  const generateNewId = () => {
    return generateId().then(res => {
      addDataForIdWoning(res.id);
      addDataForIdUser(res.id);
      addDataForIdCar(res.id);
      addDataForIdLock(res.id);
      addDataForIdFamily(res.id);
      addDataForIdLegal(res.id);
      removeFromCheckout('fire');
      removeFromCheckout('people');
      removeFromCheckout('car');
      removeFromCheckout('legal');
      return res;
    });
  };

  let isFired = false;
  const handleKeyPressed = async e => {
    const { email } = await getBrokerDistribution();
    if (
      e.key === '=' &&
      !isFired &&
      window.location.href.includes('/dashboard') &&
      email === 'dia_broker@wegroup.be'
    ) {
      isFired = true;
      cogoToast.info(
        <>
          <p
            style={{
              fontWeight: 'bold',
              marginBottom: '1rem',
              fontSize: '1.6rem',
            }}
          >
            Recieved new email
          </p>
          <p style={{ marginBottom: '0.8rem', fontSize: '1.4rem' }}>
            <span style={{ fontStyle: 'italic', marginRight: '1rem' }}>
              from:
            </span>
            john_ashray@outlook.be
          </p>
          <p style={{ fontSize: '1.4rem' }}>
            <span style={{ fontStyle: 'italic', marginRight: '1rem' }}>
              subject:
            </span>
            Damage due to the recent storm
          </p>
        </>,
        {
          hideAfter: 5,
        },
      );
      setTimeout(() => {
        cogoToast.success(
          <>
            <p
              style={{
                fontWeight: 'bold',
                marginBottom: '1rem',
                fontSize: '1.6rem',
              }}
            >
              Louise auto-responded to mail from
            </p>
            <p style={{ marginBottom: '0.8rem', fontSize: '1.4rem' }}>
              <span style={{ fontStyle: 'italic', marginRight: '1rem' }}>
                john_ashray@outlook.be
              </span>
            </p>
          </>,
          {
            hideAfter: 5,
          },
        );
      }, 5500);

      api.postWantsSendEmailDia(email);
    }
  };

  function shouldDashboardCardBeDisabled(fallBackValue, key) {
    if (key === 'Campaigns') return true;
    return fallBackValue;
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPressed);

    return () => {
      window.removeEventListener('keyup', handleKeyPressed);
    };
  }, []);

  return (
    <DashboardLayout>
      <div>
        {showTrial ? (
          <TrialPopupOnBrokerLoginScreen
            close={bool => setShowTrial(bool)}
            startScreen="cantUseClaims"
          />
        ) : null}
        {isBronze ? (
          <UpgradePopupOnBrokerLoginScreen
            startScreen="upgrade_bronze"
            kkzkkzkjwkle
            close={() => setIsBronze(false)}
          />
        ) : null}
        <TitleContainer>
          <Title>{canAccessPage ? content.title : content.titleCallant}</Title>
          <SubTitle>{content.subTitle}</SubTitle>
        </TitleContainer>
        <BlockWrapper>
          {!canAccessPage
            ? Object.keys(content.indexCallant).map(key => {
                if (!isProfFlowEnabled && key === 'Proflow') return null;
                console.log('CONTRACTS ENABLED?', isContractsEnabled);
                if (!isContractsEnabled && key === 'Contracts') return null;
                return (
                  <Block
                    key={key}
                    dataTestId={
                      getNestedObject(content, [`index`, key, 'dataTestId']) ||
                      ''
                    }
                    goTo={content.indexCallant[key].to}
                    title={content.indexCallant[key].title}
                    subTitle={content.indexCallant[key].subTitle}
                    icon={content.indexCallant[key].icon}
                    disabled={content.indexCallant[key].disabled}
                    demo={isDia ? false : content.indexCallant[key].demo}
                    beta={content.indexCallant[key].beta}
                    showTrial={bool => setShowTrial(bool)}
                    showUpgrade={() => setIsBronze(true)}
                  />
                );
              })
            : Object.keys(content.index).map(key => {
                if (key === 'Portfolio' && getTrialAccount()) return null;
                if (getTrialAccount() && key === 'Proflow') return null;
                return (
                  <Block
                    key={key}
                    dataTestId={
                      getNestedObject(content, [`index`, key, 'dataTestId']) ||
                      ''
                    }
                    generateNewId={
                      key === 'Underwriting' ? generateNewId : undefined
                    }
                    goTo={content.index[key].to}
                    title={content.index[key].title}
                    subTitle={content.index[key].subTitle}
                    icon={content.index[key].icon}
                    disabled={shouldDashboardCardBeDisabled(
                      content.index[key].disabled,
                      key,
                    )}
                    demo={isDia ? false : content.index[key].demo}
                    beta={content.index[key].beta}
                    showTrial={bool => setShowTrial(bool)}
                    showUpgrade={() => setIsBronze(true)}
                  />
                );
              })}
        </BlockWrapper>
      </div>
    </DashboardLayout>
  );
};

Dashboard.propTypes = {
  history: shape().isRequired,
};

const DashboardLayout = styled(Layout)`
  display: flex;
  align-items: center;
  width: 90%;
  max-width: 100rem;
`;

const ToastContainer = styled.p`
  text-align: center;
  width: 70vw;
  font-size: 1.6rem;
  font-weight: 700;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 3rem;
  margin-bottom: 1rem;
  @media screen and (max-width: 400px) {
    margin-top: 4rem;
  }
`;

const SubTitle = styled.h2`
  font-size: 1.6rem;
  color: #76726e;
`;

const BlockWrapper = styled.div`
  margin-top: 3.9rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default Dashboard;
