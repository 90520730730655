import React from 'react';
import { string } from 'prop-types';
const GardenHouse = ({ color }) => (
  <svg viewBox="0 0 126.68 184.76">
    <defs>
      <style>{`.prefix__cls-2{fill:${color}}`}</style>
    </defs>
    <title>{'garden_housematrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          d="M126.68 39.84v142.68a2.24 2.24 0 01-2.24 2.24h-22.32a2.24 2.24 0 01-2.24-2.24V75.4a2.7 2.7 0 00-2.7-2.7H29.5a2.7 2.7 0 00-2.7 2.7v107.12a2.24 2.24 0 01-2.24 2.24H2.24A2.24 2.24 0 010 182.52V39.84a2.24 2.24 0 011.06-1.91L62.17.33a2.24 2.24 0 012.34 0l61.11 37.6a2.24 2.24 0 011.06 1.91z"
          fillRule="evenodd"
          fill={color}
        />
        <path
          className="prefix__cls-2"
          d="M79.31 99H47.37a6.77 6.77 0 110-13.53h31.94a6.77 6.77 0 110 13.53zM79.31 127.55H47.37a6.77 6.77 0 110-13.53h31.94a6.77 6.77 0 110 13.53zM79.31 184.76H47.37a6.77 6.77 0 110-13.54h31.94a6.77 6.77 0 010 13.54zM79.31 156.16H47.37a6.77 6.77 0 110-13.54h31.94a6.77 6.77 0 010 13.54z"
        />
      </g>
    </g>
  </svg>
);

GardenHouse.defaultProps = {
  color: `white`,
};

GardenHouse.propTypes = {
  color: string,
};

export default GardenHouse;
