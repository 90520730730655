import React from 'react';
import { string } from 'prop-types';

const Students = ({ color }) => {
  return (
    <svg
      width="30"
      height="18"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9949 9.87943L25.054 5.07693V14.5572C25.054 14.869 24.8128 15.106 24.5233 15.106H5.48828C5.18671 15.106 4.95752 14.8566 4.95752 14.5572V5.10188L13.8719 9.86695C14.1976 10.0416 14.5715 10.1414 14.9334 10.1414C15.2953 10.1414 15.6692 10.0541 15.9949 9.87943Z"
        fill={color}
      />
      <path
        d="M28.3239 1.22245L29.892 0.386694C30.073 0.286902 30.0126 0 29.7955 0H0.205639C0.00057304 0 -0.0718033 0.286902 0.109138 0.386694L14.705 8.19543C14.8498 8.27027 15.0187 8.27027 15.1634 8.19543L27.0331 1.90852L26.997 13.7089L25.4529 17.6632C25.3926 17.8254 25.5012 17.9875 25.6701 17.9875H26.2008L26.8522 17.0894L27.1658 17.9875H29.6387C29.8076 17.9875 29.9161 17.8129 29.8558 17.6632L28.2756 13.6091L28.3239 1.22245Z"
        fill={color}
      />
    </svg>
  );
};

Students.propTypes = {
  color: string,
};

export default Students;
