import React from 'react';
import { bool, string, func } from 'prop-types';
const Arrow = ({
  dataTestId,
  isPointingDown,
  className,
  onClick,
  isExtended,
  colors,
}) => {
  return (
    <svg
      data-test-id={dataTestId}
      width="33"
      height="16"
      viewBox="0 0 33 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      isPointingDown={isPointingDown}
      className={className}
      onClick={() => onClick()}
    >
      <path
        d="M2 14L16.5 2L31 14"
        stroke={isExtended ? 'white' : colors}
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Arrow.defaultProps = {
  isPointingDown: false,
  onClick: () => {},
};

Arrow.propTypes = {
  isExtended: bool.isRequired,
  onClick: func.isRequired,
  isPointingDown: bool,
  className: string,
  dataTestId: string,
  colors: string,
};

export default Arrow;
