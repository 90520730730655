/* stylelint-disable declaration-no-important */
// packages
import React, { useEffect, Suspense, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { withRouter, useLocation } from 'react-router';
import { shape, bool } from 'prop-types';
import { differenceInYears, isPast } from 'date-fns';
// components
import Layout from '../../LayoutFlow/views/App';
import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';
import {
  parseObjectToNewDate,
  parseIncDateToObject,
  parseIncDate,
  isValidDate,
} from '../../../../services/dateService';

import {
  firstName,
  lastName,
  string,
  date,
} from '../../../../services/validationSchemas';
import checkmark from '../../../../../assets/images/checkmark.svg';
import DateInput from '../../../../components/DateInputFromElvas';

import {
  driverLicenseTypeOptions,
  accidentStatementType,
} from '../../../../services/FlowSearchSelectData';

import { ActionButton, SearchSelectInput, Label, TextInput } from 'wg-fe-ui';
import useForm from '../../../../hooks/useForm';
import CheckBox from '../../../../components/Checkbox';

import LouiseLeftSide from '../../../Flow/User/views/LouiseLeftSide';
import { addBrowserHistory, getNextPath } from '../../../../store';
import { setCarValue, getPayloadCar } from '../store';
import { getAddressData, getPersonalData } from '../../User/store';

const Driver = ({ history }) => {
  const { t } = useTranslation();
  const [defaultValues, setDefaultValues] = useState({});
  const [licenseOptions, setLicenseOptions] = useState([]);
  const [sameAsPolicy, setSameAsPolicy] = useState(undefined);
  const location = useLocation();
  const [accidentStatementOptions, setAccidentStatementOptions] = useState([]);
  const [numberOfAccidents, setNumberOfAccidents] = useState([]);
  const [SignupSchema, setSignupSchema] = useState(
    Yup.object().shape({
      firstName: firstName({
        required: t(`required`),
        min: t(`too short`),
        max: t(`too long`),
      }),
      lastName: lastName({
        required: t(`required`),
        min: t(`too short`),
        max: t(`too long`),
      }),
      birth: date({
        required: t(`required`),
        matches: t(`not a valid date`),
      }).test(
        'valid-birth',
        t('The driver must be at least 17 years of age'),
        handleBirthValidation,
      ),
      driverLicenseType: string({ required: t('required') }).required,
      accidentHistoryProof: string({ required: t('required') }).required,
      issueLicenseDate: date({
        required: t(`required`),
        matches: t(`not a valid date`),
      }).test(
        'issueLicenseDate',
        t('The driver is too young to obtain a driving licence'),
        handleDriveLicenseAge,
      ),
    }),
  );

  useEffect(() => {
    addBrowserHistory(location.pathname);
    const driver = getPayloadCar().primary_driver;
    if (driver) {
      const {
        first_name,
        last_name,
        birth,
        issue_license_date,
        accident_statement,
        driver_license_type,
        sameAsPolicy,
      } = driver;
      const tempDefaultValues = {
        firstName: first_name,
        lastName: last_name,
        sameAsPolicy,
        birth: `${birth.day}/${birth.month}/${birth.year}`,
        issueLicenseDate: `${issue_license_date.day}/${issue_license_date.month}/${issue_license_date.year}`,
        accidentHistoryProof: accident_statement.statement_type,
        driverLicenseType: driver_license_type,
      };

      const tempNumberOfAccidents = [];
      accident_statement.accidents.forEach((item, key) => {
        const id = item.id || uuidv4();
        tempNumberOfAccidents.push({
          date: `${item.date.day}/${item.date.month}/${item.date.year}`,
          at_fault: item.at_fault,
          id: id,
        });
        tempDefaultValues[
          `accident${id}`
        ] = `${item.date.day}/${item.date.month}/${item.date.year}`;
      });
      setNumberOfAccidents(tempNumberOfAccidents);
      setDefaultValues(tempDefaultValues);
    }
  }, []);

  useEffect(() => {
    let newSchemaObject = {
      firstName: firstName({
        required: t(`required`),
        min: t(`too short`),
        max: t(`too long`),
      }),
      lastName: lastName({
        required: t(`required`),
        min: t(`too short`),
        max: t(`too long`),
      }),
      birth: date({
        required: t(`required`),
        matches: t(`not a valid date`),
      }).test(
        'valid-birth',
        t('The driver must be between 17 and 100 years of age'),
        handleBirthValidation,
      ),
      driverLicenseType: string({ required: t('required') }).required,
      accidentHistoryProof: string({ required: t('required') }).required,
      issueLicenseDate: date({
        required: t(`required`),
        matches: t(`not a valid date`),
      }).test(
        'issueLicenseDate',
        t('The driver is too young to obtain a driving licence'),
        handleDriveLicenseAge,
      ),
    };
    numberOfAccidents.forEach(item => {
      newSchemaObject[`accident${item.id}`] = date({
        required: t(`required`),
        matches: t(`not a valid date`),
      })
        .test(
          `accident${item.id}`,
          t('This date is impossible with the drivers age'),
          handleMustBePossibleDate,
        )
        .test(
          `accident${item.id}`,
          t('Date cant be in the future'),
          handleMustBePassedDate,
        )
        .test(
          `accident${item.id}`,
          t('Date is more than 5 years ago'),
          handleMax5YearsDiff,
        );
    });
    setSignupSchema(Yup.object().shape(newSchemaObject));
  }, [numberOfAccidents]);

  const SelectPlaceholder = t('Choose your option');

  const { handleChange, errors, handleSubmit, values } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    if (sameAsPolicy !== undefined) {
      const { first_name, last_name, birth } = getPersonalData();
      handleChange({
        name: 'firstName',
        value: sameAsPolicy ? first_name : '',
      });
      handleChange({ name: 'lastName', value: sameAsPolicy ? last_name : '' });
      handleChange({
        name: 'birth',
        value: sameAsPolicy
          ? birth
              .split('/')
              .reverse()
              .join('/')
          : '',
      });
    }
  }, [sameAsPolicy]);

  useEffect(() => {
    setDefaultValuesFromStore();
  }, [defaultValues]);

  useEffect(() => {
    loadSelectOptions();
  }, []);

  function setDefaultValuesFromStore() {
    Object.keys(defaultValues).forEach(name => {
      handleChange({ name, value: defaultValues[name] });
      if (name === 'sameAsPolicy') {
        setSameAsPolicy(defaultValues['sameAsPolicy']);
      }
    });
  }

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormValues);
  }

  const handleFormValues = formValues => {
    const birth = {
      day: formValues.birth.split('/')[0],
      month: formValues.birth.split('/')[1],
      year: formValues.birth.split('/')[2],
    };
    const issue_license_date = {
      day: formValues.issueLicenseDate.split('/')[0],
      month: formValues.issueLicenseDate.split('/')[1],
      year: formValues.issueLicenseDate.split('/')[2],
    };
    const dataObject = {
      primary: true,
      first_name: formValues.firstName,
      last_name: formValues.lastName,
      sameAsPolicy: formValues.sameAsPolicy,
      birth,
      issue_license_date,
      address: getAddressData(),
      driver_license_type: formValues.driverLicenseType,
      accident_statement: {
        accidents: [],
        statement_type: formValues.accidentHistoryProof,
      },
    };
    if (numberOfAccidents.length > 0) {
      numberOfAccidents.forEach(item => {
        const { date, at_fault } = item;
        const dateObject = {
          day: date.split('/')[0],
          month: date.split('/')[1],
          year: date.split('/')[2],
        };
        dataObject.accident_statement.accidents.push({
          date: dateObject,
          at_fault,
          // id: item.id,
        });
      });
    }
    setCarValue('primary_driver', dataObject);
    history.push(getNextPath());
  };

  function loadSelectOptions() {
    setLicenseOptions(
      driverLicenseTypeOptions.map(({ value, label }) => {
        return { value, label: t(label) };
      }),
    );

    setAccidentStatementOptions(
      accidentStatementType.map(({ value, label }) => {
        return { value, label: t(label) };
      }),
    );
  }

  function handleSelectValue(object, inputName) {
    if (!values[inputName] && !defaultValues[inputName]) return '';
    const key = values[inputName]
      ? values[inputName]
      : defaultValues[inputName]
      ? defaultValues[inputName]
      : '';
    return object.filter(({ value }) => value === key)[0];
  }

  function handleSelectChange(val, name) {
    handleChange({ name, value: val ? val.value : '' });
  }

  function handleMustBePassedDate(date) {
    const dateObject = parseIncDateToObject(date);
    const currentDate = parseIncDate(dateObject);
    return isPast(currentDate);
  }

  function handleMustBePossibleDate(date) {
    const { birth } = this.parent;
    let minimumDrivingAge = 17;

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseIncDate(dateObject);

    const accidentObject = parseIncDateToObject(date);
    const accidentDate = parseIncDate(accidentObject);

    return differenceInYears(accidentDate, birthDate) >= minimumDrivingAge;
  }

  function handleMax5YearsDiff(date) {
    let maxAge = 5;

    const accidentObject = parseIncDateToObject(date);
    const accidentDate = parseIncDate(accidentObject);
    const newAccidentDate = isValidDate(accidentDate)
      ? new Date(
          accidentDate.getFullYear(),
          accidentDate.getMonth(),
          accidentDate.getDate(),
          23,
          58,
          58,
        )
      : accidentDate;
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
    );

    return differenceInYears(today, newAccidentDate) < maxAge;
  }

  function handleDriveLicenseAge() {
    const { birth, driverLicenseType, issueLicenseDate } = this.parent;

    let minimumDrivingAge = 18;
    if (driverLicenseType) {
      minimumDrivingAge = driverLicenseType.includes('provisional') ? 17 : 18;
    }

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseIncDate(dateObject);

    const licenseObject = parseIncDateToObject(issueLicenseDate);
    const licenseDate = parseIncDate(licenseObject);

    return differenceInYears(licenseDate, birthDate) >= minimumDrivingAge;
  }

  function handleBirthValidation() {
    const { birth } = this.parent;

    const dateObject = parseIncDateToObject(birth);
    const birthDate = parseObjectToNewDate(dateObject);

    return (
      17 <= differenceInYears(new Date(), birthDate) &&
      differenceInYears(new Date(), birthDate) <= 100
    );
  }

  const addNewAccident = e => {
    e.preventDefault();
    setNumberOfAccidents(numberOfAccidents => [
      ...numberOfAccidents,
      {
        id: uuidv4(),
        date: '',
        at_fault: false,
      },
    ]);
  };

  const handleChangeAccident = (id, name, value) => {
    const objIndex = numberOfAccidents.findIndex(obj => obj.id === id);
    setNumberOfAccidents(numberOfAccidents => {
      const tempPrevValue = [...numberOfAccidents];
      tempPrevValue[objIndex][name] = value;
      return tempPrevValue;
    });
  };

  const deleteAccident = (e, id) => {
    e.preventDefault();
    setNumberOfAccidents(numberOfAccidents =>
      numberOfAccidents.filter(accident => accident.id !== id),
    );
  };

  return (
    <Layout>
      <Container>
        <StyledLeftSide>
          <LouiseLeftSide
            question={t(
              `Hello Please enter the details of the primary driver here`,
            )}
          />
        </StyledLeftSide>
        <RightSide>
          <Form onSubmit={formSubmitHandler}>
            <Suspense fallback={<div>Loading...</div>}>
              <Title>{t('Primary driver information')}</Title>
              <CheckBox
                errors={errors}
                type="checkbox"
                checked={values.sameAsPolicy}
                name="sameAsPolicy"
                onChange={e => {
                  handleChange({ name: 'sameAsPolicy', value: e });
                  setSameAsPolicy(e);
                }}
                dataTestId="underwriting_personal_agree1"
              >
                {t(`The primary driver is also the policy holder`)}
              </CheckBox>
              <NameInputContainer>
                <STextInput
                  name="firstName"
                  error={errors.firstName}
                  onChange={val => handleChange(val)}
                  disabled={values.sameAsPolicy}
                  value={values.firstName}
                >
                  <NameInput>{t('First name')} *</NameInput>
                </STextInput>
                <STextInput
                  name="lastName"
                  error={errors.lastName}
                  disabled={values.sameAsPolicy}
                  onChange={val => handleChange(val)}
                  value={values.lastName}
                >
                  <NameInput>{t('Last name')} *</NameInput>
                </STextInput>
              </NameInputContainer>
              <div>
                <StyledLabel disabled={values.sameAsPolicy}>
                  {t('What is the date of birth of the primary driver')}
                </StyledLabel>
                <StyledDateInput
                  name="birth"
                  disabled={values.sameAsPolicy}
                  error={errors.birth}
                  onChange={handleChange}
                  value={values.birth}
                />
              </div>
              <div>
                <StyledLabel>
                  {t(
                    'What kind of driving licence does the primary driver hold',
                  )}
                </StyledLabel>
                <SelectInput
                  error={errors.driverLicenseType}
                  name="driverLicenseType"
                  onSelected={val => {
                    handleSelectChange(val, 'driverLicenseType');
                  }}
                  options={licenseOptions}
                  placeholder={SelectPlaceholder}
                  isClearable
                  value={handleSelectValue(licenseOptions, 'driverLicenseType')}
                />
              </div>
              <div>
                <StyledLabel>
                  {t('How would you like to prove your accident history')}
                </StyledLabel>
                <SelectInput
                  error={errors.accidentHistoryProof}
                  name="accidentHistoryProof"
                  onSelected={val => {
                    handleSelectChange(val, 'accidentHistoryProof');
                  }}
                  options={accidentStatementOptions}
                  placeholder={SelectPlaceholder}
                  isClearable
                  value={handleSelectValue(
                    accidentStatementOptions,
                    'accidentHistoryProof',
                  )}
                />
              </div>
              <div>
                <StyledLabel>
                  {t('When did the primary driver obtain the driving licence')}
                </StyledLabel>
                <StyledDateInput
                  onChange={handleChange}
                  error={errors.issueLicenseDate}
                  name="issueLicenseDate"
                  value={defaultValues.issueLicenseDate}
                />
              </div>
              <div>
                <CatTitle>
                  {t('Accidents in the last five years')}{' '}
                  <AddButton onClick={addNewAccident}>
                    + {t('Add accident')}
                  </AddButton>
                </CatTitle>
                <NumberAccidents>
                  {t('The primary driver had x claims in the last 5 years', {
                    count: numberOfAccidents.filter(function(el) {
                      return el != null;
                    }).length,
                  })}
                </NumberAccidents>
                <AccidentsContainer>
                  {numberOfAccidents.map(item => (
                    <AccidentRow key={item.id}>
                      <StyledLabel>{t('Date')}</StyledLabel>
                      <DateInputContainer error={errors[`accident${item.id}`]}>
                        <DateInputAccident>
                          <StyledDateInput
                            name={`accident${item.id}`}
                            onChange={e => {
                              handleChangeAccident(item.id, 'date', e.value);
                              handleChange(e);
                            }}
                            value={
                              values[`accident${item.id}`]
                                ? values[`accident${item.id}`]
                                : defaultValues[`accident${item.id}`]
                                ? defaultValues[`accident${item.id}`]
                                : ''
                            }
                          />
                        </DateInputAccident>
                        <DateInputAccident short={true}>
                          <StyledLabelAccident htmlFor={`checkbox${item.id}`}>
                            {t('At fault')}:
                            <HiddenInput
                              id={`checkbox${item.id}`}
                              checked={item.at_fault}
                              onChange={e =>
                                handleChangeAccident(
                                  item.id,
                                  'at_fault',
                                  e.target.checked,
                                )
                              }
                              type="checkbox"
                            />
                            <CheckboxItem>
                              <img src={checkmark} alt="checkmark" />
                            </CheckboxItem>
                          </StyledLabelAccident>
                        </DateInputAccident>
                        {errors[`accident${item.id}`] ? (
                          <ErrorMsg>{errors[`accident${item.id}`]}</ErrorMsg>
                        ) : (
                          ''
                        )}
                      </DateInputContainer>{' '}
                      <DeleteButton onClick={e => deleteAccident(e, item.id)}>
                        -
                      </DeleteButton>
                    </AccidentRow>
                  ))}
                </AccidentsContainer>
              </div>
              <ButtonContainer>
                <ActionButton
                  type="submit"
                  value="Submit"
                  data-test-id="drivers_information_submit"
                >
                  {t('Next')}
                </ActionButton>
              </ButtonContainer>
            </Suspense>
          </Form>
        </RightSide>
      </Container>
    </Layout>
  );
};

const NumberAccidents = styled.p`
  font-size: 1.5rem;
`;

const NameInputContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 2rem;
`;

const STextInput = styled(TextInput)`
  & input {
    margin-top: 0.5rem;
  }

  & > div {
    margin: 0;
  }
`;

const ErrorMsg = styled.p`
  position: absolute;
  top: 4.5rem;
  right: 0;
  color: red;
  font-size: 1.1rem;
`;

const Title = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 2rem;
`;

const CatTitle = styled.h1`
  font-weight: 900;
  font-size: 1.65rem;
  letter-spacing: 1.19px;
  color: black;
  margin-top: 2rem;
`;

const DeleteButton = styled.button`
  background-color: ${({ theme }) => theme.brand.primary};
  margin-left: 1rem;
  height: 3rem;
  width: 3rem;
  border: 0;
  color: white;
  border-radius: 0.5rem;
  align-self: center;
  font-weight: bold;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.1s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.brand.lighter};
  }
`;

const AddButton = styled.button`
  background-color: ${({ theme }) => theme.brand.primary};
  margin-left: 1rem;
  height: 3rem;
  color: white;
  border-radius: 0.5rem;
  align-self: center;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.1s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.brand.lighter};
  }
`;

const CheckboxItem = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: white;
  transition: all 0.1s ease;
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid #d3d4d8;
  border-color: #d3d4d8;
  border-radius: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: none;
  }
`;

const HiddenInput = styled.input`
  display: none;

  &:checked + ${CheckboxItem} {
    border-color: ${({ theme }) => theme.brand.primary};
    background-color: ${({ theme }) => theme.brand.primary};

    & > img {
      display: block;
    }
  }
`;

const DateInputContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  position: relative;
  width: 100%;
  max-width: 30rem;
  border: 1px solid ${({ error }) => (error ? 'red' : '#d3d4d8')};
  border-radius: 0.5rem;
`;

const AccidentsContainer = styled.div``;
const AccidentRow = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  flex-wrap: wrap;
`;

const DateInputAccident = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ short }) => (short ? '40%' : '60%')};
  overflow: hidden;
  border-radius: ${({ short }) =>
    short ? '0 .5rem .5rem 0' : '.5rem 0 0 .5rem'};
  height: 100%;

  & > div {
    height: 100%;
    width: 100%;
  }

  & > div > label {
    margin: 0;
    border-top: 0;
    border-left: 0;
    border-bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 0;
  }

  & > div > label:first-child {
    display: none;
  }
`;

const NameInput = styled.p`
  line-height: 1;
`;

const StyledLeftSide = styled(LeftSide)`
  justify-content: space-around;
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;

const StyledDateInput = styled(DateInput)``;

const StyledLabel = styled(Label)`
  color: ${({ theme, disabled }) =>
    !disabled ? theme.typo.subTitle : '#AEAEAE'};
  font-size: 1.5rem !important;
  width: 100%;
  line-height: 1 !important;
  flex-grow: 0;
  flex-shrink: 0;
`;

const StyledLabelAccident = styled.label`
  color: ${({ theme }) => theme.typo.subTitle} !important;
  font-size: 1.5rem !important;
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #eaeaea;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > div {
    min-height: 9rem;
  }
`;

const SelectInput = styled(SearchSelectInput)`
  & > div {
    margin: 0;
  }

  & > label > div {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }

  & .Select__option {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.typo.text};
  }

  & .Select__option--is-focused {
    color: ${({ theme }) => theme.typo.text};
    font-weight: 500;
    background-color: ${({ theme }) => theme.brand.lighter};
    :hover {
      color: white;
      background-color: ${({ theme }) => theme.brand.secondary};
    }
  }
`;

const ButtonContainer = styled.div`
  margin-left: auto;
  margin-top: 2rem;
  @media (max-width: 768px) {
    margin-left: 0;
  }

  & button {
    width: 20rem;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
`;

Driver.defaultProps = {
  isSocialUser: false,
};

Driver.propTypes = {
  history: shape().isRequired,
  location: shape(),
  isSocialUser: bool,
};

export default withRouter(Driver);
