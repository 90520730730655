import React from 'react';
import { string } from 'prop-types';
const solarIcon = ({ color }) => (
  <svg
    width="17"
    height="19"
    viewBox="0 0 17 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25827 12.936H0.242731C0.10866 12.936 0 12.8273 0 12.6932V8.80779C0 8.67372 0.10866 8.56506 0.242731 8.56506H7.25827C7.39234 8.56506 7.501 8.67372 7.501 8.80779V12.6932C7.50144 12.8273 7.39234 12.936 7.25827 12.936Z"
      fill={color}
    />
    <path
      d="M16.2214 12.9356H9.20587C9.07179 12.9356 8.96313 12.8269 8.96313 12.6929V8.80743C8.96313 8.67336 9.07179 8.5647 9.20587 8.5647H16.2214C16.3555 8.5647 16.4641 8.67336 16.4641 8.80743V12.6929C16.4641 12.8269 16.3555 12.9356 16.2214 12.9356Z"
      fill={color}
    />
    <path
      d="M7.25827 18.6677H0.242731C0.10866 18.6677 0 18.559 0 18.4249V14.5395C0 14.4054 0.10866 14.2968 0.242731 14.2968H7.25827C7.39234 14.2968 7.501 14.4054 7.501 14.5395V18.4249C7.50144 18.559 7.39234 18.6677 7.25827 18.6677Z"
      fill={color}
    />
    <path
      d="M16.2214 18.6677H9.20587C9.07179 18.6677 8.96313 18.559 8.96313 18.4249V14.5395C8.96313 14.4054 9.07179 14.2968 9.20587 14.2968H16.2214C16.3555 14.2968 16.4641 14.4054 16.4641 14.5395V18.4249C16.4641 18.559 16.3555 18.6677 16.2214 18.6677Z"
      fill={color}
    />
    <path
      d="M10.4729 3.24508L10.5671 0.148285C10.5702 0.0510178 10.4444 0.0102709 10.3897 0.0904509L8.64543 2.65139C8.60424 2.71186 8.64192 2.79423 8.71465 2.80211C9.29607 2.86784 9.842 3.05054 10.3283 3.32613C10.3919 3.36206 10.4707 3.31781 10.4729 3.24508Z"
      fill={color}
    />
    <path
      d="M7.81872 2.65139L6.07448 0.0904509C6.01971 0.0102709 5.89396 0.0510178 5.89703 0.148285L5.99123 3.24508C5.99342 3.31825 6.07229 3.3625 6.13582 3.32657C6.62215 3.05098 7.16808 2.86827 7.74949 2.80255C7.82179 2.79423 7.85991 2.71186 7.81872 2.65139Z"
      fill={color}
    />
    <path
      d="M12.272 5.28531L14.1678 2.83566C14.2274 2.75898 14.1494 2.65208 14.0583 2.68494L11.1416 3.73166C11.0728 3.7562 11.0553 3.84514 11.1087 3.89421C11.5272 4.27759 11.8685 4.74377 12.1073 5.26647C12.1375 5.33263 12.2273 5.34271 12.272 5.28531Z"
      fill={color}
    />
    <path
      d="M2.29582 2.83558L4.19166 5.28524C4.23635 5.34307 4.32617 5.33256 4.3564 5.26596C4.59519 4.74326 4.9365 4.27707 5.35493 3.8937C5.40882 3.84419 5.39085 3.75568 5.32207 3.73115L2.40535 2.68443C2.31422 2.652 2.23667 2.75891 2.29582 2.83558Z"
      fill={color}
    />
    <path
      d="M1.34597 7.02672H3.87844C3.93145 7.02672 3.97395 6.98422 3.97527 6.93077C3.9814 6.67621 4.00944 6.42647 4.05851 6.18505C4.07297 6.11363 4.00637 6.05229 3.93627 6.07289L1.31837 6.83657C1.21103 6.86768 1.23381 7.02672 1.34597 7.02672Z"
      fill={color}
    />
    <path
      d="M12.5856 7.02679H15.118C15.2302 7.02679 15.253 6.86775 15.1452 6.8362L12.5273 6.07252C12.4572 6.05193 12.3906 6.11327 12.4051 6.18468C12.4541 6.42654 12.4826 6.67584 12.4888 6.93084C12.4901 6.98386 12.5326 7.02679 12.5856 7.02679Z"
      fill={color}
    />
    <path
      d="M5.5087 7.02681H10.9552C11.0109 7.02681 11.0543 6.97993 11.0525 6.92472C10.9951 5.4162 9.7543 4.2113 8.23175 4.2113C6.71008 4.2113 5.46926 5.4162 5.41143 6.92472C5.40968 6.97993 5.45305 7.02681 5.5087 7.02681Z"
      fill={color}
    />
  </svg>
);

solarIcon.defaultProps = {
  color: `white`,
};

solarIcon.propTypes = {
  color: string,
};

export default solarIcon;
