import { stringLength } from './stringService';

/**
 * Parses date from object format to Date
 * @param {object} date {year, month, day, hour, minute, second}
 */
export const parseIncDate = (date = { year: 0, month: 0, day: 0 }) => {
  const isValidYearLength =
    typeof date.year === 'string'
      ? stringLength(date.year) === 4
      : date.year > 999;

  const parsedDate = new Date(
    isValidYearLength ? date.year : undefined,
    date.month - 1,
    date.day,
    date.hour || 0,
    date.minute || 0,
    date.second || 0,
  );

  return isValidDate(parsedDate) ? parsedDate : {};
};
/**
 * Parses date to the locale string separated by '/'
 * @param {object} date {year, month, day, hour, minute, second}
 */

export const parseIncDateToString = (date = {}) => {
  if (!date) return;
  const parsedDate = parseIncDate(date);
  if (isValidDate(parsedDate)) {
    return parsedDate.toLocaleDateString().replace(/\./g, '/');
  } else {
    return '';
  }
};

export function parseIncDateToIsoFormat(dateString) {
  const { day, month, year } = parseIncDateToObject(dateString);
  let date = new Date(year, month - 1, day);
  // date = setOffsetRight(date);  // Isn't necessary as the backend formatting will reformat this again
  if (isValidDate(date)) {
    return date.toISOString();
  } else {
    return null;
  }
}

export function parseDateObjToIsoFormat(dateObj) {
  if (!dateObj) return null;
  const { day, month, year } = dateObj;
  let date = new Date(year, month - 1, day);
  // date = setOffsetRight(date);  // Isn't necessary as the backend formatting will reformat this again
  if (isValidDate(date)) {
    return date.toISOString();
  } else {
    return null;
  }
}

/**
 * Parses date to an object { day: DD, month: MM, year: YYYY }
 * @param {string} date DD/MM/YYYY
 */

export function parseIncDateToObject(string, format) {
  if (string) {
    if (format === 'YYYY-MM-DD') {
      const [year, month, day] = string.split('-');
      return {
        day: parseInt(day),
        month: parseInt(month),
        year: parseInt(year),
      };
    } else {
      const [day, month, year] = string.split('/');
      if (day && month && year) {
        return {
          day: parseInt(day),
          month: parseInt(month),
          year: parseInt(year),
        };
      }
    }
  }

  return {};
}

/**
 * Parses object to an new date Sun Dec 17 1995 03:24:00 GMT...
 * @param {object} date { day: DD, month: MM, year: YYYY }
 */

export function parseObjectToNewDate(obj) {
  if (obj) return new Date(obj.year, obj.month - 1, obj.day);
  return null;
}

/**
 * Checkes if the passed date is an dateObject
 * @param {object} "[object Date]""
 */

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatISODate(date) {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const formattedDate =
    (day <= 9 ? '0' + day : day) +
    '/' +
    (month <= 9 ? '0' + month : month) +
    '/' +
    newDate.getFullYear();
  return formattedDate;
}

export function formatISODateToDashes(date) {
  const newDate = new Date(date);
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const formattedDate =
    newDate.getFullYear() +
    '-' +
    (month <= 9 ? '0' + month : month) +
    '-' +
    (day <= 9 ? '0' + day : day);
  return formattedDate;
}
