/* stylelint-disable value-keyword-case */
/* eslint-disable no-unused-expressions*/
// packages
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SearchSelectInput } from 'wg-fe-ui';

import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';

// components

// stores
import { addChatboxQuestion } from '../../../../../store/index';
import {
  retrieveAutomobileBrands,
  retrieveAutomobileModelsByBrand,
  retrieveAutomobileVersionsBySeries,
} from '../../../../../services/apiRouterService';

import { debounce } from 'lodash';

// import { setCarValue } from '../store/index';

const DEBOUNCE_CONFIG = {
  time: 500,
  options: {
    leading: true,
  },
};

const ManualSearchCarRight = ({ handleData, handleLogo }) => {
  const { t } = useTranslation();
  addChatboxQuestion(t(`CheckCarManual.question`));

  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [fuel, setFuel] = useState();
  const [transmissionType, setTransmissionType] = useState();
  const [versions, setVersions] = useState([]);
  const [displayedList, setDisplayedList] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [sorted, setSorted] = useState({});
  const [selectedVersion, setSelectedVersion] = useState({});
  const [carData, setCarData] = useState({});

  const [brandLogoOptions, setBrandLogoOptions] = useState({});

  let addedHashes = [];

  function computeStringToQuery(value) {
    if (value) return `q=${value}`;
  }

  async function handleBrands(value) {
    const { time, options } = DEBOUNCE_CONFIG;

    const debounceBrands = debounce(retrieveAutomobileBrands, time, options);
    const [resp, status] = await debounceBrands(computeStringToQuery(value));

    if (status !== 200) return null;
    const { items } = resp;

    setBrandLogoOptions(
      items.reduce(function(map, brand) {
        map[brand.brand.key] = brand.brand.logo_url;
        return map;
      }, {}),
    );
    return items.map(({ brand }) => ({
      label: brand.name,
      value: { name: brand.name, id: brand.key },
    }));
  }

  async function handleModels(value, _brand) {
    const { time, options } = DEBOUNCE_CONFIG;
    if (!_brand) return undefined;

    const debounceModels = debounce(
      retrieveAutomobileModelsByBrand,
      time,
      options,
    );
    const [resp, status] = await debounceModels(
      _brand,
      computeStringToQuery(value),
    );

    if (status !== 200) return null;
    const { items } = resp;

    return items.map(({ model }) => ({
      label: model.model,
      value: {
        brandKey: model.brand_key,
        id: model.id,
        keyCategory: model.key_category,
        model: model.model,
      },
    }));
  }

  const handleSort = type => {
    if (displayedList === null) return;
    let inversed = false;
    if (sorted?.type === type && sorted?.inversed) {
      setDisplayedList(versions);
      setSorted({});
    } else {
      if (sorted?.type === type && !sorted?.inversed) {
        inversed = true;
      }
      const sortedList = [...versions];
      sortedList.sort((a, b) => {
        if (a[type] > b[type]) return inversed ? -1 : 1;
        if (a[type] < b[type]) return inversed ? 1 : -1;
        return 0;
      });
      setDisplayedList(sortedList);
      setSorted({ type, inversed });
    }
  };

  useEffect(() => {
    handleLogo(brandLogoOptions[brand]);
    setNoResults(false);
    setTransmissionType();
    setModel();
    setFuel();
    setVersions([]);
    setDisplayedList([]);
  }, [brand]);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    setFuel();
    setVersions([]);
    setDisplayedList([]);
  }, [model]);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
  }, [fuel]);

  useEffect(() => {
    if (fuel && transmissionType) {
      addedHashes = [];
      getVersions(brand, model, fuel);
    } else {
      setVersions([]);
      setDisplayedList([]);
    }
  }, [transmissionType]);

  async function getVersions(_brand, _model, _fuel) {
    const [resp, status] = await retrieveAutomobileVersionsBySeries(
      _brand,
      _model.id,
      `motor_type=${_fuel}&transmission_type=${transmissionType}&pagelen=500`,
    );

    if (status !== 200) return null;

    const { items } = resp;

    const computedVersions = items.map(({ version }) => ({
      id: version.id,
      kw: version.kw,
      version: version.version,
      brand: _brand,
      model: _model.model,
      modelYear: version.model_year,
      price: version.price,
    }));
    if (computedVersions.length === 0) {
      setNoResults(true);
    }
    setVersions(computedVersions.length > 0 ? computedVersions : null);
    setDisplayedList(computedVersions.length > 0 ? computedVersions : null);
  }

  function handleBrand(e) {
    setCarData({ brand: e.value.name, brand_id: e.value.id });
    setBrand(e.value.id);
  }

  function handleModel(value) {
    setCarData({ ...carData, model: value.model, model_id: value.id });
    setModel(value);
  }

  function handleVersion(version_id) {
    const versionDict = versions.reduce(function(map, version) {
      map[version.id] = version.version;
      return map;
    }, {});
    setCarData({ ...carData, version: versionDict?.[version_id], version_id });
    setSelectedVersion(version_id);
  }

  useEffect(() => {
    handleData(carData);
  }, [carData]);

  const fuelTypes = {
    PET: t('Vehicles.motor_types.PET'),
    DIE: t('Vehicles.motor_types.DIE'),
    LPG: t('Vehicles.motor_types.LPG'),
    // DIE_LPG: t('Vehicles.motor_types.DIE_LPG'),
    ELE: t('Vehicles.motor_types.ELE'),
    ELE_PET: t('Vehicles.motor_types.ELE_PET'),
    ELE_DIE: t('Vehicles.motor_types.ELE_DIE'),
    // ELE_LPG: t('Vehicles.motor_types.ELE_LPG'),
    // ELE_HYDRO: t('Vehicles.motor_types.ELE_HYDRO'),
    // HYDRO: t('Vehicles.motor_types.HYDRO'),
    CNG: t('Vehicles.motor_types.CNG'),
    // CNG_PET: t('Vehicles.motor_types.CNG_PET'),
    // BIO: t('Vehicles.motor_types.BIO'),
    // OTHER: t('Vehicles.motor_types.OTHER'),
  };

  const transmissionTypes = [
    { value: 'auto', label: t('auto') },
    { value: 'man', label: t('man') },
  ];

  return (
    <>
      <FilterContainer>
        <div>
          <StyledSearchSelectInput
            async
            name="brand"
            placeholder={t('Choose your option')}
            loadOptions={handleBrands}
            onSelected={e => handleBrand(e)}
            isClearable
          >
            {t('Vehicles.Brand')}
          </StyledSearchSelectInput>
        </div>
        <div>
          <StyledSearchSelectInput
            async
            name="model"
            disabled={!brand}
            placeholder={t('Choose your option')}
            key={brand}
            loadOptions={val => handleModels(val, brand)}
            isClearable
            onSelected={({ value }) => handleModel(value)}
          >
            {t('Vehicles.Model')}
          </StyledSearchSelectInput>
        </div>
      </FilterContainer>
      <FilterContainer>
        <div>
          <StyledSearchSelectInput
            name="motorType"
            isClearable
            disabled={!model}
            placeholder={t('Choose your option')}
            key={model?.id}
            options={Object.entries(fuelTypes).map(([key, value]) => ({
              label: value,
              value: key,
            }))}
            onSelected={({ value }) => {
              setFuel(value);
            }}
          >
            {t('Vehicles.Fuel type')}
          </StyledSearchSelectInput>
        </div>
        <div>
          <StyledSearchSelectInput
            name="transmissionType"
            isClearable
            disabled={!fuel}
            placeholder={t('Choose your option')}
            key={`my_unique_select_key__${fuel}`}
            options={transmissionTypes}
            onSelected={({ value }) => {
              setTransmissionType(value);
            }}
          >
            {t('Vehicles.Transmission type')}
          </StyledSearchSelectInput>
        </div>
      </FilterContainer>
      <VersionLabel>
        <VersionSpan
          className="version"
          sorted={sorted?.type === 'version'}
          onClick={() => handleSort('version')}
        >
          {t('Select your version') +
            (sorted?.type === 'version'
              ? ` ${sorted?.inversed ? 'v' : '^'}`
              : '')}
        </VersionSpan>
        <VersionSpan
          sorted={sorted?.type === 'kw'}
          onClick={() => handleSort('kw')}
        >
          {t('kw') +
            (sorted?.type === 'kw' ? ` ${sorted?.inversed ? 'v' : '^'}` : '')}
        </VersionSpan>
        <VersionSpan
          sorted={sorted?.type === 'price'}
          onClick={() => handleSort('price')}
        >
          {t('Catalogue value') +
            (sorted?.type === 'price'
              ? ` ${sorted?.inversed ? 'v' : '^'}`
              : '')}
        </VersionSpan>
      </VersionLabel>
      <VersionList
        disabled={displayedList?.length === 0 || displayedList === null}
        key={`${fuel} - ${model?.id}`}
      >
        {displayedList?.length !== 0 && displayedList !== null ? (
          displayedList.map(versionObj => {
            const { id, kw, price, version } = versionObj || {};
            // Small hash system used to filter out the duplicates with model year
            const hash = `${kw}${price}${version}`;
            if (addedHashes.includes(hash) || price === null) return '';
            else addedHashes.push(hash);
            return (
              <VersionItem
                key={id}
                id={id}
                isSelected={selectedVersion === id}
                onClick={e => handleVersion(e.currentTarget.id)}
              >
                <Version>{`${version}`}</Version>
                <Kw>{kw}</Kw>
                <Price>
                  {price
                    ? parseFloat(price).toLocaleString('nl-BE', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })
                    : '--'}{' '}
                  EUR
                </Price>
              </VersionItem>
            );
          })
        ) : noResults ? (
          <NoResults>{t('No results')}</NoResults>
        ) : (
          ''
        )}
      </VersionList>
    </>
  );
};

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`;

const VersionList = styled.div`
  background-color: ${props => (props.disabled ? 'hsl(0,0%,95%)' : 'white')};
  box-shadow: 0 0.2rem 0.9rem rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  height: 22rem;
  overflow: scroll;
  margin-bottom: 3rem;
  overflow-x: hidden;
`;

const VersionItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.6;
  color: ${({ theme, isSelected }) =>
    isSelected ? 'white' : theme.typo.interactive};

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.brand.primary : 'none'};
  cursor: pointer;
  padding: 1rem 2rem;
  text-align: left;

  & .version {
    width: 60%;
  }

  & .power {
    width: 22%;
  }

  & .year {
    width: 14%;
  }

  :hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
  }
`;

const VersionLabel = styled.li`
  margin-bottom: 1rem;
  padding: 0 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 1.6;
  color: ${({ theme }) => theme.typo.interactive};

  & .version {
    width: 60%;
  }

  & .power {
    width: 10%;
  }

  & .price {
    width: 20%;
  }

  & span {
    cursor: pointer;
  }
`;

const StyledSearchSelectInput = styled(SearchSelectInput)`
  width: 100%;

  &:focus {
    outline: none;
  }

  & .Select {
    &__control {
      box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.1);
      border-radius: 0.3rem;
      border: none;
    }

    &__control--menu-is-open {
      .Select__dropdown-indicator {
        & svg {
          transform: rotate(180deg);
          transition: 0.1s ease;
        }
      }
    }

    &__value-container {
      padding: 0 0.5em;
      font-family: 2rem;
      font-size: 1.5rem;
      /* text-transform: uppercase; */
    }

    &__single-value {
      /* text-transform: capitalize; */
      color: black;
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-list {
      max-height: 13rem;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.typo.interactive};

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.secondary};
        color: white;
      }

      &--is-focused {
        color: white;
        background-color: ${({ theme }) => theme.brand.secondary};
      }

      &--is-selected {
        background-color: ${({ theme }) => theme.brand.primary};
        color: white;
      }
    }
    .Select__placeholder {
      font-weight: 100;
    }
  }
`;

const NoResults = styled.div`
  font-size: 2.1rem;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const Version = styled.span`
  width: 70%;
`;

const Kw = styled.span`
  width: 10%;
`;

const Price = styled.span`
  text-align: right;
  width: 20%;
`;

const VersionSpan = styled.span`
  ${({ sorted }) => (sorted ? `font-weight: 900; color: #6d6d6d;` : null)}
  position: relative;
  display: inline-block;
  margin-left: -20px;

  &::after {
    position: absolute;
    right: 0;
    content: 'v';
    display: flex;
    align-items: center;
    top: 0;
    height: 100%;
    transition: all 0.3s ease;
    opacity: ${props => (props.ordered ? 1 : 0)};
    transform: rotate(
        ${props =>
          props.orderDirection && props.orderDirection !== null ? 0 : '-180deg'}
      )
      translateY(
        ${props =>
          props.orderDirection && props.orderDirection !== null
            ? '-1px'
            : '-3px'}
      );
  }
`;

ManualSearchCarRight.propTypes = {
  handleData: func.isRequired,
  handleLogo: func.isRequired,
};

export default ManualSearchCarRight;
