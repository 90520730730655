import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

import Section from './Section';
import GardenIcon from '../../assets/images/garden-icon';
import PorchIcon from '../../assets/images/veranda-icon';
import GardenHouseIcon from '../../assets/images/matrix/garden_house';
import SolarPanelsIcon from '../../assets/images/solar-icon';
import CellarIcon from '../../assets/images/cellar-icon';
import AtticIcon from '../../assets/images/attic-icon';
import poolIcon from '../../assets/images/pool-icon';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryPropertyPresent = ({ presentInfo }) => {
  const { t } = useTranslation();

  const {
    hasGarden,
    hasPorch,
    hasGardenHouse,
    hasSolarPanels,
    hasSwimmingPool,
    hasCellar,
    hasAttic,
  } = presentInfo || {};

  return (
    <Section>
      <Section.Subtitle>
        {t('History.property.presentAtHome.title')}
      </Section.Subtitle>
      <Content data-test-id="dashboard_history_detail_property_section_present_at_residence">
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.garden')}
          checked={hasGarden}
          disabled={true}
          icon={GardenIcon}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.porch')}
          checked={hasPorch}
          disabled={true}
          icon={PorchIcon}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.garden_house')}
          checked={hasGardenHouse}
          disabled={true}
          icon={GardenHouseIcon}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.solar_panels')}
          checked={hasSolarPanels}
          disabled={true}
          icon={SolarPanelsIcon}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.swimming_pool')}
          checked={hasSwimmingPool}
          disabled={true}
          icon={poolIcon}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.cellar')}
          checked={hasCellar}
          disabled={true}
          icon={CellarIcon}
        />
        <DashboardCheckboxWithIcon
          wide={4}
          text={t('History.property.presentAtHome.attic')}
          checked={hasAttic}
          disabled={true}
          icon={AtticIcon}
        />
        <DashboardCheckboxWithIcon
          style={{ visibility: 'hidden' }}
          wide={4}
          text={''}
          checked={hasAttic}
          disabled={true}
        />
      </Content>
    </Section>
  );
};

const Content = styled(Section.Content)`
  background: none;
  box-shadow: none;
  padding: 0;
  border: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

DashboardBrokerHistoryPropertyPresent.defaultProps = {};

DashboardBrokerHistoryPropertyPresent.propTypes = {
  presentInfo: shape().isRequired,
};

export default DashboardBrokerHistoryPropertyPresent;
