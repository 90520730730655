import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { string, func, shape, array } from 'prop-types';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import { ActionButton, H1, H2, Pagination, LoadingSpinner } from 'wg-fe-ui';
import { getResultsFromFinishedBatchById } from '../services/apiRouterService';
import ProFlowOffersFilters from './ProFlowOffersFilters';
import ProFlowOffersAccordionBox from './ProFlowOffersAccordionBox';
import Frame from '../../assets/images/Frame.svg';

const ProFlowOffersRiskObject = ({
  batchId,
  error,
  quotes,
  setQuotes,
  infoInsurances,
  retrievedData,
  connectionErrorMsg,
  pagination,
  setPagination,
  filters,
  setFilters,
}) => {
  const PAGE_LENGTH = 10;
  const history = useHistory();
  const { type: riskObjectType } = useParams();
  const [filteredQuotes] = useState(quotes[riskObjectType]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (!quotes || !filters) return;
    if (isLoading) setIsLoading(false);
  }, [quotes, filters]);

  const handleUpdatedFilters = async _filters => {
    setIsLoading(true);

    const filterQueryString = await convertFiltersToQueryString(_filters);
    const [resp, status] = await getResultsFromFinishedBatchById(
      batchId,
      filterQueryString,
    );

    if (status === 200) {
      setFilters(filters => ({
        ...filters,
        [riskObjectType]: {
          ..._filters,
        },
      }));
      setPagination(pagination => ({
        ...pagination,
        [riskObjectType]: {
          page: resp?.pagination.page,
          pages: resp?.pagination.pages,
        },
      }));
      setQuotes(quotes => ({ ...quotes, [riskObjectType]: resp?.items }));
    } else {
      setIsLoading(false);
    }
  };

  const handlePageUpdate = async page => {
    setIsLoading(true);

    const filterQueryString = await convertFiltersToQueryString(
      filters[riskObjectType],
      page,
    );
    const [resp, status] = await getResultsFromFinishedBatchById(
      batchId,
      filterQueryString,
    );

    if (status === 200) {
      setQuotes(quotes => ({ ...quotes, [riskObjectType]: resp?.items }));
      setPagination(pagination => ({
        ...pagination,
        [riskObjectType]: {
          page: resp?.pagination.page,
          pages: resp?.pagination.pages,
        },
      }));
    } else {
      setIsLoading(false);
    }
  };

  async function convertFiltersToQueryString(filters, page) {
    const { insurers, guarantees, insurances, priceRange } = filters;
    const [min, max] = priceRange?.values || [];

    let sanitizedFilters = {
      pagelen: PAGE_LENGTH,
      company: Object.keys(insurers).filter(key => insurers[key]),
      guarantee: Object.keys(guarantees).filter(key => guarantees[key]),
      insurance: Object.keys(insurances).filter(key => insurances[key]),
      insurance_type: riskObjectType,
    };

    if (page) {
      sanitizedFilters.page = page;
    }

    if (min !== priceRange.min) {
      sanitizedFilters.price_min = min?.toFixed(2);
    }

    if (max !== priceRange.max) {
      sanitizedFilters.price_max = max?.toFixed(2);
    }

    return qs.stringify(sanitizedFilters);
  }

  if (error) {
    return <H1>{error}</H1>;
  }

  const clickHandler = async (e, link) => {
    if (e != null) {
      e.preventDefault();
    }
    if (link.includes('http://') || link.includes('https://')) {
      window.location.href = link;
    } else if (link) {
      history.push(link);
    } else {
      return;
    }
  };

  if (retrievedData == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('There was an issue calculating your quotes')}</h1>
        <p>{t(connectionErrorMsg)}</p>
        <ActionButton onClick={e => clickHandler(e, '/professional-flow')}>
          {t('Go back to Pro-Flow')}
        </ActionButton>
      </EmptyState>
    );
  }

  return isLoading ? (
    <LoadingSpinnerWrapper>
      <LoadingSpinner />
    </LoadingSpinnerWrapper>
  ) : (
    <MainContent>
      <ProFlowOffersFilters
        initialFilters={filters?.[riskObjectType]}
        onSubmit={handleUpdatedFilters}
        infoInsurances={infoInsurances}
      />

      <QuotesGridWrapper>
        {filteredQuotes?.length === 0 ? (
          <LoadingContainer>
            <H1>{t(`No results found`)}</H1>
            <H2>{t(`change your filters to get more quotes`)}</H2>
          </LoadingContainer>
        ) : (
          <>
            <OffersTabs>
              <span>{t('Insurer')}</span>
              <span>{t('Insurance')}</span>
              <span>{t('Guarantee')}</span>
              <span className="last">{t('Total premium')}</span>
              <span></span>
            </OffersTabs>
            {filteredQuotes?.map(sanitizedQuote => {
              return (
                !!sanitizedQuote && (
                  <ProFlowOffersAccordionBox
                    id={sanitizedQuote.id}
                    riskObjectType={riskObjectType}
                    insurance={sanitizedQuote.insurance}
                    insuranceCompany={sanitizedQuote.insuranceCompany}
                    insuranceType={sanitizedQuote.insuranceType}
                    guarantees={sanitizedQuote.guarantees}
                    options={sanitizedQuote.options}
                    language={sanitizedQuote.language}
                    links={sanitizedQuote.links}
                    premium={sanitizedQuote.premium}
                    promotions={sanitizedQuote.promotions}
                    quote={sanitizedQuote.quote}
                    CRM={sanitizedQuote.CRM}
                    error={sanitizedQuote.error}
                    originalQuote={sanitizedQuote.originalQuote}
                    fullCurrentQuote={sanitizedQuote}
                  />
                )
              );
            })}
          </>
        )}

        <ContentFooter>
          {pagination[riskObjectType]?.pages > 1 && !isLoading && (
            <Pagination
              onClick={number => handlePageUpdate(number)}
              prevText={t('PrevPGN')}
              nextText={t('NextPGN')}
              currentPage={pagination[riskObjectType]?.page}
              totalPages={pagination[riskObjectType]?.pages}
            />
          )}
        </ContentFooter>
      </QuotesGridWrapper>
    </MainContent>
  );
};

const LoadingSpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
`;

const EmptyState = styled.div`
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.font};
  height: 100%;
  justify-content: center;
  line-height: 135%;
  text-align: center;
  width: 100%;

  img {
    margin-bottom: 50px;
    margin-left: 55px;
  }

  h1 {
    font-weight: bold;
    font-size: 2.1rem;
    letter-spacing: -0.03em;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    width: 65%;
    strong {
      font-weight: bold;
    }
  }

  button {
    padding: 1rem 1.4rem;
  }
`;
const QuotesGridWrapper = styled.div`
  width: 100%;
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;

  .records {
    font-size: 1.4rem;
    line-height: 130%;
    color: ${({ theme }) => theme.ui.disabled};

    strong {
      font-weight: 900;
    }
  }
`;

const OffersTabs = styled.div`
  display: grid;
  grid-template-columns: 15% 20% 40% 17%;
  margin-top: 2rem;
  .last {
    text-align: right;
  }
`;

const LoadingContainer = styled.div`
  margin-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  display: grid;
  grid-template-columns: 20rem auto;
  margin-bottom: 5rem;
  grid-gap: 3rem;
`;

ProFlowOffersRiskObject.propTypes = {
  batchId: string,
  error: string,
  quotes: shape({}),
  setQuotes: func,
  infoInsurances: array,
  retrievedData: shape({}),
  connectionErrorMsg: string,
  pagination: shape({}),
  setPagination: func,
  filters: shape({}),
  setFilters: func,
};

export default ProFlowOffersRiskObject;
