export let loggedIn = false;

export function setLoggedIn(param) {
  loggedIn = param;
}

export async function goToLogin() {
  if (process.env.NODE_ENV === 'development') {
    window.location.href = '/admin-login';
    return;
  }
  const baseApi = 'https://api.phoenix.dev.qa.wegroup.be/';
  // const baseApi = process.env.REACT_APP_BASE_URL;
  switch (baseApi) {
    case 'https://api.phoenix.dev.qa.wegroup.be/':
      window.location.href = 'https://marketplace.dev.qa.wegroup.be/login';
      break;
    case 'https://api.phoenix.dev.staging.wegroup.be/':
      window.location.href = 'https://marketplace.dev.staging.wegroup.be/login';
      break;
    case 'https://api.phoenix.wegroup.be/':
      window.location.href = 'https://marketplace.wegroup.be/login';
      break;
    default:
  }
}

export function formattedLoginExeptions(msg, status) {
  const passwordMismatch = ['PASSWORD_MISMATCH', 'PASSWORD_VALIDATION'];
  const environmentMismatch = ['ENVIRONMENT_MISMATCH'];
  const userExpired = ['USER_EXPIRED'];
  const googleTokenExpired = ['CAPTCHA_LOGIN_FAILED'];
  const tryAgainLater = [
    'AUTHENTICATION_EXPIRED',
    'AUTHENTICATION_FAILED',
    'NOT_AUTHENTICATED',
    'REFRESH_TOKEN_INCORRECT',
  ];
  const userNotFound = ['USER_BLACKLISTED', 'USER_NOT_FOUND'];

  let responseMsg = '';

  if (status === 400) {
    if (passwordMismatch.includes(msg)) responseMsg = 'passwordMismatch';
    if (googleTokenExpired.includes(msg)) responseMsg = 'googleTokenExpired';
  }

  if (status === 401) {
    if (passwordMismatch.includes(msg)) responseMsg = 'passwordMismatch';
    if (environmentMismatch.includes(msg)) responseMsg = 'environmentMismatch';
    if (userExpired.includes(msg)) responseMsg = 'userExpired';
    if (tryAgainLater.includes(msg)) responseMsg = 'tryAgainLater';
    if (userNotFound.includes(msg)) responseMsg = 'userNotFound';
  }

  if (status === 404) {
    if (userNotFound.includes(msg)) responseMsg = 'userNotFound';
  }

  return responseMsg;
}

export function formatChangePasswordExeptions(msg, status) {
  const passwordMismatch = ['PASSWORD_MISMATCH'];
  const environmentMismatch = ['ENVIRONMENT_MISMATCH'];
  const tryAgainLater = [
    'AUTHENTICATION_EXPIRED',
    'AUTHENTICATION_FAILED',
    'NOT_AUTHENTICATED',
    'REFRESH_TOKEN_INCORRECT',
  ];
  const userNotFound = ['USER_BLACKLISTED'];

  const validationError = ['VALIDATION_ERROR'];

  const passwordsAreTheSame = ['PASSWORDS_ARE_THE_SAME'];

  let responseMsg = '';

  if (status >= 400) {
    if (passwordsAreTheSame.includes(msg)) responseMsg = 'passwordsAreTheSame';
    if (passwordMismatch.includes(msg)) responseMsg = 'passwordMismatch';
    if (environmentMismatch.includes(msg)) responseMsg = 'environmentMismatch';
    if (tryAgainLater.includes(msg)) responseMsg = 'tryAgainLater';
    if (userNotFound.includes(msg)) responseMsg = 'userNotFound';
    if (validationError.includes(msg)) responseMsg = 'isNotValid';
  }

  return responseMsg;
}
