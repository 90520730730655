/* stylelint-disable value-keyword-case, declaration-no-important */
// packages
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';

// components
import Layout from '../../../LayoutFlow/views/App';
import LeftSide from '../../../../../components/LeftSide';
import RightSide from '../../../../../components/RightSide';

// stores

import ManualSearchCarLeft from '../../../../../components/ManualSearchCarLeft';
import ManualSearchCarRight from './ManualSearchCarRight';
import Button from '../../../../../components/Button';
import LoadingButton from '../../../../../components/LoadingButton';
import {
  addChatboxAnswer,
  getNextPath,
  addBrowserHistory,
} from '../../../../../store';
import { getManualData, addCarData, addEditedCarExtra } from '../../store';
import { useTranslation } from 'react-i18next';

const CheckCarManual = ({ history }) => {
  const { t } = useTranslation();
  const [carLogo, setCarLogo] = useState();
  const [btnText, setBtnText] = useState('Verzend');
  const [sendStep, setSendStep] = useState('initial');
  const [isDisabled, setIsDisabled] = useState(true);
  const location = useLocation();
  const [selectedItems, setSelectedItems] = useState({
    brand: '',
    model: '',
    version: '',
    brand_id: '',
    model_id: '',
    version_id: '',
  });

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    if (Object.keys(selectedItems).length >= 3) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [selectedItems]);

  const goManualInput = () => history.push(getNextPath('b'));

  const carData = data => {
    setSelectedItems(data);
  };

  const logoCar = logo => {
    setCarLogo(logo);
  };

  useEffect(() => {
    let btnTextTemp = `${t(`CheckCarManual.btnText.pending`)} `;
    let _btnText;
    let _isDisabled;
    switch (sendStep) {
      case 'pending':
        _btnText = t(`CheckCarManual.btnText.pending`);
        _isDisabled = true;

        if (
          btnTextTemp.length >
          `${t(`CheckCarManual.btnText.pending`)} ...`.length - 1
        ) {
          btnTextTemp = `${t(`CheckCarManual.btnText.pending`)} `;
          setBtnText(btnTextTemp);
        } else {
          btnTextTemp = `${btnTextTemp}.`;
          setBtnText(btnTextTemp);
        }

        break;
      case 'success':
        _btnText = t(`CheckCarManual.btnText.success`);
        _isDisabled = false;
        addChatboxAnswer(
          t(`CheckCarManual.interactiveMsg`, {
            brand: selectedItems?.brand,
            model: selectedItems?.model,
            version: selectedItems?.version,
          }),
        );
        history.push(getNextPath());
        break;
      case 'error':
        _isDisabled = true;
        _btnText = t(`CheckCarManual.btnText.error`);
        break;
      default:
        _btnText = t(`CheckCarManual.btnText.default`);
        _isDisabled = true;
        break;
    }
    if (_btnText !== btnText && _btnText !== 'success') {
      setBtnText(_btnText);
      setIsDisabled(_isDisabled);
    }
  }, [sendStep]);

  const onClickHandler = () => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    } else {
      setSendStep('pending');
    }
    getManualData(selectedItems)
      .then(res => {
        const { car } = res[0];
        const formattedCar = {
          name: car?.name,
          brand: car?.brand,
          model: car?.series,
          version: car?.version,
          motor_type: car?.motor_type,
          seats: car?.amount_of_seats_incl_driver,
          cc: car?.cc !== 0 ? car?.cc : null,
          kw: car?.kw !== 0 ? car?.kw : null,
          co2: car?.co2 !== 0 ? car?.co2 : null,
          img: car?.images && car?.images.length > 0 ? car.images[0] : null,
          value: car?.price,
          model_year: car?.model_year,
          febiac_id: car?.febiacid,
          is_cabrio: car?.category === 'CABRIO',
          is_jeep: car?.category === 'SUV',
          is_sportscar: car?.is_sportscar,
        };
        Object.keys(formattedCar).forEach(key => {
          addCarData(key, formattedCar[key]);
          addEditedCarExtra(key);
        });
        setSendStep('success');
      })
      .catch(error => {
        setSendStep('error');
        console.error(error);
      });
  };

  return (
    <Layout>
      <Container>
        <LeftBlock>
          <ManualSearchCarLeft
            carLogo={carLogo}
            brandData={selectedItems?.brand}
            modelData={selectedItems?.model}
            versionData={selectedItems?.version}
          />
        </LeftBlock>
        <RightSide>
          <ManualSearchCarRight
            history={history}
            handleData={data => carData(data)}
            handleLogo={logo => logoCar(logo)}
          />
          <ButtonContainer>
            <EditButton
              data-test-id="underwriting_car_manual"
              type="submit"
              onClick={() => goManualInput()}
            >
              {t(`Car not found`)}
            </EditButton>
            {sendStep !== 'pending' ? (
              <Button
                dataTestId="underwriting_car_submit"
                type="submit"
                onClick={e => onClickHandler(e)}
                disabled={isDisabled}
              >
                {btnText}
              </Button>
            ) : (
              <LoadingButton>{btnText}</LoadingButton>
            )}
          </ButtonContainer>
        </RightSide>
      </Container>
    </Layout>
  );
};

const EditButton = styled.p`
  text-decoration: underline;
  color: ${({ theme }) => theme.brand.primary};
  cursor: pointer;
  line-height: 2rem;
`;

const LeftBlock = styled(LeftSide)`
  width: 50%;
  margin-top: 11rem;
  & p {
    text-align: left;
  }
`;

const Container = styled.section`
  display: flex;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;
`;

const ButtonContainer = styled.div`
  border-radius: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 0.5rem;
  z-index: 1;

  & button {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

CheckCarManual.propTypes = {
  history: shape().isRequired,
};

export default withRouter(CheckCarManual);
