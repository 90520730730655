import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Body,
  H4,
  // IconCustomLink,
  IconHyperLink,
  IconLinkBlock,
  IconMeasureFilled,
  IconPopup,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';

import CampaignMethodInstructions from './CampaignMethodInstructions';
import { string } from 'prop-types';
import { useCampaignById } from '../context/CampaignContext';

const CampaignUseInstructions = ({ id }) => {
  const { url } = useCampaignById(id) || {};
  const { t } = useTranslation();
  const [selectedCampaignMethod, setSelectedCampaignMethod] = useState('link');

  return (
    <InstructionsContainer>
      <Body light>{t('A campaign can be implemented in different ways')}</Body>
      <Methods>
        <H4>{t('Select one of four methods for more information')}</H4>
        <div>
          <StyledIconLinkBlock
            icon={<IconHyperLink />}
            iconType="stroke"
            onClick={() => setSelectedCampaignMethod('link')}
            name="link"
            selected={selectedCampaignMethod === 'link'}
            info={t('You can copy the URL and place it anywhere')}
            label={t('Copy as link')}
          />
          <StyledIconLinkBlock
            icon={<IconMeasureFilled color="#D5D5D5" />}
            iconType="fill"
            name="iframe"
            onClick={() => setSelectedCampaignMethod('iframe')}
            selected={selectedCampaignMethod === 'iframe'}
            info={t('Embed the flow on a website in an iframe')}
            label={t('Using an iframe')}
          />
          <StyledIconLinkBlock
            icon={<IconPopup />}
            iconType="stroke"
            name="popup"
            onClick={() => setSelectedCampaignMethod('popup')}
            selected={selectedCampaignMethod === 'popup'}
            info={t('You can use the iframe and more logic to open a popup')}
            label={t('In a popup')}
          />
          {/* <StyledIconLinkBlock
            icon={<IconCustomLink />}
            iconType="stroke"
            name="custom_link"
            onClick={() => setSelectedCampaignMethod('custom_link')}
            selected={selectedCampaignMethod === 'custom_link'}
            info={t(
              'Use your own URL to act as the flow in a safe and secure manner',
            )}
            label={t('Custom link')}
          /> */}
        </div>
      </Methods>
      <CopyAsLinkContainer>
        <CampaignMethodInstructions
          url={url}
          selected={selectedCampaignMethod}
        />
      </CopyAsLinkContainer>
    </InstructionsContainer>
  );
};

const StyledIconLinkBlock = styled(IconLinkBlock)`
  width: -webkit-fill-available;
  height: 20rem;
  padding-top: 0;
  background-color: ${({ selected }) => (selected ? '#FF8000' : '#FFFFFF')};
  div {
    color: ${({ selected }) => (selected ? '#FFFFFF !important' : '#222222')};
  }

  svg {
    padding-top: ${({ name }) =>
      name !== 'iframe' && name !== 'popup' && '.5rem'};
    padding-left: ${({ name }) =>
      name !== 'iframe' && name !== 'popup' && '.5rem'};
    margin: ${({ name }) => name === 'popup' && '.5rem 0 0 .5rem'};

    path {
      fill: ${({ selected, name }) =>
        selected && name === 'iframe' && '#FFFFFF !important'};
      stroke: ${({ selected, name }) =>
        selected && name !== 'iframe' && '#FFFFFF !important'};
    }
  }
  div:nth-child(2) {
    bottom: 6.6rem;
  }
  div:last-child {
    color: ${({ selected }) =>
      selected ? '#FFFFFF !important' : '#8990A3 !important'};
  }
  :hover {
    border: 1px solid #ff8000;
    svg > path {
      fill: ${({ selected, name }) =>
        selected && name === 'iframe' && '#FFFFFF !important'};
      stroke: ${({ selected, name }) =>
        selected && name !== 'iframe' && '#FFFFFF !important'};
    }
  }
`;

const CopyAsLinkContainer = styled.div`
  background: #f0f1f3;
  border-radius: 5px;
  padding: 3rem;
  margin-top: 3rem;
  h4 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 3rem;
  }
`;

const Methods = styled.div`
  > h4 {
    margin-bottom: 2rem;
  }
  > div {
    display: flex;
    flex-direction: row;
    grid-gap: 2rem;
  }
`;

const InstructionsContainer = styled.div`
  > p {
    margin-bottom: 3rem;
  }
`;

CampaignUseInstructions.propTypes = {
  id: string.isRequired,
};

export default CampaignUseInstructions;
