import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';
// import CNAMEmockup from '../../../../ assets / images / CNAMEmockup.png';
import CNAMEmockup from '../../../../assets/images/CNAMEmockup.png';
import {
  // ActionButton,
  Alert,
  Body,
  H4,
  CodeBox,
  GuidedImage,
  // SearchSelectInput,
  // TextInput,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';

const CampaignMethodInstructions = ({ selected, url }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState();
  const [infoDetail, setInfoDetail] = useState();
  const [exampleCode, setExampleCode] = useState();

  useEffect(() => {
    switch (selected) {
      case 'link':
        setTitle('Copy as link');
        setInfoDetail(
          'You can for example make any word in an email clickable, with the campaign URL as the link behind it This way the customer will still be able to open the flow in the browser and outside of the email An example of such a link can be found below',
        );
        setExampleCode(
          `<a href=”https://${url}” target=”_blank”>Click here</a>`,
        );
        break;
      case 'iframe':
        setTitle('Using an iframe');
        setInfoDetail(
          'You can set up a website (one static page) containing a full screen iframe, which displays the flow This page is then hosted by you, but as a result, it uses a URL of your choosing The code for such a page containing your campaign can be found below',
        );
        setExampleCode(
          `<!DOCTYPE html> <html> <head> <meta name="viewport" content="width=device-width, initial-scale=1" /> <style> .body { margin: 0px; } /* iFrame */ .iframe { position: fixed; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%; border: none; margin: 0; padding: 0; overflow: hidden; z-index: 999999; } </style> </head> <body class="body"> <iframe class="iframe" src="https://${url}" ></iframe> </body> </html>`,
        );
        break;
      case 'popup':
        setTitle('In a popup');
        setInfoDetail(
          'Adds a popup to a website which gets activated when the user presses a button for example This opens a popup containing the complete flow, which is also contained within your own website of choice',
        );
        setExampleCode(
          `
        <!DOCTYPE html>
        <html>
        <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <style>
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
          .hide-ie {
            display: none;
          }  
        }
          
        @supports not (-ms-high-contrast: none) {
          .show-ie {
            display: none;
          }
        }
          
        /* The Modal (background) */
        .modal {
          display: none; /* Hidden by default */
          position: fixed; /* Stay in place */
          z-index: 90000000; /* Sit on top */
          left: 0;
          top: 0;
          width: 100%; /* Full width */
          height: 100%; /* Full height */
          overflow: auto; /* Enable scroll if needed */
          background-color: rgb(0,0,0); /* Fallback color */
          background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        }

        /* Modal Content */
        .modal-content {
          margin: auto;
          width: 90%;
          height: 90%;
        }

        /* iFrame */
        .iframe {
          height: 100%;
          width: 100%;
          border: none;
          margin-top: 60px;
        }

        /* The Close Button */
        .close {
          color: #aaaaaa;
          font-size: 28px;
          font-weight: bold;
          position: fixed;
          right: 7%;
          top: 90px;
        }

        .close:hover,
        .close:focus {
          color: #FFFFFF;
          text-decoration: none;
          cursor: pointer;
        }

        @media only screen and (max-width: 425px) {
          .modal {
            margin-top: 0px;
            z-index: 9999999999;
          }
          .modal-content {
            width: 98%;
            height: 98%;
          }
          .close {
            top: 20px;
          }
        }
        </style>
        </head>
        <body>

        <h2>Premie berekenen voorbeeld</h2>

        <!-- Trigger/Open The Modal -->
        <button id="myBtn" class="button">Bereken je premie</button>

        <!-- The Modal -->
        <div id="myModal" class="modal">

        <!-- Modal content -->
        <div class="modal-content">
          <span class="close">&times;</span>
        <iframe src="https://${url}" class="iframe"></iframe>
        </div>

        </div>

        <script>
        // Get the modal
        var modal = document.getElementById("myModal");

        // Get the button that opens the modal
        var btn = document.getElementById("myBtn");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        // When the user clicks the button, open the modal
        btn.onclick = function() {
        modal.style.display = "block";
        }

        // When the user clicks on <span> (x), close the modal
        span.onclick = function() {
        modal.style.display = "none";
        }

        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
        }
        </script>

        </body>
        </html>`,
        );
        break;
      case 'custom_link':
        setTitle('Custom link');
        setInfoDetail(
          'A custom link can be used by using a CNAME on any url of your choosing CNAMEs create an alias of your url to redirect it to another domain, while still maintaining your url in the address bar For example https//my-campaign-urlbe can point to your campaign url https//dsf234adjk1campaignswebsiteslouiseforbrokersbe',
        );
        break;
      default:
        setTitle('Copy as link');
        setInfoDetail(
          'You can for example make any word in an email clickable, with the campaign URL as the link behind it This way the customer will still be able to open the flow in the browser and outside of the email An example of such a link can be found below',
        );
        break;
    }
  }, [selected]);

  return (
    <>
      <Title>{t(title)}</Title>
      <Body light>{t(infoDetail)}</Body>
      {selected !== 'custom_link' ? (
        <>
          <H4>{t('Example code')}</H4>
          <CodeBox canCopy>{exampleCode}</CodeBox>
        </>
      ) : (
        <>
          <StyledAlert icon type="warning">
            {t(
              'The example below is a generic example of how a record might be added This is not a working form Contact your website administrator with the following info to ensure your record is added correctly',
            )}
          </StyledAlert>
          <CNAMEInstructions>
            <H4>{t('How do I create a CNAME for my domain?')}</H4>
            <Body>
              <ol>
                <li>{t('Open the control panel of your domain name')}</li>
                <li>{t('Go to your DNS settings')}</li>
                <li>{t('Add a new CNAME record')}</li>
                <li>{t('Enter the following details')}</li>
                <ul>
                  <li>
                    -{' '}
                    {t(
                      'The subdomain (required) that will become the alias, for example, campaign',
                    )}
                  </li>
                  <li>
                    -{' '}
                    {t(
                      'The domain name that you want to create the alias of, cant be an IP address',
                    )}
                  </li>
                </ul>
                <li>{t('Save your changes')}</li>
              </ol>
            </Body>
            <H4>{t('Example of a DNS record form')}</H4>
            <GuidedImage
              className="guidedImage"
              imageUrl={CNAMEmockup}
              steps={[
                {
                  content: t(
                    'When asked what type of DNS record should be created; choose CNAME',
                  ),
                  offsetLeft: '50%',
                  offsetTop: '7rem',
                },
                {
                  content: 'This is some content for the second step.',
                  offsetLeft: '25%',
                  offsetTop: '15.5rem',
                },
                {
                  content: 'This is some content for the third step.',
                  offsetLeft: '75%',
                  offsetTop: '15.5rem',
                },
              ]}
            />
            {/* <GuidedImageContent>
              <SearchSelectInput
                placeholder={t('CNAME')}
                options={[{ label: 'CNAME', value: t('CNAME') }]}
              >
                {t('Type of DNS record')}
              </SearchSelectInput>
              <DomainNameInput placeholder={t('your-chosen-name')}>
                {t('Name')}
                <span>.your-domain.com</span>
              </DomainNameInput>
              <TextInput
                placeholder={t(
                  'https://xxxxxxxxxxxx.campaign.websites.louiseforbrokers.be',
                )}
              >
                {t('Hostname')}
              </TextInput>
              <TextInput placeholder={t('3000')}>{t('TTL')}</TextInput>
              <ActionButton
                level="default"
                onClick={() => console.log('Add DNS record')}
              >
                {t('Add DNS record')}
              </ActionButton>
            </GuidedImageContent> */}
          </CNAMEInstructions>
        </>
      )}
    </>
  );
};

const StyledAlert = styled(Alert)`
  align-items: center;
`;

const Title = styled(H4)`
  font-size: 1.8rem;
`;

// const GuidedImageContent = styled.div`
//   display: grid;
//   grid-template-columns: repeat(6, 1fr);
//   column-gap: 36px;
//   background: #ffffff;
//   border: 1px solid #f0f1f3;
//   box-sizing: border-box;
//   box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
//     0px 0px 1px rgba(0, 0, 0, 0.04);
//   border-radius: 5px;
//   padding: 3rem;
//   margin: -1rem 1rem 0 1rem;
//   div:first-child {
//     grid-column: span 6;
//   }
//   div:nth-child(2) {
//     grid-column: span 3;
//   }
//   div:nth-child(3) {
//     grid-column: span 3;
//   }
//   div:nth-child(4) {
//     grid-column: span 3;
//   }
//   button {
//     grid-column: 5 / span 2;
//     align-self: center;
//   }
// `;

// const DomainNameInput = styled(TextInput)`
//   label {
//     position: relative;
//     svg {
//       right: 5rem;
//     }
//   }

//   span {
//     border-left: 1px solid #ccc;
//     color: #222;
//     padding: 1.4rem;
//     position: absolute;
//     right: 0;
//     top: 2.5rem;
//   }

//   input::-webkit-outer-spin-button,
//   input::-webkit-inner-spin-button {
//     -webkit-appearance: none;
//     margin: 0;
//   }
//   input[type='number'] {
//     -moz-appearance: textfield;
//   }
// `;

const CNAMEInstructions = styled.div`
  h4 {
    margin-top: 2.5rem;
  }
  ol {
    color: #8990a3;
    font-size: 1.6rem;
    line-height: 2rem;
    list-style: inside decimal;
    ul {
      margin-left: 3rem;
    }
  }
  .guidedImage {
    z-index: 10;
  }
`;

CampaignMethodInstructions.propTypes = {
  selected: string,
  url: string,
};

export default CampaignMethodInstructions;
