import React from 'react';
import { string } from 'prop-types';

const CellarIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
    >
      <path
        fill={color}
        d="M17.737 5.506l-2.32-1.49V1.009a.568.568 0 00-.562-.568h-2.207a.568.568 0 00-.56.568v.852L9.328.086a.556.556 0 00-.595 0l-8.47 5.42a.563.563 0 00-.263.48v13.19c0 .452.368.824.815.824h16.37a.824.824 0 00.815-.825V5.976a.548.548 0 00-.263-.47zm-.876 13.34h-1.813v-1.853h-1.857V15.13H1.14V6.304L9.03 1.257l2.452 1.58 1.752 1.126V1.594h1.051v3.052l.526.337 2.05 1.313v12.55z"
      ></path>
      <path
        fill={color}
        d="M11.786 9.12a.828.828 0 00-1.021 0l-1.021.84V6.595c0-.328-.327-.596-.725-.596-.398 0-.725.268-.725.596v3.372l-1.021-.839c-.266-.252-.715-.277-1.021-.059-.306.218-.337.587-.072.84.02.024.051.041.072.058l2.246 1.862a.852.852 0 001.021 0l2.267-1.862c.286-.235.286-.613 0-.847.01 0 0 0 0 0z"
      ></path>
    </svg>
  );
};

CellarIcon.defaultProps = {
  color: `white`,
};

CellarIcon.propTypes = {
  color: string,
};

export default CellarIcon;
