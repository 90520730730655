import React, { useEffect, useState } from 'react';
import { ActionButton } from 'wg-fe-ui';
import { shape } from 'prop-types';
import ProFlowQuestionsPolicyHolderSection from './ProFlowQuestionsPolicyHolderSection';
import ProFlowQuestionsDriver from './ProFlowQuestionsDriver';
import ProFlowQuestionsCar from './ProFlowQuestionsCar';
import ProFlowQuestionsFamily from './ProFlowQuestionsFamily';
import ProFlowQuestionsLegal from './ProFlowQuestionsLegal';
import ProFlowQuestionsHome from './ProFlowQuestionsHome';
import ProFlowQuestionsSpecs from './ProFlowQuestionsSpecs';
import ProFlowQuestionsTrailer from './ProFlowQuestionsTrailer';
import styled from 'styled-components';
import { createRiskObjectSession } from '../services/apiRouterService';
import { useParams } from 'react-router';
import { retrieveStorageById } from '../services/storeService.js';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Modal } from 'wg-fe-ui';
import {
  parseIncDateToObject,
  formatISODate,
} from '../services/dateService.js';
import regex from '../services/regexService.js';

const FORBIDDEN_TAG = [];

const ProFLowRiskObjectsQuestion = ({ questions }) => {
  const { t, i18n } = useTranslation();
  const { sessionId } = useParams();
  const history = useHistory();
  const [tagsList, setTagsList] = useState([]);
  const [questionErrors, setQuestionErrors] = useState([]);
  const [error, setError] = useState();

  // This state is used to track the LAST focused field
  // So that API calls are not made when the field isn't focused
  const [focus, setFocus] = useState('');

  useEffect(() => {
    categoriseQuestions(questions);
  }, [questions]);

  function categoriseQuestions(_questions = []) {
    const tagsArr = retrieveQuestionTags(_questions);
    setTagsList(tagsArr);
  }

  function retrieveQuestionTags(_questions = []) {
    const tagSet = new Set();
    _questions.forEach(({ tag }) => {
      tagSet.add(tag);
    });
    return [...tagSet];
  }

  async function handleSubmit() {
    const { answers } = await retrieveStorageById('pro_flow', sessionId);
    answers.map((answer, i) => {
      if (answer.type === 'DATE') {
        answer.answer = parseIncDateToObject(formatISODate(answer.answer));
      } else if (answer.type === 'ADDRESS') {
        answer.answer = {
          street: answer.answer.streetName,
          city: answer.answer.municipalityName,
          zipcode: answer.answer.postalCode,
          housenr: answer.answer.streetNumber,
          country_code: answer.answer.country_code,
          boxnr: answer.answer.boxNumber,
        };
      } else if (answer.type === 'CAR') {
        answer.answer.catalogue_value = answer?.answer?.car_value;
        answer.answer.registration_first = parseIncDateToObject(
          answer?.answer?.registrationFirst,
        );
        delete answer.answer.vat_regime;
        delete answer.answer.registrationFirst;
        delete answer.answer.car_value;
        delete answer.answer.initial;
        delete answer.answer.options_value;
      } else if (answer.type === 'TELEPHONE_NUMBER') {
        if (answer.answer.length <= 4) {
          delete answers[i];
        }
      }
      delete answer.prefilled;
      delete answer.type;
      return answer;
    });

    const [resp, status] = await createRiskObjectSession(sessionId, {
      answers: answers
        .filter(({ id }) => id?.match(regex.uuid))
        .filter(({ tag }) => !FORBIDDEN_TAG.includes(tag))
        .map(({ typeTags, field, tag, ...rest }) => rest),
    });
    if (status === 400) {
      setError(resp?.detail?.[0]?.msg);
    }
    if (resp?.code === 400) {
      const wrongIds = resp?.validation_errors?.map(({ id }) => id) || [];
      console.log(wrongIds);
      setQuestionErrors(wrongIds);
      return;
    }
    history.push(`/professional-flow/offers/${sessionId}`);
  }

  return (
    <Container>
      {tagsList.includes('POLICY_HOLDER') && (
        <ProFlowQuestionsPolicyHolderSection
          tag="policy holder"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(
            ({ tag }) => tag === 'POLICY_HOLDER',
          )}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('FAMILY') && (
        <ProFlowQuestionsFamily
          tag="family"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'FAMILY')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('LEGAL') && (
        <ProFlowQuestionsLegal
          tag="legal"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'LEGAL')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('DRIVER') && (
        <ProFlowQuestionsDriver
          tag="driver"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'DRIVER')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('OWNER') && (
        <ProFlowQuestionsDriver
          tag="owner"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'OWNER')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('CAR') && (
        <ProFlowQuestionsCar
          tag="car"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'CAR')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('TRAILER') && (
        <ProFlowQuestionsTrailer
          tag="trailer"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'TRAILER')}
        />
      )}
      {tagsList.includes('HOME') && (
        <ProFlowQuestionsHome
          tag="home"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'HOME')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      {tagsList.includes('SPECS') && (
        <ProFlowQuestionsSpecs
          tag="specs"
          questionErrors={questionErrors}
          allQuestions={questions}
          taggedQuestions={questions.filter(({ tag }) => tag === 'SPECS')}
          focus={focus}
          setFocus={setFocus}
        />
      )}
      <ActionButton
        className="calculate"
        // a timeout to wait until answer is saved
        // will be removed when batch validation is done
        onClick={() => setTimeout(handleSubmit, 200)}
      >
        {t(`Calculate offers`)}
      </ActionButton>
      <Modal
        showModal={questionErrors.length !== 0}
        title={t(
          'Oops, the following questions have not been filled in correctly',
        )}
        setShowModal={() => setQuestionErrors([])}
      >
        <ul>
          {questionErrors?.map(errorId => {
            return (
              <li key={errorId}>
                -{' '}
                {
                  questions.filter(({ id }) => id === errorId)[0].title[
                    i18n.language
                  ]
                }
              </li>
            );
          })}
        </ul>
      </Modal>
      <Modal showModal={!!error} setShowModal={() => setError()}>
        <BigErrorMsg>{error}</BigErrorMsg>
      </Modal>
    </Container>
  );
};

const BigErrorMsg = styled.p`
  font-size: 2rem;
  margin: 0 auto 5rem;
  text-align: center;
  padding: 3rem 6rem;
`;

const Container = styled.div`
  width: 100%;

  button.calculate {
    margin-top: 3rem;
    margin-left: auto;
  }
`;

ProFLowRiskObjectsQuestion.propTypes = {
  questions: shape().isRequired,
};

export default ProFLowRiskObjectsQuestion;
