import React from 'react';
import { bool, string, shape, func } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import cogoToast from 'cogo-toast';
import { getCurrentTheme, getTrialAccount } from '../../../store/index';
import UnderwritingIcon from '../../../../assets/images/dashboard/UnderwritingIcon';
import ClaimsIcon from '../../../../assets/images/dashboard/ClaimsIcon';
import CustomerLinks from '../../../../assets/images/dashboard/CustomerLinks';
import DocsIcon from '../../../../assets/images/dashboard/DocsIcon';
import GlobeIcon from '../../../../assets/images/dashboard/GlobeIcon';
import ToolkitIcon from '../../../../assets/images/dashboard/ToolkitIcon';
import SettingsIcon from '../../../../assets/images/dashboard/SettingsIcon';
import { getNestedObject } from '../../../services/objectService';
import { getMeData } from '../../../services/meDataService';
import { getBrokerAffiliations } from '../../../services/brokerDataService';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from '../../../../env.config';

import ProspectsDashboardIcon from '../../../../assets/images/dashboard/ProspectsDashboardIcon';
import OffersDashboardIcon from '../../../../assets/images/dashboard/OffersDashboardIcon';
import ProflowDashboardIcon from '../../../../assets/images/dashboard/ProflowDashboardIcon';
import ClaimsDamageDashboardIcon from '../../../../assets/images/dashboard/ClaimsDamageDashboardIcon';
import FolderDashboardIcon from '../../../../assets/images/dashboard/FolderDashboardIcon';

const LinkBlock = ({
  icon,
  title,
  subTitle,
  disabled,
  goTo,
  demo,
  history,
  showTrial,
  showUpgrade,
  generateNewId,
  dataTestId,
  beta,
}) => {
  const { t } = useTranslation();
  const icons = {
    underwritingIcon: UnderwritingIcon,
    proflowIcon: ProflowDashboardIcon,
    claimsIcon: ClaimsDamageDashboardIcon,
    folderIcon: FolderDashboardIcon,
    customerLinks: CustomerLinks,
    offersAndLeadsIcon: ClaimsIcon,
    docsIcon: DocsIcon,
    toolkitIcon: ToolkitIcon,
    settingsIcon: SettingsIcon,
    portfolioIcon: ProspectsDashboardIcon,
    contracts: OffersDashboardIcon,
    webIcon: GlobeIcon,
  };

  // Productvergelijking: IconSavingFilled
  // Offertes en leads: Paper met checkmark icon (IconClaimsFilled)
  // Schadebeheer: IconAccidentFilled

  const UseImage = icons[icon];

  function getClaimsUrl() {
    switch (BASE_URL) {
      case 'https://api.phoenix.dev.qa.wegroup.be/':
        return 'https://claims.dev.qa.wegroup.be';
      case 'https://api.phoenix.dev.staging.wegroup.be/':
        return 'https://claims.dev.staging.wegroup.be';
      default:
        return 'https://claims.wegroup.be';
    }
  }

  const clickHandler = async e => {
    e.preventDefault();

    if (disabled) return;
    if (beta) {
      cogoToast.info(
        t(
          'At the moment we are finalising the development of this module please test it out we are looking forward to your feedback',
        ),
      );
    }
    if (goTo.includes('https://')) {
      if (getTrialAccount()) {
        showTrial(true);
      } else {
        getMeData().then(res => {
          if (getNestedObject(res, ['me', 'role']) === 'broker_bronze') {
            showUpgrade();
            return;
          }
          if (goTo === 'https://claims.wegroup.be') {
            goTo = getClaimsUrl();
          }
          window.location.href = goTo;
        });
      }
    } else {
      if (generateNewId) {
        const { items } = await getBrokerAffiliations();
        if (items?.length >= 1) {
          generateNewId().then(() => {
            history.push(goTo);
          });
        } else {
          cogoToast.error(
            t(
              'There are no companies linked to your account yet Feel free to adjust this in your settings',
            ),
          );
        }
      } else {
        history.push(goTo);
      }
    }
  };

  return (
    <BlockWrapper
      data-test-id={dataTestId}
      disabled={disabled}
      onClick={clickHandler}
    >
      {demo ? <Demo>Demo</Demo> : null}
      {beta ? <Demo>Beta</Demo> : null}
      <UseImage disabled={disabled} colors={getCurrentTheme()} />
      <TextWrapper>
        <Title disabled={disabled}>{title}</Title>
        <SubTitle disabled={disabled}>{subTitle}</SubTitle>
      </TextWrapper>
    </BlockWrapper>
  );
};

const Demo = styled.label`
  font-size: 1.2rem;
  color: white;
  background-color: ${({ theme }) => theme.brand.primary};
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  position: absolute;
  right: 5%;
  top: 12%;
`;

const Title = styled.h3`
  font-weight: 900;
  font-size: 2rem;
  color: ${props => (props.disabled ? `#76726E` : `black`)};
  margin-bottom: 0.4rem;
`;

const SubTitle = styled.h4`
  font-size: 1.6rem;
  color: ${props => (props.disabled ? `#76726E` : `#76726e`)};
  line-height: 1.1;
`;

const BlockWrapper = styled.div`
  width: 49%;
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  background: ${props => (props.disabled ? `#F1EFEF` : `white`)};
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 2.3rem;
  display: flex;
  padding: 2rem 3rem;
  align-items: center;
  transition: 0.3s;
  position: relative;
  border: 0.2px solid #ccc;

  & svg {
    box-shadow: ${props =>
      props.disabled
        ? `none`
        : `0 0 7px ${({ theme }) => theme.brand.primary}`};
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    flex-grow: 0;
    flex-shrink: 0;
  }

  :hover {
    box-shadow: ${props =>
      props.disabled ? `none` : `0 0 1.5rem rgba(0, 0, 0, 0.05)`};
  }

  :active {
    transform: scale(0.98);
  }

  @media screen and (max-width: 950px) {
    padding: 2rem;
  }

  @media screen and (max-width: 850px) {
    margin-bottom: 1.5rem;
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  padding-top: 0.6rem;
  margin-left: 2rem;
  width: 100%;
`;

LinkBlock.defaultProps = {
  disabled: false,
  goTo: '/',
  generateNewId: undefined,
};

LinkBlock.propTypes = {
  disabled: bool,
  icon: string.isRequired,
  title: string.isRequired,
  subTitle: string.isRequired,
  goTo: string,
  dataTestId: string,
  history: shape().isRequired,
  demo: bool,
  showTrial: func.isRequired,
  showUpgrade: func.isRequired,
  generateNewId: func,
  beta: bool,
};

export default withRouter(LinkBlock);
