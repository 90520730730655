import React from 'react';
import { shape, bool } from 'prop-types';

const ClaimsIcon = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };

  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30.4258"
        cy="30.5698"
        r="30"
        fill={colorsDict.secondaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.7164 20.6779H36.2208C36.0005 20.6779 35.8218 20.4983 35.8218 20.278V14.7824C35.8218 14.5622 35.6422 14.3826 35.422 14.3826H19.1352C18.9141 14.3826 18.7354 14.5622 18.7354 14.7824V46.3571C18.7354 46.5765 18.9141 46.757 19.1352 46.757H41.7164C41.9375 46.757 42.1162 46.5765 42.1162 46.3571V21.0777C42.1162 20.8566 41.9375 20.6779 41.7164 20.6779ZM36.6118 27.3323L31.2215 35.2944C30.988 35.6403 30.6058 35.8552 30.1891 35.8765C30.167 35.8774 30.1449 35.8782 30.1228 35.8782C29.73 35.8782 29.3567 35.704 29.1037 35.4005L25.7348 31.3585C25.266 30.7949 25.342 29.958 25.9047 29.4892C26.4682 29.0203 27.3042 29.0964 27.774 29.659L30.0131 32.3458L34.4134 25.8452C34.8248 25.2383 35.6502 25.0791 36.2562 25.4896C36.8631 25.901 37.0223 26.7255 36.6118 27.3323Z"
        fill={colorsDict.iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.8756 18.8784H38.0202C37.7991 18.8784 37.6204 18.6997 37.6204 18.4786V14.6232C37.6204 14.5338 37.7283 14.4896 37.7911 14.5524L41.9464 18.7077C42.0092 18.7714 41.9641 18.8784 41.8756 18.8784Z"
        fill={colorsDict.secondaryColor}
      />
    </svg>
  );
};

ClaimsIcon.defaultProps = {
  disabled: true,
};

ClaimsIcon.propTypes = {
  colors: shape().isRequired,
  disabled: bool,
};

export default ClaimsIcon;
