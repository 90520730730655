import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const OpenBuildingIcon = ({ colors }) => (
  <OpenBuilding
    width="63"
    height="62"
    viewBox="0 0 63 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="63"
      height="62"
    >
      <rect
        x="0.866211"
        y="0.114502"
        width="61.471"
        height="61.471"
        rx="30.7355"
        fill={colors.brand.primary}
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M10.9062 23.2062V20.1555C10.9062 19.7393 10.5687 19.4018 10.1526 19.4018H8.9867C8.57057 19.4018 8.23305 19.7393 8.23305 20.1555V21.2759C8.23305 21.6227 7.85007 21.833 7.55724 21.6473L3.55401 19.1036C3.2188 18.8909 2.79035 18.8901 2.45437 19.1021L-5.24926 23.963L-7.71054 25.5165C-8.2461 25.8548 -8.57129 26.4435 -8.57129 27.0777V41.3175C-8.57129 41.9509 -8.0573 42.4649 -7.42387 42.4649H13.3043C13.9378 42.4649 14.4517 41.9509 14.4517 41.3175V27.0692C14.4517 26.4397 14.1304 25.8532 13.6002 25.5142L11.3987 24.1071C11.092 23.9083 10.9062 23.57 10.9062 23.2062Z"
        fill={colors.brand.dark}
      />
      <path
        d="M70.9053 23.2062V20.1555C70.9053 19.7393 70.5677 19.4018 70.1516 19.4018H68.9857C68.5696 19.4018 68.2321 19.7393 68.2321 20.1555V21.2759C68.2321 21.6227 67.8491 21.833 67.5563 21.6473L63.553 19.1036C63.2178 18.8909 62.7894 18.8901 62.4534 19.1021L54.7498 23.963L52.2885 25.5165C51.7529 25.8548 51.4277 26.4435 51.4277 27.0777V41.3175C51.4277 41.9509 51.9417 42.4649 52.5751 42.4649H73.3034C73.9368 42.4649 74.4508 41.9509 74.4508 41.3175V27.0692C74.4508 26.4397 74.1294 25.8532 73.5993 25.5142L71.3977 24.1071C71.091 23.9083 70.9053 23.57 70.9053 23.2062Z"
        fill={colors.brand.dark}
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.9561 19.2668V22.3176C39.9561 22.6813 40.1418 23.0196 40.4485 23.2184L42.65 24.6255C43.1802 24.9646 43.5016 25.551 43.5016 26.1806V40.4288C43.5016 41.0623 42.9876 41.5763 42.3541 41.5763H34.6681V34.7209H29.3135V41.5763H21.6259C20.9925 41.5763 20.4785 41.0623 20.4785 40.4288V26.189C20.4785 25.5548 20.8037 24.9661 21.3393 24.6278L23.8005 23.0743L31.5042 18.2134C31.8402 18.0015 32.2686 18.0022 32.6038 18.2149L36.607 20.7587C36.8999 20.9444 37.2829 20.734 37.2829 20.3872V19.2668C37.2829 18.8507 37.6204 18.5131 38.0365 18.5131H39.2024C39.6185 18.5131 39.9561 18.8507 39.9561 19.2668Z"
      fill={'#fff'}
    />
  </OpenBuilding>
);

const OpenBuilding = styled.svg`
  overflow: hidden;
  border-radius: 50%;
`;

OpenBuildingIcon.propTypes = {
  colors: shape().isRequired,
};

export default OpenBuildingIcon;
