import React from 'react';
import { string } from 'prop-types';

const Informatics = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="19"
    fill="none"
    viewBox="0 0 32 19"
  >
    <path
      fill={color}
      d="M6.45 17.001V3.1c0-.123.106-.224.236-.224h18.628c.13 0 .236.1.236.224v13.902h3.03V.381A.39.39 0 0028.178 0H3.833a.39.39 0 00-.402.382v16.62H6.45z"
    />
    <path
      fill={color}
      d="M0 15.126v1.943C0 18.135.911 19 2.035 19h27.93C31.088 19 32 18.135 32 17.069v-1.943H0zm19.657 2.324a.975.975 0 01-.675.27h-5.964c-.521 0-.947-.404-.947-.898 0-.247.107-.472.284-.64a.974.974 0 01.675-.27h5.976c.52 0 .947.404.947.898a.896.896 0 01-.296.64z"
    />
  </svg>
);

Informatics.propTypes = {
  color: string.isRequired,
};

export default Informatics;
