// /* stylelint-disable selector-no-qualifying-type */
// /* stylelint-disable no-descending-specificity */

import React, { useState, useEffect } from 'react';
import { string as PropString, func } from 'prop-types';
import { Label, CheckBox } from 'wg-fe-ui';
import styled from 'styled-components';
// import accidentStatementType from '../services/FlowSearchSelectData';
import DateInput from './DateInput_v2';
import AccidentData from './AccidentData';
import { ActionButton } from 'wg-fe-ui';
import {
  parseIncDateToObject,
  isValidDate,
  parseObjectToNewDate,
} from '../services/dateService';
import { useTranslation } from 'react-i18next';
const ProFlowInputLicense = ({ title, onSubmit, name, dataTestId }) => {
  const [accidents, setAccidents] = useState([]);
  // const [statementType, setStatementType] = useState();
  const [accident, setAccident] = useState({ date: '', inFault: false });
  const [error, setError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    onSubmit({
      name,
      value: {
        accidents,
        statement_type: 'CLIENT_DECLARATION',
      },
    });
  }, [accidents]);

  function handleAddAccident() {
    const date = parseIncDateToObject(accident.date);

    if (!isValidDate(parseObjectToNewDate(date))) {
      return setError(true);
    }

    setAccidents([
      ...accidents,
      {
        date: date,
        at_fault: accident.inFault ? 'RESPONSIBLE' : 'VICTIM',
      },
    ]);
    setAccident({ date: '', inFault: false });
  }

  function deleteAccident(id) {
    setAccident(accidents.splice(id, 1));
  }

  function handleFault(e) {
    e.preventDefault();
    setAccident({ ...accident, inFault: !accident.inFault });
  }

  return (
    <AccidentsContainer>
      <TitleContainer>
        <Label>{title}</Label>
      </TitleContainer>
      <span>{t('Add the number of accidents in the last 5 years')}</span>
      <div>
        <span>{t('Date of the accident')}</span>
        <AccidentInputContainer>
          <StyledDateInput
            value={accident.date ? accident.date : ''}
            onChange={({ value }) => {
              setAccident({ ...accident, date: value });
              setError(false);
            }}
            data-test-id={`${dataTestId}_date`}
          />

          <Fault onClick={handleFault}>
            <p>{t('At fault')}</p>
            <CheckBox
              onClick={e => handleFault(e)}
              checked={accident.inFault}
              data-test-id={`${dataTestId}_fault`}
            />
          </Fault>
          <ActionButton
            onClick={handleAddAccident}
            data-test-id={`${dataTestId}_add`}
          >
            {t('Add')}
          </ActionButton>
        </AccidentInputContainer>
      </div>
      {/* <StyledSearchSelectInput
        onSelected={({ value }) => {
          setStatementType(value);
        }}
        value={'Choose an option'}
        options={accidentStatementType}
        placeholder="Choose an option"
      >
        {t('Type of declaration')}
      </StyledSearchSelectInput> */}
      {error && <Error>{t('Is not a valid date')}</Error>}
      <div>
        {accidents.map((_accident, i) => {
          return (
            <AccidentData
              key={i}
              itemId={i}
              accident={_accident}
              handleDeleteAccident={deleteAccident}
            />
          );
        })}
      </div>
    </AccidentsContainer>
  );
};

// const StyledSearchSelectInput = styled(SearchSelectInput)`
//   label {
//     color: #8990a3;
//   }
// `;

const StyledDateInput = styled(DateInput)`
  > label {
    margin-top: 0;
  }
`;

const Error = styled.div`
  color: red;
  font-size: 1.2rem;
  width: 100%;
  margin-top: -1rem;
`;

const Fault = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px silver solid;
  border-radius: 0 0.3rem 0.3rem 0;
  border-left: 0;
  padding-left: 1rem;
  color: #8990a3;
  height: 100%;
  margin-left: -0.2rem;
  margin-right: 2rem;
  > p {
    padding-right: 1rem;
  }
`;

const AccidentInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  height: 4rem;
`;

const TitleContainer = styled.div`
  display: flex;
  p {
    color: #8990a3;
    font-weight: 600;
    letter-spacing: 0.01em;
    font-size: 14px;
  }
`;

const AccidentsContainer = styled.div`
  display: grid;
  grid-template-rows: 2rem 3.5rem 8rem;
  grid-gap: 1rem;
  padding-bottom: 3rem;
  span {
    color: #8990a3;
    font-size: 14px;
  }
`;

ProFlowInputLicense.propTypes = {
  title: PropString,
  onSubmit: func.isRequired,
  name: PropString,
  dataTestId: PropString,
};

export default ProFlowInputLicense;

/* stylelint-enable selector-no-qualifying-type */
/* stylelint-enable no-descending-specificity */
