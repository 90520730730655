import React from 'react';
import { SearchSelectInput } from 'wg-fe-ui';
import { useDebouncedCallback } from 'use-debounce';
import { useTranslation } from 'react-i18next';

import { searchAddress } from '../services/apiRouterService';
import { node, func, string } from 'prop-types';
import styled from 'styled-components';

const AddressSearchSelector = ({ children, onSelected, error }) => {
  const { t } = useTranslation();
  const fetchResults = async (value, callback) => {
    if (hasNumber(value)) {
      const [data] = await searchAddress(value);
      return callback(cleanAddressData(data));
    }
  };

  const hasNumber = string => {
    return /\d/.test(string);
  };

  const cleanAddressData = payload => {
    const arr = [];
    if (payload?.code !== 404) {
      arr.push(
        payload?.map(r => {
          if (!('housenr' in r)) {
            return undefined;
          }

          const { housenr, city, street, boxnr, zipcode, lat, lng } = r;

          const cleanedStreetName = street
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
          const cleanedHouseNr = housenr
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
          const cleanedMunicipalityName = city
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
          const obj = {
            value: {
              streetName: cleanedStreetName,
              boxNumber: boxnr,
              streetNumber: cleanedHouseNr,
              postalCode: zipcode,
              municipalityName: cleanedMunicipalityName,
              latitude: lat,
              longitude: lng,
            },
            label: `${cleanedStreetName} ${cleanedHouseNr}${
              boxnr ? `/${boxnr}` : ``
            }, ${zipcode} ${cleanedMunicipalityName} `,
          };
          return housenr !== undefined ? obj : undefined;
        }),
      );
      return arr[0].filter(e => e !== undefined);
    } else {
      return arr;
    }
  };

  const [debounceFetchResults] = useDebouncedCallback(fetchResults, 500);

  return (
    <AddressContainer>
      <Label className="title">{children}</Label>
      <SearchSelectInput
        async
        error={error}
        onChange={onSelected}
        loadOptions={debounceFetchResults}
        onBlurResetsInput={false}
        // onBlur={() => handleFullAddressValidation()}
        onSelected={() => {}}
        isClearable
        noOptionsMessage={() => t(`AdresInput.input.placeholder`)}
        classNamePrefix="Select"
        placeholder={t(`AdresInput.input.placeholder`)}
        innerProps={{
          'data-test-id': 'underwriting_address_input',
        }}
      />
    </AddressContainer>
  );
};

const AddressContainer = styled.div``;

const Label = styled.label`
  color: #8990a3;
  font-weight: 600;
  letter-spacing: 0.01em;
  font-size: 14px;
`;

AddressSearchSelector.defaultProps = {
  children: null,
  onSelected: () => {},
};

AddressSearchSelector.propTypes = {
  children: node,
  onSelected: func,
  error: string,
};

export default AddressSearchSelector;
