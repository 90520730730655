import React from 'react';
import { string } from 'prop-types';

const Info = ({ color }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1566 0C5.44247 0 0 5.44247 0 12.1566C0 18.8708 5.44247 24.3133 12.1566 24.3133C18.8708 24.3133 24.3133 18.8708 24.3133 12.1566C24.3133 5.44247 18.8708 0 12.1566 0ZM12.1566 4.75694C13.0324 4.75694 13.7423 5.46678 13.7423 6.34259C13.7423 7.21839 13.0324 7.92824 12.1566 7.92824C11.2808 7.92824 10.571 7.21839 10.571 6.34259C10.571 5.46678 11.2808 4.75694 12.1566 4.75694ZM14.2708 19.0278H13.2137H11.0995H10.0424V17.9707H11.0995V11.0995H10.0424V10.0424H11.0995H13.2137V11.0995V17.9707H14.2708V19.0278Z"
        fill={color}
      />
    </svg>
  );
};

Info.propTypes = {
  color: string,
};

export default Info;
