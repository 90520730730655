import React, { useEffect, useState, useContext, useRef } from 'react';
import styled from 'styled-components';
import Table from './ProFlowOffersAccordionBoxTable';
import { func, string, shape, arrayOf, array } from 'prop-types';
import Header from './ProFlowOffersAccordionBoxHeader';
import {
  calculateQuoteByInsuranceType,
  exportToInsurerById_V2,
  getPyModelsObjectsFromQuoteStorage,
  getGuarantees,
  getInsurances,
} from '../services/apiRouterService';
import {
  Label,
  SearchSelectInput,
  AmountInput,
  LoadingSpinner,
  IconActionDropDown,
  ActionButton,
  IconDownloadFilled,
  Tooltip,
  TertiaryButton,
} from 'wg-fe-ui';
import queryString from 'query-string';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';
import { getInsuranceModuleName } from '../services/insuranceService';
import useForm from '../hooks/useForm';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import {
  getBrokerData,
  getBrokerDistribution,
} from '../services/brokerDataService';
import { isObjectEmpty } from '../services/objectService';
import { getPolicyPropositionLink } from '../services/docgenService';
import CircleSpinner from './CircleSpinner';
import _ from 'lodash';

const ProFlowOffersEditQuoteModal = ({
  id,
  insurance,
  insuranceCompany,
  insuranceType,
  options,
  quote,
  CRM,
  onClose,
  fullCurrentQuote,
}) => {
  const { t, i18n } = useTranslation();
  const { sessionId } = useParams();

  // The next state is used to always show all possible options
  const [quoteWithAllOptions, setQuoteWithAllOptions] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [premium, setPremium] = useState(fullCurrentQuote?.premium);
  const [currentQuote, setCurrentQuote] = useState(fullCurrentQuote);
  const [areQuotesLoading, setAreQuotesLoading] = useState(true);
  const [isQuoteLoading, setIsQuoteLoading] = useState(false);
  const [openPaymentSpread, setOpenPaymentSpread] = useState(false);
  const [calculationError, setCalculationError] = useState();
  const [carInsuranceModifications, setCarInsuranceModifications] = useState();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  // The following 2 states are used to check if API call should be made
  const [originalInsuredContentValue] = useState(
    Math.round(quote?.details?.insured_content_value?.value * 100) / 100,
  );
  const [originalInsuredValue] = useState(
    Math.round(quote?.details?.insured_value?.value * 100) / 100,
  );

  const canUpdateSelectedOptions = useRef(false);

  // HOME - Evaluation system ENUMS used in calculation
  const evaluationSystemTypes = [
    { value: 'LOCATION_SYSTEM', label: t('LOCATION_SYSTEM') },
    { value: 'FIRST_RISK', label: t('FIRST_RISK') },
    { value: 'X20_ANNUAL_RENT', label: t('X20_ANNUAL_RENT') },
    { value: 'OTHER', label: t('OTHER') },
  ];

  // CAR - Evaluation system ENUMS used in calculation
  const evaluationTypes = [
    { value: 'CATALOGUE', label: t('CATALOGUE') },
    { value: 'INVOICE', label: t('INVOICE') },
  ];

  const {
    selectedOffers,
    addSelectedOffers,
    removeSelectedOffers,
  } = useContext(ProFlowStorageContext);

  function receiveModifications(modifications) {
    setCarInsuranceModifications(modifications);
  }

  useEffect(() => {
    selectDefaultOptions(quote);
    setAreQuotesLoading(false);
  }, []);

  const { values, handleChange } = useForm({});

  function handleFieldChange(valObj = {}) {
    setHasChanged(true);
    handleChange(valObj);
  }

  useEffect(() => {
    if (currentQuote && currentQuote !== {}) {
      loadQuoteToParameters(currentQuote);
    }
  }, [currentQuote]);

  useEffect(() => {
    if (isObjectEmpty(selectedOptions)) return;
    if (canUpdateSelectedOptions.current) {
      handlePriceCalc(selectedOptions);
    } else {
      canUpdateSelectedOptions.current = true;
    }
  }, [selectedOptions]);

  // Loads initial values
  function handleQuoteChanges(quote) {
    const {
      payment_interval,
      franchise,
      vk,
      ck,
      bonus_malus,
      insured_value,
      insured_content_value,
      evaluation_type,
    } = quote?.details || {};

    handleChange({ name: 'vk', value: vk * 100 || 0 });
    handleChange({ name: 'ck', value: ck * 100 || 0 });
    handleChange({ name: 'bonusMalus', value: bonus_malus || 0 });
    handleChange({
      name: `paymentSpread`,
      value: payment_interval,
    });
    handleChange({
      name: `franchise`,
      value: {
        label: showFranchise(franchise?.type, franchise?.value),
        value: { type: franchise?.type, value: franchise?.value },
      },
    });

    handleChange({
      name: 'evaluationTypeBuilding',
      value: _.find(evaluationSystemTypes, {
        value: insured_value?.evaluation_type,
      }),
    });

    handleChange({
      name: 'evaluationTypeContentValue',
      value: _.find(evaluationSystemTypes, {
        value: insured_content_value?.evaluation_type,
      }),
    });

    handleChange({
      name: 'insuredValue',
      value: Math.round(insured_value?.value * 100) / 100 || null,
    });

    handleChange({
      name: 'insuredContentValue',
      value: Math.round(insured_content_value?.value * 100) / 100 || null,
    });

    handleChange({
      name: 'evaluationType',
      value: _.find(evaluationTypes, {
        value: evaluation_type,
      }),
    });
  }

  const resetValues = () => {
    handleFieldChange({
      name: 'insuredValue',
      value: originalInsuredValue,
    });

    handleFieldChange({
      name: 'insuredContentValue',
      value: originalInsuredContentValue,
    });
  };

  // Used when currentQuote was changed
  function loadQuoteToParameters(_currentQuote) {
    if (!_currentQuote) return;
    handleQuoteChanges(_currentQuote?.quote || {});
  }

  // The following function loads all the data without doing an API call which increases performance
  async function selectDefaultOptions(_quote) {
    let _selectedOptions = {};
    _quote?.options?.forEach(({ key }) => (_selectedOptions[key] = true));
    setQuoteWithAllOptions(_quote);
    handleQuoteChanges(_quote);
    setSelectedOptions(_selectedOptions);
  }

  async function calculateOfferPrice(_selectedOptions, quoteSpecifications) {
    setIsQuoteLoading(true);
    const optionsSet = new Set();
    Object.entries(_selectedOptions).forEach(([key, value]) => {
      if (!value) return;
      if (insurance.key === key) return;

      optionsSet.add(key);
    });
    const query = queryString.stringify({ option: [...optionsSet] });

    // Gets the product names
    const namedGuarantees = await computeGarantees(
      insuranceCompany.key,
      insuranceType.key,
    );

    const resp = await calculateQuote(query, quoteSpecifications);
    if (!_.isEmpty(resp)) {
      // If there was a response, map key and name to correct option
      setPremium(resp?.premium);
      resp.quote.base.key = resp?.quote?.base?.name;
      const namedBase = namedGuarantees?.filter(
        ({ key: _key }) => resp.quote.base.key === _key,
      )[0]?.name;
      resp.quote.base.name = namedBase;
      resp?.quote?.options.map(option => {
        const { name: key } = option;
        option.key = option.name;
        const namedOption = namedGuarantees?.filter(
          ({ key: _key }) => key === _key,
        )[0]?.name;
        option.name = namedOption;
        return option;
      });
      setCurrentQuote(resp);

      // Update base quote with new values
      const {
        net_premium,
        taxes,
        taxes_in_euro,
        commision,
        total_premium,
      } = resp?.quote?.base;

      quoteWithAllOptions.base = {
        ...quoteWithAllOptions?.base,
        net_premium,
        taxes,
        taxes_in_euro,
        commision,
        total_premium,
      };

      // Update details
      quoteWithAllOptions.details = resp?.quote?.details;

      // Update quote options with new values
      quoteWithAllOptions?.options.map(item => {
        const object = _.find(resp?.quote?.options, { key: item.key });

        if (object) {
          const {
            net_premium,
            taxes,
            taxes_in_euro,
            commision,
            total_premium,
          } = object;

          item.net_premium = net_premium;
          item.taxes = taxes;
          item.taxes_in_euro = taxes_in_euro;
          item.commision = commision;
          item.total_premium = total_premium;
        }

        return item;
      });
    }
    setIsQuoteLoading(false);
  }

  // Gets product names
  async function computeGarantees(insuranceCompanyKey, insuranceTypeKey) {
    const insuranceId = await returnInsuranceIdByType(
      insuranceCompanyKey,
      insuranceTypeKey,
    );
    const [resp, status] = await getGuarantees(
      insuranceCompanyKey,
      insuranceId,
    );

    if (status !== 200) return;

    return resp?.items?.map(({ guarantee_info }) => ({
      key: guarantee_info?.key || `No key found`,
      name: guarantee_info?.names?.[i18n.language] || `No Name found`,
    }));
  }

  async function returnInsuranceIdByType(companyKey, insuranceTypeKey) {
    const [resp, status] = await getInsurances(companyKey);

    if (status !== 200) return;

    const { items } = resp;
    const insuranceByType = items
      .filter(item => {
        if (
          item?.insurance.type === 'RESIDENCE' &&
          insuranceTypeKey === 'home'
        ) {
          return item?.insurance.type === 'RESIDENCE';
        } else {
          return item?.insurance.type === insuranceTypeKey.toUpperCase();
        }
      })
      .shift();
    const { id: insuranceId } = insuranceByType?.insurance;
    return insuranceId;
  }

  useEffect(() => {
    if (!isObjectEmpty(carInsuranceModifications)) calculateQuote();
  }, [carInsuranceModifications]);

  async function calculateQuote(query, quoteSpecifications = {}) {
    const baseSpecifications = {
      currency: 'EUR',
      payment_interval: 'annual',
      save_quote: true,
    };

    if (!isObjectEmpty(carInsuranceModifications)) {
      quoteSpecifications.modifications = carInsuranceModifications;
    }

    const [resp, status] = await calculateQuoteByInsuranceType({
      insuranceType: insuranceType.key,
      insuranceCompany: insuranceCompany.key,
      insurance: insurance.key,
      payload: {
        quote_specifications: { ...baseSpecifications, ...quoteSpecifications },
        id: sessionId,
      },
      query,
    });

    if (status !== 200) {
      setCalculationError(resp?.detail[i18n.language]);
      console.error('api failed');
      return {};
    } else {
      setCalculationError();
    }
    return await resp;
  }

  // Calculates offer price
  async function handlePriceCalc(_selectedOptions, _quoteSpecifications = {}) {
    const {
      ck,
      vk,
      bonusMalus,
      franchise,
      paymentSpread,
      evaluationTypeBuilding,
      insuredValue,
      insuredContentValue,
      evaluationType,
    } = values || {};

    const quoteSpecifications = {
      ..._quoteSpecifications,
      ...(ck !== undefined && { ck: ck / 100 }),
      ...(vk !== undefined && { vk: vk / 100 }),
      ...(bonusMalus !== undefined && { bonus_malus: bonusMalus }),
      ...(franchise?.value?.type && { franchise: franchise.value }),
      ...(paymentSpread && { payment_interval: paymentSpread }),
      ...(evaluationTypeBuilding && {
        evaluation_building: evaluationTypeBuilding.value,
      }),
      ...(evaluationType && { evaluation_type: evaluationType.value }),
      ...(insuredValue && { insured_value: insuredValue }),
      ...(insuredContentValue === originalInsuredContentValue &&
      insuredValue === originalInsuredValue
        ? {
            insured_content_value: null,
          }
        : {
            insured_content_value: insuredContentValue,
          }),
    };

    await calculateOfferPrice(_selectedOptions, quoteSpecifications);
  }

  async function exportToInsurer() {
    const { id: _id } = currentQuote;
    const [resp, status] = await exportToInsurerById_V2(_id);

    switch (status) {
      case 200:
        cogoToast.success(
          t(`Checkout.card.parameters.export.default.success`, {
            ref: resp?.external_ref,
            insuranceModuleName: getInsuranceModuleName(insuranceCompany.key),
          }),
        );
        break;
      case 404:
        if (resp.detail[i18n.language]) {
          cogoToast.error(resp.detail[i18n.language]);
        } else {
          cogoToast.error(
            t(
              `The configuration of your keys does not allow us to export it Please contact us for this`,
            ),
          );
        }
        break;
      default:
        cogoToast.error(
          t(
            `At the moment it is not possible to save this quote We are aware of this problem and will solve it soon`,
          ),
        );
        break;
    }
  }

  function computePercentage(decimalNumber) {
    if (decimalNumber) {
      return `Max ${(decimalNumber * 100).toFixed(2)}%`;
    }
    return '';
  }

  const showFranchise = (type, value) => {
    switch (type) {
      case 'fixed':
        return `€${value} ${t('Checkout.insured.franchiseTypes.fixed')}`;
      case 'variable':
        return `€${value} ${t('Checkout.insured.franchiseTypes.variable')}`;
      case 'percentage_on_compensation':
        return `${value * 100}% ${t(
          'Checkout.insured.franchiseTypes.percentage_on_compensation',
        )}`;
      case 'percentage_on_car_value':
        return `${value * 100}% ${t(
          'Checkout.insured.franchiseTypes.percentage_on_car_value',
        )} `;
      case 'english':
        return `€${value} ${t('Checkout.insured.franchiseTypes.english')}`;
      case 'identity':
        return `€${value} ${t('Checkout.insured.franchiseTypes.identity')}`;
      default:
        return ' -- ';
    }
  };

  function isSelectInputDisabled(_options) {
    if (!_options) return true;
    if (_options.length <= 1) return true;
    return false;
  }

  function shouldInsuranceModuleBeDisabled(insuranceModule, _riskObjectType) {
    if (getInsuranceModuleName(insuranceCompany.key)) {
      if (_riskObjectType === 'car') return false;
      if (_riskObjectType === 'family' && insuranceModule === 'allianz')
        return false;
      if (_riskObjectType === 'home') return false;
    }
    return true;
  }

  async function handleOfferDownload(e) {
    setIsDownloadLoading(true);
    if (e != null) {
      e?.preventDefault();
    }

    // Remove keys so that download works
    const currentQuoteCopy = _.cloneDeep(currentQuote);
    const optionsList = [];

    delete currentQuoteCopy?.quote?.base?.key;
    currentQuoteCopy.quote.options = currentQuoteCopy?.quote?.options.map(
      option => {
        optionsList.push(option.key);
        delete option.key;
        return option;
      },
    );

    const today = new Date();
    const { broker, theme } = await getBrokerData();
    const distribution = await getBrokerDistribution();
    const payload = {
      broker: broker,
      theme: theme,
      distribution,
      start_date: {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      },
      premium,
      quote: currentQuoteCopy?.quote,
      insurance: {
        ...currentQuoteCopy?.insurance,
        name: insurance.name,
        company: currentQuoteCopy?.insuranceCompany?.key,
        type: currentQuoteCopy?.insuranceType?.key,
        options: [...optionsList],
      },
      language: i18n.language.toUpperCase(),
    };
    downloadOffer(payload);
  }

  async function downloadOffer(payload) {
    const [resp, status] = await getPyModelsObjectsFromQuoteStorage(id);
    if (status !== 200) {
      cogoToast.error(t('Something went wrong while downloading your offer'));
      return;
    }
    const { policy_holder, risk_object } = resp;
    const [downloadLink, fileName] = await getPolicyPropositionLink({
      ...payload,
      client: policy_holder,
      risk_object,
    });
    if (downloadLink !== null && fileName !== null) {
      const downloadButton = document.createElement('a');
      downloadButton.href = downloadLink;
      downloadButton.download = fileName;
      downloadButton.click();
    } else {
      cogoToast.error(t('Something went wrong while downloading your offer'));
    }
    await setIsDownloadLoading(false);
  }

  function handleSaveOffers(e, _id, _currentQuote, offerPayload, _sessionId) {
    e.preventDefault();
    const { base: translatedBase, options: translatedOptions } =
      currentQuote?.quote || {};
    const currentQuoteOptions = _currentQuote?.quote?.options?.map(
      ({ key }) => key,
    );

    // If selectedOffers has an entry with the same id as the current quote remove it, else add to the selected offers
    if (selectedOffers.some(selectedOffer => selectedOffer.id === _id)) {
      removeSelectedOffers(_id, _sessionId);
    }

    addSelectedOffers(
      {
        id: _currentQuote?.id,
        insuranceCompany: offerPayload?.insuranceCompany,
        insuranceType: offerPayload?.insuranceType,
        insurance: offerPayload?.insurance,
        options: translatedOptions
          .filter(({ key }) => currentQuoteOptions.includes(key))
          .filter(({ key }) => key !== offerPayload?.insurance?.key)
          .map(({ key, name }) => ({ key, name })),
        premium: _currentQuote?.premium,
        quote: {
          base: {
            ..._currentQuote?.quote?.base,
            key: translatedBase?.key,
            name: translatedBase?.name,
          },
          details: _currentQuote?.quote?.details,
          options: translatedOptions.filter(({ key }) =>
            currentQuoteOptions.includes(key),
          ),
        },
        CRM: offerPayload?.CRM,
      },
      _sessionId,
    );
    onClose();
  }

  async function changeParams() {
    await handlePriceCalc(selectedOptions);
    setHasChanged(false);
  }

  if (areQuotesLoading) {
    return (
      <Container>
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      </Container>
    );
  }

  return (
    <Container>
      <StyledHeader
        logo={{
          src: insuranceCompany?.logo,
          alt: `${insuranceCompany?.name} logo`,
        }}
        insuranceName={insuranceCompany?.name}
        insuranceType={insurance?.name}
        totalPremium={premium?.total_premium?.toFixed(2)}
        interval={currentQuote?.quote?.details?.payment_interval?.toUpperCase()}
        isQuoteLoading={isQuoteLoading}
        calculationError={calculationError}
      />
      {currentQuote ? (
        <OpenAccordion>
          <GridLeft>
            <StyledTable>
              <Table
                sendModificationsUp={receiveModifications}
                insuranceCompany={insuranceCompany}
                premium={premium}
                quote={quoteWithAllOptions}
                currentQuote={currentQuote?.quote}
                canEdit
                insuranceKey={insurance?.key}
                onChange={({ key, value }) => {
                  setHasChanged(true);
                  setSelectedOptions({ ...selectedOptions, [key]: value });
                }}
                optionsState={selectedOptions}
                discounts={currentQuote?.quote?.details?.discounts || []}
                insuranceTypeKey={insuranceType?.key}
              />
            </StyledTable>
            <BottomGridContainer>
              <TopContainer>
                <StyledLabel className="title">{t(`Parameters`)} </StyledLabel>
                {(originalInsuredContentValue !== values?.insuredContentValue ||
                  originalInsuredValue !== values?.insuredValue) && (
                  <TertiaryButton
                    label={t(`Restore parameters`)}
                    onClick={resetValues}
                  />
                )}
              </TopContainer>
              <Inputs>
                {insuranceType?.key === 'car' && (
                  <>
                    <ValueInputContainer>
                      <LabelInputContainer>
                        <AmountInputLabel light>
                          {t('Bonus malus')}
                        </AmountInputLabel>
                        <StyledAmountInput
                          onChange={({ value }) =>
                            handleFieldChange({
                              value,
                              name: 'bonusMalus',
                            })
                          }
                          value={values?.bonusMalus}
                          name="bonusMalus"
                          max={22}
                          min={-2}
                          type="number"
                        />
                      </LabelInputContainer>
                    </ValueInputContainer>

                    <FranchiseContainer>
                      <InputLabel light>{t('Franchise')}</InputLabel>
                      <StyledSearchSelectInputFranchise
                        name="franchise"
                        options={currentQuote?.quote?.details?.franchises?.map(
                          ({ type, value }) => ({
                            label: showFranchise(type, value),
                            value: { type, value },
                          }),
                        )}
                        disabled={isSelectInputDisabled(
                          currentQuote?.quote?.details?.franchises,
                        )}
                        onSelected={val => {
                          handleFieldChange({
                            name: val?.name,
                            value: {
                              label: showFranchise(
                                val?.value?.type,
                                val?.value?.value,
                              ),
                              value: val?.value,
                            },
                          });
                        }}
                        inputAppend="%"
                        value={values?.franchise}
                        placeholder={t('Choose an option')}
                        menuPlacement={'top'}
                      />
                    </FranchiseContainer>

                    <FranchiseContainer>
                      <InputLabel light>{t('Evaluation method')}</InputLabel>
                      <StyledSearchSelectInputFranchise
                        name="evaluationType"
                        options={evaluationTypes}
                        onSelected={val => {
                          handleFieldChange({
                            name: 'evaluationType',
                            value: _.find(evaluationTypes, {
                              value: val.value,
                            }),
                          });
                        }}
                        value={values?.evaluationType}
                        placeholder={t('Choose an option')}
                        menuPlacement={'top'}
                      />
                    </FranchiseContainer>
                  </>
                )}
                {insuranceType?.key === 'home' && (
                  <>
                    {currentQuote?.quote?.details?.insured_value && (
                      <>
                        <FranchiseContainer>
                          <InputLabel light>
                            {t('Evaluation type building')}
                          </InputLabel>
                          <StyledSearchSelectInputFranchise
                            name="evaluationTypeBuilding"
                            options={evaluationSystemTypes}
                            onSelected={val => {
                              if (
                                val.value === 'LOCATION_SYSTEM' ||
                                val.value === 'X20_ANNUAL_RENT'
                              ) {
                                handleFieldChange({
                                  name: 'evaluationTypeContentValue',
                                  value: _.find(evaluationSystemTypes, {
                                    value: val.value,
                                  }),
                                });
                              }

                              handleFieldChange({
                                name: 'evaluationTypeBuilding',
                                value: _.find(evaluationSystemTypes, {
                                  value: val.value,
                                }),
                              });
                            }}
                            value={values?.evaluationTypeBuilding}
                            placeholder={t('Choose an option')}
                            menuPlacement={'top'}
                          />
                        </FranchiseContainer>
                        <ValueInputContainer>
                          <LabelInputContainer>
                            <AmountInputLabel light>
                              {t(`Checkout.insured.insured_value`)}
                            </AmountInputLabel>
                            <StyledAmountInput
                              value={
                                Math.round(values?.insuredValue * 100) / 100
                              }
                              onChange={({ value }) =>
                                handleFieldChange({
                                  value,
                                  name: 'insuredValue',
                                })
                              }
                              inputAppend="€"
                              name="insuredValue"
                              type="number"
                              max={2147000000}
                              min={0}
                              disabled={
                                values?.evaluationTypeBuilding.value ===
                                  'LOCATION_SYSTEM' ||
                                values?.evaluationTypeBuilding.value ===
                                  'X20_ANNUAL_RENT'
                              }
                            />
                          </LabelInputContainer>
                        </ValueInputContainer>
                      </>
                    )}
                    {currentQuote?.quote?.details?.insured_content_value && (
                      <>
                        <FranchiseContainer>
                          <InputLabel light>
                            {t('Evaluation type content value')}
                          </InputLabel>
                          <StyledSearchSelectInputFranchise
                            name="evaluationTypeContentValue"
                            options={evaluationSystemTypes}
                            onSelected={val => {
                              handleFieldChange({
                                name: 'evaluationTypeContentValue',
                                value: _.find(evaluationSystemTypes, {
                                  value: val.value,
                                }),
                              });
                            }}
                            value={values?.evaluationTypeContentValue}
                            placeholder={t('Choose an option')}
                            menuPlacement={'top'}
                            disabled
                          />
                        </FranchiseContainer>
                        <ValueInputContainer>
                          <LabelInputContainer>
                            <AmountInputLabel light>
                              {t(`Checkout.insured.insured_content_value`)}
                            </AmountInputLabel>
                            <StyledAmountInput
                              value={
                                Math.round(values?.insuredContentValue * 100) /
                                100
                              }
                              onChange={({ value }) => {
                                handleFieldChange({
                                  value,
                                  name: 'insuredContentValue',
                                });
                              }}
                              inputAppend="€"
                              name="insuredContentValue"
                              type="number"
                              max={2147000000}
                              min={0}
                            />
                          </LabelInputContainer>
                        </ValueInputContainer>
                      </>
                    )}
                  </>
                )}
                <FranchiseContainer>
                  <LabelInputContainer>
                    <AmountInputLabel className="withTooltip" light>
                      Ck
                      <StyledToolTip toggleIcon="?">
                        {i18n.t('Commission Rebate')}
                        <br />
                        {computePercentage(
                          currentQuote?.quote?.details?.max_ck,
                        )}
                      </StyledToolTip>
                    </AmountInputLabel>
                    <StyledAmountInput
                      disabled={currentQuote?.quote?.details?.max_ck === 0}
                      value={values?.ck}
                      onChange={({ value }) =>
                        handleFieldChange({ value, name: 'ck' })
                      }
                      name="ck"
                      inputAppend="%"
                      max={currentQuote?.quote?.details?.max_ck * 100}
                      min={0}
                      type="number"
                    />
                  </LabelInputContainer>
                </FranchiseContainer>
                {currentQuote?.quote?.details?.max_vk !== 0 && (
                  <FranchiseContainer>
                    <LabelInputContainer>
                      <AmountInputLabel className="withTooltip" light>
                        Vk
                        <StyledToolTip toggleIcon="?">
                          {i18n.t('Proxy Rebate')}
                          <br />
                          {computePercentage(
                            currentQuote?.quote?.details?.max_vk,
                          )}
                        </StyledToolTip>
                      </AmountInputLabel>
                      <StyledAmountInput
                        value={values?.vk}
                        onChange={({ value }) =>
                          handleFieldChange({ value, name: 'vk' })
                        }
                        name="vk"
                        inputAppend="%"
                        max={currentQuote?.quote?.details?.max_vk * 100}
                        min={0}
                        type="number"
                      />
                    </LabelInputContainer>
                  </FranchiseContainer>
                )}
              </Inputs>
            </BottomGridContainer>
          </GridLeft>
          <ActionContainer>
            <StyledLabel className="title">{t(`Actions`)}</StyledLabel>
            <ButtonContainer>
              <div>
                <DropDown
                  onClick={() => {
                    if (values?.paymentSpread != null)
                      setOpenPaymentSpread(!openPaymentSpread);
                  }}
                  disabled={values?.paymentSpread == null || isQuoteLoading}
                  name="paymentSpread"
                  level="white"
                  className="paymentSpread"
                  open={openPaymentSpread}
                  value={{
                    label: t(
                      `spread.${values?.paymentSpread
                        ?.toString()
                        .toUpperCase()}`,
                    ),
                    value: values?.paymentSpread,
                  }}
                >
                  {values?.paymentSpread != null
                    ? t(
                        `spread.${values?.paymentSpread
                          ?.toString()
                          .toUpperCase()}`,
                      )
                    : t('Choose an option')}
                  <span>
                    <IconActionDropDown />
                  </span>
                </DropDown>
                {openPaymentSpread && (
                  <ExportButtonsContainer>
                    {currentQuote?.quote?.details?.payment_intervals?.map(
                      (interval, index) => (
                        <DropDownButton
                          key={`${interval}${index}`}
                          name={interval}
                          onClick={() => {
                            handleFieldChange({
                              name: 'paymentSpread',
                              value: interval,
                            });
                            setOpenPaymentSpread(!openPaymentSpread);
                          }}
                        >
                          {t(`spread.${interval.toUpperCase()}`)}
                        </DropDownButton>
                      ),
                    )}
                  </ExportButtonsContainer>
                )}
              </div>
            </ButtonContainer>
            <ButtonContainer>
              <Button
                onClick={() => changeParams()}
                disabled={isQuoteLoading || !hasChanged}
              >
                {t('Save changes')}
              </Button>
              <Button
                disabled={
                  hasChanged || isQuoteLoading || values?.paymentSpread == null
                }
                onClick={e =>
                  handleSaveOffers(
                    e,
                    id,
                    currentQuote,
                    {
                      id,
                      insuranceCompany,
                      insuranceType,
                      insurance,
                      options,
                      premium,
                      quote,
                      CRM,
                    },
                    sessionId,
                  )
                }
              >
                {t('Add product')}
              </Button>
              <Button className="white" onClick={onClose}>
                {t('Cancel')}
              </Button>
            </ButtonContainer>
            <ButtonContainer>
              {/* <Button
                disabled={!CRM?.key}
                className="white"
                onClick={exportToCrm}
              >
                {CRM?.key
                  ? `${t(`Export to`)} ${CRM?.name}`
                  : t(`No crm found`)}
              </Button> */}
              <Button
                onClick={exportToInsurer}
                disabled={
                  shouldInsuranceModuleBeDisabled(
                    insuranceCompany.key,

                    insuranceType.key,
                  ) || isQuoteLoading
                }
              >
                {getInsuranceModuleName(insuranceCompany.key)
                  ? `${t(`Export to`)} ${getInsuranceModuleName(
                      insuranceCompany.key,
                    )}`
                  : t(`No connection available`, {
                      insurance: insuranceCompany.name,
                    })}{' '}
              </Button>
              <Button onClick={handleOfferDownload} disabled={isQuoteLoading}>
                {isDownloadLoading ? (
                  <>
                    <StyledCircleSpinner />
                    {t(`Downloading`)}
                  </>
                ) : (
                  <>
                    <IconDownloadFilled color="white" />
                    {t(`Download offer`)}
                  </>
                )}
              </Button>
            </ButtonContainer>
          </ActionContainer>
        </OpenAccordion>
      ) : null}
    </Container>
  );
};

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  height: 2rem;
`;

const StyledToolTip = styled(Tooltip)`
  div {
    width: fit-content;
    white-space: nowrap;
  }
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  height: 2.5rem;
  width: 2.5rem;
  border-width: 4px;
`;

const ExportButtonsContainer = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.15);
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 100%;
  margin-top: 0.4rem;
  width: 100%;
  :first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;

const DropDown = styled(ActionButton)`
  font-weight: bold;
  font-size: 1.3rem;
  margin: auto;
  position: relative;
  width: 100%;
  &.paymentSpread {
    color: ${({ disabled }) => (disabled ? '#AEAEAE' : '#222')};
    background-color: ${({ disabled }) => (disabled ? '#F4F4F4' : 'white')};
    &:hover {
      color: ${({ disabled }) => (disabled ? '#AEAEAE' : '#ff8000')} > span >
        svg > path {
        stroke: ${({ disabled }) => (disabled ? '#AEAEAE' : '#ff8000')};
      }
    }
  }
  &:hover {
    > span > svg > path {
      stroke: #ff8000;
    }
    > svg > path {
      fill: #ff8000;
    }
  }
  > span {
    transition-duration: 0.2s;
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
    position: absolute;
    right: 0.5rem;
    > svg {
      width: 2.5rem;
      height: 2.5rem;
      > path {
        stroke: ${({ disabled }) => (disabled ? '#AEAEAE' : '#222')};
      }
    }
  }
  > svg {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 0.5rem;
    > path {
      fill: #222;
    }
  }
`;

const DropDownButton = styled.button`
  border: none;
  background-color: ${({ disabled }) => (disabled ? '#F4F4F4' : '#FFFFFF')};
  color: ${({ disabled }) => (disabled ? '#AEAEAE' : '#8990A3')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 4rem;
  width: 100%;
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#F4F4F4' : '#FFEFDC')};
    color: ${({ disabled }) => (disabled ? '#AEAEAE' : '#FF8000;')};
  }
  :focus {
    border: 0;
  }
`;

const StyledHeader = styled(Header)`
  grid-template-columns: 15% 20% auto auto;
  > .price {
    font-size: 3rem;
    margin-right: 1rem;
    > span {
      font-size: 2.4rem;
      color: #626467;
    }
  }
`;

const StyledAmountInput = styled(AmountInput)`
  width: 50%;
  min-width: 5rem;
`;

const LabelInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputLabel = styled.label`
  color: #8990a3;
  align-self: center;
  font-size: 1.4rem;
  font-weight: 600;
  &.withTooltip {
    display: flex;
    align-items: center;
    height: 2rem;
    div {
      margin-left: 1rem;
    }
  }
`;

const AmountInputLabel = styled(InputLabel)`
  width: 50%;
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledTable = styled.div`
  overflow-y: auto;
  height: 100%;
  max-height: 43vh;
  > table {
    border-collapse: collapse;
    width: 100%;
    > thead th {
      position: sticky;
      top: 0;
      padding-bottom: 3rem;
      background: linear-gradient(
        180deg,
        white 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
    > tfoot th {
      position: sticky;
      bottom: 0;
      padding-top: 3rem;
      background: linear-gradient(0deg, white 50%, rgba(255, 255, 255, 0) 100%);
    }
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar:vertical {
    width: 11px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 11px;
  }
`;

const StyledLabel = styled(Label)`
  color: black;
  font-weight: bold;
  font-size: 1.6rem;
`;

const FranchiseContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f9f9fa;
  border-radius: 8px;
  padding: 0.75rem;
  padding-top: 1rem;
  margin-bottom: 2rem;
`;

const ValueInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f9f9fa;
  border-radius: 8px;
  padding: 0.75rem;
  padding-top: 1rem;
  margin-bottom: 2rem;
`;

const StyledSearchSelectInputFranchise = styled(SearchSelectInput)`
  font-weight: bold;
  height: unset;
  margin-top: 1rem;
  min-height: unset;

  label > div {
    margin-top: unset;
    margin-bottom: unset;
  }
  .Select__control {
    border-radius: 5px;
    border: none;
    position: relative;
  }
  .Select__indicator-separator {
    background-color: unset;
  }
  .Select__single-value {
    font-size: 1.3rem;
    margin-left: unset;
    margin-right: unset;
    width: 100%;
  }
  .Select__indicator {
    position: absolute;
    right: 0;
  }
  .Select__value-container {
    font-size: 1.4rem;
  }
`;

const Inputs = styled.div`
  column-gap: 1.4rem;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ disabled }) => (disabled ? 0.4 : '')};
  background: #ff8000;
  border-radius: 0.5rem;
  border-color: transparent;
  color: white;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin: auto;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 100%;
  width: 100%;
  height: 5rem;
  position: relative;

  &.white {
    background: white;
    color: black;
  }
`;

const ButtonContainer = styled.div`
  background: #f0f1f3;
  border-radius: 0.8rem;
  display: grid;
  padding: 1rem;
  height: fit-content;
  margin-bottom: 1rem;
  row-gap: 1rem;
  &.twoRows {
    grid-gap: 1rem;
    display: grid;
  }
`;

const BottomGridContainer = styled.div`
  border-top: 1px solid #f0f1f3;
  margin-top: 1rem;
  padding-top: 2rem;
  max-width: 60rem;
`;

const OpenAccordion = styled.div`
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 70% calc(30% - 3rem);
  margin-top: 3rem;
  min-height: 50rem;
`;

const Container = styled.div`
  overflow: auto;
  background-color: white;
  border-radius: 6px;
  height: 90%;
  bottom: 0;
  left: 0;
  margin: auto;
  padding: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 90%;
`;

const GridLeft = styled.div`
  display: grid;
  grid-template-rows: auto auto;
`;

ProFlowOffersEditQuoteModal.propTypes = {
  onClose: func.isRequired,
  id: string.isRequired,
  insurance: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceCompany: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceType: shape({ key: string.isRequired }),
  guarantees: arrayOf(
    shape({ name: string.isRequired, key: string.isRequired }),
  ),
  options: arrayOf(shape({ name: string.isRequired, key: string.isRequired })),
  language: string.isRequired,
  promotions: array,
  quote: shape(),
  CRM: shape({ name: string.isRequired, key: string.isRequired }),
  error: shape(),
  fullCurrentQuote: shape(),
};
export default ProFlowOffersEditQuoteModal;
