import React, { useState } from 'react';
import styled from 'styled-components';
import Arrow from '../../../../../assets/images/arrow';
import { string, number, func } from 'prop-types';
import { useTranslation } from 'react-i18next';

const CheckoutItemInformationRequirements = ({
  requirements,
  setRequirements,
  sortKey,
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleChange = e => {
    let val = e.target.value;
    setRequirements(val);
  };

  return (
    <Container>
      <CollapsibleTrigger onClick={() => setIsCollapsed(!isCollapsed)}>
        <H3>{t('Checkout.card.information_requirements.title')}</H3>
        <RotatingArrow
          dataTestId={`underwriting_discount_arrow_${sortKey}`}
          isCollapsed={isCollapsed}
          colors="#707070"
        />
      </CollapsibleTrigger>
      <WillCollapse isCollapsed={isCollapsed}>
        <InputContainer>
          <TextareaInput
            data-test-id={`underwriting_information_requirements_${sortKey}`}
            value={requirements}
            onChange={e => handleChange(e)}
            name="information_requirements"
            maxlength="650"
          />
        </InputContainer>
      </WillCollapse>
    </Container>
  );
};

const RotatingArrow = styled(Arrow)`
  transform: ${props => (props.isCollapsed ? 'rotate(180deg)' : '0')};
  cursor: pointer;
  padding: 1rem;
  height: 4rem;
  width: 5rem;
`;

const TextareaInput = styled.textarea`
  font-family: ${({ theme }) => theme.font};
  background-color: white;
  border: ${props =>
    props.errors && props.touched
      ? '0.1rem solid red'
      : props.touched
      ? '0.1rem solid #49C562'
      : 'none'};
  border-radius: 0.3rem;
  width: 100%;
  height: 20rem;
  padding: 1rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.brand.primary};

    & + span {
      color: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  opacity: 1;
  width: 100%;
  padding-left: 0.5rem;
  font-family: ${({ theme }) => theme.font};
`;

const WillCollapse = styled.div`
  visibility: ${props => (props.isCollapsed ? 'collapse' : 'visible')};
  height: ${props => (props.isCollapsed ? '0' : 'auto')};
  padding: ${props =>
    props.isCollapsed ? '0' : '1.5rem 3.7rem 2.5rem 3.7rem;'};
  display: flex;
  align-items: center;
  position: relative;
`;

const CollapsibleTrigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 3.7rem;
  cursor: pointer;
`;

const H3 = styled.h3`
  font-size: 1.6rem;
  font-weight: 700;
`;

const Container = styled.div`
  background-color: #e8e8e8;
`;

CheckoutItemInformationRequirements.defaultProps = {
  requirements: '',
};

CheckoutItemInformationRequirements.propTypes = {
  sortKey: number.isRequired,
  requirements: string.isRequired,
  setRequirements: func.isRequired,
};

export default CheckoutItemInformationRequirements;
