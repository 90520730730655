import { useEffect, useRef, useReducer } from 'react';
import { isEmpty } from 'lodash';
import { retrieveCampaignById } from '../services/apiRouterService';

export const useFetchedCampaignData = () => {
  const cache = useRef({});

  const initialState = {
    status: 'idle',
    error: null,
    data: [],
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  let cancelRequest = false;

  const fetchData = async campaignId => {
    if (!isEmpty(cache.current)) {
      dispatch({ type: 'FETCHED', payload: cache });
    } else {
      dispatch({ type: 'FETCHING' });
      if (!campaignId)
        return dispatch({
          type: 'FETCH_ERROR',
          payload: { error: 'no campaign id' },
        });

      const [resp, status] = await retrieveCampaignById(campaignId);
      if (status !== 200)
        return dispatch({ type: 'FETCH_ERROR', payload: resp });

      const payload = { ...resp };
      cache.current = payload;
      if (cancelRequest) return;
      dispatch({ type: 'FETCHED', payload: payload });
    }
  };

  useEffect(() => {
    return function cleanup() {
      cancelRequest = true;
    };
  }, []);

  return [state, fetchData];
};
