import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import DateInput from '../components/DateInput_v3';
import _ from 'lodash';
// import Frame from '../../assets/images/Frame.svg';

import {
  ActionButton,
  SearchSelectInput,
  ToggleInput,
  Tooltip,
  ModalWithSteps,
  TertiaryButton,
  Alert,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import useForm from '../hooks/useForm';
import { isEqual, isEmpty } from 'lodash';
import { formatISO, format, isPast, addDays, differenceInDays } from 'date-fns';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import { object, string as yupString } from 'yup';
import { string } from 'prop-types';
import {
  getBrokerDistribution,
  getBrokerCRMName,
} from '../services/brokerDataService';
import {
  getBrokersByDistributionId,
  // createOffer,
} from '../services/apiRouterService';
import RequiredInformation from './ProflowOffersModalRequiredInformation';
import TerminationLetter from './ProflowOffersModalTerminationLetter';
import CreateOffers from './ProflowOffersModalCreateOffer';
import { useHistory } from 'react-router';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
// import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';

const MAX_STEPS = 2;

const ProFlowOffersSelectedOffersSidebar = ({ prospectId }) => {
  const [generateModalOpen, setGenerateModalOpen] = useState(false);
  const [shouldApplyChanges, setShouldApplyChanges] = useState(false);
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [brokerCRMName, setBrokerCRMName] = useState();
  const history = useHistory();
  const {
    globalParameters,
    setGlobalParameters,
    offersParameters,
  } = useContext(ProFlowOffersContext);
  const { answers } = useContext(ProFlowStorageContext);
  const [currentStep, setCurrentStep] = useState(0);
  const [prospectStatus, setProspectStatus] = useState('loading');
  const [offersstatuses, setOffersstatuses] = useState([]);
  const { t } = useTranslation();

  const languages = [
    { label: 'Nederlands', value: 'NL' },
    { label: 'Français', value: 'FR' },
    { label: 'English', value: 'EN' },
  ];

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: object().shape({
      offerValidityFrom: yupString().test(
        'offer-Validity-From',
        t('Date should not be in the past'),
        value => !isPast(addDays(new Date(value), 1)),
      ),
      offerValidityUntil: yupString().test(
        'offer-Validity-until',
        t('There should be at least one day of difference between the dates'),
        function(value) {
          return (
            differenceInDays(
              new Date(value),
              new Date(this.parent.offerValidityFrom),
            ) > 0
          );
        },
      ),
    }),
  });

  useEffect(() => {
    (async () => {
      const _brokerCRMName = await getBrokerCRMName();
      setBrokerCRMName(_brokerCRMName);
    })();
  }, []);

  // compare values en globalParameters for switching from apply to generate offer
  useEffect(() => {
    setShouldApplyChanges(
      !isEqual(
        formatDatesToNonIso(values),
        formatDatesToNonIso(globalParameters),
      ),
    );
  }, [values, globalParameters]);

  // Convert date to dd/mm/yyyy so seconds are not considered in the comparison
  function formatDatesToNonIso({
    commencement,
    offerValidityFrom,
    offerValidityUntil,
    ...rest
  }) {
    return {
      commencement: handleDateValue(commencement),
      offerValidityFrom: handleDateValue(offerValidityFrom),
      offerValidityUntil: handleDateValue(offerValidityUntil),
      ...rest,
    };
  }

  useEffect(() => {
    (async () => {
      const { id: _id } = await getBrokerDistribution();
      const [resp, status] = await getBrokersByDistributionId(_id);
      if (status !== 200) return;

      const { items } = resp || {};
      setAssignedToOptions(
        items.map(item => ({
          label: item.broker.name,
          value: item.broker.id,
        })),
      );
    })();
  }, []);

  useEffect(() => {
    Object.entries(globalParameters).forEach(([key, value]) => {
      handleChange({ name: key, value: value });
    });
  }, [globalParameters]);

  function applyChanges(_values) {
    setGlobalParameters(_values);
  }

  function handleDateChange({ name, value }) {
    const [day, month, year] = value.split('/');
    const jsDate = new Date(year, month - 1, day);
    handleChange({ name, value: formatISO(jsDate) });
  }

  function handleDateValue(value) {
    if (new Date(value) !== 'Invalid Date' && !isNaN(new Date(value))) {
      return format(new Date(value), 'dd/MM/yyyy');
    }
  }

  function canProgress(_offersParameters) {
    const { requiredInformation, offers } = _offersParameters;

    if (isEmpty(requiredInformation)) return false;
    const numberOfInsurancesTypes = new Set(
      offers.map(({ insuranceType }) => insuranceType.key),
    );
    if (requiredInformation.length !== [...numberOfInsurancesTypes].length)
      return true;
    const arethereErrors = !requiredInformation
      .map(({ errors }) => errors)
      .every(errors => (errors === undefined ? false : isEmpty(errors)));
    return arethereErrors;
  }

  function canProgressTerminationLetter(_offersParameters) {
    const { terminationLetter } = _offersParameters;

    const arethereErrors = !terminationLetter
      .map(({ errors }) => errors)
      .every(errors => (errors === undefined ? false : isEmpty(errors)));
    return arethereErrors;
  }

  function hasEmail(_values) {
    const [emailField] = _values.filter(({ field }) => field === 'email') || [];
    return !!emailField;
  }

  function isModalNotClosable() {
    return (
      currentStep === MAX_STEPS &&
      (prospectStatus === 'loading' ||
        _.some(offersstatuses, item => item.status === 'loading'))
    );
  }

  const getLanguage = () => {
    const languageAnswerId = 'e1ff7109-0552-475f-b4bc-4b8ab3930d73';

    const answer = answers.find(answer => answer.id === languageAnswerId);

    // Will check if prospect language was set
    if (answer) {
      return languages.find(
        language => language.value === answer.answer?.toUpperCase(),
      );
    } else {
      return languages.find(
        language => language.value === values?.language?.toUpperCase(),
      );
    }
  };

  return (
    <Filters>
      <div>
        <StyledSideBar className="offerParams">
          {t('Offer parameters')}
          <StyledTooltip toggleIcon="i">
            {t(
              `The changes that are being set in Bulk Actions be applied to all offers You can still make changes to each individual offer by editing them and clicking on the 'Confirm changes to this offer' button`,
            )}
          </StyledTooltip>
        </StyledSideBar>
      </div>
      <div>
        <StyledSideBar>{t('Offer validity')}</StyledSideBar>
        <GroupDateInput>
          <StyledDateInput
            className="from"
            name="offerValidityFrom"
            onChange={handleDateChange}
            value={handleDateValue(values.offerValidityFrom)}
          >
            <span>{t('From')}:</span>
          </StyledDateInput>
          <StyledDateInput
            className="until"
            name="offerValidityUntil"
            onChange={handleDateChange}
            value={handleDateValue(values.offerValidityUntil)}
          >
            <span>{t('Until')}:</span>
          </StyledDateInput>
        </GroupDateInput>
        <Error>
          {errors.offerValidityFrom && (
            <StyledErrorAlert icon>{errors.offerValidityFrom}</StyledErrorAlert>
          )}
          {errors.offerValidityUntil && (
            <StyledErrorAlert icon>
              {errors.offerValidityUntil}
            </StyledErrorAlert>
          )}
        </Error>
      </div>
      <div>
        <StyledSideBar>{t('Commencement date')}</StyledSideBar>
        <StyledDateInput
          className="commencement"
          name="commencement"
          onChange={handleDateChange}
          value={handleDateValue(values.commencement)}
        >
          <span>{t('From')}:</span>
        </StyledDateInput>
      </div>
      <div>
        <StyledSideBar>{t('Assigned to')}</StyledSideBar>
        <StyledSearchSelectInput
          isMulti
          name="assigned_to"
          options={assignedToOptions}
          placeholder={t('Choose an option')}
          onSelected={({ value }) => {
            const option = value ? value : [];
            handleChange({ name: 'assigned_to', value: option });
          }}
        ></StyledSearchSelectInput>
      </div>
      <div>
        <StyledSideBar>{t('Set offer language')}</StyledSideBar>
        <StyledSearchSelectInput
          options={languages}
          onSelected={({ value }) => handleChange({ name: 'language', value })}
          placeholder={t('Choose an option')}
          name="language"
          initial={languages.find(
            language => language.value === i18n.language.toUpperCase(),
          )}
          value={getLanguage}
        ></StyledSearchSelectInput>
      </div>
      {hasEmail(answers) && (
        <div>
          <StyledSideBar>{t('Mail all offers to client')}</StyledSideBar>
          <StyledToggleInput
            falseLabel={t('No')}
            trueLabel={t('Yes')}
            name="mailToClient"
            onChange={({ checked }) =>
              handleChange({ name: 'mailToClient', value: checked })
            }
          />
        </div>
      )}
      {brokerCRMName && brokerCRMName !== 'None' && (
        <div>
          <StyledSideBar>
            {t('Export all offers to')} {brokerCRMName}
          </StyledSideBar>
          <StyledToggleInput
            falseLabel={t('No')}
            trueLabel={t('Yes')}
            onChange={({ checked }) =>
              handleChange({ name: 'exportToCRM', value: checked })
            }
          />
        </div>
      )}
      <StyledAlert icon>
        {t(
          `All offers created will automatically be exported as tarifications to the insurance modules`,
        )}
      </StyledAlert>
      <div>
        <StyledActionButton
          onClick={() => {
            shouldApplyChanges
              ? handleSubmit(applyChanges)
              : setGenerateModalOpen(true);
          }}
        >
          {shouldApplyChanges ? t('Apply changes') : t('Generate offers')}
        </StyledActionButton>
      </div>
      <StyledModalWithSteps
        canClose={currentStep !== MAX_STEPS}
        currentStep={currentStep}
        setShowModal={() => setGenerateModalOpen(false)}
        showModal={generateModalOpen}
        steps={[
          {
            label: `${t('Generate offers')} - ${t(
              'Required information sheets',
            )}`,
            step: 0,
          },
          {
            label: `${t('Generate offers')} - ${t('Termination letter')}`,
            step: 1,
          },
          {
            label: t('Creating offers'),
            step: 2,
          },
        ]}
        title=""
      >
        <ModalWithSteps.ModalContent step={0}>
          <RequiredInformation />
        </ModalWithSteps.ModalContent>
        <ModalWithSteps.ModalContent step={1}>
          <TerminationLetter />
        </ModalWithSteps.ModalContent>
        <ModalWithSteps.ModalContent step={2}>
          <CreateOffers
            prospectStatus={prospectStatus}
            setProspectStatus={setProspectStatus}
            offersstatuses={offersstatuses}
            setOffersstatuses={setOffersstatuses}
          />
        </ModalWithSteps.ModalContent>
        <StyledModalActions position="right">
          {currentStep === 1 && (
            <TertiaryButton
              onClick={() => setCurrentStep(step => step - 1)}
              label={t('Previous step')}
            ></TertiaryButton>
          )}
          {MAX_STEPS === currentStep ? (
            <>
              <TertiaryButton
                onClick={() =>
                  history.push('/professional-flow/choose-risk-and-insurance')
                }
                disabled={isModalNotClosable()}
                label={t('New tarification')}
              ></TertiaryButton>
              <ActionButton
                onClick={() => history.push('/dashboard')}
                disabled={isModalNotClosable()}
              >
                {t('Go to dashboard')}
              </ActionButton>
            </>
          ) : MAX_STEPS === currentStep + 1 ? (
            <ActionButton
              onClick={() => setCurrentStep(step => step + 1)}
              disabled={canProgressTerminationLetter(offersParameters)}
            >
              {t('Generate offers')}
            </ActionButton>
          ) : (
            <ActionButton
              disabled={canProgress(offersParameters)}
              onClick={() => setCurrentStep(step => step + 1)}
            >
              {t('Next step')}
            </ActionButton>
          )}
        </StyledModalActions>
      </StyledModalWithSteps>
    </Filters>
  );
};

const StyledErrorAlert = styled(Alert)`
  margin: 0 0 0.4rem 0;
  > * {
    color: #ff8000;
  }

  > *:first-child {
    border: 1.5px solid #ff8000;
  }
`;

const StyledActionButton = styled(ActionButton)`
  width: 100%;
`;

const StyledSearchSelectInput = styled(SearchSelectInput)`
  min-height: auto;
  margin-bottom: 2rem;
  > label > div {
    margin: 0;
  }
`;

const StyledModalWithSteps = styled(ModalWithSteps)`
  width: 60vw;
`;

const StyledModalActions = styled(ModalWithSteps.ModalActions)`
  margin-top: 0;
`;

const StyledTooltip = styled(Tooltip)`
  > div {
    width: 300px;
    pointer-events: none;
  }
`;

const StyledToggleInput = styled(ToggleInput)`
  padding-top: 0;
`;

const StyledDateInput = styled(DateInput)`
  height: fit-content;
  label > div {
    margin-top: 0;
    padding-left: 6rem;
  }
  span {
    bottom: 0;
    color: #8990a3;
    margin: 1.5rem 1rem;
    position: absolute;
    z-index: 10;
  }
  &.commencement {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
      0 0 1px rgba(0, 0, 0, 0.04);
    margin-bottom: 2rem;
  }
`;

const GroupDateInput = styled.div`
  margin-bottom: 2rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 0 1px rgba(0, 0, 0, 0.04);

  .from {
    > label > div {
      border-radius: 0.3rem 0.3rem 0 0;
      border-bottom: 0;
    }
  }
  .until {
    > label > div {
      border-radius: 0 0 0.3rem 0.3rem;
    }
  }
`;

const StyledSideBar = styled.div`
  margin-bottom: 1rem;
  font-size: 1.4rem;
  &.offerParams {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    border-bottom: 0.5px solid #d2d2d2;
    padding-bottom: 13px;
  }
`;

const Filters = styled.div`
  margin-top: 2rem;
  display: block;
  position: sticky;
  top: 3rem;
  height: fit-content;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 130%;
  color: #5b5550;
  > button {
    height: fit-content;
    margin-top: 2rem;
  }
`;

const Error = styled.p`
  color: red;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
`;

const StyledAlert = styled(Alert)`
  padding-bottom: 2rem;
`;

ProFlowOffersSelectedOffersSidebar.propTypes = {
  prospectId: string,
};

export default ProFlowOffersSelectedOffersSidebar;
