import React from 'react';
import { string, func, array, number } from 'prop-types';
import styled from 'styled-components';
import LoadingExtentionIcons from './LoadingExtentionIcons';
import { useTranslation } from 'react-i18next';

const CompareListItem = ({
  id,
  removeItem,
  insurerName,
  insuranceName,
  insurerLogo,
  packages,
  premium,
  sortKey,
  score,
  selectedPackages,
}) => {
  const { t } = useTranslation();
  return (
    <li>
      <Container>
        <RemoveCross
          data-test-id={`underwriting_compare_list_remove_${sortKey}`}
          onClick={() => removeItem(id)}
        >
          &#x2716;
        </RemoveCross>
        <PacketWrapper>
          <InsurerName>{insurerName}</InsurerName>
          <InsuranceName>{insuranceName.toLowerCase()}</InsuranceName>
        </PacketWrapper>
        <LogoContainer>
          <BoundingBoxLogo img={insurerLogo} />
        </LogoContainer>
        <Score>{score.toFixed(2)}%</Score>
        {premium ? (
          <YearlyPremium>
            <Premium>€{premium.toFixed(2)}</Premium>
            <Yearly>{t(`Checkout.aYear`)}</Yearly>
          </YearlyPremium>
        ) : (
          <YearlyPremium>
            <Nvt>{t(`Checkout.nvt`)}</Nvt>
          </YearlyPremium>
        )}
        <PackageWrapper>
          <PackageContainer>
            {selectedPackages.map(name => {
              const packageType = packages.filter(pack => pack.type === name)[0]
                .type;
              const packageLabel = packages.filter(
                pack => pack.type === name,
              )[0].name;
              return (
                <Package key={name}>
                  <LoadingExtentionIcons
                    type={packageType}
                    title={packageLabel}
                  />
                  <span>{packageLabel}</span>
                </Package>
              );
            })}
          </PackageContainer>
        </PackageWrapper>
      </Container>
    </li>
  );
};

const Nvt = styled.p`
  font-weight: 900;
  font-size: 1.6rem;
  color: #a29c95;
  line-height: 1.5;
`;

const Premium = styled.p`
  font-weight: 900;
  font-size: 1.6rem;
  color: #a29c95;
  line-height: 1.5;
`;

const Yearly = styled.p`
  font-size: 1.2rem;
  color: #a29c95;
  text-transform: uppercase;
`;

const YearlyPremium = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 8rem;
  margin: 0 3rem;
  text-align: center;
`;

const PackageWrapper = styled.li`
  overflow: auto;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const PackageContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;
const Package = styled.li`
  white-space: nowrap;
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  border-radius: 20rem;
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  border: none;
  transition: 0.2s;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.font};
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;

  &:focus {
    outline: none;
  }

  & span {
    margin-left: 0.7rem;
  }

  & svg {
    width: 2.6rem;
    height: 2.6rem;
    content: fit;
  }
`;

const Score = styled.li`
  font-weight: 900;
  font-size: 1.8rem;
  min-width: 8rem;
  text-align: center;
`;

const LogoContainer = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 12.8rem;
  height: 3.5rem;
  margin: 0 2.5rem;
`;

const BoundingBoxLogo = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
`;

const InsurerName = styled.p`
  color: #aeaeae;
  font-size: 1.3rem;
  line-height: 1.5;
`;

const InsuranceName = styled.p`
  text-transform: capitalize;
  font-size: 1.8rem;
  font-weight: 700;
  width: 12rem;
`;

const PacketWrapper = styled.li`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 12rem;
`;

const RemoveCross = styled.li`
  color: #d9d8dc;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.5rem;
  margin-right: 3rem;
`;

const Container = styled.ul`
  display: flex;
  align-items: center;
  padding: 1.4rem 0 1.4rem 4rem;
`;

CompareListItem.propTypes = {
  id: string.isRequired,
  removeItem: func.isRequired,
  insurerName: string.isRequired,
  insuranceName: string.isRequired,
  sortKey: number.isRequired,
  insurerLogo: string.isRequired,
  packages: array.isRequired,
  selectedPackages: array.isRequired,
  premium: number.isRequired,
  score: string.isRequired,
};

export default CompareListItem;
