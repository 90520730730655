/* stylelint-disable */
import React from 'react';
import styled from 'styled-components';
import { shape, string, node, bool, func } from 'prop-types';
import CheckmarkIcon from '../../../../../assets/images/lock/checkmark-lock';

const CheckMark = ({
  itemKey,
  children,
  Icon,
  changeExtra,
  checked,
  colors,
}) => (
  <CheckboxLabel data-test-id={`underwriting_extras_${itemKey}`}>
    <input
      onChange={e => changeExtra(e)}
      type="checkbox"
      name={itemKey}
      checked={checked}
    />
    <ImageContainer>
      <CheckedIcon>
        <CheckmarkIcon colors={colors} />
      </CheckedIcon>
      <Image>
        <Icon colors={colors} />
      </Image>
    </ImageContainer>
    <CheckboxText>{children}</CheckboxText>
  </CheckboxLabel>
);

CheckMark.propTypes = {
  itemKey: string.isRequired,
  children: node.isRequired,
  Icon: func.isRequired,
  changeExtra: func.isRequired,
  checked: bool.isRequired,
  colors: shape().isRequired,
};

const CheckedIcon = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
  border-radius: 50%;
  content: '';
  background-color: ${({ theme }) => theme.brand.primary};
  width: 2rem;
  height: 2rem;
  opacity: 0;
  transition: all 0.2s;
  overflow: show;

  & svg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0.3rem;
    bottom: 0.3rem;
  }
`;

const Image = styled.div`
  height: 10rem;
  width: 100%;
  max-height: 80%;
  margin: auto;
  object-fit: contain;
  transition: all 0.2s ease;
  align-items: center;
  vertical-align: middle;
  display: flex;
`;

const ImageContainer = styled.div`
  padding: 1.5rem;
  border: 5px solid ${({ theme }) => theme.brand.lighter};
  box-sizing: border-box;
  border-radius: 2rem;
  flex: 1 1 100%;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  position: relative;

  & svg {
    filter: opacity(80%);
    width: 100%;
    object-fit: contain;
    transition: all 0.2s ease;
    max-height: 90%;
    max-width: 90%;
    margin: auto;
  }

  &:hover {
    border-color: ${({ theme }) => theme.brand.dark};
    cursor: pointer;
  }

  &:hover svg {
    filter: opacity(100%);
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  flex-wrap: wrap;
  transition: all 0.2s;
  flex: 1 1 20%;
  margin: 1.5rem;

  &:active {
    transform: scale(0.95);
  }

  & input {
    display: none;
  }

  & input:checked + ${ImageContainer} {
    border-color: ${({ theme }) => theme.brand.dark};

    & ${CheckedIcon} {
      opacity: 1;
    }

    & ${Image} svg {
      filter: opacity(100%);
    }
  }
`;

const CheckboxText = styled.p`
  font-size: 2rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: 700;
  width: 100%;
  flex: 1 1 100%;
  height: 7.5rem;
  overflow: hidden;
`;

export default CheckMark;
