import ApiRoutes from '../../../../store/ApiRoutes';
import { getSubStep } from '../../../../store';

const api = new ApiRoutes();

let payload = {
  first_name: ``,
  last_name: ``,
  birth: ``,
  email: ``,
  telephonenr: null,
};

const dataById = {};
let gdprChecked = {};

const addDataForIdUser = id => {
  dataById[id] = JSON.parse(JSON.stringify(payload));
  gdprChecked[id] = false;
};

const answers = {};

const setgdprChecked = val => {
  gdprChecked[sessionStorage.getItem('id')] = val;
};

const getGdprChecked = () => gdprChecked[sessionStorage.getItem('id')];

const setPersonalData = val => {
  const id = sessionStorage.getItem('id');
  const valuesTemp = val;
  valuesTemp.birth = valuesTemp.birth.replace(/-/g, '/');
  Object.keys(valuesTemp).forEach(key => {
    let tempKey = key;
    switch (key) {
      case 'firstName':
        tempKey = 'first_name';
        break;
      case 'lastName':
        tempKey = 'last_name';
        break;
      case 'useremail':
        tempKey = 'email';
        break;
      case 'telephone':
        tempKey = 'telephonenr';
        break;
      case 'agree1':
        delete valuesTemp[key];
        break;
      case 'agree2':
        delete valuesTemp[key];
        break;
      default:
        break;
    }
    dataById[id][tempKey] = valuesTemp[key];
  });
};

const setAddressData = val => {
  const id = sessionStorage.getItem('id');
  dataById[id].billing_address = {
    street: val.streetName.toLowerCase(),
    housenr: val.streetNumber,
    boxnr: val.boxNumber ? val.boxNumber : null,
    zipcode: parseInt(val.postalCode),
    city: val.municipalityName.toLowerCase(),
    country_code: 'BE',
    lat: val.latitude,
    lng: val.longitude,
  };
};

const getAddressData = () =>
  dataById[sessionStorage.getItem('id')].billing_address;

const sendPersonalData = () =>
  api.postPersonalData(JSON.stringify(dataById[sessionStorage.getItem('id')]));

const sendEmail = value => api.checkEmail(JSON.stringify({ email: value }));

const getPersonalData = () => {
  const id = sessionStorage.getItem('id');

  return dataById[id];
};

const getAllAnswers = () => answers;

const addAnswer = answer => {
  answers[getSubStep()] = answer;
};

export {
  setgdprChecked,
  getGdprChecked,
  sendPersonalData,
  sendEmail,
  getPersonalData,
  setPersonalData,
  getAddressData,
  setAddressData,
  getAllAnswers,
  addAnswer,
  addDataForIdUser,
};
