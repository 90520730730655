import React, { useState } from 'react';
import styled from 'styled-components';
import { shape, array, func, number, bool, string } from 'prop-types';

import BigInsuranceCard from './BigInsuranceCard';
import Arrow from '../../../../../assets/images/arrow';

import { getPayloadCar } from '../../../Flow/Car/store/index';

const BigInsuranceCardCarousel = ({
  row,
  addToCompareItems,
  compareItems,
  id,
  selectedPacks,
  insuranceCompanies,
  isSelected,
  selectedItem,
}) => {
  const [activeItem, setActiveItem] = useState(
    isSelected && selectedItem ? parseInt(selectedItem) : 0,
  );

  const nextCard = () => {
    if (activeItem + 1 === row.length) {
      return;
    }
    setActiveItem(activeItem + 1);
  };

  const prevCard = () => {
    if (activeItem === 0) {
      return;
    }
    setActiveItem(activeItem - 1);
  };

  return (
    <Wrapper>
      <Row packs={row.length} activeItem={activeItem}>
        <ChevronLeft
          activeItem={activeItem}
          packs={row.length}
          show={row.length > 1 && activeItem !== 0}
          onClick={prevCard}
        >
          <Arrow isExtended />
        </ChevronLeft>
        {row.map((companyObject, i) => {
          const insuranceName = Object.keys(companyObject)[0];
          return (
            <CardWrapper active={activeItem === i} key={`${insuranceName + i}`}>
              <BigInsuranceCard
                name={companyObject[insuranceName][0].insurance_company.name}
                packs={companyObject[insuranceName]}
                activeItem={activeItem}
                currentPack={i}
                numberOfPacks={row.length}
                coverage="car"
                insuranceCompanies={insuranceCompanies}
                selectedPacks={selectedPacks}
                isSelected={isSelected}
                compare={res => addToCompareItems(res)}
                pdfs={companyObject[insuranceName][0].insurance_details}
                disableCompareItems={compareItems.length >= 5}
                dataType={getPayloadCar()}
                id={id}
              >
                {companyObject[insuranceName][0].insurance_details.type}
              </BigInsuranceCard>
            </CardWrapper>
          );
        })}
        <ChevronRight
          activeItem={activeItem}
          packs={row.length}
          show={row.length > 1 && activeItem !== row.length - 1}
          onClick={nextCard}
        >
          <Arrow isExtended />
        </ChevronRight>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 90rem;
  margin: 0 auto;
  overflow: none;
`;

const Row = styled.div`
  transition: 0.75s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: ${props => props.packs * 105}%;
  margin-left: -${props => props.activeItem * 105}%;
`;

const CardWrapper = styled.div`
  transition: 0.75s;
  display: flex;
  align-items: center;
  transform: ${props => (props.active ? `scale(1)` : `scale(0.9)`)};
  opacity: ${props => (props.active ? `1` : `0.6`)};
`;

const Chevron = styled.button`
  background: transparent;
  border: none;
  font-size: 4rem;
  color: white;
  font-weight: 700;
  cursor: pointer;
  height: 5rem;
  width: 5rem;
  padding: 0;
  margin: 0;
  line-height: 0;
  :focus {
    outline: none;
  }
`;

const ChevronLeft = styled(Chevron)`
  position: absolute;
  left: ${props => (100 / props.packs) * props.activeItem - 0.6}%;
  display: ${props => (props.show ? 'block' : `none`)};
  transform: rotate(-90deg);
  margin-left: -1rem;
  margin-top: -2rem;
`;
const ChevronRight = styled(Chevron)`
  position: absolute;
  left: ${props => (100 / props.packs) * (props.activeItem + 1) - 0.5}%;
  display: ${props => (props.show ? 'block' : `none`)};
  transform: rotate(90deg);
  margin-left: 1rem;
  margin-top: -2rem;
`;

BigInsuranceCardCarousel.defaultProps = {
  row: [],
  selectedPacks: undefined,
  isSelected: false,
  selectedItem: 0,
};

BigInsuranceCardCarousel.propTypes = {
  companyObject: shape().isRequired,
  row: array,
  selectedPacks: string,
  selectedItem: number,
  insuranceCompanies: shape(),
  isSelected: bool,
  addToCompareItems: func.isRequired,
  compareItems: array.isRequired,
  id: number.isRequired,
};

export default BigInsuranceCardCarousel;
