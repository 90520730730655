import React from 'react';
import { shape, bool } from 'prop-types';

const CustomerLinks = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };

  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30.1797"
        cy="30.2009"
        r="30"
        fill={colorsDict.secondaryColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0245 15.3638C15.7707 15.3638 15.5649 15.5695 15.5649 15.8233V30.0881C15.5649 30.3419 15.7707 30.5476 16.0245 30.5476H17.4629L17.4629 33.9636L21.2614 30.5476H37.8812C38.135 30.5476 38.3407 30.3419 38.3407 30.0881V15.8233C38.3407 15.5695 38.135 15.3638 37.8812 15.3638H16.0245Z"
        fill={colorsDict.iconColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.3342 32.0662C44.588 32.0662 44.7938 32.2719 44.7938 32.5257V41.0965C44.7938 41.3503 44.588 41.5561 44.3342 41.5561H42.8959V44.5928L39.5191 41.5561H28.1715C27.9177 41.5561 27.7119 41.3503 27.7119 41.0965V32.5257C27.7119 32.2719 27.9177 32.0662 28.1715 32.0662H44.3342Z"
        fill={colorsDict.iconColor}
      />
    </svg>
  );
};

CustomerLinks.defaultProps = {
  disabled: true,
};

CustomerLinks.propTypes = {
  colors: shape().isRequired,
  disabled: bool,
};

export default CustomerLinks;
