import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { node, bool, func } from 'prop-types';

const MODAL_ROOT = document.getElementById('modal');

/**
 * Component for a highly customizable modal.
 *
 * @component
 * @example
 * return (
 *   <Modal isOpen={true}>
 *    <ModalContainer>this is awesome</ModalContainer>
 *   </Modal>
 * )
 */

const Modal = ({ children, isOpen: isOpenProp, onClose }) => {
  const node = useRef();
  const [isOpen, setIsOpen] = useState(isOpenProp);

  const handleClickOutside = e => {
    if (node.current !== e.target) {
      // inside click
      return;
    }
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    if (!isOpen) {
      onClose();
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  if (isOpen) {
    return createPortal(
      <Background ref={node}>{children}</Background>,
      MODAL_ROOT,
    );
  }
  return null;
};

const Background = styled.div`
  z-index: 2000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
`;

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

Modal.propTypes = {
  children: node.isRequired,
  onClose: func,
  isOpen: bool,
};

export default Modal;
