import React, { useState } from 'react';
import styled from 'styled-components';
import { string, node, bool, shape } from 'prop-types';
// import PersonalData from '../store';
import { Field } from 'formik';

import checkmark from '../../assets/images/checkbox-checkmark.svg';

const CheckBox = ({
  type,
  name,
  children,
  placeholder,
  autofocus,
  errors,
  touched,
  checked,
  dataTestId,
}) => {
  const [isChecked, setChecked] = useState(checked);
  const [isTouched, setTouched] = useState(false);
  const handleChange = e => {
    setChecked(e.currentTarget.checked);
    setTouched(true);
  };
  return (
    <>
      <StyledLabel
        htmlFor={name}
        error={errors[name] && isTouched ? true : null}
        checked={isChecked}
        data-test-id={dataTestId}
      >
        <StandardInput
          id={name}
          type={type}
          name={name}
          touched={touched[name] ? touched[name] : null}
          autoFocus={autofocus}
          checked={isChecked}
          placeholder={placeholder === null ? '\u00A0' : null}
          onClick={e => handleChange(e)}
        />
        <CheckboxItem
          checked={isChecked}
          error={errors[name] && isTouched ? true : null}
        >
          {isChecked ? <img src={checkmark} alt="checkmark" /> : ''}
        </CheckboxItem>
        <span>{children}</span>
      </StyledLabel>
    </>
  );
};

const StyledLabel = styled.label`
  display: flex;
  position: relative;
  margin-top: 1rem;
  cursor: pointer;
  line-height: 1.3;
  width: 80%;

  & > span a {
    font-size: 1.4rem;
    transition: color 0.1s ease;
    color: ${props =>
      props.error ? '#F74040' : props.checked ? '#00CD39' : '#525252'};
  }

  & > span {
    font-size: 1.4rem;
    width: 90%;
    margin-left: 1rem;
    transition: color 0.1s ease;
    color: ${props =>
      props.error ? '#F74040' : props.checked ? '#00CD39' : '#525252'};

    & > :first-child {
      color: ${props =>
        props.error ? '#F74040' : props.checked ? '#00CD39' : 'black'};
    }
  }

  & + label {
    margin-top: 1.5rem;
  }
`;

const CheckboxItem = styled.div`
  width: 1.7rem;
  height: 1.7rem;
  margin-top: 0.3rem;
  background-color: white;
  transition: border 0.1s ease;
  border: ${props =>
    props.error
      ? '0.2rem solid #F74040'
      : props.checked
      ? '0.2rem solid #00CD39'
      : '0.2rem solid #a29c95'};
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StandardInput = styled(Field)`
  border-radius: 0.3rem;
  opacity: 0;
  margin-right: -1.6rem;
  &:focus + ${CheckboxItem} {
    outline: none;
    border-color: ${({ theme }) => theme.brand.lighter};
  }
`;

CheckBox.defaultProps = {
  placeholder: null,
  autofocus: false,
  errors: {},
  touched: {},
  checked: false,
  dataTestId: '',
};

CheckBox.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  children: node.isRequired,
  placeholder: string,
  autofocus: bool,
  errors: shape(),
  touched: shape(),
  checked: bool,
  dataTestId: string,
  // getInput: func.isRequired,
};

export default CheckBox;
