import React from 'react';
import { string } from 'prop-types';

const MOBILITY = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <title>{title}</title>
    <g id="icons">
      <circle cx="25" cy="14" r="4" fill={color} />
      <path
        d="M25,23a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
        fill={color}
      />
      <path
        d="M25,34a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-2a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
        fill={color}
      />
      <path
        d="M33.11,17A2,2,0,0,0,35,15.61l.6-1.88a2,2,0,0,1,.9-1.12l3.49-2a2,2,0,0,0,1-1.73V8.2a.2.2,0,0,0-.2-.2H33V7a1,1,0,0,0-1-1H18a1,1,0,0,0-1,1V8H9.2a.2.2,0,0,0-.2.2v.65a2,2,0,0,0,1,1.73l3.49,2a2,2,0,0,1,.9,1.12l.6,1.88A2,2,0,0,0,16.89,17H17v3H9.2a.2.2,0,0,0-.2.2v.65a2,2,0,0,0,1,1.73l3.49,2a2,2,0,0,1,.9,1.12l.6,1.88A2,2,0,0,0,16.89,29H17v3H9.2a.2.2,0,0,0-.2.2v.65a2,2,0,0,0,1,1.73l3.49,2a2,2,0,0,1,.9,1.12l.6,1.88A2,2,0,0,0,16.89,41H17v2a1,1,0,0,0,1,1H32a1,1,0,0,0,1-1V41h.11A2,2,0,0,0,35,39.61l.6-1.88a2,2,0,0,1,.9-1.12l3.49-2a2,2,0,0,0,1-1.73V32.2a.2.2,0,0,0-.2-.2H33V29h.11A2,2,0,0,0,35,27.61l.6-1.88a2,2,0,0,1,.9-1.12l3.49-2a2,2,0,0,0,1-1.73V20.2a.2.2,0,0,0-.2-.2H33V17ZM31,41.5a.5.5,0,0,1-.5.5h-11a.5.5,0,0,1-.5-.5V8.5a.5.5,0,0,1,.5-.5h11a.5.5,0,0,1,.5.5Z"
        fill={color}
      />
    </g>
  </svg>
);

MOBILITY.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

MOBILITY.propTypes = {
  title: string,
  color: string,
};

export default MOBILITY;
