import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SearchSelectInput } from 'wg-fe-ui';
import {
  retrieveAutomobileBrands,
  retrieveAutomobileModelsByBrand,
  retrieveAutomobileVersionsBySeries,
  retrieveAutomobileByCar,
} from '../services/apiRouterService';

import { capitalize } from '../services/stringService';
import { debounce } from 'lodash';
import { func, string } from 'prop-types';

const DEBOUNCE_CONFIG = {
  time: 500,
  options: {
    leading: true,
  },
};

const VehicleLookupComponent = ({ onChange, error, dataTestId }) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useState();
  const [model, setModel] = useState();
  const [fuel, setFuel] = useState();
  const [transmissionType, setTransmissionType] = useState();
  const [versions, setVersions] = useState([]);
  const [noResults, setNoResults] = useState(false);

  async function handleBrands(value) {
    const { time, options } = DEBOUNCE_CONFIG;

    const debounceBrands = debounce(retrieveAutomobileBrands, time, options);
    const [resp, status] = await debounceBrands(computeStringToQuery(value));

    if (status !== 200) return null;
    const { items } = resp;

    return items.map(({ brand }) => ({ label: brand.name, value: brand.key }));
  }

  function computeStringToQuery(value) {
    if (value) return `q=${value}`;
  }

  async function handleModels(value, _brand) {
    const { time, options } = DEBOUNCE_CONFIG;
    if (!_brand) return undefined;

    const debounceModels = debounce(
      retrieveAutomobileModelsByBrand,
      time,
      options,
    );
    const [resp, status] = await debounceModels(
      _brand,
      computeStringToQuery(value),
    );

    if (status !== 200) return null;
    const { items } = resp;

    return items.map(({ model }) => ({
      label: model.model,
      value: {
        brandKey: model.brand_key,
        id: model.id,
        keyCategory: model.key_category,
        model: model.model,
      },
    }));
  }

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    setModel();
    setFuel();
    setVersions([]);
    onChange();
  }, [brand]);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    setFuel();
    setVersions([]);
    onChange();
  }, [model]);

  useEffect(() => {
    setNoResults(false);
    setTransmissionType();
    onChange();
  }, [fuel]);

  useEffect(() => {
    if (fuel && transmissionType) {
      getVersions(brand, model, fuel);
    } else {
      setVersions([]);
    }
    onChange();
  }, [transmissionType]);

  async function getVersions(_brand, _model, _fuel) {
    const [resp, status] = await retrieveAutomobileVersionsBySeries(
      _brand,
      _model.id,
      `motor_type=${_fuel}&transmission_type=${transmissionType}&pagelen=500`,
    );

    if (status !== 200) return null;

    const { items } = resp;

    const computedVersions = items.map(({ version }) => ({
      id: version.id,
      kw: version.kw,
      version: version.version,
      brand: _brand,
      model: _model.model,
      modelYear: version.model_year,
    }));
    if (computedVersions.length === 0) {
      setNoResults(true);
    }
    setVersions(computedVersions.length > 0 ? computedVersions : null);
  }

  async function handleSelectedCar(_brand, modelId, brandId) {
    const [resp, status] = await retrieveAutomobileByCar(
      _brand,
      modelId,
      brandId,
    );

    if (status !== 200) return;

    const { car } = resp;
    if (model?.model != null) {
      car.model = model?.model;
    }
    onChange(car);
  }

  const fuelTypes = {
    PET: t('Vehicles.motor_types.PET'),
    DIE: t('Vehicles.motor_types.DIE'),
    LPG: t('Vehicles.motor_types.LPG'),
    DIE_LPG: t('Vehicles.motor_types.DIE_LPG'),
    ELE: t('Vehicles.motor_types.ELE'),
    ELE_PET: t('Vehicles.motor_types.ELE_PET'),
    ELE_DIE: t('Vehicles.motor_types.ELE_DIE'),
    ELE_LPG: t('Vehicles.motor_types.ELE_LPG'),
    ELE_HYDRO: t('Vehicles.motor_types.ELE_HYDRO'),
    HYDRO: t('Vehicles.motor_types.HYDRO'),
    CNG: t('Vehicles.motor_types.CNG'),
    CNG_PET: t('Vehicles.motor_types.CNG_PET'),
    BIO: t('Vehicles.motor_types.BIO'),
    OTHER: t('Vehicles.motor_types.OTHER'),
  };

  const transmissionTypes = [
    { value: 'auto', label: t('auto') },
    { value: 'man', label: t('man') },
  ];

  return (
    <>
      <Label>{t('Search based on car make and model')}</Label>
      <VehicleSearchContainer>
        <StyledSearchSelectInput
          async
          name="brand"
          error={error}
          placeholder={t('Choose your option')}
          loadOptions={handleBrands}
          onSelected={({ value }) => setBrand(value)}
          isClearable
          data-test-id={`${dataTestId}_brand`}
        >
          {t('Vehicles.Brand')}
        </StyledSearchSelectInput>
        <StyledSearchSelectInput
          async
          name="model"
          disabled={!brand}
          placeholder={t('Choose your option')}
          key={brand}
          loadOptions={val => handleModels(val, brand)}
          isClearable
          onSelected={({ value }) => setModel(value)}
          data-test-id={`${dataTestId}_model`}
        >
          {t('Vehicles.Model')}
        </StyledSearchSelectInput>
        <StyledSearchSelectInput
          name="motorType"
          isClearable
          disabled={!model}
          placeholder={t('Choose your option')}
          key={model?.id}
          options={Object.entries(fuelTypes).map(([key, value]) => ({
            label: value,
            value: key,
          }))}
          onSelected={({ value }) => {
            setFuel(value);
          }}
          data-test-id={`${dataTestId}_motor_type`}
        >
          {t('Vehicles.Fuel type')}
        </StyledSearchSelectInput>
        <StyledSearchSelectInput
          name="transmissionType"
          isClearable
          disabled={!fuel}
          placeholder={t('Choose your option')}
          key={`my_unique_select_key__${fuel}`}
          options={transmissionTypes}
          onSelected={({ value }) => {
            setTransmissionType(value);
          }}
          data-test-id={`${dataTestId}_transmission_type`}
        >
          {t('Vehicles.Transmission type')}
        </StyledSearchSelectInput>
        <VersionLabel>
          <span style={{ width: '70%' }}>{t('Version')}</span>
          <span style={{ width: '10%' }}>{t('Kw')}</span>
          <span style={{ width: '20%', textAlign: 'right' }}>
            {t('Model year')}
          </span>
        </VersionLabel>
        <VersionList
          disabled={versions?.length === 0 || versions === null}
          key={`${fuel} - ${model?.id}`}
        >
          {versions?.length !== 0 && versions !== null ? (
            versions.map(version => (
              <VersionItem
                key={version.id}
                onClick={() =>
                  handleSelectedCar(version.brand, model.id, version.id)
                }
                data-test-id={`${dataTestId}_version_option`}
              >
                <Version>{`${capitalize(version.brand)} - ${version.model} ${
                  version.version
                }`}</Version>
                <Kw>{version.kw}</Kw>
                <ModelYear>{version.modelYear}</ModelYear>
              </VersionItem>
            ))
          ) : noResults ? (
            <NoResults>{t('No results')}</NoResults>
          ) : (
            ''
          )}
        </VersionList>
      </VehicleSearchContainer>
    </>
  );
};

const NoResults = styled.div`
  font-size: 2.1rem;
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const VersionLabel = styled.div`
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 1.6;
  width: 100%;
  color: #8990a3;
`;

const Version = styled.span`
  width: 70%;
`;

const Kw = styled.span`
  width: 10%;
`;

const ModelYear = styled.span`
  width: 20%;
`;

const VersionItem = styled.li`
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: left;
  color: ${({ theme, isSelected }) => (isSelected ? 'white' : theme.typo.text)};
  width: 100%;

  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.brand.primary : 'none'};
  cursor: pointer;
  padding: 1rem 2rem;

  & > span:last-child {
    text-align: right;
  }

  & .version {
    width: 50%;
  }

  & .power {
    width: 22%;
  }

  & .year {
    width: 14%;
  }

  :hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
  }
`;

const VersionList = styled.ul`
  background-color: ${({ disabled }) => (disabled ? 'hsl(0,0%,95%)' : 'white')};
  border: 1px solid ${({ theme }) => theme.ui.outline};
  border-radius: 3px;
  height: 30rem;
  margin-bottom: 3rem;
  overflow-y: scroll;
  width: 100%;
  flex-direction: column;
`;

// const LoadingBox = styled.ul`
//   display: flex;
//   height: inherit;
//   justify-content: center;
// `;

const StyledSearchSelectInput = styled(SearchSelectInput)`
  color: #8990a3;
  font-weight: 600;
  grid-column: span 3;
  letter-spacing: 0.02em;
  width: 48%;
  label {
    color: #8990a3;
  }
  .Select__placeholder {
    font-weight: 100;
  }
`;

const VehicleSearchContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Label = styled.h3`
  color: #0e0e0e;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 1.4rem;
`;

VehicleLookupComponent.propTypes = {
  onChange: func.isRequired,
  error: string,
  dataTestId: string,
};
export default VehicleLookupComponent;
