import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const MiddleNoBath = ({ colors }) => {
  const styles = {
    main: {
      height: `100%`,
    },
    cls1: {
      fill: `${colors.brand.lightest}`,
    },
    cls2: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
    cls3: {
      fill: '#fff',
    },
    cls4: {
      fill: `${colors.brand.secondary}`,
    },
  };

  return (
    <SvgContainer>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 239.5 327"
        style={styles.main}
      >
        <g id="bath">
          <polygon
            style={styles.cls1}
            points="119.44 1 1 86 1 236 237.89 236 237.89 86 119.44 1"
          />
          <polyline
            style={styles.cls2}
            points="1.5 216.76 1.5 86.5 119.5 1.5 237.3 86 238 86.5 238 170.3 238 212.08"
          />
          <path
            style={styles.cls2}
            d="M738,214.76"
            transform="translate(-736.5 -98)"
          />
          <path
            style={styles.cls2}
            d="M738,403.5"
            transform="translate(-736.5 -98)"
          />
        </g>
        <g id="door">
          <polyline
            style={styles.cls1}
            points="1 159.8 1 156 237.89 156 237.89 325.25 1 325.25 1 159.8"
          />
          <line style={styles.cls2} x1="238" y1="325" x2="238" y2="218.5" />
          <line
            style={styles.cls2}
            x1="237.89"
            y1="199.76"
            x2="237.89"
            y2="176.5"
          />
          <line style={styles.cls2} x1="1.5" y1="188.5" x2="1.5" y2="325.5" />
          <polyline
            style={styles.cls3}
            points="87 325.32 87 216.77 150.25 216.77 150.25 325.32"
          />
          <polyline
            style={styles.cls4}
            points="91.12 325.32 91.12 216.77 155.81 216.77 155.81 325.32"
          />
          <line
            style={styles.cls2}
            x1="98.28"
            y1="270.84"
            x2="106.23"
            y2="270.84"
          />
          <polyline
            style={styles.cls2}
            points="87 323.58 87 216.77 150.25 216.77 150.25 324.45"
          />
        </g>
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  z-index: 3;
  height: 13.6rem;
  position: relative;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 14%;
  }
`;

MiddleNoBath.propTypes = {
  colors: shape().isRequired,
};
export default MiddleNoBath;
