import React from 'react';
import { ActionButton, Modal, SearchSelectInput, TextArea } from 'wg-fe-ui';
import styled from 'styled-components';
import { array, func, string as propString } from 'prop-types';
import { useTranslation } from 'react-i18next';
import useForm from '../hooks/useForm';
import DateInput from './DateInput_v2';
import * as Yup from 'yup';
import { string, date } from '../../constants/validationSchemas';

const CRMContractCancellationModal = ({
  modalState,
  terminateContract,
  type,
}) => {
  // const [validationSchema, setValidationSchema] = useState();
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    reason_for_termination: string.required,
    date_for_termination: date,
    reason: string.required,
  });

  const { errors, values, handleSubmit, handleChange } = useForm({
    validationSchema,
    change: true,
  });

  const [showModal, setShowModal] = modalState;

  const ReasonForTerminationOptions = [
    { label: t('REASON_FOR_TERMINATION.NO_MORE_RISK'), value: 'NO_MORE_RISK' },
    {
      label: t('REASON_FOR_TERMINATION.PREMIUM_INCREASE'),
      value: 'PREMIUM_INCREASE',
    },
    { label: t('REASON_FOR_TERMINATION.AFTER_CLAIM'), value: 'AFTER_CLAIM' },
    {
      label: t('REASON_FOR_TERMINATION.DEPARTURE_ABROAD'),
      value: 'DEPARTURE_ABROAD',
    },
    {
      label: t('REASON_FOR_TERMINATION.DISCONTINUATION_OF_ACTIVITIES'),
      value: 'DISCONTINUATION_OF_ACTIVITIES',
    },
    {
      label: t('REASON_FOR_TERMINATION.DEATH_OF_POLICYHOLDER'),
      value: 'DEATH_OF_POLICYHOLDER',
    },
    {
      label: t('REASON_FOR_TERMINATION.NON_PAYMENT_OF_PREMIUM'),
      value: 'NON_PAYMENT_OF_PREMIUM',
    },
    {
      label: t('REASON_FOR_TERMINATION.BANKRUPT_POLICYHOLDER'),
      value: 'BANKRUPT_POLICYHOLDER',
    },
    {
      label: t('REASON_FOR_TERMINATION.INCREASED_RISK'),
      value: 'INCREASED_RISK',
    },
    {
      label: t('REASON_FOR_TERMINATION.DEATH_OF_THE_INSURED'),
      value: 'DEATH_OF_THE_INSURED',
    },
    {
      label: t('REASON_FOR_TERMINATION.REDUCTION_OF_RISK'),
      value: 'REDUCTION_OF_RISK',
    },
    { label: t('REASON_FOR_TERMINATION.DOUBLE_USE'), value: 'DOUBLE_USE' },
    {
      label: t('REASON_FOR_TERMINATION.OBLIGATION_TO_OTHER_INSURER'),
      value: 'OBLIGATION_TO_OTHER_INSURER',
    },
    { label: t('REASON_FOR_TERMINATION.COMMUTATION'), value: 'COMMUTATION' },
    {
      label: t('REASON_FOR_TERMINATION.REPLACEMENT_WITH_OTHER_POLICY'),
      value: 'REPLACEMENT_WITH_OTHER_POLICY',
    },
    {
      label: t('REASON_FOR_TERMINATION.TERMINATION_BY_LEAD_INSURER'),
      value: 'TERMINATION_BY_LEAD_INSURER',
    },
    {
      label: t('REASON_FOR_TERMINATION.ACQUISITION_BY_OTHER_INSURER'),
      value: 'ACQUISITION_BY_OTHER_INSURER',
    },
    {
      label: t('REASON_FOR_TERMINATION.CONTRACTUALLY_STIPULATED_TERMINATION'),
      value: 'CONTRACTUALLY_STIPULATED_TERMINATION',
    },
  ];

  const handleChangeReasonForTermination = ({ name, value }) => {
    // setReasonForTermination({ label: t(value), value });
    handleChange({ name: name, value });
  };

  const onSubmit = async val => {
    setShowModal(!showModal);
    console.log('!!!!', val);
    terminateContract(values);
  };

  return (
    <Modal
      canClose
      modalElementClass="modal-full-width"
      showModal={showModal}
      setShowModal={setShowModal}
      title={t('Contract termination')}
      width={'100%'}
    >
      <Container>
        <SearchSelectInput
          error={errors.reason_for_termination}
          name="reason_for_termination"
          onSelected={handleChangeReasonForTermination}
          options={ReasonForTerminationOptions}
          placeholder={t('Choose an option')}
          // value={}
        >
          {t('Reason for termination')}
        </SearchSelectInput>
        <DateInput
          error={errors.date_for_termination}
          name="date_for_termination"
          onChange={handleChange}
          value={values.date_for_termination}
        >
          {t('Date for termination')}
        </DateInput>
        <StyledTextArea
          error={errors?.reason}
          name="reason"
          type="string"
          value={values.reason}
          onChange={handleChange}
        >
          {t('Reason for addendum')}
        </StyledTextArea>
        <Modal.ModalActions position="center">
          <ActionButton level="primary" onClick={() => handleSubmit(onSubmit)}>
            {t('Create termination')}
          </ActionButton>
        </Modal.ModalActions>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100000;
`;

const StyledTextArea = styled(TextArea)`
  margin-top: 20px;
`;

// const FlexWrapper = styled.div`;
//   margin-top: 2rem;
//   display: grid;
//   width: 100%;
//   grid-template-columns: repeat(2, 1fr);
//   grid-column-gap: 1rem;
// `;

CRMContractCancellationModal.propTypes = {
  modalState: array,
  terminateContract: func,
  type: propString,
};

export default CRMContractCancellationModal;
