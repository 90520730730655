import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import ChatBox from '../../../../components/ChatBox';
import {
  setNotice,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
  setCoverage,
  setSkippedSteps,
  getCurrentStep,
  showErrorPopup,
  getAllCoverages,
} from '../../../../store';
import { useTranslation } from 'react-i18next';
import { postLegalData, postFamData, setInsuredData } from '../store';
import {
  resetSkippedSteps,
  removeFromCheckout,
} from '../../../Marketplace/LayoutMarket/store';
import ApiRoutes from '../../../../store/ApiRoutes';
import {
  resetMatchData,
  getMatchData,
  setMatchData,
} from '../../../Marketplace/Market/store';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const InsuranceQuestionLegal = ({ history }) => {
  const [isPending, setIsPending] = useState(false);
  const location = useLocation();
  const [answer, setAnswer] = useState(null);
  const [finishedFetches, setFinishedFetches] = useState([]);
  const { t } = useTranslation();
  const answerA = t(`Yes`);
  const answerB = t(`No`);
  const api = new ApiRoutes();
  const coverages = getAllCoverages();
  const flowStore = useFlowStore();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    const trueCoverages = [];
    Object.keys(coverages).forEach(coverage => {
      switch (coverage) {
        case 'home':
        case 'family':
        case 'car':
        case 'legal':
          if (coverages[coverage]) trueCoverages.push(coverage);
          break;
        default:
      }
    });
    if (finishedFetches === trueCoverages.length) {
      api.postSession().then(() => {
        if (coverages.home) {
          history.push(getNextPath('b'));
        } else if (coverages.family) {
          history.push(getNextPath('c'));
        } else if (coverages.car) {
          history.push(getNextPath('d'));
        } else {
          history.push(getNextPath('e'));
        }
      });
    }
  }, [finishedFetches]);

  const handleAnswer = answer => {
    addChatboxQuestion(t('do you currently have a legal aid insurance?'));
    if (isPending) return;
    setIsPending(true);
    switch (answer) {
      case answerA:
        setAnswer(1);
        addChatboxAnswer(t('i currently have independent legal aid insurance'));
        history.push(getNextPath());
        // addAnswer(answer, this.content.key, true);
        break;
      case answerB:
        setAnswer(2);
        addChatboxAnswer(
          t('i currently do not have independent legal aid insurance'),
        );
        setNotice('legal', false, null);
        setInsuredData('already_insured_with', false);
        if (!coverages.family) {
          postFamData()
            .then(() => {
              handleSetData();
            })
            .catch(err => console.error(err));
        } else {
          handleSetData();
        }
        break;
      default:
    }
  };

  const handleSetData = () => {
    setInsuredData('wants_independent_legal_aid', true);
    postLegalData()
      .then(() => {
        resetSkippedSteps();
        removeFromCheckout('fire');
        removeFromCheckout('people');
        removeFromCheckout('car');
        removeFromCheckout('legal');
        setSkippedSteps(getCurrentStep(), false);
        setCoverage('legal', true);
        resetMatchData();

        Object.keys(coverages).forEach(coverageKey => {
          switch (coverageKey) {
            case 'home':
            case 'family':
            case 'car':
            case 'legal':
              if (coverages[coverageKey]) {
                const { type, campaign } = flowStore || {};
                if (type === 'campaign' && campaign?.extend === 'LEAD') {
                  history.push('/thank-you-campaigns');
                  return;
                }
                getMatchData(coverageKey)
                  .then(res => {
                    setMatchData(
                      coverageKey === 'home' ? 'fire' : coverageKey,
                      res.data,
                    );
                    setFinishedFetches(finishedFetches.push(coverageKey));
                  })
                  .catch(err => {
                    // showErrorPopup(true);
                    console.error(err);
                  });
              }
              break;
            default:
          }
        });
      })
      .catch(error => {
        showErrorPopup(true);
        console.error(error.response);
      });
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <QuestionBox
          to={null}
          Answer1={answerA}
          Answer2={answerB}
          dataTestId1="Yes"
          dataTestId2="No"
          shouldLoad={isPending ? answer : undefined}
          noLink={null}
          disabled={false}
          response={answer => handleAnswer(answer)}
        >
          {t('do you currently have a legal aid insurance?')}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

InsuranceQuestionLegal.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(InsuranceQuestionLegal);
