import { useState } from 'react';
/**
 * A hook to handle the state of a modalbox
 *
 * @param {boolean} initialMode the initial state of the modal
 * @returns {[boolean, func, func]} state of modal, state setter of the modal (boolean), toggle's the current modalstate
 */
export const useModal = (initialMode = false) => {
  const [isModalOpen, setIsModalOpen] = useState(initialMode);
  const toggle = () => setIsModalOpen(!isModalOpen);
  return [isModalOpen, setIsModalOpen, toggle];
};
