/* eslint-disable no-unused-expressions*/
/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import AsyncSelect from 'react-select/async';

import { func, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { setAddressData } from '../store';
import { useDebouncedCallback } from 'use-debounce';
import { getFullAddress, cleanAddressData } from '../../Woning/store/index';

const AddressInputSearchFullAddress = ({
  setDisabled,
  addressValues,
  disabled,
  errors,
  fullAddressError,
  clickedSubmit,
}) => {
  const [selected, setSelected] = useState(null);
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(fullAddressError);
  const { t } = useTranslation();

  const handleChange = _selected => {
    if (_selected) {
      setAddressData(_selected.value);
      setSelected(_selected);
      setIsDisabled(true);
      setDisabled();
      errors({});
      setError('');
      setSelected({});
      addressValues({
        postalCode: _selected.value.postalCode,
        municipalityName: _selected.value.municipalityName,
        streetName: _selected.value.streetName,
        streetNumber: _selected.value.streetNumber,
        boxNumber: _selected.value.boxNumber,
      });
    }
  };

  const fetchResults = (value, callback) => {
    if (hasNumber(value)) {
      //  useDebouncedCallback(value => {
      setLoading(true);
      return getFullAddress(value).then(res => {
        const data = res.data;
        callback(cleanAddressData(data));
      });

      // }, 1000);
    }
  };

  const hasNumber = myString => {
    return /\d/.test(myString);
  };

  const [debounceFetchResults] = useDebouncedCallback(fetchResults, 500);

  useEffect(() => {
    clickedSubmit ? handleFullAddressValidation() : null;
  }, [clickedSubmit]);

  const handleFullAddressValidation = () => {
    if (!selected) {
      errors({ fullAdrress: t(`AdresInput.errors.required`) });
      setError(t(`AdresInput.errors.required`));
    }
  };

  return (
    <div>
      {!isDisabled ? (
        <Label>
          {t(
            'Enter your street number and city Your box or apartment number can be filled in later',
          )}
        </Label>
      ) : null}
      <Input
        isDisabled={isDisabled}
        onChange={e => handleChange(e)}
        loadOptions={debounceFetchResults}
        onBlurResetsInput={false}
        onBlur={() => handleFullAddressValidation()}
        isClearable
        menuPlacement="top"
        loadingMessage={() =>
          loading
            ? t(`AdresInput.input.loading`)
            : t(`AdresInput.input.placeholder`)
        }
        noOptionsMessage={() => t(`AdresInput.input.placeholder`)}
        classNamePrefix="Select"
        value={selected}
        placeholder={t(`AdresInput.input.placeholder`)}
        innerProps={{
          'data-test-id': 'underwriting_address_input',
        }}
      />
      <Error>{error}</Error>
    </div>
  );
};

const Label = styled.p`
  line-height: 1.8rem;
  margin-top: 1rem;
  font-size: 1.4rem;
`;

const Error = styled.p`
  color: red;
  font-size: 1.1rem;
  width: 100%;
  margin-top: -2.8rem;
  text-align: right;
`;

const Input = styled(AsyncSelect)`
  width: 100%;
  height: 4rem;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
  justify-self: center;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__control {
      height: 100%;
      border-radius: 0.5em;
      border-color: ${({ theme, hasValue }) =>
        hasValue ? theme.brand.primary : `#ccc`};
      z-index: 999;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
    }

    &__menu {
      font-size: 1.8rem;
      margin-top: 5rem;
      z-index: 999;
      position: relative;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;
      line-height: 2.1rem;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

AddressInputSearchFullAddress.propTypes = {
  setDisabled: func,
  addressValues: func,
  disabled: bool,
  errors: func,
  fullAddressError: string,
  clickedSubmit: bool,
};

export default AddressInputSearchFullAddress;
