import React from 'react';
import { string } from 'prop-types';

const blind_spot_check = ({ className, color }) => (
  <svg viewBox="0 0 400 285.1" className={className}>
    <g fill={color}>
      <path d="m393.45 49.15h-18.38a6.57 6.57 0 0 0 -6.15 4.29l-17.4 47.08a36.72 36.72 0 0 1 -32 23.91l-8.18-78.36a51.31 51.31 0 0 0 -51.1-46.07h-103.56a151.68 151.68 0 0 0 -146.21 112.05l-8.8 32.68a47.92 47.92 0 0 0 46.27 60.37h244.47a70.68 70.68 0 0 1 66.76 47.44l9.83 28.16a6.55 6.55 0 0 0 6.19 4.4h18.28a6.56 6.56 0 0 0 6.55-6.56v-222.83a6.56 6.56 0 0 0 -6.57-6.56zm-345.51 123.18a15.15 15.15 0 0 1 -14.62-19.08l8.8-32.68a118.82 118.82 0 0 1 114.56-87.8h103.56a18.59 18.59 0 0 1 18.54 16.71l10.68 102.27a18.65 18.65 0 0 1 -18.54 20.58z" />
      <path d="m207.41 146a54.16 54.16 0 0 0 -60.74-1.22l-12.23-18.78a76.56 76.56 0 0 1 85.93 1.72z" />
      <path d="m236.4 109a101 101 0 0 0 -117.74-2l-12.79-18.4a123.43 123.43 0 0 1 143.91 2.41z" />
    </g>
  </svg>
);

blind_spot_check.propTypes = {
  className: string,
  color: string,
};

export default blind_spot_check;
