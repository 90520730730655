import React, {
  useEffect,
  useLayoutEffect,
  useContext,
  useState,
  createRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { shape } from 'prop-types';
import cogoToast from 'cogo-toast';
import {
  // AddEntityButton,
  ActionButton,
  BackButton,
  CheckBox,
  CommentBox,
  IconDocumentFilled,
  Label,
  LoadingSpinner,
  PhaseLabel,
  Text,
  Section,
  SubNavigation,
  SubTitle,
  TextArea,
  Alert,
  SubLabel,
} from 'wg-fe-ui';

import LeadConvertModal from '../../components/CRMLeadConvertModal';
import QuickActionSubMenu from '../../components/QuickActionSubmenu';
import { useTranslation } from 'react-i18next';
import CRMProspectClientData from '../../components/CRMProspectClientData';
import QuickActionSubMenuStatus from '../../components/QuickActionSubmenuStatus';
import ExportModal from '../../components/CRMExportModal';

import {
  getBrokerDistribution,
  getBrokerData,
  getBrokerCRMName,
} from '../../services/brokerDataService';
import {
  getLeadById,
  getNotesByLeadId,
  getBrokersByDistributionId,
  appendNoteToLead,
  exportLeadToCRM,
  deleteLeadNoteById,
  updateLeadById,
} from '../../services/apiRouterService';
import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';
import { formatISODate } from '../../services/dateService';
import CRMRiskObjectTabs from '../../components/CRMRiskObjectTabs';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';
import { getMeData } from '../../services/meDataService';

import * as Yup from 'yup';
import useForm from '../../hooks/useForm';

import Frame from '../../../assets/images/Frame.svg';
import { useLocation } from 'react-router';

const CRMLeadDetail = ({ match }) => {
  const location = useLocation();
  const configCatClient = useContext(FlagsProvider);
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const [isProFlowEnabled, setIsProfFlowEnabled] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { setNavTabs } = useContext(TopNavigationItems);
  const { t } = useTranslation();
  const history = useHistory();
  const refs = {};
  const [distribution, setDistribution] = useState();
  const [brokers, setBrokers] = useState([]);
  const [allBrokers, setAllBrokers] = useState([]);
  const [brokerCRMName, setBrokerCRMName] = useState();
  const [retrievedData, setRetrievedData] = useState();
  const [notes, setNotes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  //const [canEditFeatureFlag, setCanEditFeatureFlag] = useState(false);

  useEffect(() => {
    console.log('CRMLeadDetail -> retrievedData', retrievedData);
  }, [retrievedData]);

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  const [sections, setSections] = useState([
    {
      id: 1,
      label: 'Lead',
      subsections: [
        {
          id: 1,
          label: t('Prospect.Client information'),
        },
        {
          id: 2,
          label: t('Risk objects'),
        },
        {
          id: 3,
          label: t('Notes'),
        },
      ],
    },
  ]);

  const retrieveBrokersFromDistribution = async id => {
    const [resp] = await getBrokersByDistributionId(id);
    const idsToRemoveFrom = [
      '3471bb16-fe88-4e34-840e-b2d000ac1603',
      '0b6c634d-4f1d-4264-89ee-bbe8a06bfa24',
    ];
    const sortedAllBrokers = resp.items.sort((b1, b2) => {
      if (b1?.broker?.name > b2?.broker?.name) return 1;
      if (b1?.broker?.name < b2?.broker?.name) return -1;
      return 0;
    });
    setAllBrokers(sortedAllBrokers);
    const sortedBrokers = sortedAllBrokers.filter(
      item =>
        item.broker.plan !== 'CALLANT' &&
        !idsToRemoveFrom.some(obj => obj === item.broker.id),
    );
    setBrokers(sortedBrokers);
  };

  useEffect(() => {
    (async () => {
      const { me } = await getMeData();
      const { broker } = (await getBrokerData()) || {};
      setIsCallantFeature(
        await configCatClient.getValueAsync('callantFeature', true, {
          email: me?.email,
          custom: {
            plan: broker?.plan,
          },
        }),
      );
      setIsProfFlowEnabled(
        await configCatClient.getValueAsync('professionalFlow', false, {
          email: me?.email,
          custom: {
            plan: broker?.plan,
          },
        }),
      );
    })();
    getLead(match.params.leadId);
    const tmpItems = setTabItems(match.params.leadId, { test: 'test' }, t);
    setNavTabs(tmpItems);
    retrieveBrokerCRMName();
  }, []);

  async function retrieveBrokerCRMName() {
    const _brokerCRMName = await getBrokerCRMName();
    setBrokerCRMName(_brokerCRMName);
  }

  useEffect(() => {
    if (distribution !== undefined) {
      getNotes(distribution, match.params.leadId);
    }
  }, [distribution]);

  useEffect(() => {
    setSections([
      {
        id: 1,
        label: `Lead | ${retrievedData?.origin}`,
        subsections: [
          {
            id: 1,
            label: t('Prospect.Client information'),
          },
          {
            id: 2,
            label: t('Risk objects'),
          },
          {
            id: 3,
            label: t('Notes'),
          },
        ],
      },
    ]);
  }, [retrievedData]);

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const updateAssignedTo = async broker => {
    const tempData = JSON.parse(JSON.stringify(retrievedData));
    setRetrievedData(prevValue => ({
      ...prevValue,
      assigned_to_broker: {
        ...broker,
      },
    }));
    const [resp, respStatus] = await updateLeadById(
      distribution,
      match.params.leadId,
      { assigned_to: broker?.id },
    );
    if (respStatus === 200) {
      cogoToast.success(t('Offer was successfully assigned'));
    } else if (resp.msg != null || resp.detail != null) {
      setRetrievedData(tempData);
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, [handleScroll]);

  async function getLead(leadId) {
    const { id } = await getBrokerDistribution();

    setDistribution(id);

    let [response, status] = await getLeadById(id, leadId);

    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      setIsLoading(!isLoading);
      setRetrievedData(null);
      return;
    }

    retrieveBrokersFromDistribution(id);
    setRetrievedData(response);
    setIsLoading(!isLoading);
  }

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const updateStatus = async status => {
    const [resp, respStatus] = await updateLeadById(
      distribution,
      match.params.leadId,
      {
        status,
      },
    );
    if (respStatus === 200) {
      cogoToast.success(t('Lead status has been changed'));
      setRetrievedData(prevValue => ({
        ...prevValue,
        status,
      }));
    } else if (resp.msg != null || resp.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const exportClickHandler = async () => {
    setIsExporting(true);
    cogoToast.loading(t('Exporting'), {
      hideAfter: 7,
    });

    let [response, status] = await exportLeadToCRM(
      distribution,
      match.params.leadId,
    );

    if (status === 200) {
      setIsExporting(false);
      cogoToast.success(t('The lead has been exported successfully'));
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  async function getNotes(distribution, lead) {
    let [response, status] = await getNotesByLeadId(distribution, lead);
    setNotes(response.items);

    if (status !== 200) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error(response);
    }
  }

  const deleteNoteHandler = async noteId => {
    let [response, status] = await deleteLeadNoteById(
      distribution,
      match.params.leadId,
      noteId,
    );

    if (status === 200) {
      setNotes(notes.filter(object => object.note.id !== noteId));
      cogoToast.success(t('Note successfully deleted'));
    } else if (response.msg != null || response.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(response.msg)}</strong>
          <p>{t(response.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const [CommentSchema] = useState(
    Yup.object().shape({
      comment: Yup.string().nullable(),
    }),
  );

  const backButtonHandler = e => {
    e.preventDefault();
    history.push({
      pathname: match.params.prospectId
        ? `/sales-management/customer/${match.params.prospectId}/contracts`
        : `/sales-management/contracts/leads/${location?.state?.page || 1}`,
      state: {
        ...location.state,
      },
    });
  };

  const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
    validationSchema: CommentSchema,
    change: () => {},
  });

  function formSubmitHandler(e) {
    e.preventDefault();
    handleSubmit(handleFormValues);
  }

  const handleFormValues = async formValues => {
    const { broker } = await getBrokerData();

    const payload = {
      created_at: new Date(),
      private: formValues.private,
      author: broker.id,
      text: formValues.comment,
    };

    const [resp, status] = await appendNoteToLead(
      distribution,
      retrievedData?.id,
      payload,
    );
    resetForm();
    if (status >= 400) return;
    setNotes(notes => [
      {
        note: {
          id: resp?.id,
          created_at: new Date(),
          private: formValues.private,
          author: broker.id,
          text: formValues.comment,
        },
      },
      ...notes,
    ]);
    values.private = false;
  };

  const handlePrivateChange = () => {
    values.private = !values.private;
  };

  const handleConvertToOffer = () => {
    setShowModal(true);
  };

  const startNewTarrification = () => {
    history.push(
      `/professional-flow/new-tarrification-prospect/?prospectId=${retrievedData?.prospect?.id}`,
    );
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (retrievedData == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Lead not found')}</h1>
        <p>
          {`${t('We could not find a lead with id')}: `}{' '}
          <strong> {match.params.leadId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <LeadConvertModal
        modalState={[showModal, setShowModal]}
        data={retrievedData}
        revisionId={match.params.revision}
        distributionId={distribution}
      />

      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton onClick={backButtonHandler} name={t('Previous page')} />
          </TopBarLeft>

          {isCallantFeature &&
          (retrievedData?.risk_objects?.cars.length > 0 ||
            retrievedData?.risk_objects?.two_wheelers.length > 0) ? (
            <TopBarRight>
              <TopBarAction>
                <ActionButton onClick={handleConvertToOffer}>
                  {t('Convert to offer')}
                </ActionButton>
              </TopBarAction>
            </TopBarRight>
          ) : null}

          {!isCallantFeature ? (
            <TopBarRight>
              <TopBarAction>
                <ActionButton
                  onClick={startNewTarrification}
                  disabled={!isProFlowEnabled}
                >
                  {t('New tarification')}
                </ActionButton>
              </TopBarAction>
            </TopBarRight>
          ) : null}
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
          <ExportModal
            modalState={[showExportModal, setShowExportModal]}
            proceedExport={exportClickHandler}
            type="Lead"
            // data={data}
            // revisionId={match.params.revision}
            // distributionId={distributionId}
          />
          <ActionButton
            level="secondary"
            onClick={e => {
              retrievedData.amount_of_crm_exports > 0
                ? setShowExportModal(true)
                : exportClickHandler(e);
            }}
            disabled={brokerCRMName === null || isExporting}
          >
            {brokerCRMName
              ? `${t(`Export to`)} ${brokerCRMName}`
              : t(`No crm found`)}
          </ActionButton>
        </Sidebar>

        <Content>
          <section ref={refs[1][1]}>
            <ContractHeader>
              <section>
                <ContractHeaderItem>
                  <Label>Status</Label>
                  <QuickActionSubMenuStatus
                    status={retrievedData?.status}
                    label={t(`leads.${retrievedData?.status}`)}
                  >
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="PENDING">
                          {t('leads.PENDING')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('PENDING')}
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="PENDING">
                          {t('leads.IN_PROGRESS')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('IN_PROGRESS')}
                    />
                    <QuickActionSubMenuStatus.SubMenuItem
                      label={
                        <PhaseLabel status="REFUSED">
                          {t('leads.REFUSED')}
                        </PhaseLabel>
                      }
                      onClick={() => updateStatus('REFUSED')}
                    />
                  </QuickActionSubMenuStatus>
                  {/* <PhaseLabel status={retrievedData?.status}>
                    <SubTitle>{t(`leads.${retrievedData?.status}`)}</SubTitle>
                  </PhaseLabel> */}
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Created on')}</Label>
                  <SubTitle>
                    {formatISODate(retrievedData?.created_at)}
                  </SubTitle>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Created by')}</Label>
                  <Text>
                    {retrievedData?.created_by
                      ? allBrokers?.length > 0
                        ? allBrokers?.find(
                            item =>
                              item?.broker?.id === retrievedData?.created_by,
                          )?.broker?.name
                        : t('loading')
                      : t('Client')}
                  </Text>
                </ContractHeaderItem>

                <ContractHeaderItem>
                  <Label>{t('Assigned to')}</Label>
                  <QuickActionSubMenu
                    name={
                      retrievedData?.assigned_to
                        ? allBrokers?.length > 0
                          ? allBrokers?.find(
                              item =>
                                item?.broker?.id === retrievedData?.assigned_to,
                            )?.broker?.name
                          : t('loading')
                        : t('No one')
                    }
                  >
                    {brokers?.map(item => (
                      <QuickActionSubMenu.SubMenuItem
                        key={item.broker.id}
                        label={<SubLabel>{item?.broker?.name}</SubLabel>}
                        // TODO: Chane the hardcoded ID here to a mapping of all brokers in the distribution!
                        onClick={() => updateAssignedTo(item?.broker)}
                        type=""
                      />
                    ))}
                    <QuickActionSubMenu.SubMenuItem
                      label={<SubLabel>{t('No one')}</SubLabel>}
                      onClick={() => updateAssignedTo(null)}
                      type=""
                    />
                  </QuickActionSubMenu>
                  {/* <PhaseLabel status="offer-send">
                    <TextBold>{data.status ? t(data?.status) : `-`}</TextBold>
                  </PhaseLabel> */}
                </ContractHeaderItem>
                <ContractHeaderItem right>
                  <Label>{t('Origin')}</Label>
                  <SubTitle>
                    {retrievedData?.origin != null
                      ? retrievedData?.origin
                      : '-'}
                  </SubTitle>
                </ContractHeaderItem>
              </section>
            </ContractHeader>
            {retrievedData.description !== null ? (
              <StyledAlert icon type="normal">
                {retrievedData.description}
              </StyledAlert>
            ) : null}
            <CRMProspectClientData
              prospectData={retrievedData.prospect}
              distributionId={distribution}
              disableEdit
            />
          </section>

          <section ref={refs[1][2]}>
            <SubTitle>{t('Risk objects')}</SubTitle>
            <CRMRiskObjectTabs
              distributionId={distribution}
              leadId={match?.params?.leadId}
              prospectId={match?.params?.prospectId}
              riskObjects={retrievedData.amount_of_risk_objects}
              type="leads"
            />
            <SubTitleSpacer />
            <SubTitleSpacer />
          </section>

          {/* <Section>
            <Section.Title>Documents</Section.Title>

            <Section.Content background>
              <Documents>
                <DownloadBox
                  filename="Filename.ext"
                  href="#"
                  thumbnail="https://picsum.photos/100"
                />

                <DownloadBox
                  filename="Filename.ext"
                  href="#"
                  thumbnail="https://picsum.photos/105"
                />

                <DownloadBox
                  filename="Filename.ext"
                  href="#"
                  thumbnail="https://picsum.photos/110"
                />
              </Documents>
            </Section.Content>
          </Section> */}
          <section ref={refs[1][3]}>
            <Section>
              <Section.Title>{t('Notes')}</Section.Title>

              <Section.Content background>
                <form onSubmit={formSubmitHandler}>
                  <TextArea
                    maxLength={-1}
                    name="comment"
                    error={errors.comment}
                    onChange={val => handleChange(val)}
                    value={values.comment ? values.comment : ''}
                    data-test-id="quote_note_inputfield"
                  >
                    {t('Add a new note')}
                  </TextArea>

                  <NoteButtonWrapper>
                    <CheckBox
                      name="private"
                      onChange={handlePrivateChange}
                      checked={values.private}
                      data-test-id="quote_note_checkbox_private"
                    >
                      {t('Private note?')}
                    </CheckBox>

                    <ActionButton
                      type="submit"
                      value="Submit"
                      disabled={errors.comment ? true : false}
                      data-test-id="quote_note_btn_add"
                    >
                      {t('Add a new note')}
                    </ActionButton>
                  </NoteButtonWrapper>
                </form>

                {notes
                  .slice(0)
                  .reverse()
                  .map((noteObject, i) => {
                    const { note } = noteObject;
                    return (
                      <>
                        <Divider />
                        <div data-test-id={`quote_comment_${i}`}>
                          <CommentBox
                            icon={<IconDocumentFilled color="#CCCCCC" />}
                            id={note.id}
                            title={`${formatISODate(note.created_at)} - ${t(
                              `Added by`,
                            )} ${
                              note?.author
                                ? brokers?.length > 0
                                  ? brokers?.find(
                                      item => item?.broker?.id === note?.author,
                                    ).broker.name
                                  : t('loading')
                                : t('Client')
                            }`}
                            key={note.id}
                            deleteHandler={() => deleteNoteHandler(note.id)}
                          >
                            {note.text}
                          </CommentBox>
                        </div>
                      </>
                    );
                  })}
              </Section.Content>
            </Section>
          </section>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
  font-weight: bold;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    height: unset;
    line-height: 1.6rem;
    margin-right: 0.78vw;
    min-height: 4rem;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
`;

const SubTitleSpacer = styled.div`
  height: 20px;
`;

const NoteButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;

  > button {
    margin-left: 35px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.ui.outline};
  margin: 25px 0;
`;

const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 15px;

  section {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

// stylelint-disable value-keyword-case

const ContractHeaderItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${props => (props.right ? '0' : '3.5rem')};
  ${props => (props.right ? 'text-align: right;' : null)}
  ${props => (props.right ? 'margin-left: auto;' : null)}
`;

CRMLeadDetail.propTypes = {
  match: shape(),
};

export default CRMLeadDetail;
