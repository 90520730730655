import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';

const RightSide = props => {
  const { children, className } = props;

  return <Container className={className}>{children}</Container>;
};

const Container = styled.div`
  @media screen and (max-width: 650px) {
    width: 100%;
    border-radius: 3rem;
  }
  display: flex;
  width: 55%;
  height: 100%;
  flex-direction: column;
  justify-content: flex-start;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: #f0f1f3;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4rem 5rem 0;

  color: #525252;

  & p {
    max-width: 100%;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-top: 6rem;
  }
`;

RightSide.defaultProps = {
  className: '',
};

RightSide.propTypes = {
  className: string,
  children: node.isRequired,
};

export default RightSide;
