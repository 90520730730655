import React from 'react';

const Students = () => (
  <svg viewBox="0 0 248.67 144.25">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'studentsmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M132.58 79.17l75.13-38.52v76a4.44 4.44 0 01-4.44 4.45H45.45a4.44 4.44 0 01-4.45-4.42V40.89l74 38.24a19.17 19.17 0 008.84 2.15 19.39 19.39 0 008.74-2.11z"
        />
        <path
          className="prefix__cls-1"
          d="M234.76 9.8l13-6.67A1.66 1.66 0 00247 0H1.66A1.66 1.66 0 00.9 3.13l121 62.58a4.15 4.15 0 003.79 0l98.38-50.43-.28 94.57L211 141.61a1.93 1.93 0 001.79 2.64h4.4l5.37-7.22 2.63 7.22h20.51a1.92 1.92 0 001.78-2.64l-13-32.5z"
        />
      </g>
    </g>
  </svg>
);

export default Students;
