import { useEffect, useRef, useReducer } from 'react';
import {
  getBrokerData,
  getBrokerDistribution,
} from '../services/brokerDataService';
import { isEmpty } from 'lodash';
import { getMeData } from '../services/meDataService';

export const useFetchedUserData = () => {
  const cache = useRef({});

  const initialState = {
    status: 'idle',
    error: null,
    data: {},
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'FETCHING':
        return { ...initialState, status: 'fetching' };
      case 'FETCHED':
        return { ...initialState, status: 'fetched', data: action.payload };
      case 'FETCH_ERROR':
        return { ...initialState, status: 'error', error: action.payload };
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    let cancelRequest = false;

    const fetchData = async () => {
      if (!isEmpty(cache.current)) {
        dispatch({ type: 'FETCHED', payload: cache });
      } else {
        try {
          dispatch({ type: 'FETCHING' });
          const brokerData = await getBrokerData();
          const distributionData = await getBrokerDistribution();
          const meData = await getMeData();
          const payload = {
            me: meData,
            broker: brokerData,
            distribution: distributionData,
          };
          cache.current = payload;
          if (cancelRequest) return;
          dispatch({ type: 'FETCHED', payload: payload });
        } catch (error) {
          if (cancelRequest) return;
          dispatch({ type: 'FETCH_ERROR', payload: error.message });
        }
      }
    };

    fetchData();

    return function cleanup() {
      cancelRequest = true;
    };
  }, []);

  return state;
};
