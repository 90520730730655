/* stylelint-disable no-descending-specificity */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
// import CarQuestionBox from '../components/CarQuestionBox';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { setPayloadCar } from '../store';
import ChatBox from '../../../../components/ChatBox';
import {
  getComponentName,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

const MIN = 5000;
const MAX = 25000;
const STEP = 500;

const DistanceQuestion = ({ history }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(10000);
  const [text, setText] = useState();
  const [payload, setPayload] = useState();
  const location = useLocation();

  addChatboxQuestion(t(`${getComponentName()}.question`));

  // Set default value
  // value = 10000;

  let content = t(getComponentName(), {
    value: value,
    min: MIN,
    max: MAX,
    returnObjects: true,
  });

  const handleChange = e => {
    setValue(e.currentTarget.value);
  };

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    content = t(getComponentName(), {
      step: new Intl.NumberFormat(i18next.language).format(STEP),
      value: new Intl.NumberFormat(i18next.language).format(value),
      max: new Intl.NumberFormat(i18next.language).format(MAX),
      min: new Intl.NumberFormat(i18next.language).format(MIN),
      returnObjects: true,
    });

    switch (true) {
      case value <= MIN:
        setText(content.answers.low.title);
        break;
      case value < MAX:
        setText(content.answers.between.title);
        break;
      case value >= MAX:
        setText(content.answers.max.title);
        break;
      default:
    }
    setPayload(value);
  }, [value]);

  const handleClick = () => {
    setPayloadCar('km_per_year', payload);
    switch (true) {
      case value <= MIN:
        addChatboxAnswer(content.answers.low.response);
        break;
      case value < MAX:
        addChatboxAnswer(content.answers.between.response);
        break;
      case value >= MAX:
        addChatboxAnswer(content.answers.max.response);
        break;
      default:
    }
    history.push(getNextPath('a'));
  };

  return (
    <Layout>
      <Container>
        <StyledChatBox showQuestion={true} />
        <QuestionContainer>
          <Question>
            <p dangerouslySetInnerHTML={{ __html: content.question }} />
            <p>{text}</p>
          </Question>
          <SliderContainer>
            <div>
              <p>
                &lt; {new Intl.NumberFormat(i18next.language).format(MIN)} km
              </p>
              <p>
                &gt; {new Intl.NumberFormat(i18next.language).format(MAX)} km
              </p>
            </div>
            <CustomInput
              type="range"
              data-test-id="underwriting_car_distance_value"
              min={MIN}
              max={MAX}
              step={STEP}
              value={value}
              onChange={e => handleChange(e)}
            />
          </SliderContainer>
          <StyledButton
            data-test-id="underwriting_car_submit"
            onClick={() => handleClick()}
          >
            {t(`${getComponentName()}.confirm`)}
          </StyledButton>
        </QuestionContainer>
      </Container>
    </Layout>
  );
};

const SliderContainer = styled.div`
  width: 85%;
  margin: 3rem;
  margin-top: 1rem;

  & div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;

    & p {
      width: 33.3%;
      color: grey;

      &:nth-child(2) {
        text-align: center;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
`;

const StyledChatBox = styled(ChatBox)`
  height: calc(60% - 3rem);
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 80%;
  box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, 0.15);
  border-radius: 0 1.6rem 1.6rem;
  z-index: 10;
  position: relative;
  align-self: flex-end;
  margin: auto;
  margin-top: 0;
  margin-bottom: 3rem;
  flex-shrink: 0;
  background-color: white;
  padding-bottom: 4.5rem;
  height: 24rem;
`;

const Question = styled.div`
  display: flex;
  padding-bottom: 1rem;
  justify-content: space-between;
  width: 85%;
  margin: 2rem 3rem 0;

  & > p:first-child {
    flex: 1 1 100%;
  }

  & > p:nth-child(2) {
    font-weight: bold;
    margin-left: 2rem;
    flex-grow: 0;
    font-size: 1.6rem;
    flex-shrink: 0;
    color: ${({ theme }) => theme.brand.primary};
  }
`;

const CustomInput = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin-top: 1rem;
  width: 100%;
  height: 1.5rem;
  background: lightgrey;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10rem;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2.5rem;
    height: 2.5rem;
    background: ${({ theme }) => theme.brand.primary};
    cursor: pointer;
    border-radius: 10rem;
  }

  ::-moz-range-thumb {
    width: 2.5rem;
    height: 2.5rem;
    background: ${({ theme }) => theme.brand.primary};
    cursor: pointer;
    border-radius: 10rem;
  }
`;

const StyledButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  height: 5.5rem;
  font-size: 2rem;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.primary};
  color: white;
  border: solid 0.2rem;
  border-radius: 0 0 1.6rem 1.6rem;
  border-color: ${({ theme, disabled }) =>
    disabled ? theme.brand.lighter : theme.brand.secondary};
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? theme.brand.lighter : theme.brand.lighter};
    cursor: ${props => (props.disabled ? `default` : `pointer`)};
  }
  &:focus {
    outline: none;
  }
`;

DistanceQuestion.propTypes = {
  history: shape().isRequired,
};

export default withRouter(DistanceQuestion);
