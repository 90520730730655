/* eslint-disable no-unused-expressions */
/* stylelint-disable value-keyword-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Drawer from 'react-drag-drawer';
import { string, bool, func, object } from 'prop-types';
import MailIcon from '../../../../assets/images/dashboard/MailIcon';
import PhoneIcon from '../../../../assets/images/dashboard/PhoneIcon';
import { getCurrentTheme } from '../../../store';
import { useTranslation } from 'react-i18next';
import { getNestedObject } from '../../../services/objectService';

const BrokerInfoPopup = ({ className, show, onClose, data }) => {
  const { t } = useTranslation();
  const [togglen, setToggle] = useState(show);
  useEffect(() => {
    document.addEventListener('keydown', e => {
      if (e.key === 'Escape') {
        setToggle(false);
        onClose();
      }
    });
  }, []);
  const generateKBO = vat => {
    const first = vat.slice(0, 4);
    const second = vat.slice(4, 7);
    const third = vat.slice(7, 10);

    return `${first}.${second}.${third}`;
  };

  return togglen ? (
    <>
      <Drawer
        className={className}
        open={true}
        onRequestClose={() => {
          setToggle(!togglen), onClose();
        }}
      >
        <InfoContainer>
          <CloseButton
            onClick={() => {
              setToggle(!togglen), onClose();
            }}
          >
            X
          </CloseButton>
          <InfoBlock>
            <Head>
              <BrokerLogo
                src={getNestedObject(data, ['distribution', 'logo'])}
                alt="Broker Logo"
              />
              <div>
                <a
                  data-test-id="broker_history_detail_personal_email"
                  href={`mailto:${getNestedObject(data, [
                    'distribution',
                    'email',
                  ])}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MailIcon color={getCurrentTheme().brand.primary} />
                </a>
                <a
                  data-test-id="broker_history_detail_personal_phone"
                  href={`tel:${getNestedObject(data, ['broker', 'phone'])}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PhoneIcon color={getCurrentTheme().brand.primary} />
                </a>
              </div>
            </Head>
            <DataContainer>
              <InfoItem>
                <h3>{t('Company name')}</h3>
                <p>{getNestedObject(data, ['distribution', 'name'])}</p>
              </InfoItem>
              <InfoItem>
                <h3>{t('Website')}</h3>
                <a
                  href={getNestedObject(data, ['distribution', 'website'])}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getNestedObject(data, ['distribution', 'website'])}
                </a>
              </InfoItem>
              <InfoItem>
                <h3>{t('Address')}</h3>
                <p>{`${getNestedObject(data, [
                  'distribution',
                  'address',
                  'street',
                ])} ${getNestedObject(data, [
                  'distribution',
                  'address',
                  'housenr',
                ])}, ${getNestedObject(data, [
                  'distribution',
                  'address',
                  'zipcode',
                ])} ${getNestedObject(data, [
                  'distribution',
                  'address',
                  'city',
                ])}`}</p>
              </InfoItem>
              <InfoItem>
                <h3>{t('Email')}</h3>
                <a
                  href={`mailto:${getNestedObject(data, [
                    'distribution',
                    'email',
                  ])}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getNestedObject(data, ['distribution', 'email'])}
                </a>
              </InfoItem>
              <InfoItem>
                <h3>{t('Phone')}</h3>
                <a
                  href={`tel:${getNestedObject(data, ['broker', 'phone'])}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getNestedObject(data, ['broker', 'phone'])}
                </a>
              </InfoItem>
              <InfoItem>
                <h3>{t('FSMA')}</h3>
                <p>{getNestedObject(data, ['distribution', 'cbe'])}</p>
              </InfoItem>
              <InfoItem>
                <h3>{t('KBO')}</h3>
                <p>
                  {generateKBO(getNestedObject(data, ['distribution', 'cbe']))}
                </p>
              </InfoItem>
            </DataContainer>
          </InfoBlock>
        </InfoContainer>
      </Drawer>
    </>
  ) : null;
};

const BrokerLogo = styled.img`
  max-width: 20rem;
  max-height: 6rem;
  object-fit: contain;
`;

const InfoContainer = styled.div`
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 80rem;
  overflow: auto;
  z-index: 9999;
`;

const InfoBlock = styled.section`
  padding: 3rem;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font};
`;

const InfoItem = styled.div`
  width: 40%;
  margin-bottom: 1rem;

  & > a {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 1.5rem;
  }

  & > h3 {
    color: ${({ theme }) => theme.typo.interactive};
    font-size: 1.4rem;
  }

  & > p {
    font-size: 1.5rem;
  }
`;

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 0.1rem solid #d8d8d8;
  margin-bottom: 2rem;

  & > div {
    width: 40%;

    & > a {
      margin-right: 1.5rem;
    }
  }
`;

const DataContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: ${({ theme }) => theme.brand.lighter};
  font-weight: 900;
  position: absolute;
  right: 0;
  padding: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.brand.primary};
  }
`;

BrokerInfoPopup.defaultProps = {
  data: {},
  onClose: () => {},
  show: false,
};

BrokerInfoPopup.propTypes = {
  data: object,
  show: bool,
  className: string,
  onClose: func,
};

export default BrokerInfoPopup;
