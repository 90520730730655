import React from 'react';
import styled from 'styled-components';
import { func, shape } from 'prop-types';
import { TextInput, MaskedInput } from 'wg-fe-ui';
import DateInput from './DateInput_v3';

import { useTranslation } from 'react-i18next';
import PhoneInput from './PhoneInputUseHook';
import FlowAddressContainer from './AddressInputContainer';

const CRMCustomerOverviewNewProspectModalCustomer = ({
  errors,
  values,
  handleChange,
}) => {
  const { t } = useTranslation();

  return (
    <Form>
      <FlexWrapper>
        <TextInput
          name="firstName"
          error={errors?.firstName}
          onChange={val => handleChange(val)}
          value={values?.firstName}
          data-test-id="new_prospect_first_name"
        >
          <NameInput>{t('First name')} *</NameInput>
        </TextInput>
        <TextInput
          name="lastName"
          error={errors?.lastName}
          onChange={val => handleChange(val)}
          value={values?.lastName}
          data-test-id="new_prospect_last_name"
        >
          <NameInput>{t('Last name')} *</NameInput>
        </TextInput>
        <DateInput
          error={errors.birth}
          name="birth"
          onChange={handleChange}
          value={values.birth}
          data-test-id="new_prospect_birth"
        >
          {t('Birthdate')}
        </DateInput>
        <MaskedInput
          error={errors.national_register_nr}
          mask="99.99.99-999.99"
          name="national_register_nr"
          onChange={e => {
            if (e.value === '  .  .  -   .  ' || e.value === '') {
              e.value = undefined;
            }
            handleChange(e);
          }}
          type="text"
          value={values.national_register_nr}
          placeholder="  .  .  -   .  "
          data-test-id="new_prospect_register_nr"
        >
          {t('Social security number (only numbers)')}
        </MaskedInput>
        <PhoneInput
          error={errors?.phone}
          value={values?.phone}
          name="phone"
          dataTestId="crm_add_prospect_telephone"
          handleChange={handleChange}
        >
          {t(`Telephone number`)}
        </PhoneInput>
        <TextInput
          name="email"
          error={errors?.email}
          onChange={val => handleChange(val)}
          value={values?.email}
          data-test-id="new_prospect_email"
        >
          <NameInput>{t('Email')}</NameInput>
        </TextInput>
      </FlexWrapper>
      <FlowAddressContainer
        title={t(`Address`)}
        errors={errors}
        values={values}
        handleChange={val => {
          return handleChange(val);
        }}
        data-test-id="new_prospect_address"
      />
    </Form>
  );
};

const NameInput = styled.p`
  height: 1rem;
`;

const Form = styled.form`
  flex-shrink: 0;
`;

const FlexWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
`;

CRMCustomerOverviewNewProspectModalCustomer.propTypes = {
  errors: shape(),
  values: shape(),
  handleChange: func.isRequired,
};

export default CRMCustomerOverviewNewProspectModalCustomer;
