import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import ProFLowRiskObjectsQuestion from '../components/ProFLowRiskObjectsQuestion.jsx';
import { useTranslation } from 'react-i18next';
import { retrieveStorageById } from '../services/storeService.js';
import { useParams } from 'react-router';
import {
  formatISODate,
  parseDateObjToIsoFormat,
} from '../services/dateService.js';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext.js';
import { LoadingSpinner, H3 } from 'wg-fe-ui';

const ProFlowRiskObjects = () => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const { resetAnswers, addBackendAnswers } = useContext(ProFlowStorageContext);

  useEffect(() => {
    retrieveQuestionsAndTags();
    return () => resetAnswers();
  }, []);

  async function retrieveQuestionsAndTags() {
    const resp = await retrieveStorageById('pro_flow', sessionId);
    setQuestions(resp?.questions);
    const answers = [];
    resp?.questions.forEach(item => {
      if (item.value) {
        let tempValue = item.value;
        if (item?.rules?.input_type === 'DATE') {
          if (tempValue?.year) {
            tempValue = parseDateObjToIsoFormat(tempValue);
          } else if (formatISODate(tempValue).includes('NaN')) {
            tempValue = '';
          } else {
            tempValue = formatISODate(tempValue);
          }
        } else if (item?.rules?.input_type === 'ADDRESS') {
          tempValue = {};
          tempValue.streetName = item.value.street;
          tempValue.country_code = item.value.country_code;
          tempValue.streetNumber = item.value.housenr;
          tempValue.boxNumber = item.value?.boxnr;
          tempValue.postalCode = item.value.zipcode;
          tempValue.municipalityName = item.value.city;
        }
        if (item?.field === 'car_search' && item?.value) {
          tempValue.price = tempValue?.catalogue_value;
          tempValue.registrationFirst = formatISODate(
            tempValue.registration_first,
          );
          console.log(tempValue.registration_first, 'registrationFirst');
          tempValue.initial = true;
          delete tempValue.registration_first;
          delete tempValue.value;
        }
        if (item?.field === 'home_search' && item?.value) {
          tempValue.initial = true;
        }
        answers.push({
          id: item.id,
          answer: tempValue,
          typeTags: item.insurance_type_tags,
          type: item?.rules?.input_type,
          prefilled: true,
        });
      } else {
        if (item?.rules?.input_type === 'CHECKBOX') {
          answers.push({
            id: item.id,
            answer: false,
            typeTags: item.insurance_type_tags,
            type: item?.rules?.input_type,
          });
        }
      }
    });
    await addBackendAnswers(answers, sessionId);
    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
        <StyledStatus>
          {t('Gathering questions from the insurance companies')}
        </StyledStatus>
      </LoadingContainer>
    );
  }

  return (
    <ContentContainer>
      <Title>{t('Details of the policyholder')}</Title>
      <ProFLowRiskObjectsQuestion questions={questions} />
    </ContentContainer>
  );
};

const LoadingContainer = styled.div`
  width: calc(100vw - 25rem);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledStatus = styled(H3)`
  margin-top: 2rem;
`;

const ContentContainer = styled.div`
  padding: 4rem 0;
  width: 90%;
  max-width: 100rem;
  margin: 0 auto;
`;

const Title = styled.h1`
  font-size: 3.6rem;
  color: #0e0e0e;
  font-weight: 700;
  margin: 0 2rem 2rem 0;
`;

export default ProFlowRiskObjects;
