import React from 'react';
import { shape } from 'prop-types';
import Section from './Section';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryAutomotiveDrivers = ({ drivers }) => {
  const { name, dateOfBirth, licenseIssuesDate, claimLast5Years } =
    drivers || {};
  const { t } = useTranslation();

  return (
    <Section>
      <Section.Subtitle>
        {t('History.automotive.driverInfo.title')}
      </Section.Subtitle>
      <Section.Content data-test-id="dashboard_history_detail_automotive_section_primary_driver_information">
        <Section.Split>
          <div style={{ width: '25%' }}>
            <Label>{t('History.automotive.driverInfo.name')}</Label>
            <Text>{name}</Text>
          </div>
          <div style={{ width: '20%' }}>
            <Label>{t('History.automotive.driverInfo.birth')}</Label>
            <Text>{dateOfBirth}</Text>
          </div>
          <div style={{ width: '20%' }}>
            <Label>{t('History.automotive.driverInfo.license')}</Label>
            <Text>{licenseIssuesDate}</Text>
          </div>
          <div style={{ width: '25%' }}>
            <Label>{t('History.automotive.driverInfo.claim')}</Label>
            <Text>{claimLast5Years}</Text>
          </div>
        </Section.Split>
      </Section.Content>
    </Section>
  );
};

const Text = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.5;
  color: #050505;
`;

const Label = styled.label`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #a29c95;
`;

DashboardBrokerHistoryAutomotiveDrivers.defaultProps = {};

DashboardBrokerHistoryAutomotiveDrivers.propTypes = {
  drivers: shape().isRequired,
};

export default DashboardBrokerHistoryAutomotiveDrivers;
