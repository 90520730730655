import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import { shape } from 'prop-types';
import CarQuestionBox from '../components/CarQuestionBox';
import { useTranslation } from 'react-i18next';
import {
  getComponentName,
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
  getSocialLogin,
} from '../../../../store';
import { setPayloadCar, getPayloadCar } from '../store';
import ChatBox from '../../../../components/ChatBox';

const SecondHand = ({ history }) => {
  const { t } = useTranslation();
  const content = t(getComponentName(), { returnObjects: true });
  const status = getPayloadCar().vehicle_status;
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const checkAnswer = answer => {
    setPayloadCar('vehicle_type', answer);

    if (status === 'has_car') {
      addChatboxQuestion(t(`${getComponentName()}.question`));
    } else {
      addChatboxQuestion(t(`${getComponentName()}.question2`));
    }

    if (answer === 'new') {
      addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
    } else if (answer === 'second_hand') {
      addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
    }

    if (answer === 'new' && status === 'wants_car') {
      history.push(getNextPath('b'));
    } else {
      // don't go to plate lookup when social login
      if (getSocialLogin()) {
        history.push(getNextPath('b'));
      } else {
        history.push(getNextPath('a'));
      }
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <CarQuestionBox
          answers={content.answers}
          btnText="Bevestig"
          handleAnswer={answer => checkAnswer(answer)}
          to={null}
          dataTestId={['underwriting_car_new', 'underwriting_car_second_hand']}
        >
          {status === 'has_car' ? content.question : content.question2}
        </CarQuestionBox>
      </Container>
    </Layout>
  );
};

SecondHand.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(SecondHand);
