import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ToggleInput } from 'wg-fe-ui';
import LicensePlateSearchInput from './LicensePlateSearchInput';
import VehicleLookupComponent from './VehicleLookupComponent';
import VehicleData from './VehicleData';
import { func, oneOfType, string, object } from 'prop-types';
import { getMeData } from '../services/meDataService';
import { getBrokerData } from '../services/brokerDataService';
import { FlagsProvider } from '../hoc/FlagsProviderHoc';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const LicenseLookup_v2 = ({
  onSubmit,
  value,
  error,
  vehicle,
  setVehicle,
  dataTestId,
}) => {
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const [isManualActive, setIsManualActive] = useState(true);
  const [preFilled, setPreFilled] = useState(false);
  const configCatClient = useContext(FlagsProvider);
  const { t } = useTranslation();

  useEffect(() => {
    setFeatures();
  }, []);

  useEffect(() => {
    if (vehicle) onSubmit(vehicle);
  }, [vehicle]);

  useEffect(() => {
    if (_.isEqual(value, vehicle)) return;
    setVehicle(value);
  }, [value]);

  async function setFeatures() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const emailConfig = {
      email: me?.email,
      custom: {
        plan: broker?.plan,
      },
    };

    setIsCallantFeature(
      !(await configCatClient.getValueAsync(
        'canAccessPage',
        true,
        emailConfig,
      )),
    );
  }

  function handleVehicleData(_vehicle) {
    const payload = {
      brand: _vehicle?.labels?.manufacturer || vehicle?.brand,
      car_value: _vehicle?.car_value,
      cc: _vehicle?.cc,
      chassis_number: _vehicle?.chassis_number,
      co2: _vehicle?.co2,
      color: _vehicle?.color,
      febiacid: _vehicle?.febiacid,
      is_cabrio: _vehicle?.is_cabrio || false,
      is_jeep: _vehicle?.is_jeep || false,
      is_second_hand: _vehicle?.is_second_hand || false,
      is_sportscar: _vehicle?.is_sportscar || false,
      kw: _vehicle?.kw,
      kwh: _vehicle?.kwh,
      model: _vehicle?.labels?.model || vehicle?.series || vehicle?.model,
      model_year: _vehicle?.model_year,
      motor_type: _vehicle?.motorType,
      name: _vehicle?.name,
      number_plate: _vehicle?.number_plate?.toUpperCase(),
      options_value: _vehicle?.options_value,
      registrationFirst: _vehicle?.registrationFirst,
      seats: _vehicle?.seats,
      type: _vehicle?.type,
      vat_regime: _vehicle?.vat_regime,
      version: _vehicle?.version,
      initial: _vehicle?.initial,
    };

    setPreFilled(true);

    onSubmit(payload);
  }

  return (
    <LicenseLookupContainer>
      {!vehicle?.initial && (
        <ButtonContainer>
          <p>{t('Is the car registered?')}</p>
          <ToggleInput
            falseLabel={t('No')}
            trueLabel={t('Yes')}
            onChange={({ checked }) => setIsManualActive(!checked)}
            data-test-id={`${dataTestId}_is_car_registered`}
          />
        </ButtonContainer>
      )}
      {!isCallantFeature && !isManualActive && !vehicle?.initial && (
        <LicensePlateSearchInput
          error={error}
          onChange={setVehicle}
          data-test-id={`${dataTestId}_license_lookup`}
        />
      )}

      {isManualActive && !vehicle?.initial && (
        <VehicleLookupComponent
          error={error}
          onChange={setVehicle}
          dataTestId={`${dataTestId}_vehicle_lookup`}
        />
      )}

      {vehicle && (
        <VehicleData
          vehicle={vehicle}
          onChange={handleVehicleData}
          preFilled={preFilled}
        />
      )}
    </LicenseLookupContainer>
  );
};

const LicenseLookupContainer = styled.div``;
const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  > p {
    color: #8990a3;
    font-weight: 600;
    font-size: 14px;
  }
  > button {
    padding: 2.5rem 2rem;
    color: #0e0e0e;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
      0 4px 8px rgba(0, 0, 0, 0.04);
    border: 1px solid #e4e4e4;
  }
`;

LicenseLookup_v2.propTypes = {
  onSubmit: func.isRequired,
  error: string,
  value: oneOfType([string, object]),
  vehicle: object,
  dataTestId: string,
  setVehicle: func,
};
export default LicenseLookup_v2;
