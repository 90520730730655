import React, { useState, useEffect, useContext } from 'react';

import cogoToast from 'cogo-toast';
import {
  getBrokerData,
  getBrokerDistribution,
} from '../services/brokerDataService';
import {
  exportToCrmById,
  exportToInsurerById_V2,
  getPyModelsObjectsFromQuoteStorage,
  createProspect,
  createOffer,
} from '../services/apiRouterService';
import {
  ActionButton,
  Label,
  Modal,
  ButtonGroup,
  IconActionDropDown,
  IconDownloadFilled,
  IconWebWorldFilled,
  Upload,
} from 'wg-fe-ui';
import { getInsuranceModuleName } from '../services/insuranceService';
import { getPolicyPropositionLink } from '../services/docgenService';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CircleSpinner from './CircleSpinner';
import { shape, string, arrayOf, array, func } from 'prop-types';
import { FlagsProvider } from '../hoc/FlagsProviderHoc';
import { getMeData } from '../services/meDataService';
import LouiseThankyouIcon from '../../assets/images/LouiseThankyouIcon';
import { parseDateObjToIsoFormat } from '../services/dateService';
import { useParams } from 'react-router';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';

const ProFlowOffersAccordionBoxActions = ({
  id,
  riskObjectType,
  insuranceCompany,
  insuranceType,
  insurance,
  links,
  options,
  premium,
  quote,
  CRM,
  setIsModalOpen,
  originalQuote,
}) => {
  const [offerBtnState, setOfferBtnState] = useState('initial');
  const [crmExportBtnState, setCrmExportBtnState] = useState('initial');
  const [insurerExportBtnState, setInsurerExportBtnState] = useState('initial');
  const [broker, setBroker] = useState({});
  const [distribution, setDistribution] = useState({});
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const [showInDevModal, setShowInDevModal] = useState(false);
  const [offerCreated, setOfferCreated] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const configCatClient = useContext(FlagsProvider);
  const {
    selectedOffers,
    addSelectedOffers,
    removeSelectedOffers,
  } = useContext(ProFlowStorageContext);
  const { sessionId } = useParams();

  const { t, i18n } = useTranslation();

  // Get broker information
  useEffect(() => {
    initializeBrokerData();
    (async () => {
      const { me } = await getMeData();
      const { broker } = (await getBrokerData()) || {};
      setIsCallantFeature(
        !(await configCatClient.getValueAsync('canAccessPage', true, {
          email: me?.email,
          custom: {
            plan: broker?.plan,
          },
        })),
      );
    })();
  }, []);

  async function initializeBrokerData() {
    const brokerData = await getBrokerData();
    const distributionData = await getBrokerDistribution();
    distributionData.logo = distributionData?.links?.[0].href;
    setDistribution(distributionData);
    setBroker(brokerData);
  }

  function handleExportToCRM(e) {
    e.preventDefault();
    if (!CRM.key) return;
    setCrmExportBtnState('pending');
  }

  function handleExportToInsurer(e) {
    e.preventDefault();
    if (getInsuranceModuleName(insuranceCompany.key) === null) return;
    setInsurerExportBtnState('pending');
  }

  function shouldInsuranceModuleBeDisabled(insuranceModule, _riskObjectType) {
    if (getInsuranceModuleName(insuranceCompany.key)) {
      if (_riskObjectType === 'car') return false;
      if (_riskObjectType === 'family' && insuranceModule === 'allianz')
        return false;
      if (_riskObjectType === 'home') return false;
    }
    return true;
  }

  async function downloadOffer(payload) {
    const [resp, status] = await getPyModelsObjectsFromQuoteStorage(id);
    if (status !== 200) {
      setOfferBtnState('error');
      return;
    }
    const { policy_holder, risk_object } = resp;
    const [downloadLink, fileName] = await getPolicyPropositionLink({
      ...payload,
      client: policy_holder,
      risk_object,
    });
    if (downloadLink !== null && fileName !== null) {
      const downloadButton = document.createElement('a');
      downloadButton.href = downloadLink;
      downloadButton.download = fileName;
      setOfferBtnState('success');
      downloadButton.click();
    } else {
      setOfferBtnState('error');
    }
  }

  function handleOfferDownload(e) {
    if (e != null) {
      e?.preventDefault();
    }
    const today = new Date();
    const payload = {
      broker: broker.broker,
      theme: broker.theme,
      distribution,
      start_date: {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      },
      premium,
      quote: originalQuote?.resp?.quote,
      insurance: {
        ...originalQuote?.resp?.insurance,
        name: insurance.name,
      },
      language: i18n.language.toUpperCase(),
    };
    setOfferBtnState('pending');
    downloadOffer(payload);
  }

  useEffect(() => {
    if (offerBtnState === 'error') {
      cogoToast.error(t('Something went wrong while downloading your offer'));
    }
  }, [offerBtnState]);

  // Create offer in SMT for Callant flows
  async function createCallantOfferFromProFlow() {
    // First, get the risk object and prospect from backend
    const [resp, status] = await getPyModelsObjectsFromQuoteStorage(id);
    if (status !== 200) {
      cogoToast.error(t(`Something went wrong while exporting your offer`));
      return;
    }
    // Next, create a customer. Need to convert the PyModels object to SMT objects
    let customerType = '';
    let prospectPayload = {};
    if (resp?.policy_holder?.first_name) {
      customerType = 'customers';
      prospectPayload = {
        first_name: resp?.policy_holder?.first_name,
        last_name: resp?.policy_holder?.last_name,
        birth: parseDateObjToIsoFormat(resp?.policy_holder?.birth),
        email: resp?.policy_holder?.email,
        telephonenr: resp?.policy_holder?.telephonenr,
        address: resp?.policy_holder?.billing_address,
      };
    } else {
      customerType = 'companies';
      prospectPayload = {
        name: resp?.policy_holder?.name,
        cbe: resp?.policy_holder?.cbe,
        email: resp?.policy_holder?.email,
        telephonenr: resp?.policy_holder?.telephonenr,
        address: resp?.policy_holder?.billing_address,
      };
    }
    prospectPayload.risk_objects = {
      cars: [
        {
          ...resp?.risk_object,
          registration_first: parseDateObjToIsoFormat(
            resp?.risk_object?.registration_first,
          ),
          registration_last: parseDateObjToIsoFormat(
            resp?.risk_object?.registration_last,
          ),
          used_for: resp?.risk_object?.used_for.toUpperCase(),
          previous_insurance: undefined,
          type: undefined,
        },
      ],
    };
    prospectPayload.risk_objects.cars[0].drivers.map(driver => {
      driver.birth = parseDateObjToIsoFormat(driver.birth);
      driver.issue_license_date = parseDateObjToIsoFormat(
        driver.issue_license_date,
      );
      return driver;
    });
    const [respProspect, statusProspect] = await createProspect(
      distribution?.id,
      customerType,
      prospectPayload,
    );
    if (statusProspect !== 201) {
      cogoToast.error(t(`Something went wrong while exporting your offer`));
      return;
    }
    // Now, create an offer for that customer
    const mappedGuarantees = [quote?.base?.key];
    quote?.options.map(option => {
      mappedGuarantees.push(option.key);
    });
    const today = new Date();
    const offerPayload = {
      quote_id: id,
      guarantees: mappedGuarantees,
      risk_object_revisions: respProspect?.risk_object_revisions,
      start_date: parseDateObjToIsoFormat({
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      }),
      // TODO: FIX THIS, VErY BAD CODE
      commencement_date: parseDateObjToIsoFormat({
        day: today.getDate() + 1,
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      }),
      language: 'NL',
      send_mail_to_prospect: true,
    };
    const [, statusOffer] = await createOffer(
      distribution?.id,
      respProspect?.id,
      offerPayload,
    );
    if (statusOffer !== 201) {
      cogoToast.error(t(`Something went wrong while exporting your offer`));
      return;
    } else {
      cogoToast.success(t(`Your offer was successfully created`));
    }
    setOfferCreated(true);
  }

  function handleCreateOffer(e) {
    e.preventDefault();
    createCallantOfferFromProFlow();
  }

  // Export the quote as Bloc Retour to the broker CRM
  useEffect(() => {
    if (crmExportBtnState !== 'pending') {
      // Don't allow to submit when not clicked
      return;
    }
    exportToCrm(id);
  }, [crmExportBtnState]);

  async function exportToCrm(quote_id) {
    const today = new Date();
    const offerPayload = {
      broker: broker.broker,
      theme: broker.theme,
      distribution,
      start_date: {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear(),
      },
      premium,
      quote: originalQuote?.resp?.quote,
      insurance: originalQuote?.resp?.insurance,
      language: i18n.language.toUpperCase(),
    };
    const [resp, status] = await getPyModelsObjectsFromQuoteStorage(id);
    if (status !== 200 && resp.msg != null) {
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
    } else if (status !== 200) {
      setCrmExportBtnState('error');
      cogoToast.error(t(`Something went wrong while exporting your offer`));
      return;
    }

    const { policy_holder, risk_object } = resp;
    const [downloadLink, filename] = await getPolicyPropositionLink({
      ...offerPayload,
      client: policy_holder,
      risk_object,
    });
    const exportPayload = {
      type: 'TARIFICATION',
      origin: 'ProFlow - Manual Export',
      attachments: [],
    };
    if (downloadLink && filename) {
      exportPayload.attachments.push({
        filename,
        url: downloadLink,
      });
    }
    const [exportResp, exportStatus] = await exportToCrmById(id, exportPayload);
    switch (exportStatus) {
      case 200:
        setCrmExportBtnState('success');
        cogoToast.success(
          t(`The export to your crm was successful`, {
            brokerCRMName: CRM.name,
          }),
        );
        break;
      case 404:
        setCrmExportBtnState('error');
        if (exportResp.detail) {
          if (exportResp.detail[i18n.language]) {
            cogoToast.error(exportResp.detail[i18n.language]);
          } else {
            cogoToast.error(
              t(
                `The configuration of your keys does not allow us to export it Please contact us for this`,
              ),
            );
          }
        } else {
          cogoToast.error(
            t(
              `The configuration of your keys does not allow us to export it Please contact us for this`,
            ),
          );
        }
        break;
      default:
        setCrmExportBtnState('error');
        cogoToast.error(t(`Something went wrong while exporting your offer`));
        break;
    }
  }

  // Export the quote to the insurer
  useEffect(() => {
    if (insurerExportBtnState !== 'pending') {
      // Don't allow to submit when not clicked
      return;
    }
    exportToInsurer(id);
  }, [insurerExportBtnState]);

  async function exportToInsurer(quote_id) {
    const [resp, status] = await exportToInsurerById_V2(id);
    switch (status) {
      case 200:
        setInsurerExportBtnState('success');
        cogoToast.success(
          t(`Checkout.card.parameters.export.default.success`, {
            ref: resp?.external_ref,
            insuranceModuleName: getInsuranceModuleName(insuranceCompany.key),
          }),
        );
        break;
      case 404:
        setInsurerExportBtnState('error');
        if (resp.detail) {
          if (resp.detail[i18n.language]) {
            cogoToast.error(resp.detail[i18n.language]);
          } else {
            cogoToast.error(
              t(
                `The configuration of your keys does not allow us to export it Please contact us for this`,
              ),
            );
          }
        } else {
          cogoToast.error(
            t(
              `The configuration of your keys does not allow us to export it Please contact us for this`,
            ),
          );
        }
        break;
      default:
        setInsurerExportBtnState('error');
        cogoToast.error(
          t(
            `At the moment it is not possible to save this quote We are aware of this problem and will solve it soon`,
          ),
        );
        break;
    }
  }

  function handleClientOffers(e, offerPayload, _sessionId) {
    // if selectedOffers has an entry with the same id as the current quote remove it, else add to the selected offers
    if (selectedOffers.some(selectedOffer => selectedOffer.id === id)) {
      removeSelectedOffers(offerPayload.id, _sessionId);
      cogoToast.success(t(`Offer was removed`));
    } else {
      addSelectedOffers(offerPayload, _sessionId);
      cogoToast.success(t(`Offer was added`));
    }
  }

  useEffect(() => {
    window.addEventListener('click', e => {
      if (!e.target.classList.contains('exportDropdown')) {
        setOpenExport(false);
      }
    });
  });

  return (
    <ActionsContainer>
      <StyledLabel className="title">{t(`Actions`)}</StyledLabel>
      {isCallantFeature && (
        <>
          <Modal
            canClose
            showModal={showInDevModal}
            setShowModal={() => setShowInDevModal(false)}
            title="Functie in ontwikkeling"
          >
            <ModalContainer>
              <LouiseThankyouIcon />
              <p>
                We zijn momenteel hard aan het werk om deze functionaliteit in
                ons systeem te zetten
              </p>
            </ModalContainer>
          </Modal>
          <StyledButtonGroup>
            <StyledActionButton
              className="white"
              onClick={handleCreateOffer}
              disabled={offerCreated}
            >
              {t('Offerte opmaken')}
            </StyledActionButton>
          </StyledButtonGroup>
        </>
      )}
      {!isCallantFeature && (
        <>
          <StyledButtonGroup>
            <StyledActionButton
              level="white"
              onClick={() => setIsModalOpen(true)}
              fullwidth
            >
              {t('Edit')}
            </StyledActionButton>
          </StyledButtonGroup>
          <StyledButtonGroup>
            <StyledActionButton
              onClick={e =>
                handleClientOffers(
                  e,
                  {
                    id,
                    insurance,
                    insuranceCompany,
                    insuranceType,
                    options,
                    premium,
                    quote,
                    CRM,
                  },
                  sessionId,
                )
              }
            >
              {selectedOffers.some(selectedOffer => selectedOffer.id === id)
                ? t('Remove from client offers')
                : t('Add to client offers')}
            </StyledActionButton>
          </StyledButtonGroup>
          <StyledButtonGroup>
            <div>
              <DropDown
                onClick={() => {
                  setOpenExport(!openExport);
                }}
                level="white"
                open={openExport}
                className="exportDropdown"
              >
                <Upload className="exportDropdown" />
                {t(`Export offer`)}
                <span className="exportDropdown">
                  <IconActionDropDown className="exportDropdown" />
                </span>
              </DropDown>
              {openExport && (
                <ExportButtonsContainer>
                  {crmExportBtnState !== 'pending' ? (
                    <DropDownButton
                      disabled={!CRM?.key}
                      onClick={handleExportToCRM}
                    >
                      {CRM?.key
                        ? `${t(`Export to`)} ${CRM?.name}`
                        : t(`No crm found`)}
                    </DropDownButton>
                  ) : (
                    <DropDownButton disabled>
                      <StyledCircleSpinner color="#8990a3" size={20} />
                      {t(`Exporting`)}
                    </DropDownButton>
                  )}
                  {insurerExportBtnState !== 'pending' ? (
                    <DropDownButton
                      className="second white"
                      onClick={handleExportToInsurer}
                      disabled={shouldInsuranceModuleBeDisabled(
                        insuranceCompany.key,
                        riskObjectType,
                      )}
                      title={t(`No connection available`, {
                        insurance: insuranceCompany.name,
                      })}
                    >
                      {/* this is the button that is not behaving correctly  */}
                      {getInsuranceModuleName(insuranceCompany.key)
                        ? `${t(`Export to`)} ${getInsuranceModuleName(
                            insuranceCompany.key,
                          )}`
                        : t(`No connection available`, {
                            insurance: insuranceCompany.name,
                          })}{' '}
                    </DropDownButton>
                  ) : (
                    <DropDownButton className="second" disabled>
                      <StyledCircleSpinner />
                      {t(`Exporting`)}
                    </DropDownButton>
                  )}
                </ExportButtonsContainer>
              )}
            </div>
            {offerBtnState !== 'pending' ? (
              <StyledActionButton onClick={handleOfferDownload}>
                <IconDownloadFilled />
                {t(`Download offer`)}
              </StyledActionButton>
            ) : (
              <StyledActionButton disabled={true}>
                <StyledCircleSpinner />
                {t(`Downloading`)}
              </StyledActionButton>
            )}
          </StyledButtonGroup>
        </>
      )}
      <ProductInfoLinks>
        <h3 className="title">{t(`Info about the product`)}</h3>
        <span>
          <IconWebWorldFilled />
          <a href={links?.terms} target={`_blank`}>
            {t(`Terms of use`)}
          </a>
        </span>
        <span>
          <IconWebWorldFilled />
          <a href={links?.ipid} target={`_blank`}>
            {t(`IPID`)}
          </a>
        </span>
      </ProductInfoLinks>
    </ActionsContainer>
  );
};

const ExportButtonsContainer = styled.div`
  border-radius: 0.5rem;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 100%;
  margin-top: 0.4rem;
  width: 100%;
  :first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }
`;

const DropDownButton = styled.button`
  border: none;
  background-color: ${({ disabled }) => (disabled ? '#F4F4F4' : '#FFFFFF')};
  color: ${({ disabled }) => (disabled ? '#AEAEAE' : '#8990A3')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 4rem;
  width: 100%;
  /* &.first {
    border-radius: 0.5rem 0.5rem 0 0;
  } */
  &.second {
    border-radius: 0 0 0.5rem 0.5rem;
  }
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#F4F4F4' : '#FFEFDC')};
    color: ${({ disabled }) => (disabled ? '#AEAEAE' : '#FF8000;')};
  }
`;

const StyledActionButton = styled(ActionButton)`
  font-size: 1.3rem;
  font-weight: bold;
  svg {
    position: absolute;
    left: 1rem;
  }
`;

const ProductInfoLinks = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 2rem;
  h3 {
    color: black;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  span {
    height: 4rem;
    padding: 1rem;
    display: flex;

    font-size: 1.4rem;
    line-height: 2rem;
    background: #f0f1f3;
    border-radius: 0.8rem;
    svg {
      path {
        fill: #8990a3;
      }
      height: 2rem;
    }
    a {
      color: #0e0e0e;
      font-weight: 600;
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }
  }
  @media screen and (max-width: 1360px) {
    span {
      height: 3rem;
      padding: 0.5rem;
    }
    a {
      font-size: 1.2rem;
    }
    h3 {
      font-size: 1.4rem;
    }
  }
`;

const StyledButtonGroup = styled(ButtonGroup)`
  cursor: default;
  display: grid;
  grid-gap: 0.75rem;
  height: fit-content;
  &.twoRows {
    grid-gap: 1rem;
    display: grid;
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    margin-top: 2rem;
    text-align: center;
    max-width: 100rem;
    font-size: 2rem;
    line-height: 1.3;
  }
`;

const StyledLabel = styled(Label)`
  @media screen and (max-width: 1360px) {
    /* font-size: 1.4rem !important; */
    font-size: 1.4rem;
  }
`;

const ActionsContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  height: fit-content;

  p {
    color: black;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
  }
`;

const DropDown = styled(ActionButton)`
  font-weight: bold;
  font-size: 1.3rem;
  margin: auto;
  position: relative;
  width: 100%;
  &:hover {
    > span > svg > path {
      stroke: #ff8000;
    }
    > svg > path {
      fill: #ff8000;
    }
  }
  > span {
    transition-duration: 0.2s;
    transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
    position: absolute;
    right: 0.5rem;
    > svg {
      width: 2.5rem;
      height: 2.5rem;
      > path {
        stroke: #222;
      }
    }
  }
  > svg {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 0.5rem;
    > path {
      fill: #222;
    }
  }

  @media screen and (max-width: 1360px) {
    padding-right: 2rem;
    > svg {
      display: none;
    }
    > span {
      > svg {
        padding-top: 0.2rem;
      }
    }
  }
`;

const StyledCircleSpinner = styled(CircleSpinner)`
  margin: auto;
  margin-left: 0;
  height: 3rem;
`;

ProFlowOffersAccordionBoxActions.propTypes = {
  id: string.isRequired,
  riskObjectType: string.isRequired,
  // insurance: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceCompany: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceType: shape({ key: string.isRequired }),
  insurance: shape({ name: string.isRequired, key: string.isRequired }),
  guarantees: arrayOf(
    shape({ name: string.isRequired, key: string.isRequired }),
  ),
  options: arrayOf(shape({ name: string.isRequired, key: string.isRequired })),
  language: string.isRequired,
  links: arrayOf(shape({ ipid: string.isRequired, terms: string.isRequired })),
  premium: shape(),
  promotions: array,
  quote: shape(),
  CRM: shape({ name: string.isRequired, key: string.isRequired }),
  error: shape(),
  setIsModalOpen: func.isRequired,
  originalQuote: shape(),
};

export default ProFlowOffersAccordionBoxActions;
