import React from 'react';
import { string } from 'prop-types';

const Cabrio = ({ color }) => (
  <svg viewBox="0 0 286.2 99.42">
    <title>{'Asset 8'}</title>
    <path
      d="M283.74 42.37c-18.06-19.5-94.27-18.07-94.27-18.07l-29.16-19C156 2.47 151-1.11 145.46.33a3.22 3.22 0 00-1.25.35l35.6 24-125.59.17 10.38-9.3-2.33-2C32.4 18 13.08 36.28 3.78 43.44A9.87 9.87 0 00.38 54L5 70.09a9.92 9.92 0 009.48 7.16h22.39a28.05 28.05 0 0054.86 0h108.78a28.05 28.05 0 0054.86 0H271a9.61 9.61 0 009.48-7.16l5.19-18.24a9.53 9.53 0 00-1.93-9.48zM81.35 77.25a18 18 0 01-34.1 0 17.74 17.74 0 01-1-5.9 18.06 18.06 0 1135.11 5.9zm163.64 0a18 18 0 01-34.1 0 17.74 17.74 0 01-1-5.9 18.06 18.06 0 1135.11 5.9z"
      fill={color}
      data-name="Layer 2"
    />
  </svg>
);

Cabrio.defaultProps = {
  color: '#fff',
};

Cabrio.propTypes = {
  color: string,
};

export default Cabrio;
