import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import ChatBox from '../../../../components/ChatBox';
import {
  getNextPath,
  getComponentName,
  getAllCoverages,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { setTraits } from '../store';

const OwnerQuestion = ({ history }) => {
  const { t } = useTranslation();
  const coverages = getAllCoverages();
  const stepCount = coverages.family === true ? 2 : 1;

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const handleAnswer = answer => {
    addChatboxAnswer(answer);
    addChatboxQuestion(t(`${getComponentName()}.question`));
    switch (answer) {
      case t(`${getComponentName()}.answers.0.title`):
        setTraits('trustee', true);
        addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
        break;
      case t(`${getComponentName()}.answers.1.title`):
        setTraits('trustee', false);
        addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
        break;
      default:
    }
    if (stepCount > 1) {
      history.push(getNextPath('b'));
    } else {
      history.push(getNextPath('a'));
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          Answer1={t(`${getComponentName()}.answers.0.title`)}
          Answer2={t(`${getComponentName()}.answers.1.title`)}
          dataTestId1="underwriting_answer_yes"
          dataTestId2="underwriting_answer_no"
          response={answer => handleAnswer(answer)}
        >
          {t(`${getComponentName()}.question`)}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

OwnerQuestion.propTypes = {
  history: shape().isRequired,
};

export default withRouter(OwnerQuestion);
