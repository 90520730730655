import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import Layout from '../../DashboardLayout/views/Layout';
import DashboardToolbarBrokerHistory from '../../../components/DashboardToolbarBrokerHistory';
import { shape } from 'prop-types';
import DashboardBrokerHistoryInsuranceInformation from '../../../components/DashboardBrokerHistoryInsuranceInformation';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import {
  setTabItems,
  getHistory,
  setInsurTypes,
} from '../../../services/brokerhistoryService';
import { getNestedObject } from '../../../services/objectService';
import { useTranslation } from 'react-i18next';
import DashboardBrokerHistoryConversationInformation from '../../../components/DashboardBrokerHistoryConversationInformation';
import DashboardBrokerHistoryLegalNeeds from '../../../components/DashboardBrokerHistoryLegalNeeds';

const BrokerHistoryDetailLegal = ({ match, history }) => {
  const { setNavTabs } = useContext(TopNavigationItems);
  const [filteredInsureTypes, setFilteredInsureTypes] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [extraInfo, setExtraInfo] = useState({});
  const [insuranceInformation, setInsuranceInformation] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setInsurTypes(match.params.id, setSessionData).then(res => {
      setSessionData(res.sessionData);
      setFilteredInsureTypes(res.filteredData);
      const tmpItems = setTabItems(match.params.id, res.sessionData, t);
      setNavTabs(tmpItems);
    });
  }, []);

  useEffect(() => {
    const { already_insured_with } =
      getNestedObject(sessionData, ['legal']) || {};

    const { day, month, year } =
      getNestedObject(sessionData, [
        'legal',
        'previous_insurance_start_date',
      ]) || {};

    const date =
      (day || month || year) !== undefined
        ? `${day}/${month}/${year}`
        : undefined;

    setInsuranceInformation({
      insurer: already_insured_with,
      date,
    });
  }, [sessionData]);

  useEffect(() => {
    // safely check if it exists in object
    setExtraInfo(getNestedObject(sessionData, ['legal']) || {});
  }, [sessionData]);

  return (
    <>
      <DashboardToolbarBrokerHistory
        onClick={() =>
          getHistory(
            match.params.id,
            filteredInsureTypes,
            history,
            t,
            sessionData,
          )
        }
        dataTestId="dashboard_history_detail_calculate"
        btnText={t('History.goToMarket')}
      />
      <SectionWrapper>
        <DashboardBrokerHistoryConversationInformation
          sessionData={sessionData}
        />
        <DashboardBrokerHistoryLegalNeeds legalSituationData={extraInfo} />
        <DashboardBrokerHistoryInsuranceInformation
          insurer={insuranceInformation.insurer}
          date={insuranceInformation.date}
        />
      </SectionWrapper>
    </>
  );
};

const BrokerHistoryDetailLegalWrapper = ({ match, history }) => {
  return (
    <StyledLayout>
      <BrokerHistoryDetailLegal match={match} history={history} />
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

BrokerHistoryDetailLegal.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

BrokerHistoryDetailLegalWrapper.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

export default withRouter(BrokerHistoryDetailLegalWrapper);
