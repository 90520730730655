import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import styled, { keyframes } from 'styled-components';
import { shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ActionButton,
  Body,
  DataBlock,
  Label,
  StatusIcon,
  // TypeLabel,
} from 'wg-fe-ui';

import { getSocialLogin } from '../../../../store';

import {
  getInsurances,
  getGuarantees,
  getOfferById,
  refreshSignaturesFromOffer,
  // getSignatureUrl,
  // getSignatureStatus,
} from '../../../../services/apiRouterService';

import { formatISODate } from '../../../../services/dateService';
import { BASE_URL } from '../../../../../env.config';

import PDF from '../../../../../assets/images/pdf-download.svg';

const SignatureCard = ({ offer, availableAffiliations, distribution }) => {
  const { i18n, t } = useTranslation();
  const [sanitizedOffer, setSanitizedOffer] = useState({});
  const [areDocumentsSigned, setAreDocumentsSigned] = useState(false);
  const [startedDocumentsSigning, setStartedDocumentsSigning] = useState(false);
  const [refreshEnabled, setRefreshEnabled] = useState(false);
  const docsSignedRef = useRef(areDocumentsSigned);

  useEffect(() => {
    sanitizeOffer(offer, availableAffiliations);
  }, []);

  async function sanitizeOffer(offer, availableAffiliations) {
    let insuranceCompanyInfo = {};
    let packageName = '';
    let guarantees = {};
    let termsLink = '';
    let ipidLink = '';

    // Get Affiliation name and logo
    availableAffiliations?.forEach(affiliation => {
      const { affiliation_info } = affiliation;
      if (
        Object.keys(affiliation_info).some(
          name => affiliation_info[name] === offer.insurance.company,
        )
      ) {
        insuranceCompanyInfo = {
          logo: affiliation_info.logo,
          name: affiliation_info?.label,
        };
      }
    });

    // Get Affiliation guarantee names
    let insuranceId = null;

    if (!getSocialLogin()) {
      const [insurancesResp, insurancesStatus] = await getInsurances(
        offer.insurance.company,
      );

      if (insurancesStatus !== 200) {
        return console.error(insurancesResp);
      }

      // Search for the proper insurance ID from that group
      insurancesResp?.items.forEach(item => {
        if (item?.insurance?.type === offer.insurance.type) {
          insuranceId = item?.insurance?.id;
          packageName = item?.insurance?.names?.[i18n.language];
          termsLink = item?.insurance?.terms?.[i18n.language];
          ipidLink = item?.insurance?.ipid?.[i18n.language];
        }
      });
    }

    if (insuranceId) {
      // Retrieve the information from the insurance ID
      const [insuranceResp, insuranceStatus] = await getGuarantees(
        offer.insurance.company,
        insuranceId,
      );

      if (insuranceStatus !== 200) {
        return console.error(insuranceResp);
      }

      const guaranteeNamesDict = {};
      insuranceResp?.items.forEach(item => {
        guaranteeNamesDict[item?.guarantee_info?.key] =
          item?.guarantee_info?.names[i18n.language];
      });

      guarantees = guaranteeNamesDict;
    }

    setSanitizedOffer({
      id: offer.id,
      insuranceCompanyInfo,
      package_name: packageName,
      premium: {
        total_premium: offer.premium.total_premium,
        currency: offer.premium.currency,
        payment_interval: offer.premium.payment_interval,
      },
      guarantees: guarantees,
      ipid: ipidLink,
      terms: termsLink,
      offer_valid_start_date: offer.start_date,
      offer_valid_end_date: offer.end_date,
      commencement_date: offer.commencement_date,
      signature_url: `${BASE_URL}v1/api/broker/v1/api/distributions/${distribution}/offers/${offer.id}/revisions/${offer.revision}/signatures`,
    });
  }

  function handleSignatureLink(url) {
    window.open(url, '_blank');

    // Quick temp fix because real checking is not implemented in BE yet
    setStartedDocumentsSigning(
      startedDocumentsSigning => !startedDocumentsSigning,
    );
    setTimeout(() => {
      startCheckSignatureStatus(offer, 9, 3);
    }, 45000);
  }

  async function refreshStatus() {
    const [resp, status] = await refreshSignaturesFromOffer(
      distribution,
      offer.id,
      offer.revision,
    );

    if (status !== 200) {
      console.log('ERROR RESP', status, resp);
      return;
    }

    setStartedDocumentsSigning(
      startedDocumentsSigning => !startedDocumentsSigning,
    );

    startCheckSignatureStatus(offer, 9, 3);
  }

  // Checks signature status from offer X amount fo times with X amount of delay between each call.
  function startCheckSignatureStatus(offer, amount, delay) {
    console.log(docsSignedRef);
    if (!docsSignedRef.current) {
      console.log('ENTERED FUNCTION 1');
      if (amount) {
        checkStatusOfSignature(offer);
        setTimeout(() => {
          startCheckSignatureStatus(offer, amount - 1, delay);
        }, 1000 * delay);
      } else {
        setStartedDocumentsSigning(
          startedDocumentsSigning => !startedDocumentsSigning,
        );
        if (!refreshEnabled) {
          setRefreshEnabled(refreshEnabled => !refreshEnabled);
        }
      }
    }
  }

  async function checkStatusOfSignature(offer) {
    const [resp, status] = await getOfferById(
      distribution,
      offer.id,
      offer.revision,
    );

    if (status !== 200) {
      console.log('ERROR RESP', status, resp);
      return;
    }

    if (resp.documents.signed.length === resp.documents.unsigned.length) {
      console.log('DOCS LENGTH ARE THE SAME');
      setAreDocumentsSigned(true);
    }
  }

  if (_.isEmpty(sanitizedOffer)) {
    return (
      <Signature>
        <SignatureInsuranceInfo>
          <SkeletonLoading />
          <SkeletonLoading />
          <SkeletonLoading />
          <SkeletonLoading />
        </SignatureInsuranceInfo>
        <SignatureFooter>
          <SkeletonLoading />
          <SkeletonLoading />
          <SkeletonLoading />
        </SignatureFooter>
      </Signature>
    );
  }

  console.log(sanitizedOffer?.insuranceCompanyInfo);
  console.log('areDocumentsSigned', areDocumentsSigned);
  console.log('startedDocumentsSigning', startedDocumentsSigning);
  console.log('refreshEnabled', refreshEnabled);

  return (
    <Signature>
      <SignatureInsuranceInfo>
        {!_.isEmpty(sanitizedOffer?.insuranceCompanyInfo) ? (
          <InsuranceLogo
            alt={`${sanitizedOffer?.insuranceCompanyInfo?.name}'s logo`}
            src={sanitizedOffer?.insuranceCompanyInfo?.logo}
          />
        ) : (
          <InsuranceLogo />
        )}
        <div>
          <InsuranceName>
            {sanitizedOffer?.insuranceCompanyInfo?.name}
          </InsuranceName>
          <InsuranceType>{sanitizedOffer?.package_name}</InsuranceType>
        </div>
        <Guarantees>
          {/* {Object.values(sanitizedOffer?.guarantees).map(guarantee => (
            <GuaranteeLabel key={guarantee} text={guarantee} />
          ))} */}
        </Guarantees>
        <Price>
          {new Intl.NumberFormat('nl-BE', {
            style: 'currency',
            currency: sanitizedOffer?.premium?.currency,
          }).format(sanitizedOffer?.premium?.total_premium)}
          <span>{t(sanitizedOffer.premium?.payment_interval)}</span>
        </Price>
      </SignatureInsuranceInfo>
      <SignatureFooter>
        <DataBlock>
          <StyledLabel>{t('Offer documents')}</StyledLabel>
          <DocsWrapper>
            <DownloadableDoc href={sanitizedOffer?.terms} target={'_blank'}>
              <img src={PDF} alt="PDF" draggable="false" />
              <Body smaller>{t('Checkout.card.termsPDF')}</Body>
            </DownloadableDoc>
            <DownloadableDoc href={sanitizedOffer?.ipid} target={'_blank'}>
              <img src={PDF} alt="PDF" draggable="false" />
              <Body smaller>{t('Checkout.card.IPID')}</Body>
            </DownloadableDoc>
          </DocsWrapper>
        </DataBlock>
        <DataBlock>
          <StyledLabel>{t('Offer is valid till')}</StyledLabel>
          <Body smaller>
            {formatISODate(sanitizedOffer?.offer_valid_end_date)}
          </Body>
        </DataBlock>
        <DataBlock>
          <StyledLabel>{t('Commencement date')}</StyledLabel>
          <Body smaller>
            {formatISODate(sanitizedOffer?.commencement_date)}
          </Body>
        </DataBlock>
        {!startedDocumentsSigning && !areDocumentsSigned && !refreshEnabled && (
          <ActionButton
            fullwidth
            onClick={() => handleSignatureLink(sanitizedOffer?.signature_url)}
          >
            {t('Sign offer')}
          </ActionButton>
        )}
        {startedDocumentsSigning && !areDocumentsSigned && (
          <DotLabel>
            {t('Checking status')}
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </DotLabel>
        )}
        {!startedDocumentsSigning && !areDocumentsSigned && refreshEnabled && (
          <ActionButton fullwidth onClick={() => refreshStatus()}>
            {t('Refresh status')}
          </ActionButton>
        )}
        {!startedDocumentsSigning && areDocumentsSigned && refreshEnabled && (
          <StatusLabel>
            <StatusIcon type="success" />
            {t('Documents signed')}
          </StatusLabel>
        )}
      </SignatureFooter>
    </Signature>
  );
};

const Signature = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin-bottom: 2rem;
  border: 1px solid #e4e4e4;
  box-shadow: 0 0.4rem 1.6rem rgba(181, 181, 181, 0.25);
  border-radius: 1.2rem;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const SignatureInsuranceInfo = styled.header`
  width: 100%;
  display: grid;
  grid-template-columns: 11% 17% 48% 15%;
  grid-gap: 3%;
  align-items: center;
  padding: 3rem;
  border-bottom: 1px solid #e4e4e4;
`;

const SignatureFooter = styled.div`
  width: 100%;
  display: grid;
  padding: 3rem;
  grid-template-columns: 31% 15% 28% 17%;
  grid-gap: 3%;
  align-items: center;

  @media screen and (max-width: 965px) {
    grid-template-columns: 34% 15% 25% 20%;
    grid-gap: 2%;
  }

  @media screen and (max-width: 650px) {
    grid-template-columns: 34% 15% 25% 20%;
    grid-gap: 2%;
  }
`;

const InsuranceLogo = styled.img`
  max-width: 100%;
  max-height: 6rem;
`;

const InsuranceName = styled.h1`
  font-weight: 700;
`;
const InsuranceType = styled.h2`
  color: #8990a3;
  margin-top: 0.25rem;
  font-weight: 500;
`;

const Guarantees = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Price = styled.p`
  display: flex;
  flex-direction: column;
  font-weight: 900;
  font-size: 2.8rem;
  text-align: right;
  line-height: 115%;

  span {
    color: #8990a3;
    font-size: 2.1rem;
    font-weight: 600;
    text-transform: lowercase;
  }
`;

// const GuaranteeLabel = styled(TypeLabel)`
//   border-radius: 5rem;
//   margin: auto;
//   margin-left: 0;
//   height: 2.5rem;
//   line-height: 2rem;
//   margin-bottom: 0;
//   margin-right: 0.5rem;

//   &:nth-of-type(1n + 4) {
//     margin-top: 0.5rem;
//   }
// `;

const StyledLabel = styled(Label)`
  color: #8990a3;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

const DocsWrapper = styled.div`
  display: flex;
`;

const DownloadableDoc = styled.a`
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
  margin-right: 1.6rem;
  cursor: pointer;
  text-decoration: none;

  img {
    margin-right: 1rem;
    max-height: 2rem;
  }

  p {
    color: black;
    font-weight: 500;
  }

  &:hover {
    p {
      color: ${({ theme }) => theme.brand.primary};
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const load = keyframes`
  from {
    left: -150px;
  }
  to   {
    left: 100%;
  }
`;

const SkeletonLoading = styled.div`
  width: 100%;
  height: 6rem;
  position: relative;
  overflow: hidden;
  background-color: #f0f1f3;

  ::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(
      to right,
      #f0f1f3 0%,
      #c8c8cb 50%,
      #f0f1f3 100%
    );
    animation: ${load} 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
`;

const StatusLabel = styled(Label)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: #00cd39;
  text-align: right;

  > div {
    margin-right: 0.5rem;
  }
`;

const DotLabel = styled.label`
  font-size: 1.6rem;
  font-weight: 500;
  color: #8990a3;
  text-align: right;

  > span {
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    :nth-child(2) {
      animation-delay: 0.2s;
    }
    :nth-child(3) {
      animation-delay: 0.4s;
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

SignatureCard.propTypes = {
  offer: shape({}).isRequired,
  availableAffiliations: shape({}).isRequired,
  distribution: string.isRequired,
};

export default SignatureCard;
