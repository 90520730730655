/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation, useHistory } from 'react-router';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Layout from '../../LayoutFlow/views/App';
import regex from '../../../../services/regexService';
import {
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import {
  addAnswer,
  getCarData,
  setCarData,
  setPayloadCar,
  getPayloadCar,
  setCarValue,
  resetCarData,
  setManual,
} from '../store';
import ChatBox from '../../../../components/ChatBox';
import InputQuestionBox from '../../../../components/InputQuestionBox';

const LicensePlate = () => {
  const location = useLocation();
  const history = useHistory();
  const [plate, setPlate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  addChatboxQuestion(t(`${getComponentName()}.question`));
  const content = t(`${getComponentName()}`, { returnObjects: true });

  useEffect(() => {
    const { license_plate } = getPayloadCar();
    if (license_plate) {
      handleChange({ currentTarget: { value: license_plate } });
    }
    addBrowserHistory(location.pathname);
  }, []);

  function isPlateValid(_plate = '') {
    if (_plate.length < 1) {
      setError(t(`${getComponentName()}.errors.required`));
      return false;
    }
    if (_plate.length > 15) {
      setError(t(`${getComponentName()}.errors.long`));
      return false;
    }
    if (!_plate.match(regex.numberPlate)) {
      setError(t(`${getComponentName()}.errors.invalid`));
      return false;
    }
    return true;
  }

  async function submitHandler(_plate) {
    setCarValue('license_plate', _plate);
    try {
      setIsLoading(true);
      const { data } = await getCarData();
      await setIsLoading(false);
      setCarData(data);
      setPayloadCar('license_plate', _plate);
      addAnswer(`Mijn nummer plaat is ${_plate}`);
      history.push(getNextPath('b'));
    } catch (error) {
      setIsDisabled(true);
      setIsLoading(false);
      setError(t(`${getComponentName()}.errors.license_not_found`));
      resetCarData();
    }
  }

  function handleAnswer(answer, _content, _plate) {
    if (answer === _content.answers[0].title) {
      resetCarData();
      setManual(true);
      history.push(getNextPath('a'));
    } else {
      if (!isPlateValid(_plate || '')) {
        setIsDisabled(true);
        return;
      }
      setIsDisabled(false);
      submitHandler(_plate);
    }
  }

  function handleChange(e) {
    const { value } = e?.currentTarget || {};
    setError();
    setPlate(value || '');
    if (!isPlateValid(value || '')) {
      setIsDisabled(true);
      return;
    }
    addChatboxAnswer(
      t(`${getComponentName()}.interactiveMsg`, {
        licensePlate: value || '',
      }),
    );
    setIsDisabled(false);
    e?.charCode === 13 && submitHandler(value || '');
  }

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={false} />
        <InputQuestionBox
          question={t(`${getComponentName()}.question`)}
          Answer1={content.answers[0].title}
          Answer2={content.answers[1].title}
          dataTestId1="underwriting_answer_dontknow"
          dataTestId2="underwriting_answer_confirm"
          shouldLoad={isLoading && 2}
          mainDisabled={isDisabled}
          response={answer => handleAnswer(answer, content, plate)}
        >
          <InputContainer>
            <CountryFlag>B</CountryFlag>
            <Input
              data-test-id="carInput"
              onChange={handleChange}
              onKeyPress={handleChange}
              value={plate}
              pattern="\d+"
              type="text"
            />
            {error && <Error>{error}</Error>}
          </InputContainer>
        </InputQuestionBox>
      </Container>
    </Layout>
  );
};

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;
  position: relative;
`;

const CountryFlag = styled.div`
  width: 4.5rem;
  height: 4rem;
  background: #f1efef;
  border-radius: 1rem 0 0 1rem;
  border: #ccc 1px solid;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
  font-weight: 900;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  color: #76726e;

  & img {
    height: 3rem;
  }
`;

const Error = styled.p`
  color: red;
  height: 2rem;
  position: absolute;
  align-self: center;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -2rem;
  text-align: center;
`;

const Input = styled.input`
  height: 4rem;
  text-align: center;
  font-size: 2rem;
  border-radius: 1rem;
  text-transform: uppercase;
  flex-grow: 1;
  width: 100%;
  flex-shrink: 1;
  transition: 0.4s;
  padding: 0 0.5em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 5rem;
  padding-right: 5rem;
  border: #ccc 1px solid;
  border-left: none;
  font-weight: 800;
  letter-spacing: 0.25rem;
  margin-bottom: 2rem;

  &::placeholder {
    color: ${({ theme }) => theme.typo.subTitle};
    opacity: 1;
  }

  &:focus {
    color: ${({ theme }) => theme.brand.primary};
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.brand.primary};
    }
  }

  &[type='text'] {
    text-transform: uppercase;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

LicensePlate.propTypes = {
  history: shape().isRequired,
};

export default withRouter(LicensePlate);
