import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import FlowAddressInput from './FlowAddressInput';
import { object, func } from 'prop-types';
import {
  retrieveAddressStreets,
  retrieveAddressCities,
} from '../services/apiRouterService';
import { useDebouncedCallback } from 'use-debounce';
import { SearchSelectInput, TextInput } from 'wg-fe-ui/dist';
import { useTranslation } from 'react-i18next';

const FlowAddressContainer = ({
  errors,
  values,
  handleChange,
  title,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const [addressValues, setAddressValues] = useState(values);
  const [streetNames, setStreetNames] = useState();
  const [municipalityName, setMunicipalityName] = useState();
  const [selected, setSelected] = useState({});
  const [postals, setPostals] = useState({});

  useEffect(() => {
    //called once when page loads
    //not called again until given in address AND number, and hitting enter => called when all values are filled in
    //called twice in total: when page loads, and when the address is given in and the API call is done to fill in the remaining inputs

    if (addressValues) {
      const selectedObj = {};
      Object.keys(addressValues).forEach(key => {
        handleChange({ name: key, value: addressValues[key] });
        const selectedItem = {
          label: addressValues[key],
          value: addressValues[key],
        };
        selectedObj[key] = selectedItem;
      });
      setSelected(selectedObj);
    }
  }, [addressValues]);

  useEffect(() => {
    if (values.streetName) {
      getStreetNames({
        searchQuery: values['streetName'].substring(0, 6),
        municipalityName: values['municipalityName'],
      });
    }
  }, [values.streetName]);

  async function fetchStreets(value, values) {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.municipalityName)
    ) {
      let streetsOptionList = [];
      let payload;
      if (value.searchQuery) {
        payload = {
          city: value.municipalityName
            ? value.municipalityName
            : values['municipalityName'],
          searchQuery: value.searchQuery,
        };
      } else {
        payload = {
          city: value.municipalityName,
        };
      }

      const [resp, status] = await retrieveAddressStreets(payload);
      if (status !== 200) return;
      resp.forEach(street => {
        streetsOptionList.push({
          value: street.name,
          label: street.name,
        });
      });
      return streetsOptionList;
    }
  }

  async function fetchCities(value) {
    if (
      (value && value.searchQuery && value.searchQuery.length >= 2) ||
      (value && value.zipcode)
    ) {
      let citiesOptionList = [];
      let cityPostals = {};
      const [resp, status] = await retrieveAddressCities(value);
      if (status !== 200) return;
      resp.forEach(city => {
        cityPostals[city.name] = city.zipcode;
        citiesOptionList.push({
          value: city.name,
          label: city.name,
        });
      });

      return { cityPostals, citiesOptionList };
    }
  }

  const getStreetNames = async (val, callback) => {
    const resp = await fetchStreets({ searchQuery: val }, values);
    setStreetNames(resp);
    if (callback) {
      callback(resp);
    } else {
      return resp;
    }
  };

  const getCityNames = async (val, callback) => {
    if (callback) {
      const resp = await fetchCities({ searchQuery: val });
      setStreetNames({});
      setMunicipalityName(resp.citiesOptionList);
      // delete streetname
      const tmpSelected = { ...selected };
      delete tmpSelected['streetName'];
      setSelected(tmpSelected);
      handleChange({ name: 'streetName', value: undefined });
      setPostals(resp.cityPostals);

      callback(resp.citiesOptionList);
    } else {
      const resp = await fetchCities(val);
      setStreetNames({});
      setMunicipalityName(resp.citiesOptionList);
      // delete streetname
      const tmpSelected = { ...selected };
      delete tmpSelected['streetName'];
      setSelected(tmpSelected);
      handleChange({ name: 'streetName', value: undefined });
      setPostals(resp.cityPostals);
      return resp.citiesOptionList;
    }
  };

  const handleSelected = value => {
    const selectedVal = { label: value.value, value: value.value };
    const tmpSelected = { ...selected };
    if (value.name === 'municipalityName') {
      //delete streetname
      delete tmpSelected['streetName'];
      if (postals[selectedVal.value]) {
        tmpSelected['postalCode'] = postals[selectedVal.value];
        handleChange({ name: 'postalCode', value: postals[selectedVal.value] });
      }
    }
    tmpSelected[value.name] = selectedVal;
    setSelected(tmpSelected);
    handleChange(value);
  };

  const handleZipcodeValidation = async () => {
    if (values['postalCode'] && values['postalCode'].length === 4) {
      const [resp] = await getCityNames({
        zipcode: parseInt(values['postalCode']),
      });

      handleChange({ name: 'streetName', value: undefined });
      handleChange({ name: 'streetNumber', value: undefined });
      handleSelected({ name: 'municipalityName', value: resp.value });
    }
  };

  const [debounceFetchCities] = useDebouncedCallback(getCityNames, 500);
  const [debounceFetchStreets] = useDebouncedCallback(getStreetNames, 500);

  return (
    <div>
      {Object.keys(selected).length > 0 ? (
        <>
          <FlexWrapper {...otherProps}>
            <StyledSearchSelectInput
              async
              cacheOptions={false}
              defaultOptions={municipalityName}
              error={errors.municipalityName}
              id="municipalityName"
              name="municipalityName"
              loadOptions={debounceFetchCities}
              onSelected={value => handleSelected(value)}
              value={selected.municipalityName || ''}
              noOptionsMessage={() => 'no city found'}
            >
              {t('Municipality')} *
            </StyledSearchSelectInput>
            <TextInput
              error={errors.postalCode}
              onChange={handleChange}
              type="number"
              name="postalCode"
              dataTestId="underwriting_address_postalCode"
              value={values['postalCode']}
              onBlur={() => handleZipcodeValidation()}
              placeholder="zipcode"
            >
              {t('Postal code')} *
            </TextInput>
          </FlexWrapper>
          <FlexWrapper>
            <StyledSearchSelectInput
              async
              cacheOptions={false}
              defaultOptions={streetNames}
              error={errors.streetNames}
              id="streetName"
              name="streetName"
              loadOptions={debounceFetchStreets}
              onSelected={value => handleSelected(value)}
              value={selected.streetName || ''}
              noOptionsMessage={() => 'no streets found'}
            >
              {t('Street')} *
            </StyledSearchSelectInput>
            <FlexWrapper>
              <TextInput
                error={errors.streetNumber}
                onChange={handleChange}
                type="text"
                name="streetNumber"
                disabled={!values['streetName']}
                value={
                  values['streetNumber'] && values['streetName']
                    ? values['streetNumber']
                    : ''
                }
              >
                <FlowAdressInputLabel>{t('Housenr')} *</FlowAdressInputLabel>
              </TextInput>
              <TextInput
                error={errors.boxNumber}
                onChange={handleChange}
                type="string"
                name="boxNumber"
                disabled={!values['streetName']}
                value={values['boxNumber'] ? values['boxNumber'] : ''}
              >
                {t('Boxnr')}
              </TextInput>
            </FlexWrapper>
          </FlexWrapper>
        </>
      ) : (
        <FlowAddressInputWrapper>
          <FlowAddressInput
            fullAddressError={errors.municipalityName}
            addressValues={setAddressValues}
            title={title}
          />
        </FlowAddressInputWrapper>
      )}
    </div>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  height: 4rem;

  & .Select__value-container {
    height: 4rem;
    padding: 0;
    padding-left: 2rem;
    position: static;
  }

  & .Select__indicators {
    min-height: 4rem;
    height: 4rem;
    position: static;
    padding: 0;
  }

  & .Select__control {
    min-height: 4rem;
    height: 100%;
    position: static;
    padding: 0;
  }
`;

const FlowAdressInputLabel = styled.p`
  height: 1rem;
`;

const FlowAddressInputWrapper = styled.div`
  height: 9rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div + div {
    margin-left: 1rem;
  }
`;

FlowAddressContainer.propTypes = {
  errors: object.isRequired,
  values: object.isRequired,
  handleChange: func.isRequired,
  title: object,
};

export default FlowAddressContainer;
