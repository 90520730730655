/* stylelint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import CheckMark from '../components/CheckMark';
import QuestionBox from '../../../../components/QuestionBox';
import Layout from '../../LayoutFlow/views/App';
import Button from '../../../../components/Button';
import LoadingButton from '../../../../components/LoadingButton';
import Art from '../../../../../assets/images/lock/art';
import Drone from '../../../../../assets/images/lock/drone';
import Pets from '../../../../../assets/images/lock/pets';
import Garden from '../../../../../assets/images/lock/garden';
import {
  getNextPath,
  getCurrentTheme,
  showErrorPopup,
  getComponentName,
  getAllCoverages,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

import { setExtra, sendExtras, getExtras } from '../store';
import { getMatchData, setMatchData } from '../../../Marketplace/Market/store';
import {
  removeFromCheckout,
  resetSkippedSteps,
} from '../../../Marketplace/LayoutMarket/store';
import ApiRoutes from '../../../../store/ApiRoutes';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const Extras = ({ history }) => {
  const api = new ApiRoutes();
  const { t } = useTranslation();
  addChatboxQuestion(t(`${getComponentName()}.question`));
  const coverages = getAllCoverages();
  const flowStore = useFlowStore();
  const tempCov = [
    {
      type: 'home',
      bool: coverages.home,
    },
    {
      type: 'family',
      bool: coverages.family,
    },
    {
      type: 'car',
      bool: coverages.car,
    },
    {
      type: 'legal',
      bool: coverages.legal,
    },
  ];
  let covCount = 0;
  tempCov.forEach(coverage => {
    if (coverage.bool) {
      covCount += 1;
    }
  });
  const [disabled, setDisabled] = useState(false);
  const [finishedFetch, setFinishedFetch] = useState({});
  const [btnText, setBtnText] = useState(
    t(`${getComponentName()}.btnText.default`),
  );
  const [extras, setExtras] = useState(getExtras());
  const [sendStep, setSendStep] = useState(`initial`); // 'initial' | 'pending' | 'success' | 'error'

  const icons = {
    Art,
    Drone,
    Pets,
    Garden,
  };

  const location = useLocation();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    switch (sendStep) {
      case 'pending':
        setBtnText(t(`${getComponentName()}.btnText.pending`));
        setDisabled(true);
        break;
      case 'success':
        api
          .postSession()
          .then(() => {
            setDisabled(true);
            if (coverages.car) {
              history.push(getNextPath('a'));
            } else if (coverages.legal) {
              if (!coverages.family) {
                history.push(getNextPath('b'));
              } else {
                history.push(getNextPath('e'));
              }
            }
            setBtnText(t(`${getComponentName()}.btnText.success`));
          })
          .catch(err => console.error(err));
        break;
      case 'error':
        setDisabled(false);
        setBtnText(t(`${getComponentName()}.btnText.error`));
        break;
      default:
        setDisabled(false);
        if (coverages.car) {
          setBtnText(t(`${getComponentName()}.btnText.defaultCar`));
        } else {
          setBtnText(t(`${getComponentName()}.btnText.default`));
        }
        break;
    }
  }, [sendStep]);

  useEffect(() => {
    let _return = true;
    if (Object.keys(finishedFetch).length === covCount) {
      Object.keys(finishedFetch).forEach(key => {
        if (!finishedFetch[key]) {
          _return = false;
        }
      });
      if (_return) {
        api.postSession().then(() => {
          if (coverages.car) {
            history.push(getNextPath('a'));
          } else if (coverages.legal) {
            if (!coverages.family) {
              history.push(getNextPath('b'));
            } else {
              history.push(getNextPath('e'));
            }
          } else if (coverages.home) {
            history.push(getNextPath('c'));
          } else {
            if (coverages.family) {
              history.push(getNextPath('d'));
            }
          }
        });
      }
    }
  }, [finishedFetch]);

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const onClickHandler = () => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    var selected = [];
    if (getExtras()['art']) {
      selected.push(t(`${getComponentName()}.items.0.label`));
    }
    if (getExtras()['drone']) {
      selected.push(t(`${getComponentName()}.items.1.label`));
    }
    if (getExtras()['pets']) {
      selected.push(t(`${getComponentName()}.items.2.label`));
    }
    if (getExtras()['garden']) {
      selected.push(t(`${getComponentName()}.items.3.label`));
    }
    var items = '';
    if (selected.length === 1) {
      items = selected[0].toLowerCase();
    } else {
      for (var i = 0; i < selected.length; i++) {
        if (i !== selected.length - 1) {
          items += `${selected[i].toLowerCase() + ', '}`;
        } else {
          items += t(`${getComponentName()}.and`);
          items += `${' ' + selected[i].toLowerCase()}`;
        }
      }
    }
    if (selected.length === 0) {
      addChatboxAnswer(capitalize(t(`${getComponentName()}.noneMsg`)));
    } else {
      addChatboxAnswer(
        capitalize(
          t(`${getComponentName()}.interactiveMsg`, {
            items: items,
          }),
        ),
      );
    }

    setSendStep(`pending`);
    sendExtras()
      .then(() => {
        if (!coverages.car && !coverages.legal) {
          // Reset marketplace flow
          removeFromCheckout('fire');
          removeFromCheckout('people');
          removeFromCheckout('car');
          removeFromCheckout('legal');
          resetSkippedSteps();

          // End reset marketplace flow
          let tempsomething = {};
          tempCov.forEach(coverage => {
            if (coverage.bool) {
              tempsomething = {
                ...tempsomething,
                [coverage.type]: false,
              };

              const { type, campaign } = flowStore || {};
              if (type === 'campaign' && campaign?.extend === 'LEAD') {
                history.push('/thank-you-campaigns');
                return;
              }
              getMatchData(coverage.type)
                .then(r => {
                  tempsomething = {
                    ...tempsomething,
                    [coverage.type]: true,
                  };
                  setFinishedFetch(tempsomething);
                  setMatchData(
                    coverage.type === `home` ? `fire` : coverage.type,
                    r.data,
                  );
                  setSendStep(`success`);
                })
                .catch(error => {
                  console.error(error.response);
                  setSendStep(`error`);
                });
            }
          });
        } else {
          setSendStep('success');
        }
      })
      .catch(error => {
        setSendStep(`error`);
        showErrorPopup(true);
        console.error(error);
      });
  };

  const changeExtra = e => {
    setExtras({
      ...extras,
      [e.target.name]: e.target.checked,
    });
    setExtra(e.target.name, e.target.checked);
  };
  return (
    <Layout>
      <Container>
        <ScrollContainer>
          <StyledQuestionBox>
            {t(`${getComponentName()}.question`)}
          </StyledQuestionBox>
          <CheckboxContainer>
            {t(`${getComponentName()}.items`, { returnObjects: true }).map(
              item => (
                <CheckMark
                  key={item.key}
                  itemKey={item.key}
                  changeExtra={changeExtra}
                  checked={extras[item.key] ? extras[item.key] : false}
                  colors={getCurrentTheme()}
                  Icon={icons[item.icon]}
                >
                  {item.label}
                </CheckMark>
              ),
            )}
          </CheckboxContainer>
        </ScrollContainer>
        <ButtonContainer>
          {sendStep !== 'pending' ? (
            <Button
              dataTestId="underwriting_extras_submit"
              disabled={disabled}
              onClick={() => onClickHandler()}
            >
              {btnText}
            </Button>
          ) : (
            <LoadingButton>{btnText}</LoadingButton>
          )}
        </ButtonContainer>
      </Container>
    </Layout>
  );
};

const ScrollContainer = styled.div`
  width: 100%;
  min-height: calc(100% - 11.1rem);
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 4rem;
  flex: 1 1 auto;
  flex-shrink: 0;
`;

const StyledQuestionBox = styled(QuestionBox)`
  height: auto;
  flex-grow: 0;
  padding-bottom: 0;
  align-self: flex-start;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 80%;
  margin: auto;
  position: relative;
  margin-top: 2rem;
  z-index: 5;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 100%;
  font-size: 2rem;
  overflow-y: auto;
  overflow-x: scroll;
  line-height: 2.6rem;
  height: 100%;
  overflow-x: hidden;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 80%;
  padding: 0;
  margin-bottom: 4rem;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 0;

  & button {
    margin: 0;
  }

  & div {
    margin: 0;
  }
`;

Extras.propTypes = {
  history: shape().isRequired,
};

export default withRouter(Extras);
