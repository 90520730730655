import React, { useState, useEffect } from 'react';
import { object, func, bool } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  Section,
  Label,
  Text,
  Title,
  IconDriverFilled,
  IconReplacementFilled,
  IconEngineFilled,
  IconInfoFilled,
  H4,
  FeatureInput,
  IconSportsCarFilled,
  IconSuvFilled,
  IconCabrioFilled,
  IconEmergencyBrakeFilled,
  IconLaneControlFilled,
  IconCruiseControlFilled,
  IconAttentionAssistanceFilled,
  IconBlindSpotFilled,
  IconECallFilled,
  IconParkingAids,
  IconParkingFilled,
  EditActionButton,
  TextInput,
  SearchSelectInput,
  ActionButton,
  IconVanFilled,
  IconCarFilled,
} from 'wg-fe-ui';

import useForm from '../hooks/useForm';
import * as Yup from 'yup';

import {
  formatISODate,
  parseIncDateToIsoFormat,
} from '../services/dateService';
import DateInput from './DateInput_v3';

import cogoToast from 'cogo-toast';

const CRMRiskObjectDetailCarInformation = ({
  carData,
  onSubmitFunction,
  initEdit,
  isCustomerPage,
  customerPageFunction,
  disableEditing,
}) => {
  /* INITS
  -------------------------------------------------- */
  const { t } = useTranslation();

  const [data, setData] = useState(carData);
  const [tempData, setTempData] = useState(carData);
  const [editMode, setEditMode] = useState(initEdit);
  const [fuel, setFuel] = useState('');
  const [carUsage, setCarUsage] = useState('');
  const [vehicleType, setVehicleType] = useState('');

  useEffect(() => {
    setData(carData);
    setTempData(carData);
  }, [carData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  const fuelOptions = [
    { value: 'PET', label: t('ToolkitInfo.motor_types.PET') },
    { value: 'DIE', label: t('ToolkitInfo.motor_types.DIE') },
    { value: 'LPG', label: t('ToolkitInfo.motor_types.LPG') },
    { value: 'DIE_LPG', label: t('ToolkitInfo.motor_types.DIE_LPG') },
    { value: 'ELE', label: t('ToolkitInfo.motor_types.ELE') },
    { value: 'ELE_PET', label: t('ToolkitInfo.motor_types.ELE_PET') },
    { value: 'ELE_DIE', label: t('ToolkitInfo.motor_types.ELE_DIE') },
    { value: 'ELE_LPG', label: t('ToolkitInfo.motor_types.ELE_LPG') },
    { value: 'ELE_HYDRO', label: t('ToolkitInfo.motor_types.ELE_HYDRO') },
    { value: 'HYDRO', label: t('ToolkitInfo.motor_types.HYDRO') },
    { value: 'CNG', label: t('ToolkitInfo.motor_types.CNG') },
    { value: 'CNG_PET', label: t('ToolkitInfo.motor_types.CNG_PET') },
    { value: 'BIO', label: t('ToolkitInfo.motor_types.BIO') },
    { value: 'OTHER', label: t('ToolkitInfo.motor_types.OTHER') },
  ];

  const carUseOptions = [
    { value: 'PRIVATE', label: t('Vehicles.usage.PRIVATE') },
    { value: 'PROFESSIONAL', label: t('Vehicles.usage.PROFESSIONAL') },
  ];

  const vehicleTypeOptions = [
    { value: 'PASSENGER_CAR', label: t('PASSENGER_CAR') },
    { value: 'LIGHT_COMMERCIAL_VEHICLE', label: t('LIGHT_COMMERCIAL_VEHICLE') },
  ];

  /* HELPER FUNCTIONS
  -------------------------------------------------- */
  const FindFuelInEnums = fuel => {
    const result = fuelOptions.filter(obj => {
      return obj.value === fuel;
    });

    return result;
  };

  const FindCarUseInEnums = carUse => {
    const result = carUseOptions.filter(obj => {
      return obj.value === carUse;
    });

    return result;
  };

  const FindVehicleTypeInEnums = vehicleType => {
    const result = vehicleTypeOptions.filter(obj => {
      return obj.value === vehicleType;
    });

    return result;
  };

  /* FORM HANDLING
  -------------------------------------------------- */
  const ToggleEditMode = e => {
    setEditMode(!editMode);

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const cancelHandler = e => {
    e && e?.preventDefault();
    setEditMode(false);
    setData({ ...tempData });
  };

  const ValidationSchema = Yup.object().shape({
    used_for: Yup.string(),
    vehicle_type: Yup.string(),
    value: Yup.number()
      .min(1)
      .max(9999999)
      .required(t('Required')),
    brand: Yup.string()
      .matches(
        /^[A-Za-zéèùàçâêîôûäëïöü\- ]{1,100}$/,
        t('Please use a valid brand name'),
      )
      .required(t('Required')),
    model: Yup.string()
      .min(1)
      .max(100)
      .required(t('Required')),
    category: Yup.string().nullable(),
    version: Yup.string()
      .min(1)
      .max(100)
      .nullable(),
    seats: Yup.number().required(t('Required')),
    options_value: Yup.number()
      .min(1)
      .max(9999999)
      .nullable(),
    model_year: Yup.number()
      .min(1900)
      .max(new Date().getFullYear())
      .nullable(),
    registration_first: Yup.date()
      .min(1900)
      .max(new Date().getFullYear())
      .nullable(),
    registration_last: Yup.date()
      .min(1900)
      .max(new Date().getFullYear())
      .nullable(),
    number_plate: Yup.string()
      .min(1)
      .max(20)
      .matches(/^[A-Za-z0-9 -]{1,20}$/, t('Please use a valid licence plate'))
      .nullable(),
    chassis_number: Yup.string()
      .min(11)
      .max(17)
      .matches(/^[A-Z0-9]{11,17}$/, t('Please use a valid chassis no'))
      .nullable(),
    kw: Yup.number()
      .min(0, t('Form errors.min_zero'))
      .max(2000, `${t('Form errors.max_reached')} 2000.`)
      .transform(value => (isNaN(value) ? null : value))
      .required(t('Required')),
    cc: Yup.number()
      .min(0)
      .max(10000)
      .nullable(),
    co2: Yup.number()
      .min(0)
      .max(1000)
      .nullable(),
    average_km_per_year: Yup.number()
      .min(0)
      .max(200000)
      .nullable(),
    vat_regime: Yup.number()
      .min(0, t('Form errors.vat_regime_min'))
      .max(100, t('Form errors.vat_regime_max'))
      .typeError(t('Form errors.number_type_error'))
      .nullable()
      .transform(value => (isNaN(value) ? null : value))
      .when('used_for', {
        is: val => val === 'PROFESSIONAL',
        then: Yup.number()
          .required()
          .typeError(t('Form errors.number_type_error')),
        otherwise: Yup.number().typeError(t('Form errors.number_type_error')),
      }),
  });

  const setInitialFormValues = () => {
    if (data) {
      Object.keys(data).forEach(name => {
        handleChange({ name, value: data[name] });
      });

      Object.keys(data.drive_assistance_system).map(name => {
        handleChange({ name, value: data.drive_assistance_system[name] });
      });

      setFuel(FindFuelInEnums(data.motor_type));
      setCarUsage(FindCarUseInEnums(data.used_for));
      setVehicleType(FindVehicleTypeInEnums(data.vehicle_type));
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: ValidationSchema,
    // mode: 'onChange',
  });

  const handleFuelChange = e => {
    const selectedFuel = FindFuelInEnums(e.value);
    setFuel(selectedFuel);
    handleChange({ name: 'motor_type', value: selectedFuel[0].value });
  };

  const handleCarUseChange = e => {
    const selectedCarUse = FindCarUseInEnums(e.value);
    setCarUsage(selectedCarUse);
    handleChange({ name: 'used_for', value: selectedCarUse[0].value });
  };

  const handleVehicleTypeChange = e => {
    const selectedVehicleType = FindVehicleTypeInEnums(e.value);
    setVehicleType(selectedVehicleType);
    handleChange({ name: 'vehicle_type', value: selectedVehicleType[0].value });
  };

  const handleFeatureChange = ({ name, value }) => {
    handleChange({ name: name, value: value });
  };

  async function handleFormValues() {
    const payload = {
      age: values?.age,
      average_km_per_year: values?.average_km_per_year,
      brand: values?.brand,
      category: values?.category,
      cc: values?.cc,
      co2: values?.co2,
      color: values?.color,
      country_of_registration: values?.country_of_registration,
      drive_assistance_system: {
        attention_assist: values?.attention_assist,
        adaptive_cruise_control: values?.adaptive_cruise_control,
        automatic_emergency_braking: values?.automatic_emergency_braking,
        automatic_parking: values?.automatic_parking,
        blind_spot_check: values?.blind_spot_check,
        ecall: values?.ecall,
        lane_support: values?.lane_support,
        parking_aid: values?.parking_aid,
      },
      drivers: values?.drivers,
      febiacid: values?.febiacid,
      invoice_value: values?.invoice_value,
      is_cabrio: values?.is_cabrio,
      is_coupe: values?.is_coupe,
      is_jeep: values?.is_jeep,
      is_second_hand: values?.is_second_hand,
      is_sportscar: values?.is_sportscar,
      kw: values?.kw,
      kwh: values?.kwh,
      license_plate_type: values?.license_plate_type,
      model: values?.model,
      model_year: values?.model_year,
      motor_type: values?.motor_type,
      name: values?.name,
      number_plate: values?.number_plate,
      chassis_number: values?.chassis_number,
      options_value: values?.options_value,
      registration_first: values?.registration_first,
      registration_last: values?.registration_last,
      id: values?.id,
      seats: values?.seats,
      used_for: values?.used_for,
      value: values?.value,
      vat_regime: values?.vat_regime,
      vehicle_type: values?.vehicle_type,
      version: values?.version,
    };

    cogoToast.error(
      t('The editing and saving of a risk object is not supported yet'),
    );

    console.log('DEBUG PAYLOAD', payload);
  }

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
    } else {
      let newValues = {
        ...values,
        risk_object_id: data?.risk_object_id,
        risk_object_revision_id: data?.risk_object_revision_id,
        adaptive_cruise_control: values?.adaptive_cruise_control,
        attention_assist: values?.attention_assist,
        automatic_emergency_braking: values?.automatic_emergency_braking,
        automatic_parking: values?.automatic_parking,
        blind_spot_check: values?.blind_spot_check,
        ecall: values?.ecall,
        lane_support: values?.lane_support,
        parking_aid: values?.parking_aid,
        vat_regime: values?.vat_regime,
      };
      onSubmitFunction(newValues);
      setData(newValues);
      setEditMode(false);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    }
  };

  function handleIconType(vehicleIconType) {
    const color = '#CCCCCC';
    switch (vehicleIconType) {
      case 'PASSENGER_CAR':
        return <IconCarFilled color={color} />;
      case 'LIGHT_COMMERCIAL_VEHICLE':
        return <IconVanFilled color={color} />;
      default:
        return <IconCarFilled color={color} />;
    }
  }

  // useEffect(() => {
  //   console.log(typeof values?.vat_regime);
  //   console.log(parseInt(values?.vat_regime));
  // }, [values?.vat_regime]);

  return (
    <form onSubmit={handleFormSubmit}>
      <Section data-test-id="dashboard_crm_detail_automotive_section_car_information">
        <Section.Title>{t('Vehicle information')}</Section.Title>
        <Section.Content background>
          {editMode || disableEditing ? null : (
            <StyledEditButton onClick={e => ToggleEditMode(e)}>
              {t('Edit')}
            </StyledEditButton>
          )}

          <SectionContentHeader editMode={editMode}>
            <VehicleIconContainer>
              {handleIconType(data.vehicle_type)}
            </VehicleIconContainer>
            <TextWrapper>
              <Label>
                {t('Vehicles.Vehicle')} ({t(data?.vehicle_type)})
              </Label>
              <HeaderText>{`${values?.brand} ${values?.model}`}</HeaderText>
            </TextWrapper>

            <CatalogueValContainer>
              {editMode ? (
                <StyledTextInput
                  error={errors.value}
                  name="value"
                  placeholder={t('ToolkitInfo.carInfo.value')}
                  type="number"
                  value={values.value}
                  onChange={handleChange}
                  symbol="€"
                  symbolSide="left"
                  symbolText
                >
                  {t('ToolkitInfo.carInfo.value')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('ToolkitInfo.carInfo.value')}</Label>
                  <HeaderText>
                    {values?.value
                      ? parseFloat(values?.value)?.toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : '-'}
                  </HeaderText>
                </>
              )}
            </CatalogueValContainer>
          </SectionContentHeader>

          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconDriverFilled color={'#CCCCCC'} />
              </IconContainer>

              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.vehicle_type}
                    initial={vehicleType}
                    name="vehicle_type"
                    onSelected={handleVehicleTypeChange}
                    options={vehicleTypeOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Vehicles.Type')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.Type')}</Label>
                    <Text>
                      {values?.vehicle_type ? t(values?.vehicle_type) : '-'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.brand}
                  name="brand"
                  placeholder={t('Vehicles.Brand')}
                  type="text"
                  value={values.brand}
                  onChange={handleChange}
                >
                  {t('Vehicles.Brand')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Brand')}</Label>
                  <Text>{values?.brand ? values?.brand : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.model}
                  name="model"
                  placeholder={t('Vehicles.Model')}
                  type="text"
                  value={values.model}
                  onChange={handleChange}
                >
                  {t('Vehicles.Model')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Model')}</Label>
                  <Text>{values?.model ? values?.model : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.version}
                  name="version"
                  placeholder={t('Vehicles.Version')}
                  type="text"
                  value={values.version}
                  onChange={handleChange}
                >
                  {t('Vehicles.Version')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Version')}</Label>
                  <Text>{values?.version ? values?.version : '-'}</Text>
                </>
              )}
            </TextWrapper>

            {/* <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.category}
                  name="category"
                  placeholder={t('Vehicles.category.title')}
                  type="text"
                  value={values.category}
                  onChange={handleChange}
                >
                  {t('Vehicles.category.title')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.category.title')}</Label>
                  <Text>{values?.category ? values?.category : '-'}</Text>
                </>
              )}
            </TextWrapper> */}

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.seats}
                  name="seats"
                  placeholder={t('Vehicles.Seats')}
                  type="number"
                  value={values.seats}
                  onChange={handleChange}
                >
                  {t('Vehicles.Seats')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Seats')}</Label>
                  <Text>{values?.seats ? values?.seats : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.options_value}
                  name="options_value"
                  placeholder={t('Vehicles.Value of options')}
                  type="number"
                  value={values.options_value}
                  onChange={handleChange}
                  symbol="€"
                  symbolSide="left"
                  symbolText
                >
                  {t('Vehicles.Value of options')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Value of options')}</Label>
                  <Text>
                    {values?.options_value
                      ? values?.options_value?.toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.model_year}
                  name="model_year"
                  placeholder={t('Vehicles.Model year')}
                  type="number"
                  value={values.model_year}
                  onChange={handleChange}
                >
                  {t('Vehicles.Model year')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Model year')}</Label>
                  <Text>{values?.model_year ? values?.model_year : '-'}</Text>
                </>
              )}
            </TextWrapper>

            {/* <TextWrapper>
              {editMode || isCustomerPage ? null : (
                <>
                  <Label>{t('Vehicles.Age of the vehicle')}</Label>
                  <Text>
                    {formatISODate(values?.registration_first) || '--/--/----'}
                  </Text>
                </>
              )}
            </TextWrapper> */}
          </SectionContentInner>

          <SectionBorder />

          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconReplacementFilled color={'#CCCCCC'} />
              </IconContainer>

              <TextWrapper>
                {editMode ? (
                  <StyledTextInput
                    error={errors.number_plate}
                    name="number_plate"
                    placeholder={t('Vehicles.License plate')}
                    type="text"
                    value={values.number_plate}
                    onChange={handleChange}
                  >
                    {t('Vehicles.License plate')}
                  </StyledTextInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.License plate')}</Label>
                    <UpperText>
                      {values?.number_plate ? values?.number_plate : '-'}
                    </UpperText>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.chassis_number}
                  name="chassis_number"
                  placeholder={t('Vehicles.Chassis number')}
                  type="text"
                  value={values.chassis_number}
                  onChange={handleChange}
                >
                  {t('Vehicles.Chassis number')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Chassis number')}</Label>
                  <Text>
                    {values?.chassis_number ? values?.chassis_number : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <DateInput
                  error={errors.registration_first}
                  name="registration_first"
                  onChange={e =>
                    handleChange({
                      name: e.name,
                      value: parseIncDateToIsoFormat(e.value),
                    })
                  }
                  value={
                    data.registration_first
                      ? formatISODate(data.registration_first)
                      : ''
                  }
                >
                  {t('Vehicles.First registration')}
                </DateInput>
              ) : (
                <>
                  <Label>{t('Vehicles.First registration')}</Label>
                  <Text>
                    {values?.registration_first
                      ? formatISODate(values?.registration_first)
                      : '--/--/----'}
                  </Text>
                </>
              )}
            </TextWrapper>

            {isCustomerPage ? null : (
              <TextWrapper>
                {editMode ? (
                  <DateInput
                    error={errors.registration_last}
                    name="registration_last"
                    onChange={e =>
                      handleChange({
                        name: e.name,
                        value: parseIncDateToIsoFormat(e.value),
                      })
                    }
                    value={
                      data.registration_last
                        ? formatISODate(data.registration_last)
                        : ''
                    }
                  >
                    {t('Vehicles.Last registration')}
                  </DateInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.Last registration')}</Label>
                    <Text>
                      {values?.registration_last
                        ? formatISODate(values?.registration_last)
                        : '--/--/----'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            )}
          </SectionContentInner>

          <SectionBorder />

          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconEngineFilled color={'#CCCCCC'} />
              </IconContainer>

              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.motor_type}
                    initial={fuel}
                    name="motor_type"
                    onSelected={handleFuelChange}
                    options={fuelOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Vehicles.Fuel type')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.Fuel type')}</Label>
                    <Text>
                      {t('Vehicles.motor_types.' + values?.motor_type) || '-'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.kw}
                  name="kw"
                  placeholder={t('Vehicles.Power')}
                  type="number"
                  value={values.kw}
                  onChange={handleChange}
                  symbol="KW"
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.Power')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Power')}</Label>
                  <Text>{values?.kw ? `${values?.kw} KW` : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.cc}
                  name="cc"
                  placeholder={t('Vehicles.Displacement')}
                  type="number"
                  value={values.cc}
                  onChange={handleChange}
                  symbol="CC"
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.Displacement')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Displacement')}</Label>
                  <Text>{values?.cc ? `${values?.cc} CC` : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.co2}
                  name="co2"
                  placeholder={t('Vehicles.Emissions')}
                  type="number"
                  value={values.co2}
                  onChange={handleChange}
                  symbol="gr"
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.Emissions')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Emissions')}</Label>
                  <Text>{values?.co2 ? `${values?.co2} g CO2/km` : '-'}</Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>

          <SectionBorder />

          <SectionContentInner>
            {isCustomerPage && values?.average_km_per_year === null ? null : (
              <IconWrapper>
                <IconContainer>
                  <IconInfoFilled color={'#CCCCCC'} />
                </IconContainer>
                <TextWrapper>
                  {editMode ? (
                    <StyledTextInput
                      error={errors.average_km_per_year}
                      name="average_km_per_year"
                      placeholder={t('Vehicles.Annual distance')}
                      type="number"
                      value={values.average_km_per_year}
                      onChange={handleChange}
                      symbol="km"
                      symbolSide="right"
                      symbolText
                    >
                      {t('Vehicles.Annual distance')}
                    </StyledTextInput>
                  ) : (
                    <>
                      <Label>{t('Vehicles.Annual distance')}</Label>
                      <Text>
                        {values?.average_km_per_year?.toLocaleString('nl-BE') ||
                          '-'}{' '}
                        km
                      </Text>
                    </>
                  )}
                </TextWrapper>
              </IconWrapper>
            )}

            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.used_for}
                  initial={carUsage}
                  name="used_for"
                  onSelected={handleCarUseChange}
                  options={carUseOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Vehicles.usage.title')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Vehicles.usage.title')}</Label>
                  <Text>
                    {values?.used_for
                      ? t('Vehicles.usage.' + values?.used_for)
                      : '--'}
                  </Text>
                </>
              )}
            </TextWrapper>

            {isCustomerPage && values?.used_for === 'PRIVATE' ? null : (
              <TextWrapper>
                {editMode ? (
                  <StyledTextInput
                    error={errors.vat_regime}
                    name="vat_regime"
                    placeholder={t('VAT Regime')}
                    type="number"
                    value={values.vat_regime * 100}
                    onChange={({ value, name }) =>
                      handleChange({ name, value: value / 100 })
                    }
                    symbol="%"
                    symbolSide="right"
                    symbolText
                  >
                    {t('VAT Regime')}
                  </StyledTextInput>
                ) : (
                  <>
                    <Label>{t('VAT Regime')}</Label>
                    <Text>
                      {values?.vat_regime
                        ? `${parseFloat(values?.vat_regime) * 100}%`
                        : '-'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            )}
          </SectionContentInner>

          <SectionBorder />

          <FeaturesWrapper>
            <H4>{t('Category')}</H4>
            <Features>
              <FeatureInput
                checked={values?.is_sportscar}
                icon={<IconSportsCarFilled />}
                name="is_sportscar"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Sports car')}
              </FeatureInput>

              <FeatureInput
                checked={values?.is_jeep}
                icon={<IconSuvFilled size={35} />}
                name="is_jeep"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('SUV')}
              </FeatureInput>

              <FeatureInput
                checked={values?.is_cabrio}
                icon={<IconCabrioFilled size={35} />}
                name="is_cabrio"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Cabrio')}
              </FeatureInput>

              <FeatureInput
                checked={values?.is_coupe}
                icon={<IconCabrioFilled size={35} />}
                name="is_coupe"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Coupe')}
              </FeatureInput>
            </Features>
          </FeaturesWrapper>

          <SectionBorder />

          <FeaturesWrapper>
            <H4>{t('Driving aid systems')}</H4>
            <Features>
              <FeatureInput
                checked={values?.automatic_emergency_braking}
                icon={<IconEmergencyBrakeFilled />}
                name="automatic_emergency_braking"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.automatic_emergency_braking')}
              </FeatureInput>

              <FeatureInput
                checked={values?.adaptive_cruise_control}
                icon={<IconCruiseControlFilled />}
                name="adaptive_cruise_control"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.adaptive_cruise_control')}
              </FeatureInput>

              <FeatureInput
                checked={values?.lane_support}
                icon={<IconLaneControlFilled />}
                name="lane_support"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.lane_support')}
              </FeatureInput>

              <FeatureInput
                checked={values?.blind_spot_check}
                icon={<IconBlindSpotFilled />}
                name="blind_spot_check"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.blind_spot_check')}
              </FeatureInput>

              <FeatureInput
                checked={values?.attention_assist}
                icon={<IconAttentionAssistanceFilled />}
                name="attention_assist"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.attention_assist')}
              </FeatureInput>

              <FeatureInput
                checked={values?.ecall}
                icon={<IconECallFilled />}
                name="ecall"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.ecall')}
              </FeatureInput>

              <FeatureInput
                checked={values?.automatic_parking}
                icon={<IconParkingFilled />}
                name="automatic_parking"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.automatic_parking')}
              </FeatureInput>

              <FeatureInput
                checked={values?.parking_aid}
                icon={<IconParkingAids />}
                name="parking_aid"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('CheckCarExtra.questions.parking_aid')}
              </FeatureInput>
            </Features>
          </FeaturesWrapper>

          {editMode ? (
            <SectionContentFooter>
              <ActionButton level="default" onClick={cancelHandler}>
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton submit>{t('Save')}</ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

const VehicleIconContainer = styled.div`
  flex: 0 0 6rem;
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const SectionContentHeader = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  @media screen and (max-width: 500px) {
    padding: 12px 0;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;

  @media screen and (max-width: 500px) {
    justify-content: center;
    padding: 12px 0 0;
    margin: 12px 0;
  }
`;

const SectionBorder = styled.div`
  margin-top: 3rem;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.ui.outline};
`;

const CatalogueValContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  padding-right: 15px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding-right: 15px;

  /* two items */
  :first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ div {
    width: 49%;
  }

  /* three items */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 49%;
  }
`;

const HeaderText = styled(Title)`
  text-transform: capitalize;
`;

const IconWrapper = styled.div`
  display: flex;

  ${TextWrapper} {
    flex: 1 0 auto;
  }
`;

const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
  flex: 0 0 6rem;
`;

const SectionContentInner = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    :nth-of-type(2n + 3) {
      flex: 0 0 calc(50% - 6rem);
      margin-left: 6rem;
    }
  }

  > ${IconWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    padding: 12px;

    > ${TextWrapper} {
      flex: 0 0 calc(100% - 4.7rem);
      margin-left: 6.7rem;

      :nth-of-type(2n + 3) {
        flex: 0 0 calc(100% - 4.7rem);
        margin-left: 6.7rem;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }

    > ${IconWrapper} {
      flex: 0 0 calc(100%);
      margin-top: 1.5rem;

      > div {
        padding-right: 0;
      }
    }
  }
`;

const FeaturesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 3rem;
`;

const Features = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem 1rem;
  margin-top: 1.5rem;

  label > div {
    width: 90%;

    p {
      word-break: break-word;
    }
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

const StyledEditButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;

  @media screen and (max-width: 500px) {
    width: 4.5rem;
    height: 4.5rem;
    right: 1.8rem;
    top: 2.5rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
`;

const UpperText = styled(Text)`
  text-transform: uppercase;
`;

const Spacer = styled.div`
  width: 15px;
`;

CRMRiskObjectDetailCarInformation.propTypes = {
  carData: object,
  onSubmitFunction: func,
  initEdit: bool,
  isCustomerPage: bool,
  customerPageFunction: func,
  disableEditing: bool,
};

export default CRMRiskObjectDetailCarInformation;
