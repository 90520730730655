import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { getCurrentTheme } from '../../../../store';
import BrokerBackground from '../../../../../assets/images/bg-broker';
import { getBrokerDistribution } from '../../../../services/brokerDataService';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';
// import { getNestedObject } from '../../../../services/objectService';
// import { getPublicBrokerData } from '../../../../services/brokerDataService';

const Layout = ({ children }) => {
  const flowStore = useFlowStore();
  const [logo, setLogo] = useState('');

  useEffect(() => {
    getBrokerLogo();
  }, []);

  async function getBrokerLogo() {
    const { links } = await getBrokerDistribution();
    let logoRef =
      flowStore?.distribution?.logoFe ||
      links.filter(el => el.rel === 'logo_fe')[0].href;
    setLogo(logoRef);
  }

  return (
    <Background
      img={`url("data:image/svg+xml,${encodeURIComponent(
        renderToStaticMarkup(<BrokerBackground colors={getCurrentTheme()} />),
      )}")`}
    >
      <MainContainer>
        <Header>
          <Logo src={logo} alt="WeGroup" />
        </Header>
        <CoreContainer>
          <ContentContainer>
            {/* <StepIcon src={this.checkBottomIcon(currentStep)} alt="icon" /> */}
            {children}
          </ContentContainer>
        </CoreContainer>
      </MainContainer>
    </Background>
  );
};

const Background = styled.main`
  background-image: ${props => props.img},
    linear-gradient(
      180deg,
      ${({ theme }) => theme.brand.primary} 45.86%,
      ${({ theme }) => theme.brand.secondary} 100%
    );
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.font};
`;

const Header = styled.div`
  margin-top: -2%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  max-height: 7rem;
`;

const ContentContainer = styled.div`
  max-width: 100rem;
  width: 100%;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  border-radius: 3rem;
  max-height: 60rem;
  background-color: white;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MainContainer = styled.div`
  max-width: 120rem;
  width: 85%;
  height: 100%;
  display: flex;
  padding-top: 10rem;
  flex-direction: column;
`;

const CoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

Layout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: node.isRequired,
};

export default Layout;
