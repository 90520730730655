import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { shape, string, arrayOf, array } from 'prop-types';
import Modal from './Modal';
import { ActionButton, ToggleInput, Alert } from 'wg-fe-ui';
import { useModal } from '../hooks/useModal';
import ProFlowOffersEditQuoteModal from './ProFlowOffersEditQuoteModal';
import DateInput from './DateInput_v3';

import {
  // CheckBox,
  SelectOffersAccordionBox,
  TextArea,
  // AccordionBox,
  // ActionButton,
  // ButtonGroup,
  // Label,
  // Upload,
  // IconActionDropDown,
} from 'wg-fe-ui';
import AccordionHeader from './ProFlowOffersAccordionBoxHeader';
import { useContext } from 'react';
// import { createOffer } from '../services/apiRouterService';
// import { getBrokerDistribution } from '../services/brokerDataService';
import { useTranslation } from 'react-i18next';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import useForm from '../hooks/useForm';
import { formatISO, format, isPast, addDays, differenceInDays } from 'date-fns';
import { object as yupObject, string as yupString } from 'yup';
import { isEqual, isEmpty } from 'lodash';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';

const ProFlowSelectedOffersAccordionBox = ({
  id,
  prospectId,
  riskObjectType,
  insurance,
  insuranceCompany,
  insuranceType,
  guarantees,
  options,
  language,
  premium,
  promotions,
  quote,
  CRM,
  error,
  originalQuote,
}) => {
  const [isModalOpen, setIsModalOpen] = useModal();
  const [hasValuesChanged, setHasValuesChanged] = useState(false);
  const {
    getOfferParameters,
    setOfferParameters,
    offersParameters,
    createInitialOfferValitityDates,
  } = useContext(ProFlowOffersContext);
  const { answers } = useContext(ProFlowStorageContext);
  const { t } = useTranslation();

  const { values, handleChange, errors, handleSubmit } = useForm({
    validationSchema: yupObject().shape({
      offerValidityFrom: yupString().test(
        'offer-Validity-From',
        t('Date should not be in the past'),
        value => !isPast(addDays(new Date(value), 1)),
      ),
      offerValidityUntil: yupString().test(
        'offer-Validity-until',
        t('There should be at least one day of difference between the dates'),
        function(value) {
          return (
            differenceInDays(
              new Date(value),
              new Date(this.parent.offerValidityFrom),
            ) > 0
          );
        },
      ),
      motivation: yupString().test('motivation', 'Required', function(value) {
        const { overwriteMotivation } = this.parent;
        if (!overwriteMotivation) return true;
        return value.length > 0;
      }),
    }),
    mode: 'onChange',
    initialValues: {
      overwriteMotivation: false,
      mailToClient: false,
      motivation: '',
    },
  });

  useEffect(() => {
    const initialParams = getOfferParameters(id);
    if (initialParams?.length === 0) {
      createInitialOfferValitityDates({ id, quote, ...values, insuranceType });
    }
    // setOfferParameters(id, values);
  }, []);

  useEffect(() => {
    const params = getOfferParameters(id);
    params.forEach(param => {
      Object.entries(param).forEach(([name, value]) => {
        handleChange({ name, value });
      });
    });
  }, [offersParameters]);

  function handleDate({ name, value }) {
    const [day, month, year] = value.split('/');
    const jsDate = new Date(year, month - 1, day);
    handleChange({ name, value: formatISO(jsDate) });
  }

  function handleDateValue(value) {
    if (new Date(value) !== 'Invalid Date' && !isNaN(new Date(value))) {
      return format(new Date(value), 'dd/MM/yyyy');
    }
  }

  function onSumbit(_values) {
    setOfferParameters(id, _values);
  }

  useEffect(() => {
    const [params] = getOfferParameters(id);
    if (isEmpty(params) || isEmpty(values)) return;
    setHasValuesChanged(
      !isEqual(formatDatesToNonIso(values), formatDatesToNonIso(params)),
    );
  }, [values]);

  // Convert date to dd/mm/yyyy so seconds are not considered in the comparison
  function formatDatesToNonIso({
    commencement,
    offerValidityFrom,
    offerValidityUntil,
    ...rest
  }) {
    return {
      commencement: handleDateValue(commencement),
      offerValidityFrom: handleDateValue(offerValidityFrom),
      offerValidityUntil: handleDateValue(offerValidityUntil),
      ...rest,
    };
  }

  function hasEmail(_values = []) {
    const [emailField] = _values.filter(({ field }) => field === 'email') || [];
    return !!emailField;
  }

  return (
    <>
      <Modal onClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
        <ProFlowOffersEditQuoteModal
          id={id}
          insurance={insurance}
          insuranceCompany={insuranceCompany}
          insuranceType={insuranceType}
          guarantees={guarantees}
          options={options}
          premium={premium}
          quote={quote}
          CRM={CRM}
          onClose={() => setIsModalOpen(false)}
          fullCurrentQuote={originalQuote}
        />
      </Modal>
      <SelectOffersAccordionBox>
        <SelectOffersAccordionBox.Header>
          <AccordionHeader
            id={id}
            selectedOffers={true}
            logo={{
              src: insuranceCompany.logo,
              alt: `${insuranceCompany.name} logo`,
            }}
            insuranceName={insuranceCompany?.name}
            insuranceType={insurance?.name}
            options={options}
            totalPremium={premium?.total_premium?.toFixed(2)}
            error={error}
            interval={originalQuote?.quote?.details?.payment_interval.toUpperCase()}
          />
        </SelectOffersAccordionBox.Header>
        <SelectOffersAccordionBox.Content>
          <Line />
          <MiddleContainer>
            <div className="offerValidityContainer">
              <Label>{t('Offer validity for this offer')}</Label>
              <GroupDateInput>
                <StyledDateInput
                  name="offerValidityFrom"
                  value={handleDateValue(values.offerValidityFrom)}
                  onChange={handleDate}
                >
                  <span>{t('From')}:</span>
                </StyledDateInput>
                <StyledDateInput
                  name="offerValidityUntil"
                  onChange={handleDate}
                  value={handleDateValue(values.offerValidityUntil)}
                >
                  <span>{t('Until')}:</span>
                </StyledDateInput>
              </GroupDateInput>
              {errors.offerValidityFrom && (
                <StyledAlert icon>{errors.offerValidityFrom}</StyledAlert>
              )}
              {errors.offerValidityUntil && (
                <StyledAlert icon>{errors.offerValidityUntil}</StyledAlert>
              )}
            </div>
            <div className="commencementDateContainer">
              <Label>{t('Commencement date for this offer')}</Label>
              <StyledDateInput
                name="commencement"
                onChange={handleDate}
                value={handleDateValue(values.commencement)}
              >
                <span>{t('From')}:</span>
              </StyledDateInput>
            </div>
            {hasEmail(answers) && (
              <div className="mailOfferContainer">
                <Label>{t('Mail this offer to client')}</Label>
                <ToggleInput
                  className="toggle"
                  falseLabel={t('No')}
                  trueLabel={t('Yes')}
                  name="mailToClient"
                  checked={values.mailToClient || false}
                  onChange={({ checked }) =>
                    handleChange({
                      name: 'mailToClient',
                      value: checked || false,
                    })
                  }
                />
              </div>
            )}
            {values.overwriteMotivation && (
              <StyledTextArea
                name="motivation"
                onChange={handleChange}
                value={values.motivation || ''}
                error={errors.motivation}
              >
                {t('Motivation')}
              </StyledTextArea>
            )}
          </MiddleContainer>
          <Line />
          <BottomContainer>
            <div>
              <Label>{t('Override motivation')}</Label>
              <ToggleInput
                className="toggle"
                falseLabel={t('No')}
                trueLabel={t('Yes')}
                name="overwriteMotivation "
                onChange={({ checked }) =>
                  handleChange({ name: 'overwriteMotivation', value: checked })
                }
              />
            </div>
            <ActionButton
              level="secondary"
              onClick={() => setIsModalOpen(true)}
            >
              {t('View offer details')}
            </ActionButton>
            <ActionButton
              disabled={!hasValuesChanged || !isEmpty(errors)}
              onClick={() => handleSubmit(onSumbit)}
            >
              {t('Confirm changes to offer')}
            </ActionButton>
          </BottomContainer>
        </SelectOffersAccordionBox.Content>
      </SelectOffersAccordionBox>
    </>
  );
};

const StyledAlert = styled(Alert)`
  margin: 0.4rem 0;
  > * {
    color: #ff8000;
  }

  > *:first-child {
    border: 1.5px solid #ff8000;
  }
`;

const StyledTextArea = styled(TextArea)`
  grid-column: span 7;
  padding: 0 3rem 2rem;
  & textarea {
    height: 12rem;
    resize: vertical;
  }
`;

const BottomContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 1rem;
  padding: 3rem;
  > div {
    grid-column: span 4;
    .toggle {
      padding: 0 0 1rem;
    }
  }
  > button {
    align-self: center;
    grid-column: span 2;
    height: 5rem;
    line-height: 1.75rem;
  }
`;

const MiddleContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
  padding: 3rem;
  .offerValidityContainer {
    grid-column: span 4;
  }
  .commencementDateContainer {
    grid-column: span 2;
  }
  .mailOfferContainer {
    grid-column: span 2;
    > .toggle {
      padding-top: 0.5rem;
    }
  }
  @media (max-width: 1580px) {
    grid-template-rows: auto auto;
    grid-template-columns: repeat(6, 1fr);
    .offerValidityContainer {
      grid-column: span 4;
    }
    .commencementDateContainer {
      grid-column: span 2;
    }
    .mailOfferContainer {
      grid-column: span 2;
    }
  }
  @media (max-width: 1200px) {
    .offerValidityContainer {
      grid-column: span 6;
    }
    .commencementDateContainer {
      grid-column: span 3;
    }
    .mailOfferContainer {
      grid-column: span 3;
    }
  }
`;

const GroupDateInput = styled.div`
  display: flex;
  width: 100%;
  > div:first-child {
    margin-right: 1rem;

    @media (max-width: 1200px) {
      margin-right: 3rem;
    }
  }
`;

const StyledDateInput = styled(DateInput)`
  height: fit-content;
  > label {
    flex-direction: row;
  }
  label > div {
    display: flex;
    margin-top: 0;
    padding-right: 1rem;
    text-align: right;
    border-radius: 0 0.3rem 0.3rem 0;
  }
  span {
    background: #f0f1f3;
    border: 1px solid #d3d4d8;
    border-right: 0;
    bottom: 0;
    color: #8990a3;
    padding: 1.4rem 1rem;
    z-index: 10;
    border-radius: 0.3rem 0 0 0.3rem;
  }
`;

const Label = styled.div`
  color: #8990a3;
  font-size: 1.3rem;
  height: 3rem;
`;

const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: #ddd;
`;

// const OfferValidityDate = styled.div`
//   display: grid;
//   grid-template-columns: 34% 34% 30%;
//   grid-gap: 1rem;
//   margin-top: 4rem;
//   /* margin-bottom: -5rem; */
//   span {
//     display: grid;
//     grid-template-columns: 25% 75%;
//     p {
//       background: #f0f1f3;
//       border: 1px solid #e4e4e4;
//       border-right: none;
//       box-sizing: border-box;
//       border-radius: 5px 0 0 5px;
//       padding: 1.1rem;
//       font-size: 14px;
//       color: #8990a3;
//     }
//   }
//   button {
//     background: #ff8000;
//     border-radius: 5px;
//     color: white;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 100%;
//     width: 100%;
//   }
//   div {
//     height: 4rem;
//     margin-top: 0;
//   }
// `;

// const ActionsContainerSelectedOffers = styled.div`
//   margin-bottom: 3rem;
//   span {
//     background: #f0f1f3;
//     border-radius: 8px;
//     margin-top: 1.5rem;
//     height: 5rem;
//     padding: 1rem;
//     display: flex;
//     line-height: 3rem;
//   }
// `;

// const ActionButtonContainer = styled.div` margin-top: 1rem;
//   background: #f0f1f3;
//   border-radius: 8px;
//   padding: 1rem;
//   display: grid;
//   grid-template-rows: 22% 22% 22% 22%;
//   grid-gap: 1rem;
//   button {
//     background: #ff8000;
//     border-radius: 5px;
//     color: white;
//     display: flex;
//     justify-content: center;
//     margin: auto;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 100%;
//     width: 100%;
//     height: 5rem;
//     position: relative;
//     .white {
//       background: white;
//       color: #8990a3;
//     }
//     .remove {
//       color: #f74040;
//     }
//   }
//   svg {
//     path {
//       fill: #8990a3;
//       stroke: #8990a3;
//     }
//     .download {
//       right: 0.5rem;
//       position: absolute;
//     }
//     .upload {
//       margin-left: -1rem;
//     }
//     margin-top: -0.3rem;
//     height: 2rem;
//   }
// `;

// const InfoForm = styled.div`
//   display: grid;
//   grid-gap: 2rem;
//   grid-template-columns: 31.5% 31.5% 31.5%;
// `;

// const ConfirmContainer = styled.div`
//   display: grid;
//   grid-template-rows: 30% 58%;
//   grid-gap: 1rem;
// `;

// const SelectedOffersAccordion = styled.div`
//   display: grid;
//   grid-gap: 2rem;
//   grid-template-rows: 70% 10%;
//   .label {
//     color: black;
//     font-weight: bold;
//     font-size: 16px;
//     line-height: 20px;
//   }
// `;

ProFlowSelectedOffersAccordionBox.propTypes = {
  id: string.isRequired,
  prospectId: string,
  riskObjectType: string.isRequired,
  insurance: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceCompany: shape({ name: string.isRequired, key: string.isRequired }),
  insuranceType: shape({ key: string.isRequired }),
  guarantees: arrayOf(
    shape({ name: string.isRequired, key: string.isRequired }),
  ),
  options: arrayOf(shape({ name: string.isRequired, key: string.isRequired })),
  language: string.isRequired,
  premium: shape(),
  promotions: array,
  quote: shape(),
  CRM: shape({ name: string.isRequired, key: string.isRequired }),
  error: shape(),
  originalQuote: shape(),
  fullCurrentQuote: shape(),
};

export default ProFlowSelectedOffersAccordionBox;
