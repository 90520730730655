import React from 'react';
import { shape } from 'prop-types';

const StepIconPhone = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50.66 76.86"
    style={{ width: `100%`, height: `100%` }}
  >
    <title>Personal Info</title>
    <g id="Iphone">
      <path
        style={{ fill: `${colors.brand.primary}` }}
        d="M28.47,68A6.78,6.78,0,0,1,22,63.11L6.4,7.6A6.76,6.76,0,0,1,11.08-.72L30.17-6.07A6.69,6.69,0,0,1,32-6.33,6.77,6.77,0,0,1,38.49-1.4L54.06,54.11a6.76,6.76,0,0,1-4.68,8.32L30.29,67.79A7.07,7.07,0,0,1,28.47,68Z"
        transform="translate(-4.9 7.58)"
      />
      <path
        style={{ fill: `${colors.brand.dark}` }}
        d="M32-5.08h0a5.52,5.52,0,0,1,5.29,4L52.85,54.45A5.5,5.5,0,0,1,49,61.23L30,66.58a5.42,5.42,0,0,1-1.49.21,5.52,5.52,0,0,1-5.3-4L7.61,7.26A5.5,5.5,0,0,1,11.42.48L30.51-4.87A5.36,5.36,0,0,1,32-5.08m0-2.5a8.11,8.11,0,0,0-2.17.3L10.74-1.92A8,8,0,0,0,5.2,7.94L20.77,63.45a8,8,0,0,0,7.7,5.84,8.09,8.09,0,0,0,2.16-.3l19.09-5.36a8,8,0,0,0,5.54-9.86L39.69-1.74A8,8,0,0,0,32-7.58Z"
        transform="translate(-4.9 7.58)"
      />
    </g>
  </svg>
);

StepIconPhone.propTypes = {
  colors: shape().isRequired,
};

export default StepIconPhone;
