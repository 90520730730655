import React from 'react';
import { string, shape } from 'prop-types';

const SvgComponent = ({ className, colors }) => (
  <svg viewBox="0 0 400 400" className={className}>
    <path
      d="m200 400c110.46 0 200-89.54 200-200s-89.54-200-200-200-200 89.54-200 200 89.54 200 200 200z"
      fill={colors.brand.primary}
      fillRule="evenodd"
    />
    <g fill="#fff">
      <path d="m302.85 245.5h20.65v49h-20.65z" />
      <path d="m302.85 175.5h20.65v49h-20.65z" />
      <path d="m302.85 105.49h20.65v49h-20.65z" />
      <path d="m76.5 245.5h20.65v49h-20.65z" />
      <path d="m76.5 175.5h20.65v49h-20.65z" />
      <path d="m76.5 105.49h20.65v49h-20.65z" />
      <path d="m200 117.16a82.84 82.84 0 1 0 82.84 82.84 82.84 82.84 0 0 0 -82.84-82.84zm-11.66 142a5 5 0 0 1 -6.42 4.85 66.62 66.62 0 0 1 -42.37-36.32 5 5 0 0 1 4.57-7.17h22.17a22 22 0 0 1 22.05 22zm11.66-44.67a9.93 9.93 0 1 1 9.93-9.93 9.93 9.93 0 0 1 -9.93 9.93zm61.13 13.07a66.58 66.58 0 0 1 -44.54 37 5 5 0 0 1 -6.19-4.91v-17.1a22 22 0 0 1 22-22h24.07a5 5 0 0 1 4.66 7.01zm-1.35-29.56-30.31-12.24a78.72 78.72 0 0 0 -58.93 0l-30.31 12.24a5.05 5.05 0 0 1 -6.23-5.5 66.47 66.47 0 0 1 132.08 0 5.06 5.06 0 0 1 -6.3 5.5z" />
    </g>
  </svg>
);

SvgComponent.propTypes = {
  className: string,
  colors: shape(),
};

export default SvgComponent;
