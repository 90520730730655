import React, { useEffect, useContext, useState, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { shape } from 'prop-types';
import {
  SubNavigation,
  BorderedTitle,
  Text,
  OrderTable,
  Label,
  BackButton,
  RiskObjectLabel,
  PhaseLabel,
  ActionButton,
  LoadingSpinner,
  IconHeavyScooter,
  IconCarFilled,
  IconStats,
  IconFamilyFilled,
  IconPropertyFilled,
  IconLegalFilled,
} from 'wg-fe-ui';
import {
  getBrokerDistribution,
  getBrokerData,
} from '../../services/brokerDataService';
import { getMeData } from '../../services/meDataService';
import {
  getProspectById,
  getLeadsByProspectId,
  getOffersByProspectId,
  getContractsByProspectId,
  getBrokersByDistributionId,
} from '../../services/apiRouterService';
import { useTranslation } from 'react-i18next';
import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc.jsx';

const CRMCustomerContracts = ({ match }) => {
  const refs = {};
  const { setNavTabs } = useContext(TopNavigationItems);
  const { t } = useTranslation();
  const configCatClient = useContext(FlagsProvider);
  let history = useHistory();
  const location = useLocation();

  const [retrievedData, setRetrievedData] = useState({});
  const [prospectLeads, setProspectLeads] = useState([]);
  const [prospectOffers, setProspectOffers] = useState([]);
  const [isProfFlowEnabled, setIsProfFlowEnabled] = useState(false);
  const [prospectContracts, setProspectContracts] = useState([]);
  const [prospectName, setProspectName] = useState('Prospect');
  const [brokers, setBrokers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isContractsEnabled, setIsContractsEnabled] = useState(null);

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  const [sections, setSections] = useState([
    {
      id: 1,
      label: t('Louise Wegroup'),
      subsections: [
        {
          id: 1,
          label: t('Leads'),
        },
        {
          id: 2,
          label: t('Offers'),
        },
        {
          id: 3,
          label: t('Contracts'),
        },
      ],
    },
  ]);

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const clickLeadHandler = (e, _leadId) => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/customer/${match.params.prospectId}/contracts/lead/${_leadId}`,
      state: { ...location.state },
    });
  };

  const clickOfferHandler = (e, _offerId, _revisionId) => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/customer/${match.params.prospectId}/contracts/offer/${_offerId}/${_revisionId}`,
      state: { ...location.state },
    });
  };

  const clickContractHandler = (e, _caseId, _contractId) => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/customer/${match.params.prospectId}/contracts/case/${_caseId}/contract/${_contractId}`,
      state: { ...location.state },
    });
  };

  const retrieveBrokersFromDistribution = async prospectId => {
    const { id } = await getBrokerDistribution();
    const [resp, status] = await getBrokersByDistributionId(id);
    console.log(resp, status, brokers);
    const sortedBrokers = resp.items
      .filter(item => item.broker.plan !== 'CALLANT')
      .sort((b1, b2) => {
        if (b1?.broker?.name > b2?.broker?.name) return 1;
        if (b1?.broker?.name < b2?.broker?.name) return -1;
        return 0;
      });
    setBrokers(sortedBrokers);
  };

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const startNewTarrification = () => {
    history.push(
      `/professional-flow/new-tarrification-prospect/?prospectId=${retrievedData?.id}`,
    );
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  useEffect(() => {
    if (isContractsEnabled === null) setContractsAccess();
    else {
      getProspect(match.params.prospectId);
      getLeads(match.params.prospectId);
      getOffers(match.params.prospectId);
      if (isContractsEnabled) {
        getContracts(match.params.prospectId);
      }
      retrieveBrokersFromDistribution(match.params.prospectId);

      setNavTabs(
        setTabItems(
          match.params.prospectId,
          t,
          isContractsEnabled ? 'prospectDetailWithContracts' : 'prospectDetail',
          null,
          null,
          { ...location.state },
        ),
      );

      window.addEventListener('scroll', handleScroll, true);
      return () => window.removeEventListener('scroll', handleScroll, true);
    }
  }, [match.params.prospectId, isContractsEnabled]);

  async function getProspect(prospectId) {
    const { id } = await getBrokerDistribution();
    let [response, status] = await getProspectById(id, prospectId);

    if (status !== 200) {
      return console.error(response);
    }

    const { prospect } = response;
    setRetrievedData(prospect);
    setIsLoading(!isLoading);
  }

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  async function setContractsAccess() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};
    console.log('!!!!!');
    setIsContractsEnabled(
      await configCatClient.getValueAsync('contracts', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  useEffect(() => {
    if (isContractsEnabled === null) setContractsAccess();
    else {
      if (retrievedData?.type === 'CUSTOMER') {
        setProspectName(
          `${retrievedData.first_name} ${retrievedData.last_name}`,
        );
      } else {
        setProspectName(`${retrievedData.name}`);
      }

      const subsections = [
        {
          id: 1,
          label: t('Leads'),
        },
        {
          id: 2,
          label: t('Offers'),
        },
      ];

      if (isContractsEnabled) {
        subsections.push({
          id: 3,
          label: t('Contracts'),
        });
      }

      setSections([
        {
          id: 1,
          label: prospectName,
          subsections,
        },
      ]);
    }
  }, [retrievedData, prospectName, isContractsEnabled]);

  async function getLeads(prospectId) {
    const { id } = await getBrokerDistribution();
    let [response, status] = await getLeadsByProspectId(id, prospectId);

    if (status !== 200) {
      return console.error(response);
    }

    console.log('Leads: ', response);
    setProspectLeads(response.items);
  }

  async function getOffers(prospectId) {
    const { id } = await getBrokerDistribution();
    let [response, status] = await getOffersByProspectId(id, prospectId);

    if (status !== 200) {
      return console.error(response);
    }

    console.log('Offers: ', response);
    setProspectOffers(response.items);
  }

  async function getContracts(prospectId) {
    const { id } = await getBrokerDistribution();
    let [response, status] = await getContractsByProspectId(id, prospectId);

    if (status !== 200) {
      return console.error(response);
    }

    console.log('Contracts: ', response);
    setProspectContracts(response.items);
  }

  const getRiskObjectIcon = key => {
    switch (key) {
      case 'cars':
        return <IconCarFilled />;
      case 'families':
        return <IconFamilyFilled />;
      case 'residences':
        return <IconPropertyFilled />;
      case 'teachers':
        return <IconStats />;
      case 'two_wheelers':
        return <IconHeavyScooter />;
      case 'legal':
        return <IconLegalFilled />;
      default:
        return '';
    }
  };

  const checkValuesInObjectIsZero = object => {
    if (object != null || undefined) {
      return Object.keys(object).every(function(key) {
        return object[key] === 0;
      });
    }
  };

  const backButtonHandler = e => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/customers/${location?.state?.page || 1}`,
      state: {
        ...location.state,
      },
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            {' '}
            <BackButton
              name={t('Portfolio')}
              onClick={e => backButtonHandler(e)}
              data-test-id="customer_detail_prospect_btn_back"
            />
          </TopBarLeft>

          <TopBarRight>
            <TopBarAction hidden={!isProfFlowEnabled}>
              <ActionButton
                onClick={startNewTarrification}
                disabled={!isProfFlowEnabled}
              >
                {t('New tarification')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
        </Sidebar>

        <Content>
          <section ref={refs[1][1]}>
            <BorderedTitle>{t('Leads')}</BorderedTitle>
            {prospectLeads.length > 0 ? (
              <OrderTable>
                <OrderTable.Head otherProps={{}}>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Created on')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Assigned to')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Risk objects')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Origin')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Phase')}
                  </OrderTable.HeaderCell>
                </OrderTable.Head>
                <OrderTable.Body>
                  {prospectLeads.map(({ lead }) => {
                    return (
                      <OrderTable.Row
                        key={lead?.id}
                        onClick={e => clickLeadHandler(e, lead?.id)}
                      >
                        <OrderTable.BodyCell>
                          {formatDate(lead?.created_at)}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          {lead?.assigned_to
                            ? brokers?.length > 0
                              ? brokers?.find(
                                  item =>
                                    item?.broker?.id === lead?.assigned_to,
                                ).broker.name
                              : t('loading')
                            : '-'}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <RiskObjectsWrapper>
                            {!checkValuesInObjectIsZero(
                              lead?.amount_of_risk_objects,
                            ) ? (
                              Object.keys(lead?.amount_of_risk_objects).map(
                                key => {
                                  if (lead?.amount_of_risk_objects[key] === 0)
                                    return null;

                                  return (
                                    <RiskObjectLabel
                                      key={key}
                                      amount={lead?.amount_of_risk_objects[key]}
                                      icon={getRiskObjectIcon(key)}
                                    />
                                  );
                                },
                              )
                            ) : (
                              <RiskObjectLabel
                                key="legal"
                                amount="1"
                                icon={getRiskObjectIcon('legal')}
                              />
                            )}
                          </RiskObjectsWrapper>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          {lead?.origin ? lead?.origin : '-'}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <PhaseLabel
                            otherProps={{}}
                            status={lead?.status}
                            text={t(`leads.${lead?.status}`)}
                          />
                        </OrderTable.BodyCell>
                      </OrderTable.Row>
                    );
                  })}
                </OrderTable.Body>
              </OrderTable>
            ) : (
              <TextCenter>{t('There are no leads for this client')}</TextCenter>
            )}

            <CategorySpacer />
          </section>

          <section ref={refs[1][2]}>
            <BorderedTitle>{t('Offers')}</BorderedTitle>
            {prospectOffers.length > 0 ? (
              <OrderTable>
                <OrderTable.Head otherProps={{}}>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Ref no')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Created on')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Assigned to')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Risk objects')}
                  </OrderTable.HeaderCell>
                  <OrderTable.HeaderCell isTitle={false} width="20%">
                    {t('Phase')}
                  </OrderTable.HeaderCell>
                </OrderTable.Head>
                <OrderTable.Body>
                  {prospectOffers.map((offer, i) => {
                    return (
                      <OrderTable.Row
                        key={offer?.id}
                        onClick={e =>
                          clickOfferHandler(e, offer?.id, offer?.revision)
                        }
                        data-test-id={`offer_${i}_btn`}
                      >
                        <OrderTable.BodyCell>
                          <IdLabel>{`${offer?.ref}/0${offer?.revision}`}</IdLabel>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          {formatDate(offer?.created_at)}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          {offer?.assigned_to
                            ? brokers?.length > 0
                              ? brokers?.find(
                                  item =>
                                    item?.broker?.id === offer?.assigned_to,
                                ).broker?.name
                              : t('loading')
                            : '-'}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <RiskObjectsWrapper>
                            {!checkValuesInObjectIsZero(
                              offer?.amount_of_risk_objects,
                            ) ? (
                              Object.keys(offer?.amount_of_risk_objects).map(
                                key => {
                                  if (offer?.amount_of_risk_objects[key] === 0)
                                    return null;

                                  return (
                                    <RiskObjectLabel
                                      key={key}
                                      amount={
                                        offer?.amount_of_risk_objects[key]
                                      }
                                      icon={getRiskObjectIcon(key)}
                                    />
                                  );
                                },
                              )
                            ) : (
                              <RiskObjectLabel
                                key="legal"
                                amount="1"
                                icon={getRiskObjectIcon('legal')}
                              />
                            )}
                          </RiskObjectsWrapper>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <PhaseLabel
                            otherProps={{}}
                            status={offer?.status}
                            text={t(`offers.${offer?.status}`)}
                          />
                        </OrderTable.BodyCell>
                      </OrderTable.Row>
                    );
                  })}
                </OrderTable.Body>
              </OrderTable>
            ) : (
              <TextCenter>
                {t('There are no offers for this client')}
              </TextCenter>
            )}
            <CategorySpacer />
          </section>

          {isContractsEnabled ? (
            <section ref={refs[1][3]}>
              <BorderedTitle>{t('Contracts')}</BorderedTitle>
              {prospectContracts.length > 0 ? (
                <OrderTable>
                  <OrderTable.Head otherProps={{}}>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {t('Policy nr')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {t('Created on')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {t('Assigned to')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {t('Risk objects')}
                    </OrderTable.HeaderCell>
                    <OrderTable.HeaderCell isTitle={false} width="20%">
                      {t('Phase')}
                    </OrderTable.HeaderCell>
                  </OrderTable.Head>
                  <OrderTable.Body>
                    {prospectContracts.map(contract => {
                      return (
                        <OrderTable.Row
                          key={contract?.id}
                          onClick={e =>
                            clickContractHandler(
                              e,
                              contract?.contract_case_id,
                              contract?.id,
                            )
                          }
                        >
                          <OrderTable.BodyCell>
                            <Label>{`${contract?.policy_nr}`}</Label>
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            {formatDate(contract?.created_at)}
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            {contract?.assigned_to
                              ? brokers?.length > 0
                                ? brokers?.find(
                                    item =>
                                      item?.broker?.id ===
                                      contract?.assigned_to,
                                  ).broker?.name
                                : t('loading')
                              : '-'}
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            <RiskObjectsWrapper>
                              {!checkValuesInObjectIsZero(
                                contract?.amount_of_risk_objects,
                              ) ? (
                                Object.keys(
                                  contract?.amount_of_risk_objects,
                                ).map(key => {
                                  if (
                                    contract?.amount_of_risk_objects[key] === 0
                                  )
                                    return null;

                                  return (
                                    <RiskObjectLabel
                                      key={key}
                                      amount={
                                        contract?.amount_of_risk_objects[key]
                                      }
                                      icon={getRiskObjectIcon(key)}
                                    />
                                  );
                                })
                              ) : (
                                <RiskObjectLabel
                                  key="legal"
                                  amount="1"
                                  icon={getRiskObjectIcon('legal')}
                                />
                              )}
                            </RiskObjectsWrapper>
                          </OrderTable.BodyCell>
                          <OrderTable.BodyCell>
                            <PhaseLabel
                              otherProps={{}}
                              status={contract?.status}
                              text={t(`contracts.${contract?.status}`)}
                            />
                          </OrderTable.BodyCell>
                        </OrderTable.Row>
                      );
                    })}
                  </OrderTable.Body>
                </OrderTable>
              ) : (
                <TextCenter>
                  {t('There are no contracts for this client')}
                </TextCenter>
              )}
            </section>
          ) : null}
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 99999;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 100rem;
`;

const IdLabel = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.typo.interactive};
  text-align: left;
`;

const TextCenter = styled(Text)`
  text-align: center;
`;

const CategorySpacer = styled.div`
  height: 35px;
`;

const RiskObjectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex: 0 0 auto;
    margin-right: 15px;
  }
`;

CRMCustomerContracts.propTypes = {
  match: shape(),
};

export default CRMCustomerContracts;
