import React from 'react';
import { string, bool, func } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ThemePreviewCard from './ThemePreviewCard';
import { retrieveAllThemes } from '../../../services/themingService';

const ThemeSelector = ({ themeName, disabled, handleChange }) => {
  const { t } = useTranslation();
  const themes = retrieveAllThemes();
  delete themes.hillewaereBlue;

  const handleClick = e => {
    if (disabled) return;
    handleChange({ name: 'theme', value: e.target.name });
  };
  console.log(themes, themeName);
  return (
    <Container disabled={disabled}>
      {Object.keys(themes).map((themeKey, key) => {
        const currentTheme = themes[themeKey];
        return (
          <div key={key}>
            <input
              onClick={e => handleClick(e)}
              type="radio"
              name={themeKey}
              data-test-id={`dashboard_settings_customization_theme_${themeKey}`}
              id={themeKey}
              selected={themeName === themeKey}
              disabled={disabled}
            />
            <StyledLabel
              selected={themeName === themeKey}
              htmlFor={themeKey}
              disabled={disabled}
            >
              <div className="textContainer">
                <RadioCircle
                  theme={themes[themeName]}
                  selected={themeName === themeKey}
                />
                <p>{t(themeKey)}</p>
              </div>
              <ThemePreviewCard colors={currentTheme} />
            </StyledLabel>
          </div>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin-right: 2rem;
    margin-bottom: 2rem;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    opacity: ${props => (props.disabled ? '.5' : '1')};
  }

  input {
    display: none;
    cursor: pointer;
    :disabled {
      cursor: pointer;
    }
  }

  .textContainer {
    display: flex;
    margin-bottom: 1rem;
  }
`;

const StyledLabel = styled.label`
  color: #5b5550;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`;

const RadioCircle = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  position: relative;
  border-radius: 1.5rem;
  margin-right: 1rem;
  background-color: ${({ theme, selected }) =>
    selected ? theme?.brand?.primary : 'white'};
  border: 0.1rem solid ${({ theme }) => theme?.typo?.interactive};
  border: ${props =>
    props.selected
      ? 'none'
      : `0.1rem solid ${({ theme }) => theme?.typo?.interactive}`};

  &::after {
    content: '';
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    left: 0;
    transform: scale(0.4);
    position: absolute;
    border-radius: 0.7rem;
    display: inline-block;
    background-color: white;
    margin: auto;
  }
`;

ThemeSelector.defaultProps = {
  disabled: false,
  themeName: 'orange',
  handleChange: () => {},
};

ThemeSelector.propTypes = {
  themeName: string,
  disabled: bool,
  handleChange: func,
};

export default ThemeSelector;
