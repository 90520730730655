import React, { useEffect, useState, createRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { shape } from 'prop-types';
import {
  SubNavigation,
  // Label,
  // Title,
  ActionButton,
  // Section,
  // IconActionPhone,
  // IconActionMessage,
  // IconCarFilled,
  // Text,
  SubTitle,
  // RiskObjectBar,
  // AddEntityButton,
  // TextArea,
  // CommentBox,
  // CheckBox,
  BackButton,
  LoadingSpinner,
} from 'wg-fe-ui';

import { useTranslation } from 'react-i18next';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc.jsx';
import Frame from '../../../assets/images/Frame.svg';

import {
  getBrokerDistribution,
  getBrokerData,
} from '../../services/brokerDataService';
import { getMeData } from '../../services/meDataService';
// import { getBrokerCRMName } from '../services/brokerDataService';
import {
  getProspectById,
  // appendNoteToLead,
} from '../../services/apiRouterService';
import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';
import { formatCRMAddress } from '../../services/CRMService';
import CRMRiskObjectTabs from '../../components/CRMRiskObjectTabs';
// import { formatISODate } from '../services/dateService';
import CRMProspectClientData from '../../components/CRMProspectClientData';
import { useLocation } from 'react-router';

// import * as Yup from 'yup';
// import { string } from '../services/validationSchemas';
// import useForm from '../hooks/useForm';
// import { IconDocumentFilled } from 'wg-fe-ui/dist/components/Icons';

// const mockNotes = [
//   {
//     id: 1,
//     private: false,
//     created_at: '27/02/2020',
//     author: 'Schauwers P.',
//     text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet eget lectus curabitur neque. Adipiscing donec eu turpis facilisis. Id mauris non est vitae nibh. Proin blandit nisl malesuada tellus cursus. Lorem risus, cras cras cras rutrum. Ut elementum aliquam vivamus tristique ut cursus nibh.`,
//   },
//   {
//     id: 1337,
//     private: false,
//     created_at: '31/12/2019',
//     author: 'Israël A.',
//     text: `Phasellus ullamcorper ipsum rutrum nunc. Praesent egestas tristique nibh. Fusce risus nisl, viverra et, tempor et, pretium in, sapien. Nulla consequat massa quis enim. Sed magna purus, fermentum eu, tincidunt eu, varius ut, felis.`,
//   },
// ];

const CRMCustomerDetail = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { setNavTabs } = useContext(TopNavigationItems);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const refs = {};

  const [retrievedData, setRetrievedData] = useState({});
  const [prospectName, setProspectName] = useState('Prospect');
  const [prospectAddress, setProspectAddress] = useState('-');
  const [registeryNumber, setRegisteryNumber] = useState();
  const [isProfFlowEnabled, setIsProfFlowEnabled] = useState(false);
  const [distributionId, setDistributionId] = useState();
  const [cbeNumber, setCBENumber] = useState();
  // const [brokerCRMName, setBrokerCRMName] = useState();
  const configCatClient = useContext(FlagsProvider);
  const [isContractsEnabled, setIsContractsEnabled] = useState(null);
  const [
    canEditProspectsAndRiskObjects,
    setCanEditProspectsAndRiskObjects,
  ] = useState(false);
  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });
  // const [Notes, setNotes] = useState(mockNotes);

  const [sections, setSections] = useState([
    {
      id: 1,
      label: prospectName,
      subsections: [
        {
          id: 1,
          label: t('Prospect.Client information'),
        },
        {
          id: 2,
          label: t('Risk objects'),
        },
        // {
        //   id: 3,
        //   label: t('Additional notes'),
        // },
      ],
    },
  ]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  }, []);

  async function setFeatureFlags() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    setIsContractsEnabled(
      await configCatClient.getValueAsync('contracts', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    setCanEditProspectsAndRiskObjects(
      await configCatClient.getValueAsync(
        'canEditProspectsAndRiskObjects',
        false,
        {
          email: me?.email,
          custom: {
            plan: broker?.plan,
          },
        },
      ),
    );
  }

  // useEffect(() => {
  //   retrieveBrokerCRMName();
  // }, []);

  // async function retrieveBrokerCRMName() {
  //   const _brokerCRMName = await getBrokerCRMName();
  //   setBrokerCRMName(_brokerCRMName);
  // }

  useEffect(() => {
    if (isContractsEnabled === null) setFeatureFlags();
    else {
      getProspect(match.params.prospectId);
      setNavTabs(
        setTabItems(
          match.params.prospectId,
          t,
          isContractsEnabled ? 'prospectDetailWithContracts' : 'prospectDetail',
          null,
          null,
          { ...location.state },
        ),
      );
    }
  }, [match.params.prospectId, isContractsEnabled]);

  useEffect(() => {
    console.log('Retrieved Data:', retrievedData);

    switch (retrievedData?.type) {
      case 'CUSTOMER':
        setProspectName(
          `${retrievedData.first_name} ${retrievedData.last_name}`,
        );
        setRegisteryNumber(retrievedData.national_register_nr);
        break;
      case 'COMPANY':
        setProspectName(`${retrievedData.name}`);
        setCBENumber(retrievedData.cbe);
        break;
      default:
        break;
    }

    setSections([
      {
        id: 1,
        label: prospectName,
        subsections: [
          {
            id: 1,
            label: t('Prospect.Client information'),
          },
          {
            id: 2,
            label: t('Risk objects'),
          },
          // {
          //   id: 3,
          //   label: t('Additional notes'),
          // },
        ],
      },
    ]);

    if (retrievedData?.address != null) {
      setProspectAddress(formatCRMAddress(retrievedData?.address));
    }
  }, [
    retrievedData,
    prospectName,
    prospectAddress,
    registeryNumber,
    cbeNumber,
  ]);

  const startNewTarrification = () => {
    history.push(
      `/professional-flow/new-tarrification-prospect/?prospectId=${retrievedData?.id}`,
    );
  };

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  async function getProspect(prospectId) {
    const { id } = await getBrokerDistribution();
    setDistributionId(id);
    let [response, status] = await getProspectById(id, prospectId);

    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      setIsLoading(!isLoading);
      setRetrievedData(null);
      return;
    }

    const { prospect } = response;
    setRetrievedData(prospect);
    setIsLoading(!isLoading);
  }

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const backButtonHandler = e => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/customers/${location?.state?.page || 1}`,
      state: {
        ...location.state,
      },
    });
  };

  // const [CommentSchema] = useState(
  //   Yup.object().shape({
  //     comment: string({ required: t('required') }).required,
  //   }),
  // );

  // const [TopSearchValue, setTopSearchValue] = useState('');

  // const handleTopSearch = e => {
  //   setTopSearchValue(e.target.value);
  // };

  // const getCurrentDate = () => {
  //   const today = new Date();
  //   const day = String(today.getDate()).padStart(2, '0');
  //   const month = String(today.getMonth() + 1).padStart(2, '0');
  //   const year = today.getFullYear();

  //   const date = day + '/' + month + '/' + year;
  //   return date;
  // };

  // const { handleChange, errors, handleSubmit, values, resetForm } = useForm({
  //   validationSchema: CommentSchema,
  //   change: () => {},
  // });

  // function formSubmitHandler(e) {
  //   e.preventDefault();
  //   handleSubmit(handleFormValues);
  // }

  // const handleFormValues = formValues => {
  //   setNotes(Notes => [
  //     {
  //       id: 3,
  //       created_at: getCurrentDate(),
  //       private: formValues.private,
  //       author: 'Current user',
  //       text: formValues.comment,
  //     },
  //     ...Notes,
  //   ]);

  //   resetForm();
  //   values.private = false;
  // };

  // const handlePrivateChange = () => {
  //   values.private = !values.private;
  // };

  // const checkArraysInObjectIsEmpty = object => {
  //   if (object != null || undefined) {
  //     return (
  //       Object.keys(object).filter(function(key) {
  //         return object[key] > 0;
  //       }).length === 0
  //     );
  //   }
  // };

  // const checkArraysInObjectIsEmpty = object => {
  //   if (object != null || undefined) {
  //     return Object.keys(object).filter(function(key) {
  //       return object[key].value === 0;
  //     });
  //   }
  // };

  if (isLoading) {
    return (
      <LoadingContainer data-test-id="loading_spinner">
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (retrievedData == null) {
    return (
      <EmptyState data-test-id="empty_state">
        <img src={Frame} alt="Test" />
        <h1>{t('Prospect not found')}</h1>
        <p>
          {`${t('We could not find the prospect with id')}: `}{' '}
          <strong> {match.params.prospectId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton
              name={t('Portfolio')}
              onClick={e => backButtonHandler(e)}
              data-test-id="customer_detail_prospect_btn_back"
            />
          </TopBarLeft>

          <TopBarRight>
            <TopBarAction hidden={!isProfFlowEnabled}>
              <ActionButton
                onClick={startNewTarrification}
                disabled={!isProfFlowEnabled}
                data-test-id="customer_detail_prospect_btn_new_tarrification"
              >
                {t('New tarification')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}

          {/* <ActionButton
            level="secondary"
            onClick={function noRefCheck() {}}
            // disabled={brokerCRMName === null}
            disabled
          >
            {brokerCRMName
              ? `${t(`Export to`)} ${brokerCRMName}`
              : t(`No crm found`)}
          </ActionButton> */}
        </Sidebar>

        <Content>
          <section ref={refs[1][1]}>
            <CRMProspectClientData
              prospectData={retrievedData}
              distributionId={distributionId}
              disableEdit={!canEditProspectsAndRiskObjects}
            />
          </section>

          <section ref={refs[1][2]}>
            <SubTitle>{t('Risk objects')}</SubTitle>
            <CRMRiskObjectTabs
              distributionId={distributionId}
              prospectId={match.params.prospectId}
              riskObjects={retrievedData.amount_of_risk_objects}
              type="prospects"
            />
          </section>

          {/* <Section>
            <Section.Title>Documents</Section.Title>

            <Section.Content background>
              <Documents>
                <DownloadBox
                  filename="Filename.ext"
                  href="#"
                  thumbnail="https://picsum.photos/100"
                />

                <DownloadBox
                  filename="Filename.ext"
                  href="#"
                  thumbnail="https://picsum.photos/105"
                />

                <DownloadBox
                  filename="Filename.ext"
                  href="#"
                  thumbnail="https://picsum.photos/110"
                />
              </Documents>
            </Section.Content>
          </Section> */}
          {/* <section ref={refs[1][3]}>
            <Section>
              <Section.Title>Sticky notes</Section.Title>

              <Section.Content background>
                <form onSubmit={formSubmitHandler}>
                  <TextArea
                    maxLength={-1}
                    name="comment"
                    error={errors.comment}
                    onChange={val => handleChange(val)}
                    value={values.comment ? values.comment : ''}
                  >
                    Add a new note
                  </TextArea>

                  <NoteButtonWrapper>
                    <CheckBox
                      name="private"
                      onChange={handlePrivateChange}
                      checked={values.private}
                    >
                      Private note?
                    </CheckBox>

                    <ActionButton
                      type="submit"
                      value="Submit"
                      disabled={errors.comment ? true : false}
                    >
                      {t('Add a new note')}
                    </ActionButton>
                  </NoteButtonWrapper>
                </form>

                {Notes.map(note => {
                  return (
                    <>
                      <Divider />

                      <CommentBox
                        icon={<IconDocumentFilled color="#ccc" />}
                        id={note.id}
                        title={`${note.created_at} - Added by ${note.author}`}
                        key={note.id}
                      >
                        {note.text}
                      </CommentBox>
                    </>
                  );
                })}
              </Section.Content>
            </Section>
          </section> */}
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 99999;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    margin-right: 0.78vw;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

// const Documents = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: space-between;
//   align-items: center;
//   flex-wrap: wrap;

//   > div {
//     flex: 0 0 33%;
//   }
// `;

// const NoteButtonWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   margin-top: 20px;

//   > button {
//     margin-left: 35px;
//   }
// `;

// const Divider = styled.div`
//   width: 100%;
//   height: 1px;
//   background-color: ${({ theme }) => theme.ui.outline};
//   margin: 25px 0;
// `;

CRMCustomerDetail.propTypes = {
  match: shape(),
};

export default CRMCustomerDetail;
