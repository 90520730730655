import React from 'react';
import { useHistory } from 'react-router-dom';
import { shape, string, bool } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  RiskObjectBar,
  IconCarFilled,
  IconVanFilled,
  IconPropertyFilled,
  IconFamilyFilled,
  IconHeavyScooter,
  IconLightScooter,
  IconBikeFilled,
  IconMotor,
  IconStats,
  QuickActionSubMenu,
} from 'wg-fe-ui';

import { formatCRMAddress } from '../services/CRMService';
import { isObjectEmpty, sumObjectValues } from '../services/objectService';
import { useLocation } from 'react-router';

const CRMRiskObjectTabPage = ({
  riskObjects,
  riskType,
  offerId,
  offerRevision,
  prospectId,
  contractId,
  contractCaseId,
  disableEdit,
  contractState,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const riskObjClickHandler = (e, riskType, id, revision) => {
    e.preventDefault();
    let pathname = '';
    let state = { ...location.state };
    if (prospectId && offerId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}/risk-object/${riskType}/${id}/${revision}`;
    } else if (contractId && prospectId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}/risk-object/${riskType}/${id}/${revision}`;
      state = {
        ...state,
        ...contractState,
      };
    } else if (contractId) {
      pathname = `/sales-management/case/${contractCaseId}/contract/${contractId}/risk-object/${riskType}/${id}/${revision}`;
      state = {
        ...state,
        ...contractState,
      };
    } else {
      pathname = `/sales-management/offer/${offerId}/${offerRevision}/risk-object/${riskType}/${id}/${revision}`;
    }
    history.push({
      pathname,
      state,
    });
  };

  const riskObjEditHandler = (e, riskType, id, revision) => {
    e.preventDefault();
    let state = { ...location.state };
    let pathname = '';
    if (prospectId && offerId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}/risk-object/${riskType}/${id}/${revision}/edit`;
    } else if (contractId && prospectId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}/risk-object/${riskType}/${id}/${revision}/edit`;
      state = {
        ...state,
        ...contractState,
      };
    } else if (contractId) {
      pathname = `/sales-management/case/${contractCaseId}/contract/${contractId}/risk-object/${riskType}/${id}/${revision}/edit`;
      state = {
        ...state,
        ...contractState,
      };
    } else {
      pathname = `/sales-management/offer/${offerId}/${offerRevision}/risk-object/${riskType}/${id}/${revision}/edit`;
    }
    history.push({
      pathname,
      state,
    });
  };

  function handleIconType(vehicleIconType) {
    const color = '#CCCCCC';
    switch (vehicleIconType) {
      case 'PASSENGER_CAR':
        return <IconCarFilled color={color} />;
      case 'LIGHT_COMMERCIAL_VEHICLE':
        return <IconVanFilled color={color} />;
      case 'MOTORCYCLE':
        return <IconMotor color={color} />;
      case 'LIGHT_SCOOTER':
        return <IconLightScooter color={color} />;
      case 'HEAVY_SCOOTER':
        return <IconHeavyScooter color={color} />;
      case 'MOPED':
        return <IconBikeFilled color={color} />;
      default:
        return <IconCarFilled color={color} />;
    }
  }

  return (
    <RiskObjectContainer
      data-test-id={`customer_detail_offer_risk_${riskType}`}
    >
      {riskObjects !== undefined && riskObjects.length > 0
        ? riskObjects.map(item => {
            switch (riskType) {
              case 'cars':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={handleIconType(item?.vehicle_type)}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'car',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={`${item?.brand} ${item?.model}`}
                        label={t('Vehicles.Vehicle')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.number_plate ? item?.number_plate : '-'}
                        label={t('Vehicles.License plate')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.kw}
                        label={t('Vehicles.Power')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.value
                            ? item?.value.toLocaleString('nl-BE', {
                                style: 'currency',
                                currency: 'EUR',
                              })
                            : '-'
                        }
                        label={t('Vehicles.Catalogue value')}
                      />
                      <ActionMenuWrapper>
                        <QuickActionSubMenu
                          onClick={e => e.stopPropagation()}
                          otherProps={{}}
                        >
                          <QuickActionSubMenu.SubMenuItem
                            label={t('View vehicle')}
                            onClick={e =>
                              riskObjClickHandler(
                                e,
                                'car',
                                item?.risk_object_id,
                                item?.risk_object_revision_id,
                              )
                            }
                            riskType=""
                          />
                          {!disableEdit ? (
                            <QuickActionSubMenu.SubMenuItem
                              label={t('Edit vehicle')}
                              onClick={e =>
                                riskObjEditHandler(
                                  e,
                                  'car',
                                  item?.risk_object_id,
                                  item?.risk_object_revision_id,
                                )
                              }
                              type=""
                            />
                          ) : null}
                        </QuickActionSubMenu>
                      </ActionMenuWrapper>
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              case 'two_wheelers':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={handleIconType(item?.vehicle_type)}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'two_wheeler',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={`${item?.brand} ${item?.model}`}
                        label={t(`Vehicles.Vehicle`)}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.number_plate ? item?.number_plate : '-'}
                        label={t('Vehicles.License plate')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.cc ? item?.cc : '-'}
                        label={t('Vehicles.Displacement')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.value
                            ? item?.value.toLocaleString('nl-BE', {
                                style: 'currency',
                                currency: 'EUR',
                              })
                            : '-'
                        }
                        label={t('Vehicles.Catalogue value')}
                      />
                      <ActionMenuWrapper>
                        <QuickActionSubMenu
                          onClick={e => e.stopPropagation()}
                          otherProps={{}}
                        >
                          <QuickActionSubMenu.SubMenuItem
                            label={t('View vehicle')}
                            onClick={e =>
                              riskObjClickHandler(
                                e,
                                'two_wheeler',
                                item?.risk_object_id,
                                item?.risk_object_revision_id,
                              )
                            }
                            type=""
                          />
                          <QuickActionSubMenu.SubMenuItem
                            label={t('Edit vehicle')}
                            onClick={e =>
                              riskObjEditHandler(
                                e,
                                'two_wheeler',
                                item?.risk_object_id,
                                item?.risk_object_revision_id,
                              )
                            }
                            type=""
                          />
                        </QuickActionSubMenu>
                      </ActionMenuWrapper>
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              case 'residences': {
                let address = '-';
                if (item?.address != null) {
                  address = formatCRMAddress(item?.address);
                }
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={<IconPropertyFilled color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'residence',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        label={t('Address')}
                        data={address}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        label={t('Holder state')}
                        data={t(item?.holder_state)}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        label={t('House type')}
                        data={t(item?.attachment)}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        label={t('Number of rooms')}
                        data={
                          !isObjectEmpty(item?.main_building?.rooms)
                            ? sumObjectValues(item?.main_building?.rooms)
                            : `-`
                        }
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              }
              case 'families':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={<IconFamilyFilled color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'family',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={t(item?.status)}
                        label={t('Household')}
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              case 'teachers':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={<IconStats color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'teacher',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.manipulates_heavy_machinery ? t('Yes') : t('No')
                        }
                        label={t('Operates heavy machinery')}
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              default:
                break;
            }
          })
        : null}
    </RiskObjectContainer>
  );
};

const ActionMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
  text-align: center;
`;

const RiskObjectContainer = styled.div``;

const RiskObjectSpacer = styled.div`
  height: 15px;
`;

const StyledRiskObjectBar = styled(RiskObjectBar)`
  :hover svg path {
    fill: ${({ theme }) => theme.brand.primary};
  }
`;

CRMRiskObjectTabPage.propTypes = {
  riskType: string.isRequired,
  riskObjects: shape({}).isRequired,
  offerId: string,
  prospectId: string,
  offerRevision: string,
  contractCaseId: string,
  contractId: string,
  offer: shape({}),
  contractState: shape({}),
  disableEdit: bool,
  contract: shape({}),
};

export default CRMRiskObjectTabPage;
