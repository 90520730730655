/* stylelint-disable no-descending-specificity, declaration-no-important, declaration-block-no-redundant-longhand-properties */
/* eslint-disable no-unused-expressions*/
// packages
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// components
import Layout from '../../../LayoutFlow/views/App';
import LeftSide from '../../../../../components/LeftSide';
import RightSide from '../../../../../components/RightSide';
import Button from '../../../../../components/Button';
import InfoPopUp from '../../../../../components/InfoPopUp';
import CheckBox from '../../../../../components/GlobalCheckBox';

//images
import DefaultCarIcon from '../../../../../../assets/images/DefaultCarIcon';
import suvcar from '../../../../../../assets/images/car/suv.svg';
import sportscar from '../../../../../../assets/images/car/sportsCar.svg';
import cabriocar from '../../../../../../assets/images/car/cabrio.svg';

// stores
import {
  getNextPath,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
  getCurrentTheme,
} from '../../../../../store/index';

import {
  getAllCarData,
  getPayloadCar,
  addCarData,
  addEditedCarExtra,
} from '../../store/index';

import InputBox from '../../../../../components/InputBox';
import DateInput from '../../../../../components/DateInputComponent';
import SelectInput from '../../../../../components/SelectInput';
import IconCheckbox from '../../../../../components/IconCheckBox';

const CheckCarFallback = ({ history }) => {
  const { t } = useTranslation();
  const [sendStep, setSendStep] = useState('initial');
  const [btnText, setBtnText] = useState(t(`CheckCarManual.btnText.default`));
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const carData = getAllCarData();
  const carData2 = getPayloadCar();
  const [selectedItems, setSelectedItems] = useState({});
  const [carExtras, setcarExtras] = useState({
    is_jeep: carData.is_jeep ? carData.is_jeep : false,
    is_cabrio: carData.is_cabrio ? carData.is_cabrio : false,
    is_sportscar: carData.is_sportscar ? carData.is_sportscar : false,
  });
  const [carTitle, setCarTitle] = useState({
    brand: carData.brand || '',
    model: carData.model || '',
  });

  const [motorData, setMotorData] = useState([]);
  const [btwButton, setBtwButton] = useState(true);
  const [btwText, setBtwText] = useState('');
  const [btwButtonInvoice, setBtwButtonInvoice] = useState(true);
  const [btwTextInvoice, setBtwTextInvoice] = useState('');
  const [btwButtonOptions, setBtwButtonOptions] = useState(true);
  const [btwTextOptions, setBtwTextOptions] = useState('');
  let inputBtw = '';
  const [initialFuel, setInitialFuel] = useState();

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    if (btwButton === true) {
      setBtwText(t(`CheckCar.button_vat_exclu`));
    } else {
      setBtwText(t(`CheckCar.button_vat_inclu`));
    }
  }, [btwButton]);

  useEffect(() => {
    if (btwButtonInvoice) {
      setBtwTextInvoice(t(`CheckCar.button_vat_exclu`));
    } else {
      setBtwTextInvoice(t(`CheckCar.button_vat_inclu`));
    }
  }, [btwButtonInvoice]);

  useEffect(() => {
    if (btwButtonOptions) {
      setBtwTextOptions(t(`CheckCar.button_vat_exclu`));
    } else {
      setBtwTextOptions(t(`CheckCar.button_vat_inclu`));
    }
  }, [btwButtonOptions]);

  let SignupSchema;
  let dateFormat = `DD-MM-YYYY`;
  let dateRegex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/;

  SignupSchema = Yup.object().shape({
    registration_first: Yup.string()
      .required(t(`CheckCar.errors.invalid_date`))
      .matches(dateRegex, t(`CheckCar.errors.invalid_date`))
      .test('DOB', t(`CheckCar.errors.invalid_date`), value =>
        moment(value, dateFormat).isValid(),
      )
      .test(
        'DOB',
        t(`CheckCar.errors.invalid_date`),
        value => moment().diff(moment(value, dateFormat), 'years') >= 0,
      )
      .test(
        'DOB',
        t(`CheckCar.errors.old`),
        value => moment().diff(moment(value, dateFormat), 'years') <= 120,
      ),
    model_year: Yup.string()
      .matches(/^\d{4}$/, t(`CheckCar.errors.invalid_date`))
      .nullable(),
    brand: Yup.string().required(t(`CheckCar.errors.required`)),
    model: Yup.string().required(t(`CheckCar.errors.required`)),
    kw: Yup.number().required(t(`CheckCar.errors.required`)),
    seats: Yup.number()
      .required(t(`CheckCar.errors.required`))
      .min(2, t(`CheckCar.errors.minSeats`))
      .max(10, t(`CheckCar.errors.maxSeats`)),
    value: Yup.string().when('onlyBa', {
      is: false,
      then: Yup.string()
        .required(t(`CheckCar.errors.required`))
        .matches(/^[0-9 _ ,]*$/, t(`CheckCar.errors.consists`))
        .test('value', t(`CheckCar.errors.notZero`), val => val !== 0)
        .test('length', t(`CheckCar.errors.minimumValue`), val =>
          val ? parseFloat(val.replace(',', '.').replace(' ', '')) >= 100 : 0,
        )
        .test('maxValue', t(`CheckCar.errors.maximumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) < 1000000
            : 0,
        ),
    }),
    invoice_value: Yup.string().when('onlyBa', {
      is: false,
      then: Yup.string()
        .matches(/^[0-9 _ ,]*$/, t(`CheckCar.errors.consists`))
        .test('value', t(`CheckCar.errors.notZero`), val =>
          val ? val !== 0 : !val,
        )
        .test('length', t(`CheckCar.errors.minimumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) >= 100
            : !val,
        )
        .test('maxValue', t(`CheckCar.errors.maximumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) < 1000000
            : !val,
        ),
    }),
    options_value: Yup.string().when('onlyBa', {
      is: false,
      then: Yup.string()
        .matches(/^[0-9 _ ,]*$/, t(`CheckCar.errors.consists`))
        .test('value', t(`CheckCar.errors.notZero`), val =>
          val ? val !== 0 : !val,
        )
        .test('length', t(`CheckCar.errors.minimumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) >= 100
            : !val,
        )
        .test('maxValue', t(`CheckCar.errors.maximumValue`), val =>
          val
            ? parseFloat(val.replace(',', '.').replace(' ', '')) < 1000000
            : !val,
        ),
    }),
    onlyBa: Yup.bool().isRequired,
  });

  useEffect(() => {
    let btnText;
    let errorMsg = null;

    switch (sendStep) {
      case 'pending':
        btnText = t(`CheckCarManual.btnText.pending`);
        setIsDisabled(true);
        break;
      case 'success':
        btnText = t(`CheckCarManual.btnText.success`);
        history.push(getNextPath('a'));
        break;
      case 'error':
        errorMsg = t(`CheckCarManual.errorMsg`);
        btnText = t(`CheckCarManual.btnText.error`);
        break;
      default:
        btnText = t(`CheckCarManual.btnText.default`);
        break;
    }
    setBtnText(btnText);
    setErrorMsg(errorMsg);
  }, [sendStep]);

  const clickHandler = values => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }

    Object.keys(selectedItems).forEach(key => {
      if (key === 'motor_type') {
        const data = getMotorTypes(selectedItems['motor']);
        const typeMotor = Object.keys(data).find(
          item => data[item] === selectedItems[key],
        );
        addCarData(key, typeMotor);
      }
    });
    const data = t(`ToolkitInfo.motor_types_manual`, {
      returnObjects: true,
    });
    const motor_type = Object.keys(data).find(
      key => data[key] === selectedItems.motor_type,
    );
    addCarData('motor_type', motor_type);
    addEditedCarExtra('motor_type');

    Object.keys(values).forEach(valueKey => {
      let value;
      if (
        valueKey === 'registration_first' ||
        (valueKey === 'registration_last' && values[valueKey])
      ) {
        value = values[valueKey]
          .split('-')
          .reverse()
          .join('-');
      } else {
        value = values[valueKey];
      }
      if (!values.onlyBa) {
        addCarData('onlyBa', false);
      }
      if (
        valueKey === 'registration_first' ||
        valueKey === 'registration_last' ||
        valueKey === 'onlyBa'
      ) {
        addCarData(valueKey, value);
      } else {
        if (carData[valueKey] !== value) {
          addEditedCarExtra(valueKey);
        }
        if (valueKey === 'value') {
          if (btwText === t(`CheckCar.button_vat_exclu`)) {
            addCarData(
              valueKey,
              parseFloat(value.replace(',', '.').replace(' ', '')),
            );
          } else {
            addCarData(
              valueKey,
              parseFloat(
                (value.replace(',', '.').replace(' ', '') / 1.21).toFixed(2),
              ),
            );
          }
        } else if (valueKey === 'invoice_value') {
          if (btwTextInvoice === t(`CheckCar.button_vat_exclu`)) {
            addCarData(
              valueKey,
              parseFloat(value.replace(',', '.').replace(' ', '')),
            );
          } else {
            addCarData(
              valueKey,
              parseFloat(
                (value.replace(',', '.').replace(' ', '') / 1.21).toFixed(2),
              ),
            );
          }
        } else if (valueKey === 'options_value') {
          if (btwTextOptions === t(`CheckCar.button_vat_exclu`)) {
            addCarData(
              valueKey,
              parseFloat(value.replace(',', '.').replace(' ', '')),
            );
          } else {
            addCarData(
              valueKey,
              parseFloat(
                (value.replace(',', '.').replace(' ', '') / 1.21).toFixed(2),
              ),
            );
          }
        } else {
          if (valueKey === 'model' || valueKey === 'brand') {
            if (carData[valueKey] !== value) {
              addCarData('name', `${values['brand']} ${values['model']}`);
              addCarData('img', undefined);
              addCarData('color_hex', undefined);
              addCarData('version', null);
              addEditedCarExtra('version');
            }
            if (valueKey === 'model') {
              addCarData(valueKey, values[valueKey]);
            } else if (valueKey === 'brand') {
              addCarData(valueKey, values[valueKey]);
            }
          } else {
            if (valueKey === 'model_year') {
              if (carData[valueKey] !== value) {
                addCarData('img', undefined);
                addCarData('color_hex', undefined);
              }
            }
            addCarData(
              valueKey,
              valueKey === 'kw' ||
                valueKey === 'model_year' ||
                valueKey === 'seats'
                ? parseInt(value)
                : value,
            );
          }
        }
      }
    });

    addChatboxQuestion(
      t(`CheckCar.questionFallback`, {
        brand: carData.brand,
        model: carData.model,
      }),
    );

    addChatboxAnswer(
      t(`CheckCar.interactiveMsg`, {
        registrationFirst: values['registration_first'],
        carValue: values['invoice_value']
          ? parseFloat(
              values['invoice_value'].replace(',', '.').replace(' ', ''),
            )
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
          : parseFloat(values['value'].replace(',', '.').replace(' ', ''))
              .toFixed(2)
              .replace('.', ',')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '),
        VAT:
          (values['value'] || values['invoice_value']) &&
          btwText === t(`CheckCar.button_vat_exclu`)
            ? t(`CheckCar.button_vat_exclu`).toLowerCase()
            : t(`CheckCar.button_vat_inclu`).toLowerCase(),
      }),
    );
    setSendStep('success');
  };

  const disableHandler = (errors, touched, values) => {
    if (values.kw && values.registration_first && selectedItems['motor_type']) {
      if (errors & touched) {
        if (
          Object.entries(errors).length === 0 &&
          Object.entries(touched).length
        ) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        if (Object.entries(errors).length === 0) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      }
    } else {
      setIsDisabled(true);
    }
  };

  const handleBTW = (id, setFieldValue) => {
    let input = parseFloat(
      document
        .querySelector(`#${id}`)
        .value.replace(',', '.')
        .replace(/ /g, ''), // Replace ALL spaces in the string instead of the first
    );
    if (!isNaN(input)) {
      if (id === 'value') {
        if (btwText === t(`CheckCar.button_vat_exclu`)) {
          setBtwButton(false);
          inputBtw = (input * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          if (carData2.vehicle_type === 'new') {
            addCarData({ value: input }, { invoice_value: null });
          } else {
            addCarData('value', parseFloat((input * 1.21).toFixed(2)));
          }
        } else {
          setBtwButton(true);
          inputBtw = (input / 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          if (carData2.vehicle_type === 'new') {
            addCarData(
              { value: parseFloat((input / 1.21).toFixed(2)) },
              { invoice_value: null },
            );
          } else {
            addCarData('value', parseFloat((input / 1.21).toFixed(2)));
          }
        }
      } else if (id === 'invoice_value') {
        if (btwTextInvoice === t(`CheckCar.button_vat_exclu`)) {
          setBtwButtonInvoice(false);
          inputBtw = (input * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData('invoice_value', parseFloat((input * 1.21).toFixed(2)));
        } else {
          setBtwButtonInvoice(true);
          inputBtw = (input / 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData({
            invoice_value: parseFloat((input / 1.21).toFixed(2)),
          });
        }
      } else if (id === 'options_value') {
        if (btwTextOptions === t(`CheckCar.button_vat_exclu`)) {
          setBtwButtonOptions(false);
          inputBtw = (input * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData('options_value', parseFloat((input * 1.21).toFixed(2)));
        } else {
          setBtwButtonOptions(true);
          inputBtw = (input / 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
          addCarData({
            options_value: parseFloat((input / 1.21).toFixed(2)),
          });
        }
      }
    } else {
      if (id === 'value') {
        inputBtw = '';
        if (btwText === t(`CheckCar.button_vat_exclu`)) {
          setBtwButton(false);
        } else {
          setBtwButton(true);
        }
      } else if (id === 'invoice_value') {
        inputBtw = '';
        if (btwTextInvoice === t(`CheckCar.button_vat_exclu`)) {
          setBtwButtonInvoice(false);
        } else {
          setBtwButtonInvoice(true);
        }
      } else if (id === 'options_value') {
        inputBtw = '';
        if (btwTextOptions === t(`CheckCar.button_vat_exclu`)) {
          setBtwButtonOptions(false);
        } else {
          setBtwButtonOptions(true);
        }
      }
    }
    setFieldValue(id, inputBtw);
  };

  const checkValue = type => {
    let value,
      registration_first,
      invoiceValue,
      kw,
      model_year,
      seats,
      optionsValue;

    let inputValue = carData.value;
    let inputInvoice = carData.invoice_value;
    let inputOptions = carData.options_value;
    if (inputValue) {
      inputValue /= 1.21;
    }
    if (inputInvoice) {
      inputInvoice /= 1.21;
    }
    if (inputOptions) {
      inputOptions /= 1.21;
    }

    carData.invoice_value
      ? btwTextInvoice === t(`CheckCar.button_vat_exclu`)
        ? (invoiceValue = (inputInvoice * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        : (invoiceValue = inputInvoice
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
      : (invoiceValue = '');
    carData.options_value
      ? btwTextInvoice === t(`CheckCar.button_vat_exclu`)
        ? (optionsValue = (inputOptions * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        : (optionsValue = inputOptions
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
      : (optionsValue = '');
    carData.value
      ? btwText === t(`CheckCar.button_vat_exclu`)
        ? (value = (inputValue * 1.21)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
        : (value = inputValue
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 '))
      : (value = '');
    if (carData2.vehicle_status === 'wants_car') {
      registration_first = moment().format(dateFormat);
    } else {
      if (carData) {
        registration_first = carData?.registration_first
          ?.split('-')
          ?.reverse()
          ?.join('-');
      } else {
        registration_first = '';
      }
    }

    if (carData.kw !== null || carData.kw !== '') {
      kw = carData.kw;
    } else {
      kw = '';
    }
    if (carData.model_year !== null || carData.model_year !== '') {
      model_year = carData.model_year;
    } else {
      model_year = '';
    }

    if (carData.seats !== null || carData.seats !== '') {
      seats = carData.seats;
    } else {
      seats = '';
    }

    switch (type) {
      case 'value':
        return value;
      case 'invoice_value':
        return invoiceValue;
      case 'options_value':
        return optionsValue;
      case 'registration_first':
        return registration_first;
      case 'kw':
        return kw;
      case 'model_year':
        return model_year;
      case 'seats':
        return seats;
      default:
        return null;
    }
  };

  const onClickExtras = (name, checked) => {
    const tempExtras = { ...carExtras };
    tempExtras[name] = checked;
    setcarExtras(tempExtras);
    addCarData(name, checked);
  };

  const getMotorTypes = () => {
    const text = t(`ToolkitInfo.motor_types_manual`, {
      returnObjects: true,
    });
    return text;
  };

  const handleSelected = (name, value) => {
    let items = { ...selectedItems };
    items[name] = value;
    setSelectedItems(items);
  };

  useEffect(() => {
    const data = getMotorTypes();
    if (carData.motor_type !== '') {
      const typeMotor = Object.keys(data).find(
        type => carData.motor_type === type,
      );
      let items = { ...selectedItems };
      items['motor_type'] = data[typeMotor];
      setInitialFuel(data[typeMotor]);
      setSelectedItems(items);
      setMotorData(Object.values(data));
    }
  }, []);

  return (
    <Layout>
      <Container>
        <StyledLeftSide>
          <RoundImage>
            <StyledDefaultCarIcon colors={getCurrentTheme()} />
          </RoundImage>
          <CarName>
            <p>{t(`CheckCar.title`)}</p>
            <h2>
              {carTitle.brand}, {carTitle.model}
            </h2>
          </CarName>
        </StyledLeftSide>
        <RightSide>
          <TitleForm>{t(`CheckCar.specifications`)}</TitleForm>
          <Formik
            initialValues={{
              registration_first: checkValue('registration_first'),
              model_year: checkValue('model_year'),
              brand: carData.brand,
              model: carData.model,
              kw: checkValue('kw'),
              seats: checkValue('seats'),
              value: checkValue('value'),
              invoice_value: checkValue('invoice_value'),
              options_value: checkValue('options_value'),
              onlyBa: getAllCarData().onlyBa ? getAllCarData().onlyBa : false,
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              clickHandler(values);
            }}
            validate={val => {
              setCarTitle({ brand: val.brand, model: val.model });
            }}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => {
              disableHandler(errors, touched, values);
              return (
                <FormWrapper>
                  <InputWrapper>
                    <Specifications>
                      <StyledCarInputBox
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="brand"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      >
                        {t(`CheckCarManual.inputs.0`)}
                      </StyledCarInputBox>
                      <StyledCarInputBox
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="model"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        placeholder={t(`CheckCarManual.inputs.1`)}
                      >
                        {t(`CheckCarManual.inputs.1`)}
                      </StyledCarInputBox>
                      <SelectInputParent>
                        <SelectInput
                          name="motor_type"
                          data={motorData}
                          selected={(name, value) =>
                            handleSelected(name, value)
                          }
                          initial={initialFuel}
                        >
                          {t(`ToolkitInfo.carInfo.motor_type`)}*
                        </SelectInput>
                      </SelectInputParent>
                      <StyledCarInputBox
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="model_year"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      >
                        {t(`CheckCar.modelYear`)}
                      </StyledCarInputBox>
                      <StyledCarInputBox
                        errors={errors}
                        touched={touched}
                        type="text"
                        name="kw"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      >
                        {t(`CheckCarManual.inputs.3`)}*
                        <SortValueInput>KW</SortValueInput>
                      </StyledCarInputBox>
                      <StyledCarInputBox
                        errors={errors}
                        touched={touched}
                        type="number"
                        name="seats"
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      >
                        {t(`ToolkitInfo.carInfo.seats`)}{' '}
                        <InfoButton
                          title={null}
                          info={t(
                            `The number of drivers is indicated incl driver`,
                          )}
                        />
                      </StyledCarInputBox>
                    </Specifications>
                    <RegistrationContainer>
                      <FirstRegistration>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: t(`CheckCar.registration_first_question`),
                          }}
                        />
                        <StyledDateInput
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          disable={false}
                          type="text"
                          name="registration_first"
                          value={values['registration_first']}
                        />
                      </FirstRegistration>
                    </RegistrationContainer>
                    <FirstRegistration>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`CheckCar.catalogue_question`),
                        }}
                      />
                      <BTWContainer>
                        {values.onlyBa ? (
                          <NVT>{t(`Checkout.nvt`)}</NVT>
                        ) : (
                          <>
                            <SInputBox
                              errors={errors}
                              touched={touched}
                              type="text"
                              name="value"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            >
                              <EuroSign>&euro;</EuroSign>
                            </SInputBox>
                            <BTWButton
                              onClick={() => handleBTW('value', setFieldValue)}
                              type="button"
                            >
                              {btwText}
                            </BTWButton>
                          </>
                        )}
                      </BTWContainer>
                    </FirstRegistration>
                    <OptionsValue>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`CheckCar.options_question`),
                        }}
                      />
                      <BTWContainer>
                        {values.onlyBa ? (
                          <NVT>{t(`Checkout.nvt`)}</NVT>
                        ) : (
                          <>
                            <SInputBox
                              errors={errors}
                              touched={touched}
                              type="text"
                              name="options_value"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            >
                              <EuroSign>&euro;</EuroSign>
                            </SInputBox>
                            <BTWButton
                              onClick={() =>
                                handleBTW('options_value', setFieldValue)
                              }
                              type="button"
                            >
                              {btwTextOptions}
                            </BTWButton>
                          </>
                        )}
                      </BTWContainer>
                    </OptionsValue>
                    <FirstRegistration>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: t(`CheckCar.invoice_value`),
                        }}
                      />
                      <BTWContainer>
                        {values.onlyBa ? (
                          <NVT>{t(`Checkout.nvt`)}</NVT>
                        ) : (
                          <>
                            <SInputBox
                              errors={errors}
                              touched={touched}
                              disabled={values.onlyBa}
                              dataTestId="underwriting_car_value"
                              type="text"
                              name="invoice_value"
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                            >
                              <EuroSign>&euro;</EuroSign>
                            </SInputBox>
                            <BTWButton
                              dataTestId="underwriting_car_vat_button"
                              onClick={() =>
                                handleBTW('invoice_value', setFieldValue)
                              }
                              type="button"
                            >
                              {btwTextInvoice}
                            </BTWButton>
                          </>
                        )}
                      </BTWContainer>
                    </FirstRegistration>
                    <CheckBox
                      errors={errors}
                      type="checkbox"
                      touched={touched}
                      checked={values.onlyBa}
                      name="onlyBa"
                      dataTestId="underwriting_personal_agree1"
                    >
                      {t(
                        `I do not wish to receive proposals for an Omnium or Mini Omnium insurance`,
                      )}
                    </CheckBox>
                  </InputWrapper>
                  <Extra>
                    <TitleForm>Extras</TitleForm>
                    <SortCar>
                      <ExtraSortCar
                        checked={carExtras.is_jeep}
                        name={t(`CheckCar.sortCar.SUV.key`)}
                        onCheck={(name, checked) =>
                          onClickExtras(name, checked)
                        }
                      >
                        <IconExtra
                          alt={t(`CheckCar.sortCar.SUV.label`)}
                          src={suvcar}
                        />
                        <p>{t(`CheckCar.sortCar.SUV.label`)}</p>
                      </ExtraSortCar>
                      <ExtraSortCar
                        checked={carExtras.is_sportscar}
                        name={t(`CheckCar.sortCar.sportsCar.key`)}
                        onCheck={(name, checked) =>
                          onClickExtras(name, checked)
                        }
                      >
                        <IconExtra
                          alt={t(`CheckCar.sortCar.sportsCar.label`)}
                          src={sportscar}
                        />
                        <p>{t(`CheckCar.sortCar.sportsCar.label`)}</p>
                      </ExtraSortCar>
                      <ExtraSortCar
                        checked={carExtras.is_cabrio}
                        name={t(`CheckCar.sortCar.cabrio.key`)}
                        onCheck={(name, checked) =>
                          onClickExtras(name, checked)
                        }
                      >
                        <IconExtra
                          alt={t(`CheckCar.sortCar.cabrio.label`)}
                          src={cabriocar}
                        />
                        <p>{t(`CheckCar.sortCar.cabrio.label`)}</p>
                      </ExtraSortCar>
                    </SortCar>
                  </Extra>
                  <ButtonContainer>
                    {errorMsg ? <ErrorMsg>{errorMsg}</ErrorMsg> : null}
                    <Button
                      disabled={isDisabled}
                      data-testid="step-1-verify-submit"
                      type="submit"
                    >
                      {btnText}
                    </Button>
                  </ButtonContainer>
                </FormWrapper>
              );
            }}
          </Formik>
        </RightSide>
      </Container>
    </Layout>
  );
};

const StyledLeftSide = styled(LeftSide)`
  justify-content: center;
  width: 50%;
  /* margin-top: 10rem; */
`;

const NVT = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  height: 4rem;
`;

const Specifications = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  flex-wrap: wrap;
  justify-content: space-between;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

const SInputBox = styled(InputBox)`
  margin: 0 !important;
  width: 100%;
  flex-grow: 1;
  position: static !important;

  /* position: absolute;
  margin-top: 5rem;
  left: 48%; */

  @media screen and (max-width: 1050px) {
    left: 0;
    margin-top: 3.5rem;
  }

  /* & > div {
    margin: 0 !important;
    align-items: flex-start;
  }

  & > div > p {
    align-self: flex-start !important;
  }

  & label {
    & input {
      -webkit-appearance: none;
    }
  } */

  @media screen and (max-width: 1050px) {
    margin: 0 !important;
  }
`;

const StyledCarInputBox = styled(InputBox)`
  width: 48%;
  flex: 0 0 48%;

  @media screen and (max-width: 1050px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const SelectInputParent = styled.div`
  width: 48%;

  @media screen and (max-width: 1050px) {
    width: 100%;
    flex: 0 0 100%;
  }
`;

const RegistrationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  & div {
    width: 100%;
  }

  & div + div {
    margin-left: 1rem;
  }
`;

const FirstRegistration = styled.div`
  display: flex;
  font-size: 1.4rem;

  & p {
    line-height: 1.3;
    width: 48%;
    margin-right: 4%;
    padding-right: 2.5rem;
    align-self: center;
    color: ${({ theme }) => theme.typo.interactive};

    & span {
      font-weight: 900;
      font-size: 1.4rem;
      color: #505050;
    }
  }

  & div {
    width: 45%;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;

    & div {
      width: 65%;

      & p {
        width: 80%;
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;

    & p {
      width: 100%;
      align-self: flex-start;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    & div {
      width: 100%;

      & div {
        width: 100%;

        & p {
          width: 100%;
        }
      }
    }
  }
`;

const OptionsValue = styled.div`
  display: flex;
  font-size: 1.4rem;
  margin-top: -0.5rem;
  /* margin-bottom: 2rem; */
  & p {
    line-height: 1.3;
    width: 48%;
    margin-right: 4%;
    padding-right: 2.5rem;
    align-self: center;
    color: ${({ theme }) => theme.typo.interactive};

    & span {
      font-weight: 900;
      font-size: 1.4rem;
      color: #505050;
    }
  }

  & div {
    width: 45%;
    margin-bottom: 0.2rem;
    margin-top: 0.3rem;

    & div {
      width: 65%;

      & p {
        width: 80%;
        margin-bottom: 0.4rem;
        margin-top: 0.4rem;
        text-align: left;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;

    & p {
      width: 100%;
      align-self: flex-start;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    & div {
      width: 100%;

      & div {
        width: 100%;

        & p {
          width: 100%;
        }
      }
    }
  }
`;

const StyledDefaultCarIcon = styled(DefaultCarIcon)`
  width: 100%;
  height: 100%;
`;

const SortValueInput = styled.span`
  position: absolute;
  font-weight: bold;
  top: 3.3rem;
  right: 3rem;
`;

const BTWContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 48% !important;
  flex-wrap: nowrap;
  justify-content: space-between;

  position: relative;

  @media screen and (max-width: 1050px) {
    width: 100% !important;
  }
`;

const BTWButton = styled(Button)`
  align-self: center;
  padding: 0 0.5rem;
  min-width: 0;
  text-align: center;
  justify-content: center;
  height: 3.5rem;
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  width: auto;
  font-size: 1.2rem;
  margin: 0 0 0.5rem 0.5rem;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
`;

// const Img = styled.img`
//   object-fit: contain;
//   height: 26rem;
//   width: 29rem;
//   display: flex;
//   z-index: 0;

//   @media screen and (max-width: 960px) {
//     height: 18rem;
//     width: 21rem;
//   }
// `;

const StyledDateInput = styled(DateInput)`
  width: 48% !important;
  & > label {
    margin-top: 0;
    flex: 0 0 auto;
  }
`;

const CarName = styled.div`
  background: white;
  box-shadow: 0 0.4rem 1.3rem rgba(0, 0, 0, 0.1);
  border-radius: 0.8rem;
  padding: 2rem 1.8rem;
  width: 85%;
  height: 9rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & p {
    font-size: 1.2rem;
    line-height: 1.5;
    color: ${({ theme }) => theme.typo.interactive};
    text-align: left;
  }

  & h2 {
    color: #505050;
    font-weight: 900;
    font-size: 1.6rem;
  }
`;

const ErrorMsg = styled.p`
  color: red;
  font-weight: 500;
  margin-right: 2rem;
  font-size: 1.6rem;
`;

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-size: 1.5rem;
    align-items: baseline;
    transition: all 0.2s ease;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;

  & button {
    margin-right: 0;
  }
`;

const TitleForm = styled.h2`
  font-weight: 900;
  font-size: 1.6rem;
`;

const Extra = styled.div`
  margin-top: 3rem;
`;

const SortCar = styled.div`
  display: flex;
  flex-direction: row;
  width: 102%;
  margin-top: 0.5rem;
  flex-wrap: wrap;
`;

const ExtraSortCar = styled(IconCheckbox)`
  font-size: 1.5rem;
`;

const IconExtra = styled.img`
  width: 3.2rem;
  margin-right: 1rem;
`;

const FormWrapper = styled(Form)`
  width: 100%;
  position: relative;
  padding-bottom: 10rem;
`;

const EuroSign = styled.span`
  position: absolute;
  font-weight: bold;
  top: 1.8rem;
  right: 1rem;
`;

const InfoButton = styled(InfoPopUp)`
  height: 2rem;
  width: 2rem;
  margin-top: -1.2rem;
  margin-left: 0.5rem;
  font-size: 1.4rem;
`;

const InputWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

const RoundImage = styled.div`
  width: 33rem;
  height: 33rem;
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 50%;

  @media screen and (max-width: 960px) {
    width: 25rem;
    height: 25rem;
  }
`;

CheckCarFallback.propTypes = {
  history: shape().isRequired,
};

export default withRouter(CheckCarFallback);
