/* stylelint-disable declaration-no-important */
import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import AdresInputField from './AdresInputField';
import LoadingButton from './LoadingButton';
import Button from './Button';
import { func, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import regex from '../services/regexService';
const AlternativeForm = ({
  manualClickHandler,
  toggleManual,
  loading,
  question,
}) => {
  const { t } = useTranslation();
  const SignupSchema = Yup.object().shape({
    streetName: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(150, t(`AdresInput.errors.long`))
      .matches(regex.street, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
    streetNumber: Yup.string()
      .required(t(`AdresInput.errors.required`))
      .matches(regex.houseNr, t(`AdresInput.errors.invalid`)),
    boxNumber: Yup.number().max(5, t(`AdresInput.errors.long`)),
    postalCode: Yup.number()
      .min(1000, t(`AdresInput.errors.short`))
      .max(9999, t(`AdresInput.errors.long`))
      .required(t(`AdresInput.errors.required`)),
    municipalityName: Yup.string()
      .min(1, t(`AdresInput.errors.short`))
      .max(100, t(`AdresInput.errors.long`))
      .matches(regex.city, t(`AdresInput.errors.invalid`))
      .required(t(`AdresInput.errors.required`)),
  });

  const disableHandler = (errors, touched) => {
    if (Object.entries(errors).length === 0) {
      if (!(Object.entries(touched).length === 0)) {
        return false;
      }
    }
    return true;
  };

  return (
    <ManualInputContainer>
      <CloseButton
        data-test-id="underwriting_address_close"
        onClick={() => toggleManual()}
      >
        X
      </CloseButton>
      <ErrorMsgs dangerouslySetInnerHTML={{ __html: question }} />
      <Formik
        initialValues={{
          streetName: ``,
          streetNumber: ``,
          boxNumber: ``,
          postalCode: ``,
          municipalityName: ``,
        }}
        validationSchema={SignupSchema}
        onSubmit={values => {
          manualClickHandler(values);
        }}
      >
        {({ errors, touched }) => {
          const disabled = disableHandler(errors, touched);
          return (
            <FormWrapper>
              <InputWrapper>
                <AdresInputField
                  dataTestId="underwriting_address_street"
                  type="text"
                  name="streetName"
                >
                  {t(`AdresInput.manual.street`)}
                </AdresInputField>
                <AdressInputWidth
                  dataTestId="underwriting_address_streetNumber"
                  type="text"
                  name="streetNumber"
                >
                  {t(`AdresInput.manual.nr`)}
                </AdressInputWidth>
                <AdressInputWidth
                  dataTestId="underwriting_address_boxNumber"
                  type="number"
                  name="boxNumber"
                >
                  {t(`AdresInput.manual.boxnr`)}
                </AdressInputWidth>
                <AdresInputField
                  dataTestId="underwriting_address_zipcode"
                  type="number"
                  name="postalCode"
                >
                  {t(`AdresInput.manual.zipcode`)}
                </AdresInputField>
                <AdresInputField
                  dataTestId="underwriting_address_city"
                  type="text"
                  name="municipalityName"
                >
                  {t(`AdresInput.manual.city`)}
                </AdresInputField>
              </InputWrapper>
              <ButtonContainer>
                {!loading ? (
                  <StyledButton
                    type="submit"
                    dataTestId="underwriting_address_submit"
                    disabled={disabled}
                  >
                    {t(`AdresInput.manual.confirm`)}
                  </StyledButton>
                ) : (
                  <StyledLoadingButton>
                    {t(`AdresInput.manual.pending`)}
                  </StyledLoadingButton>
                )}
              </ButtonContainer>
            </FormWrapper>
          );
        }}
      </Formik>
    </ManualInputContainer>
  );
};

const CloseButton = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.brand.lighter};
  font-weight: 900;
  position: absolute;
  right: 0;
  top: -0.5rem;
  transition: color 0.2s ease;
  padding: 1.5rem;

  &:hover {
    color: ${({ theme }) => theme.brand.primary};
  }
`;

const AdressInputWidth = styled(AdresInputField)`
  width: 10%;
`;

const StyledButton = styled(Button)`
  margin: 0;
  height: 4.5rem;
  font-size: 1.5rem;
  text-align: center !important;
  justify-content: center;
  border-radius: 0 0 1.6rem 1.6rem;
  width: 100%;

  &:disabled {
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  &:active {
    transform: scale(1);
  }

  @media screen and (max-width: 1025px) {
    transform: none;
    margin-bottom: 0;

    &:active {
      transform: scale(1);
    }
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  margin: 0;
  height: 4.5rem;
  font-size: 1.5rem;
  text-align: center !important;
  justify-content: center;
  border-radius: 0 0 1.6rem 1.6rem;
  width: 100%;

  &:disabled {
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  &:active {
    transform: scale(1);
  }

  @media screen and (max-width: 1025px) {
    transform: none;
    margin-bottom: 0;

    &:active {
      transform: scale(1);
    }
  }
`;

const ErrorMsgs = styled.h2`
  font-weight: 500;
  font-size: 1.5rem;
  flex: 0 0 100%;
  color: black;
  padding: 1.5rem 3rem;
  padding-bottom: 0;
`;

const ManualInputContainer = styled.div`
  color: red;
  position: absolute;
  bottom: 0;
  height: 18rem;
  right: 0;
  left: 0;
  background: white;
  flex-wrap: wrap;
  border-radius: 0 1.6rem 1.6rem;
  z-index: 999;
  display: flex;
  padding-bottom: 5rem;
  justify-content: space-between;
`;

const FormWrapper = styled(Form)`
  display: flex;
  width: 100%;
  padding: 0 3rem;
  margin-bottom: 2rem;
`;

const InputWrapper = styled.div`
  text-align: left;
  margin: auto 0;
  flex: 0 0 100%;
  display: flex;
  z-index: 10;
  justify-content: space-between;

  & input {
    margin-top: 0;
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 3rem;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0;
  flex-direction: column;
`;

AlternativeForm.propTypes = {
  manualClickHandler: func.isRequired,
  toggleManual: func.isRequired,
  loading: bool.isRequired,
  question: string.isRequired,
};

export default AlternativeForm;
