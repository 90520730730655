import React, { useState, useEffect } from 'react';
import { shape } from 'prop-types';
import Section from './Section';
import styled from 'styled-components';
import RoomEstimationIcon from '../../assets/images/room-estimation-icon';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryPropertyRoomsInformation = ({ roomInfo }) => {
  const [roomsData, setRoomsData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setRoomsData({
      livingRooms: roomInfo.livingRooms,
      bathrooms: roomInfo.bathrooms,
      bedrooms: roomInfo.bedrooms,
      kitchens: roomInfo.kitchens,
      garages: roomInfo.garages,
      otherRooms: roomInfo.otherRooms,
      total: roomInfo.total,
    });
  }, [roomInfo]);

  return (
    <Section>
      <Section.Subtitle>{t('History.property.rooms')}</Section.Subtitle>
      <Section.Content data-test-id="dashboard_history_detail_property_section_rooms_layout">
        <Section.Split>
          <Section.SplitContent>
            <IconWrapper>
              <IconContainer>
                <RoomEstimationIcon color={'#CCCCCC'} />
              </IconContainer>
              <div>
                <Label>
                  {t('ToolkitInfo.address.addressInfo.living_rooms')}
                </Label>
                <Text>{roomsData.livingRooms}</Text>
                <Label>{t('ToolkitInfo.address.addressInfo.bathrooms')}</Label>
                <Text>{roomsData.bathrooms}</Text>
                <Label>{t('ToolkitInfo.address.addressInfo.bedrooms')}</Label>
                <Text>{roomsData.bedrooms}</Text>
              </div>
            </IconWrapper>
          </Section.SplitContent>
          <Section.SplitContent>
            <Label>{t('ToolkitInfo.address.addressInfo.kitchens')}</Label>
            <Text>{roomsData.kitchens}</Text>
            <Label>{t('ToolkitInfo.address.addressInfo.garages')}</Label>
            <Text>{roomsData.garages}</Text>
            <Label>{t('ToolkitInfo.address.addressInfo.other_rooms')}</Label>
            <Text>{roomsData.otherRooms}</Text>
          </Section.SplitContent>
        </Section.Split>
      </Section.Content>
    </Section>
  );
};

const Label = styled.label`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #a29c95;
`;

const Text = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #5b5550;
`;

const IconWrapper = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

DashboardBrokerHistoryPropertyRoomsInformation.defaultProps = {};

DashboardBrokerHistoryPropertyRoomsInformation.propTypes = {
  roomInfo: shape().isRequired,
};

export default DashboardBrokerHistoryPropertyRoomsInformation;
