import React, {
  useState,
  useReducer,
  useEffect,
  useRef,
  forwardRef,
} from 'react';
import {
  bool,
  number,
  string,
  object,
  func,
  node,
  oneOfType,
  shape,
} from 'prop-types';

import styled from 'styled-components';
// import { detect } from 'detect-browser';
// import Error from './../Messages/Error';
// import ValidationIcons from './../Inputs/ValidationIcons';

const DateInput = forwardRef(
  ({
    className,
    name,
    disabled,
    error,
    touched,
    value,
    onBlur,
    onChange,
    validate,
    children,
    ...otherProps
  }) => {
    const useClickOutside = (ref, handler) => {
      useEffect(() => {
        const listener = e => {
          if (e.keyCode === 27) return handler();
          ref.current && !ref.current.contains(e.target) && handler();
        };
        document.addEventListener('keyup', listener);
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
          document.removeEventListener('keyup', listener);
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        };
      }, [ref, handler]);
    };

    const formatISODate = date => {
      const newDate = new Date(date);
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const formattedDate =
        (day <= 9 ? '0' + day : day) +
        '/' +
        (month <= 9 ? '0' + month : month) +
        '/' +
        newDate.getFullYear();
      return formattedDate;
    };

    const ref = useRef();
    // Pad the value -> pad(4) returns '04', pad(11) returns '11'
    const pad = n => {
      if (!parseInt(n)) return '';
      return parseInt(n) < 10 ? `0${parseInt(n)}` : n;
    };

    const isISODate = date => {
      if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date))
        return false;
      var d = new Date(date);
      return d.toISOString() === date;
    };

    const init = value => {
      if (typeof value === 'object') {
        return {
          day: pad(value.day),
          month: pad(value.month),
          year: value.year,
        };
      } else if (isISODate(value)) {
        const values = formatISODate(value).split('/');
        return { day: pad(values[0]), month: pad(values[1]), year: values[2] };
      } else {
        const values = value.split('/');
        return { day: pad(values[0]), month: pad(values[1]), year: values[2] };
      }
    };

    useEffect(() => {
      const dateObj = init(value) || {};
      Object.entries(dateObj).forEach(([key, _value]) => {
        dispatch({ type: key, payload: _value });
      });
    }, [value]);

    const [date, dispatch] = useReducer((state, { type, payload }) => {
      switch (type) {
        case 'day':
          return { ...state, day: payload ? payload : '' };
        case 'month':
          return { ...state, month: payload ? payload : '' };
        case 'year':
          return { ...state, year: payload ? payload : '' };
        default:
          return { ...state };
      }
    }, init(value));

    //const browser = detect();
    const [, setFocus] = useState();
    // const [iconRight, setIconRight] = useState('1rem');
    const [errorMessage, setErrorMessage] = useState(error);
    const dayRef = useRef();
    const monthRef = useRef();
    const yearRef = useRef();

    const ARROW_LEFT = 37;
    const ARROW_UP = 38;
    const ARROW_RIGHT = 39;
    const ARROW_DOWN = 40;

    useClickOutside(ref, () => setFocus(false));

    const isDate = () => {
      const d = `${date.day}/${date.month}/${date.year}`;
      return /\d{2}\/\d{2}\/\d{4}/.test(d);
    };

    const getDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };

    const isValidDayInMonth = (day, month, year) => {
      return getDaysInMonth(month, year) >= day;
    };

    useEffect(() => {
      setErrorMessage(error);
    }, [error]);

    useEffect(() => {
      if (validate && !validate(`${date?.day}/${date?.month}/${date?.year}`))
        return;
      if (!isDate()) return;
      if (!isValidDayInMonth(date.day, date.month, date.year)) {
        setErrorMessage('Not a valid date');
      } else {
        setErrorMessage('');
      }
      onChange({ name, value: `${date.day}/${date.month}/${date.year}` });
      !touched && setFocus(false);
    }, [date.day, date.month, date.year]);

    const prevRef = {
      day: null,
      month: dayRef,
      year: monthRef,
    };

    const nextRef = {
      day: monthRef,
      month: yearRef,
      year: null,
    };

    // Change focus to the field with the classname in the nextField argument
    const focusField = field => {
      if (!field) return;
      field.current.focus();
      field.current.setSelectionRange(
        0,
        field.current.getAttribute('data-maxlengthvalue'),
      );
    };

    const setRange = e => {
      e.preventDefault();
      e.target.setSelectionRange(
        0,
        e.target.getAttribute('data-maxlengthvalue'),
      );
    };

    const keyDownHandler = (e, max, min) => {
      if (![8, 37, 38, 39, 40].includes(e.keyCode)) return true;
      const type = e.target.id;
      e.preventDefault();
      if (e.keyCode === ARROW_UP || e.keyCode === ARROW_DOWN) {
        if (date[type] === '') return dispatch({ type, payload: '01' });
        if (parseInt(date[type]) < min || parseInt(date[type]) > max)
          return false;
        if (e.keyCode === ARROW_UP && parseInt(date[type]) < max)
          dispatch({ type, payload: pad(parseInt(date[type]) + 1) });
        else if (e.keyCode === ARROW_DOWN && parseInt(date[type]) > min)
          dispatch({ type, payload: pad(parseInt(date[type]) - 1) });
        setRange(e);
      } else if ([ARROW_LEFT, 8].includes(e.keyCode)) {
        e.keyCode === ARROW_LEFT && focusField(prevRef[type]);
        type === 'day' && setRange(e);
        e.keyCode === 8 && dispatch({ type, payload: '' });
      } else if (ARROW_RIGHT === e.keyCode) {
        setRange(e);
        focusField(nextRef[type]);
      }
    };

    const handleFocus = () => {
      setFocus(true);
      // if (!browser) return;
      // switch (browser.name) {
      //   case ['', 'safari'].includes(browser.name):
      //     setIconRight('3.5rem');
      //     break;
      //   default:
      //     setIconRight('1rem');
      // }
    };

    // Single functions to handle all blurs
    const blurHandlerType = ({ id, value }, max, min) => {
      if (value === '') return false;
      const inRange = parseInt(value) < max && parseInt(value) >= min;
      let tempInput = inRange ? pad(value) : pad(date[id][0]);
      dispatch({ type: id, payload: tempInput });
    };

    const handleBlurInput = ({ target: { id, value } }) => {
      if (id === 'year') {
        dispatch({ type: 'year', payload: value });
      }

      switch (id) {
        case 'day':
          blurHandlerType({ id, value }, 32, 0);
          break;
        case 'month':
          blurHandlerType({ id, value }, 13, 0);
          break;
        default:
          break;
      }
    };

    const handleChangedInputForType = ({ id, value }, max, min) => {
      const type = id;
      let tempValue;
      if (isNaN(value)) tempValue = pad(isNaN(date[type]) ? 1 : date[type]);
      else if (parseInt(value) < min || parseInt(value) > max) {
        tempValue = date[type] ? date[type] : 1;
      }
      if (type === 'year') {
        tempValue = value.length > 4 ? date[type] : value;
      } else {
        tempValue = value.length > 2 ? date[type] : value;
      }
      dispatch({ type, payload: tempValue });
      value.length === 2 && focusField(nextRef[type]);
    };

    const handleChangedInput = e => {
      if (!/^\d{1,4}$/.test(e.target.value)) return;
      e.persist();
      handleChangedInputForType(e.target, 32, 0);
    };

    // const handleCalendarChange = dateStr => {
    //   const date = new Date(dateStr);
    //   const payload = {
    //     day: pad(date.getDate()),
    //     month: pad(date.getMonth() + 1),
    //     year: date.getFullYear(),
    //   };
    //   setFocus(false);
    //   dispatch({ type: 'full', payload });
    // };

    const onFocus = ({ target }) => {
      target.setSelectionRange(0, target.getAttribute('data-maxlengthvalue'));
    };

    return (
      <Container className={className} ref={ref} {...otherProps}>
        <StyledLabel disabled={disabled} htmlFor={name}>
          {children}
          <Input
            error={errorMessage}
            touched={touched}
            onFocus={handleFocus}
            onBlur={onBlur}
            disabled={disabled}
          >
            <StyledSingleInputDate
              id="day"
              value={date.day}
              data-maxlengthvalue={2}
              maxLength={2}
              maxValue={31}
              onBlur={handleBlurInput}
              onChange={handleChangedInput}
              placeholder="DD"
              type="text"
              autoComplete="off"
              ref={dayRef}
              onFocus={onFocus}
              min={1}
              onKeyDown={e => keyDownHandler(e, 31, 1)}
              disabled={disabled}
            />
            {'/'}
            <StyledSingleInputDate
              id="month"
              value={date.month}
              data-maxlengthvalue={2}
              maxLength={2}
              maxValue={12}
              onBlur={handleBlurInput}
              onChange={handleChangedInput}
              placeholder="MM"
              type="text"
              ref={monthRef}
              autoComplete="off"
              onFocus={onFocus}
              min={1}
              onKeyDown={e => keyDownHandler(e, 12, 1)}
              disabled={disabled}
            />
            {'/'}
            <StyledSingleInputDate
              id="year"
              value={date.year}
              maxLength={4}
              data-maxlengthvalue={9999}
              ref={yearRef}
              onBlur={handleBlurInput}
              onChange={handleChangedInput}
              placeholder="YYYY"
              type="text"
              autoComplete="off"
              onFocus={onFocus}
              minLength={1}
              min={1}
              onKeyDown={e => keyDownHandler(e, 9999, 0)}
              disabled={disabled}
            />
          </Input>
          {/* <ValidationIcons
            error={errorMessage}
            browser={browser}
            focus={focus}
            iconRight={iconRight}
            touched={touched}
          /> */}
        </StyledLabel>
        {errorMessage ? (
          <ErrorContainer className="errorBox">
            <ErrorMss name={name} component="p" className="error">
              {errorMessage}
            </ErrorMss>
          </ErrorContainer>
        ) : null}
      </Container>
    );
  },
);

const ErrorContainer = styled.div`
  height: 1.5rem;
  margin-top: 0.8rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const ErrorMss = styled.p`
  color: red;
  font-size: 1.1rem;
  width: 100%;
  margin-top: -1rem;
  margin-left: 0.5rem;
  text-align: right;
`;

// const StyledCalendar = styled(Calendar)`
//   min-width: 27rem;
//   position: absolute;
//   z-index: 2;
// `;

const Input = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: flex-start;
  background-color: white;
  padding-left: 1.2rem;
  height: 4rem;
  margin-top: 1.4rem;
  border: 0.1rem solid;
  border: ${props =>
    props.error ? '0.1rem solid red' : '0.1rem solid #d3d4d8'};
  border-radius: 0.3rem;
`;

Input.displayName = 'Input';

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  font-size: 1.4rem;
  color: ${props => (props.disabled ? '#AEAEAE' : '#5B5550')};
  line-height: 1rem;
`;

const StyledSingleInputDate = styled.input`
  flex-grow: 0;
  flex-shrink: 0;
  width: ${({ id }) => (id === 'year' ? '5rem' : '2.9rem')};
  border: none;
  letter-spacing: 0.1rem;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  display: block;
  padding: 0.2rem;
  text-align: center;
  font-size: 1.5rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    letter-spacing: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  font-family: ${({ theme }) => theme.font};
  position: relative;
  height: 7rem;
`;

Container.displayName = 'Container';
DateInput.displayName = 'DateInput';

DateInput.defaultProps = {
  disabled: false,
  touched: false,
  isCalendarEnabled: false,
  otherProps: {},
  value: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
};
DateInput.propTypes = {
  /** Beeing able to use it in Styled Components */
  className: string,
  /** name of input and label */
  name: string.isRequired,
  /** label above the input */
  children: node.isRequired,
  /** type of input: email, text, ... */
  disabled: bool,
  /** example value in the input */
  error: string,
  /** show calendar on focus */
  isCalendarEnabled: bool,
  /** object with inputname and boolean to check if touched */
  touched: bool,
  /** Callback function that is fired when blurring the input field. */
  onBlur: func,
  /** Callback function that is fired when focusing the input field. */
  onChange: func,
  /** Callback function that is fired when the component's value changes. */
  onFocus: func,
  /** Current value of the input element as { day: 'DD', month: 'MM', year: 'YYYY' } */
  value: oneOfType([
    string,
    shape({
      day: oneOfType([string, number]),
      month: oneOfType([string, number]),
      year: oneOfType([string, number]),
    }),
  ]),
  validate: func,
  /** Adds extra props to the element */
  otherProps: object,
};
export default DateInput;
