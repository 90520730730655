import React from 'react';
import { array, func, string as stringProp } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Modal,
  RiskObjectDisplay,
  RoundedButton,
  IconActionPlus,
  IconFamilyFilled,
  IconPropertyFilled,
  // IconHeavyScooter,
  IconLegalFilled,
  IconCarFilled,
  DataBlock,
  DashedButton,
  Body,
  H4,
} from 'wg-fe-ui';

const CRMLeadConvertModal = ({
  modalState,
  riskObjects,
  setRiskObject,
  title,
  type,
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = modalState;

  function parseAddressToString({ city, housenr, street, zipcode, boxnr }) {
    const fullHouseNr = boxnr ? `${housenr}/${boxnr}` : housenr;
    return `${street} ${fullHouseNr}, ${city} ${zipcode}`;
  }

  const getRoomCount = rooms => {
    let roomCount = 0;
    Object.values(rooms).forEach(amount => {
      roomCount += amount;
    });
    return roomCount;
  };

  return (
    <>
      <Modal
        canClose
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        large
      >
        <ModalContainer>
          <Row>
            {riskObjects.map(risk => (
              <StyledRiskObjectDisplay
                key={risk.id}
                hoverButton={
                  <RoundedButton
                    icon={<IconActionPlus color="black" size={40} />}
                    iconType="fill"
                    size="large"
                    type="primary"
                  />
                }
                hoverContent={t(`Tarificate`)}
                icon={
                  type === 'car' ? (
                    <IconCarFilled color="black" size={30} />
                  ) : type === 'family' ? (
                    <IconFamilyFilled color="black" size={30} />
                  ) : type === 'teachers' ? (
                    <IconLegalFilled color="black" size={30} />
                  ) : type === 'home' ? (
                    <IconPropertyFilled color="black" size={30} />
                  ) : null
                }
                onClick={() => setRiskObject(risk)}
                title={
                  type === 'car' || type === 'two_wheeler'
                    ? risk?.name
                    : type === 'home'
                    ? parseAddressToString(risk.address)
                    : type === 'family'
                    ? t(risk.status)
                    : type === 'teachers'
                    ? t('legal')
                    : t(type)
                }
              >
                {type === 'car' ? (
                  <>
                    <DataBlock>
                      <Body light smaller>
                        {t('License plate')}
                      </Body>
                      <H4 larger>{risk?.number_plate?.toUpperCase() || '-'}</H4>
                    </DataBlock>
                    <DataBlock>
                      <Body light smaller>
                        {t('Power')} (kW)
                      </Body>
                      <H4 larger>{risk?.kw || '-'}</H4>
                    </DataBlock>
                    <DataBlock>
                      <Body light smaller>
                        {t('Catalogue value')}
                      </Body>
                      <H4 larger>
                        {Number(risk?.value).toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        }) || '-'}
                      </H4>
                    </DataBlock>
                  </>
                ) : type === 'family' ? (
                  <>
                    <DataBlock>
                      <Body light smaller>
                        {t('Household')}
                      </Body>
                      <H4 larger>{t(risk.status) || '-'}</H4>
                    </DataBlock>
                  </>
                ) : type === 'teachers' ? (
                  <>
                    <DataBlock>
                      <Body light smaller>
                        {t('Legal')}
                      </Body>
                      <H4 larger>{t(risk.status) || '-'}</H4>
                    </DataBlock>
                  </>
                ) : type === 'two_wheeler' ? (
                  <>
                    <DataBlock>
                      <Body light smaller>
                        {t('License plate')}
                      </Body>
                      <H4 larger>{risk?.number_plate?.toUpperCase() || '-'}</H4>
                    </DataBlock>
                    <DataBlock>
                      <Body light smaller>
                        {t('Power')} (kW)
                      </Body>
                      <H4 larger>{risk?.kw || '-'}</H4>
                    </DataBlock>
                    <DataBlock>
                      <Body light smaller>
                        {t('Catalogue value')}
                      </Body>
                      <H4 larger>
                        {Number(risk?.value).toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        }) || '-'}
                      </H4>
                    </DataBlock>
                  </>
                ) : type === 'home' ? (
                  <>
                    <DataBlock>
                      <Body light smaller>
                        {t('Number of rooms')}
                      </Body>
                      <H4 larger>{getRoomCount(risk.main_building.rooms)}</H4>
                    </DataBlock>
                    <DataBlock>
                      <Body light smaller>
                        {t('Total surface area')}
                      </Body>
                      <H4 larger>
                        {risk?.main_building?.surface ? (
                          <>
                            {risk?.main_building?.surface} m<sup>2</sup>
                          </>
                        ) : (
                          '-'
                        )}
                      </H4>
                    </DataBlock>
                  </>
                ) : null}
              </StyledRiskObjectDisplay>
            ))}
            <StyledDashedButton
              button={
                <RoundedButton
                  icon={<IconActionPlus color="black" size={35} />}
                  iconType="fill"
                  size="large"
                  type="primary"
                />
              }
              label={t('Tarificate a new', { type: t(type).toLowerCase() })}
              onClick={() => setRiskObject(true)}
              styleName="faded"
            />
          </Row>
        </ModalContainer>
      </Modal>
    </>
  );
};

const StyledRiskObjectDisplay = styled(RiskObjectDisplay)`
  min-height: 240px;
  sup {
    vertical-align: super;
    font-size: smaller;
  }
`;

const StyledDashedButton = styled(DashedButton)`
  min-height: 240px;
`;

const ModalContainer = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 0 15px;
  grid-template-areas: '. . .';
`;

CRMLeadConvertModal.propTypes = {
  modalState: array,
  riskObjects: array,
  setRiskObject: func,
  title: stringProp,
  type: stringProp,
};

export default CRMLeadConvertModal;
