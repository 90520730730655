import React, { createContext, useContext, useReducer } from 'react';
import { node } from 'prop-types';

const FlowStateContext = createContext();
const FlowDispatchContext = createContext();

// Action Types
const SET_FLOW_TYPE = 'SET_FLOW_TYPE';
const SET_DISTRIBUTION_INFO = 'SET_DISTRIBUTION_INFO';
const SET_CAMPAIGN_INFO = 'SET_CAMPAIGN_INFO';
const SET_CAN_SELL = 'SET_CAN_SELL';

function storeReducer(state, action) {
  switch (action.type) {
    case SET_FLOW_TYPE: {
      return { ...state, type: action.payload };
    }
    case SET_DISTRIBUTION_INFO: {
      return { ...state, distribution: action.payload };
    }
    case SET_CAMPAIGN_INFO: {
      return { ...state, campaign: action.payload };
    }
    case SET_CAN_SELL: {
      return { ...state, canSell: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LouiseFlowProvider({ children }) {
  const [store, dispatch] = useReducer(storeReducer, { type: undefined });

  return (
    <FlowStateContext.Provider value={store}>
      <FlowDispatchContext.Provider value={dispatch}>
        {children}
      </FlowDispatchContext.Provider>
    </FlowStateContext.Provider>
  );
}

function useFlowStore() {
  const context = useContext(FlowStateContext);
  if (context === undefined) {
    throw new Error('useFlowStore must be used within a LouiseFlowProvider');
  }
  return context;
}

function useFlowDispatch() {
  const context = useContext(FlowDispatchContext);
  if (context === undefined) {
    throw new Error('useFlowDispatch must be used within a LouiseFlowProvider');
  }
  return context;
}

LouiseFlowProvider.propTypes = {
  children: node,
};

export { LouiseFlowProvider, useFlowStore, useFlowDispatch };
