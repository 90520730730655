import React from 'react';
import LoadingGif from '../../assets/animations/Loading.gif';

import styled from 'styled-components';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
const Loading = ({ mini, noPrices }) => {
  const { t } = useTranslation();
  return (
    <Container mini={mini}>
      <Img src={LoadingGif} />
      {noPrices ? null : <P>{t('MarketLayout.calculate')}</P>}
    </Container>
  );
};

const Img = styled.img`
  margin-bottom: 1rem;
`;

const Container = styled.div`
  transform: ${props => (props.mini ? `scale(0.8)` : `scale(1)`)};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const P = styled.h3`
  font-size: ${props => (props.mini ? `1.4rem` : `1.6rem`)};
`;

Loading.propTypes = {
  mini: false,
  noPrices: false,
};

Loading.propTypes = {
  mini: bool,
  noPrices: bool,
};

export default Loading;
