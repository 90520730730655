import React from 'react';
import { string } from 'prop-types';

const Pool = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 235.27 154.23">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M230.51,60.2A4.77,4.77,0,0,0,225.74,65V86.12C204.61,96.38,177.82,101.58,151,98.74c-16.31-1.72-31.54-5.67-46.59-9.92v-2s0,0,0,0,0,0,0,0V68s0,0,0,0,0,0,0,0V49.17s0,0,0,0,0,0,0,0V26.79A26.83,26.83,0,0,0,77.57,0,26.52,26.52,0,0,0,65,3.28,33,33,0,0,1,70.7,11a17.12,17.12,0,0,1,6.87-1.46A17.3,17.3,0,0,1,94.84,26.79v17.6h-26V26.79a26.8,26.8,0,0,0-53.6,0,4.77,4.77,0,0,0,9.53,0,17.28,17.28,0,1,1,34.55,0V77.12c-5.54-1.09-11.14-2-16.88-2.65a152.42,152.42,0,0,0-32.89.37V65A4.76,4.76,0,0,0,0,65v84.51a4.77,4.77,0,0,0,4.76,4.76H230.51a4.77,4.77,0,0,0,4.76-4.76V65A4.76,4.76,0,0,0,230.51,60.2ZM68.81,53.9h26v9.34h-26Zm0,18.84h26v9.34H80.34c-3.83-1-7.66-2-11.53-2.94Z"
        />
      </g>
    </g>
  </svg>
);

Pool.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Pool.propTypes = {
  title: string,
  color: string,
};

export default Pool;
