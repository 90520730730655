import React from 'react';

const Informatics = () => (
  <svg viewBox="0 0 270.44 169.24">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'informaticsmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M54.52 151.4V27.57a2 2 0 012-2h157.39a2 2 0 012 2V151.4h25.57V3.44A3.44 3.44 0 00238 0H32.39A3.44 3.44 0 0029 3.44v148z"
        />
        <path
          className="prefix__cls-1"
          d="M0 134.75V152a17.21 17.21 0 0017.21 17.21h236A17.22 17.22 0 00270.44 152v-17.25zm166.13 20.67a8 8 0 01-5.68 2.35H110a8 8 0 110-16.06h50.47a8 8 0 015.68 13.71z"
        />
      </g>
    </g>
  </svg>
);

export default Informatics;
