import 'rheostat/css/rheostat.css';
import 'react-dates/lib/css/_datepicker.css';

import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet';
import cssInterface from 'react-with-styles-interface-css';
import RheostatDefaultTheme from 'rheostat/lib/themes/DefaultTheme';
import ReactDatesDefaultTheme from 'react-dates/lib/theme/DefaultTheme';

import React, { useState, useEffect, useRef } from 'react';
import { TextInput } from 'wg-fe-ui';
import Rheostat from 'rheostat';
import styled from 'styled-components';
import { number, func, array } from 'prop-types';
import { useTranslation } from 'react-i18next';

const ProFlowOffersFilterPriceRange = ({
  min = 0,
  max = 0,
  values,
  onChange,
}) => {
  const [value, setValue] = useState({ min: values[0], max: values[1] });
  const { t } = useTranslation();

  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setValue({ min, max });
    }
  }, [min, max]);

  function handleRangeSlider({ values }) {
    const [_min, _max] = values || [];
    setValue({ min: _min, max: _max });
  }

  /**
   * register theme due to incompatibilities between rheostat and react-dates
   * https://github.com/airbnb/rheostat/issues/179
   */
  ThemedStyleSheet.registerInterface(cssInterface);
  ThemedStyleSheet.registerTheme({
    ...RheostatDefaultTheme,
    ...ReactDatesDefaultTheme,
  });

  return (
    <div>
      <MinMaxContainer>
        <InputContainer>
          <StyledTextInput
            value={value?.min?.toFixed(2) || 0}
            onBlur={({ currentTarget }) => {
              let _value = parseFloat(currentTarget.value);
              if (_value > max) _value = max - 50;
              setValue({ ...value, min: _value });
            }}
          >
            {t('Min')}
          </StyledTextInput>
        </InputContainer>
        <InputContainer>
          <StyledTextInput
            value={value?.max?.toFixed(2) || 0}
            onBlur={({ currentTarget }) => {
              let _value = parseFloat(currentTarget.value);
              if (_value < min) _value = min + 50;
              setValue({ ...value, max: _value });
            }}
          >
            {t('Max')}
          </StyledTextInput>
        </InputContainer>
      </MinMaxContainer>
      <SliderContainer>
        <Rheostat
          min={min}
          max={max}
          values={[value.min, value.max]}
          onValuesUpdated={handleRangeSlider}
        />
      </SliderContainer>
    </div>
  );
};

const StyledTextInput = styled(TextInput)`
  display: flex;
  align-items: center;
  height: 4rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  ::before {
    content: '€';
    display: block;
    position: relative;
    top: 7px;
    left: -7px;
    font-size: 1.8rem;
    color: #aeaeae;
    font-weight: 400;
  }

  > input {
    margin-top: 0.8rem;
  }
`;

const InputContainer = styled.div`
  width: 45%;
`;

const MinMaxContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SliderContainer = styled.div`
  width: 90%;
  margin: 2rem auto 1.5rem;
  .DefaultProgressBar__vertical {
    width: 24px;
    height: 100%;
  }
  .DefaultProgressBar_progressBar {
    background-color:#e5e6e6;
    /* background-color: ${({ theme }) => theme.brand.secondary}; */
    position: absolute;
  }
  .DefaultProgressBar_progressBar__vertical {
    height: 100%;
    width: 24px;
  }
  .DefaultProgressBar_background__vertical {
    height: 100%;
    top: 0;
    width: 15px;
  }
  .DefaultProgressBar_background__horizontal {
    height: calc(100% - 2px);
    top: -6px;
  }
  .DefaultHandle_handle {
    width: 24px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #f0f1f3;
    background-color: white;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
      0 4px 8px rgba(0, 0, 0, 0.04);
    outline: none;
    z-index: 2;
    cursor: ew-resize;
  }
  .DefaultHandle_handle__horizontal {
    margin-left: -12px;
    top: -5px;
    ::before {
      top: 7px;
      height: 10px;
      width: 1px;
      left: 10px;
    }
    ::after {
      top: 7px;
      height: 10px;
      width: 1px;
      left: 13px;
    }
  }
  .DefaultHandle_handle__vertical {
    margin-top: -12px;
    left: -10px;
    ::before {
      top: 10px;
    }
    ::after {
      top: 13px;
      left: 8px;
      height: 1px;
      width: 10px;
    }
  }
  .DefaultHandle_handle__disabled {
    border-color: #dbdbdb;
  }
  .DefaultBackground {
    background-color: #fcfcfc;
    height: 15px;
    width: 100%;
    border: 1px solid #d8d8d8;
    position: relative;
  }
  .DefaultBackground_background__horizontal {
    border-radius: 5px;
    height: 25px;
    top: -7px;
    width: 100%;
  }
  .DefaultBackground_background__vertical {
    width: 15px;
    top: 0;
    height: 100%;
  }
  .rheostat {
    position: relative;
    overflow: visible;
  }
  @media (min-width: 1128px) {
    .autoAdjustVerticalPosition {
      top: 12px;
    }
  }
  .rheostat__vertical {
    height: 100%;
  }
  .handleContainer {
    height: 15px;
    top: -2px;
    left: -2px;
    bottom: 4px;
    width: 100%;
    position: absolute;
  }
  .rheostat_background {
    background-color: #fcfcfc;
    border: 1px solid #d8d8d8;
    position: relative;
  }
  .rheostat_background__horizontal {
    height: 15px;
    top: -2px;
    left: -2px;
    bottom: 4px;
    width: 100%;
  }
  .rheostat_background__vertical {
    width: 15px;
    top: 0;
    height: 100%;
  }
`;

ProFlowOffersFilterPriceRange.propTypes = {
  min: number,
  max: number,
  values: array,
  onChange: func,
};

export default ProFlowOffersFilterPriceRange;
