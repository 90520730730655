import React from 'react';
import { shape } from 'prop-types';

const BrokerBackground = ({ colors }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1893 694.15">
    <g id="roof-small">
      <polygon
        points="238.77 251.71 345.49 192.76 603.5 192.76 603.5 252.15 238.77 251.71"
        fill="#e8e8e8"
      />
      <polyline
        points="603.5 193.15 345.5 193.15 238.5 252.15 510 252.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x1="521.66"
        y1="251.98"
        x2="604"
        y2="251.98"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="_2bedroom_other-2" data-name="2bedroom+other-2">
      <rect x="603" y="60.15" width="183.5" height="192" fill="#f7f7f7" />
      <line
        x1="603"
        y1="60.65"
        x2="603"
        y2="251.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="666" y="106.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="683.85 106.15 666 140.23 666 121.69 674.14 106.15 683.85 106.15"
        fill="#fff"
      />
      <polygon
        points="705.62 106.15 666 181.78 666 158.06 693.19 106.15 705.62 106.15"
        fill="#fff"
      />
      <rect x="666" y="107.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="716" y="109.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M718.75,116.16v95.48H665.66V116.16h53.09m3.16-3.16H662.5V214.8h59.41V113Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="bedroom_other-2" data-name="bedroom+other-2">
      <rect x="786" y="61.15" width="182" height="191" fill="#f7f7f7" />
      <path
        d="M966.5,67"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x1="786"
        y1="252.65"
        x2="786"
        y2="60.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="850" y="106.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="867.85 106.15 850 140.23 850 121.69 858.14 106.15 867.85 106.15"
        fill="#fff"
      />
      <polygon
        points="889.62 106.15 850 181.78 850 158.06 877.19 106.15 889.62 106.15"
        fill="#fff"
      />
      <rect x="850" y="107.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="900" y="109.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M902.75,116.16v95.48H849.66V116.16h53.09m3.16-3.16H846.5V214.8h59.41V113Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="_2bath-2" data-name="2bath-2">
      <rect x="1424.5" y="60.15" width="131" height="192" fill="#f7f7f7" />
      <rect x="1424.5" y="60.15" width="19.5" height="192" fill="#efefef" />
      <rect x="1462" y="104.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1479.85 104.15 1462 138.23 1462 119.69 1470.14 104.15 1479.85 104.15"
        fill="#fff"
      />
      <polygon
        points="1501.62 104.15 1462 179.78 1462 156.06 1489.19 104.15 1501.62 104.15"
        fill="#fff"
      />
      <rect x="1462" y="105.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="1512" y="107.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M1514.75,114.16v95.48h-53.09V114.16h53.09m3.16-3.16H1458.5V212.8h59.41V111Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <line
        x1="1555.5"
        y1="60.65"
        x2="1555.5"
        y2="252.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="living_kitchen-2" data-name="living+kitchen-2">
      <rect x="1099.5" y="60.15" width="325" height="192" fill="#f7f7f7" />
      <line
        x1="1424.5"
        y1="141.65"
        x2="1424.5"
        y2="252.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1262" y="103.15" width="113" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1353.9 103.15 1300.57 204.95 1265.06 204.95 1318.39 103.15 1353.9 103.15"
        fill="#fff"
      />
      <polygon
        points="1299.71 103.15 1262 175.14 1262 151.42 1287.29 103.15 1299.71 103.15"
        fill="#fff"
      />
      <rect x="1262.5" y="105.15" width="112.5" height="5" fill="#e8e8e8" />
      <rect x="1313" y="106.15" width="6" height="97" fill="#e8e8e8" />
      <rect x="1368" y="106.15" width="6" height="97" fill="#e8e8e8" />
      <line
        x1="1319.5"
        y1="105.65"
        x2="1319.5"
        y2="202.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1371.34,113.16v95.48H1263.66V113.16h107.68m3.16-3.16h-114V211.8h114V110Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <line
        x1="1424.5"
        y1="60.65"
        x2="1424.5"
        y2="132.43"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="terrace-2">
      <rect x="1146.5" y="105.15" width="59.91" height="146" fill="#f7f7f7" />
      <polygon
        points="1164.85 105.15 1147 139.23 1147 120.69 1155.14 105.15 1164.85 105.15"
        fill="#fff"
      />
      <polygon
        points="1186.62 105.15 1147 180.78 1147 157.06 1174.19 105.15 1186.62 105.15"
        fill="#fff"
      />
      <rect x="1146.5" y="105.15" width="58.5" height="6" fill="#e8e8e8" />
      <rect x="1198.5" y="106.15" width="6.5" height="145" fill="#e8e8e8" />
      <polyline
        points="1206.41 251.15 1206.41 174.65 1206.41 105.15 1205 105.15 1186.62 105.15 1174.19 105.15 1164.85 105.15 1155.14 105.15 1147 105.15 1147 111.15 1147 120.69 1147 139.23 1147 157.06 1147 174.65 1147 180.78 1147 252.15"
        fill="none"
        stroke="#dbdbdb"
        strokeMiterlimit="10"
        strokeWidth="3.164"
      />
      <rect
        x="1098.5"
        y="180.65"
        width="325"
        height="71.5"
        fill="#fff"
        opacity="0.8"
      />
      <polyline
        points="1100 180.65 1424.5 180.65 1424.5 251.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="bath-2">
      <rect x="968.5" y="60.15" width="131" height="192" fill="#f7f7f7" />
      <line
        x1="1099.5"
        y1="60.15"
        x2="1099.5"
        y2="250.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x1="968.5"
        y1="250.15"
        x2="968.5"
        y2="60.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1006" y="104.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1023.85 104.15 1006 138.23 1006 119.69 1014.14 104.15 1023.85 104.15"
        fill="#fff"
      />
      <polygon
        points="1045.62 104.15 1006 179.78 1006 156.06 1033.19 104.15 1045.62 104.15"
        fill="#fff"
      />
      <rect x="1006" y="105.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="1056" y="107.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M1058.75,114.16v95.48h-53.09V114.16h53.09m3.16-3.16H1002.5V212.8h59.41V111Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="roof">
      <polygon
        points="1555.91 60.66 1449.28 1.5 709.62 1.5 602.99 60.66 1555.91 60.66"
        fill="#e8e8e8"
      />
      <polyline
        points="1486.93 22.4 1555.51 60.6 602.5 60.6 656.55 30.6"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="1482.41 19.84 1449.54 1.5 710.69 1.5 663.7 27.55"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="_4bedroom_other-0" data-name="4bedroom+other-0">
      <rect x="238.5" y="441.15" width="182" height="193" fill="#f7f7f7" />
      <rect x="302" y="488.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="319.85 488.15 302 522.23 302 503.69 310.14 488.15 319.85 488.15"
        fill="#fff"
      />
      <polygon
        points="341.62 488.15 302 563.78 302 540.06 329.19 488.15 341.62 488.15"
        fill="#fff"
      />
      <rect x="302" y="489.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="352" y="491.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M354.75,498.16v95.48H301.66V498.16h53.09m3.16-3.16H298.5V596.8h59.41V495Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="421 441.65 238.5 441.65 238.5 633.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path d="M231,528" transform="translate(1.5 -6.85)" fill="#fff" />
    </g>
    <g id="_3bedroom_other-0" data-name="3bedroom+other-0">
      <rect x="420.5" y="442.15" width="183" height="191.5" fill="#f7f7f7" />
      <rect x="582.5" y="442.15" width="20" height="192" fill="#efefef" />
      <rect x="484" y="488.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="501.85 488.15 484 522.23 484 503.69 492.14 488.15 501.85 488.15"
        fill="#fff"
      />
      <polygon
        points="523.62 488.15 484 563.78 484 540.06 511.19 488.15 523.62 488.15"
        fill="#fff"
      />
      <rect x="484" y="489.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="534" y="491.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M536.75,498.16v95.48H483.66V498.16h53.09m3.16-3.16H480.5V596.8h59.41V495Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="603 441.65 420.5 441.65 420.5 633.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="_2bedroom_other-0" data-name="2bedroom+other-0">
      <rect x="603" y="442.15" width="182" height="191.5" fill="#f7f7f7" />
      <rect x="667" y="488.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="684.85 488.15 667 522.23 667 503.69 675.14 488.15 684.85 488.15"
        fill="#fff"
      />
      <polygon
        points="706.62 488.15 667 563.78 667 540.06 694.19 488.15 706.62 488.15"
        fill="#fff"
      />
      <rect x="667" y="489.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="717" y="491.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M719.75,498.16v95.48H666.66V498.16h53.09m3.16-3.16H663.5V596.8h59.41V495Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="786 441.65 603 441.65 603 633.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M601.5,641.5"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M601.5,602.5"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="bedroom_other-0" data-name="bedroom+other-0">
      <rect x="786" y="441.65" width="182" height="192" fill="#f7f7f7" />
      <polyline
        points="786 634.65 786 441.65 968 441.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="850" y="488.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="867.85 488.15 850 522.23 850 503.69 858.14 488.15 867.85 488.15"
        fill="#fff"
      />
      <polygon
        points="889.62 488.15 850 563.78 850 540.06 877.19 488.15 889.62 488.15"
        fill="#fff"
      />
      <rect x="850" y="489.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="900" y="491.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M902.75,498.16v95.48H849.66V498.16h53.09m3.16-3.16H846.5V596.8h59.41V495Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="_2bath-0" data-name="2bath-0">
      <polyline
        points="1424.5 633.15 1424.5 442.15 1555.5 442.15 1555.5 633.15"
        fill="#f7f7f7"
      />
      <rect x="1424.5" y="442.15" width="19.5" height="191" fill="#efefef" />
      <rect x="1462" y="487.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1479.85 487.15 1462 521.23 1462 502.69 1470.14 487.15 1479.85 487.15"
        fill="#fff"
      />
      <polygon
        points="1501.62 487.15 1462 562.78 1462 539.06 1489.19 487.15 1501.62 487.15"
        fill="#fff"
      />
      <rect x="1462" y="488.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="1512" y="490.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M1514.75,497.16v95.48h-53.09V497.16h53.09m3.16-3.16H1458.5V595.8h59.41V494Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="1424.5 442.15 1555.5 442.15 1555.5 634.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="living_kitchen-0" data-name="living+kitchen-0">
      <rect x="1099.5" y="442.15" width="325" height="192" fill="#f7f7f7" />
      <polyline
        points="1099.5 442.15 1424.5 442.15 1424.5 633.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1262" y="487.15" width="113" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1353.9 487.15 1300.57 588.95 1265.06 588.95 1318.39 487.15 1353.9 487.15"
        fill="#fff"
      />
      <polygon
        points="1299.71 487.15 1262 559.14 1262 535.42 1287.29 487.15 1299.71 487.15"
        fill="#fff"
      />
      <rect x="1263.5" y="489.15" width="111.5" height="5" fill="#e8e8e8" />
      <rect x="1313" y="490.15" width="6" height="97" fill="#e8e8e8" />
      <rect x="1368" y="490.15" width="6" height="97" fill="#e8e8e8" />
      <line
        x1="1319.5"
        y1="489.65"
        x2="1319.5"
        y2="586.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1371.34,497.16v95.48H1263.66V497.16h107.68m3.16-3.16h-114V595.8h114V494Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="terrace-0">
      <rect x="1146.5" y="488.15" width="59.91" height="146" fill="#f7f7f7" />
      <polygon
        points="1164.85 488.15 1147 522.23 1147 503.69 1155.14 488.15 1164.85 488.15"
        fill="#fff"
      />
      <polygon
        points="1186.62 488.15 1147 563.78 1147 540.06 1174.19 488.15 1186.62 488.15"
        fill="#fff"
      />
      <rect x="1146.5" y="488.15" width="58.5" height="6" fill="#e8e8e8" />
      <rect x="1198.5" y="489.15" width="6.5" height="145" fill="#e8e8e8" />
      <polyline
        points="1206.41 634.15 1206.41 557.65 1206.41 488.15 1205 488.15 1186.62 488.15 1174.19 488.15 1164.85 488.15 1155.14 488.15 1147 488.15 1147 494.15 1147 503.69 1147 522.23 1147 540.06 1147 557.65 1147 563.78 1147 635.15"
        fill="none"
        stroke="#dbdbdb"
        strokeMiterlimit="10"
        strokeWidth="3.164"
      />
      <rect
        x="1099.5"
        y="562.65"
        width="325"
        height="71.5"
        fill="#fff"
        opacity="0.8"
      />
      <polyline
        points="1100 562.65 1424.5 562.65 1424.5 633.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="door">
      <rect x="968.5" y="442.15" width="131" height="191" fill="#f7f7f7" />
      <polyline
        points="1000 634.47 1000 525.92 1063.25 525.92 1063.25 634.47"
        fill="#fff"
      />
      <polyline
        points="1004.12 634.47 1004.12 525.92 1068.81 525.92 1068.81 634.47"
        fill="#e8e8e8"
      />
      <line
        x1="1011.28"
        y1="579.99"
        x2="1019.23"
        y2="579.99"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="1000 632.73 1000 525.92 1063.25 525.92 1063.25 633.6"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="968.5 633.6 968.5 442.15 1099.5 442.15 1099.5 634.47"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="_4bedroom_other-1" data-name="4bedroom+other-1">
      <rect x="238.5" y="252.15" width="182" height="190" fill="#f7f7f7" />
      <rect x="302" y="300.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="319.85 300.15 302 334.23 302 315.69 310.14 300.15 319.85 300.15"
        fill="#fff"
      />
      <polygon
        points="341.62 300.15 302 375.78 302 352.06 329.19 300.15 341.62 300.15"
        fill="#fff"
      />
      <rect x="302" y="301.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="352" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M354.75,310.16v95.48H301.66V310.16h53.09m3.16-3.16H298.5V408.8h59.41V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <line
        x1="238.77"
        y1="414.71"
        x2="238.77"
        y2="408.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="238.5 422.65 238.5 442.15 420 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="420 252.15 239 252.15 239 396.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="_3bedroom_other-1" data-name="3bedroom+other-1">
      <rect x="419.5" y="251.15" width="182.5" height="190" fill="#f7f7f7" />
      <rect x="582.5" y="252.15" width="20" height="190" fill="#efefef" />
      <rect x="484" y="300.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="501.85 300.15 484 334.23 484 315.69 492.14 300.15 501.85 300.15"
        fill="#fff"
      />
      <polygon
        points="523.62 300.15 484 375.78 484 352.06 511.19 300.15 523.62 300.15"
        fill="#fff"
      />
      <rect x="484" y="301.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="534" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M536.75,310.16v95.48H483.66V310.16h53.09m3.16-3.16H480.5V408.8h59.41V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="603 252.15 420 252.15 420 442.15 602 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="_2bedroom_other-1" data-name="2bedroom+other-1">
      <rect x="603" y="252.15" width="182" height="190" fill="#f7f7f7" />
      <rect x="667" y="300.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="684.85 300.15 667 334.23 667 315.69 675.14 300.15 684.85 300.15"
        fill="#fff"
      />
      <polygon
        points="706.62 300.15 667 375.78 667 352.06 694.19 300.15 706.62 300.15"
        fill="#fff"
      />
      <rect x="667" y="301.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="717" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M719.75,310.16v95.48H666.66V310.16h53.09m3.16-3.16H663.5V408.8h59.41V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="786 252.15 603 252.15 603 442.15 785 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M784.5,259"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="bedroom_other-1" data-name="bedroom+other-1">
      <rect x="786" y="251.65" width="182" height="189.5" fill="#f7f7f7" />
      <polyline
        points="968 252.15 786 252.15 786 442.15 968 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="850" y="300.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="867.85 300.15 850 334.23 850 315.69 858.14 300.15 867.85 300.15"
        fill="#fff"
      />
      <polygon
        points="889.62 300.15 850 375.78 850 352.06 877.19 300.15 889.62 300.15"
        fill="#fff"
      />
      <rect x="850" y="301.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="900" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M902.75,310.16v95.48H849.66V310.16h53.09m3.16-3.16H846.5V408.8h59.41V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="_2bath-1" data-name="2bath-1">
      <rect x="1424.5" y="253.15" width="131" height="190" fill="#f7f7f7" />
      <rect x="1424.5" y="253.15" width="19.5" height="190" fill="#efefef" />
      <rect x="1462" y="300.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1479.85 300.15 1462 334.23 1462 315.69 1470.14 300.15 1479.85 300.15"
        fill="#fff"
      />
      <polygon
        points="1501.62 300.15 1462 375.78 1462 352.06 1489.19 300.15 1501.62 300.15"
        fill="#fff"
      />
      <rect x="1462" y="301.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="1512" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M1514.75,310.16v95.48h-53.09V310.16h53.09m3.16-3.16H1458.5V408.8h59.41V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <polyline
        points="1424 252.15 1555.5 252.15 1555.5 442.15 1425 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="living_kitchen-1" data-name="living+kitchen-1">
      <rect x="1099.5" y="252.15" width="325" height="190" fill="#f7f7f7" />
      <polyline
        points="1099.5 252.15 1424.5 252.15 1424.5 299.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="1424.5 333.65 1424.5 442.15 1100 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1262" y="300.15" width="113" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1353.9 300.15 1300.57 401.95 1265.06 401.95 1318.39 300.15 1353.9 300.15"
        fill="#fff"
      />
      <polygon
        points="1299.71 300.15 1262 372.14 1262 348.42 1287.29 300.15 1299.71 300.15"
        fill="#fff"
      />
      <rect x="1262.5" y="302.15" width="112.5" height="5" fill="#e8e8e8" />
      <rect x="1313" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <rect x="1368" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <line
        x1="1319.5"
        y1="302.65"
        x2="1319.5"
        y2="399.65"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1371.34,310.16v95.48H1263.66V310.16h107.68m3.16-3.16h-114V408.8h114V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
      <line
        x1="1424.5"
        y1="308.77"
        x2="1424.5"
        y2="324.43"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="terrace-1">
      <rect x="1146.5" y="301.15" width="59.91" height="141" fill="#f7f7f7" />
      <polygon
        points="1164.85 301.15 1147 335.23 1147 316.69 1155.14 301.15 1164.85 301.15"
        fill="#fff"
      />
      <polygon
        points="1186.62 301.15 1147 376.78 1147 353.06 1174.19 301.15 1186.62 301.15"
        fill="#fff"
      />
      <rect x="1146.5" y="301.15" width="58.5" height="6" fill="#e8e8e8" />
      <rect x="1198.5" y="302.15" width="6.5" height="140" fill="#e8e8e8" />
      <polyline
        points="1206.41 442.15 1206.41 370.65 1206.41 301.15 1205 301.15 1186.62 301.15 1174.19 301.15 1164.85 301.15 1155.14 301.15 1147 301.15 1147 307.15 1147 316.69 1147 335.23 1147 353.06 1147 370.65 1147 376.78 1147 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeMiterlimit="10"
        strokeWidth="3.164"
      />
      <rect
        x="1099.5"
        y="370.65"
        width="325"
        height="71.5"
        fill="#fff"
        opacity="0.8"
      />
      <polyline
        points="1100 370.65 1424.5 370.65 1424.5 442.15 1100 442.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="bath-1">
      <rect x="968.5" y="252.15" width="131" height="190" fill="#f7f7f7" />
      <polygon
        points="968.5 252.15 1099.5 252.15 1100 442.15 968.5 442.15 968.5 252.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1006" y="300.15" width="57.41" height="101.8" fill="#f7f7f7" />
      <polygon
        points="1023.85 300.15 1006 334.23 1006 315.69 1014.14 300.15 1023.85 300.15"
        fill="#fff"
      />
      <polygon
        points="1045.62 300.15 1006 375.78 1006 352.06 1033.19 300.15 1045.62 300.15"
        fill="#fff"
      />
      <rect x="1006" y="301.15" width="56" height="6" fill="#e8e8e8" />
      <rect x="1056" y="303.15" width="6" height="97" fill="#e8e8e8" />
      <path
        d="M1058.75,310.16v95.48h-53.09V310.16h53.09m3.16-3.16H1002.5V408.8h59.41V307Z"
        transform="translate(1.5 -6.85)"
        fill="#dbdbdb"
      />
    </g>
    <g id="garden">
      <path
        d="M1730.44,641H1520.77c-.7-5.06-1.15-13.62,2.92-17.82C1534.5,612,1567.5,622,1584.5,621c29.72-1.75,79.41-21.48,111-14C1724.77,613.93,1729.72,632.72,1730.44,641Z"
        transform="translate(1.5 -6.85)"
        fill="#fff"
      />
      <path
        d="M1599.42,491.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79,13.14-17.21,29.32,2.79,34.52,32.46,6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09,12.54,30.18-2.29,120.42-33.12,118.93C1598.14,586.8,1600.31,531,1599.42,491.41Z"
        transform="translate(1.5 -6.85)"
        fill="#fff"
      />
      <path
        d="M1606.42,491.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79,13.14-17.21,29.32,2.79,34.52,32.46,6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09,12.54,30.18-2.29,120.42-33.12,118.93C1605.14,586.8,1607.31,531,1606.42,491.41Z"
        transform="translate(1.5 -6.85)"
        fill="#e8e8e8"
      />
      <path
        d="M1599.42,491.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79,13.14-17.21,29.32,2.79,34.52,32.46,6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09,12.54,30.18-2.29,120.42-33.12,118.93C1598.14,586.8,1600.31,531,1599.42,491.41Z"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x1="1623.64"
        y1="632.4"
        x2="1623.64"
        y2="398.15"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1735.44,641H1525.77c-.7-5.06-1.15-13.62,2.92-17.82C1539.5,612,1572.5,622,1589.5,621c29.72-1.75,79.41-21.48,111-14C1729.77,613.93,1734.72,632.72,1735.44,641Z"
        transform="translate(1.5 -6.85)"
        fill="#e8e8e8"
      />
      <path
        d="M1730.44,641H1520.77c-.7-5.06-1.15-13.62,2.92-17.82C1534.5,612,1567.5,622,1584.5,621c29.72-1.75,79.41-21.48,111-14C1724.77,613.93,1729.72,632.72,1730.44,641Z"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke="#dbdbdb"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g id="selected_color" data-name="selected color">
      <path
        d="M784.5,259"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1423.5,449"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1422.5,259"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect
        x="1099.5"
        y="252.15"
        width="325"
        height="190"
        fill={colors.brand.lightest}
      />
      <polyline
        points="1099.5 252.15 1424.5 252.15 1424.5 299.65"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <polyline
        points="1424.5 333.65 1424.5 442.15 1100 442.15"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1262" y="300.15" width="113" height="101.8" fill="#fff" />
      <polygon
        points="1353.9 300.15 1300.57 401.95 1265.06 401.95 1318.39 300.15 1353.9 300.15"
        fill={colors.brand.lightest}
      />
      <polygon
        points="1299.71 300.15 1262 372.14 1262 348.42 1287.29 300.15 1299.71 300.15"
        fill={colors.brand.lightest}
      />
      <rect
        x="1262.5"
        y="302.15"
        width="112.5"
        height="5"
        fill={colors.brand.secondary}
      />
      <rect
        x="1313"
        y="303.15"
        width="6"
        height="97"
        fill={colors.brand.secondary}
      />
      <rect
        x="1368"
        y="303.15"
        width="6"
        height="97"
        fill={colors.brand.secondary}
      />
      <line
        x1="1319.5"
        y1="302.65"
        x2="1319.5"
        y2="399.65"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1371.5,310v95.8h-108V310h108m3-3h-114V408.8h114V307Z"
        transform="translate(1.5 -6.85)"
        fill={colors.brand.dark}
      />
      <line
        x1="1424.5"
        y1="308.77"
        x2="1424.5"
        y2="324.43"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <rect x="1146.5" y="301.15" width="59.91" height="141" fill="#fff" />
      <polygon
        points="1164.85 301.15 1147 335.23 1147 316.69 1155.14 301.15 1164.85 301.15"
        fill={colors.brand.lightest}
      />
      <polygon
        points="1186.62 301.15 1147 376.78 1147 353.06 1174.19 301.15 1186.62 301.15"
        fill={colors.brand.lightest}
      />
      <rect
        x="1146.5"
        y="301.15"
        width="58.5"
        height="6"
        fill={colors.brand.secondary}
      />
      <rect
        x="1198.5"
        y="302.15"
        width="6.5"
        height="140"
        fill={colors.brand.secondary}
      />
      <polyline
        points="1206.41 442.15 1206.41 370.65 1206.41 301.15 1205 301.15 1186.62 301.15 1174.19 301.15 1164.85 301.15 1155.14 301.15 1147 301.15 1147 307.15 1147 316.69 1147 335.23 1147 353.06 1147 370.65 1147 376.78 1147 442.15"
        fill="none"
        stroke={colors.brand.dark}
        strokeMiterlimit="10"
        strokeWidth="3.164"
      />
      <rect
        x="1099.5"
        y="370.65"
        width="325"
        height="71.5"
        fill="#fff"
        opacity="0.8"
      />
      <polyline
        points="1100 370.65 1424.5 370.65 1424.5 442.15 1100 442.15"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x1="1099.5"
        y1="252.15"
        x2="1100"
        y2="442.15"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1730.44,641H1520.77c-.7-5.06-1.15-13.62,2.92-17.82C1534.5,612,1567.5,622,1584.5,621c29.72-1.75,79.41-21.48,111-14C1724.77,613.93,1729.72,632.72,1730.44,641Z"
        transform="translate(1.5 -6.85)"
        fill="#fff"
      />
      <path
        d="M1599.42,491.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79,13.14-17.21,29.32,2.79,34.52,32.46,6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09,12.54,30.18-2.29,120.42-33.12,118.93C1598.14,586.8,1600.31,531,1599.42,491.41Z"
        transform="translate(1.5 -6.85)"
        fill="#fff"
      />
      <path
        d="M1606.42,491.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79,13.14-17.21,29.32,2.79,34.52,32.46,6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09,12.54,30.18-2.29,120.42-33.12,118.93C1605.14,586.8,1607.31,531,1606.42,491.41Z"
        transform="translate(1.5 -6.85)"
        fill={colors.brand.secondary}
      />
      <path
        d="M1599.42,491.41c-.8-35.49-35.52-113.41-14.12-141,13.88-17.88,28.76.55,37.66-1.45,13-2.91,3.68-50.92,12.75-62.79,13.14-17.21,29.32,2.79,34.52,32.46,6.08,34.72-11.09,54-21.07,83.31-9.21,27-5.16,38,6.92,67.09,12.54,30.18-2.29,120.42-33.12,118.93C1598.14,586.8,1600.31,531,1599.42,491.41Z"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <line
        x1="1623.64"
        y1="632.4"
        x2="1623.64"
        y2="398.15"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
      <path
        d="M1735.44,641H1525.77c-.7-5.06-1.15-13.62,2.92-17.82C1539.5,612,1572.5,622,1589.5,621c29.72-1.75,79.41-21.48,111-14C1729.77,613.93,1734.72,632.72,1735.44,641Z"
        transform="translate(1.5 -6.85)"
        fill={colors.brand.primary}
      />
      <path
        d="M1730.44,641H1520.77c-.7-5.06-1.15-13.62,2.92-17.82C1534.5,612,1567.5,622,1584.5,621c29.72-1.75,79.41-21.48,111-14C1724.77,613.93,1729.72,632.72,1730.44,641Z"
        transform="translate(1.5 -6.85)"
        fill="none"
        stroke={colors.brand.dark}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      />
    </g>
    <g
      id="ground"
      fill="none"
      stroke={colors.brand.dark}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <line x1="1.5" y1="634.15" x2="1891.5" y2="634.15" />
      <line x1="238.5" y1="692.15" x2="519.5" y2="692.15" />
      <line x1="744" y1="692.65" x2="820" y2="692.65" />
      <line x1="873" y1="692.65" x2="1316" y2="692.65" />
    </g>
  </svg>
);

BrokerBackground.propTypes = {
  colors: shape().isRequired,
};

export default BrokerBackground;
