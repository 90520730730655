import React, { useState, useEffect } from 'react';
import { array, func, string } from 'prop-types';
import { SearchInput } from 'wg-fe-ui';
import styled from 'styled-components';
import Loupe from '../../assets/images/Loupe.svg';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistorySearch = ({
  unFilteredData,
  onFiltered,
  searchValue,
  className,
}) => {
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (inputValue === '') onFiltered(unFilteredData);
  });

  useEffect(() => {
    const filterValue = inputValue.toLowerCase();
    let filteredList = [];
    const filteredValues = filterValue.split(' ');
    filteredList = unFilteredData.filter(item => {
      let isIncluded = true;
      const itemList = Object.values(item);
      const secondItemList = [];
      for (let i = 0; i < itemList.length; i++) {
        secondItemList[i] = itemList[i].toString().toLowerCase();
      }
      const filteredSearchQueries = filteredValues.filter(function(el) {
        return el != null && el !== '';
      });
      filteredSearchQueries.forEach(searchQuery => {
        let matchedValues = 0;
        secondItemList.forEach(item => {
          if (item.includes(searchQuery.toString().toLowerCase())) {
            matchedValues += 1;
          }
        });
        if (matchedValues <= 0) {
          isIncluded = false;
        }
      });
      if (isIncluded) return item;
    });
    if (inputValue !== '') {
      onFiltered(filteredList);
    } else {
      onFiltered(unFilteredData);
    }
  }, [inputValue]);

  return (
    <Container className={className}>
      <SearchInput
        icon={<img src={Loupe} alt="search loupe" />}
        border={false}
        className=""
        onChange={val => {
          setInputValue(val);
          searchValue(val);
        }}
        placeholder={t('Search for a name')}
        text={inputValue}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

DashboardBrokerHistorySearch.defaultProps = {
  brokerHistoryData: [],
  getFilteredData: () => {},
  searchValue: () => {},
};
DashboardBrokerHistorySearch.propTypes = {
  className: string,
  unFilteredData: array,
  onFiltered: func,
  searchValue: func,
};

export default DashboardBrokerHistorySearch;
