import React from 'react';
import { string, array, func, number } from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import DashboardBrokerHistorySectionRow from './DashboardBrokerHistorySectionRow';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const DashboardBrokerHistorySection = ({
  date,
  brokerHistoryData,
  removeSession,
  sortId,
}) => {
  const { t } = useTranslation();
  const sortedBrokerHistoryData = brokerHistoryData
    .filter(obj => obj.createdDateOn === date)
    .sort(
      (a, b) =>
        new moment(b.createdTimeOn, 'HH:mm') -
        new moment(a.createdTimeOn, 'HH:mm'),
    );
  return (
    <section>
      <Table>
        <thead>
          <tr>
            <ThTitle style={{ width: '22%' }}>
              {moment(date, 'YYYY/MM/DD')
                .locale(i18next.language)
                .format('Do MMMM')}
            </ThTitle>
            <Th style={{ width: '12.5%' }}>
              {t('History.overview.createdOn')}
            </Th>
            <Th style={{ width: '22%' }}>{t('History.overview.street')}</Th>
            <Th style={{ width: '8%' }}>{t('History.overview.housenr')}</Th>
            <Th style={{ width: '12.5%' }}>{t('History.overview.zipcode')}</Th>
            <Th style={{ width: '18%' }}>{t('History.overview.city')}</Th>
          </tr>
        </thead>
        <Tbody>
          {sortedBrokerHistoryData.map((historyData, i) => (
            <DashboardBrokerHistorySectionRow
              historyData={historyData}
              sortId={sortId}
              keyId={i}
              key={i}
              id={i}
              removeSession={removeSession}
            />
          ))}
        </Tbody>
      </Table>
    </section>
  );
};

const MainTh = styled.th`
  padding-bottom: 1.3rem;
`;

const ThTitle = styled(MainTh)`
  font-weight: bold;
  font-size: 1.6rem;
  color: #5b5550;
`;

const Th = styled(MainTh)`
  font-size: 1.4rem;
  color: #a29c95;
`;

const Table = styled.table`
  width: 100%;
  text-align: left;
  margin-top: 2.5rem;
  margin-bottom: 4rem;
`;

const Tbody = styled.tbody`
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
`;

DashboardBrokerHistorySection.propTypes = {
  date: string.isRequired,
  brokerHistoryData: array,
  removeSession: func.isRequired,
  sortId: number,
};

export default DashboardBrokerHistorySection;
