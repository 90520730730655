import React from 'react';

const Quality = () => (
  <svg viewBox="0 0 220 220">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'quality'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M216.79 118.34a15.62 15.62 0 01-.18-18.73 15.61 15.61 0 00-4.76-22.77 15.63 15.63 0 01-7.78-13.51 15.85 15.85 0 01.45-3.73 15.07 15.07 0 00.46-3.74 15.63 15.63 0 00-13.56-15.47A15.64 15.64 0 01178 27.27a15.6 15.6 0 00-19.46-12.74 15.35 15.35 0 01-4 .53 15.63 15.63 0 01-13.34-7.53 15.63 15.63 0 00-22.86-4.32 15.57 15.57 0 01-9.49 3.21 15.57 15.57 0 01-9.23-3 15.61 15.61 0 00-22.77 4.73 15.65 15.65 0 01-13.52 7.78 15.69 15.69 0 01-3.72-.44 15.15 15.15 0 00-3.75-.49 15.64 15.64 0 00-15.47 13.58A15.61 15.61 0 0127.27 42a15.61 15.61 0 00-12.73 19.43 15.69 15.69 0 01.52 4A15.63 15.63 0 017.53 78.8a15.63 15.63 0 00-4.32 22.86 15.54 15.54 0 013.21 9.48 15.6 15.6 0 01-3 9.25 15.62 15.62 0 004.76 22.76 15.65 15.65 0 017.78 13.51 15.72 15.72 0 01-.44 3.73 15.15 15.15 0 00-.46 3.75 15.63 15.63 0 0013.56 15.47A15.61 15.61 0 0142 192.73a15.6 15.6 0 0019.46 12.74 15.42 15.42 0 014-.53 15.63 15.63 0 0113.36 7.53 15.63 15.63 0 0022.86 4.32 15.62 15.62 0 0118.73-.18 15.61 15.61 0 0022.77-4.76 15.64 15.64 0 0117.24-7.34 15.07 15.07 0 003.74.46 15.63 15.63 0 0015.47-13.55A15.63 15.63 0 01192.74 178a15.61 15.61 0 0012.73-19.47 15.27 15.27 0 01-.53-4 15.63 15.63 0 017.53-13.37 15.63 15.63 0 004.32-22.86zM110 194.11A84.11 84.11 0 11194.11 110 84.1 84.1 0 01110 194.11z"
        />
        <path
          className="prefix__cls-1"
          d="M110 42.23A67.77 67.77 0 10177.76 110 67.76 67.76 0 00110 42.23zm22.24 99.14L110 129.69l-22.24 11.68L92 116.61 74 99.08l24.87-3.62L110 72.94l11.11 22.52L146 99.08l-18 17.53z"
        />
      </g>
    </g>
  </svg>
);

export default Quality;
