import React from 'react';

const Juwels = () => (
  <svg viewBox="0 0 154.86 206">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'juwelsmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M54.5 18.97H37.92L54.6 0h15.42L54.5 18.97zM69.76 18.97l7.82-9.57 7.65 9.57H69.76zM100.36 18.97L85.19 0h15.03l16.67 18.97h-16.53zM63.54 56.65L53.28 30.78H37.59l22.82 25.87h3.13zM78.95 56.65l10.17-25.87H65.99l10.25 25.87h2.71zM94.67 56.65l22.6-25.87h-15.46L91.64 56.65h3.03z"
        />
        <path
          className="prefix__cls-1"
          d="M77.44 51a77.49 77.49 0 1077.42 77.48A77.55 77.55 0 0077.44 51zm58.87 77.48a58.88 58.88 0 11-58.87-58.89 59 59 0 0158.87 58.92z"
        />
      </g>
    </g>
  </svg>
);

export default Juwels;
