import React from 'react';
import { string } from 'prop-types';
import ALLRISK from '../../../../../assets/images/extentions/ALLRISK';
import ASSISTANCE_VEHICLE from '../../../../../assets/images/extentions/ASSISTANCE_VEHICLE';
import ASSISTANCE_PERSON from '../../../../../assets/images/extentions/ASSISTANCE_PERSON';
import BICYCLE from '../../../../../assets/images/extentions/BICYCLE';
import BUSINESS from '../../../../../assets/images/extentions/BUSINESS';
import CONTENT from '../../../../../assets/images/extentions/CONTENT';
import DRIVER from '../../../../../assets/images/extentions/DRIVER';
import EXTRA from '../../../../../assets/images/extentions/EXTRA';
import GARDEN from '../../../../../assets/images/extentions/GARDEN';
import INDIRECTRISK from '../../../../../assets/images/extentions/INDIRECTRISK';
import JOKER from '../../../../../assets/images/extentions/JOKER';
import LEGAL from '../../../../../assets/images/extentions/LEGAL';
import LEGAL_PLUS from '../../../../../assets/images/extentions/LEGAL_PLUS';
import LEISURE from '../../../../../assets/images/extentions/LEISURE';
import POOL from '../../../../../assets/images/extentions/POOL';
import CONSUMER from '../../../../../assets/images/extentions/CONSUMER';
import MOBILITY from '../../../../../assets/images/extentions/MOBILITY';
import REPLACEMENT from '../../../../../assets/images/extentions/REPLACEMENT';
import SUPPORT from '../../../../../assets/images/extentions/SUPPORT';
import SURROUND from '../../../../../assets/images/extentions/SURROUND';
import THEFT from '../../../../../assets/images/extentions/THEFT';
import AFTER_FIRE from '../../../../../assets/images/extentions/AFTER_FIRE';
import THEFTPLUS from '../../../../../assets/images/extentions/THEFT+';
import VEHICLE from '../../../../../assets/images/extentions/VEHICLE';
import OMNIUM from '../../../../../assets/images/extentions/OMNIUM';
import MINIOMNIUM from '../../../../../assets/images/extentions/MINIOMNIUM';
import PROPERTY from '../../../../../assets/images/extentions/PROPERTY';
import INCOME from '../../../../../assets/images/extentions/INCOME';

const LoadingExtentionIcons = ({ type, color }) => {
  if (type.includes('mini_omnium')) {
    return <MINIOMNIUM color={color} />;
  } else if (type.includes('omnium')) {
    return <OMNIUM color={color} />;
  }
  switch (type) {
    case 'all_risk':
      return <ALLRISK color={color} />;
    case 'assistance_vehicle':
      return <ASSISTANCE_VEHICLE color={color} />;
    case 'assistance_person':
      return <ASSISTANCE_PERSON color={color} />;
    case 'bicycle':
      return <BICYCLE color={color} />;
    case 'business':
      return <BUSINESS color={color} />;
    case 'content':
      return <CONTENT color={color} />;
    case 'driver':
      return <DRIVER color={color} />;
    case 'extra':
      return <EXTRA color={color} />;
    case 'full_assistance':
      return <ASSISTANCE_VEHICLE color={color} />;
    case 'garden':
      return <GARDEN color={color} />;
    case 'indirect_risk':
      return <INDIRECTRISK color={color} />;
    case 'joker':
      return <JOKER color={color} />;
    case 'legal':
      return <LEGAL color={color} />;
    case 'legal_plus':
      return <LEGAL_PLUS color={color} />;
    case 'leisure':
      return <LEISURE color={color} />;
    case 'pool':
      return <POOL color={color} />;
    case 'premium':
      return <EXTRA color={color} />;
    case 'property_plus':
      return <EXTRA color={color} />;
    case 'replacement':
      return <REPLACEMENT color={color} />;
    case 'road_user':
      return <ASSISTANCE_PERSON color={color} />;
    case 'safety':
      return <EXTRA color={color} />;
    case 'support':
      return <SUPPORT color={color} />;
    case 'surround':
      return <SURROUND color={color} />;
    case 'theft':
      return <THEFT color={color} />;
    case 'theft_plus':
      return <THEFTPLUS color={color} />;
    case 'vehicle':
      return <VEHICLE color={color} />;
    case 'support_after_fire':
      return <AFTER_FIRE color={color} />;
    case 'mobility':
      return <MOBILITY color={color} />;
    case 'consumer':
      return <CONSUMER color={color} />;
    case 'property':
      return <PROPERTY color={color} />;
    case 'income':
      return <INCOME color={color} />;
    default:
      return <p>[No Icon found]</p>;
  }
};

LoadingExtentionIcons.defaultProps = {
  type: '',
  color: '#FFFFFF',
};

LoadingExtentionIcons.propTypes = {
  type: string,
  color: string,
};

export default LoadingExtentionIcons;
