import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { func, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchSelectInput } from 'wg-fe-ui';
import * as Yup from 'yup';

import useForm from '../hooks/useForm';

const ResidenceCardInputs = ({ homeData, onChange, dataTestId }) => {
  const { t } = useTranslation();
  const [attachment, setAttachment] = useState('');
  const [cellar, setCellar] = useState('');
  const [attic, setAttic] = useState('');

  /* HELPER FUNCTIONS
  -------------------------------------------------- */
  const FindAttachmentEnums = attachment => {
    const result = attachmentTypes.filter(obj => {
      return obj.value === attachment;
    });

    return result;
  };

  const FindCellarEnums = cellar => {
    const result = cellarQuestions.filter(obj => {
      return obj.value === cellar;
    });

    return result;
  };

  const FindAtticEnums = attic => {
    const result = atticQuestions.filter(obj => {
      return obj.value === attic;
    });

    return result;
  };

  const setInitialFormValues = () => {
    if (homeData) {
      console.log('homeData', homeData);

      Object.keys(homeData).forEach(name => {
        console.log('INITDATA', name, homeData[name]);
        handleChange({ name, value: homeData[name] });
      });

      if (homeData?.parcel?.main_building?.house_type) {
        setAttachment(
          FindAttachmentEnums(
            homeData.parcel.main_building.house_type.toUpperCase(),
          ),
        );
      } else {
        setAttachment(FindAttachmentEnums('OPEN'));
      }

      if (homeData?.cellar) {
        setCellar(FindCellarEnums(homeData.cellar.toUpperCase()));
      } else {
        setCellar(FindCellarEnums('NONE'));
      }

      if (homeData?.attic) {
        setAttic(FindAtticEnums(homeData.attic.toUpperCase()));
      } else {
        setAttic(FindAtticEnums('NONE'));
      }
    }
  };

  const handleAttachmentChange = e => {
    const selectedAttachment = FindAttachmentEnums(e.value);
    setAttachment(selectedAttachment);
    handleChange({ name: 'attachment', value: selectedAttachment[0].value });
  };

  const handleCellarChange = e => {
    const selectedCellar = FindCellarEnums(e.value);
    setCellar(selectedCellar);
    handleChange({ name: 'cellar', value: selectedCellar[0].value });
  };

  const handleAtticChange = e => {
    const selectedAttic = FindAtticEnums(e.value);
    setAttic(selectedAttic);
    handleChange({ name: 'attic', value: selectedAttic[0].value });
  };

  const validationSchema = Yup.object().shape({});

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: validationSchema,
    mode: 'onChange',
  });

  useEffect(() => {
    handleSubmit(onChange);
  }, [values]);

  useEffect(() => {
    setInitialFormValues();
  }, [homeData]);

  const attachmentTypes = [
    { value: 'OPEN', label: t('OPEN') },
    { value: 'CLOSED', label: t('CLOSED') },
    { value: 'HALFOPEN', label: t('HALFOPEN') },
    { value: 'APARTMENT', label: t('APARTMENT') },
  ];

  const cellarQuestions = [
    { value: 'NONE', label: t('NONE') },
    { value: 'INHABITABLE', label: t('INHABITABLE_CELLAR') },
    { value: 'UNINHABITABLE', label: t('UNINHABITABLE_CELLAR') },
  ];

  const atticQuestions = [
    { value: 'NONE', label: t('NONE') },
    { value: 'INHABITABLE', label: t('INHABITABLE_ATTIC') },
    { value: 'UNINHABITABLE', label: t('UNINHABITABLE_ATTIC') },
  ];

  return (
    <Container>
      <SearchSelectInput
        error={errors.attachment}
        initial={attachment}
        name="attachment"
        onSelected={handleAttachmentChange}
        options={attachmentTypes}
        placeholder={t('Choose an option')}
        data-test-id={`${dataTestId}_building_type`}
      >
        {t('Building type')}
      </SearchSelectInput>
      <SearchSelectInput
        error={errors.cellar}
        initial={cellar}
        name="cellar"
        onSelected={handleCellarChange}
        options={cellarQuestions}
        placeholder={t('Choose an option')}
        data-test-id={`${dataTestId}_cellar`}
      >
        {t('Cellar')}
      </SearchSelectInput>
      <SearchSelectInput
        error={errors.attic}
        initial={attic}
        name="attic"
        onSelected={handleAtticChange}
        options={atticQuestions}
        placeholder={t('Choose an option')}
        data-test-id={`${dataTestId}_attic`}
      >
        {t('Attic')}
      </SearchSelectInput>
      {/* <StyledTextInput
        error={errors.kw}
        name="kw"
        type="text"
        value={values.kw}
        onChange={handleChange}
      >
        {t('Ground surface')}
        <span>kW</span>
      </StyledTextInput> */}
    </Container>
  );
};

const Container = styled.div`
  width: 55%;
  padding: 2.4rem;
`;

// const StyledTextInput = styled(TextInput)`
//   position: relative;
//   span {
//     background: #cccccc;
//     border-radius: 3px;
//     bottom: 0;
//     color: white;
//     font-weight: bold;
//     padding: 1.5rem;
//     position: absolute;
//     right: 0;
//   }
// `;

ResidenceCardInputs.propTypes = {
  onChange: func.isRequired,
  homeData: shape({}).isRequired,
  dataTestId: string,
};

export default ResidenceCardInputs;
