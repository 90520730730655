/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { useTranslation } from 'react-i18next';

// import cogoToast from 'cogo-toast';
import { IconStatusCheck } from 'wg-fe-ui';

const RiskObject = ({ name, label, logo, onChange, isSelected }) => {
  const [value, setValue] = useState(isSelected);

  useEffect(() => {
    setValue(isSelected);
  }, [isSelected]);

  useEffect(() => {
    onChange({ name, value });
  }, [value]);

  return (
    <StyledDiv
      checked={value}
      onClick={() => setValue(!value)}
      data-test-id="OfferedPolicy"
    >
      <SubDiv>
        <Image src={logo} alt={label} />
      </SubDiv>
      {value && (
        <div className="selected">
          <IconStatusCheck />
        </div>
      )}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  transition: 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: ${({ checked, theme }) =>
    checked ? `1px solid ${theme.brand.primary}` : '1px solid #f0f1f3'};
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: 14rem;
  background-color: ${({ checked, theme }) =>
    checked ? theme.brand.lightest : `white`};
  position: relative;

  :hover {
    border: ${({ theme }) => `1px solid ${theme.brand.primary}`};
  }

  div.selected {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.2rem;
    margin: auto;
    position: absolute;
    top: 0;
    right: -1.3rem;
    transform: translate(0, -50%);
    background-color: ${({ theme }) => theme.brand.primary};
    border-radius: 999px;
    svg {
      padding-right: 0.2rem;
      path {
        fill: white;
      }
    }
  }
`;

const SubDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 1.5rem;
`;

const Image = styled.div`
  background-image: url(${({ src }) => src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  height: 70%;
  margin: auto;
  width: 80%;
`;

export default RiskObject;
