import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { useHistory, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Layout from '../../LayoutFlow/views/App';
import InputQuestionBox from '../../../../components/InputQuestionBox';
import ChatBox from '../../../../components/ChatBox';

import {
  setNotice,
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

// import { setInsuredData } from '../store';

import regex from '../../../../services/regexService';
import { setInsuredData, getInsuredData } from '../store';
import { getInsurances } from '../../../../services/insurancesService';

const InsuranceNameLegal = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  addChatboxQuestion(t('what is the name of your insurer?'));
  const [selected, setSelected] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [btnText, setBtnText] = useState(t('Confirm'));
  const [sendStep, setSendStep] = useState('initial');
  const [options, setOptions] = useState([
    { value: 'loading', label: 'Loading ...' },
  ]);

  useEffect(() => {
    addBrowserHistory(location.pathname);
    getInsurances()
      .then(res => {
        const _options = [];
        Object.keys(res).forEach(key => {
          _options.push({ value: key, label: res[key] });
        });
        setOptions(_options);
        const { already_insured_with } = getInsuredData();
        if (already_insured_with) {
          setSelected(
            _options.filter(item => item.label === already_insured_with)[0],
          );
          setIsDisabled(false);
        }
      })
      .catch(err => {
        setOptions([{ value: `notfound`, label: `Fetch failed` }]);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (isPending) {
      clickHandler();
    }
  }, [selected]);

  useEffect(() => {
    let _btnText;
    let _isDisabled;
    switch (sendStep) {
      case 'pending':
        _btnText = t('Gathering your info');
        _isDisabled = true;
        break;
      case 'success':
        _btnText = t('Sent');
        _isDisabled = true;
        if (answer === 2) {
          addChatboxAnswer(
            t('Currently, I am insured with', {
              insurer: selected.label,
            }),
          );
        } else {
          addChatboxAnswer(t(`i don't know who I am insured with`));
        }
        history.push(getNextPath());
        break;
      case 'error':
        _isDisabled = false;
        setIsPending(false);
        setAnswer(null);
        setError(t(`${getComponentName()}.error`));
        _btnText = t('Confirm');
        break;
      default:
        _btnText = t('Confirm');
        _isDisabled = false;
        break;
    }

    if (_btnText !== btnText) {
      setBtnText(_btnText);
      setIsDisabled(_isDisabled);
    }
  }, [sendStep]);

  const handleChange = _selected => {
    const _isDisabled = _selected === null;
    setSelected(_selected);
    setError(null);
    setIsDisabled(_isDisabled);
  };

  const handleAnswer = answer => {
    if (answer === t('I don t know')) {
      setIsPending(true);
      setAnswer(1);
      setSelected({ value: '--', label: null });
    } else {
      if (!selected.label.match(regex.insuranceName))
        return setSendStep('error');
      if (selected) {
        setIsPending(true);
        setAnswer(2);
        clickHandler();
      }
    }
  };

  const clickHandler = () => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    if (selected.label) {
      if (!selected.label.match(regex.insuranceName))
        return setSendStep('error');
    }
    setSendStep('pending');
    setInsuredData(
      'already_insured_with',
      selected.value === '' ? false : selected.label,
    );
    setNotice('legal', true, selected.label);
    setSendStep('success');
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <InputQuestionBox
          Answer1={t('I don t know')}
          Answer2={'Confirm'}
          dataTestId1="underwriting_answer_dontknow"
          dataTestId2="underwriting_answer_confirm"
          shouldLoad={isPending ? answer : undefined}
          mainDisabled={isDisabled}
          response={answer => handleAnswer(answer)}
        >
          <Input
            onChange={e => handleChange(e)}
            options={options}
            onBlurResetsInput={false}
            isClearable
            menuPlacement="top"
            noOptionsMessage={() => t('No insurers were found')}
            classNamePrefix="Select"
            value={selected}
            placeholder={t('Insurer name')}
          />
          {error ? <p>{error}</p> : null}
        </InputQuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  overflow: hidden;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;

const Input = styled(Select)`
  width: 100%;
  height: 4rem;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__control {
      height: 100%;
      border-radius: 0.5em;
      border-color: ${({ theme, hasValue }) =>
        hasValue ? theme.brand.primary : `#ccc`};
      z-index: 999;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: ${({ theme }) => theme.typo.text};
      }
    }
  }
`;

InsuranceNameLegal.propTypes = {
  history: shape().isRequired,
};

export default InsuranceNameLegal;
