import React, { useState } from 'react';
import styled from 'styled-components';
import { getPolicyPropPdf } from '../store';
import CircleSpinner from '../../../../components/CircleSpinner';
import DownloadIcon from '../../../../../assets/images/download.svg';

const MarketplaceInsuranceCardPrintButton = ({ insuranceId, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return <StyledLoadingSpinner />;
  } else {
    return (
      <>
        <Icon
          src={DownloadIcon}
          disabled={disabled}
          onClick={() => {
            if (!disabled) {
              setIsLoading(true);
              getPolicyPropPdf(insuranceId).then(() => setIsLoading(false));
            }
          }}
        />
      </>
    );
  }
};

const Icon = styled.img`
  width: 3rem;
  height: 3rem;
  /* background-color: ${({ theme }) => theme.brand.primary}; */
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
  opacity: ${props => (props.disabled ? '0.25' : '1')};
`;

const StyledLoadingSpinner = styled(CircleSpinner)`
  position: static;
  width: 3rem;
  height: 3rem;
  border-color: ${({ theme }) => theme.brand.primary};
  border-right-color: transparent;
  user-select: none;
`;

export default MarketplaceInsuranceCardPrintButton;
