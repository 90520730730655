import React, { useEffect } from 'react';
import { func, string, number, object } from 'prop-types';
import styled from 'styled-components';
import {
  RoomInput,
  IconFurnitureFilled,
  IconGarageFilled,
  IconDressingFilled,
  IconBedroomFilled,
  IconBathroomFilled,
  IconFireplaceFilled,
  IconKitchenFilled,
  IconOtherRoomsFilled,
  IconPlayroomFilled,
  IconPrivateHomeOfficeFilled,
  IconStorageRoomFilled,
  IconWashingRoomFilled,
  IconWellnessRoomFilled,
  IconPorch,
  IconArtFilled,
  IconComputerFilled,
} from 'wg-fe-ui';

const ProFlowRoomsInput = ({
  field,
  title,
  roomData,
  setRoomData,
  value,
  description,
  onChange,
  name,
  ...otherProps
}) => {
  const roomDataMap = {
    rooms_living_room: 'living_rooms',
    rooms_kitchen: 'kitchens',
    rooms_bedroom: 'bedrooms',
    rooms_bathroom: 'bathrooms',
    rooms_storage_room: 'storage_rooms',
    rooms_other: 'other_rooms',
    rooms_garage_spaces: 'garages',
  };

  useEffect(() => {
    if (roomData) {
      const roomDataMapped = mapRoomData();
      if (roomDataMapped[field]) {
        value = roomDataMapped[field];
        onChange(value);
      }
    }
  }, [roomData]);

  function updateRoom(value) {
    let newRoomData = roomData;
    newRoomData[roomDataMap[name]] = value;
    setRoomData(newRoomData);
  }

  // This function should be removed once the room estimate has been updated on backend
  function mapRoomData() {
    const newRoomData = {
      rooms_living_room: roomData.living_rooms,
      rooms_kitchen: roomData.kitchens,
      rooms_bedroom: roomData.bedrooms,
      rooms_bathroom: roomData.athrooms,
      rooms_storage_room: roomData.storage_rooms,
      rooms_other: roomData.other_rooms,
      rooms_garage_spaces: roomData.garages,
    };

    return newRoomData;
  }

  // Rooms mapped for their icons
  const icons = {
    'e737161b-3cfd-4c60-9489-92a5e176d110': (
      <IconFurnitureFilled color="black" size={50} />
    ), // Living room
    'bba0cd32-0548-4a7c-a968-4a80054ddc5d': (
      <IconKitchenFilled color="black" size={50} />
    ), // Kitchen
    'be0d8617-305e-440c-baed-eb8b00018ddc': (
      <IconBedroomFilled color="black" size={50} />
    ), // Bedroom
    '45fefecb-ac86-445b-b7d4-d2a2bc03545b': (
      <IconPrivateHomeOfficeFilled color="black" size={50} />
    ), // Private office
    '4ddfd8b5-c294-4295-9f0e-e11c7c8d09e5': (
      <IconPlayroomFilled color="black" size={50} />
    ), // Play room
    'ecf26335-9c45-4e1a-8994-4e772d840a54': (
      <IconArtFilled color="black" size={50} />
    ), // Hobby room
    '417b173f-2445-4bc2-9e68-d28c22bc6148': (
      <IconPorch color="black" size={50} />
    ), // Veranda
    '9887a02f-7642-48fe-b05e-565a2f37c4fb': (
      <IconComputerFilled color="black" size={50} />
    ), // Professional office
    '3f81d82c-b8fd-4098-a97f-45abab27d61e': (
      <IconWashingRoomFilled color="black" size={50} />
    ), // Washing room
    '06585ca4-8086-47f2-906a-8374ef78b3e8': (
      <IconFireplaceFilled color="black" size={50} />
    ), // Fireplace
    'd39104c4-0ad4-4954-b385-0f56605d8941': (
      <IconStorageRoomFilled color="black" size={50} />
    ), // Storage room
    '9c2d32db-366a-4fbc-8974-7b3a5cafda76': (
      <IconDressingFilled color="black" size={50} />
    ), // Dressing room
    '8f719616-5b7f-48df-9b56-c8e2b1c6a2cd': (
      <IconGarageFilled color="black" size={50} />
    ), // Garage space
    '2e99defe-6732-489a-afe8-89e398e92f5f': (
      <IconWellnessRoomFilled color="black" size={50} />
    ), // Welness room
    '88ab570c-407a-4ff1-a71d-fd5bad1bd356': (
      <IconBathroomFilled color="black" size={50} />
    ), // Bathroom
    '017999ff-f88b-4c64-8e29-9dd3fee43b2e': (
      <IconOtherRoomsFilled color="black" size={50} />
    ), // Other rooms
  };

  return (
    <RoomsInputContainer {...otherProps} key={field}>
      <RoomInput
        key={field}
        icon={icons[name]}
        label={title}
        info={description}
        value={value ? value : 0}
        maxValue={10}
        minValue={0}
        onInputChange={value => {
          onChange(value);
          updateRoom(value);
        }}
      />
    </RoomsInputContainer>
  );
};

const RoomsInputContainer = styled.div`
  margin-bottom: 2.4rem;

  & > * {
    height: 12rem;
  }

  & > div > div:nth-child(2) {
    padding: 1.5rem;
  }

  & > div > div:nth-child(2) > div:first-child {
    flex-grow: 1;
  }

  & > div > div:nth-child(2) > div:first-child > div:last-child {
    margin-right: 0.4rem;
  }

  & > div:nth-child(2) {
  }
`;

ProFlowRoomsInput.propTypes = {
  field: string.isRequired,
  title: string.isRequired,
  description: string,
  value: number.isRequired,
  roomData: object.isRequired,
  setRoomData: func.isRequired,
  onChange: func.isRequired,
  name: string.isRequired,
};

export default ProFlowRoomsInput;
