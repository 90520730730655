import ApiRoutes from '../../../../store/ApiRoutes';

const api = new ApiRoutes();

const data = {
  extras: {
    informatics: false,
    bicycle: false,
    e_step: false,
    juwels: false,
    art: false,
    drone: false,
    pets: false,
    garden: false,
  },
};

const dataById = {};

const addDataForIdLock = id => {
  dataById[id] = JSON.parse(JSON.stringify(data));
};

const setExtra = (key, value) => {
  dataById[sessionStorage.getItem('id')].extras[key] = value;
};

const getExtras = () => {
  return dataById[sessionStorage.getItem('id')].extras;
};

const sendExtras = () =>
  api.postExtras(JSON.stringify(dataById[sessionStorage.getItem('id')].extras));

export { setExtra, sendExtras, getExtras, addDataForIdLock };
