import React from 'react';

const TransportationPlane = () => (
  <svg viewBox="0 0 262.96 137.99">
    <title>{'transportation_planematrix_family_'}</title>
    <path
      d="M262.69 86.92l-24.86 2.72a7.17 7.17 0 01-8-6.29 7 7 0 016.17-7.47l20.5-2.25a25.48 25.48 0 00-21.86-8.43l-55.27 6.1L105.75 0 84.11 2.4l30.36 76.1-66.39 7.35L21.64 45 0 47.38l7.36 66.28a10.05 10.05 0 003.71 6.75l14.18 12.81a18.47 18.47 0 0014.41 4.66l200.57-22a25.51 25.51 0 0022.58-28.13c-.03-.25-.07-.55-.12-.83zm-98.14 5.51a5.19 5.19 0 01-5.1 5.77 5.35 5.35 0 01-5.26-4.85l-.33-3a5.4 5.4 0 014.73-6 5.21 5.21 0 015.59 4.65zm19.17-2.1a5.21 5.21 0 01-5.14 5.77 5.35 5.35 0 01-5.23-4.86l-.33-3a5.4 5.4 0 014.73-6 5.16 5.16 0 013.67 1.13 5.25 5.25 0 011.92 3.49zM202.84 88a5.35 5.35 0 01-4.06 5.88 5.21 5.21 0 01-6.26-4.52l-.38-3.45a5.21 5.21 0 014.61-5.74 5.22 5.22 0 015.74 4.61zM222 86.14a5.21 5.21 0 01-5.11 5.77 5.35 5.35 0 01-5.26-4.85l-.33-3a5.4 5.4 0 014.73-6 5.21 5.21 0 015.59 4.62z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default TransportationPlane;
