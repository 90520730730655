import React, { useEffect, useContext, useState } from 'react';
import Layout from '../../DashboardLayout/views/Layout';
import CompanyInfo from '../components/CompanyInfo';
import PersonalInfo from '../components/PersonalInfo';
import LanguageSettings from '../components/LanguageSettings';
import Customization from '../components/Customization';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';
import { isObjectEmpty } from '../../../services/objectService';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import {
  getBrokerData,
  getBrokerDistribution,
  retrieveBrokerId,
} from '../../../services/brokerDataService';
import * as Yup from 'yup';
import regexService from '../../../services/regexService';
import useForm from '../../../../hooks/useForm';
import Button from '../../../components/NewButton';
import { getTrialAccount } from '../../../store';
import { getMeData, resetMeData } from '../../../services/meDataService';
import {
  patchDistributionById,
  patchBrokerById,
  updateUserById,
  uploadBrokerAvatar,
} from '../../../services/apiRouterService';
import cogoToast from 'cogo-toast';
import { ZendeskAPI } from 'react-zendesk';

const SettingsAccountSettings = () => {
  const { t, i18n } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const [brokerDistribution, setBrokerDistribution] = useState({});
  const [userCanChange, setUserCanChange] = useState(false);

  useEffect(() => {
    initializeTabs();
    loadBrokerData();
    CheckPermission();
  }, []);

  function initializeTabs() {
    setNavTabs([
      {
        title: t('Account settings'),
        testId: 'settings_account_settings',
        to: '/settings/account-settings',
      },
      {
        title: t('Change password'),
        testId: 'settings_user_settings',
        to: '/settings/user-settings',
      },
      {
        title: t('Edit affinities'),
        testId: 'settings_affinity_settings',
        to: '/settings/affinity-settings',
      },
    ]);
  }

  const superBroker = [
    'arvid@wegroup.be',
    'louise@wegroup.be',
    'leander@wegroup.be',
    'laurien@wegroup.be',
    'remi@wegroup.be',
  ];

  const themeCamelToSnake = {
    orange: 'orange',
    lightBlue: 'light_blue',
    oceanBlue: 'ocean_blue',
    darkBlue: 'dark_blue',
    lightRed: 'light_red',
    darkRed: 'dark_red',
    pinkPurple: 'pink_purple',
    darkGreen: 'dark_green',
    lightGreen: 'light_green',
    oceanGreen: 'ocean_green',
    darkGrey: 'dark_grey',
  };

  const themeSnakeToCamel = {
    orange: 'orange',
    light_blue: 'lightBlue',
    ocean_blue: 'oceanBlue',
    dark_blue: 'darkBlue',
    light_red: 'lightRed',
    dark_red: 'darkRed',
    pink_purple: 'pinkPurple',
    dark_green: 'darkGreen',
    light_green: 'lightGreen',
    ocean_green: 'oceanGreen',
    dark_grey: 'darkGrey',
  };

  const languageOptions = [
    {
      value: 'en',
      label: t('English'),
    },
    {
      value: 'nl',
      label: t('Dutch'),
    },
    {
      value: 'fr',
      label: t('French'),
    },
  ];

  const CheckPermission = async () => {
    const { me, email } = await getMeData();
    if (superBroker.includes(email) || acceptedRoles.includes(me?.role)) {
      setUserCanChange(true);
    } else {
      setUserCanChange(false);
    }
  };

  const acceptedRoles = ['broker_silver', 'broker_gold', 'admin', 'developer'];

  async function loadBrokerData() {
    const brokerInfo = await getBrokerData();
    const distribution = await getBrokerDistribution();
    const meData = await getMeData();

    setBrokerDistribution(distribution);

    setDefaultFormFields(brokerInfo, distribution, meData);
  }

  function setDefaultFormFields(brokerInfo, distribution, meData) {
    const { email } = distribution || {};
    const { broker, theme } = brokerInfo || {};
    const { me } = meData || {};

    const themeName = theme?.name;
    let parsedThemeName;
    try {
      parsedThemeName = themeSnakeToCamel[themeName];
    } catch {
      parsedThemeName = themeName;
    }

    handleChange({ name: 'email', value: email });

    handleChange({ name: 'phone', value: broker?.phone });
    handleChange({ name: 'assistantName', value: broker?.assistant_name });
    handleChange({ name: 'theme', value: parsedThemeName });

    handleChange({ name: 'language', value: me?.language });

    handleChange({ name: 'logo', value: {} });
  }

  const SignupSchema = Yup.object().shape({
    email: Yup.string().matches(
      regexService.emailRegex,
      t(`Verify.errors.email`),
    ),
    phone: Yup.string(),
    language: Yup.string(),
  });

  let { handleChange, handleSubmit, errors, values } = useForm({
    validationSchema: SignupSchema,
  });

  async function onSubmit(data) {
    const { email, phone, assistantName, theme, language, logo } = data;
    const { me } = await getMeData();
    await sendDistributionUpdates(email);
    await sendBrokerUpdates(phone, assistantName, theme);
    await sendLanguage(language);
    await uploadAvatar(logo);

    // If language was changed, refresh the page to set the proper i18n language
    if (me.language !== language?.toUpperCase()) {
      i18n.changeLanguage(language.toLowerCase()).then(t => {
        cogoToast.success(t('Settings successfully saved'));
      });
    } else {
      cogoToast.success(t('Settings successfully saved'));
    }

    resetMeData();
  }

  async function sendDistributionUpdates(email) {
    const { id: _id, email: _email } = await getBrokerDistribution();
    if (_email !== email) {
      await patchDistributionById(_id, { email });
    }
  }

  async function sendBrokerUpdates(phone, assistantName, theme) {
    const { broker: _broker, broker: _theme } = await getBrokerData();
    if (
      _broker.phone !== phone ||
      _broker.assistantName !== assistantName ||
      _theme.name !== theme
    ) {
      await patchBrokerById(await retrieveBrokerId(), {
        phone,
        assistant_name: assistantName,
        theme: themeCamelToSnake[theme],
      });
    }
  }

  async function sendLanguage(language) {
    const { id: _id, me } = await getMeData();
    if (me.language === language?.toUpperCase()) return;
    await updateUserById(_id, { language: language?.toUpperCase() });
    // https://support.zendesk.com/hc/en-us/articles/203761906-Language-codes-for-Zendesk-supported-languages
    ZendeskAPI(
      'webWidget',
      'setLocale',
      language === 'en' ? 'en-US' : language,
    );
  }

  async function uploadAvatar(avatar) {
    const { id: _id } = await getMeData();
    if (isObjectEmpty(avatar)) return;
    const { logo, fileName } = avatar;
    const blobImage = b64toBlob(logo);

    let formData = new FormData();

    formData.append('file', blobImage, fileName);

    await uploadBrokerAvatar(_id, formData);
  }

  const b64toBlob = dataURI => {
    let byteString = window.atob(dataURI.split(',')[1].replace(/\s/g, ''));

    let mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    let arrayBuffer = new ArrayBuffer(byteString.length);
    let _ia = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    let dataView = new DataView(arrayBuffer);
    let blob = new Blob([dataView.buffer], { type: mimeString });

    return blob;
  };

  return (
    <StyledLayout>
      <Header>
        <div>
          <Line />
          <Button
            dataTestId="dashboard_account_settings_submit"
            onClick={() => {
              handleSubmit(onSubmit);
            }}
            type="submit"
          >
            {t('Save changes')}
          </Button>
        </div>
      </Header>
      <form className="content" data-test-id="dashboard_account_settings_form">
        <CompanyInfo distribution={brokerDistribution} />
        <PersonalInfo
          values={values}
          distribution={brokerDistribution}
          errors={errors}
          handleChange={handleChange}
          disabled={getTrialAccount()}
        />
        <LanguageSettings
          languageOptions={languageOptions}
          languageSelector={languageOptions.filter(
            lang => lang.value === values?.language?.toLowerCase(),
          )}
          handleChange={handleChange}
        />
        <Customization
          handleChange={handleChange}
          values={values}
          disabled={!userCanChange}
        />
      </form>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  padding-top: 7rem;
  width: 90%;
  max-width: 100rem;

  .content {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
`;

const Header = styled.div`
  width: 100% - 25rem;
  left: 25rem;
  right: 0;
  top: 8rem;
  position: fixed;
  display: flex;
  background-color: #f7f7f7;
  justify-content: center;
  z-index: 5;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

  & > div {
    height: 7rem;
    width: 90%;
    max-width: 100rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;

const Line = styled.div`
  width: 0.1rem;
  height: 80%;
  background-color: #ccc;
  margin: 0 2.5rem;
`;

SettingsAccountSettings.propTypes = {
  history: shape().isRequired,
};

export default withRouter(SettingsAccountSettings);
