import React from 'react';
import { shape } from 'prop-types';
// eslint-disable

const ArtLock = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 546 492"
    style={{ width: `100%`, height: `100%` }}
  >
    <rect
      style={{ fill: `${colors.brand.primary}` }}
      x="20"
      y="93"
      width="497"
      height="372"
    />
    <circle style={{ fill: `${colors.brand.dark}` }} cx="346" cy="324" r="48" />
    <path
      style={{ fill: `${colors.brand.secondary}` }}
      d="M509,324V452H279A128,128,0,0,1,407,324Z"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M43,283H226A162,162,0,0,1,388,445v0a0,0,0,0,1,0,0H43a0,0,0,0,1,0,0V283A0,0,0,0,1,43,283Z"
    />
    <polygon
      style={{ fill: `white`, opacity: 0.1 }}
      points="517 306.03 517 342.52 377.57 465 336.02 465 517 306.03"
    />
    <polygon
      style={{ fill: `white`, opacity: 0.1 }}
      points="517 189.56 517 261.75 285.62 465 203.44 465 517 189.56"
    />
    <path
      style={{
        fill: `none`,
        stroke: `${colors.brand.primary}`,
        strokeMiterLimit: 10,
        strokeWidth: `12px`,
      }}
      d="M388.5,93,300.58,19.55a42.7,42.7,0,0,0-55.16,0L153.5,96"
    />
    <path
      style={{ fill: `${colors.brand.dark}` }}
      d="M6,80.6V486H539.53V80.6ZM476,429H70V137H476Z"
    />
    <circle style={{ fill: `${colors.brand.dark}` }} cx="271" cy="13" r="13" />
    <rect
      style={{
        fill: `none`,
        stroke: `${colors.brand.primary}`,
        strokeMiterLimit: 10,
        strokeWidth: `12px`,
      }}
      x="71"
      y="137.5"
      width="405"
      height="291.5"
    />
    <rect
      style={{
        fill: `none`,
        stroke: `${colors.brand.primary}`,
        strokeMiterLimit: 10,
        strokeWidth: `12px`,
      }}
      x="6"
      y="81"
      width="534"
      height="405"
    />
  </svg>
);

ArtLock.propTypes = {
  colors: shape().isRequired,
};

export default ArtLock;
