import React from 'react';
import { string, shape } from 'prop-types';

const Allrisk = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.36 240">
    <title>{title}</title>
    <g id="icons">
      <path
        fill={color}
        d="M236.36,95.79a8.12,8.12,0,0,1-8.09,8.12l-27.68,0,0,27.68a8.11,8.11,0,0,1-7.6,8.1h-.5a8.09,8.09,0,0,1-8.1-8.09L184.39,104l-27.68.05h0a8.07,8.07,0,0,1-5.72-2.36,8,8,0,0,1-2.27-4.47A7.45,7.45,0,0,1,148.6,96a8.09,8.09,0,0,1,8.08-8.11l27.68,0-.05-27.69a8.43,8.43,0,0,1,.06-1,8.12,8.12,0,0,1,8-7.1h0a8.11,8.11,0,0,1,8.1,8.08l0,24.72c.45,1,.87,2,1.28,3l26.42-.06h0a8.11,8.11,0,0,1,8.1,8.08Z"
      />
      <path
        fill={color}
        d="M200.54,84.8v3h1.27C201.41,86.77,201,85.78,200.54,84.8Z"
      />
      <path
        fill={color}
        d="M174.31,60.13l0,17.68-17.68,0A18.1,18.1,0,0,0,138.6,96c0,.41,0,.82.05,1.23H110.52V216.26a23.74,23.74,0,0,1-47.48,0V204.17a7.93,7.93,0,1,1,15.86,0v12.09a7.88,7.88,0,0,0,15.76,0v-119H0a107.27,107.27,0,0,1,94.66-75.7V7.93a7.93,7.93,0,1,1,15.86,0V21.51A106.79,106.79,0,0,1,176.76,51,17.85,17.85,0,0,0,174.31,60.13Z"
      />
    </g>
  </svg>
);

Allrisk.defaultProps = {
  title: '',
  color: '',
};

Allrisk.propTypes = {
  title: string,
  color: shape(),
};

export default Allrisk;
