import React, { useContext } from 'react';
import { getTrialAccount } from '../../../store';
import { FlagsProvider } from '../../../hoc/FlagsProviderHoc';
import { useHistory, Redirect } from 'react-router';

import { getMeData } from '../../../services/meDataService';

const HandleSettingsPage = () => {
  const configCatClient = useContext(FlagsProvider);
  const history = useHistory();

  const setFeatures = async () => {
    const { me } = (await getMeData()) || {};

    const emailConfig = {
      email: me?.email,
    };

    return await configCatClient.getValueAsync(
      'passwordSettingsOnly',
      false,
      emailConfig,
    );
  };

  const isTrialAccount = getTrialAccount();

  setFeatures().then(passwordSettingsOnly => {
    if (isTrialAccount || passwordSettingsOnly) {
      history.push('/settings/user-settings');
    } else {
      history.push('/settings/account-settings');
    }
  });

  return <Redirect to={'/settings/user-settings'} />;
};

export default HandleSettingsPage;
