import React from 'react';
import { Section } from 'wg-fe-ui';
import { object } from 'prop-types';
import AddressDataTable from './AddressDataTable';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AddressProperty = ({ addressData }) => {
  const { t } = useTranslation();

  return (
    <Section data-test-id="dashboard_toolkit_addresslookup_section_addresproperty">
      <Section.Title>{t('Property')}</Section.Title>
      <StyledContent background>
        <AddressDataTable
          addressData={addressData}
          data-test-id="dashboard_toolkit_addresslookup_section_addresproperty_addresstable"
        />
      </StyledContent>
    </Section>
  );
};

const StyledContent = styled(Section.Content)`
  padding: 0;
`;

AddressProperty.propTypes = {
  addressData: object.isRequired,
};

export default AddressProperty;
