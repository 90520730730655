import React from 'react';
import { string, func, shape, bool } from 'prop-types';
// import { Section } from 'wg-fe-ui';
// import styled from 'styled-components';
import AddressSearchSelectorManualFallback from './AddressSearchSelectorManualFallback';
// import { useTranslation } from 'react-i18next';

const ProFlowInputSpecialTypeAddress = ({
  title,
  onSubmit,
  value,
  error,
  prefilled,
  ...otherProps
}) => {
  // const { t } = useTranslation();
  return (
    // <Section>
    //   <Section.Title>Address</Section.Title>
    //   <SectionContent background>
    <AddressSearchSelectorManualFallback
      onSubmit={onSubmit}
      prefilled={prefilled}
      setValue={value}
      error={error}
      title={title}
      {...otherProps}
    />
    //   </SectionContent>
    // </Section>
  );
};

// const SectionContent = styled(Section.Content)`
//   border: 1px solid #e4e4ec;
//   box-shadow: 0 6px 16px rgba(181, 181, 181, 0.25);
//   border-radius: 5px;
// `;

ProFlowInputSpecialTypeAddress.propTypes = {
  name: string.isRequired,
  title: string.isRequired,
  onSubmit: func.isRequired,
  prefilled: bool,
  value: shape(),
  error: string,
};

export default ProFlowInputSpecialTypeAddress;
