import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Layout from '../../DashboardLayout/views/Layout';
import DashboardToolbarBrokerHistory from '../../../components/DashboardToolbarBrokerHistory';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';

import {
  getHistory,
  setInsurTypes,
  setTabItems,
} from '../../../services/brokerhistoryService';
import { useTranslation } from 'react-i18next';
import DashboardBrokerHistoryConversationInformation from '../../../components/DashboardBrokerHistoryConversationInformation';
import DashboardBrokerHistoryPoliciesSection from '../../../components/DashboardBrokerHistoryPoliciesSection';
import { getNestedObject } from '../../../services/objectService';

const BrokerHistoryDetailPolicies = ({ match, history }) => {
  const { setNavTabs } = useContext(TopNavigationItems);
  const [filteredInsureTypes, setFilteredInsureTypes] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [policiesData, setPoliciesData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setInsurTypes(match.params.id, setSessionData).then(res => {
      setSessionData(res.sessionData);
      setFilteredInsureTypes(res.filteredData);
      const tmpItems = setTabItems(match.params.id, res.sessionData, t);
      setNavTabs(tmpItems);
    });
  }, []);

  useEffect(() => {
    // all policies go into an object per category
    const allPolicies = getNestedObject(sessionData, ['policy_contracts'], []);
    const tmpObj = {};
    allPolicies.forEach(policy => {
      tmpObj[getNestedObject(policy, ['policy', 'insurance', 'type'])] = policy;
    });

    if (tmpObj !== policiesData) {
      setPoliciesData(tmpObj);
    }
  }, [sessionData]);

  return (
    <StyledLayout>
      <DashboardToolbarBrokerHistory
        onClick={() => {
          return getHistory(
            match.params.id,
            filteredInsureTypes,
            history,
            t,
            sessionData,
          );
        }}
        dataTestId="dashboard_history_detail_calculate"
        btnText={t('History.goToMarket')}
      />
      <SectionWrapper>
        <DashboardBrokerHistoryConversationInformation
          sessionData={sessionData}
        />
        {Object.keys(policiesData).map((policyKey, key) => {
          const title = t(`Coverages.${policyKey}`);
          return (
            <DashboardBrokerHistoryPoliciesSection
              policiesData={policiesData[policyKey]}
              key={key}
              title={title}
            />
          );
        })}
      </SectionWrapper>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  margin-bottom: 10rem;
`;

BrokerHistoryDetailPolicies.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

export default withRouter(BrokerHistoryDetailPolicies);
