import React, { useEffect } from 'react';
import { logout } from '../../../services/apiRouterService';
import { LoadingSpinner } from 'wg-fe-ui';
import styled from 'styled-components';
import { ZendeskAPI } from 'react-zendesk';

const Logout = () => {
  useEffect(() => {
    logoutAndGoToLogin();
  }, []);

  async function logoutAndGoToLogin() {
    await logout();
    ZendeskAPI('webWidget', 'logout');
    ZendeskAPI('webWidget', 'clear');
    ZendeskAPI('webWidget', 'hide');
    window.location.href = await '#/login';
  }

  return (
    <LoadingContainer>
      <LoadingSpinner />
    </LoadingContainer>
  );
};

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Logout;
