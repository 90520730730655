import React from 'react';
import { shape } from 'prop-types';

const MarketIconCar = ({ colors }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 74.03 44.84"
    width="100%"
    height="100%"
  >
    <title>Car</title>
    <g id="Car">
      <path
        style={{
          fill: `${colors.brand.primary}`,
          stroke: colors.brand.dark,
          strokeWidth: 3,
          strokeLineJoin: `round`,
        }}
        d="M48.87,54a5.62,5.62,0,0,1-1.52-.2A5.9,5.9,0,0,1,43.76,51a7,7,0,0,1-.65-5.22l.16-.59-.37-.49c-.34-.45-.34-.45-14.83-4.36-14.24-3.84-14.52-3.84-14.73-3.84h-.08l-.74.09-.36.71,0,.13A6.48,6.48,0,0,1,6,42.47a6.74,6.74,0,0,1-1.77-.24A5.73,5.73,0,0,1,.8,39.44,7,7,0,0,1,.19,34.2a13.41,13.41,0,0,1,.54-1.34l.48-1.08-1-.55s-4.54-2.36-5.73-3.06a.84.84,0,0,1-.12-.73c.68-2.57,1.37-5.15,2.07-7.72a.7.7,0,0,1,.67-.59c3.73.12,7.43.26,11.13.4l2.78.11c1.74.08,3.48.16,5.22.2h.14a6.44,6.44,0,0,0,3.4-1l10.91-6.76a2.52,2.52,0,0,1,1.3-.4,2.47,2.47,0,0,1,.6.08l26.59,7.12A1.58,1.58,0,0,1,60.28,20q2.69,8,5.33,16a4.09,4.09,0,0,1,0,2.38c-.58,2.23-1.19,4.47-1.79,6.69l-.23.85c-.19.71-.57.83-.91.85s-.74,0-2,0c-1.54,0-3.39,0-4,0h0A1.49,1.49,0,0,0,55.27,48c-.07.44-.14.84-.19,1A6.57,6.57,0,0,1,48.87,54Z"
        transform="translate(6.97 -10.4)"
      />
    </g>
  </svg>
);

MarketIconCar.propTypes = {
  colors: shape().isRequired,
};

export default MarketIconCar;
