import React from 'react';

const SwimmingPool = () => (
  <svg viewBox="0 0 235.27 154.23">
    <title>{'swimming_poolmatrix_family_'}</title>
    <path
      d="M230.51 60.2a4.77 4.77 0 00-4.77 4.8v21.12c-21.13 10.26-47.92 15.46-74.74 12.62-16.31-1.72-31.54-5.67-46.59-9.92V26.79A26.83 26.83 0 0077.57 0 26.52 26.52 0 0065 3.28 33 33 0 0170.7 11a17.12 17.12 0 016.87-1.46 17.3 17.3 0 0117.27 17.25v17.6h-26v-17.6a26.8 26.8 0 00-53.6 0 4.77 4.77 0 009.53 0 17.28 17.28 0 1134.55 0v50.33c-5.54-1.09-11.14-2-16.88-2.65a152.42 152.42 0 00-32.89.37V65A4.76 4.76 0 000 65v84.51a4.77 4.77 0 004.76 4.76h225.75a4.77 4.77 0 004.76-4.76V65a4.76 4.76 0 00-4.76-4.8zm-161.7-6.3h26v9.34h-26zm0 18.84h26v9.34H80.34c-3.83-1-7.66-2-11.53-2.94z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default SwimmingPool;
