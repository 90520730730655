/* stylelint-disable declaration-block-no-redundant-longhand-properties, declaration-no-important */
import React, { useState } from 'react';
import styled from 'styled-components';
import { shape, bool, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Balloon from './Balloon';

import {
  getChatboxAnswers,
  getChatboxQuestions,
  removeHigherAnswers,
  getShownAnswers,
  getShownQuestions,
  updateShownChat,
} from '../store';

import { getCurrentStep } from '../store';

const ChatBox = ({ showQuestion, className }) => {
  const { t } = useTranslation();

  const answers = getChatboxAnswers();
  const questions = getChatboxQuestions();
  const [shownAnswers, setShownAnswers] = useState(getShownAnswers());
  const [shownQuestions, setShownQuestions] = useState(getShownQuestions());
  removeHigherAnswers(showQuestion);
  const content = t(`ChatBox`, { returnObjects: true });

  return (
    <Container className={className}>
      <SecondContainer>
        {Object.keys(questions).map((answerKey, j) =>
          getCurrentStep() >= answerKey ? (
            <>
              <SectionSeperator>
                <Line />
                <SectionText>
                  {t('ChatBox.sectionTitle', {
                    flow: content.flowSteps[answerKey],
                  })}
                </SectionText>
                <Line />
              </SectionSeperator>
              {Object.keys(questions[answerKey]).map((theKey, i) => (
                <div key={`${i}${j}`}>
                  <Balloon
                    left={true}
                    shown={
                      shownQuestions[answerKey]
                        ? shownQuestions[answerKey][theKey] !== undefined
                          ? shownQuestions[answerKey][theKey]
                          : false
                        : false
                    }
                    setShown={value => {
                      setShownQuestions(
                        updateShownChat('question', answerKey, theKey, value),
                      );
                    }}
                    timeStamp={questions[answerKey][theKey].timeStamp}
                    text={questions[answerKey][theKey].question}
                  />
                  {answers[answerKey] ? (
                    answers[answerKey][theKey] ? (
                      <Balloon
                        shown={
                          shownAnswers[answerKey]
                            ? shownAnswers[answerKey][theKey] !== undefined
                              ? shownAnswers[answerKey][theKey]
                              : false
                            : false
                        }
                        setShown={value => {
                          setShownAnswers(
                            updateShownChat('answer', answerKey, theKey, value),
                          );
                        }}
                        timeStamp={answers[answerKey][theKey].timeStamp}
                        text={answers[answerKey][theKey].answer}
                      />
                    ) : null
                  ) : null}
                </div>
              ))}
            </>
          ) : null,
        )}
      </SecondContainer>
    </Container>
  );
};

const SecondContainer = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  padding-left: 3rem;
  padding-right: 2rem;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;

  & div {
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const SectionSeperator = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
  flex-direction: initial !important;
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  flex-grow: 1 !important;
  flex-shrink: 1 !important;
  background-color: #b8b8b8;
`;

const SectionText = styled.div`
  color: #b8b8b8;
  white-space: nowrap;
  padding: 2rem;
  margin: 1rem;
`;

const Container = styled.div`
  height: 60%;
  width: 80%;
  margin: 0 auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 1rem;
  position: relative;
  flex-wrap: nowrap;
  display: flex;
  flex-basis: 100%;
  flex-direction: column-reverse;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  & div {
    display: flex;
    flex-direction: column;
  }
`;

ChatBox.defaultPropTypes = {
  showQuestion: false,
  className: '',
};

ChatBox.propTypes = {
  history: shape().isRequired,
  showQuestion: bool,
  className: string,
};

export default ChatBox;
