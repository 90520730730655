import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Select from 'react-select';

import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Layout from '../../LayoutFlow/views/App';
import InputQuestionBox from '../../../../components/InputQuestionBox';
import ChatBox from '../../../../components/ChatBox';

import {
  setNotice,
  getNextPath,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';

import { setInsuredData, getInsuredData } from '../store';
import { getInsurances } from '../../../../services/insurancesService';

import regex from '../../../../services/regexService';

const InsuranceNameCar = ({ history }) => {
  const { t } = useTranslation();
  addChatboxQuestion(t(`${getComponentName()}.question`));
  const content = t(getComponentName(), { returnObjects: true });
  const [selected, setSelected] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [secondDisabled, setSecondDisabled] = useState(false);
  const [answer, setAnswer] = useState(false);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [sendStep, setSendStep] = useState('initial');
  const [options, setOptions] = useState([
    { value: 'loading', label: 'Loading ...' },
  ]);
  const [btnText, setBtnText] = useState(
    t(`${getComponentName()}.btnText.default`),
  );
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
    getInsurances()
      .then(res => {
        const _options = [];
        Object.keys(res).forEach(key => {
          _options.push({ value: key, label: res[key] });
        });
        const { already_insured_with } = getInsuredData();
        if (already_insured_with) {
          setSelected(
            _options.filter(item => item.label === already_insured_with)[0],
          );
          setIsDisabled(false);
        }
        setOptions(_options);
      })
      .catch(err => {
        setOptions([{ value: `notfound`, label: `Fetch failed` }]);
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (isPending) {
      clickHandler();
    }
  }, [selected]);

  useEffect(() => {
    let _btnText;
    let _isDisabled;
    switch (sendStep) {
      case 'pending':
        _btnText = t(`${getComponentName()}.btnText.pending`);
        _isDisabled = true;
        break;
      case 'success':
        _btnText = t(`${getComponentName()}.btnText.success`);
        _isDisabled = true;
        if (answer === 2) {
          addChatboxAnswer(
            t(`${getComponentName()}.message`, {
              insurer: selected.label,
            }),
          );
        } else {
          addChatboxAnswer(t(`${getComponentName()}.unknown`));
        }
        history.push(getNextPath());
        break;
      case 'error':
        _isDisabled = false;
        setIsPending(false);
        setAnswer(null);
        setError(t(`${getComponentName()}.error`));
        _btnText = t(`${getComponentName()}.btnText.error`);
        break;
      default:
        _btnText = t(`${getComponentName()}.btnText.default`);
        _isDisabled = false;
        break;
    }

    if (_btnText !== btnText) {
      setBtnText(_btnText);
      setIsDisabled(_isDisabled);
    }
  }, [sendStep]);

  const handleChange = _selected => {
    const _isDisabled = _selected === null;
    setSelected(_selected);
    setIsDisabled(_isDisabled);
    setError(null);
  };

  const handleAnswer = answer => {
    if (answer === content.answers[0].title) {
      setIsPending(true);
      setAnswer(1);
      setSelected({ value: null, label: null });
      setSecondDisabled(false);
      setIsDisabled(true);
    } else {
      if (!selected.label.match(regex.insuranceName))
        return setSendStep('error');
      if (selected) {
        setIsPending(true);
        setSecondDisabled(true);
        setIsDisabled(false);
        setAnswer(2);
        clickHandler();
      }
    }
  };

  const clickHandler = () => {
    if (sendStep === 'pending') {
      // Don't allow to submit twice
      return;
    }
    if (selected.label) {
      if (!selected.label.match(regex.insuranceName))
        return setSendStep('error');
    }
    setInsuredData(
      'already_insured_with',
      selected.value === '' ? false : selected.label,
    );
    setNotice('car', true, selected.label);
    setSendStep('success');
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <InputQuestionBox
          Answer1={content.answers[0].title}
          Answer2={content.answers[1].title}
          shouldLoad={sendStep === 'pending' ? answer : undefined}
          mainDisabled={isDisabled}
          secondDisabled={secondDisabled}
          dataTestId1="underwriting_answer_dontknow"
          dataTestId2="underwriting_answer_confirm"
          response={answer => handleAnswer(answer)}
        >
          <Input
            onChange={handleChange}
            options={options}
            onBlurResetsInput={false}
            isClearable
            menuPlacement="top"
            noOptionsMessage={() => t(`${getComponentName()}.placeholder`)}
            classNamePrefix="Select"
            value={selected}
            placeholder={t(`${getComponentName()}.placeholder`)}
          />
          {error ? <p>{error}</p> : null}
        </InputQuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  overflow: hidden;
  position: relative;

  & span {
    font-weight: bold;
  }

  & > div {
    z-index: 10;
  }
`;
const Input = styled(Select)`
  width: 100%;
  height: 4rem;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__control {
      height: 100%;
      border-radius: 0.5em;
      border-color: ${({ theme, hasValue }) =>
        hasValue ? theme.brand.primary : `#ccc`};
      z-index: 999;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

InsuranceNameCar.propTypes = {
  history: shape().isRequired,
};

export default withRouter(InsuranceNameCar);
