/* stylelint-disable declaration-no-important */
import React from 'react';
import styled from 'styled-components';
import { node, func, string, bool } from 'prop-types';

const IconCheckbox = ({
  children,
  name,
  checked,
  onCheck,
  disabled,
  dataTestId,
}) => {
  const onClick = e => {
    e.preventDefault();
    const status = !checked;
    onCheck(name, status);
  };

  return (
    <Container
      name={name}
      checked={checked}
      data-test-id={dataTestId}
      onClick={e => onClick(e)}
      disabled={disabled}
    >
      {children}
    </Container>
  );
};

const Container = styled.button`
  background-color: ${({ theme, checked }) =>
    checked ? theme.brand.secondary : theme.ui.interactive};
  color: white;
  border: 2px solid ${({ theme, checked }) =>
    checked ? theme.brand.secondary : theme.ui.interactive};
  border-radius: 20rem;
  padding: 0.5rem 2rem 0.5rem 1.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  box-sizing: border-box;
  transition: 0.2s;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.font};
  height: 4rem;
  cursor:  pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  
  &:active {
    /* background-color: ${({ theme }) => theme.brand.primary}; */
    transform: ${props => (props.disabled ? null : 'scale(0.97)')};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${({ theme, disabled }) =>
      disabled ? 'none' : theme.brand.lighter};
    color: ${props => (props.disabled ? 'none' : 'white')};
    border: 2px solid ${({ theme, disabled }) =>
      disabled ? 'none' : theme.brand.lighter};
  }

  & svg {
    width: 2.6rem;
    height: 2.6rem;
    margin-right: 1rem;
    content: fit;
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme, checked }) =>
      checked ? theme.brand.secondary : theme.ui.interactive};
  }

  & > p {
    color: white !important;
  }
`;

IconCheckbox.defaultProps = {
  checked: false,
  name: null,
};

IconCheckbox.propTypes = {
  children: node.isRequired,
  onCheck: func,
  checked: bool,
  name: string,
  dataTestId: string,
  disabled: bool,
};

export default IconCheckbox;
