import React, { useState, useEffect } from 'react';
import { object, func, bool } from 'prop-types';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  formatISODate,
  parseIncDateToIsoFormat,
} from '../services/dateService';

import {
  Section,
  Label,
  Text,
  Title,
  IconDriverFilled,
  IconReplacementFilled,
  IconEngineFilled,
  IconInfoFilled,
  EditActionButton,
  TextInput,
  SearchSelectInput,
  ActionButton,
  IconMotor,
  IconLightScooter,
  IconBikeFilled,
  IconHeavyScooter,
} from 'wg-fe-ui';

import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import DateInput from './DateInput_v3';
import cogoToast from 'cogo-toast';

const CRMRiskObjectDetailTwoWheelerInformation = ({
  twoWheelerData,
  onSubmitFunction,
  initEdit,
  isCustomerPage,
  customerPageFunction,
  disableEditing,
}) => {
  /* INITS
  -------------------------------------------------- */
  const { t } = useTranslation();

  const [data, setData] = useState(twoWheelerData);
  const [editMode, setEditMode] = useState(initEdit);
  const [fuel, setFuel] = useState('');
  const [vehicleUsage, setvehicleUsage] = useState('');
  const [category, setCategory] = useState('');
  const [vehicleType, setVehicleType] = useState('');

  useEffect(() => {
    setData(twoWheelerData);
  }, [twoWheelerData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  const categoryOptions = [
    { value: 'ATV', label: t('Vehicles.ATV') },
    { value: 'Standard', label: t('Vehicles.Standard') },
    { value: 'Classic', label: t('Vehicles.Classic') },
    { value: 'Off-road', label: t('Vehicles.Off-road') },
    { value: 'Cruiser', label: t('Vehicles.Cruiser') },
    { value: 'Minibike', label: t('Vehicles.Minibike') },
    { value: 'Prototype', label: t('Vehicles.Prototype') },
    { value: 'Scooter', label: t('Vehicles.Scooter') },
    { value: 'Sport', label: t('Vehicles.Sport') },
    { value: 'Sport Touring', label: t('Vehicles.Sport Touring') },
    { value: 'Touring', label: t('Vehicles.Touring') },
    { value: 'Moped Class A', label: t('Vehicles.Moped Class A') },
    { value: 'Moped Class B', label: t('Vehicles.Moped Class B') },
    { value: 'Light Four Wheeler', label: t('Vehicles.Light Four Wheeler') },
    { value: 'Speed Pedelec', label: t('Vehicles.Speed Pedelec') },
  ];

  const vehicleTypeOptions = [
    { value: 'MOPED', label: t('MOPED') },
    { value: 'LIGHT_SCOOTER', label: t('LIGHT_SCOOTER') },
    { value: 'HEAVY_SCOOTER', label: t('HEAVY_SCOOTER') },
    { value: 'MOTORCYCLE', label: t('MOTORCYCLE') },
    { value: 'MICRO_CAR', label: t('MICRO_CAR') },
  ];

  const fuelOptions = [
    { value: 'PET', label: t('ToolkitInfo.motor_types.PET') },
    { value: 'DIE', label: t('ToolkitInfo.motor_types.DIE') },
    { value: 'LPG', label: t('ToolkitInfo.motor_types.LPG') },
    { value: 'DIE_LPG', label: t('ToolkitInfo.motor_types.DIE_LPG') },
    { value: 'ELE', label: t('ToolkitInfo.motor_types.ELE') },
    { value: 'ELE_PET', label: t('ToolkitInfo.motor_types.ELE_PET') },
    { value: 'ELE_DIE', label: t('ToolkitInfo.motor_types.ELE_DIE') },
    { value: 'ELE_LPG', label: t('ToolkitInfo.motor_types.ELE_LPG') },
    { value: 'ELE_HYDRO', label: t('ToolkitInfo.motor_types.ELE_HYDRO') },
    { value: 'HYDRO', label: t('ToolkitInfo.motor_types.HYDRO') },
    { value: 'CNG', label: t('ToolkitInfo.motor_types.CNG') },
    { value: 'CNG_PET', label: t('ToolkitInfo.motor_types.CNG_PET') },
    { value: 'BIO', label: t('ToolkitInfo.motor_types.BIO') },
    { value: 'OTHER', label: t('ToolkitInfo.motor_types.OTHER') },
  ];

  const vehicleUseOptions = [
    { value: true, label: t('Vehicles.Is used as a primary vehicle') },
    { value: false, label: t('Vehicles.Is not used as a primary vehicle') },
  ];

  /* HELPER FUNCTIONS
  -------------------------------------------------- */
  const FindFuelInEnums = fuel => {
    const result = fuelOptions.filter(obj => {
      return obj.value === fuel;
    });

    return result;
  };

  const FindVehicleUseInEnums = vehicleUse => {
    const result = vehicleUseOptions.filter(obj => {
      return obj.value === vehicleUse;
    });

    return result;
  };

  const FindVehicleTypeInEnums = category => {
    const result = vehicleTypeOptions.filter(obj => {
      return obj.value === category;
    });

    return result;
  };

  const FindCategoryInEnums = category => {
    const result = categoryOptions.filter(obj => {
      return obj.value === category;
    });

    return result;
  };

  /* FORM HANDLING
  -------------------------------------------------- */
  const ToggleEditMode = e => {
    setEditMode(!editMode);

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const ValidationSchema = Yup.object().shape({
    value: Yup.number()
      .min(1)
      .max(9999999)
      .transform(value => (isNaN(value) ? null : value))
      .typeError(t('Form errors.number_type_error'))
      .nullable(),
    brand: Yup.string()
      .matches(
        /^[A-Za-zéèùàçâêîôûäëïöü\- ]{1,100}$/,
        t('Please use a valid brand name'),
      )
      .required(t('Required')),
    model: Yup.string()
      .min(1)
      .max(100)
      .required(t('Required')),
    category: Yup.string().nullable(),
    vehicle_type: Yup.string().nullable(),
    version: Yup.string()
      .min(1)
      .max(100)
      .nullable(),
    seats: Yup.number().required(t('Required')),
    options_value: Yup.number()
      .min(1)
      .max(9999999)
      .nullable(),
    model_year: Yup.number()
      .min(1900)
      .max(new Date().getFullYear())
      .nullable(),
    // registration_first: Yup.number()
    //   .min(1900)
    //   .max(new Date().getFullYear())
    //   .nullable(),
    number_plate: Yup.string()
      .min(1)
      .max(20)
      .matches(/^[A-Za-z0-9 -]{1,20}$/, t('Please use a valid licence plate'))
      .nullable(),
    chassis_number: Yup.string()
      .min(11)
      .max(17)
      .matches(/^[A-Z0-9]{11,17}$/, t('Please use a valid chassis no'))
      .nullable(),
    kw: Yup.number()
      .min(0, t('Form errors.min_zero'))
      .max(2000, `${t('Form errors.max_reached')} 2000.`)
      .transform(value => (isNaN(value) ? null : value))
      .typeError(t('Form errors.number_type_error'))
      .nullable(),
    hp: Yup.number()
      .min(0, t('Form errors.min_zero'))
      .max(2000, `${t('Form errors.max_reached')} 2000.`)
      .transform(value => (isNaN(value) ? null : value))
      .typeError(t('Form errors.number_type_error'))
      .nullable(),
    cc: Yup.number()
      .min(0)
      .max(10000)
      .nullable(),
    co2: Yup.number()
      .min(0)
      .max(1000)
      .transform(value => (isNaN(value) ? null : value))
      .nullable(),
    color: Yup.string()
      .min(1)
      .max(40)
      .nullable(),
    average_km_per_year: Yup.number()
      .min(0)
      .max(200000)
      .nullable(),
    vat_regime: Yup.number()
      .min(0)
      .max(100)
      .nullable(),
    clothing_value: Yup.number()
      .min(0, t('Form errors.min_zero'))
      .max(2500, t('Form errors.clothing_value_max'))
      .transform(value => (isNaN(value) ? null : value))
      .typeError(t('Form errors.number_type_error'))
      .nullable(),
  });

  const setInitialFormValues = () => {
    if (data) {
      Object.keys(data).map(name => {
        handleChange({ name, value: data[name] });
      });

      setFuel(FindFuelInEnums(data.motor_type));
      setvehicleUsage(FindVehicleUseInEnums(data.is_main_vehicle));
      setCategory(FindCategoryInEnums(data.category));
      setVehicleType(FindVehicleTypeInEnums(data.vehicle_type));
    }
  };

  const { values, handleChange, errors, handleSubmit } = useForm({
    validationSchema: ValidationSchema,
    mode: 'onChange',
  });

  const handleFuelChange = e => {
    const selectedFuel = FindFuelInEnums(e.value);
    setFuel(selectedFuel);
    handleChange({ name: 'motor_type', value: selectedFuel[0].value });
  };

  const handlevehicleUseChange = e => {
    const selectedvehicleUse = FindVehicleUseInEnums(e.value);
    setvehicleUsage(selectedvehicleUse);
    handleChange({
      name: 'is_main_vehicle',
      value: selectedvehicleUse[0].value,
    });
  };

  const handleCategoryChange = e => {
    const selectedCategory = FindCategoryInEnums(e.value);
    setCategory(selectedCategory);
    handleChange({ name: 'category', value: selectedCategory[0].value });
  };

  const handleVehicleTypeChange = e => {
    const selectedVehicleType = FindVehicleTypeInEnums(e.value);
    setVehicleType(selectedVehicleType);
    handleChange({ name: 'vehicle_type', value: selectedVehicleType[0].value });
  };

  async function handleFormValues() {
    const payload = {
      age: values?.age,
      brand: values?.brand,
      category: values?.category,
      cc: values?.cc,
      color: values?.color,
      country_of_registration: values?.country_of_registration,
      drivers: values?.drivers,
      invoice_value: values?.invoice_value,
      is_second_hand: values?.is_second_hand,
      kw: values?.kw,
      hp: values?.hp,
      license_plate_type: values?.license_plate_type,
      model: values?.model,
      model_year: values?.model_year,
      motor_type: values?.motor_type,
      name: values?.name,
      number_plate: values?.number_plate,
      chassis_number: values?.chassis_number,
      options_value: values?.options_value,
      registration_first: values?.registration_first,
      registration_last: values?.registration_last,
      id: values?.id,
      is_main_vehicle: values?.is_main_vehicle,
      value: values?.value,
      vehicle_type: values?.vehicle_type,
      version: values?.version,
      insurance_history: values?.previous_insurances,
      clothing_value: values?.clothing_value,
    };

    cogoToast.error(
      t('The editing and saving of a risk object is not supported yet'),
    );

    console.log('DEBUG PAYLOAD', payload);
  }

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
    } else {
      const newValues = {
        ...values,
        risk_object_id: data?.risk_object_id,
        risk_object_revision_id: data?.risk_object_revision_id,
      };

      onSubmitFunction(newValues);
      setData(newValues);
      setEditMode(false);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    }
  };

  function handleIconType(vehicleIconType) {
    const color = '#CCCCCC';
    switch (vehicleIconType) {
      case 'MOTORCYCLE':
        return <IconMotor color={color} />;
      case 'LIGHT_SCOOTER':
        return <IconLightScooter color={color} />;
      case 'HEAVY_SCOOTER':
        return <IconHeavyScooter color={color} />;
      case 'MOPED':
        return <IconBikeFilled color={color} />;
      case 'MICRO_CAR':
        return <IconBikeFilled color={color} />;
      default:
        return <IconMotor color={color} />;
    }
  }

  const handlePowerChange = e => {
    if (e.name === 'kw') {
      values.hp = Math.round(e.value * 1.341);
    } else {
      values.kw = Math.round(e.value / 1.341);
    }
    handleChange({ name: e.name, value: e.value });
  };

  console.log('DEBUG', data);

  return (
    <form onSubmit={handleFormSubmit}>
      <Section data-test-id="dashboard_crm_detail_automotive_section_two_wheeler_information">
        <Section.Title>{t('vehicle information')}</Section.Title>
        <Section.Content background>
          {editMode || disableEditing ? null : (
            <StyledEditButton onClick={e => ToggleEditMode(e)}>
              {t('Edit')}
            </StyledEditButton>
          )}

          <SectionContentHeader editMode={editMode}>
            <VehicleIconContainer>
              {handleIconType(data.vehicle_type)}
            </VehicleIconContainer>
            <TextWrapper>
              <Label>{t('vehicle')}</Label>
              <HeaderText>{`${values?.brand} ${values?.model}`}</HeaderText>
            </TextWrapper>

            <CatalogueValContainer>
              {editMode ? (
                <StyledTextInput
                  error={errors.value}
                  name="value"
                  placeholder={t('ToolkitInfo.carInfo.value')}
                  type="number"
                  value={values.value}
                  onChange={handleChange}
                  symbol="€"
                  symbolSide="left"
                  symbolText
                >
                  {t('ToolkitInfo.carInfo.value')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('ToolkitInfo.carInfo.value')}</Label>
                  <HeaderText>
                    {values?.value
                      ? parseFloat(values?.value)?.toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : '-'}
                  </HeaderText>
                </>
              )}
            </CatalogueValContainer>
          </SectionContentHeader>

          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconDriverFilled color={'#CCCCCC'} />
              </IconContainer>
              <TextWrapper>
                {editMode ? (
                  <StyledTextInput
                    error={errors.brand}
                    name="brand"
                    placeholder={t('Vehicles.Brand')}
                    type="text"
                    value={values.brand}
                    onChange={handleChange}
                  >
                    {t('Vehicles.Brand')}
                  </StyledTextInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.Brand')}</Label>
                    <Text>{values?.brand ? values?.brand : '-'}</Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.model}
                  name="model"
                  placeholder={t('Vehicles.Model')}
                  type="text"
                  value={values.model}
                  onChange={handleChange}
                >
                  {t('Vehicles.Model')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Model')}</Label>
                  <Text>{values?.model ? values?.model : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.version}
                  name="version"
                  placeholder={t('Vehicles.Version')}
                  type="text"
                  value={values.version}
                  onChange={handleChange}
                >
                  {t('Vehicles.Version')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Version')}</Label>
                  <Text>{values?.version ? values?.version : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.category}
                  initial={category}
                  name="category"
                  onSelected={handleCategoryChange}
                  options={categoryOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Vehicles.category.title')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Vehicles.category.title')}</Label>
                  <Text>{values?.category ? values?.category : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.options_value}
                  name="options_value"
                  placeholder={t('Vehicles.Value of options')}
                  type="number"
                  value={values.options_value}
                  onChange={handleChange}
                  symbol="€"
                  symbolSide="left"
                  symbolText
                >
                  {t('Vehicles.Value of options')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Value of options')}</Label>
                  <Text>
                    {values?.options_value
                      ? values?.options_value?.toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.model_year}
                  name="model_year"
                  placeholder={t('Vehicles.Model year')}
                  type="number"
                  value={values.model_year}
                  onChange={handleChange}
                >
                  {t('Vehicles.Model year')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Model year')}</Label>
                  <Text>{values?.model_year ? values?.model_year : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.color}
                  name="color"
                  placeholder={t('Vehicles.Color')}
                  type="text"
                  value={values.color}
                  onChange={handleChange}
                >
                  {t('Vehicles.Color')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Color')}</Label>
                  <Text>{values?.color ? values?.color : '-'}</Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.vehicle_type}
                  initial={vehicleType}
                  name="vehicle_type"
                  onSelected={handleVehicleTypeChange}
                  options={vehicleTypeOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Vehicle type')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Vehicle type')}</Label>
                  <Text>
                    {values?.vehicle_type ? t(values?.vehicle_type) : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            {/* <TextWrapper>
              {editMode ? null : (
                <>
                  <Label>{t('Vehicles.Age of the vehicle')}</Label>
                  <Text>
                    {formatISODate(values?.registration_first) || '--/--/----'}
                  </Text>
                </>
              )}
            </TextWrapper> */}
          </SectionContentInner>

          <SectionBorder />

          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconReplacementFilled color={'#CCCCCC'} />
              </IconContainer>

              <TextWrapper>
                {editMode ? (
                  <StyledTextInput
                    error={errors.number_plate}
                    name="number_plate"
                    placeholder={t('Vehicles.License plate')}
                    type="text"
                    value={values.number_plate}
                    onChange={handleChange}
                  >
                    {t('Vehicles.License plate')}
                  </StyledTextInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.License plate')}</Label>
                    <Text>
                      {values?.number_plate ? values?.number_plate : '-'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.chassis_number}
                  name="chassis_number"
                  placeholder={t('Vehicles.Chassis number')}
                  type="text"
                  value={values.chassis_number}
                  onChange={handleChange}
                >
                  {t('Vehicles.Chassis number')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Chassis number')}</Label>
                  <Text>
                    {values?.chassis_number ? values?.chassis_number : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <DateInput
                  error={errors.registration_first}
                  name="registration_first"
                  onChange={e =>
                    handleChange({
                      name: e.name,
                      value: parseIncDateToIsoFormat(e.value),
                    })
                  }
                  value={
                    data.registration_first
                      ? formatISODate(data.registration_first)
                      : ''
                  }
                >
                  {t('Vehicles.First registration')}
                </DateInput>
              ) : (
                <>
                  <Label>{t('Vehicles.First registration')}</Label>
                  <Text>
                    {values?.registration_first
                      ? formatISODate(values?.registration_first)
                      : '--/--/----'}
                  </Text>
                </>
              )}
            </TextWrapper>

            {isCustomerPage ? null : (
              <TextWrapper>
                {editMode ? (
                  <DateInput
                    error={errors.registration_last}
                    name="registration_last"
                    onChange={e =>
                      handleChange({
                        name: e.name,
                        value: parseIncDateToIsoFormat(e.value),
                      })
                    }
                    value={
                      data.registration_last
                        ? formatISODate(data.registration_last)
                        : ''
                    }
                  >
                    {t('Vehicles.Last registration')}
                  </DateInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.Last registration')}</Label>
                    <Text>
                      {values?.registration_last
                        ? formatISODate(values?.registration_last)
                        : '--/--/----'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            )}
          </SectionContentInner>

          <SectionBorder />

          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconEngineFilled color={'#CCCCCC'} />
              </IconContainer>

              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.motor_type}
                    initial={fuel}
                    name="motor_type"
                    onSelected={handleFuelChange}
                    options={fuelOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Vehicles.Fuel type')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.Fuel type')}</Label>
                    <Text>
                      {t('Vehicles.motor_types.' + values?.motor_type) || '--'}
                    </Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.kw}
                  name="kw"
                  placeholder={t('Vehicles.Power')}
                  type="number"
                  value={values.kw}
                  onChange={e => handlePowerChange(e)}
                  symbol="KW"
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.Power')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Power')}</Label>
                  <Text>{values?.kw ? `${values?.kw} KW` : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.hp}
                  name="hp"
                  placeholder={t('Vehicles.HP')}
                  type="number"
                  value={values.hp}
                  onChange={e => handlePowerChange(e)}
                  symbol={t('Vehicles.HP')}
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.HP')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.HP')}</Label>
                  <Text>
                    {values?.hp ? `${values?.hp} ${t('Vehicles.HP')}` : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.cc}
                  name="cc"
                  placeholder={t('Vehicles.Displacement')}
                  type="number"
                  value={values.cc}
                  onChange={handleChange}
                  symbol="CC"
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.Displacement')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Displacement')}</Label>
                  <Text>{values?.cc ? `${values?.cc} CC` : '-'}</Text>
                </>
              )}
            </TextWrapper>

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.co2}
                  name="co2"
                  placeholder={t('Vehicles.Emissions')}
                  type="number"
                  value={values.co2}
                  onChange={handleChange}
                  symbol="gr"
                  symbolSide="right"
                  symbolText
                >
                  {t('Vehicles.Emissions')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Vehicles.Emissions')}</Label>
                  <Text>{values?.co2 ? `${values?.co2} g CO2/km` : '-'}</Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>

          <SectionBorder />

          <SectionContentInner>
            {isCustomerPage ? null : (
              <IconWrapper>
                <IconContainer>
                  <IconInfoFilled color={'#CCCCCC'} />
                </IconContainer>
                <TextWrapper>
                  {editMode ? (
                    <StyledTextInput
                      error={errors.average_km_per_year}
                      name="average_km_per_year"
                      placeholder={t('Vehicles.Annual distance')}
                      type="number"
                      value={values.average_km_per_year}
                      onChange={handleChange}
                      symbol="km"
                      symbolSide="right"
                      symbolText
                    >
                      {t('Vehicles.Annual distance')}
                    </StyledTextInput>
                  ) : (
                    <>
                      <Label>{t('Vehicles.Annual distance')}</Label>
                      <Text>
                        {values?.average_km_per_year?.toLocaleString('nl-BE') ||
                          '-'}{' '}
                        km
                      </Text>
                    </>
                  )}
                </TextWrapper>
              </IconWrapper>
            )}

            {isCustomerPage ? null : (
              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.is_main_vehicle}
                    initial={vehicleUsage}
                    name="is_main_vehicle"
                    onSelected={handlevehicleUseChange}
                    options={vehicleUseOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Vehicles.usage.title')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Vehicles.usage.title')}</Label>
                    <Text>
                      {values?.is_main_vehicle
                        ? t('Vehicles.Is used as a primary vehicle')
                        : t('Vehicles.Is not used as a primary vehicle')}
                    </Text>
                  </>
                )}
              </TextWrapper>
            )}

            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors.clothing_value}
                  name="clothing_value"
                  placeholder={t('Clothing value')}
                  type="number"
                  value={values.clothing_value}
                  onChange={handleChange}
                  symbol="€"
                  symbolSide="left"
                  symbolText
                >
                  {t('Clothing value')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Clothing value')}</Label>
                  <Text>
                    {values?.clothing_value
                      ? values?.clothing_value?.toLocaleString('nl-BE', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : '-'}
                  </Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>

          {editMode ? (
            <SectionContentFooter>
              <ActionButton level="default" onClick={ToggleEditMode}>
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton>{t('Save')}</ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

const CatalogueValContainer = styled.div`
  display: flex;
  flex: 0 0 50%;
  flex-direction: column;
  padding-right: 15px;
`;

const VehicleIconContainer = styled.div`
  flex: 0 0 6rem;
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const SectionContentHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;

  @media screen and (max-width: 500px) {
    padding: 12px 0;
    margin: 0 12px;
    width: calc(100% - 24px);
  }
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;

  @media screen and (max-width: 500px) {
    justify-content: center;
    padding: 12px 0 0;
    margin: 12px 0;
  }
`;

const SectionBorder = styled.div`
  margin-top: 3rem;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.ui.outline};
`;

const TextWrapper = styled.div`
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  padding-right: 15px;

  /* two items */
  :first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ div {
    width: 49%;
  }

  /* three items */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 49%;
  }
`;

const HeaderText = styled(Title)`
  text-transform: capitalize;
`;

const IconWrapper = styled.div`
  display: flex;

  ${TextWrapper} {
    flex: 1 0 auto;
  }
`;

const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
  flex: 0 0 6rem;
`;

const SectionContentInner = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    :nth-of-type(2n + 3) {
      flex: 0 0 calc(50% - 6rem);
      margin-left: 6rem;
    }
  }

  > ${IconWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    padding: 12px;

    > ${TextWrapper} {
      flex: 0 0 calc(100% - 4.7rem);
      margin-left: 6.7rem;

      :nth-of-type(2n + 3) {
        flex: 0 0 calc(100% - 4.7rem);
        margin-left: 6.7rem;
      }

      &:first-of-type {
        margin-top: 0;
      }
    }

    > ${IconWrapper} {
      flex: 0 0 calc(100%);
      margin-top: 1.5rem;

      > div {
        padding-right: 0;
      }
    }
  }
`;

const StyledEditButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;

  @media screen and (max-width: 500px) {
    width: 4.5rem;
    height: 4.5rem;
    right: 1.8rem;
    top: 2.5rem;
  }
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
`;

const Spacer = styled.div`
  width: 15px;
`;

CRMRiskObjectDetailTwoWheelerInformation.propTypes = {
  twoWheelerData: object,
  onSubmitFunction: func,
  initEdit: bool,
  isCustomerPage: bool,
  customerPageFunction: func,
  disableEditing: bool,
};

export default CRMRiskObjectDetailTwoWheelerInformation;
