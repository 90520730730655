import React from 'react';
import { object, number, func } from 'prop-types';
import { Label } from 'wg-fe-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import accidentStatementType from '../services/FlowSearchSelectData';

const AccidentData = ({ accident, itemId, handleDeleteAccident }) => {
  const { t } = useTranslation();
  const { date, at_fault } = accident;
  const { day, month, year } = date;

  return (
    <AccidentsContainer>
      <span>
        <Label>
          {t('Date')}
          {': '}
        </Label>
        <p>
          {day}/{month}/{year}
        </p>
      </span>
      <span>
        <Label>
          {t('At fault')}
          {': '}
        </Label>
        <p>{at_fault === 'RESPONSIBLE' ? t(`Yes`) : t(`No`)} </p>
      </span>
      <Button
        onClick={() => {
          handleDeleteAccident(itemId);
        }}
      >
        -
      </Button>
    </AccidentsContainer>
  );
};

const AccidentsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 10rem 7rem 3rem;
  > span {
    color: #5b5550;
  }
`;

const Button = styled.button`
  margin: 0.5rem 0;
  color: white;
  cursor: pointer;
  background-color: #ff8000;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  align-items: center;
`;

AccidentData.propTypes = {
  accident: object,
  itemId: number,
  handleDeleteAccident: func,
};

export default AccidentData;
