/* stylelint-disable value-keyword-case */
import React, { useState, useEffect, Suspense } from 'react';
import styled from 'styled-components';
import Drawer from 'react-drag-drawer';
import * as Yup from 'yup';
import { func, bool, shape, string, array, object } from 'prop-types';
import i18n from '../../i18n';
import { ActionButton, TextInput } from 'wg-fe-ui';
import cogoToast from 'cogo-toast';
// import { string, dateWithOutYear } from '../constants/validationSchemas.js';
import useForm from '../hooks/useForm';
import CarIcon from '../../assets/images/car-icon';
import FamilyIcon from '../../assets/images/family-icon';
import LegalIcon from '../../assets/images/legal-icon';
import HomeIcon from '../../assets/images/home-icon';
import { getCurrentTheme } from '../store';
import LoadingButton from './LoadingButton';
import { useTranslation } from 'react-i18next';
import {
  patchBrokerAffiliation,
  addBrokerAffiliation,
  deleteBrokerAffiliation,
  validateBrokerAffiliation,
  patchBrokerIntegrations,
  sendRequestAccessMail,
} from '../services/apiRouterService';
import i18next from 'i18next';
import { getNestedObject } from '../services/objectService';
import {
  retrieveBrokerId,
  getBrokerData,
  getBrokerDistribution,
} from '../services/brokerDataService';

const AffiliationPopUp = ({
  toggleDrawer,
  checked,
  reloadResults,
  required_nrs,
  required_integrations,
  integrations,
  name,
  logo,
  affiliationData,
  insurances,
  needs_verification,
  needs_access_request,
}) => {
  const { t } = useTranslation();
  const [validationMessage, setValidationMessage] = useState({});
  const tempSchema = {};

  if (required_nrs) {
    required_nrs.map(input => {
      tempSchema[input] = Yup.string()
        .nullable()
        .required(i18n.t(`AdresInput.errors.short`))
        .min(1, i18n.t(`AdresInput.errors.short`))
        .max(20, i18n.t(`AdresInput.errors.long`));
    });
  }
  if (required_integrations) {
    required_integrations.map(input => {
      tempSchema[input] = Yup.string()
        .nullable()
        .required(i18n.t(`AdresInput.errors.short`))
        .min(1, i18n.t(`AdresInput.errors.short`))
        .max(20, i18n.t(`AdresInput.errors.long`));
    });
  }

  const [valid, setValid] = useState(!needs_verification);
  const [requestedAccess, setRequestedAccess] = useState(false);
  const [needsAccessRequest, setNeedsAccessRequest] = useState(
    needs_access_request,
  );
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(null);
  const [loadingValidate, setLoadingValidate] = useState(null);
  const [loadingRFA, setLoadingRFA] = useState(null);
  const SignupSchema = Yup.object().shape(tempSchema);
  const {
    handleChange,
    errors,
    handleSubmit,
    values,
    validate,
    setErrors,
  } = useForm({
    validationSchema: SignupSchema,
    change: () => {},
  });

  useEffect(() => {
    setDefaultValues();
  }, []);

  useEffect(() => {
    if (name === 'allianz') {
      if (
        integrations?.allianz_home_id !== null &&
        integrations?.allianz_family_id !== null &&
        integrations?.allianz_car_id !== null
      ) {
        setNeedsAccessRequest(false);
      }
    } else if (name === 'axa') {
      if (integrations?.is_axa_allowed !== null) {
        setNeedsAccessRequest(false);
      }
    }
  }, [integrations]);

  const closePopUp = () => {
    toggleDrawer(false);
  };

  // Delete the affiliation
  const deleteAffiliation = async () => {
    setLoadingDelete(true);
    setValidationMessage({});
    const [resp, status] = await deleteBrokerAffiliation(
      await retrieveBrokerId(),
      name,
    );

    if (parseInt(status / 100) === 2) {
      cogoToast.success(t('Affiliation deleted successfully'));
      reloadResults();
      closePopUp(false);
    } else if (resp.msg != null) {
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
    } else {
      console.error(resp);
      setValidationMessage({
        error: true,
        message: t('Something went wrong while deleting your affiliation'),
      });
      cogoToast.error(
        t('Something went wrong while deleting your affiliation'),
      );
    }
    setLoadingDelete(false);
  };

  // Validate the affiliation details where supported
  const checkAffiliation = () => {
    // call validate function from form
    setValidationMessage({});
    validate(values, SignupSchema).then(async resp => {
      if (Object.keys(resp).length === 0) {
        setLoadingValidate(true);
        setErrors({}); // reset validation errors because there are none
        const [resp, status] = await validateBrokerAffiliation(name, values);
        setLoadingValidate(false);
        if (parseInt(status / 100) !== 2) {
          if (resp.detail) {
            if (resp.detail[i18next.language]) {
              setValidationMessage({
                error: true,
                message: resp.detail[i18next.language],
              });
            } else {
              setValidationMessage({
                error: true,
                message: resp.detail,
              });
            }
          } else {
            setValidationMessage({
              error: true,
              message: t('Something went wrong'),
            });
          }
        } else {
          setValidationMessage({
            error: false,
            message: t('Your affiliation number has been accepted'),
          });
        }
        setValid(parseInt(status / 100) === 2);
      } else {
        setValid(false);
        setErrors(resp);
      }
    });
  };

  const submitForm = async values => {
    let payload = {
      boost: 0,
      sell_filter: insurances,
    };
    required_nrs.map(key => {
      payload[key] = values[key];
    });
    if (checked) {
      const [resp, status] = await patchBrokerAffiliation(
        await retrieveBrokerId(),
        name,
        payload,
      );
      if (parseInt(status / 100) === 2) {
        cogoToast.success(t('Affiliation edited successfully'));
        const patchedIntegrationsPayload = {};
        if (required_integrations && required_integrations.length > 0) {
          required_integrations.map(key => {
            if (values[key] !== integrations[key]) {
              patchedIntegrationsPayload[key] = values[key];
            }
          });
          if (Object.keys(patchedIntegrationsPayload).length > 0) {
            const [respInt, statusInt] = await patchBrokerIntegrations(
              await retrieveBrokerId(),
              patchedIntegrationsPayload,
            );
            if (parseInt(statusInt / 100) === 2) {
              cogoToast.success(t('Integrations edited successfully'));
            } else {
              console.error(respInt);
              cogoToast.error(
                t('Something went wrong while saving your integration'),
              );
            }
          }
        }
        reloadResults();
        closePopUp(false);
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
      } else {
        console.error(resp);
        setValidationMessage({
          error: true,
          message: t('Something went wrong while saving your affiliation'),
        });
        cogoToast.error(
          t('Something went wrong while saving your affiliation'),
        );
      }
      return [resp, status];
    } else {
      payload.name = name;
      const [resp, status] = await addBrokerAffiliation(
        await retrieveBrokerId(),
        payload,
      );
      if (parseInt(status / 100) === 2) {
        cogoToast.success(t('Affiliation added successfully'));
        const patchedIntegrationsPayload = {};
        if (required_integrations && required_integrations.length > 0) {
          required_integrations.map(key => {
            if (values[key] !== integrations[key]) {
              patchedIntegrationsPayload[key] = values[key];
            }
          });
          if (Object.keys(patchedIntegrationsPayload).length > 0) {
            const [respInt, statusInt] = await patchBrokerIntegrations(
              await retrieveBrokerId(),
              patchedIntegrationsPayload,
            );
            if (parseInt(statusInt / 100) === 2) {
              cogoToast.success(t('Integrations edited successfully'));
            } else {
              console.error(respInt);
              cogoToast.error(
                t('Something went wrong while saving your integration'),
              );
            }
          }
        }
        reloadResults();
        closePopUp(false);
      } else if (resp.msg != null) {
        const { hide } = cogoToast.error(
          t(resp.msg, {
            onClick: () => {
              hide();
            },
          }),
        );
      } else {
        console.error(resp);
        cogoToast.error(
          t('Something went wrong while adding your affiliation'),
        );
      }
      return [resp, status];
    }
  };

  // When the insurance company needs external approval first
  const requestAccessForAffiliation = () => {
    // call validate function from form
    setValidationMessage({});
    validate(values, SignupSchema).then(async resp => {
      if (Object.keys(resp).length === 0) {
        setLoadingRFA(true);
        setErrors({}); // reset validation errors because there are none
        const payload = {
          data: {
            insurer: name,
            nr: values?.nr,
            broker: (await getBrokerData())?.broker?.name,
            broker_id: await retrieveBrokerId(),
            distribution: (await getBrokerDistribution())?.name,
          },
        };
        const [, status] = await sendRequestAccessMail(payload);
        setLoadingRFA(false);
        if (parseInt(status / 100) !== 2) {
          setValidationMessage({
            error: true,
            message: t(
              'Something went wrong while requesting your access Please contact us at support about this enquiry',
            ),
          });
        } else {
          setValidationMessage({
            error: false,
            message: t(
              'Your access has been requested We will notify you when the access has been granted',
            ),
          });
        }
        setRequestedAccess(parseInt(status / 100) === 2);
      } else {
        setRequestedAccess(false);
        setErrors(resp);
      }
    });
  };

  const handleFormValues = async info => {
    setValidationMessage({});
    setLoading(true);
    const [resp, status] = await submitForm(info);
    setLoading(false);
    if (status % 100 !== 2) {
      setValidationMessage({
        error: true,
        message: resp.msg,
      });
    }
  };

  const formSubmitHandler = e => {
    e.preventDefault();
    handleSubmit(handleFormValues);
  };

  const setDefaultValues = async () => {
    const tempValues = {};
    required_nrs.map(input => {
      tempValues[input] = getNestedObject(affiliationData, [input]);
    });
    if (required_integrations) {
      required_integrations.map(input => {
        tempValues[input] = getNestedObject(integrations, [input]);
      });
    }
    Object.keys(tempValues).map(name => {
      handleChange({ name, value: tempValues[name] });
    });
  };

  return (
    <Drawer open={true} onRequestClose={() => closePopUp()}>
      <InfoContainer onClick={e => e.stopPropagation()}>
        <InfoHeader>
          <h2>
            <span>
              {checked
                ? i18n.t(`Edit affiliation`)
                : i18n.t(`Add a new affiliation`)}
            </span>
          </h2>
          <CloseButton data-test-id="close_popup" onClick={() => closePopUp()}>
            X
          </CloseButton>
        </InfoHeader>
        <Form onSubmit={formSubmitHandler}>
          <Suspense fallback={<div>Loading...</div>}>
            <SectionContainer>
              <LeftSide>
                {logo ? (
                  <Logo src={logo} alt="" />
                ) : (
                  <div>
                    <FakeLogo>{name}</FakeLogo>
                  </div>
                )}
                <CanSellContainer>
                  <HomeIcon
                    color={
                      insurances.includes('home')
                        ? getCurrentTheme().brand.primary
                        : '#ccc'
                    }
                  />
                  <FamilyIcon
                    color={
                      insurances.includes('family')
                        ? getCurrentTheme().brand.primary
                        : '#ccc'
                    }
                  />
                  <CarIcon
                    color={
                      insurances.includes('car')
                        ? getCurrentTheme().brand.primary
                        : '#ccc'
                    }
                  />
                  <LegalIcon
                    color={
                      insurances.includes('legal')
                        ? getCurrentTheme().brand.primary
                        : '#ccc'
                    }
                  />
                </CanSellContainer>
              </LeftSide>
              <RightSide>
                <InputContainer>
                  {required_nrs
                    ? required_nrs.map(input => (
                        <TextInput
                          key={input}
                          error={errors[input]}
                          type="text"
                          name={input}
                          onChange={e => {
                            if (valid) setValid(false);
                            handleChange(e);
                          }}
                          value={values[input]}
                          dataTestId={`underwriting_termination_${input}`}
                        >
                          {input === 'nr'
                            ? i18n.t(`affinities.${input}.${name}`)
                            : i18n.t(`affinities.${input}`)}
                        </TextInput>
                      ))
                    : ''}
                  {required_integrations
                    ? required_integrations.map(input => (
                        <TextInput
                          key={input}
                          error={errors[input]}
                          type="text"
                          name={input}
                          onChange={e => {
                            if (valid) setValid(false);
                            handleChange(e);
                          }}
                          value={values[input]}
                          dataTestId={`underwriting_termination_${input}`}
                        >
                          {i18n.t(`affinities.${input}`)}
                        </TextInput>
                      ))
                    : ''}
                  {validationMessage.message ? (
                    <ValidationMessage
                      error={validationMessage.error}
                      dangerouslySetInnerHTML={{
                        __html: validationMessage.message,
                      }}
                    />
                  ) : (
                    ''
                  )}
                </InputContainer>
                {needsAccessRequest ? (
                  <ButtonContainer>
                    {needs_access_request ? (
                      loadingRFA ? (
                        <StyledLoadingButton>
                          {i18n.t('Validating')}
                        </StyledLoadingButton>
                      ) : (
                        <StyledButton
                          onClick={() => {
                            requestAccessForAffiliation();
                          }}
                          disabled={requestedAccess}
                          data-test-id="drivers_information_submit"
                        >
                          {i18n.t('Request access')}
                        </StyledButton>
                      )
                    ) : (
                      ``
                    )}
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    {checked ? (
                      loadingDelete ? (
                        <StyledLoadingButtonDelete>
                          {i18n.t('Deleting')}
                        </StyledLoadingButtonDelete>
                      ) : (
                        <StyledButton
                          onClick={() => {
                            deleteAffiliation();
                          }}
                          isDelete
                          data-test-id="drivers_information_submit"
                        >
                          {i18n.t('Delete')}
                        </StyledButton>
                      )
                    ) : (
                      ``
                    )}
                    {needs_verification ? (
                      loadingValidate ? (
                        <StyledLoadingButton>
                          {i18n.t('Validating')}
                        </StyledLoadingButton>
                      ) : (
                        <StyledButton
                          onClick={() => {
                            checkAffiliation();
                          }}
                          disabled={!validate(values, SignupSchema)}
                          data-test-id="drivers_information_submit"
                        >
                          {i18n.t('Validate')}
                        </StyledButton>
                      )
                    ) : (
                      ``
                    )}
                    {loading ? (
                      <StyledLoadingButton>
                        {checked ? i18n.t('Saving') : i18n.t('Adding')}
                      </StyledLoadingButton>
                    ) : (
                      <StyledActionButton
                        type="submit"
                        value="Submit"
                        disabled={needs_verification && !valid}
                        data-test-id="drivers_information_submit"
                      >
                        {checked ? i18n.t('Save') : i18n.t('Add affiliation')}
                      </StyledActionButton>
                    )}
                  </ButtonContainer>
                )}
              </RightSide>
            </SectionContainer>
          </Suspense>
        </Form>
      </InfoContainer>
    </Drawer>
  );
};

const StyledActionButton = styled(ActionButton)`
  &:disabled {
    cursor: not-allowed;
  }
`;

const StyledLoadingButton = styled(LoadingButton)`
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  width: ${props => (props.fullwidth ? '100%' : '')};
  height: 4rem;
  color: white;
  border: 0;
  padding: ${({ padding }) => padding || '1rem 0.4rem'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  margin-top: 3rem;
  margin-left: 1rem;
`;

const StyledLoadingButtonDelete = styled(LoadingButton)`
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  width: ${props => (props.fullwidth ? '100%' : '')};
  height: 4rem;
  color: white;
  border: 0;
  padding: ${({ padding }) => padding || '1rem 0.4rem'};
  background-color: #d67979;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;
  margin-top: 3rem;
  margin-left: 1rem;
`;

const InputContainer = styled.div`
  & > div {
    margin-bottom: 2rem;
  }
`;

const StyledButton = styled.a`
  font-family: ${({ theme }) => theme.font};
  background-color: ${({ theme, disabled, isDelete }) =>
    disabled ? theme.ui.disabled : isDelete ? 'darkred' : theme.brand.primary};
  font-size: 1.6rem;
  border-radius: 0.5rem;
  min-width: 14rem;
  width: ${props => (props.fullwidth ? '100%' : '')};
  height: 4rem;
  color: white;
  border: 0;
  padding: ${({ padding }) => padding || '1rem 0.4rem'};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :active {
    transform: scale(0.95);
  }

  :focus {
    outline: none;
  }

  :hover {
    /* background-color: #FFBF80; */
  }
`;

const ValidationMessage = styled.p`
  ${props =>
    props.error ? 'background-color: #F67975;' : 'background-color: #d0ecd0;'}
  ${props => (props.error ? 'color: white;' : 'color: #155C11;')}
  ${props =>
    props.error ? 'border: 1px solid #D55653;' : 'border: 1px solid #155C11;'}
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  margin-top: 2rem;
`;

const CanSellContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5.4rem;
  margin-top: 4rem;
  & svg {
    height: 3rem;
    margin: 1rem;
  }
`;

const Logo = styled.img`
  max-width: 90%;
  max-height: 7rem;
  min-height: 15rem;
  object-fit: contain;
`;

const FakeLogo = styled.div`
  max-width: 90%;
  max-height: 7rem;
  min-height: 15rem;
  object-fit: contain;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.brand.primary};
  text-transform: uppercase;
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 5rem;
  font-weight: 900;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 5.4rem;
`;
const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SectionContainer = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const InfoContainer = styled.div`
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 10px 24px rgba(0, 0, 0, 0.1);
  width: 100rem;
  overflow: auto;
  z-index: 9999;
  font-family: ${({ theme }) => theme.font};
`;

const InfoHeader = styled.div`
  color: white;
  background-color: ${({ theme }) => theme.brand.secondary};
  padding: 2.2rem 2.2rem 2.2rem 5.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & h2 {
    line-height: 1.3;
    width: 90%;

    & span {
      font-weight: bold;
    }
  }
`;

const CloseButton = styled.p`
  cursor: pointer;
  color: white;
  font-weight: 900;

  &:hover {
    color: ${({ theme }) => theme.brand.lighter};
  }
`;

const Form = styled.form`
  width: 100%;
  position: relative;
  padding: 2rem 5.4rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;

  & button,
  & a {
    margin: 0;
    margin-top: 3rem;
    margin-left: 1rem;
  }
`;

AffiliationPopUp.defaultValues = {
  integrations: {},
  affiliationData: shape(),
  required_integrations: [],
  required_nrs: [],
  insurances: [],
  needs_verification: false,
};

AffiliationPopUp.propTypes = {
  toggleDrawer: func,
  toggle: bool,
  checked: bool,
  reloadResults: func,
  submitForm: func,
  name: string.isRequired,
  logo: string,
  integrations: object,
  affiliationData: shape(),
  required_integrations: array,
  required_nrs: array,
  insurances: array,
  needs_verification: bool,
  needs_access_request: bool,
  reload: func,
};

export default AffiliationPopUp;
