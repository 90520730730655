import React from 'react';
import { shape, bool } from 'prop-types';

const DocsIcon = ({ colors, disabled }) => {
  const colorsDict = {
    iconColor: disabled ? '#D9D8DC' : '#fff',
    secondaryColor: disabled ? '#fff' : colors.brand.primary,
  };

  return (
    <svg
      width="400px"
      height="400px"
      viewBox="0 0 400 400"
      style={{
        enableBackground: 'new 0 0 400 400',
      }}
      xmlSpace="preserve"
    >
      <defs />
      <g id="Layer_4_copy">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={colorsDict.secondaryColor}
          d="M200,400c110.5,0,200-89.5,200-200S310.5,0,200,0S0,89.5,0,200S89.5,400,200,400z"
        />
        <g>
          <path
            fill={colorsDict.iconColor}
            d="M207.2,224.9l75.1-38.5v76c0,2.5-2,4.4-4.4,4.4H120.1c-2.5,0-4.4-2-4.4-4.4v-75.8l73.9,38.2 c2.7,1.4,5.8,2.2,8.8,2.2C201.5,227,204.5,226.3,207.2,224.9z"
          />
          <path
            fill={colorsDict.iconColor}
            d="M309.4,155.5l13-6.7c1.5-0.8,1-3.1-0.8-3.1H76.3c-1.7,0-2.3,2.3-0.8,3.1l121,62.6c1.2,0.6,2.6,0.6,3.8,0 l98.4-50.4l-0.3,94.6l-12.8,31.8c-0.5,1.3,0.4,2.6,1.8,2.6h4.4l5.4-7.2l2.6,7.2h20.5c1.4,0,2.3-1.4,1.8-2.6l-13.1-32.5 L309.4,155.5z"
          />
        </g>
      </g>
    </svg>
  );
};

DocsIcon.defaultProps = {
  disabled: true,
};

DocsIcon.propTypes = {
  colors: shape().isRequired,
  disabled: bool,
};

export default DocsIcon;
