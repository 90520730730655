import React, { useState, useEffect } from 'react';
import { shape } from 'prop-types';
import Section from './Section';
import styled from 'styled-components';
import Steerwheel from '../../assets/images/dashboard/car/Steerwheel';
import License from '../../assets/images/dashboard/car/License';
import Engine from '../../assets/images/dashboard/car/Engine';
import Info from '../../assets/images/dashboard/car/Info';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryAutomotiveCarInformation = ({ carInformation }) => {
  const [carData, setCarData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const tmpCarData = {};
    Object.keys(carInformation).forEach(key => {
      if (carInformation[key]) {
        tmpCarData[key] = carInformation[key];
      } else {
        if (key !== 'carLoan') {
          tmpCarData[key] = '--';
        } else {
          tmpCarData[key] = carInformation[key];
        }
      }
    });

    if (!isNaN(tmpCarData.catalogValue))
      tmpCarData.catalogValue = tmpCarData.catalogValue.toLocaleString(
        'nl-BE',
        { style: 'currency', currency: 'EUR' },
      );

    setCarData(tmpCarData);
  }, [carInformation]);

  return (
    <Section>
      <Section.Subtitle>
        {t('History.automotive.carInfo.title')}
      </Section.Subtitle>
      <Section.Content data-test-id="dashboard_history_detail_automotive_section_car_information">
        <Header>
          <Section.Split>
            <Section.SplitContent>
              <Label>{t('Vehicle')}</Label>
              <HeaderText>{carData.carName}</HeaderText>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.carInfo.value')}</Label>
              <HeaderText>{carData.catalogValue}</HeaderText>
            </Section.SplitContent>
          </Section.Split>
        </Header>
        <SectionBordered>
          <Section.Split>
            <Section.SplitContent>
              <IconWrapper>
                <IconContainer>
                  <Steerwheel color={'#CCCCCC'} />
                </IconContainer>
                <div>
                  <Label>{t('ToolkitInfo.carInfo.brand')}</Label>
                  <Text>{carData.brand}</Text>
                  <Label>{t('ToolkitInfo.carInfo.version')}</Label>
                  <Text>{carData.version}</Text>
                  <Label>{t('ToolkitInfo.carInfo.seats')}</Label>
                  <Text>{carData.seats}</Text>
                  <Label>{t('ToolkitInfo.carInfo.model_year')}</Label>
                  <Text>{carData.modelYear}</Text>
                </div>
              </IconWrapper>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.carInfo.model')}</Label>
              <Text>{carData.model}</Text>
              <Label>{t('ToolkitInfo.category.title')}</Label>
              <Text>{carData.category}</Text>
              <Label>{t('ToolkitInfo.carInfo.options_value')}</Label>
              <Text>{carData.valueExtraOptions}</Text>
              <Label>{t('ToolkitInfo.carInfo.age')}</Label>
              <Text>
                {carData.carAge} {t('ToolkitInfo.carInfo.months')}
              </Text>
            </Section.SplitContent>
          </Section.Split>
        </SectionBordered>
        <SectionBordered>
          <Section.Split>
            <Section.SplitContent>
              <IconWrapper>
                <IconContainer>
                  <License color={'#CCCCCC'} />
                </IconContainer>
                <div>
                  <Label>{t('ToolkitInfo.carInfo.license_plate')}</Label>
                  <Text>{carData.licensePlate}</Text>
                  <Label>{t('ToolkitInfo.carInfo.registration_first')}</Label>
                  <Text>{carData.registrationFirst}</Text>
                </div>
              </IconWrapper>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.carInfo.chassis_number')}</Label>
              <Text>{carData.chassisNumber}</Text>
              <Label>{t('ToolkitInfo.carInfo.registration_last')}</Label>
              <Text>{carData.registrationLast}</Text>
            </Section.SplitContent>
          </Section.Split>
        </SectionBordered>
        <SectionBordered>
          <Section.Split>
            <Section.SplitContent>
              <IconWrapper>
                <IconContainer>
                  <Engine color={'#CCCCCC'} />
                </IconContainer>
                <div>
                  <Label>{t('ToolkitInfo.carInfo.motor_type')}</Label>
                  <Text>{t('ToolkitInfo.motor_types.' + carData.fuel)}</Text>
                  <Label>{t('ToolkitInfo.carInfo.cc')}</Label>
                  <Text>{carData.cylinderCapacity} CC</Text>
                </div>
              </IconWrapper>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('ToolkitInfo.carInfo.kw')}</Label>
              <Text>{carData.power} KW</Text>
              <Label>{t('ToolkitInfo.carInfo.co2')}</Label>
              <Text>{carData.emission} g CO2/km</Text>
            </Section.SplitContent>
          </Section.Split>
        </SectionBordered>
        <Section.Split>
          <Section.SplitContent>
            <IconWrapper>
              <IconContainer>
                <Info color={'#CCCCCC'} />
              </IconContainer>
              <div>
                <Label>{t('History.automotive.carInfo.annualDistance')}</Label>
                <Text>{carData.annualDistance} km</Text>
                <Label>{t('History.automotive.carInfo.carLoan.title')}</Label>
                <Text>
                  {carData.carLoan
                    ? t('History.automotive.carInfo.carLoan.yes')
                    : t('History.automotive.carInfo.carLoan.no')}
                </Text>
              </div>
            </IconWrapper>
          </Section.SplitContent>
          <Section.SplitContent>
            <Label>{t('History.automotive.carInfo.usage.title')}</Label>
            <Text>
              {carData.usage
                ? t('History.automotive.carInfo.usage.' + carData.usage)
                : '--'}
            </Text>
          </Section.SplitContent>
        </Section.Split>
      </Section.Content>
    </Section>
  );
};

const Label = styled.label`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #a29c95;
`;

const HeaderText = styled.p`
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 1.5;
  color: #050505;
`;

const Text = styled.p`
  margin-bottom: 1.5rem;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #5b5550;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};
`;
const IconWrapper = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const SectionBordered = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};
`;

DashboardBrokerHistoryAutomotiveCarInformation.defaultProps = {};

DashboardBrokerHistoryAutomotiveCarInformation.propTypes = {
  carInformation: shape().isRequired,
};

export default DashboardBrokerHistoryAutomotiveCarInformation;
