import React from 'react';
import { Section, OverviewTable } from 'wg-fe-ui';
import { object } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const AddressExtras = ({ addressData }) => {
  const { t } = useTranslation();
  const properties = addressData.parcel.properties;

  return (
    <Section>
      <Section.Title>{t('Properties')}</Section.Title>
      <Section.Content>
        {properties !== null ? (
          <OverviewTable>
            <OverviewTable.Head>
              {Object.entries(properties).map(key => {
                const propertyTypes = {
                  has_solar_panels: 'Solar panels',
                  has_swimming_pool: 'Pool',
                  has_porch: 'Porch',
                  has_car_on_driveway: 'Car on driveway',
                };
                const propertyTypeValue = propertyTypes[key[0]]; // this will be high hazard
                return (
                  // eslint-disable-next-line react/jsx-key
                  <Head width={'20%'}>{t(propertyTypeValue)}</Head>
                );
              })}
            </OverviewTable.Head>
            <OverviewTable.Body>
              <StyledRow
              // data-test-id={`dashboard_toolkit_addresslookup_section_annexes_overviewTable_cell_${key}`}
              >
                {Object.entries(properties).map(key => {
                  const propertyValues = {
                    true: 'Present',
                    false: 'Not present',
                    null: '--',
                  };
                  const value = propertyValues[key[1]];
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <OverviewTable.BodyCell>{t(value)}</OverviewTable.BodyCell>
                  );
                })}
              </StyledRow>
            </OverviewTable.Body>
          </OverviewTable>
        ) : (
          <p>{t(`No property data available`)}</p>
        )}
      </Section.Content>
    </Section>
  );
};

const Head = styled(OverviewTable.HeaderCell)`
  color: #5b5550;
  font-size: 1.6rem;
  font-weight: 700;
  width: 20%;
`;

const StyledRow = styled(OverviewTable.Row)`
  /* stylelint-disable-next-line */
  &:hover * {
    background-color: white;
  }
`;

AddressExtras.propTypes = {
  addressData: object.isRequired,
};

export default AddressExtras;
