import {
  retrieveBrokerById,
  retrieveDistributionById,
  retrieveAffiliationsById,
  retrieveMembershipsById,
  retrieveIntegrationsById,
  retrieveAllCRMs,
} from './apiRouterService';
import { getMeData } from './meDataService';
import { getSocialLogin } from '../store';

let brokerData, distributionData, brokerCRMName;

export async function retrieveBrokerId() {
  if (getSocialLogin()) {
    const savedBrokerId = sessionStorage.getItem('broker_id');
    if (savedBrokerId) return savedBrokerId;
  }
  const { id: brokerId } = await getMeData();
  return brokerId;
}

export async function getBrokerData(_brokerId) {
  if (brokerData) return brokerData;
  const brokerId = _brokerId || (await retrieveBrokerId());
  const [_brokerData, status] = await retrieveBrokerById(brokerId);
  if (status === 200) {
    brokerData = _brokerData;
    await getBrokerCRMName();
  }
  return await brokerData;
}

export function resetBrokerData() {
  brokerData = undefined;
  distributionData = undefined;
}

export async function getBrokerDistribution() {
  if (distributionData) return distributionData;
  const { distribution_id } = (await getBrokerData()) || {};
  if (!distribution_id) return distributionData;
  const [{ distribution }, status] = await retrieveDistributionById(
    distribution_id,
  );
  if (status === 200) {
    distributionData = distribution;
  }
  return distributionData;
}

export async function getBrokerAffiliations() {
  const [resp] = await retrieveAffiliationsById(await retrieveBrokerId());
  return resp;
}

export async function getBrokerIntegrations() {
  const [{ integrations }] = await retrieveIntegrationsById(
    await retrieveBrokerId(),
  );
  return integrations;
}

export async function getBrokerMemberships() {
  const [{ memberships }] = await retrieveMembershipsById(
    await retrieveBrokerId(),
  );
  return memberships;
}

export async function getBrokerCRMName(_brokerId) {
  if (brokerCRMName) return brokerCRMName === `` ? null : brokerCRMName;
  const brokerData = await getBrokerData();
  const [_crmData, status] = await retrieveAllCRMs();
  if (status === 200) {
    const _brokerCRMKey = brokerData?.broker?.crm;
    const _crmNameDict = _crmData?.items.reduce((map, obj) => {
      map[obj?.broker_crm?.name] = obj?.broker_crm?.label;
      return map;
    });
    const _brokerCRMName = Object.keys(_crmNameDict).includes(_brokerCRMKey)
      ? _crmNameDict[_brokerCRMKey]
      : null;
    brokerCRMName = _brokerCRMName;
    // console.log(`Broker CRM:`, brokerCRMName);
  }
  return brokerCRMName;
}

export async function getBrokerCRMKey(_brokerId) {
  const brokerData = await getBrokerData(_brokerId);
  return brokerData;
}
