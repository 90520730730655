import React from 'react';
import { string } from 'prop-types';

const Joker = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.24 196.1">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M23.06,96.82C51.63,118,70.17,153.69,71.81,193.05a3,3,0,0,0,2.86,3h76a3.16,3.16,0,0,0,2.82-3.88c-8.31-48.12-41.94-87.75-87.14-96.76A95.32,95.32,0,0,0,23.06,96.82Z"
        />
        <path
          fill={color}
          d="M207.34,107.91a137.71,137.71,0,0,0-17.2,27.41,147.93,147.93,0,0,0-12.76,43c-4.27-18.32-11.19-34.39-20.65-48a88,88,0,0,1,41.59-21.16,84,84,0,0,1,9-1.28m7-14.31a96.17,96.17,0,0,0-18.74,1.86C174,99.77,155,111.07,140.21,127a3.56,3.56,0,0,0-.15,4.58c14.1,17,22.29,38.44,26,61.87a3,3,0,0,0,2.82,2.69h18.35a3,3,0,0,0,2.86-3c1.54-36.82,17.86-70.47,43.35-91.95,2-1.71,1.26-5.33-1.22-5.8a94.15,94.15,0,0,0-17.88-1.71Z"
        />
        <path
          fill={color}
          d="M136,54.92a113,113,0,0,0,7.25,36.28A114.65,114.65,0,0,0,131,101,132.94,132.94,0,0,0,116.13,90.2a110.83,110.83,0,0,1,13.92-27.47q2.83-4.06,5.95-7.81m13.28-30.4a2.57,2.57,0,0,0-1.53.51,121.56,121.56,0,0,0-29.18,29.69,125,125,0,0,0-18.29,39.54,3.4,3.4,0,0,0,1.55,3.83,118.73,118.73,0,0,1,27.55,20.37,2.69,2.69,0,0,0,1.91.82,2.72,2.72,0,0,0,2-.87c7.68-8,15.24-14.55,25.14-20.19a3.44,3.44,0,0,0,1.22-4.44c-9.7-19.91-11.87-42.82-7.53-65.3a3.17,3.17,0,0,0-2.8-4Z"
        />
        <circle fill={color} cx="154.27" cy="17.45" r="17.45" />
        <circle fill={color} cx="239.78" cy="96.55" r="17.45" />
        <circle fill={color} cx="17.45" cy="97.62" r="17.45" />
      </g>
    </g>
  </svg>
);

Joker.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Joker.propTypes = {
  title: string,
  color: string,
};

export default Joker;
