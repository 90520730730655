import React from 'react';

const CoverageAbroad = () => (
  <svg viewBox="0 0 264.99 211.57">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'coverage_abroad'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M262.66 141.2c-16.94-18.24-88.05-16.81-88.05-16.81l-14.34-24.77c-37.54-13.73-105.91-10.92-134-.89C22.69 100 0 127.59 0 138.06l2.14 38.57a9.27 9.27 0 008.92 6.75l19.54.93.63 5.57a27.24 27.24 0 0053.34 0l.43-5.57h98.6l1 5.57a27.24 27.24 0 0053.33 0l1.05-5.57 11.9-.93a9.29 9.29 0 008.93-6.75l4.85-17.1c.83-3.1.19-15.96-2-18.33zm-204.76 61a17.76 17.76 0 01-16.79-12.33 17.58 17.58 0 01-1.11-5.56 17.91 17.91 0 0135.82 0 17.59 17.59 0 01-1.13 5.57 17.74 17.74 0 01-16.79 12.33zm153.31 0a17.74 17.74 0 01-16.78-12.33 17.59 17.59 0 01-1.13-5.57 17.91 17.91 0 0135.82 0 17.58 17.58 0 01-1.12 5.57 17.75 17.75 0 01-16.79 12.34z"
        />
        <path
          className="prefix__cls-1"
          d="M158.17 62.53H25.65a7 7 0 100 14h17.73v27.07a7 7 0 0014 0V76.52h69.06v27.07a7 7 0 0014 0V76.52h17.73a7 7 0 100-14zM29.37 0H90a4.67 4.67 0 014.7 4.67v42.92h-70V4.67A4.67 4.67 0 0129.37 0zM155.82 20.43v27.63h-46.66v-32.3h42a4.66 4.66 0 014.66 4.67z"
        />
      </g>
    </g>
  </svg>
);

export default CoverageAbroad;
