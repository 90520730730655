import React, { useState, useEffect, useContext } from 'react';
import { string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import _ from 'lodash';
import { useDebouncedCallback } from 'use-debounce';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

/* API services */
import { getBrokerDistribution } from '../../services/brokerDataService';
import { parseIncDateToIsoFormat } from '../../services/dateService';
import {
  getFilteredContractsByDistributionId,
  getAffinityByCompanyKey,
  getBrokersByDistributionId,
  updateContractById,
  createContractRevision,
} from '../../services/apiRouterService';
import ContractCancellationModal from '../../components/CRMContractCancellationModal';
import CheckBoxContainer from '../../components/CheckBoxContainer';
import moment from 'moment'; // using moment because airbnb date range shit expects a moment object
import i18next from 'i18next';
import cogoToast from 'cogo-toast';
import {
  CollapsibleSidebar,
  CheckBox_v2 as CheckBox,
  OrderTable,
  // CheckBoxContainer,
  RiskObjectLabel,
  PhaseLabel,
  SearchSelectInput,
  // Pagination,
  IconPropertyFilled,
  // IconFlightFilled,
  IconLegalFilled,
  IconFamilyFilled,
  IconCarFilled,
  IconHeavyScooter,
  IconStats,
  ActionButton,
  SearchInput,
  LoadingSpinner,
  SubLabel,
  OverviewTable,
} from 'wg-fe-ui';

import QuickActionSubMenuStatus from '../../components/QuickActionSubmenuStatus';
import QuickActionSubMenu from '../../components/QuickActionSubmenu';

import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';
import DateFilterComponent from '../../components/DateFilterComponent';

import Frame from '../../../assets/images/Frame.svg';

const rejectedContract = {};

const CRMContractsOverview = ({ match }) => {
  const { t } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const [startDateCreated, setStartDateCreated] = useState(null);
  const [endDateCreated, setEndDateCreated] = useState(null);
  const [startDateEnded, setStartDateEnded] = useState(null);
  const [endDateEnded, setEndDateEnded] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [topSearchValue, setTopSearchValue] = useState('');
  const [showCount, setShowCount] = useState(15);
  const [distributionId, setDistributionId] = useState();
  const [paginationData, setPaginationData] = useState({});
  const [affinities, setAffinities] = useState([]);
  const [affinityKeyList, setAffinityKeyList] = useState([]);
  const [cachedPaginationData, setCachedPaginationData] = useState({});
  const [amounOfEntries, setAmountOfEntries] = useState('');
  const [brokers, setBrokers] = useState([]);
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  // const [allBrokers, setAllBrokers] = useState([]);
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);
  const [selectedCreatedByOptions, setSelectedCreatedByOptions] = useState([]);
  const [selectedAssignedToOptions, setSelectedAssignedToOptions] = useState(
    [],
  );
  const [tempAssignedToOptions, setTempAssignedToOptions] = useState([]);
  const [tempCreatedByOptions, setTempCreatedByOptions] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    offer: true,
    contract: true,
    // property: true,
    // family: true,
    // car: true,
    // two_wheeler: true,
    // teacher: true,
    PENDING: true,
    ACCEPTED: true,
    REJECTED: true,
    TERMINATED: true,
    startDateCreated: null,
    endDateCreated: null,
    startDateEnded: null,
    endDateEnded: null,
    created_by: [],
  });
  const [previousFilters, setPreviousFilters] = useState({
    offer: true,
    contract: true,
    // property: true,
    // family: true,
    // car: true,
    // two_wheeler: true,
    // teacher: true,
    PENDING: true,
    ACCEPTED: true,
    REJECTED: true,
    TERMINATED: true,
    startDateCreated: null,
    endDateCreated: null,
    startDateEnded: null,
    endDateEnded: null,
    created_by: [],
  });
  const [haveFiltersChanged, setHaveFiltersChanged] = useState(false);
  const history = useHistory();

  const [retrievedData, setRetrievedData] = useState([]);
  const [cachedData, setCachedData] = useState([]);

  const getAffinities = async () => {
    const [resp, status] = await getAffinityByCompanyKey('callant');
    const tempAffinities = [];
    const tempAffinityKeyList = [];
    const tempFilters = { ...activeFilters };
    const tempPreviousFilters = { ...previousFilters };
    if (status !== 200) return setAffinities(false);
    resp.items.forEach(element => {
      tempAffinities.push({
        key: element.affinity_info.key,
        label: element.affinity_info.names[i18next.language],
      });
      tempAffinityKeyList.push(element.affinity_info.key);
      tempFilters[element.affinity_info.key] = true;
      tempPreviousFilters[element.affinity_info.key] = true;
    });
    setAffinityKeyList(tempAffinityKeyList);
    setActiveFilters(tempFilters);
    setPreviousFilters(tempPreviousFilters);
    setAffinities(tempAffinities);
  };

  const retrieveBrokersFromDistribution = async id => {
    const [resp] = await getBrokersByDistributionId(id);

    const idsToRemoveFrom = [
      '3471bb16-fe88-4e34-840e-b2d000ac1603',
      '0b6c634d-4f1d-4264-89ee-bbe8a06bfa24',
    ];

    // Sets all brokers and removes specific brokers from the CALLANT list.
    setBrokers(
      resp.items.filter(
        item =>
          item.broker.plan !== 'CALLANT' &&
          !idsToRemoveFrom.some(obj => obj === item.broker.id),
      ),
    );

    const sortedAllBrokers = resp.items.sort((b1, b2) => {
      if (b1?.broker?.name > b2?.broker?.name) return 1;
      if (b1?.broker?.name < b2?.broker?.name) return -1;
      return 0;
    });

    const tempCreatedByOptions = [];

    sortedAllBrokers
      .filter(object => !idsToRemoveFrom.some(obj => obj === object.broker.id))
      .forEach(item => {
        tempCreatedByOptions.push({
          label: item.broker.name,
          value: item.broker.id,
        });
      });

    setCreatedByOptions(tempCreatedByOptions);

    const tempAssignedToOptions = [];
    resp.items
      .filter(object => !idsToRemoveFrom.some(obj => obj === object.broker.id))
      .forEach(item => {
        tempAssignedToOptions.push({
          label: item.broker.name,
          value: item.broker.id,
        });
      });

    setAssignedToOptions(tempAssignedToOptions);
  };

  useEffect(() => {
    getAffinities();
  }, []);

  useEffect(() => {
    setNavTabs(
      setTabItems(null, t, 'contractsOverview', match.match.params.page),
    );
    getFilteredContracts();
  }, [match.match.params.page]);

  useEffect(() => {
    if (cachedData.length > 0) return handleApplyFilters();
    showAmountOfEntries(paginationData);
  }, [cachedPaginationData, cachedData /*, retrievedData*/]);

  useEffect(() => {
    if (startDateCreated !== previousFilters.startDateCreated) {
      setHaveFiltersChanged(true);
    } else {
      setHaveFiltersChanged(false);
    }
    setActiveFilters({
      ...activeFilters,
      startDateCreated,
    });
  }, [startDateCreated]);

  useEffect(() => {
    if (
      _.isEqual(previousFilters, activeFilters) &&
      _.isEqual(tempCreatedByOptions, selectedCreatedByOptions) &&
      _.isEqual(tempAssignedToOptions, selectedAssignedToOptions)
    ) {
      setHaveFiltersChanged(false);
    } else {
      setHaveFiltersChanged(true);
    }
  }, [selectedCreatedByOptions, selectedAssignedToOptions]);

  useEffect(() => {
    if (endDateCreated !== previousFilters.endDateCreated) {
      setHaveFiltersChanged(true);
    } else {
      setHaveFiltersChanged(false);
    }
    setActiveFilters({
      ...activeFilters,
      endDateCreated,
    });
  }, [endDateCreated]);

  useEffect(() => {
    if (startDateEnded !== previousFilters.startDateEnded) {
      setHaveFiltersChanged(true);
    } else {
      setHaveFiltersChanged(false);
    }
    setActiveFilters({
      ...activeFilters,
      startDateEnded,
    });
  }, [startDateEnded]);

  useEffect(() => {
    if (endDateEnded !== previousFilters.endDateEnded) {
      setHaveFiltersChanged(true);
    } else {
      setHaveFiltersChanged(false);
    }
    setActiveFilters({
      ...activeFilters,
      endDateEnded,
    });
  }, [endDateEnded]);

  const showAmountOfEntries = ({ amount, page, pagelen }) => {
    setAmountOfEntries(
      amount > 0
        ? `${page * (showCount >= pagelen ? pagelen : showCount) -
            (showCount >= pagelen ? pagelen : showCount) +
            1} ${t('pagination.to')} ${
            page * showCount >= pagelen ? pagelen : showCount
          } ${t('pagination.of')} ${amount}`
        : `0`,
    );
  };

  const bottomScrolled = () => {
    setShowCount(prevValue => {
      if (prevValue >= paginationData.pagelen) return prevValue;
      return prevValue + 5;
    });
    showAmountOfEntries(paginationData);
  };

  useBottomScrollListener(bottomScrolled);

  // async function getContracts() {
  //   const { id } = await getBrokerDistribution();

  //   let [response, status] = await getAllOffersByDistributionId(
  //     id,
  //     match.match.params.page,
  //   );

  //   if (status !== 200) {
  //     return console.error('Error Response: ', response);
  //   }

  //   console.log('Response: ', response);

  //   setRetrievedData(await response.items);
  //   setPaginationData(await response.pagination);
  //   setIsLoading(false);
  // }

  const handleApplyFilters = () => {
    setPreviousFilters({ ...activeFilters });
    setTempCreatedByOptions([...selectedCreatedByOptions]);
    setTempAssignedToOptions([...selectedAssignedToOptions]);
    const tempData = JSON.parse(JSON.stringify(cachedData));
    const tempPaginationData = JSON.parse(JSON.stringify(cachedPaginationData));
    const filteredData = tempData.filter(item => {
      let _RETURN = false;
      let _RETURNRISK = true;
      let _RETURNDATECREATED = false;
      let _RETURNDATEENDED = false;
      let _RETURNEDASSIGNEDTO =
        selectedAssignedToOptions.length > 0 ? false : true;
      let _RETURNAFFINITY = affinityKeyList.length > 0 ? false : true;
      let _RETURNCREATEDBY = selectedCreatedByOptions.length > 0 ? false : true;
      if (selectedCreatedByOptions.length > 0) {
        if (
          selectedCreatedByOptions.filter(e => item.created_by === e.value)
            .length > 0
        ) {
          _RETURNCREATEDBY = true;
        }
      }
      if (selectedAssignedToOptions.length > 0) {
        if (
          selectedAssignedToOptions.filter(e => item.assigned_to === e.value)
            .length > 0
        ) {
          _RETURNEDASSIGNEDTO = true;
        }
      }
      Object.keys(activeFilters).forEach(key => {
        if (key === item.insurance.affinity) _RETURNAFFINITY = true;
        switch (key) {
          case 'PENDING':
            if (item.status === 'PENDING') _RETURN = true;
            break;
          case 'ACCEPTED':
            if (item.status === 'ACCEPTED') _RETURN = true;
            break;
          case 'REJECTED':
            if (item.status === 'REJECTED') _RETURN = true;
            break;
          case 'TERMINATED':
            if (item.status === 'TERMINATED') _RETURN = true;
            break;
          default:
            break;
        }
        switch (key) {
          case 'car':
            if (item.amount_of_risk_objects.cars > 0) {
              _RETURNRISK = true;
            } else {
              _RETURNRISK = false;
            }
            break;
          case 'family':
            if (item.amount_of_risk_objects.families > 0) {
              _RETURNRISK = true;
            } else {
              _RETURNRISK = false;
            }
            break;
          case 'property':
            if (item.amount_of_risk_objects.residences > 0) {
              _RETURNRISK = true;
            } else {
              _RETURNRISK = false;
            }
            break;
          case 'teacher':
            if (item.amount_of_risk_objects.teachers > 0) {
              _RETURNRISK = true;
            } else {
              _RETURNRISK = false;
            }
            break;
          case 'two_wheeler':
            if (item.amount_of_risk_objects.two_wheelers > 0) {
              _RETURNRISK = true;
            } else {
              _RETURNRISK = false;
            }
            break;
          default:
            return false;
        }
      });
      const itemDate = moment(new Date(item.created_at));
      if (startDateCreated && endDateCreated) {
        const tempEnd = endDateCreated.set({ h: 23, m: 59 });
        const tempStart = startDateCreated.set({ h: 0, m: 1 });
        _RETURNDATECREATED = itemDate.isBetween(tempStart, tempEnd);
      } else if (startDateCreated && !endDateCreated) {
        const tempStart = startDateCreated.set({ h: 0, m: 1 });
        _RETURNDATECREATED = itemDate.isSameOrAfter(tempStart);
      } else if (!startDateCreated && endDateCreated) {
        const tempEnd = endDateCreated.set({ h: 23, m: 59 });
        _RETURNDATECREATED = itemDate.isSameOrBefore(tempEnd);
      } else {
        _RETURNDATECREATED = true;
      }
      const itemDateEnded = moment(new Date(item.end_date));
      if (startDateEnded && endDateEnded) {
        const tempEnd = endDateEnded.set({ h: 23, m: 59 });
        const tempStart = startDateEnded.set({ h: 0, m: 1 });
        _RETURNDATEENDED = itemDateEnded.isBetween(tempStart, tempEnd);
      } else if (startDateEnded && !endDateEnded) {
        const tempStart = startDateEnded.set({ h: 0, m: 1 });
        _RETURNDATEENDED = itemDateEnded.isSameOrAfter(tempStart);
      } else if (!startDateEnded && endDateEnded) {
        const tempEnd = endDateEnded.set({ h: 23, m: 59 });
        _RETURNDATEENDED = itemDateEnded.isSameOrBefore(tempEnd);
      } else {
        _RETURNDATEENDED = true;
      }
      return (
        _RETURN &&
        _RETURNRISK &&
        _RETURNDATECREATED &&
        _RETURNDATEENDED &&
        _RETURNAFFINITY &&
        _RETURNCREATEDBY &&
        _RETURNEDASSIGNEDTO
      );
    });
    tempPaginationData.amount = filteredData.length;
    tempPaginationData.pagelen = filteredData.length;
    setShowCount(15);
    setRetrievedData(filteredData);
    setPaginationData(tempPaginationData);
    showAmountOfEntries(tempPaginationData);
    setHaveFiltersChanged(false);
  };

  async function getFilteredContracts() {
    const { id } = await getBrokerDistribution();

    let [response, status] = await getFilteredContractsByDistributionId(
      id,
      match.match.params.page,
      activeFilters,
      topSearchValue,
    );

    if (status !== 200) {
      return console.error('Error Response: ', response);
    }

    setDistributionId(id);
    console.log('Response: ', response);
    retrieveBrokersFromDistribution(id);
    setRetrievedData(await response.items);
    setCachedData(await response.items);
    setPaginationData(await response.pagination);
    setCachedPaginationData(await response.pagination);
    setIsLoading(false);
  }

  const [debounceFetchContracts] = useDebouncedCallback(
    getFilteredContracts,
    500,
  );

  const handleTopSearch = e => {
    setTopSearchValue(e);
    debounceFetchContracts();
  };

  const handleTopSearchClear = () => {
    setTopSearchValue('');
    debounceFetchContracts();
  };

  const contractClickHandler = (e, contract) => {
    e.preventDefault();
    history.push(
      `/sales-management/case/${contract.contract_case_id}/contract/${contract.id}`,
    );
  };

  const createOfferHandler = () => {
    console.log('Create contract');
  };

  function getRiskObjectIcon(key) {
    switch (key) {
      case 'cars':
        return <IconCarFilled />;
      case 'families':
        return <IconFamilyFilled />;
      case 'residences':
        return <IconPropertyFilled />;
      case 'teachers':
        return <IconStats />;
      case 'two_wheelers':
        return <IconHeavyScooter />;
      case 'legal':
        return <IconLegalFilled />;
      default:
        return '';
    }
  }

  const checkValuesInObjectIsZero = object => {
    if (object != null || undefined) {
      return Object.keys(object).every(function(key) {
        return object[key] === 0;
      });
    }
  };

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  const updateAssignedTo = async (broker, i, caseId) => {
    const { id } = await getBrokerDistribution();
    const tempData = JSON.parse(JSON.stringify(retrievedData));
    setRetrievedData(prevValue => {
      const temp = JSON.parse(JSON.stringify(prevValue));
      temp[i].assigned_to = broker;
      return temp;
    });
    const [resp, respStatus] = await updateContractById(id, caseId, {
      assigned_to: broker,
    });
    if (respStatus === 200 || respStatus === 201) {
      cogoToast.success(t('Offer was successfully assigned'));
    } else if (resp.msg != null) {
      setRetrievedData(tempData);
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const updateStatus = async (status, i, caseId) => {
    if (status === 'TERMINATED') return setShowCancellationModal(true);
    const { id } = await getBrokerDistribution();
    const tempData = JSON.parse(JSON.stringify(retrievedData));
    setRetrievedData(prevValue => {
      const temp = JSON.parse(JSON.stringify(prevValue));
      temp[i].status = status;
      return temp;
    });
    const [resp, respStatus] = await updateContractById(id, caseId, { status });
    if (respStatus === 201) {
      cogoToast.success(t('Contract status has been changed'));
    } else if (resp.msg != null) {
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
      setRetrievedData(tempData);
    } else {
      setRetrievedData(tempData);
      cogoToast.error(t('Something went wrong'));
    }
  };

  const handleContractCancellation = async extraValues => {
    let payload = {};

    if (extraValues) {
      payload = {
        ...payload,
        ...extraValues,
      };
    }

    const closeCogoToast = cogoToast.loading(t('Terminating contract'), {
      hideAfter: 0,
    });

    const [resp, status] = await createContractRevision(
      distributionId,
      rejectedContract.case_id,
      rejectedContract.contract_id,
      payload,
    );

    closeCogoToast();

    if (status === 201) {
      setRetrievedData(prevValue => {
        const temp = JSON.parse(JSON.stringify(prevValue));
        temp[rejectedContract.index].status = 'TERMINATED';
        return temp;
      });
      cogoToast.success(
        t('The contract adjustments have been successfully saved'),
      );
    } else if (resp.msg != null || resp.detail != null) {
      cogoToast.error(
        <ToasterContent>
          <strong>{t(resp.msg)}</strong>
          <p>{t(resp.detail)}</p>
        </ToasterContent>,
        {
          onClick: hide => {
            hide();
          },
          hideAfter: 15,
        },
      );
      return console.error('ERROR', resp);
    } else {
      cogoToast.error(t('Something went wrong'));
      console.log(
        `DEBUG RESP: ${JSON.stringify(resp, null, 4)} & STATUS: ${status}`,
      );
    }
  };

  const submitContractCancellation = async values => {
    values.termination = {
      date_for_termination: parseIncDateToIsoFormat(
        values.date_for_termination,
      ),
      reason: values?.reason,
      reason_for_termination: values?.reason_for_termination,
    };
    values.revision_type = 'CONTRACT_TERMINATION';
    delete values?.reason;
    delete values?.reason_for_termination;
    delete values?.date_for_termination;
    handleContractCancellation(values);
  };

  const toggleAllCheckBoxes = (checkboxNames, value) => {
    const newFilters = { ...activeFilters };
    checkboxNames.forEach(key => {
      newFilters[key] = value;
    });
    if (_.isEqual(previousFilters, newFilters)) {
      setHaveFiltersChanged(false);
    } else {
      setHaveFiltersChanged(true);
    }
    setActiveFilters(newFilters);
  };

  const handleFilterChange = e => {
    let newFilters = { ...activeFilters };
    newFilters[e.name] = e.value;
    if (e.value === false) delete newFilters[e.name];

    if (
      _.isEqual(previousFilters, newFilters) &&
      _.isEqual(tempCreatedByOptions, selectedCreatedByOptions)
    ) {
      setHaveFiltersChanged(false);
    } else {
      setHaveFiltersChanged(true);
    }
    setActiveFilters(newFilters);
  };

  // const handleSearchSelectInput = e => {
  //   console.log(e);
  // };

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft></TopBarLeft>

          <TopBarRight>
            <TopBarSearch
              placeholder={t('Search for a name')}
              onChange={event => {
                handleTopSearch(event);
              }}
              text={topSearchValue}
              onClear={() => handleTopSearchClear()}
              data-test-id="contracts_search"
            />

            <TopBarAction>
              <ActionButton onClick={createOfferHandler} disabled>
                {t('New contract')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <ContractCancellationModal
        modalState={[showCancellationModal, setShowCancellationModal]}
        terminateContract={submitContractCancellation}
        type="Contract"
      />

      <MainContent>
        {retrievedData?.length > 0 || retrievedData !== cachedData ? (
          <>
            <Sidebar>
              <CollapsibleSidebar label={t('Filters')} open>
                {affinities ? (
                  <>
                    <CheckBoxContainer
                      label={t('Affinities')}
                      defaultChecked={() => {
                        let isChecked = true;
                        affinityKeyList.forEach(el => {
                          if (!activeFilters[el]) return (isChecked = false);
                        });
                        return isChecked;
                      }}
                      toggleAllCheckBoxes={toggleAllCheckBoxes}
                    >
                      {affinities.map(item => (
                        <StyledCheckBox
                          key={item.key}
                          name={item.key}
                          checked={activeFilters[item.key]}
                          onChange={handleFilterChange}
                        >
                          {t(`${item.label}`)}
                        </StyledCheckBox>
                      ))}
                    </CheckBoxContainer>

                    <SidebarSpacer />
                  </>
                ) : null}
                <CheckBoxContainer
                  label={t('Contract phase')}
                  toggleAllCheckBoxes={toggleAllCheckBoxes}
                  defaultChecked={
                    activeFilters.PENDING &&
                    activeFilters.REJECTED &&
                    activeFilters.ACCEPTED &&
                    activeFilters.TERMINATED
                  }
                >
                  <StyledCheckBox
                    name="PENDING"
                    checked={activeFilters.PENDING}
                    onChange={handleFilterChange}
                  >
                    {t(`contracts.PENDING`)}
                  </StyledCheckBox>
                  <StyledCheckBox
                    name="ACCEPTED"
                    checked={activeFilters.ACCEPTED}
                    onChange={handleFilterChange}
                  >
                    {t(`contracts.ACCEPTED`)}
                  </StyledCheckBox>
                  <StyledCheckBox
                    name="REJECTED"
                    checked={activeFilters.REJECTED}
                    onChange={handleFilterChange}
                  >
                    {t(`contracts.REJECTED`)}
                  </StyledCheckBox>
                  <StyledCheckBox
                    name="TERMINATED"
                    checked={activeFilters.TERMINATED}
                    onChange={handleFilterChange}
                  >
                    {t(`contracts.TERMINATED`)}
                  </StyledCheckBox>
                </CheckBoxContainer>

                <SidebarSpacer />

                <CheckBoxContainer
                  label={t('Risk objects')}
                  toggleAllCheckBoxes={toggleAllCheckBoxes}
                  defaultChecked={
                    activeFilters.property &&
                    activeFilters.family &&
                    activeFilters.car &&
                    activeFilters.teacher &&
                    activeFilters.two_wheeler
                  }
                >
                  <StyledCheckBox
                    name="property"
                    checked={activeFilters.property}
                    onChange={handleFilterChange}
                  >
                    <IconPropertyFilled size={18} color="#505050" />
                    <CheckBoxSpacer /> {t('Property')}
                  </StyledCheckBox>

                  <StyledCheckBox
                    name="family"
                    checked={activeFilters.family}
                    onChange={handleFilterChange}
                  >
                    <IconFamilyFilled size={18} color="#505050" />
                    <CheckBoxSpacer />
                    {t('Liability')}
                  </StyledCheckBox>

                  <StyledCheckBox
                    name="car"
                    checked={activeFilters.car}
                    onChange={handleFilterChange}
                  >
                    <IconCarFilled size={18} color="#505050" />
                    <CheckBoxSpacer />
                    {t('Car insurance type')}
                  </StyledCheckBox>

                  <StyledCheckBox
                    name="two_wheeler"
                    checked={activeFilters.two_wheeler}
                    onChange={handleFilterChange}
                  >
                    <IconHeavyScooter size={18} color="#505050" />
                    <CheckBoxSpacer />
                    {t('Two wheeler insurance type')}
                  </StyledCheckBox>

                  <StyledCheckBox
                    name="teacher"
                    checked={activeFilters.teacher}
                    onChange={handleFilterChange}
                  >
                    <IconStats size={18} color="#505050" />
                    <CheckBoxSpacer />
                    {t('Teacher insurance type')}
                  </StyledCheckBox>

                  {/* <StyledCheckBox name="travel">
                    <IconFlightFilled size={18} color="#505050" />
                    <CheckBoxSpacer />
                    Travel
                  </StyledCheckBox> */}
                </CheckBoxContainer>

                <SidebarSpacer />

                <DateFilterComponent
                  startDate={startDateCreated}
                  endDate={endDateCreated}
                  setStartDate={setStartDateCreated}
                  name={'filterDateCreated'}
                  setEndDate={setEndDateCreated}
                >
                  {t('Created between')}
                </DateFilterComponent>

                <SidebarSpacer />

                <DateFilterComponent
                  startDate={startDateEnded}
                  endDate={endDateEnded}
                  name={'filterDateEnded'}
                  setStartDate={setStartDateEnded}
                  setEndDate={setEndDateEnded}
                >
                  {t('Ended between')}
                </DateFilterComponent>

                <SidebarSpacer />

                <StyledSearchSelectInput
                  isMulti
                  name="assigned_to"
                  options={assignedToOptions}
                  placeholder={t('Choose an option')}
                  onSelected={({ value }) => {
                    const option = value ? value : [];
                    setSelectedAssignedToOptions(option);
                  }}
                >
                  {t('Assigned to')}
                </StyledSearchSelectInput>

                <StyledSearchSelectInput
                  isMulti
                  name="created_by"
                  options={createdByOptions}
                  placeholder={t('Choose an option')}
                  onSelected={({ value }) => {
                    const option = value ? value : [];
                    setSelectedCreatedByOptions(option);
                  }}
                >
                  {t('Created by')}
                </StyledSearchSelectInput>

                <SidebarSpacer />

                <ActionButton
                  disabled={!haveFiltersChanged}
                  fullwidth
                  icon={null}
                  level="primary"
                  onClick={() => {
                    handleApplyFilters();
                  }}
                >
                  {t('Apply filters')}
                </ActionButton>
              </CollapsibleSidebar>
            </Sidebar>

            <Content>
              <OrderTable>
                <OverviewTable.Head otherProps={{}}>
                  <OverviewTable.HeaderCell width="12%">
                    {t('Policy nr')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="18%">
                    {t('Client / Company name')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="15%">
                    {t('Created on')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="20%">
                    {t('Assigned to')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="20%">
                    {t('Risk objects')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="15%">
                    {t('Phase')}
                  </OverviewTable.HeaderCell>
                </OverviewTable.Head>

                <OrderTable.Body>
                  {retrievedData.slice(0, showCount).map((contract, i) => {
                    return (
                      <StyledOrderTableRow
                        onClick={e => contractClickHandler(e, contract)}
                        key={i}
                      >
                        <OrderTable.BodyCell
                          data-test-id={`quote_${i}_policy_nr`}
                        >
                          <IdLabel>{`${contract?.policy_nr}`}</IdLabel>
                        </OrderTable.BodyCell>
                        <StyledBodyCell data-test-id={`quote_${i}_name`}>
                          {contract?.prospect?.first_name}{' '}
                          {contract?.prospect?.last_name}
                          {contract?.prospect?.name}
                        </StyledBodyCell>
                        <OrderTable.BodyCell>
                          {formatDate(contract?.created_at)}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <QuickActionSubMenu
                            onClick={e => e.stopPropagation()}
                            name={
                              contract?.assigned_to
                                ? brokers?.length > 0
                                  ? brokers?.find(
                                      item =>
                                        item?.broker?.id ===
                                        contract?.assigned_to,
                                    )?.broker?.name
                                  : t('loading')
                                : t('No one')
                            }
                          >
                            {brokers?.map(item => (
                              <QuickActionSubMenu.SubMenuItem
                                key={item.broker.id}
                                label={
                                  <SubLabel>{item?.broker?.name}</SubLabel>
                                }
                                onClick={() =>
                                  updateAssignedTo(
                                    item?.broker?.id,
                                    i,
                                    contract?.contract_case_id,
                                  )
                                }
                                type=""
                              />
                            ))}
                            <QuickActionSubMenu.SubMenuItem
                              label={<SubLabel>{t('No one')}</SubLabel>}
                              onClick={() =>
                                updateAssignedTo(
                                  null,
                                  i,
                                  contract?.contract_case_id,
                                )
                              }
                              type=""
                            />
                          </QuickActionSubMenu>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell
                          data-test-id={`quote_${i}_amount_risk_objects`}
                        >
                          <RiskObjectsWrapper>
                            {!checkValuesInObjectIsZero(
                              contract?.amount_of_risk_objects,
                            ) ? (
                              Object.keys(contract?.amount_of_risk_objects).map(
                                key => {
                                  if (
                                    contract?.amount_of_risk_objects[key] === 0
                                  )
                                    return null;
                                  return (
                                    <RiskObjectLabel
                                      key={key}
                                      amount={
                                        contract?.amount_of_risk_objects[key]
                                      }
                                      icon={getRiskObjectIcon(key)}
                                    />
                                  );
                                },
                              )
                            ) : (
                              <RiskObjectLabel
                                key={'legal'}
                                amount="1"
                                icon={getRiskObjectIcon('legal')}
                              />
                            )}
                          </RiskObjectsWrapper>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell
                          data-test-id={`quote_${i}_contract_status`}
                        >
                          <QuickActionSubMenuStatus
                            onClick={e => e.stopPropagation()}
                            status={contract?.status}
                            label={t(`contracts.${contract?.status}`)}
                          >
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="PENDING">
                                  {t('contracts.PENDING')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'PENDING',
                                  i,
                                  contract?.contract_case_id,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="ACCEPTED">
                                  {t('contracts.ACCEPTED')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'ACCEPTED',
                                  i,
                                  contract?.contract_case_id,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="REJECTED">
                                  {t('contracts.REJECTED')}
                                </PhaseLabel>
                              }
                              onClick={() =>
                                updateStatus(
                                  'REJECTED',
                                  i,
                                  contract?.contract_case_id,
                                )
                              }
                              type=""
                            />
                            <QuickActionSubMenuStatus.SubMenuItem
                              label={
                                <PhaseLabel status="TERMINATED">
                                  {t('contracts.TERMINATED')}
                                </PhaseLabel>
                              }
                              onClick={() => {
                                updateStatus(
                                  'TERMINATED',
                                  i,
                                  contract?.contract_case_id,
                                );
                                rejectedContract.case_id =
                                  contract?.contract_case_id;
                                rejectedContract.contract_id = contract?.id;
                                rejectedContract.index = i;
                              }}
                              type=""
                            />
                          </QuickActionSubMenuStatus>
                        </OrderTable.BodyCell>
                      </StyledOrderTableRow>
                    );
                  })}
                </OrderTable.Body>
              </OrderTable>

              <ContentFooter>
                <span className="records">
                  {t('pagination.showing')} <strong>{amounOfEntries}</strong>{' '}
                  {t('pagination.entries')}
                </span>

                {/* <Pagination
                  base="/sales-management/contracts/contracts/"
                  currentPage={paginationData.page}
                  pageLength={10}
                  totalPages={paginationData.pages}
                /> */}
              </ContentFooter>
            </Content>
          </>
        ) : topSearchValue !== '' && retrievedData?.length === 0 ? (
          <EmptyState>
            <img src={Frame} alt="Test" />
            <h1>{t('No search results found')}</h1>
            <p>
              {`${t(
                'We could not find anything with the following search term',
              )}: `}{' '}
              <strong> {topSearchValue}</strong>
            </p>
          </EmptyState>
        ) : (
          <EmptyState>
            <img src={Frame} alt="Missing data" />
            <h1>{t('Create your first contract!')}</h1>
            <p>
              {t('You can only make contracts from quotes')} <br />{' '}
              {t(
                'Select an offer of your choice and convert it into a contract!',
              )}
            </p>
            {/* <ActionButton onClick={createOfferHandler}>
                Create contract
              </ActionButton> */}
          </EmptyState>
        )}
      </MainContent>
    </MainContainer>
  );
};

const StyledSearchSelectInput = styled(SearchSelectInput)`
  > label {
    font-weight: bold;
  }

  div {
    font-weight: normal;
  }
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 6px;
`;

const StyledBodyCell = styled(OrderTable.BodyCell)`
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarSearch = styled(SearchInput)`
  background-color: #f7f7f7;
  padding-left: 3.5rem;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
  visibility: hidden;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 32px;
  flex: 0 0 252px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 182px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 110rem;
`;

const SidebarSpacer = styled.div`
  margin-top: 20px;
`;

const RiskObjectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex: 0 0 33.33%;
  }
`;

const ToasterContent = styled.div`
  strong {
    font-weight: bold;
  }

  p {
    margin-top: 1rem;
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  .records {
    font-size: 1.4rem;
    line-height: 130%;
    color: ${({ theme }) => theme.ui.disabled};

    strong {
      font-weight: 900;
    }
  }
`;

const CheckBoxSpacer = styled.div`
  width: 6px;
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;
    margin-bottom: 35px;
  }
`;

const IdLabel = styled.p`
  font-size: 1.1rem;
  color: ${({ theme }) => theme.typo.interactive};
  text-align: left;
`;

const StyledOrderTableRow = styled(OrderTable.Row)`
  &:hover {
    p {
      color: ${({ theme }) => theme.typo.title};
    }
  }
`;

CRMContractsOverview.propTypes = {
  match: shape({}).isRequired,
  type: string.isRequired,
};

export default CRMContractsOverview;
