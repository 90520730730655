/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { shape } from 'prop-types';

const Drone = ({ colors }) => (
  <svg
    width="143"
    height="84"
    viewBox="0 0 143 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.2525 64.4219H108.567V81.7775"
      stroke={colors.brand.secondary}
      strokeWidth="2.33368"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M108.457 68.9446C110.884 68.9446 112.851 66.9773 112.851 64.5506C112.851 62.1238 110.884 60.1565 108.457 60.1565C106.03 60.1565 104.063 62.1238 104.063 64.5506C104.063 66.9773 106.03 68.9446 108.457 68.9446Z"
      fill={colors.brand.primary}
    />
    <path
      d="M100.919 81.7775H116.013"
      stroke={colors.brand.lighter}
      strokeWidth="3.11156"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.3466 81.7775V64.4219H49.6798"
      stroke={colors.brand.secondary}
      strokeWidth="2.33368"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.4569 68.9446C36.8837 68.9446 38.8509 66.9773 38.8509 64.5506C38.8509 62.1238 36.8837 60.1565 34.4569 60.1565C32.0301 60.1565 30.0628 62.1238 30.0628 64.5506C30.0628 66.9773 32.0301 68.9446 34.4569 68.9446Z"
      fill={colors.brand.primary}
    />
    <path
      d="M26.919 81.7775H42.0132"
      stroke={colors.brand.lighter}
      strokeWidth="3.11156"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M98.7681 34.2153H116.344V1.54492"
      stroke={colors.brand.secondary}
      strokeWidth="2.33368"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M116.344 40.0803C119.323 40.0803 121.731 37.6719 121.731 34.6935V25.8502C121.731 22.8718 119.323 20.4634 116.344 20.4634C113.366 20.4634 110.957 22.8718 110.957 25.8502V34.6935C110.957 37.6719 113.366 40.0803 116.344 40.0803Z"
      fill={colors.brand.primary}
    />
    <path
      opacity="0.15"
      d="M121.713 31.2001V34.6933C121.713 37.6716 119.304 40.0617 116.326 40.0617C113.348 40.0617 110.957 37.6533 110.957 34.6933V31.2001C110.957 34.1785 113.366 36.5685 116.326 36.5685C119.304 36.5685 121.713 34.1601 121.713 31.2001Z"
      fill="#141414"
    />
    <path
      d="M140.227 7.63031H92.6826C91.5611 7.63031 90.6602 6.72943 90.6602 5.60794C90.6602 4.48644 91.5611 3.58557 92.6826 3.58557H140.227C141.348 3.58557 142.249 4.48644 142.249 5.60794C142.249 6.71105 141.348 7.63031 140.227 7.63031Z"
      fill={colors.brand.light}
    />
    <path
      d="M44.1274 34.2153H26.5512V1.54492"
      stroke={colors.brand.secondary}
      strokeWidth="2.33368"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5512 40.0803C29.5296 40.0803 31.938 37.6719 31.938 34.6935V25.8502C31.938 22.8718 29.5296 20.4634 26.5512 20.4634C23.5728 20.4634 21.1643 22.8718 21.1643 25.8502V34.6935C21.1827 37.6719 23.5912 40.0803 26.5512 40.0803Z"
      fill={colors.brand.primary}
    />
    <path
      opacity="0.15"
      d="M31.8645 31.2001V34.6933C31.8645 37.6716 29.456 40.0617 26.4776 40.0617C23.4992 40.0617 21.1092 37.6533 21.1092 34.6933V31.2001C21.1092 34.1785 23.5176 36.5685 26.4776 36.5685C29.4376 36.5685 31.8645 34.1601 31.8645 31.2001Z"
      fill="#141414"
    />
    <path
      d="M50.4335 7.63031H2.88946C1.76797 7.63031 0.867096 6.72943 0.867096 5.60794C0.867096 4.48644 1.76797 3.58557 2.88946 3.58557H50.4335C51.555 3.58557 52.4558 4.48644 52.4558 5.60794C52.4742 6.71105 51.555 7.63031 50.4335 7.63031Z"
      fill={colors.brand.light}
    />
    <path
      d="M97.8305 34.2151H45.5615V69.5881H97.8305V34.2151Z"
      fill={colors.brand.light}
    />
    <path
      opacity="0.15"
      d="M97.8305 34.2151H45.5615V43.0032H97.8305V34.2151Z"
      fill="#141414"
    />
    <path
      d="M101.618 30.391H41.6086V40.08H101.618V30.391Z"
      fill={colors.brand.light}
    />
    <path
      d="M70.933 61.718C74.1111 61.718 76.6875 59.1416 76.6875 55.9634C76.6875 52.7853 74.1111 50.2089 70.933 50.2089C67.7548 50.2089 65.1784 52.7853 65.1784 55.9634C65.1784 59.1416 67.7548 61.718 70.933 61.718Z"
      fill={colors.brand.secondary}
    />
    <path
      opacity="0.15"
      d="M71.9074 61.6273C71.5949 61.6825 71.2639 61.7009 70.933 61.7009C67.7524 61.7009 65.1784 59.127 65.1784 55.9463C65.1784 52.7657 67.7524 50.1918 70.933 50.1918C71.2639 50.1918 71.5949 50.2102 71.9074 50.2653C69.2048 50.7249 67.1273 53.0966 67.1273 55.9279C67.1273 58.7592 69.1864 61.1677 71.9074 61.6273Z"
      fill="#141414"
    />
  </svg>
);

Drone.propTypes = {
  colors: shape().isRequired,
};

export default Drone;
