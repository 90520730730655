/* stylelint-disable no-descending-specificity, declaration-no-important, value-keyword-case */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import { isMobile } from 'react-device-detect';
import Layout from '../../LayoutFlow/views/App';

import LeftSide from '../../../../components/LeftSide';
import RightSide from '../../../../components/RightSide';
import InteractiveMap from '../components/InteractiveMap';
import IconCheckBox from '../../../../components/IconCheckBox';
import Button from '../../../../components/Button';
import InfoPopUp from '../../../../components/InfoPopUp';
import gardenIcon from '../../../../../assets/images/garden-icon';
import solarIcon from '../../../../../assets/images/solar-icon';
import poolIcon from '../../../../../assets/images/pool-icon';
import shedIcon from '../../../../../assets/images/shed-icon';
import verandaIcon from '../../../../../assets/images/veranda-icon';
import ClosedBuildingIcon from '../../../../../assets/images/closed-building-icon';
import OpenBuildingIcon from '../../../../../assets/images/open-building-icon';
import HalfOpenBuildingIcon from '../../../../../assets/images/halfopen-building-icon';
import ApartmentBuildingIcon from '../../../../../assets/images/apartment-building-icon';
import checkmark from '../../../../../assets/images/checkmark.svg';

// HouseStore import
import {
  setTraits,
  getTraits,
  getAdress,
  getAddressInfo,
  setAddressSizes,
  setRoomLayout,
} from '../store';

// Commonstore import
import {
  getCurrentTheme,
  getNextPath,
  getComponentName,
  addChatboxQuestion,
  addChatboxAnswer,
  addBrowserHistory,
} from '../../../../store';

// UserStore import
import AppartmentInput from '../components/AppartmentInput';
import { useTranslation } from 'react-i18next';
import CarportAddCarToCarFlowPopup from '../../../Dashboard/components/CarportAddCarToCarFlowPopup';
import { getBrokerDistribution } from '../../../../services/brokerDataService';

const AdresCheck = ({ history }) => {
  const checkIcons = {
    gardenIcon,
    solarIcon,
    poolIcon,
    shedIcon,
    verandaIcon,
  };

  const info = getAddressInfo();
  const traits = getTraits();
  const { t } = useTranslation();
  const [tempTraits, setTempTraits] = useState(traits);
  const [showCarportAddToCarFlow, setShowCarportAddToCarFlow] = useState(false);
  const [koetsierStraat, setKoetsierstraat] = useState(false);
  const [goToNextPath, setGoToNextPath] = useState(false);
  const [buttonPopupClicked, setButtonPopupClicked] = useState(false);
  const [checkboxesChecked, setCheckboxesChecked] = useState({
    specialFlooring: getTraits().special_flooring
      ? getTraits().special_flooring
      : false,
    cellar: getTraits().cellar ? getTraits().cellar : false,
    cellarHabitable:
      getTraits().cellar && !getTraits().is_cellar_unhabited ? true : false,
  });

  const [manual, setManual] = useState(getAdress().manual);
  const [surface, setSurface] = useState(
    info.building && info.surface ? info.surface : '',
  );
  const [habitableSurface, setHabitableSurface] = useState(
    info.habitableSurface ? info.habitableSurface : '',
  );

  const [height, setHeight] = useState(
    info.building && info.height ? info.height : '',
  );
  const [parcel_area, setParcel_area] = useState(
    info.building && info.parcel_area ? info.parcel_area : '',
  );
  const [price, setPrice] = useState(
    info.building && traits.rental_price ? traits.rental_price : '',
  );
  const [floor, setFloor] = useState(
    info.building && traits.floor
      ? traits.floor !== null
        ? traits.floor
        : ''
      : '',
  );
  const [disabled, setDisabled] = useState(true);
  const [building, setBuilding] = useState(
    info.building ? info.building : null,
  );
  const [apartment, setApartment] = useState({});
  const [errors, setErrors] = useState({});
  const location = useLocation();
  const extras = [];
  let shouldUpdateFlooring = true;
  addChatboxQuestion(t(`${getComponentName()}.question`));
  useEffect(() => {
    addBrowserHistory(location.pathname);
    const tempAppartment = { ...apartment };
    switch (building) {
      case 'apartment':
        tempAppartment['floor'] = floor;
        tempAppartment['habitableSurface'] = habitableSurface;
        if (!traits.is_owner) {
          tempAppartment['price'] = price;
        }
        setApartment(tempAppartment);
        break;
      default:
        if (building === null || building === '') {
          setDisabled(false);
        } else {
          if (!traits.is_owner) {
            if (price !== '' || price !== null) {
              setDisabled(true);
            } else {
              setDisabled(false);
            }
          } else {
            setDisabled(false);
          }
        }
        break;
    }
  }, []);

  useEffect(() => {
    if (building === 'apartment') {
      if (checkboxesChecked['cellar'] !== getTraits().cellar) {
        setTraits('cellar', checkboxesChecked['cellar']);
        setTraits('is_cellar_unhabited', true);
      }
      if (checkboxesChecked['cellar'] === false) {
        setTraits('is_cellar_unhabited', null);
      }
    }
  }, [checkboxesChecked['cellar']]);

  useEffect(() => {
    if (building === 'apartment') {
      if (checkboxesChecked['cellar'] === true) {
        if (checkboxesChecked['cellarHabitable'] === true) {
          setTraits(
            'is_cellar_unhabited',
            !checkboxesChecked['cellarHabitable'],
          );
        } else {
          setTraits('is_cellar_unhabited', true);
        }
      } else {
        setTraits('is_cellar_unhabited', null);
      }
    }
  }, [checkboxesChecked['cellarHabitable']]);

  useEffect(() => {
    if (building === 'apartment') {
      if (checkboxesChecked['specialFlooring'] !== getTraits().special_flooring)
        setTraits('special_flooring', checkboxesChecked['specialFlooring']);
    }
  }, [checkboxesChecked['specialFlooring']]);

  useEffect(() => {
    if (!getAdress().manual) {
      if (surface !== info.surface) {
        setSurface(info.surface);
        setHabitableSurface(info.habitableSurface);
        setHeight(info.height);
        setParcel_area(info.parcel_area);
        setBuilding(info.building);
      }
    }
    setManual(getAdress().manual);
  }, [getAdress().manual]);

  useEffect(() => {
    if (building !== 'apartment') {
      if (!traits.is_owner) {
        setDisabled(building !== null && building !== '' && price !== '');
      } else {
        setDisabled(!(building === null || building === ''));
      }
    } else {
      const enabled = traits.is_owner
        ? habitableSurface !== '' && floor !== ''
        : habitableSurface !== '' && floor !== '' && price !== '';
      if (enabled) {
        setDisabled(!enabled);
      } else {
        if (isMobile) {
          setDisabled(true);
          setApartment(
            traits.is_owner
              ? {
                  habitableSurface: 120,
                  floor: 0,
                  price: 650,
                }
              : {
                  habitableSurface: 120,
                  floor: 0,
                },
          );
        }
      }
    }

    // empty apartment in state
  }, [building]);

  ///DIA MUNICH DEFAULT TRUE!
  useEffect(() => {
    if (
      getAdress().street === 'Koetsierstraat' &&
      getAdress().housenr === '10' &&
      getAdress().zipcode === 8560 &&
      getAdress().city === 'Wevelgem'
    ) {
      setKoetsierstraat(true);
      const koetsierTraits = { ...tempTraits };
      koetsierTraits.garden = true;
      koetsierTraits.garden_house = true;
      koetsierTraits.porch = true;
      koetsierTraits.solar_panels = true;
      setTempTraits(koetsierTraits);
      setTraits('garden', true);
      setTraits('garden_house', true);
      setTraits('porch', true);
      setTraits('solar_panels', true);
    }
  }, []);

  useEffect(() => {
    if (building === 'apartment') {
      const minLength = traits.is_owner ? 2 : 3;
      if (
        Object.keys(apartment).length === minLength &&
        Object.keys(errors).length === 0
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [apartment]);

  const loadExtraList = () => {
    for (let i = 0; i < 5; i++) {
      const Icon = checkIcons[t(`${getComponentName()}.extras.${i}.icon`)];
      const extraItem = (
        <IconCheckBox
          checked={tempTraits[t(`${getComponentName()}.extras.${i}.key`)]}
          key={t(`${getComponentName()}.extras.${i}.key`)}
          name={t(`${getComponentName()}.extras.${i}.key`)}
          dataTestId={`underwriting_home_extras_${t(
            `${getComponentName()}.extras.${i}.key`,
          )}`}
          onCheck={(name, checked) => onBoxClick(name, checked)}
        >
          <Icon />
          <p>{t(`${getComponentName()}.extras.${i}.text`)}</p>
        </IconCheckBox>
      );
      extras.push(extraItem);
    }
  };

  const inputChange = (name, value, error) => {
    const tempAppartment = { ...apartment };
    const tempErrors = { ...errors };
    let buttonDisabled = true;
    tempAppartment[name] = value;
    if (value === '') {
      delete tempAppartment[name];
    }

    if (error) {
      tempErrors[name] = 'not valid';
    } else {
      delete tempErrors[name];
    }

    if (Object.keys(tempErrors).length > 0) {
      buttonDisabled = false;
    }

    if (building === '' || building === null) {
      buttonDisabled = false;
    }

    if (value.length === 0) {
      buttonDisabled = false;
    }

    switch (name) {
      case 'floor':
        setFloor(value);
        break;
      case 'habitableSurface':
        setHabitableSurface(value);
        break;
      case 'price':
        setPrice(value);
        break;
      default:
        break;
    }

    setApartment(tempAppartment);
    setErrors(tempErrors);
    setDisabled(buttonDisabled);
  };

  const special_flooring_title =
    t(`${getComponentName()}.apartmentCheckboxes.special_flooring.name`) +
    t(
      `${getComponentName()}.apartmentCheckboxes.special_flooring.popUp.button`,
    );

  const onBoxClick = (name, checked) => {
    // Store in step store
    const localTraits = { ...tempTraits };
    localTraits[name] = checked;
    setTempTraits(localTraits);
    setTraits(name, checked);
  };

  const handleButton = async () => {
    // save apartment data in store
    if (building === 'apartment') {
      setRoomLayout({
        bathrooms: 1,
        bedrooms: 1,
        garages: 0,
        kitchens: 1,
        living_rooms: 1,
        other_rooms: 0,
        room_count: 4,
      });
      setTraits('floor', floor);
      setAddressSizes({
        habitableSurface,
        building,
      });
    } else {
      setAddressSizes({
        surface,
        height,
        building,
      });
    }
    if (!traits.is_owner) {
      setTraits('rental_price', price);
    }
    addChatboxAnswer(
      t(`${getComponentName()}.interactiveMsg`, {
        type: t(
          `${getComponentName()}.building_types.${building.toLowerCase()}`,
        ).toLowerCase(),
      }),
    );
    const { email } = await getBrokerDistribution();
    const dia_broker = email.includes('dia_broker');

    if (koetsierStraat && !goToNextPath && dia_broker) {
      setShowCarportAddToCarFlow(true);
      if (buttonPopupClicked) {
        setGoToNextPath(true);
      }
    } else {
      if (building === 'apartment') {
        history.push(getNextPath('b'));
      } else {
        history.push(getNextPath('a'));
      }
    }
  };
  loadExtraList();

  useEffect(() => {
    if (goToNextPath) {
      if (building === 'apartment') history.push(getNextPath('b'));
      else history.push(getNextPath('a'));
    }
  }, [goToNextPath, buttonPopupClicked]);

  return (
    <Layout>
      {showCarportAddToCarFlow ? (
        <CarportAddCarToCarFlowPopup
          showPopup={bool => {
            setShowCarportAddToCarFlow(bool);
          }}
          setNextPath={bool => {
            setGoToNextPath(bool);
          }}
          buttonPopupClicked={bool => {
            setButtonPopupClicked(bool);
          }}
        />
      ) : null}
      <Container>
        <StyledLeftSide>
          {/* wordt data uit store */}
          <div>
            <InteractiveMap manual={getAdress().manual} building={building} />
            {/* <HomeTitle>
              <h3>{t(`${getComponentName()}.address`)}</h3>
              <p>
                {`${getAdress().street} ${getAdress().housenr}${
                  getAdress().boxnr ? `/${getAdress().boxnr}` : ``
                }, ${getAdress().zipcode} ${getAdress().city}`.toLowerCase()}
              </p>
            </HomeTitle> */}
          </div>
        </StyledLeftSide>
        <StyledRightSide>
          <Form>
            <RadioContainer>
              <SubTitle>{t(`${getComponentName()}.bebouwing`)}</SubTitle>
              <InputField manual={manual}>
                <StandardInput
                  type="radio"
                  id="open"
                  name="building"
                  value="open"
                  checked={building === 'open'}
                  onChange={() => setBuilding('open')}
                />
                <label
                  htmlFor="open"
                  data-test-id="underwriting_home_building_open"
                >
                  <BuildingIcon>
                    <div>
                      <div>
                        <OpenBuildingIcon colors={getCurrentTheme()} />
                      </div>
                    </div>
                  </BuildingIcon>
                  <BuildingText>
                    {t(`${getComponentName()}.building.0`)}
                  </BuildingText>
                </label>
              </InputField>
              <InputField manual={manual}>
                <StandardInput
                  type="radio"
                  name="building"
                  value="halfopen"
                  checked={building === 'halfopen'}
                  id="halfopen"
                  manual={getAdress().manual}
                  onChange={() => setBuilding('halfopen')}
                />
                <label
                  htmlFor="halfopen"
                  data-test-id="underwriting_home_building_halfopen"
                >
                  <BuildingIcon>
                    <div>
                      <div>
                        <HalfOpenBuildingIcon colors={getCurrentTheme()} />
                      </div>
                    </div>
                  </BuildingIcon>
                  <BuildingText>
                    {t(`${getComponentName()}.building.1`)}
                  </BuildingText>
                </label>
              </InputField>
              <InputField manual={manual}>
                <StandardInput
                  type="radio"
                  name="building"
                  value="gesloten"
                  id="gesloten"
                  manual={getAdress().manual}
                  checked={building === 'closed'}
                  onChange={() => setBuilding('closed')}
                />
                <label
                  htmlFor="gesloten"
                  data-test-id="underwriting_home_building_closed"
                >
                  <BuildingIcon>
                    <div>
                      <div>
                        <ClosedBuildingIcon colors={getCurrentTheme()} />
                      </div>
                    </div>
                  </BuildingIcon>
                  <BuildingText>
                    {t(`${getComponentName()}.building.2`)}
                  </BuildingText>
                </label>
              </InputField>
              <InputField manual={manual}>
                <StandardInput
                  type="radio"
                  name="building"
                  value="appartement"
                  id="appartement"
                  manual={getAdress().manual}
                  checked={building === 'apartment'}
                  onChange={() => setBuilding('apartment')}
                />
                <label
                  htmlFor="appartement"
                  data-test-id="underwriting_home_building_apartment"
                >
                  <BuildingIcon>
                    <div>
                      <div>
                        <ApartmentBuildingIcon colors={getCurrentTheme()} />
                      </div>
                    </div>
                  </BuildingIcon>
                  <BuildingText>
                    {t(`${getComponentName()}.building.3`)}
                  </BuildingText>
                </label>
              </InputField>
            </RadioContainer>
            {!getAdress().manual && building !== 'apartment' && surface > 1 ? (
              <SpecificationsContainer>
                <SubTitle>{t(`${getComponentName()}.specifications`)}</SubTitle>
                {surface !== '' ? (
                  <SpecDiv>
                    <StyledLabel>
                      {t(`${getComponentName()}.surface`)}
                    </StyledLabel>
                    <StandardInput
                      type="text"
                      name="surface"
                      value={!getAdress().manual ? `${surface} m²` : ''}
                      onChange={e => inputChange(e)}
                      disabled={!getAdress().manual}
                    />
                  </SpecDiv>
                ) : null}
                {height !== '' ? (
                  <SpecDiv>
                    <StyledLabel>
                      {t(`${getComponentName()}.height`)}
                    </StyledLabel>
                    <StandardInput
                      type="text"
                      name="height"
                      value={
                        !getAdress().manual
                          ? typeof height === 'string'
                            ? `-- m`
                            : `${height.toFixed(1)} m`
                          : ''
                      }
                      onChange={e => inputChange(e)}
                      disabled={!getAdress().manual}
                    />
                  </SpecDiv>
                ) : null}
                {parcel_area !== '' ? (
                  <SpecDiv>
                    <StyledLabel>
                      {t(`${getComponentName()}.parcel_area`)}
                    </StyledLabel>
                    <StandardInput
                      type="text"
                      name="surface"
                      value={!getAdress().manual ? `${parcel_area} m²` : ''}
                      onChange={e => inputChange(e)}
                      disabled={!getAdress().manual}
                    />
                  </SpecDiv>
                ) : null}
              </SpecificationsContainer>
            ) : null}
            {!traits.is_owner ? (
              <AppartmentInput
                type="number"
                name="price"
                incrValue={25}
                value={price}
                dataTestId="underwriting_home_price"
                infoTitle={t(`${getComponentName()}.appartment.2.info.title`)}
                info={t(`${getComponentName()}.appartment.2.info.text`)}
                placeholder="650"
                handleChange={(name, value, error) =>
                  inputChange(name, value, error)
                }
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(`${getComponentName()}.appartment.2.text`),
                  }}
                />
              </AppartmentInput>
            ) : null}
            {building === 'apartment' ? (
              <>
                <AppartmentInput
                  type="text"
                  dataTestId="underwriting_home_floor"
                  name={t(`${getComponentName()}.appartment.0.payload`)}
                  placeholder={t(
                    `${getComponentName()}.appartment.0.placeholder`,
                  )}
                  incrValue={1}
                  value={floor}
                  handleChange={(name, value, error) =>
                    inputChange(name, value, error)
                  }
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`${getComponentName()}.appartment.0.text`),
                    }}
                  />
                </AppartmentInput>
                <AppartmentInput
                  type="number"
                  dataTestId="underwriting_home_surface"
                  name={t(`${getComponentName()}.appartment.1.payload`)}
                  placeholder={t(
                    `${getComponentName()}.appartment.1.placeholder`,
                  )}
                  incrValue={10}
                  value={habitableSurface}
                  handleChange={(name, value, error) =>
                    inputChange(name, value, error)
                  }
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(`${getComponentName()}.appartment.1.text`),
                    }}
                  />
                </AppartmentInput>
                <ExtrasContainer>
                  <SubTitle>{t(`${getComponentName()}.extrasTitle`)}</SubTitle>
                  <StyledLabelCheckbox
                    htmlFor="cellar"
                    data-test-id="underwriting_home_cellar"
                    checked={checkboxesChecked['cellar']}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          `${getComponentName()}.apartmentCheckboxes.cellar.name`,
                        ),
                      }}
                    />
                    <StandardInputCheckbox
                      id="cellar"
                      type="checkbox"
                      name="cellar"
                      checked={checkboxesChecked['cellar']}
                      onChange={e => {
                        const tempCheckboxesChecked = { ...checkboxesChecked };
                        if (!e.target.checked) {
                          tempCheckboxesChecked['cellarHabitable'] = false;
                        }
                        tempCheckboxesChecked['cellar'] = e.target.checked;
                        setCheckboxesChecked(tempCheckboxesChecked);
                      }}
                    />
                    <CheckboxItem checked={checkboxesChecked['cellar']}>
                      {checkboxesChecked['cellar'] ? (
                        <img src={checkmark} alt="checkmark" />
                      ) : (
                        ''
                      )}
                    </CheckboxItem>
                  </StyledLabelCheckbox>
                  <StyledLabelCheckbox
                    htmlFor="cellarHabitable"
                    checked={checkboxesChecked['cellarHabitable']}
                    disabled={!checkboxesChecked['cellar']}
                    data-test-id="underwriting_home_cellar_habitable"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t(
                          `${getComponentName()}.apartmentCheckboxes.cellarHabitable.name`,
                        ),
                      }}
                    />
                    <StandardInputCheckbox
                      id="cellarHabitable"
                      type="checkbox"
                      name="cellarHabitable"
                      disabled={!checkboxesChecked['cellar']}
                      checked={checkboxesChecked['cellarHabitable']}
                      onChange={e => {
                        const tempCheckboxesChecked = { ...checkboxesChecked };
                        tempCheckboxesChecked['cellarHabitable'] =
                          e.target.checked;
                        setCheckboxesChecked(tempCheckboxesChecked);
                      }}
                    />
                    <CheckboxItem
                      checked={checkboxesChecked['cellarHabitable']}
                    >
                      {checkboxesChecked['cellarHabitable'] ? (
                        <img src={checkmark} alt="checkmark" />
                      ) : (
                        ''
                      )}
                    </CheckboxItem>
                  </StyledLabelCheckbox>
                  <StyledLabelCheckbox
                    checked={checkboxesChecked['specialFlooring']}
                    data-test-id="underwriting_home_special_flooring"
                  >
                    <span>
                      {t(
                        `${getComponentName()}.apartmentCheckboxes.special_flooring.name`,
                      )}
                      <InfoPopUp
                        title={special_flooring_title}
                        buttonText={t(
                          `${getComponentName()}.apartmentCheckboxes.special_flooring.popUp.button`,
                        )}
                        info={t(
                          `${getComponentName()}.apartmentCheckboxes.special_flooring.popUp.text`,
                        )}
                        isLink={true}
                        onClick={closing => {
                          shouldUpdateFlooring = closing;
                        }}
                      />
                    </span>
                    <StandardInputCheckbox
                      id="specialFlooring"
                      type="checkbox"
                      name="specialFlooring"
                      checked={checkboxesChecked['specialFlooring']}
                      onChange={e => {
                        const tempCheckboxesChecked = { ...checkboxesChecked };
                        tempCheckboxesChecked['specialFlooring'] =
                          e.target.checked;
                        if (shouldUpdateFlooring) {
                          setCheckboxesChecked(tempCheckboxesChecked);
                        } else {
                          shouldUpdateFlooring = true;
                        }
                      }}
                    />
                    <CheckboxItem
                      checked={checkboxesChecked['specialFlooring']}
                    >
                      {checkboxesChecked['specialFlooring'] ? (
                        <img src={checkmark} alt="checkmark" />
                      ) : (
                        ''
                      )}
                    </CheckboxItem>
                  </StyledLabelCheckbox>
                </ExtrasContainer>
              </>
            ) : null}
            {building !== 'apartment' ? (
              <>
                <SubTitle>{t(`${getComponentName()}.extrasTitle`)}</SubTitle>
                <CheckContainer>{extras}</CheckContainer>
              </>
            ) : null}
            <ButtonContainer>
              <Button
                onClick={() => handleButton()}
                dataTestId="underwriting_home_submit"
                disabled={!disabled}
              >
                {t(`${getComponentName()}.btnText`)}
              </Button>
            </ButtonContainer>
          </Form>
        </StyledRightSide>
      </Container>
    </Layout>
  );
};

const StyledLabelCheckbox = styled.label`
  display: flex;
  position: relative;
  margin-top: 0.5rem;
  cursor: pointer;
  line-height: 1.3;
  max-width: calc(50% - 0.75rem);
  flex: 0 0 50%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: ${props => (!props.disabled ? 'white' : 'none')};
  align-items: center;
  margin-bottom: 0.75rem;

  &:first-child {
    padding-right: 1rem;
  }

  & > span a {
    font-size: 1.4rem;
    transition: color 0.1s ease;
    color: #ff8000;
    font-weight: bold;
  }
  & > span {
    font-size: 1.4rem;
    width: 90%;
    transition: color 0.1s ease;
    color: #525252;

    & > span {
      font-weight: bold;
      color: black;
    }
  }
  ${props =>
    props.disabled
      ? `
    color: #AEAEAE;
    & > span {
      color: #aeaeae;

      & > span {
        font-weight: bold;
        color: #aeaeae;
      }
    }
  `
      : null}
`;

const ExtrasContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CheckboxItem = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  margin-top: 0.3rem;
  background-color: ${props => (props.checked ? '#ff8000' : 'none')};
  transition: all 0.1s ease;
  border: ${props =>
    props.checked ? '0.2rem solid #ff8000' : '0.2rem solid #D3D4D8'};
  border-radius: 0.3rem;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;

  & > img {
    height: 90%;
    width: 90%;
    margin: auto;
    object-fit: contain;
  }
`;

const StandardInputCheckbox = styled.input`
  border-radius: 0.3rem;
  opacity: 0;
  margin-left: 2rem;
  margin-right: -1.6rem;
  cursor: pointer;
  &:focus + ${CheckboxItem} {
    outline: none;
    border-color: ${({ theme }) => theme.brand.lighter};
  }
`;

const StyledLeftSide = styled(LeftSide)`
  width: 45%;
  justify-content: center;
  > div {
    /* overflow: auto; */
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledRightSide = styled(RightSide)`
  width: 55%;
  padding-bottom: 0;

  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`;

const SpecificationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`;

const SpecDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;

  & > p {
    line-height: 1.4;
  }
`;

const SubTitle = styled.h2`
  font-weight: 900;
  font-size: 1.65rem;
  flex: 0 0 100%;
  letter-spacing: 1.19px;
  color: black;
  margin-bottom: 1rem;
`;

const BuildingIcon = styled.div`
  background-color: ${({ theme }) => theme.brand.primary};
  flex-basis: 100%;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.15);
  max-height: 6rem;
  max-width: 6rem;
  width: 100%;
  border-radius: 50%;
  transition: opacity 0.3s;

  & > div {
    width: 100%;
    height: 0;
    position: relative;
    padding-top: 100%;
    display: flex;

    & > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const BuildingText = styled.div`
  color: black;
  line-height: 1.4;
  flex: 0 0 100%;
  padding-top: 0.5rem;
  height: 4rem;
`;

const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  max-width: 36rem;
  flex-wrap: wrap;
`;

const InputField = styled.label`
  flex-basis: 23%;
  width: 23%;
  margin: 1%;
  display: flex;
  justify-content: center;
  align-items: center;

  & label {
    opacity: 0.4;
    border-radius: 0.5rem;
    flex-wrap: wrap;
    color: white;
    font-weight: 700;
    text-align: center;
    font-size: 1.1rem;
    flex: 0 0 100%;
    transition: 0.3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;

    &:active {
      transform: scale(0.9);
    }
    &:hover {
      opacity: 0.85;
      cursor: pointer;
    }
  }

  & input {
    display: none;

    &:checked + label {
      opacity: 1;
      color: white;
    }
  }
`;

// const HomeTitle = styled.div`
//   font-size: 1.5rem;
//   color: #505050;
//   text-transform: lowercase;
//   font-weight: 900;
//   width: 40%;
//   padding: 1.5rem 2rem;
//   background: white;
//   box-shadow: 0 4px 13px rgba(0, 0, 0, 0.1);
//   border-radius: 1rem;
//   position: absolute;
//   bottom: 4rem;

//   z-index: 9 & > h3 {
//     text-transform: capitalize;
//     color: #a29c95;
//     font-weight: normal;
//   }

//   & > p {
//     text-transform: capitalize;
//     width: 100%;
//     max-width: auto;
//     text-align: left;
//   }
// `;

const Container = styled.section`
  display: flex;
  position: relative;
  height: 100%;
  font-size: 2rem;
  line-height: 2.6rem;
  width: 100%;
  margin: auto;
  overflow: hidden;

  & > span {
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 3rem 0;
  align-items: flex-end;
  z-index: 0.5;

  & button {
    margin: 0;
  }
`;

const CheckContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-items: flex-start;
  z-index: 10;

  & p {
    font-size: 1.5rem;
    line-height: 2rem;
    max-width: 29rem;
    text-align: left;
    color: black;
  }
`;

const StandardInput = styled.input`
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  line-height: 2rem;
  font-size: 1.3rem;

  &:disabled {
    color: black;
    -webkit-text-fill-color: black;
    font-weight: 700;
  }

  &:focus {
    outline: none;
  }
`;

const StyledLabel = styled.p`
  line-height: 1.5;
  height: 4rem;
  color: #a29c95;
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
  letter-spacing: 1.19px;
  display: flex;
`;

const Form = styled.form`
  margin: 0 auto;
  z-index: 10;
  width: 100%;
  position: relative;
`;

AdresCheck.propTypes = {
  history: shape().isRequired,
};

export default withRouter(AdresCheck);
