import React from 'react';
import { string } from 'prop-types';

const adaptive_cruise_control = ({ className, color }) => (
  <svg
    viewBox="0 0 369.55 400"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill={color}>
      <path d="m224 108.92a145.54 145.54 0 1 0 145.55 145.54 145.54 145.54 0 0 0 -145.55-145.54zm0 176.08a30.56 30.56 0 0 1 -30.55-30.55 30 30 0 0 1 1.63-9.73l-46.9-46.35a13.41 13.41 0 0 1 18.82-19.06l46.9 46.35a30.22 30.22 0 0 1 10.1-1.74 30.55 30.55 0 0 1 0 61.08z" />
      <path d="m204.21 0-14.79 18.2 15.9 12.93c-114.81 9.59-205.32 106.09-205.32 223.33h26.81c0-102.72 78.91-187.35 179.29-196.46l-11.17 13.71 18.2 14.8 38.8-47.71z" />
    </g>
  </svg>
);

adaptive_cruise_control.propTypes = {
  className: string,
  color: string,
};

export default adaptive_cruise_control;
