import React from 'react';
import styled from 'styled-components';
import { shape, string } from 'prop-types';

import Ground from '../../../../../../assets/images/house/Ground';
import Pool from '../../../../../../assets/images/house/Pool';
import Middle from '../../../../../../assets/images/house/Middle';
import MiddleNoBath from '../../../../../../assets/images/house/MiddleNoBath';
import Livingroom from '../../../../../../assets/images/house/Livingroom';
import LivingroomSolar from '../../../../../../assets/images/house/LivingroomSolar';
import Bedroom from '../../../../../../assets/images/house/Bedroom';
import Garage from '../../../../../../assets/images/house/Garage';
import Garden from '../../../../../../assets/images/house/Garden';
import leftNeighbour from '../../../../../../assets/images/house/left-neighbour.svg';
import rightNeighbour from '../../../../../../assets/images/house/right-neighbour.svg';

import { getCurrentTheme } from '../../../../../store/index';
import BedroomSolar from '../../../../../../assets/images/house/BedroomSolar';

const InteractiveHouse = props => {
  const { roomData, traits, buildingType } = props;
  return (
    <SecondContainer>
      <Container>
        <BuildingContainer>
          {buildingType === 'closed' || buildingType === 'halfopen' ? (
            <LeftNeighbour src={leftNeighbour} alt="Neighbour" />
          ) : null}
          {roomData.bedrooms > 0 ? (
            traits.solar_panels ? (
              <BedroomSolar
                mirror={false}
                colors={getCurrentTheme()}
                alt="bedroom"
              />
            ) : (
              <Bedroom
                mirror={false}
                colors={getCurrentTheme()}
                alt="bedroom"
              />
            )
          ) : null}
          {roomData.bathrooms > 0 ? (
            <Middle colors={getCurrentTheme()} alt="bathroom" />
          ) : (
            <MiddleNoBath colors={getCurrentTheme()} alt="bathroom" />
          )}
          {roomData.living_rooms > 0 ? (
            traits.solar_panels ? (
              <LivingroomSolar colors={getCurrentTheme()} alt="livingroom" />
            ) : (
              <Livingroom colors={getCurrentTheme()} alt="livingroom" />
            )
          ) : (
            ''
          )}
          {roomData.living_rooms === 0 && roomData.kitchens > 0 ? (
            traits.solar_panels ? (
              <BedroomSolar mirror colors={getCurrentTheme()} alt="bedroom" />
            ) : (
              <Bedroom mirror colors={getCurrentTheme()} alt="bedroom" />
            )
          ) : (
            ''
          )}
          {roomData.garages > 0 ? (
            <Garage colors={getCurrentTheme()} alt="garage" />
          ) : (
            ''
          )}
          {buildingType === 'closed' ? (
            <RightNeighbour src={rightNeighbour} alt="Neighbour" />
          ) : null}
        </BuildingContainer>

        {traits.garden ? (
          <Garden colors={getCurrentTheme()} alt="Garden" />
        ) : null}
        {traits.swimming_pool ? (
          <Pool colors={getCurrentTheme()} alt="Pool" />
        ) : (
          ''
        )}

        <Ground colors={getCurrentTheme()} alt="ground" />
      </Container>
    </SecondContainer>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  font-size: 2rem;
  line-height: 2.6rem;
  transform: scale(0.85);
  transition: transform 0.2s ease;
  /* @media screen and (max-width: 1025px) {
    transform: scale(0.8);
  }

  @media screen and (max-width: 760px) {
    transform: scale(0.6);
  }

  @media screen and (max-height: 869px) {
    transform: scale(0.6);
  }

  @media screen and (max-height: 750px) {
    transform: scale(0.25);
  } */

  & span {
    font-weight: bold;
  }

  @keyframes fadeInLeft {
    from {
      margin-left: -2rem;
    }

    to {
      margin-left: 0;
    }
  }

  @keyframes fadeInRight {
    from {
      margin-right: -2rem;
    }

    to {
      margin-right: 0;
    }
  }
`;

const LeftNeighbour = styled.img`
  height: 8rem;
  z-index: -1;
  position: relative;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 11%;
  }
`;

const RightNeighbour = styled.img`
  height: 8rem;
  margin-left: -4rem;
  z-index: -1;
  position: relative;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 11%;
  }
`;

const BuildingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  width: 70rem;
  justify-content: center;
`;

const SecondContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 17rem;
  transition: height 0.2s ease;

  @media screen and (max-width: 1025px) {
    height: 17rem;
  }

  @media screen and (max-width: 750px) {
    height: 10rem;
  }

  /* @media screen and (max-height: 869px) {
    height: 9rem;
  }

  @media screen and (max-height: 750px) {
    height: 7rem;
  }

  @media screen and (max-height: 680px) {
    display: none;
  } */
`;

InteractiveHouse.propTypes = {
  roomData: shape().isRequired,
  traits: shape().isRequired,
  buildingType: string.isRequired,
};

export default InteractiveHouse;
