import React from 'react';
import { shape } from 'prop-types';
const dashboardBackGroundElement = ({ colors }) => {
  return (
    <svg
      width="793"
      height="1080"
      viewBox="0 0 793 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1658.54 -61.2458L823.971 -398L662.914 -302.83L0.386963 1223.54H1244.91L1687.82 140.075L1658.54 -61.2458Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="1505.09"
          y1="-720.739"
          x2="355.083"
          y2="1743.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={colors.brand.lightest} stopOpacity="0.6" />
          <stop
            offset="0.864"
            stopColor={colors.brand.lightest}
            stopOpacity="0.0816163"
          />
          <stop offset="1" stopColor={colors.brand.lightest} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

dashboardBackGroundElement.propTypes = {
  colors: shape().isRequired,
};

export default dashboardBackGroundElement;
