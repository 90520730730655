import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import Layout from '../../LayoutFlow/views/App';
import QuestionBox from '../../../../components/QuestionBox';
import { shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../../../components/ChatBox';
import {
  setNotice,
  showErrorPopup,
  getNextPath,
  getComponentName,
  getAllCoverages,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { sendCarData, sendCarExtraData, setInsuredData } from '../store';
import {
  getMatchData,
  setMatchData,
  resetMatchData,
} from '../../../Marketplace/Market/store';
import {
  removeFromCheckout,
  resetSkippedSteps,
} from '../../../Marketplace/LayoutMarket/store';
import ApiRoutes from '../../../../store/ApiRoutes';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const InsuranceQuestionCar = ({ history }) => {
  const api = new ApiRoutes();
  const { t } = useTranslation();
  const location = useLocation();
  const [finishedFetch, setFinishedFetch] = useState({});
  const [isPending, setIsPending] = useState(false);
  const [answer, setAnswer] = useState(null);
  const content = t(`${getComponentName()}`, { returnObjects: true });
  const answerA = t(`${getComponentName()}.answers.0.title`);
  const answerB = t(`${getComponentName()}.answers.1.title`);
  const question = t(`${getComponentName()}.question`);
  const coverages = getAllCoverages();
  const flowStore = useFlowStore();
  const tempCov = [
    {
      type: 'home',
      bool: coverages.home,
    },
    {
      type: 'family',
      bool: coverages.family,
    },
    {
      type: 'car',
      bool: coverages.car,
    },
  ];
  let covCount = 0;
  tempCov.map(coverage => {
    if (coverage.bool) {
      covCount += 1;
    }
  });

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    let _return = true;
    if (Object.keys(finishedFetch).length === covCount) {
      Object.keys(finishedFetch).map(key => {
        if (!finishedFetch[key]) {
          _return = false;
        }
      });
      if (_return) {
        api.postSession().then(() => {
          if (coverages.legal) {
            if (!coverages.family) {
              history.push(getNextPath('e'));
            } else {
              history.push(getNextPath('f'));
            }
            return;
          }
          if (coverages.home) {
            history.push(getNextPath('b'));
          } else if (coverages.family) {
            history.push(getNextPath('c'));
          } else {
            history.push(getNextPath('d'));
          }
        });
      }
    }
  }, [finishedFetch]);

  const handleAnswer = answer => {
    if (isPending) return false;
    setIsPending(true);
    addChatboxQuestion(content.question);
    switch (answer) {
      case answerA:
        addChatboxAnswer(t(`${getComponentName()}.answers.0.response`));
        setAnswer(1);
        history.push(getNextPath('a'));
        break;
      case answerB:
        addChatboxAnswer(t(`${getComponentName()}.answers.1.response`));
        setInsuredData('already_insured_with', false);
        setNotice('car', false, null);
        setAnswer(2);
        sendCarData()
          .then(() => {
            sendCarExtraData()
              .then(() => {
                if (coverages.legal) {
                  let tempsomething = {};
                  tempCov.map(coverage => {
                    if (coverage.bool) {
                      tempsomething = {
                        ...tempsomething,
                        [coverage.type]: true,
                      };
                    }
                  });
                  setFinishedFetch(tempsomething);
                } else {
                  resetSkippedSteps();
                  removeFromCheckout('fire');
                  removeFromCheckout('people');
                  removeFromCheckout('car');
                  removeFromCheckout('legal');
                  let tempsomething = {};
                  tempCov.map(coverage => {
                    if (coverage.bool) {
                      tempsomething = {
                        ...tempsomething,
                        [coverage.type]: false,
                      };

                      const { type, campaign } = flowStore || {};
                      console.log(flowStore);
                      if (type === 'campaign' && campaign?.extend === 'LEAD') {
                        history.push('/thank-you-campaigns');
                        return;
                      }
                      resetMatchData();
                      getMatchData(coverage.type)
                        .then(r => {
                          tempsomething = {
                            ...tempsomething,
                            [coverage.type]: true,
                          };
                          setFinishedFetch(tempsomething);
                          setMatchData(
                            coverage.type === `home` ? `fire` : coverage.type,
                            r.data,
                          );
                        })
                        .catch(error => {
                          console.error(error.response);
                        });
                    }
                  });
                }
              })
              .catch(error => {
                showErrorPopup(true);
                console.error(error.response);
              });
          })
          .catch(error => {
            showErrorPopup(true);
            console.error(error.response);
          });
        break;
    }
  };

  return (
    <Layout>
      <Container>
        <ChatBox showQuestion={true} />
        <QuestionBox
          Answer1={answerA}
          Answer2={answerB}
          dataTestId1="underwriting_answer_yes"
          dataTestId2="underwriting_answer_no"
          shouldLoad={isPending ? answer : undefined}
          response={answer => handleAnswer(answer)}
        >
          {question}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

InsuranceQuestionCar.propTypes = {
  history: shape().isRequired,
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default withRouter(InsuranceQuestionCar);
