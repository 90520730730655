import React from 'react';

const Vehicle = () => (
  <svg viewBox="0 0 286.03 100.58">
    <title>{'vehiclematrix_family_'}</title>
    <path
      d="M283.54 42.4c-18.12-19.51-94.15-18-94.15-18L153.47 1.08C75.66-6.78 20.36 30.54 3.79 43.48a9.87 9.87 0 00-3.4 10.57L5 70.17a9.93 9.93 0 009.55 7.22h21.52a29.14 29.14 0 0057.06 0h106.76a29.14 29.14 0 0057.06 0h13.94a9.93 9.93 0 009.55-7.22l5.18-18.28a9.94 9.94 0 00-2.08-9.49zM64.6 90.58a19.16 19.16 0 01-18.19-13.19 18.94 18.94 0 01-1-6 19.15 19.15 0 0138.29 0 18.66 18.66 0 01-1 6 19.16 19.16 0 01-18.1 13.19zm163.82 0a19.16 19.16 0 01-18.19-13.19 18.94 18.94 0 01-1-6 19.15 19.15 0 0138.29 0 18.93 18.93 0 01-.95 6 19.18 19.18 0 01-18.15 13.19z"
      fill="#fff"
      data-name="Layer 2"
    />
  </svg>
);

export default Vehicle;
