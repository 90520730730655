import React, { useState } from 'react';
import styled from 'styled-components';
import { node, func, string, bool } from 'prop-types';

import { getRoomLayout } from '../store';
import InfoPopUp from '../../../../components/InfoPopUp';

const Counter = props => {
  const {
    children,
    ifChanged,
    id,
    increaseDisabled,
    buttonTouched,
    decreaseDisabled,
    info,
  } = props;

  const [value, setValue] = useState(getRoomLayout()[id]);
  const [hover, setHover] = useState(false);

  const increase = () => {
    const number = parseInt(value, 10) + 1;
    if (!increaseDisabled) {
      setValue(number);
      ifChanged(id, number);
    }
    buttonTouched(`increase`);
  };

  const decrease = () => {
    const number = parseInt(value, 10) - 1;
    if (!decreaseDisabled && number >= 0) {
      setValue(number);
      ifChanged(id, number);
    }
    buttonTouched(`decrease`);
  };

  const handleChange = e => {
    setValue(e.currentTarget.value);
    ifChanged(id, e.currentTarget.value);
  };

  return (
    <Container infoLength={info ? info.length : null}>
      <h2
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setHover(!hover)}
      >
        {children}
        {info !== null ? <InfoPopUp title={children} info={info} /> : ''}
      </h2>
      <InputContainer>
        <Button
          type="button"
          className={value === 0 ? `disabled` : null}
          data-test-id={`underwriting_home_rooms_decrease_${id}`}
          onClick={() => {
            decrease();
          }}
        >
          <StyleButton lighter>-</StyleButton>
        </Button>
        <Input
          type="number"
          disabled
          min="0"
          max="13"
          name="value"
          value={value}
          onChange={e => handleChange(e)}
          data-test-id={`underwriting_home_rooms_input_${id}`}
        />
        <Button
          type="button"
          data-test-id={`underwriting_home_rooms_increase_${id}`}
          className={increaseDisabled ? `disabled` : null}
          onClick={() => {
            increase();
          }}
        >
          <StyleButton>+</StyleButton>
        </Button>
      </InputContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;
  margin: 0.5rem 1rem 0;
  flex: 1 0 25%;
  margin-bottom: 2rem;
  cursor: pointer;

  & span {
    font-weight: bold;
  }

  & h2 {
    color: ${({ theme }) => theme.brand.primary};
    font-size: 2rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    position: relative;
  }
`;

const Input = styled.input`
  width: 5.5rem;
  height: 5.5rem;
  text-align: center;
  border-radius: 40%;
  border: 0;
  appearance: textfield;
  font-weight: 900;
  font-size: 2rem;
  margin: 0 1rem;
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(204, 204, 204);

  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: black;
    -webkit-text-fill-color: black;
    opacity: 1;
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyleButton = styled.div`
  background-color: ${({ theme, lighter }) =>
    lighter ? theme.brand.secondary : theme.brand.primary};
  border: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 2.5rem;
  line-height: 3rem;
  border-radius: 50%;
  transition: all 0.2s ease;

  &:active {
    background-color: ${({ theme }) => theme.brand.dark};
  }
`;

const Button = styled.button`
  background-color: none;
  border: 0;
  width: 3.5rem;
  height: 3.5rem;
  padding: 0;
  border-radius: 50%;
  cursor: pointer;

  &.disabled ${StyleButton} {
    filter: greyscale(85%);

    &:active {
      background-color: auto;
    }
  }

  &:focus {
    outline: none;
  }
`;

Counter.defaultProps = {
  ifChanged: func,
  info: '',
};

Counter.propTypes = {
  children: node.isRequired,
  ifChanged: func,
  id: string.isRequired,
  increaseDisabled: bool.isRequired,
  buttonTouched: func.isRequired,
  info: string,
  decreaseDisabled: bool.isRequired,
};

export default Counter;
