import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import { useFlowStore } from '../services/context/LouiseFlowContext';
import { postLeadFlow } from '../services/apiRouterService';

const ThankYouCampaigns = () => {
  const [apiStatus, setApiStatus] = useState('loading');
  const { t, i18n } = useTranslation();
  const flowStore = useFlowStore();

  useEffect(() => {
    (async () => {
      const [, status] = await postLeadFlow(
        i18n.language,
        sessionStorage.getItem('id'),
      );
      if (status > 201) return setApiStatus('error');
      setApiStatus('success');
    })();
  }, []);

  function apiRenderOptions(status) {
    switch (status) {
      case 'loading':
        return <LoadingSpinner />;
      case 'success':
        return (
          <>
            <AvatarContainer>
              <Avatar src={flowStore?.distribution?.avatar} alt="" />
              <div>
                <ContentHeader>
                  <h1>{t('Thank you')}</h1>
                  <p>{t('We have received your information correctly')}</p>
                  <p>{t('You can close this window')}</p>
                </ContentHeader>
              </div>
            </AvatarContainer>
          </>
        );
      default:
        return (
          <>
            <AvatarContainer>
              <Avatar src={flowStore?.distribution?.avatar} alt="" />
              <div>
                <ContentHeader>
                  <h1>{t('Something has gone wrong')}</h1>
                  <p>{t('Try again later')}</p>
                </ContentHeader>
              </div>
            </AvatarContainer>
          </>
        );
    }
  }

  return (
    <Background>
      <MainContainer>
        <Header>
          <Logo src={flowStore?.distribution?.logoFe} alt="WeGroup" />
        </Header>
        <ContentContainer>{apiRenderOptions(apiStatus)}</ContentContainer>
      </MainContainer>
    </Background>
  );
};

const AvatarContainer = styled.div`
  display: flex;
`;

const Avatar = styled.img`
  width: 20rem;
  margin-right: 3rem;
`;
const Background = styled.main`
  background-color: ${({ theme }) => theme.brand.primary};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.font};
  font-size: 1.5rem;
  padding: 4rem 0;
  min-height: 100vh;
  overflow: hidden;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  max-height: 7rem;
`;

const ContentContainer = styled.div`
  width: 100%;
  margin-top: 3.8rem;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  max-width: 120rem;
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentHeader = styled.header`
  color: white;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 2rem;

  margin-top: 2.5rem;
  & h1 {
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 1.5rem;
  }
  & p {
    margin-top: 0.5rem;
  }
`;

export default ThankYouCampaigns;
