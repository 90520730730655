import React, { useState } from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import Apartment from '../../../../../assets/images/mock/Apartment';
import MapMock from '../../../../../assets/images/mock/MapMock';
import EyeIcon from '../../../../../assets/images/eye-icon';
import CrossedEyeIcon from '../../../../../assets/images/crossed-eye-icon';
import { getMapsPhoto, getMarkedMapsPhoto } from '../store';
import { getCurrentTheme } from '../../../../store';
import { string } from 'prop-types';
import { getAdress } from '../store';
import { useTranslation } from 'react-i18next';

const InteractiveMap = ({ manual, building }) => {
  const [showEye, setShoweye] = useState(getMarkedMapsPhoto() ? true : false);
  const [showPictureDialog, setShowPictureDialog] = useState(false);
  const { t } = useTranslation();

  return (
    <Wrapper>
      {showPictureDialog ? (
        <PictureDialogWrapper onClick={() => setShowPictureDialog(false)}>
          <PictureDialog
            src={`data:image/png;base64, ${
              showEye ? getMarkedMapsPhoto() : getMapsPhoto()
            }`}
            alt=""
          />
        </PictureDialogWrapper>
      ) : null}
      <MapContainer>
        {getMapsPhoto() ? (
          <>
            {getMarkedMapsPhoto() ? (
              <>
                <StyledImg2
                  shouldShow={showEye}
                  manual={manual}
                  src={`data:image/png;base64, ${getMarkedMapsPhoto()}`}
                  alt="map of house"
                  onClick={() => setShowPictureDialog(true)}
                />
                {/* <EyeButton onClick={() => setShoweye(!showEye)}>
                  {showEye ? (
                    <CrossedEyeIcon colors={getCurrentTheme()} />
                  ) : (
                    <EyeIcon colors={getCurrentTheme()} />
                  )}
                </EyeButton> */}
              </>
            ) : null}
            <StyledImg
              manual={manual}
              src={`data:image/png;base64, ${getMapsPhoto()}`}
              alt="map of house"
              onClick={() => setShowPictureDialog(true)}
            />
          </>
        ) : building === 'apartment' ? (
          <ApartmentPhoto colors={getCurrentTheme()} />
        ) : (
          <HousePhoto colors={getCurrentTheme()} />
        )}
        <HomeTitle>
          <h3>{t(`Address`)}</h3>
          <p>
            {`${getAdress().street} ${getAdress().housenr}${
              getAdress().boxnr ? `/${getAdress().boxnr}` : ``
            },`}
          </p>
          <p>{`${getAdress().zipcode} ${getAdress().city}`.toLowerCase()}</p>
          {getMapsPhoto() ? (
            <EyeButton onClick={() => setShoweye(!showEye)}>
              {showEye ? (
                <CrossedEyeIcon colors={getCurrentTheme()} />
              ) : (
                <EyeIcon colors={getCurrentTheme()} />
              )}
            </EyeButton>
          ) : null}
        </HomeTitle>
      </MapContainer>
    </Wrapper>
  );
};

const HomeTitle = styled.div`
  font-size: 1.5rem;
  color: #505050;
  font-weight: 900;
  width: 100%;
  padding: 1.5rem 2rem;
  background: white;
  box-shadow: 0 4px 13px rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  position: absolute;
  bottom: -2rem;
  z-index: 9;

  > h3 {
    color: #a29c95;
    font-weight: normal;
  }

  & > p {
    text-transform: capitalize;
    width: 90%;
    max-width: auto;
    text-align: left;
  }
`;

const EyeButton = styled.div`
  background-color: ${({ theme }) => theme.brand.primary};
  position: absolute;
  right: 1rem;
  user-select: none;
  bottom: 3rem;
  height: 4rem;
  width: 4rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`;

const ApartmentPhoto = styled(Apartment)`
  /* height: 100%; */
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  margin-bottom: 2rem;
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
  width: 100%;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const HousePhoto = styled(MapMock)`
  /* height: 100%; */
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  margin-bottom: 2rem;
  margin-top: 10rem;
  justify-content: center;
  align-items: center;
  width: 100%;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  bottom: 0;
  top: 3rem;
  align-items: center;
  margin: 0;

  @media screen and (max-width: 1300px) {
    width: 35.8vw;
    padding-bottom: 53.6vh;
  }

  @media screen and (max-width: 1200px) {
    width: 36.9vw;
    padding-bottom: 51.6vh;
  }

  @media screen and (max-height: 700px) {
    width: 60%;
    padding-bottom: 60%;
  }

  @media screen and (max-height: 600px) {
    width: 40%;
    padding-bottom: 40%;
  }
`;

const StyledImg = styled.img`
  /* height: 90%; */
  border-radius: 0.6rem;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  -webkit-user-drag: none;
  z-index: 1;
  cursor: pointer;

  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
`;

const StyledImg2 = styled.img`
  /* height: 90%; */
  border-radius: 0.6rem;
  opacity: ${props => (props.shouldShow ? 1 : 0)};
  transition: opacity 0.3s ease;
  background-color: pink;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  -webkit-user-drag: none;
  right: 0;
  bottom: 0;
  z-index: 2;
  cursor: pointer;

  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
`;

const PictureDialogWrapper = styled.div`
  position: fixed;
  width: 100%;
  /* height: 100%; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 1s;
`;

const PictureDialog = styled.img`
  display: block;
  width: 50%;
  border-radius: 0.3rem;
  ::after {
    content: 'x';
  }
`;

const Wrapper = styled.div`
  padding: 2rem;
  width: 100%;
`;

InteractiveMap.defaultProps = {
  manual: false,
  building: '',
};

InteractiveMap.propTypes = {
  manual: bool,
  building: string,
};

export default InteractiveMap;
