/* eslint-disable no-useless-escape */
// Disabled due to regex issues
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { object, func, bool } from 'prop-types';

import {
  IconActionMarker,
  IconReplacementFilled,
  IconMeasureFilled,
  IconConstructionFilled,
  IconCornFilled,
  IconStoneFilled,
  IconAtticFilled,
  IconBasementFilled,
  IconPassiveHouseFilled,
  IconHomeOfficeFilled,
  IconSurroundingsFilled,
  IconPropertyExtraFilled,
  IconClaimsFilled,
  Section,
  Label,
  Text,
  Title,
  EditActionButton,
  ActionButton,
  SearchSelectInput,
  TextInput,
  H4,
  FeatureInput,
} from 'wg-fe-ui';

import useForm from '../hooks/useForm';
import * as Yup from 'yup';
import cogoToast from 'cogo-toast';

const CRMRiskObjectDetailResidenceInformation = ({
  homeData,
  onSubmitFunction,
  customerPageFunction,
  disableEditing,
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState(homeData);
  const [editMode, setEditMode] = useState(false);
  const [holderState, setHolderState] = useState('');
  const [attachment, setAttachement] = useState('');
  const [buildingAge, setBuildingAge] = useState('');
  const [flooded, setFlooded] = useState('');
  const [finishingType, setFinishingType] = useState('');
  const [constructionType, setConstructionType] = useState('');

  useEffect(() => {
    setData(homeData);
  }, [homeData]);

  useEffect(() => {
    setInitialFormValues();
  }, [data]);

  function parseAddressToString({ city, housenr, street, zipcode, boxnr }) {
    const fullHouseNr = boxnr ? `${housenr}/${boxnr}` : housenr;
    return `${street} ${fullHouseNr}, ${city} ${zipcode}`;
  }

  const attachmentOptions = [
    { value: 'OPEN', label: t('OPEN') },
    { value: 'CLOSED', label: t('CLOSED') },
    { value: 'HALFOPEN', label: t('HALFOPEN') },
    { value: 'APARTMENT', label: t('APARTMENT') },
    { value: 'VILLA', label: t('VILLA') },
    { value: 'APARTMENT', label: t('MULTIPLEX') },
  ];

  const constructionTypeOptions = [
    { value: 'CONCRETE', label: t('CONCRETE') },
    { value: 'BRICK', label: t('BRICK') },
    { value: 'WOOD', label: t('WOOD') },
    { value: 'STEEL_FRAME', label: t('STEEL_FRAME') },
    { value: 'OTHER', label: t('OTHER') },
  ];

  const finishingTypeOptions = [
    { value: 'NATURAL_STONE', label: t('NATURAL_STONE') },
    { value: 'VENEER', label: t('VENEER') },
    { value: 'CONCRETE', label: t('CONCRETE') },
    { value: 'BRICK', label: t('BRICK') },
    { value: 'WOOD', label: t('WOOD') },
    { value: 'OTHER', label: t('OTHER') },
  ];

  const floodedOptions = [
    { value: 'NOT_IN_LAST_5_YEARS', label: t('NOT_IN_LAST_5_YEARS') },
    {
      value: 'ONCE_OR_MORE_IN_LAST_5_YEARS',
      label: t('ONCE_OR_MORE_IN_LAST_5_YEARS'),
    },
    { value: 'NOT_IN_LAST_10_YEARS', label: t('NOT_IN_LAST_10_YEARS') },
    {
      value: 'ONCE_OR_MORE_IN_LAST_10_YEARS',
      label: t('ONCE_OR_MORE_IN_LAST_10_YEARS'),
    },
  ];

  const holderStateOptions = [
    { value: 'OWNER', label: t('OWNER') },
    { value: 'TENANT', label: t('TENANT') },
    { value: 'LANDLORD', label: t('LANDLORD') },
    { value: 'CO_TENANT', label: t('CO_TENANT') },
  ];

  const buildingAgeOptions = [
    { value: 'LESS_THAN_10', label: t('LESS_THAN_10') },
    { value: 'BETWEEN_10_AND_20', label: t('BETWEEN_10_AND_20') },
    { value: 'BETWEEN_20_AND_50', label: t('BETWEEN_20_AND_50') },
    { value: 'MORE_THAN_50', label: t('MORE_THAN_50') },
  ];

  /*
   *  Helper Functions
   */

  const findEnumsInObject = (enumObject, selectedValue) => {
    const result = enumObject.filter(obj => {
      return obj.value === selectedValue;
    });

    return result;
  };

  /*
   *  Form handling
   */

  const ToggleEditMode = e => {
    setEditMode(!editMode);

    if (customerPageFunction != null) {
      customerPageFunction(!editMode);
    }
  };

  const ValidationSchema = Yup.object().shape({
    street: Yup.string()
      .matches(
        /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- \'\.\&’]{1,150}$/,
        t('Please use a valid address name'),
      )
      .min(1)
      .max(150)
      .required(t('Required')),
    housenr: Yup.string()
      .matches(/^[a-z0-9\- /]{1,20}$/, t('Please use a valid house no'))
      .min(1)
      .max(20)
      .required(t('Required')),
    boxnr: Yup.string()
      .matches(/^[a-z0-9\- /]{1,20}$/, t('Please use a valid house no'))
      .min(1)
      .max(20)
      .nullable(),
    zipcode: Yup.number()
      .min(1000)
      .max(9999)
      .required(t('Required')),
    city: Yup.string()
      .matches(
        /^[A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- \'\.\&’]{1,150}$/,
        t('Please use a valid city'),
      )
      .min(1)
      .max(150)
      .required(t('Required')),
  });

  const setInitialFormValues = () => {
    if (data) {
      Object.keys(data).forEach(name => {
        handleChange({ name, value: data[name] });
      });

      Object.keys(data.address).forEach(name => {
        handleChange({ name, value: data.address[name] });
      });

      Object.keys(data.main_building).forEach(name => {
        handleChange({ name, value: data.main_building[name] });
      });
      Object.keys(data.main_building.facade).forEach(name => {
        handleChange({ name, value: data.main_building.facade[name] });
      });

      setHolderState(findEnumsInObject(holderStateOptions, data.holder_state));
      setAttachement(findEnumsInObject(attachmentOptions, data.attachment));
      setBuildingAge(
        findEnumsInObject(buildingAgeOptions, data.main_building?.age),
      );
      setFlooded(findEnumsInObject(floodedOptions, data?.was_flooded));
      setFinishingType(
        findEnumsInObject(
          finishingTypeOptions,
          data.main_building?.facade?.finishing_type,
        ),
      );
      setConstructionType(
        findEnumsInObject(
          constructionTypeOptions,
          data.main_building?.construction_type,
        ),
      );
    }
  };

  const { values, handleChange, handleSubmit, errors } = useForm({
    validationSchema: ValidationSchema,
    mode: 'onChange',
  });

  const handleHolderState = e => {
    const selectedFuel = findEnumsInObject(holderStateOptions, e.value);
    setHolderState(selectedFuel);
    handleChange({ name: 'holder_state', value: selectedFuel[0].value });
  };

  const handleAttachment = e => {
    const selectedAttachement = findEnumsInObject(attachmentOptions, e.value);
    setAttachement(selectedAttachement);
    handleChange({ name: 'holder_state', value: selectedAttachement[0].value });
  };

  const handleBuildingAge = e => {
    const selectedBuildingAge = findEnumsInObject(buildingAgeOptions, e.value);
    setBuildingAge(selectedBuildingAge);
    handleChange({ name: 'age', value: selectedBuildingAge[0].value });
  };

  const handleFlooded = e => {
    const selectedFlooded = findEnumsInObject(floodedOptions, e.value);
    setFlooded(selectedFlooded);
    handleChange({ name: 'was_flooded', value: selectedFlooded[0].value });
  };

  const handleFinishingType = e => {
    const selectedFinishingType = findEnumsInObject(
      finishingTypeOptions,
      e.value,
    );
    setFinishingType(selectedFinishingType);
    handleChange({
      name: 'finishing_type',
      value: selectedFinishingType[0].value,
    });
  };

  const handleConstructionType = e => {
    const selectedConstructionType = findEnumsInObject(
      constructionTypeOptions,
      e.value,
    );
    setConstructionType(selectedConstructionType);
    handleChange({
      name: 'construction_type',
      value: selectedConstructionType[0].value,
    });
  };

  const handleFeatureChange = ({ name, value }) => {
    handleChange({ name: name, value: value });
  };

  const handleFormValues = async () => {
    cogoToast.error(
      t('The editing and saving of a risk object is not supported yet'),
    );
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    if (errors) {
      console.log(`FORM ERRORS : ${JSON.stringify(errors, null, 4)}`);
    }

    if (!onSubmitFunction) {
      handleSubmit(handleFormValues);
    } else {
      const newValues = {
        address: {
          street: values?.street || null,
          zipcode: values?.zipcode || null,
          housenr: values?.housenr || null,
          country_code: values?.country_code || null,
          boxnr: values?.boxnr || null,
          city: values?.city || null,
          lat: values?.lat || null,
          lng: values?.lng || null,
          region: values?.region || null,
        },
        attachment: values?.attachment || null,
        has_company_registered: values?.has_company_registered || false,
        has_garden: values?.has_garden || null,
        has_outside_swimming_pool: values?.has_outside_swimming_pool || null,
        has_solar_panels: values?.has_solar_panels || null,
        holder_state: values?.holder_state || null,
        is_primary: values?.is_primary || null,
        are_solar_panels_anchored: values?.are_solar_panels_anchored || null,
        main_building: {
          age: values?.age || null,
          construction_type: values?.construction_type || null,
          distance_nearest_neighbor_in_meters:
            values?.distance_nearest_neighbor_in_meters || null,
          facade: {
            ...homeData?.main_building?.facade,
            is_protected: !!values?.is_protected,
            finishing_type: values?.finishing_type || null,
          },
          habitable_surface: values?.habitable_surface || null,
          has_attic: values?.has_attic || null,
          has_cellar: values?.has_cellar || null,
          has_elevator: values?.has_elevator || null,
          has_high_ceilings: values?.has_high_ceilings || null,
          has_inside_swimming_pool: values?.has_inside_swimming_pool || null,
          has_porch: values?.has_porch || null,
          has_special_flooring: values?.has_special_flooring || null,
          has_special_roofing: values?.has_special_roofing || null,
          height: values?.height || null,
          is_attic_uninhabited: !!values?.is_attic_uninhabited,
          is_cellar_uninhabited: !!values?.is_cellar_uninhabited,
          is_passive_house: values?.is_passive_house || null,
          is_under_construction: values?.is_under_construction || null,
          main_building_type: values?.main_building_type || null,
          prevention_measures: values?.prevention_measures || null,
          rooms: data?.main_building.rooms || null,
          surface: values?.surface || null,
          volume: values?.volume || null,
          was_recently_renovated: values?.was_recently_renovated || null,
        },
        occupation: values?.occupation,
        parcel_area: values?.parcel_area,
        rental_price: values?.rental_price,
        was_flooded: values?.was_flooded,
        annexes: data?.annexes,
      };

      delete newValues?.street;
      delete newValues?.zipcode;
      delete newValues?.housenr;
      delete newValues?.country_code;
      delete newValues?.is_protected;
      delete newValues?.finishing_type;
      delete newValues?.boxnr;
      delete newValues?.city;
      delete newValues?.lat;
      delete newValues?.lng;
      delete newValues?.region;
      delete newValues?.age;
      delete newValues?.construction_type;
      delete newValues?.distance_nearest_neighbor_in_meters;
      delete newValues?.facade;
      delete newValues?.habitable_surface;
      delete newValues?.has_attic;
      delete newValues?.has_cellar;
      delete newValues?.has_elevator;
      delete newValues?.has_high_ceilings;
      delete newValues?.has_inside_swimming_pool;
      delete newValues?.has_porch;
      delete newValues?.has_special_flooring;
      delete newValues?.has_special_roofing;
      delete newValues?.height;
      delete newValues?.is_attic_uninhabited;
      delete newValues?.is_cellar_uninhabited;
      delete newValues?.is_passive_house;
      delete newValues?.is_under_construction;
      delete newValues?.main_building_type;
      delete newValues?.prevention_measures;
      delete newValues?.surface;
      delete newValues?.volume;
      delete newValues?.was_recently_renovated;

      onSubmitFunction(newValues);
      setData(newValues);
      setEditMode(false);
      if (customerPageFunction != null) {
        customerPageFunction(false);
      }
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Section>
        <Section.Title>{t('Property information')}</Section.Title>

        <Section.Content background>
          {editMode || disableEditing ? null : (
            <StyledEditButton onClick={e => ToggleEditMode(e)}>
              {t('Edit')}
            </StyledEditButton>
          )}
          <SectionContentHeader>
            <IconContainer>
              <IconActionMarker color={'#CCCCCC'} />
            </IconContainer>
            <TextWrapper>
              {editMode ? (
                <AddressWrapper>
                  {/* <IconContainer>
                    <IconActionMarker color={'#CCCCCC'} />
                  </IconContainer> */}
                  <StreetInput
                    error={errors.street}
                    name="street"
                    placeholder={t('Street')}
                    type="text"
                    value={values.address.street}
                    onChange={handleChange}
                  >
                    {t('Street')}
                  </StreetInput>

                  <HouseNumberInput
                    error={errors.housenr}
                    name="housenr"
                    placeholder={t('House no')}
                    type="housenr"
                    value={values.address.housenr}
                    onChange={handleChange}
                  >
                    {t('House no')}
                  </HouseNumberInput>

                  <BoxNumberInput
                    error={errors.boxnr}
                    name="boxnr"
                    placeholder={t('Box no')}
                    type="boxnr"
                    value={values.address.boxnr}
                    onChange={handleChange}
                  >
                    {t('Box no')}
                  </BoxNumberInput>

                  <ZipCodeInput
                    error={errors.zipcode}
                    name="zipcode"
                    placeholder={t('Zipcode')}
                    type="zipcode"
                    value={values.address.zipcode}
                    onChange={handleChange}
                  >
                    {t('Zipcode')}
                  </ZipCodeInput>

                  <CityInput
                    error={errors.city}
                    name="city"
                    placeholder={t('City')}
                    type="city"
                    value={values.address.city}
                    onChange={handleChange}
                  >
                    {t('City')}
                  </CityInput>
                </AddressWrapper>
              ) : (
                <>
                  <Label>{t('Address')}</Label>
                  <HeaderText>
                    {parseAddressToString(data?.address || {})}
                  </HeaderText>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.attachment}
                  initial={attachment}
                  name="attachment"
                  onSelected={handleAttachment}
                  options={attachmentOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('House type')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('House type')}</Label>
                  <HeaderText>{t(`${[data?.attachment]}`)}</HeaderText>
                </>
              )}
            </TextWrapper>
          </SectionContentHeader>
          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconReplacementFilled color={'#CCCCCC'} />
              </IconContainer>
              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.holder_state}
                    initial={holderState}
                    name="holder_state"
                    onSelected={handleHolderState}
                    options={holderStateOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Holder state')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Holder state')}</Label>
                    <Text>{t(`${[data?.holder_state]}`)}</Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.age}
                  initial={buildingAge}
                  name="age"
                  onSelected={handleBuildingAge}
                  options={buildingAgeOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Age of the property')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Age of the property')}</Label>
                  <Text>{t(`${[data?.main_building?.age]}`)}</Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>
          <SectionBorder />
          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconMeasureFilled color={'#CCCCCC'} />
              </IconContainer>
              <TextWrapper>
                {editMode ? (
                  <StyledTextInput
                    error={errors.main_building?.surface}
                    name="surface"
                    placeholder={t('Surface area')}
                    type="number"
                    value={(
                      Math.round(values?.main_building?.surface * 100) / 100
                    ).toFixed(2)}
                    onChange={handleChange}
                    symbol="m2"
                    symbolSide="right"
                    symbolText
                  >
                    {t('Surface area')}
                  </StyledTextInput>
                ) : (
                  <>
                    <Label>{t('Surface area')}</Label>
                    <Text>
                      {`${
                        data?.main_building?.surface
                          ? `${(
                              Math.round(data?.main_building?.surface * 100) /
                              100
                            ).toFixed(2)} m2`
                          : '-'
                      }`}
                    </Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors?.parcel_area}
                  name="parcel_area"
                  placeholder={t('Parcel area')}
                  type="number"
                  value={values?.parcel_area}
                  onChange={handleChange}
                  symbol="m2"
                  symbolSide="right"
                  symbolText
                >
                  {t('Parcel area')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Parcel area')}</Label>
                  <Text
                    dangerouslySetInnerHTML={{
                      __html: data?.parcel_area
                        ? `${(
                            Math.round(data?.parcel_area * 100) / 100
                          ).toFixed(2)} m<sup>2</sup>`
                        : '-',
                    }}
                  />
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors?.main_building?.habitable_surface}
                  name="habitable_surface"
                  placeholder={t('Habitable surface area')}
                  type="number"
                  value={values?.main_building?.habitable_surface}
                  onChange={handleChange}
                  symbol="m2"
                  symbolSide="right"
                  symbolText
                >
                  {t('Habitable surface area')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Habitable surface area')}</Label>
                  <Text>
                    {data?.main_building?.habitable_surface
                      ? `${(
                          Math.round(
                            data?.main_building?.habitable_surface * 100,
                          ) / 100
                        ).toFixed(2)} m2`
                      : `-`}
                  </Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={errors?.main_building?.height}
                  name="height"
                  placeholder={t('Building height')}
                  type="number"
                  value={values?.main_building?.height}
                  onChange={handleChange}
                  symbol="m2"
                  symbolSide="right"
                  symbolText
                >
                  {t('Building height')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Building height')}</Label>
                  <Text>
                    {data?.main_building?.height
                      ? `${(
                          Math.round(data?.main_building?.height * 100) / 100
                        ).toFixed(2)} m`
                      : `-`}
                  </Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <StyledTextInput
                  error={
                    errors?.main_building?.distance_nearest_neighbor_in_meters
                  }
                  name="distance_nearest_neighbor_in_meters"
                  placeholder={t('Distance from closest neighbour')}
                  type="number"
                  value={
                    values?.main_building?.distance_nearest_neighbor_in_meters
                  }
                  onChange={handleChange}
                  symbol="m"
                  symbolSide="right"
                  symbolText
                >
                  {t('Distance from closest neighbour')}
                </StyledTextInput>
              ) : (
                <>
                  <Label>{t('Distance from closest neighbour')}</Label>
                  <Text>
                    {data?.main_building?.distance_nearest_neighbor_in_meters
                      ? `${data?.main_building?.distance_nearest_neighbor_in_meters} m`
                      : `-`}
                  </Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>
          <SectionBorder />
          <SectionContentInner>
            <IconWrapper>
              <IconContainer>
                <IconPropertyExtraFilled color={'#CCCCCC'} />
              </IconContainer>
              <TextWrapper>
                {editMode ? (
                  <SearchSelectInput
                    error={errors.was_flooded}
                    initial={flooded}
                    name="was_flooded"
                    onSelected={handleFlooded}
                    options={floodedOptions}
                    placeholder={t('Choose an option')}
                  >
                    {t('Was flooded')}
                  </SearchSelectInput>
                ) : (
                  <>
                    <Label>{t('Was flooded')}</Label>
                    <Text>{t(`${[data?.was_flooded]}`) || '-'}</Text>
                  </>
                )}
              </TextWrapper>
            </IconWrapper>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.finishing_type}
                  initial={finishingType}
                  name="finishing_type"
                  onSelected={handleFinishingType}
                  options={finishingTypeOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Facade finishing type')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Facade finishing type')}</Label>
                  <Text>
                    {t(`${[data?.main_building?.facade?.finishing_type]}`) ||
                      '-'}
                  </Text>
                </>
              )}
            </TextWrapper>
            <TextWrapper>
              {editMode ? (
                <SearchSelectInput
                  error={errors.construction_type}
                  initial={constructionType}
                  name="construction_type"
                  onSelected={handleConstructionType}
                  options={constructionTypeOptions}
                  placeholder={t('Choose an option')}
                >
                  {t('Construction type')}
                </SearchSelectInput>
              ) : (
                <>
                  <Label>{t('Construction type')}</Label>
                  <Text>
                    {t(`${[data?.main_building?.construction_type]}`) || '-'}
                  </Text>
                </>
              )}
            </TextWrapper>
          </SectionContentInner>
          <SectionBorder />
          <SectionContentInner>
            <H4>{t('Questions')}</H4>
            <Features>
              <FeatureInput
                checked={data?.main_building?.is_under_construction}
                icon={<IconConstructionFilled />}
                name="is_under_construction"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('Building is still under construction')}
              </FeatureInput>

              <FeatureInput
                checked={data?.main_building?.has_special_roofing}
                icon={<IconCornFilled />}
                name="has_special_roofing"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t(
                  'The roofing material consist of straw, reed or natural slate',
                )}
              </FeatureInput>

              <FeatureInput
                checked={!data?.main_building?.is_attic_uninhabited}
                icon={<IconAtticFilled />}
                name="is_attic_uninhabited"
                onChange={({ name, value }) =>
                  handleFeatureChange({ name, value: !value })
                }
                editable={editMode}
              >
                {t('There is an attic that is used as a living space')}
              </FeatureInput>

              <FeatureInput
                checked={data?.has_company_registered}
                icon={<IconHomeOfficeFilled />}
                name="has_company_registered"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t(
                  'The building is used for the exercise of a liberal profession',
                )}
              </FeatureInput>

              <FeatureInput
                checked={data?.main_building?.facade?.is_protected}
                icon={<IconClaimsFilled />}
                name="is_protected"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('The house or the facade of the house is protected')}
              </FeatureInput>

              <FeatureInput
                checked={data?.main_building?.is_passive_house}
                icon={<IconPassiveHouseFilled />}
                name="is_passive_house"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('The building is a passive house')}
              </FeatureInput>

              <FeatureInput
                checked={data?.main_building?.has_special_flooring}
                icon={<IconStoneFilled />}
                name="has_special_flooring"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t(
                  'The building has a floor in natural stone, marble or parquet of solid wood',
                )}
              </FeatureInput>

              <FeatureInput
                checked={!data?.main_building?.is_cellar_uninhabited}
                icon={<IconBasementFilled />}
                name="is_cellar_uninhabited"
                onChange={({ name, value }) =>
                  handleFeatureChange({ name, value: !value })
                }
                editable={editMode}
              >
                {t('There is a cellar that is used as a living space')}
              </FeatureInput>

              <FeatureInput
                checked={data?.is_primary}
                icon={<IconSurroundingsFilled />}
                name="is_primary"
                onChange={e => handleFeatureChange(e)}
                editable={editMode}
              >
                {t('The house is the main residence')}
              </FeatureInput>
            </Features>
          </SectionContentInner>
          {editMode ? (
            <SectionContentFooter>
              <ActionButton level="default" onClick={ToggleEditMode}>
                {t('Cancel')}
              </ActionButton>
              <Spacer />
              <ActionButton>{t('Save')}</ActionButton>
            </SectionContentFooter>
          ) : null}
        </Section.Content>
      </Section>
    </form>
  );
};

// const StreetnameInfo = styled.div`
//   flex: 1 0 auto;
// `;

const Features = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1.5rem;
  flex-flow: row wrap;

  label > div {
    width: 90%;
    p {
      word-break: break-word;
    }
  }

  > * {
    flex: 0 0 49%;
    margin-top: 1rem;
    margin-right: 1%;
  }

  :nth-of-type(-n + 2) {
    margin-top: 0;
  }

  @media only screen and (max-width: 940px) {
    flex: 0 0 100%;
  }
`;

const Spacer = styled.div`
  width: 15px;
`;

const StyledEditButton = styled(EditActionButton)`
  position: absolute;
  right: 1.8vw;
`;

const IconWrapper = styled.div`
  display: flex;
`;
const IconContainer = styled.div`
  padding-right: 3rem;
  padding-top: 0.75rem;
`;

const SectionBorder = styled.div`
  margin-top: 3rem;
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.ui.outline};
`;

const SectionContentHeader = styled.div`
  /* width: 100%; */
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding-bottom: 24px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  flex: 1 0 0;

  /* two items */
  :first-child:nth-last-child(2),
  :first-child:nth-last-child(2) ~ div {
    width: 49%;
  }

  /* three items */
  :first-child:nth-last-child(3),
  :first-child:nth-last-child(3) ~ div {
    width: 49%;
  }
`;

const HeaderText = styled(Title)`
  text-transform: capitalize;
`;

const SectionContentInner = styled.div`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  > ${TextWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    :nth-of-type(2n + 3) {
      flex: 0 0 calc(50% - 6rem);
      margin-left: 6rem;
    }
  }

  > ${IconWrapper} {
    flex: 0 0 50%;
    margin-top: 1.5rem;

    > ${TextWrapper} {
      flex: 1 1 100%;
    }
  }
`;

const StyledTextInput = styled(TextInput)`
  height: auto;
`;

const SectionContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ccc;
  padding-top: 24px;
  margin-top: 24px;
`;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
`;

const StreetInput = styled(TextInput)`
  flex: 0 0 60%;
  margin-right: 5%;
`;

const HouseNumberInput = styled(TextInput)`
  flex: 0 0 15%;
  margin-right: 5%;
`;

const BoxNumberInput = styled(TextInput)`
  flex: 0 0 15%;
`;

const ZipCodeInput = styled(TextInput)`
  flex: 0 0 47.5%;
  margin-right: 5%;
`;

const CityInput = styled(TextInput)`
  flex: 0 0 47.5%;
`;

CRMRiskObjectDetailResidenceInformation.propTypes = {
  homeData: object,
  onSubmitFunction: func,
  customerPageFunction: func,
  disableEditing: bool,
};

export default CRMRiskObjectDetailResidenceInformation;
