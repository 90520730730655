/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { string, bool, object, func, number, shape } from 'prop-types';
import styled from 'styled-components';

import { withRouter } from 'react-router';
import TerminationPopUp from '../../../Market/components/TerminationPopUp';

import {
  addToCheckout,
  getCurrentStep,
  removeFromCheckout,
  setSkippedSteps,
  getSkippedSteps,
  getCheckout,
} from '../../store/index';

import { getAllCoverages } from '../../../../../store/index';
import { getNestedObject } from '../../../../../services/objectService';

const AddToCart = props => {
  const {
    children,
    white,
    card,
    priceData,
    remove,
    clicked,
    // activeItem,
    id,
    disabled,
    typeButton,
    insurance,
    enteredData,
    insuranceCompanies,
    history,
    dataTestId,
  } = props;
  const coverages = getAllCoverages();
  const [togglen, setToggle] = useState(false);
  const [nextPath, setNextPath] = useState();
  const [typeId, setTypeId] = useState();
  let subPath = '/session';
  if (history.location.pathname.includes('review')) subPath = '/review';

  const handleClick = () => {
    clicked();
    if (remove === true) {
      if (id === 1) {
        removeFromCheckout('fire');
      }
      if (id === 2) {
        removeFromCheckout('people');
      }
      if (id === 3) {
        removeFromCheckout('car');
      }
      if (id === 4) {
        removeFromCheckout('legal');
      }
    } else {
      const isInsured =
        getNestedObject(enteredData, ['already_insured_with']) !== undefined
          ? true
          : false;
      switch (getCurrentStep()) {
        case 1:
          addToCheckout('fire', card, priceData);
          if (isInsured) {
            setTypeId(1);
            setToggle(true);
          }
          // is next path family
          if (
            !getSkippedSteps()[2] &&
            coverages.family &&
            !getCheckout('people')
          ) {
            if (isInsured) {
              setNextPath(`${subPath}/market/family`);
            } else {
              history.push(`${subPath}/market/family`);
            }
            // is next path car
          } else if (
            !getSkippedSteps()[3] &&
            coverages.car &&
            !getCheckout('car')
          ) {
            if (isInsured) {
              setNextPath(`${subPath}/market/car`);
            } else {
              history.push(`${subPath}/market/car`);
            }
            // is next path legal
          } else if (
            !getSkippedSteps()[4] &&
            coverages.legal &&
            !getCheckout('legal')
          ) {
            if (isInsured) {
              setNextPath(`${subPath}/market/legal`);
            } else {
              history.push(`${subPath}/market/legal`);
            }
            // is next path checkout
          } else {
            if (isInsured) {
              setNextPath(`${subPath}/market/checkout`);
            } else {
              history.push(`${subPath}/market/checkout`);
            }
          }
          setSkippedSteps(1, false);
          break;
        case 2:
          addToCheckout('people', card, priceData);
          if (isInsured) {
            setTypeId(2);
            setToggle(true);
          }
          if (!getSkippedSteps()[3] && coverages.car && !getCheckout('car')) {
            if (isInsured) {
              setNextPath(`${subPath}/market/car`);
            } else {
              history.push(`${subPath}/market/car`);
            }
            // is next path legal
          } else if (
            !getSkippedSteps()[4] &&
            coverages.legal &&
            !getCheckout('legal')
          ) {
            if (isInsured) {
              setNextPath(`${subPath}/market/legal`);
            } else {
              history.push(`${subPath}/market/legal`);
            }
            // is next path checkout
          } else {
            if (isInsured) {
              setNextPath(`${subPath}/market/checkout`);
            } else {
              history.push(`${subPath}/market/checkout`);
            }
          }
          setSkippedSteps(2, false);
          break;
        case 3:
          addToCheckout('car', card, priceData);
          if (isInsured) {
            setTypeId(3);
            setToggle(true);
          }
          // is next path legal
          if (
            !getSkippedSteps()[4] &&
            coverages.legal &&
            !getCheckout('legal')
          ) {
            if (isInsured) {
              setNextPath(`${subPath}/market/legal`);
            } else {
              history.push(`${subPath}/market/legal`);
            }
            // is next path checkout
          } else {
            if (isInsured) {
              setNextPath(`${subPath}/market/checkout`);
            } else {
              history.push(`${subPath}/market/checkout`);
            }
          }
          setSkippedSteps(3, false);
          break;
        case 4:
          addToCheckout('legal', card, priceData);
          if (isInsured) {
            setTypeId(4);
            setToggle(true);
          }
          if (isInsured) {
            setNextPath(`${subPath}/market/checkout`);
          } else {
            history.push(`${subPath}/market/checkout`);
          }
          setSkippedSteps(4, false);
          break;
        default:
          return;
      }
    }
  };

  // const handleClickOld = () => {
  //   clicked();
  //   if (remove === true) {
  //     if (id === 1) {
  //       removeFromCheckout('fire');
  //     }
  //     if (id === 2) {
  //       removeFromCheckout('people');
  //     }
  //     if (id === 3) {
  //       removeFromCheckout('car');
  //     }
  //     if (id === 4) {
  //       removeFromCheckout('legal');
  //     }
  //   } else {
  //     if (getCurrentStep() === 1) {
  //       if (
  //         getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //       ) {
  //         setTypeId(1);
  //         setToggle(true);
  //       }
  //       addToCheckout('fire', card, priceData);
  //       if (!getSkippedSteps()[2] && coverages.family) {
  //         if (getCheckout('people')) {
  //           if (!getSkippedSteps()[3] && coverages.car) {
  //             if (getCheckout('car')) {
  //               if (
  //                 getNestedObject(enteredData, ['already_insured_with']) !==
  //                 undefined
  //               ) {
  //                 setNextPath(`${subPath}/market/checkout`);
  //               } else {
  //                 history.push(`${subPath}/market/checkout`);
  //               }
  //             } else {
  //               if (
  //                 getNestedObject(enteredData, ['already_insured_with']) !==
  //                 undefined
  //               ) {
  //                 setNextPath(`${subPath}/market/car`);
  //               } else {
  //                 history.push(`${subPath}/market/car`);
  //               }
  //             }
  //           } else {
  //             if (
  //               getNestedObject(enteredData, ['already_insured_with']) !==
  //               undefined
  //             ) {
  //               setNextPath(`${subPath}/market/checkout`);
  //             } else {
  //               history.push(`${subPath}/market/checkout`);
  //             }
  //           }
  //         } else {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/family`);
  //           } else {
  //             history.push(`${subPath}/market/family`);
  //           }
  //         }
  //       } else if (!getSkippedSteps()[3] && coverages.car) {
  //         if (getCheckout('car')) {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/checkout`);
  //           } else {
  //             history.push(`${subPath}/market/checkout`);
  //           }
  //         } else {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/car`);
  //           } else {
  //             history.push(`${subPath}/market/car`);
  //           }
  //         }
  //       } else {
  //         if (
  //           getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //         ) {
  //           setNextPath(`${subPath}/market/checkout`);
  //         } else {
  //           history.push(`${subPath}/market/checkout`);
  //         }
  //       }
  //       setSkippedSteps(1, false);
  //     } else if (getCurrentStep() === 2) {
  //       if (
  //         getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //       ) {
  //         setTypeId(2);
  //         setToggle(true);
  //       }
  //       addToCheckout('people', card, priceData);
  //       if (!getSkippedSteps()[3] && coverages.car) {
  //         if (getCheckout('car')) {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/checkout`);
  //           } else {
  //             history.push(`${subPath}/market/checkout`);
  //           }
  //         } else {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/car`);
  //           } else {
  //             history.push(`${subPath}/market/car`);
  //           }
  //         }
  //       } else if (!getSkippedSteps()[4] && coverages.legal) {
  //         if (!getCheckout('legal')) {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/legal`);
  //           } else {
  //             history.push(`${subPath}/market/legal`);
  //           }
  //         } else {
  //           if (
  //             getNestedObject(enteredData, ['already_insured_with']) !==
  //             undefined
  //           ) {
  //             setNextPath(`${subPath}/market/checkout`);
  //           } else {
  //             history.push(`${subPath}/market/checkout`);
  //           }
  //         }
  //       } else {
  //         if (
  //           getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //         ) {
  //           setNextPath(`${subPath}/market/checkout`);
  //         } else {
  //           history.push(`${subPath}/market/checkout`);
  //         }
  //       }
  //       setSkippedSteps(2, false);
  //     } else if (getCurrentStep() === 3) {
  //       if (
  //         getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //       ) {
  //         setTypeId(3);
  //         setToggle(true);
  //         if (
  //           !getSkippedSteps()[4] &&
  //           coverages.legal &&
  //           !getCheckout('legal')
  //         ) {
  //           setNextPath(`${subPath}/market/legal`);
  //         } else {
  //           setNextPath(`${subPath}/market/checkout`);
  //         }
  //       } else {
  //         history.push(`${subPath}/market/checkout`);
  //       }
  //       if (activeItem) card.activeItem = activeItem;
  //       addToCheckout('car', card, priceData);
  //       setSkippedSteps(3, false);
  //     } else if (getCurrentStep() === 4) {
  //       if (
  //         getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //       ) {
  //         setTypeId(4);
  //         setToggle(true);
  //       }
  //       addToCheckout('legal', card, priceData);
  //       if (
  //         getNestedObject(enteredData, ['already_insured_with']) !== undefined
  //       ) {
  //         setNextPath(`${subPath}/market/checkout`);
  //       } else {
  //         history.push(`${subPath}/market/checkout`);
  //       }
  //       setSkippedSteps(4, false);
  //     }
  //   }
  // };

  if (
    enteredData &&
    getNestedObject(enteredData, ['already_insured_with']) !== undefined &&
    togglen
  ) {
    return (
      <>
        <StyledButton
          onClick={() => handleClick()}
          disabled={disabled}
          white={white}
          type={typeButton}
          data-test-id={dataTestId}
        >
          {children}
        </StyledButton>
        <TerminationPopUp
          toggle={togglen}
          toggleDrawer={() => setToggle(!togglen)}
          next={nextPath}
          insuranceCompanies={insuranceCompanies}
          data={enteredData}
          isurance_name={insurance}
          type={typeId}
          data-test-id={dataTestId}
        />
      </>
    );
  } else {
    return (
      <StyledButton
        onClick={() => handleClick()}
        disabled={disabled}
        white={white}
        type={typeButton}
        data-test-id={dataTestId}
      >
        {children}
      </StyledButton>
    );
  }
};

const StyledButton = styled.button`
  background-color: ${({ theme, type, disabled }) =>
    type == 2
      ? 'white'
      : disabled == true
      ? theme.ui.disabled
      : type == 3
      ? theme.brand.secondary
      : theme.brand.primary};
  height: 5rem;
  min-width: 20rem;
  max-width: 25rem;
  border-radius: ${props => (props.type !== 1 ? '2.5rem' : '0.5rem')};
  display: flex;
  font-size: 1.8rem;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  color: ${({ theme, type }) => (type == 2 ? theme.brand.primary : 'white')};
  border: 0;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  font-family: ${({ theme }) => theme.font};
  position: relative;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }
`;

AddToCart.defaultProps = {
  white: false,
  remove: false,
  clicked: () => {},
  card: {},
  id: 0,
  disabled: false,
  typeButton: 1,
  activeItem: 0,
};

AddToCart.propTypes = {
  children: string.isRequired,
  white: bool,
  card: object,
  remove: bool,
  clicked: func,
  activeItem: number,
  id: number,
  insuranceCompanies: shape(),
  disabled: bool,
  typeButton: number,
  insurance: string,
  dataTestId: string,
  enteredData: object,
  history: shape().isRequired,
  priceData: object,
};

export default withRouter(AddToCart);
