import React from 'react';
import { string } from 'prop-types';

const AtticIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="26"
      fill="none"
      viewBox="0 0 14 26"
    >
      <path
        fill={color}
        d="M13.991 3.95a6.45 6.45 0 00-.577-1.099A6.32 6.32 0 009.618.158 5.626 5.626 0 008.59 0H2.612a.094.094 0 00-.095.095v.718H1.405V.106A.094.094 0 001.311.01H.094C.042 0 0 .042 0 .106v3.95c0 .052.042.094.094.094h1.217a.094.094 0 00.094-.095v-.718h1.112v.718c0 .053.042.095.095.095h2.192l.01 8.639h-.839c-.063 0-.105.042-.105.105l-.377 10.35c-.053 1.467 1.08 2.734 2.527 2.755 1.49.032 2.696-1.203 2.643-2.703l-.377-10.402a.105.105 0 00-.105-.105h-.85l-.01-8.639h2.202a.094.094 0 00.095-.095V2.788c0-.053.042-.095.094-.106a6.266 6.266 0 014.122 1.384c.084.063.199-.021.157-.116z"
      ></path>
    </svg>
  );
};

AtticIcon.defaultProps = {
  color: `white`,
};

AtticIcon.propTypes = {
  color: string,
};

export default AtticIcon;
