/* eslint-disable no-unused-expressions */
/* eslint-disable babel/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { array, string, bool, func, shape, object, number } from 'prop-types';
import * as Yup from 'yup';
import Loading from '../../../../components/Loading';
import { useTranslation } from 'react-i18next';
import AddToCart from '../../LayoutMarket/components/Buttons/AddToCart';
import CardCheckBox from './CardCheckBox';
import IconCheckBox from '../../../../components/IconCheckBox';
import BAIcon from '../../../../../assets/images/ba.jsx';
import {
  getCurrentTheme,
  getSocialLogin,
  getAllCoverages,
} from '../../../../store';
import pdfIcon from '../../../../../assets/images/pdf-icon.svg';
import ResetIcon from '../../../../../assets/images/reset';
import LoadingExtentionIcons from './LoadingExtentionIcons';
import InfoPopUp from '../../../../components/InfoPopUp';
import BigInsuranceCardManualDiscount from './BigInsuranceCardManualDiscount';
import { Formik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { getPrice } from '../store';
import i18next from 'i18next';
import MarketplaceInsuranceCardValueInput from '../../../../components/MarketplaceInsuranceCardValueInput';
import { getNestedObject } from '../../../../services/objectService';
import MarketplaceInsuranceCardCoverageCarousel from '../../../../components/MarketplaceInsuranceCardCoverageCarousel';
import BigInsuranceCardDiaDemoGardenCheck from '../../../../components/BigInsuranceCardDiaDemoGardenCheck';
import MarketplaceLegalWarningPopup from '../../../../components/MarketplaceLegalWarningPopup';
import MarketplaceInsuranceCardPrintButton from './MarketplaceInsuranceCardPrintButton';
import { getPayloadCar } from '../../../Flow/Car/store';
import { getBrokerDistribution } from '../../../../services/brokerDataService';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';
const fireContentBrokers = [
  'lander_broker@wegroup.be',
  'info@insura.be',
  'kattia.piens@insura.be',
];

const BigInsuranceCard = ({
  packs,
  name,
  coverage,
  children,
  compare,
  pdfs,
  disableCompareItems,
  dataType,
  isSelected,
  id,
  insuranceCompanies,
  selectedPacks,
  activeItem,
  currentPack,
}) => {
  const { t } = useTranslation();
  const [selectedPackages, setSelectedPackages] = useState(
    selectedPacks && isSelected ? selectedPacks.split('&') : [],
  );
  const [quote, setQuote] = useState(packs[0]);
  const [card, setCard] = useState({});
  const [price, setPrice] = useState({
    total_premium: 0,
    insured_content_value: 0,
    insured_value: 0,
  });
  const [impossible, setImpossible] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [packages, setPackages] = useState([]);
  const insuranceCompany = packs[0].insurance_company;
  const score = quote ? Math.floor(quote.score * 10000) / 100 : 0;
  const [firstPageRender, setFirstPageRender] = useState(true);
  const [selectedFranchise, setSelectedFranchise] = useState({});
  const [shouldCalculate, setShouldCalculate] = useState(false);
  const [insuredValues, setInsuredValues] = useState({});
  const [discountData, setDiscountData] = useState({ id: quote.id });
  const [loadPrice, setLoadPrice] = useState(true);
  const [priceData, setPriceData] = useState({});
  const [exceptionErrorInitialText, setExceptionErrorInitialText] = useState(
    'No price could be calculated, click here for more info',
  );
  const [exceptionError, setExceptionError] = useState();
  const [exceptionErrorColorCode, setExceptionErrorColorCode] = useState(
    '#DC143C',
  );
  const [franchiseToolTipHelper, setFranchiseToolTipHelper] = useState([]);
  const [franchiseToolTipText, setFranchiseToolTipText] = useState('');
  const [showGardenDiaPopup, setShowGardenDiaPopup] = useState(false);
  const [showLegalPopup, setShowLegalPopup] = useState(false);
  const [numberOfAccidents, setNumberOfAccidents] = useState([]);
  const flowStore = useFlowStore();

  const Schema = Yup.object().shape({
    insured_value: Yup.string()
      .required(t(`This field is required to be filled in`))
      .matches(/^[0-9 _ , .]*$/, t(`This input field can only contain digits`)),
    insured_content_value: Yup.string()
      .required(t(`This field is required to be filled in`))
      .matches(/^[0-9 _ , .]*$/, t(`This input field can only contain digits`)),
  });

  const [debouncedCallback] = useDebouncedCallback(
    ({ id, ck, vk, franchise, insuredValues }) => {
      const acceptedTerms =
        coverage === 'car'
          ? numberOfAccidents?.filter(function(el) {
              return el != null;
            })?.length === 0
          : null;
      setPriceData({ id, ck, vk, franchise, insuredValues, acceptedTerms });
      getPrice(id, ck, vk, franchise, insuredValues)
        .then(({ data }) => {
          setIsDisabled(false);
          setExceptionError(null);
          setPrice(data.quotes[id]);
          setLoadPrice(false);
        })
        .catch(err => {
          console.error('QUOTE ERROR:', err);
          handleExceptionError(err);
          setLoadPrice(false);
          setIsDisabled(true);
          setPrice({ total_premium: null });
        });
    },
    1500,
  );

  const handleExceptionError = err => {
    if (!err.response) {
      // This implies a network error happened, or a 502
      setExceptionError('try again');
    } else {
      const { status } = err.response;
      const errData = err.response.data;

      if (status === 422 && errData.msg) {
        setExceptionErrorColorCode('#00BFFF');
        setExceptionErrorInitialText('No acceptation by the company');
        if (errData.detail) {
          if (errData.detail[i18next.language]) {
            setExceptionError(errData.detail[i18next.language]);
          } else {
            setExceptionError(errData.detail.en);
          }
        } else {
          setExceptionError(t('No acceptation by the company'));
        }
      } else if (status >= 400 && errData.msg) {
        setExceptionErrorColorCode('#DC143C');
        if (status == 503) {
          setExceptionErrorInitialText('No connection with the insurer API');
        } else {
          setExceptionErrorInitialText(
            'No price could be calculated, click here for more info',
          );
        }
        if (errData.detail) {
          if (errData.detail[i18next.language]) {
            setExceptionError(errData.detail[i18next.language]);
          } else {
            setExceptionError(errData.detail.en);
          }
        } else {
          setExceptionError(
            t('Something went wrong while calculating the price'),
          );
        }
      } else {
        if (err.response.status >= 400 && err.response.status < 500) {
          setExceptionError(
            t('Something went wrong while calculating the price'),
          );
        }
      }
    }
  };

  useEffect(() => {
    const { ck, vk } = discountData;

    const { id } = quote;
    // if (
    //   // parseFloat(insuredValues.insured_value) > 60000 ||
    //   insuredValues.insured_value === undefined
    // ) {
    setIsDisabled(true);
    setLoadPrice(true);
    console.log(insuredValues);
    debouncedCallback({
      id,
      ck,
      vk,
      franchise: selectedFranchise,
      insuredValues,
    });
    // }
  }, [discountData, insuredValues, selectedFranchise, quote]);

  useEffect(() => {
    packs.forEach(pack => {
      const extensions = pack.extensions;
      extensions.forEach(extension => {
        const _name = extension.name;
        const _type = extension.type;
        if (!packages.find(pack => pack.name === extension.name)) {
          packages.push({
            name: _name,
            type: _type,
          });
        }
      });
    });

    // set the order of the packages array
    const compareArrays = {
      home: [
        'CONTENT',
        'THEFT',
        'THEFT+',
        'EXTRA',
        'VEHICLE',
        'GARDEN',
        'POOL',
        'BUSINESS',
        'ALLRISK',
        'BICYCLE',
        'SUPPORT',
        'LEGAL',
      ],
      family: ['EXTRA', 'LEGAL', 'LEGAL_PLUS'],
      car: [
        'DRIVER',
        'ASSISTANCE_VEHICLE',
        'ASSISTANCE_PERSON',
        'SAFETY',
        'LEGAL',
        'BICYCLE',
        'ROAD_USER',
        'FULL_ASSISTANCE',
        'THEFT',
        'LEGAL_PLUS',
      ],
      legal: [
        'PRIVATE_LIFE',
        'SUPPORT_AFTER_FIRE',
        'VEHICLE',
        'MOBILITY',
        'CONSUMER',
        'PROPERTY',
        'INCOME',
        'EXTRA',
        'DRIVER',
      ],
    };
    const orderedPackages = [];
    compareArrays[coverage].forEach(compareType => {
      packages.forEach(pack => {
        if (pack.type === compareType) {
          orderedPackages.push(pack);
        }
      });
    });
    setPackages(orderedPackages);
    const carInfo = getPayloadCar();
    setNumberOfAccidents(
      getNestedObject(carInfo, [
        'primary_driver',
        'accident_statement',
        'accidents',
      ]),
    );
  }, []);

  useEffect(() => {
    if (
      activeItem === currentPack ||
      activeItem + 1 === currentPack ||
      activeItem === undefined
    ) {
      setShouldCalculate(true);
    }
  }, [activeItem]);

  useEffect(() => {
    const card = {
      logo: quote.insurance_company.logo,
      company: quote.insurance_company.name,
      packagename: quote.insurance_details.name.toLowerCase(),
      details: {
        quote: {
          net_premium: price ? price.net_premium : 0,
          policy_costs: price ? price.policy_costs : 0,
          taxes_in_euro: price ? price.taxes_in_euro : 0,
          total_premium: price ? price.total_premium : 0,
        },
        extensions: quote.extensions,
      },
      id: quote.id,
      pdfs: {
        terms: pdfs.terms.link,
        ipid: pdfs.ipid.link,
      },
    };

    card.scrollId = id;

    if (price) {
      Object.keys(price).forEach(key => {
        card.details.quote[key] = price[key];
      });
    }

    if (coverage === 'home') {
      if (price.insured_value)
        card.details.quote.insured_value = price.insured_value;
      if (price.insured_content_value)
        card.details.quote.insured_content_value = price.insured_content_value;
    }

    if (coverage === 'car') {
      if (price) {
        card.details.quote.bonus_malus = price.bonus_malus;
        if (price.franchise) {
          card.details.quote.franchise = showFranchise(
            price.franchise.type,
            price.franchise.value,
          );
        }
      }
    }

    /* Check if there is a promotion price, add data to card*/

    if (price && price.promotion_premium) {
      card.details.quote.promotion_premium = {
        net_premium: price.promotion_premium.net_premium,
        policy_costs: price.promotion_premium.policy_costs,
        taxes_in_euro: price.promotion_premium.taxes_in_euro,
        total_premium: price.promotion_premium.total_premium,
        promotion: price.total_premium - price.promotion_premium.total_premium,
      };
    }

    /* Check if there is a discount,
      when there is a promotion you calculate the discount on the total promotion price,
      add data to card*/

    if (price && price.discount_premium) {
      card.details.quote.discount_premium = {
        net_premium: price.discount_premium.net_premium,
        policy_costs: price.discount_premium.policy_costs,
        taxes_in_euro: price.discount_premium.taxes_in_euro,
        total_premium: price.discount_premium.total_premium,
        discount: price.promotion_premium
          ? price.promotion_premium.total_premium -
            price.discount_premium.total_premium
          : price.total_premium - price.discount_premium.total_premium,
      };
    }
    setCard(card);

    if (price?.mandatory?.length > 0) {
      const selectedPackagesSet = new Set(selectedPackages);
      price?.mandatory.map(mandatoryOption =>
        selectedPackagesSet.add(mandatoryOption),
      );
      setSelectedPackages([...selectedPackagesSet]);
      setFirstPageRender(true);
    }
  }, [quote, price, selectedFranchise, insuredValues]);

  useEffect(() => {
    if (selectedPackages.length === 0 && !isSelected) {
      const initialSelected = [];

      packs[0].extensions.forEach(extension => {
        initialSelected.push(extension.type);
      });

      if (coverage === 'home') {
        getBrokerDistribution()
          .then(({ email }) => {
            if (!getSocialLogin() && fireContentBrokers.includes(email)) {
              setSelectedPackages(['CONTENT']);
            } else {
              setSelectedPackages(initialSelected);
            }
          })
          .catch(() => setSelectedPackages(initialSelected));
      } else {
        setSelectedPackages(initialSelected);
      }
    } else {
      setSelectedPackages(selectedPacks ? selectedPacks.split('&') : []);
    }
  }, [insuranceCompany]);

  useEffect(() => {
    setIsDisabled(false);
    if (!firstPageRender || isSelected) {
      const selectedQuote = getSelectedPackage();
      setPrice({
        total_premium: 0,
        insured_content_value: 0,
        insured_value: 0,
      });
      console.log(selectedQuote);

      if (selectedQuote) {
        setQuote(selectedQuote);
        setImpossible(false);
      } else {
        setImpossible(true);
      }
    } else {
      setFirstPageRender(false);
    }
  }, [selectedPackages]);

  const handleClick = (name, checked) => {
    if (
      (name === 'LEGAL' || name === 'LEGAL_PLUS') &&
      checked &&
      getAllCoverages().legal
    ) {
      if (!showLegalPopup) {
        setShowLegalPopup(true);
        return;
      } else {
        setShowLegalPopup(false);
      }
    }
    if (
      (name === 'LEGAL' || name === 'LEGAL_PLUS') &&
      !checked &&
      showLegalPopup
    ) {
      setShowLegalPopup(false);
      return;
    }
    if (name === 'GARDEN' && !checked) {
      setShowGardenDiaPopup(true);
      return;
    }
    if (!(price && price.mandatory && price.mandatory.includes(name))) {
      handlePackageToggle(name);
    }
  };

  const getSelectedPackage = () => {
    const selectedPack = packs.map(pack => {
      const tempExtensions = [];
      pack.extensions.forEach(ext => {
        tempExtensions.push(ext.type);
      });

      let trueExts = 0;
      tempExtensions.forEach(extName => {
        if (selectedPackages.includes(extName)) {
          trueExts += 1;
        }
      });
      if (
        trueExts === selectedPackages.length &&
        tempExtensions.length === trueExts
      ) {
        return pack;
      }
    });

    const [cleanedPacks] =
      selectedPack.filter(pack => pack !== undefined) || [];

    return cleanedPacks;
  };

  const handlePackageToggle = type => {
    let tempSelected = [...selectedPackages];
    if (!tempSelected.includes(type)) {
      tempSelected.push(type);
    } else {
      tempSelected = tempSelected.filter(e => e !== type);
    }

    if (price && price.mandatory) {
      price.mandatory.forEach(exten => {
        if (!tempSelected.includes(exten)) {
          tempSelected.push(exten);
        }
      });
    }
    setSelectedPackages(tempSelected);
  };

  useEffect(() => {
    if (price.hasOwnProperty('franchise')) {
      setSelectedFranchise({
        value: price.franchise.value,
        type: price.franchise.type,
      });
    }
  }, []);

  const selectedFranchisehandler = selected => {
    setSelectedFranchise({
      value: parseFloat(selected.split(', ')[0]),
      type: selected.split(', ')[1],
    });
  };

  const showFranchise = (type, value) => {
    switch (type) {
      case 'fixed':
        if (!franchiseToolTipHelper.includes('fixed'))
          setFranchiseToolTipHelper([...franchiseToolTipHelper, 'fixed']);
        return `€${value} ${t('Checkout.insured.franchiseTypes.fixed')}`;
      case 'variable':
        if (!franchiseToolTipHelper.includes('variable'))
          setFranchiseToolTipHelper([...franchiseToolTipHelper, 'variable']);
        return `€${value} ${t('Checkout.insured.franchiseTypes.variable')}`;
      case 'percentage_on_compensation':
        if (!franchiseToolTipHelper.includes('percentage_on_compensation'))
          setFranchiseToolTipHelper([
            ...franchiseToolTipHelper,
            'percentage_on_compensation',
          ]);
        return `${value * 100}% ${t(
          'Checkout.insured.franchiseTypes.percentage_on_compensation',
        )}`;
      case 'percentage_on_car_value':
        if (!franchiseToolTipHelper.includes('percentage_on_car_value'))
          setFranchiseToolTipHelper([
            ...franchiseToolTipHelper,
            'percentage_on_car_value',
          ]);
        return `${value * 100}% ${t(
          'Checkout.insured.franchiseTypes.percentage_on_car_value',
        )} `;
      case 'english':
        if (!franchiseToolTipHelper.includes('english'))
          setFranchiseToolTipHelper([...franchiseToolTipHelper, 'english']);
        return `€${value} ${t('Checkout.insured.franchiseTypes.english')}`;
      case 'identity':
        if (!franchiseToolTipHelper.includes('identity'))
          setFranchiseToolTipHelper([...franchiseToolTipHelper, 'identity']);
        return `€${value} ${t('Checkout.insured.franchiseTypes.identity')}`;
    }
  };

  useEffect(() => {
    const textArr = [];
    franchiseToolTipHelper.map(franchiseType => {
      switch (franchiseType) {
        case 'fixed':
          textArr.push(
            `<h2 style="margin-bottom: 1rem; margin-top: 2rem; font-size: 1.4rem;" >${t(
              'Checkout.insured.franchiseTypes.fixed',
            )}</h2>
             <p>${t(
               'The amount of the exemption remains unchanged When a claim is submitted, the agreed amount will always be for your account',
             )}</p>`,
          );
          break;
        case 'variable':
          textArr.push(
            `<h2 style="margin-bottom: 1rem; margin-top: 2rem; font-size: 1.4rem;" >${t(
              'Checkout.insured.franchiseTypes.variable',
            )}</h2>
            <p>${t(
              'The franchise amount will start high but decrease every year This is a kind of reward for driving without damage',
            )}</p>`,
          );
          break;
        case 'percentage_on_compensation':
          textArr.push(
            `<h2 style="margin-bottom: 1rem; margin-top: 2rem; font-size: 1.4rem;" >${t(
              'Checkout.insured.franchiseTypes.percentage_on_compensation',
            )}</h2>
          <p>${t(
            'The amount of the franchise can be calculated as a percentage of the total compensation to which you are entitled Suppose that after an accident you have to have a repair done for 1000 euro and you have a franchise of 10%, then you will have to impose 100€ yourself and you will receive a compensation of 900€',
          )}</p>`,
          );
          break;
        case 'percentage_on_car_value':
          textArr.push(
            `<h2 style="margin-bottom: 1rem; margin-top: 2rem; font-size: 1.4rem;" >${t(
              'Checkout.insured.franchiseTypes.percentage_on_car_value',
            )}</h2>
            <p>${t(
              'The exemption can also be determined on the basis of the catalogue value of your car The more expensive your car, the higher the exemption will also be',
            )}</p>`,
          );
          break;
        case 'english':
          textArr.push(
            `<h2 style="margin-bottom: 1rem; margin-top: 2rem; font-size: 1.4rem;" >${t(
              'Checkout.insured.franchiseTypes.english',
            )}</h2>
            <p>${t(
              'If the damage exceeds the amount of the exemption, you will be refunded the full amount of the damage If the damage is less than the franchise amount, you will not receive anything and you will have to pay for all the costs yourself',
            )}</p>`,
          );
          break;
        case 'identity':
          textArr.push(
            `<h2 style="margin-bottom: 1rem; margin-top: 2rem; font-size: 1.4rem;" >${t(
              'Checkout.insured.franchiseTypes.identity',
            )}</h2>
          <p>${t(
            'This franchise means that in the guarantee of material damage, no exemption applies if the insured vehicle is involved in a collision with an identified pedestrian, cyclist or vehicle, not belonging to the family members of the policyholder',
          )}</p>`,
          );
          break;
      }
    });

    setFranchiseToolTipText(textArr.join(''));
  }, [franchiseToolTipHelper]);

  const getInsuredValueText = name => {
    let values;
    let option1;
    let option2;
    let option3;
    let option4;

    if (name === 'insured_value') {
      option1 = t('Checkout.card.insured_values.building');
      values = price.insured_value;
    } else {
      values = price.insured_content_value;
      option1 = t('Checkout.card.insured_values.contents');
    }

    if (values) {
      if (values.insured_with_evaluation_grid) {
        option2 = t('Checkout.card.insured_values.grid');
      } else if (values.insured_as_first_risk) {
        option2 = t('Checkout.card.insured_values.risk');
      } else {
        option2 = t('Checkout.card.insured_values.rule');
      }

      if (values.max_value) {
        const max_value = values.max_value.toLocaleString('nl-BE');
        option3 = t('Checkout.card.insured_values.max_amount', {
          max_value: `€${max_value}`,
        });
      } else {
        option3 = t('Checkout.card.insured_values.no_amount');
      }

      if (values.reconstruction_with_new_standards) {
        option4 = t('Checkout.card.insured_values.extra');
      }
    } else {
      return 'NA';
    }

    const mainText = t('Checkout.card.insured_values.text', {
      option1: option1,
      option2: option2,
      value: option3,
      extra: option4,
    });
    return mainText;
  };

  const getInitialValues = () => {
    const tmpVal = getNestedObject(price, ['insured_value', 'value']);
    const tmpContentVal = getNestedObject(price, [
      'insured_content_value',
      'value',
    ]);

    const obj = {
      insured_value: '',
      insured_content_value: '',
    };

    if (tmpVal)
      obj['insured_value'] = parseFloat(tmpVal.toFixed(2)).toLocaleString(
        'nl-BE',
      );
    if (tmpContentVal)
      obj['insured_content_value'] = parseFloat(
        tmpContentVal.toFixed(2),
      ).toLocaleString('nl-BE');

    return obj;
  };

  const resetPrice = () => {
    const { ck, vk } = discountData;
    const { id } = quote;
    setExceptionError(null);
    setIsDisabled(true);
    setLoadPrice(true);
    debouncedCallback({
      id,
      ck,
      vk,
      franchise: selectedFranchise,
      insuredValues: { insured_value: null, insured_content_value: null },
    });
  };

  useEffect(() => {
    setPriceData({ ...priceData, acceptedTerms });
  }, [acceptedTerms]);

  return (
    <>
      {showLegalPopup ? (
        <MarketplaceLegalWarningPopup
          name="LEGAL"
          close={(key, value) => handleClick(key, value)}
        />
      ) : null}
      <CardContainer className={`${name}${id}`}>
        {showGardenDiaPopup ? (
          <BigInsuranceCardDiaDemoGardenCheck
            showPopup={bool => {
              setShowGardenDiaPopup(bool);
            }}
            setGarden={bool => {
              if (!bool) handlePackageToggle('GARDEN');
            }}
          />
        ) : null}

        <MatchContainer>
          <ToggleButton
            data-test-id={`underwriting_compare_${id}`}
            disableCompareItems={disableCompareItems}
            onClick={() =>
              !disableCompareItems
                ? compare({
                    score,
                    insurerName: quote.insurance_company.name,
                    insuranceName: quote.insurance_details.name,
                    insurerLogo: quote.insurance_company.logo,
                    premium:
                      price && price.promotion_premium && price.discount_premium
                        ? price.discount_premium.total_premium
                        : price && price.promotion_premium
                        ? price.promotion_premium.total_premium
                        : price && price.discount_premium
                        ? price.discount_premium.total_premium
                        : price && price.total_premium
                        ? price.total_premium
                        : 0,
                    selectedPackages,
                    packages,
                    id: quote.id,
                  })
                : null
            }
          >
            {t(`Checkout.matrix_button.compare`)}
          </ToggleButton>
          <MatchValue>
            <span>{score.toFixed(2)}%</span> Match
          </MatchValue>
          {console.log(price)}
          {price?.quote_id && <p>ref: {price.quote_id}</p>}
        </MatchContainer>
        <InfoContainer>
          <h2>{children}</h2>
          <Header>
            <LinkInsurance
              target="_blank"
              rel="noopener noreferrer"
              href={quote.insurance_company.website}
            >
              {console.log('flowStore', flowStore)}
              {quote.insurance_company.logo ? (
                flowStore.type === 'campaign' ? (
                  <Img src={flowStore?.distribution?.logo} alt={name} />
                ) : (
                  <Img src={quote.insurance_company.logo} alt={name} />
                )
              ) : (
                <FakeLogo>{quote.insurance_company.name}</FakeLogo>
              )}
            </LinkInsurance>
            <div>
              <p className="company">{name}</p>
              <h1>{quote.insurance_details.name.toLowerCase()}</h1>

              {!getSocialLogin() ? (
                <PDFFile>
                  <PDFLink href={pdfs.terms.link} target="_blank">
                    {pdfs.terms ? (
                      <img src={pdfIcon} alt="Download pdf" />
                    ) : null}
                    {t('Checkout.card.termsPDF')}
                  </PDFLink>
                  <PDFLink href={pdfs.ipid.link} target="_blank">
                    {pdfs.ipid ? (
                      <img src={pdfIcon} alt="Download pdf" />
                    ) : null}
                    {t(`Checkout.card.IPID`)}
                  </PDFLink>
                </PDFFile>
              ) : null}
            </div>

            <Price
              data-test-id={
                loadPrice
                  ? `underwriting_tarification_price_${id}_loading`
                  : price.total_premium && price && !impossible
                  ? `underwriting_tarification_price_${id}_success`
                  : `underwriting_tarification_price_${id}_error`
              }
            >
              {(!price || impossible) && exceptionError !== 'try again' ? (
                <div style={{ display: 'flex' }}>
                  {exceptionError ? (
                    getSocialLogin() ? (
                      <CantCalculate color={exceptionErrorColorCode}>
                        {t(`Checkout.card.noPrice`, {
                          InsuranceCompany: quote.insurance_company.name,
                        })}
                      </CantCalculate>
                    ) : (
                      <StyledInfoPopUp
                        color={exceptionErrorColorCode}
                        buttonText={
                          <CantCalculate
                            color={exceptionErrorColorCode}
                            dangerouslySetInnerHTML={{
                              __html: exceptionError
                                ? t(exceptionErrorInitialText)
                                : t(`Checkout.card.noPrice`, {
                                    InsuranceCompany:
                                      quote.insurance_company.name,
                                  }),
                            }}
                          ></CantCalculate>
                        }
                        isLink={true}
                        title={t('Acceptance company')}
                        info={exceptionError}
                      />
                    )
                  ) : null}
                </div>
              ) : loadPrice && exceptionError !== 'try again' ? (
                <Loading width="25rem" height="7.5rem" />
              ) : price.total_premium === null &&
                exceptionError !== 'try again' ? (
                <div style={{ display: 'flex' }}>
                  {exceptionError ? (
                    getSocialLogin() ? (
                      <CantCalculate color={exceptionErrorColorCode}>
                        {t(`Checkout.card.noPrice`, {
                          InsuranceCompany: quote.insurance_company.name,
                        })}
                      </CantCalculate>
                    ) : (
                      <StyledInfoPopUp
                        color={exceptionErrorColorCode}
                        buttonText={
                          <CantCalculate
                            color={exceptionErrorColorCode}
                            dangerouslySetInnerHTML={{
                              __html: exceptionError
                                ? t(exceptionErrorInitialText)
                                : t(`Checkout.card.noPrice`, {
                                    InsuranceCompany:
                                      quote.insurance_company.name,
                                  }),
                            }}
                          ></CantCalculate>
                        }
                        isLink={true}
                        title={t('Acceptance company')}
                        info={exceptionError}
                      />
                    )
                  ) : null}
                </div>
              ) : exceptionError === 'try again' ? (
                <div>
                  <TryAgain onClick={() => resetPrice()}>
                    {t(`Connection error Click here to try again`)}
                  </TryAgain>
                </div>
              ) : price.promotion_premium && price.discount_premium ? (
                <>
                  <Row>
                    <OldPrice>
                      <span>€{price.total_premium.toFixed(2)}</span>
                      <br />
                      <span>
                        €{price.promotion_premium.total_premium.toFixed(2)}
                      </span>
                    </OldPrice>
                    <InfoButton
                      title={t('Checkout.card.sale.promotion')}
                      info={price.promotion_premium.text}
                    />
                  </Row>
                  <strong>
                    €{price.discount_premium.total_premium.toFixed(2)}{' '}
                  </strong>
                  <p>{t('Checkout.aYear').toUpperCase()}</p>
                </>
              ) : price.promotion_premium ? (
                <>
                  <Row>
                    <OldPrice>€{price.total_premium.toFixed(2)} </OldPrice>
                    <InfoButton
                      title={t('Checkout.card.sale.promotion')}
                      info={price.promotion_premium.text}
                    />
                  </Row>
                  <strong>
                    €{price.promotion_premium.total_premium.toFixed(2)}{' '}
                  </strong>
                  <p>{t('Checkout.aYear').toUpperCase()}</p>
                </>
              ) : price.discount_premium ? (
                <>
                  <Row>
                    <OldPrice>€{price.total_premium.toFixed(2)} </OldPrice>
                  </Row>
                  <strong>
                    €{price.discount_premium.total_premium.toFixed(2)}{' '}
                  </strong>
                  <p>{t('Checkout.aYear').toUpperCase()}</p>
                </>
              ) : (
                <>
                  <strong>€{price.total_premium.toFixed(2)} </strong>
                  <p>{t('Checkout.aYear').toUpperCase()}</p>
                </>
              )}
            </Price>
          </Header>
          {coverage === 'home' ? (
            <InsuredAmountContainer>
              <div style={{ width: '100%' }}>
                {price ? (
                  <Formik
                    enableReinitialize={true}
                    initialValues={getInitialValues()}
                    validationSchema={Schema}
                  >
                    {({
                      errors,
                      touched,
                      setFieldTouched,
                      setFieldValue,
                      values,
                    }) => {
                      return (
                        <ValueContainer>
                          <Wrapper>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <p>{t('Checkout.insured.insured_value')}</p>
                              {price ? (
                                price.insured_value ? (
                                  <InfoPopUp
                                    title={t('Checkout.insured.insured_value')}
                                    info={getInsuredValueText('insured_value')}
                                  />
                                ) : null
                              ) : null}
                            </div>
                            <InsureValueContainer>
                              <MarketplaceInsuranceCardValueInput
                                name="insured_value"
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                setFieldTouched={setFieldTouched}
                                insuredValues={insuredValues}
                                setInsuredValues={setInsuredValues}
                                value={values['insured_value'] || 0}
                              />
                            </InsureValueContainer>
                          </Wrapper>
                          <Wrapper>
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <p>
                                {t('Checkout.insured.insured_content_value')}
                              </p>
                              {price ? (
                                price.insured_value ? (
                                  <InfoPopUp
                                    title={t(
                                      'Checkout.insured.insured_content_value',
                                    )}
                                    info={getInsuredValueText(
                                      'insured_content_value',
                                    )}
                                  />
                                ) : null
                              ) : null}
                            </div>
                            <InsureValueContainer>
                              <MarketplaceInsuranceCardValueInput
                                name="insured_content_value"
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                setFieldTouched={setFieldTouched}
                                insuredValues={insuredValues}
                                setInsuredValues={setInsuredValues}
                                value={values['insured_content_value']}
                              />
                            </InsureValueContainer>
                          </Wrapper>
                          <RightButtonContainer>
                            {!getSocialLogin() ? (
                              <MarketplaceInsuranceCardPrintButton
                                insuranceId={card.id}
                              />
                            ) : null}
                            <Reset>
                              <div onClick={() => resetPrice()}>
                                <ResetIcon
                                  colors={getCurrentTheme().brand.primary}
                                  width="4rem"
                                  height="4rem"
                                />
                              </div>
                            </Reset>
                          </RightButtonContainer>
                        </ValueContainer>
                      );
                    }}
                  </Formik>
                ) : (
                  <p> nope </p>
                )}
              </div>
            </InsuredAmountContainer>
          ) : null}
          {coverage === 'car' ? (
            <InsuredAmountContainer>
              <Wrapper>
                <p>{t('Checkout.insured.bonusMalus')}</p>
                {price ? (
                  price.bonus_malus != null ? (
                    <NVT>{price.bonus_malus}</NVT>
                  ) : (
                    <NVT>&nbsp;</NVT>
                  )
                ) : (
                  <NVT>{t('Checkout.nvt')}</NVT>
                )}
              </Wrapper>
              <Wrapper>
                <Flex>
                  {/* change value based on whats in the options list */}
                  <p>{t('Checkout.insured.franchise')}</p>
                  {franchiseToolTipText ? (
                    <InfoPopUp title="franchise" info={franchiseToolTipText} />
                  ) : null}
                </Flex>
                {getNestedObject(price, ['franchise']) ? (
                  <>
                    <SelectContainer>
                      <Select
                        value={`${selectedFranchise.value}, ${selectedFranchise.type}`}
                        onChange={e => selectedFranchisehandler(e.target.value)}
                        name="franchise"
                      >
                        {price.franchises.map(franchise => (
                          <option
                            key={franchise.value + franchise.type}
                            value={`${franchise.value}, ${franchise.type}`}
                          >
                            {showFranchise(franchise.type, franchise.value)}
                          </option>
                        ))}
                      </Select>
                      <label htmlFor="franchise">
                        <svg
                          width="15"
                          height="9"
                          viewBox="0 0 15 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7.39572 7.41711L13.7914 1"
                            stroke="#CCCCCC"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </label>
                    </SelectContainer>
                  </>
                ) : price.bonus_malus != null ? (
                  <NVT>{t('Checkout.nvt')}</NVT>
                ) : (
                  <NVT>&nbsp;</NVT>
                )}
              </Wrapper>
              {!getSocialLogin() ? (
                <MarketplaceInsuranceCardPrintButton
                  disabled={
                    isDisabled ||
                    price.total_premium === 0 ||
                    impossible ||
                    !price.total_premium
                  }
                  insuranceId={card.id}
                />
              ) : null}
            </InsuredAmountContainer>
          ) : null}
          {!getSocialLogin() && coverage !== 'home' && coverage !== 'car' ? (
            <PrintButtonContainer>
              <MarketplaceInsuranceCardPrintButton insuranceId={card.id} />
            </PrintButtonContainer>
          ) : null}
          <PackagesContainer>
            {packages.length === 0 && coverage !== 'car' ? (
              <h2>{t(`Checkout.card.extentions.extention_empty`)}</h2>
            ) : (
              <h2>{t(`Checkout.card.extentions.extention_title`)}</h2>
            )}

            <ExtentionsList>
              {coverage === 'car' ? (
                <CarPackageBa>
                  <BAIcon
                    title={t('MarketCar.BA')}
                    colors={'#fff'}
                    height="2.5rem"
                  />
                  <BaLabel>{t('MarketCar.BA')} </BaLabel>
                </CarPackageBa>
              ) : null}

              {Object.values(packages).map((pack, i) => {
                getNestedObject(
                  price,
                  ['promotion_premium', 'mandatory'],
                  [],
                ).forEach(extention => {
                  <>
                    <IconCheckBox
                      checked={selectedPackages.includes(pack.type)}
                      key={i}
                      dataTestId={`underwriting_package_${pack.type}`}
                      name={pack.type}
                      onCheck={(name, checked) => handleClick(name, checked)}
                    >
                      <LoadingExtentionIcons type={extention} />
                      <p>{extention}</p>
                    </IconCheckBox>
                  </>;
                });
                return (
                  <IconCheckBox
                    checked={selectedPackages.includes(pack.type)}
                    key={i}
                    dataTestId={`underwriting_package_${pack.type}`}
                    name={pack.type}
                    onCheck={(name, checked) => handleClick(name, checked)}
                  >
                    <LoadingExtentionIcons type={pack.type} title={pack.name} />
                    <p>{pack.name}</p>
                  </IconCheckBox>
                );
              })}
            </ExtentionsList>
          </PackagesContainer>
          {impossible ? (
            <Impossible>{t('This combination is not possible')}</Impossible>
          ) : (
            <>
              <Properties>
                {getNestedObject(quote, ['properties', 'best'], []).length >
                0 ? (
                  <Propertie>
                    <h2>{t(`Checkout.card.included_title`)}</h2>
                    <MarketplaceInsuranceCardCoverageCarousel
                      items={getNestedObject(quote, ['properties', 'best'], [])}
                    />
                  </Propertie>
                ) : null}
                {getNestedObject(quote, ['properties', 'worst'], []).length >
                0 ? (
                  <Propertie>
                    <h2>{t(`Checkout.card.excluded_title`)}</h2>
                    <MarketplaceInsuranceCardCoverageCarousel
                      items={getNestedObject(
                        quote,
                        ['properties', 'worst'],
                        [],
                      )}
                    />
                  </Propertie>
                ) : null}
              </Properties>
            </>
          )}
        </InfoContainer>
        {!getSocialLogin() && shouldCalculate ? (
          <BigInsuranceCardManualDiscount
            insurer={name}
            discounts={price ? price.discounts : null}
            discountPremium={price ? price.discount_premium : null}
            discountData={data => setDiscountData(data)}
            // changedDiscount={val => setPrice(val)}
            id={quote.id}
            sortKey={id}
            price={price}
            insuranceType={coverage}
            disabledExport={
              isDisabled ||
              price.total_premium === 0 ||
              impossible ||
              !price.total_premium
            }
            disabledButton={val => setIsDisabled(val)}
          />
        ) : null}
        <BottomContainer>
          {coverage !== 'car' ? (
            <CardCheckBox
              coverage={coverage}
              accepted={status => setAcceptedTerms(status)}
              id={quote.id}
              dataTestId={id}
            />
          ) : (
            t('The primary driver had x claims in the last 5 years', {
              count: numberOfAccidents?.filter(function(el) {
                return el != null;
              })?.length,
            })
          )}
          {price ? (
            <AddToCart
              card={card}
              priceData={priceData}
              activeItem={activeItem ? activeItem : undefined}
              insuranceCompanies={insuranceCompanies}
              disabled={
                (coverage !== 'car' && acceptedTerms === null) ||
                isDisabled ||
                price.total_premium === 0 ||
                impossible ||
                !price.total_premium
              }
              typeButton={1}
              insurance={t(`Coverages.${coverage}`)}
              enteredData={dataType}
              dataTestId={`underwriting_submit_${id}`}
            >
              {t('Checkout.card.addBtn')}
            </AddToCart>
          ) : null}
        </BottomContainer>
      </CardContainer>
    </>
  );
};

const CantCalculate = styled.div`
  font-weight: 400;
  font-size: 1.8rem;
  width: 19rem;
  margin-left: 2rem;
  color: ${({ color }) => color};
`;

const TryAgain = styled.div`
  line-height: 1.2;
  font-weight: 400;
  font-size: 1.8rem;
  width: 19rem;
  margin-left: 2rem;
  color: red;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 50%;
`;

const ValueContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Reset = styled.div`
  align-self: center;
  margin-top: 1rem;

  :hover {
    cursor: pointer;
  }
`;

const InsureValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 20rem;

  /* & div {
    position: absolute;
    margin-top: 4.5rem;
    margin-bottom: 2rem;

    @media screen and (max-width: 1050px) {
      left: 0;
      margin-top: 3.5rem;
    }
  } */

  & label {
    margin-top: 1rem;
    margin-bottom: 2.7rem;

    &::before {
      content: '€';
      position: absolute;
      color: black;
      left: 1rem;
      font-size: 1.5rem;
      bottom: 0.9rem;
      font-weight: bold;
    }
    & input {
      -webkit-appearance: none;
      padding-left: 2.5rem;
    }
  }
`;

const SelectContainer = styled.div`
  display: flex;
  position: absolute;
  & label {
    position: absolute;
    right: 1.1rem;
    border-left: 1px solid #ccc;
    user-select: none;
    pointer-events: none;
    padding: 0.5rem 0;
    height: 2.6rem;
    top: 27%;
    padding-left: 0.6rem;
  }
`;

const Select = styled.select`
  border: 1px solid #ccc;
  background-color: white;
  min-width: 22rem;
  padding-right: 2rem;
  font-weight: 700;
  margin: 1.7rem 0 2.7rem;
  height: 3.3rem;
  color: #736f6a;
  font-size: 1.3rem;
  padding-left: 1rem;
  cursor: pointer;
  appearance: none;

  :focus {
    outline: none;
  }
`;

const ToggleButton = styled.button`
  background-color: ${({ theme, disableCompareItems }) =>
    disableCompareItems ? theme.brand.interactive : 'white'};
  color: ${({ theme, disableCompareItems }) =>
    disableCompareItems ? 'white' : theme.brand.secondary};
  padding: 0.75rem 4.5rem;
  border-radius: 2.5rem;
  margin-right: 2rem;
  z-index: 11;
  cursor: ${props => (props.disableCompareItems ? 'default' : 'pointer')};
  border: none;
  font-size: 1.8rem;
  font-weight: 700;
  :focus {
    outline: none;
  }
`;

const OldPrice = styled.p`
  font-weight: 900;
  color: ${({ theme }) => theme.typo.interactive};
  text-decoration: line-through;
  font-size: 2rem;
  margin-bottom: 0.5rem;

  & span :first-child {
    font-size: 1.4rem;
    font-weight: 900;
  }
`;

const PackagesContainer = styled.div`
  margin-bottom: 2.6rem;

  & span {
    align-self: center;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const PDFFile = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-top: 2rem;
`;

const PDFLink = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
  padding: 0.9rem 1.2rem;
  background-color: ${({ theme }) => theme.brand.primary};
  font-size: 1.6rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;

  & img {
    margin-right: 1.3rem;
    max-height: 3rem;
    height: 100%;
    flex: 0;
  }
`;

const InfoButton = styled(InfoPopUp)`
  padding: 0.4rem;
`;

const InsuredAmountContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.typo.interactive};
`;

const Amount = styled.p`
  font-size: 2rem;
  color: ${({ theme }) => theme.typo.text};
  font-weight: 900;
  margin: 1.7rem 0 2.7rem;
  height: 2rem;

  &::before {
    content: '€';
    margin-right: 0.5rem;
  }
`;

const NVT = styled(Amount)`
  color: ${({ theme }) => theme.typo.interactive};
  font-size: 1.6rem;

  &::before {
    content: '';
    margin: 0;
  }
`;

const BaLabel = styled.p`
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  padding: 1.3rem 1.5rem 1rem 4.5rem;
  border-radius: 2.5rem;
`;

const CarPackageBa = styled.div`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-right: 1rem;
  display: flex;
`;

const ExtentionsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledInfoPopUp = styled(InfoPopUp)`
  color: ${({ color }) => color};
`;

const FakeLogo = styled.div`
  padding: 3rem 2rem;
  margin-right: 2rem;
  background-color: ${({ theme }) => theme.brand.primary};
  height: 6rem;
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3.5rem;
  font-weight: 900;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20rem;
`;

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  transition: all 0.1s ease 0s;
  background-color: white;
  border-radius: 3.3rem;
  margin-bottom: 5rem;
  font-size: 1.6rem;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in;
  overflow: hidden;
  width: 90rem;
  justify-content: center;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    5% {
      transform: translate3d(0, 10%, 0);
    }
    95% {
      opacity: 1;
    }
    100% {
      transform: none;
    }
  }
`;

const Propertie = styled.div`
  display: flex;
  flex-wrap: wrap;

  & .best {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }

  & a,
  p {
    margin-top: 0;
    font-size: 1.6rem;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }

  & img {
    width: 9.5rem;
  }
`;

const Properties = styled.div`
  margin-bottom: 1rem;
`;

const MatchContainer = styled.div`
  padding: 0 2rem;
  height: 6.4rem;
  border-radius: 3.3rem 3.3rem 0 0;
  background-color: ${({ theme }) => theme.brand.secondary};
  color: white;
  font-size: 2rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;

  & span {
    font-weight: 900;
    font-size: 2.5rem;
  }
  > p {
    font-size: 1.2rem;
    color: white;
    opacity: 0.5;
  }
`;

const MatchValue = styled.h2`
  position: absolute;
  margin: 0 auto;
  text-align: center;
  width: 86rem;
`;

const InfoContainer = styled.div`
  padding: 0 4rem;
  margin: 2.4rem 0 0;
  position: relative;

  & h2 {
    font-size: 1.6rem;
    font-weight: normal;
    color: grey;
    margin-bottom: 2.5rem;
  }

  & > h2:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const Impossible = styled.h3`
  color: red;
  margin-bottom: 3rem;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
  padding-bottom: 1.7rem;
  border-bottom: 1px solid #d9d8dc;

  & .company {
    color: grey;
    font-size: 2.2rem;
    align-self: flex-end;
  }

  & h1 {
    font-size: 2.2rem;
    margin-top: 0.5rem;
    font-weight: bold;
    text-transform: capitalize;
  }

  & li {
    font-size: 2rem;
    line-height: 2.6rem;
  }
`;

const LinkInsurance = styled.a`
  text-decoration: none;
  align-self: center;
  width: 20rem;
  margin-right: 2rem;
`;

const Img = styled.img`
  margin-right: 3rem;
  user-select: none;
  -webkit-user-drag: none;
  max-width: 20rem;
  max-height: 10rem;
  &[src*='svg'] {
    height: auto;
    width: auto;
  }
`;

const Price = styled.div`
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: 1.5rem;

  & strong {
    font-size: 5.5rem;
    font-weight: 900;
  }

  & p {
    margin-top: 0.5rem;
    letter-spacing: 0.4rem;
    text-align: right;
  }
`;

const BottomContainer = styled.div`
  background-color: #e8e8e8;
  display: flex;
  padding: 1.7rem 3.7rem 2.7rem;
  justify-content: space-between;
  align-items: center;
`;

const RightButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`;

const PrintButtonContainer = styled.div`
  position: absolute;
  right: 4rem;
`;

BigInsuranceCard.defaultProps = {
  children: '',
  selectedPacks: null,
  isSelected: false,
  activeItem: undefined,
  numberOfPacks: 0,
  currentPack: 0,
};

BigInsuranceCard.propTypes = {
  packs: array.isRequired,
  name: string.isRequired,
  coverage: string.isRequired,
  children: string,
  isSelected: bool,
  car: bool,
  compare: func.isRequired,
  pdfs: shape().isRequired,
  disableCompareItems: bool.isRequired,
  dataType: object,
  activeItem: number,
  selectedPacks: string,
  id: number.isRequired,
  insuranceCompanies: shape(),
  numberOfPacks: number,
  currentPack: number,
};
export default BigInsuranceCard;
