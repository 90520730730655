import React, { useState, useEffect, useContext } from 'react';
import { string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';
import { cloneDeep, isEqual } from 'lodash';
import cogoToast from 'cogo-toast';
/* API services */
import {
  getBrokerDistribution,
  getBrokerData,
} from '../../services/brokerDataService';
import { getMeData } from '../../services/meDataService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc.jsx';
import DateFilterComponent from '../../components/DateFilterComponent';
import {
  getFilteredLeadsByDistributionId,
  getBrokersByDistributionId,
  updateLeadById,
} from '../../services/apiRouterService';

import CheckBoxContainer from '../../components/CheckBoxContainer';
import QuickActionSubMenuStatus from '../../components/QuickActionSubmenuStatus';
import QuickActionSubMenu from '../../components/QuickActionSubmenu';

import {
  ActionButton,
  CollapsibleSidebar,
  CheckBox_v2 as CheckBox,
  SearchSelectInput,
  // CheckBoxContainer,
  IconHeavyScooter,
  IconCarFilled,
  IconStats,
  IconFamilyFilled,
  // IconFlightFilled,
  IconLegalFilled,
  IconPropertyFilled,
  Modal,
  OrderTable,
  Pagination,
  PhaseLabel,
  RiskObjectLabel,
  SearchInput,
  TertiaryButton,
  LoadingSpinner,
  SubLabel,
  //IconMotor,
  OverviewTable,
} from 'wg-fe-ui';

import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import { setTabItems } from '../../services/CRMService';

import Frame from '../../../assets/images/Frame.svg';
import { useLocation } from 'react-router';
import { stringify } from 'query-string';
// import CRMFiltersLead from '../../components/CRMFiltersLeads';

const statusesLength = 3;
let insuranceTypeLength = 4;

const initialFilters = {
  status: {
    ACCEPTED: true,
    REFUSED: true,
    PENDING: true,
    IN_PROGRESS: true,
  },
  insurance_type: {
    CAR: true,
    FAMILY: true,
    RESIDENCE: true,
    LEGAL: true,
  },
  created_at_ge: null,
  created_at_le: null,
};

const CRMContractLeadsOverview = ({ match }) => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const configCatClient = useContext(FlagsProvider);
  const { t } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const [isLoadingLeads, setIsLoadingLeads] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [topSearchValue, setTopSearchValue] = useState('');
  const [paginationData, setPaginationData] = useState({});
  const [retrievedData, setRetrievedData] = useState([]);
  const [isCallantFeature, setIsCallantFeature] = useState(null);
  const [amounOfEntries, setAmountOfEntries] = useState('');
  const [brokers, setBrokers] = useState([]);
  const [allBrokers, setAllBrokers] = useState([]);
  const [assignedToOptions, setAssignedToOptions] = useState([]);
  const [createdByOptions, setCreatedByOptions] = useState([]);

  // TODO: check to convert to useReducer
  const [activeFilters, setActiveFilters] = useState(
    location?.state?.filters || initialFilters,
  );

  // TODO: check to convert to useReducer
  const [previousFilters, setPreviousFilters] = useState(
    location?.state?.filters || initialFilters,
  );
  const [haveFiltersChanged, setHaveFiltersChanged] = useState(false);
  const history = useHistory();

  const handleApplyFilters = filters => {
    setIsLoadingLeads(true);
    if (!filters) filters = activeFilters;
    setPreviousFilters(cloneDeep(filters));
    setHaveFiltersChanged(false);
    getFilteredLeads(filters);
  };

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const callantFeature = await configCatClient.getValueAsync(
      'callantFeature',
      true,
      {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      },
    );
    let areFiltersSet = false;
    if (location?.state?.filters) areFiltersSet = true;
    const tempFilters = cloneDeep(activeFilters);
    if (callantFeature) {
      if (areFiltersSet) {
        tempFilters.insurance_type.TEACHER =
          tempFilters?.insurance_type?.TEACHER || false;
        tempFilters.insurance_type.TWO_WHEELER =
          tempFilters?.insurance_type?.TWO_WHEELER || false;
      } else {
        tempFilters.insurance_type.TEACHER = true;
        tempFilters.insurance_type.TWO_WHEELER = true;
      }
      setActiveFilters(tempFilters);
      setPreviousFilters(tempFilters);
      insuranceTypeLength = 6;
    }
    setIsCallantFeature(callantFeature);
    return tempFilters;
  }

  async function getFilteredLeads(filters) {
    if (!filters) filters = activeFilters;
    const { id } = await getBrokerDistribution();
    const paramDefaultLength = {
      status: statusesLength,
      insurance_type: insuranceTypeLength,
    };
    const objectToConvertToParams = {};
    Object.keys(filters).forEach(key => {
      switch (key) {
        case 'insurance_type':
        case 'status':
          if (
            Object.keys(filters[key]).filter(item => item).length <
            paramDefaultLength[key]
          ) {
            objectToConvertToParams[key] = Object.keys(filters[key]).filter(
              item => item,
            );
          }
          break;
        case 'created_at_ge':
        case 'created_at_le':
          if (filters[key]) {
            objectToConvertToParams[key] = filters[key]?.toISOString();
          }
          break;
        case 'assigned_to':
        case 'created_by':
          if (filters[key].length > 0) {
            objectToConvertToParams[key] = filters[key]?.map(
              item => item.value,
            );
          }
          break;
        default:
          return;
      }
    });
    let [response, status] = await getFilteredLeadsByDistributionId(
      id,
      match.match.params.page,
      stringify(objectToConvertToParams),
      topSearchValue,
    );

    if (status !== 200) {
      return console.error('Error Response: ', response);
    }
    console.log('Response: ', response);
    retrieveBrokersFromDistribution(id);
    setRetrievedData(await response.items);
    setPaginationData(await response.pagination);
    setIsLoading(false);
    setIsLoadingLeads(false);
  }

  const toggleAllCheckBoxes = (checkboxNames, value, type) => {
    const newFilters = cloneDeep(activeFilters);
    checkboxNames.forEach(key => {
      if (!value) delete newFilters[type][key];
      else newFilters[type][key] = value;
    });
    setActiveFilters(newFilters);
    if (isEqual(previousFilters, newFilters)) {
      setHaveFiltersChanged(false);
    } else {
      setHaveFiltersChanged(true);
    }
  };

  const handleFilterChange = (e, key) => {
    setActiveFilters(prevValue => {
      let newFilters = cloneDeep(prevValue);
      if (key) {
        newFilters[key][e.name] = e.value;
        if (!e.value) delete newFilters[key][e.name];
      } else {
        newFilters[e.name] = e.value;
        if (!e.value) delete newFilters[e.name];
      }
      if (isEqual(previousFilters, newFilters)) {
        setHaveFiltersChanged(false);
      } else {
        setHaveFiltersChanged(true);
      }
      return newFilters;
    });
  };

  const [debounceFetchLeads] = useDebouncedCallback(getFilteredLeads, 500);

  const retrieveBrokersFromDistribution = async id => {
    const [resp] = await getBrokersByDistributionId(id);
    const idsToRemoveFrom = [
      '3471bb16-fe88-4e34-840e-b2d000ac1603',
      '0b6c634d-4f1d-4264-89ee-bbe8a06bfa24',
    ];

    const sortedAllBrokers = resp.items.sort((b1, b2) => {
      if (b1?.broker?.name > b2?.broker?.name) return 1;
      if (b1?.broker?.name < b2?.broker?.name) return -1;
      return 0;
    });

    setAllBrokers(sortedAllBrokers);

    // Sets all brokers and removes specific brokers from the CALLANT list.
    setBrokers(
      sortedAllBrokers.filter(
        item =>
          item.broker.plan !== 'CALLANT' &&
          !idsToRemoveFrom.some(obj => obj === item.broker.id),
      ),
    );

    const tempCreatedByOptions = [];

    sortedAllBrokers
      .filter(object => !idsToRemoveFrom.some(obj => obj === object.broker.id))
      .forEach(item => {
        tempCreatedByOptions.push({
          label: item.broker.name,
          value: item.broker.id,
        });
      });

    setCreatedByOptions(tempCreatedByOptions);

    const tempAssignedToOptions = [];
    resp.items
      .filter(object => !idsToRemoveFrom.some(obj => obj === object.broker.id))
      .forEach(item => {
        tempAssignedToOptions.push({
          label: item.broker.name,
          value: item.broker.id,
        });
      });

    setAssignedToOptions(tempAssignedToOptions);
  };

  useEffect(() => {
    setIsLoadingLeads(true);
    const getFilteredData = async () => {
      const tempFilters = await setFeatureToggles();
      setNavTabs(
        setTabItems(null, t, 'offersOverview', match.match.params.page),
      );
      if (location?.state?.filters) {
        setActiveFilters(location?.state?.filters);
        setPreviousFilters(location?.state?.filters);
        await getFilteredLeads(location?.state?.filters);
      } else {
        await getFilteredLeads(tempFilters);
      }
    };

    getFilteredData();
  }, [match.match.params.page, location?.state?.filters]);

  useEffect(() => {
    showAmountOfEntries(paginationData);
  }, [paginationData]);

  const showAmountOfEntries = ({ amount, pagelen, page }) => {
    setAmountOfEntries(
      `${page * pagelen - pagelen + 1} ${t('pagination.to')} ${page *
        pagelen} ${t('pagination.of')} ${amount}`,
    );
  };

  const updateAssignedTo = async (broker, i, offerId) => {
    const { id } = await getBrokerDistribution();
    const tempData = JSON.parse(JSON.stringify(retrievedData));
    setRetrievedData(prevValue => {
      const temp = JSON.parse(JSON.stringify(prevValue));
      temp[i].lead.assigned_to = broker;
      return temp;
    });
    const [resp, respStatus] = await updateLeadById(id, offerId, {
      assigned_to: broker,
    });
    if (respStatus === 200) {
      cogoToast.success(t('Offer was successfully assigned'));
    } else if (resp.msg != null) {
      setRetrievedData(tempData);
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
    } else {
      cogoToast.error(t('Something went wrong'));
    }
  };

  const updateStatus = async (status, i, leadId) => {
    const { id } = await getBrokerDistribution();
    const tempData = JSON.parse(JSON.stringify(retrievedData));
    setRetrievedData(prevValue => {
      const temp = JSON.parse(JSON.stringify(prevValue));
      temp[i].lead.status = status;
      return temp;
    });

    const [resp, respStatus] = await updateLeadById(id, leadId, {
      status,
    });
    if (respStatus === 200) {
      cogoToast.success(t('Lead status has been changed'));
    } else if (resp.msg != null) {
      setRetrievedData(tempData);
      const { hide } = cogoToast.error(
        t(resp.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
    } else {
      setRetrievedData(tempData);
      cogoToast.error(t('Something went wrong'));
    }
  };

  const handleTopSearch = e => {
    setTopSearchValue(e);
    debounceFetchLeads();
  };

  const handleTopSearchClear = () => {
    setTopSearchValue('');
    debounceFetchLeads();
  };

  const clickHandler = (e, lead) => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/lead/${lead.id}`,
      state: {
        filters: { ...previousFilters },
        page: match.match.params.page,
        topSearch: topSearchValue,
      },
    });
  };

  // const createLeadHandler = () => {
  //   console.log('Create offer');
  // };

  const checkValuesInObjectIsZero = object => {
    if (object != null || undefined) {
      return Object.keys(object).every(function(key) {
        return object[key] === 0;
      });
    }
  };

  const formatDate = date => {
    const newDate = new Date(date);
    const month = newDate.getMonth() + 1;
    let currentHours = newDate.getHours();
    currentHours = ('0' + currentHours).slice(-2);
    let currentMinutes = newDate.getMinutes();
    currentMinutes = ('0' + currentMinutes).slice(-2);
    const formattedDate =
      (newDate.getDate() <= 9 ? '0' + newDate.getDate() : newDate.getDate()) +
      '/' +
      (month <= 9 ? '0' + month : month) +
      '/' +
      newDate.getFullYear() +
      ' ' +
      currentHours +
      ':' +
      currentMinutes;
    return formattedDate;
  };

  function getRiskObjectIcon(key) {
    switch (key) {
      case 'cars':
        return <IconCarFilled />;
      case 'families':
        return <IconFamilyFilled />;
      case 'residences':
        return <IconPropertyFilled />;
      case 'teachers':
        return <IconStats />;
      case 'two_wheelers':
        return <IconHeavyScooter />;
      case 'legal':
        return <IconLegalFilled />;
      default:
        return '';
    }
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <MainContainer>
      <Modal
        canClose
        showModal={showModal}
        setShowModal={setShowModal}
        title="Add new lead"
      >
        <p>Hello Modal</p>
        <Modal.ModalActions position="right">
          <TertiaryButton
            label="Cancel"
            onClick={() => setShowModal(!showModal)}
          />
          <ActionButton
            disabled={false}
            level="primary"
            onClick={function noRefCheck() {}}
          >
            Primary
          </ActionButton>
        </Modal.ModalActions>
      </Modal>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft></TopBarLeft>

          <TopBarRight>
            <TopBarSearch
              placeholder={t('Search for a name')}
              onChange={event => {
                handleTopSearch(event);
              }}
              text={topSearchValue}
              onClear={() => handleTopSearchClear()}
              data-test-id="leads_search"
            />

            <TopBarAction>
              <ActionButton disabled onClick={() => setShowModal(!showModal)}>
                {t('New lead')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          <CollapsibleSidebar label={t('Filters')} open>
            <CheckBoxContainer
              label={t('Lead status')}
              toggleAllCheckBoxes={(names, value) =>
                toggleAllCheckBoxes(names, value, 'status')
              }
              defaultChecked={
                activeFilters.status.ACCEPTED &&
                activeFilters.status.REFUSED &&
                activeFilters.status.PENDING
              }
            >
              <StyledCheckBox
                name="ACCEPTED"
                checked={activeFilters.status.ACCEPTED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`leads.ACCEPTED`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="PENDING"
                checked={activeFilters.status.PENDING}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`leads.PENDING`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="IN_PROGRESS"
                checked={activeFilters.status.IN_PROGRESS}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`leads.IN_PROGRESS`)}
              </StyledCheckBox>
              <StyledCheckBox
                name="REFUSED"
                checked={activeFilters.status.REFUSED}
                onChange={e => handleFilterChange(e, 'status')}
              >
                {t(`leads.REFUSED`)}
              </StyledCheckBox>
            </CheckBoxContainer>

            <SidebarSpacer />

            <CheckBoxContainer
              label={t('Insurance types')}
              toggleAllCheckBoxes={(names, value) =>
                toggleAllCheckBoxes(names, value, 'insurance_type')
              }
              defaultChecked={
                activeFilters?.insurance_type?.RESIDENCE &&
                activeFilters?.insurance_type?.FAMILY &&
                activeFilters?.insurance_type?.CAR &&
                activeFilters?.insurance_type?.LEGAL
              }
            >
              <StyledCheckBox
                name="RESIDENCE"
                checked={activeFilters?.insurance_type?.RESIDENCE}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconPropertyFilled size={18} color="#505050" />
                <CheckBoxSpacer /> {t('Property')}
              </StyledCheckBox>

              <StyledCheckBox
                name="FAMILY"
                checked={activeFilters?.insurance_type?.FAMILY}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconFamilyFilled size={18} color="#505050" />
                <CheckBoxSpacer />
                {t('Liability')}
              </StyledCheckBox>

              <StyledCheckBox
                name="CAR"
                checked={activeFilters?.insurance_type?.CAR}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconCarFilled size={18} color="#505050" />
                <CheckBoxSpacer />
                {t('Car insurance type')}
              </StyledCheckBox>
              <StyledCheckBox
                name="LEGAL"
                checked={activeFilters?.insurance_type?.LEGAL}
                onChange={e => handleFilterChange(e, 'insurance_type')}
              >
                <IconLegalFilled size={18} color="#505050" />
                <CheckBoxSpacer />
                {t('legal')}
              </StyledCheckBox>

              {isCallantFeature
                ? [
                    <StyledCheckBox
                      key={0}
                      name="TWO_WHEELER"
                      checked={activeFilters?.insurance_type?.TWO_WHEELER}
                      onChange={e => handleFilterChange(e, 'insurance_type')}
                    >
                      <IconHeavyScooter size={18} color="#505050" />
                      <CheckBoxSpacer />
                      {t('Two wheeler insurance type')}
                    </StyledCheckBox>,
                    <StyledCheckBox
                      key={1}
                      name="TEACHER"
                      checked={activeFilters?.insurance_type?.TEACHER}
                      onChange={e => handleFilterChange(e, 'insurance_type')}
                    >
                      <IconStats size={18} color="#505050" />
                      <CheckBoxSpacer />
                      {t('Teacher insurance type')}
                    </StyledCheckBox>,
                  ]
                : null}
            </CheckBoxContainer>

            <SidebarSpacer />

            <DateFilterComponent
              startDate={activeFilters.created_at_ge}
              endDate={activeFilters.created_at_le}
              name={'filterDateCreated'}
              setStartDate={value =>
                handleFilterChange({ name: 'created_at_ge', value })
              }
              setEndDate={value =>
                handleFilterChange({ name: 'created_at_le', value })
              }
            >
              {t('Created between')}
            </DateFilterComponent>

            <SidebarSpacer />
            <StyledSearchSelectInput
              isMulti
              name="assigned_to"
              value={activeFilters.assigned_to}
              placeholder={t('Choose an option')}
              options={assignedToOptions}
              onSelected={({ value }) => {
                const option = value ? value : [];
                handleFilterChange({ name: 'assigned_to', value: option });
              }}
            >
              {t('Assigned to')}
            </StyledSearchSelectInput>

            <StyledSearchSelectInput
              isMulti
              name="created_by"
              value={activeFilters.created_by}
              placeholder={t('Choose an option')}
              options={createdByOptions}
              onSelected={({ value }) => {
                const option = value ? value : [];
                handleFilterChange({ name: 'created_by', value: option });
              }}
            >
              {t('Created by')}
            </StyledSearchSelectInput>

            <SidebarSpacer />

            <ActionButton
              disabled={!haveFiltersChanged}
              fullwidth
              icon={null}
              level="primary"
              onClick={() => {
                handleApplyFilters();
              }}
            >
              {t('Apply filters')}
            </ActionButton>
          </CollapsibleSidebar>
        </Sidebar>
        <Content>
          {isLoadingLeads ? (
            <LoadingContainerLeads>
              <LoadingSpinner />
            </LoadingContainerLeads>
          ) : topSearchValue !== '' && retrievedData?.length === 0 ? (
            <EmptyState>
              <img src={Frame} alt="Test" />
              <h1>{t('No search results found')}</h1>
              <p>
                {`${t(
                  'We could not find anything with the following search term',
                )}: `}{' '}
                <strong> {topSearchValue}</strong>
              </p>
            </EmptyState>
          ) : retrievedData?.length === 0 ? (
            <EmptyState>
              <img src={Frame} alt="Test" />
              <h1>{t('There are no leads yet')}</h1>
              <p>{t('Looks like theres no leads yet')}</p>
              {/* <ActionButton onClick={createLeadHandler}>
              {t('New lead')}
            </ActionButton> */}
            </EmptyState>
          ) : (
            <OrderTable>
              <OverviewTable.Head>
                <OverviewTable.HeaderCell width="20%">
                  {t('Client / Company name')}
                </OverviewTable.HeaderCell>
                <OverviewTable.HeaderCell width="11%">
                  {t('Created on')}
                </OverviewTable.HeaderCell>
                <OverviewTable.HeaderCell width="20%">
                  {t('Assigned to')}
                </OverviewTable.HeaderCell>
                <OverviewTable.HeaderCell width="20%">
                  {t('Risk objects')}
                </OverviewTable.HeaderCell>
                <OverviewTable.HeaderCell width="17%">
                  {t('Origin')}
                </OverviewTable.HeaderCell>
                <OverviewTable.HeaderCell width="12%">
                  {t('Status')}
                </OverviewTable.HeaderCell>
              </OverviewTable.Head>

              <OrderTable.Body>
                {retrievedData.map(({ lead }, i) => {
                  const { prospect, amount_of_risk_objects } = lead;
                  return (
                    <StyledOrderTableRow
                      onClick={e => clickHandler(e, lead)}
                      key={lead?.id}
                      data-test-id={`smt_lead_${i}`}
                    >
                      <StyledBodyCell>
                        {prospect?.name
                          ? prospect?.name
                          : `${prospect?.first_name} ${prospect?.last_name}`}
                      </StyledBodyCell>
                      <OrderTable.BodyCell>
                        {formatDate(lead?.created_at)}
                      </OrderTable.BodyCell>
                      <OrderTable.BodyCell>
                        <QuickActionSubMenu
                          onClick={e => e.stopPropagation()}
                          name={
                            lead?.assigned_to
                              ? allBrokers?.length > 0
                                ? allBrokers?.find(
                                    item =>
                                      item.broker.id === lead?.assigned_to,
                                  )?.broker?.name
                                : t('loading')
                              : t('No one')
                          }
                          data-test-id={`smt_lead_${i}_assigned_to`}
                        >
                          {brokers?.map(item => (
                            <QuickActionSubMenu.SubMenuItem
                              key={item?.broker?.id}
                              label={<SubLabel>{item?.broker?.name}</SubLabel>}
                              onClick={() =>
                                updateAssignedTo(item?.broker?.id, i, lead?.id)
                              }
                              type=""
                            />
                          ))}
                          <QuickActionSubMenu.SubMenuItem
                            label={<SubLabel>{t('No one')}</SubLabel>}
                            onClick={() => updateAssignedTo(null, i, lead?.id)}
                            type=""
                          />
                        </QuickActionSubMenu>
                      </OrderTable.BodyCell>
                      <OrderTable.BodyCell>
                        <RiskObjectsWrapper>
                          {!checkValuesInObjectIsZero(
                            amount_of_risk_objects,
                          ) ? (
                            Object.keys(amount_of_risk_objects).map(key => {
                              if (amount_of_risk_objects[key] === 0)
                                return null;

                              return (
                                <RiskObjectLabel
                                  key={key}
                                  amount={amount_of_risk_objects[key]}
                                  icon={getRiskObjectIcon(key)}
                                />
                              );
                            })
                          ) : (
                            <RiskObjectLabel
                              key={'legal'}
                              amount="1"
                              icon={getRiskObjectIcon('legal')}
                            />
                          )}
                        </RiskObjectsWrapper>
                      </OrderTable.BodyCell>
                      <OrderTable.BodyCell>
                        {lead?.origin ? lead?.origin : '-'}
                      </OrderTable.BodyCell>
                      <OrderTable.BodyCell>
                        <QuickActionSubMenuStatus
                          onClick={e => e.stopPropagation()}
                          status={lead?.status}
                          label={t(`leads.${lead?.status}`)}
                        >
                          <QuickActionSubMenuStatus.SubMenuItem
                            label={
                              <PhaseLabel status="PENDING">
                                {t('leads.PENDING')}
                              </PhaseLabel>
                            }
                            onClick={() => updateStatus('PENDING', i, lead?.id)}
                          />
                          <QuickActionSubMenuStatus.SubMenuItem
                            label={
                              <PhaseLabel status="PENDING">
                                {t('leads.IN_PROGRESS')}
                              </PhaseLabel>
                            }
                            onClick={() =>
                              updateStatus('IN_PROGRESS', i, lead?.id)
                            }
                          />
                          <QuickActionSubMenuStatus.SubMenuItem
                            label={
                              <PhaseLabel status="REFUSED">
                                {t('leads.REFUSED')}
                              </PhaseLabel>
                            }
                            onClick={() => updateStatus('REFUSED', i, lead?.id)}
                          />
                        </QuickActionSubMenuStatus>
                      </OrderTable.BodyCell>
                    </StyledOrderTableRow>
                  );
                })}
              </OrderTable.Body>
            </OrderTable>
          )}
          <ContentFooter>
            <span className="records">
              {t('pagination.showing')} <strong>{amounOfEntries}</strong>{' '}
              {t('pagination.entries')}
            </span>

            <Pagination
              base="/sales-management/contracts/leads/"
              currentPage={paginationData.page}
              pageLength={15}
              onClick={() => setIsLoadingLeads(true)}
              totalPages={paginationData.pages}
            />
          </ContentFooter>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const LoadingContainerLeads = styled.div`
  width: 100%;
  flex: 0 0 100%;
  min-height: 70vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Sidebar = styled.div`
  display: flex;
  padding-bottom: 2rem;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 32px;
  flex: 0 0 252px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 182px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const SidebarSpacer = styled.div`
  margin-top: 20px;
`;

const StyledBodyCell = styled(OrderTable.BodyCell)`
  text-transform: capitalize;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 99999;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarSearch = styled(SearchInput)`
  background-color: #f7f7f7;
  padding-left: 3.5rem;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 6px;
`;

const CheckBoxSpacer = styled.div`
  width: 6px;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
  visibility: hidden;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 110rem;
`;

const RiskObjectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex: 0 0 33.33%;
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  .records {
    font-size: 1.4rem;
    line-height: 130%;
    color: ${({ theme }) => theme.ui.disabled};

    strong {
      font-weight: 900;
    }
  }
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;
    margin-bottom: 35px;
  }
`;

const StyledSearchSelectInput = styled(SearchSelectInput)`
  > label {
    font-weight: bold;
  }

  div {
    font-weight: normal;
  }
`;

const StyledOrderTableRow = styled(OrderTable.Row)`
  &:hover {
    p {
      color: ${({ theme }) => theme.typo.title};
    }
  }
`;

CRMContractLeadsOverview.propTypes = {
  match: shape({}).isRequired,
  type: string.isRequired,
};

export default CRMContractLeadsOverview;
