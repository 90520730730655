import React, { useState } from 'react';
import styled from 'styled-components';
import { string, node, shape, bool, func, array } from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import MaskedInput from 'react-text-mask';

import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { detect } from 'detect-browser';
import checkmark from '../../assets/images/validation-checkmark.svg';
import errormark from '../../assets/images/validation-error.svg';

const DateInput = ({
  type,
  name,
  children,
  errors,
  touched,
  disabled,
  setFieldTouched,
  setFieldValue,
  customDateMask,
  newPlaceholder,
  dataTestId,
}) => {
  const browser = detect();
  const [focus, setFocus] = useState();
  const [iconRight, setIconRight] = useState('1rem');
  let autoCorrectedDatePipe;
  let placeholder;

  const dateMask = [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  // if (navigator.language.startsWith('en')) {
  //   autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm-dd-yyyy');
  //   placeholder = 'mm-dd-yyyy';
  // } else {
  autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd-mm-yyyy');
  placeholder = 'dd-mm-yyyy';
  // }

  const handleFocus = () => {
    setFocus(true);
    switch (browser.name) {
      case 'safari':
        setIconRight('3.5rem');
        break;
      case '':
        setIconRight('3.5rem');
        break;
      default:
        setIconRight('1rem');
        break;
    }
  };

  return (
    <Container>
      <StyledLabel
        htmlFor={name}
        onFocus={() => handleFocus()}
        onBlur={() => setFocus(false)}
      >
        <Field
          name={name}
          render={({ field }) => {
            return (
              <StandardInput
                {...field}
                mask={customDateMask ? customDateMask : dateMask}
                placeholder={
                  newPlaceholder
                    ? newPlaceholder
                    : placeholder === null
                    ? '\u00A0'
                    : placeholder
                }
                id={name}
                errors={errors[name] ? errors[name] : null}
                touched={touched[name] ? touched[name] : null}
                onChange={e => {
                  setFieldTouched(name, true);
                  setFieldValue(name, e.target.value);
                }}
                type={type}
                pipe={autoCorrectedDatePipe}
                disabled={disabled}
                className="dateInput"
                data-test-id={dataTestId}
              />
            );
          }}
        />
        <span>{children}</span>
        {errors[name] && touched[name] ? (
          <Icon
            focus={focus}
            right={iconRight}
            browser={browser.name}
            src={errormark}
            alt="error"
          />
        ) : touched[name] ? (
          <Icon
            focus={focus}
            right={iconRight}
            browser={browser.name}
            src={checkmark}
            alt="checkmark"
          />
        ) : null}
      </StyledLabel>
      <ErrorContainer className="errorBox">
        <ErrorMss name={name} component="p" className="error" />
      </ErrorContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 11rem;
  flex-shrink: 0;
  flex-grow: 0;
`;

const StyledLabel = styled.label`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column-reverse;

  & > span {
    font-size: 1.4rem;
    transition: 0.2s;
    line-height: 1rem;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.typo.interactive};
    margin-left: 0.5rem;
  }
`;

const StandardInput = styled(MaskedInput)`
  background-color: white;
  border: ${props =>
    props.errors && props.touched
      ? '0.1rem solid red'
      : props.touched
      ? '0.1rem solid #49C562'
      : 'none'};
  border-radius: 0.3rem;
  width: 100%;
  height: 3.5rem;
  padding-left: 1rem;
  font-size: 1.5rem;
  box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: ${({ theme }) => theme.brand.primary};
  }
`;

const ErrorContainer = styled.div`
  height: 1.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.brand.primary};
`;

const ErrorMss = styled(ErrorMessage)`
  color: red;
  font-size: 1.2rem;
  width: 100%;
  line-height: 1;
  margin-top: 1rem;
`;

const Icon = styled.img`
  position: absolute;
  right: ${props => (props.focus ? props.right : '1rem')};
  bottom: 1rem;
  transition: 0.2s;
`;

DateInput.defaultProps = {
  errors: {},
  touched: {},
  disabled: false,
  dataTestId: '',
};

DateInput.propTypes = {
  type: string.isRequired,
  name: string.isRequired,
  children: node,
  errors: shape(),
  touched: shape(),
  values: shape(),
  disabled: bool,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
  customDateMask: array,
  newPlaceholder: string,
  dataTestId: string,
};

export default DateInput;
