/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Layout from '../../LayoutMarket/views/Layout';
import BigInsuranceCard from '../components/BigInsuranceCard';
import CompareListDialUp from '../components/CompareListDialUp';
import { useTranslation } from 'react-i18next';
import { shape } from 'prop-types';

import { getCards } from '../store';
import { addBrowserHistory } from '../../../../store/index';
import { setCurrentStep } from '../../LayoutMarket/store';
import { getLegalData } from '../../../Flow/Legal/store';
import { removeDuplicates } from '../../../../services/objectService';
import { getInsurances } from '../../../../services/insurancesService';
import { useLocation } from 'react-router';

const Legal = ({ match }) => {
  const { params } = match;
  const { t } = useTranslation();
  const legalData = getLegalData();
  setCurrentStep(4);
  const [companyCards, setCompanyCards] = useState([]);
  const [insuranceData, setInsuranceData] = useState({});
  const [loadedItems, setLoadedItems] = useState(
    params.packageId
      ? params.packageId + 1 + (3 - ((params.packageId + 1) % 3))
      : 3,
  );
  const [compareItems, setCompareItems] = useState([]);
  const cards = getCards('legal');

  let insuranceQuotes;
  if (cards?.length > 0) {
    insuranceQuotes = Array.from(
      new Set(
        cards.map(card => ({
          company: card.insurance_company.name,
          insurance: card.insurance_details.name,
        })),
      ),
    );
  }

  insuranceQuotes = removeDuplicates(insuranceQuotes);
  const location = useLocation();
  useEffect(() => {
    addBrowserHistory(location.pathname);
    if (Object.keys(insuranceData) < 1) {
      getInsurances().then(res => setInsuranceData(res));
    }
  }, []);

  useEffect(() => {
    // const companyArray = [];

    // if (cards.length > 0) {
    //   console.log(insuranceCompanies);
    //   insuranceCompanies.map(insuranceCompany => {
    //     companyArray.push({
    //       [insuranceCompany]: cards
    //         .filter(item => item.insurance_company.name === insuranceCompany)
    //         .sort((a, b) => b.score - a.score),
    //     });
    //   });
    // }

    const companyArray = [];
    const insuranceTypes = [];
    if (cards?.length > 0) {
      insuranceQuotes?.forEach(insuranceQuoteName => {
        companyArray?.push({
          [insuranceQuoteName.insurance]: cards
            .filter(
              item =>
                item.insurance_details.name === insuranceQuoteName.insurance &&
                item.insurance_company.name === insuranceQuoteName.company,
            )
            .sort((a, b) => b.score - a.score),
        });
      });
    }

    // make list of all different types of car insurances
    companyArray?.forEach(companyArr => {
      Object.keys(companyArr).forEach(insuranceName => {
        insuranceTypes.push({
          insurer: companyArr[insuranceName][0].insurance_company.name,
          type: companyArr[insuranceName][0].insurance_details.type,
        });
      });
    });

    const uniqueInsuranceTypes = removeDuplicates(insuranceTypes);

    // combine insurances based on the different types
    const filteredCompanyArray = uniqueInsuranceTypes?.map(typesList => {
      const matchingCompanies = [];
      const tmpObj = {};
      companyArray?.forEach(insurancePack => {
        const insuranceTitle = Object.keys(insurancePack)[0];
        const packs = insurancePack[insuranceTitle];
        tmpObj[insuranceTitle] = [];
        packs?.forEach(pack => {
          if (
            pack.insurance_company.name === typesList.insurer &&
            pack.insurance_details.type === typesList.type
          ) {
            tmpObj[insuranceTitle].push(pack);
          } else {
            delete tmpObj[insuranceTitle];
          }
        });
      });
      Object.keys(tmpObj).forEach(key => {
        matchingCompanies.push({ [key]: tmpObj[key] });
      });

      return matchingCompanies;
    });

    setCompanyCards(filteredCompanyArray);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (companyCards?.length > 0) {
      if (params?.packageName) {
        if (
          document.querySelector(`.${params.packageName}${params.packageId}`)
        ) {
          setTimeout(() => {
            document
              .querySelector(`.${params.packageName}${params.packageId}`)
              .scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 350);
        }
      }
    }
  }, [companyCards]);

  const addToCompareItems = insuranceItem => {
    if (compareItems?.length === 0) {
      // store first item in array
      setCompareItems([insuranceItem]);
    } else {
      // check if id is already in array, if not store it
      const doesIdExist = compareItems?.some(
        ({ id }) => id === insuranceItem.id,
      );
      !doesIdExist ? setCompareItems([...compareItems, insuranceItem]) : null;
    }
  };

  const removeCompareItem = id => {
    setCompareItems(compareItems?.filter(obj => obj.id !== id));
  };

  /* eslint-disable array-callback-return */
  return (
    <Layout noMatches={cards?.length === 0}>
      <Header>
        <h1>{t('MarketLegal.title')}</h1>
        <p>{t('MarketLegal.text')}</p>
      </Header>
      <List className="bigInsuranceCardList">
        {companyCards?.slice(0, loadedItems).map(([companyObject], i) => {
          if (companyObject) {
            const companyName = Object.keys(companyObject)[0];
            return (
              <BigInsuranceCard
                cardType={t('MarketFamily.insurType')}
                key={companyName}
                selectedPacks={params.selectedPackages}
                isSelected={parseInt(params.packageId) === i}
                name={companyName}
                insuranceCompanies={insuranceData}
                packs={companyObject[companyName]}
                coverage="legal"
                pdfs={companyObject[companyName][0].insurance_details}
                compare={res => addToCompareItems(res)}
                disableCompareItems={compareItems.length >= 5}
                dataType={legalData}
                id={i}
              >
                {t('Coverages.legal')}
              </BigInsuranceCard>
            );
          }
        })}
        {cards?.length === 0 ? (
          <NoMatchesError>{t('MarketLayout.noMatches')}</NoMatchesError>
        ) : null}
        {companyCards?.length > loadedItems ? (
          <Button onClick={() => setLoadedItems(loadedItems + 3)}>
            {t('MarketFamily.loadTxt')}
          </Button>
        ) : null}
      </List>
      <CompareListDialUp
        hidden={compareItems?.length === 0}
        compareItems={compareItems}
        removeId={id => removeCompareItem(id)}
        insurType="legal"
      />
    </Layout>
  );
};

Legal.propTypes = {
  match: shape().isRequired,
};

const NoMatchesError = styled.h3`
  font-size: 3rem;
  color: white;
  text-align: left;
  font-weight: bold;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const Button = styled.button`
  background-color: white;
  width: 70%;
  height: 5.1rem;
  border-radius: 0.5rem;
  display: flex;
  font-size: 2rem;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.brand.primary};
  border: 0;
  font-weight: bold;
  text-decoration: none;
  align-self: flex-end;
  margin: 2rem auto;
  z-index: 11;
  cursor: pointer;
  transition: all 0.2s ease 0s;

  &:active {
    outline: none;
    transform: scale(0.98);
  }

  &:focus {
    border: 0 0 0;
    outline: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.brand.secondary};
    color: white;
    box-shadow: 0 0 0.35rem 0 rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.brand.lighter};
  }

  @media screen and (max-width: 1025px) {
    transform: scale(0.9);
    margin-bottom: 1rem;

    &:active {
      transform: scale(0.88);
    }
  }
`;

const List = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: all 0.1s ease 0s;
  align-items: center;

  @media print {
    &.hidePrint {
      display: none;
    }
  }
`;
const Header = styled.header`
  color: white;
  font-size: 1.6rem;
  margin-bottom: 5rem;
  line-height: 2rem;

  & h1 {
    font-weight: 900;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
`;

// const CardContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;

//   & article:nth-child(3) {
//     order: -1;
//   }

//   & article:nth-child(1) {
//     transform: scale(${props => (props.selected ? '' : 1.1)})
//   }
// `;

// const SelectedContainer = styled.div`
//   margin-top: 4rem;
// `;

export default Legal;
