import React from 'react';
import { ActionButton, Modal, TertiaryButton } from 'wg-fe-ui';
import styled from 'styled-components';
import { array, shape, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

const CRMExportModal = ({ modalState, data, proceedExport, type }) => {
  // const [validationSchema, setValidationSchema] = useState();
  const { t } = useTranslation();

  const [showModal, setShowModal] = modalState;

  function handleClick(event) {
    setShowModal(!showModal);
    proceedExport();
  }

  return (
    <Modal
      canClose
      modalElementClass="modal-full-width"
      showModal={showModal}
      setShowModal={setShowModal}
      title={t(type + ' has already been exported')}
      width={'100%'}
    >
      <Container>
        <Body>{t('Are you sure you would like to export it again')}</Body>
        <Modal.ModalActions position="center">
          <CancelButton
            // level="secondary"
            label={t('No')}
            onClick={() => setShowModal(!showModal)}
          >
            {t('No')}
          </CancelButton>
          <ActionButton level="primary" onClick={() => handleClick()}>
            {t('Yes')}
          </ActionButton>
        </Modal.ModalActions>
      </Container>
    </Modal>
  );
};

const Body = styled.div`
  text-align: center;
`;

const CancelButton = styled(TertiaryButton)`
  background: #f0f1f3;
  border-radius: 0.5rem;
  min-width: 14rem;
  height: 4rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100000;
`;

CRMExportModal.propTypes = {
  modalState: array,
  data: shape(),
  proceedExport: func,
  type: string,
};

export default CRMExportModal;
