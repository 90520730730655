/* eslint-disable no-case-declarations */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AsyncSelect from 'react-select/async';

import { func, bool, string } from 'prop-types';

import { useDebouncedCallback } from 'use-debounce';
import { searchAddress } from '../services/apiRouterService';
import { useTranslation } from 'react-i18next';
import { cleanAddressData } from '../models/Flow/Woning/store';
// import { retrieveStorageById } from '../helpers/storeService';

const FlowAddressInput = ({
  // setDisabled,
  addressValues,
  disabled,
  //   errors,
  fullAddressError,
  className,
  title,
}) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState();
  const [isDisabled, setIsDisabled] = useState(disabled);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(fullAddressError);

  const handleChange = _selected => {
    if (_selected) {
      setSelected(_selected);
      setIsDisabled(true);
      setError('');
      setSelected({});
      addressValues({
        postalCode: _selected.value.postalCode,
        municipalityName: _selected.value.municipalityName,
        streetName: _selected.value.streetName,
        streetNumber: _selected.value.streetNumber,
        boxNumber: _selected.value.boxNumber,
      });
    }
  };

  const fetchResults = async (value, callback) => {
    if (hasNumber(value)) {
      setLoading(true);
      const [res, status] = await searchAddress(value);
      if (status !== 200) return callback([]);
      setLoading(false);
      callback(cleanAddressData(res));
    }
  };

  const [debounceFetchResults] = useDebouncedCallback(fetchResults, 500);

  const hasNumber = myString => {
    return /\d/.test(myString);
  };

  useEffect(() => {
    setError(fullAddressError);
  }, [fullAddressError]);

  const handleFullAddressValidation = () => {
    if (!selected) {
      setError(t(`required`));
    }
  };

  return (
    <Container>
      <Label>
        {!isDisabled
          ? title
            ? `${title}`
            : `${t('Fill in your address')}`
          : null}
        <Input
          isDisabled={isDisabled}
          onChange={e => handleChange(e)}
          loadOptions={debounceFetchResults}
          onBlurResetsInput={false}
          onBlur={() => handleFullAddressValidation()}
          error={error}
          isClearable
          menuPlacement="top"
          loadingMessage={() =>
            loading
              ? t('Searching addresses') + '...'
              : t('Street, number and municipality')
          }
          noOptionsMessage={() => t('No results')}
          classNamePrefix="Select"
          value={selected}
          placeholder={t('Street, number and municipality')}
        >
          {title ? title : t('Fill in your address')}
        </Input>
        <ErrorContainer>
          <p>{error}</p>
        </ErrorContainer>
      </Label>
    </Container>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.status.error};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${({ theme }) => theme.font};
  height: 9rem;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  transition: 0.2s;
  line-height: 1rem;
  color: ${({ theme, disabled }) =>
    disabled ? theme.ui.disabled : theme.typo.subTitle};
`;

const Input = styled(AsyncSelect)`
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  justify-self: center;

  span {
    border-color: ${({ theme, hasValue }) =>
      hasValue ? theme.brand.primary : `#ccc`};
  }

  .Select {
    &__indicators {
      height: 4rem;
      min-height: 4rem;
    }

    &__control {
      height: 4rem;
      min-height: 4rem;
      border-radius: 0.3rem;
      border-color: ${({ theme, hasValue, error }) =>
        error ? 'red' : hasValue ? theme.brand.primary : `#ccc`};
      z-index: 999;

      :hover {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
      }

      &--is-focused {
        border-color: ${({ theme, hasValue }) =>
          hasValue ? theme.brand.primary : `#ccc`};
        box-shadow: none;

        :hover {
          border-color: ${({ theme, hasValue }) =>
            hasValue ? theme.brand.primary : `#ccc`};
        }
      }
    }

    &__value-container {
      padding: 0 1em;
      font-family: 2rem;
      font-size: 1.8rem;
      position: static;
      /* text-transform: uppercase; */
      z-index: 999;

      @media screen and (max-width: 500px) {
        font-size: 1.5rem;
      }
    }

    &__single-value {
      text-transform: capitalize;
      color: ${({ theme }) => theme.brand.primary};
    }

    &__menu {
      font-size: 1.8rem;
      margin-top: 5rem;
      z-index: 999;
      position: relative;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      z-index: 999;
      line-height: 2.1rem;

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-focused {
        background-color: ${({ theme }) => theme.brand.lighter};
      }

      &--is-selected {
        background: white;
        color: #292929;
      }
    }
  }
`;

FlowAddressInput.propTypes = {
  setDisabled: func,
  addressValues: func,
  disabled: bool,
  errors: func,
  fullAddressError: string,
  clickedSubmit: bool,
  className: string,
  title: string,
};

export default FlowAddressInput;
