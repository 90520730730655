/* eslint-disable no-unused-expressions */
import ApiRoutes from '../../../../store/ApiRoutes';
import MockData from '../../../../../assets/mock/car.json';
import { getNestedObject } from '../../../../services/objectService';
// import MockData from '../../../../../assets/mock/family.json';

const api = new ApiRoutes();

let fireData;
let peopleData;
let carData;
let legalData;
let fireName = 'Brandverzekering';
let fireNotice;
let peopleNotice;
let carNotice;
let legalNotice;
let specific_needs;

const insurances = {
  insurances: [],
};

let signatureData = {};
// let comparisonList = [];

const getSignatureData = () => signatureData;

const setSignatureData = payload => {
  signatureData = payload;
};

const getCards = insurType => {
  let content;
  switch (insurType) {
    case 'fire':
      content = fireData;
      break;
    case 'family':
      content = peopleData;
      break;
    case 'car':
      content = carData;
      break;
    case 'legal':
      content = legalData;
      break;
    default:
      content = peopleData;
      break;
  }

  if (process.env.NODE_ENV === `development` && content === undefined) {
    content = MockData;
  }

  if (content === undefined || content === null) {
    content = {
      matches: [],
    };
  }
  return content.matches;
};

const resetMatchData = () => {
  fireData = null;
  peopleData = null;
  carData = null;
  legalData = null;
};

const setMatchData = (insurType, data) => {
  switch (insurType) {
    case 'fire':
      fireData = data;
      break;
    case 'family':
      peopleData = data;
      break;
    case 'car':
      carData = data;
      break;
    case 'legal':
      legalData = data;
      break;
    default:
      peopleData = data;
      break;
  }
};

const getPrice = (id, ck, vk, franchise, insuredValues, paymentInterval) => {
  const payload = {
    insurances: [
      {
        id,
        options: {
          currency: 'EUR',
          payment_interval: 'annual',
          save_quote: true,
        },
      },
    ],
  };

  if (paymentInterval)
    payload.insurances[0].options.payment_interval = paymentInterval;
  if (ck) payload.insurances[0].options.ck = ck;
  if (vk) payload.insurances[0].options.vk = vk;

  if (getNestedObject(insuredValues, ['insured_value']))
    payload.insurances[0].options.insured_value = parseFloat(
      insuredValues.insured_value,
    ).toFixed(2);
  if (getNestedObject(insuredValues, ['insured_content_value']))
    payload.insurances[0].options.insured_content_value = parseFloat(
      insuredValues.insured_content_value,
    ).toFixed(2);

  if (franchise && Object.entries(franchise).length !== 0)
    payload.insurances[0].options.franchise = franchise;

  return api.postPricing(payload);
};

const getMatchData = insurType => {
  return api.getMatchingScores(insurType).catch(() => {
    // window.location.href = '/dashboard?popup=error';
  });
};

const retrieveAcceptances = payload => {
  return api.getAcceptances(payload);
};

const retrieveRequirements = (insureType, id) => {
  const payload = {
    chosen_id: id,
  };
  return api.retrieveInformationRequirements(insureType, payload);
};

const setOldInsurance = (type, data) => {
  if (type === 1) {
    fireNotice = data;
  } else if (type === 2) {
    peopleNotice = data;
  } else if (type === 3) {
    carNotice = data;
  } else if (type === 4) {
    legalNotice = data;
  } else {
    null;
  }
};

const getOldInsurance = type => {
  if (type === 1) {
    return fireNotice;
  } else if (type === 2) {
    return peopleNotice;
  } else if (type === 3) {
    return carNotice;
  } else if (type === 4) {
    return legalNotice;
  } else {
    return null;
  }
};

const addInsurance = (
  id,
  year,
  day,
  month,
  inusuranceData,
  specific_needs,
  sendToProspect = true,
  acknowledgements = [],
) => {
  const insuranceObj = {
    id,
    send_mail_to_prospect: sendToProspect,
    start_date: {
      year: year,
      day: day,
      month: month,
    },
    specific_needs,
    notice: inusuranceData,
    acknowledgements,
  };

  if (
    inusuranceData === '' ||
    inusuranceData === undefined ||
    inusuranceData === null ||
    Object.entries(inusuranceData).length === 0
  )
    delete insuranceObj.notice;

  insurances.insurances.push(insuranceObj);
};

const clearInsurances = () => {
  insurances.insurances = [];
};

const sendCheckout = () => api.postCheckout(insurances);

const getFireName = () => fireName;

const setFireName = name => {
  fireName = name;
};

const setSpecificNeeds = needs => {
  specific_needs = needs;
};

const getSpecificNeeds = () => specific_needs;

let comChecked = false;

const setComChecked = val => {
  comChecked = val;
};

const getComChecked = () => comChecked;

let privacyChecked = false;

const setPrivacyChecked = val => {
  privacyChecked = val;
};

const getPrivacyChecked = () => privacyChecked;

let termsChecked = false;

const setTermsChecked = val => {
  termsChecked = val;
};

const getTermsChecked = () => termsChecked;

const getCompareMatrix = (insurType, idArr) =>
  api.postCompareMatrix(insurType, { insurances: idArr });

const getPolicyPropPdf = async id => {
  const { data } = await api.getPolicyPropositionPdf(id);
  const { link, filename } = data;
  const downloadLink = document.createElement('a');
  downloadLink.href = link;
  downloadLink.download = filename;
  downloadLink.click();
  return;
};

export {
  getCards,
  getMatchData,
  setMatchData,
  retrieveAcceptances,
  sendCheckout,
  addInsurance,
  getOldInsurance,
  setOldInsurance,
  clearInsurances,
  getSignatureData,
  setSignatureData,
  getFireName,
  setFireName,
  getPrice,
  setComChecked,
  getComChecked,
  setPrivacyChecked,
  getPrivacyChecked,
  setTermsChecked,
  resetMatchData,
  getTermsChecked,
  getCompareMatrix,
  retrieveRequirements,
  setSpecificNeeds,
  getSpecificNeeds,
  getPolicyPropPdf,
};
