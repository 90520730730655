import React from 'react';
import { number, func, string } from 'prop-types';
// import styled from 'styled-components';

const SingleInputDate = ({
  inputtype,
  value,
  id,
  maxLength,
  onChange,
  placeholder,
  onBlur,
  className,
}) => (
  <input
    type="text"
    id={id}
    autoComplete="off"
    className={className}
    inputtype={inputtype}
    value={value}
    onFocus={e => {
      e.target.setSelectionRange(0, e.target.getAttribute('maxlengthvalue'));
    }}
    onBlur={e => {
      onBlur(e);
    }}
    maxlengthvalue={maxLength}
    min={1}
    onChange={onChange}
    placeholder={placeholder}
  />
);

// const Input = styled.input`
//   &:focus {
//     outline: none;
//   }
// `;

SingleInputDate.defaultProps = {
  value: '',
  id: '',
};

SingleInputDate.propTypes = {
  inputtype: string.isRequired,
  value: string,
  maxLength: number.isRequired,
  className: string.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  maxValue: number.isRequired,
  placeholder: string.isRequired,
  id: string,
};

export default SingleInputDate;
