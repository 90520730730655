import { getNestedObject } from '../../../../services/objectService';

// import routes from '../../../../store/content.json';

let currentStep = 1;
let subStep = 0;
let skippedSteps = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
};

const cart = {};

// Checkout cart

const getCartSize = () => {
  return Object.keys(cart).length;
};

const getCart = () => cart;

const getCheckout = category => {
  return cart[category];
};

const updateCard = ({ type, payment_interval }) => {
  cart[type].details.payment_interval = payment_interval;
};

const getCheckoutPriceData = category =>
  getNestedObject(cart, [category, 'priceData']) || {};

const addToCheckout = (category, insurance, priceData) => {
  cart[category] = insurance;
  if (priceData) {
    cart[category].priceData = priceData;
  } else {
    cart[category].priceData = {};
  }
};

const removeFromCheckout = category => {
  if (cart[category]) {
    delete cart[category];
  }
};

// Step Manager
const setCurrentStep = value => {
  currentStep = value;
};
const getCurrentStep = () => currentStep;

const getSubStep = () => subStep;

const getSkippedSteps = () => skippedSteps;

const resetSkippedSteps = () => {
  skippedSteps = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  };
};

const setSkippedSteps = (step, bool) => {
  skippedSteps[step] = bool;
};
// API calls
const getUserId = () => sessionStorage.getItem('id');

const getIconData = () => 'car';

export {
  getUserId,
  getCurrentStep,
  setCurrentStep,
  getSubStep,
  getSkippedSteps,
  setSkippedSteps,
  getIconData,
  updateCard,
  getCheckout,
  addToCheckout,
  removeFromCheckout,
  resetSkippedSteps,
  getCartSize,
  getCart,
  getCheckoutPriceData,
};
