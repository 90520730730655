import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import Section from './Section';
import styled from 'styled-components';
import { getNestedObject } from '../services/objectService';
import DashboardCheckboxWithIcon from './DashboardCheckboxWithIcon';
import LoadingExtentionIconsByType from '../models/Marketplace/Market/components/LoadingExtentionIconsByType';
import { getCurrentTheme } from '../store';
import { useTranslation } from 'react-i18next';
import DashboardBrokerHistoryPoliciesPriceOverview from './DashboardBrokerHistoryPoliciesPriceOverview';
import DownloadBox from './DownloadBox';

const DashboardBrokerHistoryPoliciesSection = ({ policiesData, title }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const tmpOptions = getNestedObject(
      policiesData,
      ['policy', 'quote', 'options'],
      [],
    );

    if (tmpOptions !== options) {
      setOptions(tmpOptions);
    }
  }, [policiesData]);

  return (
    <Section>
      <Section.Subtitle>{title}</Section.Subtitle>
      <Section.Content>
        <Header>
          <Section.Split>
            <Section.SplitContent>
              <Label>{t('Insurance company')}</Label>
              <HeaderText>
                {getNestedObject(
                  policiesData,
                  ['policy', 'insurance', 'company'],
                  '--',
                ).toUpperCase()}
              </HeaderText>
            </Section.SplitContent>
            <Section.SplitContent>
              <Label>{t('Insurance name')}</Label>
              <HeaderText>
                {getNestedObject(
                  policiesData,
                  ['policy', 'insurance', 'name'],
                  '--',
                )}
              </HeaderText>
            </Section.SplitContent>
          </Section.Split>
        </Header>
        {getNestedObject(policiesData, [
          'policy',
          'premium',
          'insured_value',
          'value',
        ]) && getNestedObject(policiesData, ['policy', 'type']) !== 'car' ? (
          <Section.Split>
            <Section.SplitContent>
              <SubTitle>{t('insured value')}</SubTitle>
              <Value>
                €
                {getNestedObject(
                  policiesData,
                  ['policy', 'premium', 'insured_value', 'value'],
                  0,
                ).toLocaleString('nl-BE')}
              </Value>
            </Section.SplitContent>
            <Section.SplitContent>
              <SubTitle>{t('insured content value')}</SubTitle>
              <Value>
                €
                {getNestedObject(
                  policiesData,
                  ['policy', 'premium', 'insured_content_value', 'value'],
                  0,
                ).toLocaleString('nl-BE')}
              </Value>
            </Section.SplitContent>
          </Section.Split>
        ) : null}
        {getNestedObject(policiesData, ['type']) === 'car' ? (
          <Section.Split>
            {getNestedObject(policiesData, [
              'policy',
              'premium',
              'bonus_malus',
            ]) ? (
              <Section.SplitContent>
                <SubTitle>{t('Bonus malus')}</SubTitle>
                <Value>
                  {getNestedObject(policiesData, [
                    'policy',
                    'premium',
                    'bonus_malus',
                  ])}
                </Value>
              </Section.SplitContent>
            ) : null}
            {getNestedObject(policiesData, [
              'policy',
              'premium',
              'franchise',
            ]) ? (
              <Section.SplitContent>
                <SubTitle>{t('Franchise')}</SubTitle>
                <Value>
                  {`${getNestedObject(policiesData, [
                    'policy',
                    'premium',
                    'franchise',
                    'value',
                  ])} ${getNestedObject(
                    policiesData,
                    ['policy', 'premium', 'franchise', 'type'],
                    '',
                  )}`}
                </Value>
              </Section.SplitContent>
            ) : null}
          </Section.Split>
        ) : null}
        <Section.Split style={{ display: 'inline-block' }}>
          <SubTitle>{t('Options')}</SubTitle>
          <Options>
            {options.map((option, key) => {
              return (
                <DashboardCheckboxWithIcon
                  key={key}
                  wide={3.5}
                  text={t(`anon_pack_types.${option.name}`)}
                  checked={true}
                  disabled={true}
                >
                  <LoadingExtentionIconsByType
                    type={option.name}
                    color={getCurrentTheme().brand.primary}
                  />
                </DashboardCheckboxWithIcon>
              );
            })}
          </Options>
        </Section.Split>
        <Line />
        <Section.Split>
          <Section.SplitContent>
            <SubTitle>{t('Price Overview')}</SubTitle>
            <DashboardBrokerHistoryPoliciesPriceOverview
              policiesData={policiesData}
            />
          </Section.SplitContent>
          <Section.SplitContent>
            <SubTitle>{t('Documents')}</SubTitle>
            {Object.keys(
              getNestedObject(policiesData, ['policy', 'policy_documents'], {}),
            ).map((docKey, key) => {
              const document = getNestedObject(
                policiesData,
                ['policy', 'policy_documents'],
                {},
              )[docKey];
              return (
                <StyledDownloadBox
                  key={key}
                  filename={document.filename}
                  href={document.link}
                  thumbnail={document.base64 ? document.base64 : null}
                />
              );
            })}
          </Section.SplitContent>
        </Section.Split>
      </Section.Content>
    </Section>
  );
};

const StyledDownloadBox = styled(DownloadBox)`
  margin-bottom: 0.7rem;
`;

const Value = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 2.5rem;
  color: ${({ theme }) => theme.typo.highlight};
`;

const Label = styled.label`
  font-size: 1.4rem;
  line-height: 1.5;
  color: #a29c95;
`;

const SubTitle = styled.h2`
  text-transform: uppercase;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.typo.highlight};
  margin-bottom: 1.5rem;
`;

const Options = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 2.5rem;
  }
`;

const HeaderText = styled.p`
  margin-top: 0.3rem;
  font-weight: bold;
  font-size: 2.1rem;
  line-height: 1.5;
  color: #050505;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  line-height: 2.7rem;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.ui.background};
`;

const Line = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.ui.background};
  margin-bottom: 2rem;
`;

DashboardBrokerHistoryPoliciesSection.defaultProps = {
  string: '',
};

DashboardBrokerHistoryPoliciesSection.propTypes = {
  policiesData: shape().isRequired,
  title: string,
};

export default DashboardBrokerHistoryPoliciesSection;
