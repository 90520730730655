import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { auth } from '../store/index';
import { func, string } from 'prop-types';

const PrivateSessionRouteHoc = ({ component: Component, to, ...rest }) => {
  if (auth.isAuthenticated) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  return <Route {...rest} render={() => <Redirect to={`/${to}`} />} />;
};

PrivateSessionRouteHoc.propTypes = {
  component: func.isRequired,
  to: string.isRequired,
};

export default PrivateSessionRouteHoc;
