import { setMatchData } from '../models/Marketplace/Market/store';
import { getAllCoverages, setCoverage, generateId } from '../store';
import ApiRoutes from '../store/ApiRoutes';
import cogoToast from 'cogo-toast';
import {
  resetSkippedSteps,
  removeFromCheckout,
} from '../models/Marketplace/LayoutMarket/store';
import { setCarValue, addDataForIdCar } from '../models/Flow/Car/store';
import { getNestedObject } from './objectService';

const api = new ApiRoutes();

export const setTabItems = (id, sessionData, t) => {
  if (sessionData) {
    const navTabs = [];
    navTabs.push({
      title: t('Personal'),
      to: `/broker-history/${id}/personal`,
      testId: `history_detail_personal_nav`,
    });
    if (sessionData.home)
      navTabs.push({
        title: t('Property'),
        to: `/broker-history/${id}/property`,
        testId: `history_detail_property_nav`,
      });
    if (sessionData.family)
      navTabs.push({
        title: t('Liability'),
        to: `/broker-history/${id}/liability`,
        testId: `history_detail_liability_nav`,
      });
    if (sessionData.car)
      navTabs.push({
        title: t('Automotive'),
        to: `/broker-history/${id}/automotive`,
        testId: `history_detail_automotive_nav`,
      });
    if (sessionData.legal)
      navTabs.push({
        title: t('legal aid'),
        to: `/broker-history/${id}/legal`,
        testId: `history_detail_legal_nav`,
      });
    if (sessionData.policy_contracts.length > 0)
      navTabs.push({
        title: t('Policies'),
        to: `/broker-history/${id}/policies`,
        testId: `history_detail_policies_nav`,
      });
    return navTabs;
  }
};

export const gotoMatches = (types, history) => {
  resetSkippedSteps();

  if (types.includes('home')) {
    history.push('/review/market/fire');
  } else if (types.includes('family')) {
    history.push('/review/market/family');
  } else if (types.includes('car')) {
    history.push('/review/market/car');
  } else {
    history.push('/review/market/legal');
  }
};

export const getHistory = (
  id,
  filteredInsureTypes,
  history,
  t,
  sessionData,
) => {
  console.log(t('Loading your data'));
  const closeLoading = cogoToast.loading(t('Loading your data'), {
    hideAfter: 0,
  });
  sessionStorage.setItem('sessionId', id);
  generateId().then(resp => {
    api.getSession(id).then(() => {
      Promise.all(
        filteredInsureTypes.map(insurType => {
          if (insurType === 'car') {
            addDataForIdCar(resp.id);
            removeFromCheckout('fire');
            removeFromCheckout('people');
            removeFromCheckout('car');
            removeFromCheckout('legal');
            setCarValue(
              'primary_driver',
              getNestedObject(sessionData, ['car', 'primary_driver']),
            );
          }
          return api.getMatchingScores(insurType, id);
        }),
      )
        .then(res => {
          res.forEach((obj, i) => {
            setMatchData(
              filteredInsureTypes[i] === `home`
                ? `fire`
                : filteredInsureTypes[i],
              obj.data,
            );
          });
          closeLoading();
          cogoToast.success(t('Data Successfully Loaded'));
          gotoMatches(filteredInsureTypes, history);
        })
        .catch(err => {
          closeLoading();
          console.error(err);
          cogoToast.error(t('Something went wrong while retrieving your data'));
        });
    });
  });
};

const filterSessionData = sessionData => {
  const insurTypes = ['home', 'family', 'car', 'legal'];
  Object.keys(getAllCoverages()).forEach(coverage => {
    if (sessionData[coverage]) {
      setCoverage(coverage, true);
    } else {
      setCoverage(coverage, false);
    }
  });
  return insurTypes.filter(t => getAllCoverages()[t]);
};

export const setInsurTypes = id => {
  const sessionData = JSON.parse(sessionStorage.getItem(`sessionData_${id}`));
  if (sessionData)
    return new Promise(resolve => {
      resolve({ filteredData: filterSessionData(sessionData), sessionData });
    });

  return api.getSession(id).then(({ data }) => {
    sessionStorage.setItem(`sessionData_${id}`, JSON.stringify(data));
    return { filteredData: filterSessionData(data), sessionData: data };
  });
};
