import React from 'react';
// import React from 'react';
import { ToggleInput } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { string, func, shape } from 'prop-types';
const ProFlowInputTypeCheckBox = ({
  value,
  name,
  handleChange,
  enums,
  title,
  ...otherProps
}) => {
  const { t } = useTranslation();

  // useEffect(() => {
  //   handleChange({ name, value: false });
  // }, []);

  return (
    <>
      <Label>{title}</Label>
      <StyledToggleInput
        name={name}
        onChange={({ checked }) => {
          handleChange({ name, value: checked });
        }}
        checked={value}
        falseLabel={t('No')}
        trueLabel={t('Yes')}
        {...otherProps}
      />
    </>
  );
};

const Label = styled.label`
  color: #8990a7;
  font-weight: 600;
  font-size: 1.4rem;
`;

const StyledToggleInput = styled(ToggleInput)`
  padding: 1.6rem 0 0;
  margin-bottom: 2rem;
`;

ProFlowInputTypeCheckBox.propTypes = {
  enums: shape().isRequired,
  error: string,
  name: string.isRequired,
  handleChange: func.isRequired,
  value: string,
  title: string,
};

export default ProFlowInputTypeCheckBox;
