import React, { useEffect, useState, createRef, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SubNavigation, BackButton, ActionButton } from 'wg-fe-ui';
import { Section, Text, Label } from 'wg-fe-ui';
import { getMeData } from '../../services/meDataService';
import { getBrokerData } from '../../services/brokerDataService';
import { getRiskObjectByTypeAndId } from '../../services/apiRouterService';
import Frame from '../../../assets/images/Frame.svg';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';

const CRMRiskObjectDetailLegal = () => {
  const configCatClient = useContext(FlagsProvider);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const refs = {};
  const [legalData, setLegalData] = useState();
  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });
  const [isCallantFeature, setIsCallantFeature] = useState();
  const [isProFlowEnabled, setIsProfFlowEnabled] = useState();
  const {
    id: riskObjectId,
    offerId,
    contractCaseId,
    contractId,
    prospectId,
    leadId,
    offerRevision,
    revisionId,
  } = useParams();

  useEffect(() => {
    retrieveLegalData();
    setLegalData({
      family_situation: 'couple',
      number_of_vehicles: 2,
      has_underage_children: false,
      previous_insurance: null,
      type: 'legal',
    });
    setFeatureToggles();
  }, []);

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    const emailConfig = {
      email: me?.email,
      custom: {
        plan: broker?.plan,
      },
    };

    setIsCallantFeature(
      await configCatClient.getValueAsync('callantFeature', true, emailConfig),
    );

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  async function retrieveLegalData() {
    const { distribution_id } = await getBrokerData();
    const [resp, status] = await getRiskObjectByTypeAndId(
      distribution_id,
      'legal',
      riskObjectId,
      revisionId,
    );

    if (status !== 200) {
      if (status !== 404) {
        return console.error(resp);
      }
      console.log('FAILED TO LOAD');
      setLegalData(null);
      return;
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  });

  const [sections] = useState([
    {
      id: 1,
      label: `${t('Risk object')} - ${t('Legal')}`,
      subsections: [
        {
          id: 1,
          label: t('Legal information'),
        },
      ],
    },
  ]);

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const startNewTarrification = () => {
    history.push({
      pathname: `/professional-flow/new-tarrification-prospect/`,
      search: `?prospectId=${prospectId}`,
      state: { type: 'legal', riskObjectId },
    });
  };

  const backButtonHandler = () => {
    let pathname = '';
    if (prospectId && offerId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`;
    } else if (prospectId && contractId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`;
    } else if (prospectId && leadId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/lead/${leadId}`;
    } else if (prospectId) {
      pathname = `/sales-management/customer/${prospectId}`;
    } else if (contractId) {
      pathname = `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    } else if (leadId) {
      pathname = `/sales-management/lead/${leadId}`;
    } else {
      pathname = `/sales-management/offer/${offerId}/${offerRevision}`;
    }
    history.push({
      pathname,
      state: { ...location.state },
    });
  };

  if (legalData == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Risk object not found')}</h1>
        <p>
          {`${t('We could not find the risk object with id')}: `}{' '}
          <strong>{riskObjectId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton onClick={backButtonHandler} name={t('Previous page')} />
          </TopBarLeft>
          {!isCallantFeature && (
            <TopBarRight>
              <TopBarAction>
                <ActionButton
                  onClick={startNewTarrification}
                  disabled={!isProFlowEnabled}
                >
                  {t('New tarification')}
                </ActionButton>
              </TopBarAction>
            </TopBarRight>
          )}
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
        </Sidebar>

        <Content>
          <section ref={refs[1][1]}>
            <Section>
              <Section.Title>{t('Legal information')}</Section.Title>
              <Section.Content background>
                <SectionMain>
                  <SectionMainItem>
                    <Label>{t('Family situation')}</Label>
                    <StyledText>{legalData?.family_situation}</StyledText>
                    <Label>{t('Number of vehicles')}</Label>
                    <StyledText>{legalData?.number_of_vehicles}</StyledText>
                  </SectionMainItem>
                  <SectionMainItem>
                    <Label>{t('Underage children')}</Label>
                    <StyledText>
                      {legalData?.has_underage_children
                        ? t('Does have underage children')
                        : t(`Doesn't have underage children`)}
                    </StyledText>
                  </SectionMainItem>
                </SectionMain>
              </Section.Content>
            </Section>
          </section>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const StyledText = styled(Text)`
  margin-top: 0.5rem;
  margin-bottom: 2rem;
`;

const SectionItem = styled.div`
  width: 50%;
`;

const SectionMainItem = styled(SectionItem)`
  margin-top: 2rem;
`;

const SectionMain = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 99999;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    margin-right: 0.78vw;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

export default CRMRiskObjectDetailLegal;
