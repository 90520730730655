/* stylelint-disable value-keyword-case, declaration-no-important */
import React, { useState, useEffect, useContext, createRef } from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import Layout from '../../DashboardLayout/views/Layout';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';
import ToolkitRequiredInformationGenerator from './ToolkitRequiredInformationGenerator';
import ToolkitTerminationGenerator from './ToolkitTerminationGenerator';
import ToolkitCustomerSheetGenerator from './ToolkitCustomerSheetGenerator';
import { getBrokerMemberships } from '../../../services/brokerDataService';
import { useTranslation } from 'react-i18next';
import { SubNavigation } from 'wg-fe-ui';

const ToolkitDocuments = () => {
  const { t } = useTranslation();
  const { setNavTabs } = useContext(TopNavigationItems);
  const refs = {};
  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });
  const [isFvfMember, setIsFvfMember] = useState(false);
  const [sections, setSections] = useState([
    {
      id: 1,
      label: t('Documents generator'),
      subsections: [
        {
          id: 1,
          label: t('Termination letter'),
        },
        {
          id: 2,
          label: t('Information requirement sheet'),
        },
      ],
    },
  ]);

  useEffect(() => {
    setNavTabs([
      {
        title: t('Car lookup'),
        testId: 'toolkit_car_lookup_nav',
        to: '/toolkit/car-lookup',
      },
      {
        title: t('Address lookup'),
        testId: 'toolkit_address_lookup_nav',
        to: '/toolkit/address-lookup',
      },
      {
        title: t('Documents generator'),
        testId: 'toolkit_documents_generator_nav',
        to: '/toolkit/documents-generator',
      },
    ]);

    getFvfMembership();
  }, []);

  async function getFvfMembership() {
    const { fvf } = (await getBrokerMemberships()) || {};
    setIsFvfMember(fvf);

    if (fvf) {
      setSections([
        {
          id: 1,
          label: t('Documents generator'),
          subsections: [
            {
              id: 1,
              label: t('Termination letter'),
            },
            {
              id: 2,
              label: t('Information requirement sheet'),
            },
            {
              id: 3,
              label: t('Customer sheet'),
            },
          ],
        },
      ]);
    }
  }

  const handleClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;
    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  sections.forEach(s => {
    refs[s.id] = {};
    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  return (
    <DashboardLayout>
      <StyledSubNavigation>
        {sections.map(section => (
          <React.Fragment key={section.id}>
            <SubNavigation.SectionContainer
              active={section.id === currentSection.section}
            >
              {section.subsections.map(subsection => (
                <SubNavigation.SubSection
                  style={{ width: '20rem' }}
                  key={subsection.id}
                  onClick={() => handleClick(section.id, subsection.id)}
                  active={
                    currentSection.section === section.id &&
                    currentSection.subsection === subsection.id
                  }
                >
                  {subsection.label}
                </SubNavigation.SubSection>
              ))}
            </SubNavigation.SectionContainer>
          </React.Fragment>
        ))}
      </StyledSubNavigation>
      <Content>
        <section ref={refs[1][1]}>
          <ToolkitTerminationGenerator />
        </section>
        <section ref={refs[1][2]}>
          <ToolkitRequiredInformationGenerator />
        </section>
        {isFvfMember ? (
          <section ref={refs[1][3]}>
            <ToolkitCustomerSheetGenerator />
          </section>
        ) : null}
      </Content>
    </DashboardLayout>
  );
};

const DashboardLayout = styled(Layout)`
  display: grid;
  grid-template-areas: 'SubMenu Content';
  grid-template-columns: minmax(210px, 14.5vw) auto;
  padding-right: 9vw;
  margin-top: 6rem;

  @media (max-width: 1200px) {
    padding-right: 4vw;
  }

  @media (max-width: 768px) {
    position: initial;
    display: flex;
    flex-direction: column;
    grid-template-areas: auto;
  }
`;

const Content = styled.div`
  grid-area: Content;
  padding-left: 6vw;
`;

const StyledSubNavigation = styled(SubNavigation)`
  padding-left: 2rem;
  grid-area: SubMenu;
  position: fixed;

  @media (max-width: 1600px) {
    padding-left: 2.2vw;
  }

  @media (max-width: 768px) {
    position: initial;
    display: flex;
    grid-template-areas: auto;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      flex-direction: row;
      max-height: initial;
      max-width: 100vw;
      transition: opacity 0.5s, max-width 0.5s;
    }
  }
`;

ToolkitDocuments.propTypes = {
  history: shape().isRequired,
};

export default ToolkitDocuments;
