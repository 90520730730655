import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { func, shape, array, bool } from 'prop-types';
import {
  SearchSelectInput,
  CheckBox_v2 as CheckBox,
  TextInput,
  StripedTable,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import QuoteGuaranteeIcons from '../components/QuoteGuaranteeIcons';
import DateInput from './DateInput_v2';
import { getBrokerCRMName } from '../services/brokerDataService';

const CRMOfferConvertModalForm = ({
  errors,
  values,
  handleChange,
  paymentSpread,
  handleGuaranteesChange,
  handleChangePaymentSpread,
  guarantees,
  riskObjects,
  handleNetPremiumChange,
  handleTotalPremiumChange,
  setChangeGuaranteePricing,
  changeGuaranteePricing,
  manualGuarantees,
  languageOverwrite,
  handleChangeLanguageOverwrite,
}) => {
  const { t } = useTranslation();
  const [brokerCRMName, setBrokerCRMName] = useState('');

  useEffect(() => {
    getBrokerCRMName().then(resp => {
      setBrokerCRMName(resp);
    });
  }, []);

  const checkRiskObjects = object => {
    if (object != null) {
      if (object.cars.length > 0 || object.two_wheelers.length > 0) {
        return true;
      }
    }
  };

  return (
    <Form>
      <FlexWrapper>
        {checkRiskObjects(riskObjects) ? (
          <>
            <TextInput
              error={errors.number_plate}
              name="number_plate"
              placeholder={t('Vehicles.License plate')}
              type="text"
              value={values.number_plate}
              onChange={handleChange}
            >
              {t('Vehicles.License plate')}
            </TextInput>
            <TextInput
              error={errors.chassis_number}
              name="chassis_number"
              placeholder={t('Vehicles.Chassis number')}
              type="text"
              value={values.chassis_number}
              onChange={handleChange}
            >
              {t('Vehicles.Chassis number')}
            </TextInput>
          </>
        ) : null}
        <DateInput
          error={errors.commencement_date}
          name="commencement_date"
          onChange={({ name, value }) => {
            handleChange({ name, value });
            if (value.length === 10) {
              guarantees?.map(item => {
                handleGuaranteesChange({ name: item.name, value });
              });
            }
          }}
          value={values.commencement_date}
        >
          {t('Commencement date')}
        </DateInput>
        <SearchSelectInput
          error={errors.payment_spread}
          initial={paymentSpread}
          name="payment_spread"
          onSelected={handleChangePaymentSpread}
          options={[
            { label: t('ANNUAL'), value: 'ANNUAL' },
            { label: t('MONTHLY'), value: 'MONTHLY' },
            { label: t('QUARTERLY'), value: 'QUARTERLY' },
            { label: t('HALF_ANNUAL'), value: 'HALF_ANNUAL' },
          ]}
          placeholder={t('Choose an option')}
          // value={}
        >
          {t('Payment spread')}
        </SearchSelectInput>
      </FlexWrapper>

      <GuaranteesContainer>
        <CatTitle>{t('Guarantees')}</CatTitle>
        <GuaranteesTable>
          <tr>
            <th>{t('Guarantee')}</th>
            <th>{t('Start date')}</th>
          </tr>
          {guarantees?.map(item =>
            item.taken ? (
              <GuaranteeItem>
                <GuaranteeColumn>
                  <StyledQuoteGuaranteeIcons
                    type={item?.name}
                    color="#8990A3"
                    size={25}
                  />
                  <GuaranteeName>
                    {t(item.name === 'family' ? 'family_guarantee' : item.name)}
                  </GuaranteeName>
                </GuaranteeColumn>
                <GuaranteeColumn>
                  <DateInput
                    key={item.name}
                    error={errors[item.name]}
                    name={item.name}
                    onChange={handleGuaranteesChange}
                    value={values[item.name]}
                  >
                    {/* {t('Commencement date')} */}
                  </DateInput>
                </GuaranteeColumn>
              </GuaranteeItem>
            ) : (
              ''
            ),
          )}
        </GuaranteesTable>
      </GuaranteesContainer>

      <FlexWrapper>
        <DateInput
          includeYear={false}
          error={errors.main_expiration_date}
          name="main_expiration_date"
          onChange={handleChange}
          value={values.main_expiration_date}
        >
          {t('Main expiration date')}
        </DateInput>
        <SearchSelectInput
          error={errors.language_overwrite}
          initial={languageOverwrite}
          name="language_overwrite"
          onSelected={handleChangeLanguageOverwrite}
          options={[
            {
              value: 'NL',
              label: t('Dutch'),
            },
            {
              value: 'FR',
              label: t('French'),
            },
          ]}
          placeholder={t('Choose an option')}
          // value={}
        >
          {t('Language')}
        </SearchSelectInput>
      </FlexWrapper>

      <StyledCheckBox
        name="export_to_crm"
        checked={values.export_to_crm}
        onChange={handleChange}
      >
        {t(`Export to after creation`, {
          crm: brokerCRMName,
        })}
      </StyledCheckBox>

      <StyledCheckBox2
        name="edit_pricing"
        checked={changeGuaranteePricing}
        onChange={() => {
          setChangeGuaranteePricing(!changeGuaranteePricing);
        }}
      >
        {t('Change the pricing of the premiums')}
      </StyledCheckBox2>

      {changeGuaranteePricing && manualGuarantees ? (
        <>
          <Spacer medium />
          <StripedTable>
            <StripedTable.Head>
              <StripedTable.HeaderCell width="unset">
                {t(`Guarantee`)}
              </StripedTable.HeaderCell>
              <StripedTable.HeaderCell width="unset">
                {t(`Net premium`)}
              </StripedTable.HeaderCell>
              <StripedTable.HeaderCell width="2%"></StripedTable.HeaderCell>
              <StripedTable.HeaderCell width="unset">
                {t(`Taxes`)}
              </StripedTable.HeaderCell>
              <StripedTable.HeaderCell width="unset">
                {t(`Total premium`)}
              </StripedTable.HeaderCell>
            </StripedTable.Head>
            <StripedTable.Body>
              {manualGuarantees.map((guarantee, i) => {
                if (guarantee.taken) {
                  return (
                    <StripedTable.Row key={i + 1}>
                      <StripedTable.BodyCell>
                        {t(
                          guarantee?.name === 'family'
                            ? 'family_guarantee'
                            : guarantee?.name,
                        )}
                      </StripedTable.BodyCell>
                      <StripedTable.BodyCell>
                        <StyledTextInput
                          error={errors.net_premium}
                          name="net_premium"
                          type="number"
                          min="1"
                          value={Math.round(guarantee?.net_premium * 100) / 100}
                          onChange={e => {
                            handleNetPremiumChange(
                              e,
                              guarantee?.taxes,
                              guarantee?.id,
                            );
                          }}
                          symbolSide="right"
                          symbol="EUR"
                          symbolText={true}
                        ></StyledTextInput>
                      </StripedTable.BodyCell>

                      <StripedTable.BodyCell></StripedTable.BodyCell>

                      <StripedTable.BodyCell>
                        {guarantee?.taxes * 100} %
                      </StripedTable.BodyCell>
                      <StripedTable.BodyCell>
                        <StyledTextInput
                          error={errors.total_premium}
                          name="total_premium"
                          type="number"
                          min="1"
                          value={
                            Math.round(guarantee?.total_premium * 100) / 100
                          }
                          onChange={e => {
                            handleTotalPremiumChange(
                              e,
                              guarantee?.taxes,
                              guarantee?.id,
                            );
                          }}
                          symbolSide="right"
                          symbol="EUR"
                          symbolText={true}
                        ></StyledTextInput>
                      </StripedTable.BodyCell>
                    </StripedTable.Row>
                  );
                }
              })}
            </StripedTable.Body>
          </StripedTable>
        </>
      ) : null}
    </Form>
  );
};

const StyledCheckBox2 = styled(CheckBox)`
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-top: 2rem;
  border-top: 1px solid #e4e4e4;
`;

const StyledTextInput = styled(TextInput)`
  margin: 0;
  height: auto;
  margin-right: 15px;

  input {
    margin: 0;
  }
`;

const Spacer = styled.div`
  height: ${({ small, medium, large }) =>
    small ? '10px' : medium ? '20px' : large ? '30px' : '6px'};

  @media screen and (max-width: 500px) {
    display: ${({ desktop }) => (desktop ? 'none' : '')};
  }
`;

const StyledCheckBox = styled(CheckBox)`
  margin-top: 3rem;
`;

const StyledQuoteGuaranteeIcons = styled(QuoteGuaranteeIcons)`
  width: 30px;
`;

const GuaranteeName = styled.p`
  margin-left: 2rem;
`;

const GuaranteesTable = styled.table`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const GuaranteeColumn = styled.td`
  &:nth-child(1) {
    display: flex;
    height: 6.8rem;
    align-items: center;
    padding-left: 1rem;
  }

  &:nth-child(2) {
    padding-right: 1rem;
  }
`;

const CatTitle = styled.p``;

const GuaranteesContainer = styled.div``;

const GuaranteeItem = styled.tr`
  width: 100%;

  &:nth-child(odd) {
    background-color: #f9f9fa;
  }
`;

const Form = styled.form`
  flex-shrink: 0;
  max-width: 100%;
  font-size: 1.4rem;
`;

const FlexWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
`;

CRMOfferConvertModalForm.propTypes = {
  errors: shape(),
  values: shape(),
  paymentSpread: shape(),
  languageOverwrite: shape(),
  handleChange: func.isRequired,
  handleGuaranteesChange: func.isRequired,
  handleChangePaymentSpread: func.isRequired,
  handleChangeLanguageOverwrite: func.isRequired,
  guarantees: array,
  riskObjects: shape({}),
  handleNetPremiumChange: func.isRequired,
  handleTotalPremiumChange: func.isRequired,
  setChangeGuaranteePricing: func.isRequired,
  changeGuaranteePricing: bool.isRequired,
  manualGuarantees: shape().isRequired,
};

export default CRMOfferConvertModalForm;
