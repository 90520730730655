import React from 'react';

const CarValue = () => (
  <svg viewBox="0 0 247.27 130.17">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'car_value'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M206.58 21.42H40.7a31.15 31.15 0 01-18.14 18.12V90.4a31.08 31.08 0 0118.22 18.36h165.71a31.08 31.08 0 0118.22-18.36V39.54a31.11 31.11 0 01-18.13-18.12zm-83 68.37a24.71 24.71 0 1124.71-24.71 24.73 24.73 0 01-24.66 24.71z"
        />
        <path
          className="prefix__cls-1"
          d="M0 0v130.17h247.27V0zm235.4 99.17a20.05 20.05 0 00-20.05 20v.23H31.91a1.69 1.69 0 000-.23 20 20 0 00-20.05-20v-68.4a20 20 0 0020.05-20h183.44a20.05 20.05 0 0020.05 20z"
        />
      </g>
    </g>
  </svg>
);

export default CarValue;
