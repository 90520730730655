import React from 'react';

const ReplacementCar = () => (
  <svg viewBox="0 0 192.5 243.37">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'replacement_car'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M186.18 182.37l-66.92 26.57a10 10 0 01-13-5.6l-25.61-64.55v-21.63h14.78a21.65 21.65 0 0021.63-21.62V28.18A21.65 21.65 0 0095.43 6.55H69.68a45.31 45.31 0 016.66-3.29A45.94 45.94 0 01136.06 29l55.73 140.35a10 10 0 01-5.61 13.02zM36.41 227.24V117.16h32.24V155l-16.14 6.78a1.15 1.15 0 000 1.91l16.14 6.79v14.92l-16.14 6.78a1.16 1.16 0 000 1.92l16.14 6.78v26.35a16.12 16.12 0 11-32.24 0z"
        />
        <path
          className="prefix__cls-1"
          d="M95.43 18.55H9.63A9.63 9.63 0 000 28.18v67.36a9.62 9.62 0 009.63 9.62h85.8a9.62 9.62 0 009.63-9.62V28.18a9.63 9.63 0 00-9.63-9.63zM84.9 63a7.19 7.19 0 01-5.1 2.12H25.26a7.22 7.22 0 010-14.44H79.8A7.22 7.22 0 0184.9 63z"
        />
        <path className="prefix__cls-1" d="M30.41 95.48h44.24v9.68H30.41z" />
      </g>
    </g>
  </svg>
);

export default ReplacementCar;
