import ApiRoutes from '../store/ApiRoutes';

const api = new ApiRoutes();

let insurances = [];

// Secured broker data
export const setInsurances = () => {
  return fetchData();
};

const fetchData = () => {
  return api.getInsurersToolkit().then(({ data }) => {
    insurances = data;
    return data;
  });
};

export const getInsurances = () => {
  if (insurances.length === 0) return setInsurances();

  return new Promise(resolve => {
    resolve(insurances);
  });
};
