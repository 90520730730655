import React, { useEffect, useState, createRef, useContext } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import cogoToast from 'cogo-toast';
import styled from 'styled-components';
import {
  SubNavigation,
  BackButton,
  ActionButton,
  LoadingSpinner,
  Alert,
} from 'wg-fe-ui';
import Frame from '../../../assets/images/Frame.svg';

import TwoWheelerInformation from '../../components/CRMRiskObjectDetailTwoWheelerInformation';
import DriversList from '../../components/CRMRiskObjectDetailCarDrivers';
import { getBrokerData } from '../../services/brokerDataService';
import {
  getRiskObjectByTypeAndId,
  createRiskObjectRevision,
} from '../../services/apiRouterService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc';
import { getMeData } from '../../services/meDataService';

const CRMRiskObjectDetailTwoWheeler = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const refs = {};
  const configCatClient = useContext(FlagsProvider);
  const [isLoading, setIsLoading] = useState(true);
  const [twoWheelerData, setTwoWheelerData] = useState(
    location?.state?.updatedTwoWheelerData ?? {},
  );
  const [canEditOffers, setCanEditOffers] = useState(false);

  const [updatedTwoWheelerData, setUpdatedTwoWheelerData] = useState(
    location?.state?.updatedTwoWheelerData ?? {},
  );
  const [updateRequired, setUpdateRequired] = useState(false);

  const [currentSection, setCurrentSection] = useState({
    section: 1,
    subsection: 1,
  });

  const {
    id: riskObjectId,
    revision: riskObjectRevision,
    offerId,
    contractCaseId,
    contractId,
    prospectId,
    leadId,
    riskObjectType,
    offerRevision,
    editing,
  } = useParams();

  useEffect(() => {
    setFeatureToggles();
    if (location?.state?.updatedTwoWheelerData) return setIsLoading(false);
    retrieveTwoWheelerData();
    setTwoWheelerData({});
  }, []);

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};
    if (offerId || contractId || prospectId) {
      setCanEditOffers(
        leadId
          ? false
          : await configCatClient.getValueAsync(
              offerId || contractId
                ? 'editOffers'
                : 'canEditProspectsAndRiskObjects',
              false,
              {
                email: me?.email,
                custom: {
                  plan: broker?.plan,
                },
              },
            ),
      );
    }
  }

  async function retrieveTwoWheelerData() {
    const { distribution_id } = await getBrokerData();
    const [response, status] = await getRiskObjectByTypeAndId(
      distribution_id,
      'two_wheelers',
      riskObjectId,
      riskObjectRevision,
    );

    if (status !== 200) {
      if (status !== 404) {
        return console.error(response);
      }
      console.log('FAILED TO LOAD');
      setIsLoading(false);
      setTwoWheelerData(null);
      return;
    }

    setTwoWheelerData(response);
    setUpdatedTwoWheelerData(response);
    setIsLoading(!isLoading);
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);
    return () => window.removeEventListener('scroll', handleScroll, true);
  });

  const [sections] = useState([
    {
      id: 1,
      label: `${t('Risk object')} - ${t('Two wheeler')}`,
      subsections: [
        {
          id: 1,
          label: t('vehicle information'),
        },
        {
          id: 3,
          label: t('Drivers'),
        },
      ],
    },
  ]);

  sections.forEach(s => {
    refs[s.id] = {};

    s.subsections.forEach(value => {
      refs[s.id][value.id] = createRef();
    });
  });

  const handleSideBarClick = (section, sub) => {
    const yCoordinate =
      refs[section][sub].current.getBoundingClientRect().top +
      window.pageYOffset;

    window.scrollTo({
      top: yCoordinate - 160,
      behavior: 'smooth',
    });
  };

  const handleScroll = () => {
    const currentScroll = window.pageYOffset;
    // Iterate over sections refs
    for (const [key, val] of Object.entries(refs)) {
      // Iterate over subsections refs
      for (const [k, v] of Object.entries(val)) {
        if (v.current && currentSection !== k) {
          const currentElScroll =
            v.current.getBoundingClientRect().top + currentScroll;
          if (currentElScroll > currentScroll) {
            setCurrentSection({ section: Number(key), subsection: Number(k) });
            return;
          }
        }
      }
    }
  };

  const twoWheelerInformationSave = values => {
    console.log('DEBUG SUBMITTED TWOWHEELER', values);

    const payload = {
      age: values?.age ? values?.age : updatedTwoWheelerData.age,
      average_km_per_year: values?.average_km_per_year
        ? values?.average_km_per_year
        : updatedTwoWheelerData.average_km_per_year,
      brand: values?.brand ? values?.brand : updatedTwoWheelerData.brand,
      risk_object_id: updatedTwoWheelerData?.risk_object_id,
      risk_object_revision_id: updatedTwoWheelerData?.risk_object_revision_id,
      category: values?.category
        ? values?.category
        : updatedTwoWheelerData.category,
      cc: values?.cc ? values?.cc : updatedTwoWheelerData.cc,
      co2: values?.co2 ? values?.co2 : updatedTwoWheelerData.co2,
      color: values?.color ? values?.color : updatedTwoWheelerData.color,
      country_of_registration: values?.country_of_registration
        ? values?.country_of_registration
        : updatedTwoWheelerData.country_of_registration,
      drivers: [
        {
          // accident_statement:
          //   values?.accident_statement != null
          //     ? values?.accident_statement
          //     : updatedTwoWheelerData.drivers[0].accident_statement != null
          //     ? updatedTwoWheelerData.drivers[0].accident_statement
          //     : null,
          accident_statement: null,
          address:
            values?.address != null
              ? values?.address
              : updatedTwoWheelerData.drivers[0].address != null
              ? updatedTwoWheelerData.drivers[0].address
              : null,
          birth:
            values?.birth != null
              ? values?.birth
              : updatedTwoWheelerData.drivers[0].birth != null
              ? updatedTwoWheelerData.drivers[0].birth
              : null,
          birth_place: null,
          // values?.birth_place != null
          //   ? values?.birth_place
          //   : updatedTwoWheelerData.drivers[0].birth_place != null
          //   ? updatedTwoWheelerData.drivers[0].birth_place
          //   : null,
          driver_license_type:
            values?.driver_license_type != null
              ? values?.driver_license_type
              : updatedTwoWheelerData.drivers[0].driver_license_type != null
              ? updatedTwoWheelerData.drivers[0].driver_license_type
              : null,
          first_name:
            values?.first_name != null
              ? values?.first_name
              : updatedTwoWheelerData.drivers[0].first_name != null
              ? updatedTwoWheelerData.drivers[0].first_name
              : null,
          home_to_work_distance: null,
          // values?.home_to_work_distance != null
          //   ? values?.home_to_work_distance
          //   : updatedTwoWheelerData.drivers[0].home_to_work_distance != null
          //   ? updatedTwoWheelerData.drivers[0].home_to_work_distance
          //   : null,
          issue_license_date:
            values?.issue_license_date != null
              ? values?.issue_license_date
              : updatedTwoWheelerData.drivers[0].issue_license_date != null
              ? updatedTwoWheelerData.drivers[0].issue_license_date
              : null,
          last_name:
            values?.last_name != null
              ? values?.last_name
              : updatedTwoWheelerData.drivers[0].last_name != null
              ? updatedTwoWheelerData.drivers[0].last_name
              : null,
          number_of_accidents_last_five_years:
            values?.number_of_accidents_last_five_years != null
              ? values?.number_of_accidents_last_five_years
              : updatedTwoWheelerData.drivers[0]
                  .number_of_accidents_last_five_years != null
              ? updatedTwoWheelerData.drivers[0]
                  .number_of_accidents_last_five_years
              : null,
          primary:
            values?.primary != null
              ? values?.primary
              : updatedTwoWheelerData.drivers[0].primary != null
              ? updatedTwoWheelerData.drivers[0].primary
              : null,
          profession:
            values?.profession != null
              ? values?.profession
              : updatedTwoWheelerData.drivers[0].profession != null
              ? updatedTwoWheelerData.drivers[0].profession
              : null,
          special_employment:
            values?.special_employment != null
              ? values?.special_employment
              : updatedTwoWheelerData.drivers[0].special_employment != null
              ? updatedTwoWheelerData.drivers[0].special_employment
              : false,
        },
      ],
      febiacid: values?.febiacid
        ? values?.febiacid
        : updatedTwoWheelerData.febiacid,
      invoice_value: values?.invoice_value
        ? values?.invoice_value
        : updatedTwoWheelerData.invoice_value,
      is_main_vehicle: values?.is_main_vehicle
        ? values?.is_main_vehicle
        : updatedTwoWheelerData.is_main_vehicle,
      is_cabrio: values?.is_cabrio
        ? values?.is_cabrio
        : updatedTwoWheelerData.is_cabrio,
      is_coupe: values?.is_coupe
        ? values?.is_coupe
        : updatedTwoWheelerData.is_coupe,
      is_jeep: values?.is_jeep
        ? values?.is_jeep
        : updatedTwoWheelerData.is_jeep,
      is_second_hand: values?.is_second_hand
        ? values?.is_second_hand
        : updatedTwoWheelerData.is_second_hand,
      is_sportscar: values?.is_sportscar
        ? values?.is_sportscar
        : updatedTwoWheelerData.is_sportscar,
      kw: values?.kw ? values?.kw : updatedTwoWheelerData.kw,
      kwh: values?.kwh ? values?.kwh : updatedTwoWheelerData.kwh,
      license_plate_type: values?.license_plate_type
        ? values?.license_plate_type
        : updatedTwoWheelerData.license_plate_type,
      model: values?.model ? values?.model : updatedTwoWheelerData.model,
      model_year: isNaN(
        parseInt(
          values?.model_year
            ? values?.model_year
            : updatedTwoWheelerData.model_year,
        ),
      )
        ? null
        : parseInt(
            values?.model_year
              ? values?.model_year
              : updatedTwoWheelerData.model_year,
          ),
      motor_type: values?.motor_type
        ? values?.motor_type
        : updatedTwoWheelerData.motor_type,
      name: values?.name ? values?.name : updatedTwoWheelerData.name,
      number_plate: values?.number_plate
        ? values?.number_plate
        : updatedTwoWheelerData.number_plate,
      options_value: isNaN(
        parseFloat(
          values?.options_value
            ? values?.options_value
            : updatedTwoWheelerData.options_value,
        ),
      )
        ? null
        : parseFloat(
            values?.options_value
              ? values?.options_value
              : updatedTwoWheelerData.options_value,
          ),
      clothing_value: values?.clothing_value
        ? values?.clothing_value
        : updatedTwoWheelerData.clothing_value,
      registration_first: values?.registration_first
        ? values?.registration_first
        : updatedTwoWheelerData.registration_first,
      registration_last: values?.registration_last
        ? values?.registration_last
        : updatedTwoWheelerData.registration_last,
      seats: values?.seats ? values?.seats : updatedTwoWheelerData.seats,
      used_for: values?.used_for
        ? values?.used_for
        : updatedTwoWheelerData.used_for,
      value: isNaN(
        parseFloat(values?.value ? values?.value : updatedTwoWheelerData.value),
      )
        ? null
        : parseFloat(
            values?.value ? values?.value : updatedTwoWheelerData.value,
          ),
      vat_regime: values?.vat_regime
        ? values?.vat_regime
        : updatedTwoWheelerData.vat_regime,
      vehicle_type: values?.vehicle_type
        ? values?.vehicle_type
        : updatedTwoWheelerData.vehicle_type,
      version: values?.version
        ? values?.version
        : updatedTwoWheelerData.version,
    };

    setUpdatedTwoWheelerData(payload);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    console.log('DEBUG SUBMITTED TWOWHEELERINFOSAVE payload', payload);

    if (!isEqual(twoWheelerData, payload)) {
      showUpdateMessage();
    }
  };

  const showUpdateMessage = () => {
    setUpdateRequired(true);
  };

  const handleSaveTwoWheelerOfferChanges = () => {
    const pathname =
      prospectId && offerId
        ? `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`
        : `/sales-management/offer/${offerId}/${offerRevision}`;
    history.push({
      pathname,
      state: { ...location.state, updatedTwoWheelerData },
    });
  };

  const handleSaveTwoWheelerContractChanges = () => {
    const pathname = prospectId
      ? `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`
      : `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    history.push({
      pathname,
      state: { ...location.state, updatedTwoWheelerData },
    });
  };

  const handleSaveTwoWheelerChanges = async () => {
    const { distribution_id } = await getBrokerData();
    const tempUpdatedTwoWheelerData = cloneDeep(updatedTwoWheelerData);
    delete tempUpdatedTwoWheelerData.id;
    delete tempUpdatedTwoWheelerData.risk_object_id;
    delete tempUpdatedTwoWheelerData.risk_object_revision_id;
    let [response, status] = await createRiskObjectRevision(
      distribution_id,
      leadId || prospectId,
      'two_wheelers',
      riskObjectId,
      updatedTwoWheelerData,
    );

    if (!(status < 204 && status >= 200)) {
      cogoToast.error(t('Something went wrong'));
      return console.error(response);
    }

    cogoToast.success(t('Risk object revision successfully saved'));

    setUpdateRequired(false);
    history.push({
      pathname:
        prospectId && leadId
          ? `/sales-management/customer/${prospectId}/contracts/lead/${leadId}/risk-object/${riskObjectType}/${riskObjectId}/${response.risk_object_revision_id}`
          : `/sales-management/customer/${prospectId}/risk-object/${riskObjectType}/${riskObjectId}/${response.risk_object_revision_id}`,
      state: { ...location.state },
    });
  };

  const backButtonHandler = () => {
    let pathname = '';
    if (prospectId && offerId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/offer/${offerId}/${offerRevision}`;
    } else if (prospectId && contractId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/case/${contractCaseId}/contract/${contractId}`;
    } else if (prospectId && leadId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/lead/${leadId}`;
    } else if (prospectId) {
      pathname = `/sales-management/customer/${prospectId}`;
    } else if (contractId) {
      pathname = `/sales-management/case/${contractCaseId}/contract/${contractId}`;
    } else if (leadId) {
      pathname = `/sales-management/lead/${leadId}`;
    } else {
      pathname = `/sales-management/offer/${offerId}/${offerRevision}`;
    }
    history.push({
      pathname,
      state: { ...location.state },
    });
  };

  if (isLoading) {
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  if (twoWheelerData == null) {
    return (
      <EmptyState>
        <img src={Frame} alt="Test" />
        <h1>{t('Risk object not found')}</h1>
        <p>
          {`${t('We could not find the risk object with id')}: `}{' '}
          <strong> {riskObjectId}</strong>
        </p>
      </EmptyState>
    );
  }

  return (
    <MainContainer>
      <TopBar>
        <TopBarContainer>
          <TopBarLeft>
            <BackButton onClick={backButtonHandler} name={t('Previous page')} />
          </TopBarLeft>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          {sections.map(section => (
            <SubNavigation key={section.id}>
              <SubNavigation.Section>{section.label}</SubNavigation.Section>
              <SubNavigation.SectionContainer
                active={section.id === currentSection.section}
              >
                {section.subsections.map(subsection => (
                  <SubNavigation.SubSection
                    style={{ width: '20rem' }}
                    key={subsection.id}
                    onClick={() =>
                      handleSideBarClick(section.id, subsection.id)
                    }
                    active={
                      currentSection.section === section.id &&
                      currentSection.subsection === subsection.id
                    }
                  >
                    {subsection.label}
                  </SubNavigation.SubSection>
                ))}
              </SubNavigation.SectionContainer>
            </SubNavigation>
          ))}
        </Sidebar>

        <Content>
          {updateRequired && offerId && !contractId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object, would you like to save these changes and go back to the offer',
                )}
                <ActionButton
                  onClick={() => handleSaveTwoWheelerOfferChanges()}
                >
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}

          {updateRequired && contractId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object, would you like to save these changes and go back to the contract',
                )}
                <ActionButton
                  onClick={() => handleSaveTwoWheelerContractChanges()}
                >
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}

          {updateRequired &&
          (prospectId || leadId) &&
          !contractId &&
          !offerId ? (
            <StyledAlert icon type="normal">
              <StyledAlertContent>
                {t(
                  'You have made changes to the risk object would you like to save these changes',
                )}
                <ActionButton onClick={() => handleSaveTwoWheelerChanges()}>
                  {t(`Save`)}
                </ActionButton>
              </StyledAlertContent>
            </StyledAlert>
          ) : null}
          <section ref={refs[1][1]}>
            <TwoWheelerInformation
              twoWheelerData={twoWheelerData}
              initEdit={editing ?? false}
              onSubmitFunction={twoWheelerInfo =>
                twoWheelerInformationSave(twoWheelerInfo)
              }
              disableEditing={!canEditOffers}
            />
          </section>
          <section ref={refs[1][2]}>
            <DriversList
              onSubmitFunction={driversInformationValues =>
                twoWheelerInformationSave(driversInformationValues)
              }
              disableEditing={!canEditOffers}
              mainDriver={
                updatedTwoWheelerData?.drivers?.filter(
                  ({ primary }) => !!primary,
                )[0]
              }
              secondaryDrivers={updatedTwoWheelerData?.drivers?.filter(
                ({ primary }) => !primary,
              )}
            />
          </section>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`;

const StyledAlertContent = styled.div`
  font-size: 1.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 10;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: sticky;
  top: 200px;
  margin-right: 32px;
  flex: 0 0 220px;

  & > button {
    margin-right: 0.78vw;
  }

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 0 1 auto;
  max-width: 100rem;
  width: 100%;
`;

export default CRMRiskObjectDetailTwoWheeler;
