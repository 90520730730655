import React from 'react';
import styled from 'styled-components';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { retrieveAllThemes } from '../../../services/themingService';

const ChatThemePreview = ({ logo, theme, name, avatar }) => {
  const { t } = useTranslation();
  const themes = retrieveAllThemes();

  return (
    <Container>
      <Avatar src={logo ? logo : avatar} />
      <div>
        <TextBalloon
          theme={themes[theme]}
          dangerouslySetInnerHTML={{
            __html: t('Settings.customization.balloon-text', { name: name }),
          }}
        />
        <Time theme={themes[theme]}>10:45</Time>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
`;

const Time = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme?.brand?.primary};
  transition: 0.3s;
`;

const Avatar = styled.img`
  width: 4.5rem;
  align-self: flex-end;
  height: 4.5rem;
  border-radius: 4.5rem;
  margin-right: 1rem;
`;

const TextBalloon = styled.p`
  transition: 0.3s;
  background-color: ${({ theme }) => theme?.brand?.primary};
  border-radius: 2rem 2rem 2rem 0;
  color: white;
  font-size: 1.6rem;
  max-width: 32rem;
  padding: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 1rem;

  span {
    font-weight: bold;
  }
`;

ChatThemePreview.propTypes = {
  logo: string,
  avatar: string,
  theme: object,
  name: string,
};

export default ChatThemePreview;
