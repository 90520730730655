import React, { useState } from 'react';
import styled from 'styled-components';
import { string, func, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

const LoadingBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background-color: ${({ theme }) => theme.ui.background};
  margin-top: 1.1rem;
  margin-bottom: 2rem;
  position: relative;

  &::before {
    content: '';
    width: ${({ loaded }) => (loaded === 0 ? `0%` : `100%`)};
    transition: ${({ loaded }) => (loaded === 0 ? null : 'width 2s')};
    height: 0.5rem;
    position: absolute;
    background-color: ${({ theme }) => theme.brand.primary};
  }
`;

const bytesToMega = value => Math.round(value / 100000) / 10;

const shortifyText = text => {
  if (text.length < 20) return text;
  else return `${text.substring(0, 17)}...`;
};

const Uploader = ({ className, setLogo, disabled, getFileName }) => {
  const [loaded, setLoaded] = useState(0);
  const [file, setFile] = useState({ name: '', size: 0 });

  /**
   * Handles file input changes.
   * Changes size text, updates progress bar value
   * @param {object} event
   */

  const handleChange = event => {
    const reader = new FileReader();
    const selectedFile = event.target.files[0];
    setLoaded(0);
    reader.addEventListener('progress', e => {
      if (e.type === 'progress') {
        const percentage = (selectedFile.size / e.loaded) * 100;
        setLoaded(percentage);
      }
    });

    getFileName(event.target.files[0].name);

    reader.addEventListener('loadend', e => {
      if (e.type === 'loadend') {
        setLogo(e.target.result);
      }
    });

    if (selectedFile) {
      setFile({
        name: shortifyText(selectedFile.name),
        size: bytesToMega(selectedFile.size),
      });
      reader.readAsDataURL(selectedFile);
    }
  };

  const { t } = useTranslation();

  return (
    <div className={className}>
      <input
        type="file"
        name="file"
        id="file"
        data-test-id="dashboard_settings_customization_upload_avatar"
        accept="image/*"
        onChange={handleChange}
        disabled={disabled}
      />
      <div className="topOfBar">
        <span className="fileName">{file.name}</span>
        <span className="fileSize">{file.size} mb</span>
      </div>
      <LoadingBar loaded={loaded} />
      <Label
        disabled={disabled}
        htmlFor="file"
        data-test-id="dashboard_settings_customization_info_select_avatar"
      >
        {t('Settings.customization.upload-button')}
      </Label>
      <P disabled={disabled}>reset avatar</P>
    </div>
  );
};

const StyledUploader = styled(Uploader)`
  align-self: center;
  margin-left: 2rem;
  padding-bottom: 1rem;
  width: 100%;
  max-width: 26rem;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    z-index: -1;
  }

  input:focus + label {
    outline: 1px dotted black;
    outline: -webkit-focus-ring-color auto 5px;
  }

  span {
    font-size: 1.5rem;
  }

  .topOfBar {
    display: flex;
    justify-content: space-between;
  }

  .fileName {
    font-weight: 900;
    max-width: 70%;
  }

  .fileSize {
    color: #76726e;
    margin-left: auto;
  }
`;

const Label = styled.label`
  max-width: 70%;
  border: 1px solid #a29c95;
  box-sizing: border-box;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  padding: 0.4rem 1.3rem;
  font-size: 1.4rem;
  color: #5b5550;
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  background-color: ${props => (props.disabled ? `#F0F1F3` : `white`)};
`;

const P = styled.p`
  font-size: 1.4rem;
  color: #5b5550;
  cursor: ${props => (props.disabled ? `default` : `pointer`)};
  margin-top: 1rem;
`;

Uploader.defaultProps = {
  disabled: false,
};

Uploader.propTypes = {
  className: string,
  setLogo: func,
  getFileName: func,
  disabled: bool,
};

export default StyledUploader;
