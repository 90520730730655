import React from 'react';
import { string } from 'prop-types';

const Extra = ({ title, color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 224.55 204.76">
    <title>{title}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="icons">
        <path
          fill={color}
          d="M175.29,97.32V76.09H154.07a11.21,11.21,0,1,1,0-22.42h21.22V51l-15.5-9.81V10.41A5.79,5.79,0,0,0,154,4.62H131.11a5.79,5.79,0,0,0-5.8,5.79V19.1L96.68.9a5.77,5.77,0,0,0-6.18,0L2.7,56.37A5.78,5.78,0,0,0,0,61.26V196.33a8.42,8.42,0,0,0,8.43,8.43H32.82V142.44H76.55v62.32H178.08a8.43,8.43,0,0,0,8.43-8.43V108.52h0A11.22,11.22,0,0,1,175.29,97.32Z"
        />
        <path
          fill={color}
          d="M192.11,97.32V70.48H219a5.61,5.61,0,0,0,0-11.21H192.11V32.44a5.61,5.61,0,1,0-11.21,0V54.53l2.93,1.86a5.75,5.75,0,0,1,2.68,4.87v41.66A5.6,5.6,0,0,0,192.11,97.32Z"
        />
        <path
          fill={color}
          d="M183.83,56.39l-2.93-1.86v4.74H154.07a5.61,5.61,0,0,0,0,11.21H180.9V97.32a5.6,5.6,0,0,0,5.6,5.6h0V61.26A5.75,5.75,0,0,0,183.83,56.39Z"
        />
      </g>
    </g>
  </svg>
);

Extra.defaultProps = {
  title: '',
  color: '#FFFFFF',
};

Extra.propTypes = {
  title: string,
  color: string,
};

export default Extra;
