import React from 'react';
import { string } from 'prop-types';

const SpecialRoofingIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="29"
      fill="none"
      viewBox="0 0 26 29"
    >
      <path
        fill={color}
        d="M25.62 7.983l-3.352-2.147V1.478a.822.822 0 00-.81-.822H18.27a.822.822 0 00-.81.822V2.7L13.474.13a.737.737 0 00-.43-.129.737.737 0 00-.43.129L.38 7.983a.847.847 0 00-.38.694v19.127C0 28.46.531 29 1.177 29h23.646C25.47 29 26 28.46 26 27.805V8.677c0-.283-.14-.54-.38-.694zm-13.55 15.85a.31.31 0 01-.304.308H2.885a.31.31 0 01-.304-.309v-5a.31.31 0 01.304-.309h8.881a.31.31 0 01.304.309v5zm0-7.366a.31.31 0 01-.304.308H2.885a.31.31 0 01-.304-.308v-5a.31.31 0 01.304-.31h8.881a.31.31 0 01.304.31v5zm11.349 7.365a.31.31 0 01-.304.309h-8.881a.31.31 0 01-.304-.309v-5a.31.31 0 01.304-.309h8.881a.31.31 0 01.304.309v5zm0-7.365a.31.31 0 01-.304.308h-8.881a.31.31 0 01-.304-.308v-5a.31.31 0 01.304-.31h8.881a.31.31 0 01.304.31v5z"
      ></path>
    </svg>
  );
};

SpecialRoofingIcon.defaultProps = {
  color: `white`,
};

SpecialRoofingIcon.propTypes = {
  color: string,
};

export default SpecialRoofingIcon;
