import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { shape } from 'prop-types';

import Layout from '../../DashboardLayout/views/Layout';
import DashboardToolbarBrokerHistory from '../../../components/DashboardToolbarBrokerHistory';
import { TopNavigationItems } from '../../../services/context/TopNavigationBarContext';

import {
  getHistory,
  setInsurTypes,
  setTabItems,
} from '../../../services/brokerhistoryService';
import { getNestedObject } from '../../../services/objectService';
import DashboardBrokerHistoryPropertyHouseInformation from './../../../components/DashboardBrokerHistoryPropertyHouseInformation';
import DashboardBrokerHistoryPropertyRoomsInformation from './../../../components/DashboardBrokerHistoryPropertyRoomsInformation';
import DashboardBrokerHistoryPropertyQuestions from './../../../components/DashboardBrokerHistoryPropertyQuestions';
import DashboardBrokerHistoryPropertyPresent from './../../../components/DashboardBrokerHistoryPropertyPresent';
import DashboardBrokerHistoryInsuranceInformation from './../../../components/DashboardBrokerHistoryInsuranceInformation';
import { useTranslation } from 'react-i18next';
import DashboardBrokerHistoryConversationInformation from '../../../components/DashboardBrokerHistoryConversationInformation';

const BrokerHistoryDetailProperty = ({ match, history }) => {
  const { setNavTabs } = useContext(TopNavigationItems);
  const [filteredInsureTypes, setFilteredInsureTypes] = useState([]);
  const [sessionData, setSessionData] = useState({});
  const [propertyInfo, setPropertyInfo] = useState({});
  const [roomInfo, setRoomInfo] = useState({});
  const [questionInfo, setQuestionInfo] = useState({});
  const [presentInfo, setPresentInfo] = useState({});
  const [insuranceInformation, setInsuranceInformation] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setInsurTypes(match.params.id, setSessionData).then(res => {
      setSessionData(res.sessionData);
      setFilteredInsureTypes(res.filteredData);
      const tmpItems = setTabItems(match.params.id, res.sessionData, t);
      setNavTabs(tmpItems);
    });
  }, []);

  useEffect(() => {
    const { city, housenr, street, zipcode } =
      getNestedObject(sessionData, ['home', 'address']) || {};

    const { is_owner, age, trustee, private_garage } =
      getNestedObject(sessionData, ['home']) || {};

    const {
      house_type,
      surface,
      parcel_area,
      annexes,
      facade_width,
      habitable_surface,
      distance_nearest_neighbor_in_meters,
      height,
    } = getNestedObject(sessionData, ['home_extra']) || {};

    setPropertyInfo({
      street,
      housenr,
      zipcode,
      city,
      private_garage,
      houseType: house_type,
      isOwner: is_owner,
      age,
      surface,
      trustee,
      parcelArea: parcel_area,
      annexes: annexes,
      facadeWidth: facade_width,
      habitableSurface: habitable_surface,
      buildingHeight: height,
      distantNeighbor: distance_nearest_neighbor_in_meters,
    });
  }, [sessionData]);

  useEffect(() => {
    // safely check if it exists in object
    const {
      bathrooms,
      bedrooms,
      garages,
      kitchens,
      living_rooms,
      other_rooms,
    } = getNestedObject(sessionData, ['home', 'room_layout']) || {};

    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalRooms = [
      bathrooms,
      bedrooms,
      garages,
      kitchens,
      living_rooms,
      other_rooms,
    ].reduce(reducer);

    setRoomInfo({
      livingRooms: living_rooms,
      bathrooms,
      bedrooms,
      kitchens,
      garages,
      otherRooms: other_rooms,
      total: totalRooms,
    });
  }, [sessionData]);

  useEffect(() => {
    // safely check if it exists in object
    const {
      under_construction,
      special_roofing,
      is_attic_unhabited,
      passive_house,
      special_flooring,
      is_cellar_unhabited,
    } = getNestedObject(sessionData, ['home']) || {};

    setQuestionInfo({
      underConstruction: under_construction ? under_construction : false,
      specialRoofing: special_roofing ? special_roofing : false,
      isAtticUnhabited: is_attic_unhabited ? !is_attic_unhabited : false,
      passiveHouse: passive_house ? passive_house : false,
      specialFlooring: special_flooring ? special_flooring : false,
      isCellarUnhabited: is_cellar_unhabited ? !is_cellar_unhabited : false,
    });
  }, [sessionData]);

  useEffect(() => {
    // safely check if it exists in object
    const { has_cellar, has_attic } =
      getNestedObject(sessionData, ['home']) || {};

    const {
      has_garden,
      has_porch,
      has_garden_house,
      has_solar_panels,
      has_swimming_pool,
    } = getNestedObject(sessionData, ['home_extra']) || {};

    setPresentInfo({
      hasGarden: has_garden,
      hasPorch: has_porch,
      hasGardenHouse: has_garden_house,
      hasSolarPanels: has_solar_panels,
      hasSwimmingPool: has_swimming_pool,
      hasCellar: has_cellar,
      hasAttic: has_attic,
    });
  }, [sessionData]);

  useEffect(() => {
    // safely check if it exists in object
    const { already_insured_with } =
      getNestedObject(sessionData, ['home']) || {};

    const { day, month, year } =
      getNestedObject(sessionData, ['home', 'previous_insurance_start_date']) ||
      {};

    const date = day || month || year ? `${day}/${month}/${year}` : undefined;

    setInsuranceInformation({
      insurer: already_insured_with,
      date,
    });
  }, [sessionData]);

  return (
    <StyledLayout>
      <DashboardToolbarBrokerHistory
        onClick={() => {
          return getHistory(
            match.params.id,
            filteredInsureTypes,
            history,
            t,
            sessionData,
          );
        }}
        dataTestId="dashboard_history_detail_calculate"
        btnText={t('History.goToMarket')}
      />
      <SectionWrapper>
        <DashboardBrokerHistoryConversationInformation
          sessionData={sessionData}
        />
        <DashboardBrokerHistoryPropertyHouseInformation
          propertyInfo={propertyInfo}
        />
        <DashboardBrokerHistoryPropertyRoomsInformation roomInfo={roomInfo} />
        <DashboardBrokerHistoryPropertyQuestions questionInfo={questionInfo} />
        <DashboardBrokerHistoryPropertyPresent presentInfo={presentInfo} />
        <DashboardBrokerHistoryInsuranceInformation
          insurer={insuranceInformation.insurer}
          date={insuranceInformation.date}
        />
      </SectionWrapper>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

const SectionWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

BrokerHistoryDetailProperty.propTypes = {
  history: shape().isRequired,
  match: shape(),
};

export default withRouter(BrokerHistoryDetailProperty);
