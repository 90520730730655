import React from 'react';
import { shape } from 'prop-types';

const ClosedBuildingIcon = ({ colors }) => (
  <svg
    width="44"
    height="24"
    viewBox="0 0 44 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.83885 2.07913C8.67395 1.97356 8.4628 1.97356 8.29713 2.07759L0.627404 6.91692C0.480991 7.00939 0.391602 7.16967 0.391602 7.34383V22.7287C0.391602 23.3622 0.905588 23.8762 1.53902 23.8762H7.47799C8.11141 23.8762 8.6254 23.3622 8.6254 22.7287V7.70601C8.6254 6.84756 9.06618 6.04846 9.79208 5.59072L11.6885 4.39399C11.8704 4.27917 11.8711 4.01409 11.6901 3.8985L8.83885 2.07913Z"
      fill={colors.brand.dark}
    />
    <path
      d="M42.7759 6.91769L35.1894 2.07913C35.0245 1.97356 34.8133 1.97356 34.6484 2.07759L32.144 3.65731C32.0585 3.71125 32.0068 3.80449 32.0068 3.90544C32.0068 4.00561 32.0577 4.09886 32.1425 4.1528L34.7448 5.81497C35.2503 6.13862 35.5523 6.69036 35.5523 7.29143V22.728C35.5523 23.3614 36.0663 23.8754 36.6998 23.8754H41.8612C42.4946 23.8754 43.0086 23.3614 43.0086 22.728V7.34383C43.0086 7.17121 42.9208 7.01016 42.7759 6.91769Z"
      fill={colors.brand.dark}
    />
    <path
      d="M30.0547 1.50092V4.5517C30.0547 4.91542 30.2404 5.25371 30.5471 5.45253L32.7487 6.85963C33.2788 7.19869 33.6002 7.78512 33.6002 8.41469V22.663C33.6002 23.2964 33.0862 23.8104 32.4528 23.8104H24.7657V16.9551H19.4111V23.8104H11.7246C11.0911 23.8104 10.5771 23.2964 10.5771 22.663V8.42317C10.5771 7.78897 10.9023 7.20023 11.4379 6.86194L21.6028 0.447517C21.9388 0.235604 22.3672 0.236374 22.7024 0.449058L26.7057 2.99279C26.9985 3.1785 27.3815 2.96813 27.3815 2.62136V1.50092C27.3815 1.0848 27.719 0.747278 28.1351 0.747278H29.301C29.7172 0.747278 30.0547 1.0848 30.0547 1.50092Z"
      fill={'#fff'}
    />
  </svg>
);

ClosedBuildingIcon.propTypes = {
  colors: shape().isRequired,
};

export default ClosedBuildingIcon;
