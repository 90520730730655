import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { func, string, shape } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { getNestedObject } from '../services/objectService';

const MarketplaceCheckoutItemContractLength = props => {
  const {
    debouncedCallback,
    id,
    displayedCard,
    setLoadPrice,
    setExceptionError,
  } = props;

  const { t } = useTranslation();

  const checkRadioDisabled = key => {
    let val = true;

    const paymentIntervals =
      getNestedObject(displayedCard, [
        'details',
        'quote',
        'payment_intervals',
      ]) || [];

    paymentIntervals.forEach(interval => {
      if (interval === key) {
        val = false;
      }
    });
    return val;
  };

  return (
    <>
      <DurationLabel>{t('Checkout.contract.title')}</DurationLabel>
      <DurationButtonContainer
        data-test-id={`underwriting_checkout_contract_length`}
      >
        <RadioInput
          name={id.toString() + '-pay_interval'}
          id={id.toString() + '-Jaarlijks'}
          type="radio"
          value="annual"
          disabled={checkRadioDisabled('annual')}
          onChange={e => {
            setLoadPrice(true);
            setExceptionError(null);
            debouncedCallback({ value: e.currentTarget.value });
          }}
          defaultChecked
        />
        <label
          htmlFor={id.toString() + '-Jaarlijks'}
          data-tip
          data-for={checkRadioDisabled('annual') ? 'yearly' : ''}
          data-test-id={`underwriting_checkout_contract_length_annual`}
        >
          {t('Checkout.contract.duration.0')}
        </label>
        <ReactTooltip effect="solid" id={'yearly'}>
          {t('Annual premium split is not possible with this package')}
        </ReactTooltip>
        <RadioInput
          onChange={e => {
            setLoadPrice(true);
            setExceptionError(null);
            debouncedCallback({ value: e.currentTarget.value });
          }}
          name={id.toString() + '-pay_interval'}
          id={id.toString() + '-Semesterieel'}
          type="radio"
          disabled={checkRadioDisabled('half_annual')}
          value="half_annual"
        />
        <label
          htmlFor={id.toString() + '-Semesterieel'}
          data-tip
          data-for={checkRadioDisabled('half_annual') ? 'Semesterly' : ''}
          data-test-id={`underwriting_checkout_contract_length_half_annual`}
        >
          {t('Checkout.contract.duration.1')}
        </label>
        <ReactTooltip effect="solid" id={'Semesterly'}>
          {t('Half-yearly premium split is not possible with this package')}
        </ReactTooltip>
        <RadioInput
          onChange={e => {
            setLoadPrice(true);
            setExceptionError(null);
            debouncedCallback({ value: e.currentTarget.value });
          }}
          name={id.toString() + '-pay_interval'}
          id={id.toString() + '-Trimesterieel'}
          type="radio"
          disabled={checkRadioDisabled('quarterly')}
          value="quarterly"
        />
        <label
          htmlFor={id.toString() + '-Trimesterieel'}
          data-tip
          data-for={checkRadioDisabled('quarterly') ? 'Trimesterly' : ''}
          data-test-id={`underwriting_checkout_contract_length_quarterly`}
        >
          {t('Checkout.contract.duration.2')}
        </label>
        <ReactTooltip effect="solid" id="Trimesterly">
          {t('Quarterly premium split is not possible with this package')}
        </ReactTooltip>
        <RadioInput
          onChange={e => {
            setLoadPrice(true);
            setExceptionError(null);
            debouncedCallback({ value: e.currentTarget.value });
          }}
          name={id.toString() + '-pay_interval'}
          id={id.toString() + '-Maandelijks'}
          type="radio"
          disabled={checkRadioDisabled('monthly')}
          value="monthly"
        />
        <label
          htmlFor={id.toString() + '-Maandelijks'}
          data-tip
          data-for={checkRadioDisabled('monthly') ? 'monthly' : ''}
          data-test-id={`underwriting_checkout_contract_length_monthly`}
        >
          {t('Checkout.contract.duration.3')}
        </label>
        <ReactTooltip effect="solid" id={'monthly'}>
          {t('Monthly premium split is not possible with this package')}
        </ReactTooltip>
      </DurationButtonContainer>
    </>
  );
};

const DurationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  border-radius: 0.3rem;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 1.4rem;
`;

const RadioInput = styled.input`
  display: none;

  & + label {
    background-color: ${({ theme }) => theme.ui.interactive};
    padding: 1.3rem 0;
    text-align: center;
    color: white;
    font-size: 1.4rem;
    transition: 0.2s all;
    width: 11.5rem;

    &:hover {
      background-color: ${({ theme }) => theme.brand.lighter};
      cursor: pointer;
    }
  }

  &:checked + label {
    background-color: ${({ theme }) => theme.brand.primary};
    color: white;
    font-weight: bold;
  }

  &:disabled + label {
    background-color: ${({ theme }) => theme.ui.disabled};
    color: white;
    cursor: default;
  }
`;

const DurationLabel = styled.label`
  font-size: 1.6rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.brand.interactive};
`;

MarketplaceCheckoutItemContractLength.propTypes = {
  debouncedCallback: func.isRequired,
  setLoadPrice: func.isRequired,
  setExceptionError: func.isRequired,
  id: string.isRequired,
  displayedCard: shape().isRequired,
};

export default MarketplaceCheckoutItemContractLength;
