import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { func, string, bool, object } from 'prop-types';
import { useTranslation } from 'react-i18next';

const SelectInput = ({
  selected,
  isClearable,
  loading,
  data,
  name,
  disabled,
  children,
  long,
  initial,
  dataTestId,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState();
  const [isSelected, setSelected] = useState();

  const handleChange = (e, action) => {
    if (action === 'clear') {
      selected(name, '');
      setSelected({ value: '', label: '' });
    } else {
      selected(name, e.value);
      setSelected(e);
    }
  };

  useEffect(() => {
    const _options = [];
    if (loading || data.length === 0) {
      setSelected('');
    }

    if (initial !== '') {
      const obj = {
        value: initial,
        label: initial,
      };

      setSelected(obj);
    }

    if (!Array.isArray(data)) {
      Object.keys(data).forEach(itemName => {
        const obj = {
          value: itemName,
          label: itemName,
        };
        _options.push(obj);
      });
    } else {
      data.forEach(itemName => {
        const obj = {
          value: itemName,
          label: itemName,
        };
        _options.push(obj);
      });
    }
    setOptions(_options);
  }, [data]);

  return (
    <>
      <Label>{children}</Label>
      <Input
        long={long}
        isDisabled={disabled}
        onChange={(e, { action }) => handleChange(e, action)}
        options={options}
        value={isSelected}
        isClearable={isClearable}
        noOptionsMessage={() => t(`CheckCarManual.defaultSelect`)}
        placeholder={
          loading
            ? t(`CheckCarManual.loading`)
            : t(`CheckCarManual.defaultSelect`)
        }
        classNamePrefix="Select"
        data-test-id={dataTestId}
      />
    </>
  );
};

const Input = styled(Select)`
  width: 100%;
  margin-bottom: 2rem;

  &:focus {
    outline: none;
  }

  & .Select {
    &__control {
      box-shadow: 0 0.3rem 0.9rem rgba(0, 0, 0, 0.1);
      border-radius: 0.3rem;
      border: none;
    }

    &__control--menu-is-open {
      .Select__dropdown-indicator {
        & svg {
          transform: rotate(180deg);
          transition: 0.1s ease;
        }
      }
    }

    &__value-container {
      padding: 0 0.5em;
      font-family: 2rem;
      font-size: 1.5rem;
      /* text-transform: uppercase; */
    }

    &__single-value {
      /* text-transform: capitalize; */
      color: black;
    }

    &__menu {
      font-size: 1.8rem;
      z-index: 999;
    }

    &__menu-list {
      max-height: 13rem;
    }

    &__menu-notice--no-options {
      text-align: left;
    }

    &__option {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.typo.interactive};

      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.brand.secondary};
        color: white;
      }

      &--is-focused {
        color: white;
        background-color: ${({ theme }) => theme.brand.secondary};
      }

      &--is-selected {
        background-color: ${({ theme }) => theme.brand.primary};
        color: white;
      }
    }
  }
`;

const Label = styled.p`
  font-size: 1.4rem;
  transition: 0.2s;
  line-height: 1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.typo.interactive};
  margin-left: 0.5rem;
  width: 80%;
`;

SelectInput.defaultProps = {
  disabled: false,
  loading: false,
  long: false,
};

SelectInput.propTypes = {
  selected: func.isRequired,
  name: string.isRequired,
  disabled: bool,
  children: string.isRequired,
  data: object.isRequired,
  loading: bool,
  isClearable: bool,
  long: bool,
  initial: string,
  dataTestId: string,
};

export default SelectInput;
