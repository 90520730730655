import React from 'react';
import { string } from 'prop-types';

const LegalIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="92"
      fill="none"
      viewBox="0 0 34 35"
    >
      <path
        fill={color}
        d="M28 3.98a1.242 1.242 0 00-.24-.31 1.63 1.63 0 00-.93-.28h-8.76V1.13a1.13 1.13 0 10-2.26 0v2.26H7.05a1.63 1.63 0 00-.93.28 1.24 1.24 0 00-.24.31L0 14.15v.57a6.2 6.2 0 006.82 6.17 6.37 6.37 0 005.6-6.45v-.29L8.17 6.78h7.64v23.71H8.47a.56.56 0 00-.56.56v3.39a.55.55 0 00.56.56h16.94a.549.549 0 00.56-.56v-3.39a.56.56 0 00-.56-.56h-7.34V6.78h7.64l-4.25 7.37v.29a6.37 6.37 0 005.6 6.45 6.2 6.2 0 006.82-6.17v-.57L28 3.98zM9.82 14.15H2.61l3.6-6.23 3.61 6.23zm14.24 0l3.61-6.23 3.6 6.23h-7.21z"
      ></path>
    </svg>
  );
};

LegalIcon.defaultProps = {
  color: '#ccc',
};

LegalIcon.propTypes = {
  color: string,
};

export default LegalIcon;
