import React from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Layout from '../../models/DashboardLayout/views/Layout';
import CRMCustomerOverview from './CRMCustomerOverview';
import CRMCustomerDetail from './CRMCustomerDetail';
import CRMLeadDetail from './CRMLeadDetail';
import CRMCustomerContracts from './CRMCustomerContracts';
import CRMOfferDetail from './CRMOfferDetail';
import CRMOfferRevisions from './CRMOfferRevisions';
import CRMContractDetail from './CRMContractDetail';
import CRMRiskObjectTypes from './CRMRiskObjectTypes';
import CRMContractTypes from './CRMContractTypes';
import CRMAffinitiesOverview from './CRMAffinitiesOverview';
import CRMContractRevisions from './CRMContractRevisions';

const CRMRouter = () => {
  let history = useHistory();

  history.listen((location, action) => {
    window.scrollTo(0, 0);
  });

  return (
    <StyledLayout showTop={true}>
      <Switch>
        <Redirect
          exact
          from="/customer-management"
          to="/sales-management/customers/1"
        />
        <Redirect
          exact
          from="/sales-management"
          to="/sales-management/customers/1"
        />
        <Redirect
          exact
          from="/sales-management/customers"
          to="/sales-management/customers/1"
        />
        <Redirect
          exact
          from="/customer-management/contracts"
          to="/sales-management/contracts/contracts/1"
        />
        <Redirect
          exact
          from="/sales-management/contracts"
          to="/sales-management/contracts/contracts/1"
        />
        <Redirect
          exact
          from="/sales-management/contracts/contracts"
          to="/sales-management/contracts/contracts/1"
        />
        <Redirect
          exact
          from="/customer-management/contracts/leads"
          to="/sales-management/contracts/leads/1"
        />
        <Redirect
          exact
          from="/sales-management/contracts/leads"
          to="/sales-management/contracts/leads/1"
        />
        <Route
          path={'/sales-management/customers/:page'}
          component={CRMCustomerOverview}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/case/:contractCaseId/contract/:contractId/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/lead/:leadId/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/offer/:offerId/:offerRevision/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/case/:caseId/contract/:contractId/addendums'
          }
          key={history.location.pathname}
          component={CRMContractRevisions}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/case/:caseId/contract/:contractId'
          }
          key={history.location.pathname}
          component={CRMContractDetail}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/offer/:id/:revision/revisions'
          }
          key={history.location.pathname}
          component={CRMOfferRevisions}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/offer/:id/:revision'
          }
          key={history.location.pathname}
          component={CRMOfferDetail}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/contracts/lead/:leadId/'
          }
          component={CRMLeadDetail}
        />
        <Route
          path={'/sales-management/customer/:prospectId/contracts'}
          component={CRMCustomerContracts}
        />
        <Route
          path={
            '/sales-management/customer/:prospectId/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={'/sales-management/customer/:prospectId'}
          component={CRMCustomerDetail}
        />
        <Route
          path={'/sales-management/contracts/:type/:page'}
          component={CRMContractTypes}
        />
        <Route
          path={'/sales-management/offer/:id/:revision/revisions'}
          component={CRMOfferRevisions}
        />
        <Route
          path={
            '/sales-management/offer/:offerId/:offerRevision/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={'/sales-management/offer/:id/:revision'}
          key={history.location.pathname}
          component={CRMOfferDetail}
        />
        <Route
          path={
            '/sales-management/lead/:leadId/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={'/sales-management/lead/:leadId'}
          component={CRMLeadDetail}
        />
        <Route
          path={'/sales-management/case/:caseId/contract/:contractId/addendums'}
          component={CRMContractRevisions}
        />
        <Route
          path={
            '/sales-management/case/:contractCaseId/contract/:contractId/risk-object/:riskObjectType/:id/:revision/:editing?'
          }
          key={history.location.pathname}
          component={CRMRiskObjectTypes}
        />
        <Route
          path={'/sales-management/case/:caseId/contract/:contractId'}
          key={history.location.pathname}
          component={CRMContractDetail}
        />
        <Route
          path={
            '/sales-management/risk-object/:riskObjectType/:id/:revision/:offerId?/:offerRevision?/:editing?'
          }
          component={CRMRiskObjectTypes}
        />
        <Route
          path={'/sales-management/affinities'}
          component={CRMAffinitiesOverview}
        />
      </Switch>
    </StyledLayout>
  );
};

const StyledLayout = styled(Layout)`
  width: 100%;
`;

export default CRMRouter;
