/* stylelint-disable value-keyword-case */
import React, { useState } from 'react';
import styled from 'styled-components';
import Checkmark from '../../../../assets/images/dashboard/checkmark';
import AffiliationPopUp from '../../../components/AffiliationPopUp';
import { array, string, bool, func, shape, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getNestedObject } from '../../../services/objectService';
import {
  IconCarFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconLegalFilled,
} from 'wg-fe-ui';

const Affinity = ({
  name,
  label,
  affiliationData,
  logo,
  integrations,
  required_nrs,
  required_integrations,
  insurances,
  needs_verification,
  needs_access_request,
  checked,
  reload,
}) => {
  const [toggle, setToggle] = useState(false);
  const { t } = useTranslation();

  function getIcon(name) {
    switch (name) {
      case 'car':
        return <IconCarFilled color="#e4e4e4" size={35} />;
      case 'family':
        return <IconFamilyFilled color="#e4e4e4" size={35} />;
      case 'home':
        return <IconPropertyFilled color="#e4e4e4" size={35} />;
      case 'legal':
        return <IconLegalFilled color="#e4e4e4" size={35} />;
      default:
        break;
    }
  }

  return (
    <>
      <Label
        key={name}
        checked={checked}
        onClick={() => setToggle(!toggle)}
        htmlFor={name}
      >
        <LabelContentContainer>
          <CheckmarkContainer checked={checked}>
            {checked ? <Checkmark color="white" /> : ''}
          </CheckmarkContainer>
          <LogoContainer>
            {logo ? (
              <Logo src={logo} alt="" />
            ) : (
              <div>
                <FakeLogo>{name}</FakeLogo>
              </div>
            )}
          </LogoContainer>
          <Content>
            <InsuranceName>{label}</InsuranceName>
            {getNestedObject(affiliationData, ['nr']) ? (
              <ProducerNr>
                {t('Producer number')}:{' '}
                {getNestedObject(affiliationData, ['nr'])}
              </ProducerNr>
            ) : (
              ``
            )}
          </Content>
        </LabelContentContainer>
        <Icons checked={checked}>
          {insurances.map(insurance => getIcon(insurance))}
        </Icons>
      </Label>
      {toggle ? (
        <AffiliationPopUp
          data-test-id={`underwriting_termination_popup`}
          checked={checked}
          toggle={toggle}
          reloadResults={reload}
          required_nrs={required_nrs}
          required_integrations={required_integrations}
          integrations={integrations}
          name={name}
          logo={logo}
          affiliationData={affiliationData}
          insurances={insurances}
          needs_verification={needs_verification}
          needs_access_request={needs_access_request}
          toggleDrawer={() => setToggle(!toggle)}
        />
      ) : null}
    </>
  );
};

const FakeLogo = styled.div`
  height: 100%;
  max-width: 100%;
  padding: 0 1rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.brand.primary};
  text-transform: uppercase;
  display: flex;
  overflow: hidden;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 3rem;
  font-weight: 900;
`;

const ProducerNr = styled.p`
  font-size: 1.2rem;
  color: #505050;
`;

const InsuranceName = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: #505050;
  margin-bottom: 0.5rem;
`;

const Label = styled.label`
  background: white;
  border: 1px solid #d3d4d8;
  box-sizing: border-box;
  /* Drop Shadow / Pressed */
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 1rem 2rem;
  transition: all 0.2s ease;

  ${({ theme, checked }) =>
    checked
      ? `
    border: 1px solid ${theme.brand.primary};
    box-sizing: border-box;
    /* Drop Shadow / Pressed */

    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  `
      : ``}

  &:hover {
    cursor: pointer;
  }

  &:active {
    transform: scale(0.95);
  }
`;

const LabelContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr 5fr;
  align-items: center;
  height: 8.5rem;
`;

const CheckmarkContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${({ theme, checked }) => (checked ? theme.brand.primary : '#d3d4d8')};
  border-radius: 0.5rem;
  background-color: ${({ theme, checked }) =>
    checked ? theme.brand.primary : 'white'};

  & svg {
    width: 80%;
    height: 80%;
    flex: 0 0 80%;
  }
`;

const LogoContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;

  & > div {
    height: 100%;
  }
`;
const Logo = styled.img`
  width: 90%;
  height: 90%;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
`;
const Content = styled.div`
  padding-left: 2rem;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: solid 1px
    ${({ checked, theme }) => (checked ? theme.brand.primary : '#F0F1F3')};
  padding-top: 5%;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;

  ${({ checked, theme }) =>
    `> svg {
        padding-left: 0.35rem;
        padding-right: 0.35rem;
        
        > path {
          fill: ${checked ? theme.brand.primary : '#e4e4e4'}
        }
      }


      :first-of-type {
        padding-right: 0.35rem;
        padding-left: 0;
      }

      :last-of-type {
        padding-left: 0.35rem;
        padding-right: 0;
      }
    }`}
`;

Affinity.defaultProps = {
  logo: '',
  inputs: [],
  insurances: [],
  needs_verification: '',
};

Affinity.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  logo: string,
  integrations: object,
  affiliationData: shape(),
  required_integrations: array,
  required_nrs: array,
  insurances: array,
  needs_verification: bool,
  needs_access_request: bool,
  checked: bool,
  reload: func,
};

export default Affinity;
