import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import cogoToast from 'cogo-toast';
import { isEqual, cloneDeep } from 'lodash';
import { useDebouncedCallback } from 'use-debounce';

import { TopNavigationItems } from '../../services/context/TopNavigationBarContext';
import DateFilterComponent from '../../components/DateFilterComponent';
import {
  getBrokerDistribution,
  getBrokerData,
} from '../../services/brokerDataService';
import { getMeData } from '../../services/meDataService';
import {
  getFilteredProspectsByDistributionId,
  deleteProspectById,
} from '../../services/apiRouterService';

import { formatCRMAddress } from '../../services/CRMService';
import { FlagsProvider } from '../../hoc/FlagsProviderHoc.jsx';
import {
  ActionButton,
  CheckBox_v2 as CheckBox,
  CollapsibleSidebar,
  IconActionMessage,
  IconActionPhone,
  OrderTable,
  OverviewTable,
  Pagination,
  QuickActionSubMenu,
  SearchInput,
  RiskObjectLabel,
  IconCarFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconStats,
  IconHeavyScooter,
  IconLegalFilled,
  LoadingSpinner,
  TypeLabel,
} from 'wg-fe-ui';

import Frame from '../../../assets/images/Frame.svg';
import NewProspectModal from '../../components/CRMCustomerOverviewNewProspectModal';
import CheckBoxContainer from '../../components/CheckBoxContainer';
// import CheckBox from '../components/CheckBox_v2';
import { shape } from 'prop-types';
import { useLocation } from 'react-router';
import { stringify } from 'query-string';

const clientTypeLength = 3;
const statutesLength = 2;
const initialFilters = {
  statute: {
    CUSTOMER: true,
    COMPANY: true,
  },
  client_type: {
    PROSPECT: true,
    LEAD: true,
  },
  created_at_ge: null,
  created_at_le: null,
};

const CRMCustomerOverview = ({ match }) => {
  const { setNavTabs } = useContext(TopNavigationItems);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProspects, setIsLoadingProspects] = useState(true);
  const location = useLocation();
  const [topSearchValue, setTopSearchValue] = useState(
    location?.state?.topSearch || '',
  );
  const { t } = useTranslation();
  const [haveFiltersChanged, setHaveFiltersChanged] = useState(false);
  const [activeFilters, setActiveFilters] = useState(
    location?.state?.filters || initialFilters,
  );
  const [previousFilters, setPreviousFilters] = useState(
    location?.state?.filters || initialFilters,
  );
  const configCatClient = useContext(FlagsProvider);

  const [paginationData, setPaginationData] = useState({});
  const [retrievedData, setRetrievedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [amountOfEntries, setAmountOfEntries] = useState('');
  const [distributionId, setDistributionId] = useState();
  const [isContractsEnabled, setIsContractsEnabled] = useState(null);
  const history = useHistory();

  const handleApplyFilters = async filters => {
    setIsLoadingProspects(true);
    if (!filters) filters = activeFilters;
    setPreviousFilters(cloneDeep(filters));
    setHaveFiltersChanged(false);
    getFilteredProspects(filters);
  };

  async function getFilteredProspects(filters) {
    setIsLoadingProspects(true);
    const { id } = await getBrokerDistribution();
    if (!filters) filters = activeFilters;
    setDistributionId(id);
    const paramDefaultLength = {
      statute: statutesLength,
      client_type: clientTypeLength,
    };
    const objectToConvertToParams = {};
    Object.keys(filters).forEach(key => {
      switch (key) {
        case 'statute':
        case 'client_type':
          if (
            Object.keys(filters[key]).filter(item => item).length <
            paramDefaultLength[key]
          ) {
            objectToConvertToParams[key] = Object.keys(filters[key]).filter(
              item => item,
            );
          }
          break;
        case 'created_at_ge':
        case 'created_at_le':
          if (filters[key]) {
            objectToConvertToParams[key] = filters[key]?.toISOString();
          }
          break;
        default:
          break;
      }
    });

    let [response, status] = await getFilteredProspectsByDistributionId(
      id,
      match.params.page || 1,
      stringify(objectToConvertToParams),
      topSearchValue,
    );

    if (status !== 200) {
      return console.error('Error Response: ', response);
    }

    // If the current page is not 1 and has no items in the response,
    // Redirect to page one so the call is redone to see if there are any items
    if (match.params.page > 1 && response?.items?.length === 0)
      return history.push({
        pathname: '/sales-management/customers/1',
        filters,
      });
    setRetrievedData(await response.items);
    setPaginationData(await response.pagination);
    setIsLoading(false);
    setIsLoadingProspects(false);
  }

  const [debounceFetchProspects] = useDebouncedCallback(
    getFilteredProspects,
    500,
  );

  useEffect(() => {
    setIsLoadingProspects(true);
    if (location?.state?.filters) {
      setActiveFilters(location?.state?.filters);
      setPreviousFilters(location?.state?.filters);
      getFilteredProspects(location?.state?.filters);
    } else {
      getFilteredProspects();
    }
  }, [match.params.page, location?.state?.filters]);

  useEffect(() => {
    setNavTabs([]);
    setFeatureToggles();
  }, []);

  useEffect(() => {
    showAmountOfEntries(paginationData);
  }, [paginationData]);

  const showAmountOfEntries = ({ amount, pagelen, page }) => {
    setAmountOfEntries(
      `${page * pagelen - pagelen + 1} ${t('pagination.to')} ${page *
        pagelen} ${t('pagination.of')} ${amount}`,
    );
  };

  const handleTopSearch = e => {
    setTopSearchValue(e);
    debounceFetchProspects();
  };

  const handleTopSearchClear = () => {
    setTopSearchValue('');
    debounceFetchProspects();
  };

  const prospectsClickHandler = (e, prospect) => {
    e.preventDefault();
    history.push({
      pathname: `/sales-management/customer/${prospect.id}`,
      state: {
        filters: { ...previousFilters },
        page: match.params.page,
        topSearch: topSearchValue,
      },
    });
  };

  const phoneClickHandler = (e, phonenr) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `tel:${phonenr}`;
  };

  const emailClickHandler = (e, email) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `mailto:${email}`;
  };

  function getRiskObjectIcon(key) {
    switch (key) {
      case 'cars':
        return <IconCarFilled />;
      case 'families':
        return <IconFamilyFilled />;
      case 'residences':
        return <IconPropertyFilled />;
      case 'teachers':
        return <IconStats />;
      case 'two_wheelers':
        return <IconHeavyScooter />;
      case 'legal':
        return <IconLegalFilled />;
      default:
        return '';
    }
  }

  const checkValuesInObjectIsZero = object => {
    if (object != null || undefined) {
      return Object.keys(object).every(function(key) {
        return object[key] === 0;
      });
    }
  };

  const deleteProspect = async prospect => {
    const [response, status] = await deleteProspectById(
      distributionId,
      prospect.id,
    );

    if (status === 200) {
      setRetrievedData(
        retrievedData.filter(object => object.prospect.id !== prospect.id),
      );
      cogoToast.success(
        <>
          <span style={{ fontWeight: 'bold' }}>{`${
            prospect.name
              ? prospect.name
              : `${prospect.first_name} ${prospect.last_name}`
          }`}</span>{' '}
          {t(`has been successfully deleted`)}
        </>,
      );
    } else if (response.msg != null) {
      const { hide } = cogoToast.error(
        t(response.msg, {
          onClick: () => {
            hide();
          },
        }),
      );
      return console.error('ERROR', response);
    } else {
      cogoToast.error(t('Something went wrong'));
      return console.error('Error Response: ', response);
    }
  };

  const toggleAllCheckBoxes = (checkboxNames, value, type) => {
    const newFilters = cloneDeep(activeFilters);
    checkboxNames.forEach(key => {
      if (!value) delete newFilters[type][key];
      else newFilters[type][key] = value;
    });
    setActiveFilters(newFilters);
    if (isEqual(previousFilters, newFilters)) {
      setHaveFiltersChanged(false);
    } else {
      setHaveFiltersChanged(true);
    }
  };

  const handleFilterChange = (e, key) => {
    setActiveFilters(prevValue => {
      let newFilters = cloneDeep(prevValue);
      if (key) {
        newFilters[key][e.name] = e.value;
        if (!e.value) delete newFilters[key][e.name];
      } else {
        newFilters[e.name] = e.value;
        if (!e.value) delete newFilters[e.name];
      }
      if (isEqual(previousFilters, newFilters)) {
        setHaveFiltersChanged(false);
      } else {
        setHaveFiltersChanged(true);
      }
      return newFilters;
    });
  };

  async function setFeatureToggles() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};

    setIsContractsEnabled(
      await configCatClient.getValueAsync('contracts', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );
  }

  if (isLoading) {
    return (
      <LoadingContainer data-test-id="loading_spinner">
        <LoadingSpinner />
      </LoadingContainer>
    );
  }

  return (
    <MainContainer>
      <NewProspectModal
        getProspects={getFilteredProspects}
        modalState={[showModal, setShowModal]}
      />
      <TopBar>
        <TopBarContainer>
          <TopBarLeft></TopBarLeft>

          <TopBarRight>
            <TopBarSearch
              placeholder={t('Search for a name')}
              onChange={event => {
                handleTopSearch(event);
              }}
              text={topSearchValue}
              onClear={() => handleTopSearchClear()}
              data-test-id={`wallet_search`}
            />

            <TopBarAction>
              <ActionButton
                onClick={() => setShowModal(!showModal)}
                data-test-id={`new_prospect_button`}
              >
                {t('New prospect')}
              </ActionButton>
            </TopBarAction>
          </TopBarRight>
        </TopBarContainer>
      </TopBar>

      <MainContent>
        <Sidebar>
          <CollapsibleSidebar
            label={t('Filters')}
            open
            data-test-id="wallet_filter_container_open"
          >
            <CheckBoxContainer
              label={t('Client type')}
              toggleAllCheckBoxes={(names, value) =>
                toggleAllCheckBoxes(names, value, 'client_type')
              }
              defaultChecked={
                activeFilters?.client_type?.LEAD &&
                activeFilters?.client_type?.PROSPECT
              }
              data-test-id="wallet_filter_client_type_container"
            >
              <StyledCheckBox
                name="LEAD"
                checked={activeFilters?.client_type?.LEAD}
                onChange={e => handleFilterChange(e, 'client_type')}
                data-test-id="wallet_filter_client_type_lead"
              >
                {/* <IconWitnessFilled size={18} color="#505050" />
                    <CheckBoxSpacer /> */}
                Lead
              </StyledCheckBox>
              <StyledCheckBox
                name="PROSPECT"
                checked={activeFilters?.client_type?.PROSPECT}
                onChange={e => handleFilterChange(e, 'client_type')}
                data-test-id="wallet_filter_client_type_offer"
              >
                {/* <IconDocumentFilled size={18} color="#505050" />
                    <CheckBoxSpacer /> */}
                Prospect
              </StyledCheckBox>
              {isContractsEnabled ? (
                <StyledCheckBox
                  name="CONTRACT"
                  checked={activeFilters?.client_type?.CONTRACT}
                  onChange={e => handleFilterChange(e, 'client_type')}
                  data-test-id="wallet_filter_client_type_contract"
                >
                  {/* <IconDocumentFilled size={18} color="#505050" />
                    <CheckBoxSpacer /> */}
                  {t('Contracts')}
                </StyledCheckBox>
              ) : null}
            </CheckBoxContainer>

            <SidebarSpacer />

            <CheckBoxContainer
              label={t('Entity type')}
              toggleAllCheckBoxes={(names, value) =>
                toggleAllCheckBoxes(names, value, 'statute')
              }
              defaultChecked={
                activeFilters?.statute?.CUSTOMER &&
                activeFilters?.statute?.COMPANY
              }
              data-test-id="wallet_filter_entity_type_container"
            >
              <StyledCheckBox
                name="CUSTOMER"
                checked={activeFilters?.statute?.CUSTOMER}
                onChange={e => handleFilterChange(e, 'statute')}
                data-test-id="wallet_filter_entity_type_customer"
              >
                {t('Natural person')}
              </StyledCheckBox>
              <StyledCheckBox
                name="COMPANY"
                checked={activeFilters?.statute?.COMPANY}
                onChange={e => handleFilterChange(e, 'statute')}
                data-test-id="wallet_filter_entity_type_company"
              >
                {t('Legal person')}
              </StyledCheckBox>
            </CheckBoxContainer>

            <SidebarSpacer />

            <DateFilterComponent
              startDate={activeFilters.created_at_ge}
              endDate={activeFilters.created_at_le}
              name={'filterDateCreated'}
              setStartDate={value =>
                handleFilterChange({ name: 'created_at_ge', value })
              }
              setEndDate={value =>
                handleFilterChange({ name: 'created_at_le', value })
              }
            >
              {t('Created between')}
            </DateFilterComponent>

            <SidebarSpacer />

            <ActionButton
              disabled={!haveFiltersChanged}
              fullwidth
              icon={null}
              level="primary"
              onClick={() => {
                handleApplyFilters();
              }}
              data-test-id="wallet_filter_submit"
            >
              {t('Apply filters')}
            </ActionButton>
          </CollapsibleSidebar>
        </Sidebar>
        <Content>
          {isLoadingProspects ? (
            <LoadingContainerProspects>
              <LoadingSpinner />
            </LoadingContainerProspects>
          ) : topSearchValue !== '' && retrievedData?.length === 0 ? (
            <EmptyState>
              <img src={Frame} alt="Test" />
              <h1>{t('No search results found')}</h1>
              <p>
                {`${t(
                  'We could not find anything with the following search term',
                )}: `}{' '}
                <strong> {topSearchValue}</strong>
              </p>
            </EmptyState>
          ) : retrievedData?.length === 0 ? (
            <EmptyState>
              <img src={Frame} alt="Test" />
              <h1>{t('Add your first prospect')}</h1>
              <p>{t('Add your first prospect text1')}</p>
              <ButtonWrapper>
                <ActionButton onClick={() => setShowModal(!showModal)}>
                  {t('New prospect')}
                </ActionButton>
              </ButtonWrapper>
            </EmptyState>
          ) : (
            <>
              <OrderTable>
                <OverviewTable.Head otherProps={{}}>
                  <OverviewTable.HeaderCell width="25%">
                    {t('Client / Company name')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="12%">
                    {t('Entity type')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="38%">
                    {t('Address')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="15%">
                    {t('Risk objects')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="15%">
                    {t('Type')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="10%">
                    {t('Contact')}
                  </OverviewTable.HeaderCell>
                  <OverviewTable.HeaderCell width="5%"></OverviewTable.HeaderCell>
                </OverviewTable.Head>

                <OrderTable.Body>
                  {retrievedData.map((retrievedProspect, i) => {
                    const { prospect } = retrievedProspect;

                    let address = '-';
                    if (prospect.address != null) {
                      address = formatCRMAddress(prospect.address);
                    }

                    return (
                      <StyledTableRow
                        key={prospect.id}
                        onClick={e => prospectsClickHandler(e, prospect)}
                      >
                        <ProspectNameCell data-test-id={`customer_${i}_name`}>
                          {prospect.name
                            ? prospect.name
                            : `${prospect.first_name} ${prospect.last_name}`}
                        </ProspectNameCell>
                        <OrderTable.BodyCell
                          data-test-id={`customer_${i}_person_type`}
                        >
                          {prospect.type === 'COMPANY'
                            ? t('Legal person')
                            : t('Natural person')}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell
                          data-test-id={`customer_${i}_address`}
                        >
                          {address}
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <RiskObjectsWrapper>
                            {!checkValuesInObjectIsZero(
                              prospect?.amount_of_risk_objects,
                            ) ? (
                              Object.keys(prospect?.amount_of_risk_objects).map(
                                key => {
                                  if (
                                    prospect?.amount_of_risk_objects[key] === 0
                                  )
                                    return null;
                                  return (
                                    <RiskObjectLabel
                                      key={key}
                                      data-test-id={`smt_risk_object_${key}`}
                                      amount={
                                        prospect?.amount_of_risk_objects[key]
                                      }
                                      icon={getRiskObjectIcon(key)}
                                    />
                                  );
                                },
                              )
                            ) : (
                              <RiskObjectLabel
                                key={'legal'}
                                data-test-id={`smt_risk_object_legal`}
                                amount="1"
                                icon={getRiskObjectIcon('legal')}
                              />
                            )}
                          </RiskObjectsWrapper>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell
                          data-test-id={`customer_${i}_prospect_or_lead`}
                        >
                          <TypeLabel
                            text={
                              prospect.amount_of_contracts > 0
                                ? t('Customer')
                                : prospect.amount_of_leads > 0
                                ? 'Lead'
                                : 'Prospect'
                            }
                            // text={
                            //   prospect.amount_of_leads === 1
                            //     ? 'Lead'
                            //     : 'Prospect'
                            // }
                            type={
                              prospect.amount_of_contracts > 0
                                ? 'customer'
                                : prospect.amount_of_leads > 0
                                ? 'lead'
                                : 'prospect'
                            }
                          />
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <RiskObjectsWrapper>
                            <ContactButton
                              onClick={e =>
                                phoneClickHandler(e, prospect.telephonenr)
                              }
                              data-test-id={`customer_${i}_phone`}
                            >
                              <IconActionPhone size={25} />
                            </ContactButton>

                            <ContactButton
                              onClick={e =>
                                emailClickHandler(e, prospect.email)
                              }
                              data-test-id={`customer_${i}_email`}
                            >
                              <IconActionMessage size={25} />
                            </ContactButton>
                          </RiskObjectsWrapper>
                        </OrderTable.BodyCell>
                        <OrderTable.BodyCell>
                          <ActionMenuWrapper>
                            <QuickActionSubMenu
                              onClick={e => e.stopPropagation()}
                              otherProps={{}}
                              data-test-id={`customer_${i}_options`}
                            >
                              <QuickActionSubMenu.SubMenuItem
                                label={t('View customer')}
                                onClick={e =>
                                  prospectsClickHandler(e, prospect)
                                }
                                type=""
                              />
                              {/* <QuickActionSubMenu.SubMenuItem
                                label="Edit customer"
                                onClick={function noRefCheck() {}}
                                type=""
                              /> */}
                              <QuickActionSubMenu.SubMenuItem
                                label={t('Delete customer')}
                                onClick={() => deleteProspect(prospect)}
                                type="danger"
                              />
                            </QuickActionSubMenu>
                          </ActionMenuWrapper>
                        </OrderTable.BodyCell>
                      </StyledTableRow>
                    );
                  })}
                </OrderTable.Body>
              </OrderTable>
            </>
          )}
          <ContentFooter>
            <span className="records">
              {t('pagination.showing')} <strong>{amountOfEntries}</strong>{' '}
              {t('pagination.entries')}
            </span>
            <Pagination
              base="/sales-management/customers/"
              currentPage={paginationData.page}
              pageLength={15}
              prevText={t('PrevPGN')}
              nextText={t('NextPGN')}
              onClick={() => setIsLoadingProspects(true)}
              totalPages={paginationData.pages}
            />
          </ContentFooter>
        </Content>
      </MainContent>
    </MainContainer>
  );
};

// const NewProspectButton = styled(ActionButton)`
//   visibility: hidden;
// `;

const ButtonWrapper = styled.div`
  margin-top: 2.4rem;
`;

const LoadingContainerProspects = styled.div`
  width: 100%;
  flex: 0 0 100%;
  min-height: 70vh;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  position: sticky;
  top: 8rem;
  z-index: 9;
  border-bottom: 1px solid #dbdbdb;

  @media screen and (max-width: 1240px) {
    top: 8rem;
  }
`;

const TopBarContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  align-items: center;
  display: flex;
  padding: 12px 30px;
`;

const TopBarLeft = styled.div`
  flex: 0 0 220px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TopBarRight = styled.div`
  margin-left: 32px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
`;

const TopBarSearch = styled(SearchInput)`
  background-color: #f7f7f7;
  padding-left: 3.5rem;
`;

const TopBarAction = styled.div`
  flex: 0 1 auto;
  padding-left: 25px;
  margin-left: 25px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-left: 2px solid #ccc;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 55px 30px;
  position: relative;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-bottom: 2rem;
  margin-right: 32px;
  flex: 0 0 220px;

  @media screen and (max-width: 1500px) {
    flex: 0 0 150px;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Content = styled.div`
  flex: 1 0 auto;
  max-width: 95rem;
`;

const SidebarSpacer = styled.div`
  margin-top: 20px;
`;

const RiskObjectsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  > div {
    flex: 0 0 33.33%;
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  .records {
    font-size: 1.4rem;
    line-height: 130%;
    color: ${({ theme }) => theme.ui.disabled};

    strong {
      font-weight: 900;
    }
  }
`;

const ActionMenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
`;

const ContactButton = styled.a`
  cursor: pointer;
  margin-right: 9px;

  svg {
    path {
      fill: ${({ theme }) => theme.ui.interactive};
      stroke: ${({ theme }) => theme.ui.interactive};
    }
  }

  &:hover {
    svg path {
      fill: ${({ theme }) => theme.brand.primary};
      stroke: ${({ theme }) => theme.brand.primary};
    }
  }
`;

const StyledTableRow = styled(OrderTable.Row)`
  td {
    padding-right: 1rem;
    word-break: break-word;
  }
  & ${ActionMenuWrapper} {
    visibility: hidden;
  }

  &:hover {
    ${ActionMenuWrapper} {
      visibility: visible;
    }
    ${RiskObjectsWrapper} > ${ContactButton} {
      svg path {
        fill: black;
        stroke: black;

        &:hover {
          svg path {
            fill: ${({ theme }) => theme.brand.primary};
            stroke: ${({ theme }) => theme.brand.highlight};
          }
        }
      }
    }
  }
`;

// const CheckBoxSpacer = styled.div`
//   width: 6px;
// `;

const EmptyState = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 55px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${({ theme }) => theme.font};
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.03em;
    color: black;
    margin-bottom: 10px;
  }

  p {
    font-family: ${({ theme }) => theme.font};
    font-size: 1.6rem;
    line-height: 135%;
    text-align: center;
    color: black;

    strong {
      font-weight: bold;
    }
  }
`;

const ProspectNameCell = styled(OrderTable.BodyCell)`
  text-transform: capitalize;
`;

const StyledCheckBox = styled(CheckBox)`
  margin-bottom: 6px;
`;

CRMCustomerOverview.propTypes = {
  match: shape({}).isRequired,
};

export default CRMCustomerOverview;
