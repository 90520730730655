import { BASE_URL } from '../../env.config';

export const requestInterceptor = ({
  axiosConfig, // axios.create({...})
  baseVersion, // v1
  apiPath, // marketplace
  apiVersion, // v1
}) => {
  return axiosConfig.interceptors.request.use(request => {
    const baseUrl = `${BASE_URL}${baseVersion}/api/${apiPath}/${apiVersion}/api`;
    const requestUrl = request.url;
    let url = `${baseUrl}${requestUrl}`;

    if (requestUrl.includes(baseUrl)) {
      url = requestUrl;
    }

    request.url = url;
    return request;
  });
};
