import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { string, bool, func } from 'prop-types';

import { H4, Body } from 'wg-fe-ui';
import QuoteGuaranteeIcons from './QuoteGuaranteeIcons.jsx';

const OfferedGuarantee = ({
  icon,
  tag,
  title,
  description,
  initialChecked,
  handleChange,
  disabled,
  ...otherProps
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(initialChecked);
  }, [initialChecked]);

  return (
    <StyledDiv
      onClick={() => {
        if (!disabled) {
          handleChange({
            name: tag,
            checked: !isSelected,
          });
          setIsSelected(!isSelected);
        }
      }}
      checked={isSelected}
      disabled={disabled}
      {...otherProps}
    >
      <GuaranteeHeader>
        <QuoteGuaranteeIcons type={icon} color={'#050505'} />
        <H4>{title}</H4>
      </GuaranteeHeader>
      <StyledBody light>{description}</StyledBody>
    </StyledDiv>
  );
};

const GuaranteeHeader = styled.div`
  display: flex;
  align-items: center;
  & > *:first-child {
    width: 3.5rem;
  }

  & > *:nth-child(2) {
    margin-left: 1rem;
    flex-grow: 1;
  }

  h4 {
    flex: 0;
    ${({ mandatory }) => mandatory && `color: #8990a3`}
  }

  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e4e4e4;
`;

const StyledBody = styled(Body)`
  margin-top: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.6rem;

  @media screen and (min-width: 1460px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1120px) {
    font-size: 1.4rem;
  }
`;

const StyledDiv = styled.div`
  background-color: white;
  /* Border is increased by 1px on checked, padding has to be 1px lower, otherwise elements move */
  padding: ${({ checked }) => (checked ? '15px 19px' : '16px 20px')};
  display: flex;
  flex-direction: column;
  border: ${({ checked, theme }) =>
    checked ? `2px solid ${theme.brand.primary}` : '1px solid #f0f1f3'};
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.05);
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.05);
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  height: 20rem;

  ${({ mandatory }) =>
    !mandatory &&
    css`
      :hover {
        transition: 0.2s ease box-shadow;
        box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
          0 16px 24px rgba(0, 0, 0, 0.06);
      }
    `}

  @media screen and (max-width: 760px) {
    height: 19.5rem;
  }

  @media screen and (max-width: 660px) {
    height: 15rem;
  }
`;

OfferedGuarantee.propTypes = {
  title: string,
  description: string,
  icon: string,
  tag: string,
  handleChange: func,
  initialChecked: bool,
  disabled: bool,
};

export default OfferedGuarantee;
