import * as Yup from 'yup';
import i18n from '../i18n';

export const string = {
  required: Yup.string()
    .required(i18n.t(`required`))
    .ensure(),
  notRequired: Yup.string().nullable(),
};

export const number = {
  required: Yup.number()
    .typeError(i18n.t(`You must specify a number`))
    .required(i18n.t(`required`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`You must specify a number`)),
};

export const date = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
    i18n.t(`not a valid date`),
  )
  .required(i18n.t(`required`));

export const dateNotRequired = Yup.string().matches(
  /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
  i18n.t(`not a valid date`),
);

export const dateWithOutYear = Yup.string()
  .matches(
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))$/,
    i18n.t(`not a valid date`),
  )
  .ensure()
  .required(i18n.t(`required`));

export const firstName = Yup.string()
  .required(i18n.t(`required`))
  .ensure()
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const lastName = Yup.string()
  .ensure()
  .required(i18n.t(`required`))
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const companyName = Yup.string()
  .ensure()
  .required(i18n.t(`required`))
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ_& ]{1,50}$/,
    i18n.t(`incorrect format`),
  )
  .min(1, i18n.t(`too short`))
  .max(50, i18n.t(`too long`));

export const cbe = Yup.string()
  .matches(/0[0-9]{3}.[0-9]{3}.[0-9]{3}/, i18n.t(`incorrect format`))
  .ensure()
  .required(i18n.t(`required`));

export const cbeNotRequired = Yup.string().matches(
  /0[0-9]{3}.[0-9]{3}.[0-9]{3}/,
  i18n.t(`incorrect format`),
);

export const email = Yup.string()
  .email(i18n.t(`email`))
  .required(i18n.t(`required`))
  .ensure()
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const emailNotRequired = Yup.string()
  .email(i18n.t(`email`))
  .nullable()
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const password = Yup.string()
  .required(i18n.t(`required`))
  .ensure()
  .min(2, i18n.t(`too short`))
  .max(100, i18n.t(`too long`));

export const year = {
  required: Yup.number()
    .typeError(i18n.t(`not a number`))
    .required(i18n.t(`required`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
  notRequired: Yup.number()
    .nullable()
    .typeError(i18n.t(`not a number`))
    .min(1885, i18n.t(`car too old`))
    .max(2030, i18n.t(`car too young`)),
};

export const licensePlate = Yup.string()
  .matches(/^[A-Za-z0-9 -]{1,20}$/, i18n.t('Please use a valid licence plate'))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`));

export const chassisNumber = {
  required: Yup.string()
    .matches(/^[A-Z0-9]{11,17}$/, i18n.t('Please use a valid chassis no'))
    .min(11, i18n.t(`too short`))
    .max(17, i18n.t(`too long`))
    .ensure()
    .required(i18n.t(`required`)),
  notRequired: Yup.string()
    .matches(/^[A-Z0-9]{11,17}$/, i18n.t('Please use a valid chassis no'))
    .min(11, i18n.t(`too short`))
    .max(17, i18n.t(`too long`))
    .nullable(),
};

export const vinNumber = Yup.string()
  .matches(/^[A-Z0-9]{17,17}$/, i18n.t(`incorrect format`))
  .ensure()
  .required(i18n.t(`required`));

export const captcha = Yup.string(i18n.t(`required`))
  .matches(/[0-9a-zA-Z_-]{40}/)
  .nullable()
  .required(i18n.t(`required`));

export const website = Yup.string(i18n.t(`required`))
  .matches()
  .nullable()
  .required(i18n.t(`required`));

export const numberplate = Yup.string(i18n.t(`required`));

export const nationalRegisterNr = Yup.string(i18n.t(`required`))
  .matches(
    /^[0-9][0-9].[0-9][0-9].[0-9][0-9]-[0-9][0-9][0-9].[0-9][0-9]$/,
    i18n.t(`incorrect format`),
  )
  .nullable();

export const street = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`),
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const streetnotRequired = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`),
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable();

export const zipcode = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1000, i18n.t(`too short`))
  .max(9999, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const zipcodeNotRequired = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1000, i18n.t(`too short`))
  .max(9999, i18n.t(`too long`))
  .nullable();

export const housenr = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1, i18n.t(`too short`))
  .max(99999999, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const housenrNotRequired = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(1, i18n.t(`too short`))
  .max(99999999, i18n.t(`too long`))
  .nullable();

export const boxNr = Yup.string()
  .matches(/[a-zA-Z0-9\- /]{1,20}/, i18n.t(`incorrect format`))
  .min(1, i18n.t(`too short`))
  .max(20, i18n.t(`too long`))
  .nullable();

export const city = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`),
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable()
  .required(i18n.t(`required`));

export const cityNotRequired = Yup.string()
  .matches(
    /^[0-9A-Za-zéèùàçâêîôûäëïöüÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\- '.&’]{1,150}$/,
    i18n.t(`incorrect format`),
  )
  .min(1, i18n.t(`too short`))
  .max(150, i18n.t(`too long`))
  .nullable();

export const bonusMalus = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(-2, i18n.t(`bm too low`))
  .max(22, i18n.t(`bm too high`))
  .nullable()
  .required(i18n.t(`required`));

export const vat_regime = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(0, i18n.t(`give percentage between 0 and 100`))
  .max(100, i18n.t(`give percentage between 0 and 100`))
  .nullable();

export const seats = Yup.number()
  .typeError(i18n.t(`not a number`))
  .min(2, i18n.t(`minSeats`))
  .max(10, i18n.t(`maxSeats`))
  .nullable()
  .required(i18n.t(`required`));
