import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Label, Alert, LinkBox } from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import {
  useCampaignById,
  useCampaignDispatch,
} from '../context/CampaignContext';
import { string } from 'prop-types';
import cogoToast from 'cogo-toast';

const NewCampaignModal = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useCampaignDispatch();
  const { url } = useCampaignById(id) || {};

  useEffect(() => {
    dispatch({ type: 'RELOAD_CAMPAIGNS' });
  }, []);

  return (
    <Container>
      <Alert icon type="warning">
        {t(
          'Not sure how to implement your new campaign? Click here to learn more about the different methods of implementation',
        )}
      </Alert>
      <Label>{t('Your campaigns URL')}</Label>
      <StyledLinkBox
        onClick={() =>
          cogoToast.success(t('Your email campaign has been copied'))
        }
        url={url}
      />
    </Container>
  );
};

const StyledLinkBox = styled(LinkBox)`
  div {
    background: #ffffff !important;
  }
`;

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  z-index: 100000;
  p {
    color: #8990a3;
    margin: 2rem 0 1rem;
  }
`;

NewCampaignModal.propTypes = {
  id: string,
};

export default NewCampaignModal;
