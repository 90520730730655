import React from 'react';

const shedIcon = () => (
  <svg
    width="17"
    height="23"
    viewBox="0 0 17 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.0957031" width="17" height="22.7938" fill="url(#pattern0)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2067 5.55899V19.8344C14.2067 19.9581 14.1064 20.058 13.9832 20.058H11.7494C11.6257 20.058 11.5259 19.9576 11.5259 19.8344V9.11705C11.5259 8.96753 11.4047 8.84633 11.2552 8.84633H4.48477C4.33525 8.84633 4.21405 8.96753 4.21405 9.11705V19.8344C4.21405 19.9581 4.11371 20.058 3.99052 20.058H1.75624C1.63256 20.058 1.53271 19.9576 1.53271 19.8344V5.55899C1.53271 5.4815 1.57295 5.40898 1.63901 5.36825L7.75224 1.60603C7.82426 1.56182 7.91467 1.56182 7.98669 1.60603L14.0999 5.36825C14.1665 5.40947 14.2067 5.4815 14.2067 5.55899Z"
      fill="white"
    />
    <path
      d="M9.46746 11.473H6.27201C5.89797 11.473 5.59497 11.17 5.59497 10.7959C5.59497 10.4219 5.89797 10.1189 6.27201 10.1189H9.46746C9.84149 10.1189 10.1445 10.4219 10.1445 10.7959C10.1445 11.17 9.84149 11.473 9.46746 11.473Z"
      fill="white"
    />
    <path
      d="M9.46746 14.3343H6.27201C5.89797 14.3343 5.59497 14.0313 5.59497 13.6573C5.59497 13.2832 5.89797 12.9802 6.27201 12.9802H9.46746C9.84149 12.9802 10.1445 13.2832 10.1445 13.6573C10.1445 14.0313 9.84149 14.3343 9.46746 14.3343Z"
      fill="white"
    />
    <path
      d="M9.46746 20.0577H6.27201C5.89797 20.0577 5.59497 19.7547 5.59497 19.3807C5.59497 19.0066 5.89797 18.7036 6.27201 18.7036H9.46746C9.84149 18.7036 10.1445 19.0066 10.1445 19.3807C10.1445 19.7542 9.84149 20.0577 9.46746 20.0577Z"
      fill="white"
    />
    <path
      d="M9.46746 17.1962H6.27201C5.89797 17.1962 5.59497 16.8932 5.59497 16.5192C5.59497 16.1452 5.89797 15.8422 6.27201 15.8422H9.46746C9.84149 15.8422 10.1445 16.1452 10.1445 16.5192C10.1445 16.8927 9.84149 17.1962 9.46746 17.1962Z"
      fill="white"
    />
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlinkHref="#image0" />
      </pattern>
      <image
        id="image0"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABZUAAAd9CAYAAAAfRbNsAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA"
      />
    </defs>
  </svg>
);
export default shedIcon;
