import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Section,
  H1,
  H2,
  H3,
  IconCarFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconLegalFilled,
  Body,
  //Alert,
  TertiaryButton,
} from 'wg-fe-ui';
import OfferedRisk from '../components/OfferedRisk.jsx';
import { useHistory } from 'react-router';
import ProFlowChooseRiskAndInsuranceDrivers from '../components/ProFlowChooseRiskAndInsuranceDrivers';
import { retrieveProFlowQuestions } from '../services/apiRouterService.js';
import { getBrokerData } from '../services/brokerDataService.js';
import { newStorageEntry, patchStorage } from '../services/storeService.js';
import queryString from 'query-string';
import TrialPopupOnBrokerLoginScreen from '../components/TrialPopupOnBrokerLoginScreen.jsx';
import { getMeData } from '../services/meDataService.js';
import { FlagsProvider } from '../hoc/FlagsProviderHoc.jsx';
import _ from 'lodash';
import ProFlowInsuranceAndGuarantee from '../components/ProFlowInsuranceAndGuarantee.jsx';
import ProFlowRiskAndInsuranceButton from '../components/ProFlowRiskAndInsuranceButton.jsx';

const ChooseRiskAndInsurance = () => {
  const { t } = useTranslation();
  const [chosenInsurances, setChosenInsurances] = useState([]);
  const [chosenRisks, setChosenRisks] = useState([]);
  const [chosenGuarantees, setChosenGuarantees] = useState({});
  const [riskOptions, setRiskOptions] = useState({});
  const [showTrial, setShowTrial] = useState(false);
  const [isProfFlowEnabled, setIsProfFlowEnabled] = useState(false);
  const [isCallantFeature, setIsCallantFeature] = useState(false);
  const [hasPiaEnabled, setHasPiaEnabled] = useState(false);
  const [proFlowCreationError, setProFlowCreationError] = useState(true);
  const [proFlowErrorMessage, setProFlowErrorMessage] = useState();
  const configCatClient = useContext(FlagsProvider);

  const history = useHistory();

  useEffect(() => {
    featureChecking();
  }, []);

  async function featureChecking() {
    const { me } = (await getMeData()) || {};
    const { broker } = (await getBrokerData()) || {};
    const canAccessPage = await configCatClient.getValueAsync(
      'canAccessPage',
      true,
      {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      },
    );
    setIsCallantFeature(!canAccessPage);

    setIsProfFlowEnabled(
      await configCatClient.getValueAsync('professionalFlow', false, {
        email: me?.email,
        custom: {
          plan: broker?.plan,
        },
      }),
    );

    // TODO: Port this to a real feature flag
    setHasPiaEnabled(me?.email === 'piatestaccount@wegroup.be');
  }

  function handleRisk({ value, name }) {
    let arr = [];
    if (value) {
      if (!chosenRisks.includes(name)) arr = [...chosenRisks, name];
    } else {
      if (chosenRisks.includes(name))
        arr = chosenRisks.filter(insurance => insurance !== name);
    }
    setChosenRisks(arr);
  }

  let icons = [
    { name: 'car', icon: IconCarFilled },
    { name: 'family', icon: IconFamilyFilled },
    { name: 'legal', icon: IconLegalFilled },
    { name: 'home', icon: IconPropertyFilled },
  ];

  if (isCallantFeature) {
    icons = [{ name: 'car', icon: IconCarFilled }];
  }
  const enabledRiskObjects = ['car', 'family', 'legal', 'home'];

  async function saveAndRenderRiskObjects() {
    if (!isProfFlowEnabled) {
      setShowTrial(true);
      return;
    }

    let options = {};

    if (chosenInsurances.includes('car')) {
      options.car = {
        drivers: riskOptions?.drivers,
        trailers: riskOptions?.trailers,
      };
    }

    const qs = queryString.stringify({
      company: chosenInsurances,
      insurance_type: chosenRisks,
      drivers: riskOptions?.drivers,
      trailers: riskOptions?.trailers,
    });

    const tagsPayload = { tags: chosenGuarantees };

    const [resp] = await retrieveProFlowQuestions(qs, tagsPayload);

    const payload = {
      risk_object: {
        selected: chosenRisks,
        options: options,
      },
      insurances: chosenInsurances,
      questions: resp.questions,
      ...tagsPayload,
    };

    await newStorageEntry('pro_flow', resp.id);
    await patchStorage('pro_flow', resp.id, payload);

    history.push(`/professional-flow/risk-objects/${resp.id}`);
  }

  function selectAllChosenRisks() {
    // All risks located in icons
    if (chosenRisks?.length === icons.length) {
      setChosenRisks([]);
    } else {
      setChosenRisks(_.map(icons, risk => risk.name));
    }
  }

  return (
    <Wrapper>
      {showTrial ? (
        <TrialPopupOnBrokerLoginScreen
          close={bool => setShowTrial(bool)}
          startScreen="cantUseProFlow"
        />
      ) : null}
      <PageHeader>
        <H1>{t('New tarification')} </H1>
        <H2>
          {t(
            'Create a new calculation with the selected risk objects and insurance companies',
          )}
        </H2>
      </PageHeader>

      <StyledSection>
        <TitleContainer>
          <div>
            <H3>{t('What risk objects would you like to tarifficate?')}</H3>
            <Body light>
              {t('Select which risico objects you would like to tarificate')}
            </Body>
          </div>
          <StyledTertiaryButton
            label={
              icons.length === chosenRisks?.length
                ? t('Deselect all')
                : t('Select all')
            }
            onClick={() => selectAllChosenRisks()}
          />
        </TitleContainer>
        <StyledContent>
          <OfferedPoliciesContainer>
            {icons.map(({ name, icon }) => {
              return (
                <OfferedRisk
                  key={name}
                  Logo={icon}
                  name={name}
                  label={name}
                  onChange={value => handleRisk(value)}
                  disabled={!enabledRiskObjects.includes(name)}
                  chosenRisks={chosenRisks}
                />
              );
            })}
          </OfferedPoliciesContainer>
        </StyledContent>
      </StyledSection>
      {chosenRisks.includes('car') && !isCallantFeature ? (
        <ProFlowChooseRiskAndInsuranceDrivers
          onChange={obj => setRiskOptions(obj)}
        />
      ) : null}

      <ProFlowInsuranceAndGuarantee
        riskOptions={riskOptions}
        chosenRisks={chosenRisks}
        chosenInsurances={chosenInsurances}
        setChosenInsurances={setChosenInsurances}
        chosenGuarantees={chosenGuarantees}
        setChosenGuarantees={setChosenGuarantees}
        isCallantFeature={isCallantFeature}
        hasPiaEnabled={hasPiaEnabled}
        setProFlowCreationError={setProFlowCreationError}
        setProFlowErrorMessage={setProFlowErrorMessage}
      />

      <ProFlowRiskAndInsuranceButton
        chosenRisks={chosenRisks}
        chosenGuarantees={chosenGuarantees}
        chosenInsurances={chosenInsurances}
        proFlowCreationError={proFlowCreationError}
        proFlowErrorMessage={proFlowErrorMessage}
        saveAndRenderRiskObjects={saveAndRenderRiskObjects}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 0 auto;
`;

const StyledTertiaryButton = styled(TertiaryButton)`
  outline: none;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OfferedPoliciesContainer = styled.div`
  display: grid;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;

  @media screen and (max-width: 965px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  p {
    text-align: center;
  }
`;

const StyledContent = styled(Section.Content)`
  ${props =>
    props.background
      ? `
    box-shadow: 0 2px 4px rgba(32, 32, 35, 0.1);
    border: 1px solid #e4e4e4;
  `
      : null}
`;

const PageHeader = styled(Section)`
  width: 100%;
  margin: 6rem auto 0;
  padding: 0 3rem;
`;

const StyledSection = styled(Section)`
  transition: opacity 0.2s ease-in, max-height 2.5s ease-out;
  margin: auto;
  padding: 0 3rem;
  margin-bottom: 5vh;
  margin-top: 6rem;
  width: 100%;
`;

export default ChooseRiskAndInsurance;
