import React, { useState } from 'react';
import ProFlowOffersFilterPriceRange from './ProFlowOffersFilterPriceRange';
import {
  ActionButton,
  CheckBox_v2 as CheckBox,
  CollapsibleSidebar,
} from 'wg-fe-ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { shape, func } from 'prop-types';

const ProFlowOffersFilters = ({ infoInsurances, onSubmit, initialFilters }) => {
  const [filters, setFilters] = useState(initialFilters);
  const { t, i18n } = useTranslation();
  const { guarantees, insurances, insurers, priceRange } = filters;

  return (
    <Filters>
      <StyledCollSideBar open label={t('companies')}>
        <StyledCollapsibleSidebar>
          {Object.entries(insurers).map(([insurer, checked]) => {
            return (
              <CheckBox
                disabled={Object.keys(insurers).length <= 1}
                key={insurer + '-insurer'}
                onChange={() => {
                  setFilters({
                    ...filters,
                    insurers: { ...filters.insurers, [insurer]: !checked },
                  });
                }}
                checked={checked}
                name={insurer + '-insurer'}
              >
                {infoInsurances?.[insurer]?.names?.[i18n.language]}
              </CheckBox>
            );
          })}
        </StyledCollapsibleSidebar>
      </StyledCollSideBar>
      <StyledCollSideBar open label={t('insurances')}>
        <StyledCollapsibleSidebar>
          {Object.entries(insurances).map(([insurance, checked]) => {
            return (
              <CheckBox
                key={insurance + '-insurance'}
                onChange={() => {
                  setFilters({
                    ...filters,
                    insurances: {
                      ...filters.insurances,
                      [insurance]: !checked,
                    },
                  });
                }}
                checked={checked}
                name={insurance + '-insurance'}
              >
                {t(`anon_pack_types.${insurance}`)}
              </CheckBox>
            );
          })}
        </StyledCollapsibleSidebar>
      </StyledCollSideBar>
      {Object.entries(guarantees).length > 0 && (
        <StyledCollSideBar open label={t('guarantees')}>
          <StyledCollapsibleSidebar>
            {Object.entries(guarantees).map(([guarantee, checked]) => {
              return (
                <CheckBox
                  key={guarantee + '-guarantee'}
                  onChange={() => {
                    setFilters({
                      ...filters,
                      guarantees: {
                        ...filters.guarantees,
                        [guarantee]: !checked,
                      },
                    });
                  }}
                  checked={checked}
                  name={guarantee + '-guarantee'}
                >
                  {t(`anon_pack_types.${guarantee}`)}
                </CheckBox>
              );
            })}
          </StyledCollapsibleSidebar>
        </StyledCollSideBar>
      )}
      <StyledCollSideBar open label={t('Price range')}>
        <ProFlowOffersFilterPriceRange
          min={priceRange?.min}
          max={priceRange?.max}
          values={priceRange?.values}
          onChange={({ min, max }) => {
            setFilters({
              ...filters,
              priceRange: {
                ...filters.priceRange,
                values: [min, max],
              },
            });
          }}
        />
      </StyledCollSideBar>
      <ActionButton
        fullwidth
        icon={null}
        level="primary"
        onClick={() => onSubmit(filters)}
      >
        {t('Apply filters')}
      </ActionButton>
    </Filters>
  );
};

const Filters = styled.div`
  margin-top: 2rem;
  display: block;
  position: sticky;
  top: 3rem;
  height: fit-content;
`;

const StyledCollSideBar = styled(CollapsibleSidebar)`
  margin-bottom: 3rem;
`;

const StyledCollapsibleSidebar = styled.div`
  margin-bottom: 3rem;
  span {
    display: grid;
    grid-template-columns: 25% 75%;
    p {
      background: #f0f1f3;
      border: 1px solid #e4e4e4;
      border-right: none;
      box-sizing: border-box;
      border-radius: 5px 0 0 5px;
      padding: 1.1rem;
      font-size: 14px;
      color: #8990a3;
    }
  }
  & > label {
    margin-bottom: 6px;
  }
`;

// ProFlowOffersFilters.defaultProps = {
//   filters: {
//     guarantees: {},
//     insurances: {},
//     insurers: {},
//     priceRange: {},
//   },
// };

ProFlowOffersFilters.propTypes = {
  initialFilters: shape({
    guarantees: shape(),
    insurances: shape(),
    insurers: shape(),
    priceRange: shape(),
  }),
  setFilters: func.isRequired,
  infoInsurances: shape(),
  onSubmit: func.isRequired,
};

export default ProFlowOffersFilters;
