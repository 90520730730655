import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { shape, string } from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Pagination,
  RiskObjectBar,
  IconCarFilled,
  IconPropertyFilled,
  IconFamilyFilled,
  IconHeavyScooter,
  IconStats,
  IconVanFilled,
  IconMotor,
  IconLightScooter,
  IconBikeFilled,
} from 'wg-fe-ui';

import { formatCRMAddress } from '../services/CRMService';
import { isObjectEmpty, sumObjectValues } from '../services/objectService';
import { useLocation } from 'react-router';

const CRMRiskObjectTabPage = ({ riskObjects, type, prospectId, leadId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [amounOfEntries, setAmountOfEntries] = useState('');

  const { pagination, items } = riskObjects || {};

  useEffect(() => {
    showAmountOfEntries(pagination);
  }, [pagination]);

  const showAmountOfEntries = ({ amount, pagelen, page }) => {
    setAmountOfEntries(
      `${page * pagelen - pagelen + 1} ${t('pagination.to')} ${page *
        pagelen} ${t('pagination.of')} ${amount}`,
    );
  };

  const riskObjClickHandler = (e, type, id, revision) => {
    e.preventDefault();
    let pathname = '';
    if (prospectId && leadId) {
      pathname = `/sales-management/customer/${prospectId}/contracts/lead/${leadId}/risk-object/${type}/${id}/${revision}`;
    } else if (prospectId) {
      pathname = `/sales-management/customer/${prospectId}/risk-object/${type}/${id}/${revision}`;
    } else {
      pathname = `/sales-management/lead/${leadId}/risk-object/${type}/${id}/${revision}`;
    }
    history.push({
      pathname,
      state: { ...location.state },
    });
  };

  function handleIconType(vehicleIconType) {
    const color = '#CCCCCC';
    switch (vehicleIconType) {
      case 'PASSENGER_CAR':
        return <IconCarFilled color={color} />;
      case 'LIGHT_COMMERCIAL_VEHICLE':
        return <IconVanFilled color={color} />;
      case 'MOTORCYCLE':
        return <IconMotor color={color} />;
      case 'LIGHT_SCOOTER':
        return <IconLightScooter color={color} />;
      case 'HEAVY_SCOOTER':
        return <IconHeavyScooter color={color} />;
      case 'MOPED':
        return <IconBikeFilled color={color} />;
      default:
        return <IconCarFilled color={color} />;
    }
  }

  return (
    <RiskObjectContainer data-test-id={`customer_detail_risk_object_${type}`}>
      {items.length > 0
        ? items.map(item => {
            switch (type) {
              case 'cars':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={handleIconType(item?.vehicle_type)}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'car',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={`${item?.brand} ${item?.model}`}
                        label={t('Vehicles.Vehicle')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.number_plate
                            ? item?.number_plate?.toUpperCase()
                            : '-'
                        }
                        label={t('Vehicles.License plate')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.kw}
                        label={t('Vehicles.Power')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.value
                            ? item?.value.toLocaleString('nl-BE', {
                                style: 'currency',
                                currency: 'EUR',
                              })
                            : '-'
                        }
                        label={t('Vehicles.Catalogue value')}
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              case 'two_wheelers':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={handleIconType(item?.vehicle_type)}
                      // icon={<IconHeavyScooter color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'two_wheeler',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={`${item?.brand} ${item?.model}`}
                        label={t('Vehicles.Vehicle')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.number_plate ? item?.number_plate : '-'}
                        label={t('Vehicles.License plate')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={item?.cc ? item?.cc : '-'}
                        label={t('Vehicles.Displacement')}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.value
                            ? item?.value.toLocaleString('nl-BE', {
                                style: 'currency',
                                currency: 'EUR',
                              })
                            : '-'
                        }
                        label={t('Vehicles.Catalogue value')}
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              case 'residences': {
                let address = '-';
                if (item?.address != null) {
                  address = formatCRMAddress(item?.address);
                }
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={<IconPropertyFilled color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'residence',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        label={t('Address')}
                        data={address}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        label={t('Holder state')}
                        data={t(item?.holder_state)}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        label={t('House type')}
                        data={t(item?.attachment)}
                      />
                      <RiskObjectBar.RiskObjectBarData
                        label={t('Number of rooms')}
                        data={
                          !isObjectEmpty(item?.main_building?.rooms)
                            ? sumObjectValues(item?.main_building?.rooms)
                            : `-`
                        }
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              }
              case 'families':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={<IconFamilyFilled color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'family',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={t(item?.status)}
                        label={t('Household')}
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              case 'teachers':
                return (
                  <>
                    <StyledRiskObjectBar
                      icon={<IconStats color="#CCCCCC" />}
                      onClick={e =>
                        riskObjClickHandler(
                          e,
                          'teacher',
                          item?.risk_object_id,
                          item?.risk_object_revision_id,
                        )
                      }
                    >
                      <RiskObjectBar.RiskObjectBarData
                        data={
                          item?.manipulates_heavy_machinery ? t('Yes') : t('No')
                        }
                        label={t('Operates heavy machinery')}
                      />
                    </StyledRiskObjectBar>
                    <RiskObjectSpacer />
                  </>
                );
              default:
                break;
            }
          })
        : 'null'}
      {pagination.pages > 1 ? (
        <ContentFooter>
          <span className="records">
            {t('pagination.showing')} <strong>{amounOfEntries}</strong>{' '}
            {t('pagination.entries')}
          </span>

          <Pagination
            base="/sales-management/customers/"
            prevText={t('PrevPGN')}
            nextText={t('NextPGN')}
            currentPage={pagination.page}
            pageLength={10}
            totalPages={pagination.pages}
          />
        </ContentFooter>
      ) : null}
    </RiskObjectContainer>
  );
};

const RiskObjectContainer = styled.div``;

const RiskObjectSpacer = styled.div`
  height: 15px;
`;

const StyledRiskObjectBar = styled(RiskObjectBar)`
  :hover svg path {
    fill: ${({ theme }) => theme.brand.primary};
  }
`;

const ContentFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;

  .records {
    font-size: 1.4rem;
    line-height: 130%;
    color: ${({ theme }) => theme.ui.disabled};

    strong {
      font-weight: 900;
    }
  }
`;

CRMRiskObjectTabPage.propTypes = {
  type: string.isRequired,
  prospectId: string,
  leadId: string,
  riskObjects: shape({}).isRequired,
};

export default CRMRiskObjectTabPage;
