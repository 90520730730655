import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../../LayoutFlow/views/App';
import { useLocation, useHistory } from 'react-router-dom';
import {
  addBrowserHistory,
  getNextPath,
  addChatboxAnswer,
  getAllCoverages,
  addChatboxQuestion,
  showErrorPopup,
  setCoverage,
} from '../../../../store';
import ChatBox from '../../../../components/ChatBox';
import QuestionBox from '../../../../components/QuestionBox';
import { useTranslation } from 'react-i18next';
import { setInsuredData, postLegalData } from '../store';
import {
  removeFromCheckout,
  resetSkippedSteps,
} from '../../../Marketplace/LayoutMarket/store';
import ApiRoutes from '../../../../store/ApiRoutes';
import {
  resetMatchData,
  getMatchData,
  setMatchData,
} from '../../../Marketplace/Market/store';
import { useFlowStore } from '../../../../services/context/LouiseFlowContext';

const WantsLegal = () => {
  const { t } = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const api = new ApiRoutes();
  const coverages = getAllCoverages();
  const [disabled, setDisabled] = useState(false);
  const [finishedFetches, setFinishedFetches] = useState([]);
  const flowStore = useFlowStore();
  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  useEffect(() => {
    const trueCoverages = [];
    Object.keys(coverages).forEach(coverage => {
      switch (coverage) {
        case 'home':
        case 'family':
        case 'car':
        case 'legal':
          if (coverages[coverage]) trueCoverages.push(coverage);
          break;
        default:
      }
    });
    if (finishedFetches === trueCoverages.length) {
      api.postSession().then(() => {
        if (coverages.home) {
          history.push(getNextPath('c'));
        } else if (coverages.family) {
          history.push(getNextPath('d'));
        } else {
          history.push(getNextPath('e'));
        }
      });
    }
  }, [finishedFetches]);

  const handleYes = () => {
    addChatboxQuestion(t('do you wish to make use of independent legal aid?'));
    addChatboxAnswer(t('i want an independent legal aid insurance'));
    setInsuredData('wants_independent_legal_aid', true);
    if (!coverages.family) {
      history.push(getNextPath('a'));
    } else {
      history.push(getNextPath('b'));
    }
  };

  useEffect(() => {
    if (!coverages.home && !coverages.family && !coverages.car) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  const handleNo = () => {
    if (!disabled) {
      setInsuredData('wants_independent_legal_aid', false);
      resetMatchData();
      handleSetData();
    }
  };

  const handleSetData = () => {
    setInsuredData('wants_independent_legal_aid', false);
    postLegalData()
      .then(() => {
        resetSkippedSteps();
        removeFromCheckout('fire');
        removeFromCheckout('people');
        removeFromCheckout('car');
        removeFromCheckout('legal');
        setCoverage('legal', false);
        resetMatchData();
        Object.keys(coverages).forEach(coverageKey => {
          switch (coverageKey) {
            case 'home':
            case 'family':
            case 'car':
            case 'legal':
              if (coverages[coverageKey]) {
                const { type, campaign } = flowStore || {};
                if (type === 'campaign' && campaign?.extend === 'LEAD') {
                  history.push('/thank-you-campaigns');
                  return;
                }
                getMatchData(coverageKey)
                  .then(res => {
                    setMatchData(
                      coverageKey === 'home' ? 'fire' : coverageKey,
                      res.data,
                    );
                    setFinishedFetches(finishedFetches.push(coverageKey));
                  })
                  .catch(err => {
                    // showErrorPopup(true);
                    console.error(err);
                  });
              }
              break;
            default:
          }
        });
      })
      .catch(error => {
        showErrorPopup(true);
        console.error(error.response);
      });
  };

  return (
    <Layout>
      <Container>
        <ChatBox />
        <QuestionBox
          response={res => (res === t('Yes') ? handleYes(res) : handleNo())}
          Answer1={t('Yes')}
          Answer2={t('No')}
          dataTestId1="Yes"
          dataTestId2="No"
          noLink={disabled ? 'stay' : null}
        >
          {t(
            'do you wish to make use of an legal aid In this way you will benefit from high-quality independent cover with an international representation specialising in legal aid',
          )}
        </QuestionBox>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  position: relative;

  & span {
    font-weight: bold;
  }

  & div:first-child {
    z-index: 10;
  }
`;

export default WantsLegal;
