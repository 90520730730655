import React from 'react';
import styled from 'styled-components';
import { string, object, shape, func } from 'prop-types';
import InputBox from './InputBox';

const MarketplaceInsuranceCardValueInput = ({
  name,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  value,
  insuredValues,
  setInsuredValues,
}) => {
  return (
    <StyledInputBox
      name={name}
      type="text"
      setFieldValue={(valName, number) => {
        let dotlessValue = number;
        if (dotlessValue.includes('.')) {
          dotlessValue = number.replace(/\./g, '');
        }
        const tmpVal = dotlessValue.replace(',', '.');
        setFieldValue(valName, dotlessValue);
        const tmp = { ...insuredValues };
        tmp[valName] = tmpVal;
        setInsuredValues(tmp);
      }}
      onBlur={(valName, number) => {
        let dotlessValue = number;
        if (dotlessValue.includes('.')) {
          dotlessValue = number.replace(/\./g, '');
        }
        const tmpVal = dotlessValue.replace(',', '.');
        const finalVal = parseFloat(tmpVal).toLocaleString('nl-BE');
        setFieldValue(valName, finalVal);
      }}
      onFocus={(valName, number) => {
        const tmpVal = number.replace(/\./g, '');
        setFieldValue(valName, tmpVal);
      }}
      setFieldTouched={(valName, number) => {
        setFieldTouched(valName, number);
      }}
      errors={errors}
      touched={touched}
      value={value}
    />
  );
};

const StyledInputBox = styled(InputBox)`
  width: 50rem;
`;

MarketplaceInsuranceCardValueInput.defaultProps = {
  errors: {},
  touched: {},
  setFieldValue: () => {},
  setFieldError: () => {},
  setFieldTouched: () => {},
  setValidEmail: () => {},
  setPending: () => {},
};

MarketplaceInsuranceCardValueInput.propTypes = {
  name: string,
  errors: shape(),
  touched: shape(),
  setFieldValue: func,
  setFieldTouched: func,
  value: string,
  insuredValues: object,
  setInsuredValues: func,
};

export default MarketplaceInsuranceCardValueInput;
