import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

import {
  Body,
  DataBlock,
  H4,
  IconCarFilled,
  IconHistoryFilled,
  IconLegalFilled,
  IconFamilyFilled,
  IconPropertyFilled,
  IconHyperLink,
  TypeLabel,
} from 'wg-fe-ui';
import { useTranslation } from 'react-i18next';
import { useCampaignById, useCampaignStore } from '../context/CampaignContext';
import cogoToast from 'cogo-toast';
import { differenceInCalendarDays } from 'date-fns';

const CampaignAccordionHeader = ({ id }) => {
  const {
    active,
    type,
    name,
    logo,
    url,
    extend,
    end_date: endDate,
    assigned_to: assignedTo,
    insurances,
  } = useCampaignById(id);
  const { campaigns } = useCampaignStore();
  const [isActive, setIsActive] = useState(
    active && 0 <= computeAmountOfDaysLeft(endDate),
  );

  const { t } = useTranslation();

  function renderInsuranceTypes(insuranceTypes) {
    return insuranceTypes.map(insuranceType => {
      switch (insuranceType) {
        case 'LEGAL':
          return <IconLegalFilled />;
        case 'FAMILY':
          return <IconFamilyFilled />;
        case 'CAR':
          return <IconCarFilled />;
        case 'RESIDENCE':
          return <IconPropertyFilled />;
        default:
          return <p key={insuranceType}>Icon not found {insuranceType}</p>;
      }
    });
  }

  function handleLinkCopy(e, _url) {
    e.preventDefault();
    e.stopPropagation();

    cogoToast.success(t('Your email campaign has been copied'));
    navigator.clipboard.writeText(_url);
  }

  function computeAmountOfDaysLeft(date) {
    return differenceInCalendarDays(new Date(date), new Date());
  }

  const campaignExtend = {
    LEAD: 'Leads',
    INTERNAL_OFFER: 'Internal offers',
    EXTERNAL_OFFER: 'External offers',
    ONLINE_INSURANCE: 'online insurance',
  };

  useEffect(() => {
    setIsActive(
      active && (0 <= computeAmountOfDaysLeft(endDate) || endDate === null),
    );
  }, [active, endDate]);

  return (
    <StyledAccordionBox disabled={!isActive}>
      <InsuranceLogo
        alt="insurance logo"
        src={logo || campaigns?.distribution?.logos?.logo}
      />
      <DataBlock>
        <Body light smaller>
          {t('Name')}
        </Body>
        <H4 larger>{name}</H4>
      </DataBlock>
      <DataBlock>
        <Body light smaller>
          {t('Assigned')}
        </Body>
        <H4 larger>
          {
            campaigns.brokers.items.filter(
              ({ broker }) => broker.id === assignedTo,
            )?.[0]?.broker?.name
          }
        </H4>
      </DataBlock>
      <DataBlock>
        <Body light smaller>
          {t('Insurance types')}
        </Body>
        <IconContainer>
          {renderInsuranceTypes([
            ...new Set(insurances.map(({ insurance_type }) => insurance_type)),
          ])}
        </IconContainer>
      </DataBlock>
      <DataBlock>
        <Body light smaller>
          {t('Type')}
        </Body>
        <StyledTypeLabel text={t(campaignExtend[extend])} />
      </DataBlock>
      {!isActive ? (
        <>
          <p></p>
          <Disabled>{t('Disabled campaign')}</Disabled>
        </>
      ) : (
        <>
          {type === 'EMAIL' ? (
            <CopyLink onClick={e => handleLinkCopy(e, url)}>
              <IconHyperLink /> {t('Copy link')}
            </CopyLink>
          ) : (
            <p></p>
          )}
          {type === 'EMAIL' ? (
            <CampaignDuration
              className="duration"
              days={computeAmountOfDaysLeft(endDate) > 7 || endDate === null}
            >
              <StyledIconHistoryFilled
                showWarning={
                  !(computeAmountOfDaysLeft(endDate) > 7 || endDate === null)
                }
              />
              <p>
                {endDate === null
                  ? t('No end date')
                  : `${computeAmountOfDaysLeft(endDate)} ${t('days left')}`}
              </p>
            </CampaignDuration>
          ) : (
            <CopyLink onClick={e => handleLinkCopy(e, url)}>
              <IconHyperLink /> {t('Copy link')}
            </CopyLink>
          )}
        </>
      )}
    </StyledAccordionBox>
  );
};

const StyledIconHistoryFilled = styled(IconHistoryFilled)`
  > path {
    /* stylelint-disable-next-line */
    ${props => props.showWarning && `fill: #f9716b !important`}
  }
`;

const StyledTypeLabel = styled(TypeLabel)`
  min-width: 50px;
  width: fit-content;
`;

const CopyLink = styled.a`
  display: flex;
  cursor: copy;
  align-items: center;
  background: white;
  border: 2px solid #f0f1f3;
  box-sizing: border-box;
  border-radius: 99999px;
  width: fit-content;
  padding: 0.3rem 2rem;
  justify-self: center;
  font-size: 1.4rem;
  & svg {
    width: 1.4rem;
    margin-right: 1rem;
  }
`;

const IconContainer = styled.div`
  height: 2rem;
  svg {
    height: 2.5rem;
  }
`;

const StyledAccordionBox = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 7% 17% 16% 14% 12% 15% 12%;
  grid-gap: 1rem;
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
  h4 {
    font-size: 1.7rem;
  }
  > div {
    height: 100%;
    justify-content: space-between;
  }
`;

const CampaignDuration = styled.div`
  &.duration {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: unset;
  }
  p {
    color: ${({ days }) => (days ? '#d5d5d5' : '#FA4A43')};
    font-size: 1.4rem;
  }
  path {
    fill: #d5d5d5;
  }
`;

const Disabled = styled.div`
  color: white;
  background: #fa4a43;
  border-radius: 9999px;
  text-align: center;
  display: flex;
  align-items: center;
`;

const InsuranceLogo = styled.img`
  max-width: 90%;
  max-height: 6rem;
`;

CampaignAccordionHeader.propTypes = {
  id: string.isRequired,
};

export default CampaignAccordionHeader;
