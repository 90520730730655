import React, { useEffect, useContext, useState } from 'react';
import {
  createProspect,
  getPyModelsObjectsFromQuoteStorageSMT,
  createOffer,
  updateProspectCustomerById,
  createRiskObjectRevision,
  createRiskObjectForProspect,
  updateProspectCompanyById,
} from '../services/apiRouterService';
import { getBrokerDistribution } from '../services/brokerDataService.js';
import { useParams } from 'react-router';
import { ProFlowStorageContext } from '../services/context/ProFlowStorageContext';
import { StatusIcon, LoadingSpinner } from 'wg-fe-ui';
import styled from 'styled-components';
import { ProFlowOffersContext } from '../services/context/ProFlowOffersContext';
import { useTranslation } from 'react-i18next';
import { retrieveStorageById } from '../services/storeService';
import { string, arrayOf, func } from 'prop-types';
import _ from 'lodash';

const ProflowOffersModalCreateOffer = ({
  prospectStatus,
  offersstatuses,
  setProspectStatus,
  setOffersstatuses,
}) => {
  const [error, setError] = useState('');

  const { selectedOffers } = useContext(ProFlowStorageContext);
  const { offersParameters } = useContext(ProFlowOffersContext);
  const { sessionId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const quoteIds = selectedOffers.map(({ id }) => id);
      const initialOfferStatusses = quoteIds.map(id => ({
        id,
        status: 'loading',
      }));
      setOffersstatuses(initialOfferStatusses);
      const custumerObj = await createProspectOnMount(sessionId, quoteIds);

      // id: "19148eb2-28ec-4edc-b414-683af1815310"
      // risk_object_revisions:
      // cars: ["259bb41d-cce8-4513-80f1-f6e275aabe4b"]
      // families: ["8c6ed313-a5a9-465d-8287-9bf33279022e"]
      // residences: []
      // teachers: []
      // two_wheelers: []
      if (!custumerObj)
        return setError('Something has gone wrong with creating your offers');
      createAllOffers(
        custumerObj,
        selectedOffers,
        initialOfferStatusses,
        offersParameters,
      );
    })();
  }, []);

  async function createProspectOnMount(_sessionId, quoteIds) {
    const quoteResponses = await Promise.all(
      quoteIds.map(async quoteId => {
        const [
          quoteDataResp,
          quoteDataStatus,
        ] = await getPyModelsObjectsFromQuoteStorageSMT(quoteId);

        if (quoteDataStatus !== 200) {
          setProspectStatus('error');
          return undefined;
        }
        return quoteDataResp;
      }),
    );

    const { id: distributionId } = await getBrokerDistribution();
    const { prospectId, risk_object } = await retrieveStorageById(
      'pro_flow',
      sessionId,
    );
    const isCustomer = !!quoteResponses[0]?.policy_holder?.first_name;
    if (prospectId) {
      let status;
      if (isCustomer) {
        [, status] = await updateProspectCustomerById(
          distributionId,
          prospectId,
          await generateProspectFromQuoteData(await quoteResponses),
        );
      } else {
        [, status] = await updateProspectCompanyById(
          distributionId,
          prospectId,
          await generateProspectFromQuoteData(await quoteResponses),
        );
      }
      const { ids: riskObjectIds } = risk_object || {};

      const types = {
        car: 'cars',
        family: 'families',
        home: 'residences',
      };

      const riskObjectData = quoteResponses
        .map(({ risk_object, response }) => ({
          ...risk_object,
          type: response.insurance.type,
        }))
        .filter(obj => obj !== undefined)
        .map(({ type, ...rest }) => ({ [types[type]]: [{ ...rest }] }))
        .reduce(function(current, next) {
          return Object.assign({}, current, next);
        }, {});

      const revisionKeys = riskObjectIds
        .filter(({ riskObjectId }) => !!riskObjectId)
        .map(({ key }) => types[key]);

      const test1 = await Promise.all(
        Object.entries(riskObjectData).map(async ([key, value]) => {
          if (revisionKeys.includes(key))
            return new Promise(resolve => resolve());
          const {
            type,
            first_name,
            birth,
            registration_first,
            registration_last,
            previous_insurance,
            drivers,
            attachment,
            main_building,
            status,
            used_for,
            ...rest
          } = value[0] || {};

          if (main_building?.age)
            main_building.age = main_building.age.toUpperCase();

          if (main_building?.main_building_type)
            main_building.main_building_type = main_building.main_building_type.toUpperCase();

          const payload = {
            ...(registration_first && {
              registration_first: computeDateObjToIso(registration_first),
            }),
            ...(registration_last && {
              registration_last: computeDateObjToIso(registration_last),
            }),
            ...(birth && first_name && { birth: _.replace(birth, ' ', 'T') }),
            ...(drivers && {
              drivers: drivers.map(
                ({
                  birth: driverBirth,
                  issue_license_date,
                  ...driverRest
                }) => ({
                  ...(driverBirth && {
                    birth: computeDateObjToIso(driverBirth),
                  }),
                  ...(issue_license_date && {
                    issue_license_date: computeDateObjToIso(issue_license_date),
                  }),
                  ...driverRest,
                }),
              ),
            }),
            ...(attachment && { attachment: attachment.toUpperCase() }),
            ...(used_for && { used_for: used_for.toUpperCase() }),
            ...(status && { status: status.toUpperCase() }),
            ...rest,
            main_building,
          };

          const [
            revisionReps,
            revisionStatus,
          ] = await createRiskObjectForProspect(
            distributionId,
            prospectId,
            key,
            payload,
          );

          if (revisionStatus !== 201) {
            setProspectStatus('error');
            return undefined;
          }
          return { [key]: [revisionReps.risk_object_revision_id] };
        }),
      );
      const test2 = await Promise.all(
        riskObjectIds.map(async ({ key, riskObjectId }) => {
          if (!riskObjectId) return new Promise(resolve => resolve());
          const riskobjectType = types[key];
          const {
            birth,
            first_name,
            registration_first,
            registration_last,
            previous_insurance,
            drivers,
            main_building,
            attachment,
            status,
            used_for,
            ...rest
          } = riskObjectData[riskobjectType]?.[0] || {};

          if (main_building?.age)
            main_building.age = main_building.age.toUpperCase();

          if (main_building?.main_building_type)
            main_building.main_building_type = main_building.main_building_type.toUpperCase();

          const payload = {
            ...(registration_first && {
              registration_first: computeDateObjToIso(registration_first),
            }),
            ...(registration_last && {
              registration_last: computeDateObjToIso(registration_last),
            }),
            ...(birth && first_name && { birth: _.replace(birth, ' ', 'T') }),
            ...(drivers && {
              drivers: drivers.map(
                ({
                  birth: driverBirth,
                  issue_license_date,
                  ...driverRest
                }) => ({
                  ...(driverBirth && {
                    birth: computeDateObjToIso(driverBirth),
                  }),
                  ...(issue_license_date && {
                    issue_license_date: computeDateObjToIso(issue_license_date),
                  }),
                  ...driverRest,
                }),
              ),
            }),
            ...(attachment && { attachment: attachment.toUpperCase() }),
            ...(used_for && { used_for: used_for.toUpperCase() }),
            ...(status && { status: status.toUpperCase() }),
            ...rest,
            main_building,
          };

          const [revisionReps, revisionStatus] = await createRiskObjectRevision(
            distributionId,
            prospectId,
            riskobjectType,
            riskObjectId,
            payload,
          );

          if (revisionStatus !== 201) {
            setProspectStatus('error');
            return undefined;
          }
          return { [riskobjectType]: [revisionReps.risk_object_revision_id] };
        }),
      );

      if (status > 201) {
        setProspectStatus('error');
        return undefined;
      } else {
        setProspectStatus('success');
      }

      const promiseObjects = [];
      test1.filter(obj => !!obj).forEach(obj => promiseObjects.push(obj));
      test2.filter(obj => !!obj).forEach(obj => promiseObjects.push(obj));

      let _risk_object_revisions = {};

      Object.keys(promiseObjects).forEach(key => {
        const typeKey = Object.keys(promiseObjects[key])[0];
        _risk_object_revisions[typeKey] = promiseObjects[key][typeKey];
      });

      return {
        id: prospectId,
        risk_object_revisions: _risk_object_revisions,
      };
    } else {
      const [resp, status] = await createProspect(
        distributionId,
        isCustomer ? 'customers' : 'companies',
        await generateProspectFromQuoteData(await quoteResponses),
      );

      if (status > 201) {
        setProspectStatus('error');
        return undefined;
      } else {
        setProspectStatus('success');
      }
      return resp;
    }
  }

  async function generateProspectFromQuoteData(quoteData = []) {
    const { prospectId } = await retrieveStorageById('pro_flow', sessionId);

    const types = {
      car: 'cars',
      family: 'families',
      home: 'residences',
    };

    let riskObjects = quoteData
      .map(({ risk_object, response }) => {
        const {
          birth,
          first_name,
          registration_first,
          registration_last,
          previous_insurance,
          drivers,
          main_building,
          annexes,
          attachment,
          status,
          used_for,
          rooms,
          ...rest
        } = risk_object || {};
        if (response?.insurance.type === 'legal') return undefined;

        if (main_building?.age)
          main_building.age = main_building.age.toUpperCase();

        if (main_building?.main_building_type)
          main_building.main_building_type = main_building.main_building_type.toUpperCase();

        return {
          [types[response.insurance.type]]: [
            {
              ...(registration_first && {
                registration_first: computeDateObjToIso(registration_first),
              }),
              ...(registration_last && {
                registration_last: computeDateObjToIso(registration_last),
              }),
              ...(birth && first_name && { birth: _.replace(birth, ' ', 'T') }),
              ...(drivers && {
                drivers: drivers.map(
                  ({
                    birth: driverBirth,
                    issue_license_date,
                    ...driverRest
                  }) => ({
                    ...(driverBirth && {
                      birth: computeDateObjToIso(driverBirth),
                    }),
                    ...(issue_license_date && {
                      issue_license_date: computeDateObjToIso(
                        issue_license_date,
                      ),
                    }),
                    ...driverRest,
                  }),
                ),
              }),
              ...(annexes && {
                annexes: annexes.map(({ type: typeObj, ...annexesrest }) => ({
                  ...(typeObj && {
                    type: typeObj.toUpperCase(),
                  }),
                  ...annexesrest,
                })),
              }),
              ...(attachment && { attachment: attachment.toUpperCase() }),
              ...(used_for && { used_for: used_for.toUpperCase() }),
              ...(status && { status: status.toUpperCase() }),
              ...rest,
              main_building,
            },
          ],
        };
      })
      .filter(obj => obj !== undefined)
      .reduce(function(current, next) {
        return Object.assign({}, current, next);
      }, {});

    const { policy_holder: policyHolder } = quoteData[0] || {};

    if (!policyHolder) return undefined;
    return {
      address: policyHolder.address,
      ...(policyHolder.name && { name: policyHolder.name }),
      ...(policyHolder.birth && {
        birth: _.replace(policyHolder.birth, ' ', 'T'),
      }),
      email: policyHolder.email,
      first_name: policyHolder.first_name,
      iban: policyHolder.iban,
      last_name: policyHolder.last_name,
      national_register_nr: policyHolder.national_register_nr,
      native_language: policyHolder.native_language,
      telephonenr: policyHolder.telephonenr,
      ...(!prospectId && { risk_objects: riskObjects }),
    };
  }

  async function createAllOffers(
    customerObj,
    quoteData,
    offersstatusesCopy,
    _offersParameters,
  ) {
    const { id: distributionId } = await getBrokerDistribution();
    const { prospectId: _prospectId } = await retrieveStorageById(
      'pro_flow',
      sessionId,
    );

    const promiseArr = await quoteData.map(
      ({ id, options, insurance, quote, insuranceType, ...rest }) => {
        let optionsCopy = options;
        optionsCopy.push({ key: quote?.base?.key, name: quote?.base?.name });
        optionsCopy.push({ key: insurance?.key });
        const prospectId = customerObj.id || _prospectId;

        // Mapping risk objects for backend
        let riskObjects = {};
        Object.keys(customerObj?.risk_object_revisions).forEach(key => {
          riskObjects[key.toLowerCase()] = [];
        });

        switch (insuranceType?.key?.toLowerCase()) {
          case 'home':
            riskObjects['residences'] =
              customerObj?.risk_object_revisions['residences'];
            break;
          case 'car':
            riskObjects['cars'] = customerObj?.risk_object_revisions['cars'];
            break;
          case 'family':
            riskObjects['families'] =
              customerObj?.risk_object_revisions['families'];
            break;
          default:
            break;
        }

        return createOffer(distributionId, prospectId, {
          quote_id: id,
          guarantees: _.uniq(optionsCopy.map(({ key }) => key)),
          ...(riskObjects && {
            risk_object_revisions: riskObjects,
          }),
          ...generateOfferOptions(
            { id, options, insuranceType, ...rest },
            _offersParameters,
          ),
        });
      },
    );

    const results = await Promise.all(promiseArr);
    const temp = offersstatusesCopy.map(({ id }, i) => {
      return {
        id,
        status: results[i][1] !== 201 ? 'error' : 'success',
      };
    });
    setOffersstatuses(temp);
  }

  function generateOfferOptions(
    quoteData,
    { offers, requiredInformation, terminationLetter },
  ) {
    const {
      id,
      insuranceType: { key },
    } = quoteData;

    const [currentOffer] = offers.filter(({ id: _id }) => id === _id);
    const [currentInfo] = requiredInformation.filter(
      ({ insuranceType }) => insuranceType === key,
    );
    const [currentTermination] = terminationLetter.filter(
      ({ insuranceType }) => insuranceType === key,
    );
    return {
      start_date: currentOffer.offerValidityFrom,
      end_date: currentOffer.offerValidityUntil,
      commencement_date: currentOffer.commencement,
      ...(currentOffer.assigned_to && {
        assigned_to: currentOffer.assigned_to.map(({ value }) => value)[0],
      }),

      ...(currentOffer.language && { language: currentOffer.language }),
      ...(currentTermination?.enableTermination && {
        notice: {
          previous_insurance_company_key: currentTermination?.insuranceCompany,
          main_expiry_date: currentTermination?.expirationDate,
          current_policy_nr: currentTermination?.policyNumber,
          notice_type: currentTermination?.reasonOfTermination,
          ...(currentTermination?.extraInformation && {
            reason: currentTermination?.extraInformation,
          }),
        },
      }),
      send_mail_to_prospect: currentOffer.mailToClient || false,
      information_requirement_sheet_specification: {
        specific_needs: currentInfo.prospectAsksToInsure,
        reason_for_contact: currentInfo.reasonOfContract,
        broker_gives_advise: currentInfo.doesBrokerGiveAdvice,
        independant_advice: currentInfo.didTheBrokeGivePersonalisedAdvice,
        prospect_follows_advice: currentInfo.didTheProspectFollowedAdvice,
        no_other_contract: currentInfo.anotherInsuranceContract,
        motivation: currentInfo.motivation?.[id]
          ? currentInfo.motivation?.[id]
          : currentInfo.general,
      },
      export_to_crm: currentOffer.exportToCRM,
    };
  }

  function computeDateObjToIso(birth = {}) {
    return _.replace(birth, ' ', 'T');
  }

  function computeOfferName(id, _selectedOffers) {
    const [{ insurance, insuranceCompany }] = selectedOffers.filter(
      ({ id: _id }) => _id === id,
    );
    return `${insuranceCompany?.name} - ${insurance.name}`;
  }

  return (
    <Container>
      <ListContainer>
        <li>
          <StatusContainer>
            {prospectStatus === 'loading' ? (
              <LoadingSpinner />
            ) : (
              <StatusIcon type={prospectStatus} />
            )}
          </StatusContainer>
          {t('Create prospect')}
        </li>
        {offersstatuses.map(({ id, status }) => (
          <li key={id}>
            <StatusContainer>
              {status === 'loading' ? (
                <LoadingSpinner />
              ) : (
                <StatusIcon type={status} />
              )}
            </StatusContainer>
            {computeOfferName(id, selectedOffers)}
          </li>
        ))}
      </ListContainer>
      {error && <Error>{error}</Error>}
    </Container>
  );
};

const Error = styled.p`
  font-weight: 600;
  color: red;
  font-size: 1.8rem;
  margin-top: 2rem;
`;

const StatusContainer = styled.span`
  margin-right: 1rem;
  width: 2rem;
  > svg {
    width: 100%;
    height: auto;
    min-width: initial;
    min-height: initial;
    transform-origin: initial;
    circle {
      stroke-width: 8px;
    }
  }
`;

const ListContainer = styled.ul`
  > li {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
`;

const Container = styled.div`
  padding: 4rem;
`;

ProflowOffersModalCreateOffer.propTypes = {
  prospectStatus: string,
  offersstatuses: arrayOf(string),
  setProspectStatus: func.isRequired,
  setOffersstatuses: func.isRequired,
};

export default ProflowOffersModalCreateOffer;
