import React from 'react';

const Travels = () => (
  <svg viewBox="0 0 169.29 221">
    <defs>
      <style>{'.prefix__cls-1{fill:#fff}'}</style>
    </defs>
    <title>{'travelsmatrix_family_'}</title>
    <g id="prefix__Layer_2" data-name="Layer 2">
      <g id="prefix__icons">
        <path
          className="prefix__cls-1"
          d="M126.21 38.26a19.13 19.13 0 10-19.13-19.13 19.07 19.07 0 0019.13 19.13zM169.29 67.38v50.93a9.55 9.55 0 01-9.39 9.69 9.23 9.23 0 01-6.18-2.44l-.13.06v83.81A11.57 11.57 0 01142 221a11.57 11.57 0 01-11.57-11.57v-71.34a4.24 4.24 0 00-4.25-4.24 4.23 4.23 0 00-4.23 4.23v71.34A11.57 11.57 0 01110.4 221a11.57 11.57 0 01-11.58-11.58v-84.14l-.14-.06a9 9 0 01-6.05 2.78c-5.22.26-9.5-4.23-9.5-9.45V67.38c0-12.1 9.43-24.82 23.9-24.82h38.35c14.41 0 23.91 12.78 23.91 24.82zM59.1 104.1h-8.23V71.34a6.79 6.79 0 00-6.79-6.79H26.35a6.8 6.8 0 00-6.8 6.79v32.76h-8.23A11.32 11.32 0 000 115.42v85.51a11.32 11.32 0 0011.32 11.32H59.1a11.32 11.32 0 0011.32-11.32v-85.51A11.32 11.32 0 0059.1 104.1zM26.35 73.6a2.26 2.26 0 012.26-2.26h13.2a2.27 2.27 0 012.27 2.26v30.5H26.35z"
        />
        <path
          className="prefix__cls-1"
          d="M13.33 205.68h9.85v10.82a4.5 4.5 0 01-4.5 4.5h-.85a4.5 4.5 0 01-4.5-4.5v-10.82zM47.25 205.68h9.85v10.82a4.5 4.5 0 01-4.5 4.5h-.85a4.5 4.5 0 01-4.5-4.5v-10.82z"
        />
      </g>
    </g>
  </svg>
);

export default Travels;
