import React, { useState } from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import Apartment from '../../assets/images/mock/Apartment';
import MapMock from '../../assets/images/mock/MapMock';
import EyeIcon from '../../assets/images/eye-icon';
import CrossedEyeIcon from '../../assets/images/crossed-eye-icon';
import { getMapsPhoto, getMarkedMapsPhoto } from '../models/Flow/Woning/store';
import { getCurrentTheme } from '../store';
import { string } from 'prop-types';

const ToolkitAddressLookupInteractivemap = ({ manual, building }) => {
  const [showEye, setShoweye] = useState(getMarkedMapsPhoto() ? true : false);
  const [showPictureDialog, setShowPictureDialog] = useState(false);

  return (
    <>
      {showPictureDialog ? (
        <PictureDialogWrapper onClick={() => setShowPictureDialog(false)}>
          <PictureDialog
            src={`data:image/png;base64, ${
              showEye ? getMarkedMapsPhoto() : getMapsPhoto()
            }`}
            alt=""
          />
        </PictureDialogWrapper>
      ) : null}
      <MapContainer>
        {getMapsPhoto() ? (
          <>
            {getMarkedMapsPhoto() ? (
              <>
                <StyledImg2
                  shouldShow={showEye}
                  manual={manual}
                  src={`data:image/png;base64, ${getMarkedMapsPhoto()}`}
                  alt="map of house"
                  onClick={() => setShowPictureDialog(true)}
                />
                <EyeButton onClick={() => setShoweye(!showEye)}>
                  {showEye ? (
                    <CrossedEyeIcon colors={getCurrentTheme()} />
                  ) : (
                    <EyeIcon colors={getCurrentTheme()} />
                  )}
                </EyeButton>
              </>
            ) : null}
            <StyledImg
              manual={manual}
              src={`data:image/png;base64, ${getMapsPhoto()}`}
              alt="map of house"
              onClick={() => setShowPictureDialog(true)}
            />
          </>
        ) : building === 'apartment' ? (
          <ApartmentPhoto colors={getCurrentTheme()} />
        ) : (
          <HousePhoto colors={getCurrentTheme()} />
        )}
      </MapContainer>
    </>
  );
};

const EyeButton = styled.div`
  background-color: ${({ theme }) => theme.brand.primary};
  position: absolute;
  right: 15%;
  user-select: none;
  bottom: 12%;
  height: 3rem;
  width: 3rem;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
`;

const ApartmentPhoto = styled(Apartment)`
  height: 100%;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const HousePhoto = styled(MapMock)`
  height: 100%;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  margin-bottom: 2rem;
  justify-content: center;
  align-items: center;

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  /* margin-bottom: 5rem; */

  margin-bottom: -2rem;

  display: flex;
  justify-content: center;
  /* bottom: 2rem; */
  align-items: center;

  @media screen and (max-height: 700px) {
    width: 60%;
    padding-bottom: 60%;
  }

  @media screen and (max-height: 600px) {
    width: 40%;
    padding-bottom: 40%;
  }
`;

const StyledImg = styled.img`
  height: 100%;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  user-select: none;
  -webkit-user-drag: none;
  z-index: 1;
  cursor: pointer;
  width: 100%;
`;

const StyledImg2 = styled.img`
  height: 100%;
  opacity: ${props => (props.shouldShow ? 1 : 0)};
  transition: opacity 0.3s ease;
  background-color: pink;
  position: absolute;
  object-fit: contain;
  top: 0;
  left: 0;
  -webkit-user-drag: none;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 2;
  cursor: pointer;
  width: 100%;
`;

const PictureDialogWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 1s;
`;

const PictureDialog = styled.img`
  display: block;
  width: 50%;
  border-radius: 1rem;
  ::after {
    content: 'x';
  }
`;

ToolkitAddressLookupInteractivemap.defaultProps = {
  manual: false,
  building: '',
};

ToolkitAddressLookupInteractivemap.propTypes = {
  manual: bool,
  building: string,
};

export default ToolkitAddressLookupInteractivemap;
