import React from 'react';
import { shape } from 'prop-types';

const StepIconLegal = ({ colors }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <title>legal</title>
    <g id="icons">
      <path
        d="M34.8,16.52a1.24,1.24,0,0,0-.22-.27,1.38,1.38,0,0,0-.82-.25H26V14a1,1,0,0,0-2,0v2H16.24a1.38,1.38,0,0,0-.82.25,1.24,1.24,0,0,0-.22.27l-5.2,9V26a5.49,5.49,0,0,0,6,5.47,5.65,5.65,0,0,0,5-5.71v-.26L17.23,19H24V40H17.5a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5v-3a.5.5,0,0,0-.5-.5H26V19h6.77L29,25.53v.26a5.65,5.65,0,0,0,5,5.71A5.49,5.49,0,0,0,40,26v-.5Zm-16.11,9H12.31L15.5,20Zm12.62,0L34.5,20l3.19,5.52Z"
        fill={colors.brand.primary}
        stroke={colors.brand.dark}
        strokeMiterlimit="10"
        strokeWidth="1"
      />
      <path
        d="M25.16,5.1,26,6.69a.18.18,0,0,0,.14.1l1.75.26a.18.18,0,0,1,.1.31L26.67,8.6a.18.18,0,0,0-.05.16l.3,1.75a.18.18,0,0,1-.26.19l-1.57-.82a.19.19,0,0,0-.18,0l-1.57.82a.18.18,0,0,1-.26-.19l.3-1.75a.18.18,0,0,0-.05-.16L22.06,7.36a.18.18,0,0,1,.1-.31l1.75-.26a.18.18,0,0,0,.14-.1l.79-1.59A.18.18,0,0,1,25.16,5.1Z"
        fill={colors.brand.dark}
      />
      <path
        d="M34.16,5.1,35,6.69a.18.18,0,0,0,.14.1l1.75.26a.18.18,0,0,1,.1.31L35.67,8.6a.18.18,0,0,0-.05.16l.3,1.75a.18.18,0,0,1-.26.19l-1.57-.82a.19.19,0,0,0-.18,0l-1.57.82a.18.18,0,0,1-.26-.19l.3-1.75a.18.18,0,0,0-.05-.16L31.06,7.36a.18.18,0,0,1,.1-.31l1.75-.26a.18.18,0,0,0,.14-.1l.79-1.59A.18.18,0,0,1,34.16,5.1Z"
        fill={colors.brand.dark}
      />
      <path
        d="M16.16,5.1,17,6.69a.18.18,0,0,0,.14.1l1.75.26a.18.18,0,0,1,.1.31L17.67,8.6a.18.18,0,0,0-.05.16l.3,1.75a.18.18,0,0,1-.26.19l-1.57-.82a.19.19,0,0,0-.18,0l-1.57.82a.18.18,0,0,1-.26-.19l.3-1.75a.18.18,0,0,0-.05-.16L13.06,7.36a.18.18,0,0,1,.1-.31l1.75-.26a.18.18,0,0,0,.14-.1l.79-1.59A.18.18,0,0,1,16.16,5.1Z"
        fill={colors.brand.dark}
      />
    </g>
  </svg>
);

StepIconLegal.propTypes = {
  colors: shape().isRequired,
};
export default StepIconLegal;
