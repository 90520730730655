/* stylelint-disable */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { withRouter, useLocation } from 'react-router';
import { shape } from 'prop-types';
import QuestionBox from '../../../../components/QuestionBox';
import Layout from '../../LayoutFlow/views/App';
import CheckMark from '../components/CheckMark';
import Button from '../../../../components/Button';
import Gsm from '../../../../../assets/images/lock/gsm';
import Bike from '../../../../../assets/images/lock/bike';
import Step from '../../../../../assets/images/lock/step';
import Jewels from '../../../../../assets/images/lock/jewels';
import {
  getNextPath,
  getCurrentTheme,
  getComponentName,
  addChatboxAnswer,
  addChatboxQuestion,
  addBrowserHistory,
} from '../../../../store';
import { setExtra, getExtras } from '../store';
import { useTranslation } from 'react-i18next';

const Extras = ({ history }) => {
  const { t } = useTranslation();
  const location = useLocation();
  addChatboxQuestion(t(`${getComponentName()}.question`));
  const [extras, setExtras] = useState(getExtras());

  const icons = {
    Gsm,
    Bike,
    Step,
    Jewels,
  };

  useEffect(() => {
    addBrowserHistory(location.pathname);
  }, []);

  const changeExtra = e => {
    setExtras({
      ...extras,
      [e.target.name]: e.target.checked,
    });
    setExtra(e.target.name, e.target.checked);
  };

  const capitalize = s => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const onClickHandler = () => {
    var selected = [];
    if (getExtras()['informatics']) {
      selected.push(t(`${getComponentName()}.items.0.label`));
    }
    if (getExtras()['bicycle']) {
      selected.push(t(`${getComponentName()}.items.1.label`));
    }
    if (getExtras()['e_step']) {
      selected.push(t(`${getComponentName()}.items.2.label`));
    }
    if (getExtras()['Juwellery']) {
      selected.push(t(`${getComponentName()}.items.3.label`));
    }
    var items = '';
    if (selected.length === 1) {
      items = selected[0].toLowerCase();
    } else {
      for (var i = 0; i < selected.length; i++) {
        if (i !== selected.length - 1) {
          items += `${selected[i].toLowerCase() + ', '}`;
        } else {
          items += t(`${getComponentName()}.and`);
          items += `${' ' + selected[i].toLowerCase()}`;
        }
      }
    }
    if (selected.length === 0) {
      addChatboxAnswer(capitalize(t(`${getComponentName()}.noneMsg`)));
    } else {
      addChatboxAnswer(
        capitalize(
          t(`${getComponentName()}.interactiveMsg`, {
            items: items,
          }),
        ),
      );
    }
    history.push(getNextPath('a'));
  };

  return (
    <Layout>
      <Container>
        <ScrollContainer>
          <StyledQuestionBox>
            {t(`${getComponentName()}.question`)}
          </StyledQuestionBox>
          <CheckboxContainer>
            {t(`${getComponentName()}.items`, { returnObjects: true }).map(
              item => (
                <CheckMark
                  key={item.key}
                  itemKey={item.key}
                  changeExtra={changeExtra}
                  checked={extras[item.key] ? extras[item.key] : false}
                  colors={getCurrentTheme()}
                  Icon={icons[item.icon]}
                >
                  {item.label}
                </CheckMark>
              ),
            )}
          </CheckboxContainer>
        </ScrollContainer>
        <ButtonContainer>
          <Button
            dataTestId="underwriting_extras_submit"
            onClick={() => onClickHandler()}
            disabled={false}
          >
            {t(`${getComponentName()}.btnText`)}
          </Button>
        </ButtonContainer>
      </Container>
    </Layout>
  );
};

const ScrollContainer = styled.div`
  width: 100%;
  min-height: calc(100% - 11.1rem);
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-top: 4rem;
  flex: 1 1 auto;
  flex-shrink: 0;
`;

const StyledQuestionBox = styled(QuestionBox)`
  height: auto;
  flex-grow: 0;
  padding-bottom: 0;
  align-self: flex-start;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 80%;
  margin: auto;
  position: relative;
  margin-top: 2rem;
  z-index: 5;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0 100%;
  font-size: 2rem;
  overflow-y: auto;
  overflow-x: scroll;
  line-height: 2.6rem;
  height: 100%;
  overflow-x: hidden;
`;

const ButtonContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 80%;
  padding: 0;
  margin-bottom: 4rem;
  border-radius: 3rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 0;

  & button {
    margin: 0;
  }
`;

Extras.propTypes = {
  history: shape().isRequired,
};

export default withRouter(Extras);
