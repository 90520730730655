import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';
import { getNestedObject } from '../services/objectService';
import { useTranslation } from 'react-i18next';

const DashboardBrokerHistoryPoliciesPriceOverview = ({ policiesData }) => {
  const { t } = useTranslation();

  return (
    <PriceCalculation>
      <PriceListItem>
        <h2>{t('Checkout.card.nettoBonus')}</h2>
        <p>
          €{' '}
          {getNestedObject(
            policiesData,
            ['policy', 'premium', 'net_premium'],
            0,
          ).toFixed(2)}{' '}
        </p>
      </PriceListItem>
      <PriceListItem>
        <h2>{t('Taxes')}</h2>
        <p>
          €{' '}
          {getNestedObject(
            policiesData,
            ['policy', 'premium', 'taxes_in_euro'],
            0,
          ).toFixed(2)}{' '}
        </p>
      </PriceListItem>
      <PriceListItemTotal>
        <h2>{t('Total Premium')}</h2>
        <p>
          €{' '}
          {getNestedObject(
            policiesData,
            ['policy', 'premium', 'total_premium'],
            0,
          ).toFixed(2)}
        </p>
      </PriceListItemTotal>
    </PriceCalculation>
  );
};
const PriceCalculation = styled.div`
  display: flex;
  flex-direction: column;
  width: 35rem;
  margin-top: 1.6rem;
  margin-left: ${props => (props.social ? 'auto' : null)};

  & p {
    color: ${({ theme }) => theme.ui.highlight};
    font-weight: bold;
  }

  & .price {
    text-align: right;
  }
`;

const PriceListItem = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 1.6rem;
  margin-bottom: 1rem;

  & h2 {
    font-size: 1.6rem;
    font-weight: bold;
    color: ${({ theme }) => theme.typo.highlight};
    margin-bottom: 0.2rem;
  }

  & > h2:first-child {
    margin-bottom: 0;
    margin-top: 0;
  }

  & p {
    white-space: nowrap;
  }
`;

const PriceListItemTotal = styled.li`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.typo.highlight};
  border-top: 1px solid ${({ theme }) => theme.ui.outline};
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 1rem;
  padding-top: 1rem;
  & p {
    color: ${({ theme }) => theme.typo.highlight};
    text-align: right;
  }

  @media screen and (max-width: 750px) {
    font-size: 1.8rem;
  }
`;
DashboardBrokerHistoryPoliciesPriceOverview.defaultProps = {
  string: '',
};

DashboardBrokerHistoryPoliciesPriceOverview.propTypes = {
  policiesData: shape().isRequired,
};

export default DashboardBrokerHistoryPoliciesPriceOverview;
