import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const Garage = ({ colors }) => {
  const styles = {
    main: {
      height: `100%`,
    },
    cls1: {
      fill: `${colors.brand.lightest}`,
    },
    cls2: {
      fill: '#fff',
    },
    cls3: {
      fill: `${colors.brand.primary}`,
    },
    cls4: {
      fill: `${colors.brand.secondary}`,
    },
    cls5: {
      fill: 'none',
      stroke: `${colors.brand.dark}`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: '3px',
    },
    cls6: {
      fill: `${colors.brand.darker}`,
      opacity: '0.1',
    },
  };
  return (
    <SvgContainer>
      <svg
        id="garage"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 225.61 234.68"
        style={styles.main}
      >
        <title>Garage</title>
        <rect
          style={styles.cls1}
          x="1.5"
          y="44.5"
          width="224.11"
          height="189"
        />
        <rect
          style={styles.cls2}
          x="42.11"
          y="97.41"
          width="143"
          height="135.77"
        />
        <polygon
          style={styles.cls3}
          points="223.61 44.45 146.61 1.5 1.92 1.5 1.92 44.5 223.61 44.45"
        />
        <rect
          style={styles.cls4}
          x="48.11"
          y="97.41"
          width="143"
          height="135.77"
        />
        <rect
          style={styles.cls5}
          x="42.11"
          y="97.41"
          width="143"
          height="135.77"
        />
        <line style={styles.cls5} x1="1.5" y1="44.5" x2="79.11" y2="44.5" />
        <line
          style={styles.cls5}
          x1="92.17"
          y1="44.48"
          x2="106.11"
          y2="44.48"
        />
        <line
          style={styles.cls5}
          x1="166.69"
          y1="44.46"
          x2="224.11"
          y2="44.46"
        />
        <rect style={styles.cls6} x="2.11" y="1.5" width="18.5" height="232" />
        <polyline
          style={styles.cls5}
          points="1.92 1.5 147.11 1.5 224.11 44 224.11 233.18"
        />
        <line
          style={styles.cls5}
          x1="42.11"
          y1="130.64"
          x2="185.11"
          y2="130.64"
        />
        <line style={styles.cls5} x1="43.11" y1="165" x2="185.11" y2="165" />
        <line
          style={styles.cls5}
          x1="42.11"
          y1="199.21"
          x2="185.11"
          y2="199.21"
        />
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  z-index: 1;
  height: 6.7rem;
  position: relative;
  animation: fadeInLeft 0.5s;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 9%;
  }
`;

Garage.propTypes = {
  colors: shape().isRequired,
};
export default Garage;
