import React from 'react';
import { shape } from 'prop-types';
import styled from 'styled-components';

const Pool = ({ colors }) => {
  const styles = {
    main: {
      height: '100%',
    },
    cls1: {
      fill: `${colors.brand.lightest}`,
    },
    cls2: {
      strokeMiterlimit: '10',
      fill: `none`,
      stroke: `${colors.brand.dark}`,
      strokeWidth: `3px`,
    },
    cls3: {
      fill: `none`,
      stroke: `${colors.brand.dark}`,
      strokeWidth: `3px`,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  };
  return (
    <SvgContainer>
      <svg
        id="pool"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 274.5 113.46"
        style={styles.main}
      >
        <title>Pool</title>
        <path
          style={styles.cls1}
          d="M406.5,441.77V484h-268V441.77a33.95,33.95,0,0,0,53.6,0,33.95,33.95,0,0,0,53.6,0,33.95,33.95,0,0,0,53.6,0,33.95,33.95,0,0,0,53.6,0,33.95,33.95,0,0,0,53.6,0Z"
          transform="translate(-132 -370.54)"
        />
        <rect
          style={styles.cls2}
          x="1.5"
          y="52.46"
          width="268"
          height="57.61"
        />
        <polyline
          style={styles.cls3}
          points="109.03 68.25 109.03 52.46 109.03 38.04 109.03 31.8 109.03 29.12 109.03 25.56 109.03 21.99 109.03 1.5"
        />
        <polyline
          style={styles.cls3}
          points="162.5 68.09 162.5 52.46 162.5 38.04 162.5 31.8 162.5 29.12 162.5 25.56 162.5 21.99 162.5 1.5"
        />
        <line
          style={styles.cls3}
          x1="109.03"
          y1="38.04"
          x2="162.5"
          y2="38.04"
        />
        <line
          style={styles.cls3}
          x1="109.03"
          y1="25.56"
          x2="162.5"
          y2="25.56"
        />
        <path
          style={styles.cls3}
          d="M401.5,438.38a33.95,33.95,0,0,1-53.6,0,33.95,33.95,0,0,1-53.6,0,33.95,33.95,0,0,1-53.6,0,33.95,33.95,0,0,1-53.6,0,33.95,33.95,0,0,1-53.6,0"
          transform="translate(-132 -370.54)"
        />
      </svg>
    </SvgContainer>
  );
};

const SvgContainer = styled.div`
  height: 4.6rem;
  position: absolute;
  margin: -0.1rem 0 0 -26rem;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 16%;
  }
`;

Pool.propTypes = {
  colors: shape().isRequired,
};

export default Pool;
