import React, { useEffect } from 'react';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';

import { TopNavigationItemsProvider } from '../js/services/context/TopNavigationBarContext';

import BrokerLogin from '../js/models/Login/views/BrokerLogin';
import GoogleCallBack from '../js/models/Login/views/GoogleCallBack';
import Logout from '../js/models/Login/views/Logout';

import Dashboard from '../js/models/Dashboard/views/index';
import Academy from '../js/models/Dashboard/views/Academy';
import ToolkitDocuments from '../js/models/Dashboard/views/ToolkitDocuments';
import ToolkitCarLookup from '../js/models/Dashboard/views/ToolkitCarLookup';
import ToolkitAddressLookup from '../js/models/Dashboard/views/ToolkitAddressLookup_v2';

import Coverages from '../js/models/Flow/Coverages/views/Coverages';
import Verify from '../js/models/Flow/User/views/Verify';
import CaptchaLogin from '../js/models/Login/views/Captcha';
import Woning from '../js/models/Flow/Woning/views/Woning';
import InsureThisHouse from '../js/models/Flow/Woning/views/InsureThisHouse';
import AdresInput from '../js/models/Flow/User/views/AdresInput';
import AdresInputHome from '../js/models/Flow/Woning/views/AdresInputHome';
import AdresCheck from '../js/models/Flow/Woning/views/AdresCheck';
import AdresCheckQuestions from '../js/models/Flow/Woning/views/AdresCheckQuestions';
import Rooms from '../js/models/Flow/Woning/views/Rooms';
import RoomsCheck from '../js/models/Flow/Woning/views/RoomsCheck';
import ExtraQuestion from '../js/models/Flow/Woning/views/ExtraQuestion';
import SecondExtraQuestion from '../js/models/Flow/Woning/views/SecondExtraQuestion';
import InsuranceQuestionHome from '../js/models/Flow/Woning/views/InsuranceQuestionHome';
import InsuranceNameHome from '../js/models/Flow/Woning/views/InsuranceNameHome';
import OldInsuranceStartDateInputHome from '../js/models/Flow/Woning/views/OldInsuranceStartDateInputHome';
import OwnerQuestion from '../js/models/Flow/Woning/views/OwnerQuestion';
import Gezin from '../js/models/Flow/Gezin/views/Gezin';
import SecondQuestion from '../js/models/Flow/Gezin/views/SecondQuestion';
import ThirdQuestion from '../js/models/Flow/Gezin/views/ThirdQuestion';
import FourthQuestion from '../js/models/Flow/Gezin/views/FourthQuestion';
import FifthQuestion from '../js/models/Flow/Gezin/views/FifthQuestion';
import SixthQuestion from '../js/models/Flow/Gezin/views/SixthQuestion';
import SeventhQuestion from '../js/models/Flow/Gezin/views/SeventhQuestion';
import InsuranceQuestionFamily from '../js/models/Flow/Gezin/views/InsuranceQuestionFamily';
import InsuranceNameFamily from '../js/models/Flow/Gezin/views/InsuranceNameFamily';
import OldInsuranceStartDateInputFamily from '../js/models/Flow/Gezin/views/OldInsuranceStartDateInputFamily';
import Driver from '../js/models/Flow/Car/views/Driver';
import LicensePlate from '../js/models/Flow/Car/views/LicensePlate';
import CheckCar from '../js/models/Flow/Car/views/CheckCar';
import InsuranceQuestionCar from '../js/models/Flow/Car/views/InsuranceQuestionCar';
import InsuranceNameCar from '../js/models/Flow/Car/views/InsuranceNameCar';
import OldInsuranceStartDateInputCar from '../js/models/Flow/Car/views/OldInsuranceStartDateInputCar';
import Extras from '../js/models/Flow/Lock/views/Extras';
import ExtrasOther from '../js/models/Flow/Lock/views/ExtrasOther';
import ThankYou from '../js/models/Marketplace/Market/views/ThankYou';
import CarPossession from '../js/models/Flow/Car/views/CarPossession';
import SecondHand from '../js/models/Flow/Car/views/SecondHand';
import OptionQuestion from '../js/models/Flow/Car/views/OptionQuestion';
import DistanceQuestion from '../js/models/Flow/Car/views/DistanceQuestion';
import CarLoan from '../js/models/Flow/Car/views/CarLoan';
import MobileQuestion from '../js/models/Flow/Car/views/MobileQuestion';
import CarUseQuestion from '../js/models/Flow/Car/views/CarUseQuestion';
import CheckCarManual from '../js/models/Flow/Car/views/CheckCarManual';
import CheckCarFallback from '../js/models/Flow/Car/views/CheckCarFallback';
import CheckCarExtra from '../js/models/Flow/Car/views/CheckCarExtra';

import Family from '../js/models/Marketplace/Market/views/Family';
import Fire from '../js/models/Marketplace/Market/views/Fire';
import Car from '../js/models/Marketplace/Market/views/Car';
import Checkout from '../js/models/Marketplace/Market/views/Checkout';
import BrokerHistory from '../js/models/Dashboard/views/BrokerHistory.js';
import BrokerHistoryDetailPersonal from '../js/models/Dashboard/views/BrokerHistoryDetailPersonal.js';
import BrokerHistoryDetailLiability from '../js/models/Dashboard/views/BrokerHistoryDetailLiability';
import BrokerHistoryDetailAutomotive from '../js/models/Dashboard/views/BrokerHistoryDetailAutomotive';
import BrokerHistoryDetailProperty from '../js/models/Dashboard/views/BrokerHistoryDetailProperty';

import { getComponentPath } from '../js/store/index';
import SettingsAccountSettings from '../js/models/Dashboard/views/SettingsAccountSettings';
import SettingsAffinitySettings from '../js/models/Dashboard/views/SettingsAffinitySettings';

import PrivateRouteHoc from '../js/hoc/PrivateRouteHoc';
import PrivateSessionRouteHoc from '../js/hoc/PrivateSessionRouteHoc';

import BrokerHistoryDetailPolicies from '../js/models/Dashboard/views/BrokerHistoryDetailPolicies';
import SettingsUserSettings from '../js/models/Dashboard/views/SettingsUserSettings';
import WantsLegal from '../js/models/Flow/Legal/views/WantsLegal';
import LegalConsumer from '../js/models/Flow/Legal/views/LegalConsumer';
import LegalProperty from '../js/models/Flow/Legal/views/LegalProperty';
import LegalIncome from '../js/models/Flow/Legal/views/LegalIncome';
import InsuranceQuestionLegal from '../js/models/Flow/Legal/views/InsuranceQuestionLegal';
import InsuranceNameLegal from '../js/models/Flow/Legal/views/InsuranceNameLegal';
import OldInsuranceStartDateInputLegal from '../js/models/Flow/Legal/views/OldInsuranceStartDateInputLegal';
import Legal from '../js/models/Marketplace/Market/views/Legal';
import LegalChildrenQuestion from '../js/models/Flow/Legal/views/LegalChildrenQuestion';
import LegalStudentsQuestion from '../js/models/Flow/Legal/views/LegalStudentsQuestion';
import LegalMarriedQuestion from '../js/models/Flow/Legal/views/LegalMarriedQuestion';
import BrokerHistoryDetailLegal from '../js/models/Dashboard/views/BrokerHistoryDetailLegal';
import ToolkitTerminationGenerator from '../js/models/Dashboard/views/ToolkitTerminationGenerator';
import HandleSettingsPage from '../js/models/Dashboard/components/HandleSettingsPage';

import SignatureThankYou from '../js/pages/SignatureThankYou.js';
import ThankYouForSigningLouise from '../js/pages/ThankYouForSigningLouise';

import ProFlowRouter from '../js/pages/ProFlowRouter';

import CampaignsRouter from '../packages/campaigns/src/CampaignsRouter';
import CRMRouter from '../js/pages/SMT/CRMRouter';
import CustomerRouter from '../js/pages/CustomerRouter';
import { FlagsProviderHoc } from '../js/hoc/FlagsProviderHoc';
import { LouiseFlowProvider } from '../js/services/context/LouiseFlowContext';
import ThankYouCampaigns from '../js/pages/ThankYouCampaigns';

import { ZendeskAPI } from 'react-zendesk';
import CampaignExpired from '../js/pages/CampaignExpired';

const Root = () => {
  if (window.location.pathname !== '/') {
    window.location.href = `#${window.location.pathname}`;
  }

  useEffect(() => {
    console.log('UPDATING URL', window.location.href);
    ZendeskAPI('webWidget', 'updatePath', {
      url: window.location.href,
    });
  }, [window.location.href]);

  return (
    <TopNavigationItemsProvider>
      <LouiseFlowProvider>
        <HashRouter>
          <FlagsProviderHoc>
            <Switch>
              {/* Login-screen */}
              <Redirect from="/" to="/dashboard" exact />
              <Route path="/login" component={BrokerLogin} exact />
              <Redirect from="/broker/:id" to="/session/broker/:id" />
              <Route
                path="/session/broker/:id"
                render={props => <Verify {...props} isSocialUser={true} />}
                exact
              />
              <Route
                path="/broker/captcha/:id"
                component={CaptchaLogin}
                exact
              />
              <Route path="/google_callback/" component={GoogleCallBack} />
              <Route
                path="/signature-thank-you"
                component={SignatureThankYou}
              />
              <Route
                path="/thank-you-for-signing"
                component={ThankYouForSigningLouise}
              />
              <Route
                path="/thank-you-campaigns"
                component={ThankYouCampaigns}
              />
              <Route path="/campaign-expired" component={CampaignExpired} />
              <Route path="/logout" component={Logout} />
              <Route path={'/client-overview'} component={CustomerRouter} />
              {/* dashboard */}
              <PrivateRouteHoc
                noSocial
                path={'/dashboard'}
                component={Dashboard}
                exact
              />
              <PrivateRouteHoc
                noSocial
                noTrial
                path={'/academy'}
                component={Academy}
                exact
              />
              <Redirect from="/toolkit" to="/toolkit/car-lookup" exact />
              <PrivateRouteHoc
                noSocial
                path={'/toolkit/car-lookup'}
                component={ToolkitCarLookup}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path={'/toolkit/address-lookup'}
                component={ToolkitAddressLookup}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path={'/professional-flow'}
                component={ProFlowRouter}
              />
              <PrivateRouteHoc
                noSocial
                path={'/sales-management'}
                component={CRMRouter}
              />
              <PrivateRouteHoc
                noSocial
                path={'/campaigns'}
                component={CampaignsRouter}
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history"
                component={BrokerHistory}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history/:id/personal"
                component={BrokerHistoryDetailPersonal}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history/:id/liability"
                component={BrokerHistoryDetailLiability}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history/:id/automotive"
                component={BrokerHistoryDetailAutomotive}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history/:id/property"
                component={BrokerHistoryDetailProperty}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history/:id/policies"
                component={BrokerHistoryDetailPolicies}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path="/broker-history/:id/legal"
                component={BrokerHistoryDetailLegal}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path={'/toolkit/termination-generator'}
                component={ToolkitTerminationGenerator}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path={'/toolkit/documents-generator'}
                component={ToolkitDocuments}
                exact
              />
              {/* <PrivateRouteHoc
          noSocial
          noTrial
          path={'/settings'}
          component={Settings}
          exact
        /> */}
              <PrivateRouteHoc
                noSocial
                path={'/settings'}
                component={HandleSettingsPage}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path={'/settings/account-settings'}
                component={SettingsAccountSettings}
                exact
              />
              <PrivateRouteHoc
                noSocial
                noTrial
                path={'/settings/affinity-settings'}
                component={SettingsAffinitySettings}
                exact
              />
              <PrivateRouteHoc
                noSocial
                path={'/settings/user-settings'}
                component={SettingsUserSettings}
                exact
              />
              {/* userflow */}
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Verify')}
                component={Verify}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('AdresInput')}
                component={AdresInput}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Coverages')}
                component={Coverages}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Woning')}
                component={Woning}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsureThisHouse')}
                component={InsureThisHouse}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('AdresInputHome')}
                component={AdresInputHome}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('AdresCheck')}
                component={AdresCheck}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('AdresCheckQuestions')}
                component={AdresCheckQuestions}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Rooms')}
                component={Rooms}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('RoomsCheck')}
                component={RoomsCheck}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('OwnerQuestion')}
                component={OwnerQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceQuestionHome')}
                component={InsuranceQuestionHome}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceNameHome')}
                component={InsuranceNameHome}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('OldInsuranceStartDateInputHome')}
                component={OldInsuranceStartDateInputHome}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Gezin')}
                component={Gezin}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('SecondQuestion')}
                component={SecondQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('ThirdQuestion')}
                component={ThirdQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('FourthQuestion')}
                component={FourthQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('FifthQuestion')}
                component={FifthQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('SixthQuestion')}
                component={SixthQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('SeventhQuestion')}
                component={SeventhQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceQuestionFamily')}
                component={InsuranceQuestionFamily}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceNameFamily')}
                component={InsuranceNameFamily}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('OldInsuranceStartDateInputFamily')}
                component={OldInsuranceStartDateInputFamily}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Driver')}
                component={Driver}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CarPossession')}
                component={CarPossession}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('SecondHand')}
                component={SecondHand}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                noSocial
                path={getComponentPath('LicensePlate')}
                component={LicensePlate}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CheckCar')}
                component={CheckCar}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CheckCarManual')}
                component={CheckCarManual}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CheckCarFallback')}
                component={CheckCarFallback}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CheckCarExtra')}
                component={CheckCarExtra}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('OptionQuestion')}
                component={OptionQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CarLoan')}
                component={CarLoan}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('DistanceQuestion')}
                component={DistanceQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('MobileQuestion')}
                component={MobileQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('CarUseQuestion')}
                component={CarUseQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceQuestionCar')}
                component={InsuranceQuestionCar}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceNameCar')}
                component={InsuranceNameCar}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('OldInsuranceStartDateInputCar')}
                component={OldInsuranceStartDateInputCar}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('WantsLegal')}
                component={WantsLegal}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('LegalChildrenQuestion')}
                component={LegalChildrenQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('LegalStudentsQuestion')}
                component={LegalStudentsQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('LegalMarriedQuestion')}
                component={LegalMarriedQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('LegalConsumer')}
                component={LegalConsumer}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('LegalProperty')}
                component={LegalProperty}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('LegalIncome')}
                component={LegalIncome}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceQuestionLegal')}
                component={InsuranceQuestionLegal}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('OldInsuranceStartDateInputLegal')}
                component={OldInsuranceStartDateInputLegal}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('InsuranceNameLegal')}
                component={InsuranceNameLegal}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('Extras')}
                component={Extras}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('ExtrasOther')}
                component={ExtrasOther}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('ExtraQuestion')}
                component={ExtraQuestion}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path={getComponentPath('SecondExtraQuestion')}
                component={SecondExtraQuestion}
                exact
              />
              {/* marketplace */}
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/session/market/fire/:packageName?/:packageId?/:selectedPackages?"
                component={Fire}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/session/market/family/:packageName?/:packageId?/:selectedPackages?"
                component={Family}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/session/market/car/:packageName?/:packageId?/:selectedPackages?/:activeItem?"
                component={Car}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/session/market/legal/:packageName?/:packageId?/:selectedPackages?/:activeItem?"
                component={Legal}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/session/market/checkout"
                component={Checkout}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/session/market/thankyou"
                component={ThankYou}
                exact
              />
              {/* review marketplace */}
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/review/market/fire/:packageName?/:packageId?/:selectedPackages?"
                component={Fire}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/review/market/family/:packageName?/:packageId?/:selectedPackages?"
                component={Family}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/review/market/car/:packageName?/:packageId?/:selectedPackages?/:activeItem?"
                component={Car}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/review/market/legal/:packageName?/:packageId?/:selectedPackages?/:activeItem?"
                component={Legal}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/review/market/checkout"
                component={Checkout}
                exact
              />
              <PrivateSessionRouteHoc
                to="dashboard"
                path="/review/market/thankyou"
                component={ThankYou}
                exact
              />
            </Switch>
          </FlagsProviderHoc>
        </HashRouter>
      </LouiseFlowProvider>
    </TopNavigationItemsProvider>
  );
};

export default Root;
